import React from 'react'
import TextInput from '../textInput/TextInput'
import Lookup from '../../../../modules/Lookup'
import Format from '../../../../modules/utils/Format'

// props
//  - items : array of objects with url and description property
//  - changeFunction (takes array of strings)

const delay=200;
const max200 = (txt) => {
    return Lookup.wordCount(txt) <= 200
}

export default class BrandHandles extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.renderedUrl = this.renderedUrl.bind(this);
        this.add = this.add.bind(this);
        this.handleItemChange = this.handleItemChange.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.delegateChange = this.delegateChange.bind(this);

        this.state = {
            items : null,
            hoveredIndex : -1
        };

        this.timeout = -1;
    }

    handleItemChange(newItem, index) {
        let newList = [];
        this.state.items.map((item, itemIndex) => {
            if (itemIndex === index) {
                newList.push(newItem);
            } else {
                newList.push(item);
            }
        })
        this.setState({
            items : newList
        }, this.delegateChange)
    }

    deleteItem(index) {
        let newItems = [];
        this.state.items.map(function (item, ix) {
            if (ix !== index) {
                newItems.push(item);
            }
        })
        this.setState({
            items: newItems
        }, this.delegateChange)
    }

    renderedUrl( item, index) {
        let self = this;
        let mouseOver = function() {
            self.setState({hoveredIndex : index});
            clearTimeout(self.timeout);
            self.timeout = setTimeout(function(){
                self.setState({showLinkIndex : index})
            },200);
        }

        let mouseOut = function() {
            clearTimeout(self.timeout);
            self.setState({
                hoveredIndex : -1,
                showLinkIndex : -1
            })
        }

        let deleteFunction = function() {
            self.deleteItem(index);
        }

        let handleDescriptionChange = function(newDescription) {
            self.state.items[index].description = newDescription;
            self.delegateChange();
        }

        let handleUrlChange = function(newUrl) {
            self.state.items[index].url = newUrl;
            self.delegateChange();
        }

        let linkIconTitle = '';
        let deleteIconTitle = '';
        let displayClassName = 'displayUrl';
        let deleteClassName = "delete";
        let editClassName = "editUrl";
        if (!item.url){
            editClassName += " edited";
            displayClassName += ' edited';
        }
        if (this.state.hoveredIndex === index) {
            displayClassName += ' edited';
            deleteClassName += ' edited';
            editClassName += " edited";
            deleteIconTitle = "Delete link"
        }
        let linkIconClassName='link';
        if (this.state.showLinkIndex === index) {
            linkIconClassName += " edited";
            linkIconTitle = item.url;
        }
        return (
            <div key={index}
                 className="listItem urlItem"
                 onMouseOver={mouseOver}
                 onMouseOut={mouseOut}
            >
                <div className="number">
                    {index + 1}.
                </div>
                <div>
                    <div className="urlContainer">
                        <div className={displayClassName} onClick={function(e){}}>
                            <a href={Format.webAddress(item.url)} target="_blank" style={{ color: '#078ade', textDecoration: 'none'}}>{item.url}</a>
                        </div>
                        <div className={editClassName}>
                            <TextInput
                                disableSpace
                                disabled={!this.props.permission}
                                showInput
                                itemText={item.url}
                                changeFunction={handleUrlChange}
                                placeholder="url"
                            />
                        </div>
                    </div>
                    <div className="description">
                        <TextInput
                            showInput
                            itemText={item.description}
                            disabled={!this.props.permission}
                            changeFunction={handleDescriptionChange}
                            placeholder="Description"
                            validateFunction = {max200}
                            invalidMessage = "maximum 200 words"
                        />
                    </div>
                </div>
                {item.url&& this.props.permission &&
                    <div
                        className={deleteClassName}
                        title="Go to link"
                    >
                        <a href={Format.webAddress(item.url)} target="_blank">
                            <img src="/images/ic_eye_c2.svg"/>
                        </a>
                    </div>
                }

                {this.props.permission && <div
                    onClick={deleteFunction}
                    className={deleteClassName}
                    title={deleteIconTitle}
                >
                    <img src="/images/ic-trash.svg"/>
                </div>}
                <div style={{width:15}}/>
            </div>
        )
    }

    delegateChange() {
        let f = this.props.changeFunction;
        if (typeof f === 'function') {
            f(this.state.items);
        }
    }

    add() {
        let newItems = this.state.items;
        let newIndex = this.state.items.length;
        newItems.push({
            url: '',
            description: ''
        });
        this.setState({
            items : newItems,
            hoveredIndex : newIndex
        }, this.delegateChange)
    }

    componentDidMount() {
        this.setState({
            items : this.props.items
        })
    }

    render() {
        if (!Array.isArray(this.state.items)) {
            return null;
        }
        let urls = [];
        this.state.items.map( (item, index) => urls.push(this.renderedUrl(item, index)))
        return (
            <div className="box hideAmBorder">
                { urls }
                { urls.length < 3 && this.props.permission &&
                    <div className="controlLink addItem" onClick={this.add}>
                        + Add Brand URL
                    </div>
                }
            </div>
        )
    }
}