import { call, put } from 'redux-saga/effects';
import { setSortFilter } from '../actionCreators';
import influencersSaga from './influencersSaga';

export default function* sortDisplayFilterChangeSaga({ active: { sort, preventRefresh } }) {
    yield put(setSortFilter(sort));

    if (!preventRefresh) {
        yield call(influencersSaga);
    }
}
