import React from 'react'
import cx from 'classnames';
import './actions.scss'
import Api from "../../../modules/Api";

// props
// - show
// - profile
// - inviteFunction (takes the profile)

export default class Actions extends React.Component {

    constructor (props, context) {
        super(props, context);

        // this.goToChat = this.goToChat.bind(this);
        this.goToProfile = this.goToProfile.bind(this);
        this.addToPlan = this.addToPlan.bind(this);
        this.invite = this.invite.bind(this);
    }

    invite() {
        const f = this.props.inviteFunction;
        if (typeof f === 'function') {
            f(this.props.profile.uid);
        }
    }

    goToProfile() {
        var linkToDetails = '';

        // check if connected or public profile
        if (this.props.profile.id != null) {
            linkToDetails = `/influencerDetailPage/${this.props.profile.id}`
            // ?Connected`;
        }
        if (this.props.profile.uid != null && this.props.profile.id == null) {
            linkToDetails = `/publicInfluencerDetailsPage/${
                this.props.profile.uid
            }/${this.props.profile.userNetworks[0].networkType.toUpperCase()}`;
            // ?Public`;
        }

        if (linkToDetails != '') {
            window.open(linkToDetails, '_blank');
        }
    }

    addToPlan(e) {
        if (this.props.addToPlan)
            this.props.addToPlan(e);
    }

    handleDeleteInfluencer = () => {
        this.props.removeInfluencer();
    };

    handleInviteInfluencer = () => {
        Api.inviteInfluencer(this.props.profile.email).then(() => {
            this.props.showSuccess();
        }).catch((e) => {
            this.props.showApiError(e)
        })
    };

    render () {
        const { profile, show, permission, tmAction, inviteFunction, removeInfluencer, hideAddToPlan, excludeCreator, onRemove, planWidth, 
            openFromCreator } = this.props;
        if (!show) {
            return null;
        }
        const showInvite = tmAction && profile.management && profile.management.status.includes('REMOVED');
        const showRemove = tmAction && profile.management && !profile.management.status.includes('REMOVED');
        const addToPlan = true;
        return (
            <div className={cx("actionsForOneInfluencer2022", planWidth && "planWidth")}>
                { /* typeof inviteFunction === 'function' && permission &&
                    <div onClick={this.invite}>Add to Campaign</div>
                    */
                }
                {
                    (profile.management && profile.management.status === 'ACTIVE')
                    ||!profile.management &&
                        <div onClick={this.goToProfile}>View profile</div>
                }
                {
                    !hideAddToPlan&& addToPlan &&
                        <div className="addToPlan" onClick={this.addToPlan}>Add to plan</div>
                }
                {
                    removeInfluencer && showRemove &&
                        <div onClick={this.handleDeleteInfluencer}>Remove Creator</div>
                }
                {
                    showInvite &&
                        <div onClick={this.handleInviteInfluencer}>Reinvite Creator</div>
                }
                {/* {
                    excludeCreator &&
                        <div onClick={excludeCreator}>Add to Exclude</div>
                } */}
                {
                    openFromCreator &&
                        <div onClick={openFromCreator}>Add To Assignment</div>
                }
                {
                    onRemove &&
                        <div onClick={onRemove}>Remove From Plan</div>
                }
            </div>
        )
    }
}
