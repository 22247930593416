import {LOOKUP_FILTERS_LOADING, LOOKUP_FILTERS_LOADED} from "./types";
import Api from "../../../modules/Api";

export const startLoadingLookupFilters = () => {
    let d = null;
    Api.getFilters().then(
        function(res) {
            let resolvedAction = {
                type : LOOKUP_FILTERS_LOADED,
                lookupFilters: res
            }
            d(resolvedAction);
        }
    );

    let action = {
        type : LOOKUP_FILTERS_LOADING,
        flag : true
    }
    return (dispatch) => {
        d = dispatch;
        return dispatch(action);
    }

}
