import React from 'react';
import { connect } from 'react-redux';
import { cloneDeep, get, isFinite } from 'lodash';
import moment from 'moment';
import Api from '../../../modules/Api';
import LookUp from '../../../modules/Lookup';
import Format from '../../../modules/utils/Format';
import DropdownSelect from '../../shared/selectBox/DropdownSelect';
import Checkbox from '../../shared/checkbox/Checbox';
import RateInputForInvite from './RateInputForInvite';
import ModalDialogCamp from '../../shared/modalDialog/ModalDialogCampaign';
import { refreshAssignmentList } from '../../../store/campaign/actions/campaignActions';
import { addInvitationsInSession } from '../../../store/global/globalActions';
import PleaseWait from '../../shared/pleaseWait/PleaseWaitWhite';
import {
    ACTIVE_TAB,
    GENERAL,
    PER_POST,
} from '../../../constants/rateTypes';
import './invite.scss';
import RatesService from '../../../services/ratesService';
import {
    setShowMissingChannelsError,
    setData,
} from '../../../store/campaign/influencerSearch/actionCreators'
import ConfirmModalContinue from "../../shared/confirmModal/ConfirmModalContinue";

// props
// - inviteInProgress flag
// - finishFunction
//       can have an argument to pass back , if the invite went through
//          { campaignId :
//            campaignTitle :
//            assignmentId :
//            assignmentTitle :
//            inviteList :
//          }
// - apiErrorFunction
// - uninviteFunction
// - inviteList
// - campaignId, if this is part of a campaign influencers update


class Invite extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.addToAssignment = this.addToAssignment.bind(this);
        this.removeInfluencer = this.removeInfluencer.bind(this);
        this.handleCommentChange = this.handleCommentChange.bind(this);
        this.handleSelectionChange = this.handleSelectionChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCampaignChange = this.handleCampaignChange.bind(this);
        this.handleInviteType = this.handleInviteType.bind(this);
        this.handleRateInput = this.handleRateInput.bind(this);
        this.setSelectTop=this.setSelectTop.bind(this);
        this.confirmModalContinue = this.confirmModalContinue.bind(this);
        this.checkBeforeAdd = this.checkBeforeAdd.bind(this);

        this.single=!((this.props.inviteList.length>1));
        this.state = {
            campaignId : Number(this.props.campaignId),
            assignmentId : Number(this.props.assignmentId),
            campaignTitle : '',
            assignmentTitle : '',
            inviteUsers : [],
            existUsers : [],
            inviteType: this.single? 2 : -1,
            inviteRate: {},
            assignments : [],
            isTotalRate: false,
            fieldsFilled : false,
            editUid: '',
            comment: '',
            minimalRate: 0,
            haveRequiredNetworks: true,
            resetOnInvite: false,
            showConfirm: false,
            loading: false,
        }
    }

    checkBeforeAdd() {
        const {
            inviteType,
            inviteRate,
            inviteUsers,
            isTotalRate,
        } = this.state;
        let needConfirm = false;
        const inviteInfo = {};

        if (inviteType === 0) {
            inviteInfo.rates = inviteRate;
        }

        if (Array.isArray(inviteUsers) && inviteUsers.length >= 1 && inviteType > -1) {
            // let invite = inviteUsers[0];
            for (let i = 0; i < inviteUsers.length; i++) {
                const invite = inviteUsers[i];
                const addInfo = {};
                let userRates = {};
                if (inviteType > 0) {
                    userRates = isTotalRate
                        ? {
                            [GENERAL]: inviteRate[invite.uid][GENERAL],
                            perCampaign: true,
                        }
                        : Object.entries(inviteRate[invite.uid]).reduce((acc, [key, value]) => {
                            // rewrite perCampaign value based on isTitalRate and keep other values untouched
                            acc[key] = (key === 'perCampaign' && isTotalRate) ? 0 : value;
    
                            return acc;
                        }, {});
                    addInfo.rates = Object.keys(userRates).reduce((object, key) => {
                        if (key !== 'totalRate' && key !== 'valid' && key !== 'perCampaign') {
                            object[key] = inviteRate[invite.uid][key];
                        }
    
                        if (key === 'perCampaign') {
                            object[ACTIVE_TAB] = RatesService.getActiveTabFromPerCampaign(userRates[key]);
                        }
    
                        return object;
                    }, {});
                } else if (isTotalRate) {
                    addInfo.rates = {
                        [GENERAL]: inviteRate[GENERAL],
                    };
                } else {
                    addInfo.rates = Object.keys(inviteInfo.rates).reduce((object, key) => {
                        if (key !== GENERAL) {
                            object[key] = inviteRate[key];
                        }
    
                        return object;
                    }, {});
                }
    
                const inviteData = inviteType === 0 ? inviteInfo : addInfo;
                needConfirm = isTotalRate ? (inviteData.rates.GENERAL === undefined || inviteData.rates.GENERAL === 0) : userRates.totalRate !== undefined ? userRates.totalRate === 0 : addInfo.rates ? (addInfo.rates.GENERAL === undefined || addInfo.rates.GENERAL === 0 ): false;
                if (needConfirm) {
                    break;
                }
            }
        }

        if (needConfirm) {
            this.setState({
                showConfirm: true,
            })
        }else {
            this.addToAssignment();
        }

    }

    addToAssignment() {
        const {
            assignmentId,
            assignmentTitle,
            inviteType,
            inviteRate,
            comment,
            inviteUsers,
            existUsers,
            isTotalRate,
            resetOnInvite,
        } = this.state;
        const {
            apiErrorFunction,
            afterSuccessFunction,
            finishFunction,
            setData,
        } = this.props;
        const inviteInfo = {};

        if (inviteType === 0) {
            inviteInfo.rates = inviteRate;
        }
        let addedCount = 0;
        const promises = [];
        this.setState({
            showConfirm: false,
            loading: true,
        });

        if (Array.isArray(inviteUsers) && inviteUsers.length && inviteType > -1) {
            inviteUsers.forEach((invite) => {
                const addInfo = {};

                if (inviteType > 0) {
                    const userRates = isTotalRate
                        ? {
                            [GENERAL]: inviteRate[invite.uid][GENERAL],
                            perCampaign: true,
                        }
                        : Object.entries(inviteRate[invite.uid]).reduce((acc, [key, value]) => {
                            // rewrite perCampaign value based on isTitalRate and keep other values untouched
                            acc[key] = (key === 'perCampaign' && isTotalRate) ? 0 : value;

                            return acc;
                        }, {});
                    addInfo.rates = Object.keys(userRates).reduce((object, key) => {
                        if (key !== 'totalRate' && key !== 'valid' && key !== 'perCampaign') {
                            object[key] = inviteRate[invite.uid][key];
                        }

                        if (key === 'perCampaign') {
                            object[ACTIVE_TAB] = RatesService.getActiveTabFromPerCampaign(userRates[key]);
                        }

                        return object;
                    }, {});
                } else if (isTotalRate) {
                    addInfo.rates = {
                        [GENERAL]: inviteRate[GENERAL],
                    };
                } else {
                    addInfo.rates = Object.keys(inviteInfo.rates).reduce((object, key) => {
                        if (key !== GENERAL) {
                            object[key] = inviteRate[key];
                        }

                        return object;
                    }, {});
                }

                if (comment) {
                    addInfo.comment = comment;
                }

                promises.push(
                    Api.addAssignmentUser(
                        inviteType === 0 ? inviteInfo : addInfo,
                        Number(assignmentId),
                        invite.uid,
                    ).then(
                        (res) => {
                            if (!res.alreadyExists) {
                                addedCount += 1;
                            }
                        },
                        (err) => {
                            if (apiErrorFunction) {
                                apiErrorFunction(err);
                            }
                        },
                    ),
                );
            });
        }

        Promise.all(promises).then(
            (values) => {
                const inviteRes = [];

                inviteUsers.forEach((invite) => {  
                    inviteRes.push({
                        uid: invite.uid,
                        inviteResult: `Invited to Assignment [${assignmentTitle}]`,
                    });
                });

                existUsers.forEach((existingUser) => {
                    inviteRes.push({
                        uid: existingUser.uid,
                        inviteResult: `${existingUser.status}: Assignment [${assignmentTitle}]`,
                    });
                });

                if (typeof afterSuccessFunction === 'function') {
                    // it is called only when Invite is used inside InfluencerBody
                    afterSuccessFunction(addedCount > 0);
                }

                this.setState({loading: false})

                // not necessary to show invite successfully sent if addedCount is 0
                finishFunction(addedCount > 0 ? inviteRes : null);

                /*
                    As for now, multiple select from Discovery page was turned off due to invite process issues.
                    Also, major changes were made for Influencer Search process (most of the business logic
                    was moved into sagas and store instead of local state and methods).
                    Unfortunately, Invite component still uses the previous approach (invite influencer to
                    campaign, send request and update UI not on the response, but instead update
                    existUsers and inviteUsers and use mix of these two lists).
                    * Influencer Search worked in the same way, but now it uses store updates instead.

                    The next block is needed to avoid the case when single influencer was invited from Discovery page,
                    then brand owner decides to invite another one, but in the store there already is the first
                    invited influencer and multi invite will be triggered. To avoid it - we reset inviteList
                    and inappropriateInviteList to keep invites one per time (as for now).
                 */
                if (resetOnInvite) {
                    setData({
                        inviteList: [],
                        inappropriateInviteList: [],
                    });
                }
            },
        )
            .catch(err => {
                this.setState({loading: false});
                finishFunction(null);
            });
    }

    removeInfluencer(id) {
        const {inviteType, inviteRate, inviteUsers} = this.state;
        if (inviteType>0) {
            delete inviteRate[id];
        }
        const rIndex=inviteUsers.findIndex(inv=>inv.uid===id);
        inviteUsers.splice(rIndex,1);
        this.props.uninviteFunction(id);
        this.setState({inviteUsers, inviteRate},this.handleChange());
    }

    handleChange() {
        const {
            assignmentId,
            inviteType,
            inviteRate,
            assignments,
            isTotalRate,
            minimalRate,
            haveRequiredNetworks,
        } = this.state;
        let fieldsFilled = true;
        if (!assignmentId>0 || inviteType < 0) {
            fieldsFilled = false;
        } else if (inviteType===0) {
            let totalPrice = 0;
            const assignment = assignments.find(a=>a.id===assignmentId);
            if (!assignment) {
                fieldsFilled = false;
            } else {
                const rateTypes = isTotalRate ? [GENERAL] : assignment.types.filter((type) => type !== GENERAL);

                if (isTotalRate) {
                    totalPrice = inviteRate[GENERAL] ? Number(inviteRate[GENERAL]) : 0;
                    fieldsFilled = totalPrice >= minimalRate;
                } else {
                    rateTypes.forEach((type) => {

                        if (inviteRate.hasOwnProperty(type)) {
                            if (Number(inviteRate[type]) < minimalRate) {
                                fieldsFilled = false;
                            }

                            totalPrice += Number(inviteRate[type]);
                        } else {
                            fieldsFilled = false;
                        }
                    });
                }
            }

            if (totalPrice < minimalRate) {
                fieldsFilled = false;
            }
        } else if (isTotalRate) {
            if (Object.values(inviteRate).filter(rate => rate[GENERAL] === undefined || Number(rate[GENERAL]) < minimalRate).length) {
                fieldsFilled = false;
            }
        } else if (Object.keys(inviteRate).length === 0) {
            fieldsFilled = false;
        } else if (Object.values(inviteRate).filter(r => r.valid === false ).length) {
            fieldsFilled = false;
        }


        this.setState({
            fieldsFilled: haveRequiredNetworks ? fieldsFilled : false,
        });
    }


    handleCampaignChange(option) {
        if (option.value>0) {
            if (this.assignmentSelection) {
                this.assignmentSelection.reset();
            }
            this.setState({
                campaignId: option.value,
                campaignTitle : option.label,
                editUid: '',
                assignmentId: 0,
                inviteType: (this.single)? 2 : -1,
                inviteRate: {}
            }, this.handleChange);
        }
    }

    handleSelectionChange(option) {
        /*
            It is called only when brand owner found influencer via Discovery page and tries to invite
            him(her) to the campaign on the step of assignment selection from the dropdown.
            So, we stick to the pattern saga expects (at discovery page there is no checkboxes right now).
            We mark influencer with isSelected to proceed him(her) to inviteList. runInvitation will
            trigger check for channels and Invite modal if all required channels are there.
         */
        const {
            influencers,
            inviteList,
            finishFunction,
            setShowMissingChannelsError,
        } = this.props;
        const {
            value: assignmentId,
            label: assignmentTitle,
        } = option;

        if (assignmentId > 0) {
            const { assignments } = this.state;
            const defaultAssignment = assignments.find((a) => Number(a.id) === Number(assignmentId));
            // const channels = convertRateTypesIntoChannels(defaultAssignment.types) || [];
            /*
                As for now - only single invite is allowed through discovery page
            */
            // const influencerUid = get(inviteList, '[0].uid');
            // const influencer = influencers.find(({ uid }) => uid === influencerUid);
            // const { userNetworks } = influencer;
            // const hasMissingChannels = !channels.every((channel) =>
            //     userNetworks.findIndex(({ networkType }) => networkType === channel) > -1,
            // );

            // Show popup with missing channels error ans close the Invite modal
            // if (hasMissingChannels) {
            //     finishFunction();
            //     setShowMissingChannelsError(true);
            //     return;
            // }

            const assignmentUsers = defaultAssignment.users;
            const inviteUsers = [];
            const existUsers = [];
            inviteList.forEach(user => {
                if (assignmentUsers.length>0 && assignmentUsers.findIndex(au=>au.user.uid===user.uid) > -1) {
                    existUsers.push({uid:user.uid, name: user.name, pictureUrl:user.pictureUrl, status:assignmentUsers.status});
                } else {
                    inviteUsers.push(user);
                }
            });

            this.setState({
                assignmentId,
                assignmentTitle,
                existUsers,
                inviteUsers,
                editUid: '',
                inviteType: this.single ? 2 : -1,
                // hideRateTabs: true,
                inviteRate: this.single && inviteUsers.length
                    ? getInviteRate(inviteUsers, defaultAssignment.types)
                    : {},
                resetOnInvite: true,
            }, this.handleChange);
        }
    }

    handleInviteType(value){
        const {
            assignmentId,
            assignments,
            inviteUsers,
        } = this.state;
        let inviteRate = {};
        if (value>0) {
            const assignmentTypes=assignments.find(a=>a.id==assignmentId).types;
            inviteRate = getInviteRate(inviteUsers, assignmentTypes);
        }
        this.setState({inviteType:value, inviteRate, editUid: ''}, this.handleChange);
    }

    handleRateInput(value, type, uid) {
        const { inviteRate, isTotalRate, minimalRate } = this.state;

        const clonedInviteRate = cloneDeep(inviteRate);

        if (uid) {
            const nonRateKeys = ['totalRate', 'valid', 'perCampaign'];
            const rateInfo = clonedInviteRate[uid];
            clonedInviteRate[uid][type] = Number(value);

            const totalRate = !isTotalRate
                ? Object.entries(rateInfo).reduce((acc, [key, rate]) => {
                    return nonRateKeys.indexOf(key) > -1 ? acc : acc + rate;
                }, 0)
                : rateInfo[GENERAL];

            const valid = !Object.entries(rateInfo).filter(([key, objectValue]) => {
                // nonRateKeys should not be validated
                if (nonRateKeys.indexOf(key) > -1) return false;

                return (isTotalRate && key === GENERAL) || key !== GENERAL
                    ? objectValue < minimalRate
                    : false;
            }).length;

            clonedInviteRate[uid].totalRate = totalRate;
            clonedInviteRate[uid].valid = valid;
        } else {
            clonedInviteRate[type] = Number(value);
        }

        this.setState({inviteRate: clonedInviteRate}, this.handleChange);
    }

    handleCommentChange(e) {
        this.setState({
            comment : e.target.value
        })
    }

    tabChangeHandler(isTotalRate) {
        // const {
        //     inviteUsers,
        // } = this.state;
        // const assignmentTypes = this.getAssignmentTypesByAssignmentId();
        // const newInviteRate = getInviteRate(inviteUsers, assignmentTypes);

        this.setState({
            isTotalRate,
        }, this.handleChange);
    }

    componentDidMount() {
        const {
            appropriateInviteList,
            assignmentId,
            campaignId,
            inviteList,
            apiErrorFunction
        } = this.props;
        const { inviteType } = this.state;
        this.setState({loading: true});

        const getAssignments = campaignId ? Api.getAssignmentList : Api.getAllAssignments;

        getAssignments(campaignId).then(
            (res) => {
                const assignments = [];
                let currentCtitle = '';
                let currentAtitle = '';
                let currentCid= 0;
                let currentAid= 0;
                let inviteRate = {};
                let assignmentUsers = [];
                let assignmentType = [];
                const inviteUsers = [];
                const existUsers = [];

                const data = campaignId ? res.results : res;
                if (Array.isArray(data)) {
                    data.forEach(a=>{
                        if (Boolean(a.types) &&
                            a.types.length>0 /* && a.assignmentFlights.length>0 */) {
                            // const assignmentFlightObj = a.assignmentFlights[a.assignmentFlights.length - 1];
                            // const publishEndDate = !assignmentFlightObj.perChannelDateRanges ? assignmentFlightObj.publishEndDate : (assignmentFlightObj.channelFlightDates && assignmentFlightObj.channelFlightDates.length > 0 ?
                            //     assignmentFlightObj.channelFlightDates[0].endPublishDate : null);
                            // const expirationCheckService = new ExpirationCheckService(a, a.id, a.status);
                            // expirationCheckService.init();
                            // const {assignmentStatus} = expirationCheckService;

                            // only include the assignment hasn't expired yet
                            // if ((publishEndDate && moment(publishEndDate).isAfter(moment())) || assignmentStatus !== EXPIRED) {
                            if (( Boolean(campaignId) &&
                                            Number(campaignId)>0 &&
                                            Number(campaignId)===Number(a.campaign.id) &&
                                            (!assignmentId || Number(assignmentId) === 0) )
                                        || ( Number(assignmentId) === Number(a.id) )
                                        || !campaignId) {
                                assignments.push(a);

                                if( Boolean(campaignId) &&
                                        Number(campaignId)>0 &&
                                        Number(campaignId)===Number(a.campaign.id) &&
                                        currentCtitle ==='') {
                                    currentCtitle = a.campaign.name;
                                    currentCid = campaignId;
                                }
                                if( Boolean(assignmentId) &&
                                        Number(assignmentId)>0 &&
                                        Number(assignmentId)===Number(a.id) &&
                                        currentAtitle ==='') {
                                    currentAtitle = a.name;
                                    currentAid = assignmentId;
                                    assignmentUsers = a.users;
                                    assignmentType = a.types;
                                }
                            }
                            // }
                        }
                    })
                    if (!campaignId) {
                        assignments.sort((a,b)=>{
                            if (a.campaign.name.toLowerCase() > b.campaign.name.toLowerCase()) return 1;
                            if (a.campaign.name.toLowerCase() === b.campaign.name.toLowerCase()) return 0;
                            return -1;
                        });
                    }

                }
                this.setState({ assignments });

                /*
                    Invite component is used inside two Components:
                    * client/src/components/influencers/detailPage/influencerProfileBU/secondaryNavigation/SecondaryNavigation.js
                    * client/src/components/influencers/InfluencersBody.js

                    Flow for InfluencerBody has changed. Only influencers with appropriate set of channels can be invited.
                    For this reason comes additional param appropriateInviteList.
                    If it's not empty - it should replace the inviteList
                 */
                const list = appropriateInviteList && appropriateInviteList.length ? appropriateInviteList : inviteList;

                list.forEach(user => {
                    if (assignmentUsers.length>0 && assignmentUsers.findIndex(au=>au.user.uid===user.uid) > -1) {
                        existUsers.push({uid:user.uid, name: user.name, pictureUrl:user.pictureUrl, status:assignmentUsers.status});
                    } else {
                        inviteUsers.push(user);
                    }
                });
                if (inviteType > 0 && inviteUsers.length) {
                    inviteRate = getInviteRate(inviteUsers, assignmentType);
                }

                const fieldsFilled = currentAid
                    ? Object.keys(inviteRate).length
                    && !Object.values(inviteRate).filter(r => r.valid === false ).length : false;

                this.setState({
                    // assignments: assignments,
                    campaignId: currentCid,
                    assignmentId: currentAid,
                    campaignTitle : currentCtitle,
                    assignmentTitle : currentAtitle,
                    existUsers,
                    inviteUsers,
                    inviteRate,
                    fieldsFilled,
                    loading: false,
                }, () => {
                    /*
                        https://jira.hubub.com/browse/PGSIMPL-688
                        according to the new flow (described in comments to this ticket) if there is a single
                        influencer invited and (s)he set up in his profile perPost rate - then no tabs perChannel/Total
                        should be shown for brand owner at the Invite modal window. And Influencer's perPost
                        rate should be used as the default value for the negotiations input
                     */
                    if (!this.single) return;

                    // const { inviteUsers, inviteRate } = this.state;
                    const userId = get(inviteUsers, '[0].uid');
                    // const totalRate = get(inviteRate, `[${userId}].totalRate`);
                    const perCampaign = get(inviteRate, `[${userId}].perCampaign`);

                    this.setState({
                        hideRateTypesTabs: perCampaign,
                        // negotiateBaseRate: totalRate,
                        isTotalRate: perCampaign,
                    });
                });
            }
        ).catch(err => {
            const f = apiErrorFunction;
            if (typeof f === 'function') {
                f(err);
            }
            this.setState({loading: false});
        });
    }

    setSelectTop(e){
        // console.log(this.inviteNote.parentElement.parentElement.parentElement.offsetTop, this.inviteNote.scrollHeight,e.offsetTop)
        const topOffset=Number(this.inviteNote.parentElement.parentElement.parentElement.offsetTop+80);
        const invScrollTop = this.inviteNote.parentElement.scrollTop;
        if (invScrollTop > 0) {
            // console.log(e.childNodes[0].className);
            e.childNodes[0].style.top=`${Number(topOffset+e.offsetTop-invScrollTop)}px`;
        }
    }

    renderRateTypesTabs = () => {
        const { isTotalRate } = this.state;

        return (
            <div className="ratesTabContainer">
                <div className="ratesTabs">
                    <div
                        className={`rate-item-tab${isTotalRate ? ' active' : ''}`}
                        onClick={() => this.tabChangeHandler(true)}
                        role='button'
                    >
                        Total
                    </div>
                    <div
                        className={`rate-item-tab${!isTotalRate ? ' active' : ''}`}
                        onClick={() => this.tabChangeHandler(false)}
                        role='button'
                    >
                        Per channel
                    </div>
                </div>
            </div>
        )
    }

    aggregateRenderData = () => {
        const {
            assignmentId: currentAssignmentId,
            assignments,
            campaignId,
        } = this.state;

        const campaigns = [];
        const assignmentOptions = [];
        let assignmentTypes = [];
        let currentCampaignId = 0;
        let campaignBudget = 0;

        if (assignments && assignments.length) {
            assignments.forEach((assignment) => {
                const assignmentCampaignId = get(assignment, 'campaign.id', -1);
                const assignmentCampaignName = get(assignment, 'campaign.name', '');
                const assignmentCampaignBudget = get(assignment, 'campaign.budget', 0);
                const assignmentId = get(assignment, 'id', -1);
                const assignmentName = get(assignment, 'name', '');

                if (assignmentCampaignId !== currentCampaignId) {
                    campaigns.push({
                        value: assignmentCampaignId,
                        label: assignmentCampaignName,
                    });
                    currentCampaignId = assignmentCampaignId;
                }

                if (assignmentCampaignId === campaignId && !assignment.archived) {
                    assignmentOptions.push({
                        value: assignmentId,
                        label: assignmentName,
                    });
                    campaignBudget = assignmentCampaignBudget;
                }

                if (assignmentId === currentAssignmentId) {
                    assignmentTypes = get(assignment, 'types', []);
                }


            });
        }
        assignmentOptions.sort((a,b) => {
            if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
            if (a.label.toLowerCase() === b.label.toLowerCase()) return 0;
            return -1;
        })

        return {
            assignmentOptions,
            assignmentTypes,
            campaigns,
            campaignBudget,
            currentCampaignId,
        };
    };

    calculateTotalCost = (assignmentTypes) => {
        const {
            assignmentId,
            assignments,
            inviteRate,
            inviteType,
            isTotalRate,
        } = this.state;

        if (inviteType === 0) {
            const targetAssignment = assignments.find(({ id }) => id === assignmentId);
            const types = isTotalRate
                ? [GENERAL]
                : get(targetAssignment, 'types', []);

            const totalCost = types.reduce((acc, type) => {
                const rate = get(inviteRate, type, 0);

                return acc + rate;
            }, 0);
            return totalCost;
        }
        const totalCost = Object.values(inviteRate).reduce((acc, userRateInfo) => {
            // negotiations case when rates are saved directly in inviteRate, instead of nested objects (per user)
            if (typeof userRateInfo !== 'object') {
                return acc + Number(userRateInfo);
            }

            if (isTotalRate) {
                /*
                    we do have a case when influencer didn't set perPost rate (or set, but the profile
                    wasn't updated since this changes and there is no ACTIVE_TAB field in DB yet)
                 */
                const alternativeTotalRate = get(userRateInfo, GENERAL, 0);
                return acc + Number(alternativeTotalRate);
            } 

            // sum with channels. As totalRate could be changed when switch between "Total" and "Per channel"
            // const {assignmentTypes} = this.aggregateRenderData();
            const assignmentRateTypes = userRateInfo.perCampaign ? [GENERAL] : [...assignmentTypes];

            const userTotal = Object.entries(userRateInfo).reduce((acc, [key, rate]) => {
                // use the types match to assignment if available
                if (assignmentRateTypes.length) {
                    return assignmentRateTypes.findIndex((type) => type === key) > -1
                        ? acc + rate
                        : acc;
                }
                return acc + get(inviteRate, `[${acc.uid}][totalRate]`, 0);
            }, 0);
            return acc + userTotal;
        }, 0);

        return totalCost;
    };

    getProposeRatesList = (assignmentTypes) => {
        const {
            editUid,
            inviteRate,
            isTotalRate,
        } = this.state;

        if (editUid) {
            const perCampaign = get(inviteRate, `[${editUid}].perCampaign`);

            /*
                TotalCost should be shown in two cases:
                * influencer set perPost as active tab in his account (no tabs are shown)
                * influencer set perChannel as active tab (tabs are shown to brand owner. (S)He can
                switch to total instead of perChannel
             */
            return perCampaign || (!perCampaign && isTotalRate) ? [GENERAL] : assignmentTypes;
        }

        return isTotalRate ? [GENERAL] : assignmentTypes;
    };

    getAssignmentTypesByAssignmentId = () => {
        const {
            assignments,
            assignmentId,
        } = this.state;

        const targetAssignment = assignments.find(({ id }) => id === assignmentId);
        const types = get(targetAssignment, 'types', []);

        return types;
    };

    confirmModalContinue = () => {
        this.addToAssignment();
    }

    render() {
        const {inviteInProgress, finishFunction} = this.props;
        const {
            assignments,
            campaignId,
            assignmentId,
            campaignTitle,
            assignmentTitle,
            inviteRate,
            inviteType,
            editUid,
            comment,
            fieldsFilled,
            existUsers,
            inviteUsers,
            isTotalRate,
            haveRequiredNetworks,
            hideRateTypesTabs,
            showConfirm,
            loading
        } = this.state;
        const self = this;
        const {
            assignmentOptions,
            assignmentTypes,
            campaigns,
            campaignBudget,
        } = this.aggregateRenderData();
        const totalCost = this.calculateTotalCost(assignmentTypes);

        const confirmTitle = 'Confirm and Invite';
        let confirmMsg = 'Please confirm that the rate is $'
        const confirmBtn = 'Yes, Confirm';
        if ( totalCost > 0) {
            confirmMsg += new Intl.NumberFormat({currency:'CAD'}).format(Number(totalCost))
        } else {
            confirmMsg += "0"
        }

        const inviteClass=`inviteUser${ this.single ? " single": " multi"}`;
        const ulClass=`userList inviteType_${inviteType}`;
        return (
            <ModalDialogCamp
                show = {inviteInProgress}
                title={this.single ? "Confirm and Invite" : "Invite Selected Creators" }
                proceedButtonLabel="INVITE"
                readyToProceed={fieldsFilled && inviteUsers.length > 0}
                proceedFunction={this.checkBeforeAdd}
                cancelFunction={finishFunction}
                checkBeforeProceed
            >
                <ConfirmModalContinue
                    show={showConfirm}
                    maxWidth={420}
                    title={confirmTitle}
                    msg={confirmMsg}
                    actionText={confirmBtn}
                    cancelFunc={() => this.setState({ showConfirm: false })}
                    proceedFunc={this.confirmModalContinue}
                />
                <PleaseWait show={loading} />
                {!assignments || assignments.length===0 ?
                    <div>
                        No Campaign/assignment available.
                    </div>
                    :
                    <div className={inviteClass} ref={e=>{this.inviteNote=e}}>
                        <div className="selectCampaign">
                            <span>Campaign</span>
                            {!this.props.campaignId ?
                                <DropdownSelect
                                    clearable={false}
                                    searchable
                                    single
                                    value={campaignId}
                                    placeholder="Select"
                                    options={campaigns}
                                    onChange={this.handleCampaignChange}
                                    onClick={this.setSelectTop}
                                />: <span>{campaignTitle}</span>
                            }
                        </div>

                        {campaignId > 0 &&
                            <div className="selectAssignment">
                                <span>Assignment</span>
                                {!this.props.assignmentId || Number(this.props.assignmentId)===0 ?
                                    <DropdownSelect
                                        clearable={false}
                                        searchable
                                        single
                                        value={assignmentId}
                                        placeholder="Select"
                                        options={assignmentOptions}
                                        onChange={this.handleSelectionChange}
                                        onClick={this.setSelectTop}
                                        ref={e=>{this.assignmentSelection=e}}
                                    /> : <span>{assignmentTitle}</span> }
                                <div className="campaignBudget">
                                    <div>
                                        Campaign Budget: <span>${new Intl.NumberFormat({currency:'CAD'}).format(Number(campaignBudget))}</span>
                                    </div>
                                    {this.single && !hideRateTypesTabs && (
                                        <div>{this.renderRateTypesTabs()}</div>
                                    )}
                                </div>
                            </div>}
                        {assignmentId > 0 && !this.single && inviteUsers.length>0 &&
                            <div className="inviteType">
                                <div>
                                    <Checkbox size={24} changeFunction={(e) => {this.handleInviteType(0)}} checked={inviteType === 0} />
                                    <span>Set the same rate</span>
                                </div>
                                <div>
                                    <Checkbox size={24} changeFunction={(e) => {this.handleInviteType(1)}} checked={inviteType === 1} />
                                    <span>Use Creator's standard rates</span>
                                </div>
                                <div>
                                    <Checkbox size={24} changeFunction={(e) => {this.handleInviteType(2)}} checked={inviteType === 2} />
                                    <span>Negotiate rates</span>
                                </div>
                            </div>
                        }
                        {inviteType > -1 && (assignmentId > 0 || this.single ) &&
                            <div className={ulClass}>
                                {existUsers.length>0 &&
                                <div className="selectedExist">
                                    {existUsers.map(eu=>{
                                        return (

                                            <div className="existUser" key={eu.uid}>
                                                <img className="photo" src={Format.influencerPhotoSrc(eu.pictureUrl)} alt="" />
                                                {eu.name}{this.single && " has been invited to this assignment"}
                                            </div>
                                        )
                                    })}
                                    {!this.single &&
                                    <div className="totalExist">
                                        <span>{existUsers.length} selected creator(s)</span> has been invited to this assignment
                                    </div>
                                    }
                                </div>
                                }

                                { !this.single && this.renderRateTypesTabs() }

                                {!this.single && inviteUsers.length>0 &&
                                <div className="selectedTotal">
                                    <div>
                                        <span>Selected {inviteUsers.length} creator(s)</span> {totalCost > 0 && inviteUsers.length>0 && ` = $${  new Intl.NumberFormat({currency:'CAD'}).format(Number(inviteType>0?totalCost:totalCost*inviteUsers.length))}`}
                                    </div>
                                </div>
                                }
                                { inviteUsers.length ? inviteUsers.map((user) => {
                                    /*
                                        here we should show the static info for influencer set in his profile
                                        so user total cost should be based on profile rates and current assignment types
                                    */
                                    const uid = get(user, 'uid');
                                    const rates = get(inviteRate, uid, {});
                                    const perCampaign = get(inviteRate, `[${uid}][perCampaign]`);
                                    const assignmentRateTypes = perCampaign ? [GENERAL] : [...assignmentTypes];
                                    const userTotal = Object.entries(rates).reduce((acc, [key, rate]) => {
                                        if (assignmentRateTypes.length) {
                                            return assignmentRateTypes.findIndex((type) => type === key) > -1
                                                ? acc + rate
                                                : acc;
                                        }
                                        return get(inviteRate, `[${user.uid}][totalRate]`, 0);
                                    }, 0);
                                    const ratesService = new RatesService(user);
                                    let rateTypes = ratesService.filterRatesListByAssignmentRates(assignmentRateTypes);
                                    let userTotalCost;
                                    /*
                                        it's the case when Total tab can overweight influencer profile settings
                                    */
                                    rateTypes = !this.single && isTotalRate ? [GENERAL] : rateTypes;
                                    // if it's single influencer, then use the sum. 
                                    // More than one influencer, use GENERAL if in "Total" tab or has perCampaign as true. userTotal otherwise.
                                    userTotalCost = (this.single)? (userTotal > 0 ? `$${  new Intl.NumberFormat({currency:'CAD'}).format(Number(userTotal))}`:'') :
                                        (`$${  new Intl.NumberFormat({currency:'CAD'}).format(Number(isTotalRate || (inviteRate[user.uid] && inviteRate[user.uid].perCampaign) ? (inviteRate[user.uid] && inviteRate[user.uid].GENERAL) || 0 : userTotal))}`);

                                    return (
                                        <div className="user" key={user.uid}>
                                            {!haveRequiredNetworks &&
                                            <div className="missingChannelsError">
                                                User doesn't have all required networks!
                                            </div>
                                            }
                                            <div className="influencer-details">
                                                <img className="photo" src={Format.influencerPhotoSrc(user.pictureUrl)} alt="" />
                                                <span>{user.name}</span>{inviteType > 0 && "'s rates"}
                                                {inviteType > -1 && !this.single &&
                                                <img className="remove"
                                                    src="/images/ic-close-b-d.svg"
                                                    alt=""
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        self.removeInfluencer(user.uid);
                                                    }}
                                                />
                                                }
                                            </div>

                                            { (inviteType === 2 ) && editUid !==user.uid && !this.single && <img className="edit" src="/images/ic-edit.svg" alt=""
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    self.setState({editUid: user.uid});
                                                }}
                                            />}
                                            {inviteType > 0 &&
                                            <div className="ProposeCost">
                                                {rateTypes.map((type, i, ratesArr) => {
                                                    const showRate = (this.single) ?
                                                        (Boolean(user.rates[type]) && Number(user.rates[type])>0 ?
                                                            `$${  new Intl.NumberFormat({currency:'CAD'}).format(Number(user.rates[type]))}`: ""):
                                                        (inviteRate[user.uid][type]>0 ?
                                                            `$${  new Intl.NumberFormat({currency:'CAD'}).format(Number(inviteRate[user.uid][type]))}`: "");
                                                    const rateClass = showRate === 'N/A' ? 'CostRate emptyRate' : 'CostRate validRate';

                                                    return (
                                                        <div key={type} className={rateClass}>
                                                            <span>{LookUp.getRateType(type).label}</span>
                                                            {!this.single && (editUid ===user.uid || (inviteType === 1 && (!user.rates[type] || Number(user.rates[type]) === 0))) ?
                                                                <RateInputForInvite
                                                                    rateValue={inviteRate[user.uid][type]?inviteRate[user.uid][type]:0}
                                                                    changeFunction={(value) =>
                                                                    { this.handleRateInput(value, type, user.uid)}
                                                                    }
                                                                /> : showRate
                                                            }
                                                            {(i === ratesArr.length - 1) &&
                                                            <div className="totalCost"><span>Total Cost</span>
                                                                {userTotalCost}
                                                            </div>
                                                            }
                                                        </div>
                                                    )})
                                                }

                                            </div>
                                            }
                                        </div>
                                    )
                                }) : null}

                            </div>
                        }

                        {assignmentId > 0 && this.single && inviteUsers.length>0 && editUid !== inviteUsers[0].uid &&
                            <div className="button" onClick={(e) => {
                                e.preventDefault();
                                self.setState({editUid: inviteUsers[0].uid});
                            }}>PROPOSE A NEW RATE</div>
                        }
                        {(inviteType === 0 || (this.single && inviteUsers.length>0 &&  editUid === inviteUsers[0].uid )) && assignmentId > 0 &&
                            <div className="setBudget">
                                {Array.isArray(assignmentTypes) && assignmentTypes.length > 0 &&
                                <div className="ProposeCost new-propose-cost">

                                    {
                                        this.getProposeRatesList(assignmentTypes).map((type) => {
                                            const rateValue = editUid ? inviteRate[editUid][type] : inviteRate[type];
                                            const label = type !== GENERAL
                                                ? LookUp.getRateType(type).label
                                                : 'Total Cost'; // not default label should be shown here

                                            return (
                                                <div key={type}>
                                                    <span>{label}</span>
                                                    <RateInputForInvite
                                                        rateValue={rateValue || 0}
                                                        changeFunction={ (value) => {
                                                            this.handleRateInput(value, type, editUid);
                                                        }}
                                                    />
                                                </div>
                                            )
                                        })
                                    }

                                    <div className="totalCost">
                                        <span>Total Proposed Cost</span>
                                        ${totalCost > 0 && new Intl.NumberFormat({currency:'CAD'}).format(Number(totalCost))}
                                    </div>
                                    {this.single &&
                                    <div className="comment">
                                        <textarea resize="false" placeholder="Add a note" value={comment} onChange={this.handleCommentChange} />
                                    </div>
                                    }
                                </div>
                                }

                            </div>}

                    </div>
                }
            </ModalDialogCamp>
        )
    }
}


const mapDispatchToProps = dispatch => {
    return {
        refreshAssignmentList : (campaignId) => {
            dispatch(refreshAssignmentList(campaignId));
        },
        addInvitationInSession : (invites) => {
            dispatch(addInvitationsInSession(invites));
        },
        setData: (data) => dispatch(setData(data)),
        setShowMissingChannelsError: (show) => dispatch(setShowMissingChannelsError(show)),
    }
};

const mapStateToProps = (state) => {
    return {
        campaignState : state.campaign,   // the assignments stuff for each campaign stored with key  assignments<campaignId>
        profileAccount: state.global.loggedInUserProfile,
        influencers: state.campaignReducer.influencerSearch.influencers,
    }
};

const getInviteRate = (inviteList, types) => {
    const inviteRate = inviteList.reduce((acc, user) => {
        const ratesService = new RatesService(user);
        const activeRatesOption = ratesService.getInfluencerActiveRatesOption();
        const isPerCampaignRate = activeRatesOption === PER_POST;
        const { rates, uid } = user;

        const userRate = {
            perCampaign: false,
        };
        let totalCost = 0;
        let valid = true;

        if (isPerCampaignRate) {
            /*
                https://jira.hubub.com/browse/PGSIMPL-688 look for comments. There is an explanation.
                Starting from this point (of time) the info shown at the Invite/Negotiate modal
                is based on which option (perChannel/perPost) is selected by Influencer in his profile
                at rates section, but not on perChannel/Total tabs at the Invite modal itself.
             */
            const rateValue = get(rates, GENERAL, -1);
            totalCost = isFinite(rateValue) && rateValue > 0 ? rateValue : 0;
            valid = isFinite(rateValue) && rateValue > 0;
            userRate[GENERAL] = rateValue;
            userRate.totalRate = totalCost;
            userRate.valid = valid;
            userRate.perCampaign = true;
            acc[uid] = userRate;

            return acc;
        }

        types.forEach((rate) => {
            const rateValue = get(rates, `${rate}`, -1);

            if (isFinite(rateValue) && rateValue > 0) {
                totalCost += Number(rateValue);
                userRate[rate] = Number(rateValue);
            } else {
                userRate[rate] = 0;
                valid = false;
            }
        });

        userRate.totalRate = totalCost;
        userRate.valid = valid;
        acc[uid] = userRate;

        return acc;
    }, {});

    return inviteRate;
};

export default connect(mapStateToProps, mapDispatchToProps)(Invite);
