import React from 'react'

import Format from '../../../../modules/utils/Format'
import './mediaArticleDisplay.scss'

// props
// - mediaArticle
// - imageVersion

export default class MediaArticle extends React.Component {
    constructor (props, context) {
        super(props, context);
    }

    render() {
        let article = this.props.mediaArticle;
        let key = article.id;

        let url = Format.webAddress(article.mediaUrl);

        return (
            <div className="article" key={key}>
                <div className="image">
                    { article.screenshotUrl &&
                        <img src={article.screenshotUrl}/>
                    }
                    { !article.screenshotUrl &&
                        <img src="/images/noImage.svg" style={{width:54,height:54,marginLeft:102,marginTop:48, marginBottom:48}}/>
                    }
                </div>
                <div className="text">
                    <div className="title">
                        { article.title}
                    </div>
                    { article.description }
                    <div className="url">
                        <a href={url} target="_blank">{article.mediaUrl}</a>
                    </div>
                </div>
            </div>
        )
    }
}