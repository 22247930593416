import React from 'react'
import './style.scss'

class Toggle extends React.Component{
    state = {
        isChecked: false
    };

    handleChange = (e) => {
        const {isChecked} = this.state;
        const {handleChange, name} = this.props;
        this.setState({isChecked: !isChecked});
        handleChange(name, e.target.checked)
    };


    render() {
        const {value} = this.props;
        return (
            <div className="toggle-container">
                <div className={`toggle-label ${value ? 'checked' : 'not-checked'}`}>{value ? 'Allow' : 'Not Allow'}</div>
                <input checked={value} onChange={(e) => this.handleChange(e)} type="checkbox"/>
            </div>
        );
    }
}

export default Toggle;