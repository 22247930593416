import React from 'react'
import './actions.scss'
import Api from "../../../modules/Api";

// props
// - show
// - profile
// - inviteFunction (takes the profile)

export default class Actions extends React.Component {

    constructor (props, context) {
        super(props, context);

        // this.goToChat = this.goToChat.bind(this);
        this.goToProfile = this.goToProfile.bind(this);
        this.invite = this.invite.bind(this);
    }

    invite() {
        const f = this.props.inviteFunction;
        if (typeof f === 'function') {
            f(this.props.profile.uid);
        }
    }

    goToProfile() {
        const url = `/influencerDetailPage/${  this.props.profile.id}`
        window.open(url, '_blank');
    }

    handleDeleteInfluencer = () => {
        this.props.removeInfluencer();
    };

    handleInviteInfluencer = () => {
        Api.inviteInfluencer(this.props.profile.email).then(() => {
            this.props.showSuccess();
        }).catch((e) => {
            this.props.showApiError(e)
        })
    };

    render () {
        const { profile, show, permission, tmAction, inviteFunction, removeInfluencer }=this.props;
        if (!show) {
            return null;
        }
        const showInvite = tmAction && profile.management && profile.management.status.includes('REMOVED');
        const showRemove = tmAction && profile.management && !profile.management.status.includes('REMOVED');
        return (
            <div className="actionsForOneInfluencer">
                { /* typeof inviteFunction === 'function' && permission &&
                    <div onClick={this.invite}>Add to Campaign</div>
                    */
                }
                {
                    (profile.management && profile.management.status === 'ACTIVE')
                    ||!profile.management &&
                        <div onClick={this.goToProfile}>View Full Profile</div>
                }
                {
                    removeInfluencer && showRemove &&
                        <div onClick={this.handleDeleteInfluencer}>Remove Creator</div>
                }
                {
                    showInvite &&
                        <div onClick={this.handleInviteInfluencer}>Reinvite Creator</div>
                }
            </div>
        )
    }
}
