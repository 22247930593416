import React from 'react'

import classNames from 'classnames';
import ModalDialog from '../modalDialog/ModalDialog';
import CheckBox from '../checkbox/Checbox'
import StagwellPixel from './StagwellPixel';
import PdfViewer from '../pdfViewer/PdfViewer';
import Format from '../../../modules/utils/Format'
import "./YoutubeDialog.scss"

// props for helper component RadioBox
// - selected
//  -clickFunction

class RadioBox extends React.Component {

    constructor(props, context) {
        super(props, context);
    }

    render() {
        let radioClass = "radio";

        let radioBox = "radioBox"
        if (this.props.selected) {
            radioClass += " selected";
            radioBox += " selected";
        }
        if (this.props.className) {
            radioBox += " ";
            radioBox += this.props.className;
        }
        const self = this;
        const clickHandler = function () {
            const f = self.props.clickFunction;
            if (typeof f === 'function') {
                f();
            }
        }
        return (
            <div className={radioBox} onClick={clickHandler}>
                <div>
                    <div className={radioClass}>
                        <div />
                    </div>
                </div>
                <div>
                    {this.props.children}
                </div>
            </div>
        )
    }
}

// props
// - selected

const headTag = () => ({ __html: '</head>' });

const clearState = {
    isPixel: false,
    isManual: true,
    privacyOK: false,
    blogName: '',
    blogUrl: '',
    blogReach: '',
    showPixelScreen: false,
    showStored: false,
    isContractOpen: false
}

export default class YoutubeDialog extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.clickManual = this.clickManual.bind(this);
        this.clickPixel = this.clickPixel.bind(this);
        this.handleBlogNameChange = this.handleBlogNameChange.bind(this);
        this.handleBlogUrlChange = this.handleBlogUrlChange.bind(this);
        this.handleBlogReachChange = this.handleBlogReachChange.bind(this);
        this.canConnectBlog = this.canConnectBlog.bind(this);
        this.proceedWithBlog = this.proceedWithBlog.bind(this);
        this.backBtnPressed = this.backBtnPressed.bind(this);

        this.state = clearState;

        this.urlTimeout = null;

        this.pixelCode = `<script>(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*newDate();a=s.createElement(o),m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)})(window,document,'script','https://www.google-analytics.com/analytics.js','ga'); ga('create', 'UA-94046338-1', 'auto'); ga('send', 'pageview');</script>`
    }

    privacyClicked = (flag) => {
        this.setState({
            privacyOK: flag
        }, this.canConnectBlog)
    }

    proceedWithBlog() {
        if (this.state.isPixel) {
            // we are displaying the pixel screen
            this.backBtn = true;
            this.proceedButtonLabel = 'Test Connection';
            this.cancelBlog = null;
            this.setState({
                showPixelScreen: true
            })
        } else {
            const f = this.props.proceedWithBlog;
            if (typeof f === 'function') {
                f({
                    name: this.state.blogName,
                    url: this.state.blogUrl,
                    reach: this.state.blogReach.replace(/,/gi, '')
                })
                this.backBtn = false;
                this.cancelBlog = this.props.cancelBlog;
                this.proceedButtonLabel = null;
            }
        }
    }

    clickManual() {
        this.setState({
            isPixel: false,
            isManual: true
        }, this.canConnectBlog);
    }

    clickPixel() {
        this.setState({
            isPixel: true,
            isManual: false
        }, this.canConnectBlog);
    }

    canConnectBlog() {
        const pixelFine = this.state.isPixel && this.state.privacyOK;
        const manualFine = this.state.isManual && this.state.blogReach.length > 0;
        this.setState({
            readyToConnect:
                this.state.blogName.length > 0
                && this.state.blogUrl.length > 0
                && this.state.urlOk
                && (pixelFine || manualFine)
        })
    }

    handleBlogNameChange(e) {
        this.setState({
            blogName: e.target.value
        }, this.canConnectBlog);
    }

    checkUrl= () => {
        this.canConnectBlog();
        const delay = 2000;
        this.urlTimeout = setTimeout( () => {
            const urlOk = Format.validateUrl(this.state.blogUrl);
            this.setState({
                urlError :  urlOk ? null : 'Please, enter a valid url',
                urlOk
            }, this.canConnectBlog);
        }, delay)
    }

    handleBlogUrlChange(e) {
        clearTimeout(this.urlTimeout);
        const {value} = e.target;
        this.setState({
            blogUrl: value,
            urlError : null,
            urlOk : false
        }, this.checkUrl);
    }

    handleBlogReachChange(e) {
        const reach = e.target.value.replace(/,/gi, '');
        const reg = /^\d+$/;
        if (e.target.value === '') {
            this.setState({
                blogReach: ''
            }, this.canConnectBlog);
        }
        if (reg.test(reach)) {
            const re = /(?=(?!\b)(\d{3})+$)/g;
            this.setState({
                blogReach: reach.replace(re, ',')
            }, this.canConnectBlog);
        }
    }

    backBtnPressed() {
        this.backBtn = false;
        this.proceedButtonLabel = null;
        this.cancelBlog = this.props.cancelBlog;
        this.setState({
            showPixelScreen: false
        })
    }

    myPixelFunction() {
        const copyText = document.getElementById("myPixelCopy");
        copyText.select();
        document.execCommand("copy");
    }

    static getDerivedStateFromProps(props, state) {
        let newState = {};
        if (!state.showStored && state.show) {
            // eslint-disable-next-line no-console
            console.log('just opening');
            newState = clearState;
        }
        newState.showStored = props.show;
        return newState;
    }

    render() {

        let tService = 'https://www.koalifyed.com/terms-of-service/';
        let pPolicy = 'https://www.koalifyed.com/privacy-policy/';
        return (
            <>
                {this.state.isContractOpen &&
                    <PdfViewer pdfUrl="/images/docs/PlatformPrivacyAgreement.pdf" onClose={() => this.setState({ isContractOpen: false })} />
                }
                <ModalDialog
                    show={this.props.show}
                    title="Connect Your Account with YouTube"
                    proceedButtonLabel={this.proceedButtonLabel || "CONTINUE"}
                    proceedFunction={this.proceedWithBlog}
                    cancelFunction={this.props.cancelBlog}
                    closeButton
                    readyToProceed
                    backBtn={this.backBtn}
                    backBtnPressed={this.backBtnPressed}
                    dontProceed={this.state.isPixel}
                    noCancel
                    noTitle
                    footerColor
                >
                    <div className="youtubeTitle">Connect Your Account with YouTube</div>
                    {!this.state.showPixelScreen && <div className="youtubeBody">
                        Resources: 
                        <a href={tService} target="_blank" rel="noopener noreferrer">Terms of Services, </a>
                        <a href={pPolicy} target="_blank" rel="noopener noreferrer">Privacy Policy, </a>
                        <a href="https://www.youtube.com/t/terms" target="_blank" rel="noopener noreferrer">Security Settings </a>
                    </div>}
                </ModalDialog>
            </>
        )
    }
}
