import { Auth } from 'aws-amplify';

export function getUser() {
  return Auth.currentAuthenticatedUser();
}

/*
// To setup TOTP, first you need to get a `authorization code` from Amazon Cognito
// `user` is the current Authenticated user
Auth.setupTOTP(user).then((code) => {
  // You can directly display the `code` to the user or convert it to a QR code to be scanned.
  // E.g., use following code sample to render a QR code with `qrcode.react` component:
  //      import QRCode from 'qrcode.react';
  //      const str = "otpauth://totp/AWSCognito:"+ username + "?secret=" + code + "&issuer=" + issuer;
  //      <QRCode value={str}/>
});
*/
export function setupTOTP(user) {
  // return Auth.currentAuthenticatedUser().then(user => {
  // });
  return Auth.setupTOTP(user);
    // .then(code => {
    //   return code;
    // })
}

/*
// Then you will have your TOTP account in your TOTP-generating app (like Google Authenticator)
// Use the generated one-time password to verify the setup
*/
export function verifyTotpToken(user, challengeAnswer) {
  // return Auth.currentAuthenticatedUser().then(user => {
  // });
  return Auth.verifyTotpToken(user, challengeAnswer);
    // .then(() => {
    //
    //   // don't forget to set TOTP as the preferred MFA method
    //   Auth.setPreferredMFA(user, 'TOTP');
    //   // ...
    // })
    // .catch( e => {
    //   // Token is not verified
    // });
}

/*
// You can select preferred mfa type, for example:
// Select TOTP as preferred
Auth.setPreferredMFA(user, 'TOTP').then((data) => {
  console.log(data);
  // ...
}).catch(e => {});

// Select SMS as preferred
Auth.setPreferredMFA(user, 'SMS');

// Select no-mfa
Auth.setPreferredMFA(user, 'NOMFA');
 */
export function setPreferredMFA(user, mfaType) {
  return Auth.setPreferredMFA(user, mfaType);
    // .then((data) => {
    //   console.log(data);
    //   // ...
    // }).catch(e => {});
}

/*
// Will retrieve the current mfa type from cache
Auth.getPreferredMFA(user,{
  // Optional, by default is false.
  // If set to true, it will get the MFA type from server side instead of from local cache.
  bypassCache: false
}).then((data) => {
  console.log('Current prefered MFA type is: ' + data);
})
*/
export function getPrefferedMFA(user) {
  return Auth.getPreferredMFA(user);
}
