import React from 'react';
import './checkbox.scss';

// props :
//  changeFunction
//  checked
//  size (defaults to 24)
//  isDisabled

export default class Checkbox extends React.Component {

    constructor (props, context) {
        super(props, context);

        this.handleClick = this.handleClick.bind(this);
        this.changeCallback = this.changeCallback.bind(this);

        this.state = {
            checked : this.props.checked
        }
    }

    componentDidUpdate(prevProps) {
        const {checked} = this.props;
        if (checked !== prevProps.checked) {
            this.setState({checked});
        }
    }

    changeCallback() {
        const f = this.props.changeFunction;
        if (typeof f !== 'function') {
            return;
        }
        f(this.state.checked);
    }

    handleClick(e) {
        e.stopPropagation();

        if (!this.props.controlled) {
            this.setState({
                checked: !this.state.checked
            }, this.changeCallback);
        } else {
            this.changeCallback();
        }
    }

    render() {
        let className = 'iCheckbox2022';
        let { checked } = this.state;
        const { size, partialSelectController, partialSelect, controlled } = this.props;

        if (controlled) {
            checked = this.props.checked;
        }
        
        const divStyle = {};
        const imgStyle = {};

        if (size) {
            divStyle.width = size;
            divStyle.height = size;
            imgStyle.width = size - 2;
            imgStyle.height = size - 2;
        }
        
        if (!checked) {
            className += ' hidden';
        } else {
            className += ' checkbox-checked';
            if (size && !this.props.fixedSize) {
                divStyle.width = size + 4;
                divStyle.height = size + 4;
                imgStyle.width = size + 8;
                imgStyle.height = size + 8;
            }
        }
        
        let img = <img src="/images/ic-checkmark3.svg" style={imgStyle} alt="checkbox" />;

        if (partialSelectController && partialSelect) {
            img = <span>&#8211;</span>;
            className = 'iCheckbox';
        }

        return (
            <div className={[className, this.props.isDisabled ? 'checkbox-disabled' : ''].join(" ")} onClick={(e) => this.props.isDisabled ? null : this.handleClick(e)} style={divStyle}>
                {img}
            </div>
        );
    }
}
