import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import cx from 'classnames';
import PageDropDown from '../favourite/PageDropDown';
import Api from '../../modules/Api';

const SavedSearchDropDown = ({
    onChange = () => null,
    disabled = false,
    selected,
    height = 36,
    placeHolder = 'Select list',
    multi
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [data, setData] = useState();
    const [searchValue, setSearchValue] = useState('');
    const currentPage = useRef(1);
    const totalPages = useRef(0);

    useLayoutEffect(() => {
        fetchData();
    }, [searchValue]);

    useLayoutEffect(() => {
        if (data) {
            if (data.content) {
                if (currentPage.current === 1) {
                    setDataList(data.content);
                } else {
                    const update = [...dataList, ...data.content];
                    setDataList(update);
                }
            };
            if (data.totalPages) {
                totalPages.current = data.totalPages;
            };
        };
    }, [data]);

    const fetchData = () => {
        const params = {
            desc: false,
            page: currentPage.current,
            num: 20,
            sortBy: 'name',
            search: searchValue,
        }
        setIsLoading(true);
        Api.getAgentSavedSearches(params)
            .then(res => {
                setData(res);
                setIsLoading(false);
            })
            .catch(err => {
                handleApiError(err);
                setIsLoading(false);
            });
    }

    const onLoadMore = () => {
        if (currentPage.current < totalPages.current) {
            currentPage.current++;
            fetchData();
        }
    };

    const onSearch = (value) => {
        currentPage.current = 1;
        setSearchValue(value);
    };

    const getOptions = () => {
        if (dataList.length > 0) {
            return dataList.map(ab => ({
                ...ab,
                value: ab.id,
                label: ab.name
            }));
        } 
        return [];
        
    }

    return <PageDropDown
        options={getOptions()}
        onChange={onChange}
        placeHolder={placeHolder}
        height={height}
        loading={isLoading}
        onLoadMore={onLoadMore}
        disabled={disabled}
        selected={selected}
        multi={multi}
        searchable
        onSearch={onSearch}
    />

}

export default SavedSearchDropDown;