import { hotjar } from 'react-hotjar';
import Api from '../Api';
import {INFLUENCER_STR, TALENT_MANAGER_STR} from '../../constants/authorities';

const verifiedPeriod = 7;
let currentLoginUserProfile = null;

export default class ProfileHelper {
    static getCurrentLoginUserProfile(force = false) {
        return new Promise(async (resolve, reject) => {
            if (currentLoginUserProfile && !force) {
                return resolve(currentLoginUserProfile);
            }
            try {
                const res = await Api.getProfile();
                currentLoginUserProfile = res;
                if (!!process.env.HOTJAR_SITE_ID && currentLoginUserProfile && currentLoginUserProfile.id) {
                    hotjar.identify('USER_ID', { userProperty: currentLoginUserProfile.id });
                }
                return resolve(res);
            } catch(err) {
                return reject(err);
            }
        })
    }

    static getSpecifiedUserProfile(id) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await Api.getProfile(id);
                return resolve(res);
            } catch(err) {
                return reject(err);
            }
        })
    }

    static reset() {
        currentLoginUserProfile = null;
    }

    static saveProfile(data, id = null, managerPermission) {
        return new Promise(async (resolve, reject) => {
            try {
                if (!Array.isArray(data.brands)) {
                    data.brands = [];
                }
                if (!Array.isArray(data.hobbies)) {
                    data.hobbies = [];
                }
                if (!Array.isArray(data.foods)) {
                    data.foods = [];
                }
                await Api.saveProfile(data, id, managerPermission);
                // due to the response of save profile API doesn't have all the up to date data, so need to call
                // get currentLoginUserProfile again to get the latest data.
                const res = await Api.getProfile(id);
                if (id === null || (currentLoginUserProfile && currentLoginUserProfile.id === id)) {
                    currentLoginUserProfile = res;
                }
                return resolve(res);
            } catch(err) {
                console.log(err);
                return reject(err);
            }
        })
    }

    static shouldLockCurrentLoginUser() {
        if (currentLoginUserProfile && currentLoginUserProfile.createdOn && 
            (currentLoginUserProfile.role === INFLUENCER_STR|| currentLoginUserProfile.role === TALENT_MANAGER_STR)) {
            // if the account is not verified in past 7 days
            try {
                const created = new Date(currentLoginUserProfile.createdOn);
                const today = new Date();
                const remainingDays = verifiedPeriod - Math.ceil((today.getTime() - created.getTime()) / (1000 * 60 * 60 * 24));
                if (remainingDays < 0) {
                    return true;
                }
            } catch (err) {
                console.log(err);
            }
        }
        return false;
    }
}
