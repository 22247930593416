import { useState, useEffect } from 'react';
import { mapValuesToSelectOptions } from '../OptionFilter.helpers';

export const useOptionFilter = ({ filter, onSubmit, specificNeedChange, processedChange, filterData }) => {
    // {
    //     "value": 1,
    //     "label": "6 Months"
    // }[]
    const [selectedOptions, setSelectedOptions] = useState([]);
    // {
    // optionId
    // customValue
    // }[]
    const [customValues, setCustomValues] = useState([]);

    const options = mapValuesToSelectOptions(filter.filterOptions)
        // Remove selected options from available options
        .filter((filterOption) => !selectedOptions.find((selectedOption) => selectedOption.value === filterOption.value));

    const updateCustomValues = ({ optionId, newCustomValue }) => {
        const customValueIndex = customValues.findIndex((customValue) => customValue.optionId === optionId);
        let newCustomValues = [...customValues];
        if (customValueIndex === -1) {
            newCustomValues = [...customValues, { optionId, customValue: newCustomValue }];
        } else {
            newCustomValues[customValueIndex] = { optionId, customValue: newCustomValue };
        }

        setCustomValues(newCustomValues);

        return newCustomValues;
    };

    const handleSubmit = ({ options, newCustomValues, latestCustomValue }) => {
        const filterOptionIds = options.map((option) => option.value);
        if (filter.weighted) {
            if (filter.multipleSelect) {
                const customRanges = newCustomValues
                    .filter((customValueItem) => filterOptionIds.includes(customValueItem.optionId))
                    .map((customValueItem) => ({
                        minValue: customValueItem.customValue,
                        field: customValueItem.optionId,
                    }));

                const payload = {
                    filterOptionIds,
                    customRanges,
                };
                onSubmit(payload);
            } else {
                const payload = {
                    filterOptionIds,
                    customValue: latestCustomValue,
                };
                onSubmit(payload);
            }
        } else {
            onSubmit({ filterOptionIds });
        }
    };

    const onCustomValueBlur = (option) => (newCustomValue) => {
        if (filter.multipleSelect) {
            const newCustomValues = updateCustomValues({ optionId: option.value, newCustomValue });
            handleSubmit({ options: selectedOptions, newCustomValues });
        } else {
            handleSubmit({ options: selectedOptions, latestCustomValue: newCustomValue });
        }
    };

    const onSelectValueChange = (option) => {
        const isSelectedAlready = selectedOptions.find((selectedOption) => selectedOption.value === option.value);

        if (isSelectedAlready) {
            return;
        }

        if (filter.weighted) {
            if (filter.multipleSelect) {
                updateCustomValues({ optionId: option.value, newValue: 0 });
                setSelectedOptions((prevState) => [...prevState, option]);
            } else {
                setSelectedOptions([option]);
            }
        } else {
            let updatedSelectedOptions = [];
            if (filter.multipleSelect) {
                updatedSelectedOptions = [...selectedOptions, option];
            } else {
                updatedSelectedOptions = [option];
            }
            setSelectedOptions(updatedSelectedOptions);
            handleSubmit({ options: updatedSelectedOptions });
        }
    };

    const onOptionRemove = (option) => () => {
        const updatedOptions = selectedOptions.filter((prevStateOption) => prevStateOption.value !== option.value);

        setSelectedOptions(updatedOptions);
        console.log('updatedOptions:', updatedOptions);

        if (updatedOptions.length) {
            handleSubmit({ options: updatedOptions, newCustomValues: customValues });
        }
    };

    const firstSelectedOption = selectedOptions?.length ? selectedOptions[0] : undefined;
    const selectCurrentValue = !filter.multipleSelect ? firstSelectedOption : undefined;
    useEffect(() => {
        if(specificNeedChange && filterData) {
            const { filterOptionIds } = filterData
            const refreshOptions = selectedOptions.filter(item => filterOptionIds.includes(item.value))
            setSelectedOptions(refreshOptions)
            processedChange()
        }
    }, [specificNeedChange]);
    return {
        options,
        selectCurrentValue,
        onOptionRemove,
        onSelectValueChange,
        onCustomValueBlur,
        selectedOptions,
    };
};
