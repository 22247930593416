import React from 'react'
import './Pagination.scss'

export default class Pagination extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.handlePageChange = this.handlePageChange.bind(this);
    }

    handlePageChange(e) {
        if (e.target.className.indexOf("disabled") < 0) {
            const currentPage = Number(e.target.id);
            const f = this.props.handlePageChange;
            if (typeof f === "function") {
                f(currentPage);
            }
            if (this.props.parentContainer) {
                this.props.parentContainer.scrollTo(0, 0);
            }
        }
    }

    render() {
        if (this.props.total > 0) {

            const pageNumbers = [];
            for (let i = 1; i <= Math.ceil(this.props.total / this.props.limit); i++) {
                pageNumbers.push(i);
            }

            const renderPageNumbers = pageNumbers.map(number => {
                return (
                    <li
                        key={number}
                        id = {number}
                        className={(number===this.props.current)?"current":"pageNumber"}
                        onClick={this.handlePageChange}
                    >
                        {number}
                    </li>
                );
            });
            return (<div className="pageNav"><ul>
                {pageNumbers.length > 1 &&
                <li key="prev" id={this.props.current - 1} className={`prevButton pageNav${  this.props.current === 1 ?" disabled":""}`} onClick={this.handlePageChange}>
                    Previous
                </li>
                }
                {renderPageNumbers}
                {pageNumbers.length > 1 &&
                <li key="next" id={this.props.current + 1} className={`nextButton pageNav${  this.props.current === pageNumbers.length ?" disabled":""}`} onClick={this.handlePageChange}>
                    Next
                </li>
                }
            </ul></div>);

        } 
        return null;
        

    }
}
