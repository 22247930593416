import React, { Component } from 'react';
import Modal from 'react-modal';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import './ImageSlider.scss';

export default class ImageSlider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentIndex: 1,
        };
    }

    onSlide = (currentIndex) => {
        this.setState({
            currentIndex: currentIndex + 1,
        });
    };

    close = () => {
        const { onClose } = this.props;
        onClose();
    };

    onDelete = () => {
        const { deleteFunc, items } = this.props;
        const { currentIndex } = this.state;
        deleteFunc(currentIndex, items);
        this.setState({ currentIndex: 1 });
    };

    createDeleteList = (newList, oldList) => {
        if (!oldList || oldList.length === 0) {
            return newList.map((a, i) => {
                return i;
            });
        };
        let oldUrls = oldList.map(ol => { return ol.split('?')[0] || '' });
        let newUrls = newList.map(nl => { return nl.split('?')[0] || '' });
        let list = [];
        newUrls.map((nu, index) => {
            if (!oldUrls.includes(nu))
                list.push(index);
        })
        return list;
    }

    render() {
        const { items, isOpen, deleteDisable, unDeleteList } = this.props;
        const { currentIndex } = this.state;
        const slideItems = [];
        items.forEach((item) => slideItems.push({ original: item }));
        const deleteList = this.createDeleteList(items, unDeleteList);

        return (
            <Modal
                closeTimeoutMS={300}
                overlayClassName="imageSliderOverlay"
                className="imageSliderModal"
                isOpen={isOpen}
            >
                <div className="close-btn" onClick={this.close} />
                <div className="topBar">
                    <div className="indicators">
                        {currentIndex}/{items.length} screenshots
                    </div>
                    {!deleteDisable && deleteList.includes(currentIndex - 1) && (
                        <div className="deleteBtn" onClick={this.onDelete}>
                            Delete
                        </div>
                    )}
                </div>
                <ImageGallery
                    items={slideItems}
                    showPlayButton={false}
                    showFullscreenButton={false}
                    showThumbnails={false}
                    onSlide={this.onSlide}
                />
            </Modal>
        );
    }
}
