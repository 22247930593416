const xConfig = require('../../../server/config/public');

let xConfigPlublic = null;
export default function getConfigs(){
    // //uncomment below to enable fetching config from server instead of taking config from process.env.uiconfig by default
    if (!xConfigPlublic) {
        const request = new XMLHttpRequest();
        request.open('GET', '/xconfig/public', false);  // `false` makes the request synchronous
        request.send(null);

        if (request.status === 200) {
            try {
                xConfigPlublic = JSON.parse(request.responseText);
            } catch (e) {
                console.log('xConfigPlublic', e.message);
            }

        }
        // xConfigPlublic = xConfig;
    }

    // console.log('xConfigPlublic.USER_POOL_ID', xConfigPlublic.USER_POOL_ID);
    return xConfigPlublic;
}
