import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import Select from 'react-select';
import { CSVLink } from 'react-csv';
import Api from '../../../../../modules/Api';
import PendoHelper from '../../../../../modules/utils/PendoHelper';
import './exportDropdown.scss';

export const MAX_EXPORT_COMMENTS = 2000;

const exportOptions = [
    { label: 'Comment Sentiment Summary CSV', value: 'SUMMARY' },
    { label: 'Comment List CSV', value: 'COMMENT' },
    // { label: 'Sentiment PDF', value: 'PDF' },
];

const getSummaryHeaders = ({ topics }) => [
    { label: 'Campaign', key: 'campaignName' },
    { label: 'Assignment', key: 'assignments' },
    { label: 'Date', key: 'date' },
    { label: 'Channel', key: 'channels' },
    { label: 'Creators', key: 'creators' },
    { label: 'Posts', key: 'posts' },
    { label: 'Overall - Total Comments', key: 'totalCommentCount' },
    { label: 'Overall - Unique Commenters', key: 'totalUniqueCommentCount' },

    { label: 'Channel - IG - Total Comments', key: 'instagramCommentsCount' },
    { label: 'Channel - IG - Unique Comments', key: 'instagramUniqueCommentCount' },
    { label: 'Channel - IG - Branded Comments', key: 'instagramBrandedCommentCount' },
    { label: 'Channel - IG - Branded Comments Ratio', key: 'instagramBrandedCommentRatio' },

    { label: 'Channel - TT - Total Comments', key: 'tiktokCommentsCount' },
    { label: 'Channel - TT - Unique Comments', key: 'tiktokUniqueCommentCount' },
    { label: 'Channel - TT - Branded Comments', key: 'tiktokBrandedCommentCount' },
    { label: 'Channel - TT - Branded Comments Ratio', key: 'tiktokBrandedCommentRatio' },

    { label: 'Channel - YT - Total Comments', key: 'youtubeCommentsCount' },
    { label: 'Channel - YT - Unique Comments', key: 'youtubeUniqueCommentCount' },
    { label: 'Channel - YT - Branded Comments', key: 'youtubeBrandedCommentCount' },
    { label: 'Channel - YT - Branded Comments Ratio', key: 'youtubeBrandedCommentRatio' },

    { label: 'Overall Perception - Total Mentions', key: 'overallTotal' },
    { label: 'Overall Perception - Positives', key: 'overallPositive' },
    { label: 'Overall Perception - Neutral', key: 'overallNeutral' },
    { label: 'Overall Perception - Negative', key: 'overallNegative' },

    { label: 'Product Perception - Total Mentions', key: 'productTotal' },
    { label: 'Product Perception - Positives', key: 'productPositive' },
    { label: 'Product Perception - Neutral', key: 'productNeutral' },
    { label: 'Product Perception - Negative', key: 'productNegative' },

    { label: 'Category Perception - Total Mentions', key: 'categoryTotal' },
    { label: 'Category Perception - Positives', key: 'categoryPositive' },
    { label: 'Category Perception - Neutral', key: 'categoryNeutral' },
    { label: 'Category Perception - Negative', key: 'categoryNegative' },

    ...topics.reduce((prev, topic) => {
        return [
            ...prev,
            { label: `Sentiment by Topic - ${topic} - Total Mentions`, key: `topic-${topic.replaceAll(' ', '_')}-totalMentions` },
            { label: `Sentiment by Topic - ${topic} - Positives`, key: `topic-${topic.replaceAll(' ', '_')}-positive` },
            { label: `Sentiment by Topic - ${topic} - Neutral`, key: `topic-${topic.replaceAll(' ', '_')}-neutral` },
            { label: `Sentiment by Topic - ${topic} - Negative`, key: `topic-${topic.replaceAll(' ', '_')}-negative` },
        ];
    }, []),

    { label: 'Comment Sentiment - Total Comments', key: 'commentTotal' },
    { label: 'Comment Sentiment - Positives', key: 'commentPositive' },
    { label: 'Comment Sentiment - Neutral', key: 'commentNeutral' },
    { label: 'Comment Sentiment - Negative', key: 'commentNegative' },

    // TODO: add Sentiment by AI
];

const commentHeaders = [
    { label: 'Campaign', key: 'campaignName' },
    { label: 'Assignment', key: 'assignments' },
    { label: 'Date', key: 'date' },
    { label: 'Channel', key: 'channels' },
    { label: 'Creators', key: 'creators' },
    { label: 'Posts', key: 'posts' },

    { label: 'Keyword', key: 'keywords' },
    { label: 'Languages', key: 'languages' },
    { label: 'Relevancy', key: 'relevancy' },
    { label: 'Sentiment', key: 'sentiment' },
    { label: 'Comment Line - Translated', key: 'translatedComment' },
    { label: 'Comment Line - Original', key: 'originalComment' },
    { label: 'Total Likes', key: 'totalLikes' },
    { label: 'Total Replies', key: 'totalReplies' },
    { label: 'Link', key: 'link' },
];

const CSVDownload = (props) => {
    const btnRef = useRef(null);
    useEffect(() => btnRef.current?.click(), [btnRef]);
    return (
        <CSVLink {...props}>
            <span ref={btnRef} />
        </CSVLink>
    );
};

const getLanguage = (code) => {
    if (!code) return null;
    const lang = new Intl.DisplayNames(['en'], { type: 'language' });
    return lang.of(code);
};

function ExportDropdown({
    campaignInfo,
    startDate,
    endDate,
    channels,
    creators,
    posts,
    analysisData,
    commentFilters,
    setLoading,
    showError,
}) {
    const selectedChannels = channels.filter((channel) => channel.selected);
    const channelsData = selectedChannels.length === channels.length ? 'All' : selectedChannels.map((channel) => channel.label).join(', ');

    const selectedCreators = creators.filter((creator) => creator.selected);
    const creatorsData = selectedCreators.length === creators.length ? 'All' : `Selected (${selectedCreators.length})`;

    const allPosts = [...posts.instagram.posts, ...posts.tiktok.posts, ...posts.youtube.posts];
    const selectedPosts = allPosts.filter((post) => post.selected);
    const postsData = selectedPosts.length === allPosts.length ? 'All' : `Selected (${selectedPosts.length})`;

    const getCommentsData = async () => {
        let commentsData;

        if (selectedChannels.length === 0) {
            return commentsData;
        }

        await Api.getCampaignSentimentComments(
            campaignInfo.assignmentIds,
            selectedChannels.map((channel) => channel.value),
            startDate,
            endDate,
            commentFilters?.excludedCreators,
            commentFilters?.excludedPosts,
            commentFilters?.sortBy,
            commentFilters?.sortOption,
            commentFilters?.languages,
            commentFilters?.relevancy,
            commentFilters?.sentiment,
            commentFilters?.keywords,
            commentFilters?.perPage,
            0,
        )
            .then((res) => {
                commentsData = res;
            })
            .catch(() => console.error('Could not load comments data.'));

        return commentsData;
    };

    const getCommentDataCSV = (commentsData) =>
        commentsData?.comments
            .reduce((prev, comment) => [...prev, comment, ...comment.replies], [])
            .map((comment) => ({
                campaignName: campaignInfo.campaignName,
                assignments: campaignInfo.assignments.join(', '),
                date: `${startDate} - ${endDate}`,
                channels: channelsData,
                creators: creatorsData,
                posts: postsData,

                keywords: commentsData?.keywords?.join(', ') || '',
                languages: getLanguage(comment.detectedTextLanguage) || '',
                relevancy: [...(comment.categories || []), ...(comment.products || []), ...(comment.topics || [])].join(', '),
                sentiment: comment.effectiveSentiment,
                translatedComment: comment.translatedText?.replace(/"/g, '""') || '',
                originalComment: comment.text?.replace(/"/g, '""') || '',
                totalLikes: comment.likes,
                totalReplies: comment?.replies?.length || 0,
                link: comment.url,
            })) || [];

    const exportCSV = (csvData, headers, fileName, pendoHelperName) => {
        const link = <CSVDownload data={csvData} target="_blank" headers={headers} filename={fileName} />;

        const elem = document.createElement('div');
        elem.id = 'export-report-container';
        document.getElementById('root').appendChild(elem);
        ReactDOM.render(link, elem);

        if (startDate && endDate) PendoHelper.pendoTrack(pendoHelperName, {});
    };

    const handleExportSelect = async (option) => {
        if (option.value === 'SUMMARY') {
            const topics = analysisData?.topicPerception ? Object.keys(analysisData?.topicPerception) : [];
            const summaryDataCSV = getSummaryCSV({
                campaignInfo,
                channelsData,
                creatorsData,
                postsData,
                startDate,
                endDate,
                analysisData,
                topics,
            });
            exportCSV(
                summaryDataCSV,
                getSummaryHeaders({ topics }),
                `Sentiment-Summary-${startDate}-${endDate}.csv`,
                'Sentiment_Summary_ExportCSV',
            );
        } else if (option.value === 'COMMENT') {
            setLoading(true);
            const commentData = await getCommentsData()
                .then((res) => {
                    setLoading(false);
                    return res;
                })
                .catch((e) => showError());
            const commentDataCSV = getCommentDataCSV(commentData);
            exportCSV(commentDataCSV, commentHeaders, `Sentiment-Comment-${startDate}-${endDate}.csv`, 'Sentiment_Comment_ExportCSV');
        }
        // TODO: PDF export
    };

    const menuRenderer = ({ options }) => (
        <>
            {options.map((option) => (
                <div key={option.label} className="export-option" onClick={() => handleExportSelect(option)}>
                    {option.label}
                </div>
            ))}
        </>
    );

    const valueRenderer = () => <span className="export-placeholder">Export</span>;

    return (
        <Select
            className="sentiment-analytics-filter export-dropdown"
            searchable={false}
            value={{ label: 'Export' }}
            options={exportOptions}
            menuRenderer={menuRenderer}
            valueRenderer={valueRenderer}
            clearable={false}
            single
            onChange={handleExportSelect}
        />
    );
}

const getSummaryCSV = ({ campaignInfo, channelsData, creatorsData, postsData, startDate, endDate, analysisData, topics }) => {
    const overallTotal = analysisData
        ? analysisData.productRelatedPerception.positiveCount +
          analysisData.productRelatedPerception.neutralCount +
          analysisData.productRelatedPerception.negativeCount
        : 0;
    const productTotal = analysisData
        ? analysisData.productPerception.positiveCount +
          analysisData.productPerception.neutralCount +
          analysisData.productPerception.negativeCount
        : 0;
    const categoryTotal = analysisData
        ? analysisData.categoryPerception.positiveCount +
          analysisData.categoryPerception.neutralCount +
          analysisData.categoryPerception.negativeCount
        : 0;
    const commentTotal = analysisData
        ? analysisData.overallCommentPerception.positiveCount +
          analysisData.overallCommentPerception.neutralCount +
          analysisData.overallCommentPerception.negativeCount
        : 0;

    const summaryDataCSV = [
        {
            campaignName: campaignInfo.campaignName,
            assignments: campaignInfo.assignments.join(', '),
            date: `${startDate} - ${endDate}`,
            channels: channelsData,
            creators: creatorsData,
            posts: postsData,
            totalCommentCount: analysisData?.totalCommentCount || 0,
            totalUniqueCommentCount: analysisData?.totalUniqueCommentCount || 0,

            instagramUniqueCommentCount: analysisData?.instagramUniqueCommentCount || 0,
            instagramBrandedCommentCount: analysisData?.brandedCommentCount?.['INSTAGRAM'] || 0,
            instagramCommentsCount: analysisData?.instagramCommentsCount || 0,
            instagramBrandedCommentRatio: analysisData?.brandedCommentRatio?.['INSTAGRAM'] || 0,

            tiktokUniqueCommentCount: analysisData?.tiktokUniqueCommentCount || 0,
            tiktokBrandedCommentCount: analysisData?.brandedCommentCount?.['TIKTOK'] || 0,
            tiktokCommentsCount: analysisData?.tiktokCommentsCount || 0,
            tiktokBrandedCommentRatio: analysisData?.brandedCommentRatio?.['TIKTOK'] || 0,

            youtubeUniqueCommentCount: analysisData?.youtubeUniqueCommentCount || 0,
            youtubeBrandedCommentCount: analysisData?.brandedCommentCount?.['YOUTUBE'] || 0,
            youtubeCommentsCount: analysisData?.youtubeCommentsCount || 0,
            youtubeBrandedCommentRatio: analysisData?.brandedCommentRatio?.['YOUTUBE'] || 0,

            overallTotal,
            overallPositive: analysisData?.productRelatedPerception.positiveCount || 0,
            overallNeutral: analysisData?.productRelatedPerception.neutralCount || 0,
            overallNegative: analysisData?.productRelatedPerception.negativeCount || 0,

            productTotal,
            productPositive: analysisData?.productPerception.positiveCount || 0,
            productNeutral: analysisData?.productPerception.neutralCount || 0,
            productNegative: analysisData?.productPerception.negativeCount || 0,

            categoryTotal,
            categoryPositive: analysisData?.categoryPerception.positiveCount || 0,
            categoryNeutral: analysisData?.categoryPerception.neutralCount || 0,
            categoryNegative: analysisData?.categoryPerception.negativeCount || 0,

            ...topics.reduce((prev, topic) => {
                return {
                    ...prev,
                    [`topic-${topic.replaceAll(' ', '_')}-totalMentions`]: analysisData?.topicPerception[topic].totalCount,
                    [`topic-${topic.replaceAll(' ', '_')}-positive`]: analysisData?.topicPerception[topic].positiveCount,
                    [`topic-${topic.replaceAll(' ', '_')}-neutral`]: analysisData?.topicPerception[topic].neutralCount,
                    [`topic-${topic.replaceAll(' ', '_')}-negative`]: analysisData?.topicPerception[topic].negativeCount,
                };
            }, {}),

            commentTotal,
            commentPositive: analysisData?.overallCommentPerception.positiveCount || 0,
            commentNeutral: analysisData?.overallCommentPerception.neutralCount || 0,
            commentNegative: analysisData?.overallCommentPerception.negativeCount || 0,
        },
    ];

    return summaryDataCSV;
};

export default ExportDropdown;
