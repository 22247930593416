import React from 'react'

import Lookup from '../../../../modules/Lookup'
import DropdownSelect from '../../../shared/selectBox/DropdownSelect'
import FreeStringArray from '../../../shared/freeStringArray/FreeStringArray'
import Age from '../Age'
import moment from 'moment'

import { SINGLE_SELECTION, MULTIPLE_SELECTION, DATE, AGE, FREE_LIST, FREE_TEXT,
    fieldEditType, fieldEditable, fieldLookupKey} from '../../../../modules/influencer/ProfileFields'


// props
// - title
// - profile
// - keys
// - fieldChangeFunction   takes key, value
// - setSaveEnabled takes flag

export default class EditSection extends React.Component {
    constructor( props,context) {
        super(props.context);
    }

    dropdown = (key, multi, label) => {
        let osKeys = ['gender','occupation','relationship_status','politically_active'];
        let lookupKey = fieldLookupKey(key);
        let optionList = Lookup.getTableOptions(lookupKey,osKeys.indexOf(lookupKey)>-1);
        let handleSelectionChange = (newSelection) => {
            if (multi){
                let newSelections = [];
                for (let selection of newSelection){
                    newSelections.push(selection.value)
                }
                this.props.fieldChangeFunction(key, newSelections);
            }else{
                this.props.fieldChangeFunction(key, newSelection.value);
            }
        }
        let initialSelection = this.props.profile[key];
        return (
            <div className="inputCell">
                <DropdownSelect
                    className="select"
                    clearable={false} multi={multi}
                    value={initialSelection} placeholder={'Select ' + label}
                    options={optionList}
                    onChange={handleSelectionChange}
                />
            </div>
        );
    }

    freeFieldEdit = (key) => {
        let changeField = (e) => {
            this.props.fieldChangeFunction(key, e.target.value);
        }
        return (
            <input className="free" onChange={ changeField} defaultValue={this.props.profile[key]}/>
        )
    }

    dateInput = (key) => {
        let changeField = (e) => {
            this.props.fieldChangeFunction(key, e.target.value);
            this.props.setSaveEnabled(moment(e.target.value).year()>=1918);
        }
        const exceedLimit=this.date&&moment(this.date.value).year()<1918
        return (
            <div>
                <input defaultValue={this.props.profile[key]} type="date" className="free" onChange={ changeField}
                       min={"1918-01-01"} max={"9999-12-31"} ref={(e)=>this.date=e}/>
                <div style={{color:'#e5004d', marginTop:5, fontWeight: 'bold',
                    display: (exceedLimit ? '' : 'none')}}>
                    Please enter a year after 1918
                </div>
            </div>
        )
    }

    ageInput = (key) => {
        let changeDate = (newDate) => {
            this.props.fieldChangeFunction(key, newDate);
        }

        return (
            <Age
                key={key}
                editMode={true}
                birthdate={this.props.profile[key]}
                changeFunction={changeDate}
                setSaveEnabled={this.props.setSaveEnabled}
            />
        )
    }


    freeListEdit = (key) => {
        let changeFunction = (newValue) => {
            this.props.fieldChangeFunction(key, newValue);
        }
        return (
            <FreeStringArray
                strings={this.props.profile[key] || []}
                changeFunction={changeFunction}
                placeholder="Type keyword and press Enter"
            />
        )
    }

    inputField = (key, label) => {
        if (fieldEditType(key) == SINGLE_SELECTION) {
            return this.dropdown(key, false, label)
        }
        if (fieldEditType(key) === DATE) {
            return this.dateInput(key);
        }
        if (fieldEditType(key) === AGE) {
            return this.ageInput(key);
        }
        if (fieldEditType(key) === MULTIPLE_SELECTION) {
            return this.dropdown(key, true, label);
        }
        if (fieldEditType(key) === FREE_LIST ){
            return this.freeListEdit(key);
        }
        if (fieldEditType(key) === FREE_TEXT) {
            return this.freeFieldEdit(key);
        }
        return key + ' something went wrong'
    }


    editField = (key) => {
        let label = Lookup.getFilterLabel(key.replace(/[A-Z]/g, u=>'_'+u.toLowerCase()));
        
        return (
            <div key={key} className="field edit">
                <div className="editLabel">
                    {label} :
                </div>
                <div>
                    {this.inputField(key, label)}
                </div>
            </div>
        )
    }

    render() {
        let keys = this.props.keys;
        let col1 = [];
        for (let key of keys) {
            if (fieldEditable(key)) {
                let d = this.editField(key);
                col1.push(d)
            }
        }
        return (
            <div>
                <div className="pieceTitle2">{this.props.title}</div>
                <div className="displayColumns">
                    <div>
                        { col1}
                    </div>
                </div>

            </div>
        )
    }
}
