import React from "react";
import { Route, Switch } from "react-router-dom";
import LoginPage from "./components/loginSignup/LoginPage";
import OnBoard from "./components/onBoard/OnBoard";
import AgentAdmin from "./components/agentAdmin/AgentAdmin"
import Influencers from "./components/influencers/Influencers"
import Influencers2 from "./components/influencers2/Influencers"
import NotFound from "./containers/NotFound";
import Campaign from "./components/campaign/Campaign"
import InfluencerProfile from "./components/influencerProfile/InfluencerProfile"
import InfluencerSettings from "./components/influencerProfile/influencerSettings/InfluencerSettings";
import InfluencerDetailPage from "./components/influencers/detailPage/InfluencerDetailPage"
import Home from "./components/home/HomePageNew";
import RedirectPage from "./components/RedirectPage";
import InfluencerOpportunity from "./components/influencerOpportunity/InfluencerOpportunity";
import InfluencerAssignments from "./components/influencerAssignments/InfluencerAssignments";
import influencerAnalytics from "./components/influencerOpportunity/influencerAnalytics";
import CampaignWorkPage from "./components/campaign/CampaignWorkPage"
import AuthCheckWrapper from "./components/AuthCheckWrapper"
import draftComment from "./components/campaign/draftComment/draftComment";
import DraftCreate from "./components/campaign/draftCreate/DraftCreate";
import PermissionPage from "./components/influencerProfile/influencerSettings/PermissionPage";
import PermissionCard from "./components/influencerProfile/influencerSettings/PermissionCard";
import AdminManagementPage from "./components/superAdmin/userManagement";
import PublicRecord from "./components/superAdmin/publicRecord";
import TalentManagementPage from "./components/talentManager/Management";
import AdminBrandsPage from "./components/superAdmin/Brands";
import Advertisers from './components/superAdmin/Advertisers';
import Plans from './components/plans';
import SupportPage from './components/SupportPage/SupportPage';
import KeywordGroupsPage from './components/keywordGroups/KeywordGroupsPage';
import { SUPER_ADMIN, BRAND_OWNER, AGENT, VIEWER } from './constants/authorities';
import VerifyPage from './components/loginSignup/VerifyPage';
import FavouriteList from './components/favourite/FavouriteList';
import FavoriteDetails from './components/favourite/FavoriteDetails';
import ExclusionList from './components/favourite/ExclusionList';
import ExclusionDetails from './components/favourite/ExclusionDetails';
import homeDashboard from './components/homeDashboard/homeDashboard';
import PlansList from './components/plans/PlansList';
import PlanDetails from './components/plans/PlanDetails';
import SavedSearches from "./components/influencers2/savedSearches/savedSearches";
import Playbook from './components/Playbook/Playbook';
import Video from './components/Video/Video';
import FAQ from './components/FAQ/FAQ';
import ReleaseNotes from './components/ReleaseNotes/ReleaseNotes';

export default () => (

    <Switch>
        {/* temp for testing convenience
        <Route path="/" exact component={LoginPage} />
       <Route path="/" exact component={OnBoard} />
       <Route path="/" exact component={TempNav} />

       */}
        <Route path="/" exact component={Home} />
        <Route path="/account_verify/:code" exact component={VerifyPage}/>
        {/* <Route path="/login" exact component={LoginPage} /> */}
        <Route path="/login" exact render={(props) => <AuthCheckWrapper authorizedType={['ROLE_INFLUENCER', 'ROLE_AGENT', 'ROLE_SUPER_ADMIN', 'ROLE_VIEWER', 'ROLE_TALENT_MANAGER', 'ROLE_BRAND_OWNER']} {...props} privateComponent={LoginPage} />} />
        {/* // ROLE_AGENT,  ROLE_BRAND_OWNER, ROLE_INFLUENCER, ROLE_SUPER_ADMIN, ROLE_TALENT_MANAGER, ROLE_VIEWER */}
        <Route path="/support" exact render={(props) => <AuthCheckWrapper authorizedType={['ROLE_INFLUENCER', 'ROLE_AGENT', 'ROLE_SUPER_ADMIN', 'ROLE_VIEWER', 'ROLE_TALENT_MANAGER', 'ROLE_BRAND_OWNER']} {...props} privateComponent={SupportPage} />} />
        <Route path="/playbook" exact render={(props) => <AuthCheckWrapper authorizedType={['ROLE_INFLUENCER', 'ROLE_AGENT', 'ROLE_SUPER_ADMIN', 'ROLE_VIEWER', 'ROLE_TALENT_MANAGER', 'ROLE_BRAND_OWNER']} {...props} privateComponent={Playbook} />} />
        <Route path="/video" exact render={(props) => <AuthCheckWrapper authorizedType={['ROLE_INFLUENCER', 'ROLE_AGENT', 'ROLE_SUPER_ADMIN', 'ROLE_VIEWER', 'ROLE_TALENT_MANAGER', 'ROLE_BRAND_OWNER']} {...props} privateComponent={Video} />} />
        <Route path="/faq" exact render={(props) => <AuthCheckWrapper authorizedType={['ROLE_INFLUENCER', 'ROLE_AGENT', 'ROLE_SUPER_ADMIN', 'ROLE_VIEWER', 'ROLE_TALENT_MANAGER', 'ROLE_BRAND_OWNER']} {...props} privateComponent={FAQ} />} />
        <Route path="/releasenotes" exact render={(props) => <AuthCheckWrapper authorizedType={['ROLE_INFLUENCER', 'ROLE_AGENT', 'ROLE_SUPER_ADMIN', 'ROLE_VIEWER', 'ROLE_TALENT_MANAGER', 'ROLE_BRAND_OWNER']} {...props} privateComponent={ReleaseNotes} />} />
        {/* --influencer */}
        <Route path="/profile" exact render={(props) => <AuthCheckWrapper authorizedType={['ROLE_INFLUENCER']} {...props} privateComponent={InfluencerProfile} />} />
        <Route path="/profile/settings" render={(props) => <AuthCheckWrapper authorizedType={['ROLE_INFLUENCER']} {...props} privateComponent={InfluencerSettings} />} />
        <Route path="/profile/permissions/:managerId" render={(props) => <AuthCheckWrapper authorizedType={['ROLE_INFLUENCER']} {...props} privateComponent={PermissionPage} />} />
        <Route path="/profile/permissions" render={(props) => <AuthCheckWrapper authorizedType={['ROLE_INFLUENCER']} {...props} privateComponent={PermissionCard} />} />
        <Route path="/onboard" exact render={(props) => <AuthCheckWrapper authorizedType={['ROLE_INFLUENCER']} {...props} privateComponent={OnBoard} />} />
        <Route path="/opportunities" render={(props) => <AuthCheckWrapper authorizedType={['ROLE_INFLUENCER']} {...props} privateComponent={InfluencerOpportunity} />} />
        {/* <Route path="/payments" render={(props) => <AuthCheckWrapper authorizedType={['ROLE_INFLUENCER']} {...props} privateComponent={InfluencerPayments} />} /> */}
        <Route path="/postAnalytics" render={(props) => <AuthCheckWrapper authorizedType={['ROLE_INFLUENCER']} {...props} privateComponent={influencerAnalytics} />} />
        <Route path="/assignments" render={(props) => <AuthCheckWrapper authorizedType={['ROLE_INFLUENCER', "ROLE_TALENT_MANAGER"]} {...props} privateComponent={InfluencerAssignments} />} />
        <Route path="/redirect" exact component={RedirectPage} />

        {/* talent manager */}
        <Route path="/talentManagement" exact render={(props) => <AuthCheckWrapper authorizedType={["ROLE_TALENT_MANAGER"]} {...props} privateComponent={TalentManagementPage} />} />
        <Route path="/talentOpportunities" render={(props) => <AuthCheckWrapper authorizedType="ROLE_TALENT_MANAGER" {...props} privateComponent={InfluencerOpportunity} />} />

        {/* keyword groups */}
        <Route path="/keywordGroups/:brandId?/:keywordGroupId?" exact render={(props) => <AuthCheckWrapper authorizedType={['ROLE_AGENT', 'ROLE_VIEWER', 'ROLE_SUPER_ADMIN', 'ROLE_BRAND_OWNER']} {...props} privateComponent={KeywordGroupsPage} />} />

        {/* --agent */}
        <Route path="/home" exact render={(props) => <AuthCheckWrapper authorizedType={['ROLE_AGENT', 'ROLE_VIEWER', 'ROLE_SUPER_ADMIN', 'ROLE_BRAND_OWNER']} {...props} privateComponent={homeDashboard} />} />
        <Route path="/influencersOld" exact render={(props) => <AuthCheckWrapper authorizedType={['ROLE_AGENT', 'ROLE_VIEWER', 'ROLE_SUPER_ADMIN', 'ROLE_BRAND_OWNER']} {...props} privateComponent={Influencers} />} />
        <Route path="/influencers" exact render={(props) => <AuthCheckWrapper authorizedType={['ROLE_AGENT', 'ROLE_VIEWER', 'ROLE_SUPER_ADMIN', 'ROLE_BRAND_OWNER']} {...props} privateComponent={Influencers2} />} />
        {window.plans && <Route path="/influencers/:type/:id" exact render={(props) => <AuthCheckWrapper authorizedType={['ROLE_AGENT', 'ROLE_VIEWER', 'ROLE_SUPER_ADMIN', 'ROLE_BRAND_OWNER']} {...props} privateComponent={Influencers2} />} />}
        {window.plans && <Route path="/influencers/:type/:id/:subId" exact render={(props) => <AuthCheckWrapper authorizedType={['ROLE_AGENT', 'ROLE_VIEWER', 'ROLE_SUPER_ADMIN', 'ROLE_BRAND_OWNER']} {...props} privateComponent={Influencers2} />} />}
        <Route path="/plans" exact render={(props) => <AuthCheckWrapper authorizedType={['ROLE_AGENT', 'ROLE_VIEWER', 'ROLE_SUPER_ADMIN', 'ROLE_BRAND_OWNER']} {...props} privateComponent={PlansList} />} />
        <Route path="/adminManagement" exact render={(props) => <AuthCheckWrapper authorizedType={['ROLE_AGENT', 'ROLE_VIEWER', 'ROLE_SUPER_ADMIN', 'ROLE_BRAND_OWNER']} {...props} privateComponent={AdminManagementPage} />} />
        <Route path="/publicRecord" exact render={(props) => <AuthCheckWrapper authorizedType={['ROLE_SUPER_ADMIN']} {...props} privateComponent={PublicRecord} />} />
        <Route path="/adminBrands/:advertiserId?"  render={(props) => <AuthCheckWrapper authorizedType={['ROLE_AGENT', 'ROLE_VIEWER', 'ROLE_SUPER_ADMIN', 'ROLE_BRAND_OWNER']} {...props} privateComponent={AdminBrandsPage} />} />
        <Route path="/influencerDetailPage/:influencerId" render={(props) => <AuthCheckWrapper authorizedType={['ROLE_AGENT', 'ROLE_VIEWER', 'ROLE_SUPER_ADMIN', "ROLE_TALENT_MANAGER", 'ROLE_BRAND_OWNER']} {...props} privateComponent={InfluencerDetailPage} />} />
        <Route path="/publicInfluencerDetailsPage/:influencerUid/:networkType" render={(props) => <AuthCheckWrapper authorizedType={['ROLE_AGENT', 'ROLE_VIEWER', 'ROLE_SUPER_ADMIN', 'ROLE_TALENT_MANAGER', 'ROLE_BRAND_OWNER']} {...props} privateComponent={InfluencerDetailPage}/>}/>
        <Route path="/campaign" render={(props) => <AuthCheckWrapper authorizedType={['ROLE_AGENT', 'ROLE_VIEWER', 'ROLE_SUPER_ADMIN', 'ROLE_BRAND_OWNER']} {...props} privateComponent={Campaign} />} />
        <Route path="/favourite" exact render={(props) => <AuthCheckWrapper authorizedType={['ROLE_AGENT', 'ROLE_VIEWER', 'ROLE_SUPER_ADMIN', 'ROLE_BRAND_OWNER']} {...props} privateComponent={FavouriteList} />} />
        <Route path="/favourite/:favouriteId" exact render={(props) => <AuthCheckWrapper authorizedType={['ROLE_AGENT', 'ROLE_VIEWER', 'ROLE_SUPER_ADMIN', 'ROLE_BRAND_OWNER']} {...props} privateComponent={FavoriteDetails} />} />
        <Route path="/exclusion" exact render={(props) => <AuthCheckWrapper authorizedType={['ROLE_AGENT', 'ROLE_VIEWER', 'ROLE_SUPER_ADMIN', 'ROLE_BRAND_OWNER']} {...props} privateComponent={ExclusionList} />} />
        <Route path="/exclusion/:exclusionId" exact render={(props) => <AuthCheckWrapper authorizedType={['ROLE_AGENT', 'ROLE_VIEWER', 'ROLE_SUPER_ADMIN', 'ROLE_BRAND_OWNER']} {...props} privateComponent={ExclusionDetails} />} />
        {/* <Route path="/plans" exact render={(props) => <AuthCheckWrapper authorizedType={['ROLE_AGENT', 'ROLE_VIEWER', 'ROLE_SUPER_ADMIN', 'ROLE_BRAND_OWNER']} {...props} privateComponent={ExclusionList} />} /> */}
        {window.plans && <Route path="/plans/:planId" exact render={(props) => <AuthCheckWrapper authorizedType={['ROLE_AGENT', 'ROLE_VIEWER', 'ROLE_SUPER_ADMIN', 'ROLE_BRAND_OWNER']} {...props} privateComponent={PlanDetails} />} />}
        <Route path="/campaignWork/:campaignId/" render={(props) => <AuthCheckWrapper authorizedType={['ROLE_AGENT', 'ROLE_VIEWER', 'ROLE_SUPER_ADMIN', 'ROLE_BRAND_OWNER']} {...props} privateComponent={CampaignWorkPage} />} />
        <Route path="/assignment/:assignmentId/post/:postId" exact render={(props) => <AuthCheckWrapper authorizedType={['ROLE_AGENT', 'ROLE_VIEWER', 'ROLE_SUPER_ADMIN', 'ROLE_BRAND_OWNER']} {...props} privateComponent={draftComment} />}/>
        <Route path="/assignment/:assignmentId/draftCreate/:influencerUid/:postType" exact render={(props) => <AuthCheckWrapper authorizedType={[AGENT, BRAND_OWNER, VIEWER, SUPER_ADMIN]} {...props} privateComponent={DraftCreate} />}/>
        <Route path="/savedsearches" exact render={(props) => <AuthCheckWrapper authorizedType={['ROLE_AGENT', 'ROLE_VIEWER', 'ROLE_SUPER_ADMIN', 'ROLE_BRAND_OWNER']} {...props} privateComponent={SavedSearches} />} />
        <Route path="/agentAdmin" render={(props) =>
            <AuthCheckWrapper
                authorizedType={['ROLE_AGENT', 'ROLE_SUPER_ADMIN', 'ROLE_VIEWER', 'ROLE_TALENT_MANAGER', 'ROLE_BRAND_OWNER']}
                {...props}
                privateComponent={AgentAdmin}
            />}
        />
        <Route
            path='/advertisers'
            render={
                (props) => <AuthCheckWrapper
                    authorizedType={[SUPER_ADMIN]}
                    {...props}
                    privateComponent={Advertisers}
                />
            }
        />
        <Route path='/logout' render={(props) => <AuthCheckWrapper {...props} />}/>

        { /* Finally, catch all unmatched routes */ }
        <Route component={NotFound} />
    </Switch>
);
