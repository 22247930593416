import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getData, resetData } from '../../store/campaign/influencerSearch/actionCreators';
import { resetData as resetGeneralData } from '../../store/general/actionCreators';
// import below have cycle dependencies (potential memory leak)
import InfluencersBody from './InfluencersBody';
import TopNavigation from '../shared/topNavigation/TopNavigation';
import Api from '../../modules/Api';
import { updateSavedSearches } from '../../modules/utils/CommonUtils';
import Lookup from '../../modules/Lookup2022';

class Influencers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editSearch: null,
            type: undefined,
            typeId: '',
            plansData: null,
            subId: '',
        }
    }

    componentDidMount() {
        const {
            actions: { getData },
            history,
        } = this.props;

        getData();
        const {location} = history;
        if (location) {
            const {state, pathname} = location;
            if (state) {
                const {search} = state;
                this.setState({editSearch: search});
                if (search) {
                    // avoid refresh the page still getting this state.search
                    delete state.search;
                    history.replace({...history.location, state});
                }
                
                const {plansData} = state;
                if (plansData) {
                    delete state.plansData;
                    history.replace({...history.location, state});
                    this.setState({ plansData });
                }
            }
            if (pathname) {
                const p = pathname.split('/');
                if (p && p.length > 4) {
                    this.setState({ type: p[2], typeId: p[3], subId: p[4] });
                } else if (p && p.length > 3) {
                    this.setState({ type: p[2], typeId: p[3] });
                }
            }
        }
    }

    componentDidUpdate(prevProps) {
        const {editSearch, subId} = this.state;
        const {filter} = this.props;
        if (!_.isEqual(prevProps.filter.lookupFilters, filter.lookupFilters) && 
            this.props.filter.lookupFilters &&
            !editSearch &&
            subId) {

            // refresh page which doesn't have state.search anymore, need to load the data again
            const connectedFilters = Lookup.getAllFilters('CONNECTED');
            const publicFilters = Lookup.getAllFilters('PUBLIC');
            if (connectedFilters && !_.isEmpty(connectedFilters) && publicFilters && !_.isEmpty(publicFilters)) {
                Api.getPlanSavedSearchById(subId)
                    .then(async (res) => {
                        if (res) {
                            const searchableOptions = [];
                            const temp = [];
                            temp.push(res);
                            const updatedObj = updateSavedSearches(publicFilters, connectedFilters, temp);
                            if (updatedObj && Array.isArray(updatedObj.searches) && updatedObj.searches.length > 0) {
                                // updatedObj.searches.forEach(s => {
                                //     if (s?.param && s?.param?.selectedFilters) {
                                //         s?.param?.selectedFilters.forEach(async (ps) => {
                                //             if (ps?.searchable && searchableOptions.find(so => so.id === ps.id) === undefined) {
                                //                 try {
                                //                     const res = await Api.searchFilterOptions({filterId: ps.id, keyword: ''});
                                //                     searchableOptions.push({id: ps.id, options: res});
                                //                 }catch (err) {

                                //                 }
                                //             }
                                //         })
                                //     }
                                // })
                                for (const s of updatedObj.searches) {
                                  if (s?.param && s?.param?.selectedFilters) {
                                    for (const ps of s?.param?.selectedFilters) {
                                      if (ps?.searchable && searchableOptions.find(so => so.id === ps.id) === undefined) {
                                        try {
                                            const res = await Api.searchFilterOptions({filterId: ps.id, keyword: ''});
                                            searchableOptions.push({id: ps.id, options: res});
                                        }catch (err) {

                                        }
                                      }
                                    }
                                  }
                                }
                                const newSearch = updatedObj.searches[0].param;
                                newSearch.searchableOptions = searchableOptions;
                                newSearch.planName = updatedObj.searches[0].name;
                                this.setState({editSearch: newSearch || null});
                            }
                        }
                    })
            }
        }
    }

    componentWillUnmount() {
        const {
            actions: { resetData, resetGeneralData },
        } = this.props;
        resetData();
        setTimeout(() => {
            resetGeneralData();
        }, 50);
    }

    render() {
        const {editSearch, type, typeId, plansData, subId} = this.state;
        let planId;
        if (type && type.toLowerCase() === 'plan' && typeId) {
            planId = typeId;
        }
        return (
            <div className="influencers2022">
                <TopNavigation
                    current={!planId ? "influencers" : "plans"}
                    clickHandlerForCurrent={this.triggerRerender}
                />
                <InfluencersBody disableMultipleInvite editSearch={editSearch} planId={planId} plansData={plansData} subId={subId}/>
            </div>
        );
    }
}

Influencers.propTypes = {
    actions: PropTypes.shape({
        getData: PropTypes.func,
        resetData: PropTypes.func,
        resetGeneralData: PropTypes.func,
    }),
};

const mapStateToProps = (state) => state;
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({
        getData,
        resetData,
        resetGeneralData,
    }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Influencers);
