import React, { useState } from 'react';
import './NumberInput.scss';

const NumberInput = ({ maxValue, minValue, onBlur, placeholder, isWeighted, initialValue, ...props }) => {
    const [value, setValue] = useState(initialValue || '');
    const [errors, setErrors] = useState([]);

    const onChange = (event) => {
        let newValue = event.target.value;
        if (placeholder === "%") {
            newValue = newValue.replace(/\D/g,'');
        }

        setValue(newValue);
        const newErrors = [];

        if (maxValue !== undefined && Number(newValue) > maxValue) {
            newErrors.push(`Max value is ${maxValue}.`);
        }

        if (minValue !== undefined && Number(newValue) < minValue) {
            newErrors.push(`Min value is ${minValue}.`);
        }

        setErrors(newErrors);
    };

    const handleOnFocus = (event) => {
        if (!isWeighted) {
            return;
        }

        event.target.setSelectionRange(0, event.target.value.length - 1);
    };

    const handleOnBlur = () => {
        if (!errors.length && onBlur) {
            onBlur(Number(value));
        }
    };

    const handleOnKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleOnBlur();
        }
    };

    return (
        <div className="number-input">
            {placeholder !== "%" ? 
                <input
                    {...props}
                    value={value}
                    onChange={onChange}
                    onBlur={handleOnBlur}
                    min={minValue}
                    max={maxValue}
                    onKeyDown={handleOnKeyDown}
                    maxLength={isWeighted ? 4 : undefined}
                    onFocus={handleOnFocus}
                />
                :
                <input
                    {...props}
                    value={`${value}%`}
                    onChange={onChange}
                    onBlur={handleOnBlur}
                    placeholder="%"
                    onKeyDown={handleOnKeyDown}
                    maxLength={isWeighted ? 5 : undefined}
                    onFocus={handleOnFocus}
                />
            }
            <div className="errorContainer">
                {errors.map((errorText) => (
                    <div key={errorText}>{errorText}</div>
                ))}
            </div>
        </div>
    );
};

export default NumberInput;
