import { all, fork } from 'redux-saga/effects';
import campaignCalendarSaga from './calendar/sagas/campaignCalendar';
import campaignInfluencerSearch from './influencerSearch/saga';

export default function* campaignSaga() {
    yield all([
        fork(campaignCalendarSaga),
        fork(campaignInfluencerSearch),
    ]);
}
