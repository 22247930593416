import React from 'react'
import { withRouter } from 'react-router'
import {getAssignmentList, setAssignmentSelection} from '../../../store/campaign/actions/campaignActions';
import {connect} from 'react-redux';

import './selectSingleAssignment.scss'

// props
// - campaignId
// - noselect  (for days when this is not a dropdown)
// No need for change function: changing the selection changes the Redux store
// and the change can be picked up through props

class SelectSingleAssignment extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            showList : false
        }
    }

    boxClicked = (e) => {
        if (this.props.noselect) {
            return;
        }
        e.stopPropagation();
        this.setState({
            showList : true
        })
    }

    closeList = () => {
        this.setState({
            showList : false
        })
    }

    renderedChoice = (assignment) => {
        let clickHandler =  () => {
            if (assignment.id == this.state.selectedAssignmentId) {
                return;
            }
            let url = `/campaignWork/${assignment.campaign.id}/assignment/${assignment.id}`;
            this.props.history.push(url);
        }

        return (<div
            className="listElement"
            onClick={clickHandler}
            key={assignment.id}
        >
            {assignment.name}
        </div>)
    }

    static getDerivedStateFromProps(props, state) {
        if (!props.campaignId) {
            return {};
        }
        let selectedAssignment = null;
        props.getAssignmentList(props.campaignId);
        let urlPieces = window.location.pathname.split('/');
        let navigatedAssignmentId = urlPieces[urlPieces.length-1];
        let assignments = props.campaignState['assignments' + props.campaignId];
        let assignmentList = null;
        if (assignments) {
            assignmentList = assignments.assignments;
            if (Array.isArray(assignmentList)){
                for (let a of assignmentList) {
                    if ('' + a.id === navigatedAssignmentId) {
                        selectedAssignment = a;
                    }
                }
            }
        }
        return {
            selectedAssignment : selectedAssignment,
            assignmentList : assignmentList
        }
    }

    componentDidMount() {
        window.addEventListener("click", this.closeList);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.closeList)
    }


    render() {
        let list = [];
        for (let assignment of this.state.assignmentList) {
            list.push(this.renderedChoice(assignment))
        }

        return (
            <div className="selectSingleAssignment">
                <div className="box" onClick={this.boxClicked}>
                    <span className="label">
                        Assignment:&nbsp;
                    </span>
                    { this.state.selectedAssignment &&
                        <span>
                            { this.state.selectedAssignment.name}
                        </span>
                    }
                    { !this.props.noselect &&
                        <div className="downicon">
                            <img src="/images/ic-down.svg"/>
                        </div>
                    }
                </div>
                { this.state.showList &&
                    <div className="list">
                        {list}
                    </div>
                }
            </div>
        )
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAssignmentList: (campaignId) => {
            dispatch(getAssignmentList(campaignId))
        },
        setAssignmentSelection : (campaignId, selection) => {
            dispatch(setAssignmentSelection(campaignId, selection))
        }
    }
};


const mapStateToProps= (state) => {
    return{
        campaignState : state.campaign   // the assignments stuff for each campaign are stored with keys containing the campaign id
    }
};

export default  withRouter(connect(mapStateToProps, mapDispatchToProps)(SelectSingleAssignment))
