import React from 'react';
import _ from 'lodash';
import Lookup from '../../../modules/Lookup';
import './displayTopics.scss';

// props : - topics (array of ids)
//         - short (flag for just the top level list)

export default class DisplayTopics extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.renderedList = this.renderedList.bind(this);

        this.state = {
            lists: null,
        };

        this.map = null;
    }

    processProps(props) {
        const { topics, max } = props;
        this.map = Lookup.getTopicMap();
        const lists = [];
        let index = 0;
        for (const id of topics) {
            const topic = this.map[id];
            if (!topic) {
                // what?
                continue;
            }
            if (!topic.parentId) {
                if (!max || index < max) {
                    lists.push({
                        id: topic.id,
                        value: topic.value,
                        label: topic.label,
                        subs: [],
                    });
                }
                index++;
            }
        }
        for (const id of topics) {
            const topic = this.map[id];
            if (!topic) {
                // what?
                continue;
            }
            if (topic.parentId) {
                for (const main of lists) {
                    if (topic.parentId === main.id) {
                        main.subs.push(topic);
                    }
                }
            }
        }
        this.setState({
            lists,
        });
    }

    renderedList(main) {
        const subs = [];
        for (const sub of main.subs) {
            subs.push(
                <div key={sub.id} className="sub">
                    <div>{sub.label}</div>
                </div>,
            );
        }
        return (
            <div key={main.id}>
                <div className="main">{main.label}</div>
                {subs}
            </div>
        );
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.max !== this.props.max || !_.isEqual(prevProps.topics, this.props.topics)) && (this.props.topics && this.props.max)) {
            this.processProps(this.props);
        }
    }

    componentDidMount() {
        if (this.props.topics && this.props.max) {
            this.processProps(this.props);
        }
    }

    render() {
        const { lists } = this.state;
        const { short } = this.props;
        if (!Array.isArray(lists)) {
            return null;
        }
        if (!short) {
            const topicLists = [];
            for (const main of this.state.lists) {
                topicLists.push(this.renderedList(main));
            }
            return <div className="displayTopics full">{topicLists}</div>;
        } 
        return (
            <span>
                {lists.map(({ id, label }) => (
                    <span key={id}>{label} </span>
                ))}
            </span>
        );
        
    }
}
