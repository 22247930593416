import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import ReachIcon from '../../shared/ReachIcon/ReachIcon';
import Format from '../../../modules/utils/Format';
import './ReachAndRate.scss';
// import below has a cycle dependency (potential memory leak)
import RateIcon from '../../shared/rateIcon/RateIcon';
import RatesService from '../../../services/ratesService';
import { PER_POST } from '../../../constants/rateTypes';


class ReachAndRateComponent extends Component {
    reachFormat = (val) => {
        const mega = 1000000;
        const kilo = 1000;
        let value = val;

        if (value / mega >= 1) {
            value = `${value / mega}M`;
        } else if (value / kilo >= 1) {
            value = `${value / kilo}K`;
        }

        return value || 0;
    }

    getTotalReach = () => {
        const {
            profile: { userNetworks = [] },
        } = this.props;
        const totalReach = userNetworks.reduce((acc, { reach }) => acc += reach, 0);
        const formattedTotalReach = this.reachFormat(totalReach);

        return formattedTotalReach;
    }

    getRatesListAndActiveRatesOption = () => {
        const { profile, profileFromStore } = this.props;
        const profileToUse = Object.prototype.hasOwnProperty.call(profileFromStore, 'rates')
            ? profileFromStore : profile;
        const ratesService = new RatesService(profileToUse);
        const activeRatesOption = ratesService.getInfluencerActiveRatesOption();
        const ratesList = ratesService.filterRatesListByActiveTab();

        return {
            ratesList,
            activeRatesOption,
        };
    };

    getRatesAndUserNetworks = () => {
        const { profile, profileFromStore } = this.props;
        const rates = get(profileFromStore, 'rates', profile.rates);
        const userNetworks = get(profileFromStore, 'userNetworks', profile.userNetworks);
        userNetworks.sort((a, b) => a.networkType > b.networkType ? 1 : -1);

        return {
            rates,
            userNetworks,
        };
    };

    render() {
        /*
            Well, if you take a look on those two methods - they could be to you kinda strange.
            The problem is - the main idea about the work with data. When we change data with UI - it is sent
            to backend. But the UI update is based not on the new data came from server, but some local states.
            And it is really confusing - you need to update both local state and data stored on backend.

            * profile comes to props after it's fetched at AuthCheckWrapper. And after that - it is never updated.
            * profileFromStore - is the live data. But, for some reason, it lacks required data. It happens because
            there is stored the shortProfile version (which will be updated once user changes his rates or active tab)

            So, we initialize this component based on profile. And update it based on profileFromStore.
        */
        const { rates, userNetworks } = this.getRatesAndUserNetworks();
        const { ratesList, activeRatesOption } = this.getRatesListAndActiveRatesOption();

        return (
            <div className="reach-and-rate">
                <div className="row">
                    <div className="reach-and-rate-item">
                        <div className="reach-and-rate-item_label">Reach</div>
                        <div className="reach-and-rate-item_holder">
                            {userNetworks.length && userNetworks.map((network, index) => (
                                <div className="network-item" key={`${index}_network_item`}>
                                    <div className="network-item_icon">
                                        <ReachIcon network={network.networkType} value={1} size={14} padding={7} />
                                    </div>
                                    <div className="network-item_reach">
                                        {this.reachFormat(network.reach)}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="reach-and-rate-item">
                        <div className="reach-and-rate-item_label">Total Followers</div>
                        <div className="reach-and-rate-item_holder">
                            <div className="reach-and-rate-item_text">
                                {this.getTotalReach()}
                            </div>
                        </div>
                    </div>
                    {/* <div className="reach-and-rate-item">
                        <div className="reach-and-rate-item_label">Engagement Rate</div>
                        <div className="reach-and-rate-item_holder">
                            <div className="reach-and-rate-item_text">
                                6% (hardcoded)
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className="row">
                    <div className="reach-and-rate-item">
                        <div className="reach-and-rate-item_label">Rates</div>
                        <div className="reach-and-rate-item_holder">
                            {ratesList.length && ratesList.map((key, index) => (
                                <div className="network-item" key={`${index}_network_item`}>
                                    <div className="network-item_icon">
                                        <RateIcon
                                            rateKey={key}
                                            size={30}
                                            bgColor="#2b8dff"
                                            value={rates[key]}
                                        />
                                    </div>
                                    <div className="network-item_reach">
                                        {Format.expressInDollars(Number(rates[key]))}
                                        {activeRatesOption === PER_POST && (
                                            <span>avg per post/campaign</span>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ global }) => ({
    profileFromStore: global.loggedInUserProfile,
});

export default connect(mapStateToProps, {})(ReachAndRateComponent);
