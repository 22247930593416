import React, { useState, useEffect, useLayoutEffect } from 'react';
import './likesTab.scss';
import cx from 'classnames';
import NoDataCard from './NoDataCard';
import NoteAvatar from './NoteAvatar';

const LikesTab = ({
    like,
    data,
    create,
    viewAll,
}) => {
    return (
        <div className='likeTabContainer'>
            {data.length > 0 ? <div className='planNotesListContainer'>
                {data.map((note, i) => {
                    return (((create && i < 5) || (!create) || !viewAll) && <div className='noteTabItemContainer'>
                        <NoteAvatar
                            picUrl={note.picUrl}
                            firstName={note.agentFirstname}
                            lastName={note.agentLastname}
                            icon={like ? <img src='/images/ic-thumb-up.svg' /> : <img src='/images/ic-thumb-down.svg' />}
                        />
                        <div className='likeAgentName'>{`${note.agentFirstname ? note.agentFirstname + ' ' : ''}${note.agentLastname ? note.agentLastname + ' ' : ''}`}</div>
                    </div>)
                })}
                {create && data.length > 5 && viewAll && <div onClick={viewAll} className='viewAll'>View all</div>}
            </div> : <NoDataCard
                message={`There is no ${like ? 'like' : 'dislike'} for this creator`}
                icon={like ? '/images/ic-no-likes.svg' : '/images/ic-no-dislikes.svg'}
            />}
        </div>
    )
}

export default LikesTab;