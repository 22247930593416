import {
    STATUS_SELECT_CHANGE,
    STATUS_SELECT_RESET,
    STATUS_SELECT_SET_DATA,
} from './actions';

export const statusSelectChange = (selectValue, updateSaga) => ({
    type: STATUS_SELECT_CHANGE,
    selectValue,
    updateSaga,
});

export const resetStatusSelect = () => ({
    type: STATUS_SELECT_RESET,
});

export const setStatusSelectData = (payload) => ({
    type: STATUS_SELECT_SET_DATA,
    payload,
});
