import React from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import Invite from '../../../invite/Invite';
import FeedbackOnTheTop from '../../../../shared/feedbackOnTheTop/FeedbackOnTheTop';
import ApiError from '../../../../shared/apiError/ApiError';
import './secondaryNavigation.scss';
import ProfileHelper from '../../../../../modules/utils/ProfileHelper';
import cx from 'classnames';
import { setData } from '../../../../../store/campaign/influencerSearch/actionCreators';
import {TALENT_MANAGER_STR, INFLUENCER_STR} from '../../../../../constants/authorities';

class SecondaryNavigation extends React.Component {
    constructor(props, context) {
        super(props.context);

        this.handleModeChange = this.handleModeChange.bind(this);
        this.showApiError = this.showApiError.bind(this);
        this.finishInvite = this.finishInvite.bind(this);

        this.state = {
            showApiError: false,
            inviteInProgress: false,
            showInviteSuccess: false,
            profile: null,
            modalOpen: false
        };

        this.positions = {};
    }

    finishInvite(successObject) {
        this.setState({
            inviteInProgress: false,
            showInviteSuccess: Boolean(successObject),
        });
    }

    showApiError(error) {
        this.setState({
            showApiError: true,
            apiError: error,
        });
    }

    handleModeChange(editMode) {
        const f = this.props.handleModeChange;
        if (typeof f === 'function') {
            f(editMode);
        }
    }

    componentDidMount() {
        this.isUnMounted = false;
        ProfileHelper.getCurrentLoginUserProfile().then(profile => this.setState({profile}))
    }

    componentWillUnmount() {
        this.isUnMounted = true;
    }

    addToCampaignHandler = () => {
        const { inviteProfile, setData } = this.props;

        this.setState(
            {
                inviteInProgress: true,
            },
            () =>
                setData({
                    influencers: [inviteProfile],
                }),
        );
    };

    openModal = () => {
        // this.setState({modalOpen: true})
        const { inviteProfile } = this.props
        window.open(inviteProfile.reportUrl, "Vetting Report", "height=1000,width=1000")
    }

    // closeModal = () => {
    //     this.setState({modalOpen: false})
    // }

    render() {
        const { inviteProfile, disableAddToCampaign, permission, disableContact } = this.props;
        const {profile, modalOpen} = this.state;
        const addExtraTop = profile && (profile.role === TALENT_MANAGER_STR || profile.role === INFLUENCER_STR) && !profile.accountVerified;
        const inviteList = [];
        if (inviteProfile) {
            inviteList.push({
                id: inviteProfile.id,
                uid: inviteProfile.uid,
                pictureUrl: inviteProfile.pictureUrl ? inviteProfile.pictureUrl : '/images/ic-avatar-blank-d.svg',
                name: `${inviteProfile.firstname  } ${  inviteProfile.lastname}`,
                rates: inviteProfile.rates,
                userNetworks: inviteProfile.userNetworks,
                firstname: inviteProfile?.firstname,
                lastname: inviteProfile?.lastname,
            });
        }
        return (
            <div className={cx("secondaryNav", {extra: addExtraTop})}>
                {inviteList.length > 0 && this.state.inviteInProgress && (
                    <Invite
                        inviteInProgress={this.state.inviteInProgress}
                        finishFunction={this.finishInvite}
                        apiErrorFunction={this.showApiError}
                        inviteList={inviteList}
                    />
                )}
                <FeedbackOnTheTop
                    message="Invite successfully sent."
                    show={this.state.showInviteSuccess}
                    closeFunction={() => {
                        this.setState({
                            showInviteSuccess: false,
                        });
                    }}
                />

                <ApiError
                    show={this.state.showApiError}
                    error={this.state.apiError}
                    cancelFunction={() => this.setState({ showApiError: false })}
                />
                {/* {inviteProfile.wformUrl && <Modal
                    overlayClassName="influencerCardOverlay"
                    className="confirmRatesModal"
                    isOpen={modalOpen}
                    shouldCloseOnOverlayClick
                    onRequestClose={this.closeModal}>
                        <iframe src={inviteProfile.wformUrl} />
                </Modal>} */}
                <div>
                    <div>
                        <div className="spacer">{this.props.children}</div>
                        <div className="secondaryNavControl">
                            {/* !disableAddToCampaign && inviteProfile.userNetworks.length >= 1 && permission && <div onClick={this.addToCampaignHandler}>Add to Campaign</div> */}
                            {inviteProfile.reportUrl && <div onClick={this.openModal}>Vetting Report</div>}
                            {inviteProfile.email && <div>
                                <a href={`mailto:${  inviteProfile.email}`}>Contact</a>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    showMissingChannelsError: state.campaignReducer.influencerSearch.showMissingChannelsError,
});

const mapDispatchToProps = (dispatch) => ({
    setData: (payload) => dispatch(setData(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SecondaryNavigation);
