import React, { useMemo, useState } from 'react';
import InfluencerPhoto from '../oneInfluencer2022/InfluencerPhoto';
import AddToActions from '../favourite/AddToActions';
import { formatBigNumber, formatPercentageToWhole } from '../../modules/utils/helpers';
import Api from '../../modules/Api';
import Tooltip from '../shared/tooltip/Tooltip';
import './SummaryTable.scss';

const Creator = ({ selectedTab, tableData, influencer, index, planId, setIsLoading, onChange = () => null }) => {
    const [activeRow, setActiveRow] = useState(-1);

    const actions = [
        { key: 'view', label: 'View Profile' },
        { key: 'remove', label: 'Remove' },
    ];
    const toggleActions = (index) => {
        setActiveRow(index === activeRow ? -1 : index);
    };
    const handleActions = (action) => {
        switch (action) {
            case 'view':
                actionViewHandler(tableData[activeRow]);
                setActiveRow(-1);
                break;
            case 'remove':
                actionRemoveHandler(tableData[activeRow]);
                setActiveRow(-1);
                break;
        }
    };
    const actionViewHandler = (influencer) => {
        if (influencer.id != null) {
            const linkToDetails = `/influencerDetailPage/${influencer.id}?Connected`;
            window.open(linkToDetails, '_blank');
        }
        if (influencer.uid != null && influencer.id == null) {
            const linkToDetails = `/publicInfluencerDetailsPage/${influencer.uid}/${selectedTab.toUpperCase()}?Public`;
            window.open(linkToDetails, '_blank');
        }
    };
    const actionRemoveHandler = (influencer) => {
        const connectedUserIds = [];
        const publicUsers = [];

        if (influencer.id != null) {
            connectedUserIds.push(influencer.id);
        }
        if (influencer.uid != null && influencer.id == null) {
            publicUsers.push({ userId: influencer.uid, userType: influencer.userNetworks[0].networkType });
        }

        if (connectedUserIds?.length > 0 || publicUsers?.length > 0) {
            setIsLoading(true);

            const payload = {
                connectedUserIds,
                publicUsers,
            };

            Api.removeUsersPlan(planId, payload)
                .then((res) => {
                    onChange();
                })
                .catch((err) => {
                    handleApiError(err);
                })
                .finally(() => setIsLoading(false));
        }
    };

    const renderHandle = (handle) => {
        if(!handle) return ""
        if(handle[0] === '@') return handle;
        return `@${handle}`;
    }

    let influencerLabel = `${influencer?.firstname} ${influencer?.lastname}`;
    if (selectedTab && selectedTab !== 'All Channels') {
        const network = influencer.userNetworks.find(n => n.networkType === selectedTab);
        if (network && network.socialHandle) {
            influencerLabel = renderHandle(network.socialHandle);
        }
    }

    return (
        <div className="tableRow creatorsTable">
            <div className="tableCell">
                <div className="tableCellContent index">{index + 1}</div>
            </div>
            <div className="tableCell">
                <div className="tableCellContent name">
                    <InfluencerPhoto url={influencer.pictureUrl} firstName={influencer.firstname} lastName={influencer.lastname} />
                    {influencerLabel}
                </div>
            </div>
            <div className="tableCell">
                <div className="tableCellContent">
                    {influencer.userNetworks.filter((network) => network.networkType === selectedTab)[0]?.reach
                        ? formatBigNumber(influencer.userNetworks.filter((network) => network.networkType === selectedTab)[0].reach)
                        : '-'}
                </div>
            </div>
            <div className="tableCell">
                <div className="tableCellContent more">
                    <div
                        className={`moreButton ${index === activeRow ? 'selected' : ''}`}
                        onClick={() => toggleActions(index)}
                        onBlur={() => setActiveRow(-1)}
                        tabIndex={index === activeRow ? 0 : -1}
                    >
                        <img id="moreBlack" src="/images/ic-more-vertical-gray.svg" />
                        <img id="moreGray" src="/images/ic-more-vertical.svg" />
                        {index === activeRow && <AddToActions data={actions} onActions={handleActions} />}
                    </div>
                </div>
            </div>
        </div>
    );
};

const SummaryCellData = ({ feature }) => {
    const renderValue = useMemo(() => {
        if(feature.weight !== null) {
            return !!feature.weight ? `${formatPercentageToWhole(feature.weight * 100)} %` : '- %'
        }
        if(feature.weightedValue !== null) {
            return !!feature.weightedValue ? formatBigNumber(feature.weightedValue) : '-'
        }
        return '-'
    }, [feature])

    return (
        <div className="tableCell">
            <div className="tableCellContent">{renderValue}</div>
        </div>
    );
};

const UniqueReachCellData = ({ platform, dataItem }) => {
    const { creators, totalReach, totalUniqueReach } = dataItem;
    return (
        <>
            <div className="tableCell" key={`${creators} - creators`}>
                <div className="tableCellContent">{creators ? formatBigNumber(creators) : '-'}</div>
            </div>
            <div className="tableCell" key={`${platform} - totalReach`}>
                <div className="tableCellContent">{totalReach ? formatBigNumber(totalReach) : '-'}</div>
            </div>

            <div className="tableCell" key={`${platform} - totalUniqueReach`}>
                <div className="tableCellContent">{totalUniqueReach ? formatBigNumber(totalUniqueReach) : '-'}</div>
            </div>
        </>
    );
};

const SummaryTable = ({
    selectedTab,
    tableHeads,
    tableHeadsToolTip,
    tableSummary,
    tableData,
    isWithSummary,
    isCreatorsTable,
    isUnique,
    planId,
    setIsLoading,
    onChange = () => null,
}) => {

    const fillEmpty = (summary) => {
        const requiredColCount = tableHeads.length - 1 - summary.length; // minus platform col, minus total cols
        return [...summary, ...(new Array(requiredColCount).fill({ _empty: true }))]
    }
    return (
        <div className={`summaryTable ${isCreatorsTable ? 'creatorsTable' : ''}`}>
            <div className={`tableHead ${isCreatorsTable ? 'creatorsTable' : ''}`}>
                <div className="tableRow">
                    {tableHeads.map((header, index) => (
                        <div className="tableCell" key={header}>
                            <div className="tableCellContainer">
                                <div className="tableCellContent">{header}</div>
                                <div className='tableCellContentTooltipContainer'>
                                    {tableHeadsToolTip[index] != '' ? <Tooltip tooltipText={tableHeadsToolTip[index]} image="/images/tooltipUser.svg" lessWidth /> : ''}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className={`tableBody ${isWithSummary ? 'hasIndentation' : ''}${isCreatorsTable ? 'creatorsTable' : ''}`}>
                {!isCreatorsTable && isWithSummary && selectedTab === 'All Channels' && (
                    <div className={`tableRow ${isWithSummary ? 'tableSummaryRow' : ''}`}>
                        <div className="tableCell">
                            <div className="tableCellContent">{'All Platforms'}</div>
                        </div>
                        {fillEmpty(tableSummary).map((feature) => !feature._empty ? (
                            <SummaryCellData key={`${feature.planSummaryFeatureType}`} feature={feature} />
                        ) : (<div className="tableCell"/>))}
                    </div>
                )}

                {!isCreatorsTable && (
                    <>
                        {tableData?.map((dataItem, index) => {
                            let platformName = dataItem.platform ? dataItem.platform[0].toUpperCase() + dataItem?.platform.slice(1) : 'N/A'
                            if (dataItem?.platform === "youtube") {
                                platformName = "YouTube"
                            }
                            return <div
                                key={`${dataItem?.platform}-${dataItem?.id}-${dataItem?.uid}`}
                                className={`tableRow ${
                                    isWithSummary && selectedTab !== 'All Channels' && index === 0 ? 'tableSummaryRow' : ''
                                }`}
                            >
                                <div className='tableCell'>
                                    <div className='tableCellContent'>
                                        {platformName}
                                    </div>
                                </div>

                                {isUnique && dataItem.uniqueReachFeatures?.map(feature => (
                                    <SummaryCellData
                                        key={`${dataItem?.platform} - ${feature.planSummaryFeatureType}`}
                                        feature={feature}
                                    />
                                ))}

                                {!isUnique &&
                                    dataItem?.planSummaryFeatures.map((feature) => (
                                        <SummaryCellData
                                            key={`${dataItem?.platform} - ${feature.planSummaryFeatureType}`}
                                            feature={feature} />
                                    ))}
                            </div>
                        })}
                        {tableData?.length === 0 && (
                            <div className="tableRow">
                                <div className="tableCell">No Data</div>
                            </div>
                        )}
                    </>
                    )}

                {isCreatorsTable &&
                    tableData?.map((influencer, index) => (
                        <Creator
                            key={`${influencer?.id} - ${influencer?.uid}`}
                            selectedTab={selectedTab}
                            tableData={tableData}
                            influencer={influencer}
                            index={index}
                            planId={planId}
                            setIsLoading={setIsLoading}
                            onChange={onChange}
                        />
                    ))}
            </div>
        </div>
    );
};
export default SummaryTable;
