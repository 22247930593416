import React from 'react';
import Select from "react-select";
import cx from "classnames";
import withRouter from "react-router/withRouter";
import CurrentBrand from "./currentBrand";
import Pagination from "../../../shared/pagination/Pagination";

const pageLimitOptions = [
    { value:'10', label: '10 per page'},
    { value:'20', label: '20 per page'},
    { value:'50', label: '50 per page'}
];

const campaigns = [{
    campaign: 'Infusion Deep Sea Kelp Cleanser',
    assignment: 6,
    owner: ['Adelaide Kelly'],
    budget: '8000',
    remaining: '200',
    date: 'Oct 14',
    id: 1
},{
    campaign: 'Summer 2020 Daily Post',
    assignment: 11,
    owner: ['Theodore Gomez'],
    budget: '10000',
    remaining: '400',
    date: 'Oct 14',
    id: 1
},
];

class Brand extends React.Component {
    state = {
        active: true,
        dateSort: '',
        campaignSort: '',
        assignmentSort: '',
        ownerSort: '',
        budgetSort: '',
        remainingSort: '',
        searchValue: '',
        sortBy: 'campaign',
    };

    flipSort = (sortBy) => {
        const newState = {};
        const fieldName = `${sortBy  }Sort`;
        newState[fieldName] = this.state[fieldName] === 'desc' ? 'asc' : 'desc';
        this.setState(newState)
    };

    setSortBy = (sortField) => {
        if (this.state.sortBy === sortField) {
            this.flipSort(sortField);
            return;
        }
        this.setState({sortBy : sortField});
    };

    sortUsers = (arr) => {
        const {sortBy} = this.state;
        const fieldName = `${sortBy  }Sort`;
        return arr.sort((a, b) => {
            if (this.state[fieldName] === 'desc') {
                return a[sortBy] > b[sortBy] ? -1 : 1
            } 
            return a[sortBy] > b[sortBy] ? 1 : -1
            
        });
    };

    handleSearch = (e) => {
        this.setState({searchValue: e.target.value})
    };

    toggleTab = (val) => {
        this.setState({active: val})
    };

    goBack = () => {
        this.props.history.push('/adminBrands');
    };

    render() {
        const {active, searchValue} = this.state;



        const campaignDirectionOpacity = {};
        const ownersDirectionOpacity = {};
        const assignmentDirectionOpacity = {};
        const budgetDirectionOpacity = {};
        const remainingDirectionOpacity = {};
        const dateDirectionOpacity = {};
        if (this.state.sortBy === 'campaign') {
            ownersDirectionOpacity.opacity = 0.2;
            assignmentDirectionOpacity.opacity = 0.2;
            budgetDirectionOpacity.opacity = 0.2;
            remainingDirectionOpacity.opacity = 0.2;
            dateDirectionOpacity.opacity = 0.2;
        } else if (this.state.sortBy === 'owners') {
            campaignDirectionOpacity.opacity = 0.2;
            assignmentDirectionOpacity.opacity = 0.2;
            budgetDirectionOpacity.opacity = 0.2;
            remainingDirectionOpacity.opacity = 0.2;
            dateDirectionOpacity.opacity = 0.2;
        } else if (this.state.sortBy === 'assignment') {
            campaignDirectionOpacity.opacity = 0.2;
            ownersDirectionOpacity.opacity = 0.2;
            budgetDirectionOpacity.opacity = 0.2;
            remainingDirectionOpacity.opacity = 0.2;
            dateDirectionOpacity.opacity = 0.2;
        } else if (this.state.sortBy === 'budget') {
            campaignDirectionOpacity.opacity = 0.2;
            ownersDirectionOpacity.opacity = 0.2;
            assignmentDirectionOpacity.opacity = 0.2;
            remainingDirectionOpacity.opacity = 0.2;
            dateDirectionOpacity.opacity = 0.2;
        } else if (this.state.sortBy === 'remaining') {
            campaignDirectionOpacity.opacity = 0.2;
            ownersDirectionOpacity.opacity = 0.2;
            assignmentDirectionOpacity.opacity = 0.2;
            dateDirectionOpacity.opacity = 0.2;
        } else {
            campaignDirectionOpacity.opacity = 0.2;
            ownersDirectionOpacity.opacity = 0.2;
            assignmentDirectionOpacity.opacity = 0.2;
            budgetDirectionOpacity.opacity = 0.2;
            remainingDirectionOpacity.opacity = 0.2;
        }

        const filteredBrands = campaigns.filter((el) => {
            const str = `${el.campaign} ${el.owner.join(' ')} ${el.budget} ${el.remaining} ${el.date}`;
            if(str.toLowerCase().includes(searchValue)) return el
        });
        const sortedBrands = this.sortUsers(filteredBrands);


        return (
            <div>
                <div className="blue-header admin-header">
                    <div className="contentDiv">
                        <div className="controlContainer">
                            <div className="left-container">
                                <div onClick={this.goBack} className="arrow"/>
                                <div className="image-holder">
                                    {/* <img src="" alt=""/> */}
                                </div>
                                <h1>Pantene</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="brand-page brands-list all-users-page">
                    <div className="filter-subheader">
                        <div className="contentDiv">
                            <div className="header">
                                <div className="left-block">
                                    <div onClick={() => this.toggleTab(true)} className={`tab ${active && 'active'}`}>Active</div>
                                    <div onClick={() => this.toggleTab(false)} className={`tab ${!active && 'active'}`}>Archive</div>
                                </div>
                                <div className="right-block">
                                    <div className="limitOption">  {/* cell */}
                                        <Select className="select"
                                            clearable={false}
                                            searchable={false}
                                            value={this.props.limit}
                                            single
                                            placeholder="Page size"
                                            options={pageLimitOptions}
                                            onChange={this.setLimit}
                                        />
                                    </div>
                                    <div>
                                        <input onChange={this.handleSearch} className="search" type="text" placeholder="Search user"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="contentDiv">
                        <table className="data-table"
                            ref={(e) => {
                                this.table = e;}}>
                            <tbody>
                                <tr className="table-header">
                                    <th onClick={() => this.setSortBy('campaign')} className="user-info-holder">
                                        <div>
                                            Campaign
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                style={campaignDirectionOpacity}
                                                className={cx({'up':this.state.campaignSort==='desc'},{'no':this.state.campaignSort===''})}>
                                                <path fill="#666666" fillRule="nonzero" d="M16.46 9.27a.889.889 0 0 1 1.276 0 .938.938 0 0 1 0 1.305l-5.097 5.154a.888.888 0 0 1-1.278 0l-5.096-5.154a.936.936 0 0 1 0-1.305.889.889 0 0 1 1.276 0l4.46 4.227 4.458-4.227z"/>
                                            </svg>
                                        </div>
                                    </th>
                                    <th onClick={() => this.setSortBy('assignment')} className="email">
                                        <div>
                                            Assignment
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                style={assignmentDirectionOpacity}
                                                className={cx({'up':this.state.assignmentSort==='desc'},{'no':this.state.assignmentSort===''})}>
                                                <path fill="#666666" fillRule="nonzero" d="M16.46 9.27a.889.889 0 0 1 1.276 0 .938.938 0 0 1 0 1.305l-5.097 5.154a.888.888 0 0 1-1.278 0l-5.096-5.154a.936.936 0 0 1 0-1.305.889.889 0 0 1 1.276 0l4.46 4.227 4.458-4.227z"/>
                                            </svg>
                                        </div>
                                    </th>
                                    <th onClick={() => this.setSortBy('owners')} className="role">
                                        <div>
                                            Brand Owner
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                style={ownersDirectionOpacity}
                                                className={cx({'up':this.state.ownersSort==='desc'},{'no':this.state.ownersSort===''})}>
                                                <path fill="#666666" fillRule="nonzero" d="M16.46 9.27a.889.889 0 0 1 1.276 0 .938.938 0 0 1 0 1.305l-5.097 5.154a.888.888 0 0 1-1.278 0l-5.096-5.154a.936.936 0 0 1 0-1.305.889.889 0 0 1 1.276 0l4.46 4.227 4.458-4.227z"/>
                                            </svg>
                                        </div>
                                    </th>
                                    <th onClick={() => this.setSortBy('budget')} className="brands">
                                        <div>
                                            Budget
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                style={budgetDirectionOpacity}
                                                className={cx({'up':this.state.budgetSort==='desc'},{'no':this.state.budgetSort===''})}>
                                                <path fill="#666666" fillRule="nonzero" d="M16.46 9.27a.889.889 0 0 1 1.276 0 .938.938 0 0 1 0 1.305l-5.097 5.154a.888.888 0 0 1-1.278 0l-5.096-5.154a.936.936 0 0 1 0-1.305.889.889 0 0 1 1.276 0l4.46 4.227 4.458-4.227z"/>
                                            </svg>
                                        </div>
                                    </th>
                                    <th onClick={() => this.setSortBy('remaining')} className="brands">
                                        <div>
                                            Remaining
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                style={remainingDirectionOpacity}
                                                className={cx({'up':this.state.remainingSort==='desc'},{'no':this.state.remainingSort===''})}>
                                                <path fill="#666666" fillRule="nonzero" d="M16.46 9.27a.889.889 0 0 1 1.276 0 .938.938 0 0 1 0 1.305l-5.097 5.154a.888.888 0 0 1-1.278 0l-5.096-5.154a.936.936 0 0 1 0-1.305.889.889 0 0 1 1.276 0l4.46 4.227 4.458-4.227z"/>
                                            </svg>
                                        </div>
                                    </th>
                                    <th onClick={() => this.setSortBy('date')} className="brands">
                                        <div>
                                            Created
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                style={dateDirectionOpacity}
                                                className={cx({'up':this.state.dateSort==='desc'},{'no':this.state.dateSort===''})}>
                                                <path fill="#666666" fillRule="nonzero" d="M16.46 9.27a.889.889 0 0 1 1.276 0 .938.938 0 0 1 0 1.305l-5.097 5.154a.888.888 0 0 1-1.278 0l-5.096-5.154a.936.936 0 0 1 0-1.305.889.889 0 0 1 1.276 0l4.46 4.227 4.458-4.227z"/>
                                            </svg>
                                        </div>
                                    </th>
                                </tr>
                                {
                                    sortedBrands.length ?
                                        sortedBrands.map((brand, i) => (
                                            <tr key={i} className="table-row">
                                                <CurrentBrand brand={brand} />
                                            </tr>
                                        ))
                                        :''
                                }

                            </tbody>
                        </table>
                        <Pagination
                            // total={totalCount}
                            total={campaigns.length}
                            limit={10}
                            current={1}
                            parentContainer={this.table}
                            handlePageChange={()=> {}}
                        />
                    </div>
                </div>
            </div>

        );
    }
}

export default withRouter(Brand);