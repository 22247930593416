import moment from 'moment';
import * as rateTypes from '../../constants/rateTypes';
import * as channelTypes from '../../constants/channels';

const isNotEmptyArray = (array) => Array.isArray(array) && array.length;
const isNotExpiredDate = (date) => moment(date).isAfter(
    moment(), // now
);
const checkPath = (pathname) => (/^\/campaignWork\/\d+\/influencerSearch\/\d+$/).test(pathname);

export const getCampaignAndAssignmentIdsFromRoute = (router) => {
    // this project has a weird data flow. The fastest way to get these two values - from route url
    const {
        location: {
            pathname,
        },
    } = router;
    const validPath = checkPath(pathname);

    if (!validPath) {
        return {
            assignmentId: null,
            campaignId: null,
        };
    }
    const pathnameSplit = pathname.split('/');

    return {
        campaignId: Number(pathnameSplit[2]),
        assignmentId: Number(pathnameSplit[4]),
    };
};

export const checkAssignmentPastDue = (flights) => {
    const flightsNotEmpty = isNotEmptyArray(flights);

    if (!flightsNotEmpty) return false;

    // I'm not sure if multiple flights logic is still valid
    const lastFlightObject = flights.slice(-1)[0];
    const latestPublishDate = !lastFlightObject.perChannelDateRanges ? lastFlightObject.publishEndDate : (lastFlightObject.channelFlightDates && lastFlightObject.channelFlightDates.length > 0 ?
        lastFlightObject.channelFlightDates[0].endPublishDate : null);

    return isNotExpiredDate(latestPublishDate);
};

export const sortFavoritesList = (favoritesList) => {
    const favoritesListSorted = favoritesList.sort((a, b) => {
        const aCombined = a.combined;
        const bCombined = b.combined;
        const aNameLessBName = a.name < b.name;
        const aNameMoreBName = a.name > b.name;

        // todo. this doesn't seem working
        switch (true) {
        case aCombined:
        case aNameLessBName:
            return -1;
        case bCombined:
        case aNameMoreBName:
            return 1;
        default:
            return 0;
        }
    });

    return favoritesListSorted;
};

/*
    It is used to convert assignment rate types into the required channels.
    Channels are used to filter invited to assignment influencers (if they lack some channels)
 */
export const convertRateTypesIntoChannels = (types) => {
    const channels = types.map((type) => {
        switch (type) {
        case rateTypes.BLOG_POST:
            return channelTypes.BLOG;
        case rateTypes.FACEBOOK_POST:
            return channelTypes.FACEBOOK;
        case rateTypes.INSTAGRAM_POST:
        case rateTypes.INSTAGRAM_STORY:
            return channelTypes.INSTAGRAM;
        case rateTypes.SOCIAL_SHARE:
            return channelTypes.SOCIAL;
        case rateTypes.TWITTER_POST:
            return channelTypes.TWITTER;
        case rateTypes.YOUTUBE:
            return channelTypes.YOUTUBE;
        default:
            return channelTypes.GENERAL;
        }
    });

    return [...new Set(channels)];
}
