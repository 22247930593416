import React from 'react';
import './matchItem.scss';
import className from 'classnames';
import moment from 'moment';
import VideoThumbnail from 'react-video-thumbnail';
import SocialNetworks from '../../../modules/influencer/SocialNetworks';
import Format from '../../../modules/utils/Format';
import ReachIcon from "../../shared/ReachIcon/ReachIcon";
import { loadThumbnailError } from '../../../modules/utils/CommonUtils';

export default class MatchItem extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.onExpand = this.onExpand.bind(this);

        this.state = {
            expanded: props.expanded,
        };
    }

    onExpand() {
        this.setState({
            expanded: !this.state.expanded
        })
    }

    onPostClick = (post) => {
        const { onClickPost } = this.props;
        onClickPost(post);
    }

    renderCaption = (cp) => {
        const { keywords } = this.props;
        return (
            <div className="title" dangerouslySetInnerHTML={{ __html: Format.highlightKeywords(cp, keywords) }} />
        )
    }

    render() {
        const { data, type, totalMatches } = this.props;

        const networkOptions = SocialNetworks.getNetworkOptionList();
        let netWork;
        if (Array.isArray(networkOptions)) {
            netWork = networkOptions.find((no) => {
                return no.id === type.toUpperCase();
            });
        }

        return (
            <div className="matchItemContainer">
                <div className="matchMainSection">
                    {netWork && data && data.length > 0 && <div className="labelContainer">
                        <ReachIcon network={netWork.id} value={1} size={24} padding={12} />
                        {netWork.label && <div className="label">
                            <div>{netWork.label}</div>
                            <span>{totalMatches} Matches</span>
                        </div>}
                    </div>}
                    <div className="actionsContainer">
                        <div className="expandIcon" onClick={this.onExpand}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                className={className({ right: !this.state.expanded }, { down: this.state.expanded })}
                            >
                                <path
                                    fill={this.state.expanded ? '#333333' : '#999999'}
                                    fillRule="nonzero"
                                    d="M16.46 9.27a.889.889 0 0 1 1.276 0 .938.938 0 0 1 0 1.305l-5.097 5.154a.888.888 0 0 1-1.278 0l-5.096-5.154a.936.936 0 0 1 0-1.305.889.889 0 0 1 1.276 0l4.46 4.227 4.458-4.227z"
                                />
                            </svg>

                        </div>
                    </div>
                </div>
                <div className={className("matchExpandSection", { expand: this.state.expanded })}>
                    {data && data.length > 0 && data.map((post, index) => {
                        return (
                            <>
                                {type === 'instagram' && (
                                    <div className={`postCard ${type}`} onClick={() => this.onPostClick(post)}>
                                        <img src={post.thumbnail ? post.thumbnail : '/images/ic-default-post.svg'} className="thumbnail" onError={loadThumbnailError} />
                                        <div className="dateTime">{moment(post.created_time).format('MMM DD, YYYY')} at {moment(post.created_time).format('HH:MMA')}</div>
                                        {this.renderCaption(post.text)}
                                        {(post.thumbnail || post.link) && <div className="dataWrapper">
                                            {post.like_count !== null && <div className="data">
                                                <img src="/images/ic-heart-fill.svg" />
                                                {post.like_count.toLocaleString()}
                                            </div>}
                                            {post.comments_count !== null && <div className="data">
                                                <img src="/images/ic-comment.svg" />
                                                {post.comments_count.toLocaleString()}
                                            </div>}
                                        </div>}
                                    </div>
                                )}
                                {type === 'twitter' && (
                                    <div className={`postCard ${type}`} onClick={() => this.onPostClick(post)}>
                                        <img src="/images/ic-tweet.svg" />
                                        <img src={post.thumbnail ? post.thumbnail : '/images/ic-default-post.svg'} className="thumbnail" onError={loadThumbnailError} />
                                        <div className="dateTime">{moment(parseInt(post.created_time)).format('MMM DD')} at {moment(parseInt(post.created_time)).format('HH:MMA')}</div>
                                        {this.renderCaption(post.text)}
                                    </div>
                                )}
                                {type === 'youtube' && (
                                    <div className={`postCard ${type}`} onClick={() => this.onPostClick(post)}>
                                        <img src={post.thumbnail ? post.thumbnail : '/images/ic-default-post.svg'} className="thumbnail" onError={loadThumbnailError} />
                                        {this.renderCaption(post.text)}
                                    </div>
                                )}
                                {type === 'facebook' && (
                                    <div className={`postCard ${type}`} onClick={() => this.onPostClick(post)}>
                                        <img src={post.thumbnail ? post.thumbnail : '/images/ic-default-post.svg'} className="thumbnail" onError={loadThumbnailError} />
                                        <div className="dateTime">{moment(parseInt(post.created_time)).format('MMM DD')} at {moment(parseInt(post.created_time)).format('HH:MMA')}</div>
                                        {this.renderCaption(post.text)}
                                    </div>
                                )}
                            </>
                        )
                    })}
                </div>
            </div>
        );
    }
}
