import React from 'react';
import PropTypes from 'prop-types';
import style from './styles.module.scss';
import EditIcon from '../../../../../shared/EditIcon';
import SubmitButton from '../SubmitButton';

const BillingSection = ({
        isEmailEditable,
        influencerEmail,
        onEditEmail,
        onHandleChange,
        emailWarningMessage,
        onSaveEmail,
    }) => {

    return (
        <div className={style.billingContactSection}>
            <div>
                <span className={style.billingContactTitle}>Billing Contact</span>
                {isEmailEditable ? (
                    <span className={style.editBlock}>
                        <span className={style.inputCover}>
                            <input
                                name='email'
                                onChange={onHandleChange}
                                className={style.editEmail}
                                value={influencerEmail}
                            />
                            <span className={style.warningMessage}>{emailWarningMessage}</span>
                        </span>
                        <SubmitButton
                            isDisabled={!!emailWarningMessage}
                            onClick={onSaveEmail}
                            className={style.saveButton}>
                            Save
                        </SubmitButton>
                    </span>
                ) : (
                    <span className={style.influencerEmail}>{influencerEmail}</span>
                )}
            </div>
            <div onClick={onEditEmail} className={style.billingContactIcon}>
                <EditIcon fill='#808080' width='18px' height='18px' />
            </div>
        </div>
    )
};

BillingSection.propTypes = {
    isEmailEditable: PropTypes.bool,
    influencerEmail: PropTypes.string,
    onEditEmail: PropTypes.func,
    onHandleChange: PropTypes.func,
    emailWarningMessage: PropTypes.string,
    onSaveEmail: PropTypes.func,
}

export default BillingSection;
