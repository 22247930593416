import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { render } from 'react-dom';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import pptxgen from "pptxgenjs";
import moment from "moment"
import Format from '../../modules/utils/Format';
import PleaseWaitWhite from '../shared/pleaseWait/PleaseWaitWhite';
import TopNavigation from '../shared/topNavigation/TopNavigation';
import ApiError from '../shared/apiError/ApiError';
import SuccessBanner from '../favourite/SuccessBanner';
import Api from '../../modules/Api';
import { startLoadingLookupTables } from '../../store/global/globalActions';
import BreadCrumbs from '../favourite/BreadCrumbs';
import CreatorsTab from './CreatorsTab';
import ExcludedTab from './ExcludedTab';
import SavedSearchesTab from './SavedSearchesTab';
import AddEditModal from './AddEditModal';
import SummaryTab from './SummaryTab';
import { PlanReport } from './planPDF';
import CompetitiveAnalysisTab, { graphColors } from './CompetitiveAnalysis/CompetitiveAnalysis';
import { DurationOptions } from './CompetitiveAnalysis/Filters/DurationFilter';
import { CompetitiveAnalysisFilters } from './CompetitiveAnalysis/Filters/CompetetiveAnalysisFilters';
import './planDetails.scss';

const Tabs = [
    { key: 'creators', label: 'Creators' },
    { key: 'summary', label: 'Summary' },
    { key: 'competitiveAnalysis', label: 'Competitive Analysis' },
    // { key: 'saved', label: 'Saved searches' },
    // { key: 'excluded', label: 'Excluded creators' },
];

const PlanDetails = (props) => {
    const { getLookupTables, lookupTablesLoading, lookupTables, location, history, authCheckData } = props;
    const [unique, setUnique] = useState(null);
    const [socials, setSocials] = useState(null);
    const [features, setFeatures] = useState(null);
    const [audience, setAudience] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [apiError, setApiError] = useState();
    const [errTitle, setErrTitle] = useState('');
    const [tabSelected, setTab] = useState('creators');
    const [plansData, setPlansData] = useState();
    const [breadCrumbs, setBreadCrumbs] = useState([]);
    const [showPlanModal, setShowPlanModal] = useState(false);
    const [brandData, setBrandData] = useState();
    const [uniqueError, setUniqueError] = useState(false);
    const containerRef = useRef();
    const plansId = useRef();

    const [isCompetitorsLoading, setIsCompetitorsLoading] = useState(false);
    const [duration, setDuration] = useState(DurationOptions[0]);
    const [competitorsData, setCompetitorsData] = useState();
    const [selectedCompetitors, setSelectedCompetitors] = useState([]);
    const [filters, setFilters] = useState([]);

    const competitorColors = brandData?.brandCompetitors.map((competitor, idx) => ({ uid: competitor.uid, color: graphColors[idx] }));

    const isFiltersActive = !!filters.length;
    
    const [showFilters, setShowFilters] = useState(false);

    const handleDataUpdate = async (duration, filters) => {
        const competitorIds = selectedCompetitors.filter(brand => brand.selected).map(brand => brand.value);
        const countryIds = filters.filter(filter => filter.filterType === 'Country').map(country => country.value);
        const hashtags = filters.filter(filter => filter.filterType === 'Hashtag').map(hashtag => hashtag.value);
        const keywords = filters.filter(filter => filter.filterType === 'Keyword').map(keyword => keyword.value);
        let {startDate} = duration.value;
        let {endDate} = duration.value;

        if (duration.value.isCustom) {
            startDate = duration.value.startDate.format('YYYY-MM-DD');
            endDate = duration.value.endDate.format('YYYY-MM-DD');
        }

        setIsCompetitorsLoading(true);
        getCompetitorsData(competitorIds, startDate, endDate, countryIds, hashtags, keywords)
            .then(res => setCompetitorsData(res))
            .catch(err => handleApiError(err))
            .finally(() => setIsCompetitorsLoading(false));
    }

    const getCompetitorsData = async (competitorIds, dateFrom, dateTo, countryIds, hashtags, keywords) => {
        if (competitorIds?.length) 
            return Api.getBrandCompetitorsData(competitorIds, dateFrom, dateTo, countryIds, hashtags, keywords);
    }

    useEffect(() => {
        const competitorIds = brandData?.brandCompetitors.map(brand => brand.uid);
        const countryIds = filters.filter(filter => filter.filterType === 'Country').map(country => country.value);
        const hashtags = filters.filter(filter => filter.filterType === 'Hashtag').map(hashtag => hashtag.value);
        const keywords = filters.filter(filter => filter.filterType === 'Keywords').map(keyword => keyword.value);

        let {startDate} = duration.value;
        let {endDate} = duration.value;

        if (duration.value.isCustom) {
            startDate = duration.value.startDate.format('YYYY-MM-DD');
            endDate = duration.value.endDate.format('YYYY-MM-DD');
        }

        if (competitorIds)
            getCompetitorsData(competitorIds, startDate, endDate, countryIds, hashtags, keywords)
                .then(response => {
                    setCompetitorsData(response);

                    const sortedBrands = response?.competitorBrands.sort((a,b) => b.totalFollowers - a.totalFollowers).slice(0, 10) || [];
                    setSelectedCompetitors(sortedBrands.map(brand => ({ value: brand.uid, label: brand.socialHandle, selected: true })));
                })
                .catch(err => handleApiError(err))
                .finally(() => setIsCompetitorsLoading(false));

    }, [brandData]);

    useLayoutEffect(() => {
        updateBreadCrumbs();
        setShowFilters(tabSelected === 'competitiveAnalysis');
    }, [plansData, tabSelected]);

    useEffect(() => {
        if (!lookupTablesLoading && !lookupTables) {
            getLookupTables();
        }
        if (location) {
            if (location.pathname) {
                try {
                    plansId.current = location.pathname.split('/')[2];
                    fetchData();
                } catch (err) {}
            }
            if (location.state && location.state?.tab) {
                setTab(location.state.tab);
            }
        }
    }, []);

    const updateBreadCrumbs = () => {
        const crumbs = [{ label: 'Plans', path: '/plans' }];

        if (plansData && plansData.name) {
            crumbs.push({
                label: `${plansData.brand && plansData.brand.brandName ? `${plansData.brand.brandName  } : ` : ''}${plansData.name}`,
                callback: onReset,
            });
        }

        crumbs.push({ label: Tabs.find((tab) => tab.key === tabSelected).label });

        setBreadCrumbs(crumbs);
    };

    const onReset = () => {
        setTab('creators');
    };

    const fetchData = () => {
        if (plansId.current) {
            setIsLoading(true);
            setIsCompetitorsLoading(true);
            Api.getPlanDetails(plansId.current)
                .then((res) => {
                    setPlansData(res.data);
                    getBrandData(res.data.brand.id);
                })
                .catch((err) => handleApiError(err))
                .finally(() => setIsLoading(false));
        }
    };

    const getBrandData = async (brandId) => 
        Api.getBrand(brandId)
            .then((res) => setBrandData(res))
            .catch(err => handleApiError(err));

    const handleApiError = (err) => {
        setApiError(err);
        if (err && err.originalMessage) {
            setErrTitle(err.originalMessage);
        }
    };

    const CancelError = () => {
        setApiError(null);
        setErrTitle('');
    };

    const closeModal = () => {
        setShowPlanModal(false);
    };

    const onEdit = () => {
        setShowPlanModal(true);
    };

    const setSuccess = (message) => {
        setShowSuccess(true);
        setSuccessMessage(message);
    };

    const onSubmit = (data) => {
        setIsLoading(true);
        closeModal();
        if (data.id) {
            Api.updatePlan(data.id, data)
                .then((res) => {
                    setIsLoading(false);
                    fetchData();
                })
                .catch((err) => {
                    setIsLoading(false);
                    handleApiError(err);
                });
        } else {
            Api.createPlan(data)
                .then((res) => {
                    setIsLoading(false);
                    fetchData();
                })
                .catch((err) => {
                    setIsLoading(false);
                    handleApiError(err);
                });
        }
    };

    const closeSuccessMessage = () => {
        setShowSuccess(false);
        setSuccessMessage('');
    };

    const onTabChange = (tab) => {
        if (tab !== tabSelected) {
            setTab(tab);
        }
    };

    const exportPDF = () => {
        createAndDownloadPDF(
            <PlanReport
                plansData={plansData}
                auth={authCheckData}
                socials={socials}
                features={features}
                audience={audience}
                unique={unique}
            />,
            () => {},
        );
    };

    const createAndDownloadPDF = (pdfContent, callback) => {
        setTimeout(() => {
            const link = (
                <div id="export-report">
                    <PDFDownloadLink document={pdfContent} fileName={plansData.name.includes(".") ? plansData.name + ".pdf" : plansData.name}>
                        {({ loading }) => {
                            if (!loading) {
                                setTimeout(() => {
                                    document.getElementById('export-report').children[0].click();
                                    callback();
                                    document.getElementById('root').removeChild(document.getElementById('export-report-container'));
                                }, 3);
                            }
                        }}
                    </PDFDownloadLink>
                </div>
            );
            const elem = document.createElement('div');
            elem.id = 'export-report-container';
            document.getElementById('root').appendChild(elem);
            render(link, elem);
        }, 50);
    };

    const sliceIntoChunks = (arr) => {
        const res = [];
        for (let i = 0; i < arr.length; i += 28) {
            const chunk = arr.slice(i, i + 28);
            res.push(chunk);
        }
        return res;
    }

    const exportPPT = () => {
        const pptx = new pptxgen();
        const slide = pptx.addSlide();
        slide.addImage({ path: "/images/kal_logo_svg.svg", x: 4.25, y: "30%", w: 1.5, h: 0.24});
        const coverInfo = [
            { text: `${plansData.name}`, options: { breakLine: true } },
            { text: `Exported on ${moment().format('MMMM D, YYYY - hh:mm a')}`, options: { breakLine: true } },
            { text: `Exported by: ${authCheckData.user.firstname} ${authCheckData.user.lastname}`, options: { breakLine: true } },
            { text: `View plan report: ` },
            {
                text: "link",
                options: { hyperlink: { url: `https://secure.koalifyed.com/plans/${plansId.current}`} },
            },

        ];
        slide.addText(coverInfo, { x: 0, y: "45%", w: "100%", align: "center"});
        const slide2 = pptx.addSlide();
        const rows = [];

        rows.push(["Platform", "Creators", "Total Followers", "Avg Weighted Cred Score", "Estimated Real Followers", "Avg Weighted Eng. Rate", 
            "Avg Weighted Eng", "Avg Weighted Spon. Eng. Rate", "Avg Weighted Spon. Eng.", "Avg Weighted View Rate", "Avg Weighted Views", 
            "Avg Weighted Eng. Rate by Views"]);
        const firstItem = features.find(i => i.planSummaryFeatureType === "CREATORS")
        const secondItem = features.find(i => i.planSummaryFeatureType === "TOTAL_REACH")
        rows.push(['All Platforms', firstItem && firstItem.weightedValue ? Format.expressInK(firstItem.weightedValue) : '-',
            secondItem && secondItem.weightedValue? Format.expressInK(secondItem.weightedValue) : '-', '', '', '', '', '', '', '', '', ''])
        socials.forEach(item => {
            const name = item.platform.charAt(0).toUpperCase() + item.platform.slice(1)
            const s1 = item.planSummaryFeatures.find(i => i.planSummaryFeatureType === "CREATORS")
            const s2 = item.planSummaryFeatures.find(i => i.planSummaryFeatureType === "TOTAL_REACH")
            const s3 = item.planSummaryFeatures.find(i => i.planSummaryFeatureType === "AVG_AUDIENCE_CREDIBILITY")
            const s4 = item.planSummaryFeatures.find(i => i.planSummaryFeatureType === "ESTIMATED_REAL_FOLLOWERS")
            const s5 = item.planSummaryFeatures.find(i => i.planSummaryFeatureType === "AVG_ENGAGEMENT_RATE")
            const s6 = item.planSummaryFeatures.find(i => i.planSummaryFeatureType === "AVG_ENGAGEMENT")
            const s7 = item.planSummaryFeatures.find(i => i.planSummaryFeatureType === "AVG_SPONSORED_ENGAGEMENT_RATE")
            const s8 = item.planSummaryFeatures.find(i => i.planSummaryFeatureType === "AVG_SPONSORED_ENGAGEMENT")
            const s9 = item.planSummaryFeatures.find(i => i.planSummaryFeatureType === "AVG_VIEW_RATE")
            const s10 = item.planSummaryFeatures.find(i => i.planSummaryFeatureType === "AVG_VIEWS")
            const s11 = item.planSummaryFeatures.find(i => i.planSummaryFeatureType === 'ENGAGEMENT_VIEWS')
            rows.push([name, s1 && s1.weightedValue !== null ? Format.expressInK(s1.weightedValue) : '-', 
                s2  && s2.weightedValue !== null ? Format.expressInK(s2.weightedValue) : '-',
                s3 && s3.weight !== null ?  `${(s3.weight * 100).toFixed(0)  }%` : '-%',
                s4 && s4.weightedValue !== null ? Format.expressInK(s4.weightedValue) : '-',
                s5 && s5.weight !== null ? `${(s5.weight * 100).toFixed(0)  }%`: '-%',
                s6 && s6.weightedValue !== null ? Format.expressInK(s6.weightedValue) : '-',
                s7 && s7.weightedValue !== null ? `${s7.weightedValue  }%`: '-%',
                s8 && s8.weightedValue !== null ? Format.expressInK(s8.weightedValue) : '-',
                s9 && s9.weight !== null ? `${(s9.weight * 100).toFixed(0)  }% `: '-%',
                s10 && s10.weightedValue !== null ? Format.expressInK(s10.weightedValue) : '-',
                s11 && s11.weight && s11.weight !== null ? `${(s11.weight * 100).toFixed(0)  }% `: '-%'])
        })
        slide2.addTable(rows, { x: 0.5, y: 1.0, w: 9, fontSize: 9, border: {type: "solid"} });
        if(unique) {
            const slide3 = pptx.addSlide();
            const rows3 = [];
            rows3.push(["Channel", "Creators", "Total Followers", "Unique Followers", "Avg Weighted Eng. Rate", "Forecasted Unique Eng.", 
                "Avg Weighted Spon. Eng. Rate", "Forecasted Unique Spon. Eng.", "Avg Weighted View Rate", "Forecasted Unique Views"]);
            const labelInfo = [
                { text: `Unique Followers Analysis on Top Creators`, options: { breakLine: true } },
                { text: `Only Available for Instagram and Youtube. Analysis includes top creators to up to 300 million followers`}
            ];
            slide3.addText(labelInfo, { x: 0.4, y: 0.5, fontSize: 9});
            unique.forEach(item => {
                const name = item.platform.charAt(0).toUpperCase() + item.platform.slice(1)
                const u1 = item.uniqueReachFeatures.find(i => i.uniqueReachFeatureType === "CREATORS")
                const u2 = item.uniqueReachFeatures.find(i => i.uniqueReachFeatureType === "TOTAL_REACH")
                const u3 = item.uniqueReachFeatures.find(i => i.uniqueReachFeatureType === "UNIQUE_REACH")
                const u4 = item.uniqueReachFeatures.find(i => i.uniqueReachFeatureType === "AVG_ENGAGEMENT_RATE")
                const u5 = item.uniqueReachFeatures.find(i => i.uniqueReachFeatureType === "FORECASTED_UNIQUE_ENGAGEMENT")
                const u6 = item.uniqueReachFeatures.find(i => i.uniqueReachFeatureType === "AVG_SPONSORED_ENGAGEMENT_RATE")
                const u7 = item.uniqueReachFeatures.find(i => i.uniqueReachFeatureType === "FORECASTED_UNIQUE_SPONSORED_ENGAGEMENTS")
                const u8 = item.uniqueReachFeatures.find(i => i.uniqueReachFeatureType === "AVG_VIEW_RATE")
                const u9 = item.uniqueReachFeatures.find(i => i.uniqueReachFeatureType === "FORECASTED_UNIQUE_VIEWS")
                rows3.push([name, u1 && u1.weightedValue !== null ? Format.expressInK(u1.weightedValue) : '-', 
                    u2  && u2.weightedValue !== null ? Format.expressInK(u2.weightedValue) : '-',
                    u3 && u3.weightedValue !== null ? Format.expressInK(u3.weightedValue) : '-',
                    u4 && u4.weight !== null ? `${(u4.weight * 100).toFixed(0)  }%` : '-%',
                    u5 && u5.weightedValue !== null ? u5.weightedValue: '-',
                    u6 && u6.weightedValue !== null ? (u6.weight * 100).toFixed(0) : '-%',
                    u7 && u7.weightedValue !== null ? u7.weightedValue : '-',
                    u8 && u8.weight !== null ? `${(u8.weight * 100).toFixed(0)  }%` : '-%',
                    u9 && u9.weightedValue !== null ? u9.weightedValue : '-'])
            })
            slide3.addTable(rows3, { x: 0.5, y: 1.0, w: 9, fontSize: 9, border: {type: "solid"} });
        }
        const slide4 = pptx.addSlide();
        const audienceGeo = audience.find(item => item.aggregatedAudienceInsightsGroupType === 'GEOGRAPHY')
        const audienceDemo = audience.find(item => item.aggregatedAudienceInsightsGroupType === 'DEMOGRAPHICS')
        const audienceInterest = audience.find(item => item.aggregatedAudienceInsightsGroupType === 'INTERESTS')
        let geoMax = 0
        let demoMax = 0
        let interestMax = 0
        audienceGeo && audienceGeo.aggregatedAudienceInsights.map(insights => {
            if (insights.audienceInsights.length > geoMax) {
                geoMax = insights.audienceInsights.length
            }
        })
        audienceDemo && audienceDemo.aggregatedAudienceInsights.map(insights => {
            if (insights.audienceInsights.length > demoMax) {
                demoMax = insights.audienceInsights.length
            }
        })
        audienceInterest && audienceInterest.aggregatedAudienceInsights.map(insights => {
            if (insights.audienceInsights.length > interestMax) {
                interestMax = insights.audienceInsights.length
            }
        })
        slide4.addText("Aggregated Audience Insights", { x: 0.4, y: 0.5, fontSize: 9});
        slide4.addText("Geography", { x: 0.4, y: 0.8, fontSize: 9});
        const geoExtraPages = []
        if (geoMax > 28) {
            const maxPage = Math.ceil(geoMax/28)
            for(let i=1; i<maxPage; i+=1) {
                const newSlide = pptx.addSlide();
                geoExtraPages.push(newSlide)
            }
        }
        audienceGeo.aggregatedAudienceInsights.map((geoItem, geoIndex) => {
            const name = [{ text: `${geoItem.name}`, options: { breakLine: true }}]
            const leftArray = []
            const rightArray = []
            slide4.addText(name, { x: 0.4 + geoIndex* 3, y: 1, fontSize: 9, valign: "top"});
            geoItem.audienceInsights.map(insight => {
                let valueToUse = insight.weight * 100;
                if (valueToUse > 0 && valueToUse < 1) {
                    valueToUse = '<1%'
                } else {
                    valueToUse = `${(insight.weight * 100).toFixed(0)  }%`
                }
                leftArray.push({ text: `${insight.name}`, 
                    options: { breakLine: true }})
                rightArray.push({ text: `${valueToUse}(${(Format.expressInK(insight.weightedValue))})`, 
                    options: { breakLine: true }})
                
            })
            if (geoExtraPages.length > 0) {
                const leftChunk = sliceIntoChunks(leftArray)
                const rightChunk = sliceIntoChunks(rightArray)
                slide4.addText(leftChunk[0], { x: 0.4 + geoIndex* 3, y: 1.2, fontSize: 9, valign: "top"});
                slide4.addText(rightChunk[0], { x: 2.6 + geoIndex* 3, y: 1.2, fontSize: 9, valign: "top"});
                for (let i=0; i<geoExtraPages.length; i+=1) {
                    if (leftChunk[i+1] && leftChunk[i+1].length > 0) {
                        geoExtraPages[i].addText(name, { x: 0.4 + geoIndex* 3, y: 1, fontSize: 9, valign: "top"});
                    }
                    geoExtraPages[i].addText(leftChunk[i+1], { x: 0.4 + geoIndex* 3, y: 1.2, fontSize: 9, valign: "top"});
                    geoExtraPages[i].addText(rightChunk[i+1], { x: 2.6 + geoIndex* 3, y: 1.2, fontSize: 9, valign: "top"});
                }
            } 
            else if (geoItem.audienceInsights.length === 0) {
                slide4.addText("No data found", { x: 0.4 + geoIndex* 3, y: 1.2, fontSize: 9, valign: "top"});
            } else {
                slide4.addText(leftArray, { x: 0.4 + geoIndex* 3, y: 1.2, fontSize: 9, valign: "top"});
                slide4.addText(rightArray, { x: 2.6 + geoIndex* 3, y: 1.2, fontSize: 9, valign: "top"});
            }
        })
        const slide5 = pptx.addSlide();
        slide5.addText("Demographics", { x: 0.4, y: 0.8, fontSize: 9});
        const demoExtraPages = []
        if (demoMax > 28) {
            const maxPage = Math.ceil(demoMax/28)
            for(let i=1; i<maxPage; i+=1) {
                const newSlide = pptx.addSlide();
                demoExtraPages.push(newSlide)
            }
        }
        audienceDemo.aggregatedAudienceInsights.map((demoItem, demoIndex) => {
            const name = [{ text: `${demoItem.name}`, options: { breakLine: true }}]
            const leftArray = []
            const rightArray = []
            slide5.addText(name, { x: 0.4 + demoIndex* 3, y: 1, fontSize: 9, valign: "top"});
            demoItem.audienceInsights.map(insight => {
                let valueToUse = insight.weight * 100;
                if (valueToUse > 0 && valueToUse < 1) {
                    valueToUse = '<1%'
                } else {
                    valueToUse = `${(insight.weight * 100).toFixed(0)  }%`
                }
                leftArray.push({ text: `${insight.name}`, 
                    options: { breakLine: true }})
                rightArray.push({ text: `${valueToUse}(${(Format.expressInK(insight.weightedValue))})`, 
                    options: { breakLine: true }})
                
            })
            if (demoExtraPages.length > 0) {
                const leftChunk = sliceIntoChunks(leftArray)
                const rightChunk = sliceIntoChunks(rightArray)
                slide5.addText(leftChunk[0], { x: 0.4 + demoIndex* 3, y: 1.2, fontSize: 9, valign: "top"});
                slide5.addText(rightChunk[0], { x: 2.6 + demoIndex* 3, y: 1.2, fontSize: 9, valign: "top"});
                for (let i=0; i<demoExtraPages.length; i+=1) {
                    if (leftChunk[i+1] && leftChunk[i+1].length > 0) {
                        demoExtraPages[i].addText(name, { x: 0.4 + demoIndex* 3, y: 1, fontSize: 9, valign: "top"});
                    }
                    demoExtraPages[i].addText(leftChunk[i+1], { x: 0.4 + demoIndex* 3, y: 1.2, fontSize: 9, valign: "top"});
                    demoExtraPages[i].addText(rightChunk[i+1], { x: 2.6 + demoIndex* 3, y: 1.2, fontSize: 9, valign: "top"});
                }
            } else if (demoItem.audienceInsights.length === 0) {
                slide5.addText("No data found", { x: 0.4 + demoIndex* 3, y: 1.2, fontSize: 9, valign: "top"});
            } else {
                slide5.addText(leftArray, { x: 0.4 + demoIndex* 3, y: 1.2, fontSize: 9, valign: "top"});
                slide5.addText(rightArray, { x: 2.6 + demoIndex* 3, y: 1.2, fontSize: 9, valign: "top"});
            }
        })
        const slide6 = pptx.addSlide();
        slide6.addText("Interests", { x: 0.4, y: 0.8, fontSize: 9});
        const interestExtraPages = []
        if (interestMax > 28) {
            const maxPage = Math.ceil(interestMax/28)
            for(let i=1; i<maxPage; i+=1) {
                const newSlide = pptx.addSlide();
                interestExtraPages.push(newSlide)
            }
        }
        audienceInterest.aggregatedAudienceInsights.map((audienceItem, audienceIndex) => {
            const name = [{ text: `${audienceItem.name}`, options: { breakLine: true }}]
            const leftArray = []
            const rightArray = []
            slide6.addText(name, { x: 0.4 + audienceIndex* 3, y: 1, fontSize: 9, valign: "top"});
            audienceItem.audienceInsights.map(insight => {
                let valueToUse = insight.weight * 100;
                if (valueToUse > 0 && valueToUse < 1) {
                    valueToUse = '<1%'
                } else {
                    valueToUse = `${(insight.weight * 100).toFixed(0)  }%`
                }
                leftArray.push({ text: `${insight.name}`, 
                    options: { breakLine: true }})
                rightArray.push({ text: `${valueToUse}(${(Format.expressInK(insight.weightedValue))})`, 
                    options: { breakLine: true }})
                
            })
            if (interestExtraPages.length > 0) {
                const leftChunk = sliceIntoChunks(leftArray)
                const rightChunk = sliceIntoChunks(rightArray)
                slide6.addText(leftChunk[0], { x: 0.4 + audienceIndex* 3, y: 1.2, fontSize: 9, valign: "top"});
                slide6.addText(rightChunk[0], { x: 2.6 + audienceIndex* 3, y: 1.2, fontSize: 9, valign: "top"});
                for (let i=0; i<interestExtraPages.length; i+=1) {
                    if (leftChunk[i+1] && leftChunk[i+1].length > 0) {
                        interestExtraPages[i].addText(name, { x: 0.4 + audienceIndex* 3, y: 1, fontSize: 9, valign: "top"});
                    }
                    interestExtraPages[i].addText(leftChunk[i+1], { x: 0.4 + audienceIndex* 3, y: 1.2, fontSize: 9, valign: "top"});
                    interestExtraPages[i].addText(rightChunk[i+1], { x: 2.6 + audienceIndex* 3, y: 1.2, fontSize: 9, valign: "top"});
                }
            } else if (audienceItem.audienceInsights.length === 0) {
                slide6.addText("No data found", { x: 0.4 + audienceIndex* 3, y: 1.2, fontSize: 9, valign: "top"});
            } else {
                slide6.addText(leftArray, { x: 0.4 + audienceIndex* 3, y: 1.2, fontSize: 9, valign: "top"});
                slide6.addText(rightArray, { x: 2.6 + audienceIndex* 3, y: 1.2, fontSize: 9, valign: "top"});
            }
        })
        // const allInfluencers = [...plansData.influencers, ...plansData.iqUsers]
        // const slide7 = pptx.addSlide();
        // slide7.addText("Creators", { x: 0.4, y: 0.5, fontSize: 9});
        // slide7.addText("Account", { x: 0.4, y: 0.8, fontSize: 9});
        // slide7.addText("Total Followers", { x: 2.5, y: 0.8, fontSize: 9});
        pptx.writeFile({ fileName: plansData.name });
    };
    const hasAllData = plansData && authCheckData && socials && features && audience && (unique || uniqueError)
    return (
        <div className="planDetailsContainer">
            <PleaseWaitWhite show={isLoading} />
            <ApiError show={apiError} errTitle={errTitle} error={apiError} cancelFunction={CancelError} />
            <TopNavigation current="plans" />
            <div className="contentContainer" ref={containerRef}>
                <div className="topWrapper">
                    <div className="topContainer">
                        <BreadCrumbs history={history} data={breadCrumbs} />
                        <div className="topRightSection">
                            <div className="planButtonContainer" onClick={onEdit}>
                                <span>Edit</span>
                            </div>
                            {hasAllData && (
                                <div className="planButtonContainer" onClick={exportPDF}>
                                    <span>Download PDF</span>
                                </div>
                            )}
                            {hasAllData && (
                                <div className="planButtonContainer" onClick={exportPPT}>
                                    <span>Download PPT</span>
                                </div>
                            )}
                            {!hasAllData && tabSelected === "summary" && (
                                <div>
                                    <span>Loading data...</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="tabsWrapper">
                    <div className="tabsContainer">
                        {Tabs.map((tab, index) => (
                            <div
                                key={`tabs ${index}`}
                                onClick={() => onTabChange(tab.key)}
                                className={cx('tabWrapper', { active: tab.key === tabSelected })}
                                role="button"
                            >
                                {tab.label}
                            </div>
                        ))}
                    </div>
                </div>
                {showFilters && !!brandData?.brandCompetitors.length && (
                    <CompetitiveAnalysisFilters 
                        selectedCompetitors={selectedCompetitors} 
                        setSelectedCompetitors={setSelectedCompetitors}
                        duration={duration}
                        setDuration={setDuration}
                        filters={filters}
                        setFilters={setFilters}
                        handleDataUpdate={handleDataUpdate}
                    />
                )}
                <div className="tabContentContainer">
                    {/* {plansData && authCheckData && socials && features && audience && <PDFViewer>
                    <PlanReport plansData={plansData} auth={authCheckData} socials={socials} features={features} audience={audience} unique={unique} />
                </PDFViewer>} */}
                    {tabSelected === 'creators' && (
                        <CreatorsTab
                            plansData={plansData}
                            currency={brandData?.defaultCurrencyCode || 0}
                            onChange={fetchData}
                            setSuccess={setSuccess}
                        />
                    )}
                    {tabSelected === 'summary' && plansData && (
                        <SummaryTab
                            plansData={plansData}
                            onChange={fetchData}
                            setSocials={setSocials}
                            setFeatures={setFeatures}
                            setAudience={setAudience}
                            setUnique={setUnique}
                            setUniqueError={setUniqueError}
                        />
                    )}
                    {tabSelected === 'competitiveAnalysis' && (
                        <CompetitiveAnalysisTab
                            brandData={brandData}
                            competitorsData={competitorsData}
                            competitorColors={competitorColors}
                            duration={duration}
                            onChange={fetchData}
                            setSuccess={setSuccess}
                            onBrandDataInvalidation={getBrandData}
                            isCompetitorsLoading={isLoading || isCompetitorsLoading}
                            isFiltersActive={isFiltersActive}
                        />
                    )}
                    {tabSelected === 'excluded' && <ExcludedTab plansData={plansData} onChange={fetchData} setSuccess={setSuccess} />}
                    {tabSelected === 'saved' && <SavedSearchesTab plansData={plansData} onChange={fetchData} setSuccess={setSuccess} />}
                </div>
            </div>
            <AddEditModal show={showPlanModal} data={plansData} onClose={closeModal} onSubmit={onSubmit} />
            <SuccessBanner show={showSuccess} closeFunction={closeSuccessMessage} message={successMessage} />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        lookupTables: state.global.lookupTables,
        lookupTablesLoading: state.global.lookupTablesLoading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getLookupTables: () => dispatch(startLoadingLookupTables()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlanDetails));
