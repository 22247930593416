import React from 'react'
import PulseLoader from '../pulseLoader/PulseLoader';
import './pleaseWait.scss'

/*
    props
    - show
    - container (html element; make sure position is relative)
    - progressPercentage
    - onCancel
 */

export default class PleaseWaitPartial extends React.Component {

    constructor (props, context) {
        super(props, context);
    }

    render() {
        if (!this.props.show || !this.props.container) {
            return null;
        }
        const customStyles = this.props.customStyles ? this.props.customStyles : {};
        let style={
            position: 'absolute',
            zIndex: 20000,
            top: 0,
            bottom: 0,
            width: this.props.container.clientWidth,
            height: this.props.container.clientHeight,
            backgroundColor: "rgba(255,255,255,0.5)",
            ...customStyles
        };
        return (
            <div className="pleaseWaitWhite" style={style}>
                <PulseLoader color="#4AA129" size="16px" margin="4px"/>
                {this.props.progressPercentage && <div>{this.props.progressPercentage + " %"}</div>}
                {this.props.onCancel && <div className="cancelButton" onClick={this.props.onCancel}>CANCEL</div>}
            </div>
        );
    }
}
