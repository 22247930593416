import React from "react";

export default class Contract extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        return (<div className="contract">
            contract
        </div>)
    }
}