import {
    all,
    call,
    put,
    select,
} from 'redux-saga/effects';
import Api from '../../../../modules/Api';
import {
    setData,
    setError,
} from '../actionCreators';
import {
    getCampaignAndAssignmentIdsFromRoute,
    getSelectedAssignment,
    formatAssignments,
    createEventList,
    getAssignmentOpportunitiesDetailRequestList,
    updateEventListWithDateChangeRequestEvents,
} from '../helpers';

export default function* requestInitialData() {
    const {
        assignmentId,
        campaignId,
    } = yield select(({ router }) => getCampaignAndAssignmentIdsFromRoute(router));
    // As for initial data load - there is only a single basic assignment and empty eventList.
    // But this is needed for getSelectedAssignment and createEventList work as generic functions
    const {
        currentAssignments,
        currentEventList,
        userList,
    } = yield select(({ campaignReducer }) => ({
        currentAssignments: campaignReducer.calendar.assignments,
        currentEventList: campaignReducer.calendar.eventList,
        userList: campaignReducer.calendar.userList,
    }));

    try {
        // get campaignAssignmentList from server
        const { results: campaignAssignmentList } = yield call(Api.getCampaignAssignment, campaignId);
        // assignments - available assignment filters
        // selectedAssignment - currently selected filter (single and set to the current assignment)
        const assignments = formatAssignments(campaignAssignmentList, currentAssignments);
        const selectedAssignment = getSelectedAssignment(assignments, assignmentId);

        // events to be displayed on big calendar
        let eventList = createEventList(campaignAssignmentList, assignmentId, currentEventList);

        // get userInfo and info about pending date change requests
        const requestList = getAssignmentOpportunitiesDetailRequestList(campaignAssignmentList, assignmentId);
        const assignmentOpportunityDetails = yield all(requestList);
        eventList = updateEventListWithDateChangeRequestEvents(eventList, assignmentOpportunityDetails);

        // set all data to the store
        yield put(setData({
            assignments,
            selectedAssignments: [selectedAssignment],
            campaignAssignmentList,
            eventList,
            userList: [...userList, ...assignmentOpportunityDetails],
        }));
    } catch (err) {
        console.log(err);
        yield put(setError(err.message));
    }
}
