import React from 'react';
import Select from 'react-select';
import Checkbox from '../../../shared/checkbox2022/Checkbox';
import './competitiveAnalysisFilters.scss';

export const CompetitorsFilter = ({ selectedCompetitors, setSelectedCompetitors }) => {
    const numSelectedCompetitors = selectedCompetitors.filter((competitor) => competitor.selected).length;
    const allSelected = numSelectedCompetitors === selectedCompetitors.length;

    const selectValue = allSelected
        ? { label: `All brands (${selectedCompetitors.length})` }
        : numSelectedCompetitors
        ? { label: `Selected competitors (${numSelectedCompetitors})` }
        : { label: 'No brands selected' };

    const handleClick = (option) =>
        setSelectedCompetitors((prev) =>
            prev.reduce((list, curr) => [...list, curr.value === option.value ? { ...curr, selected: !curr.selected } : curr], []),
        );

    const handleSelectAll = () => setSelectedCompetitors((prev) => prev.map((competitor) => ({ ...competitor, selected: !allSelected })));
    const getCheckboxValue = (option) => selectedCompetitors.find((competitor) => competitor.value === option.value).selected;

    const menuRenderer = (props) => (
        <div>
            <div className="filter-option" onClick={handleSelectAll}>
                <Checkbox size={16} checked={allSelected} changeFunction={handleSelectAll} controlled />
                All brands
            </div>
            <div className="line-break"></div>
            {props.options.map((option) => (
                <div className="filter-option" onClick={() => handleClick(option)} key={option.label}>
                    <Checkbox
                        size={16}
                        checked={getCheckboxValue(option)}
                        onClick={() => handleClick(option)}
                        changeFunction={() => handleClick(option)}
                        controlled
                    />
                    {option.label}
                </div>
            ))}
        </div>
    );

    const valueRenderer = (props) => (
        <div>
            <span className="filter-placeholder">Competitors:</span>
            {props.label}
        </div>
    );

    return (
        <Select
            className="comp-analysis-filter"
            searchable={false}
            value={selectValue}
            options={selectedCompetitors}
            menuRenderer={menuRenderer}
            valueRenderer={valueRenderer}
            clearable={false}
        />
    );
};
