import React, { useState } from 'react';
import './clampText.scss';

const CHAR_LIMIT = 400;

export default function ClampText({ text }) {
    const [isExpanded, setIsExpanded] = useState(false);
    const isClamped = text.length > CHAR_LIMIT;
    const adjustedText = isClamped && !isExpanded ? text.slice(0, CHAR_LIMIT).split(' ').slice(0, -1).join(' ') + '...' : text;

    return (
        <div>
            {adjustedText}
            {isClamped ? (
                isExpanded ? (
                    <span className="read-more-btn" onClick={() => setIsExpanded(false)}>
                        read less
                    </span>
                ) : (
                    <span className="read-more-btn" onClick={() => setIsExpanded(true)}>
                        read more
                    </span>
                )
            ) : null}
        </div>
    );
}
