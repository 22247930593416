class Session {



  /**
   * Saving a property/value in session storage (all values stored as string)
   * @param key
   * @param data
   */
  static set(key, data) {
    sessionStorage.setItem(key, data);
  }

  /**
   * Getting value for specified property from session storage
   * @param key
   */
  static get(key) {
    return sessionStorage.getItem(key);
  }

  /**
   * Remove given property from session storage
   * @param key
   */
  static remove(key) {
    sessionStorage.removeItem(key);
  }

}

export default Session;
export const PROP_NETWORK_NAME = "network_name";
export const PROP_CONNECTION_ERROR = "connection_error";
export const PROP_REDIRECTED = "redirected";
