export const states = ['AL', 'AK', 'AZ', 'AR',
    'CA', 'CO', 'CT', 'DE',
    'FL', 'GA', 'HI', 'ID',
    'IL', 'IN', 'IA', 'KS',
    'KY', 'LA', 'ME', 'MD',
    'MA', 'MI', 'MN', 'MS',
    'MO', 'MT', 'NE', 'NV',
    'NH', 'NJ', 'NM', 'NY',
    'NC', 'ND', 'OH', 'OK',
    'OR', 'PA', 'RI', 'SC',
    'SD', 'TN', 'TX', 'UT',
    'VT', 'VA', 'WA', 'WV',
    'WI', 'WY'].sort();
export const provinces = ['AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'NT', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT'].sort();
export const countryOptions = [{label: 'USA', value: 'USA'}, {label: 'Canada', value: 'Canada'}];


