import moment from 'moment';
import React from 'react';
import Lookup from '../../../../modules/Lookup';
import './otherDocument.scss';

const OtherDocument = ({ resource, onDelete, onDownload }) => {

    if (!resource)
        return null;

    return (
        <div key={resource.id} className="otherDocumentContainer">
            <div className="otherDocumentFileIcon">
                <img src={Lookup.getFileIcon(resource.name)} />
            </div>
            <div className="otherDocumentFileName" onClick={onDownload}>
                {resource.name}
            </div>
            <div className="otherDocumentFileInfoDate">
                {moment(resource.createdOn).format("MMM DD, YYYY")}
            </div>
            <div className="otherDocumentFileDelete">
                <img src={"/images/delete.svg"} onClick={onDelete}/>
            </div>
        </div>
    );
}

export default OtherDocument;