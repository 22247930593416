import React from 'react';
import Api from '../../../modules/Api';

/**
 * Represents a list of files with their URLs and formats.
 * @typedef {Object} FileObject
 * @property {Array<{ url: string, format: string }>} files - Array of files with their URLs and formats.
 */

/**
 * @type {FileObject}
 */
export default function DownloadLink({ files, fileName, setIsLoading, isDisabled, children }) {
    return <div onClick={() => !isDisabled && handleFileDownload(files, fileName, setIsLoading)}>{children}</div>;
}

export const handleFileDownload = async (files, fileName, setIsLoading) => {
    try {
        setIsLoading?.(true);
        const response = await Api.getCompressedResources(files);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        // Create a virtual anchor element
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = fileName;

        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);

        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error('Error downloading file:', error);
    } finally {
        setIsLoading?.(false);
    }
};
