import React, { Component } from 'react';
import cx from 'classnames';
import {
    allFavoritesName
} from '../../../store/actions/favoriteActions';

/*
  Props
  - list
  - isDuplicate  (name) => {}
  - loading
  - activateFilterFunction, saveFunction, deleteFunction  (list) => {}
 */

export default class FavoriteItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editInProgress: false,
            name: '',
            errorMessage: null,
            loading: false
        }

        this.timeout = null;
    }

    deleteList = (e) => {
        e.stopPropagation();
        this.props.deleteFunction(this.props.list);
    }

    // returns error message or null
    checkNewListName = (tx) => {
        if (tx.length < 8) {
            return 'Your List Name must be at least 8 letters'
        }
        if (tx.length >= 25) {
            return 'Maximum 25 letters'
        }
        if (this.props.isDuplicateFunction(tx)) {
            return 'Name is already in use'
        }
        return null;
    }

    inputFocus = () => {
        setTimeout(() => {
            let e = document.getElementById('editFavName');
            if (e) {
                e.focus();
            }
        }, 100)
    }

    startEdit = (e) => {
        if (this.props.list.isAll) {
            return;
        }
        e.stopPropagation();
        this.setState({
            editInProgress: true,
            name: this.props.list.name,
            errorMessage: null
        }, this.inputFocus)
    }

    renderedEmpty = () => {
        return (<div className="favoriteItemContainer empty" onClick={this.startEdit}>
            <img src="/images/ic-plus-circle-green.svg" />
            Create new list
        </div>)
    }

    nameChangeHandler = (e) => {
        let delay = 500;
        clearTimeout(this.timeout);
        let value = e.target.value;
        this.setState({
            name: value
        })
        this.timeout = setTimeout(() => {
            let errorMessage = this.checkNewListName(value);
            this.setState({
                errorMessage: errorMessage,
                canBeSaved: (value.length > 0 && !errorMessage),
            })
        }, delay)
    }

    save = (e) => {
        e.stopPropagation();
        if (!this.state.canBeSaved) {
            return;
        }
        this.props.saveFunction({ ...this.props.list, name: this.state.name });
    }

    cancelEdit = () => {
        console.log('clicked')
        this.setState({ editInProgress: false });
    }

    renderedEdit = (list) => {
        let saveClassName = "button";
        if (!this.state.canBeSaved) {
            saveClassName += " disabled";
        }
        return (<div className="listBox edited">
            <input id="editFavName" value={this.state.name} onChange={this.nameChangeHandler} maxLength={25} />
            <div className="inputError">
                {this.state.errorMessage}
            </div>
            <div className="controls">
                <div onClick={this.deleteList}>
                    <img src="/images/ic-trash.svg" />
                </div>
                <div>
                    <div className="button cancel" onClick={this.cancelEdit}>
                        Cancel
                    </div>
                </div>
                <div>
                    <div className={saveClassName} onClick={this.save}>
                        Save
                    </div>
                </div>
            </div>
            <div style={{ clear: 'both' }} />
        </div>)
    }

    activateFilter = () => {
        this.props.activateFilterFunction(this.props.list);
    }


    renderedFull = (list) => {
        const { selected } = this.props;
        let className = "listBox full";

        if (selected !== undefined) {
            className += " empty selected";
        }

        let name = list.combined ? allFavoritesName : list.name;

        return (<div className={className} onClick={() => this.activateFilter()}>
            <div className={cx("name", { selected })}>
                <span>
                    {name}
                </span>
            </div>
            <div className={cx("counter", { selected })}>
                {`${list.users.length} creator${list.users.length > 1 ? 's' : ''}`}
            </div>
            { !list.combined &&
                <div className="icons">
                    <div onClick={this.startEdit}>
                        <img src="/images/ic-edit-c6.svg" />
                    </div>
                    {/* <div onClick={this.deleteList}>
                        <img src="/images/ic-close.svg" />
                    </div> */}
                </div>
            }
        </div>)
    }

    static getDerivedStateFromProps(props, state) {
        let newState = {};
        if (state.loading && !props.loading) {
            newState.editInProgress = false;
        }
        newState.loading = props.loading;
        return newState;
    }

    render() {
        let list = this.props.list;

        return (
            <div className="favList">
                { list.empty && !this.state.editInProgress && this.renderedEmpty()}
                { list.empty && this.state.editInProgress && this.renderedEdit(list)}
                { !list.empty && !this.state.editInProgress && this.renderedFull(list)}
                { !list.empty && this.state.editInProgress && this.renderedEdit(list)}
            </div>
        )
    }

}
