import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import Modal from 'react-modal';
import './noteModal.scss';
import PlanNoteComponent from '../PlanNoteComponent';

const NoteModal = ({
    show,
    onClose = () => null,
    creator,
    notes,
    likes,
    dislikes,
    profile
}) => {
    return (
        <Modal
            isOpen={show}
            overlayClassName="modalDialog"
            className="dialog"
        >
            <div className='noteModalContainer'>
                <div className='noteModalHeader'>
                    <div className='noteModalTitle'>
                        {`${creator?.firstname ? creator.firstname + ' ' : ''}${creator?.lastname ? creator.lastname : ''}`}
                    </div>
                    <img onClick={onClose} src='/images/ic-close-new.svg' />
                </div>
                <div className='noteModalContentContainer'>
                    <PlanNoteComponent
                        noteList={notes}
                        likeList={likes}
                        dislikeList={dislikes}
                        profile={profile}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default NoteModal;