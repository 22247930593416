import cx from 'classnames';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { VIEWER_STR } from '../../../constants/authorities';
import Api from '../../../modules/Api';
import '../../shared/InfChatBox/infTabHeaders.scss';
import ApiError from '../../shared/apiError/ApiError';
import NoResults from '../../shared/noResults/NoResults';
import PleaseWaitPartial from '../../shared/pleaseWait/PleaseWaitPartial';
import './resourceComments.scss';

const pageSize = 20;
class ResourceComments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            comments: [],
            newComments: '',
            loading: false,
            editingComment: null,
            currentPage: 1,
            totalPages: 1,
        };

        this.addInputRef = React.createRef();
    }

    componentDidMount() {
        this.fetchComments();
    }

    componentDidUpdate(prevProps) {
        if (this.props.resourceId !== prevProps.resourceId) {
         this.setState({ comments: [], currentPage: 1 }, this.fetchComments);
        }
      }

    fetchComments = () => {
        const { postId, resourceId } = this.props;
        const { currentPage, comments } = this.state;

        if (!postId || !resourceId )
        return null;

        this.setState({
            loading: true
        });
        if (postId && resourceId) {
            Api.getDraftPostResourceComments(postId, resourceId, currentPage, pageSize)
                .then((result) => {
                    this.setState({
                        comments: [...comments, ...result.content],
                        loading: false,
                        totalPages: result.totalPages,
                    });
                })
                .catch((error) => {
                    this.setState({
                        showApiError: true,
                        apiError: error,
                        loading: false,
                    });
                });

        }
    }

    onSubmitButton = () => {
        const { newComments } = this.state;
        const { postId, resourceId } = this.props;
        this.setState({
            loading: true
        });
        Api.addDraftPostResourceComments({ value: newComments, userDraftPostId: postId , resourceId: resourceId})
            .then(res => {
                if (this.addInputRef && this.addInputRef.current) {
                    this.addInputRef.current.value = "";
                }

                this.setState({ newComments: '', comments: [], currentPage: 1 }, this.fetchComments);
            })
            .catch(error => {
                this.setState({
                    showApiError: true,
                    apiError: error,
                    loading: false,
                });
            })
    }

    handleChange = (e) => {
        this.setState({
            newComments: e.target.value
        })
    }

    handleDelete = (comment) => {
        this.setState({
            loading: true
        })

        Api.deleteDraftPostResourceComment(comment.id)
            .then(res => {
                this.setState({ comments: [], currentPage: 1 }, this.fetchComments);
            })
            .catch(error => {
                this.setState({
                    showApiError: true,
                    apiError: error,
                    loading: false,
                });
            })
    }

    handleEdit = (comment) => {
        this.setState({ editingComment: comment });
    }

    editingHandler = (e, comment) => {
        const update = { ...comment};
        update.value = e.target.value;
        this.setState({
            editingComment: update,
        })
    }

    cancelEdit = () => {
        this.setState({
            editingComment: null
        })
    }

    saveComments = () => {
        const { editingComment } = this.state;
        const { postId, resourceId } = this.props;

        this.setState({ loading: true });
        Api.updateDraftPostResourceComment(editingComment.id, { value: editingComment.value, userDraftPostId: postId, resourceId: resourceId })
            .then(res => {
                this.setState({ editingComment: null, comments: [], currentPage: 1 }, this.fetchComments);
            })
            .catch(error => {
                this.setState({
                    showApiError: true,
                    apiError: error,
                    loading: false,
                });
            })

    };

    renderAvatar(agent) {
        if (!agent)
            return null;

        const name = (agent.firstname && agent.firstname[0] || '') + (agent.lastname && agent.lastname[0] || '');

        return (
            <>
                {agent.pictureUrl && <img src={agent.pictureUrl} alt="" className="avatar" />}
                {!agent.pictureUrl && <div className="avatar">{name}</div>}
            </>
        );
    }

    createTime = (time) => {
        const today = moment();
        if (moment.utc(time).local().isSame(today, 'day')) {
            return `Today ${  moment.utc(time).local().format("hh:mmA")}`;
        }
        return moment.utc(time).local().format("MMM DD, YYYY hh:mmA");
    }

    loadMore = () => {
        const { currentPage } = this.state;

        this.setState({ currentPage: currentPage + 1 }, this.fetchComments);
    }

    render() {
        const { comments, newComments, loading, editingComment, currentPage, totalPages } = this.state;
        const { profile, postId, resourceId } = this.props
        const isBrandViewer = (profile && profile.role === VIEWER_STR);

        const submitEnabled = postId && resourceId && newComments && newComments.length > 0;

        return (
            <div className="resourceComments" ref={e => this.container = e}>
                <PleaseWaitPartial
                    show={loading}
                    container={this.container}
                />
                <ApiError
                    show={this.state.showApiError}
                    error={this.state.apiError}
                    cancelFunction={() => this.setState({ showApiError: false })}
                />
                <div className="resourceCommentsHeader">Media Comments</div>
                <div className="resourceCommentsAddContainer">
                    <div className="inputWrapper">
                        <input
                            placeholder="Add Comment…"
                            ref={this.addInputRef}
                            autoFocus
                            onChange={this.handleChange}
                        />
                    </div>
                    <button
                        onClick={submitEnabled ? () => this.onSubmitButton() : null}
                        className={cx("submitCommentButton", { enabled: submitEnabled && !isBrandViewer })}
                        disabled={isBrandViewer}
                        type='button'
                    >
                        Submit
                    </button>
                </div>
                <div className="resourceCommentsList">
                    {comments.length === 0 && !loading && <NoResults className="noResults">No comment available.</NoResults>}
                    {comments.map((comment, index) => {
                        const fullName = mapFullNameFromComment(comment);
                        return (
                            <div key={`resourceCommentsList${  index}`} className="resourceCommentItem">
                                {this.renderAvatar(comment.createdByAgent || comment.createdByUser)}
                                <div className="resourceCommentContent">
                                    <div className="resourceCommentContentTitle">
                                        {fullName} {this.createTime(comment.updatedOn)}
                                        <div className={cx("resourceCommentContentIcons", { displayed: isCommentOwner(comment, profile) })}>
                                            <img src="/images/ic-edit-c6.svg" onClick={() => this.handleEdit(comment)} />
                                            <img src="/images/ic-trash-grey.svg" onClick={() => this.handleDelete(comment)} />
                                        </div>
                                    </div>
                                    {editingComment && editingComment.id === comment.id ? <div className="resourceCommentContentEditing">
                                        {/* <input id="editComments"
                                            value={editingComment.value}
                                            onChange={(e) => this.editingHandler(e, editingComment)}
                                        /> */}
                                        <textarea
                                            // resize="false"
                                            placeholder="Editing"
                                            value={editingComment.value}
                                            onChange={(e) => this.editingHandler(e, editingComment)}
                                        />
                                        <div className="controls">
                                            <div>
                                                <div className="button cancel" onClick={this.cancelEdit}>
                                                    Cancel
                                                </div>
                                            </div>
                                            <div>
                                                <div className={cx("button", { disabled: false })} onClick={this.saveComments}>
                                                    Save
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        : <div className="resourceCommentContentValue">{comment.value}</div>}
                                </div>
                            </div>
                        )
                    })}
                    {currentPage < totalPages && <div className="commentsLoadMore">
                        <div className="loadMore" onClick={() => this.loadMore()}>Load more</div>
                    </div>}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.global.loggedInUserProfile,
    };
};

const mapFullNameFromComment = (comment) => {
    if (comment.createdByAgent) {
        return `${comment.createdByAgent.firstname || ''  } ${  comment.createdByAgent.lastname || ''}`;
    } else if (comment.createdByUser) {
        return `${comment.createdByUser.firstname || ''  } ${  comment.createdByUser.lastname || ''}`;
    }
    return '';
};

const isCommentOwner = (comment, profile) => {
    if (comment.createdByAgent) {
        return comment.createdByAgent.id === profile.id;
    } else if (comment.createdByUser) {
        return comment.createdByUser.id === profile.id;
    }
    return false;
};

export default connect(mapStateToProps, null)(ResourceComments);
