import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import FileSaver from "file-saver";
import style from './contractStatus.module.scss';
import Spinner from '../spinner';
import Api from '../../../../modules/Api'

const nullFunc = () => {};

export default class ContractStatus extends React.PureComponent {

    static propTypes = {
        assignmentId: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.number.isRequired,
        ]),
        signerId: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.number.isRequired,
        ]),
        uid: PropTypes.oneOfType([
            PropTypes.string.isRequired,
        ]),
        isOpen: PropTypes.bool.isRequired,
        onClose: PropTypes.func,
        contractStatusData: PropTypes.any
    };

    static defaultProps = {
        assignmentId: '',
        signerId: '',
        uid: '',
        isOpen: false,
        onClose: nullFunc
    };

    constructor(props) {
        super(props);
        this.previewTemplateRef = React.createRef();
        this.state = {
            loadingMode: false,
            loadedStatus: null,
            loadedStatusError: null,
            showGenerateContract: false,
            showGenerateContractLoading: false,
        };

        this.previewFetching = false;
    }

    componentDidMount() {
        // console.log('mounted');
        // console.log('mount');
        const {assignmentId, uid, isOpen} = this.props;
        if (isOpen && assignmentId !== '' && uid !== '') {
            this.loadStatusData(assignmentId, uid);
        }
    }

    componentDidUpdate(prevProps) {
        // console.log('did update');
        // Typical usage (don't forget to compare props):
        const {assignmentId, uid, isOpen} = this.props;
        const { loadedStatus } = this.state;
        if (isOpen) {
            if (assignmentId !== prevProps.assignmentId || uid !== prevProps.uid || !loadedStatus) {
                if (assignmentId !== '' && uid !== '' && !this.previewFetching) {
                    this.setState({loadingMode: true}, this.loadStatusData(assignmentId, uid));
                }
            }
        }
    }


    loadStatusData = (assignmentId, uid) => {
        const { isOpen } = this.props;
        const self = this;
        // only trigger load data from api if this component is open and visible
        if (isOpen && !self.previewFetching) {
            // console.log('api called');
            self.previewFetching = true;

            Api.contractEnvelopeStatus(assignmentId, uid)
                .then(
                    (res) => {

                        if(res.error) {
                            // console.log('failed res contractEnvelopeStatus', res.error);
                            this.handleLoadStatusError(res.error);
                            self.previewFetching = false;
                        } else {
                            self.previewFetching = false;
                            // console.log('success contractEnvelopeStatus', res);
                            self.setState({
                                loadingMode:false,
                                loadedStatus: res,
                                loadedStatusError: null,
                                showGenerateContract: false
                            });
                        }


                    }
                )
                .catch((err) => {
                    console.log('failed catch contractEnvelopeStatus', err);
                    this.handleLoadStatusError(err.originalMessage);
                    self.previewFetching = false;
                })
        }
    };

    handleLoadStatusError = (err) => {
        const self = this;
        const makeEnvelope = err === 'No Contract Envelope';
        const errorLabel = err === 'No Contract Envelope' ? 'No Contract Generated' : err;
        self.setState({
            loadingMode: false,
            loadedStatusError: errorLabel,
            showGenerateContract: makeEnvelope,
            loadedStatus: null,
        });
    };

    generateContract = (forced) => {
        const {assignmentId, uid, location} = this.props;
        const makeForced = !!forced;

        // console.log('generateContract attempt');
        const self = this;

        if (!self.previewFetching) {
            // console.log('api called');
            self.previewFetching = true;

            self.setState({
                showGenerateContractLoading: true,
            }, () => {
                const returnPath = (location && location.pathname) || '/';
                Api.contractGenerateContractLink(assignmentId, uid, makeForced, returnPath)
                    .then(
                        (res) => {
                            console.log('success contractGenerateContractLink', res);
                            self.previewFetching = false;
                            if(res.error) {
                                self.setState({
                                    loadingMode:false,
                                    loadedStatusError: res.error,
                                    showGenerateContract: true,
                                    showGenerateContractLoading: false,
                                });
                            } else {
                                self.setState({
                                    loadingMode:false,
                                    loadedContract: res,
                                    loadedStatusError: null,
                                    showGenerateContract: false,
                                    showGenerateContractLoading: false,
                                }, this.loadStatusData(assignmentId, uid));
                            }
                            self.previewFetching = false;
                        },
                        (err) => {
                            console.log('failed contractGenerateContractLink', err);
                            self.setState({
                                loadingMode:false,
                                loadedStatusError: err,
                                showGenerateContract: true,
                                showGenerateContractLoading: false,
                            });
                            self.previewFetching = false;
                        }
                    )
                    .catch((err) => {
                        console.log('failed contractGenerateContractLink', err);
                        self.setState({
                            loadingMode:false,
                            loadedStatusError: err,
                            showGenerateContract: true,
                            showGenerateContractLoading: false,
                        });
                        self.previewFetching = false;
                    })
            });

        }
    };

    forceGenerateContract = () => {
        this.generateContract(true);
    };

    getSigningLink = () => {
        const self = this;
        const {assignmentId, uid, location} = this.props;

        if (!self.previewFetching) {
            // console.log('api called');
            self.previewFetching = true;
            const returnPath = (location && location.pathname) || '/';

            Api.contractGenerateContractLink(assignmentId, uid, false, returnPath)
                .then(
                    (res) => {
                        // console.log('success contractGenerateContractLink', res);
                        self.setState({
                            loadingMode:false,
                            loadedContract: res,
                            loadedStatusError: null,
                            showGenerateContract: false
                        }, this.loadStatusData(assignmentId, uid));
                        if(res.result && res.result.url) {
                            window.location = res.result.url;
                        }

                        self.previewFetching = false;
                    },
                    (err) => {
                        // console.log('failed contractGenerateContractLink', err);
                        self.setState({
                            loadingMode:false,
                            loadedStatusError: err,
                            showGenerateContract: true
                        });
                        self.previewFetching = false;
                    }
                )
                .catch((err) => {
                    // console.log('failed contractGenerateContractLink', err);
                    self.setState({
                        loadingMode:false,
                        loadedStatusError: err,
                        showGenerateContract: true
                    });
                    self.previewFetching = false;
                })
        }
    };

    downloadContract = () => {
        const {assignmentId, uid} = this.props;
        Api.contractEnvelopeFile(assignmentId, uid).then(function (res) {
            // remaking blob since firefox tries to save this as docx
            const newBlob = new Blob([res], { type: "application/pdf" });
            FileSaver.saveAs(newBlob, "Contract File.pdf");
        });
    };


    render() {
        // console.log('render', 'settings');
        const { isOpen, onClose, signerId, contractStatusData} = this.props;
        const { loadingMode, loadedStatus, loadedStatusError, showGenerateContract, showGenerateContractLoading } = this.state;

        const index=loadedStatus&&loadedStatus.signers.findIndex(item=>{console.log(item.clientUserId);return String(item.clientUserId) === String(signerId)});
        // console.log('render',  selectedOptions);
        return (
            <Modal
                closeTimeoutMS={300}
                overlayClassName={style.contractOverlay}
                className={style.contractModal}
                isOpen={isOpen}
                contentLabel="Contract Status">

                <div className={style.container}>
                    <div className={style.title}>Contract Status</div>
                    <div className={style.content}>

                        {loadingMode && <Spinner label="Loading"/>}

                        {!loadingMode &&
                        <div className={style.setHero}>
                            <div><div className={style.col1}>Campaign:</div><span>{contractStatusData ? contractStatusData.campaignName : ''}</span></div>
                            <div><div className={style.col1}>Assignment:</div><span>{contractStatusData ? contractStatusData.assignmentName: ''}</span></div>
                        </div>
                        }

                        <div className={style.setDetails}>

                            {!loadingMode && loadedStatusError &&
                            <div className={style.errorLbl}>Warning: {loadedStatusError}</div>
                            }

                            {!loadingMode && showGenerateContract &&
                            <div className={style.generateBtn} onClick={this.generateContract}>Generate Contract</div>
                            }

                            {!loadingMode && localStorage.getItem('jwdebug') === 'jwu' &&
                            <div className={style.generateBtn} onClick={this.forceGenerateContract}>Re-Generate Contract [Debug Mode Only]</div>
                            }

                            {showGenerateContractLoading && <Spinner label="Generating"/>}

                            <div className={style.statusBox}>
                                {!loadingMode && !showGenerateContract && loadedStatus &&
                                loadedStatus.signers.map((item, idx) => {

                                    const role= contractStatusData.userEmail? ((contractStatusData.userEmail.toLowerCase() === item.email.toLowerCase())? 'Creator' : 'Agent'):'';
                                    return <div key={idx} className={style.statusSigner}>
                                        <div><div className={style.col1}>{role} Signee: </div><span>{item.name}</span></div>
                                        <div><div className={style.col1}>Contract Status: </div>
                                            <span style={{color:item.status==='completed'?'#0065da':item.status==='sent'?'#e5004d':'#e5004d'}}>{item.status}</span>
                                        </div>
                                    </div>;
                                })}
                            </div>


                        </div>
                    </div>
                    <div className={style.close}>
                        <img alt="close" src="/images/ic-close-b-d.svg" onClick={onClose}/>
                    </div>
                    <div className={style.footer}>
                        {!loadingMode && !showGenerateContract && loadedStatus &&
                        <div className={style.downloadFile} onClick={this.downloadContract}>Download Contract</div>
                        }
                        {!loadingMode && !showGenerateContract && loadedStatus &&
                        <div>
                            {index>-1&&(String(loadedStatus.signers[index].clientUserId) === String(signerId))&&
                            <span className={style.signlink} onClick={this.getSigningLink}>
                                {loadedStatus.signers[index].status==='completed'
                                    ?'View Contract'
                                    :'Sign Contract'
                                }
                                {/* {item.status === 'completed' ? 'View Signed Contract' : 'Sign Contract'} */}
                            </span>}
                        </div>
                        }
                    </div>
                </div>

            </Modal>
        )
    }
}
