import React from 'react';
import './style.scss';




class CurrentBrand extends React.Component {
    state = {
        isShowMoreOpen: false
    };

    handleBlur = () => {
        this.setState({isShowMoreOpen: false})
    };


    render () {
        const {brand} = this.props;
        return (
            <>
                <td>
                    <div className="user-info-holder">
                        <div className="user-info">
                            <div className="user-name">{brand.campaign}</div>
                        </div>
                    </div>
                </td>
                <td className="email">{brand.assignment}</td>
                <td className="role">{brand.owner.join(', ')}</td>
                <td className="budget">{brand.budget}</td>
                <td className="remaining">{brand.remaining}</td>
                <td className="brands">{brand.date}</td>
            </>
        )
    }
}

export default CurrentBrand;