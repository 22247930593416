import React, { useState, useRef, useEffect } from 'react';
import './loadingButton.scss';
import cx from 'classnames';

const LoadingButton = ({ isLoading, loaderStyle = '', className = '', children, ...props }) => {
    return (
        <button
            {...props}
            className={cx("loadingButtonContainer", className)}
        >
            {isLoading &&
                <div className={cx("loader", loaderStyle)}>
                    <div className="spinner"><div></div></div>
                </div>
            }
            {children}
        </button >
    )
}

export default LoadingButton;