import React from 'react';
import './noteAvatar.scss';

const NoteAvatar = ({
    firstName,
    lastName,
    picUrl,
    icon,
}) => {

    return (
        <div className='noteAvatarContainer'>
            <div className='noteAvatarWrapper'>
                {picUrl ? <img src={picUrl} />
                    : `${firstName?.charAt(0).toUpperCase() || lastName?.charAt(0).toUpperCase() || ''}`}
            </div>
            {icon && <div className='noteAvatarIcon' >{icon}</div>}
        </div>
    );
}

export default NoteAvatar;