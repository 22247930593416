import React from 'react';
import _, { cloneDeep } from 'lodash';
import { connect } from 'react-redux';

import './profileDetails.scss';
import ProfilePhoto from '../../influencerProfile/ProfilePhoto';
import Api from '../../../modules/Api';
import { storeUserProfile } from '../../../store/global/globalActions';
import { AGENT_STR, BRAND_OWNER_STR, INFLUENCER_STR, SUPER_ADMIN_STR, TALENT_MANAGER_STR, VIEWER_STR } from '../../../constants/authorities';
import ProfileHelper from '../../../modules/utils/ProfileHelper';

class ProfileDetails extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            email: '',
            firstName: '',
            lastName: '',
            imgUrl: '',
            editMode: false,
            cancel: false,
            imgFile: null,
        };
    }

    componentDidMount() {
        if (this.props.profile)
            this.setState({
                email: this.props.profile && this.props.profile.email,
                firstName: this.props.profile && this.props.profile.firstname,
                lastName: this.props.profile && this.props.profile.lastname,
                imgUrl: this.props.profile && this.props.profile.pictureUrl,
            });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.profile, this.props.profile) && this.props.profile)
            this.setState({
                email: this.props.profile.email,
                firstName: this.props.profile.firstname,
                lastName: this.props.profile.lastname,
                imgUrl: this.props.profile.pictureUrl,
            });
    }

  renderedBrand = (brand, firstBrand) => {
      return (
          <span key={brand.brandId}>
              {!firstBrand && <span>,</span>}
              {brand.brandName}
          </span>
      );
  };

  handleSave = async () => {
      const { lastName, firstName, imgFile } = this.state;
      const newProfile = cloneDeep(this.props.profile);
      newProfile.firstname = firstName;
      newProfile.lastname = lastName;
      if (imgFile) {
          try {
              const imageUpload = await Api.uploadFile(
                  imgFile,
                  false,
                  //   (percentage) => this.setState({ uploadProgressPercentage: percentage }),
                  //   (fn) => this.setState({ onUploadCancel: fn })
              );
              if (imageUpload && typeof imageUpload === 'object' && imageUpload.url) {
                  newProfile.pictureUrl = imageUpload.url;
              }
          } catch(err) {
              this.setState({
                  showApiError: true,
                  apiError: err,
              });
          }
      }
      await ProfileHelper.saveProfile(newProfile).then(
          (result) => {
              this.props.storeProfile(result);
              this.setState({
                  ...this.state,
                  imgUrl: result.pictureUrl,
                  imgFile: null
              });
          },
          (error) => {
              this.setState({
                  showApiError: true,
                  apiError: error,
              });
          },
      );
      this.setState({ editMode: false, cancel: false });
  };

  saveFileState = (url) => {
      this.setState({ imgFile: url, imgUrl: url });
  };

  handleCancel = () => {
      this.setState({
          editMode: false,
          email: this.props.profile.email,
          firstName: this.props.profile.firstname,
          lastName: this.props.profile.lastname,
          imgUrl: this.props.profile.pictureUrl,
          cancel: true,
          imgFile: null,
      });
  };

  handleEnableEdit = () => {
      this.setState({ editMode: true, cancel: false });
  };

  triggerUpload = () => {
      this.setState({
          fileUploadCounter: this.state.fileUploadCounter + 1,
      });
  };

  handleChange = (e, name) => {
      this.setState({ [name]: e.target.value });
  };

  render() {
      const { profile } = this.props;

      if (!profile) {
          return null;
      }
      const { editMode, firstName, lastName, imgUrl } = this.state;

      let classForPlate = '';
      let content = '';
      if (profile.role) {

          if (profile.role === SUPER_ADMIN_STR) {
              classForPlate = 'super-admin';
              content = 'SUPER ADMIN';
          } else if (profile.role === BRAND_OWNER_STR) {
              classForPlate = 'brand-user';
              content = 'BRAND OWNER';
          } else if (profile.role === AGENT_STR) {
              classForPlate = 'user user-agent-gradient';
              content = 'user';
          } else if (profile.role === VIEWER_STR) {
              classForPlate = 'viewer';
              content = 'viewer';
          } else if (profile.role === TALENT_MANAGER_STR) {
              classForPlate = 'talent_manager';
              content = 'Talent Manager';
          } else if (profile.role === INFLUENCER_STR) {
              classForPlate = 'talent_manager';
              content = 'Creator';
          }
      }

      return (
          <div className="profileDetails">
              <div className="featureBox">
                  {profile && profile.role === TALENT_MANAGER_STR ? (
                      <span className="img-container" onClick={this.handleEnableEdit}>
                          <img className="pencil" src="/images/ic-edit.svg" />
                      </span>
                  ) : (
                      ''
                  )}
                  <div className="boxContent">
                      <div>
                          {' '}
                          {/* picture row */}
                          <div className="label">Profile Image</div>
                          <div>
                              <div className="edit-image" onClick={this.triggerUpload}>
                                  {profile && profile.role === TALENT_MANAGER_STR ? (
                                      <ProfilePhoto
                                          cancel={this.state.cancel}
                                          updatedPhoto={this.state.imgUrl}
                                          isEdit={this.state.editMode}
                                          updatePhotoFunction={(file) => this.saveFileState(file)}
                                      />
                                  ) : (
                                      <div className="image-holder">{profile.firstname ? profile.firstname[0] : ''}</div>
                                  )}
                              </div>
                          </div>
                      </div>
                      <div>
                          {' '}
                          {/* name row */}
                          <div className="label">Full name</div>
                          {editMode ? (
                              <div className="name-holder">
                                  <input
                                      value={firstName}
                                      className="edit-input"
                                      type="text"
                                      placeholder="First name"
                                      onChange={(e) => this.handleChange(e, 'firstName')}
                                  />
                                  <input
                                      value={lastName}
                                      className="edit-input"
                                      type="text"
                                      placeholder="Last name"
                                      onChange={(e) => this.handleChange(e, 'lastName')}
                                  />
                              </div>
                          ) : (
                              <div className="editable-cell">
                                  {profile.firstname} {profile.lastname}
                              </div>
                          )}
                      </div>
                      <div>
                          {' '}
                          {/* email row */}
                          <div className="label">Email</div>
                          {/* { */}
                          {/*    editMode ? */}
                          {/*        <div> */}
                          {/*            <input value={email} className="edit-input" type="text" placeholder="Email" onChange={(e) => this.handleChange(e, 'email')}/> */}
                          {/*        </div> */}
                          {/*        : */}
                          <div className="editable-cell">{profile.email}</div>
                          {/* } */}
                      </div>
                      <div>
                          {' '}
                          {/* role row */}
                          <div className="label" style={{ verticalAlign: 'top' }}>
                              Role
                          </div>
                          <div>
                              <div className={`user-plate ${classForPlate}`}>{content}</div>
                          </div>
                      </div>
                  </div>
                  {editMode && (
                      <div className="buttons">
                          <div className="button cancel" onClick={this.handleCancel}>
                              Cancel
                          </div>
                          <div className="button save" onClick={this.handleSave}>
                              Save
                          </div>
                      </div>
                  )}
              </div>
          </div>
      );
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getLookupTables: () => {
            dispatch(startLoadingLookupTables());
        },
        storeProfile: (profile) => {
            dispatch(storeUserProfile(profile));
        },
    };
};

const mapStateToProps = (state) => {
    return {
        profile: state.global.loggedInUserProfile,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDetails);
