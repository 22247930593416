import React, { useState } from 'react';
import cx from 'classnames';
import ReachIcon from '../shared/ReachIcon/ReachIcon';
import PopUpDialogueBase from '../shared/popUpDialogueBase/PopUpDialogueBase';
import Audience from './Audience2';
import Api from '../../modules/Api';
import './CreatorDetailsTab.scss';

/**
 * for public user to request audience insights data. It also handles if already sent request before
 * and it's inProgress
 */
const PublicUserInsightsRequest = ({
    data,
    insightsData,
    status,
    closeFunction,
    sentRequestSuccess,
    sentRequestFailure,
    showNoAudienceData,
}) => {
    // const { networkSelected: data, insightsData, showPopup, status } = this.state;
    const [showPopup, setShowPopup] = useState(false);
    const [loading, setLoading] = useState(false);

    const popupMessage =
        status === 'notRequested'
            ? 'Are you sure you want to request the audience details? It will take up to 48 hours to get this information'
            : 'We have received your request and we will send out an email once we have the latest data';

    const reset = () => {
        if (closeFunction) {
            closeFunction();
        }
    };

    const onRequest = () => {
        setShowPopup(false);
        setLoading(true);

        Api.sendInsightRequest({
            networkType: data.networkType?.toUpperCase(),
            socialHandle: data.socialHandle || 'asdfsf',
        })
            .then((res) => {
                setLoading(false);
                if (sentRequestSuccess) {
                    sentRequestSuccess(res);
                }
                if (res.statusCode === 200) {
                    setShowPopup(true);
                }
            })
            .catch((err) => {
                setLoading(false);
                if (sentRequestFailure) {
                    sentRequestFailure(err);
                }
            });
    };
    const renderNoInsights = () => {
        const title = 'No Insights';
        const message = 'Insights for this profile haven’t been fetched.';
        const buttonLabel = status === 'notRequested' ? 'Request Followers Insights' : 'Request in Progress';

        return (
            <div className="noInsightsContainer">
                <div className="warningContent">
                    <img src="/images/ic-alert-gray.svg" />
                    <div className="title">{title}</div>
                    <div className="label">{message}</div>
                    <div
                        className={cx('requestInsightsButton', {
                            disabled: status === 'inProgress' || status === 'noData',
                        })}
                        onClick={() => (status === 'inProgress' ? null : setShowPopup(true))}
                    >
                        {buttonLabel}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div>
            {(status === 'notRequested' || status === 'inProgress') && (
                <div className="insightsContainer">
                    <div className="insightsHeader" onClick={reset}>
                        <img src="/images/ic-arrow-left-gray.svg" />
                        DETAILS
                    </div>
                    {data && data.networkType && (
                        <div className="insightsTitle">
                            <ReachIcon network={data.networkType?.toUpperCase()} value={1} size={15} padding={6} />
                            <div className="insightsLabelContainer">
                                {data.networkType.charAt(0).toUpperCase() + data.networkType.slice(1).toLowerCase()}{' '}
                                Audience Insights
                                {/* <span>@{data.socialHandle ? data.socialHandle : ""}</span> */}
                            </div>
                        </div>
                    )}
                    {renderNoInsights()}
                </div>
            )}
            {(status === 'noData' || status === 'dataReady' || showNoAudienceData) && (
                <Audience
                    audience={insightsData?.insightsData}
                    label="DETAILS"
                    status={status}
                    type={insightsData?.networkType ? insightsData.networkType.toLowerCase() : ''}
                    handle={insightsData?.socialHandle ? `@${insightsData.socialHandle}` : ''}
                    goBack={reset}
                />
            )}
            <PopUpDialogueBase
                show={showPopup}
                title="Request Audience Insights"
                message={popupMessage}
                hideCancel={status === 'inProgress'}
                proceedLabel={status === 'inProgress' ? 'Ok' : 'Yes'}
                proceedFunction={status === 'inProgress' ? () => setShowPopup(false) : onRequest}
                cancelFunction={() => setShowPopup(false)}
            />
        </div>
    );
};

export default PublicUserInsightsRequest;
