import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import cx from 'classnames';
import './customItem.scss';
import Lookup from '../../../../modules/Lookup'
import InputBox from './InputBox';
import InfluencerPhoto from '../../../oneInfluencerNew/InfluencerPhoto';

const createDeliverables = () => {
    const data = {};
    Lookup.getRateTypeList2().forEach(key => {
        data[key] = '';
    });
    return data;
}

export const CustomItem = ({
    profile = {},
    type = 'deliverables',
    keyId = '',
    hideDelete = false,
    editable = true,
    onDelete = () => null,
    onChange = () => null,
}) => {
    const publicOnly = {'INSTAGRAM_POST': '', 'INSTAGRAM_STORY': '', 'TIKTOK_POST': '', 'YOUTUBE': ''}
    const [totalDeliverables, setTotalDeliverables] = useState(0);
    const [deliverablesData, setDeliverablesData] = useState({});

    useEffect(() => {
        switch (type) {
        case 'deliverables':
            let dData = createDeliverables();
            if (!profile.id || profile.isPublic) {
                dData = publicOnly
            }
            setDeliverablesData({...dData, ...profile.deliverables});
            break;
        }
    }, []);

    useLayoutEffect(() => {
        const total = Object.values(deliverablesData).reduce((acc, val) => {
            const parsed = Number.parseInt(val);
            return acc + (Number.isNaN(parsed) ? 0 : parsed);
        }, 0);
        setTotalDeliverables(total);
    }, [deliverablesData]);

    const onDeliverablesChange = (val, key) => {
        const updated = { ...deliverablesData};
        updated[key] = val;
        setDeliverablesData(updated);
        onChange(updated);
    }

    return (
        <div key={keyId} className='customDeliverableItemContainer'>
            {!hideDelete && <img
                src="/images/ic-remove.svg"
                className='removeItem'
                onClick={() => onDelete()}
                alt={`removeItem_${keyId}`}
            />}
            <div className='itemCardContainer'>
                <div className='topSection'>
                    <div className='infoContainer'>
                        <InfluencerPhoto url={profile.pictureUrl} firstName={profile?.firstname} lastName={profile?.lastname}/>
                        {profile.name}
                    </div>
                    {type === 'deliverables' && <div className={cx('deliverableCount', { editable })}>Deliverables: {totalDeliverables}</div>}
                </div>
                {type === 'deliverables' && <div className={cx('bottomSection', { editable })}>
                    {Object.keys(deliverablesData).map((key, index) => {
                        const {label} = Lookup.getDeliverableType(key);
                        const value = deliverablesData[key];
                        return (
                            <div key={label + index} className='inputContainer'>
                                <InputBox newBox keyString={label + index} value={value} onChange={val => onDeliverablesChange(val, key)} label={label} />
                            </div>
                        )
                    })}
                </div>}
            </div>
        </div>
    )
}