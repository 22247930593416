import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import classNames from 'classnames/bind';
import {Scrollbars} from "react-custom-scrollbars"
import FileSaver from "file-saver"
import style from './contractManualUpload.module.scss';
import Spinner from '../spinner';
import Api from '../../../../modules/Api';
import 'filepond/dist/filepond.min.css';
import { PENDING, NEGOTIATE, ACCEPTED, CONTRACT_UPLOADED } from '../../../../constants/statusTypes';
import { GENERAL } from '../../../../constants/rateTypes';
import RateInputForInvite from "../../../influencers/invite/RateInputForInvite";
import Lookup from "../../../../modules/Lookup";
import Format from '../../../../modules/utils/Format';
import PleaseWaitPartial from "../../pleaseWait/PleaseWaitPartial"
import UploadedContract from './UploadedContract';

const cx = classNames.bind(style);
const nullFunc = () => {};
registerPlugin(FilePondPluginFileValidateType);

const debounce = (fn, delay) => {
    let timeoutId;
    return function(...args) {
        clearInterval(timeoutId);
        timeoutId = setTimeout(() => fn.apply(this, args), delay);
    };
};

export default class ContractManualUpload extends React.PureComponent {

    static propTypes = {
        assignmentId: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.number.isRequired,
        ]),
        uploader: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.number.isRequired,
        ]),
        uid: PropTypes.oneOfType([
            PropTypes.string.isRequired,
        ]),
        isOpen: PropTypes.bool.isRequired,
        onClose: PropTypes.func,
        contractStatusData: PropTypes.any,
        uploadSuccess: PropTypes.func,
    };

    static defaultProps = {
        assignmentId: '',
        signerId: '',
        uid: '',
        isOpen: false,
        onClose: nullFunc,
        uploadSuccess: nullFunc
    };

    constructor(props) {
        super(props);

        this.state = {
            assignmentUserDataLoaded: false,
            loadError: '',
            offlineUploaded: false,
            onlineSigned: false,
            allowOfflineUpload: false,
            prepareRates: {},
            preparedFile: '',
            showSaveWarning: false,
            isSavedSuccess: false,
            uploadProgressPercentage: null,
            onUploadCancel: null,
            isUploading: false,
            downloading: false,
            contractValue: null,
            currentContract: null
        };

    }

    componentDidUpdate(prevProps) {
        const { assignmentId, uid, isOpen } = this.props;
        const { assignmentUserDataLoaded } = this.state;
        if(isOpen) {
            if(assignmentId !== prevProps.assignmentId || uid !==prevProps.uid || !assignmentUserDataLoaded) {
                this.setState({assignmentUserDataLoaded: false}, this.loadAssignment);
            }
        }
    }

    loadAssignmentHandler = async (assignmentId, uid) => {
        const { contractStatusData } = this.props
        if (!contractStatusData.isPublic) {
            return Api.getAssignmentUser(assignmentId, uid)
                .then((data) => {
                    return data
                },
                (err) => {
                    console.error('Error getting assignment', err)
                    return {error: 'Error getting assignment'}
                },
                )
                .catch((err) => {
                    console.error('Unhandled Error getting assignment', err)
                    return {error: 'Unhandled Error getting assignment'}
                }
                );
        } 
        return Api.getAssignmentUserPublic(assignmentId, uid)
            .then((data) => {
                return data
            },
            (err) => {
                console.error('Error getting assignment', err)
                return {error: 'Error getting assignment'}
            },
            )
            .catch((err) => {
                console.error('Unhandled Error getting assignment', err)
                return {error: 'Unhandled Error getting assignment'}
            }
            );
        
    }

    loadContractStatusHandler = async (assignmentId, uid) => {
        const { contractStatusData } = this.props
        if (!contractStatusData.isPublic) {
            return Api.contractEnvelopeStatus(assignmentId, uid)
                .then((data) => {
                    return data
                },
                (err) => {
                    console.error('Error getting online contract status', err)
                    return {error: 'Error getting online contract status'}
                },
                )
                .catch((err) => {
                    console.error('Unhandled Error getting online contract status', err)
                    return {error: 'Unhandled Error getting online contract status'}
                }
                );
        } 
        return {}
        
    }

    loadAssignment = async () => {
        const { assignmentId, uid, contractStatusData } = this.props
        const assignmentUserData = await this.loadAssignmentHandler(assignmentId, uid);
        const onlineContractStatus = await this.loadContractStatusHandler(assignmentId, uid);
        const { error: e1, rates } = assignmentUserData;
        if(e1) {
            this.setState({
                loadError: e1,
                assignmentUserDataLoaded: true,
                preparedFile: ''
            })
        } else {
            const { signers, error: e2 } = onlineContractStatus || {};
            const signersList = !e2 && Array.isArray(signers) ? signers : [];
            const { status: taskStatus, contract } = assignmentUserData || {};
            const { status: contractStatus, signedDocumentId } = contract || {};

            /**
             * offline contract upload is determine by the following:
             *
             *  Not already uploaded once,
             *  Status is one of [PENDING, NEGOTIATE, ACCEPTED],
             *  Online docusign is not fully signed by both parties yet,
             *
             * * */

            let offlineUploaded = contractStatus === CONTRACT_UPLOADED;
            if (contractStatusData.isPublic) {
                offlineUploaded = contractStatusData.contractResourceId
            }
            const onlineSigned = signersList.length > 0 && signersList.every(s => s.status === 'completed');
            let allowOfflineUpload = false;
            if ([PENDING, NEGOTIATE, ACCEPTED].includes(taskStatus) && !offlineUploaded) {
                allowOfflineUpload = !(taskStatus === ACCEPTED && onlineSigned);
            }
            if (contractStatusData.isPublic) {
                allowOfflineUpload = true
            }

            const currentContract = assignmentUserData && assignmentUserData.contract;
            const contractValue = assignmentUserData && assignmentUserData.contract && assignmentUserData.contract.contractValue;

            this.setState({ 
                offlineUploaded, 
                onlineSigned, 
                allowOfflineUpload, 
                assignmentUserDataLoaded: true, 
                rates: rates || {}, 
                assignmentUserId: assignmentUserData.id, 
                signedDocumentId,
                currentContract: currentContract,
                contractValue: contractValue
            });
        }
    }

    handleClose = (e) => {
        const { onClose } = this.props;
        const { isSavedSuccess } = this.state;
        const triggerReload = isSavedSuccess
        this.setState({
            loadError: '',
            preparedFile: '',
            isSavedSuccess: false
        }, () => {onClose(e, triggerReload)})
    }


    handleSetRates = (type, value ) => {
        this.setState(prevState => {
            const newRates = {...(prevState.prepareRates || {})};
            newRates[type] = Number(value) || 0;
            return { prepareRates: newRates };
        });
    }

    handleSaveComplete = () => {
        this.setState({assignmentUserDataLoaded: false, isSaving: false, showSaveWarning: false, loadError: '', isSavedSuccess: true}, () => {
            const {uploadSuccess} = this.props;
            if(typeof uploadSuccess === 'function') {
                uploadSuccess();
            }
        });
    }

    debounceSave = debounce(() => {
        const { contractStatusData, assignmentId, refreshContract } = this.props;
        const { contractValue } = this.state;
        this.setState({isSaving: true}, () => {
            const { rates, prepareRates, preparedFile, assignmentUserId} = this.state;
            const mergedRates = {...rates, ...prepareRates};
            const confirmedRates = this.getConfirmAndReviewRates(mergedRates);
            if(assignmentUserId && preparedFile.id) {
                if (Object.keys(confirmedRates).length > 0 && !contractStatusData.isPublic) {
                    Api.contractOfflineUpload(assignmentUserId, preparedFile.id, confirmedRates, contractValue)
                        .then(() => {
                            this.handleSaveComplete();
                        })
                        .catch(e => {
                            console.log("contractOfflineUpload err", e);
                            this.setState({ isSaving: false, showSaveWarning: false, loadError: (e.message ? e.message : 'An error occurred. Please try again later.')})
                        })
                } else if (contractStatusData.isPublic) {
                    const body = {
                        contractResourceId: preparedFile.id,
                        status: "ACCEPTED",
                        contractValue
                    }
                    Api.inviteInfluencerUpdatePublic(assignmentId, assignmentUserId, body)
                        .then(res => {
                            contractStatusData.contractResourceId = res.contractResourceId
                            refreshContract(contractStatusData)
                            this.handleSaveComplete();
                        })
                        .catch(e => {
                            console.log("contractOfflineUpload err", e);
                            this.setState({ isSaving: false, showSaveWarning: false, loadError: (e.message ? e.message : 'An error occurred. Please try again later.')})
                        })
                }
            } else {
                this.setState({ isSaving: false, showSaveWarning: false, loadError: 'missing steps'})
            }
        })
    }, 500)

    handleSaveOfflineUpload = () => {
        this.debounceSave();
    }

    handleContractValueChange = () => {
        const { contractStatusData, assignmentId } = this.props;
        const { contractValue, assignmentUserId} = this.state;
        const handler = contractStatusData.isPublic 
            ? () => Api.inviteInfluencerUpdatePublic(assignmentId, assignmentUserId, {contractValue}) 
            : () => Api.contractValueUpdate(assignmentUserId, contractValue);
        this.setState({isSaving: true}, () => {
            handler().then((res) => {
                this.setState({ isSaving: false, currentContract: res.contract, isSavedSuccess: true });
            }).catch(e => {
                console.log("Contract Value Update Error: ", e);
                this.setState({ isSaving: false })
            })
        })

    }

    debounceDownloadFile = debounce(() => {
        const { contractStatusData, assignmentId, uid } = this.props
        const { offlineUploaded, signedDocumentId, assignmentUserId } = this.state;
        if(offlineUploaded) {
            if (!contractStatusData.isPublic && signedDocumentId) {
                this.setState({downloading: true})
                Api.contractOfflineDownloadUrl(assignmentUserId, signedDocumentId).then((res) => {
                    if(res && res.url) {
                        const updateFileProgress = (progressPercentage) => {
                            if (progressPercentage > 0) {
                                this.setState({downloadProgressPercentage: progressPercentage})
                            }
                        };
                
                        const updateFileFinished = (response) => {
                            // handle old data with no name & extensions
                            const ext = response.data && response.data.type ? Format.mimeToExt(response.data.type) : '';
                            const fileName = res.name==='File' ? `File${ext}` : res.name;
                            const url = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', fileName); // or any other extension
                            document.body.appendChild(link);
                            link.click();
                            this.setState({ downloadProgressPercentage: null, downloading: false });
                        };
                
                        updateFileProgress(1);
                        // Call gradual download
                        Api.getS3FileGradually(res.url,
                            (progressPercentage) => updateFileProgress(progressPercentage),
                            (response) => updateFileFinished(response));
            
                    }else{
                        this.setState({downloading: false, downloadProgressPercentage: null})
                    }
    
                })
                    .catch(err => {
                        this.setState({downloading: false, downloadProgressPercentage: null})
                    });
            } else if (contractStatusData.isPublic) {
                Api.contractOfflineDownloadUrlPublic(assignmentId, uid, contractStatusData.contractResourceId).then((res) => {
                    if(res && res.url) {
                        const updateFileProgress = (progressPercentage) => {
                            if (progressPercentage > 0) {
                                this.setState({downloadProgressPercentage: progressPercentage})
                            }
                        };
                
                        const updateFileFinished = (response) => {
                            // handle old data with no name & extensions
                            const ext = response.data && response.data.type ? Format.mimeToExt(response.data.type) : '';
                            const fileName = res.name==='File' ? `File${ext}` : res.name;
                            const url = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', fileName); // or any other extension
                            document.body.appendChild(link);
                            link.click();
                            this.setState({ downloadProgressPercentage: null, downloading: false });
                        };
                
                        updateFileProgress(1);
                        // Call gradual download
                        Api.getS3FileGradually(res.url,
                            (progressPercentage) => updateFileProgress(progressPercentage),
                            (response) => updateFileFinished(response));
            
                    }else{
                        this.setState({downloading: false, downloadProgressPercentage: null})
                    }
    
                })
                    .catch(err => {
                        this.setState({downloading: false, downloadProgressPercentage: null})
                    });
            }

        }
    }, 500)

    getConfirmAndReviewRates = (rates) => {
        if (!rates) return [];
        const perCampaign = Object.prototype.hasOwnProperty.call(rates, GENERAL);
        const perCampaignFilter = ([rateType, rateValue]) => rateType === GENERAL;
        const perChannelFilter = ([rateType, rateValue]) => rateType !== GENERAL;
        const filter = perCampaign ? perCampaignFilter : perChannelFilter;

        return Object.fromEntries(Object.entries(rates).filter(filter));
    };

    setOnUploadCancel = (fn, abort) => {
        const onUploadCancel = () => {
            if (fn) fn();
            if (abort) abort();
            this.setState({preparedFile: '', uploadProgressPercentage: null, onUploadCancel: null, isUploading: false});
        }
        this.setState({onUploadCancel})
    }

    renderFilepond = () => {
        return (
            <FilePond
                acceptedFileTypes="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf"
                maxFiles={1}
                server={{
                    url: '',
                    revert: (uniqueFileId, load, error) => {
                        // Should remove the earlier created temp file here
                        this.setState({preparedFile: ''});
                        // Should call the load method when done, no parameters required
                        load();
                    },
                    process: async (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
                        this.setState({ isUploading: true });
                        return Api.uploadResource(
                            file,
                            true,
                            null,
                            null,
                            (val) => progress(1, val, 100),
                            (fn) => this.setOnUploadCancel(fn, abort)
                        ).then(data => {
                            if(data && data.id) {
                                load(data.id);
                                this.setState({
                                    preparedFile: {...data, size: file.size},
                                    uploadProgressPercentage: null,
                                    onUploadCancel: null,
                                    isUploading: false
                                });
                            } else {
                                this.setState({preparedFile: '', uploadProgressPercentage: null, isUploading: false, onUploadCancel: null,});
                            }
                        }).then(() => {
                            return {
                                abort: () => {
                                    if (this.state.onUploadCancel)
                                        this.setState({ onUploadCancel: null });
                                    // This function is entered if the user has tapped the cancel button
                                    this.setState({preparedFile: '', uploadProgressPercentage: null, isUploading: false, onUploadCancel: null,});
                                    // Let FilePond know the request has been cancelled
                                    abort();
                                }
                            };
                        })
                    }
                }}
                name="file"
                labelIdle='Drag & Drop your Offline Contract File or <span class="filepond--label-action">Browse</span>'
            />
        );
    };

    renderSaveButton(saveEnabled, isContractValueChanged) {
        const { contractStatusData} = this.props;
        if (isContractValueChanged) {
            return (
                <div className='button save' onClick={this.handleContractValueChange}>
                    Save
                </div>
            );
        } else {
            return saveEnabled ? <div className='button save' onClick={contractStatusData.isPublic ? this.handleSaveOfflineUpload : () => {this.setState({showSaveWarning: true})}} >Save</div> : 
            <div className='button save disabled' >Save</div>; 
        }
    }

    render() {
        const self = this;
        const { isOpen, contractStatusData} = this.props;
        const { rates, prepareRates, preparedFile, showSaveWarning, isSaving, contractValue, currentContract } = this.state;
        const { loadError, assignmentUserDataLoaded, offlineUploaded, onlineSigned, allowOfflineUpload, downloading } = this.state;

        const taskTodo = <span className={style.guideStepTodo}><img alt="invalid step" src="/images/ic-error-red.svg" /></span>;
        const taskDone = <span className={style.guideStepDone}><img alt="valid step" src="/images/ic-success-green.svg" /></span>;
        const mergedRates = {...rates, ...prepareRates};
        const confirmedRates = this.getConfirmAndReviewRates(mergedRates)
        const mergedTotalChannel = Object.keys(confirmedRates).reduce((a, c) => a + confirmedRates[c], 0);
        const mergedChannelRatesNotZero = Object.keys(confirmedRates).every(r => confirmedRates[r] >= 0);

        const isOkayRates = mergedTotalChannel >= 0 && mergedChannelRatesNotZero;
        const isOkayUpload = preparedFile && preparedFile.id;
        const isOkayContractValue = (!!contractValue || contractValue === 0) && contractValue >= 0;
        const saveEnabled = assignmentUserDataLoaded && allowOfflineUpload && !loadError && !isSaving 
            && isOkayRates && isOkayUpload && isOkayContractValue;
        const isContractValueChanged = currentContract && currentContract.contractValue != contractValue;

        return (
            <Modal
                closeTimeoutMS={300}
                overlayClassName={style.contractOverlay}
                className={style.contractModal}
                isOpen={isOpen}
                ref={(e => this.container = e)}
                contentLabel="Upload Contract">

                <div className={style.container}>
                    <PleaseWaitPartial
                        show={downloading}
                        container={this.container}
                        progressPercentage={this.state.downloadProgressPercentage}
                        onCancel={this.state.onUploadCancel}
                    />
                    <div className={style.title}>Offline Contract</div>
                    <div className={style.content}>
                        <Scrollbars style={{ width:'100%', height: '500px' }}>
                            <div className={style.setHero}>
                                <div><div className={style.col1}>Campaign:</div><span>{contractStatusData ? contractStatusData.campaignName : ''}</span></div>
                                <div><div className={style.col1}>Assignment:</div><span>{contractStatusData ? contractStatusData.assignmentName: ''}</span></div>
                                <div><div className={style.col1}>Creator:</div><span>{contractStatusData ? `${contractStatusData.userName}`: ''}</span></div>
                            </div>
                            {!assignmentUserDataLoaded && <Spinner className={style.fade} label="Checking Creator Assignment"/>}
                            {assignmentUserDataLoaded && <div className={cx(style.setHero,style.fade)}>
                                <div><div className={style.col1}><b>Contract Status</b></div></div>
                                <div><div className={style.col1}>Signed via DocuSign:</div><span>{onlineSigned ? 'Yes' : 'No'}</span></div>
                                <div><div className={style.col1}>Contract Uploaded:</div><span>{offlineUploaded ? 'Yes' : 'No'} &nbsp;</span>{offlineUploaded ? <span>(<span className="contractDownload" onClick={this.debounceDownloadFile}>download</span>)</span> : ''}</div>
                                <div><div className={style.col1}>Upload Allowed:</div><b>{allowOfflineUpload ? 'Yes' : 'No'}</b></div>
                            </div>}

                            <div className={style.setDetails}>
                                {loadError && <div className={cx(style.errorLbl, style.fade)}>Load Error: {loadError}</div>}

                                {assignmentUserDataLoaded && !loadError && allowOfflineUpload && <div className={style.fade}>
                                    {contractStatusData.isPublic ? <div className={style.guideSteps}>
                                        <div>{(isOkayUpload || contractStatusData.contractResourceId) ? taskDone : taskTodo} 1 Upload Offline Contract</div>
                                        <div>{contractStatusData.contractResourceId ? taskDone : taskTodo} 2 Save & Confirmed</div>
                                    </div> : 
                                        <div className={style.guideSteps}>
                                        <div>{isOkayRates ? taskDone : taskTodo} 1 Edit Contract Rates</div>
                                        <div>{(isOkayUpload || contractStatusData.contractResourceId) ? taskDone : taskTodo} 2 Upload Offline Contract</div>
                                        <div>{contractStatusData.contractResourceId ? taskDone : taskTodo} 3 Save & Confirmed</div>
                                    </div>
                                    }

                                    {!contractStatusData.isPublic && <div className={style.ratesPanel}>
                                        <div className="rateDisplay">
                                            <div className="rateTotal">
                                                <div className="rateTotalLbl">Total Cost</div>
                                                <div className="rateTotalTally">${mergedTotalChannel}</div>
                                            </div>
                                            <div className="rateCost">
                                                <div className="rateCostGroup rChannel">
                                                    {Object.keys(confirmedRates).map(r => {
                                                        return <div key={r} className="rateCostBlock">
                                                            <div className="rateCostLbl">{Lookup.getRateType(r).label}</div>
                                                            <div><RateInputForInvite rateValue={(confirmedRates && confirmedRates[r]) || rates[r] || 0} changeFunction={(val) => {this.handleSetRates(r, val )} } /></div>
                                                        </div>
                                                    })}
                                                </div>
                                            </div>
                                        </div>

                                    </div>}
                                    <div style={{position: "relative"}}>
                                        <div className={style.uploadTitle}>Upload Contract</div>
                                        <div className={style.warning}>Click Save to link the uploaded contract to the assignment. The file will be lost if the window is closed without saving it.</div>
                                        {this.renderFilepond()}
                                        {(this.state.isUploading && this.state.onUploadCancel) && <div className={style.uploadCancelOverlay} onClick={this.state.onUploadCancel}/>}
                                    </div>
                                </div>}
                                { assignmentUserDataLoaded && (preparedFile || (currentContract && currentContract.resource)) &&
                                    <UploadedContract 
                                        preparedFile={preparedFile || (currentContract && currentContract.resource)} 
                                        contractValue={contractValue}
                                        onContractValueChange={(value) => this.setState({contractValue: value})}
                                        downloadFunction={this.debounceDownloadFile}/>
                                }
                            </div>
                        </Scrollbars>
                    </div>
                    <div className={style.close}>
                        {/* // header elements here */}
                    </div>
                    <div className={style.footer}>
                        {assignmentUserDataLoaded && !loadError && isSaving && <div>
                            Saving ...
                        </div>}
                        {!isSaving && <div className="button cancel" onClick={this.handleClose} >Cancel</div>}
                        {this.renderSaveButton(saveEnabled, isContractValueChanged)}
                        {assignmentUserDataLoaded && !isSaving && showSaveWarning && <div className={style.saveWarning}>
                            <div className="sWContent">
                                <div className="sWMsg">
                                    <span>Confirm</span><br/>Please confirm the rates and file you have uploaded are correct. You will not be able to make changes after saving.
                                </div>
                                <div className="sWBar">
                                    <div className="button cancel" onClick={() => {this.setState({showSaveWarning: false})}} >Cancel</div>
                                    <div className='button save' onClick={this.handleSaveOfflineUpload} >Save</div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>

            </Modal>
        )
    }
}
