import React from 'react'
import './noResults.scss'

// props : none; the blurb between the NoResults tags get displayed as message

export default class NoResults extends React.Component {

    constructor (props, context) {
        super(props, context);

        this.state = {
        }
    }

    render () {
        return (
            <div className="noResults">
                <img src="/images/ic-post.svg"/>
                <div className="message">
                    {this.props.children}
                </div>
            </div>
        )
    }
}
