import React, {useState} from 'react';
import Api from '../../modules/Api';
import Select from 'react-select';

const RenameKeywordGroupModal = ({keywordGroup, close, onUpdate}) => {
    const [isFetching, setIsFetching] = useState(false);
    const [groupName, setGroupName] = useState(keywordGroup.groupName);

    const onSubmit = () => {
        setIsFetching(true);
        const data = {
            id: keywordGroup.id,
            groupName: groupName
        };
        Api.updateKeywordGroup(data).then(creationResponse => {
            setIsFetching(false);
            close();
            onUpdate(creationResponse);
        }).catch(error => {
            setIsFetching(false);
            console.log("Error while editing keyword group: ", error);
        });
    }

    return (
        <div key={"rename-modal"} className="modalOverlay" onClick={close}>
            <div className="modalContent" onClick={event => event.stopPropagation()}>
                <div>
                    <div className="modalTitle">
                        Edit Group
                    </div>
                    <div className="label">
                        Group Name
                    </div>
                    <input className="groupNameInput" value={groupName} onChange={e => setGroupName(e.target.value)} />
                </div>
                <div id="buttons" className="buttons">
                    <div className="button" onClick={close}>
                        Cancel
                    </div>
                    <div className="button proceedButton" onClick={onSubmit}>
                        Save Name
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RenameKeywordGroupModal;
