import combineEvents from '../../combineEvents';
import {
    STATUS_SELECT_RESET,
    STATUS_SELECT_SET_DATA,
} from './actions';

export const allStatusFilter = {
    value: 'ALL',
    label: 'All statuses',
};

export const draftStatusFilter = {
    value: 'DRAFT',
    label: 'Drafts',
};

export const scheduledStatusFilter = {
    value: 'SCHEDULED',
    label: 'Scheduled',
};

export const publishedStatusFilter = {
    value: 'PUBLISHED',
    label: 'Published',
};

export const pastDueStatusFilter = {
    value: 'PAST_DUE',
    label: 'Past Due',
};

export const dateChangeRequestStatusFilter = {
    value: 'DATE_CHANGE_REQUEST',
    label: 'Date Change Request',
};

const initialState = {
    label: 'Status:',
    selectOptions: [
        allStatusFilter,
        draftStatusFilter,
        // scheduledStatusFilter,
        publishedStatusFilter,
        pastDueStatusFilter,
        dateChangeRequestStatusFilter,
    ],
    selectedOptions: [allStatusFilter],
    permissions: ['ROLE_AGENT', 'ROLE_SUPER_ADMIN', 'ROLE_BRAND_OWNER'],
};

export default combineEvents({
    [STATUS_SELECT_RESET]: (state) => ({
        ...state,
        selectedOptions: [allStatusFilter],
    }),
    [STATUS_SELECT_SET_DATA]: (state, { payload }) => ({
        ...state,
        ...payload,
    }),
}, initialState);
