import {
    OPEN_MODAL,
    CLOSE_MODAL,
} from './actions';

export const openModal = () => ({
    type: OPEN_MODAL,
    isOpen: true,
});

export const closeModal = () => ({
    type: CLOSE_MODAL,
    isOpen: false,
});
