import React from 'react'
import RateInput from '../../oneInfluencer/rateBox/RateInput'

// props
// - rateValue
// - changeFunction takes value, isValid

export default class RateInputForInvite extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            errorMessage : null,
            rateValue: this.props.rateValue
        }
    }

    rateChange = ( value, errorMessage) => {
        this.setState({rateValue : value, errorMessage : errorMessage}, this.props.changeFunction(value, !errorMessage));
    }

     render() {
        const {errorMessage} = this.state; 
        let rateClass= errorMessage?'CostRate emptyRate':'CostRate validRate';
        return (
            <div className={rateClass}>
                <RateInput
                    rateValue={this.state.rateValue}
                    inputClass="rateInput"
                    changeFunction={this.rateChange}
                />
               {errorMessage && 
                <div className="inValidNotice">
                    {errorMessage}
               </div> }
            </div>
        )
    }
}