import React, { useState, useRef, useEffect } from 'react';
import Select from 'react-select';
import { DayPickerRangeController } from 'react-dates';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './competitiveAnalysisFilters.scss';

export const DurationOptions = [
    {
        label: 'Last 6 months',
        value: {
            startDate: moment().subtract(6, 'months').format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            focusedInput: false,
        },
    },
    {
        label: 'Last 12 months',
        value: {
            startDate: moment().subtract(12, 'months').format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            focusedInput: false,
        },
    },
    {
        label: 'Custom date',
        value: {
            startDate: null,
            endDate: null,
            focusedInput: 'startDate',
            isCustom: true,
        },
    },
];

export const DurationFilter = ({ duration, setDuration }) => {
    const dateData = duration.value;
    const isCustom = duration.value.isCustom;
    const setDateData = ({ startDate, endDate, focusedInput }) => {
        if (focusedInput) {
            setDuration((prev) => ({ ...prev, value: { ...prev.value, focusedInput } }));
        } else {
            setDuration((prev) => ({ ...prev, value: { ...prev.value, startDate, endDate } }));
        }
    };

    const isValidDate = duration.value.isCustom ? dateData.startDate && dateData.endDate : true;

    const inputRef = useRef(null);
    const menuRef = useRef(null);
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const selectValue =
        duration.label === DurationOptions[0].label
            ? { label: `${duration.label}` }
            : duration.value.isCustom && dateData.startDate && dateData.endDate
            ? { label: `${dateData.startDate.format('MMM D, YYYY')} - ${dateData.endDate.format('MMM D, YYYY')}` }
            : { label: `${duration.label}` };

    useEffect(() => {
        if (!inputRef.current || !menuRef.current) return;
        const onClick = ({ target }) => menuRef.current && inputRef.current && !inputRef.current.contains(target);
        document.addEventListener('click', onClick);
        return () => document.removeEventListener('click', onClick);
    }, [inputRef.current, menuRef.current]);

    const menuRenderer = ({ options }) => (
        <div ref={menuRef}>
            {options.map((option) => (
                <div className="filter-option" onClick={() => setDuration(option)} key={option.label}>
                    <input className="radio-button" type="radio" checked={duration.label === option.label} readOnly />
                    {option.label}
                </div>
            ))}
            {isCustom && menuIsOpen && (
                <div className="date-picker">
                    <DatePicker dateData={dateData} setDateData={setDateData} />
                </div>
            )}
        </div>
    );

    const inputRenderer = (props) => (
        <div>
            <span className="filter-placeholder">Duration:</span>
            {props.label}
        </div>
    );

    return (
        <>
            <div ref={inputRef}>
                <Select
                    className={`comp-analysis-filter ${isValidDate ? '' : 'invalid-input'}`}
                    searchable={false}
                    value={selectValue}
                    options={DurationOptions}
                    menuRenderer={menuRenderer}
                    valueRenderer={inputRenderer}
                    clearable={false}
                    onOpen={() => setMenuIsOpen(true)}
                    closeOnSelect={false}
                />
            </div>
        </>
    );
};

const DatePicker = ({ dateData, setDateData }) => {
    return (
        <DayPickerRangeController
            startDate={dateData.startDate}
            endDate={dateData.endDate}
            onDatesChange={({ startDate, endDate }) => setDateData({ startDate, endDate })}
            focusedInput={dateData.focusedInput}
            onFocusChange={(focusedInput) => setDateData({ focusedInput: focusedInput ?? 'startDate' })}
            numberOfMonths={2}
            isOutsideRange={(date) => !date.isBefore(moment())}
        />
    );
};
