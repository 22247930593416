import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import rootSaga from './rootSaga';
import rootReducer from './rootReducer';
import {
    name as favoriteReducerName,
} from './reducers/favoriteReducer';
import {
    name as exclusionReducerName,
} from './reducers/exclusionReducer';

export const history = createBrowserHistory();

export default function configureStore(initialState = {
    campaign: {},
    agent: [],
    filter: {
        lookupFiltersLoading: false,
        lookupFilters: null,
    },
    global: {
        lookupTablesLoading: false,
        lookupTables: null,
        loggedInUserProfile: null,
        tenantList: null,
        profilePictureVersion: Math.round(Date.now()/(5*1000)),
    },
    chat: {
        isChatOpen: false,
    },
    [favoriteReducerName]: {
        favoriteData: [],
        favoriteLoading: false,
        favoriteError: null,
        favoriteChangeCount: 1,
    },
    [exclusionReducerName]: {
        exclusionData: [],
        exclusionLoading: false,
        exclusionError: null,
    },
}) {
    const sagaMiddleware = createSagaMiddleware();
    const middlewares = [
        thunkMiddleware,
        sagaMiddleware,
        routerMiddleware(history),
    ];
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const store = createStore(
        connectRouter(history)(rootReducer),
        initialState,
        composeEnhancers(
            applyMiddleware(...middlewares),
        ),
    );

    sagaMiddleware.run(rootSaga);

    if (module.hot) {
        module.hot.accept('../index', () => {
            const nextRootReducer = require('./rootReducer').default;
            store.replaceReducer(nextRootReducer);
        });
    }
    return store;
}
