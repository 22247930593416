import React, { useEffect, useLayoutEffect, useState, useCallback } from 'react';
import cx from 'classnames';
import './listCard.scss';
import Format from '../../modules/utils/Format';
import AddToBase from './AddToBase';
import AddToActions from './AddToActions';

const ListCard = ({
    columns,
    actions,
    onActions,
    uniqueKey,
    onClick = () => null,
    disable = () => null,
    data,
    countryList
}) => {
    const [showAction, setShowAction] = useState(false);
    const [showAddToPlan, setShowAddToPlan] = useState(false);

    const hideActions = useCallback(() => {
        setShowAction(false);
        window.removeEventListener('click', hideActions);
    }, []);

    const hideAddTo = useCallback(() => {
        setShowAddToPlan(false);
        // window.removeEventListener('click', hideAddTo);
        setTimeout(() => {
            window.addEventListener('click', hideActions);
        }, 300)
    }, []);

    const showActions = (e) => {
        e.stopPropagation();
        e.preventDefault();

        if (!showAction) {
            setShowAction(true);
            setTimeout(() => {
                window.addEventListener('click', hideActions);
            }, 300)
        } else {
            hideActions();
        }
    };

    const onAddToPlan = (e) => {
        e.stopPropagation();
        e.preventDefault();

        setShowAddToPlan(true);
        window.removeEventListener('click', hideActions);
        // setTimeout(() => {
        //     window.addEventListener('click', hideAddTo);
        // }, 300);
    };

    const onBack = () => {
        hideAddTo();
    }

    const onCardClick = (e) => {
        onClick();
    }

    const onMouseLeave = () => {
        setShowAction(false);
    }

    const handleActions = (action, e) => {
        if (action === 'plan') {
            onAddToPlan(e);
        } else {
            onActions(action);
            setShowAction(false);
        }
    }

    return (
        <div
            className={cx("listCardContainer", { disabled: disable(data) })}
            key={data.name + uniqueKey}
            onClick={() => disable(data) ? null : onCardClick()}
            onMouseLeave={onMouseLeave}
        >
            {columns.map((col, index) => {
                const countryLabel = countryList?.find(x => x.value === data[col.key].countryCode)?.label;
                return (col.flag === 'tags' ? <div
                    key={`${data[col.key]} ${index}`}
                    className={cx('listTags')}
                    style={{ width: col.width, ...col.style }}
                >
                    {data[col.key].map((tag, i) => (
                        <div key={`tags ${tag} ${i}`} className='tagContainer'>
                            {tag}
                        </div>
                    ))}
                </div> : col.flag === 'brand' ? <div
                    key={`${data[col.key]} ${index}`}
                    className='brandCellStyle'
                    style={{ width: col.width, ...col.style }}
                >
                    {data[col.key].brandImage ? <img src={data[col.key].brandImage} /> : <div className="image-holder">{data[col.key].brandName[0]}</div>}
                    <div className="planLabel">
                        <span className="brandName">{data[col.key].brandName}</span>
                        {countryLabel && <span className="country">{countryLabel}</span>}
                    </div>
                </div> : <div
                    key={`${data[col.key]} ${index}`}
                    className='textCellStyle'
                    style={{ width: col.width, ...col.style }}
                >
                    {col.flag === 'date' ? Format.formatDateAlwaysYear(data[col.key]) : data[col.key]}
                </div>)
            })}
            <div className={cx("moreButton", { selected: showAction })} onClick={showActions}>
                <img id='moreBlack' src="/images/ic-more-black.svg" />
                <img id='moreGray' src="/images/ic-more.svg" />
                {showAction && actions && <AddToActions data={actions} onActions={handleActions} />}
            </div>
            {showAddToPlan && <div className='addToPlan'>
                <AddToBase onBack={onBack} favorite={data} />
            </div>}
        </div>
    )
};

export default ListCard;