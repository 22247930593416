import React from 'react';
import Select, { components } from 'react-select-v5';
import './dateFilter.scss';

export default function DateFilter({ options, selected, setSelected }) {
    return (
        <Select
            name="date-filter"
            className="date-filter-select"
            classNamePrefix="filter"
            defaultValue={selected}
            options={options}
            isSearchable={false}
            onChange={(val) => setSelected(val)}
            theme={(theme) => ({
                ...theme,
                colors: {
                    ...theme.colors,
                    primary: 'none',
                    primary25: 'none',
                    primary75: 'none',
                },
            })}
            components={{
                IndicatorSeparator: () => null,
                Control: ({ children, ...props }) => {
                    return (
                        <components.Control {...props}>
                            <span className="filter-prefix">Date</span>
                            {children}
                        </components.Control>
                    );
                },
                Option: ({ children, ...props }) => {
                    return (
                        <components.Option {...props}>
                            {children}
                            {props.data.count && <span className="date-count">{`(${props.data.count})`}</span>}
                        </components.Option>
                    );
                },
            }}
        />
    );
}
