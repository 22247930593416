import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ReachIcon from '../../shared/ReachIcon/ReachIcon';
import { getNetworkByChannel, getNetworkNameByChannel } from './Analytics.helper';
import Format from '../../../modules/utils/Format'
import moment from "moment";

export default class AnalyticsChannelDisplay extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            expanded: false,
        };
    }

    openTab = () => {
        const { expanded } = this.state;
        this.setState({ expanded: !expanded });
    };

    render() {
        const {
            data: { reach, impressions, engagement, likes, comments, shares, saves },
            details,
            channel,
        } = this.props;
        const { expanded } = this.state;
        return (
            <div className="channelDisplay">
                <div className={cx('tableChannel', expanded && 'selected')} onClick={this.openTab}>
                    <div className="summary">
                        <span className="expandIcon">{expanded ? '-' : '+'}</span>
                        <ReachIcon network={getNetworkByChannel(channel)} value={1} size={16} padding={7} noToolTip />
                        <span>{getNetworkNameByChannel(channel)}</span>
                    </div>
                    <div className="publish"/>
                    <div className="reach">{reach ? Format.expressInK(reach) : '0'}</div>
                    <div className="views">{impressions ? Format.expressInK(impressions) : '0'}</div>
                    <div className="engagement">{engagement ? Format.expressInK(engagement) : '0'}</div>
                    <div className="likes">{likes ? Format.expressInK(likes) : '0'}</div>
                    <div className="comments">{comments ? Format.expressInK(comments) : '0'}</div>
                    <div className="shares">{shares ? Format.expressInK(shares) : '0'}</div>
                    <div className="saves">{saves ? Format.expressInK(saves) : '0'}</div>
                    <div className="postLink"/>
                </div>
                {expanded &&
                    details &&
                    details.map((detail, index) => {
                        return (
                            <div className="tableChannel" key={index}>
                                <div className="summary">
                                    <img src={detail.authorAvatar} className="profilePic" />
                                    {detail.author}
                                </div>
                                <div className="publish">{moment(detail.publishDate).format("YYYY-MM-DD")}</div>
                                <div className="reach">{detail.reach ? Format.expressInK(detail.reach) : '0'}</div>
                                <div className="views">{detail.impressions ? Format.expressInK(detail.impressions) : '0'}</div>
                                <div className="engagement">{detail.engagement ? Format.expressInK(detail.engagement) : '0'}</div>
                                <div className="likes">{detail.likes ? Format.expressInK(detail.likes) : '0'}</div>
                                <div className="comments">{detail.comments ? Format.expressInK(detail.comments) : '0'}</div>
                                <div className="shares">{detail.shares ? Format.expressInK(detail.shares) : '0'}</div>
                                <div className="saves">{detail.saves ? Format.expressInK(detail.saves) : '0'}</div>
                                <div className="postLink"><a href={detail.postUrl} target="_blank"><img src="/images/link.svg" /></a></div>
                            </div>
                        );
                    })}
            </div>
        );
    }
}

AnalyticsChannelDisplay.propTypes = {
    data: PropTypes.shape({
        comments: PropTypes.number,
        engagement: PropTypes.number,
        impressions: PropTypes.number,
        likes: PropTypes.number,
        reach: PropTypes.number,
        shares: PropTypes.number,
    }),
    details: PropTypes.arrayOf(
        PropTypes.shape({
            author: PropTypes.string,
            publishDate: PropTypes.string,
            comments: PropTypes.number,
            engagement: PropTypes.number,
            impressions: PropTypes.number,
            likes: PropTypes.number,
            reach: PropTypes.number,
            shares: PropTypes.number,
        }),
    ),
    channel: PropTypes.string.isRequired,
};

AnalyticsChannelDisplay.defaultProps = {
    data: {
        comments: 0,
        engagement: 0,
        impressions: 0,
        likes: 0,
        reach: 0,
        shares: 0,
    },
    details: [
        {
            author: "",
            publishDate: "",
            reach: 0,
            impressions: 0,
            engagement: 0,
            likes: 0,
            comments: 0,
            shares: 0
        }
    ],
};
