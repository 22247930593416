import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import './addAssignmentModal.scss';
import cx from 'classnames';
import Modal from 'react-modal';
import Select from 'react-select';
import Api from '../../modules/Api';
import ApiError from '../shared/apiError/ApiError';
import CampaignDropDown from './CampaignDropDown';
import PleaseWaitPartial from '../shared/pleaseWait/PleaseWaitPartial';
import PopUpDialogueBase from '../shared/popUpDialogueBase/PopUpDialogueBase';


const AddAssignmentModal = ({
    onClose = () => null,
    onSubmit = () => null,
    show,
    warning,
    creators,
    publicUsers,
    connectedUsers
}) => {
    let allPublic = true
    creators.forEach(item => {
        if (item.details.id) {
            allPublic = false;
        }})
    const [isLoading, setIsLoading] = useState(false);
    const [showWarning, setShowWarning] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');
    const [warningTitle, setWarningTitle] = useState('');
    const [apiError, setApiError] = useState();
    const [errTitle, setErrTitle] = useState('');
    const [validated, setValidated] = useState(false);
    const [campaignSelected, setCampaignSelected] = useState();
    const [assignments, setAssignments] = useState([]);
    const [assignmentSelected, setAssignmentSelected] = useState();
    const containerRef = useRef();
    const availableUsers = useRef();
    useLayoutEffect(() => {
        if (assignmentSelected) {
            setValidated(true);
        } else {
            setValidated(false);
        }
    }, [assignmentSelected]);

    useLayoutEffect(() => {
        if (campaignSelected && campaignSelected.assignments) {
            setAssignments(campaignSelected.assignments);
        } else {
            setAssignments([]);
        }
    }, [campaignSelected]);

    const getAssignmentOptions = () => {
        if (assignments.length > 0) {
            return assignments.filter(ab => !ab.archived).map(ab => {
                let disabled = ab.assignmentContractType === 'CONTRACT_TEMPLATE' && !ab.defaultContract;
                if (allPublic) {
                    disabled = false
                }
                return {
                    value: ab.id,
                    label: (
                        <div className={cx("assignmentOption", { disabled })}>
                            <div>{ab.name}</div>
                            {disabled && <span>Missing contract</span>}
                        </div>
                    ),
                    name: ab.name,
                    disabled
                }
            });
        } 
        return [];
        
    };

    const handleCampaignChange = (e) => {
        setCampaignSelected(e);
    };

    const handleSelectAssignments = (value) => {
        setAssignmentSelected(value);
    };

    const clearData = () => {
        setValidated(false);
        setCampaignSelected(null);
        setAssignments([]);
        setAssignmentSelected(null);
        setWarningMessage('');
        setWarningTitle('');
        setShowWarning(false);
        availableUsers.current = null;
    };

    const CancelError = () => {
        setApiError(null);
        setErrTitle('');
    };

    const handleApiError = (err) => {
        setApiError(err);
        if (err && err.originalMessage) {
            setErrTitle(err.originalMessage);
        }
    };

    const onContinue = () => {
        if (availableUsers.current && availableUsers.current.length > 0) {
            onSubmit({
                campaign: campaignSelected,
                assignment: assignmentSelected,
                creators: availableUsers.current
            });
            clearData();
        }else {
            setWarningMessage('');
            setShowWarning(false);
        }
    };

    const assignmentValidate = (assignment) => {
        const submit = () => {
            const users = assignment.users.map(u => u.user.id);
            const update = creators.filter(c => !users.includes(c.id));
            onSubmit({
                campaign: campaignSelected,
                assignment: assignmentSelected,
                creators: update.map(c => c.details)
            });
            clearData();
        };
        if (assignment && ((assignment.users && assignment.users.length > 0) || (assignment.publicUsers && assignment.publicUsers.length > 0))) {
            const users = assignment.users ? assignment.users.map(u => u.user.id) : []
            const publicUsers = assignment.publicUsers ? assignment.publicUsers.map(u => u.networkUserId) : []
            let update = creators.filter(c => !users.includes(c.id));
            update = update.filter(c => !publicUsers.includes(c.id));
            if (update.length === 0) {
                const message = <div className="favouriteDeleteMessage">
                    All creators selected are already on this assignment. Please choose another assignment or find other creators to add.
                </div>
                setShowWarning(true);
                setWarningTitle('All caught up');
                setWarningMessage(message);
            } else if (update.length < creators.length) {
                const existing = creators.filter(c => users.includes(c.id) || publicUsers.includes(c.id));
                availableUsers.current = update.map(u => u.details);
                const names = existing.reduce((acc, e, i) => {
                    acc += `${i > 0 ? ', ' : ''}${e.details.firstname ? e.details.firstname : ''} ${e.details.lastname ? e.details.lastname : ''}`;
                    return acc;
                }, '');
                const message = <div className="favouriteDeleteMessage">
                    {`Creator${existing.length > 1 ? 's' : ''} "`}
                    <span>{names}</span>
                    {`" already exist${existing.length === 1 ? 's' : ''} in this assignment. We will continue without adding the creator${existing.length > 1 ? 's' : ''} to assignment.`}
                </div>
                setShowWarning(true);
                setWarningTitle('Existing creators');
                setWarningMessage(message);
            } else {
                submit();
            }
        } else {
            submit();
        }
    };

    const handleSubmit = () => {
        setIsLoading(true);
        Api.getAssignment(assignmentSelected.value)
            .then(res => {
                setIsLoading(false);
                assignmentValidate(res);
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false);
                handleApiError(err);
            })
    };

    const onCancel = () => {
        clearData();
        onClose();
    };
    let messageText = `Adding `
    if (publicUsers.length > 0) {
        messageText += `${publicUsers.length} public creator${publicUsers.length > 1 ? "s" : ''} `
    }
    if (publicUsers.length > 0 && connectedUsers.length > 0) {
        messageText += "and "
    }
    if (connectedUsers.length > 0) {
        messageText += `${connectedUsers.length} connected creator${connectedUsers.length > 1 ? "s" : ''} `
    }
    messageText += " to..."
    return (
        <Modal
            isOpen={show}
            overlayClassName="modalDialog"
            className="dialog"
            ref={containerRef}
        >
            <div
                className='addAssignmentContainer'
                id='addAssignmentContainer'
            >
                <PleaseWaitPartial
                    show={isLoading}
                    container={containerRef.current}
                    customStyles={{ height: 'none' }}
                />
                <ApiError
                    show={apiError}
                    errTitle={errTitle}
                    error={apiError}
                    cancelFunction={CancelError}
                />
                <div className='addAssignmentModalHeader'>
                    Add to assignment
                </div>
                <div className='addAssignmentModalContent'>
                    {warning && <div className='warningContainer'>
                        <img src="/images/ic-warning-new.svg" />
                        <span>{warning}</span>
                    </div>}
                    <div className='messageText'>{messageText}</div>
                    <div className='labelContainer'>
                        Campaign
                    </div>
                    <div className='addAssignmentInputWrapper'>
                        <CampaignDropDown
                            onChange={handleCampaignChange}
                        />
                    </div>
                    <div className='labelContainer'>
                        Assignment
                    </div>
                    <div className='addAssignmentInputWrapper'>
                        <Select
                            className="select"
                            clearable={false}
                            searchable
                            value={assignmentSelected}
                            // multi
                            disabled={!campaignSelected}
                            placeholder="Select"
                            options={getAssignmentOptions()}
                            onChange={(...args) => handleSelectAssignments(...args)}
                        />
                    </div>
                </div>
                <div className='addAssignmentFooter'>
                    <div className='addAssignmentFooterCancelButton' onClick={onCancel}>Cancel</div>
                    <div
                        className={cx('addAssignmentFooterAddButton', { disabled: !validated })}
                        onClick={() => validated ? handleSubmit() : null}>
                        Add
                    </div>
                </div>
                <PopUpDialogueBase
                    title={warningTitle}
                    show={showWarning}
                    windowStyle={{ padding: 20 }}
                    icon={<img size={34} src="/images/ic-warning-new.svg" />}
                    message={warningMessage}
                    cancelFunction={onContinue}
                    cancelLabel="Continue"
                    // proceedLabel="Continue"
                    loading={isLoading}
                    hideProceed
                // proceedButtonStyle={{ backgroundColor: '#d90036', width: 100 }}
                // cancelButtonStyle={{ width: 100 }}
                // proceedFunction={deleteFavourite}
                />
            </div>
        </Modal>
    )
}

export default AddAssignmentModal;
