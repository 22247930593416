import React, { useState, useRef } from 'react';
import cx from 'classnames';
import './inviteSteps.scss';

export const InviteSteps = ({ data, selected }) => {
    return (
        <div className='inviteStepsContainer'>
            {data && data.length > 0 && data.map((item, index )=> {
                const active = index <= selected;
                return <div key={item.label} className={cx('stepContainer', { active })}>
                    <div className={cx('label', { active })}>{item.label}</div>
                </div>
            })}
        </div>
    )
}