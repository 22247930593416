import React from 'react';
import { Page, Text, View, Image, Document, StyleSheet, Font, Link } from '@react-pdf/renderer';
import moment from "moment";
import { getNetworkNameByChannel } from './Analytics.helper';
import Format from '../../../modules/utils/Format'
import { formatPercentageToWhole } from '../../../modules/utils/helpers';

// Register font
// Font.register({
//     family: "Nunito Sans",
//     src: "/fonts/NunitoSans-Regular.ttf",
// });
// Font.registerEmojiSource({
//     format: 'png',
//     url: 'https://twemoji.maxcdn.com/2/72x72/',
// });

// Create styles
const styles = StyleSheet.create({
    page: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#ffffff',
        width: '100%',
        height: '100%',
        paddingBottom: 20,
    },
    section: {
        marginTop: 20,
        marginLeft: 30,
        marginRight: 30,
    },
    sectionTitle: {
        height: 20,
        color: '#121212',
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    totalPerception: {
        height: 20,
        color: '#121212',
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 10,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    infoLine: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: '#121212',
        fontSize: 14,
        lineHeight: '24px',
    },
    infoLineCampaign: {
        height: 24,
        color: '#121212',
        fontSize: 16,
        fontWeight: 'bold',
    },
    infoLineAssignment: {
        backgroundColor: '#f7f7f7',
        paddingVertical: 3,
        paddingHorizontal: 3,
    },
    campaignSummary: {
        display: 'flex',
        flexDirection: 'row'
    },
    campaignSummaryLeft: {
        width: 'calc(50% - 5px)',
        borderRadius: 3,
        backgroundColor: '#00998C',
        paddingVertical: 5,
        paddingHorizontal: 20,
        color: '#ffffff',
        fontSize: 18,
        display: 'flex',
        flexDirection: 'column',
    },
    campaignSummaryLeftBox: {
        borderRadius: 3,
        backgroundColor: '#00998C',
        color: '#ffffff',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
        marginBottom: 15,
        paddingVertical: 10,
        paddingHorizontal: 20,
        fontSize: 14,
    },
    campaignSummaryLeftBoxValue: {
        marginTop: 5,
        fontSize: 20,
        fontWeight: 'bold',
    },
    campaignSummaryRight: {
        width: 'calc(50% - 5px)',
        marginLeft: 5,
        borderRadius: 3,
        backgroundColor: '#00998C',
        paddingVertical: 5,
        paddingHorizontal: 20,
        color: '#ffffff',
        fontSize: 18,
        display: 'flex',
        flexDirection: 'column',
    },
    campaignSummaryRightBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#DDD',
        height: 50,
        justifyContent: 'space-between'
    },
    campaignSummaryRightBoxLabel: {
        fontWeight: 'normal',
        fontSize: 14
    },
    topPost: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    topPostCard: {
        width: '33%',
        borderWidth: 1,
        boderColor: '#e7e7e7',
        borderRadius: 3,
        paddingTop: 10,
        paddingBottom: 15,
        paddingHorizontal: 15,
    },
    topPostCardUpper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#d8d8d8',
        marginBottom: 15,
        color: '#414141',
        fontSize: 14,
        fontWeight: 'bold',
    },
    topPostCardLower: {
        display: 'flex',
        flexDirection: 'column'
    },
    topPostCardLowerImg: {
        width: 80,
        height: 80,
        objectFit: 'cover',
        borderRadius: 3,
        marginRight: 10,
    },
    topPostCardLowerText: {
        width: 'calc(100% - 90px)',
        color: '#121212',
        fontSize: 14,
        height: 140,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        ellipsis: true
    },
    topPostCardLowerText2: {
        color: '#121212',
        fontSize: 14,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        ellipsis: true,
        marginBottom: 10,
        marginRight:10
    },
    link: {
        color: '#121212',
        fontSize: 14,
    },
    topPostCardLowerLabel: {
        textTransform: 'capitalize',
        opacity: 0.5,
        fontSize: 12,
        marginTop: 5,
    },
    topPostCardLowerLabel2: {
        textTransform: 'capitalize',
        opacity: 0.5,
        fontSize: 12,
        marginTop: 5,
        marginRight: 10
    },
});
const channelListStyles = StyleSheet.create({
    postList: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: 11,
        color: '#121212',
    },
    postSingle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 30,
        borderWidth: 1,
        borderColor: '#e7e7e7',
        borderRadius: 3,
        marginTop: 8,
        fontSize: 12,
        paddingHorizontal: 5,
    },
    postImage: {
        height: 15,
        width: 15,
        borderRadius: 15,
        marginRight: 5,
        objectFit: 'cover',
    },
    postContent: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
    summary: {
        width: '23.1%',
        flex: 1,
    },
    publish: {
        width: '10.9%',
    },
    reach: {
        width: '10.9%',
    },
    views: {
        width: '10.9%',
    },
    engagement: {
        width: '10.9%',
    },
    likes: {
        width: '10.9%',
    },
    comments: {
        width: '10.9%',
    },
    shares: {
        width: '10.9%',
    },
    share: {
        width: '10.9%',
    },
    saves: {
        width: '10.9%',
    },
});
const infListStyles = StyleSheet.create({
    postList: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: 11,
        color: '#121212',
    },
    postSingle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 30,
        borderWidth: 1,
        borderColor: '#e7e7e7',
        borderRadius: 3,
        marginTop: 8,
        fontSize: 12,
        paddingLeft: 5,
    },
    postImage: {
        height: 15,
        width: 15,
        borderRadius: 15,
        marginRight: 5,
        objectFit: 'cover',
    },
    postContent: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
    influencer: {
        width: '25.3%',
        flex: 1,
    },
    facebook: { width: '12.6%' },
    igPost: { width: '11.9%' },
    igStories: { width: '12.7%' },
    twitter: { width: '12.3%' },
    youtube: { width: '12.7%' },
    reach: { width: '12.5%' },
    reachBody: {
        backgroundColor: '#f0f0f0',
        paddingLeft: 10,
        height: 30,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
});
const postListStyles = StyleSheet.create({
    postList: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: 11,
        color: '#121212',
    },
    postSingle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 60,
        borderWidth: 1,
        borderColor: '#e7e7e7',
        borderRadius: 3,
        marginTop: 8,
        fontSize: 12,
        paddingHorizontal: 5,
    },
    postImage: {
        height: 40,
        width: 40,
        borderRadius: 3,
        marginRight: 5,
        objectFit: 'cover',
    },
    postContent: { display: 'flex', flexDirection: 'row', alignItems: 'center' },
    post: {
        width: '6%',
        flex: 1,
    },
    channel: {
        width: '7.6%',
    },
    publish: {
        width: '9.3%',
    },
    reach: {
        width: '6.6%',
    },
    views: {
        width: '6.2%',
    },
    engagement: {
        width: '7.3%',
    },
    likes: {
        width: '6%',
    },
    comments: {
        width: '6.9%',
    },
    shares: {
        width: '6.2%',
    },
    influencer: {
        width: '7.6%',
    },
    newshares: {
        width: '6.2%'
    },
    saves: {
        width: '6.2%'
    },
    brandedengagement: {
        width: '6.2%'
    },
    brandedengagementpercent: {
        width: '10.2%'
    }
});

const renderInfo = (info) => {
    return (
        <View>
            <View style={styles.infoLine}>
                <Text style={styles.infoLineCampaign}>Campaign: {info.campaignName || ''}</Text>
            </View>
            <View style={styles.infoLine}>
                <Text>Assignments: </Text>
                {info.assignments.map((item) => (
                    <Text key={item} style={styles.infoLineAssignment}>
                        {item}
                    </Text>
                ))}
            </View>
            <View style={styles.infoLine}>
                <Text>Post Dates: </Text>
                <Text>
                    {info.startDate} - {info.endDate}
                </Text>
            </View>
        </View>
    );
};

// Overview Report
export const OverviewReport = (data) => {
    const { overviewData, info, channel, influencer, post, pieimg, overviewDataPost, assignmentSummaryData, totalPerception  } = data;
    const { topReach, topViews, topEngagements } = post;
    const { topPosts, views, engagements } = overviewDataPost;
    return (
        <Document>
            <Page size="A4" orientation="landscape" style={styles.page}>
                <View style={styles.section}>{renderInfo(info)}</View>
                <View style={styles.section}>
                    <Text style={styles.sectionTitle}>Assignment Summary</Text>
                    <View style={styles.campaignSummary}>
                        <View style={styles.campaignSummaryLeft}>
                        <View style={styles.campaignSummaryRightBox}>
                                <Text style={styles.campaignSummaryRightBoxLabel}>Cost Per Engagement</Text>
                                <Text>{overviewData.costPerEngagement ? `$${overviewData.costPerEngagement}` : "$0"}</Text>
                            </View>
                            <View style={styles.campaignSummaryRightBox}>
                                <Text style={styles.campaignSummaryRightBoxLabel}>Engagement</Text>
                                <Text>{overviewData.engagements ? `${overviewData.engagements}` : 'N/A'}</Text>
                            </View>
                            <View style={styles.campaignSummaryRightBox}>
                                <Text style={styles.campaignSummaryRightBoxLabel}>Engagement Rate</Text>
                                <Text>{overviewData.engagementRate
                                            ? `${Math.round(overviewData.engagementRate)}%`
                                            : 'N/A'}</Text>
                            </View>
                            <View style={styles.campaignSummaryRightBox}>
                                <Text style={styles.campaignSummaryRightBoxLabel}>Total cost</Text>
                                <Text>${Format.numberWithCommas(overviewData.totalCost)}</Text>
                            </View>
                            <View style={[styles.campaignSummaryRightBox, { borderBottomWidth: 0 }]}>
                                <Text style={styles.campaignSummaryRightBoxLabel}>Creators Assigned / Published Posts</Text>
                                <Text>{overviewData.influencersAssigned || 0} / {overviewData.influencersPosted || 0}</Text>
                            </View>
                        </View>
                        <View style={styles.campaignSummaryRight}>
                            <View style={styles.campaignSummaryRightBox}>
                                <Text style={styles.campaignSummaryRightBoxLabel}>Followers</Text>
                                <Text>{overviewData.totalFollowerCount ? `${overviewData.totalFollowerCount}` : 'N/A'}</Text>
                            </View>
                            <View style={styles.campaignSummaryRightBox}>
                                <Text style={styles.campaignSummaryRightBoxLabel}>Views</Text>
                                <Text>{overviewData.views ? `${overviewData.views}` : 'N/A'}</Text>
                            </View>
                            <View style={styles.campaignSummaryRightBox}>
                                <Text style={styles.campaignSummaryRightBoxLabel}>View Rate</Text>
                                <Text>{overviewData.views && overviewData.totalFollowerCount ? `${formatPercentageToWhole((overviewData.views/overviewData.totalFollowerCount)*100)}%` : 'N/A'}</Text>
                            </View>
                            <View style={[styles.campaignSummaryRightBox, { borderBottomWidth: 0 }]}>
                                <Text style={styles.campaignSummaryRightBoxLabel}>Engagement Rate by Views</Text>
                                <Text>{(overviewData.engagements && overviewData.views) ? `${formatPercentageToWhole((overviewData.engagements/overviewData.views)*100)}%` : '-'}</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </Page>
            <Page size="A4" orientation="landscape" style={styles.page}>
                <View style={styles.section}>
                    <View style={styles.campaignSummary}>
                        <View style={styles.campaignSummaryLeft}>
                        <View style={styles.campaignSummaryRightBox}>
                                <Text style={styles.campaignSummaryRightBoxLabel}>Shares</Text>
                                <Text>{assignmentSummaryData.shares ? `${assignmentSummaryData.shares}` : "N/A"}</Text>
                            </View>
                            <View style={styles.campaignSummaryRightBox}>
                                <Text style={styles.campaignSummaryRightBoxLabel}>Saves</Text>
                                <Text>{assignmentSummaryData.saves ? `${assignmentSummaryData.saves}` : 'N/A'}</Text>
                            </View>
                            <View style={styles.campaignSummaryRightBox}>
                                <Text style={styles.campaignSummaryRightBoxLabel}>Branded Comments</Text>
                                <Text>{assignmentSummaryData.brandedComments ? `${assignmentSummaryData.brandedComments}` : 'N/A'}</Text>
                            </View>
                            <View style={[styles.campaignSummaryRightBox, { borderBottomWidth: 0 }]}>
                                <Text style={styles.campaignSummaryRightBoxLabel}>Branded Engagements</Text>
                                <Text>{assignmentSummaryData.brandedEngagements ? `${assignmentSummaryData.brandedEngagements}` : 'N/A'}</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </Page>
            <Page size="A4" orientation="landscape" style={styles.page}>
                <View style={styles.section} wrap={false}>
                    <Text style={styles.sectionTitle}>Channel Summary</Text>
                    <Image src={channel ? channel.img : ''} cache={false} />
                </View>
                {/* <View style={styles.section} wrap={false}>
                    <Text style={styles.sectionTitle}>Creator’s total followers</Text>
                    <Image src={influencer ? influencer.img : ''} cache={false} />
                </View> */}
                <View style={styles.section} wrap={false}>
                    <Text style={styles.sectionTitle}>Overall Perception</Text>
                    <Image src={pieimg ? pieimg.img : ''} cache={false} />
                    <Text style={styles.totalPerception}>{totalPerception} total mentions</Text>
                </View>
                <View style={styles.section} wrap={false}>
                    <Text style={styles.sectionTitle}>Top Posts</Text>
                    <View style={styles.topPost}>
                        <View style={styles.topPostCard}>
                            <View style={styles.topPostCardUpper}>
                                <View style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Image
                                        src="/images/exportImg/ic_eye_a8.png"
                                        style={{ width: 16, marginRight: 5 }}
                                    />
                                    <Text>Views</Text>
                                </View>
                                <Text>{(topViews && topViews.views) || '0'}</Text>
                            </View>
                            <View style={styles.topPostCardLower}>
                                {topViews ? (<View style={{ display: 'flex', flexDirection: 'row' }}>
                                        <View style={styles.topPostCardLowerText2}>
                                            <Text style={[styles.topPostCardLowerLabel, {marginTop: 0}]}>By: {topViews.influencerName}</Text>
                                            <Text style={styles.topPostCardLowerLabel}>
                                                {getNetworkNameByChannel(topViews.channel)}
                                            </Text>
                                        </View>
                                        <Link src={topViews.url} style={styles.link}>View Here</Link>
                                    </View>) : (
                                    <View style={styles.topPostCardLowerText}>
                                        <Text>No post yet!</Text>
                                    </View>
                                )}
                            </View>
                        </View>
                        <View style={styles.topPostCard}>
                            <View style={styles.topPostCardUpper}>
                                <View style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Image
                                        src="/images/exportImg/ic-thread-d.png"
                                        style={{ width: 16, marginRight: 5 }}
                                    />
                                    <Text>Engagement</Text>
                                </View>
                                <Text>{topEngagements && topEngagements.engagement || '0'}</Text>
                            </View>
                            <View style={styles.topPostCardLower}>
                                {topEngagements ? (
                                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                                        <View style={styles.topPostCardLowerText2}>
                                            <View style={{ display: 'flex', flexDirection: 'row', alignItems: "flex-start" }}>
                                                <View style={{display: 'flex', flexDirection: 'column'}}>
                                                    <Text style={[styles.topPostCardLowerLabel, {marginTop: 0}]}>By: {topEngagements.influencerName}</Text>
                                                    <Text style={styles.topPostCardLowerLabel2}>
                                                        {getNetworkNameByChannel(topEngagements.channel)}
                                                        {topEngagements.postType && `(${topEngagements.postType.toLowerCase()})`}
                                                    </Text>
                                                </View>
                                                <Link src={topEngagements.url} style={styles.link}>View Here</Link>
                                            </View>
                                            <Text style={{marginTop: '5px'}}>{topEngagements.name}</Text>
                                        </View>
                                    </View>
                                ) : (
                                    <View style={styles.topPostCardLowerText}>
                                        <Text>No post yet!</Text>
                                    </View>
                                )}
                            </View>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

// ByChannel Report
export const ByChannelReport = (data) => {
    const { dataByChannel, info, channel } = data;
    const { summary, details } = dataByChannel;

    const summaryKeys = summary ? Object.keys(summary) : [];
    return (
        <Document>
            <Page size="A4" orientation="landscape" style={styles.page}>
                <View style={styles.section}>{renderInfo(info)}</View>
                <View style={styles.section}>
                    <Text style={styles.sectionTitle}>Channel Summary</Text>
                    <Image src={channel ? channel.img : ''} cache={false} />
                </View>

                <View style={styles.section}>
                    <View style={channelListStyles.postList}>
                        <Text style={channelListStyles.summary}>Channel Summary</Text>
                        <Text style={channelListStyles.publish}>Submitted Date</Text>
                        <Text style={channelListStyles.reach}>Followers</Text>
                        <Text style={channelListStyles.views}>Views</Text>
                        <Text style={channelListStyles.engagement}>Engagement</Text>
                        <Text style={channelListStyles.likes}>Likes</Text>
                        <Text style={channelListStyles.comments}>Comments</Text>
                        <Text style={channelListStyles.share}>Shares</Text>
                        <Text style={channelListStyles.saves}>Saves</Text>
                    </View>
                    <View>
                        {summaryKeys &&
                            summaryKeys.map((media) => {
                                const channelData = summary[media];
                                const channelDetails = details[media];
                                const { reach, impressions, engagement, likes, comments, shares, saves } = channelData;
                                return (
                                    <View key={media}>
                                        <View style={channelListStyles.postSingle} wrap={false}>
                                            <View style={[channelListStyles.summary, channelListStyles.postContent]}>
                                                <Text style={{ width: 10 }}>+</Text>
                                                <Text style={{ textTransform: 'capitalize' }}>
                                                    {getNetworkNameByChannel(media)}
                                                </Text>
                                            </View>
                                            <Text style={channelListStyles.publish} />
                                            <Text style={channelListStyles.reach}>{reach || '0'}</Text>
                                            <Text style={channelListStyles.views}>{impressions || '0'}</Text>
                                            <Text style={channelListStyles.engagement}>{engagement || '0'}</Text>
                                            <Text style={channelListStyles.likes}>{likes || '0'}</Text>
                                            <Text style={channelListStyles.comments}>{comments || '0'}</Text>
                                            <Text style={channelListStyles.share}>{shares || '0'}</Text>
                                            <Text style={channelListStyles.saves}>{saves || '0'}</Text>
                                        </View>
                                        {channelDetails &&
                                            channelDetails.map((item, index) => {
                                                return (
                                                    <View key={index} style={channelListStyles.postSingle} wrap={false}>
                                                        <View
                                                            style={[
                                                                channelListStyles.summary,
                                                                channelListStyles.postContent,
                                                            ]}
                                                        >
                                                            <Text style={{ width: 10 }} />
                                                            <Text>{item.author}</Text>
                                                        </View>
                                                        <Text style={channelListStyles.publish}>
                                                            {item.publishDate ? moment(item.publishDate).format("YYYY-MM-DD") : '-'}
                                                        </Text>
                                                        <Text style={channelListStyles.reach}>{item.reach || '0'}</Text>
                                                        <Text style={channelListStyles.views}>
                                                            {item.impressions || '0'}
                                                        </Text>
                                                        <Text style={channelListStyles.engagement}>
                                                            {item.engagement || '0'}
                                                        </Text>
                                                        <Text style={channelListStyles.likes}>{item.likes || '0'}</Text>
                                                        <Text style={channelListStyles.comments}>
                                                            {item.comments || '0'}
                                                        </Text>
                                                        <Text style={channelListStyles.share}>
                                                            {item.shares || '0'}
                                                        </Text>
                                                        <Text style={channelListStyles.saves}>
                                                            {item.saves || '0'}
                                                        </Text>
                                                    </View>
                                                );
                                            })}
                                    </View>
                                );
                            })}
                    </View>
                </View>
            </Page>
        </Document>
    );
};

// ByInfluencer Report
export const ByInfluencerReport = (data) => {
    const { dataByInfluencer, info, influencer } = data;
    const { influencers } = dataByInfluencer;
    return (
        <Document>
            <Page size="A4" orientation="landscape" style={styles.page}>
                <View style={styles.section}>{renderInfo(info)}</View>
                <View style={styles.section}>
                    <Text style={styles.sectionTitle}>Top Creators</Text>
                    <Image src={influencer ? influencer.img : ''} cache={false} />
                </View>
                <View style={styles.section}>
                    <View style={infListStyles.postList}>
                        <Text style={infListStyles.influencer}>Creator</Text>
                        <Text style={infListStyles.facebook}>Facebook</Text>
                        <Text style={infListStyles.igPost}>IG Post</Text>
                        <Text style={infListStyles.igStories}>IG Stories</Text>
                        <Text style={infListStyles.twitter}>Twitter</Text>
                        <Text style={infListStyles.youtube}>YouTube</Text>
                        <Text style={infListStyles.reach}>Total Followers</Text>
                    </View>
                    <View>
                        {influencers &&
                            influencers.map((item, index) => (
                                <View key={index} style={infListStyles.postSingle} wrap={false}>
                                    <View style={infListStyles.influencer}>
                                        <Text>{item.name}</Text>
                                    </View>
                                    <Text style={infListStyles.facebook}>{item.facebookReach || '0'}</Text>
                                    <Text style={infListStyles.igPost}>{item.instagramReach || '0'}</Text>
                                    <Text style={infListStyles.twitter}>{item.twitterReach || '0'}</Text>
                                    <Text style={infListStyles.youtube}>{item.youtubeReach || '0'}</Text>
                                    <View style={[infListStyles.reach, infListStyles.reachBody]}>
                                        <Text>{item.totalReach || '0'}</Text>
                                    </View>
                                </View>
                            ))}
                    </View>
                </View>
            </Page>
        </Document>
    );
};

// ByPost Report
export const ByPostReport = (data) => {
    const { info, post } = data;
    const { topReach, topViews, topEngagements, posts } = post;
    console.log(posts)
    return (
        <Document>
            <Page size="A4" orientation="landscape" style={styles.page}>
                <View style={styles.section}>{renderInfo(info)}</View>
                <View style={styles.section}>
                    <Text style={styles.sectionTitle}>Top Posts</Text>
                    <View style={styles.topPost}>
                        <View style={styles.topPostCard}>
                            <View style={styles.topPostCardUpper}>
                                <View style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Image src="/images/ic_click.png" style={{ width: 16, marginRight: 5 }} />
                                    <Text>Followers</Text>
                                </View>
                                <Text>{(topReach && topReach.reach) || '0'}</Text>
                            </View>
                            <View style={styles.topPostCardLower}>
                                {topReach ? (
                                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                                        <View style={styles.topPostCardLowerText}>
                                            <Text style={[styles.topPostCardLowerLabel, {marginTop: 0}]}>By: {topReach.influencerName}</Text>
                                            <Text style={styles.topPostCardLowerLabel}>
                                                {getNetworkNameByChannel(topReach.channel)}
                                                {topReach.postType && `(${topReach.postType.toLowerCase()})`}
                                            </Text>
                                            <Text style={{marginTop: '5px'}}>{topReach.name}</Text>
                                        </View>
                                    </View>
                                ) : (
                                    <View style={styles.topPostCardLowerText}>
                                        <Text>No post yet!</Text>
                                    </View>
                                )}
                            </View>
                        </View>
                        <View style={styles.topPostCard}>
                            <View style={styles.topPostCardUpper}>
                                <View style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Image
                                        src="/images/exportImg/ic_eye_a8.png"
                                        style={{ width: 16, marginRight: 5 }}
                                    />
                                    <Text>Views</Text>
                                </View>
                                <Text>{(topViews && topViews.views) || '0'}</Text>
                            </View>
                            <View style={styles.topPostCardLower}>
                                {topViews ? (
                                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                                        <View style={styles.topPostCardLowerText}>
                                            <Text style={[styles.topPostCardLowerLabel, {marginTop: 0}]}>By: {topViews.influencerName}</Text>
                                            <Text style={styles.topPostCardLowerLabel}>
                                                {getNetworkNameByChannel(topViews.channel)}
                                                {topViews.postType && `(${topViews.postType.toLowerCase()})`}
                                            </Text>
                                            <Text style={{marginTop: '5px'}}>{topViews.name}</Text>
                                        </View>
                                    </View>
                                ) : (
                                    <View style={styles.topPostCardLowerText}>
                                        <Text>No post yet!</Text>
                                    </View>
                                )}
                            </View>
                        </View>
                        <View style={styles.topPostCard}>
                            <View style={styles.topPostCardUpper}>
                                <View style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Image
                                        src="/images/exportImg/ic-thread-d.png"
                                        style={{ width: 16, marginRight: 5 }}
                                    />
                                    <Text>Engagement</Text>
                                </View>
                                <Text>{(topEngagements && topEngagements.engagement) || '0'}</Text>
                            </View>
                            <View style={styles.topPostCardLower}>
                                {topEngagements ? (
                                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                                        <View style={styles.topPostCardLowerText}>
                                            <Text style={[styles.topPostCardLowerLabel, {marginTop: 0}]}>By: {topEngagements.influencerName}</Text>
                                            <Text style={styles.topPostCardLowerLabel}>
                                                {getNetworkNameByChannel(topEngagements.channel)}
                                                {topEngagements.postType && `(${topEngagements.postType.toLowerCase()})`}
                                            </Text>
                                            <Text style={{marginTop: '5px'}}>{topEngagements.name}</Text>
                                        </View>
                                    </View>
                                ) : (
                                    <View style={styles.topPostCardLowerText}>
                                        <Text>No post yet!</Text>
                                    </View>
                                )}
                            </View>
                        </View>
                    </View>
                </View>
                <View style={styles.section}>
                    <View style={postListStyles.postList}>
                        <Text style={postListStyles.post}>Post</Text>
                        <Text style={postListStyles.channel}>Channel</Text>
                        <Text style={postListStyles.shares}>Type</Text>
                        <Text style={postListStyles.publish}>Submitted Date</Text>
                        <Text style={postListStyles.reach}>Followers</Text>
                        <Text style={postListStyles.views}>Views</Text>
                        <Text style={postListStyles.engagement}>Engagement</Text>
                        <Text style={postListStyles.brandedengagement}>Branded Engagement</Text>
                        <Text style={postListStyles.brandedengagementpercent}>Branded Engagement %</Text>
                        <Text style={postListStyles.likes}>Likes</Text>
                        <Text style={postListStyles.comments}>Comments</Text>
                        <Text style={postListStyles.influencer}>Creator</Text>
                        <Text style={postListStyles.newshares}>Shares</Text>
                        <Text style={postListStyles.saves}>Saves</Text>
                    </View>
                    <View>
                        {posts &&
                            posts.map((item, index) => {
                                console.log(item)
                                const brandedEngagement = (item.brandedCommentCount || 0) + (item.shares || 0) + (item.saves || 0)
                                const brandedEngagementPercent = brandedEngagement/item.comments
                                return <View key={index} style={postListStyles.postSingle} wrap={false}>
                                    <View style={[postListStyles.post, postListStyles.postContent]}>
                                        <Text style={{ maxHeight: 60 }}>{item.name}</Text>
                                    </View>
                                    <Text style={postListStyles.channel}>{getNetworkNameByChannel(item.channel)}</Text>
                                    <Text style={postListStyles.shares}>{(item.postType&&item.postType.toLowerCase()) || '-'}</Text>
                                    <Text style={postListStyles.publish}>{item.publishDate ? moment(item.publishDate).format("YYYY-MM-DD") : '-'}</Text>
                                    <Text style={postListStyles.reach}>{item.reach ? Format.expressInK(item.reach) : '0'}</Text>
                                    <Text style={postListStyles.views}>{item.views ? Format.expressInK(item.views) : '0'}</Text>
                                    <Text style={postListStyles.engagement}>{item.engagement ? Format.expressInK(item.engagement) : '0'}</Text>
                                    <Text style={postListStyles.brandedengagement}>{Format.expressInK(brandedEngagement)}</Text>
                                    <Text style={postListStyles.brandedengagementpercent}>{Format.percentage2(brandedEngagementPercent * 100)}</Text>
                                    <Text style={postListStyles.likes}>{item.likes ? Format.expressInK(item.likes) : '0'}</Text>
                                    <Text style={postListStyles.comments}>{item.comments ? Format.expressInK(item.comments) : '0'}</Text>
                                    <Text style={postListStyles.influencer}>{item.influencerName || '0'}</Text>
                                    <Text style={postListStyles.newshares}>{item.shares ? Format.expressInK(item.shares) : '0'}</Text>
                                    <Text style={postListStyles.saves}>{item.saves ? Format.expressInK(item.saves) : '0'}</Text>
                                </View>
                            })}
                    </View>
                </View>
            </Page>
        </Document>
    );
};
