import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import Modal from 'react-modal';
import Select from 'react-select';
import Api from '../../modules/Api';
import FavoriteDropDown from './FavoriteDropdown';
import ExclusionDropDown from './ExclusionDropdown';
import SavedSearchDropDown from './SavedSearchDropdown';

const AddEditModal = ({
    onClose = () => null,
    onSubmit = () => null,
    show,
    data,
    shouldClear = false,
    countryList
}) => {
    const [name, setName] = useState('');
    const [tagValue, setTagValue] = useState('');
    const [tags, setTags] = useState([]);
    const [nameError, setNameError] = useState(null);
    const [validated, setValidated] = useState(false);
    const [brands, setBrands] = useState([]);
    const [brandSelected, setBrandSelected] = useState();
    const [favList, setFavList] = useState([]);
    const [excList, setExcList] = useState([]);
    const [savedList, setSavedList] = useState([]);
    const inputRef = useRef();

    useEffect(() => {
        getBrands();
    }, []);

    useEffect(() => {
        if (data) {
            setName(data.name);
            setTags(data.tags || []);
            setBrandSelected(data.brand && data.brand.id);
        } else {
            setName('');
            setTags([]);
            setBrandSelected(null);
        }
    }, [data]);

    useLayoutEffect(() => {
        setValidated(name.length > 0 && !nameError && brandSelected && 
            (data ? (data.name !== name || 
                (data.brand && data.brand.id !== Number(brandSelected)) || 
                tagsChanged(data.tags, tags)) : true));
    }, [name, tags, brandSelected, nameError]);

    const tagsChanged = (origin, updates) => {
        return !(origin.length === updates.length && !updates.some(t => !origin.includes(t)));
    }

    const checkNewListName = (tx) => {
        if (tx.length === 0) {
            return 'Your List Name must be at least 1 letters'
        }
        if (tx.length > 255) {
            return 'Maximum 255 letters'
        }
        // if (isDuplicate(tx)) {
        //     return 'Name is already in use'
        // }
        return null;
    };

    const getBrands = () => {
        Api.getAgentBrands()
            .then(res => {
                setBrands(res);
            })
            .catch(err => {

            })
    };

    const getBrandOptions = () => {
        if (brands.length > 0) {
            const brandItems = brands.filter(ab => !ab.isArchived).map(ab => {
                const countryLabel = countryList?.find(x => x.value === ab.countryCode)?.label
                return {
                    value: ab.brandId,
                    label: <div className="brandNameLabel">{ab.brandName} {countryLabel && <div className="country">- {countryLabel}</div>}</div>,
                    name: ab.brandName
                }});
            brandItems.sort((a,b) => a.name.localeCompare(b.name))
            return brandItems;
        } 
        return [];
        
    };

    const handleBrandChange = (e) => {
        setBrandSelected(e.value);
    };

    const handleSubmit = () => {
        const payload = {
            name,
            tags,
            brandId: brandSelected,
            favouritesListIds: favList.map(f => f.id),
            exclusionListIds: excList.map(e => e.id),
            // agentSavedSearchIds: savedList.map(s => s.id),
        };
        if (data && data.id) {
            payload.id = data.id;
        }
        onSubmit(payload);
        clearData();
    };

    const clearData = () => {
        setNameError(null);
        setValidated(false);
        setName('');
        setTagValue('');
        setTags([]);
        setBrandSelected(null);
        setFavList([]);
        setExcList([]);
        setSavedList([]);
    };

    const onCancel = () => {
        if (shouldClear) {
            clearData();
        }
        onClose();
    };

    const onNameChange = (e) => {
        setName(e.target.value);
        setNameError(checkNewListName(e.target.value));
    }

    const handleClick = () => {
        if (inputRef.current)
            inputRef.current.focus();
    }

    const removeTag = (e, i) => {
        const update = [...tags];
        update.splice(i, 1);
        setTags(update);
    }

    const handleKeyUp = (keyCode) => {
        const update = [...tags];
        if (keyCode === 13) {
            if (inputRef.current.value.length > 0) {
                update.push(inputRef.current.value);
                setTags(update);
                setTagValue('');
            }
        }
    };

    return (
        <Modal
            overlayClassName="modalDialog"
            className="dialog create-advertisers-modal"
            isOpen={show}
        >
            <div className="container">
                <div className="planTitle">
                    {data ? 'Edit Plan' : 'New Plan'}
                </div>
                <div className="content">
                    <div className="inputArea">
                        <div className="input-holder">
                            <div className="label withreq"><div className="labelName">Name</div><div className="req">*required</div></div>
                            <input
                                type="text"
                                defaultValue={name}
                                onChange={onNameChange}
                                maxLength={255}
                            />
                        </div>
                        <div className="input-holder dropdown">
                            <div className="label withreq"><div className="labelName">Brand</div><div className="req">*required</div></div>
                            <Select
                                className="dropdown brand"
                                clearable={false}
                                searchable={false}
                                // disabled={this.isEditingCampaign}
                                value={brandSelected}
                                single
                                placeholder="Select Brand"
                                options={getBrandOptions()}
                                onChange={handleBrandChange}
                            />
                        </div>
                        <div className="input-holder">
                            <div className="label">Tags</div>
                            <div className="inputCustom" onClick={handleClick}>
                                <div className="labelContainer">
                                    {tags.map((tag, i) => {
                                        return (
                                            <div className="tagItem" key={`tag${  i}`}>
                                                {tag}
                                                <div onClick={e => removeTag(e, i)} className="filterBar-closeIcon">+</div>
                                            </div>
                                        )
                                    })}
                                </div>
                                <input
                                    onChange={e => setTagValue(e.target.value)}
                                    ref={inputRef}
                                    onKeyUp={({ keyCode }) => handleKeyUp(keyCode)}
                                    value={tagValue} />
                            </div>
                        </div>
                        {!data && <div className="input-holder dropdown">
                            <div className="label">Plan Creators - Import from favourite lists</div>
                            <FavoriteDropDown
                                height={46}
                                placeHolder='Select'
                                multi
                                onChange={v => setFavList(v)}
                            />
                        </div>}
                        {/* {!data && <div className="input-holder dropdown">
                            <div className="label">Plan Exclusions - Import from exclusion lists</div>
                            <ExclusionDropDown
                                height={46}
                                placeHolder='Select'
                                multi
                                onChange={v => setExcList(v)}
                            />
                        </div>} */}
                        {/* {!data && <div className="input-holder dropdown">
                            <div className="label">Plan Saved Searches - Import from my saved searches</div>
                            <SavedSearchDropDown
                                height={46}
                                placeHolder='Select'
                                multi
                                onChange={v => setSavedList(v)}
                            />
                        </div>} */}
                    </div>
                </div>
            </div>
            <div className="planFooter">
                <button
                    onClick={onCancel}
                    className="planButtonCancel"
                >
                    Сancel
                </button>
                <button
                    onClick={handleSubmit}
                    className='planButtonCreate'
                    disabled={!validated}
                >
                    {data? 'Update' : 'Create'}
                </button>
            </div>
        </Modal>
    )
}

export default AddEditModal;
