import React from 'react'
import FreeStringArray from '../../../shared/freeStringArray/FreeStringArray'
import FreeStringArrayDisplayed from '../../../shared/freeStringArray/FreeStringArrayDisplayed'

import './brandAndMore.scss'

/*
 props :
 - editMode
 - saveFlag
 - profile
 - changeFunction
 */

export default class Hobbies extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            hobbies : null,
            hobbiesLoaded : false
        }
    }

    delegateChange = (hobbies) => {
        this.setState({ hobbies : hobbies })
    }

    static getDerivedStateFromProps(props, state) {
        let newState = {};
        if (!state.hobbiesLoaded) {
            newState.hobbiesLoaded = true;
            newState.hobbies = props.profile.hobbies || [];
        }
        if (state.editMode && !props.editMode) {
            // finishing edit
            if (props.saveFlag) {
                let newProfile = props.profile;
                newProfile.hobbies = state.hobbies;
                props.changeFunction(newProfile)
            } else {
                newState.hobbies = props.profile.hobbies;
            }
            newState.saveFlag = props.saveFlag;
        }

        newState.editMode = props.editMode;

        return newState;
    }

    render() {
        if (!this.state.hobbiesLoaded) {
            return null;
        }
        return(
            <div className="pastBrandNames">
                <div className="title">
                    Hobbies
                </div>
                { this.props.editMode &&
                    <div className="editNames">
                        <div>Hobbies</div>
                        <div>
                            <FreeStringArray
                                strings={this.state.hobbies}
                                changeFunction={this.delegateChange}
                            />
                        </div>
                    </div>
                }
                { !this.props.editMode &&
                    <FreeStringArrayDisplayed
                        strings={this.props.profile.hobbies}
                    />
                }
            </div>
        )
    }
}