import React, { PureComponent } from "react";
import "./draftComposer.scss"
import RichTextEditor from '../editor/editor';

class DraftComposer extends PureComponent {

    render() {
        return (
            <div>
                <div className="draft-composer">
                    <RichTextEditor
                        saveEditorData={this.props.saveEditorData}
                        user={this.props.user}
                        isNew={!this.props.draftPost}
                        assignment={this.props.assignment}
                        postId={this.props.postId}
                        saveComments={this.props.saveComments}
                        readOnly={this.props.readonly}
                        draftPost={this.props.draftPost}
                        showWarning={this.props.showWarning}
                        influencerUID={this.props.influencerUID}
                        changeEditState={this.props.changeEditState}
                    />

                </div>
            </div>
        )
    }
}


export default DraftComposer
