import React, { Component } from 'react';
import Modal from 'react-modal';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import './UploadedImages.scss';

export default class UploadedImages extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentIndex: 1,
        };
    }

    onSlide = (currentIndex) => {
        this.setState({
            currentIndex: currentIndex + 1,
        });
    };

    close = () => {
        const { onClose } = this.props;
        onClose();
    };

    onDelete = () => {
        const { deleteFunc, items } = this.props;
        const { currentIndex } = this.state;
        deleteFunc(currentIndex, items);
        this.setState({ currentIndex: 1 });
    };

    render() {
        const { items, isOpen, deleteDisable } = this.props;
        const { currentIndex } = this.state;
        const slideItems = [];
        // items.forEach((item) => slideItems.push({ original: deleteDisable ? item : item.image }));
        items.forEach((item) => slideItems.push({ original: item }));

        return (
            <Modal
                closeTimeoutMS={300}
                overlayClassName="uploadedImagesOverlay"
                className="uploadedImagesModal"
                isOpen={isOpen}
            >
                <div className="close-btn" onClick={this.close} />
                <div className="topBar">
                    <div className="indicators">
                        {currentIndex}/{items.length} screenshots
                    </div>
                    {!deleteDisable && (
                        <div className="deleteBtn" onClick={this.onDelete}>
                            Delete
                        </div>
                    )}
                </div>
                <ImageGallery
                    items={slideItems}
                    showPlayButton={false}
                    showFullscreenButton={false}
                    showThumbnails={false}
                    onSlide={this.onSlide}
                />
            </Modal>
        );
    }
}
