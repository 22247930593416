import React from 'react'

import {aboutKeyList, moreKeyList} from '../../../../modules/influencer/ProfileFields'
import DisplayTabContent from './DisplayTabContent'
import EditAboutSections from './EditAboutSections'
import './about.scss'

// props
// - profile
// - editMode
// - changeFunction
// - saveFlag
// - setScrollTop
// - getScrollTop
// - setSaveEnabled takes flag


export default class About extends React.Component {
    constructor( props,context) {
        super(props.context);

        this.state = {
            selectedTab : 'about',
            displayedProfile : null
        }
    }

    changeFunction = (newProfile) => {
        let displayedProfile = {};
        for (let ix in this.state.displayedProfile) {
            displayedProfile[ix] = this.state.displayedProfile[ix];
            if (newProfile[ix]) {
                displayedProfile[ix] = newProfile[ix];
            }
        }
        this.setState({displayedProfile : displayedProfile});
        this.props.changeFunction(newProfile);
    }

    clickAbout = () => {
        this.setState({ selectedTab : 'about'})
    }

    clickMore = () => {
        this.setState({ selectedTab : 'more'})
    }

    static getDerivedStateFromProps(props, state) {
        let newState = {};

        if (props.editMode && !state.storedEditMode) {
            // going into edit mode, marking scroll position
            state.lastViewedScrollTop = props.getScrollTop();
        }
        if (!props.editMode && state.storedEditMode) {
            // restoring scroll position
            if (!isNaN(state.lastViewedScrollTop)) {
                props.setScrollTop(state.lastViewedScrollTop);
            }
        }

        // first load
        if (!state.profileLoaded && props.profile) {
            newState.displayedProfile = props.profile;
            newState.profileLoaded = true;
        }
        newState.storedEditMode = props.editMode;
        return newState;
    }

    render() {
        if (!this.props.profile) {
            return null;
        }

        let tabClasses = {
            about: '',
            more : ''
        }
        tabClasses[this.state.selectedTab] = "selected";

        const keyLists = {
            'about' : this.props.readOnlyAge?['age_readonly'].concat(aboutKeyList):aboutKeyList,
            'more' : moreKeyList
        }

        return (
            <div className="profileAbout" ref={(e) => { this.container = e;}}>
                <div className="selectTabs pieceTitle2"
                     style={{display: (this.props.editMode ? 'none' : '')}}
                >
                    <div
                        className={tabClasses.about}
                        onClick={this.clickAbout}
                    >
                        About
                    </div>
                    <div className="separator"></div>
                    <div
                        className={tabClasses.more}
                        onClick={this.clickMore}
                    >
                        More Details
                    </div>
                </div>
                <DisplayTabContent
                    show={!this.props.editMode}
                    keys={keyLists[this.state.selectedTab]}
                    profile={this.state.displayedProfile}
                />
                <EditAboutSections
                    show={this.props.editMode}
                    profile={this.props.profile}
                    changeFunction={this.changeFunction}
                    saveFlag={this.props.saveFlag}
                    editMode={this.props.editMode}
                    setSaveEnabled={this.props.setSaveEnabled}
                />
            </div>
        )

    }
}
