import React from 'react';
import Modal from 'react-modal';
import cx from 'classnames';
import moment from 'moment';
import './transactionLog.scss';

/**
 * @param {string} [data.userFirstName]
 * @param {string} [data.userLastName]
 * @param {Object[]} [data.posts]
 * @param {Object[]} [data.autoDetectedPosts]
 * @param {string} [data.userPictureUrl]
 */
export default function TransactionLogModal({ data, isOpen, close, transactionList }) {
    const creatorName = `${transactionList?.userFirstName} ${transactionList?.userLastName ?? ''}`;
    const creatorType =
    transactionList?.posts?.[0]?.connectionType === 'PUBLIC' || transactionList?.autoDetectedPosts?.[0]?.connectionType === 'PUBLIC'
            ? 'Public creator'
            : 'Connected creator';
    const dataArray = []
    data?.forEach(item => {
        if(Array.isArray(item)) {
            if (item.length === 1) {
                dataArray.push(item[0])
            } else {
                item.forEach(subitem => {
                    dataArray.push(subitem)
                })
            }
        } else {
            dataArray.push(item)
        }
    })
    return (
        <Modal
            closeTimeoutMS={300}
            isOpen={isOpen}
            overlayClassName="wideOverlay"
            className="transaction-log-modal"
            onRequestClose={close}
            shouldCloseOnOverlayClick
        >
            <div className="transaction-log-header">
                <div className="title-wrapper">
                    <h3 className="transaction-log-title">Transaction Log</h3>
                    <img src="/images/ic-close-g.svg" className="close-btn" onClick={close} />
                </div>

                <div className="creator-wrapper">
                    <img src={transactionList?.userPictureUrl || "/images/ic-avatar-blank-d.svg"} className="creator-image" />
                    <div className="creator-info">
                        <div className="creator-name">{creatorName}</div>
                        <div className="creator-type">{creatorType}</div>
                    </div>
                </div>
            </div>

            <div className="transaction-log-list">
                {dataArray.map((log, i) => (
                    <TransactionLog key={"post" + i} data={log} />
                ))}
            </div>
        </Modal>
    );
}

function TransactionLog({ data }) {
    const actionMappings = {
        URL_UPDATE: 'Update',
        CREATE: 'Add',
        ARCHIVE: 'Archive',
        AUTO_DETECT: 'Add'
    }
    if (data.connectionType) {
        return (
            <div className="transaction-log">
                <div className="log-date">{moment(data.createdOn).format('MMM DD, yyyy')}</div>
                <div className="log-info">
                    <div className="info-row">
                        <div className="row-label">URL</div>
                        <div className="urlContainer">
                            <a target="_blank" href={data.originalUrl}className="row-data url">{data.originalUrl}</a>
                            {1 && <span>(Auto-detect)</span>}
                        </div>
                    </div>
                    <div className="info-row">
                        <div className="row-label">Action</div>
                        <div className="row-data">Add</div>
                    </div>
                </div>
            </div>
        );
    }
    const urlToUse = actionMappings[data.action] === "Add" ? data.urlAfter : data.urlBefore
    return (
        <div className="transaction-log">
            <div className="log-date">{moment(data.createdOn).format('MMM DD, yyyy')}</div>
            <div className="log-info">
                <div className="info-row">
                    <div className="row-label">URL</div>
                    <div className="urlContainer">
                        <a target="_blank" href={urlToUse} className={cx("row-data url", actionMappings[data.action] !== "Add" && "strike")}>{urlToUse}</a>
                        {data.fromAutodetect && <span>(Auto-detect)</span>}
                    </div>
                </div>
                {data.action === "URL_UPDATE" && data.urlAfter !== "" && <div className="info-row">
                    <div className="row-label">To</div>
                    <a target="_blank" href={data.urlAfter} className="row-data url">{data.urlAfter}</a>
                </div>}
                <div className="info-row">
                    <div className="row-label">By</div>
                    <div className="row-data">{data.action === "AUTO_DETECT" ? "(Auto Detect)" : data.userFullName}</div>
                </div>
                <div className="info-row">
                    <div className="row-label">Action</div>
                    <div className="row-data">{actionMappings[data.action]}</div>
                </div>
            </div>
        </div>
    );
}
