import React from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import Tippy from '@tippyjs/react';
import { formatPercentageToWhole } from '../../../../modules/utils/helpers';
import './analyticsBySentiment.scss';

const RADIAN = Math.PI / 180;
const FONT_HEIGHT = 16;
const areLabelsOverlapping = (label1, label2) =>
    label1.x === label2.x &&
    ((label1.top < label2.top && label1.bottom > label2.top) ||
        (label2.top < label1.top && label2.bottom > label1.top));

function PieChartCard({
    cardTitle,
    tooltipText,
    chartWidth,
    chartHeight,
    chartData,
    total,
    totalLabel,
    highlightedText,
    handleLink,
    cardRef,
}) {
    const filteredChartData = chartData.filter((data) => !!data.value);

    let labelPositions = [];
    let adjustedLabelPositions = [];

    const chartLabelLine = (props) => {
        const { cx, cy, midAngle, outerRadius, category } = props;

        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const x = cos >= 0 ? sx + (435 - sx) : 0;

        let path = `M${sx},${sy}L${x},${sy}`;

        const currentLabel = { category: category, x: x, top: sy - 8, bottom: sy + 22 + FONT_HEIGHT };
        labelPositions = [...labelPositions, currentLabel];

        const overlappingLabel =
            currentLabel &&
            labelPositions.find((label) => label.category !== category && areLabelsOverlapping(currentLabel, label));

        if (overlappingLabel) {
            const labelHeight = 50;
            const isPossibleToMoveUp = currentLabel?.top > labelHeight && overlappingLabel?.top > currentLabel?.top;
            const isPossibleToMoveDown =
                currentLabel?.bottom < chartHeight - labelHeight && overlappingLabel?.bottom < currentLabel?.bottom;
            const newY = isPossibleToMoveUp ? sy - labelHeight : isPossibleToMoveDown ? sy + labelHeight : sy;
            const halfX = (x + sx) / 2;

            path = `M${sx},${sy}L${halfX},${newY}L${x},${newY}`;

            adjustedLabelPositions = [
                ...adjustedLabelPositions.filter((label) => label.category !== category),
                { category, x: x, top: newY - 8, bottom: newY + 22 + FONT_HEIGHT, y: newY },
            ];
        }

        return (
            <g>
                <path d={path} stroke="gray" fill="none" />
            </g>
        );
    };

    const chartLabel = (props, total) => {
        const { cx, cy, midAngle, fill, payload, outerRadius } = props;

        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        let sy = cy + (outerRadius + 10) * sin;
        const textAnchor = cos >= 0 ? 'end' : 'start';
        const x = cos >= 0 ? sx + (435 - sx) : 0;

        const adjustedLabelPosition = adjustedLabelPositions.find((label) => label.category === payload.category);
        if (adjustedLabelPosition) sy = adjustedLabelPosition.y;

        return (
            <g>
                <text x={x} y={sy} dy={-8} textAnchor={textAnchor} fill="gray">
                    {payload.category}
                </text>
                <text x={x} y={sy} dy={22} textAnchor={textAnchor} fill="gray">
                    <tspan style={{ fill: 'black', fontWeight: 'bold' }}>
                        {formatPercentageToWhole((payload.value / total) * 100)}%
                    </tspan>
                    <tspan> ({payload.value})</tspan>
                </text>
            </g>
        );
    };

    return (
        <div className="card">
            <div className="card-title">
                <div>{cardTitle}</div>
                <div className="info-tooltip-icon">
                    <Tippy className="text-tooltip" content={tooltipText}>
                        <img src="/images/tooltipUser.svg" />
                    </Tippy>
                </div>
                {handleLink && (
                    <div className="card-title-link" onClick={handleLink}>
                        View all
                    </div>
                )}
            </div>
            <div className="card-content">
                {filteredChartData.length ? (
                    <PieChart width={chartWidth} height={chartHeight} ref={cardRef}>
                        <Pie
                            data={filteredChartData}
                            dataKey="value"
                            nameKey="category"
                            cx="50%"
                            cy="50%"
                            innerRadius={60}
                            outerRadius={80}
                            paddingAngle={2}
                            fill="#82ca9d"
                            label={(props) => chartLabel(props, total)}
                            labelLine={(props) => chartLabelLine(props)}
                            isAnimationActive={false}
                        >
                            {filteredChartData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.color} />
                            ))}
                        </Pie>
                    </PieChart>
                ) : (
                    <EmptyGraph />
                )}
            </div>
            <div className="card-label">
                {total} {totalLabel}
            </div>
            {highlightedText && (
                <div className="card-label highlighted">
                    <div className="inner-highlighted">{highlightedText}</div>
                </div>
            )}
        </div>
    );
}

function EmptyGraph() {
    return (
        <div className="empty-graph">
            <div className="outer-circle">
                <div className="inner-circle" />
            </div>
        </div>
    );
}

export default React.memo(PieChartCard);
