import React from 'react'
import RichContentEditor from '../../shared/contentEditor/RichContentEditor';


// props
//  - initialContent
//  - saveFunction  takes new content

export default class EditSummary extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.handleSummaryChange = this.handleSummaryChange.bind(this);
        this.cancel = this.cancel.bind(this);
        // this.save = this.save.bind(this);
        this.saveText = this.saveText.bind(this);

        this.typingTimer = null;
        this.doneTypingInterval = 2000;

        this.state = {};
    }

    handleSummaryChange(newSummary) {
        if (newSummary == this.props.originalContent) {
            return;
        }
        this.setState({
            summary: newSummary
            // changed : true
        }, () => {
            if (this.typingTimer) {
                clearTimeout(this.typingTimer);
            }
            this.typingTimer = setTimeout(this.saveText, this.doneTypingInterval);
        })
    }

    cancel() {
        this.setState({
            summary : this.props.originalContent
            // changed : false
        })
    }

    // save() {
    //     let f = this.props.saveFunction;
    //     if (typeof f === 'function') {
    //         f(this.state.summary);
    //         this.setState({
    //             changed : false
    //         })
    //     }
    // }

    saveText() {
        let f = this.props.saveFunction;
        if (typeof f === 'function') {
            f(this.state.summary);
            // this.setState({
            //     changed : false
            // })
        }
    }

    componentDidMount() {
        this.setState({
            summary : this.props.originalContent
            // changed : false
        })
    }

    render() {
        // let controlClass = "hide";
        // if (this.state.changed) {
        //     controlClass = "";
        // }
        return (
            <div className="editSummary">
                <RichContentEditor
                    readOnly={!this.props.permission}
                    className="richContentEditor"
                    content={this.state.summary}
                    onChange={this.handleSummaryChange}
                    placeholder="Write an assignment summary"
                />
            </div>

        )
    }
}
