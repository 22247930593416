import React from 'react'
import ModalDialog from '../../shared/modalDialog/ModalDialog'

// Props :
// - show
// - campaign
// - createFunction  takes an assignment object
// - cancelFunction

export default class DeleteCampaign extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.deleteCampaign = this.deleteCampaign.bind(this);
    }

    deleteCampaign() {
        let f = this.props.proceedFunction;
        if (typeof f === 'function') {
            f();
        }
    }

    render() {
        return (
            <ModalDialog
                show={this.props.show}
                title="Delete Campaign"
                proceedButtonLabel="DELETE"
                readyToProceed={true}
                closeButton={true}
                proceedFunction={this.deleteCampaign}
                cancelFunction={this.props.cancelFunction}
            >
                <div className="deleteCampaign">
                    <div className="label">Are you sure you want to delete this campaign?</div>
                </div>
            </ModalDialog>
        )
    }
}
