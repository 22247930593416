import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import Lookup from '../../../modules/Lookup';
import { isNull } from '../../../../../node_modules/util';
import { fetchFavorites } from '../../../store/actions/favoriteActions';
import './discoverPanel.scss';
import ReachIcon from '../../shared/ReachIcon/ReachIcon';
import SocialNetwork from '../../../modules/influencer/SocialNetworks';
import LocationInput from '../../shared/locationInput/LocationInput';
import moment from 'moment';
import { SingleDatePicker } from 'react-dates';
import DropdownSelect from '../../shared/selectBox/DropdownSelect';
import InputRange from './InputRange';
import CustomRangeInputs from './CustomRangeInputs';
import { LOOKUP_FILTER_GROUP } from '../../../constants/lookup-constants';
import { getActiveChannelName } from './DiscoverPanel.helpers';
import { KeywordsInput } from './KeywordsInput';

const filterType = ['Connected', 'Public'];

class FilterMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            source: props.allFiltersData.type,
            channelSelect: props.channelSelect,
            selectedFilters: props.selectedFilters,
            availableConnectedFilters: props.connectedFilters,
            availableCommonFilters: props.commonFilters,
            availableAudienceFilters: props.audienceFilters,
            addedFilters: props.addedFilters,
        };
    }

    setSource = (source) => {
        this.setState({ source }, () => this.delegateChanges(true));
    };

    toggleChannel = (channel, sourceType) => {
        const { channelSelect, selectedFilters, addedFilters } = this.state;

        // Allow to select only 1 channel if source type is PUBLIC
        if (sourceType === 'PUBLIC') {
            const updatedChannelSelect = Object.keys(channelSelect[sourceType]).reduce((accumulator, current) => {
                accumulator[current] = false;
                return accumulator;
            }, {});
            if (channelSelect[sourceType][channel]) {
                return;
            }

            const activePublicChannel = channel.toUpperCase();

            const allFilterIds = [
                ...Lookup.getGroupFilters(LOOKUP_FILTER_GROUP.COMMON, sourceType, activePublicChannel),
                ...Lookup.getGroupFilters(LOOKUP_FILTER_GROUP.CONNECTED, sourceType, activePublicChannel),
                ...Lookup.getGroupFilters(LOOKUP_FILTER_GROUP.AUDIENCE, sourceType, activePublicChannel),
            ].map((filter) => filter.id);

            selectedFilters.PUBLIC = selectedFilters.PUBLIC.filter((filter) => allFilterIds.includes(filter.id));
            addedFilters.PUBLIC = addedFilters.PUBLIC.filter((filter) => allFilterIds.includes(filter.filterId));

            updatedChannelSelect[channel] = true;
            channelSelect[sourceType] = updatedChannelSelect;

            const updatedAvailableFilters = this.getAvailableFiltersWithoutSelected(sourceType);

            this.setState({ ...updatedAvailableFilters, selectedFilters, addedFilters, channelSelect }, () => {
                this.delegateChanges(true);
            });
        } else {
            channelSelect[sourceType][channel] = !channelSelect[sourceType][channel];
            this.setState({ channelSelect }, () => this.delegateChanges(true));
        }
    };

    addFilter = (filter, group) => {
        const { selectedFilters, availableConnectedFilters, availableCommonFilters, availableAudienceFilters, source } = this.state;
        selectedFilters[source].push(filter);
        if (group === 'common') {
            const newCommonFilters = availableCommonFilters[source].filter((item) => item.id !== filter.id);
            let updatedCommonFilters = Object.assign({}, availableCommonFilters);
            updatedCommonFilters[source] = newCommonFilters;
            this.setState({ selectedFilters, availableCommonFilters: updatedCommonFilters }, () => this.delegateChanges(false));
        } else if (group === 'connected') {
            const newConnectedFilters = availableConnectedFilters[source].filter((item) => item.id !== filter.id);
            let updatedConnectedFilters = Object.assign({}, availableConnectedFilters);
            updatedConnectedFilters[source] = newConnectedFilters;
            this.setState({ selectedFilters, availableConnectedFilters: updatedConnectedFilters }, () => this.delegateChanges(false));
        } else {
            const newAudienceFilters = availableAudienceFilters[source].filter((item) => item.id !== filter.id);
            let updatedAudienceFilters = Object.assign({}, availableAudienceFilters);
            updatedAudienceFilters[source] = newAudienceFilters;
            this.setState({ selectedFilters, availableAudienceFilters: updatedAudienceFilters }, () => this.delegateChanges(false));
        }
    };

    getAvailableFiltersWithoutSelected = (source = this.state.source) => {
        const { selectedFilters, availableConnectedFilters, availableCommonFilters, availableAudienceFilters, channelSelect } = this.state;
        const activePublicChannel = getActiveChannelName(channelSelect.PUBLIC);

        availableConnectedFilters[source] = Lookup.getGroupFilters(LOOKUP_FILTER_GROUP.CONNECTED, source, activePublicChannel).filter(
            (item) =>
                !selectedFilters[source].find((s) => {
                    return s.id === item.id;
                }),
        );
        availableCommonFilters[source] = Lookup.getGroupFilters(LOOKUP_FILTER_GROUP.COMMON, source, activePublicChannel).filter(
            (item) =>
                !selectedFilters[source].find((s) => {
                    return s.id === item.id;
                }),
        );
        availableAudienceFilters[source] = Lookup.getGroupFilters(LOOKUP_FILTER_GROUP.AUDIENCE, source, activePublicChannel).filter(
            (item) =>
                !selectedFilters[source].find((s) => {
                    return s.id === item.id;
                }),
        );

        return { availableConnectedFilters, availableCommonFilters, availableAudienceFilters };
    };

    removeFilter = (filter) => {
        const { selectedFilters, addedFilters, source } = this.state;

        selectedFilters[source] = selectedFilters[source].filter((item) => item.id !== filter.id);
        addedFilters[source] = addedFilters[source].filter((item) => item.filterId !== filter.id);

        const updatedAvailableFilters = this.getAvailableFiltersWithoutSelected();

        this.setState({ ...updatedAvailableFilters, selectedFilters, addedFilters }, () => this.delegateChanges(true));
    };

    clearAllFilter = () => {
        const { selectedFilters, addedFilters, source, availableConnectedFilters, availableCommonFilters, availableAudienceFilters } =
            this.state;

        const activePublicChannel = getActiveChannelName(this.state.channelSelect.PUBLIC);

        selectedFilters[source] = [];
        addedFilters[source] = [];
        availableCommonFilters[source] = Lookup.getGroupFilters(LOOKUP_FILTER_GROUP.COMMON, source, activePublicChannel);
        availableConnectedFilters[source] = Lookup.getGroupFilters(LOOKUP_FILTER_GROUP.CONNECTED, source, activePublicChannel);
        availableAudienceFilters[source] = Lookup.getGroupFilters(LOOKUP_FILTER_GROUP.AUDIENCE, source, activePublicChannel);
        this.setState({ selectedFilters, addedFilters, availableConnectedFilters, availableCommonFilters, availableAudienceFilters }, () =>
            this.delegateChanges(true),
        );
    };

    handleChange = (filter, changeValue) => {
        const { addedFilters, source } = this.state;
        let updatedFilters = addedFilters[source].filter((f) => f.filterId !== filter.id);

        let newFilter = { filterId: filter.id };
        let addFilter = false;

        let returnValue = '';

        switch (filter.type) {
            case 'CUSTOM_VALUE':
                const activeFilter = changeValue.filterOptions[0];
                if (activeFilter) {
                    newFilter.filterOptionIds = [activeFilter.id];
                    newFilter.customValue = activeFilter.value === 'gte' ? activeFilter.minValue : activeFilter.maxValue;
                    addFilter = true;
                }
                break;

            case 'JSON':
                if (changeValue instanceof Object) {
                    newFilter.json = changeValue;
                    addFilter = true;
                }
                break;
            case 'DATE_TEXT':
                if (moment(changeValue).isValid()) {
                    newFilter.keywords = [moment(changeValue).format('YYYY-MM-DD')];
                    addFilter = true;
                }
                break;
            case 'FREE_TEXT':
                if (changeValue.length > 0) {
                    newFilter.keywords = changeValue;
                    addFilter = true;
                }
                break;
            case 'PERCENTAGE_RANGE':
            case 'FREE_RANGE':
                if (changeValue.length === 2) {
                    newFilter.range_from = changeValue[0];
                    newFilter.range_to = changeValue[1];
                    addFilter = true;
                }
                break;
            case 'CUSTOM_RANGES':
                if (changeValue && changeValue instanceof Object && changeValue.filterOptions.length > 0) {
                    newFilter.data = changeValue;
                    addFilter = true;
                }
                break;
            case 'RANGE':
            case 'OPTION_RANGE':
            case 'OPTION':
            default:
                returnValue = [];
                if (Array.isArray(changeValue)) {
                    changeValue.forEach((option) => {
                        if (!isNull(option) && option !== '') {
                            returnValue.push(option);
                        }
                    });
                }
                if (returnValue.length > 0) {
                    newFilter.filterOptionIds = returnValue;
                    addFilter = true;
                }
                break;
        }

        if (addFilter) {
            updatedFilters.push(newFilter);
        }
        addedFilters[source] = updatedFilters;
        console.log('addedFilters[source]:', addedFilters[source]);
        this.setState({ addedFilters }, () => this.delegateChanges(true));
    };

    delegateChanges(search) {
        const { onUpdate } = this.props;
        const {
            addedFilters,
            channelSelect,
            source,
            selectedFilters,
            availableCommonFilters,
            availableConnectedFilters,
            availableAudienceFilters,
        } = this.state;
        if (onUpdate) {
            onUpdate(
                addedFilters,
                channelSelect,
                source,
                selectedFilters,
                availableCommonFilters,
                availableConnectedFilters,
                availableAudienceFilters,
                search,
            );
        }
    }

    mergeFilterOptions(filter1, filter2) {
        let ids = filter2.filterOptions.map((f) => f.id);
        let unchangedOptions = filter1.filterOptions.filter((op) => ids.indexOf(op.id) === -1);
        let updatedFilter = Object.assign({}, filter1);
        updatedFilter.filterOptions = [...unchangedOptions, ...filter2.filterOptions];
        return updatedFilter;
    }

    filterOperationShow = (filter) => {
        const { addedFilters, source } = this.state;
        let filterData = addedFilters[source].find((f) => f.filterId === filter.id);
        let filtershow = <div />;
        let type = undefined;
        switch (filter.type) {
            case 'PERCENTAGE_RANGE':
                type = 'percentage';
            case 'FREE_RANGE':
                let rangeObj = { from: filter.minValue, to: filter.maxValue };
                let values = filterData ? [filterData.range_from, filterData.range_to] : ['', ''];
                let unlimitMax = filter.id === 2 ? false : true;
                filtershow = (
                    <InputRange
                        id={'filter_' + filter.id}
                        rangeObject={rangeObj}
                        unlimitMax={unlimitMax}
                        options={values}
                        type={type}
                        label={filter.label}
                        changeFunction={(value) => this.handleChange(filter, value)}
                        ref={(e) => {
                            this.filterOperation = e;
                        }}
                    />
                );
                break;

            case 'CUSTOM_VALUE':
                const updates2 = {
                    ...filter,
                    filterOptions: filter.filterOptions.map((option) => {
                        if (option.id === filterData?.filterOptionIds[0]) {
                            const isGte = option.customValue === 'gte';
                            if (isGte) {
                                return { ...option, maxValue: 100, minValue: filterData?.customValue };
                            }
                            return { ...option, minValue: 0, maxValue: filterData?.customValue };
                        }
                        return option;
                    }),
                };
                filtershow = (
                    <CustomRangeInputs
                        isSingleInput
                        label={`Audience ${filter.label}`}
                        clearable={false}
                        closeOnSelect={false}
                        placeholder=""
                        filterValue={updates2}
                        onChange={(data) => this.handleChange(filter, data)}
                        searchable
                    />
                );
                break;

            case 'CUSTOM_RANGES':
                let updates =
                    filterData && filterData.data && filterData.data.filterOptions.length > 0
                        ? this.mergeFilterOptions(filter, filterData.data)
                        : filter;
                filtershow = (
                    <CustomRangeInputs
                        isMinOnly={filter.minOnly}
                        label={`Audience ${filter.label}`}
                        clearable={false}
                        // multi
                        closeOnSelect={false}
                        placeholder=""
                        filterValue={updates}
                        onChange={(data) => this.handleChange(filter, data)}
                        searchable
                    />
                );
                break;
            case 'RANGE':
                break;
            case 'JSON':
                filtershow = (
                    <LocationInput
                        id={'filter_' + filter.id}
                        original={
                            filterData && filterData.json && filterData.json.hasOwnProperty('formattedAddress')
                                ? filterData.json
                                : { formattedAddress: '' }
                        }
                        changeFunction={(value) => this.handleChange(filter, value)}
                        ref={(e) => {
                            this.filterOperation = e;
                        }}
                    />
                );
                break;
            case 'DATE_TEXT':
                let yearArray = [];
                for (let i = moment().year() - 120; i < moment().year() + 21; i++) {
                    yearArray.push(i);
                }
                filtershow = (
                    <SingleDatePicker
                        placeholder=""
                        ref={(e) => {
                            this.filterOperation = e;
                        }}
                        numberOfMonths={1}
                        onDateChange={(value) => this.handleChange(filter, value)}
                        focused={this.state[`${filter.id}`] === true} // PropTypes.bool
                        onFocusChange={({ focused }) => this.setState({ [`${filter.id}`]: focused })} // PropTypes.func.isRequired
                        id={'filter_' + filter.id} // PropTypes.string.isRequired,
                        date={
                            filterData && filterData.keywords && filterData.keywords.length > 0 && moment(filterData.keywords[0]).isValid()
                                ? moment(filterData.keywords[0])
                                : null
                        }
                        displayFormat="YYYY-MM-DD"
                        isOutsideRange={() => false}
                        showDefaultInputIcon
                        inputIconPosition="after"
                        renderMonthElement={({ month, onMonthSelect, onYearSelect }) => (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div>
                                    <select
                                        value={month.month()}
                                        onChange={(e) => {
                                            onMonthSelect(month, e.target.value);
                                        }}
                                    >
                                        {moment.months().map((label, value) => (
                                            <option key={value} value={value}>
                                                {label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    <select
                                        value={month.year()}
                                        onChange={(e) => {
                                            onYearSelect(month, e.target.value);
                                        }}
                                    >
                                        {yearArray.map((year) => (
                                            <option key={year} value={year}>
                                                {year}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        )}
                    />
                );
                break;
            case 'FREE_TEXT':
                filtershow = (
                    <div>
                        <KeywordsInput
                            isInfluencerFilterInput
                            data={filterData?.keywords}
                            onChange={(value) => this.handleChange(filter, value)}
                        />
                    </div>
                );
                break;
            case 'OPTION_RANGE':
            case 'OPTION':
            default:
                let options = [];
                filter.filterOptions.forEach((option) => {
                    options.push({ value: option.id, label: option.label });
                });
                filtershow = (
                    <DropdownSelect
                        id={'filter_' + filter.id}
                        clearable={false}
                        value={filterData ? filterData.filterOptionIds : null}
                        simpleValue={true}
                        multi={filter.multipleSelect}
                        placeholder=""
                        options={options}
                        onChange={(data) => this.handleChange(filter, data)}
                        ref={(e) => {
                            this.filterOperation = e;
                        }}
                    />
                );

                break;
        }
        return filtershow;
    };

    render() {
        const {
            source,
            channelSelect,
            selectedFilters,
            availableConnectedFilters,
            availableCommonFilters,
            availableAudienceFilters,
            addedFilters,
        } = this.state;
        const { disableMultipleInvite } = this.props;

        let selectedFiltersShow = selectedFilters[source];

        return (
            <div>
                <div className={cx('filterMenuSection', { disabled: !disableMultipleInvite })}>
                    <div className="filterMenuSectionTitle">Search in:</div>
                    <div className="filterMenuTwoOptions">
                        {filterType.map((type) => {
                            return (
                                <div
                                    key={type}
                                    className={cx({ selected: source === type.toUpperCase(), noHover: !disableMultipleInvite })}
                                    onClick={!disableMultipleInvite ? null : () => this.setSource(type.toUpperCase())}
                                >
                                    {type}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="filterMenuSection">
                    <div className="filterMenuSectionTitle">Required Channels</div>
                    <div className="filterMenuChannels">
                        {Object.keys(channelSelect[source]).map((key, index) => {
                            return (
                                <div
                                    key={index}
                                    className={cx('filterNetworkOption', { disabled: !channelSelect[source][key] })}
                                    style={{ borderColor: SocialNetwork.getNetwork(key).color }}
                                    onClick={() => this.toggleChannel(key, source)}
                                >
                                    <ReachIcon network={key} value={1} size={14} padding={5} noToolTip />
                                </div>
                            );
                        })}
                    </div>
                </div>
                {selectedFiltersShow.length > 0 && (
                    <div className="filterMenuSection">
                        {selectedFiltersShow.map((item) => {
                            return (
                                <div
                                    key={item.id}
                                    className={cx('filterOperation', item.type.toLowerCase(), {
                                        selected: addedFilters[source].find((f) => f.filterId === item.id),
                                    })}
                                >
                                    <img src="/images/ic-remove.svg" onClick={() => this.removeFilter(item)} />
                                    {item.type !== 'FREE_RANGE' &&
                                        item.type !== 'PERCENTAGE_RANGE' &&
                                        item.type !== 'CUSTOM_RANGES' &&
                                        item.type !== 'CUSTOM_VALUE' && <div className="title">{item.label}</div>}
                                    {this.filterOperationShow(item)}
                                </div>
                            );
                        })}
                        <hr />
                        <div className="clearAllBtn">
                            <div onClick={this.clearAllFilter}>Clear all filters</div>
                        </div>
                    </div>
                )}
                <div className="filterMenuSection">
                    <div className="filterMenuSectionTitle largerFont">{source === 'PUBLIC' ? 'Creator' : 'More filters'} </div>
                    <div>
                        {availableCommonFilters[source].map((filter) => {
                            return (
                                <div key={filter.id} className="filterMenuOptions">
                                    <img src="/images/ic-plus-circle.svg" onClick={() => this.addFilter(filter, 'common')} />
                                    <span>{filter.label}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className={cx('filterMenuSection', { hidden: source === 'PUBLIC' })}>
                    <div className="filterMenuSectionTitle largerFont">Connected account</div>
                    <div className="filterMenuSectionTitle subTitle">Filters apply to connected account only</div>
                    {source !== 'PUBLIC' &&
                        availableConnectedFilters[source].map((filter) => {
                            return (
                                <div key={filter.id} className="filterMenuOptions">
                                    <img src="/images/ic-plus-circle.svg" onClick={() => this.addFilter(filter, 'connected')} />
                                    <span>{filter.label}</span>
                                </div>
                            );
                        })}
                </div>
                <div className="filterMenuSection">
                    <div className="filterMenuSectionTitle largerFont">
                        {source === 'PUBLIC' ? 'Audience' : 'Audience Demographic Filters'}
                    </div>
                    <div>
                        {availableAudienceFilters[source].map((filter) => {
                            return (
                                <div key={filter.id} className="filterMenuOptions">
                                    <img src="/images/ic-plus-circle.svg" onClick={() => this.addFilter(filter, 'insights')} />
                                    <span>{filter.label}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchFavorites: () => dispatch(fetchFavorites()),
    };
};

export default connect(null, mapDispatchToProps)(FilterMenu);
