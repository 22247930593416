import React from 'react'
import cx from 'classnames'

// campaign second fixed header line
// props
// - current  (as opposed to past, flag
// - currentChangeFunction takes flag
// - searchChangeFunction takes string
// - sortBy : 'name' or 'date'
// - dateSort  'asc' or 'desc'
// - nameSort  'asc' or 'desc'
// - sortByName function
// - sortByDate function
// - limit
// - limitChangeFunction: ƒ (limit)


export default class ListHeaderSecondary extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.searchTimeout = null;
    }

    setCurrent = (flag) => {
        this.props.currentChangeFunction(flag);
    }

    render() {

        // hide the Current tab for now as it's the only tab, KM-403
        return (
            <div className="listHeaderSecondary listPanel" style={{zIndex:110, height:60, overflowY:'visible'}}>
                {/* <div className="subHeaderPanel">
                    <div className="contentDiv">
                        <div className="listTopNav">
                            <div className="filterStatus">
                                <span className={cx({'current':this.props.current})}
                                    onClick={ () => this.setCurrent(true) }
                                >
                                    Current
                                </span>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="contentDiv">
                    <div className="listTopNav">
                        <div className="listedCampaignTitle" style={{marginTop:25, height: 34, borderRadius: 3, backgroundColor: '#E3E4E5', paddingTop: 5, paddingBottom: 5}}>
                            <div className="logo" onClick={this.props.sortByBrand}>
                                <span>Brand</span>
                                {<img src="/images/ic_down14x14.svg" className={cx({up: this.props.brandSort === 'desc', no: this.props.sortBy !== 'brand'})} />}
                            </div>
                            <div className="campaign" onClick={this.props.sortByName}>
                                <span>Campaign</span>
                                <img src="/images/ic_down14x14.svg" className={cx({up: this.props.nameSort === 'desc', no: this.props.sortBy !== 'name'})} />
                            </div>

                            <div className="assignmentName">Assignment(s)</div>
                            <div className="contact">Contact(s)</div>
                            {/* <div className="contact" onClick={this.props.sortBySigner}> */}
                            <div className="contact">
                                Signer
                                {/* {<img src="/images/ic_down14x14.svg" className={cx({up: this.props.signerSort === 'desc', no: this.props.sortBy !== 'signer'})} />} */}
                            </div>

                            <div className="created" onClick={this.props.sortByDate}>
                                <span>Date Created</span>
                                <img src="/images/ic_down14x14.svg" className={cx({up: this.props.dateSort === 'desc', no: this.props.sortBy !== 'date'})} />
                            </div>

                            
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
