const config = require('../config/index.json');

// console.log(process.env);

const uiconfig = {
    "USER_POOL_ID": process.env.USER_POOL_ID || config.USER_POOL_ID,
    "APP_CLIENT_ID": process.env.USER_POOL_APP_CLIENT_ID || config.USER_POOL_APP_CLIENT_ID,
    "REGION": process.env.USER_POOL_REGION || config.USER_POOL_REGION,
    "DOMAIN": process.env.AMAZON_USER_POOL_DOMAIN_URL || config.AMAZON_USER_POOL_DOMAIN_URL,
    "IDENTITY_POOL_ID": process.env.IDENTITY_POOL_ID || config.IDENTITY_POOL_ID,

    "Auth0": {
        "CLIENT_ID": process.env.Auth0_CLIENT_ID || config.Auth0.CLIENT_ID,
        "CLIENT_DOMAIN": process.env.Auth0_CLIENT_DOMAIN || config.Auth0.CLIENT_DOMAIN,
        "SCOPE": process.env.Auth0_SCOPE || config.Auth0.SCOPE,
        "RESPONSE_TYPE": process.env.Auth0_RESPONSE_TYPE || config.Auth0.RESPONSE_TYPE
    },
    "CKEDITOR_LICENSE_KEY": process.env.CKEDITOR_LICENSE_KEY || config.CKEDITOR_LICENSE_KEY,
    "GOOGLE_API_CLIENT_ID": process.env.GOOGLE_API_CLIENT_ID || config.GOOGLE_API_CLIENT_ID,

    "API_MS_URL": process.env.API_MS_URL || config.API_MS_URL,
    // "PG_SSO_DOMAIN": process.env.PG_SSO_DOMAIN || config.PG_SSO_DOMAIN,
    // "PG_SSO_REDIRECT_URI": process.env.PG_SSO_REDIRECT_URI || config.PG_SSO_REDIRECT_URI,
    // "PG_SSO_CLIENT_ID": process.env.PG_SSO_CLIENT_ID || config.PG_SSO_CLIENT_ID,
    // "PG_SSO_CLIENT_SECRET": process.env.PG_SSO_CLIENT_SECRET || config.PG_SSO_CLIENT_SECRET,
    "API_DOCUSIGN_URL": process.env.API_DOCUSIGN_URL || config.API_DOCUSIGN_URL,
    "APP_URL": process.env.APP_URL || config.APP_URL,
    "HOTJAR_SITE_ID": process.env.HOTJAR_SITE_ID || config.HOTJAR_SITE_ID
};

module.exports = uiconfig;
