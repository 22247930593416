import React from 'react'
import { withRouter } from 'react-router'
import {connect} from 'react-redux';
import Api from "../../../modules/Api";

import './headerWCA.scss'

class HeaderWithCampaignAndAssignment extends React.Component {

    constructor(props, context) {
        super(props, context);

        // this.processProps = this.processProps.bind(this);
        this.renderedAssignment = this.renderedAssignment.bind(this);
        this.backToCampaign = this.backToCampaign.bind(this);
        this.onCancel = this.onCancel.bind(this);
        const {campaignId, campaignState} = this.props;
        const aObj = campaignState[`assignments${  campaignId}`];
        this.state = {
            campaignName : aObj ? aObj.campaignName: '',
            assignments : aObj ? aObj.assignmentOptionSelection: []
        };
    }

    backToCampaign() {
        this.props.history.push(`/campaign/${  this.props.campaignId}`);
    }

    renderedAssignment(assignmentOption) {
        return <span className="campaignName" key={assignmentOption.value}>
            {assignmentOption.label}
        </span>
    }

    onCancel() {
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }

    componentDidMount() {
        const {assignmentId} = this.props;
        if (!this.state.campaignName) {
            const self = this;
            Api.getAssignment(assignmentId)
                .then(res => {
                    self.setState({
                        campaignName : res.campaign.name,
                        assignments : [{value: assignmentId,label:res.name}]
                    })
                })
                .catch(err => {
                    const f = this.props.apiErrFunction;
                    if (typeof f === "function") {
                        f(err);
                    }
                });
        }
        
    }

    render() {
        if (!this.state.campaignName) {
            return null;
        }
        const{AllAssignment} = this.props;
        const backToInfluencers = () => {
            this.props.history.push(`/campaignWork/${  this.props.campaignId  }/influencers/${  this.props.assignmentId}`);
        }
        const assignmentList = [];
        if (!AllAssignment && Array.isArray(this.state.assignments)) {
            for (const assignment of this.state.assignments) {
                assignmentList.push(this.renderedAssignment(assignment));
            }
        }

        return (
            <div className="headerWCA">
                <div>
                    <div>
                        <img src="/images/ic-back-gray.svg" onClick={this.onCancel} />
                    </div>
                    <div>
                        <div>
                            <span className="label">
                                Campaign:&nbsp;
                            </span>
                            <span className="campaignName">
                                {this.state.campaignName} :&nbsp;&nbsp;
                            </span>
                            {assignmentList.length > 0 ? assignmentList : <span className="campaignName">All Assignments</span>}
                        </div>
                        <div className='cancelButton' onClick={this.onCancel}>Cancel</div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {}
};

const mapStateToProps= (state) => {
    return {
        campaignState : state.campaign
    }
};

export default  withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderWithCampaignAndAssignment))


