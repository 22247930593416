export const AGENT = 'ROLE_AGENT';
export const BRAND_OWNER = 'ROLE_BRAND_OWNER';
export const INFLUENCER = 'ROLE_INFLUENCER';
export const SUPER_ADMIN = 'ROLE_SUPER_ADMIN';
export const TALENT_MANAGER = 'ROLE_TALENT_MANAGER';
export const VIEWER = 'ROLE_VIEWER';

export const SUPER_ADMIN_STR = "SUPER_ADMIN";
export const BRAND_OWNER_STR = "BRAND_OWNER";
export const INFLUENCER_STR = "INFLUENCER";
export const TALENT_MANAGER_STR = "TALENT_MANAGER";
export const AGENT_STR = "AGENT";
export const VIEWER_STR = "VIEWER";

export const DisplayRoleNames = {
    "SUPER_ADMIN": "Super Admin",
    "BRAND_OWNER": "Brand Owner",
    "INFLUENCER": "Influencer",
    "TALENT_MANAGER": "Talent Manager",
    "AGENT": "Agent",
    "VIEWER": "Viewer",
};
