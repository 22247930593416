import React, {useState, useEffect, useLayoutEffect} from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import cx from 'classnames'
import Modal from 'react-modal';
import Checkbox from '../../shared/checkbox2022/Checkbox';
import ListTab from './ListTab'
import SavedSearch from './SavedSearch'
import './listingTop.scss';
import SortButton from './SortButton';
import Format from "../../../modules/utils/Format";
import Api from '../../../modules/Api';
import ApiError from '../../shared/apiError/ApiError';
import Tooltip from '../../shared/tooltip/Tooltip'

const ListingTop = (props) => {
    const { allSelected,
        pageLimit,
        pageLimitOptions,
        permission,
        selectedCount,
        sort,
        sortOptions,
        validCount,
        addToCampaignFunction,
        changePageLimitFunction,
        selectAllFunction,
        sortTypeChange,
        favoriteList,
        orderType,
        orderDisabled,
        orderTypeChange,
        isFavoritesVisible,
        refreshSearch,
        refreshPlan,
        startIndex,
        endIndex,
        totalCount,
        favoriteData,
        inviteList,
        searchFilters,
        setSuccess,
        planId,
        currency,
        handleCurrencyChange,
        saveSearchFunction,
        existingSavedSearches,
        currencyList,
        publicButton
    } = props
    const [showAdd, setAdd] = useState(false);
    const [addState, setAddState] = useState(false);
    const [showSearch, setSearch] = useState(false);
    const [needRefresh, setNeedRefresh] = useState(false);
    const [apiError, setApiError] = useState(null);
    const [currentSocial, setSocial] = useState(null);
    const [handle, setHandle] = useState(null);
    const [publicModal, openModal] = useState(false)
    const [additionalModal, setSecondModal] = useState(false)
    const handleChange = item => {
        if(item.length === 0) {
            setHandle('')
        } else if (item[0] !== "@") {
            setHandle(`@${item}`)
        } else {
            setHandle(item)
        }
    }
    useLayoutEffect(() => {
        if (!showAdd && needRefresh) {
            setNeedRefresh(false);
            refreshSearch();
        }
    }, [showAdd, needRefresh]);
    
    const changeAddState = bool => {
        setAdd(bool)
        if (bool) {
            setSearch(false)
        }
        document.addEventListener('click', function(e){
            const addSection = document.getElementById('addSection');
            const addSectionOpen = document.getElementById('addSectionOpen');
            const addState = document.getElementById('addState');
            if (!addSection.contains(e.target) && !addSectionOpen.contains(e.target) && !addState.contains(e.target)) {
                setAdd(false)
            }
        })
    }
    const addToPlan = () => {
        if (Array.isArray(inviteList)) {
            const arePublicUsers = searchFilters?.type === 'PUBLIC';
            let channel;
            if (arePublicUsers && Array.isArray(searchFilters?.requiredChannels) && searchFilters?.requiredChannels.length > 0) {
                channel = searchFilters?.requiredChannels[0].toLowerCase();
            }
            const payload = {};
            let singleUser = true;
            if (arePublicUsers) {
                const users = [];
                inviteList.forEach((invite) => {
                    users.push(
                        {
                            userId: invite.uid,
                            userType: channel
                        }
                    )
                })
                payload.publicUsers = users;
                if (users.length > 1) {
                    singleUser = false;
                }
            } else {
                payload.connectedUserIds = inviteList.map(u => u.id);
                if (payload.connectedUserIds.length > 1) {
                    singleUser = false;
                }
            }
            Api.addUsersPlan(Number(planId), payload)
                .then(res => {
                    setSuccess(`Success! Added creator${singleUser ? '' : 's'} to plan`);
                    if (refreshPlan)
                        refreshPlan();
                })
                .catch(err => {
                    setApiError(err);
                })
        }
    }
    const excludeCreators = () => {
        if (Array.isArray(inviteList)) {
            const arePublicUsers = searchFilters?.type === 'PUBLIC';
            let channel;
            if (arePublicUsers && Array.isArray(searchFilters?.requiredChannels) && searchFilters?.requiredChannels.length > 0) {
                channel = searchFilters?.requiredChannels[0].toLowerCase();
            }
            const payload = {};
            let singleUser = true;
            if (arePublicUsers) {
                const users = [];
                inviteList.forEach((invite) => {
                    users.push(
                        {
                            userId: invite.uid,
                            userType: channel
                        }
                    )
                })
                payload.publicUsers = users;
                if (users.length > 1) {
                    singleUser = false;
                }
            } else {
                payload.connectedUserIds = inviteList.map(u => u.id);
                if (payload.connectedUserIds.length > 1) {
                    singleUser = false;
                }
            }
            Api.addUsersToPlanExclusion(Number(planId), payload)
                .then(res => {
                    setSuccess(`Success! Excluded creator${singleUser ? '' : 's'} to plan`);
                })
                .catch(err => {
                    setApiError(err);
                })
        }
    }
    const onSetSuccess = (message) => {
        setSuccess(message);
        if (addState === 'Exclusion lists' || addState === 'Favourite lists') {
            setNeedRefresh(true);
        }
    }
    const sortOrderChange = () => {
        const newOrder = orderType === 'asc' ? 'desc' : 'asc';
        orderTypeChange(newOrder);
    }

    const savedSearchFunc = (name, id, successCallback) => {
        saveSearchFunction(name, id, () => {
            setSearch(false);
            successCallback();
        })
    }
    const arePublicUsers = searchFilters?.type === 'PUBLIC';
    return (
        <div className="influencerListingTop2022">
            <ApiError
                show={apiError !== null}
                error={apiError}
                cancelFunction={() => setApiError(null)}
            />
            <div className="left">
                {/* Note: need to figure out permissions for select all */}
                {/* {validCount > 0 && permission && ( */}
                <div className="selectAll">
                    <Checkbox size={16} controlled changeFunction={selectAllFunction} checked={allSelected} />
                    <div className='selectAll-text'>{selectedCount > 0 && (<div className='selected-text'>Selected {selectedCount} -</div>)}Showing {Format.formatNumber(startIndex)}-
                        {Format.formatNumber(endIndex)} of{" "}
                        {Format.formatNumber(totalCount)} results</div>
                </div>
                {/* )} */}
            </div>
            <div className="middle">
                {/* {isFavoritesVisible && favoriteList && <div className="favoriteList">Favorite list: {favoriteList}</div>} */}
            </div>
            <div className="right">
                {arePublicUsers && publicButton && publicButton()}
                {
                    planId ?
                        <>
                            <div className="buttonSection show">
                                <div className={cx("listingButton-right addToPlan", {disabled: selectedCount === 0})} onClick={() => selectedCount > 0 ? addToPlan() : null} role='button'>
                                    <div className='listingButton-right-text'>Add to this plan</div>
                                </div>
                            </div>
                            {/* <div className="buttonSection show">
                                <div className={cx("listingButton-right", {disabled: selectedCount === 0})} onClick={() => selectedCount > 0 ? excludeCreators() : null} role='button'>
                                    <div className='listingButton-right-text'>Exclude creators</div>
                                </div>
                            </div> */}
                        </>
                        :
                        <div className={cx("buttonSection", {show: selectedCount > 0})}>
                            <div
                                className="listingButton-right"
                                onClick={() => changeAddState(!showAdd)}
                                id="addState"
                                role='button'
                            >
                                <div className='listingButton-right-text'>Add to...</div>
                            </div>
                            <div className={cx("addSection", { show: showAdd && !addState})} id="addSection">
                                <div onClick={() => setAddState("Favourite lists")} role='button'>Favourite lists</div>
                                <div onClick={() => setAddState("Exclusion lists")} role='button'>Exclusion lists</div>
                                {window.plans && <div onClick={() => setAddState("Plans")} role='button'>Plans</div>}
                            </div>
                            <ListTab show={showAdd && addState} type={addState} favoriteData={favoriteData} revert={() => setAddState(false)}
                                inviteList={inviteList} setSuccess={onSetSuccess} searchFilters={searchFilters}/>
                        </div>
                }
                {searchFilters.type === "CONNECTED" && <div className='right-container currency'>
                    <div className="labeling">Currency: </div>
                    <Select
                        className="select sort-select right-container-select"
                        clearable={false}
                        searchable={false}
                        value={currency}
                        single
                        placeholder="Select"
                        closeMenuOnSelect={false}
                        options={currencyList}
                        onChange={handleCurrencyChange}
                    />
                </div>}
                {searchFilters.type === "CONNECTED" && <Tooltip tooltipText="Rates displayed below are estimated values based on currency conversion rates last updated on 12/1/2022. 
                They are for reference only." image="/images/tooltipNew.svg" />}
                {!planId && <div className="buttonSection show">
                    <div className="listingButton-right" onClick={() => setSearch(!showSearch)} role='button'>
                        <div className='listingButton-right-icon'><img src="/images/bookmark.svg" alt="save" /></div>
                        <div className='listingButton-right-text'>Save search</div>
                    </div>
                    <SavedSearch 
                        show={showSearch} 
                        revert={() => setSearch(false)}
                        save={savedSearchFunc}
                        existingSavedSearches={existingSavedSearches || []}
                    />
                </div>}
                <div className='right-container'>
                    <img onClick={sortOrderChange} className={cx('right-container-sort-icon', orderType === "desc" ? "" : "flip")} src="/images/sort_down.svg" alt="sort" />
                    <Select
                        className="select sort-select right-container-select"
                        clearable={false}
                        searchable={false}
                        value={sort}
                        single
                        placeholder="Select"
                        closeMenuOnSelect={false}
                        options={sortOptions}
                        onChange={sortTypeChange}
                    />
                </div>
            </div>
        </div>
    )
};

ListingTop.propTypes = {
    allSelected: PropTypes.bool,
    pageLimit: PropTypes.number,
    pageLimitOptions: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.number,
            label: PropTypes.string,
        }),
    ),
    permission: PropTypes.bool,
    selectedCount: PropTypes.number,
    sort: PropTypes.string,
    sortOptions: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string,
        }),
    ),
    validCount: PropTypes.number,
    addToCampaignFunction: PropTypes.func,
    changePageLimitFunction: PropTypes.func,
    selectAllFunction: PropTypes.func,
    sortTypeChange: PropTypes.func,
    refreshSearch: PropTypes.func,
    refreshPlan: PropTypes.func,
    startIndex: PropTypes.number,
    endIndex: PropTypes.number,
    totalCount: PropTypes.number,
    saveSearchFunction: PropTypes.func,
    existingSavedSearches: PropTypes.arrayOf(PropTypes.string),
    inviteList: PropTypes.arrayOf(
        PropTypes.shape({
            fields: PropTypes.string,
            limit: PropTypes.number,
            page: PropTypes.number,
            sort: PropTypes.string,
            sortOrder: PropTypes.string,
            filters: PropTypes.arrayOf(PropTypes.string),
        })
    ),
    planId: PropTypes.string,
};

export default ListingTop;
