import React from 'react'
import {withRouter} from "react-router";
import './creatorHeader.scss';

class CreatorHeader extends React.Component {
    constructor( props,context) {
        super(props.context);

        this.state = {};
    }

    goBack = () => {
        this.props.history.push('/talentManagement');
    };

    render() {
        if (!this.props.profile) {
            return null;
        }
        return (
                <div className="creatorHeader">
                    <img className="back" src="/images/ic-long-back-b.svg" onClick={this.goBack} alt=""/>
                    <div className="name">{this.props.profile.firstname} {this.props.profile.lastname}</div>
                </div>)
    }
}

export default withRouter(CreatorHeader);