import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import { Warning } from 'phosphor-react';
import Select from 'react-select';
import cx from 'classnames';
import Format from '../../../../modules/utils/Format';
import PleaseWaitPartial from '../../../shared/pleaseWait/PleaseWaitPartial'
import exportInfluencerShippingAddress from '../../../../modules/influencer/exportInfluencerShippingAddress';
import ProfileInfo from './ProfileInfo';
import AssignmentInfo from './AssignmentInfo';
import Checkbox from '../../../shared/checkbox/Checbox';
import Api from '../../../../modules/Api';
import {
    ACCEPTED,
    COMPLETED,
    CONTRACT_UPLOADED,
    DECLINED,
    EXPIRED,
    NEGOTIATE,
    PENDING,
    REVOKED,
    PAID
} from '../../../../constants/statusTypes';
import Contract from '../../../shared/contract/contract';
import CustomizeContractPopup from '../../../shared/contract/CustomizeContractPopup';
import { AGENT_STR, BRAND_OWNER_STR, SUPER_ADMIN_STR, VIEWER_STR } from '../../../../constants/authorities';
import PopUpDialogueBase from '../../../shared/popUpDialogueBase/PopUpDialogueBase';
import ApiError from '../../../shared/apiError/ApiError';

// const permissions = ['ROLE_AGENT', 'ROLE_SUPER_ADMIN', 'ROLE_BRAND_OWNER'];

class InfluencerCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showActions: '',
            showButton: true,
            contractSelectedTemplate: null,
            contractAssignmentUserId: null,
            openContract: false,
            showCustomizeContractPopup: false,
            openError: false,
            loading: false,
            downloadProgressPercentage: null,
            showCurrentContract: false,
            showRevoke: false,
            showApiError: false,
            apiError: null,
            changeStatus: false,
            currentStatus: props?.details?.status || null
        };
    }

    componentDidMount() {
        const { assignmentId, details, permission, player } = this.props;
        const { status } = details;
        if ([PENDING].includes(status) && permission && assignmentId && ['AGENT'].includes(player)) {
            this.getCurrentContractTemplateMapping();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.closeAction);
    }

    closeAction = (e) => {
        if (this.state.showActions !== '' && !this.state.openContract) {
            this.setState({
                showActions: '',
            })
        }
        window.removeEventListener('click', this.closeAction);
    }

    getCurrentContractTemplateMapping() {

        // contract flow
        /**
         * 1.0 Open only for Agents
         * 2.0 Get the AssignmentUserDTO
         * 3.0 check if 'contract' property exit
         * 3.A If exist, get contract data and pass to <Contract /> component. STOP
         * 3.B If Not exist, Get the AssignmentDTO
         * 4.0 Check if 'defaultContract' property exist
         * 4.A If exist, get contract data and pass to <Contract /> component. STOP
         * 4.B If Not exist, Throw Error, "Default Template Not Set."
         *
         *
         * * */

        const{assignmentId, details: { user, isPublic }, player} = this.props;
        const self = this;

        // player check START
        // 1.0
        if(['AGENT'].includes(player) && !isPublic) {
            let assignmentUserId = null;

            // 2.0, 3.0
            let hasAssignmentUserDTO = false;
            Api.getAssignmentUser(assignmentId, user.uid)
                .then(
                    (res) => {
                        // 3.A
                        // console.log('success getAssignmentUser', res);
                        if(res && res.id) {
                            assignmentUserId = res.id;
                        }

                        if(res && res.contract && assignmentUserId) {
                            hasAssignmentUserDTO = true;
                            // console.log('res.contract', res.contract);
                            const cData = res.contract;
                            let dataObj = {};
                            try {
                                if (cData && cData.data && cData.data.mapping) {
                                    dataObj = JSON.parse(cData.data.mapping);
                                }
                            }catch(err) {
                            }
                            self.setState({
                                contractSelectedTemplate: {
                                    id: cData.data.contractTemplateId,
                                    name: cData.data.contractTemplateName,
                                    resource: cData.resource,
                                    data: dataObj,
                                },
                                contractAssignmentUserId: assignmentUserId,
                            });
                        }
                        this.getCurrentContractTemplateMappingFinnaly(hasAssignmentUserDTO, assignmentUserId);
                    },
                    () => {
                        // console.log('failed getAssignmentUser', err);
                        this.getCurrentContractTemplateMappingFinnaly(hasAssignmentUserDTO, assignmentUserId);
                    }
                )
                .catch(() => {
                    // console.log('failed getAssignmentUser', err);
                    this.getCurrentContractTemplateMappingFinnaly(hasAssignmentUserDTO, assignmentUserId);
                })
        }
        // player check END

    }

    getCurrentContractTemplateMappingFinnaly(hasAssignmentUserDTO, assignmentUserId) {
        // 3.B
        const{assignmentId} = this.props;
        const self = this;
        if (!hasAssignmentUserDTO) {
            let hasAssignmentDTO = false;
            Api.getAssignment(assignmentId)
                .then(
                    (res) => {
                        // console.log('success getAssignment', res);
                        // 4.0, 4.A
                        if (res && res.defaultContract && assignmentUserId) {
                            hasAssignmentDTO = true;
                            const cData = res.defaultContract;

                            self.setState({
                                contractSelectedTemplate: {
                                    id: cData.data.contractTemplateId,
                                    name: cData.data.contractTemplateName,
                                    resourceId: cData.resource.id,
                                    resource: cData.resource,
                                    data: JSON.parse(cData.data.mapping)
                                },
                                contractAssignmentUserId: assignmentUserId,
                            });
                        }

                        if (!hasAssignmentDTO) {
                            // 4.B
                            console.log('Default Template Not Set.');
                        }
                    },
                    () => {
                        // console.log('failed getAssignment', err);
                        if (!hasAssignmentDTO) {
                            // 4.B
                            console.log('Default Template Not Set.');
                        }
                    }
                )
                .catch(() => {
                    // console.log('failed getAssignment', err);
                    if (!hasAssignmentDTO) {
                        // 4.B
                        console.log('Default Template Not Set.');
                    }
                })
        }
    }

    handleOpenContractStatus = (e) => {
        const { assignmentId, details: { user: { id } }, handleOpenContractStatus } = this.props;
        handleOpenContractStatus(e, assignmentId, id);
    };

    handleOpenContractUpload = (e) => {
        const { assignmentId, details: { user: { id } }, handleOpenContractUpload } = this.props;
        handleOpenContractUpload(e, assignmentId, id);
    };

    handleOpenOtherDocuments = (e) => {
        const { assignmentId, details: { user: { id } }, handleOpenOtherDocuments } = this.props;
        handleOpenOtherDocuments(e, assignmentId, id);
    };

    handleCreatorEditing = () => {
        const { handleEditing } = this.props;

        if (handleEditing)
            handleEditing();

    }

    handleRevoke = () => {
        this.setState({ showRevoke: true });
    }

    onRevokeCancel = () => {
        this.setState({ showRevoke: false });
    }

    proceedRevoke = () => {
        const { details } = this.props;

        this.setState({
            loading: true
        })

        Api.revokeInfluencer(details.id)
            .then(res => {
                window.location.reload();
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    showApiError: true,
                    showRevoke: false,
                    apiError: err
                })
            })
    }

    onCancelError = () => {
        this.setState({
            showApiError: false,
            apiError: null
        })
    }

    startPropose = () => {
        const { assignmentId, details: { user }, negotiations: slots, assignmentTypes: types, startPropose, } = this.props;
        startPropose({ assignmentId, slots, types, user });
    }

    onMoreCLick = (e) => {
        const { id } = this.props.details;
        const { showActions } = this.state;
        if (showActions === id) {
            this.closeAction()
        }else {
            this.setState({
                showActions: id
            });
            setTimeout(()=> {
                window.addEventListener('click', this.closeAction);
            }, 300)
        }     
    }

    hideChangeRequestButton = () => this.setState({ showButton: false });

    exportBtnHandler = () => exportInfluencerShippingAddress(this.props.campaignId, this.props.details.user.id);

    exportFormHandler = () => {
        const { wformUploaded, details } = this.props;
        const payload = [details.user.id]
        if (wformUploaded) {
            this.setState({loading: true})
            return Api.getInfluencerWFormUrl(payload).then(res => {
                if(res && res.url) {
                    const updateFileProgress = (progressPercentage) => {
                        if (progressPercentage > 0) {
                            this.setState({downloadProgressPercentage: progressPercentage})
                        }
                    };
            
                    const updateFileFinished = (response) => {
                        // handle old data with no name & extensions
                        const ext = response.data && response.data.type ? Format.mimeToExt(response.data.type) : '';
                        const fileName = res.name==='File' ? `File${ext}` : res.name;
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', fileName); // or any other extension
                        document.body.appendChild(link);
                        link.click();
                        this.setState({ downloadProgressPercentage: null, loading: false });
                    };
            
                    updateFileProgress(1);
                    // Call gradual download
                    Api.getS3FileGradually(res.url,
                        (progressPercentage) => updateFileProgress(progressPercentage),
                        (response) => updateFileFinished(response));
        
                }else{
                    this.setState({loading: false, downloadProgressPercentage: null})
                }
            })
                .catch(err => {
                    this.setState({loading: false, downloadProgressPercentage: null})
                })
        } 
        this.setState({openError: true})
        
    }

    removeInfluencerFromAssignment = async () => {
        const {
            assignmentId,
            details: { id, isPublic, user: { uid: userId } },
            removeUserFromAssignment,
        } = this.props;
        try {
            if (!isPublic) {
                await Api.removeInfluencerFromAssignment(assignmentId, userId);
                removeUserFromAssignment(userId);
            } else {
                await Api.removePublicInfluencerFromAssignment(assignmentId, id)
                removeUserFromAssignment(id, true);
            }
        } catch (error) {
            console.log(error);
        }
    }

    closeError = () => {
        this.setState({openError: false})
    }

    closeCallback = (saved) => {
        this.setState({ openContract: false, showActions: '', showCurrentContract: false }, () => {
            if (saved) {
                window.location.reload();
            }
        });
    }

    openTemplate = () => {
        this.setState({
            showCurrentContract: true,
            openContract: false,
            showCustomizeContractPopup: false,
        });
    }

    closeCustomizeContractPopup = (needToRefresh) => {
        this.setState({showCustomizeContractPopup: false}, function() {
            if (needToRefresh) {
                this.getCurrentContractTemplateMapping();
            }
        })
    }

    updateStatus = bool => [
        this.setState({changeStatus: bool})
    ]

    submitStatusUpdate = async () => {
        const { currentStatus } = this.state;
        const { assignmentId, details, status, updateUserFromAssignment } = this.props;
        const data = {
            status: currentStatus
        }
        if (currentStatus !== status) {
            await Api.inviteInfluencerUpdatePublic(assignmentId, details.id, data)
            this.setState({changeStatus: false})
            updateUserFromAssignment(details.id, currentStatus)
        }
    }

    render() {
        const { showActions, showButton, openError, showRevoke, showApiError, apiError, changeStatus, currentStatus } = this.state;
        const { assignmentId, contractStatus, details, negotiations, permission, wformUploaded, profile, symbol, assignmentName } = this.props;
        const { campaignId, campaign, checkboxChangeHandler, isSelected, signerList } = this.props;
        const { assignmentSlots, assignmentSlotChangeRequests, id, status, statusDateTime, totalRate, totalReach, user, isPublic, networkUserId, 
            networkType } = details;
        const offlineContractUploaded = details.contractStatus === CONTRACT_UPLOADED;
        const { authenticityRating, firstname, id: userId, lastname, pictureUrl, avatarUrl, creatorName } = user
        const selectInfo = { userId, id, wformUploaded, userName: isPublic ? creatorName : `${firstname} ${lastname}` };
        const { contractSelectedTemplate, contractAssignmentUserId, showCustomizeContractPopup, showCurrentContract } = this.state;
        const linkToDetails = isPublic ? `/publicInfluencerDetailsPage/${networkUserId}/${networkType.toUpperCase()}` : `/influencerDetailPage/${userId}`;
        const contractPermission = profile && (profile.role === AGENT_STR || profile.role === SUPER_ADMIN_STR || profile.role === BRAND_OWNER_STR);
        const removePermission = profile && profile.role !== VIEWER_STR;
        const editInvitePermission = profile && profile.role !== VIEWER_STR;
        const revokePermission = profile && profile.role !== VIEWER_STR;
        const revokeMessage = (<div className='revokeMessage'>Are you sure you want to revoke the <span>invite for above</span> creator?</div>);
        const statusForPublic = [{label: 'Accepted', value: ACCEPTED}, {label: 'Pending', value: PENDING}, {label: 'Completed', value: COMPLETED}, 
            {label: 'Paid', value: PAID}, {label: 'Declined', value: DECLINED}, {label: 'Revoked', value: REVOKED}]
        return (
            <div
                className="influencerBox"
                ref={(e => this.container = e)}
            >
                <PleaseWaitPartial
                    show={this.state.loading}
                    container={this.container}
                    progressPercentage={this.state.downloadProgressPercentage}
                    onCancel={this.state.onUploadCancel}
                />
                <Modal
                    overlayClassName="influencerCardOverlay"
                    className="confirmRatesModal"
                    isOpen={openError}>
                    <div className="formError">
                        <div className="title">Missing form</div>
                        <div className="description">The following creator(s) have not uploaded their W8/W9 forms:</div>
                        <ul className="list">
                            <li>{selectInfo.userName}</li>
                        </ul>
                        <div className="buttonSection">
                            <div className="cancelButton" onClick={this.closeError}>Cancel</div>
                            <div className="continueButton" onClick={this.closeError}>Continue</div>
                        </div>
                    </div>
                </Modal>
                <Modal
                    overlayClassName="influencerCardOverlay"
                    className="confirmRatesModal"
                    isOpen={changeStatus}>
                    <div className="changeStatus">
                        <div className="header">Update Status</div>
                        <div className="middle">
                            <div className="information">
                                <div className="item">
                                    <div className="label">Campaign</div>
                                    <div className="value">{campaign && campaign.name}</div>
                                </div>
                                <div className="item">
                                    <div className="label">Assignment</div>
                                    <div className="value">{assignmentName}</div>
                                </div>
                                <div className="item">
                                    <div className="label">Creator</div>
                                    <div className="value">{creatorName}</div>
                                </div>
                                <div className="item">
                                    <div className="label">Status</div>
                                    <div className="value">{status}</div>
                                </div>
                            </div>
                            <div className="select">
                                <div className="title">Select Status</div>
                                <Select
                                    className="status"
                                    options={statusForPublic}
                                    value={currentStatus}
                                    onChange={e => this.setState({currentStatus: e.value})}
                                    clearable={false}
                                />
                            </div>

                        </div>
                        <div className="buttonSection">
                            <div className="cancel" onClick={() => this.updateStatus(false)}>Cancel</div>
                            <div className={cx("update", currentStatus === status && "disabled")} onClick={this.submitStatusUpdate}>Update</div>
                        </div>
                    </div>
                </Modal>
                <CustomizeContractPopup
                    showCustomizeContractPopup={showCustomizeContractPopup}
                    assignmentId={assignmentId}
                    assignmentUserId={contractAssignmentUserId}
                    signerList={signerList || []}
                    contractSelectedTemplate={contractSelectedTemplate}
                    closeFunction={(needToRefresh) => this.closeCustomizeContractPopup(needToRefresh)}
                    openTemplateFunction={this.openTemplate}
                />
                {showCurrentContract && 
                    <Contract
                        permission
                        selectedTemplate={contractSelectedTemplate}
                        level="agent-user"
                        assignmentId={assignmentId}
                        assignmentUserID={contractAssignmentUserId}
                        userId={userId}
                        styleType="customizeContractInDropDown"
                        openCallback={() => this.setState({openContract: true})}
                        closeCallback={this.closeCallback}
                        showCurrentContract={showCurrentContract}
                    />
                }
                <div className="checkbox">
                    {!isPublic && <div>
                        <Checkbox
                            id={`userCheckbox-${userId}`}
                            size={16}
                            controlled
                            changeFunction={() => checkboxChangeHandler(selectInfo)}
                            checked={isSelected}
                        />
                    </div>}
                </div>
                <ProfileInfo
                    authenticityRating={authenticityRating}
                    fullName={isPublic ? creatorName : `${firstname} ${lastname}`}
                    id={userId}
                    pictureUrl={isPublic ? avatarUrl : pictureUrl}
                    totalReach={totalReach}
                    firstName={isPublic ? creatorName : (firstname || '')}
                    lastName={lastname ?? ''}
                    isPublic={isPublic}
                    linkToDetails={linkToDetails}
                />
                <AssignmentInfo
                    user={details.user}
                    rates={details.rates}
                    deliverables={details.deliverables}
                    totalReach={totalReach}
                    campaignId={campaignId}
                    campaign={campaign}
                    assignmentId={assignmentId}
                    assignmentSlots={assignmentSlots}
                    assignmentSlotChangeRequests={assignmentSlotChangeRequests}
                    contractStatus={contractStatus}
                    firstName={firstname}
                    negotiations={negotiations}
                    status={status}
                    statusDateTime={statusDateTime}
                    showButton={showButton}
                    totalRate={totalRate}
                    handleOpenContractStatus={this.handleOpenContractStatus}
                    startPropose={this.startPropose}
                    profile={profile}
                    symbol={symbol}
                />
                <div className="more">
                    {permission &&
                        <img
                            src="/images/ic-more.svg"
                            alt="more"
                            onClick={this.onMoreCLick}
                        />
                    }
                    {
                        showActions === id && !isPublic &&
                        <div className="userOperations">
                            {([DECLINED, EXPIRED, REVOKED].includes(status) && removePermission) &&
                            <div
                                onClick={this.removeInfluencerFromAssignment}
                            >
                                Remove Creator
                            </div>
                            }
                            <div>
                                <Link to={linkToDetails} target="_blank">View Profile</Link>
                            </div>
                            <div
                                className="export-address-btn noBottom"
                                onClick={this.exportBtnHandler}
                            >
                                Export Address
                            </div>
                            <div
                                className="export-address-btn"
                                onClick={this.exportFormHandler}
                            >
                                Export W8/W9 form
                            </div>
                            {(contractPermission && [PENDING, NEGOTIATE, ACCEPTED].includes(status)) &&
                            <div onClick={this.handleOpenContractUpload} className="noBottom">
                                Offline Contract
                            </div>
                            }
                            <div onClick={this.handleOpenOtherDocuments} className="noBottom">
                                Other Documents
                            </div>
                            {contractPermission && !offlineContractUploaded && [ACCEPTED, COMPLETED].includes(status) &&
                            <div onClick={this.handleOpenContractStatus}>
                                View Online Contract Status
                            </div>
                            }
                            {contractPermission && contractSelectedTemplate &&
                            <Contract
                                permission
                                selectedTemplate={contractSelectedTemplate}
                                level="agent-user"
                                assignmentId={assignmentId}
                                assignmentUserID={contractAssignmentUserId}
                                userId={userId}
                                styleType="customizeContractInDropDown"
                                openCallback={() => this.setState({openContract: true})}
                                closeCallback={this.closeCallback}
                                customizeContractCallback={() => this.setState({showCustomizeContractPopup: true})}
                            />
                            }
                            {(editInvitePermission && [PENDING].includes(status)) &&
                            <div className="export-address-btn noBottom" onClick={this.handleCreatorEditing}>
                                Edit Deliverables and Cost
                            </div>
                            }
                            {revokePermission && [PENDING, NEGOTIATE].includes(status) &&
                            <div onClick={this.handleRevoke}>
                                Revoke Invite
                            </div>
                            }
                        </div>
                    }
                    {
                        showActions === id && isPublic &&
                        <div className="userOperations">
                            <div>
                                <Link to={linkToDetails} target="_blank">View Profile</Link>
                            </div>
                            <div onClick={this.handleOpenContractUpload} className="noBottom">
                                Offline Contract
                            </div>
                            <div onClick={this.handleOpenOtherDocuments} className="noBottom">
                                Other Documents
                            </div>
                            <div className="export-address-btn noBottom" onClick={this.handleCreatorEditing}>
                                Edit Deliverables and Cost
                            </div>
                            <div onClick={() => this.updateStatus(true)}>
                                Update Status
                            </div>
                            {contractPermission && contractSelectedTemplate &&
                            <Contract
                                permission
                                selectedTemplate={contractSelectedTemplate}
                                level="agent-user"
                                assignmentId={assignmentId}
                                assignmentUserID={contractAssignmentUserId}
                                userId={userId}
                                styleType="customizeContractInDropDown"
                                openCallback={() => this.setState({openContract: true})}
                                closeCallback={this.closeCallback}
                                customizeContractCallback={() => this.setState({showCustomizeContractPopup: true})}
                            />
                            }
                            <div
                                onClick={this.removeInfluencerFromAssignment}
                            >
                                Remove Creator
                            </div>
                        </div>
                    }
                </div>
                <PopUpDialogueBase
                    show={showRevoke}
                    icon={<Warning color="#111111" size={40} weight="fill" />}
                    title={selectInfo.userName}
                    message={revokeMessage}
                    proceedLabel="Revoke"
                    cancelLabel="Cancel"
                    proceedButtonStyle={{backgroundColor: '#d90036'}}
                    cancelFunction={this.onRevokeCancel}
                    proceedFunction={this.proceedRevoke}
                />
                <ApiError
                    show={showApiError}
                    error={apiError}
                    cancelFunction={this.onCancelError}
                />
            </div>
        );
    }
}

InfluencerCard.propTypes = {
    campaignId: PropTypes.number,
    campaignAssignmentId: PropTypes.string,
    assignmentId: PropTypes.number,
    assignmentTypes: PropTypes.arrayOf(PropTypes.string),
    contractStatus: PropTypes.bool,
    details: PropTypes.shape({
        assignmentSlots: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                dueData: PropTypes.string,
                status: PropTypes.string,
                type: PropTypes.string,
            })
        ),
        status: PropTypes.string,
        statusDateTime: PropTypes.string,
        totalRate: PropTypes.number,
        totalReach: PropTypes.number,
        user: PropTypes.shape({
            authenticityRating: PropTypes.number,
            firstname: PropTypes.string,
            id: PropTypes.number,
            lastname: PropTypes.string,
            pictureUrl: PropTypes.string,
            totalReach: PropTypes.number,
        })
    }),
    negotiations: PropTypes.array,
    permission: PropTypes.bool,
    handleOpenContractStatus: PropTypes.func,
    startPropose: PropTypes.func,
    startChatWithInfluencer: PropTypes.func,
    isSelected: PropTypes.bool,
    checkboxChangeHandler: PropTypes.func,
    removeUserFromAssignment: PropTypes.func,
    handleOpenContractUpload: PropTypes.func,
};

export default InfluencerCard;
