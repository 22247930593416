import React from 'react';
import { withRouter } from 'react-router';
import _ from 'lodash';
import cx from 'classnames';
import * as Honeybadger from 'honeybadger-js';
import { connect } from 'react-redux';
import Api from '../../../modules/Api';
import './campaignDetails.scss';
import Assignments from './Assignments';
import CreateAssignmentPopup from '../assignment/createAssigment/CreateAssignmentPopup';
import CopyAssignmentPopup from '../assignment/createAssigment/CopyAssignmentPopup';
import ConfirmModal from '../../shared/confirmModal/ConfirmModal';
import { getAssignmentList, refreshAssignmentList } from '../../../store/campaign/actions/campaignActions';
import DeleteCampaign from './DeleteCampaign';
import PleaseWait from '../../shared/pleaseWait/PleaseWaitWhite';
import ApiError from '../../shared/apiError/ApiError';
import CreateCampaign from '../CreateCampaign';
import { assignemntStatusType } from './assignemntStatusType';
import { AGENT_STR, BRAND_OWNER_STR, SUPER_ADMIN_STR } from '../../../constants/authorities';
import Tooltip from '../../shared/tooltip/Tooltip';

// const permissions = ['ROLE_AGENT', 'ROLE_SUPER_ADMIN', 'ROLE_BRAND_OWNER'];

class CampaignDetails extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.getCampaignDetails = this.getCampaignDetails.bind(this);
        this.loadCampaign = this.loadCampaign.bind(this);
        this.startCreation = this.startCreation.bind(this);
        this.stopCreation = this.stopCreation.bind(this);
        this.createCampaign = this.createCampaign.bind(this);
        this.startEdit = this.startEdit.bind(this);
        this.stopEdit = this.stopEdit.bind(this);
        this.saveCampaign = this.saveCampaign.bind(this);
        this.startDelete = this.startDelete.bind(this);
        this.stopDelete = this.stopDelete.bind(this);
        this.deleteCampaign = this.deleteCampaign.bind(this);
        this.handleCreateClick = this.handleCreateClick.bind(this);
        this.cancelCreate = this.cancelCreate.bind(this);
        this.saveAssignment = this.saveAssignment.bind(this);
        this.editAssignment = this.editAssignment.bind(this);
        this.showApiError = this.showApiError.bind(this);
        this.deleteAssignment = this.deleteAssignment.bind(this);
        this.deleteAssignmentDirectly = this.deleteAssignmentDirectly.bind(this);
        this.processProps = this.processProps.bind(this);
        this.showDropdown = this.showDropdown.bind(this);
        this.hideDropdown = this.hideDropdown.bind(this);

        this.initCopyAssignment = this.initCopyAssignment.bind(this);
        this.copyAssignment = this.copyAssignment.bind(this);
        this.cancelCopyAssignment = this.cancelCopyAssignment.bind(this);

        this.state = {
            waitingForCampaign: false,
            waitingForAssignments: false,
            creationInProgress: false,
            deleteInProgress: false,
            copyModalOpen: false,
            editMode: false,
            creationModalOpen: false,
            current: true,
            campaignOperationOpen: false,
            assignmentStatus: assignemntStatusType.default,
        };
        this.campaignId = this.props.match.params.campaignId;
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.campaignState, this.props.campaignState) && this.props.campaignState) {
            this.processProps(this.props);
        }
    }

    showApiError(error, errTitle) {
        this.setState({
            waitingForCampaign: false,
            waitingForAssignments: false,
            showApiError: true,
            apiError: error,
            errTitle,
        });
    }

    editAssignment(assignment, newAssignmentStatus) {
        this.setState({
            selectedAssignment: assignment,
            creationModalOpen: true,
            assignmentStatus: assignemntStatusType.default,
        });
    }

    loadCampaign() {
        const { campaignId } = this;
        this.props.refreshAssignmentList(campaignId);
        if (isNaN(campaignId)) {
            return;
        }
        this.getCampaignDetails(campaignId);
    }

    createCampaign(campaignData) {
        const self = this;
        Api.addCampaign(campaignData)
            .then((response) => {
                self.stopCreation();
            })
            .catch((err) => {
                self.showApiError(err);
                // console.log('adding wrong',err);
            });
    }

    startCreation() {
        this.setState({
            creationInProgress: true,
        });
    }

    stopCreation() {
        this.setState({
            creationInProgress: false,
        });
    }

    startEdit() {
        this.setState({
            // rerenderCount : self.state.rerenderCount + 1,
            editMode: true,
        });
    }

    stopEdit() {
        this.setState({
            editMode: false,
        });
    }

    saveCampaign(campaignData) {
        const self = this;
        Api.updateCampaign(this.state.campaign.id, campaignData)
            .then((response) => {
                // this.stopEdit();
                this.getCampaignDetails(this.state.campaign.id);
            })
            .catch((err) => {
                let errorFinal = err.message;
                let errTitle;
                if (err.originalMessage && err.originalMessage.includes('campaign_name_agency_id_unique_idx')) {
                    errorFinal = 'Campaign with this name already exists, please choose a different name.';
                    errTitle = ' ';
                }

                self.showApiError(errorFinal, errTitle);
                // console.log('adding wrong',err);
            });
        // this.getCampaignDetails(this.state.campaign.id);
    }

    deleteCampaign() {
        const self = this;
        Api.deleteCampaign(this.state.campaign.id)
            .then(() => {
                self.stopDelete();
                self.props.history.push('/campaign');
            })
            .catch((err) => {
                self.showApiError(err);
                // console.log('adding wrong',err);
            });
    }

    startDelete() {
        this.setState({
            deleteInProgress: true,
        });
    }

    stopDelete() {
        this.setState({
            deleteInProgress: false,
        });
    }

    handleContactChange(list) {
        this.campaign.contacts = list;
        this.handleChange();
    }

    cancelCreate() {
        this.setState({
            selectedAssignment: null,
            creationModalOpen: false,
        });
    }

    initCopyAssignment(assignment) {
        this.setState({
            selectedAssignment: assignment,
            copyModalOpen: true,
        });
    }

    cancelCopyAssignment() {
        this.setState({
            selectedAssignment: null,
            copyModalOpen: false,
        });
    }

    copyAssignment(aObject) {
        aObject.copy = {
            assignmentId: aObject.id,
            copyInfluencers: false,
            copyAssets: true,
        };
        Api.createAssignment(aObject).then(
            () => {
                this.setState(
                    {
                        campaign: null,
                        selectedAssignment: null,
                    },
                    this.loadCampaign,
                );
            },
            (error) => {
                this.showApiError(error);
            },
        );
    }

    saveAssignment(aObject) {
        const self = this;
        const callback = function () {
            self.setState(
                {
                    campaign: null,
                    selectedAssignment: null,
                },
                self.loadCampaign,
            );
        };
        this.setState({
            creationModalOpen: false,
        });
        if (this.state.selectedAssignment) {
            Api.updateAssignment(aObject.id, aObject)
                .then(callback)
                .catch((err) => this.showApiError(err));
        } else {
            Api.createAssignment(aObject)
                .then(callback)
                .catch((err) => this.showApiError(err));
        }
    }

    deleteAssignmentConfirmed = () => {
        const { id } = this.state.selectedAssignment;
        // console.log(id)
        this.setState({
            showConfirmDeleteAssignment: false,
            selectedAssignment: null,
        });
        Api.deleteAssignment(id)
            .then(() => {
                // console.log('a')
                // todo : refresh assignment list
                this.getCampaignDetails(this.state.campaign.id);
            })
            .catch((err) => this.showApiError(err));
    };

    handleCreateClick() {
        this.setState({
            creationModalOpen: true,
        });
    }

    injectSignerDetails = () => {
        // check if campaign data has signer data
        // if yes, get signer agent data from signer id string
        const { campaign } = this.state;
        if (campaign.signer && Number.isInteger(Number(campaign.signer))) {
            Api.getAgent(campaign.signer)
                .then((data) => {
                    this.setState((prevState) => {
                        return { campaign: { ...prevState.campaign, signerDetails: data } };
                    });
                })
                .catch((err) => this.showApiError(err));
        }
    };

    injectInstaStoryNumberFrames = () => {
        // original commit 8b7bf34a VladimirMaly <vladmaly7@gmail.com> on 2019-01-02 at 1:22 p.m.
        // moved 28-09-2020
        // would be expensive to get for all assignments all details,
        // so if assignmnet has INSTAGRAM_STORY, get more details, add numberOfFrames to assignment object
        if (
            this.state &&
            this.state.campaign &&
            this.state.campaign.assignments &&
            this.state.campaign.assignments.length > 0
        ) {
            for (let i = 0; i < this.state.campaign.assignments.length; i++) {
                const assignmentObj = this.state.campaign.assignments[i];
                for (let t = 0; t < assignmentObj.types.length; t++) {
                    if (assignmentObj.types[t] === 'INSTAGRAM_STORY') {
                        const tempIndex = i;
                        Api.getAssignment(Number(assignmentObj.id))
                            .then((result) => {
                                this.setState((prevState) => {
                                    const campaignObj = prevState.campaign;
                                    campaignObj.assignments[tempIndex].numberOfFrames = result.numberOfFrames;
                                    return { ...prevState, campaign: campaignObj };
                                });
                            })
                            .catch((error) => {
                                this.showApiError(error);
                            });
                    }
                }
            }
        }
    };

    getCampaignDetails(campaignId) {
        const { symbolArray } = this.state;
        this.setState({ waitingForCampaign: true });
        Api.getCampaign(Number(campaignId))
            .then((result) => {
                Honeybadger.setContext({
                    campaign: result,
                });
                this.setState(
                    {
                        waitingForCampaign: false,
                        campaign: { ...result, categoryNames: [...new Set(result.categoryNames)] },
                        editMode: false,
                    },
                    () => {
                        // this.injectSignerDetails();
                        // this.injectInstaStoryNumberFrames();
                    },
                );
            })
            .catch((error) => {
                this.showApiError(error);
            });
        const type = this.state.current ? 'current' : 'past';
        this.props.getAssignmentList(campaignId, type);
        if (!symbolArray) {
            Api.getCurrencyList().then((result) => {
                const { data } = result;
                const symbolList = {};
                data.forEach((x) => {
                    symbolList[x.code] = x.symbol;
                });
                this.setState({ symbolArray: symbolList });
            });
        }
    }

    deleteAssignmentDirectly(assigmentObj) {
        this.setState(
            {
                selectedAssignment: assigmentObj,
            },
            this.deleteAssignment(),
        );
    }

    changeCurrent(current) {
        this.setState({ current });
    }

    processProps(props) {
        const assignmentsObject = props.campaignState[`assignments${this.campaignId}`];
        if (!assignmentsObject) {
            return;
        }
        this.setState({
            waitingForAssignments: assignmentsObject.status !== 'ready',
            assignments: assignmentsObject.assignments,
        });
    }

    showDropdown(event) {
        event.preventDefault();
        this.setState({ campaignOperationOpen: true }, () => {
            document.addEventListener('click', this.hideDropdown);
        });
    }

    hideDropdown() {
        !this.isUnMounted &&
            this.setState({ campaignOperationOpen: false }, () => {
                document.removeEventListener('click', this.hideDropdown);
            });
    }

    isCampaignNotHaveAssignments() {
        return this.state.campaign.assignments && this.state.campaign.assignments.length < 1;
    }

    deleteAssignment = (id) => {
        this.setState({
            creationModalOpen: false,
            showConfirmDeleteAssignment: true,
        });
    };

    ApiErrorCancelFunction() {
        this.setState({
            waitingForCampaign: false,
            waitingForAssignments: false,
            showApiError: false,
            campaign: this.state.campaign || null,
            selectedAssignment: null,
            apiError: null,
            errTitle: '',
            errorFinal: '',
        });
    }

    componentDidMount() {
        this.processProps(this.props);
        this.loadCampaign();
        this.isUnMounted = false;
        setTimeout(() => {
            this.setState(() => {
                return { matchHeight: 'does not display' };
            });
        }, 500);
    }

    componentWillUnmount() {
        this.isUnMounted = true;
    }

    render() {
        if (!this.state.campaign) {
            return (
                <div>
                    <PleaseWait show={this.state.waitingForCampaign || this.state.waitingForAssignments} />
                    <ApiError
                        show={this.state.showApiError}
                        error={this.state.apiError}
                        cancelFunction={() => this.ApiErrorCancelFunction()}
                    />
                </div>
            );
        }
        const { campaign, assignments, symbolArray } = this.state;
        const { profile } = this.props;
        const userList = [];
        let totalCost = 0;
        campaign.assignments.map((assignment) => {
            if (assignment.users) {
                assignment.users.map((user) => {
                    totalCost += user.totalRate;
                    const index = userList.findIndex((a) => {
                        return a.uid === user.user.uid;
                    });
                    if (index > -1) {
                        userList[index].assignments.push(assignment.name);
                    } else {
                        userList.push({
                            uid: user.user.uid,
                            userName: `${user.user.firstname} ${user.user.lastname}`,
                            userUrl: user.user.pictureUrl,
                            assignments: [assignment.name],
                        });
                    }
                });
            }
        });

        const bugetString = campaign.budget ? campaign.budget.toString().replace(/(?=(?!\b)(\d{3})+$)/g, ',') : '0';
        const budgetExceed = totalCost > campaign.budget;
        const containerHeight = this.detailContainer && this.detailContainer.getBoundingClientRect().height;
        const windowHeight = window.innerHeight;
        const basedOnWindow =
            !containerHeight || (windowHeight && containerHeight && windowHeight - 200 > containerHeight);
        const permission =
            profile &&
            (profile.role === AGENT_STR || profile.role === SUPER_ADMIN_STR || profile.role === BRAND_OWNER_STR);
        let brandLogo = null;
        if (campaign && campaign.agencyBrand && campaign.agencyBrand.brandLogo) {
            brandLogo = campaign.agencyBrand.brandLogo;
        }
        const categoryNames = campaign.categoryNames;
        return (
            <div className="campaignDetails">
                <PleaseWait show={this.state.waitingForCampaign || this.state.waitingForAssignments} />
                <ConfirmModal
                    show={this.state.showConfirmDeleteAssignment}
                    message="Are you sure you want to delete this assignment?"
                    cancelFunction={() =>
                        this.setState({
                            showConfirmDeleteAssignment: false,
                            selectedAssignment: null,
                        })
                    }
                    actionText="DELETE"
                    proceedFunction={this.deleteAssignmentConfirmed}
                />
                <DeleteCampaign
                    show={this.state.deleteInProgress}
                    cancelFunction={this.stopDelete}
                    proceedFunction={this.deleteCampaign}
                />
                <CreateAssignmentPopup
                    show={this.state.creationModalOpen}
                    campaign={this.state.campaign}
                    statusOfAssignment={this.state.assignmentStatus}
                    cancelFunction={this.cancelCreate}
                    deleteFunction={this.deleteAssignment}
                    saveFunction={this.saveAssignment}
                    assignment={this.state.selectedAssignment}
                    otherAssignments={this.state.assignments}
                />
                <CopyAssignmentPopup
                    show={this.state.copyModalOpen}
                    campaign={this.state.campaign}
                    statusOfAssignment={this.state.assignmentStatus}
                    cancelFunction={this.cancelCopyAssignment}
                    saveFunction={this.copyAssignment}
                    assignment={this.state.selectedAssignment}
                    otherAssignments={this.state.assignments}
                />
                <CreateCampaign
                    show={this.state.editMode}
                    cancelFunction={this.stopEdit}
                    proceedFunction={this.saveCampaign}
                    campaign={campaign}
                />
                <ApiError
                    show={this.state.showApiError}
                    errTitle={this.state.errTitle}
                    error={this.state.apiError}
                    cancelFunction={() => this.ApiErrorCancelFunction()}
                />
                <div className="detailsHeader">
                    <div>
                        <div>
                            <span>Campaign: </span>
                            {campaign.name}
                        </div>
                        <div className="buttonCell">
                            {permission ? (
                                <div className="createButton controlButton" onClick={this.handleCreateClick}>
                                    <img src="/images/ic-plus-circle-b.svg" />
                                    <span>Create Assignment</span>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </div>
                <div className="campaignDetailsContainer">
                    <div>
                        <div className="detailsPanel" ref={(e) => (this.detailContainer = e)}>
                            <div className="detailsContent">
                                {brandLogo ? (
                                    <img src={brandLogo} />
                                ) : (
                                    <div className="brandLogo">{campaign.agencyBrand.brandName[0]}</div>
                                )}
                                <div className="label">Brand</div>
                                <div className="value">
                                    {(campaign.agencyBrand && campaign.agencyBrand.brandName) || ''}
                                </div>
                                <div className="label">Products</div>
                                <div className="value">
                                    {campaign.productNames.map((product) => (
                                        <div className="category">{product}</div>
                                    ))}
                                </div>
                                <div className="label">Product categories</div>
                                <div className="value">
                                    {campaign.categoryNames.map((product) => (
                                        <div className="category">{product}</div>
                                    ))}
                                </div>
                                <div className="label">Contact(s)</div>
                                <div className="value">
                                    {campaign.contacts.map((contact, i) => (
                                        <div key={i}>{`${contact.firstname} ${contact.lastname}`}</div>
                                    ))}
                                </div>
                                <div className="label">Contract Signer</div>
                                <div className="value">{`${
                                    campaign.signer ? `${campaign.signer.firstname} ${campaign.signer.lastname}` : '-'
                                }`}</div>
                                <div className={cx('label', { exceed: budgetExceed })}>
                                    Campaign Budget<span>(Exceeded)</span>
                                </div>
                                <div className={cx('value', { exceed: budgetExceed })}>
                                    {symbolArray && symbolArray[campaign.currencyCode]}
                                    {bugetString}
                                </div>
                                {/* <div className="label">Potential Savings<Tooltip tooltipText="sniff saving tooltip" image="/images/tooltipUser.svg" /></div> */}
                                {/* <div className="value">{symbolArray && symbolArray[campaign.currencyCode]}{ campaign.sniffSavings }</div> */}
                                {permission && (
                                    <div onClick={this.showDropdown} className="details-panel-option-container">
                                        <img src="/images/ic-more-vertical.svg" />
                                        {/* Need to add 'edit' button somewhere upon clicking options panel */}
                                    </div>
                                )}
                            </div>
                            <div className={cx('campaignOperation', { hidden: !this.state.campaignOperationOpen })}>
                                <div onClick={this.startEdit}>Edit Campaign</div>
                                {this.isCampaignNotHaveAssignments() && <div onClick={this.startDelete}>Delete</div>}
                            </div>
                        </div>

                        <div className={cx('assignmentsPanel', { past: !this.state.current })}>
                            <Assignments
                                assignments={campaign.assignments}
                                permission={permission}
                                current={this.state.current}
                                changeCurrent={(current) => this.changeCurrent(current)}
                                editFunction={this.editAssignment}
                                copyFunction={this.initCopyAssignment}
                                removeFunction={this.deleteAssignmentDirectly}
                                selectedAssignment={this.state.selectedAssignment}
                                assignmentHeight={basedOnWindow ? 'calc(100vh - 253px)' : `${containerHeight - 53}px`}
                                supplementalAssignments={assignments}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAssignmentList: (campaignId, type) => {
            dispatch(getAssignmentList(campaignId, type));
        },
        refreshAssignmentList: (campaignId) => {
            dispatch(refreshAssignmentList(campaignId));
        },
    };
};

const mapStateToProps = (state) => {
    return {
        profile: state.global.loggedInUserProfile,
        campaignState: state.campaign, // the assignments stuff for each campaign stored with key  assignments<campaignId>
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CampaignDetails));
