import React from 'react'

import './infuencerInput.scss'
import "../FloatingLabelInput/FloatingLabelInput.scss";
import classNames from 'classnames';

// props :
//  - label (string)
//  - placeholder (defaults to Label)
//  - inputType (defaults to '')
//  - valueChangeFunction (function taking a string value)
//  - frozenValue  if not empty, freezes the display with the given value (used in filtered dropdown)
//  - initialValue
//  - updatedValue   (the location component will want to change the displayed value)
//  - validateFunction  (returns error message
//  - autocomplete - input autocomplete prop value
//  takes 100% of the horizontal space  (unless otherwise styled by the classname influencerInput)

export default class InfluencerInput extends React.Component {

    constructor (props, context) {
        super(props, context);

        this.handleChange = this.handleChange.bind(this);

        this.state = {
            value : '',
            errorMessage: null
        }

        this.timeout = null;
    }

    checkValue = (value) => {
        this.setState({
            errorMessage : null,
            value
        })
        const change = () => {
            if (typeof this.props.valueChangeFunction === 'function') {
                this.props.valueChangeFunction(value);
            }
        }
        clearTimeout(this.timeout);
        const f = this.props.validateFunction;
        if (typeof f === 'function') {
            this.timeout = setTimeout( () => {
                const errorMessage = f(value);
                this.setState({
                    errorMessage
                }, change)
            }, 300)
        } else {
            change();
        }
    }

    handleChange(e) {
        const { handleChangeWithoutSelect } = this.props;
        const self = this;
        const {value} = e.target;
        this.checkValue(value);
        if (handleChangeWithoutSelect) {
            handleChangeWithoutSelect(e)
        }
    }

    componentDidMount() {
        const { initialValue } = this.props;
        if (initialValue) {
            this.setState({ value: initialValue})
        }
    }

    componentDidUpdate(prevProps) {
        const { updatedValue, clearState } = this.props;
        if (prevProps.updatedValue !== updatedValue && (updatedValue || updatedValue === '')) {
            this.setState({
                value: updatedValue
            })
        }
        if (prevProps.clearState !== clearState) {
            this.setState({
                value: ''
            })
        }
    }

    clearAll = () => {
        this.setState({value: ''})
    }

    render () {
        const {errorMessage, value} = this.state;
        const {frozenValue, required, autocomplete, placeholder} = this.props;
        const inputClasses = classNames('fl-input',{'fl-valid': (value || frozenValue) && !errorMessage}, {'fl-invalid': (value || frozenValue) && errorMessage});
        const errMsgClasses = classNames({'fl-error-msg': errorMessage}, {'fl-error-show' : (errorMessage && (value || frozenValue))});
        let labelTextClass = "labelText ";
        let {label} = this.props;
        if (value || frozenValue) {
            labelTextClass += ' full';
        } else {
            labelTextClass += 'empty';
            if (placeholder) {
                label = placeholder;
            }
        }

        return (
            <div className="influencerInput">
                <div className="fl-input-container">
                    <input
                        value={value}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        className={inputClasses}
                        autoComplete={autocomplete}
                    />
                    <label className={`fl-input-label${required ? ' required' : ''}`}>{label}</label>
                    {errorMessage && <div className={errMsgClasses}>{errorMessage}</div>}
                </div>
            </div>
        );
    }
}
