import React from 'react';
import { Page, Text, View, Image, Document, StyleSheet } from '@react-pdf/renderer';
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import Format from '../../modules/utils/Format';
import { formatPercentageToWhole } from '../../modules/utils/helpers';


// Create styles
const styles = StyleSheet.create({
    page: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#ffffff',
        width: '100%',
        height: '100%',
        paddingBottom: 42,
        paddingLeft: 30,
        paddingRight: 30
    },
    sectionTop: {
        marginTop: 25,
        marginBottom: 20,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row'
    },
    topRightText: {
        fontSize: 7,
        lineHeight: '10px',
        color: '#666666',
        paddingRight: 6,
        borderRightWidth: 1,
        borderRightColor: '#66C102'
    },
    rightSide: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    pageNumber: {
        color: '#333333',
        fontSize: 7,
        lineHeight: '10px',
        marginLeft: 6
    },
    summaryTitle: {
        fontWeight: 800,
        fontSize: 14,
        lineHeight: '19px',
        color: '#000000',
        marginBottom: 10
    },
    summarySection: {
        marginBottom: 20,
        backgroundColor: '#F6F7F8',
        padding: 15,
        display: 'flex',
        flexDirection: 'column'
    },
    summaryFirst: {
        paddingBottom: 12,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderBottomWidth: 1,
        borderBottomColor: '#CBCBCB',
        marginBottom: 12
    },
    planNameLabel: {
        fontSize: 9,
        lineHeight: '12px',
        color: '#666666'
    },
    planName: {
        color: '#111111',
        fontSize: 9,
        lineHeight: '12px',
        fontWeight: 700
    },
    summaryLine: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 6
    },
    summaryLeft: {
        fontSize: 8,
        lineHeight: '11px',
        color: '#666666'
    },
    summaryRight: {
        fontSize: 8,
        lineHeight: '11px',
        color: '#111111'
    },
    channelSelections: {
        display: 'flex',
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: '#CBCBCB',
        marginBottom: 20,
        marginRight: 20,
        width: 47
    },
    active: {
        fontSize: 8,
        lineHeight: '11px',
        fontWeight: 700,
        width: 47,
        color: '#00998C',
        paddingBottom: 4,
        borderBottomWidth: 3,
        borderBottomColor: '#00998C',
        display: 'flex',
        textAlign: 'center',
        marginRight: 20
    },
    inactive: {
        fontSize: 8,
        lineHeight: '11px',
        fontWeight: 700,
        paddingBottom: 7,
        width: 47,
        justifyContent: 'center',
        display: 'flex',
        textAlign: 'center',
        marginRight: 20
    },
    table: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 20
    },
    tableCustom: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: -30
    },
    header: {
        backgroundColor: '#F9F9F9',
        display: 'flex',
        paddingTop: 7.5,
        paddingLeft: 10,
        paddingRight: 8,
        paddingBottom: 6.5,
        flexDirection: 'row',
        alignItems: 'flex-end'
    },
    header1: {
        fontWeight: 600,
        fontSize: 8,
        lineHeight: '10px',
        width: 50,
        marginRight: 5.5,
        color: '#4F4F4F',
    },
    header2: {
        fontWeight: 600,
        fontSize: 8,
        lineHeight: '10px',
        width: 32,
        marginRight: 5.5,
        color: '#4F4F4F',
        textAlign: 'right'
    },
    header3: {
        fontWeight: 600,
        fontSize: 8,
        lineHeight: '10px',
        width: 41,
        marginRight: 5.5,
        color: '#4F4F4F',
        textAlign: 'right'
    },
    header4: {
        fontWeight: 600,
        fontSize: 8,
        lineHeight: '10px',
        width: 36,
        marginRight: 5.5,
        color: '#4F4F4F',
        textAlign: 'right'
    },
    header5: {
        fontWeight: 600,
        fontSize: 8,
        lineHeight: '10px',
        width: 33,
        marginRight: 5.5,
        color: '#4F4F4F',
        textAlign: 'right'
    },
    header6: {
        fontWeight: 600,
        fontSize: 8,
        lineHeight: '10px',
        width: 36,
        marginRight: 6,
        color: '#4F4F4F',
        textAlign: 'right'
    },
    header7: {
        fontWeight: 600,
        fontSize: 8,
        lineHeight: '10px',
        width: 36,
        marginRight: 5.5,
        color: '#4F4F4F',
        textAlign: 'right'
    },
    header8: {
        fontWeight: 600,
        fontSize: 8,
        lineHeight: '10px',
        width: 41,
        marginRight: 5.5,
        color: '#4F4F4F',
        textAlign: 'right'
    },
    header9: {
        fontWeight: 600,
        fontSize: 8,
        lineHeight: '10px',
        width: 41,
        marginRight: 5.5,
        color: '#4F4F4F',
        textAlign: 'right'
    },
    header10: {
        fontWeight: 600,
        fontSize: 8,
        lineHeight: '10px',
        width: 38,
        color: '#4F4F4F',
        textAlign: 'right',
        marginRight: 5.5
    },
    header11: {
        fontWeight: 600,
        fontSize: 8,
        lineHeight: '10px',
        width: 38,
        color: '#4F4F4F',
        textAlign: 'right',
        marginRight: 5.5
    },
    header12: {
        fontWeight: 600,
        fontSize: 8,
        lineHeight: '10px',
        width: 36,
        color: '#4F4F4F',
        textAlign: 'right'
    },
    items: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 7,
        paddingLeft: 10,
        paddingRight: 8,
        paddingBottom: 8,
        borderTopWidth: 1,
        borderTopColor: '#CECECE'
    },
    itemsB: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 7,
        paddingLeft: 10,
        paddingRight: 8,
        paddingBottom: 8,
        borderTopWidth: 1,
        borderTopColor: 'black'
    },
    item1: {
        fontWeight: 400,
        fontSize: 8,
        lineHeight: '11px',
        width: 50,
        marginRight: 5.5,
        color: '#333333',
    },
    item2: {
        fontWeight: 400,
        fontSize: 8,
        lineHeight: '11px',
        width: 32,
        marginRight: 5.5,
        color: '#333333',
        textAlign: 'right'
    },
    item3: {
        fontWeight: 400,
        fontSize: 8,
        lineHeight: '11px',
        width: 41,
        marginRight: 5.5,
        color: '#333333',
        textAlign: 'right'
    },
    item4: {
        fontWeight: 400,
        fontSize: 8,
        lineHeight: '11px',
        width: 36,
        marginRight: 5.5,
        color: '#333333',
        textAlign: 'right'
    },
    item5: {
        fontWeight: 400,
        fontSize: 8,
        lineHeight: '11px',
        width: 33,
        marginRight: 5.5,
        color: '#333333',
        textAlign: 'right'
    },
    item6: {
        fontWeight: 400,
        fontSize: 8,
        lineHeight: '11px',
        width: 36,
        marginRight: 5.5,
        color: '#333333',
        textAlign: 'right'
    },
    item7: {
        fontWeight: 400,
        fontSize: 8,
        lineHeight: '11px',
        width: 36,
        marginRight: 5.5,
        color: '#333333',
        textAlign: 'right'
    },
    item8: {
        fontWeight: 400,
        fontSize: 8,
        lineHeight: '11px',
        width: 41,
        marginRight: 5.5,
        color: '#333333',
        textAlign: 'right'
    },
    item9: {
        fontWeight: 400,
        fontSize: 8,
        lineHeight: '11px',
        width: 41,
        marginRight: 5.5,
        color: '#333333',
        textAlign: 'right'
    },
    item10: {
        fontWeight: 400,
        fontSize: 8,
        lineHeight: '11px',
        width: 38,
        color: '#333333',
        textAlign: 'right',
        marginRight: 5.5
    },
    item11: {
        fontWeight: 400,
        fontSize: 8,
        lineHeight: '11px',
        width: 38,
        color: '#333333',
        textAlign: 'right',
        marginRight: 5.5
    },
    item12: {
        fontWeight: 400,
        fontSize: 8,
        lineHeight: '11px',
        width: 36,
        color: '#333333',
        textAlign: 'right'
    },
    uniqueTitle: {
        fontWeight: 700,
        fontSize: 9,
        lineHeight: '13px',
        color: '#333333',
        marginBottom: 2
    },
    uniqueDesc: {
        fontWeight: 400,
        color: '#666666',
        fontSize: 8,
        lineHeight: '11px',
        marginBottom: 8
    },
    uheader1: {
        fontWeight: 600,
        fontSize: 8,
        lineHeight: '10px',
        width: 38,
        marginRight: 19.5,
        color: '#4F4F4F',
    },
    uheader2: {
        fontWeight: 600,
        fontSize: 8,
        lineHeight: '10px',
        width: 32,
        marginRight: 9,
        color: '#4F4F4F',
        textAlign: 'right'
    },
    uheader3: {
        fontWeight: 600,
        fontSize: 8,
        lineHeight: '10px',
        width: 41,
        marginRight: 9.5,
        color: '#4F4F4F',
        textAlign: 'right'
    },
    uheader4: {
        fontWeight: 600,
        fontSize: 8,
        lineHeight: '10px',
        width: 43,
        marginRight: 10,
        color: '#4F4F4F',
        textAlign: 'right'
    },
    uheader5: {
        fontWeight: 600,
        fontSize: 8,
        lineHeight: '10px',
        width: 36,
        marginRight: 10,
        color: '#4F4F4F',
        textAlign: 'right'
    },
    uheader6: {
        fontWeight: 600,
        fontSize: 8,
        lineHeight: '10px',
        width: 47,
        marginRight: 10,
        color: '#4F4F4F',
        textAlign: 'right'
    },
    uheader7: {
        fontWeight: 600,
        fontSize: 8,
        lineHeight: '10px',
        width: 38,
        marginRight: 10,
        color: '#4F4F4F',
        textAlign: 'right'
    },
    uheader8: {
        fontWeight: 600,
        fontSize: 8,
        lineHeight: '10px',
        width: 43,
        marginRight: 10,
        color: '#4F4F4F',
        textAlign: 'right'
    },
    uheader9: {
        fontWeight: 600,
        fontSize: 8,
        lineHeight: '10px',
        width: 58,
        marginRight: 10,
        color: '#4F4F4F',
        textAlign: 'right'
    },
    uheader10: {
        fontWeight: 600,
        fontSize: 8,
        lineHeight: '10px',
        width: 42,
        color: '#4F4F4F',
        textAlign: 'right'
    },
    uitem1: {
        fontWeight: 700,
        fontSize: 8,
        lineHeight: '11px',
        width: 38,
        marginRight: 19.5,
        color: '#333333',
    },
    uitem2: {
        fontWeight: 400,
        fontSize: 8,
        lineHeight: '11px',
        width: 32,
        marginRight: 9,
        color: '#333333',
        textAlign: 'right'
    },
    uitem3: {
        fontWeight: 400,
        fontSize: 8,
        lineHeight: '11px',
        width: 41,
        marginRight: 9.5,
        color: '#333333',
        textAlign: 'right'
    },
    uitem4: {
        fontWeight: 400,
        fontSize: 8,
        lineHeight: '11px',
        width: 43,
        marginRight: 10,
        color: '#333333',
        textAlign: 'right'
    },
    uitem5: {
        fontWeight: 400,
        fontSize: 8,
        lineHeight: '11px',
        width: 38,
        marginRight: 10,
        color: '#333333',
        textAlign: 'right'
    },
    uitem6: {
        fontWeight: 400,
        fontSize: 8,
        lineHeight: '11px',
        width: 47,
        marginRight: 10,
        color: '#333333',
        textAlign: 'right'
    },
    uitem7: {
        fontWeight: 400,
        fontSize: 8,
        lineHeight: '11px',
        width: 38,
        marginRight: 10,
        color: '#333333',
        textAlign: 'right'
    },
    uitem8: {
        fontWeight: 400,
        fontSize: 8,
        lineHeight: '11px',
        width: 43,
        marginRight: 10,
        color: '#333333',
        textAlign: 'right'
    },
    uitem9: {
        fontWeight: 400,
        fontSize: 8,
        lineHeight: '11px',
        width: 58,
        marginRight: 10,
        color: '#333333',
        textAlign: 'right'
    },
    uitem10: {
        fontWeight: 400,
        fontSize: 8,
        lineHeight: '11px',
        width: 42,
        color: '#333333',
        textAlign: 'right'
    },
    aggregateTitle: {
        fontWeight: 700,
        fontSize: 9,
        lineHeight: '13px',
        color: '#333333',
        marginBottom: 11
    },
    aggregateSection: {
        fontWeight: 700,
        fontSize: 9,
        lineHeight: '13px',
        color: '#333333',
        marginBottom: 5,
        paddingBottom: 5,
        borderBottomWidth: 1,
        borderBottomColor: '#CBCBCB'
    },
    audienceSection: {
        display: 'flex',
        flexDirection: 'column'
    },
    insightNameTop: {
        color: '#007D72',
        fontWeight: 700,
        fontSize: 8,
        lineHeight: '11px',
        marginBottom: 4
    },
    insightSection: {
        marginBottom: 25,
        display: 'flex',
        flexDirection: 'column'
    },
    insight: {
        width: 158,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginRight: 30,
        alignItems: 'center',
        marginBottom: 1.5,
        minHeight: 11
    },
    insightName: {
        fontSize: 8,
        lineHeight: '11px',
        color: '#4F4F4F',
        fontWeight: 400,
        maxWidth: 100
    },
    insightRight: {
        display: 'flex',
        flexDirection: 'row',
        fontSize: 8,
        lineHeight: '11px'
    },
    influencerTableHeader: {
        paddingLeft: 5,
        paddingBottom: 2,
        borderBottomWidth: 1,
        borderBottomColor: '#CBCBCB',
        display: 'flex',
        flexDirection: 'row'
    },
    influencer1: {
        width: 99,
        marginRight: 20,
        color: '#000000',
        fontWeight: 800,
        fontSize: 7,
        lineHeight: '8px'
    },
    influencer2: {
        color: '#000000',
        fontWeight: 800,
        fontSize: 7,
        lineHeight: '8px'
    },
    creator: {
        padding: 5,
        display: 'flex',
        flexDirection: 'row'
    },
    section1: {
        width: 99,
        marginRight: 20,
        display: 'flex',
        flexDirection: 'row'
    },
    label: {
        fontSize: 7,
        lineHeight: '9px',
        color: '#333333',
        marginRight: 2,
        width: 10
    },
    handle: {
        color: '#00998C',
        fontWeight: 700,
        fontSize: 7,
        lineHeight: '9px'
    },
    followings: {
        color: '#333333',
        fontSize: 7,
        lineHeight: '9px',
        textAlign: 'left'
    }
});

const filterCreatorsByPlatform = (selectedTab, creatorsGroup) => {
    const filteredCreators = creatorsGroup.filter((creator) =>
        creator?.userNetworks.some((network) => network.networkType === selectedTab),
    );
    return filteredCreators;
};

export const PlanReport = (data) => {
    const { plansData, auth, socials, features, unique, audience } = data
    const socialNames = []
    socials.map(item => {
        const name = item.platform.charAt(0).toUpperCase() + item.platform.slice(1)
        socialNames.push(name)
    })
    const allInfluencers = [...plansData.influencers, ...plansData.iqUsers]
    return (
        <Document>
            <Page size="A4" orientation="portrait" style={styles.page}>
                {renderInfo(plansData)}
                <Text style={styles.summaryTitle}>Summary</Text>
                <View style={styles.summarySection}>
                    <View style={styles.summaryFirst}>
                        <Text style={styles.planNameLabel}>Plan name</Text>
                        <Text style={styles.planName}>{plansData.name}</Text>
                    </View>
                    <View style={styles.summaryLine}>
                        <Text style={styles.summaryLeft}>Brand</Text>
                        <Text style={styles.summaryRight}>{plansData.brand.brandName}</Text>
                    </View>
                    <View style={styles.summaryLine}>
                        <Text style={styles.summaryLeft}>Platform</Text>
                        <Text style={styles.summaryRight}>{socialNames.join(', ')}</Text>
                    </View>
                    <View style={styles.summaryLine}>
                        <Text style={styles.summaryLeft}>Requested on</Text>
                        <Text style={styles.summaryRight}>{moment().format('MMMM D, YYYY - hh:mm a')}</Text>
                    </View>
                    <View style={styles.summaryLine}>
                        <Text style={styles.summaryLeft}>Requested by</Text>
                        <Text style={styles.summaryRight}>{`${auth.user.firstname} ${auth.user.lastname}`}</Text>
                    </View>
                </View>
                {renderChannelSelection(socialNames)}
                {renderTable(socials, features)}
                {unique && renderUniqueTable(unique)}
                {renderAudience(audience)}
                {renderCreators(allInfluencers)}
            </Page>
            {socials.map(social => (
                <Page size="A4" orientation="portrait" style={styles.page}>
                    {renderInfo(plansData)}
                    {renderChannelSelection(socialNames, social.platform)}
                    {renderTable([social])}
                    {unique && renderUniqueTable(unique, social.platform)}
                    {renderAudience(social.aggregatedAudienceInsightsGroups)}
                    {renderCreators(filterCreatorsByPlatform(social.platform, allInfluencers))}
                </Page>
            ))}
        </Document>
    )
}

const renderCreators = (creators) => {
    return (
        <View>
            <View style={styles.aggregateSection} fixed>
                <Text>Creators</Text>
            </View>
            <View fixed style={styles.influencerTableHeader}>
                <Text style={styles.influencer1}>Account</Text>
                <Text style={styles.influencer2}>Total Followers</Text>
            </View>
            {creators.map((influencer, i) => {
                let handle = ''
                if (influencer.userNetworks[0]?.socialHandle) {
                    if (influencer.userNetworks[0].socialHandle.startsWith("@")) {
                        handle = `${influencer.userNetworks[0]?.socialHandle}`
                    } else {
                        handle = `@${influencer.userNetworks[0]?.socialHandle}`
                    }
                } else {
                    handle = `${influencer?.firstname} ${influencer?.lastname}`
                }
                const stylesBasedOnIndex = i % 2 === 1 ? {...styles.creator, backgroundColor: '#F6F7F8'} : styles.creator
                return (
                    <View style={stylesBasedOnIndex} wrap={false}>
                        <View style={styles.section1}>
                            <View style={styles.label}>
                                <Text>{i + 1}</Text>
                            </View>
                            <Text style={styles.handle}>{handle}</Text>
                        </View>
                        <Text style={styles.followings}>
                            {influencer.userNetworks[0]?.followings ? influencer.userNetworks[0]?.followings.toLocaleString() : ''}</Text>
                    </View>
                )
            })}
        </View>
    )
}

const renderAudience = (audience) => {
    const audienceGeo = audience.find(item => item.aggregatedAudienceInsightsGroupType === 'GEOGRAPHY')
    const audienceDemo = audience.find(item => item.aggregatedAudienceInsightsGroupType === 'DEMOGRAPHICS')
    const audienceInterest = audience.find(item => item.aggregatedAudienceInsightsGroupType === 'INTERESTS')
    let geoMax = 0
    let demoMax = 0
    let interestMax = 0
    audienceGeo && audienceGeo.aggregatedAudienceInsights.map(insights => {
        if (insights.audienceInsights.length > geoMax) {
            geoMax = insights.audienceInsights.length
        }
    })
    audienceDemo && audienceDemo.aggregatedAudienceInsights.map(insights => {
        if (insights.audienceInsights.length > demoMax) {
            demoMax = insights.audienceInsights.length
        }
    })
    audienceInterest && audienceInterest.aggregatedAudienceInsights.map(insights => {
        if (insights.audienceInsights.length > interestMax) {
            interestMax = insights.audienceInsights.length
        }
    })
    return (
        <View style={styles.tableCustom}>
            <Text fixed style={styles.aggregateTitle}>Aggregated Audience Insights</Text>
            <View style={styles.audienceSection}>
                <View>
                    <View style={styles.aggregateSection} fixed>
                        <Text>Geography</Text>
                    </View>
                    <View style={{flexDirection: 'row'}}>{audienceGeo && audienceGeo.aggregatedAudienceInsights.map(insights => renderInsights(insights, geoMax))}</View>
                </View>
                <View>
                    <View style={styles.aggregateSection} fixed>
                        <Text>Demographics</Text>
                    </View>
                    <View style={{flexDirection: 'row'}}>{audienceDemo && audienceDemo.aggregatedAudienceInsights.map(insights => renderInsights(insights, demoMax))}</View>
                </View>
                <View>
                    <View style={styles.aggregateSection} fixed>
                        <Text>Interests</Text>
                    </View>
                    <View style={{flexDirection: 'row'}}>{audienceInterest && audienceInterest.aggregatedAudienceInsights.map(insights => renderInsights(insights, interestMax))}</View>
                </View>
            </View>
        </View>
    )
}

const renderInsights = (insight, maximum) => {
    const diff = maximum - insight.audienceInsights.length
    const diffArray = [...Array(diff).keys()]
    return (
        <View style={styles.insightSection}>
            <Text style={styles.insightNameTop}>{insight.name}</Text>
            {insight.audienceInsights.map(insight => {
                let valueToUse = insight.weight * 100;
                if (valueToUse > 0 && valueToUse < 1) {
                    valueToUse = '<1%'
                } else {
                    valueToUse = `${(insight.weight * 100).toFixed(0)  }%`
                }
                return (
                    <View style={styles.insight} wrap={false}>
                        <Text style={styles.insightName}>{insight.name}</Text>
                        <View style={styles.insightRight}>
                            <Text style={{color: '#1D1D1D', marginRight: 4}}>{valueToUse}</Text>
                            <Text style={{color: '#666666'}}>{`(${(Format.expressInK(insight.weightedValue))})`}</Text>
                        </View>
                    </View>
                )
            })}
            {insight.audienceInsights.length === 0 && <View style={styles.insight} wrap={false}>
                <Text style={styles.insightName}>No data found</Text>
            </View>}
            {diffArray.length > 0 && diffArray.map(filler => (<View style={styles.insight} />))}
        </View>
    )
}

const renderUniqueTable = (socials, name) => {
    let result = socials
    if (name) {
        result = socials.filter(item => item.platform === name)
        if (result.length === 0) {
            return null
        }
    }
    return (
        <>
            <Text style={styles.uniqueTitle}>Unique Reach Analysis on Top Creators</Text>
            <Text style={styles.uniqueDesc}>Only available for Instagram and YouTube. Analysis includes top creators to up to 300 million 
                followers.</Text>
            <View style={styles.table}>
                <View style={styles.header}>
                    <View style={styles.uheader1}>
                        <Text>Platform</Text>
                    </View>
                    <View style={styles.uheader2}>
                        <Text>Creators</Text>
                    </View>
                    <View style={styles.uheader3}>
                        <Text>Total reach</Text>
                    </View>
                    <View style={styles.uheader4}>
                        <Text>Unique Reach</Text>
                    </View>
                    <View style={styles.uheader5}>
                        <Text>Avg Weighted Eng. Rate</Text>
                    </View>
                    <View style={styles.uheader6}>
                        <Text>Forecasted Unique View</Text>
                    </View>
                    <View style={styles.uheader7}>
                        <Text>Avg Weighted Eng Rate</Text>
                    </View>
                    <View style={styles.uheader8}>
                        <Text>Forecasted Unique Eng</Text>
                    </View>
                    <View style={styles.uheader9}>
                        <Text>Avg.Weighted Spon. Eng. Rate</Text>
                    </View>
                    <View style={styles.uheader10}>
                        <Text>Forecasted Unique Spon. Eng.</Text>
                    </View>
                </View>
                {result.map((item, i) => (renderUniqueItems(item, i)))}
            </View>
        </>
    )
}

const renderUniqueItems = (item, i) => {
    const items = []
    const name = item.platform.charAt(0).toUpperCase() + item.platform.slice(1)
    items.push(name)
    const firstItem = item.uniqueReachFeatures.find(i => i.uniqueReachFeatureType === "CREATORS")
    const secondItem = item.uniqueReachFeatures.find(i => i.uniqueReachFeatureType === "TOTAL_REACH")
    const thirdItem = item.uniqueReachFeatures.find(i => i.uniqueReachFeatureType === "UNIQUE_REACH")
    const fourthItem = item.uniqueReachFeatures.find(i => i.uniqueReachFeatureType === "AVG_ENGAGEMENT_RATE")
    const fifthItem = item.uniqueReachFeatures.find(i => i.uniqueReachFeatureType === "FORECASTED_UNIQUE_ENGAGEMENT")
    const sixthItem = item.uniqueReachFeatures.find(i => i.uniqueReachFeatureType === "AVG_SPONSORED_ENGAGEMENT_RATE")
    const seventhItem = item.uniqueReachFeatures.find(i => i.uniqueReachFeatureType === "FORECASTED_UNIQUE_SPONSORED_ENGAGEMENTS")
    const eighthItem = item.uniqueReachFeatures.find(i => i.uniqueReachFeatureType === "AVG_VIEW_RATE")
    const ninthItem = item.uniqueReachFeatures.find(i => i.uniqueReachFeatureType === "FORECASTED_UNIQUE_VIEWS")
    items.push(firstItem && firstItem.weightedValue !== null ? Format.expressInK(firstItem.weightedValue) : '-')
    items.push(secondItem  && secondItem.weightedValue !== null ? Format.expressInK(secondItem.weightedValue) : '-')
    items.push(thirdItem && thirdItem.weightedValue !== null ? Format.expressInK(thirdItem.weightedValue) : '-')
    items.push(fourthItem && fourthItem.weight !== null ? `${(fourthItem.weight * 100).toFixed(0)  }%` : '-%')
    items.push(fifthItem && fifthItem.weightedValue !== null ? Format.expressInK(fifthItem.weightedValue) : '-')
    items.push(sixthItem && sixthItem.weightedValue !== null ? (sixthItem.weight * 100).toFixed(0) : '-%')
    items.push(seventhItem && seventhItem.weightedValue !== null ? Format.expressInK(seventhItem.weightedValue) : '-')
    items.push(eighthItem && eighthItem.weight !== null ? `${(eighthItem.weight * 100).toFixed(0)  }%` : '-%')
    items.push(ninthItem && ninthItem.weightedValue !== null ? Format.expressInK(ninthItem.weightedValue) : '-')
    const styleContainer = {}
    styleContainer.style1 = styles.uitem1
    styleContainer.style2 = styles.uitem2
    styleContainer.style3 = styles.uitem3
    styleContainer.style4 = styles.uitem4
    styleContainer.style5 = styles.uitem5
    styleContainer.style6 = styles.uitem6
    styleContainer.style7 = styles.uitem7
    styleContainer.style8 = styles.uitem8
    styleContainer.style9 = styles.uitem9
    styleContainer.style10 = styles.uitem10
    const styleToUse = i === 0 ? {...styles.items, borderTopColor: 'black'} : styles.items
    return (
        <View style={styleToUse}>
            {items.map((item, i) => {
                const stringForm = `style${i + 1}`
                return (
                    <View style={styleContainer[stringForm]}>
                        <Text>{item}</Text>
                    </View>
                )
            })}
        </View>
    )
}

const renderTable = (socials, features) => {
    return (
        <View style={styles.table}>
            <View style={styles.header}>
                <View style={styles.header1}>
                    <Text>Platform</Text>
                </View>
                <View style={styles.header2}>
                    <Text>Creators</Text>
                </View>
                <View style={styles.header3}>
                    <Text>Total reach</Text>
                </View>
                <View style={styles.header4}>
                    <Text>Weighted Avg. Cred Score</Text>
                </View>
                <View style={styles.header5}>
                    <Text>Est. Real Follower Count</Text>
                </View>
                <View style={styles.header6}>
                    <Text>Avg. Weighted Eng Rate</Text>
                </View>
                <View style={styles.header7}>
                    <Text>Avg. Weighted Eng</Text>
                </View>
                <View style={styles.header8}>
                    <Text>Avg. Weighted Sponsored{'\n'}Eng Rate</Text>
                </View>
                <View style={styles.header9}>
                    <Text>Avg. Weighted Sponsored Eng</Text>
                </View>
                <View style={styles.header10}>
                    <Text>Avg. Weighted View Rate</Text>
                </View>
                <View style={styles.header11}>
                    <Text>Avg. Weighted Views</Text>
                </View>
                <View style={styles.header12}>
                    <Text>Avg. Weighted Eng. Rate by Views</Text>
                </View>
            </View>
            {features && renderItems(features, true)}
            {socials && socials.map(socialItem => renderItems(socialItem))}
        </View>
    )
}

const renderItems = (item, type) => {
    const items = []
    if (type) {
        items.push('All Platforms')
        const firstItem = item.find(i => i.planSummaryFeatureType === "CREATORS")
        const secondItem = item.find(i => i.planSummaryFeatureType === "TOTAL_REACH")
        items.push(firstItem && firstItem.weightedValue ? Format.expressInK(firstItem.weightedValue) : '-')
        items.push(secondItem && secondItem.weightedValue? Format.expressInK(secondItem.weightedValue) : '-')
    } else {
        const name = item.platform.charAt(0).toUpperCase() + item.platform.slice(1)
        items.push(name)
        const firstItem = item.planSummaryFeatures.find(i => i.planSummaryFeatureType === "CREATORS")
        const secondItem = item.planSummaryFeatures.find(i => i.planSummaryFeatureType === "TOTAL_REACH")
        const thirdItem = item.planSummaryFeatures.find(i => i.planSummaryFeatureType === "AVG_AUDIENCE_CREDIBILITY")
        const fourthItem = item.planSummaryFeatures.find(i => i.planSummaryFeatureType === "ESTIMATED_REAL_FOLLOWERS")
        const fifthItem = item.planSummaryFeatures.find(i => i.planSummaryFeatureType === "AVG_ENGAGEMENT_RATE")
        const sixthItem = item.planSummaryFeatures.find(i => i.planSummaryFeatureType === "AVG_ENGAGEMENT")
        const seventhItem = item.planSummaryFeatures.find(i => i.planSummaryFeatureType === "AVG_SPONSORED_ENGAGEMENT_RATE")
        const eighthItem = item.planSummaryFeatures.find(i => i.planSummaryFeatureType === "AVG_SPONSORED_ENGAGEMENT")
        const ninthItem = item.planSummaryFeatures.find(i => i.planSummaryFeatureType === "AVG_VIEW_RATE")
        const tenthItem = item.planSummaryFeatures.find(i => i.planSummaryFeatureType === "AVG_VIEWS")
        const eleventhItem = item.planSummaryFeatures.find(i => i.planSummaryFeatureType === "ENGAGEMENT_VIEWS")
        items.push(firstItem && firstItem.weightedValue !== null ? Format.expressInK(firstItem.weightedValue) : '-')
        items.push(secondItem  && secondItem.weightedValue !== null ? Format.expressInK(secondItem.weightedValue) : '-')
        items.push(thirdItem && thirdItem.weight !== null ?  `${(thirdItem.weight * 100).toFixed(0)  }%` : '-%')
        items.push(fourthItem && fourthItem.weightedValue !== null ? Format.expressInK(fourthItem.weightedValue) : '-')
        items.push(fifthItem && fifthItem.weight !== null ? `${(fifthItem.weight * 100).toFixed(0)  }%`: '-%')
        items.push(sixthItem && sixthItem.weightedValue !== null ? Format.expressInK(sixthItem.weightedValue) : '-')
        items.push(seventhItem && seventhItem.weight ? `${formatPercentageToWhole(seventhItem.weight*100)}%`: '-%')
        items.push(eighthItem && eighthItem.weightedValue !== null ? Format.expressInK(eighthItem.weightedValue) : '-')
        items.push(ninthItem && ninthItem.weight !== null ? `${(ninthItem.weight * 100).toFixed(0)  }% `: '-%')
        items.push(tenthItem && tenthItem.weightedValue !== null ? Format.expressInK(tenthItem.weightedValue) : '-')
        items.push(eleventhItem && eleventhItem.weight && eleventhItem.weight !== null ? `${(eleventhItem.weight * 100).toFixed(0)  }% `: '-%')
    }
    const styleContainer = {}
    styleContainer.style1 = type ? {...styles.item1, fontWeight: 700} : styles.item1
    styleContainer.style2 = type ? {...styles.item2, fontWeight: 700} : styles.item2
    styleContainer.style3 = type ? {...styles.item3, fontWeight: 700} : styles.item3
    styleContainer.style4 = type ? {...styles.item4, fontWeight: 700} : styles.item4
    styleContainer.style5 = type ? {...styles.item5, fontWeight: 700} : styles.item5
    styleContainer.style6 = type ? {...styles.item6, fontWeight: 700} : styles.item6
    styleContainer.style7 = type ? {...styles.item7, fontWeight: 700} : styles.item7
    styleContainer.style8 = type ? {...styles.item8, fontWeight: 700} : styles.item8
    styleContainer.style9 = type ? {...styles.item9, fontWeight: 700} : styles.item9
    styleContainer.style10 = type ? {...styles.item10, fontWeight: 700} : styles.item10
    styleContainer.style11 = type ? {...styles.item11, fontWeight: 700} : styles.item11
    styleContainer.style12 = type ? {...styles.item12, fontWeight: 700} : styles.item12
    return (
        <View style={type ? styles.itemsB : styles.items}>
            {items.map((item, i) => {
                const stringForm = `style${i + 1}`
                return (
                    <View style={styleContainer[stringForm]}>
                        <Text>{item}</Text>
                    </View>
                )
            })}
        </View>
    )
}

const renderChannelSelection = (socials, name) => {
    return (
        <View style={styles.channelSelections}>
            <View style={!name ? styles.active : styles.inactive}>
                <Text>
                    All Channels
                </Text>
            </View>
            {socials.map(social => (
                <View style={name === social.toLowerCase() ? styles.active : styles.inactive}>
                    <Text>
                        {social}
                    </Text>
                </View>
            ))}
        </View>
    )
}

const renderInfo = (info) => {
    const { name } = info;
    return (
        <View style={styles.sectionTop} fixed>
            <Image src="/images/logo.png" style={{ width: 30 }} />
            <View style={styles.rightSide}>
                <Text style={styles.topRightText}>{name}</Text>
                <Text fixed style={styles.pageNumber} render={({ pageNumber }) => (`${(pageNumber).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})}`)} />
            </View>
        </View>
    );
};
