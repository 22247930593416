import {
    REQUEST_INITIAL_DATA,
    UPDATE_DATA,
    SET_DATA,
    SET_ERROR,
} from './actions';

export const requestInitialData = () => ({
    type: REQUEST_INITIAL_DATA,
});

export const updateData = () => ({
    type: UPDATE_DATA,
});

export const setData = (payload) => ({
    type: SET_DATA,
    payload,
});

export const setError = (message) => ({
    type: SET_ERROR,
    message,
});
