import React from 'react';
import './CreatorContacts.scss';
import ReachIcon from '../shared/ReachIcon/ReachIcon';
import Tooltip from '../shared/tooltip/Tooltip'

const CreatorContacts = ({ contacts, currentNetwork }) => {

    if (!contacts || contacts.length === 0)
        return null;

    const getPrettyName = (name) => {
        switch (name) {
            case "instagram":
                return "Instagram";
            case "tiktok":
                return "TikTok";
            case "youtube":
                return "YouTube";
            default:
                return name;
        }
    }

    const publicNetworkTypes = ["instagram", "tiktok", "youtube"];
    let socialNetworkContacts = contacts.filter(c => publicNetworkTypes.includes(c.type.toLowerCase())).map(c => {
        const isRecognized = c.value !== null && !(c.value.includes("?") || c.value.includes("&") || c.value.includes("=") || c.value.includes("/"));
        return {
            type: c.type.toLowerCase(),
            isRecognized: isRecognized,
            value: isRecognized ? c.value : c.formattedValue
        };
    });

    if (currentNetwork) {
        socialNetworkContacts = socialNetworkContacts.filter(c => c.type !== currentNetwork);
    }

    if (!socialNetworkContacts || socialNetworkContacts.length === 0)
        return null;

    const toolTipText = "The other social media accounts get captured from the information creators have provided in their Bio or the last 30-60 post captions.";

    return (
        <div className="creatorContacts">
            <div className="contactsTitleBox">
                <div className="contactsTitle">
                    Related Social Channels
                </div>
                <Tooltip tooltipText={toolTipText} image="/images/tooltipUser.svg" lessWidth />
            </div>
            <div className="contactsData">
                {socialNetworkContacts.map(c => c.isRecognized ?
                    <a className="contactItem" href={`/publicInfluencerDetailsPage/${c.value}/${c.type.toUpperCase()}?Public`} target="_blank">
                        <ReachIcon network={c.type} value={1} size="16" />
                        <div className="contactLabel">
                            {getPrettyName(c.type)}
                        </div>
                    </a>
                    : <a className="contactItem" href={`${c.value}`} target="_blank">
                    <ReachIcon network={c.type} value={1} size="16" />
                    <div className="contactLabel">
                        {getPrettyName(c.type)}
                    </div>
                </a>
                )}
            </div>
        </div>
    )
};

export default CreatorContacts;
