import React, { Component } from 'react';
import FileSaver from 'file-saver';
import RichContentEditor from '../../shared/contentEditor/RichContentEditor';
import ResourceDownload from '../../shared/resourcesDownload/resourcesDownload';
import PleaseWait from '../../shared/pleaseWait/PleaseWaitWhite';
import Format from '../../../modules/utils/Format';
import Api from '../../../modules/Api';
import './AssignmentDetails.scss';
import { safeIsUrl } from '../../../modules/CommonServices';
import { CONTRACT_UPLOADED } from '../../../constants/statusTypes';
import { TALENT_MANAGER_STR } from '../../../constants/authorities';
import Lookup from '../../../modules/Lookup';


export default class AssignmentADetails extends Component {
    constructor(props) {
        super(props);
        this.state = { contract: false, waiting: false, assignmentUser: null };
    }

    downloadContract = () => {
        const { assignment, uid, profile } = this.props;
        const { contract, assignmentUser } = this.state;
        if (contract === 'OFFLINE') {
            const assignmentUserId = assignmentUser.id;
            const {signedDocumentId} = assignmentUser.contract;
            // Api.contractOfflineDownload(assignmentUserId, signedDocumentId).then((response) => {
            //     FileSaver.saveAs(response, 'Contract File');
            // });
            this.setState({waiting: true})
            Api.contractOfflineDownloadUrl(assignmentUserId, signedDocumentId).then(res => {
                if(res && res.url) {
                    const updateFileProgress = (progressPercentage) => {
                        if (progressPercentage > 0) {
                            this.setState({downloadProgressPercentage: progressPercentage})
                        }
                    };
            
                    const updateFileFinished = (response) => {
                        // handle old data with no name & extensions
                        const ext = response.data && response.data.type ? Format.mimeToExt(response.data.type) : '';
                        const fileName = res.name==='File' ? `File${ext}` : res.name;
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', fileName); // or any other extension
                        document.body.appendChild(link);
                        link.click();
                        this.setState({ downloadProgressPercentage: null, waiting: false });
                    };
            
                    updateFileProgress(1);
                    // Call gradual download
                    Api.getS3FileGradually(res.url,
                        (progressPercentage) => updateFileProgress(progressPercentage),
                        (response) => updateFileFinished(response));
        
                }else{
                    this.setState({waiting: false, downloadProgressPercentage: null})
                }
    
            })
                .catch(err => {
                    this.setState({waiting: false, downloadProgressPercentage: null})
                })
        } else {
            const userUid = profile.role && profile.role === TALENT_MANAGER_STR ? assignmentUser.user.uid : uid;
            Api.contractEnvelopeFile(assignment.id, userUid)
                .then((res) => {
                    const newBlob = new Blob([res], { type: "application/pdf" });
                    FileSaver.saveAs(newBlob, "Contract File.pdf");
                    this.setState({waiting: false, downloadProgressPercentage: null})
                })
                .catch(err => {
                    this.setState({waiting: false, downloadProgressPercentage: null})
                });
        }
    };

    componentDidMount() {
        this.setState({ waiting: true });
        const { assignment, uid, profile } = this.props;
        if (profile && profile.role === TALENT_MANAGER_STR) {
            const params = new URLSearchParams(this.props.location.search);
            const influencerId = params.get('influencerId');
            Api.getTMAssignment(assignment.id, influencerId)
                .then((res) => {
                    if (res.contract && res.contract.status === CONTRACT_UPLOADED) {
                        this.setState({ contract: 'OFFLINE', waiting: false, assignmentUser: res });
                    } else if (res.user && res.user.uid) {
                        Api.contractEnvelopeStatus(assignment.id, res.user.uid).then(
                            () => {
                                this.setState({ contract: true, waiting: false, assignmentUser: res });
                            },
                            (error) => {
                                this.setState({ waiting: false });
                            },
                        );
                    } else {
                        this.setState({ waiting: false });
                    }
                })
                .catch((err) => this.setState({ waiting: false }));
        } else {
            Api.getAssignmentUser(assignment.id, uid)
                .then((res) => {
                    if (res.contract && res.contract.status === CONTRACT_UPLOADED) {
                        this.setState({ contract: 'OFFLINE', waiting: false, assignmentUser: res });
                    } else {
                        Api.contractEnvelopeStatus(assignment.id, uid).then(
                            () => {
                                this.setState({ contract: true, waiting: false, assignmentUser: res });
                            },
                            () => {
                                this.setState({ waiting: false });
                            },
                        );
                    }
                })
                .catch((err) => this.setState({ waiting: false }));
        }
    }

    renderFramesInfo(chore, index, count) {
        return (
            <div key={chore.id} className="framesInfoText">
                <div style={{marginRight: "20px"}}>•</div>{`(${index + 1}/${count}) - ${chore.numberOfFrames} frames`}
            </div>
        );
    }

    renderSingleDeliverableInfo(type, count) {
        if (count <= 0) {
            return null;
        }
        return (
            <div key={type} className="choreContainer" style={{padding: 0}}>
                <div className="choreLabel">
                    {`${Lookup.getRateType(type).label} x ${count}`}
                </div>
            </div>
        );
    }

    renderAssignmentDeliverablesInfo() {
        const { assignmentUser } = this.state;
        if (!assignmentUser || !assignmentUser.deliverables)
            return null;

        return (
            <div className="brandUrls">
                <div className="title" style={{marginBottom: "10px"}}>
                    Assignment Deliverables:
                </div>
                {Object.entries(assignmentUser.deliverables).map(([key, value]) => this.renderSingleDeliverableInfo(key, value))}
            </div>
        );
    }

    render() {
        const { assignment } = this.props;
        const { contract } = this.state;
        let brandLogo = "/images/brandLogo.png";
        if (assignment && assignment.campaign && assignment.campaign.brandImage) {
            brandLogo = assignment.campaign.brandImage;
        }
        return (
            <div className="assignmentDetails">
                <PleaseWait show={this.state.waiting} />
                <div className="header">
                    {assignment.campaign && (
                        <img
                            src={brandLogo}
                            className="logo"
                        />
                    )}
                    {assignment.name}
                </div>
                <div className="summary">
                    <div className="title">Assignment Summary</div>
                    <RichContentEditor className="richContentEditor" readOnly content={assignment.summary} />
                </div>

                {Array.isArray(assignment.checklist) && assignment.checklist.length > 0 && (
                    <div className="checklist">
                        <div className="title">Checklist:</div>
                        <ol>
                            {assignment.checklist.map((item, i) => {
                                return <li key={`cl-${  i}`}>{item}</li>;
                            })}
                        </ol>
                    </div>
                )}
                {((Array.isArray(assignment.brandHandles) && assignment.brandHandles.length > 0) ||
                    (Array.isArray(assignment.programHashtags) && assignment.programHashtags.length > 0)) && (
                    <div className="socialNetworkOptions">
                        <div className="title">Social Network Options:</div>
                        {Array.isArray(assignment.brandHandles) && assignment.brandHandles.length > 0 && (
                            <div className="brandHandles">
                                <div className="subtitle">Brand Handles:</div>
                                {assignment.brandHandles.map((item, i) => {
                                    return (
                                        <div className="brandHandle" key={`bh-${  i}`}>
                                            <span>{item.type.toLowerCase()}: </span>
                                            {item.handle}
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                        {Array.isArray(assignment.programHashtags) && assignment.programHashtags.length > 0 && (
                            <div className="programHashtags">
                                <div className="subtitle">Program hashtags:</div>
                                {assignment.programHashtags.map((item, i) => {
                                    return (
                                        <div className="programHashtag" key={`ph-${  i}`}>
                                            {item}
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                )}

                {Array.isArray(assignment.brandUrls) && assignment.brandUrls.length > 0 && (
                    <div className="brandUrls">
                        <div className="title">Brand Urls:</div>
                        <ol>
                            {assignment.brandUrls.map((item, i) => {
                                return item.url && safeIsUrl(item.url) ? (
                                    <li key={i}>
                                        <a href={Format.webAddress(item.url)} target="_blank">
                                            {item.url}
                                        </a>
                                        <div>{item.description}</div>
                                    </li>
                                ) : null;
                            })}
                        </ol>
                    </div>
                )}

                {this.renderAssignmentDeliverablesInfo()}

                {(Boolean(assignment.ftcDisclaimer) || Boolean(assignment.promotionalText)) && (
                    <div className="additionalOptions">
                        <div className="title">Additional Options:</div>
                        {Boolean(assignment.ftcDisclaimer) && (
                            <div className="ftcDisclaimer">
                                <div className="subtitle">FTC Disclaimer:</div>
                                <div>{assignment.ftcDisclaimer}</div>
                            </div>
                        )}
                        {Boolean(assignment.promotionalText) && (
                            <div className="promotionalText">
                                <div className="subtitle">Promotional Text:</div>
                                <div>{assignment.promotionalText}</div>
                            </div>
                        )}
                    </div>
                )}
                {Array.isArray(assignment.resources) && assignment.resources.length > 0 && (
                    <div className="resources">
                        <div className="title">Resources:</div>
                        <ResourceDownload resources={assignment.resources} assignmentId={assignment.id} />
                    </div>
                )}
                {contract && (
                    <div>
                        <div className="title contractDownload" onClick={this.downloadContract}>
                            Download Contract
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
