import React, { Component } from 'react';
import Select from 'react-select';

export default class Filter extends Component {
    pageSizes = [
        { value: 10, label: '10 per page' },
        { value: 20, label: '20 per page' },
        { value: 50, label: '50 per page' },
    ];

    state = {
        filter: 'active',
        pageSize: this.pageSizes[0],
    }


    handleChange = filter => {
        const { handleFiltersChange } = this.props;
        const isArchived = filter === 'archived';

        this.setState({ filter });
        handleFiltersChange(isArchived);
    }

    handlePageSizeChange = ({ value }) => {
        const { handlePageSize } = this.props;
        this.setState({ pageSize: value });
        handlePageSize(value);
    }

    handleSearch = (event) => {
        const { value } = event.target;
        const { onSearch } = this.props;

        onSearch(value);
    }

    render = () => {
        const { filter } = this.state;

        return (
            <div className="filter-subheader">
                <div className="contentDiv">
                    <div className="header">
                        <div className="left-block">
                            <div
                                onClick={() => this.handleChange('active')}
                                className={filter === 'active' ? 'active': ''}
                            >
                                Active
                            </div>
                            <div
                                onClick={() => this.handleChange('archived')}
                                className={filter === 'archived' ? 'active': ''}
                            >
                                Archive
                            </div>
                        </div>
                        {/* <div className="right-block">
                            <div className="limitOption"> 
                                <Select className="select"
                                    clearable={false}
                                    searchable={false}
                                    value={this.pageSize}
                                    single
                                    placeholder="Page size"
                                    options={this.pageSizes}
                                    onChange={this.handlePageSizeChange}
                                />
                            </div>
                            
                        </div> */}
                    </div>
                </div>
            </div>
        )
    }
}