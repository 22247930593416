import React from 'react';

function EmptyCard({ title, subtitle }) {
    return (
        <div className="empty-table">
            <div className="empty-icon-container">
                <div className="empty-icon-background">
                    <img src="/images/ic-comment-g.svg" />
                </div>
            </div>
            <div className="empty-title">{title}</div>
            <div className="empty-subtitle">{subtitle}</div>
        </div>
    );
}

export default EmptyCard;
