import React from 'react';

const StagwellPixel = (props) => (
    <div className="ssp">
        <section>
            <div className="badgeNumber"><span>1</span></div>
            <div className="containerBlog">
                <div className="titleBlog">Locate header</div>
                <div className="contentBlog">Locate the header file of your website. It should be something like <span className="select">header.html</span> or <span className="select">header.php</span></div>
            </div>
        </section>
        <section>
            <div className="badgeNumber"><span>2</span></div>
            <div className="containerBlog">
                <div className="titleBlog">Copy the following tracking code</div>
                <textarea  readOnly className="codeBlog" id="myPixelCopy" defaultValue={props.pixelCode}>
                </textarea>
                <div onClick={()=>props.myPixelFunction()} className="pixelButton">COPY CODE</div>
            </div>
        </section>
        <section>
            <div className="badgeNumber"><span>3</span></div>
            <div className="containerBlog">
                <div className="titleBlog">Find <span className="nt">&lt;/head&gt;</span> tag</div>
                <div className="contentBlog">Find the <span className="nt">&lt;/head&gt;</span> tag in this file and paste tracking code just before <span className="nt">&lt;/head&gt;</span> tag.</div>
            </div>
        </section>
    </div>
);

export default StagwellPixel;