import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import query from 'query-string';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import TopNavigation from '../../shared/topNavigation/TopNavigation';
import './style.scss';
import Brand from "./brand";
import BrandsList from './brandsList';
import BrandModal from './BrandModal';
import utils from './utils';
import constants from './constants';
import operations from './operations';
import Api from '../../../modules/Api';
import ApiError from '../../shared/apiError/ApiError';
import ProfileHelper from '../../../modules/utils/ProfileHelper';
import {getAgentBrands} from "../../../store/agent/actions";
import AddBrandCountryModal from './BrandModal/AddBrandCountryModal';

class AdminBrandsPage extends React.Component {

    constructor(props) {
        const { initialState } = constants;
        super(props);
        this.state = {
            ...initialState,
            advertiserIds: [],
            addBrandCountryModalContent: null
        };
    }

    onChangeStatement = (callback) => {
        this.setState(callback)
    };

    componentDidMount = async () => {
        const { onInitializeData } = operations;
        const { search } = this.props.location;
        const parsedQuery = query.parse(search);
        const { advertiserId } = parsedQuery;
        try {
            const profile = await ProfileHelper.getCurrentLoginUserProfile();
            const advertiserIds = [];
            const resAdvertisers = await Api.getMyAdvertisers();
            if (!advertiserId) {
                resAdvertisers.forEach(a => advertiserIds.push(a.id));
            } else {
                advertiserIds.push(advertiserId);
            }
            this.setState({ profile, advertiserIds });
            const curried = onInitializeData(this.onChangeStatement, resAdvertisers, advertiserIds);
            await curried(advertiserId, this.onFilterByAdvertiser);
        } catch(err) {
            console.log(err);
        }
    }

    handleOpenModal = (val) => this.setState({ showModal: val, modalType: 'create' });

    handleAddBrandCountry = (val) => this.setState({ addBrandCountryModalContent: val });

    onCloseAddCountryModal = () => this.setState({ addBrandCountryModalContent: null });

    onCloseModal = () => this.setState({ showModal: false });

    /** operation can be either create or edit a brand */
    onManipulateBrand = async (brandId, data, operationType) => {
        const { brandOperations } = operations;
        const { brandsList, advertiserIds } = this.state;
        const { getAgentBrands } = this.props;
        const curried = brandOperations(this.onChangeStatement, brandsList, advertiserIds);
        await curried(operationType, data, brandId);
        getAgentBrands();
    };

    onAddBrandCountry = async (parentBrandId, data) => {
        const { addBrandCountryOperation } = operations;
        const { brandsList, advertiserIds } = this.state;
        const { getAgentBrands } = this.props;
        const curried = addBrandCountryOperation(this.onChangeStatement, brandsList, this.onCloseAddCountryModal, advertiserIds);
        await curried(data, parentBrandId);
        getAgentBrands();
    };

    /** on change page limit */
    onChangeNumPerPage = async (option) => {
        const { convertParamsData } = utils;
        const { onChangePageLimit } = operations;
        const { filters, sortBy, advertiserIds } = this.state;
        const data = {
            ...convertParamsData(filters),
            sortBy: Object.entries(sortBy).map(([key, value]) => [key, value])[0],
        };
        const curried = onChangePageLimit(this.onChangeStatement, data, advertiserIds);
        curried(option);
    };

    /** on change brands status */
    onChangeBrandsStatus = (selectedStatus) => () => {
        const { convertParamsData } = utils;
        const { onChangeBrandsStatus: onChange } = operations;
        const { filters, sortBy, advertiserIds } = this.state;
        const data = {
            ...convertParamsData(filters),
            sortBy: Object.entries(sortBy).map(([key, value]) => [key, value])[0],
        };
        const curried = onChange(this.onChangeStatement, data, advertiserIds)
        curried(selectedStatus);
    };

    /** pagination */
    onChangePage = (pageNumber) => {
        const { convertParamsData } = utils;
        const { onChangePage } = operations;
        const { filters, sortBy, advertiserIds } = this.state;
        const data = {
            ...convertParamsData(filters),
            sortBy: Object.entries(sortBy).map(([key, value]) => [key, value])[0],
        };
        window.scrollTo(0, 0);
        const curriedOnChangePage = onChangePage(this.onChangeStatement, data, advertiserIds);
        curriedOnChangePage(pageNumber);
    };
    /** ----- */

    //   onChangeFilterValue = async (selectedOption) => {
    //       const { convertParamsData } = utils;
    //       const { filters } = this.state;
    //       const { onChangeFilterVariable } = operations;
    //       const data = convertParamsData(filters);
    //       const curried = onChangeFilterVariable(this.onChangeStatement, data);
    //       curried(selectedOption);
    //   };

    /** filter by advertiser */
    onFilterByAdvertiser = async (object) => {
        const id = get(object, 'id', '');
        const value = get(object, 'value', '');
        const payload = { id, value };
        const { convertParamsData } = utils;
        const { onAdvertiserFilter } = operations;
        const { filters, sortBy, advertiserIds } = this.state;
        const data = {
            ...convertParamsData(filters),
            sortBy: Object.entries(sortBy).map(([key, value]) => [key, value])[0],

        };
        const curried = onAdvertiserFilter(this.onChangeStatement, data, advertiserIds);
        curried(payload);
    };

    onFilterByBrand = async (object) => {
        const payload = { searchString: object };
        const { convertParamsData } = utils;
        const { onBrandChange } = operations;
        const { filters, sortBy, advertiserIds } = this.state;
        const data = {
            ...convertParamsData(filters),
            sortBy: Object.entries(sortBy).map(([key, value]) => [key, value])[0],

        };
        const curried = onBrandChange(this.onChangeStatement, data, advertiserIds);
        curried(payload);
    };
    /** ----- */

    // onSortByField = async (object) => {
    //     const { convertParamsData } = utils;
    //     const
    // }

    /** archiveBrand and update list */
    onArchiveBrand = async (id) => {
        const { filters, advertiserIds } = this.state;
        const params = utils.convertParamsData(filters);

        try {
            await Api.archiveBrand(id);
            const { results, meta } = await Api.getUserBrands(params, advertiserIds);

            const brandsList = results.map(utils.convertBrandItem);
            this.setState({
                brandsList,
                pagination: {
                    currentPage: 1,
                    total: meta.totalCount
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    onUnarchiveBrand = async (id) => {
        const { filters, advertiserIds } = this.state;
        const params = utils.convertParamsData(filters);

        try {
            await Api.unarchiveBrand(id);
            const { results, meta } = await Api.getUserBrands(params, advertiserIds);

            const brandsList = results.map(utils.convertBrandItem);
            this.setState({
                brandsList,
                pagination: {
                    currentPage: 1,
                    total: meta.totalCount
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    onSortBy = async (field) => {
        this.setState({ isLoading: true });

        const { sortBy, filters, pagination, advertiserIds } = this.state;
        const { brandItemsFactory, convertParamsData } = utils;
        let sortByValue;
        let response;

        if (sortBy[field] === undefined) {
            sortByValue = false;
        } else {
            sortByValue = !sortBy[field];
        }

        try {
            response = await Api.getUserBrands({
                sortBy: [ field, sortByValue ],
                ...convertParamsData(filters),
            }, advertiserIds)
        } catch (error) {
            console.log(error);
        }

        this.setState((prevState) => ({
            ...prevState,
            sortBy: {
                [field]: sortByValue,
            },
            brandsList: brandItemsFactory(response.results),
            isLoading: false,
            pagination: { ...pagination, currentPage: 1 },
        }));
    }

    handleCloseModal = () => {
        this.setState({
            showModal: false,
            brandToEdit: null,
        });
    };

    handleSetBrandToEdit = (brand = {}) => {
        this.setState({
            brandToEdit: brand,
            showModal: true,
            modalType: 'edit',
        });
    };

    hideError = () => this.setState({ hasError: false, errorMessage: '' });

    render() {
        const {
            brandToEdit,
            showModal,
            modalType,
            brandsList,
            userAdvertisers,
            // userBrandOwners,
            filterValue,
            pagination,
            filters: { advertiserValue, brandsStatusValue, numPerPage },
            isLoading,
            profile,
            sortBy,
            hasError,
            errorMessage,
            addBrandCountryModalContent
        } = this.state;
        const role = get(profile, 'role', '');
        const { match } = this.props;

        return (
            <div className="admin-brands-page admin-management-page talent-management">
                <TopNavigation current='/adminBrands' />

                <div>
                    <Switch>
                        <Route
                            exact
                            path={`${match.url}`}
                            render={() => (
                                <BrandsList
                                    isLoading={isLoading}
                                    pagination={pagination}
                                    brandsList={brandsList}
                                    userAdvertisers={userAdvertisers}
                                    filterValue={filterValue}
                                    advertiserFilterValue={advertiserValue}
                                    brandsStatusValue={brandsStatusValue}
                                    numPerPage={numPerPage}
                                    onArchiveBrand={this.onArchiveBrand}
                                    onUnarchiveBrand={this.onUnarchiveBrand}
                                    onChangeNumPerPage={this.onChangeNumPerPage}
                                    onChangeBrandsStatus={this.onChangeBrandsStatus}
                                    onFilterByAdvertiser={this.onFilterByAdvertiser}
                                    onSortBy={this.onSortBy}
                                    handleOpenModal={this.handleOpenModal}
                                    handleSetBrandToEdit={this.handleSetBrandToEdit}
                                    handleAddBrandCountry={this.handleAddBrandCountry}
                                    onChangePage={this.onChangePage}
                                    role={role}
                                    sortBy={sortBy}
                                    onFilterByBrand={this.onFilterByBrand}
                                />
                            )}
                        />
                        <Route
                            exact
                            path={`${match.url}/:brandId`}
                            component={Brand}
                        />
                    </Switch>
                </div>
                {showModal && <BrandModal
                    brand={modalType === 'create' ? {} : brandToEdit}
                    type={modalType}
                    userAdvertisers={userAdvertisers}
                    // userBrandOwners={userBrandOwners}
                    onCloseModal={this.onCloseModal}
                    onManipulateBrand={this.onManipulateBrand}
                    show={showModal}
                    role={role}
                />}
                {addBrandCountryModalContent && <AddBrandCountryModal
                    brand={addBrandCountryModalContent}
                    userAdvertisers={userAdvertisers}
                    onCloseModal={this.onCloseAddCountryModal}
                    show={!!addBrandCountryModalContent}
                    onManipulateBrand={this.onAddBrandCountry}
                />}
                {
                    <ApiError
                        show={hasError}
                        error={errorMessage}
                        message={errorMessage}
                        cancelFunction={this.hideError}
                    />
                }
            </div>
        );
    }
};

AdminBrandsPage.propTypes = {
    match: PropTypes.shape({
        isExact: PropTypes.bool,
        params: PropTypes.object,
        path: PropTypes.string,
        url: PropTypes.string,
    }),
};

const mapDispatchToProps = (dispatch) => {
    return {
        getAgentBrands: () => {
            dispatch(getAgentBrands())
        },
    };
};

export default connect(null, mapDispatchToProps)(AdminBrandsPage);
