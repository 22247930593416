import React from 'react';

import DisplayTopics from '../../../shared/displayTopics/DisplayTopics';
import InfluencerPhoto from '../../../oneInfluencerNew/InfluencerPhoto';

export default class ProfileBasics extends React.Component {
    render() {
        const { profile } = this.props;
        return (
            <div className="displayBasics">
                <div className="photoContainer">
                    {profile.authenticityRating && (
                        <div className="score">
                            <img src="/images/ic-score.svg" />
                            <span>{Math.round(profile.authenticityRating)}</span>
                        </div>
                    )}
                    <InfluencerPhoto url={profile.pictureUrl} firstName={profile?.firstname} lastName={profile?.lastname} />
                </div>
                <div className="name">
                    {profile.firstname} {profile.lastname}
                </div>
                {profile.country && (
                    <div className="location">
                        {profile.city && <div>{profile.city},&nbsp;</div>}
                        {profile.state && <div>{profile.state},&nbsp;</div>}
                        <div>&nbsp;{profile.country}</div>
                    </div>
                )}
                {profile?.topics?.length > 0 && (
                    <div className="topics">
                        <DisplayTopics topics={this.props.profile.topics} short />
                    </div>
                )}
            </div>
        );
    }
}
