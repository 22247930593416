import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import BreakOutOfDom from './BreakOutOfDom';
import './modalWithSingleAction.scss';

const ModalAlertWithAction = ({ show, messageValues, style, titleId, messageId, className, noLogo, customBtn, flavor, actionFunction }) => {
    if (!show) {
        return null;
    }

    const clickHandler = () => {
        if (typeof actionFunction === 'function') {
            actionFunction();
        }
    };

    const panelClickHandler = (e) => {
        e.stopPropagation();
    };

    let values = {};
    if (typeof messageValues === 'object') {
        values = messageValues;
    }

    let imgSrc = 'ic-errorInCircle.svg';
    let classNameForMessagePanel = 'messagePanel';
    if (flavor === 'success') {
        imgSrc = 'ic-successForPopup.svg';
        classNameForMessagePanel += ' success';
    }
    if (flavor === 'warning') {
        imgSrc = 'ic-warningForPopup.svg';
        classNameForMessagePanel += ' warning';
    }

    return (
        <BreakOutOfDom>
            <div className={cx('modalWithAction container', className)} onClick={panelClickHandler}>
                <div className={classNameForMessagePanel} style={style}>
                    {!noLogo && <img src={`/images/${imgSrc}`} />}
                    <div className="title">
                        <FormattedMessage id={titleId} />
                    </div>
                    <div className="message">
                        <FormattedMessage id={messageId} values={values} />
                    </div>
                    <div>
                        {customBtn ? (
                            customBtn
                        ) : (
                            <div className="button" onClick={clickHandler}>
                                <FormattedMessage id="OK" />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </BreakOutOfDom>
    );
};

export default ModalAlertWithAction;

ModalAlertWithAction.propTypes = {
    show: PropTypes.bool.isRequired,
    flavor: PropTypes.string,
    messageValues: PropTypes.shape(),
    style: PropTypes.shape(),
    titleId: PropTypes.string,
    messageId: PropTypes.string,
    className: PropTypes.string,
    noLogo: PropTypes.bool,
    customBtn: PropTypes.element,
    actionFunction: PropTypes.func,
};

ModalAlertWithAction.defaultProps = {
    messageValues: {},
    flavor: 'warning',
    style: null,
    titleId: '',
    messageId: '',
    className: '',
    noLogo: false,
    customBtn: null,
    actionFunction: () => {},
};

// todo
// - animation
// - isDisposable
// - folder structure
