const BRANDS_FILTER_OPTIONS = [
    {
        value: 'all',
        label: 'All',
    },
    {
        value: 'advertiser',
        label: 'Advertiser',
    }
];

const initialState = {
    modalType: 'create',
    showModal: false,
    brandToEdit: null,
    brandsList: [],
    filterValue: 'all',
    filters: {
        advertiserValue: '',
        advertiserQuery: undefined,
        brandsStatusValue: 'active',
        brandsStatusQuery: undefined,
        numPerPage: 10,
        searchString: null
    },
    pagination: {
        total: null,
        currentPage: 1,
    },
    profile: null,
    sortBy: {
        createdDate: true,
    },
    hasError: false,
    errorMessage: undefined,
};

export default {
    BRANDS_FILTER_OPTIONS,
    initialState,
};
