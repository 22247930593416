import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';
import moment from 'moment';
import cx from 'classnames';
import { DateRangePicker } from 'react-dates';
import './Analytics.scss';
import AnalyticsOverview from './AnalyticsOverview/AnalyticsOverview';
import AnalyticsByChannel from './AnalyticsByChannel';
import AnalyticsByInfluencer from './AnalyticsByInfluencer';
import AnalyticsByPost from './AnalyticsByPost';
import AnalyticsBySentiment from './AnalyticsBySentiment/AnalyticsBySentiment';
import Api from '../../../modules/Api';
import { AGENT_STR, BRAND_OWNER_STR, SUPER_ADMIN_STR, VIEWER_STR } from '../../../constants/authorities';
import PleaseWait from '../../shared/pleaseWait/PleaseWaitWhite';
import ApiError from '../../shared/apiError/ApiError';
import PendoHelper from '../../../modules/utils/PendoHelper';

// const permissions = [AGENT, BRAND_OWNER, SUPER_ADMIN, VIEWER];

const formatAssignmentRes = (list) => {
    const a = [];
    for (let i = 0; i < list.length; i++) {
        const b = {
            value: list[i].id,
            label: list[i].name,
        };
        a.push(b);
    }
    return a;
};

const getAssignmentIdList = (list) => {
    const a = [];
    for (let i = 0; i < list.length; i++) {
        if (list[i].value !== 'ALL') {
            a.push(list[i].value);
        }
    }
    return a;
};

class Analytics extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            showLoading: false,
            assignments: [],
            selectedAssignments: ['ALL'],
            currentTab: 1,
            overviewData: {},
            summaryData: {},
            analysisData: {},
            byPostData: {},
            byChannelData: {},
            byInfleuncerData: {},
            startDate: moment().subtract(30, 'days'),
            endDate: moment(),
            focusedInput: null,
            showError: false,
        };
    }

    componentDidMount() {
        const {
            match: { params },
            campaignId,
            setNavigationFunction,
            campaign
        } = this.props;
        this.assignmentId = Number(params.assignmentId);
        setNavigationFunction(this.assignmentId);
        
        const prepareCampaignAssignment = () => Api.getCampaignAssignment(campaignId).then((res) => {
            const assignList = formatAssignmentRes(res.results);
            const initialAssignment = res.results.find(({ id }) => id === Number(params.assignmentId));
            const initialFlight = initialAssignment.assignmentFlights && initialAssignment.assignmentFlights.length > 0 && initialAssignment.assignmentFlights[0];
            const selectedAssignments = assignList.find(({ value }) => value === Number(params.assignmentId)) || 'ALL';
            this.setState({
                assignments: [{ value: 'ALL', label: 'All assignments' }].concat(assignList),
                selectedAssignments: [selectedAssignments],
                startDate: initialFlight ? moment(initialFlight.flightStartDate) : this.state.startDate,
                endDate: initialFlight ? moment(initialFlight.flightEndDate) : this.state.endDate
            });
        }).catch((err) => {
            console.log(err);
        });

        const payload = {
            campaignId: campaignId,
            params: {
                num: 20,
                page: 1,
                sortby: 'publishDate',
                // filter: self.state.filter,
                assignmentIds: this.assignmentId
            },
        };

        const prepareCampaignPublishedPosts = () => Api.getCampaignPublishedPosts(payload).then((response) => {
            if (response.results
                && response.results.length > 0
                && response.results[0].posts
                && response.results[0].posts.length > 0) {
                this.setState({
                    startDate: moment(response.results[0].posts[0].createdOn)
                });
            }
        }).catch((err) => {
            console.log(err);
        });

        const prepareCurrencyCode = () => Api.getCurrencyCode(campaign.currencyCode).then(result => {
            this.setState({currency: result.data})
        })

        prepareCampaignAssignment().then(() => {
            return prepareCampaignPublishedPosts();
        }).then(() => {
            return prepareCurrencyCode();
        }).then(() => {
            this.setUpData();
        })
    }

    fetchData = async () => {
        const { campaignId } = this.props;
        const { startDate, endDate, selectedAssignments, assignments } = this.state;
        if (startDate) {
            PendoHelper.pendoTrack('Campaign_Assignment_Analytics_UpdateResult', {});
            const start = moment(startDate).format('YYYY-MM-DD');
            const end = endDate ? moment(endDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
            const assignmentIds = selectedAssignments.find(({ value }) => value === 'ALL')
                ? getAssignmentIdList(assignments)
                : getAssignmentIdList(selectedAssignments);
            const overviewData = Api.getCampaignSentimentOverview(assignmentIds, start, end);
            const byChannelData = Api.getCampaignAnalyticByChannel(campaignId, assignmentIds, start, end);
            const byInfleuncerData = Api.getCampaignAnalyticByInfluencer(campaignId, assignmentIds, start, end);
            const byPostData = Api.getCampaignAnalyticByPost(campaignId, assignmentIds, start, end);
            const summaryData = Api.getCampaignAnalyticSummary(campaignId, assignmentIds, start, end);
            const analysisData = Api.getCampaignSentiment(assignmentIds, [], start, end, [], []);
            return Promise.allSettled([overviewData, byChannelData, byInfleuncerData, byPostData, summaryData, analysisData]);
        }
        return Promise.allSettled([{}, {}, {}, {}, {}, {}]);
    };

    setUpData = () => {
        this.setState({ showLoading: true });
        this.fetchData()
            .then((result) => {
                const res = [];
                result.forEach((item) => {
                    if (item && item.status === 'fulfilled') {
                        res.push(item.value);
                    } else {
                        res.push({});
                    }
                });
                this.setState({
                    overviewData: res[0],
                    byChannelData: res[1],
                    byInfleuncerData: res[2],
                    byPostData: res[3],
                    summaryData: res[4],
                    analysisData: res[5],
                    showLoading: false,
                });
            })
            .catch(() => {
                this.setState({ showLoading: false, showError: true });
            });
    };

    handleDatesChange = ({ startDate, endDate }) => {
        this.setState({ startDate, endDate });
    };

    handleSelectAssignments = (value) => {
        const { selectedAssignments, assignments } = this.state;
        let newValue = value;
        if (value.find((assignment) => assignment.value === 'ALL')) {
            if (selectedAssignments[0].value !== 'ALL') {
                newValue = [{ value: 'ALL', label: 'All assignments' }];
            } else if (value.length > 1) {
                const a = value.findIndex((assignment) => assignment.value === 'ALL');
                newValue.splice(a, 1);
            }
        }
        if (value.length === 0 || value.length === assignments.length - 1) {
            newValue = [{ value: 'ALL', label: 'All assignments' }];
        }
        this.setState({ selectedAssignments: newValue }, this.setUpData);
    };

    selectTab = (currentTab) => {
        this.setState({ currentTab });
    };

    render() {
        const { assignments, selectedAssignments, currentTab, startDate, endDate, focusedInput } = this.state;
        const { overviewData, summaryData, analysisData, byPostData, byChannelData, byInfleuncerData, showLoading, showError, currency } = this.state;
        const { profile, campaign, showLoadingScreen, hideLoadingScreen } = this.props;
        const assignmentsName = [];
        selectedAssignments.map((item) => assignmentsName.push(item.label));
        const assignmentIds = selectedAssignments.find(({ value }) => value === 'ALL')
            ? getAssignmentIdList(assignments)
            : getAssignmentIdList(selectedAssignments);
        const campaignInfo = {
            campaignId: this.props.campaignId,
            campaignName: campaign && campaign.name,
            assignmentIds,
            assignments: assignmentsName,
            startDate: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
            endDate: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
            currency: currency
        };
        const permission = profile &&
            (profile.role === AGENT_STR || profile.role === SUPER_ADMIN_STR || profile.role === BRAND_OWNER_STR || profile.role === VIEWER_STR);
        return (
            <div className="analyticsPage" id="analyticsPage">
                <ApiError
                    show={showError}
                    cancelFunction={() => this.setState({ showError: false })}
                    errTitle=" "
                    error="Oops! We're unable to load some of the data right now. Please try again later."
                />
                <PleaseWait show={showLoading} top={200} />
                <div className="header">
                    <div className="selectAssignments">
                        <div className="select-box">
                            <div className="label">Assignments</div>
                            <Select
                                disabled={!permission}
                                className="select"
                                clearable={false}
                                value={selectedAssignments}
                                multi
                                placeholder=""
                                options={assignments}
                                onChange={(...args) => this.handleSelectAssignments(...args)}
                            />
                        </div>
                    </div>
                    <div className="selectRange">
                        <div className="selectRangeLabel">Post Dates</div>
                        <DateRangePicker
                            customArrowIcon={<div>-</div>}
                            displayFormat={'MMM DD, YYYY'}
                            isOutsideRange={(day) => moment(day).isAfter(moment(), 'd')}
                            isDayBlocked={(day) => moment(day).isAfter(moment(), 'd')}
                            startDate={startDate}
                            startDateId="assignmentStartDate"
                            endDate={endDate}
                            endDateId="assignmentEndDate"
                            onDatesChange={this.handleDatesChange} // PropTypes.func.isRequired,
                            focusedInput={focusedInput}
                            onFocusChange={(newInput) => this.setState({ focusedInput: newInput })}
                            readOnly={true}
                        />
                        <div className="selectRangeBtn" onClick={() => this.setUpData()}>
                            Update Result
                        </div>
                    </div>
                    <div className="selectTab">
                        <div className={cx({ active: currentTab === 1 })} onClick={() => this.selectTab(1)}>
                            Overview
                        </div>
                        <div className={cx({ active: currentTab === 2 })} onClick={() => this.selectTab(2)}>
                            By Channel
                        </div>
                        {/* <div className={cx({ active: currentTab === 3 })} onClick={() => this.selectTab(3)}>
                            By Creator
                        </div> */}
                        <div className={cx({ active: currentTab === 4 })} onClick={() => this.selectTab(4)}>
                            By Post
                        </div>
                        <div className={cx({ active: currentTab === 5 })} onClick={() => this.selectTab(5)}>
                            By Sentiment
                        </div>
                    </div>
                </div>
                {currentTab === 1 && (
                    <AnalyticsOverview
                        campaignInfo={campaignInfo}
                        overviewData={overviewData}
                        summaryData={summaryData}
                        analysisData={analysisData}
                        dataByPost={byPostData}
                        dataByInfluencer={byInfleuncerData}
                        dataByChannel={byChannelData}
                        selectTab={this.selectTab}
                        startDate={moment(startDate).format('YYYY-MM-DD')}
                        endDate={endDate ? moment(endDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')}
                        showLoadingScreen={showLoadingScreen}
                        hideLoadingScreen={hideLoadingScreen}
                    />
                )}
                {currentTab === 2 && (
                    <AnalyticsByChannel
                        campaignInfo={campaignInfo}
                        dataByChannel={byChannelData}
                        startDate={moment(startDate).format('YYYY-MM-DD')}
                        endDate={moment(endDate).format('YYYY-MM-DD')}
                        showLoadingScreen={showLoadingScreen}
                        hideLoadingScreen={hideLoadingScreen}
                    />
                )}
                {currentTab === 3 && (
                    <AnalyticsByInfluencer
                        campaignInfo={campaignInfo}
                        dataByInfluencer={byInfleuncerData}
                        startDate={moment(startDate).format('YYYY-MM-DD')}
                        endDate={moment(endDate).format('YYYY-MM-DD')}
                        showLoadingScreen={showLoadingScreen}
                        hideLoadingScreen={hideLoadingScreen}
                    />
                )}
                {currentTab === 4 && (
                    <AnalyticsByPost
                        campaignInfo={campaignInfo}
                        dataByPost={byPostData}
                        startDate={moment(startDate).format('YYYY-MM-DD')}
                        endDate={moment(endDate).format('YYYY-MM-DD')}
                        showLoadingScreen={showLoadingScreen}
                        hideLoadingScreen={hideLoadingScreen}
                    />
                )}
                {currentTab === 5 && (
                    <AnalyticsBySentiment
                        campaignInfo={campaignInfo}
                        startDate={moment(startDate).format('YYYY-MM-DD')}
                        endDate={endDate ? moment(endDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')}
                        showError={() => this.setState({ showError: true })}
                    />
                )}
            </div>
        );
    }
}

Analytics.propTypes = {
    match: PropTypes.shape().isRequired,
    campaignId: PropTypes.string,
    setNavigationFunction: PropTypes.func,
    profile: PropTypes.shape(),
    campaign: PropTypes.shape(),
    showLoadingScreen: PropTypes.func,
    hideLoadingScreen: PropTypes.func,
};

Analytics.defaultProps = {
    campaignId: '',
    setNavigationFunction: () => {},
    profile: {},
    campaign: {},
    showLoadingScreen: () => {},
    hideLoadingScreen: () => {},
};

const mapStateToProps = (state) => ({
    profile: state.global.loggedInUserProfile,
});

export default connect(mapStateToProps)(Analytics);
