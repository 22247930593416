import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import cx from 'classnames';
import { isNull } from 'util';
import moment from 'moment';
import { SingleDatePicker } from 'react-dates';
import { fetchFavorites } from '../../../store/actions/favoriteActions';
import './discoverPanel.scss';
import LocationInput from '../../shared/locationInput/LocationInput';
import DropdownSelect from '../../shared/selectBox/DropdownSelect2';
import InputRange from './InputRange';
import CustomRangeInputs from './CustomRangeInputs';
import CustomRangeOption from './CustomRangeOption';
import { KeywordsInput } from './KeywordsInput';
import NumberInput from './NumberInput/NumberInput';
import OptionFilter from './OptionFilter/OptionFilter';
import { getDisplayContent } from './FilterMenu.helpers';
import { FILTERS_AUDIENCE, FILTERS_COMMON } from '../../../constants/filtersNameConstants';
import TopicTensorInput from './TopicTensorInput';
import PendoHelper from '../../../modules/utils/PendoHelper';
import Tooltip from '../../shared/tooltip/Tooltip'

const filterType = ['Connected', 'Public'];

class FilterMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            source: props.allFiltersData.type,
            channelSelect: props.channelSelect,
            selectedFilters: props.selectedFilters,
            availableConnectedFilters: props.connectedFilters,
            availableCommonFilters: props.commonFilters,
            availableAudienceFilters: props.audienceFilters,
            addedFilters: props.addedFilters,
            specificNeedChange: false
        };
        this.renderFilter = this.renderFilter.bind(this);
    }

    componentDidMount() {
        const { setTopFilter } = this.props;
        setTopFilter(this.topFilters);
    }

    removeSpecificFilter = (id, index, type) => {
        const { addedFilters } = this.state;
        const { allFiltersData } = this.props;
        const source = allFiltersData.type;
        const addedFiltersShow = addedFilters[source];
        const indexToFind = addedFiltersShow.findIndex((item) => item.filterId === id);
        switch (type) {
            case 'OPTION':
                addedFiltersShow[indexToFind].filterOptionIds.splice(index, 1);
                addedFilters[source] = addedFiltersShow;
                break;
            case 'FREE_TEXT':
                addedFiltersShow[indexToFind].keywords.splice(index, 1);
                addedFilters[source] = addedFiltersShow;
                break;
            case 'CUSTOM_RANGES':
                addedFiltersShow[indexToFind].data.filterOptions.splice(index, 1);
                addedFilters[source] = addedFiltersShow;
                break;
            default:
                break;
        }
        this.setState({ addedFilters, specificNeedChange: true }, () => this.delegateChanges(true));
    };

    topFilters = () => {
        const { allFiltersData, selectedFilters, removekeyword, removeFavourite, keywordFilters, editSearch, publicButton } = this.props;
        const { addedFilters } = this.state;
        const source = allFiltersData.type;
        let selectedFiltersShow = selectedFilters[source];
        const addedFiltersShow = addedFilters[source];
        const closeIcon = <div className="filterBar-closeIcon">+</div>;
        selectedFiltersShow = selectedFiltersShow.filter((item) => {
            let arrayToCheck = [];
            if (item.group === 'common') {
                arrayToCheck = allFiltersData.commonFilters;
            } else if (item.group === 'insights') {
                arrayToCheck = allFiltersData.audienceFilters;
            } else if (item.group === 'connected') {
                arrayToCheck = allFiltersData.connectedFilters;
            }
            if (arrayToCheck.find((arrayItem) => arrayItem.filterId === item.id) || item.filterId || item.type === 'favourite') {
                return true;
            }
            return false;
        });
        selectedFiltersShow.forEach(item => {
            let arrayToCheck = [];
            if (item.group === 'common') {
                arrayToCheck = allFiltersData.commonFilters;
            } else if (item.group === 'insights') {
                arrayToCheck = allFiltersData.audienceFilters;
            } else if (item.group === 'connected') {
                arrayToCheck = allFiltersData.connectedFilters;
            }
            const itemValues = arrayToCheck.find((arrayItem) => arrayItem.filterId === item.id)
            if (itemValues?.customValue) {
                item.customValue = itemValues.customValue
            }
            if (itemValues?.customRanges) {
                item.customRanges = itemValues.customRanges
            }
        })
        if (selectedFiltersShow.length === 0) {
            return null;
        }
        return (
            <>
                <div className="filterBarContainer">
                    <div className={cx("filterBar", publicButton && "lower")}>
                        {selectedFiltersShow.map((filter) => {
                            const addedFilterItem = addedFiltersShow.find((item) => item.filterId === filter.id);
                            const filterToShow = getDisplayContent(
                                filter,
                                source,
                                addedFilterItem,
                                keywordFilters,
                                false,
                                closeIcon,
                                this.removeFilter,
                                this.removeSpecificFilter,
                                removekeyword,
                                removeFavourite,
                                editSearch && editSearch?.searchableOptions || []);

                            return filterToShow;
                        })}
                        <div className="clearAll" onClick={() => this.clearAllFilter()}>
                            Clear all
                        </div>
                    </div>
                    {publicButton && publicButton()}
                </div>
                <div className="filterBarContainer hiddenVersion">
                    <div className="filterBar">
                        {selectedFiltersShow.map((filter) => {
                            const addedFilterItem = addedFiltersShow.find((item) => item.filterId === filter.id);
                            const filterToShow = getDisplayContent(
                                filter,
                                source,
                                addedFilterItem,
                                keywordFilters,
                                false,
                                closeIcon,
                                this.removeFilter,
                                this.removeSpecificFilter,
                                removekeyword,
                                removeFavourite,
                                editSearch && editSearch?.searchableOptions || []);

                            return filterToShow;
                        })}
                        <div className="clearAll" onClick={() => this.clearAllFilter()}>
                            Clear all
                        </div>
                    </div>
                    {publicButton && publicButton()}
                </div>
            </>
        );
    };

    addFilter = (filter, group) => {
        const { allFiltersData, selectedFilters } = this.props;
        const source = allFiltersData.type;
        filter.group = group;
        filter.hidden = false;
        const indexToFind = selectedFilters[source].findIndex((item) => item.id === filter.id);
        if (indexToFind === -1) {
            selectedFilters[source].push(filter);
        } else {
            selectedFilters[source][indexToFind].hidden = false;
        }
        this.setState({ selectedFilters }, () => {
            if (indexToFind === -1) {
                this.delegateChanges(false);
            }
        });
    };

    removeFilter = (filter) => {
        const { addedFilters } = this.state;
        const { allFiltersData, selectedFilters } = this.props;
        const source = allFiltersData.type;
        selectedFilters[source] = selectedFilters[source].filter((item) => item.id !== filter.id);
        addedFilters[source] = addedFilters[source].filter((item) => item.filterId !== filter.id);
        this.setState({ selectedFilters, addedFilters }, () => this.delegateChanges(true));
    };

    closeFilter = (filter) => {
        const { allFiltersData, selectedFilters } = this.props;
        const source = allFiltersData.type;
        const indexToFind = selectedFilters[source].findIndex((item) => item.id === filter.id);
        selectedFilters[source][indexToFind].hidden = true;
        this.setState({ selectedFilters });
    };

    clearAllFilter = () => {
        PendoHelper.pendoTrack('DiscoverFilter_FilterKeywords_ClearAll', {});

        const { addedFilters, channelSelect } = this.state;
        const {
            connectedFilters: availableConnectedFilters,
            commonFilters: availableCommonFilters,
            audienceFilters: availableAudienceFilters,
            selectedFilters,
        } = this.props;
        const { allFiltersData } = this.props;
        const source = allFiltersData.type;
        const defaultChannelSelect = {
            CONNECTED: {
                blog: false,
                facebook: false,
                instagram: false,
                tiktok: false,
                twitter: false,
                youtube: false,
            },
            PUBLIC: {
                instagram: true,
                tiktok: false,
            },
        }
        // if (source === "CONNECTED") {
        //     channelSelect.CONNECTED = defaultChannelSelect.CONNECTED
        // } else {
        //     channelSelect.PUBLIC = defaultChannelSelect.PUBLIC
        // }
        selectedFilters[source] = [];
        addedFilters[source] = [];
        this.setState({ selectedFilters, addedFilters, channelSelect }, () => this.delegateChanges(true));
    };

    handleChange = (filter, changeValue) => {
        const { addedFilters } = this.state;
        const { allFiltersData } = this.props;
        const source = allFiltersData.type;
        const updatedFilters = addedFilters[source].filter((f) => f.filterId !== filter.id);
        const newFilter = { filterId: filter.id };
        let addFilter = false;

        let returnValue = '';

        switch (filter.type) {
            case 'CUSTOM_VALUE':
                const activeFilter = changeValue.filterOptions[0];
                if (activeFilter) {
                    newFilter.filterOptionIds = [activeFilter.id];
                    newFilter.customValue = activeFilter.value === 'gte' ? activeFilter.minValue : activeFilter.maxValue;
                    addFilter = true;
                }
                break;

            case 'JSON':
                if (changeValue instanceof Object) {
                    newFilter.json = changeValue;
                    addFilter = true;
                }
                break;
            case 'DATE_TEXT':
                if (moment(changeValue).isValid()) {
                    newFilter.keywords = [moment(changeValue).format('YYYY-MM-DD')];
                    addFilter = true;
                }
                break;
            case 'FREE_TEXT':
                if (changeValue.length > 0) {
                    newFilter.keywords = changeValue;
                    addFilter = true;
                }
                break;
            case 'PERCENTAGE_RANGE':
            case 'FREE_RANGE':
                if (changeValue.length === 2) {
                    newFilter.range_from = changeValue[0];
                    newFilter.range_to = changeValue[1];
                    addFilter = true;
                }
                break;
            case 'DUAL_RANGE':
                if (changeValue && changeValue instanceof Object && changeValue.length >= 2) {
                    newFilter.filterOptionIds = changeValue.slice(0, 2).map((option) => option?.id);
                    filter.weighted && changeValue[2] && (newFilter.customValue = changeValue[2]);
                    addFilter = true;
                }
                break;
            case 'CUSTOM_RANGES':
                if (changeValue && changeValue instanceof Object && changeValue.filterOptions.length > 0) {
                    newFilter.data = changeValue;
                    addFilter = true;
                }
                break;

            case 'WEIGHT':
                newFilter.customValue = changeValue;
                addFilter = true;
                break;

            case 'OPTION':
                newFilter.filterOptionIds = changeValue.filterOptionIds;
                newFilter.customValue = changeValue.customValue;
                newFilter.customRanges = changeValue.customRanges;
                addFilter = true;
                break;

            case 'RANGE':
            case 'OPTION_RANGE':
            default:
                returnValue = [];
                if (Array.isArray(changeValue)) {
                    changeValue.forEach((option) => {
                        if (!isNull(option) && option !== '') {
                            returnValue.push(option);
                        }
                    });
                }
                if (returnValue.length > 0) {
                    newFilter.filterOptionIds = returnValue;
                    addFilter = true;
                }
                break;
        }
        if (addFilter) {
            updatedFilters.push(newFilter);
        }
        addedFilters[source] = updatedFilters;

        this.setState({ addedFilters }, () => this.delegateChanges(true));
    };

    delegateChanges(search) {
        const {
            onUpdate,
            channelSelect,
            allFiltersData,
            connectedFilters: availableConnectedFilters,
            commonFilters: availableCommonFilters,
            audienceFilters: availableAudienceFilters,
        } = this.props;
        const source = allFiltersData.type;
        const { addedFilters, selectedFilters } = this.state;
        if (onUpdate) {
            onUpdate(
                addedFilters,
                channelSelect,
                source,
                selectedFilters,
                availableCommonFilters,
                availableConnectedFilters,
                availableAudienceFilters,
                search,
            );
        }
    }

    mergeFilterOptions(filter1, filter2) {
        const ids = filter2.filterOptions.map((f) => f.id);
        const unchangedOptions = filter1.filterOptions.filter((op) => ids.indexOf(op.id) === -1);
        const updatedFilter = { ...filter1 };
        updatedFilter.filterOptions = [...unchangedOptions, ...filter2.filterOptions];
        return updatedFilter;
    }

    getSearchOptions = (filter) => {
        const { editSearch } = this.props;
        let temp;
        if (Array.isArray(editSearch?.searchableOptions) && Array.isArray(filter.filterOptionIds)) {
            const t = editSearch?.searchableOptions.find(so => so.id === filter.filterId);
            if (t && t.options) {
                temp = t.options;
            }
        }
        return temp;
    }

    getInitialValue = (filter, forCustomValue) => {
        const { editSearch } = this.props;
        let initialValue;
        let found = false;
        if (editSearch && Array.isArray(editSearch.commonFilters)) {
            const f = editSearch.commonFilters.find(s => s.filterId === filter.id);
            if (f) {
                found = true;
                if (forCustomValue) {
                    if (f?.customValue) {
                        initialValue = f.customValue;
                    }
                } else {
                    initialValue = f;
                    const searchableOptions = this.getSearchOptions(f);
                    if (searchableOptions) {
                        initialValue.searchableOptions = searchableOptions;
                    }
                }
            }
        }
        if (!found) {
            if (editSearch && Array.isArray(editSearch.audienceFilters)) {
                const f = editSearch.audienceFilters.find(s => s.filterId === filter.id);
                if (f) {
                    found = true;
                    if (forCustomValue) {
                        if (f?.customValue) {
                            initialValue = f.customValue;
                        }
                    } else {
                        initialValue = f;
                        const searchableOptions = this.getSearchOptions(f);
                        if (searchableOptions) {
                            initialValue.searchableOptions = searchableOptions;
                        }
                    }
                }
            }
        }
        if (!found) {
            if (editSearch && Array.isArray(editSearch.connectedFilters)) {
                const f = editSearch.connectedFilters.find(s => s.filterId === filter.id);
                if (f) {
                    found = true;
                    if (forCustomValue) {
                        if (f?.customValue) {
                            initialValue = f.customValue;
                        }
                    } else {
                        initialValue = f;
                        const searchableOptions = this.getSearchOptions(f);
                        if (searchableOptions) {
                            initialValue.searchableOptions = searchableOptions;
                        }
                    }
                }
            }
        }
        if (!initialValue && filter.type === 'OPTION') {
            initialValue = filter;
        }
        return initialValue;
    }

    filterOperationShow = (filter) => {
        const { addedFilters } = this.state;
        const { allFiltersData, scrollContainer, currency, symbol } = this.props;
        const { platform, showApiError, apiError } = this.props;
        const source = allFiltersData.type;
        const filterData = addedFilters[source].find((f) => f.filterId === filter.id);
        let filtershow = <div />;
        let type;
        let updates;
        let id = -1;
        const temp = { ...filter };
        const setFilterOptions = filterOption => {
            filter.filterOptions = filterOption;
        }

        switch (filter.type) {
            case 'PERCENTAGE_RANGE':
                type = 'percentage';
            case 'FREE_RANGE':
                const rangeObj = { from: filter.minValue, to: filter.maxValue };
                const values = filterData ? [filterData.range_from, filterData.range_to] : ['', ''];
                const unlimitMax = filter.id !== 2;
                filtershow = (
                    <>
                        {filter.id === 41 && <div className="currencySection">Currency: <span>{currency} ({symbol})</span></div>}
                        <InputRange
                            id={`filter_${filter.id}`}
                            rangeObject={rangeObj}
                            unlimitMax={unlimitMax}
                            options={values}
                            type={type}
                            label={filter.label}
                            changeFunction={(value) => this.handleChange(filter, value)}
                            ref={(e) => {
                                this.filterOperation = e;
                            }}
                        />
                    </>
                );
                break;

            case 'CUSTOM_VALUE':
                const updates2 = {
                    ...filter,
                    filterOptions: filter.filterOptions.map((option) => {
                        if (option.id === filterData?.filterOptionIds[0]) {
                            const isGte = option.customValue === 'gte';
                            if (isGte) {
                                return { ...option, maxValue: 100, minValue: filterData?.customValue };
                            }
                            return { ...option, minValue: 0, maxValue: filterData?.customValue };
                        }
                        return option;
                    }),
                };
                filtershow = (
                    <CustomRangeInputs
                        isSingleInput
                        label={`Audience ${filter.label}`}
                        clearable={false}
                        closeOnSelect
                        placeholder=""
                        filterValue={updates2}
                        onChange={(data) => this.handleChange(filter, data)}
                        searchable
                    />
                );
                break;
            case 'DUAL_RANGE':
                if (filterData && filterData.data) {
                    if (filterData.data?.filterOptions?.length > 0) {
                        updates = this.mergeFilterOptions(filter, filterData?.data || []);
                    } else {
                        updates = filter;
                    }
                } else if (filterData && filterData?.customRanges && Array.isArray(filterData?.filterOptionIds)) {
                    filterData.filterOptionIds.forEach(f => {
                        id = filter.filterOptions.findIndex(o => f === o.id);
                        if (id !== -1) {
                            filterData.customRanges.forEach(c => {
                                for (const [key, value] of Object.entries(c)) {
                                    temp.filterOptions[id][key] = value;
                                }
                            })
                        }
                    })
                    updates = temp;
                } else {
                    updates = filter;
                }
                filtershow = (
                    <CustomRangeOption
                        isMinOnly={filter.minOnly}
                        label={`Audience ${filter.label}`}
                        clearable={false}
                        // multi
                        closeOnSelect
                        placeholder=""
                        filterValue={updates}
                        onChange={(data) => this.handleChange(filter, data)}
                        searchable
                        currentItems={filterData?.data?.filterOptions}
                    />
                );
                break;

            case 'CUSTOM_RANGES':
                if (filterData && filterData.data) {
                    if (filterData.data?.filterOptions?.length > 0) {
                        updates = this.mergeFilterOptions(filter, filterData?.data || []);
                    } else {
                        updates = filter;
                    }
                } else if (filterData && filterData?.customRanges && Array.isArray(filterData?.filterOptionIds)) {
                    filterData.filterOptionIds.forEach(f => {
                        id = filter.filterOptions.findIndex(o => f === o.id);
                        if (id !== -1) {
                            filterData.customRanges.forEach(c => {
                                for (const [key, value] of Object.entries(c)) {
                                    temp.filterOptions[id][key] = value;
                                }
                            })
                        }
                    })
                    updates = temp;
                } else {
                    updates = filter;
                }
                filtershow = (
                    <CustomRangeInputs
                        isMinOnly={filter.minOnly}
                        label={`Audience ${filter.label}`}
                        clearable={false}
                        // multi
                        closeOnSelect
                        placeholder=""
                        filterValue={updates}
                        onChange={(data) => this.handleChange(filter, data)}
                        searchable
                        currentItems={filterData?.data?.filterOptions}
                    />
                );
                break;
            case 'RANGE':
                break;
            case 'JSON':
                filtershow = (
                    <LocationInput
                        id={`filter_${filter.id}`}
                        original={
                            filterData && filterData.json && filterData.json.hasOwnProperty('formattedAddress')
                                ? filterData.json
                                : { formattedAddress: '' }
                        }
                        changeFunction={(value) => this.handleChange(filter, value)}
                        ref={(e) => {
                            this.filterOperation = e;
                        }}
                        placeholder="Location"
                        scrollContainer={scrollContainer}
                    />
                );
                break;
            case 'DATE_TEXT':
                const yearArray = [];
                for (let i = moment().year() - 120; i < moment().year() + 21; i++) {
                    yearArray.push(i);
                }
                filtershow = (
                    <SingleDatePicker
                        placeholder=""
                        ref={(e) => {
                            this.filterOperation = e;
                        }}
                        numberOfMonths={1}
                        onDateChange={(value) => this.handleChange(filter, value)}
                        focused={this.state[`${filter.id}`] === true} // PropTypes.bool
                        onFocusChange={({ focused }) => this.setState({ [`${filter.id}`]: focused })} // PropTypes.func.isRequired
                        id={`filter_${filter.id}`} // PropTypes.string.isRequired,
                        date={
                            filterData && filterData.keywords && filterData.keywords.length > 0 && moment(filterData.keywords[0]).isValid()
                                ? moment(filterData.keywords[0])
                                : null
                        }
                        displayFormat="YYYY-MM-DD"
                        isOutsideRange={() => false}
                        showDefaultInputIcon
                        inputIconPosition="after"
                        renderMonthElement={({ month, onMonthSelect, onYearSelect }) => (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div>
                                    <select
                                        value={month.month()}
                                        onChange={(e) => {
                                            onMonthSelect(month, e.target.value);
                                        }}
                                    >
                                        {moment.months().map((label, value) => (
                                            <option key={value} value={value}>
                                                {label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    <select
                                        value={month.year()}
                                        onChange={(e) => {
                                            onYearSelect(month, e.target.value);
                                        }}
                                    >
                                        {yearArray.map((year) => (
                                            <option key={year} value={year}>
                                                {year}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        )}
                    />
                );
                break;
            case 'FREE_TEXT':
                const onFilterChange = (value) => {
                    let clonedValue = [...value];
                    if (filter.name === 'relevance') {
                        clonedValue = clonedValue.map((item) => {
                            const trimmedItem = item.trim();
                            if (trimmedItem.charAt(0) !== '@') {
                                return `@${trimmedItem}`;
                            }
                            return trimmedItem;
                        });
                    }
                    this.handleChange(filter, clonedValue);
                };
                filtershow = (
                    <div>
                        {filter.name === FILTERS_COMMON.CREATOR_LOOK_A_LIKE || filter.name === FILTERS_AUDIENCE.AUDIENCE_LOOK_A_LIKE ?
                            (<TopicTensorInput
                                maxLength={100}
                                data={filterData?.keywords}
                                placeHolder="Add handle"
                                platform={platform}
                                onSuggestionAction={onFilterChange}
                                selectedFilterId={filter.id}
                                sideFilter
                                key={filter.id}
                                showApiError={showApiError}
                                apiError={apiError}
                                isLookalike
                                isFirstKeywordOnly={!filter.multipleSelect}
                            />)
                            :
                            (<KeywordsInput
                                isInfluencerFilterInput
                                data={filterData?.keywords}
                                onChange={onFilterChange}
                                placeHolder="Add keyword"
                                sideFilter
                                isFirstKeywordOnly={!filter.multipleSelect}
                                maxLength={100}
                            />)
                        }
                    </div>
                );
                break;

            case 'WEIGHT':
                const weightInitialValue = this.getInitialValue(filter, true);
                filtershow = (
                    <NumberInput
                        className="filter-menu-input"
                        onBlur={(newValue) => this.handleChange(filter, newValue)}
                        placeholder='%'
                        maxValue={filter.maxValue}
                        minValue={filter.minValue}
                        isWeighted={filter.weighted}
                        initialValue={weightInitialValue}
                    />
                );
                break;

            case 'OPTION':
                const { specificNeedChange } = this.state;
                const initialValue = this.getInitialValue(filter, false);
                if (filter && (!filter.filterOptions || _.isEmpty(filter.filterOptions))
                    && initialValue && initialValue.searchableOptions) {
                    temp.filterOptions = initialValue.searchableOptions;
                    filtershow = <OptionFilter specificNeedChange={specificNeedChange}
                        processedChange={() => this.setState({ specificNeedChange: false })} filter={temp}
                        onSubmit={(data) => this.handleChange(filter, data)} initialValue={initialValue} filterData={filterData}
                        setFilterOptions={setFilterOptions} />;
                } else {
                    filtershow = <OptionFilter specificNeedChange={specificNeedChange}
                        processedChange={() => this.setState({ specificNeedChange: false })} filter={filter}
                        onSubmit={(data) => this.handleChange(filter, data)} initialValue={initialValue} filterData={filterData}
                        setFilterOptions={setFilterOptions} />;
                }
                break;

            case 'OPTION_RANGE':
            default:
                const options = [];
                filter.filterOptions.forEach((option) => {
                    options.push({ value: option.id, label: option.label });
                });
                filtershow = (
                    <DropdownSelect
                        id={`filter_${filter.id}`}
                        clearable={false}
                        value={filterData ? filterData.filterOptionIds : null}
                        simpleValue
                        multi={filter.multipleSelect}
                        placeholder=""
                        options={options}
                        onChange={(data) => this.handleChange(filter, data)}
                        ref={(e) => {
                            this.filterOperation = e;
                        }}
                    />
                );

                break;
        }
        return filtershow;
    };

    renderFilter = (filter, type) => {
        const { selectedFilters } = this.state;
        const { allFiltersData } = this.props;
        const source = allFiltersData.type;
        const selectedFiltersShow = selectedFilters[source];
        const isfilteravaliable = selectedFiltersShow.find((item) => filter.id === item.id && item.type !== "favourite");
        const showClassName = isfilteravaliable && !isfilteravaliable.hidden ? 'avaliable' : 'notAvaliable';
        let filterhelp = ""
        if (filter.label === "Age") {
            filterhelp = "Identify age of creators by analyzing profile photos and selfies in recent posts."
        }
        if (filter.label === "Audience Age") {
            filterhelp = "Identify age of audience analyzing profile photos and selfies of audiences in recent posts."
        }
        return (
            <div key={filter.id} className="filterMenuOptionContainer">
                <div className="filterMenuOptions filterMenuOptions2022">
                    {filter.label !== "Audience Age" && filter.label !== "Age" && <span>{filter.label}</span>}
                    {isfilteravaliable && !isfilteravaliable.hidden && (filter.label === "Audience Age" || filter.label === "Age") && <div className="specialContainer">
                        <span>{filter.label}</span>
                        <Tooltip tooltipText={filterhelp} image="/images/tooltipUser.svg" hoverImage="/images/tooltipBlack.svg" />
                    </div>}
                    {(!isfilteravaliable || isfilteravaliable.hidden) && (filter.label === "Audience Age" || filter.label === "Age") && <span>{filter.label}</span>}
                    {(!isfilteravaliable || isfilteravaliable.hidden) && (
                        <div onClick={() => this.addFilter(filter, type)}>
                            <img className="plus" src="/images/icon_plus_grey.svg" />
                        </div>
                    )}
                    {isfilteravaliable && !isfilteravaliable.hidden && (
                        <div onClick={() => this.closeFilter(filter, type)}>
                            <img className="plus" src="/images/Minus.svg" />
                        </div>
                    )}
                </div>
                <div className={showClassName}>{isfilteravaliable && this.filterOperationShow(isfilteravaliable)}</div>
            </div>
        );
    }

    getAvailableCategories = (filters) => {
        let categories = [];
        filters.forEach(f => {
            if (f.filterCategory && !categories.find(c => c.name === f.filterCategory.name))
                categories.push(f.filterCategory);
        });
        return categories.sort(function (a, b) {
            return a.rank - b.rank;
        });
    }

    render() {
        const {
            allFiltersData,
            connectedFilters: availableConnectedFilters,
            commonFilters: availableCommonFilters,
            audienceFilters: availableAudienceFilters,
        } = this.props;
        const source = allFiltersData.type;
        let creatorFilters = source !== 'PUBLIC' ? availableConnectedFilters[source] : [];
        let commonFilters = creatorFilters.concat(availableCommonFilters[source]);
        let audienceFilters = availableAudienceFilters[source];
        const availableCreatorCategories = this.getAvailableCategories(creatorFilters.concat(commonFilters));
        const availableAudienceCategories = this.getAvailableCategories(audienceFilters);
        const noCategoryCommonFilters = commonFilters.filter(f => !f.filterCategory);
        const noCategoryConnectedFilters = creatorFilters.filter(f => !f.filterCategory);
        const noCategoryAudienceFilters = audienceFilters.filter(f => !f.filterCategory);
        return (
            <div>
                <div className="filterLabel">Filters</div>
                <div className="filterMenuSection noPaddingBottom">
                    <div className="filterMenuSectionTitle largerFont">Creator</div>
                    {noCategoryCommonFilters.map((filter) => this.renderFilter(filter, 'common'))}
                    {noCategoryConnectedFilters.map((filter) => this.renderFilter(filter, 'connected'))}
                    {availableCreatorCategories.map(cat => {
                        const categoryCommonFilters = availableCommonFilters[source].filter(f => f.filterCategory && f.filterCategory.name === cat.name);
                        const categoryConnectedFilters = availableConnectedFilters[source].filter(f => f.filterCategory && f.filterCategory.name === cat.name);
                        return (
                            <div>
                                <div className="filterMenuSectionTitle mediumFont">{cat.label}</div>
                                <div>
                                    {categoryCommonFilters.map((filter) => this.renderFilter(filter, 'common'))}
                                </div>
                                <div>
                                    {source !== 'PUBLIC' &&
                                        categoryConnectedFilters.map((filter) => this.renderFilter(filter, 'connected'))
                                    }
                                </div>
                            </div>
                        );
                    })}
                </div>

                <div className="filterMenuSection">
                    <div className="filterMenuSectionTitle largerFont">
                        Audience
                    </div>
                    {noCategoryAudienceFilters.map((filter) => this.renderFilter(filter, 'insights'))}
                    {availableAudienceCategories.map(cat => {
                        const categoryAudienceFilters = availableAudienceFilters[source].filter(f => f.filterCategory && f.filterCategory.name === cat.name);
                        return (
                            <div>
                                <div className="filterMenuSectionTitle mediumFont">{cat.label}</div>
                                <div>
                                    {categoryAudienceFilters.map((filter) => this.renderFilter(filter, 'insights'))}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchFavorites: () => dispatch(fetchFavorites()),
    };
};

export default connect(null, mapDispatchToProps)(FilterMenu);
