import React from 'react';
import PropTypes from 'prop-types';

export default class AnalyticsInfluencerDisplay extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            sortBy: 'name',
            desc: 'true',
        };
    }

    render() {
        const {
            data: {
                avatar,
                instagramReach,
                facebookReach,
                twitterReach,
                youtubeReach,
                totalReach,
                tiktokReach,
                name,
            },
        } = this.props;
        return (
            <div className="tableBody">
                <div className="influencer">
                    {avatar ? <img src={avatar} /> : <div>{name[0]}</div>}
                    {name}
                </div>
                <div className="facebook">{facebookReach || '0'}</div>
                <div className="igPost">{instagramReach || '0'}</div>
                <div className="twitter">{twitterReach || '0'}</div>
                <div className="tiktok">{tiktokReach || '0'}</div>
                <div className="youtube">{youtubeReach || '0'}</div>
                <div className="reach">{totalReach || '0'}</div>
            </div>
        );
    }
}

AnalyticsInfluencerDisplay.propTypes = {
    data: PropTypes.shape({
        avatar:PropTypes.string,
        facebookReach: PropTypes.number,
        instagramReach: PropTypes.number,
        name: PropTypes.string,
        totalReach: PropTypes.number,
        twitterReach: PropTypes.number,
        tiktokReach: PropTypes.number,
        youtubeReach: PropTypes.number,
    }),
};

AnalyticsInfluencerDisplay.defaultProps = {
    data: {
        avatar:'',
        facebookReach: 0,
        instagramReach: 0,
        name: null,
        totalReach: 0,
        twitterReach: 0,
        tiktokReach: 0,
        youtubeReach: 0,
    },
};
