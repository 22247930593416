import { get, last } from 'lodash';
import constants from './constants';

/** "value: lastItem ? [lastItem] : null" - allows only one user to be selected, if you want multiple users to be selected, just use value argument itself */
const selectOnChange = (name, onChange, selectType) => (value) => {
    onChange({
        target: {
            name,
            value: selectType === 'multi' ? value : [last(value)],
        },
    });
};

const selectOnChangeValue = (name, onChange) => (value) => {
    onChange({
        target: {
            name,
            value: value.value
        },
    });
};

const validateField = (field) => {
    return field ? undefined : 'empty_field';
};

const validateEmail = (field) => {
    const { emailValidation } = constants;
    const isEmailValid = emailValidation.test(String(field).toLowerCase());
    if (!field) return 'empty_field';
    if (!isEmailValid) return 'wrong_email';

    return undefined;
};

const validateSelect = (array) => {
    if (!get(array, '[0]')) return 'empty_field';

    return undefined;
};

const validateForm = (key, errorsArray) => errorsArray.indexOf(key) !== -1;

export default {
    selectOnChange,
    validateField,
    validateEmail,
    validateForm,
    validateSelect,
    selectOnChangeValue
};
