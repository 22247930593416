import React from 'react';
import { injectIntl } from 'react-intl';
import qs from 'query-string';
import Select from 'react-select';

import './creatorProfileRate.scss';
import RatesService from '../../services/ratesService';
import EditablePiece from './editablePiece/EditablePiece';
import ProfileRate from './piecesInMain/ProfileRate';
import {
    PER_CHANNEL,
    PER_POST,
} from '../../constants/rateTypes';
import Api from '../../modules/Api';

class CreatorProfileRate extends React.Component {
    constructor(props) {
        super(props);

        CreatorProfileRate.getQueryParams = CreatorProfileRate.getQueryParams.bind(this);

        const params = CreatorProfileRate.getQueryParams();
        const editingSocialConnections = !!(params && (typeof params.con !== 'undefined'));

        this.state = {
            // edit flags within the edited component
            rateLocal: false,
            rateSave: false,
            activeRatesOption: null,
            currencyList: [],
            currency: 'USD',
            symbol: "$"
        };
    }

    setRateError = (flag) => {
        this.setState({
            rateError: flag,
        });
    }


    handleProfileChange = (newProfile) => {
        const { changeFunction } = this.props;
        const { currency } = this.state;
        newProfile.currencyCode = currency
        if (newProfile) {
            changeFunction(newProfile);
        }
    }

    static getQueryParams() {
        return qs.parse(document.location.search);
    }

    handleLocalEditChange = (piece, editFlag, saveFlag) => {
        const newVal = {};
        newVal[`${piece}Local`] = editFlag;
        newVal[`${piece}Save`] = !!saveFlag;

        this.setState(newVal);

        const { handleLocalEditChange } = this.props;

        if (typeof handleLocalEditChange === 'function') {
            handleLocalEditChange(editFlag, piece);
        }
    }

    componentDidMount() {
        this.storeProfileActiveRatesOptionToState();
    }

    storeProfileActiveRatesOptionToState = () => {
        const { profile } = this.props;
        const ratesService = new RatesService(profile);
        const activeRatesOption = ratesService.getInfluencerActiveRatesOption();

        this.setState({
            activeRatesOption,
            currency: profile.currencyCode
        });
        Api.getCurrencyList().then(result => {
            const { data } = result;
            const currencyList = []
            data.forEach(x => {
                const currency = {label: x.code, value: x.code, symbol: x.symbol}
                currencyList.push(currency)
            })
            this.setState({currencyList})
        })
    }

    toggleProfileActiveRatesOption = (activeRatesOption) => this.setState({
        activeRatesOption,
    })

    handleCurrencyChange = e => {
        this.setState({currency: e.value, symbol: e.symbol})
    }

    render() {
        const {
            activeRatesOption,
            rateLocal,
            rateSave,
            rateError,
            currencyList,
            currency,
            symbol
        } = this.state;
        const {
            className,
            currentLocal,
            editMode,
            intl: { formatMessage },
            isTalentManager,
            profile,
        } = this.props;
        const {
            management,
        } = profile;

        const permissions = {
            editInfo: management ? management.permissionsSet.editInfo : true,
            editPortfolio: management ? management.permissionsSet.editPortfolio : true,
            editRates: management ? management.permissionsSet.editRates : true,
            editSummary: management ? management.permissionsSet.editSummary : true,
        };

        if (!profile) {
            return null;
        }
        return (
            <div className={`creatorProfileRate ${className}`}>
                <div className="pieceBox rates">
                    <EditablePiece
                        editMode={permissions.editRates && editMode}
                        handleLocalEditChange={
                            (editFlag, saveFlag) => this.handleLocalEditChange('rate', editFlag, saveFlag)
                        }
                        isTalentManager={isTalentManager}
                        disableEdit={currentLocal !== null}
                        disableSave={rateError}
                    >
                        <div className="sectionRateHeader">
                            <div className="sectionRateHeader_title">
                                Rates
                            </div>
                            {rateLocal && (
                                <>
                                    <span className="ratesTabsLabel">Enter your rate by</span>
                                    <div className="ratesTabs">
                                        <div
                                            className={`rate-item-tab ${(activeRatesOption === PER_CHANNEL ? 'active' : '')}`}
                                            onClick={() => this.toggleProfileActiveRatesOption(PER_CHANNEL)}
                                        >
                                            per channel
                                        </div>
                                        <div
                                            className={`rate-item-tab ${(activeRatesOption === PER_POST ? 'active' : '')}`}
                                            onClick={() => this.toggleProfileActiveRatesOption(PER_POST)}
                                        >
                                            per campaign
                                        </div>
                                    </div>
                                    <div className="divider" />
                                </>
                            )}
                            {rateLocal && <Select value={currency} options={currencyList} placeholder="Select Currency"
                            onChange={this.handleCurrencyChange} clearable={false} single/>}
                        </div>

                        <ProfileRate
                            activeRatesOption={activeRatesOption}
                            isTalentManager={isTalentManager}
                            editMode={rateLocal}
                            saveFlag={rateSave}
                            profile={profile}
                            changeFunction={this.handleProfileChange}
                            errorStatusFunction={this.setRateError}
                            symbol={symbol}
                        />
                    </EditablePiece>
                </div>

            </div>
        );
    }
}

export default injectIntl(CreatorProfileRate);
