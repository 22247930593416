import React, { useState } from 'react';
import { CompetitorsFilter } from './CompetitorsFilter';
import { DurationFilter } from './DurationFilter';
import { CountryFilter } from './CountryFilter';
import { FilterTags } from '../../../shared/FilterTags/FilterTags'

import './competitiveAnalysisFilters.scss';

export const CompetitiveAnalysisFilters = ({
  selectedCompetitors,
  setSelectedCompetitors,
  duration,
  setDuration,
  filters,
  setFilters,
  handleDataUpdate,
}) => {
  const [dateInput, setDateInput] = useState(duration);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [hashtagInput, setHashtagInput] = useState('');
  const [keywordInput, setKeywordInput] = useState('');

  const handleSetCompetitors = (data) => {
    setIsButtonEnabled(true);
    setSelectedCompetitors(data);
  };

  const handleSetDuration = (data) => {
    setIsButtonEnabled(true);
    setDateInput(data);
  };

  const handleSetCountry = (country) => {
    const newFilters = [
      ...filters.filter(filter => filter.filterType !== 'Country'),
      ({ ...country, filterType: 'Country' })
    ];
    setFilters(newFilters);
    handleDataUpdate(duration, newFilters);
  };

  const handleSetHashtag = (e) => {
    const inputValue = e.target.value;
    setHashtagInput(inputValue);
    if (inputValue) setIsButtonEnabled(true);
  };

  const handleSetKeyword = (e) => {
    const inputValue = e.target.value;
    setKeywordInput(inputValue);
    if (inputValue) setIsButtonEnabled(true);
  };

  const handleRemoveFilter = (filterType, value) => {
    const newFilters = filters.filter((filter) => filter.filterType !== filterType && filter.value !== value);
    setFilters(newFilters);
    handleDataUpdate(duration, newFilters);
  };

  const isFilterSelected = (filterType, value) => filters.find(filter => filter.filterType === filterType && filter.value === value);

  const handleSubmit = (e) => {
    e.preventDefault();

    const newFilters = [
      ...filters,
      ...(hashtagInput && !isFilterSelected('Hashtag', hashtagInput)
        ? [{ value: hashtagInput, label: hashtagInput, filterType: 'Hashtag' }]
        : []
      ),
      ...(keywordInput && !isFilterSelected('Keyword', keywordInput)
        ? [{ value: keywordInput, label: keywordInput, filterType: 'Keyword' }]
        : []
      ),
    ];

    handleDataUpdate(dateInput, newFilters);
    setFilters(newFilters);
    setDuration(dateInput);
    setHashtagInput('');
    setKeywordInput('');
  };

  const handleClearFilters = () => {
    setFilters([]);
    handleDataUpdate(duration, []);
  };

  const tagOptions = [
    ...filters.map(filter => ({
      ...filter,
      filterLabel: filter.filterType === 'Country' ? 'Country:' : filter.filterType === 'Hashtag' ? '#' : 'Keyword:',
    }))
  ];

  return (
    <form className="filters-container" style={{ padding: tagOptions.length ? '24px 0 0 0' : '24px 0' }} onSubmit={handleSubmit}>
      <div className="inner-filters-wrapper">
        <div className="filter-row">
          <div className="dropdown-filter">
            <CompetitorsFilter selectedCompetitors={selectedCompetitors} setSelectedCompetitors={handleSetCompetitors} />
          </div>
          <div className="dropdown-filter">
            <DurationFilter duration={dateInput} setDuration={handleSetDuration} />
          </div>
        </div>
        <div className="filter-row">
          <input className="text-filter" placeholder="#Hashtags" onChange={handleSetHashtag} value={hashtagInput} />
          <input className="text-filter" placeholder="Keywords" onChange={handleSetKeyword} value={keywordInput} />
          <CountryFilter
            filters={filters}
            handleSetCountry={(country) => handleSetCountry(country)}
          />
          <button type="submit" className="filter-button" disabled={!isButtonEnabled}>
            Show update
          </button>
        </div>
        {tagOptions.length ? (
          <>
            <div className="filter-row-separator"></div>
            <div className="active-filters-bar">
              <FilterTags
                selectedOptions={tagOptions}
                handleRemove={handleRemoveFilter}
                handleClearFilters={handleClearFilters}
              />
            </div>
          </>
        ) : null}
      </div>
    </form>
  );
};
