import React, { Component } from 'react';
import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { SUPER_ADMIN, SUPER_ADMIN_STR } from '../../constants/authorities';

class logoItem extends Component {
    state = {
        showDropdown: false,
        imageError: false
    };

    open = () => this.setState({ showDropdown: true });

    close = () => this.setState({ showDropdown: false });

    handleEdit = () => {
        const {
            plan,
            handleEditOpen,
        } = this.props;

        handleEditOpen(plan);
    }

    delete = () => {

    }

    moveToBrands = () => {
        const {
            plan: { id },
            moveToBrandsWithFilter,
        } = this.props;

        moveToBrandsWithFilter(id);
    }

    setError = () => {
        this.setState({imageError: true})
    }

    render() {
        const { plan, profile } = this.props;
        const { showDropdown, imageError } = this.state;
        return (
            <tr className="table-row">
                <td>
                    <div
                        className="user-info-holder"
                        onClick={this.moveToBrands}
                    >
                        <div className="image-holder">
                            {plan.logo && !imageError ? <img
                                src={plan.logo}
                                alt={plan.name}
                                className="logoClass"
                                onError={this.setError}
                            /> : plan.name[0]}
                        </div>
                        <div className="user-info">
                            <div className="user-name">
                                { plan.brand }
                            </div>
                        </div>
                    </div>
                </td>
                <td className="name">
                    { plan.name }
                </td>
                <td className="creator">
                    { plan.creators }
                </td>
                <td className="tag">
                    { plan.tags.map(tag => <div className="tagItem">{tag}</div>) }
                </td>
                <td className="role">
                    { plan.createdBy }
                </td>
                <td className="date">
                    { moment(plan.createdOn).format('MMM D') }
                </td>
                <td className="date">
                    { moment(plan.modifedOn).format('MMM D') }
                </td>
                {
                    profile && profile.role === SUPER_ADMIN_STR &&
                    <td
                        onMouseOver={this.open}
                        onMouseLeave={this.close}
                    >
                        <div className={cx("action", { hidden: plan.isArchived })}>
                            {showDropdown && !plan.isArchived ? (
                                <div className="popup">
                                    <div onClick={this.handleEdit}>Edit plan</div>
                                    <div onClick={this.delete}>Delete</div>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    </td>
                }
            </tr>
        )
    }
}


logoItem.propTypes = {
};


export default logoItem;
