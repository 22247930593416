import get from 'lodash/get';
import moment from 'moment';

const convertBrandItem = (brand) => ({
    id: get(brand, 'brandId'),
    campaigns: get(brand, 'campaigns'),
    advertiser: get(brand, 'advertiser.name'),
    advertiserOption: get(brand, 'advertiser'),
    logo: get(brand, 'brandLogo'),
    brand: get(brand, 'brandName'),
    createdBy: get(brand, 'createdBy'),
    agents: get(brand, 'agents'),
    date: moment.utc(get(brand, 'createdOn')).local().format('MMM DD, YYYY'),
    email: get(brand, 'billingContractEmail'),
    owner: [`${get(brand, 'createdBy.firstname')} ${get(brand, 'createdBy.lastname')}`],
    countryCode: get(brand, 'countryCode'),
    defaultCurrencyCode: get(brand, 'defaultCurrencyCode'),
    accessRights: get(brand, 'accessRights'),
    parentBrandId: get(brand, 'parentBrand') ? get(brand, 'parentBrand').id : null
})

const brandItemsFactory = (brands = []) => brands.map(convertBrandItem);

const convertAdvertiserOption = (item) => ({
    value: get(item, 'name'),
    label: get(item, 'name'),
    profileImage: get(item, 'logoUrl'),
    isArchived: get(item, 'isArchived'),
    id: get(item, 'id')
})

const convertBrandOwnerOption = (item) => ({
    value: `${get(item, 'firstname')} ${get(item, 'lastname')}`,
    label: `${get(item, 'firstname')} ${get(item, 'lastname')}`,
    profileImage: get(item, 'pictureUrl'),
    id: get(item, 'id')
})

const advertiserOptionsFactory = (advertisers = []) => advertisers.map(convertAdvertiserOption);

const brandOwnerOptionsFactory = (brandOwners = []) => brandOwners.map(convertBrandOwnerOption);

const convertParamsData = (filters) => ({
    num: get(filters, 'numPerPage'),
    status: get(filters, 'brandsStatusQuery'),
    filter: get(filters, 'advertiserQuery')
})

export default {
    brandItemsFactory,
    convertBrandItem,
    advertiserOptionsFactory,
    brandOwnerOptionsFactory,
    convertAdvertiserOption,
    convertBrandOwnerOption,
    convertParamsData,
};
