import React from 'react';
import moment from 'moment';
import cx from 'classnames';
import Lookup from '../../../../../modules/Lookup';
import DropdownMenu from '../../../DropdownMenu/DropdownMenu';
import { history } from '../../../../../store/configureStore';
import { StarsIcon } from '../../../../campaign/posts/AutoDetectedPopup/AutoDetectedPopup';
import { NEEDS_REVIEW, APPROVED } from '../../../../../constants/statusTypes';
import { hexToRGB } from '../../../../../modules/utils/helpers';
import './assignmentItem.scss';
import GeneralTooltip from '../../../../shared/generalTooltip/GeneralTooltip';

export default function AssignmentItem({ item, openUpdateURL, openSlider, handleFileUpload }) {
    return (
        <div className={cx('assignment-list-item', item.post.publishType === 'AUTODETECTED' && 'auto-detected-strip')}>
            <div className="assignment-type">
                <span className="type-label">{Lookup.getRateType(item.type).label}</span>
                <span className="type-count">{item.idx && item.total ? ` (${item.idx}/${item.total})` : ''}</span>
                {item.publishedListLength.length === 0 && <span className="type-draft"> - Draft</span>}
            </div>

            <div className="post-analytics">
                {item.post && item.post.state !=="DRAFT" && <><label class="upload-btn">
                    <input
                        type="file"
                        accept=".gif,.jpg,.jpeg,.png,.svg"
                        onChange={(e) => handleFileUpload(e, item.postKey, item.post.analyticsScreenshotUrls || [])}
                        multiple
                    />
                    <img className="upload-img" src="/images/ic-upload-white.svg" />
                    <span className="upload-label">Upload Screenshot</span>
                </label>

                <span
                    className={cx('screenshot-count', !!item?.postAScreens?.length && 'screenshots-active')}
                    onClick={() => item?.postAScreens?.length && openSlider(item.postKey)}
                >
                    {`${item?.postAScreens?.length || 0} screenshots`}
                </span></>}
            </div>

            <div className="submit-date">{moment(item.postDate).format('MMM DD')}</div>

            <div className="status">
                <div
                    className="status-tag"
                    style={{
                        color: Lookup.getStatusType(item.pstatus).icolor,
                        backgroundColor: hexToRGB(Lookup.getStatusType(item.pstatus).icolor, 0.15),
                    }}
                >
                    <span className="status-label">{Lookup.getStatusType(item.pstatus).ilabel}</span>
                </div>
            </div>

            <div className="actions">
                {item.post.publishType === 'AUTODETECTED' && (
                    <div className="auto-detected-link-creator">
                        <StarsIcon />
                        Auto-detected link
                        <GeneralTooltip text="Automated detection of sponsored posts will be conducted by examining social handles or campaign hashtags or brand URLs. If a post is not captured within a three-month, kindly proceed to publish the post URL manually or update the auto-detected post URL." />
                    </div>
                )}

                {item.post.state === 'DRAFT' && item.pstatus !== NEEDS_REVIEW && (
                    <div className="view-edit-btn" onClick={() => history.push(item.composeurl)}>
                        {item.pstatus === APPROVED ? 'View' : 'Edit'}
                    </div>
                )}

                {item.post.state !== 'DRAFT' &&
                    <div className="actions-menu">
                        <div className="dropdown-menu-wrapper">
                            <DropdownMenu>
                                <div onClick={openUpdateURL}>Update Post URL</div>
                                {/* <div onClick={() => this.setState({ transactionLogData: item })}>Transaction Log</div> */}
                            </DropdownMenu>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export function AssignmentDraftPost({
    itemIdx,
    totalIdx,
    postType,
    isContractSigned,
    isDraftRequired,
    isPublishOverDue,
    publishDate,
    handleSubmitPost,
    influencerId,
    assignment,
}) {
    const handlePostClick = () =>
        isDraftRequired &&
        isContractSigned &&
        history.push({
            pathname: `/assignments/${assignment.id}/compose/${postType}`,
            search: influencerId ? `?influencerId=${influencerId}` : '',
        });

    return (
        <div className={cx('assignment-list-item', 'draft-post')} onClick={handlePostClick}>
            <div className="assignment-type">
                <span className="type-label">{Lookup.getRateType(postType).label}</span>
                <span className="type-count">{itemIdx && totalIdx ? ` (${itemIdx}/${totalIdx})` : ''}</span>
                {isDraftRequired && <span className="type-draft"> - Draft</span>}
            </div>
            {!isDraftRequired && (
                <span className="type-draft" onClick={handleSubmitPost}>
                    + Submit your published post
                </span>
            )}
            {isPublishOverDue > -1 && (
                <div className="dueStatusNotice">
                    {`${isPublishOverDue === 0 ? 'Publish Due' : 'Post Past Due'} : ${moment(publishDate).format(
                        'MMM DD, YYYY',
                    )}`}
                </div>
            )}
        </div>
    );
}
