import React, { Component } from 'react';
import Api from '../../modules/Api';
import Lookup from '../../modules/Lookup';
import NetworkUrlInput from '../shared/networkUrlInput/networkUrlInput';
import { safeIsUrl } from '../../modules/CommonServices';
import './PublishContent.scss';
import { INSTAGRAM_POST, INSTAGRAM_STORY, BLOG_POST } from '../../constants/rateTypes';

export default class PublishContent extends Component {
    constructor(props) {
        super(props);

        this.handleInputURL = this.handleInputURL.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.submitPublish = this.submitPublish.bind(this);
        const { publishInfo } = this.props;
        const postUrls = [];
        if (Array.isArray(publishInfo) && publishInfo.length > 0) {
            publishInfo.forEach((publish) => {
                postUrls.push({ itemType: 'POST', networkType: publish.type, postId: publish.postId, url: '' });
            });
        }
        this.state = {
            postUrls,
            submitValid: false,
        };
        this.editIndex = -1;
    }

    submitPublish() {
        this.setState({
            submitValid: false,
            waiting: true,
        });
        const { publishInfo, assignmentUserId, publishedSuccessFunction, assignmentChoreId } = this.props;
        const { postUrls } = this.state;
        const posts = [];
        if (
            postUrls.filter((curl) => curl.itemType === 'POST').length === publishInfo.length &&
            publishInfo.length > 0
        ) {
            postUrls
                .filter((curl) => curl.itemType === 'POST')
                .forEach((publish) => {
                    const sharedOn = [];
                    const shareLinks = postUrls.filter(
                        (surl) => surl.itemType === 'SHARE' && surl.postId === publish.networkType,
                    );
                    if (shareLinks.length > 0) {
                        shareLinks.forEach((share) => {
                            sharedOn.push({ network: share.networkType, url: share.url });
                        });
                    }
                    if (publish.postId) {
                        posts.push({
                            contentUrl: publish.url,
                            userPostId: publish.postId,
                            postType: publish.networkType,
                            sharedOn,
                            assignmentChoreId
                        });
                    } else {
                        posts.push({
                            contentUrl: publish.url,
                            postType: publish.networkType,
                            sharedOn,
                            assignmentChoreId
                        });
                    }
                });
        }
        if (posts.length > 0) {
            // TODO: provide sequential handling of multiple posts (if needed).
            const payload ={
                assignmentUserId: Number(assignmentUserId),
            };
            const post = posts[0];
            Object.keys(post).forEach((name) => {
                payload[name] = post[name];
            });


            Api.PublishSinglePost(payload).then(
                (res) => {
                    publishedSuccessFunction(res);
                },
                (err) => {
                    const error = err.message || JSON.stringify({ message: 'Please refresh or try again later.' });
                    this.props.apiErrorFunction(error);
                    this.setState({
                        submitValid: true,
                    });
                },
            );
        }
    }

    handleEdit(e) {
        const { value } = e.target;
        this.state.postUrls[this.editIndex].url = value;
        this.handleInputURL();
    }

    handleInputURL(urls) {
        const { postUrls } = this.state;
        const currentUrls = postUrls.slice(0);
        let shareValid = true;
        if (Array.isArray(urls) && urls.length > 0) {
            const surls = currentUrls.filter((curl) => curl.postId === urls[0].postId && curl.itemType === 'SHARE');
            if (surls.length > 0) {
                surls.forEach((surl) => {
                    const rindex = currentUrls.findIndex(
                        (furl) =>
                            furl.postId === surl.postId &&
                            furl.itemType === 'SHARE' &&
                            furl.networkType === surl.networkType,
                    );
                    if (rindex > -1) {
                        currentUrls.splice(rindex, 1);
                    }
                });
            }
            urls.forEach((posturl) => {
                if (safeIsUrl(posturl.url)) {
                    currentUrls.push(posturl);
                } else if (posturl.url !== '') {
                    shareValid = false;
                }
            });
        }

        let sValid =
            shareValid &&
            currentUrls.length > 0 &&
            currentUrls.filter((curl) => curl.itemType === 'POST').length === this.props.publishInfo.length;
        if (sValid) {
            currentUrls
                .filter((curl) => curl.itemType === 'POST')
                .forEach((posturl) => {
                    if (!safeIsUrl(posturl.url)) {
                        sValid = false;
                    }
                });
        }
        this.setState({ postUrls: currentUrls, submitValid: sValid });
    }

    filterPublishInfo = (list) => {
        let resultList = [];
        if (list && list.length > 0) {
            resultList = list.filter((i) => i.type !== INSTAGRAM_POST && i.type !== INSTAGRAM_STORY);
        }
        return resultList;
    };

    checkUrl = (url, type) => {
        if (type.includes("TIKTOK")) {
            const tikTokRegex1 = /\bhttps:\/\/www\.tiktok\.com\/@[^/]*\/video\/[^/]*$/
            const tikTokRegex2 = /\bhttps:\/\/vm\.tiktok\.com\/[^/]*\/[^/]*$/
            return tikTokRegex1.test(url) || tikTokRegex2.test(url)
        }
        if (type.includes("YOUTUBE")) {
            const youtubeRegex1 = /\bhttps:\/\/m\.youtube\.com\/watch\?v=(.)/
            const youtubeRegex2 = /\bhttps:\/\/www\.youtube\.com\/watch\?v=(.)/
            const youtubeRegex3 = /\bhttps:\/\/youtu\.be\/(.)/
            return youtubeRegex1.test(url) || youtubeRegex2.test(url) || youtubeRegex3.test(url)
        }
        return safeIsUrl(url)
    }

    checkallUrl = () => {
        const { publishInfo } = this.props;
        const { postUrls } = this.state
        let isValid = true;
        publishInfo.map((info, index) => {
            if (!this.checkUrl(postUrls[index].url, info.type)) {
                isValid = false
            }
        })
        console.log(isValid)
        return isValid;
    }

    render() {
        const { publishInfo, readOnly, typeLabel, statusLabel, cancelFunc } = this.props;
        const { postUrls, submitValid } = this.state;
        const publishInfoExcludeIGType = this.filterPublishInfo(publishInfo);
        const self = this;
        if (publishInfoExcludeIGType.length > 0) {
            return (
                <div>
                    <div className="newContainer">
                        <div className="labelRow">
                            <div className="typeLabel">{typeLabel}</div>
                            <div className="statusLabel">{statusLabel}</div>
                        </div>
                        {Array.isArray(publishInfo) &&
                        publishInfo.length > 0 &&
                        publishInfo.map((publish, index) => {
                            if (publish.type !== INSTAGRAM_POST && publish.type !== INSTAGRAM_STORY) {
                                return (
                                    <div className="urlsUpload" key={publish.type}>
                                        <div className="assignmentsUploadSubTitle">
                                            {Lookup.getRateType(publish.type).label} URL
                                        </div>
                                        <div className="assignmentsUploadedArea">
                                            <div className="urlInput">
                                                <div>
                                                    <div className="valueContainer">
                                                        <input
                                                            value={postUrls[index].url}
                                                            onFocus={() => {
                                                                self.editIndex = index;
                                                            }}
                                                            disabled={readOnly}
                                                            onChange={self.handleEdit}
                                                            className={
                                                                this.editIndex === index &&
                                                                !this.checkUrl(postUrls[index].url, publish.type)
                                                                    ? 'editInput invalid'
                                                                    : 'editInput'
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {this.editIndex === index &&
                                            postUrls[index] &&
                                            !this.checkUrl(postUrls[index].url, publish.type) && (
                                                <div className="invalid placeholder">Invalid {Lookup.getRateType(publish.type).label} URL. Please check the URL you entered and try again.</div>
                                            )}
                                        </div>
                                        {publish.type !== BLOG_POST && (
                                            <>
                                                <div className="assignmentsUploadSubTitle">Shared Links</div>
                                                <div className="assignmentsUploadedArea">
                                                    <NetworkUrlInput
                                                        placeholder="Your URL"
                                                        readOnly={readOnly}
                                                        itemType="SHARE"
                                                        postId={publish.type}
                                                        valueChangeFunction={this.handleInputURL}
                                                    />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                );
                            } 
                            return null;
                            
                        })}
                        <div className="buttonSection">
                            <div
                                className="cancelButton"
                                onClick={cancelFunc}
                            >
                                Cancel
                            </div>
                            <div
                                className={`submit-link-btn ${  (submitValid && this.checkallUrl()) ? '' : 'disabled'}`}
                                onClick={
                                    (submitValid && this.checkallUrl())
                                        ? (e) => {
                                            e.target.disabled = true;
                                            this.submitPublish();
                                        }
                                        : null
                                }
                            >
                                Submit
                            </div>
                        </div>
                    </div>
                </div>
            );
        } 
        return null;
        
    }
}
