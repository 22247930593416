import combineEvents from '../../combineEvents';
import {
    OPEN_MODAL,
    CLOSE_MODAL,
} from './actions';

const initialState = {
    isOpen: false,
};

export default combineEvents({
    [CLOSE_MODAL]: (state, { isOpen }) => ({
        ...state,
        isOpen,
    }),
    [OPEN_MODAL]: (state, { isOpen }) => ({
        ...state,
        isOpen,
    }),
}, initialState);
