import React, { useState, useRef, useEffect } from 'react';
import '../favourite/favouriteList.scss';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Trash } from 'phosphor-react';
import PleaseWaitWhite from '../shared/pleaseWait/PleaseWaitWhite';
import TopNavigation from "../shared/topNavigation/TopNavigation"
import ApiError from '../shared/apiError/ApiError';
import PaginationNew from '../shared/pagination/PaginationNew'
import SuccessBanner from "../favourite/SuccessBanner";
import Api from '../../modules/Api';
import PopUpDialogueBase from '../shared/popUpDialogueBase/PopUpDialogueBase';
import SortableTable from '../favourite/SortableTable';
import ListPageTopBar from '../favourite/ListPageTopBar';
import AddEditModal from './AddEditModal';

const defaultOrders = {
    Name: 'DESC',
    Brand: 'DESC',
    CreatedOn: 'DESC',
    UpdatedOn: 'DESC',
    CreatedBy: 'DESC',
};

const titles = [
    { key: 'Brand', label: 'Brand', width: '18%', flag: 'brand' },
    { key: 'Name', label: 'Name', width: '18%', flag: 'text', style: { color: '#000000', fontWeight: 'bold' } },
    { key: 'creatorCount', label: 'Creators', width: '10%', flag: 'text' },
    { key: 'tags', label: 'Tags', width: '18%', flag: 'tags' },
    { key: 'CreatedBy', label: 'Created by', width: '12%', flag: 'text' },
    { key: 'CreatedOn', label: 'Created on', width: '12%', flag: 'date' },
    { key: 'UpdatedOn', label: 'Modified on', width: '12%', flag: 'date' },
];

const Actions = [
    { key: 'edit', label: 'Edit plan' },
    // { key: 'duplicate', label: 'Duplicate' },
    { key: 'delete', label: 'Delete' },
    // 'divider',
    // { key: 'plan', label: 'Add to plan' },
];

const PlansList = ({
    history,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [apiError, setApiError] = useState();
    const [errTitle, setErrTitle] = useState('');
    const [sortBy, setSortBy] = useState('UpdatedOn');
    const [orders, setOrders] = useState(defaultOrders);
    const [totalCount, setTotalCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [planList, setPlanList] = useState([]);
    const [currentPlan, setCurrentPlan] = useState(null);
    const [showDelete, setShowDelete] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [filterValue, setFilterValue] = useState('');
    const containerRef = useRef();
    const [countryList, setCountryList] = useState([]);


    useEffect(() => {
        Api.getCountries().then(response => {
            const { data: responseData } = response
            const newList = []
            responseData.forEach(x => {
                const newItem = {
                    label: x.name,
                    value: x.code,
                }
                newList.push(newItem);
            })
            setCountryList(newList)
        })
    }, [])

    useEffect(() => {
        fetchData();
    }, [sortBy, orders, currentPage, pageSize]);

    useEffect(() => {
        setCurrentPage(1);
        fetchData();
    }, [filterValue]);

    const fetchData = () => {
        const params = {
            direction: orders[sortBy],
            keyword: filterValue,
            page: currentPage - 1,
            size: pageSize,
            sortBy
        }
        setIsLoading(true);
        Api.searchPlans(params)
            .then(res => {
                if (res.data) {
                    setPlanList(res.data.map(d => ({
                        ...d,
                        Name: d.name,
                        Brand: d.brand,
                        UpdatedOn: d.updatedOn,
                        CreatedOn: d.createdOn,
                        CreatedBy: `${d.createdBy.firstname ? `${d.createdBy.firstname  } ` : ''}${d.createdBy.lastname ? d.createdBy.lastname : ''}`
                    })));
                } else {
                    setPlanList([]);
                }
                if (res.pageInfo) {
                    setTotalCount(res.pageInfo.totalElements);
                } else {
                    setTotalCount(0);
                }
                setIsLoading(false);
            })
            .catch(err => {
                handleApiError(err);
                setIsLoading(false);
            });
    };

    const handleApiError = (err) => {
        setApiError(err);
        if (err && err.originalMessage) {
            setErrTitle(err.originalMessage);
        }
    };

    const handleSortChange = (val) => {
        if (sortBy === val) {
            if (orders[val]) {
                const update = { ...orders };
                update[val] = orders[val] === 'ASC' ? 'DESC' : 'ASC';
                setOrders(update);
            }
        } else {
            setSortBy(val);
        }
    };

    const handlePageChange = (val) => {
        setCurrentPage(val);
    };

    const handleLimitChange = (val) => {
        setPageSize(val);
    };

    const addNew = () => {
        setCurrentPlan(null);
        setShowModal(true);
    };

    const CancelError = () => {
        setApiError(null);
        setErrTitle('');
    };

    const filterLists = (e) => {
        setFilterValue(e.target.value);
    };

    const onActions = (action, plan) => {
        switch (action) {
        case 'delete':
            setShowDelete(true);
            break;
        case 'edit':
            setShowModal(true);
            break;
        default:
            return;
        }
        setCurrentPlan(plan);
    };

    const cancelDelete = () => {
        setShowDelete(false);
        setCurrentPlan(null);
    };

    const closeModal = () => {
        setShowModal(false);
        setCurrentPlan(null);
    };

    const onSuccess = (res, update) => {
        setShowSuccess(true);
        if (update) {
            setSuccessMessage('Success! Your list has been updated.');
        } else {
            setSuccessMessage('Success! Your list has been created.');
        }
    };

    const onSubmit = (data) => {
        closeModal();
        setIsLoading(true);

        if (data.id) {
            Api.updatePlan(data.id, data)
                .then(res => {
                    fetchData();
                    onSuccess(res, true);
                })
                .catch(err => {
                    setIsLoading(false);
                    handleApiError(err);
                })
        } else {
            Api.createPlan(data)
                .then(res => {
                    // fetchData();
                    onSuccess(res, false);
                    history.push(`/plans/${res.data.id}`);
                })
                .catch(err => {
                    setIsLoading(false);
                    handleApiError(err);
                })
        }
    };

    const closeSuccessMessage = () => {
        setShowSuccess(false);
        setSuccessMessage('');
    };

    const deletePlan = () => {
        if (currentPlan) {
            setIsLoading(true);
            Api.deletePlan(currentPlan.id)
                .then(res => {
                    setShowDelete(false);
                    setCurrentPlan(null);
                    fetchData();
                })
                .catch(err => {
                    setIsLoading(false);
                    setShowDelete(false);
                    setCurrentPlan(null);
                    handleApiError(err);
                });
        } else {
            setShowDelete(false);
            setCurrentPlan(null);
        }
    };

    const onCardClick = (plan) => {
        history.push(`/plans/${plan.id}`);
    };
    return (
        <div className='favouriteListContainer'>
            <PleaseWaitWhite
                show={isLoading}
            />
            <ApiError
                show={apiError}
                errTitle={errTitle}
                error={apiError}
                cancelFunction={CancelError}
            />
            <TopNavigation current="plans" />
            <div className='contentContainer' ref={containerRef}>
                <ListPageTopBar
                    label="Plans"
                    onChange={filterLists}
                    onButton={addNew}
                    searchPlaceHolder="Search plan..."
                    buttonLabel="New plan"
                />
                <div className='listTableContainer'>
                    {planList && planList.length > 0 && <SortableTable
                        columns={titles}
                        orders={orders}
                        sortBy={sortBy}
                        onChange={handleSortChange}
                        listData={planList}
                        actions={Actions}
                        onActions={onActions}
                        onCardClick={onCardClick}
                        countryList={countryList}
                    />}
                    <PaginationNew
                        total={totalCount}
                        limit={pageSize}
                        current={currentPage}
                        parentContainer={containerRef.current}
                        handlePageChange={handlePageChange}
                        limitChangeFunction={handleLimitChange}
                    />
                    <div style={{ height: 45 }} />
                </div>
            </div>
            <PopUpDialogueBase
                title="Confirm delete"
                show={showDelete}
                icon={<Trash color="#d90036" size={40} weight="bold" />}
                message={<div className="favouriteDeleteMessage">Are you sure you want to delete <span>{`"${currentPlan && currentPlan.name}"`}</span> list?</div>}
                cancelFunction={cancelDelete}
                proceedLabel="Delete"
                loading={isLoading}
                proceedButtonStyle={{ backgroundColor: '#d90036', width: 100 }}
                cancelButtonStyle={{ width: 100 }}
                proceedFunction={deletePlan}
            />
            <AddEditModal
                show={showModal}
                data={currentPlan}
                onClose={closeModal}
                onSubmit={onSubmit}
                shouldClear
                countryList={countryList}
            />
            <SuccessBanner
                show={showSuccess}
                closeFunction={closeSuccessMessage}
                message={successMessage}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlansList));