import React, { useState, useEffect, useLayoutEffect, createRef } from 'react';
import cx from 'classnames';
import './campaignInfluencerSearch.scss';
import { Warning } from 'phosphor-react';
import InviteDeliverables from './InviteDeliverables';
import InviteRates from './InviteRates';
import HeaderWithCampaignAndAssignments from '../headerWithCampaignAndAssignments/HeaderWithCampaignAndAssignments';
import { InviteSteps } from './components/InviteSteps';
import { InviteControl } from './components/InviteControl';
import PopUpDialogueBase from '../../shared/popUpDialogueBase/PopUpDialogueBase';
import Api from '../../../modules/Api';
import ApiError from '../../shared/apiError/ApiError';
import { LoadingDialogue } from '../../shared/loadingDialogue/loadingDialogue';


const steps = [
    { value: 'channels', label: 'Deliverables & Channels' },
    { value: 'rates', label: 'Rates' },
];

const CampaignInfluencerInviteEdit = ({
    campaignId,
    budget,
    setNavigationFunction,
    history,
    match: { params: { assignmentId, userId } },
    currencyCode
}) => {
    const [curStep, setCurStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [creators, setCreators] = useState([]);
    const [nextLabel, setNextLabel] = useState('Next');
    const [initialize, setInitialized] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [showApiError, setShowApiError] = useState(false);
    const [apiError, setApiError] = useState(null);
    const [currency, setCurrency] = useState(null);
    const [ratesSelectedOptions, setRatesSelectedOptions] = useState({rateOption: 2, subOption: 1});
    const deliverableRef = createRef();
    const ratesRef = createRef();
    const isPublic = history.location.search === '?isPublic=true' || history.location.pathname.endsWith('?isPublic=true')
    const fetchInfluencer = () => {
        setLoading(true);
        if(!isPublic) {
            Api.getAssignmentUser(assignmentId, userId)
            .then(res => {
                const influencer = {
                    id: res.id,
                    rates: res.rates || {},
                    deliverables: res.deliverables || {},
                    name: `${res.user.firstname} ${res.user.lastname}`,
                    pictureUrl: res.user.pictureUrl,
                };
                setCreators([influencer]);
                if (res.rates && res.rates.GENERAL !== undefined && res.rates.GENERAL >= 0) {
                    setRatesSelectedOptions({rateOption: 2, subOption: 0});
                }
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
            })
        } else {
            Api.getAssignmentUserPublic(assignmentId, userId)
            .then(res => {
                const influencer = {
                    id: res.id,
                    rates: res.rates || {},
                    deliverables: res.deliverables || {},
                    name: res.creatorName,
                    pictureUrl: res.avatarUrl,
                    isPublic: true
                };
                setCreators([influencer]);
                if (res.rates && res.rates.GENERAL !== undefined && res.rates.GENERAL >= 0) {
                    setRatesSelectedOptions({rateOption: 2, subOption: 0});
                }
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
            })
        }
    }

    useEffect(() => {
        Api.getCurrencyCode(currencyCode).then(result => {
            setCurrency(result.data)
        })
    }, []);

    useEffect(() => {
        if (!initialize && setNavigationFunction) {
            setNavigationFunction(assignmentId);
            setInitialized(true);
        }

        if (assignmentId && userId) {
            fetchInfluencer();
        }
    }, [assignmentId, userId]);

    useLayoutEffect(() => {
        switch (steps[curStep].value) {
        case 'channels':
            setNextLabel('Next');
            break;
        case 'rates':
            setNextLabel('Save and Exit');
            break;
        }
    }, [curStep]);

    const handleCancel = () => {
        setShowPopup(true);
    };

    const onCancelError = () => {
        setApiError(null);
        setShowApiError(false);
    }

    const onBack = () => {
        if (steps[curStep].value === 'rates') {
            const data = ratesRef.current.onSaveData(true);
            if (data && data.creators) {
                setCreators(data.creators);
            }
            if (data && data.selectedOptions) {
                setRatesSelectedOptions(data.selectedOptions);
            }
        } 
        setCurStep(curStep - 1);
    }

    const handleSubmit = (data) => {
        const payload = data.map(d => {
            const rates = {};
            Object.keys(d.rates).map(key => {
                if (d.rates[key] !== '') {
                    const parsed = Number.parseInt(d.rates[key]);
                    rates[key] = (Number.isNaN(parsed) ? 0 : parsed)
                }
            })

            const deliverables = {};
            Object.keys(d.deliverables).map(key => {
                if (d.deliverables[key] !== '' && d.deliverables[key] !== '0') {
                    const parsed = Number.parseInt(d.deliverables[key]);
                    deliverables[key] = (Number.isNaN(parsed) ? 0 : parsed)
                }
            })

            return {
                rates,
                deliverables,
            }
        })

        setLoading(true);
        if (!data[0].isPublic) {
            Api.inviteInfluencerUpdate(data[0].id, payload[0])
            .then(res => {
                history.goBack();
            })
            .catch(err => {
                setApiError(err);
                setShowApiError(true);
                setLoading(false);
            })
        } else {
            Api.inviteInfluencerUpdatePublic(assignmentId, data[0].id, payload[0])
            .then(res => {
                history.goBack();
            })
            .catch(err => {
                setApiError(err);
                setShowApiError(true);
                setLoading(false);
            })
        }

    }

    const onNext = () => {
        if (steps[curStep].value === 'channels') {
            const data = deliverableRef.current.onNext();
            if (data) {
                setCurStep(curStep + 1);
                setCreators(data.creators || []);
                return;
            }
        }
        if (steps[curStep].value === 'rates') {
            const data = ratesRef.current.onSaveData();
            if (data && data.creators) {
                handleSubmit(data.creators);
            }
        }
    }

    const onCreatorsChange = () => {

    }

    const renderContent = () => {
        switch (steps[curStep].value) {
        case 'channels':
            return <InviteDeliverables
                ref={ref => ref ? deliverableRef.current = ref : null}
                editing
                profiles={creators}
                onChange={onCreatorsChange}
                currency={currency}
            />
        case 'rates':
            return <InviteRates
                ref={ref => ref ? ratesRef.current = ref : null}
                profiles={creators}
                editing
                selectedOptions={ratesSelectedOptions}
                currency={currency}
            />
        default:
            return null;
        }
    };
    return (
        <div className='cifSearch'>
            <div className='stepsContainer'>
                <InviteSteps selected={curStep} data={steps} />
            </div>
            <HeaderWithCampaignAndAssignments
                assignmentId={assignmentId}
                campaignId={campaignId}
                // AllAssignment={!searchId === assignmentId}
                onCancel={handleCancel}
            />
            <div className='contentContainer' >
                {renderContent()}
            </div>
            <div className='bottomContainer'>
                <InviteControl
                    hidePre={curStep === 0}
                    // disableNext={disableNextButton}
                    nextLabel={nextLabel}
                    onBack={onBack}
                    onNext={onNext}
                    budget={budget}
                />
            </div>
            <PopUpDialogueBase
                show={showPopup}
                icon={<Warning color="#111111" size={40} weight="fill" />}
                message={'Are you sure you want to cancel and lose all the progress you\'ve made?'}
                proceedLabel="Yes"
                cancelLabel="No"
                proceedFunction={() => history.goBack()}
                cancelFunction={() => setShowPopup(false)}
            />
            <ApiError
                show={showApiError}
                error={apiError}
                cancelFunction={onCancelError}
            />
            <LoadingDialogue
                show={loading}
            />
        </div>
    )
}

export default CampaignInfluencerInviteEdit;