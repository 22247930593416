import React from 'react'
import {connect} from 'react-redux';
import {storeUserProfile}  from '../../store/global/globalActions'
import FileUpload from '../shared/fileUpload/FileUpload'


class CreatorProfilePhoto extends React.Component {

    constructor( props,context) {
        super(props.context);

        this.state = {
            imgUrl:'',
            fileUploadCounter : 1
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.cancel && this.props.cancel) {
            this.setState({imgUrl: ''})
        }
    }

    triggerUpload = () => {
        if (!this.props.isEdit) {
            return;
        }
        this.setState({
            fileUploadCounter: this.state.fileUploadCounter + 1
        })
    }

    handleSelectedFile = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        let self = this;
        reader.onload = function(e) {
            const newUrl = this.result;
            self.setState({imgUrl:newUrl})
        }
        this.props.updatePhotoFunction(file);
    }

    render()  {
        let showImage = true;
        let containerStyle = {};
        if (!showImage) {
            containerStyle.maxHeight = 0;
            containerStyle.overflow = 'hidden';
        }
        let pictureUrl = '/images/ic-nouser.svg';   // for display only
        let pictureStyle = { opacity : 0.5};
        if (this.props.profile.pictureUrl) {
            if (this.state.imgUrl&&this.props.updatedPhoto){
                if (this.props.cancel) {
                    pictureUrl=this.props.updatedPhoto;

                } else {
                    pictureUrl=this.state.imgUrl;
                }
                pictureStyle = {};
            }else {
                pictureUrl = this.props.profile.pictureUrl;
                pictureStyle = {};
            }
        } else {
            if (this.state.imgUrl && this.props.updatedPhoto) {
                pictureUrl = this.state.imgUrl;
                pictureStyle = {};
            }
        }
        return (
            <div className="hideContainer" id="profilePhotoContainer" style={{position: 'relative'}}>

                <div className="photoContainer">
                    <div onClick={this.triggerUpload}>
                        <FileUpload
                            accept=".jpg, .jpeg, .png, .svg"
                            handleSelectedFile={this.handleSelectedFile}
                            clickCounter={this.state.fileUploadCounter}
                        />
                        { this.props.isEdit &&
                        <div className="ctrl">
                            <div> {/* need a relative positioned container */}
                                <div>
                                </div>
                                Update
                            </div>
                        </div>
                        }
                        <img src={pictureUrl} style={pictureStyle}/>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        storeProfile: (profile) => {
            dispatch(storeUserProfile(profile, 9))
        }
    }
};

const mapStateToProps= (state) => {
    return{
        version : state.global.profilePictureVersion
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatorProfilePhoto)