import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import classNames from 'classnames/bind';
import Api from '../../../modules/Api';
import style from './DateChangeRequestModal.module.scss';

const cx = classNames.bind(style);

class DateChangeRequestModal extends PureComponent {
  constructor(props) {
    super(props);

  }

  formatDate = (date, pattern) => moment(date).format(pattern);

  handleViewCalendar = () => {
    const { assignmentId, campaignId } = this.props;
    this.props.history.push(`/campaignWork/${campaignId}/calendar/${assignmentId}`);
  };

  handleReject = async () => {
    const { assignmentId, onClose, hideChangeRequestButton, setShowPopupMessage } = this.props;
    const requestId = this.getRequestId();

    try {
      await Api.updateAssignmentDateChange(assignmentId, requestId, 'DECLINED');
      hideChangeRequestButton();
      onClose();
      setShowPopupMessage(true);
    } catch (err) {
      console.log('err ===>>>', err);
    }
  }

  handleAccept = async () => {
    const { assignmentId, hideChangeRequestButton, onClose, setShowPopupMessage } = this.props;
    const requestId = this.getRequestId();

    try {
      await Api.updateAssignmentDateChange(assignmentId, requestId, 'ACCEPTED');
      hideChangeRequestButton();
      onClose();
      setShowPopupMessage(true);
    } catch (err) {
      console.log('err ===>>>', err);
    }
  }

  getDates = () => {
    const { draftDate, publishDate } = this.props.changeRequests.slice(-1)[0] || {};

    return { draftDate, publishDate };
  }

  getRequestId = () => {
    const { id } = this.props.changeRequests.slice(-1)[0] || {};

    return id;
  }

  render() {
    const { assignmentTitle, campaignTitle, name } = this.props;
    const { draftDate, publishDate } = this.getDates();

    return (
      <Modal
        closeTimeoutMS={300}
        isOpen={this.props.isOpen}
        overlayClassName={style.overlay}
        className={style.modal}
        conentLabel={'Date Change Request'}
      >
        <div className={style.container}>
          <div className={style.title}>{`${name} has requested new date`}</div>
          <div className={style.content}>
            <div className={style.contentArea}>
              <div className={style.areaItem}>
                <div className={style.areaName}>Campaign</div>
                <span>{campaignTitle}</span>
              </div>
              <div className={style.areaItem}>
                <div className={style.areaName}>Assignment</div>
                <span>{assignmentTitle}</span>
              </div>
              {/*<div className={style.areaItem}>*/}
              {/*  <div className={style.areaName}>Type</div>*/}
              {/*  <span>Blog Post</span>*/}
              {/*</div>*/}
            </div>
            <div className={style.contentArea}>
              <div className={style.areaItem}>
                <div className={style.areaName}>New Draft Date:</div>
                <span><strong>{this.formatDate(draftDate, 'MMM DD, YYYY')}</strong></span>
              </div>
              <div className={style.areaItem}>
                <div className={style.areaName}>New Publish Date:</div>
                <span><strong>{this.formatDate(publishDate, 'MMM DD, YYYY')}</strong></span>
              </div>
            </div>

            <button
              type="button"
              className={style.viewButton}
              onClick={this.handleViewCalendar}
            >
              View Date in Calendar
            </button>
          </div>
          <div className={style.footer}>
            <div>
              <button
                type="button"
                className={style.cancelButton}
                onClick={this.props.onClose}
              >
                cancel
              </button>
            </div>
            <div>
              <button
                type="button"
                className={style.rejectButton}
                onClick={this.handleReject}
              >
                reject
              </button>
              <button
                type="submit"
                className={style.acceptButton}
                onClick={this.handleAccept}
              >
                accept
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

DateChangeRequestModal.propTypes = {
  assignmentId: PropTypes.string,
  assignmentTitle: PropTypes.string,
  campaignId: PropTypes.number,
  campaignTitle: PropTypes.string,
  changeRequests: PropTypes.arrayOf(PropTypes.shape({
    assigneeId: PropTypes.number,
    assignmentId: PropTypes.number,
    createdOn: PropTypes.string,
    draftDate: PropTypes.string,
    id: PropTypes.string,
    publishDate: PropTypes.string,
    status: PropTypes.string,
    updatedOn: PropTypes.string,
  })),
  isOpen: PropTypes.bool,
  name: PropTypes.string,
  userId: PropTypes.string,
  onClose: PropTypes.func,
  hideChangeRequestButton: PropTypes.func,
  setShowPopupMessage: PropTypes.func,
};

export default withRouter(DateChangeRequestModal);
