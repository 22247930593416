import React from 'react'
import Select from 'react-select'
import './dropdownSelect.scss'

// props
export default class DropdownSelect extends React.Component {

    constructor (props, context) {
        super(props, context);

        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        let selected='';
        if (this.props.value) {
            if (this.props.simpleValue) {
                if (Array.isArray(this.props.value)) {
                    selected=this.props.value.toString();
                } else {
                    selected=this.props.value;
                }
            } else if (Array.isArray(this.props.value)) {
                selected=this.props.value;
            } else if (typeof this.props.value ==='object') {
                selected=this.props.value;
            } else {
                const sArray=this.props.options.find(option=>{return option.value==this.props.value});
                if (this.props.single) {
                    selected=sArray[0];
                } else {
                    selected=sArray;
                }
            }
        }
        this.state = {
            selected,
            menuOpen: false,
            prev: selected
        }
    }

    static getDerivedStateFromProps(props, state) {
        let selected='';
        if (props.value) {
            if (props.simpleValue) {
                if (Array.isArray(props.value)) {
                    selected=props.value.toString();
                } else {
                    selected=props.value;
                }
            } else if (Array.isArray(props.value)) {
                selected=props.value;
            } else if (typeof props.value ==='object') {
                selected=props.value;
            } else {
                const sArray=props.options.find(option=>{return option.value==props.value});
                if (props.single) {
                    selected=sArray[0];
                } else {
                    selected=sArray;
                }
            }
        }
        if (state.prev !== selected) {
            return{
                selected,
                prev: selected
            };
        }
        return null
    }

    handleClick(e) {
        if (this.state.menuOpen) {
            this.selectNode.handleMouseDownOnArrow(e);
        }
        const f = this.props.onClick;
        const self = this;
        if (typeof f === 'function') {
            let element=e.target;
            while (Boolean(element) && element.className.indexOf('Select ')<0) {
                element=element.parentElement;
            }
            f(this.Element);
        }
        this.setState({menuOpen:!this.state.menuOpen});
    }

    preHandleChange(value) {
        let { selected } = this.state;
        selected = selected.split(',').filter(item => value !== item).join(',')
        this.handleChange(selected)
    }

    handleChange(value) {
        const{simpleValue} = this.props;
        const passValue=(simpleValue)?value.toString().split(','):value;
        const f = this.props.onChange;
        if (typeof f === 'function') {
            f(passValue);
        }
        this.setState({selected:value, menuOpen: false});
    }

    reset(value) {
        const{simpleValue,single}=this.props;
        const selectedValue = value ? ((simpleValue||single)?value.toString():value) : ((simpleValue||single)?'':[]);
        this.setState({selected: selectedValue, menuOpen: false});
    }

    render() {
        const{options, simpleValue, maxLimit, id, multi, clearable,placeholder, className}=this.props;
        const{selected}=this.state;
        const dsClass='selectBox';

        const optionList=[];
        if (maxLimit) {
            const scnt = (simpleValue)?selected.split(',').length:selected.length;
            options.forEach(option=>{

                let disabled = false;
                if (scnt>=maxLimit){
                    if (simpleValue) {
                        disabled = (`${selected},`).indexOf(`${option.value},`) < 0;
                    } else {
                        disabled = selected.findIndex(sv=>sv.value == option.value) < 0;
                    }
                }
                option.disabled=disabled;
                optionList.push(option);
            });
        }
        const selectedArray = multi && typeof selected === 'string' && selected.split(',');
        return (
            <div className={dsClass}>
                <div className={multi ? "multi" : ""} onClick={this.handleClick} id={id} ref={e=>this.Element=e}>
                <Select className={className}
                    clearable={clearable}
                    backspaceRemoves={false}
                    searchable
                    value={selected}
                    multi={multi}
                    simpleValue={simpleValue}
                    placeholder={multi ? "Add multiselect" : 'Select'}
                    options={(maxLimit)?optionList:options}
                    onChange={this.handleChange}
                    ref={e=>this.selectNode=e}
                />
                </div>
                {multi && selectedArray && <div className="filterMultiOptions">
                    {selectedArray.map((item, index) => {
                        const itemDesc = options.find(option => option.value === Number(item))
                        if (itemDesc) {
                            const closeIcon = <div onClick={() => this.preHandleChange(item)} className='filterBar-closeIcon'>+</div>;
                            return <div key={"filtermulti" + itemDesc.label + Number(item) + index}>
                                {closeIcon} <div className="multiOptionLabel">{itemDesc.label}</div>
                            </div>
                        }
                    })}
                </div>}
            </div>
        )
    }
}
