import React from 'react';
import { postTypeMap } from '../posts';
import { StarsIcon } from '../AutoDetectedPopup/AutoDetectedPopup';
import Api from '../../../../modules/Api';
import './autoDetectedPosts.scss';

const channelIcons = {
    INSTAGRAM_POST: '/images/IG.svg',
    FACEBOOK_POST: '/images/FB.svg',
    YOUTUBE: '/images/rate-video.svg',
    TIKTOK_POST: '/images/ic-rate-tiktok.svg',
};

export default function AutoDetectedPosts({ posts, refetchData }) {
    const approvePost = (post) =>
        Api.approveAutoDetectedPost(post.connectionType !== 'PUBLIC', post.userPublishedPostId).then(() =>
            refetchData(),
        );

    return (
        <div className="auto-detected-posts-wrapper">
            {posts.map((post) => (
                <div key={post.userPublishedPostId} className="auto-detected-post">
                    <img className="channel-logo" src={channelIcons[post.postType]} />
                    <p className="post-type-label">{postTypeMap[post.postType].label}</p>
                    <div className="posts-actions">
                        <div className="auto-detect-link">
                            <StarsIcon />
                            Auto-detect link
                        </div>
                        <button className="post-btn" onClick={() => approvePost(post)}>
                            Approve
                        </button>
                        <a href={post.originalUrl} className="post-btn" target="_blank">
                            Visit post
                        </a>
                    </div>
                </div>
            ))}
        </div>
    );
}
