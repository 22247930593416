import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import cx from 'classnames';
import './planNoteComponent.scss';
import ApiError from '../shared/apiError/ApiError';
import Api from '../../modules/Api';
import PleaseWaitPartial from '../shared/pleaseWait/PleaseWaitPartial';
import NoteTab from './note/NoteTab';
import LikesTab from './note/LikesTab';
import NoteModal from './note/NoteModal';

const Tabs = [
    { key: 'notes', label: 'Note' },
    { key: 'like', label: 'Like' },
    { key: 'dislike', label: 'Dislike' },
];

const PlanNoteComponent = ({
    planId,
    type,
    creator,
    profile,
    noteList,
    likeList,
    dislikeList,
    updateState
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [notes, setNotes] = useState([]);
    const [likes, setLikes] = useState([]);
    const [dislikes, setDislikes] = useState([]);
    const [apiError, setApiError] = useState();
    const [errTitle, setErrTitle] = useState('');
    const [tabSelected, setTab] = useState(null);
    const [tabs, setTabs] = useState([]);
    const [likeStatus, setLikeStatus] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const containerRef = useRef();

    useEffect(() => {
        if (planId) {
            fetchNote();
            fetchLike();
            fetchDislike();
        } else {
            setTab('notes');
        }
    }, [planId, updateState]);

    useEffect(() => {
        noteList && noteList.length && setNotes(noteList);
        likeList && likeList.length && setLikes(likeList);
        dislikeList && dislikeList.length && setDislikes(dislikeList);
    }, [noteList, likeList, dislikeList]);

    useLayoutEffect(() => {
        if (notes.length || likes.length || dislikes.length) {
            const updates = Tabs.map(t => {
                if (t.key === 'notes' && notes.length) {
                    return { ...t, ...{ label: `${notes.length} ${t.label}${notes.length > 1 ? 's' : ''}` } }
                }
                if (t.key === 'like' && likes.length) {
                    return { ...t, ...{ label: `${likes.length} ${t.label}${likes.length > 1 ? 's' : ''}` } }
                }
                if (t.key === 'dislike' && dislikes.length) {
                    return { ...t, ...{ label: `${dislikes.length} ${t.label}${dislikes.length > 1 ? 's' : ''}` } }
                }
                return { ...t };
            })
            setTabs(updates);
        } else {
            setTabs(Tabs);
        }

        if (likes.length && likes.find(l => l.agentId === profile.id)) {
            setLikeStatus('liked');
        }else {
            if (likeStatus === 'liked') {
                setLikeStatus(null);
            }
        }

        if (planId && likes.length === 0 && tabSelected === 'like') {
            setTab(null);
        }

        if (dislikes.length && dislikes.find(l => l.agentId === profile.id)) {
            setLikeStatus('disliked');
        }else {
            if (likeStatus === 'disliked') {
                setLikeStatus(null);
            }
        }

        if (planId && dislikes.length === 0 && tabSelected === 'dislike') {
            setTab(null);
        }
    }, [notes, likes, dislikes]);

    const fetchNote = () => {
        if (planId) {
            setIsLoading(true);
            Api.getPlanNotes(planId, type, creator.id || creator.uid, (type === 'PUBLIC' ? creator.userNetworks[0].networkType : null))
                .then(res => {
                    if (res?.data?.length) {
                        setNotes(res.data);
                    }
                })
                .catch(err => {
                    handleApiError(err);
                })
                .finally(() => setIsLoading(false))
        }
    };

    const fetchLike = () => {
        if (planId) {
            setIsLoading(true);
            Api.getPlanLikes(planId, creator.id || creator.uid, (type === 'PUBLIC' ? creator.userNetworks[0].networkType : null))
                .then(res => {
                    if (res?.data?.length) {
                        setLikes(res.data);
                    }else {
                        setLikes([]);
                    }
                })
                .catch(err => {
                    handleApiError(err);
                })
                .finally(() => setIsLoading(false))
        }
    };

    const fetchDislike = () => {
        if (planId) {
            setIsLoading(true);
            Api.getPlanDislikes(planId, creator.id || creator.uid, (type === 'PUBLIC' ? creator.userNetworks[0].networkType : null))
                .then(res => {
                    if (res?.data?.length) {
                        setDislikes(res.data);
                    }else {
                        setDislikes([]);
                    }
                })
                .catch(err => {
                    handleApiError(err);
                })
                .finally(() => setIsLoading(false))
        }
    };

    const handleApiError = (err) => {
        setApiError(err);
        if (err && err.originalMessage) {
            setErrTitle(err.originalMessage);
        }
    };

    const CancelError = () => {
        setApiError(null);
        setErrTitle('');
    };

    const onTabChange = (tab) => {
        if (tab !== tabSelected) {
            setTab(tab);
        }
    };

    const handleClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };

    const onAddNote = () => {
        setTab('notes');
    };

    const onLike = () => {
        if (planId) {
            setIsLoading(true);
            const payload = {};
            if (type === 'PUBLIC') {
                payload.publicUsers = [{
                    userId: creator.uid,
                    userType: creator.userNetworks[0].networkType
                }]
            } else {
                payload.connectedUserIds = [creator.id]
            }
            Api.createPlanLike(planId, payload)
                .then(res => {
                    fetchLike();
                    setTab('like');
                })
                .catch(err => {
                    handleApiError(err);
                })
                .finally(() => setIsLoading(false))
        }
    };

    const onDislike = () => {
        if (planId) {
            setIsLoading(true);
            const payload = {};
            if (type === 'PUBLIC') {
                payload.publicUsers = [{
                    userId: creator.uid,
                    userType: creator.userNetworks[0].networkType
                }]
            } else {
                payload.connectedUserIds = [creator.id]
            }
            Api.createPlanDislike(planId, payload)
                .then(res => {
                    fetchDislike();
                    setTab('dislike');
                })
                .catch(err => {
                    handleApiError(err);
                })
                .finally(() => setIsLoading(false))
        }
    };

    const onRemoveStatus = (isLiked) => {
        if (planId) {
            setIsLoading(true);
            const payload = {};
            if (type === 'PUBLIC') {
                payload.publicUsers = [{
                    userId: creator.uid,
                    userType: creator.userNetworks[0].networkType
                }]
            } else {
                payload.connectedUserIds = [creator.id]
            }
            Api.deletePlanStatus(planId, payload)
                .then(res => {
                    if (isLiked){
                        fetchLike();
                    }else {
                        fetchDislike();
                    }
                })
                .catch(err => {
                    handleApiError(err);
                })
                .finally(() => setIsLoading(false))
        }
    }

    const onSaveNote = (note) => {
        setIsLoading(true);
        const payload = {
            content: note,
            type,
            userId: creator.id || creator.uid
        }
        if (type === 'PUBLIC') {
            payload.publicUserType = creator.userNetworks[0].networkType;
        }
        Api.createPlanNote(planId, payload)
            .then(res => {
                fetchNote();
            })
            .catch(err => {
                handleApiError(err);
            })
            .finally(() => setIsLoading(false))
    }

    return (
        <div className='planNoteComponentContainer'
            ref={containerRef}
            onClick={handleClick}
        >
            <PleaseWaitPartial
                show={isLoading}
                container={containerRef.current}
                customStyles={{ height: 'none' }}
            />
            <ApiError
                show={apiError}
                errTitle={errTitle}
                error={apiError}
                cancelFunction={CancelError}
            />
            <div className={cx('planNoteComponentHeaderContainer', { disabled: tabSelected === null })}>
                <div className='tabsContainer'>
                    {tabs.map((tab, index) => <div
                        key={`tabs ${index}`}
                        onClick={() => onTabChange(tab.key)}
                        className={cx('tabWrapper', { active: tab.key === tabSelected })}
                        role='button'
                    >
                        {tab.label}
                    </div>)}
                </div>
                {!!planId && <div className='planNoteComponentButtonContainer'>
                    <div className={cx('addNoteButton', { active: tabSelected === 'notes' })} onClick={onAddNote}>
                        <img src='/images/ic-add-note.svg' alt='addNoteButton' />
                        Add notes
                    </div>
                    <div className='likeContainer'>
                        {likeStatus === null && <div className='likeIcon' onClick={onLike}>
                            <img src='/images/ic-like.svg' alt='ic-like.svg' />
                        </div>}
                        {likeStatus === null && <div className='likeIcon' onClick={onDislike}>
                            <img src='/images/ic-unlike.svg' alt='ic-unlike.svg' />
                        </div>}
                        {likeStatus === 'liked' && <div className='statusIcon' onClick={() => onRemoveStatus(true)}>
                            <img src='/images/ic-liked.svg' alt='ic-liked.svg' />
                        </div>}
                        {likeStatus === 'disliked' && <div className='statusIcon' onClick={() => onRemoveStatus(false)}>
                            <img src='/images/ic-disliked.svg' alt='ic-disliked.svg' />
                        </div>}
                    </div>
                </div>}
            </div>
            <div className='planNoteTabsContainer'>
                {tabSelected === 'notes' && <NoteTab
                    notes={notes}
                    create={!!planId}
                    onSave={onSaveNote}
                    profile={profile}
                    viewAll={() => setShowModal(true)}
                />}
                {tabSelected === 'like' && <LikesTab
                    data={likes}
                    like={true}
                    create={!!planId}
                    // onSave={onSaveNote}
                    // profile={profile}
                    viewAll={() => setShowModal(true)}
                />}
                {tabSelected === 'dislike' && <LikesTab
                    data={dislikes}
                    like={false}
                    create={!!planId}
                    // onSave={onSaveNote}
                    // profile={profile}
                    viewAll={() => setShowModal(true)}
                />}
            </div>
            <NoteModal
                notes={notes}
                likes={likes}
                dislikes={dislikes}
                creator={creator}
                show={showModal}
                profile={profile}
                onClose={() => setShowModal(false)}
            />
        </div>
    )
}

export default PlanNoteComponent;