import React, { Component } from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import moment from 'moment';
import Api from '../../../modules/Api';
import PleaseWait from '../../shared/pleaseWait/PleaseWaitWhite';
import ApiError from '../../shared/apiError/ApiError';
import { storeUserProfile } from '../../../store/global/globalActions';
import Lookup from '../../../modules/Lookup';
import AssignmentDetails from '../AssignmentCompose/AssignmentADetails';
import { safeIsUrl } from '../../../modules/CommonServices';
import FeedbackOnTheTopImproved from '../../shared/feedbackOnTheTop/FeedbackOnTheTopImproved';
import ExpirationCheckService from '../../../services/expirationCheckService';
import { NEEDS_REVIEW, COMPLETED, APPROVED, NEW, CONTRACT_UPLOADED } from '../../../constants/statusTypes';
import './AssignmentComposeList.scss';
import ModalWithAction from '../../shared/modalWithSingleAction/ModalWithAction';
import FeedbackOnTheTop from '../../shared/feedbackOnTheTop/FeedbackOnTheTop';
import ImageSlider from './ImageSlider';
import ProfileHelper from '../../../modules/utils/ProfileHelper';
import { TALENT_MANAGER_STR, INFLUENCER_STR } from '../../../constants/authorities';
import TransactionLog from '../../campaign/posts/TransactionLog/TransactionLog';
import UpdatePostURLModal from '../../campaign/posts/UpdatePostURLModal/UpdatePostURLModal';
import AssignmentItem, { AssignmentDraftPost } from './AssignmentTable/AssignmentItem/AssignmentItem';
import AssignmentTableHeader from './AssignmentTable/AssignmentTableHeader/AssignmentTableHeader';

class AssignmentComposeList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disclaimer: false,
            assignment: '',
            assignmentStatus: '',
            assignmentUser: {},
            uid: '',
            draftList: [],
            comments: 0,
            publishedList: [],
            publishNoDraft: false,
            currentView: 1,
            contractSigned: true,
            parentStates: props.history.location.state,
            showDatepicker: false,
            showPopupMessage: false,
            transactionLogData: null,
            updateURLModalData: null,
            showInfChatBox: true,
            isLoading: false,
            showSuccessMsg: false,
            screenFile: {},
            openSlider: {},
            profile: {},
            urlsUploaded: {},
            uploadProgressPercentage: null,
            onUploadCancel: null,
            waiting: false,
        };

        this.inputFile = React.createRef();
        this.assignmentId = props.match.params.uid;
        this.successfulRequestMessage = 'Request has been sent';
        this.showSuccessPublish = props.history.location.state && props.history.location.state.publishSuccess;
    }

    setShowDatepicker = (value) => this.setState({ showDatepicker: value });

    setShowPopupMessage = (value) => this.setState({ showPopupMessage: value });

    sendReview() {
        const { assignment, draftList, uid, profile } = this.state;
        const params = new URLSearchParams(this.props.location.search);
        const influencerId = profile && profile.role === TALENT_MANAGER_STR ? params.get('influencerId') : profile.id;
        const influencerUID = assignment.users.find((au) => au.user.id == influencerId).user.uid;

        this.setState({ waiting: true });
        const promises = [];
        const self = this;

        if (Array.isArray(draftList) && draftList.length > 0) {
            draftList.forEach(({ id, content }) => {
                const payload = {
                    content,
                    status: NEEDS_REVIEW,
                };

                promises.push(Api.updateDraftPost(this.assignmentId, id, payload, influencerUID));
            });
        }

        Promise.all(promises).then(() => {
            this.setState({
                isLoading: false,
            });
            this.refresh(profile);
        });
    }

    componentDidMount() {
        ProfileHelper.getCurrentLoginUserProfile().then((profile) => {
            this.props.storeProfile(profile);
            this.setState({ profile });
            this.refresh(profile);
        });
        if (this.showSuccessPublish) {
            this.setState({ showSuccessMsg: true });
        }
    }

    refresh(profile) {
        if (profile.role === TALENT_MANAGER_STR) {
            const params = new URLSearchParams(this.props.location.search);
            const influencerId = params.get('influencerId');
            Api.getTMAssignment(this.assignmentId, influencerId).then(
                (res) => {
                    const hasOfflineContract = res && res.contract && res.contract.status === CONTRACT_UPLOADED;
                    Api.contractEnvelopeStatus(this.assignmentId, res.user.uid).then(
                        (contract) => {
                            let contractStatus = true;
                            if (Array.isArray(contract.signers) && contract.signers.length > 0) {
                                contract.signers.forEach((signer) => {
                                    contractStatus = contractStatus && signer.status === 'completed';
                                });
                            }
                            this.getPostList(
                                res.assignment,
                                profile.uid,
                                contractStatus || hasOfflineContract,
                                profile,
                                res,
                            );
                        },
                        () => {
                            this.getPostList(res.assignment, profile.uid, true, profile, res);
                        },
                    );
                },
                (error) => {
                    this.setState({
                        showApiError: true,
                        apiError: error,
                        waiting: false,
                    });
                },
            );
        } else {
            Api.getAssignmentUser(this.assignmentId, profile.uid).then(
                async (details) => {
                    const hasOfflineContract =
                        details && details.contract && details.contract.status === CONTRACT_UPLOADED;
                    return Api.contractEnvelopeStatus(this.assignmentId, profile.uid).then(
                        (contract) => {
                            let contractStatus = true;
                            if (Array.isArray(contract.signers) && contract.signers.length > 0) {
                                contract.signers.forEach((signer) => {
                                    contractStatus = contractStatus && signer.status === 'completed';
                                });
                            }
                            this.getPostList(details, profile.uid, contractStatus || hasOfflineContract, profile);
                        },
                        () => {
                            this.getPostList(details, profile.uid, true, profile);
                        },
                    );
                },
                (error) => {
                    this.setState({
                        showApiError: true,
                        apiError: error,
                        waiting: false,
                    });
                },
            );
        }
    }

    getPostList(assignment, uid, contractSigned, profile, tmDetail) {
        const self = this;
        if (profile && profile.role === TALENT_MANAGER_STR) {
            const promises = [];
            if (Array.isArray(tmDetail.userDraftPosts) && tmDetail.userDraftPosts.length > 0) {
                tmDetail.userDraftPosts.forEach((draft) => {
                    promises.push(
                        Api.getDraftPostComment(draft.id).then(
                            (commentRes) => {
                                return commentRes.length;
                            },
                            () => {
                                return 0;
                            },
                        ),
                    );
                });
            }
            Promise.all(promises).then((result) => {
                const comments = result.reduce((a, b) => a + b, 0);
                self.setState({
                    assignmentUser: tmDetail,
                    assignment,
                    assignmentUserId: tmDetail.id,
                    draftList: tmDetail.userDraftPosts,
                    comments,
                    publishedList: tmDetail.userPublishedPosts,
                    uid,
                    currentView: 1,
                    contractSigned,
                    publishNoDraft: false,
                    waiting: false,
                });
            });
        } else {
            self.setState({ waiting: true });
            if (assignment.assignment) {
                if (assignment.assignmentSlotChangeRequests && !assignment.assignment.assignmentSlotChangeRequests) {
                    assignment.assignment.assignmentSlotChangeRequests = [...assignment.assignmentSlotChangeRequests];
                }
                if (assignment.assignmentSlots && !assignment.assignment.assignmentSlots) {
                    assignment.assignment.assignmentSlots = [...assignment.assignmentSlots];
                }
            }
            const promises = [];
            if (Array.isArray(assignment.userDraftPosts) && assignment.userDraftPosts.length > 0) {
                assignment.userDraftPosts.forEach((draft) => {
                    promises.push(
                        Api.getDraftPostComment(draft.id).then(
                            (commentRes) => {
                                return commentRes.length;
                            },
                            () => {
                                return 0;
                            },
                        ),
                    );
                });
            }
            Promise.all(promises).then((result) => {
                const comments = result.reduce((a, b) => a + b, 0);
                self.setState({
                    assignmentUser: assignment,
                    assignment: assignment.assignment,
                    assignmentStatus: assignment.status,
                    draftList: assignment.userDraftPosts,
                    comments,
                    publishedList: assignment.userPublishedPosts,
                    uid,
                    currentView: 1,
                    contractSigned,
                    publishNoDraft: false,
                    waiting: false,
                });
                // });
            });
        }
    }

    forceInfChatBoxRerender = () => {
        this.setState(
            {
                showInfChatBox: false,
            },
            () => {
                this.setState({ showInfChatBox: true });
            },
        );
    };

    toggleDisclaimerDescription = () => {
        const { disclaimer } = this.state;
        this.setState({ disclaimer: !disclaimer });
    };

    submitReviewHandler = async () => {
        this.setState({ isLoading: true });
        await this.sendReview();
    };

    onChangeScreenShotFile = (event, uid, screenshots) => {
        const files = Array.from(event.target.files);
        const countOfFiles = files.length;
        event.target.value = null;

        if (countOfFiles > 0) {
            this.setState(
                (prevState) => {
                    const $fileList = prevState.screenFile[uid] ? [...prevState.screenFile[uid], ...files] : files;
                    const uniqFileList = [...new Map($fileList.map((item) => [item.name, item])).values()];
                    const newScreen = prevState.screenFile;
                    newScreen[uid] = uniqFileList;
                    return {
                        screenFile: newScreen,
                        waiting: true,
                        // isValidFile: size <= 10000000 && files.length <= 10,
                        isValidFile: files.length <= 10,
                    };
                },
                async () => {
                    const { screenFile, isValidFile, urlsUploaded } = this.state;
                    if (isValidFile && screenFile && screenFile[uid] && screenFile[uid].length > 0) {
                        const fileUrls = [];
                        for (let i = 0; i < screenFile[uid].length; i++) {
                            try {
                                const fileUpload = await Api.uploadFile(
                                    screenFile[uid][i],
                                    false,
                                    (percentage) => this.setState({ uploadProgressPercentage: percentage }),
                                    (fn) => this.setState({ onUploadCancel: fn }),
                                );
                                if (fileUpload && typeof fileUpload === 'object' && fileUpload.url) {
                                    fileUrls.push(fileUpload.url);
                                }
                            } catch (error) {}
                        }
                        const screenFileUpdate = { ...screenFile };
                        screenFileUpdate[uid] = null;
                        if (fileUrls.length > 0) {
                            const uploaded = urlsUploaded[uid] ? urlsUploaded[uid] : screenshots;
                            const toUpdate = [...fileUrls, ...uploaded];
                            Api.updateAnalyticScreenshots(uid, { screenshots: toUpdate })
                                .then((res) => {
                                    const urlsUpdated = { ...urlsUploaded };
                                    urlsUpdated[uid] = res.analyticsScreenshotUrls || [];
                                    this.setState({
                                        successMessage: true,
                                        waiting: false,
                                        uploadProgressPercentage: null,
                                        onUploadCancel: null,
                                        urlsUploaded: urlsUpdated,
                                    });
                                })
                                .catch((err) => {
                                    this.setState({
                                        showApiError: true,
                                        apiError: err,
                                        waiting: false,
                                        uploadProgressPercentage: null,
                                        onUploadCancel: null,
                                    });
                                });
                        }
                        this.setState({
                            screenFile: screenFileUpdate,
                            uploadProgressPercentage: null,
                            onUploadCancel: null,
                            waiting: false,
                        });
                    } else {
                        this.setState({
                            showApiError: true,
                            waiting: false,
                            apiError: 'Size of files is large (The maximum size is 10mb) and/or more than 10 items',
                            uploadProgressPercentage: null,
                            onUploadCancel: null,
                            screenFile: {},
                        });
                    }
                },
            );
        }
    };

    deleteScreenshots = (index, postKey, items) => {
        if (items.length === 1) {
            this.closeSlider(postKey);
        }

        this.setState(
            {
                waiting: true,
            },
            () => {
                const { urlsUploaded } = this.state;
                const urlsUpdate = Object.assign([], items);
                urlsUpdate.splice(index - 1, 1);
                Api.updateAnalyticScreenshots(postKey, { screenshots: urlsUpdate })
                    .then((res) => {
                        const urlsUpdated = { ...urlsUploaded };
                        urlsUpdated[postKey] = res.analyticsScreenshotUrls || [];

                        this.setState({ waiting: false, uploadProgressPercentage: null, urlsUploaded: urlsUpdated });
                    })
                    .catch((err) => {
                        this.setState({
                            showApiError: true,
                            apiError: err,
                            waiting: false,
                        });
                    });
            },
        );
    };

    openSlider = (postKey) => {
        const { openSlider } = this.state;
        openSlider[postKey] = true;
        this.setState({ openSlider });
    };

    closeSlider = (postKey) => {
        const { openSlider } = this.state;
        openSlider[postKey] = false;
        this.setState({ openSlider });
    };

    goBack = () => {
        const { profile, parentStates } = this.state;
        if (profile && profile.role && profile.role === TALENT_MANAGER_STR) {
            this.props.history.push({ pathname: '/talentOpportunities', state: parentStates });
        } else {
            this.props.history.push({ pathname: '/opportunities', state: parentStates });
        }
    };

    renderItemInUserDraftOrPublishLists = (item) => {
        const { openSlider } = this.state;
        return (
            <div key={item.postKey || item.postDate}>
                <AssignmentItem
                    item={item}
                    openUpdateURL={() => this.setState({ updateURLModalData: item })}
                    openSlider={this.openSlider}
                    handleFileUpload={this.onChangeScreenShotFile}
                />
                {openSlider[item.postKey] && (
                    <ImageSlider
                        isOpen={openSlider[item.postKey]}
                        items={item.postAScreens || []}
                        onClose={() => this.closeSlider(item.postKey)}
                        deleteDisable={false}
                        unDeleteList={item.post.analyticsScreenshotUrls || []}
                        deleteFunc={(index, items) => this.deleteScreenshots(index, item.postKey, items)}
                    />
                )}
            </div>
        );
    };

    render() {
        const {
            assignment,
            uid,
            draftList,
            comments,
            publishedList,
            publishNoDraft,
            currentView,
            parentStates,
            updateURLModalData,
        } = this.state;
        const { showSuccessMsg, contractSigned, showDatepicker, showInfChatBox, disclaimer } = this.state;
        const { isLoading, assignmentStatus, urlsUploaded, assignmentUser, profile } = this.state;
        const showList = [];
        const newposts = 0;
        const publishOverDue = -1;
        const publishDate = null;
        // const draftOverDue = -1;
        // const draftDate = null;
        const params = new URLSearchParams(this.props.location.search);
        const influencerId = params.get('influencerId');
        const draftRequired = true;
        const addExtraTop =
            profile &&
            (profile.role === TALENT_MANAGER_STR || profile.role === INFLUENCER_STR) &&
            !profile.accountVerified;

        const transactionLogData = this.state.transactionLogData
            ? {
                  ...this.state.transactionLogData,
                  userFirstName: profile.firstname,
                  userLastName: profile.lastname,
                  userPictureUrl: profile.pictureUrl,
              }
            : null;

        if (
            typeof assignmentUser.deliverables === 'object' &&
            (Array.isArray(draftList) || Array.isArray(publishedList))
        ) {
            Object.entries(assignmentUser.deliverables).forEach(([k, v]) => {
                // filter the entries without value first
                if (v) {
                    try {
                        if (typeof v === 'string') {
                            // eslint-disable-next-line no-param-reassign
                            v = Number(v);
                        }
                    } catch (e) {
                        console.log(e);
                    }
                    let idx = 1;
                    const publishedListLength = publishedList.length;
                    publishedList.forEach((publishedPost) => {
                        if (publishedPost.postType === k) {
                            const item = {};
                            item.type = k;
                            item.publishedListLength = publishedListLength;
                            item.isAutoDetected = publishedPost.publishType === 'AUTODETECTED';
                            item.idx = idx;
                            item.total = v;
                            item.pstatus = COMPLETED;
                            item.publishOverDue = -1;
                            item.post = publishedPost;
                            item.postKey = publishedPost.userPublishedPostId;
                            item.postTitle =
                                publishedPost.content && Boolean(publishedPost.content.title)
                                    ? publishedPost.content.title
                                    : 'No title found';
                            item.postContent = publishedPost.text
                                ? publishedPost.text.replace(/(<([^>]+)>)/gi, '')
                                : 'No description found';
                            item.postUrl = safeIsUrl(publishedPost.link)
                                ? publishedPost.link
                                : publishedPost.originalUrl;
                            item.postDate = publishedPost.createdOn;
                            item.postAScreens = urlsUploaded[item.postKey]
                                ? urlsUploaded[item.postKey]
                                : publishedPost.analyticsScreenshotUrls || [];
                            item.composeurl = '';

                            item.titleDisplay =
                                item.postTitle && item.postTitle.trim() !== '' && item.postTitle !== 'Title'
                                    ? item.postTitle
                                    : 'No title found';
                            item.contentDisplay =
                                item.postContent && item.postContent.trim() !== ''
                                    ? item.postContent.substring(0, 200) +
                                      (item.postContent.length > 200 ? '... ...' : '')
                                    : 'No description found';
                            showList.push(this.renderItemInUserDraftOrPublishLists(item));
                            idx += 1;
                        }
                    });
                    draftList.forEach((draftPost) => {
                        const findPublished = (id) => {
                            return publishedList.find((p) => p.postId === id);
                        };

                        if (draftPost.type === k && !findPublished(draftPost.id)) {
                            const item = {};
                            item.type = k;
                            item.publishedListLength = publishedListLength;
                            item.idx = idx;
                            item.total = v;
                            item.post = draftPost;
                            item.postKey = draftPost.id;
                            item.pstatus = draftPost.status;
                            item.postTitle = draftPost.title;
                            item.postUrl = draftPost.link;
                            // let blocks = JSON.parse(post.content).blocks;
                            const blocks = [];
                            if (Array.isArray(blocks)) {
                                const block = blocks.find(({ text }) => {
                                    return text.trim() !== '';
                                });
                                if (block) {
                                    item.postContent = block.text.replace(/(<([^>]+)>)/gi, '');
                                }
                            }
                            item.postDate = draftPost.submittedDate;
                            item.composeurl = `/assignments/${draftPost.assignmentId}/post/${draftPost.id}`;
                            item.composeurl +=
                                draftPost.status === APPROVED
                                    ? `/view/${item.type}/${draftPost.assignmentUserId}`
                                    : '/edit';
                            item.composeurl += influencerId ? `?influencerId=${influencerId}` : '';
                            // item.composeurl += `${influencerId ? '&' : '?'}assignmentChoreId=${draftPost.id}`;
                            // if (item.pstatus === NEW) {
                            //     newposts++;
                            // }
                            item.titleDisplay =
                                item.postTitle && item.postTitle.trim() !== '' && item.postTitle !== 'Title'
                                    ? item.postTitle
                                    : 'No title found';
                            item.contentDisplay =
                                item.postContent && item.postContent.trim() !== ''
                                    ? item.postContent.substring(0, 200) +
                                      (item.postContent.length > 200 ? '... ...' : '')
                                    : 'No description found';
                            showList.push(this.renderItemInUserDraftOrPublishLists(item));
                            idx += 1;
                        }
                    });
                    if (!publishNoDraft) {
                        for (; idx <= v; idx++) {
                            showList.push(
                                <AssignmentDraftPost
                                    itemIdx={idx}
                                    totalIdx={v}
                                    postType={k}
                                    isDraftRequired={draftRequired}
                                    isContractSigned={contractSigned}
                                    isPublishOverDue={publishOverDue}
                                    publishDate={publishDate}
                                    handleSubmitPost={() => this.setState({ publishNoDraft: true })}
                                    influencerId={influencerId}
                                    assignment={assignment}
                                />,
                            );
                        }
                    }
                }
            });
        }
        const containerClass = 'cardContainer';

        const expirationService = new ExpirationCheckService(assignment, assignment.id, assignment.status);
        const lockNewDateRequest = expirationService.checkExpiration() || assignmentStatus === COMPLETED;

        return (
            <div className="assignmentComposeList">
                <PleaseWait
                    show={this.state.waiting}
                    progressPercentage={this.state.uploadProgressPercentage}
                    onCancel={this.state.onUploadCancel}
                />
                <ApiError
                    show={this.state.showApiError}
                    error={this.state.apiError}
                    cancelFunction={() => {
                        this.setState({ showApiError: false, waiting: false });
                    }}
                />
                <FeedbackOnTheTop
                    show={this.state.successMessage}
                    closeFunction={() => this.setState({ successMessage: false })}
                    message="Screenshots has been uploaded"
                    extraTop={addExtraTop}
                />
                {/* <div className={cx("listHeaderContainer", {extraHeader: addExtraTop})}>
                    <div className="listHeader">
                        <div className="contentDiv">
                            <div className="goBack">
                                <img
                                    src="/images/ic-back.svg"
                                    onClick={this.goBack}
                                />
                                {assignment.campaign && assignment.campaign.name}:{' '}
                                <span>{assignment.name && assignment.name}</span>
                            </div>
                            <div className="actionDropDown">
                                <Select
                                    className="select"
                                    clearable={false}
                                    value={currentView}
                                    placeholder=""
                                    searchable={false}
                                    options={[
                                        { value: 1, label: 'My Post' },
                                        { value: 2, label: 'Assignment Details' },
                                    ]}
                                    onChange={(selected) => {
                                        this.setState({ currentView: selected.value });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="breadCrumb">
                    <div className="crumbContainer">
                        <div
                            className="homeImage"
                            onClick={() =>
                                this.props.history.push({ pathname: '/talentOpportunities', state: parentStates })
                            }
                        >
                            <img src="/images/home.svg" alt="home" />
                        </div>
                        <div className="slash">/</div>
                        <div
                            className="crumbLink"
                            onClick={() => this.props.history.push({ pathname: '/opportunities', state: parentStates })}
                        >
                            Opportunities
                        </div>
                        <div className="slash">/</div>
                        <div className="crumbLink inactive">
                            <div className="assignmentLabel">
                                {assignment.campaign && assignment.campaign.name}: {assignment.name && assignment.name}
                            </div>
                            <div className="slash">/</div>
                            <div className="assignmentLabel">
                                {currentView === 1 ? 'My Post' : 'Assignment Details'}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="newSelect">
                    <div className="selectContainer">
                        <div
                            className={currentView === 1 ? 'active' : ''}
                            onClick={() => this.setState({ currentView: 1 })}
                        >
                            My Post
                        </div>
                        <div
                            className={currentView === 2 ? 'active' : ''}
                            onClick={() => this.setState({ currentView: 2 })}
                        >
                            Assignment Details
                        </div>
                    </div>
                </div>
                <div className={cx('contentContainer', { extraContentTop: addExtraTop })}>
                    <div className="page-content">
                        {currentView === 1 && !showDatepicker && (
                            <div className="composerListContainer">
                                {!contractSigned && draftList.length === 0 && publishedList.length === 0 && (
                                    <div className="contractNotice">
                                        <img alt="" src="/images/ic-small-warning.svg" />
                                        Awaiting Final Contract. Please check back soon to begin working on this
                                        Assignment.
                                    </div>
                                )}
                                <div className={containerClass}>
                                    <AssignmentTableHeader />
                                    {!publishNoDraft && showList}
                                    {newposts === showList.length && newposts > 0 && comments === 0 && (
                                        <div className="disclaimer">
                                            <div className="title" onClick={this.toggleDisclaimerDescription}>
                                                <span>Disclaimer Guidelines & Reader Information</span>
                                                <img src="/images/ic-down.svg" className={disclaimer ? 'up' : 'down'} />
                                            </div>
                                            <div className={`content${disclaimer ? ' visible' : ''}`}>
                                                <div>
                                                    <span>Post Disclaimer</span>
                                                    <br />
                                                    This is a sponsored conversation written by me on behalf of test.
                                                    The opinions and text are all mine.
                                                </div>
                                                <div>
                                                    <span>Reader Comment Disclaimer</span>
                                                    <br />
                                                    Comments submitted may be displayed on other websites owned by the
                                                    sponsoring brand.
                                                </div>
                                            </div>
                                            <button disabled={isLoading} onClick={this.submitReviewHandler}>
                                                SEND FOR REVIEW
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}

                        {currentView === 2 && !showDatepicker && (
                            <div className="assignment-details-section">
                                <div className="leftSection">
                                    <AssignmentDetails
                                        location={this.props.location}
                                        assignment={assignment}
                                        uid={uid}
                                        profile={profile}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <FeedbackOnTheTopImproved
                    show={this.state.showPopupMessage}
                    message={this.successfulRequestMessage}
                    closeFunction={() => this.setShowPopupMessage(false)}
                />
                <ModalWithAction
                    className="needPostAnalyticsMsg"
                    noLogo
                    titleId="opportunityView.needPostAnalyticsTitle"
                    messageId="opportunityView.needPostAnalyticsMsg"
                    show={showSuccessMsg}
                    actionFunction={() => this.setState({ showSuccessMsg: false })}
                />
                <TransactionLog
                    data={transactionLogData}
                    isOpen={!!transactionLogData}
                    close={() => this.setState({ transactionLogData: null })}
                />
                <UpdatePostURLModal
                    data={updateURLModalData}
                    refetchData={() => this.refresh(this.state.profile)}
                    isOpen={!!updateURLModalData}
                    close={() => this.setState({ updateURLModalData: null })}
                    assignmentId={this.assignmentId}
                    userId={this.state.profile.id}
                    isUserCreator
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    assigneeId: state.global.loggedInUserProfile ? state.global.loggedInUserProfile.id : null,
    influencerId: state.global.loggedInUserProfile ? state.global.loggedInUserProfile.uid : null,
});

const mapDispatchToProps = (dispatch) => {
    return {
        storeProfile: (profile) => {
            dispatch(storeUserProfile(profile));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentComposeList);
