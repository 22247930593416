import React, { useState, useEffect, useLayoutEffect } from 'react';
import './noteCreate.scss';
import cx from 'classnames';
import NoteAvatar from './NoteAvatar';

const NoteCreate = ({
    onSave = () => null,
    onCancel = () => null,
    profile,
}) => {
    const [note, setNote] = useState('');
    const [showButton, setShowButton] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    useLayoutEffect(() => {
        if (isFocused || note.length) {
            setShowButton(true);
        } else {
            setShowButton(false);
        }
    }, [isFocused, note]);

    const textChange = (e) => {
        setNote(e.target.value);
    };

    const onCancelClick = () => {
        setNote('');
        onCancel();
    };

    const onSaveClick = () => {
        onSave(note);
        setNote('');
    };

    const onFocus = () => {
        setIsFocused(true);
    };

    const onBlur = () => {
        setIsFocused(false);
    }

    return (
        <div className='noteCreateContainer'>
            <NoteAvatar
                picUrl={profile?.pictureUrl}
                firstName={profile?.firstname}
                lastName={profile?.lastname}
            />
            <div className="noteCreateInputWrapper">
                <div className="noteCreateInputContainer">
                    <textarea
                        onChange={textChange}
                        placeholder={'Enter your notes'}
                        // type="textarea" 
                        id="addToBaseInput"
                        value={note}
                        // multiple={true}
                        rows={1}
                        onFocus={onFocus}
                        onBlur={onBlur}
                    />
                </div>
                {showButton && <div className='noteCreateButtonContainer'>
                    <div className='cancelButton' onClick={onCancelClick}>Cancel</div>
                    <div className={cx('saveButton', { enabled: note.length > 0 })} onClick={onSaveClick}>Save</div>
                </div>}
            </div>
        </div>
    )
}

export default NoteCreate;