import { store } from '../index';
import SocialNetworks from './influencer/SocialNetworks';
import Format from './utils/Format';
import {
    BLOG_POST,
    FACEBOOK_POST,
    GENERAL,
    INSTAGRAM_POST,
    INSTAGRAM_STORY,
    SOCIAL_SHARE,
    TWITTER_POST,
    YOUTUBE,
    TIKTOK_POST,
} from '../constants/rateTypes';
import { SUPER_ADMIN_STR, BRAND_OWNER_STR, AGENT_STR, VIEWER_STR } from '../constants/authorities';

export default class Lookup {
    static getRequiredRateFields(networks) {
        const requiredRateFields = networks.reduce((acc, network) => {
            const rateTypes = SocialNetworks.getRateTypes(network.networkType);
            if (Array.isArray(rateTypes)) {
                acc.push(...rateTypes);
            } else {
                acc.push(rateTypes);
            }
            return acc;
        }, []);

        if (
            requiredRateFields.find(
                (requiredRateField) =>
                    requiredRateField === 'BLOG_POST' ||
                    requiredRateField === 'TIKTOK_POST' ||
                    requiredRateField === 'FACEBOOK_POST' ||
                    requiredRateField === 'INSTAGRAM_POST' ||
                    requiredRateField === 'YOUTUBE',
            )
        ) {
            requiredRateFields.push('SOCIAL_SHARE');
        }

        return requiredRateFields;
    }

    static getOptionList(listKey) {
        if (listKey === 'rateType') {
            return Lookup.getRateTypeOptionList();
        }
        if (listKey === 'network') {
            return SocialNetworks.getNetworkOptionList();
        }
        const globalState = store.getState().global;
        if (!globalState.lookupTables) {
            throw `Lookup invoked for ${listKey} before tables loaded with Redux action`;
            // the idea is that the main components load the lookup tables and don't render the pieces
            // and don't let the children render until they are loaded
        }
        if (listKey === 'children') {
            listKey = 'yesno';
        }
        return globalState.lookupTables[listKey];
    }

    static getTableOptions(listKey, osort) {
        const map = [];
        const lastItem = [];
        const options = Lookup.getOptionList(listKey);
        options.forEach((option) => {
            if (option.label.toLowerCase() === 'other') {
                lastItem.push({ value: option.id, label: option.label });
            } else if (option.label.toLowerCase() === 'n/a') {
                lastItem.unshift({ value: option.id, label: option.label });
            } else {
                map.push({ value: option.id, label: option.label });
            }
        });
        if (!osort) {
            map.sort((a, b) => {
                if (a.label < b.label) {
                    return -1;
                }
                if (a.label > b.label) {
                    return 1;
                }
                return 0;
            });
        }

        if (lastItem.length > 0) {
            lastItem.forEach((l) => {
                map.push(l);
            });
        }
        return map;
    }

    static getTopicMap() {
        const map = {};
        const options = Lookup.getOptionList('topic');
        for (const main of options) {
            map[main.id] = {
                id: main.id,
                value: main.value,
                label: main.label,
            };
        }
        return map;
    }

    static getTopicOptions() {
        const map = [];
        const lastItem = [];
        const options = Lookup.getOptionList('topic');
        options.forEach((option) => {
            if (option.label.toLowerCase() === 'other') {
                lastItem.push({ value: option.id, label: option.label });
            } else {
                map.push({ value: option.id, label: option.label });
            }
        });

        map.sort((a, b) => {
            if (a.label < b.label) {
                return -1;
            }
            if (a.label > b.label) {
                return 1;
            }
            return 0;
        });

        if (lastItem.length > 0) {
            lastItem.forEach((l) => {
                map.push(l);
            });
        }
        return map;
    }

    static getTopicLabel(id) {
        const option = Lookup.getTopicMap();
        return option[id].label;
    }

    static getDisplay(listKey, optionValue, filler) {
        const list = Lookup.getOptionList(listKey);
        if (!list) {
            return null;
        }
        if (!Array.isArray(optionValue)) {
            for (const option of list) {
                if (option.id == optionValue) {
                    return option.label;
                }
            }
            if (typeof filler === 'string') {
                return filler;
            }
            return '';
        }
        if (optionValue.length === 0 && typeof filler === 'string') {
            return filler;
        }
        let retval = '';
        for (const id of optionValue) {
            for (const option of list) {
                if (option.id === id) {
                    if (retval.length > 0) {
                        retval += ', ';
                    }
                    retval += option.label;
                }
            }
        }
        return retval;
    }

    static fromFieldToLookup(field) {
        if (field === 'pets') {
            return 'pet';
        }
        if (field === 'languages') {
            return 'language';
        }
        if (field === 'relationshipStatus') {
            return 'relationship_status';
        }
        if (field === 'children') {
            return 'yesno';
        }
        return field;
    }

    static getRateTypeList() {
        return [
            BLOG_POST,
            FACEBOOK_POST,
            GENERAL,
            INSTAGRAM_POST,
            INSTAGRAM_STORY,
            YOUTUBE,
            SOCIAL_SHARE,
            TWITTER_POST,
            TIKTOK_POST,
        ].sort();
    }

    // new lookup without per campaign, ratetypelist is used in other places that I am not too sure about such as registration, so we do not
    // want to touch that as it will mess with other places that uses it
    static getRateTypeList2() {
        return [BLOG_POST, TIKTOK_POST, FACEBOOK_POST, INSTAGRAM_POST, INSTAGRAM_STORY, SOCIAL_SHARE, TWITTER_POST, YOUTUBE].sort();
    }

    static getRateType(id) {
        const urlHead = '/images/rateIcons3/';
        const map = {
            BLOG_POST: {
                img: `${urlHead}Blog_list2.svg`,
                label: 'Blog Post',
                rateDescription: 'Rate per blog post.',
                suggested: 25,
            },
            FACEBOOK_POST: {
                img: `${urlHead}Blog_facebook.svg`,
                label: 'Facebook Post',
                rateDescription: 'Rate for creating a Facebook post.',
                suggested: 20,
            },
            TIKTOK_POST: {
                img: `${urlHead}Blog_tiktok.svg`,
                label: 'TikTok Post',
                rateDescription: 'Rate per tiktok post.',
                suggested: 25,
            },
            TWITTER_POST: {
                img: `${urlHead}Blog_twitter.svg`,
                label: 'Twitter Post',
                rateDescription: '',
                suggested: 55,
            },
            INSTAGRAM_POST: {
                img: `${urlHead}Blog_insta.svg`,
                label: 'Instagram Post',
                rateDescription: 'Rate for creating an Instagram post.',
                suggested: 20,
            },
            INSTAGRAM_STORY: {
                img: `${urlHead}Blog_story.svg`,
                label: 'Instagram Story',
                rateDescription: 'Rate for creating a story on either Instagram, Facebook, or both.',
                suggested: 20,
            },
            YOUTUBE: {
                img: `${urlHead}Blog_play.svg`,
                label: 'YouTube Post',
                shortLabel: 'YouTube',
                abLabel: 'YouTube',
                rateDescription: 'Rate for creating a video for Youtube, Twitch or similar video network.',
                suggested: 35,
            },
            SOCIAL_SHARE: {
                img: `${urlHead}Blog_share.svg`,
                label: 'Social Share',
                shortLabel: 'Social Share',
                abLabel: 'Social Share',
                rateDescription: 'Rate for sharing a link via Twitter, Facebook, or similar social media platform',
                suggested: 55,
            },
            GENERAL: {
                img: `${urlHead}Blog_list.svg`,
                label: 'Per Campaign',
                shortLabel: 'Per Campaign',
                abLabel: 'Per Campaign',
                rateDescription: 'Enter average cost per post/campaign',
                suggested: 45,
            },
        };

        return map[id];
    }

    static getDeliverableType(id) {
        const map = {
            BLOG_POST: {
                network: 'blog',
                label: 'Blog',
            },
            FACEBOOK_POST: {
                network: 'facebook',
                label: 'Facebook',
            },
            TIKTOK_POST: {
                network: 'tiktok',
                label: 'TikTok',
            },
            TWITTER_POST: {
                network: 'twitter',
                label: 'Twitter',
            },
            INSTAGRAM_POST: {
                network: 'instagram',
                label: 'Instagram Post',
            },
            INSTAGRAM_STORY: {
                network: 'instagram',
                label: 'Instagram Story',
            },
            YOUTUBE: {
                network: 'youtube',
                label: 'YouTube',
            },
            SOCIAL_SHARE: {
                network: 'share',
                label: 'Social Share',
            },
            GENERAL: {
                network: 'blog',
                label: 'Per Campaign',
            },
        };

        return map[id];
    }

    static getRateTypeOptionList() {
        const retval = [];
        for (const key of Lookup.getRateTypeList()) {
            const option = Lookup.getRateType(key);
            option.id = key;
            retval.push(option);
        }
        return retval;
    }

    static checkInfluencerProfile(profile) {
        const isEmptyString = function (fieldname) {
            const value = profile[fieldname];
            if (typeof value !== 'string') {
                return true;
            }
            if (value.length === 0) {
                return true;
            }
            return false;
        };
        const noValidArrayElement = function (fieldname) {
            const value = profile[fieldname];
            if (!Array.isArray(value)) {
                return true;
            }
            if (value.length === 0) {
                return true;
            }
            return false;
        };

        const messages = [];

        if (isEmptyString('firstname')) {
            messages.push('First name is missing');
        }
        if (isEmptyString('lastname')) {
            messages.push('Last name is missing');
        }
        if (isEmptyString('pictureUrl') && isEmptyString('base64Photo')) {
            messages.push('User photo is missing');
        }
        if (isEmptyString('formattedAddress')) {
            messages.push('Location is missing');
        }
        if (isEmptyString('paymentEmail')) {
            messages.push('Paypal Email is missing');
        }
        if (noValidArrayElement('topics')) {
            messages.push('Topics are missing');
        }
        if (noValidArrayElement('userNetworks')) {
            messages.push('Connections to social media are missing');
        }
        const { rates } = profile;
        const keys = Lookup.getRateTypeList();
        let rateFound = false;
        for (const rateKey of keys) {
            const rate = rates[rateKey];
            if (!rate) {
                continue;
            }
            if (!isNaN(rate) && Number(rate) > 0) {
                rateFound = true;
                break;
            }
        }
        if (!rateFound) {
            messages.push('Rates are missing');
        }

        return messages;
    }

    static getResourceTypeMap() {
        const resourceTypes = {
            TAX_FORM: {
                id: 'taxForm',
                label: 'Upload Tax Form',
            },
            LEGAL_TERMS: {
                id: 'legalTerms',
                label: 'Upload Legal Terms',
            },
            REQUIREMENTS: {
                id: 'requirements',
                label: 'Upload Requirements',
                single: true,
            },
            BRAND_IMAGES: {
                id: 'brandImage',
                label: 'Add brand Image',
                accept: '.jpg, .jpeg, .png, .svg',
                image: true,
            },
            ADDITIONAL: {
                id: 'additional',
                label: 'Add additional resources',
            },
            // TAX_FORM : {
            //     id : 'tax',
            //     label : 'Upload Additional Form'
            // },
            LEGAL: {
                id: 'legal',
                label: 'Upload Legal Terms',
                single: true,
            },
        };
        return resourceTypes;
    }

    static getResourceType(key) {
        const res = Lookup.getResourceTypeMap()[key];
        if (!res) {
            throw `wrong resource key ${key}`;
        }
        return Lookup.getResourceTypeMap()[key];
    }

    static getAgents() {
        const agents = store.getState().agent.list;
        return agents;
    }

    static getAgentBrands() {
        const { brands } = store.getState().agent;
        return brands;
    }

    static getFilters(listKey) {
        const filterState = store.getState().filter;

        if (!filterState.lookupFilters) {
            throw `Lookup invoked for ${listKey} before tables loaded with Redux action`;
            // the idea is that the main components load the lookup tables and don't render the pieces
            // and don't let the children render until they are loaded
        }

        return filterState.lookupFilters[listKey];
    }

    static getAllFilters(source) {
        const filterState = store.getState().filter;
        if (source === 'PUBLIC') {
            return filterState.publicFilters || [];
        }
        if (source === 'CONNECTED') {
            return filterState.connectedFilters || [];
        }
        return filterState.lookupFilters || [];
    }

    static getFilterList() {
        return Lookup.getFilters('filters');
    }

    static getFilterGroups() {
        return Lookup.getFilters('groups');
    }

    static getFilterGroup(groupId, source) {
        return Lookup.getAllFilters(source).find((group) => {
            return groupId === group.id;
        });
    }

    static getGroupFilters(groupId, source, activeChannel) {
        let returnValue = [];
        const group = Lookup.getFilterGroup(groupId, source);
        if (group) {
            returnValue = group.filters;
        }

        if (source === 'PUBLIC' && activeChannel) {
            returnValue = returnValue.filter((filterGroup) => filterGroup.supportedNetworkTypes.includes(activeChannel));
        }

        return returnValue || [];
    }

    static getFilter(filterId) {
        const allFilters = Lookup.getAllFilters();
        let filter;
        for (let i = 0; i < allFilters.length; i++) {
            filter = allFilters[i].filters.find((f) => {
                return filterId === f.id;
            });
            if (filter) {
                break;
            }
        }
        return filter || {};
    }

    static getFilterOptions(id) {
        const map = Lookup.getFilter(id).filterOptions;
        return map;
    }

    static getFilterOptionsMap(id) {
        const options = Lookup.getFilterOptions(id);
        const map = {};
        for (const option of options) {
            map[`${option.value}`] = option;
        }
        return map;
    }

    static getFilterId(key, id) {
        let fid = 0;
        const cfilter = Lookup.getFilterOptions(id).find((category) => category.label === key);
        if (cfilter) {
            fid = cfilter.id;
        }
        return fid;
    }

    static getStatus() {
        return ['ACCEPTED', 'PENDING', 'NEGOTIATE', 'DECLINED', 'EXPIRED', 'COMPLETED', 'PAID', 'REVOKED'];
    }

    static getStatusType(id) {
        const urlHead = '/images/statusIcons/';
        const map = {
            PENDING: {
                img: `${urlHead}ic-pending.svg`,
                label: 'Pending',
                color: '#ffc300',
                ovalColor: '#ffc300',
            },
            NEGOTIATE: {
                img: `${urlHead}ic-negociate.svg`,
                color: '#ffa500',
                label: 'Negotiating',
                ovalColor: '#ffa500',
            },
            EXPIRED: {
                img: `${urlHead}ic-expire.svg`,
                label: 'Expired',
                color: '#444444',
                ovalColor: '#333333',
            },
            DECLINED: {
                img: `${urlHead}ic-decline.svg`,
                label: 'Declined',
                color: '#e5004d',
                ovalColor: '#E5004D',
            },
            REVOKED: {
                img: `${urlHead}ic-decline.svg`,
                label: 'Revoked',
                color: '#e5004d',
                ovalColor: '#E5004D',
            },
            ACCEPTED: {
                img: `${urlHead}ic-accept.svg`,
                label: 'Accepted',
                color: '#0fb5bd',
                scolor: '#4AA129',
                ovalColor: '#69C249',
            },
            DRAFT: {
                img: `${urlHead}ic-pending.svg`,
                label: 'Draft Pending',
                color: '#969696',
            },
            SUBMITTED: {
                img: `${urlHead}ic-negociate.svg`,
                label: 'Waiting',
                color: '#d0021b',
            },
            NEEDS_REVIEW: {
                img: `${urlHead}ic-pending.svg`,
                label: 'Needs Review',
                color: '#f16000',
                ilabel: 'Draft Submitted',
                icolor: '#0fb5bd',
            },
            EDITS_SENT: {
                img: `${urlHead}ic-negociate.svg`,
                label: 'Edits Sent',
                color: '#0fb5bd',
                ilabel: 'Needs Revision',
                icolor: '#f16000',
            },
            IN_REVIEW: {
                img: `${urlHead}ic-negociate.svg`,
                label: 'In Review',
                color: '#00336d',
                ilabel: 'In Review',
                icolor: '#00336d',
            },
            APPROVED: {
                img: `${urlHead}ic-check-w.svg`,
                label: 'Approved',
                color: '#6AC14A',
                ilabel: 'Ready to Post',
                icolor: '#6AC14A',
            },
            COMPLETED: {
                img: `${urlHead}ic-accept.svg`,
                label: 'Completed',
                color: '#67c100',
                ilabel: 'Completed',
                icolor: '#67c100',
                ovalColor: '#0087FF',
            },
            PUBLISHED: {
                img: `${urlHead}ic-accept.svg`,
                label: 'Completed',
                color: '#67c100',
                ilabel: 'Completed',
                icolor: '#67c100',
            },
            PAID: {
                img: `${urlHead}ic-payment-completed.svg`,
                label: 'Paid',
                color: '#666666',
                ilabel: 'Paid',
                slabel: 'Paid',
                icolor: '#666666',
                ovalColor: '#00BBAB',
            },
            NEW: {
                img: `${urlHead}ic-negociate.svg`,
                label: 'In Progress',
                color: '#a8a8a8',
                ilabel: 'Need to Submit',
                icolor: '#f16000',
            },
        };

        return map[id];
    }

    static getFilterLabel(name) {
        let returnValue = Format.capitalize(name.replace(/_/g, ' '));
        const map = {
            gender: { label: 'Gender' },
            age: { label: 'Age Range' },
            age_readonly: { label: 'Age' },
            language: { label: 'Language(s)' },
            languages: { label: 'Language(s)' },
            location: { label: 'Location' },
            ethnicity: { label: 'Ethnicity' },
            joined: { label: 'Date Joined' },
            created_on: { label: 'Date Joined' },
            relationship_status: { label: 'Relationship Status' },
            pets: { label: 'Pets Type' },
            network_total_reach: { label: 'Total' },
            network_facebook_reach: { label: 'Facebook' },
            network_instagram_reach: { label: 'Instagram' },
            network_twitter_reach: { label: 'Twitter' },
            network_youtube_reach: { label: 'YouTube' },
            network_blog_reach: { label: 'Blog' },
            name: { label: 'Creator First OR Last Name' },
            category: { label: 'Category' },
            rate_blog_post: { label: 'Blog Post' },
            rate_social_share: { label: 'Social Share' },
            rate_youtube: { label: 'YouTube' },
            rate_facebook_post: { label: 'Facebook Post' },
            rate_instagram_post: { label: 'Instagram Post' },
            email: { label: 'Email Address' },
            personal_summary: { label: 'Personal Summary' },
            active_memberships: { label: 'Active Memberships' },
            political_affiliation: { label: 'Political Affiliation' },
            favorite_color: { label: 'Favorite Colors' },
            politically_active: { label: 'Politically Active' },
            religion: { label: 'Religion' },
            favorites: { label: 'Favorites' },
            hobbies: { label: 'Interests / Hobbies' },
            brands: { label: 'Favorite Brands' },
            foods: { label: 'Favorite Foods' },
            rate_instagram_story: { label: 'Instagram Story' },
            mediakit_title: { label: 'Website Name' },
            mediakit_description: { label: 'Portfolio Description' },
            children: { label: 'Children' },
            rate_total: { label: 'Total' },
            birthday: { label: 'Birthday' },
            date_of_birth: { label: 'Birthday' },
            agent_name: { label: 'Agent Name / Company' },
            anniversary: { label: 'Anniversary Date' },
            rate: { label: 'Rates' },
            userinfo: { label: 'Keyword' },
            social_reach: { label: 'Reach' },
            occupation: { label: 'Occupation' },
        };
        if (Object.keys(map).indexOf(name) > -1) {
            returnValue = map[name].label;
        }
        return returnValue;
    }

    static wordCount(txt) {
        let txt2 = txt.replace(/(^\s*)|(\s*$)/gi, '');
        txt2 = txt2.replace(/[ ]{2,}/gi, ' ');
        txt2 = txt2.replace(/\n /, '\n');
        return txt2.split(' ').length;
    }

    static fileExtension(filename) {
        const pieces = filename.split('.');
        return pieces[pieces.length - 1];
    }

    static getAgentRole(role) {
        switch (role) {
            case SUPER_ADMIN_STR:
                return 'Super Admin';
            case BRAND_OWNER_STR:
                return 'Brand Owner';
            case AGENT_STR:
                return 'User';
            case VIEWER_STR:
                return 'Viewer';
            default:
                return '';
        }
    }
}
