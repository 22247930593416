import React from 'react';
import PropTypes from 'prop-types';
import classes from 'classnames';
import style from './styles.module.scss';

const SubmitButton = ({ onClick, className, children, isDisabled }) => (
    <div
        onClick={onClick}
        className={classes(style.submitButton, className, {
            [style.disabled]: isDisabled,
        })}
    >
        {children}
    </div>
);

SubmitButton.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
    children: PropTypes.node,
    isDisabled: PropTypes.bool,
};

export default SubmitButton;
