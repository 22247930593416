import React from 'react';
import Modal from 'react-modal';
import './postUpdateSuccessModal.scss';

export default function PostUpdateSuccessModal({ isOpen, close }) {
    return (
        <Modal overlayClassName="modalDialog" className="post-update-modal" isOpen={isOpen}>
            <img src="/images/ic-success-green.svg" className="success-icon" />

            <h3 className="modal-title">Success</h3>

            <p className="success-text">
                Your post URL has been updated! Please kindly be aware that the campaign statistics will now reflect the
                changes based on the newly updated post URL going forward.
            </p>

            <button className="close-btn" onClick={close}>
                Close
            </button>
        </Modal>
    );
}
