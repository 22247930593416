export default (array, sortType = 'ASC', field = 'id') => {
    let sortFunc;

    if (sortType === 'ASC') {
        sortFunc = (a, b) => {
            if (a[field] < b[field]) return -1;
            if (a[field] > b[field]) return 1;
            return 0;
        };
    } else if (sortType === 'DESC') {
        sortFunc = (a, b) => {
            if (a[field] > b[field]) return -1;
            if (a[field] < b[field]) return 1;
            return 0;
        };
    }

    array.sort(sortFunc);
};
