import React from 'react'
import './freeStringArray.scss'

/*
     This is the component for editing
     props :
     - strings
     - changeFunction
     - placeholder
 */

export default class FreeStringArray extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            stringsLoaded : false,
            strings : null,
            stringInProgress : ''
        }
    }

    delegateChange = () => {
        this.props.changeFunction(this.state.strings);
    }

    inputChangeHandler = (e) => {
        this.setState({
            stringInProgress : e.target.value
        })
    }

    blurHandler = (e) => {
        let content = e.target.value;
        console.log((typeof content === 'string') , (content.length > 0))
        if ( (typeof content === 'string') && (content.length > 0)) {
            this.addContent();
        }
    }

    addContent = () => {
        console.log('adding');
        let newStrings = this.state.strings;
        newStrings.push(this.state.stringInProgress);
        this.setState({
            strings : newStrings,
            stringInProgress : ''
        }, this.delegateChange)
    }

    inputKeyPressHandler = (e) => {
        if (e.key === 'Enter'){
            this.addContent();
        };
    }

    renderedAdded = (tx, index) => {

        let handleDelete = () => {
            let newStrings = [];
            for (let str of this.state.strings) {
                newStrings.push(str);
            }
            newStrings.splice(index, 1);
            this.setState({ strings : newStrings}, this.delegateChange)
        }

        return <div className="added" key={index}>
            <div>{tx}</div>
            <div
                className="delete"
                title={ 'delete ' + tx}
                onClick={handleDelete}
            >
                x
            </div>
        </div>
    }

    static getDerivedStateFromProps(props, state) {
        let newState = {};
        if (!state.stringsLoaded && Array.isArray(props.strings)) {
            newState.stringsLoaded = true;
            newState.strings = props.strings;
        }
        return newState;
    }

    render() {
        let strings = this.state.strings;
        let addedList = [];
        for (let i=0; i<strings.length; i++) {
            addedList.push(this.renderedAdded(strings[i], i))
        }
        return(
            <div className="freeStringArray">
                <input
                    onKeyPress={this.inputKeyPressHandler}
                    onChange={this.inputChangeHandler}
                    value={this.state.stringInProgress}
                    placeholder={this.props.placeholder || ''}
                    onBlur={this.blurHandler}
                    maxLength={50}
                />
                <div className="showAdded">
                    { addedList }
                </div>

            </div>
        )
    }
}