import React from 'react'

export default class PercentBar extends React.Component {

    constructor (props, context) {
        super(props, context);

        this.renderedPiece = this.renderedPiece.bind(this);
        this.state = {
            smallWidth : 34
        }
    }

    renderedPiece(startPercent) {
        let percent = this.props.percent;
        let style = { width: this.state.smallWidth, minWidth: this.state.smallWidth};
        if (percent >= startPercent+20) {
            style.backgroundColor = this.secondColor;
        }
        let insideStyle = {};
        if (percent > startPercent && percent < startPercent + 20) {
            insideStyle.width = this.state.smallWidth * (percent-startPercent) /20;
            insideStyle.height = 8;
            insideStyle.backgroundColor = this.secondColor;
        }
        return(
            <div style={style} key={startPercent}>
                <div style={insideStyle}></div>
            </div>
        )
    }

    componentDidMount() {
        if (this.props.size) {
            this.setState({
                smallWidth: this.props.size
            })
        }
    }


    render () {
        let percent = this.props.percent;

        this.secondColor = '#00336d';
        if (percent >= 80) {
            this.secondColor = '#00336d'
        } else if (percent >= 60) {
            this.secondColor = '#00864d';
        }

        let pieces = [];
        for (let i=0; i<100; i=i+20) {
            pieces.push(this.renderedPiece(i));
        }

        return (
            <div className="percentBar" style={{minWidth:5*this.state.smallWidth + 4*4 + 3}}>
                { pieces }
            </div>
        );
    }


}
