import combineEvents from '../../combineEvents';
import {
    ASSIGNMENT_SELECT_RESET,
    ASSIGNMENT_SELECT_SET_DATA,
} from './actions';

export const allAssignmentsFilter = {
    value: 'All',
    label: 'All Assignments',
};

const initialState = {
    label: 'Assignments:',
    selectOptions: [allAssignmentsFilter],
    selectedOptions: [allAssignmentsFilter],
    permissions: ['ROLE_AGENT', 'ROLE_SUPER_ADMIN', 'ROLE_BRAND_OWNER'],
};

export default combineEvents({
    [ASSIGNMENT_SELECT_RESET]: (state) => ({
        ...state,
        selectedOptions: [allAssignmentsFilter],
    }),
    [ASSIGNMENT_SELECT_SET_DATA]: (state, { payload }) => ({
        ...state,
        ...payload,
    }),
}, initialState);
