import React, { Component } from 'react';
import cx from 'classnames';
import moment from 'moment';
import ImageGallery from 'react-image-gallery';
import './AssignmentCompose.scss';
import Select from 'react-select';
import { connect } from 'react-redux';
import DraftComposer from '../../shared/draftComposer/draftComposer';
import Api from '../../../modules/Api';
import Lookup from '../../../modules/Lookup';
import PleaseWait from '../../shared/pleaseWait/PleaseWaitWhite';
import ApiError from '../../shared/apiError/ApiError';
import { storeUserProfile } from '../../../store/global/globalActions';
import AssignmentDetails from './AssignmentADetails';
import '../../shared/InfChatBox/infTabHeaders.scss';
import InstagramComposer from '../../shared/InstagramComposer/InstagramComposer';
import PublishContent from '../PublishContent';
import { NEEDS_REVIEW } from '../../../constants/statusTypes';
import FeedbackOnTheTopImproved from '../../shared/feedbackOnTheTop/FeedbackOnTheTopImproved';
import UserDraftPostResources from "../../campaign/draftComment/UserDraftPostResources";
import ManageMediaModal from "../../campaign/draftComment/ManageMediaModal";
import ProfileHelper from '../../../modules/utils/ProfileHelper';
import { TALENT_MANAGER_STR, INFLUENCER_STR, VIEWER_STR } from '../../../constants/authorities';

const resolutions = [{value: "fhdUrl", label: "1080p"}, {value: "shdUrl", label: "720p"}, {value: "sdUrl", label: "480p"}, {value: "lsdUrl", label: "360p"}];
class AssignmentComposeEdit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            disclaimer: false,
            assignment: '',
            manageAssignments: true,
            assignmentTypeOfPost: '',
            title: '',
            currentView: 1,
            comments: [],
            commentFocus: 0,
            commentShow: false,
            isCommentsDiscussionTypeOpen: true,
            editorData: null,
            profile: null,
            suggestions: null,
            draftPost: null,
            resourceList: [],
            archivedResourceList: [],
            showDownloading: false,
            showPostInReviewMessage: false,
            onUploadCancel: null,
            waiting: false,
            isManageMediaModalVisible: false,
            showVideo: false,
            saveAllowed: false
        }

        this.showApiError = this.showApiError.bind(this);
        this.fetchResourceList = this.fetchResourceList.bind(this);
        this.downloadVideo = this.downloadVideo.bind(this);
        this.handlePublishSuccess = this.handlePublishSuccess.bind(this);
        // this.submitDraft=this.submitDraft.bind(this);
        this.assignmentId = this.props.match.params.uid;
        this.postId = this.props.match.params.postId;
        this.assignmentUserId = this.props.match.params.assignmentUserId;
        this.type = this.props.match.params.type;
        const params = new URLSearchParams(this.props.location.search);
        this.influencerId = params.get('influencerId');
        this.isTalentAgent = false;
        this.influencerUid = '';
    }

    componentDidMount() {
        this.fetchResourceList();
        ProfileHelper.getCurrentLoginUserProfile().then((profile) => {
            this.props.storeProfile(profile);
            this.uid = profile.uid;
            this.setState({ profile });

            if (this.influencerId) {
                // Talent agent, already populated from params
                this.isTalentAgent = true;
            } else {
                // Influencer is logged in, this.influencerId is not coming from params, get influencer id from profile object
                this.influencerId = profile.id;
            }

            if (profile && profile.role === TALENT_MANAGER_STR) {
                Api.getTMAssignment(this.assignmentId, this.influencerId).then(
                    (res) => {
                        this.influencerUid = res.user.uid;
                        this.setState({ assignment: res.assignment, manageAssignments: res.manageAssignments, waiting: false });
                        Api.getDraftPost(Number(this.assignmentId), this.postId, res.user.uid).then(
                            (result) => {
                                this.setState({
                                    draftPost: result,
                                    title: result.title,
                                    assignmentTypeOfPost: result.type,
                                    waitingForDraft: false,
                                });
                            },
                            (error) => {
                                let errorFinal = error.message;
                                let apiErrTitle;
                                if (error.originalMessage && error.originalMessage.startsWith("Insufficient privileges")) {
                                    apiErrTitle = "Permission Denied";
                                    errorFinal = "You do not have access to perform this, please contact your creator."
                                }
                                this.setState({
                                    showApiError: true,
                                    apiErrTitle,
                                    apiError: errorFinal,
                                    waiting: false,
                                });
                            },
                        );
                    },
                    (err) => {
                        this.showApiError(err)
                    },
                );
            } else {
                Api.getAssignmentUser(this.assignmentId, this.uid).then(
                    (res) => {
                        this.setState({
                            assignment: res.assignment,
                        });
                        Api.getDraftPost(Number(this.assignmentId), this.postId)
                            .then((result) => {
                                this.setState({
                                    draftPost: result,
                                    title: result.title,
                                    assignmentTypeOfPost: result.type,
                                    waiting: false,
                                })
                            })
                            .catch(err => {
                                this.showApiError(err);
                            })
                    })
                    .catch(error => {
                        this.showApiError(error);
                    });
            }
        });
    }

    createOptions = (res) => {
        const options = [];
        resolutions.map(resolution => {
            if (res[resolution.value]) {
                options.push(resolution);
            }
        })
        return {options, selected: options.length > 0 ? options[0].value : ""};
    }

    fetchResourceList() {
        Api.getAllUserDraftPostResources(this.postId).then((result) => {
            const resourceList = [];
            if (result && result.content) {
                for (let i = 0; i < result.content.length; i++) {
                    resourceList.push({
                        ...result.content[i],
                        ...this.createOptions(result.content[i].resource),
                        size: 0,
                        percentage: 0,
                        isComplete: false,
                    });
                }
            }
            this.setState({
                resourceList: resourceList.filter(r => r.status === "ACTIVE"),
                archivedResourceList: resourceList.filter(r => r.status === "ARCHIVED")
            });
        }).catch((error) => {
            this.showApiError(error);
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const {draftPost} = this.state;
        const draftPostChanged = (!prevState.draftPost && draftPost) || (prevState.draftPost && draftPost && prevState.draftPost.id !== draftPost.id)
        if (draftPostChanged && draftPost.status === NEEDS_REVIEW)
            this.setState({showPostInReviewMessage: true});
    }

    saveComments = (threadId, comments) => {
        const otherThreadsComments = this.state.comments.filter(c => c.threadId != threadId);
        const combinedComments = [...otherThreadsComments, ...comments];
        combinedComments.sort((a, b) => new Date(b.updatedOnLocal) - new Date(a.updatedOnLocal));

        this.setState({
            comments: combinedComments,
        });
    };

    saveEditorData = (editorData) => {
        this.setState({ editorData });
    };

    submitDraft = async (status, isRedirect) => {
        const {
            editorData,
            draftPost,
        } = this.state;
        const payload = {
            content: editorData,
            status: status || 'NEEDS_REVIEW',
        };
        const haveImage = false;
        const isContentEmpty = false; // !currentContent.hasText() && !haveImage;
        if (true) {
            try {
                let response;
                if (this.state.profile && this.state.profile.role && this.state.profile.role === TALENT_MANAGER_STR) {
                    if (this.influencerUid) {
                        response = await Api.updateDraftPost(this.assignmentId, this.postId, payload, this.influencerUid);
                        if (!!response && isRedirect)
                            this.props.history.push(`/assignments/${this.assignmentId}/draftList?influencerId=${this.influencerId}`);
                    }
                } else {
                    response = await Api.updateDraftPost(this.assignmentId, this.postId, payload);
                    if (!!response && isRedirect)
                        this.props.history.push(`/assignments/${this.assignmentId}/draftList`);
                }
            } catch (error) {
                this.showApiError(error);
            }
        }
    };

    publishSinglePostIG = (formData) => {
        this.setState({ waiting: true });
        Api.PublishSinglePost(formData).then(
            (res) => {
                if (res.status) {
                    this.setState({ showApiError: true, apiError: res.message, waiting: false });
                } else {
                    this.props.history.push({
                        pathname: `/assignments/${this.assignmentId}/draftList`,
                        search: this.state.profile && this.state.profile.role === TALENT_MANAGER_STR ? `?influencerId=${this.influencerId}` : '',
                        state: { publishSuccess: true, waiting: false },
                    });
                }
            },
            (error) => {
                this.showApiError(error);
            },
        );
    };

    showApiError(error) {
        this.setState({
            showApiError: true,
            apiError: error,
            waiting: false,
        });
    }

    uploadFileGradually(file, tempResourceId, publicRead) {
        const updateFileProgress = (progressPercentage) => {
            const resourceList = this.state.resources;
            if (progressPercentage > 0) {
                const resIndex = resourceList.findIndex(item => item.tempResourceId == tempResourceId);
                if (resIndex > -1 && resourceList[resIndex]) {
                    resourceList[resIndex].uploadedPercentage = progressPercentage;
                    resourceList[resIndex].isComplete = false;
                    this.setState({
                        resources: resourceList
                    });
                }
            }
        }

        const updateFileFinished = (response) => {
            if (response) {
                const resourceList = this.state.resources;
                const resIndex = resourceList.findIndex(item => item.tempResourceId == tempResourceId);
                if (resIndex > -1 && resourceList[resIndex]) {
                    Api.userDraftPostResourceAction(this.postId, response.id, "create")
                        .then(() => {
                            return this.fetchResources();
                        })
                        .catch(error => this.showApiError(error))
                }
            }
        }

        this.setState({
            uploadedPercentage: 1
        })

        Api.postS3FileGradually(file,
            (progressPercentage) => updateFileProgress(progressPercentage),
            publicRead,
            (fn) => this.setState({onUploadCancel: fn})
        ).then((response) => {
            updateFileFinished(response);
            this.setState({ onUploadCancel: null });
        }).catch((err) => {
            this.showApiError(err);
            this.setState({ onUploadCancel: null });
        })
    };

    removeVideo = (resourceId) => {
        if (this.state.onUploadCancel)
            this.state.onUploadCancel();

        if (this.state.showDownloading == false) {
            Api.userDraftPostResourceAction(this.postId, resourceId, "archive").then(() => {
                return this.fetchResources();
            });
        }
    }

    isValidVideo = (url) => {
        const tempUrl = url.toLowerCase();
        return (tempUrl.indexOf('.mov') != -1) || (tempUrl.indexOf('.mp4') != -1) || (tempUrl.indexOf('.ogg') != -1) || (tempUrl.indexOf('.webm') != -1);
    }

    isValidImage = (url) => {
        const tempUrl = url.toLowerCase();
        return (tempUrl.indexOf('.heic') != -1 || tempUrl.indexOf('.jpg') != -1) || tempUrl.indexOf('.jpeg') != -1 || (tempUrl.indexOf('.png') != -1) || (tempUrl.indexOf('.gif') != -1) || (tempUrl.indexOf('.webp') != -1);
    }

    showVideos = (resource, index) => {
        const videoPreview = {
            indexCount: index,
            resourceId: resource.resource.id,
            original: resource.resource.url,
            thumbnail: '/images/ic-play.png',
            name: resource.resource.name,
            options: resource.options,
            selected: resource.resource.selected,
            isValidImage: this.isValidImage(resource.resource.name),
            isValidVideo: this.isValidVideo(resource.resource.name)
        };

        if (videoPreview.options.length > 0 && resource.resource[resource.resource.selected]) {
            videoPreview.original = resource.resource[resource.resource.selected];
            videoPreview.isValidVideo = true;
        }

        this.setState({
            showVideo: true,
            videoPreview,
        });
    }

    createImages = (videoPreview) => {
        return [{ original: videoPreview?.original }];
    }

    optionsChange = (option, videoPreview) => {
        const resourcesList = Object.assign([], this.state.resourceList);

        resourcesList[videoPreview.indexCount].resource.selected = option.value;

        videoPreview.selected = option.value;
        videoPreview.original = resourcesList[videoPreview.indexCount].resource[option.value];

        this.setState({
            videoPreview,
            resourceList: resourcesList
        })
    }

    downloadVideo = (resourceId) => {
        const resourceList = this.state.resourceList.concat(this.state.archivedResourceList)
        const resIndex = resourceList.findIndex(item => item.resource.id == resourceId);
        if (this.state.showDownloading == false) {
            // Api.getS3File(url, name); // download without progress bar
            this.setState({
                // enable download progress UI
                showDownloading: true
            }, () => {
                this.downloadFileListGradually(resIndex, resIndex + 1);
            });
        }
    }

    downloadFileListGradually(fileCountStart, fileCountEnd) {
        let currentFileCount = fileCountStart;

        const isCancelFileDownload = () => {
            return this.state.showDownloading == false;
        }

        const updateFileProgress = (fileNumber, progressPercentage) => {
            if (isCancelFileDownload()) {
                return true;
            }
            if (progressPercentage > 0) {
                const downloadList = this.state.resourceList;
                downloadList[fileNumber].percentage = progressPercentage;
                downloadList[fileNumber].isComplete = false;
                this.setState({
                    resourceList: downloadList,
                    showDownloading: true,
                });
            }

        }

        const updateFileFinished = (fileNumber, response) => {
            const downloadList = this.state.resourceList;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', downloadList[fileNumber].resource.name);
            document.body.appendChild(link);
            link.click();

            downloadList[fileNumber].percentage = 100;
            downloadList[fileNumber].isComplete = true;
            this.setState({
                resourceList: downloadList,
                showDownloading: true,
            });

            currentFileCount++
            downloadFile(currentFileCount);
        }

        const downloadFile = (currentFileCount) => {
            const downloadList = this.state.resourceList;
            if (downloadList.length > 0 && currentFileCount > -1 && downloadList.length > currentFileCount && fileCountEnd > currentFileCount) {
                // update to show that new file is downloading in progress
                updateFileProgress(currentFileCount, 1);
                // Call gradual download
                Api.getS3FileGradually(downloadList[currentFileCount].resource.url,
                    (progressPercentage) => updateFileProgress(currentFileCount, progressPercentage),
                    (response) => updateFileFinished(currentFileCount, response));
            } else {
                this.setState({
                    showDownloading: false
                })
            }
        }

        downloadFile(currentFileCount);
    }

    handlePublishSuccess() {
        this.props.history.push({
            pathname: `/assignments/${this.assignmentId}/draftList`,
            search: this.state.profile && this.state.profile.role === TALENT_MANAGER_STR ? `?influencerId=${this.influencerId}` : '',
            state: { publishSuccess: true },
        });
    }

    closePostInReviewMessage() {
        this.props.history.push({
            pathname: `/assignments/${this.assignmentId}/draftList`,
            search: this.state.profile && this.state.profile.role === TALENT_MANAGER_STR ? `?influencerId=${this.influencerId}` : ''
        });
    }

    renderContent() {
        const {
            assignment,
            manageAssignments,
            currentView,
            assignmentTypeOfPost,
            title,
            resourceList,
            profile,
            draftPost
        } = this.state;
        const { readonly } = this.props;
        const readOnlyModeForEditor = readonly || postStatus === NEEDS_REVIEW || !manageAssignments;
        const locationSearchParams = new URLSearchParams(this.props.location.search);
        const isContentEmpty = false; // !currentContent.hasText() && !haveImage;
        const postType = draftPost && draftPost.type;
        const postStatus = draftPost && draftPost.status;
        return (
            <>
                {currentView === 1 && (
                    <div className="leftSection leftSectionSaved">
                        {(!readOnlyModeForEditor && this.state.draftPost) && (
                            <div className="assignment-compose-media-container">
                                <UserDraftPostResources resourceList={resourceList}
                                    userDraftPostId={this.state.draftPost.id}
                                    refreshResources={this.fetchResourceList}
                                    showApiError={this.showApiError}
                                    // showVideos={resource => this.downloadVideo(resource.resource.id)}
                                    showVideos={this.showVideos}
                                    openMediaModal={() => this.setState({isManageMediaModalVisible: true})}
                                    thumbnailOverlayAdditionalStyles={{top: "85px"}}
                                    withDelete
                                    profile={profile}
                                    isBrandViewer={profile && profile.role === VIEWER_STR}/>
                            </div>
                        )}
                        <div className={cx("composerEditContainer composerEditContainerSaved", !(!readOnlyModeForEditor && this.state.draftPost) && "changedEditor")}>
                            {Boolean(this.props.readonly) &&
                                (this.type === 'INSTAGRAM_POST' || this.type === 'INSTAGRAM_STORY' ? (
                                    <InstagramComposer
                                        type={this.type}
                                        readOnly={!manageAssignments}
                                        assignmentUserId={this.assignmentUserId}
                                        postId={this.postId}
                                        onPublishSinglePost={(formData) => {
                                            this.publishSinglePostIG(formData);
                                        }}
                                        resourcesList={resourceList}
                                        uploadFiles={(files) => this.handleVideosUpload(files)}
                                        assignmentChoreId={locationSearchParams.get('assignmentChoreId')}
                                        typeLabel={postType && Lookup.getRateType(postType).label}
                                        statusLabel={postStatus && Lookup.getStatusType(postStatus).ilabel}
                                        cancelFunc={() =>
                                            this.props.history.push({
                                                pathname: `/assignments/${  this.assignmentId  }/draftList`,
                                                search: this.isTalentAgent ? `?influencerId=${this.influencerId}` : '',
                                            })
                                        }
                                    />
                                ) : (
                                    <PublishContent
                                        assignmentId={assignment.id}
                                        readOnly={!manageAssignments}
                                        assignmentUserId={this.assignmentUserId}
                                        publishInfo={[{ type: this.type, postId: this.postId }]}
                                        publishedSuccessFunction={this.handlePublishSuccess}
                                        assignmentChoreId={locationSearchParams.get('assignmentChoreId')}
                                        apiErrorFunction={(err) => {
                                            this.showApiError(err)
                                        }}
                                        typeLabel={postType && Lookup.getRateType(postType).label}
                                        statusLabel={postStatus && Lookup.getStatusType(postStatus).ilabel}
                                        cancelFunc={() =>
                                            this.props.history.push({
                                                pathname: `/assignments/${  this.assignmentId  }/draftList`,
                                                search: this.isTalentAgent ? `?influencerId=${this.influencerId}` : '',
                                            })
                                        }
                                    />
                                ))}
                            {
                                readOnlyModeForEditor && resourceList && resourceList.length > 0 && (
                                    <div className="video-name-list readOnlyMode">
                                        { resourceList.map(r => r.resource).map(({ name, id, tempResourceId, url, percentage, uploadedPercentage }, index) => (
                                            <div index={name + (tempResourceId || id)} key={name + (tempResourceId || id)} className={((percentage && (percentage > 0 && percentage < 100)) || (uploadedPercentage && (uploadedPercentage > 0 && uploadedPercentage < 100))) ? 'video-name video-downloading' : 'video-name'}>
                                                <p onClick={() => this.downloadVideo(id)}>{name}</p>
                                                {(percentage > 0 && percentage < 100) && <p className="video-percentage">Downloading {percentage || 0}%</p>}
                                            </div>
                                        ))}
                                    </div>
                                )
                            }
                            <>
                                {!this.props.readonly && Boolean(assignmentTypeOfPost) && isContentEmpty && <div className="inputNotice"><img alt="" src="/images/ic-small-warning.svg" /> Please input {Lookup.getRateType(assignmentTypeOfPost).label} Description</div>}
                                <DraftComposer
                                    title={title}
                                    onTitleChange={(e) => this.setState({ title: e.target.value })}
                                    readonly={readOnlyModeForEditor}
                                    assignmentId={this.assignmentId}
                                    commentFocusFunction={(commentId) => this.setState({ commentFocus: commentId, isCommentsDiscussionTypeOpen: true })}
                                    saveEditorData={this.saveEditorData}
                                    user={this.state.profile}
                                    assignment={this.state.assignment}
                                    postId={this.postId}
                                    saveComments={this.saveComments}
                                    draftPost={this.state.draftPost}
                                    influencerUID={this.influencerUid}
                                    changeEditState={e => this.setState({ saveAllowed: e })}
                                />
                            </>
                        </div>
                    </div>
                )}
                {currentView === 2 && (
                    <div className="leftSection">
                        <AssignmentDetails location={this.props.location} assignment={assignment} uid={this.uid} profile={this.state.profile} />
                    </div>
                )}
            </>
        )
    }

    render() {
        const {
            assignment,
            currentView,
            manageAssignments,
            businessUser,
            comments,
            commentFocus,
            profile,
            editorData,
            showVideo,
            videoPreview,
            saveAllowed
        } = this.state;
        const postStatus = this.state.draftPost && this.state.draftPost.status;
        const postType = this.state.draftPost && this.state.draftPost.type;
        const { readonly } = this.props;
        const readOnlyModeForEditor = readonly || postStatus === NEEDS_REVIEW || !manageAssignments;
        const addExtraTop = profile && (profile.role === TALENT_MANAGER_STR || profile.role === INFLUENCER_STR) && !profile.accountVerified;
        const videoWidth = Math.floor(window.innerWidth * 0.6);
        return (
            <div className="assignmentCompose">
                <PleaseWait show={this.state.waiting} />
                <ApiError
                    show={this.state.showApiError}
                    errTitle={this.state.apiErrTitle}
                    error={this.state.apiError}
                    cancelFunction={() => {
                        this.setState({ showApiError: false, waiting: false, apiErrTitle:'', apiError: null });
                    }}
                />
                {this.state.isManageMediaModalVisible &&
                    <ManageMediaModal   close={() => this.setState({isManageMediaModalVisible: false})}
                        resources={this.state.resourceList}
                        archivedResources={this.state.archivedResourceList}
                        userDraftPostId={this.state.draftPost.id}
                        showApiError={this.showApiError}
                        // showVideos={resource => this.downloadVideo(resource.resource.id)}
                        showVideos={this.showVideos}
                        refreshResources={this.fetchResourceList}
                        withDelete
                        profile={profile}/>
                }
                {/* <div className={cx("listHeaderContainer", {extraHeader: addExtraTop})}>
                    <div className="listHeader">
                        <div className="contentDiv">
                            <div className="goBack">
                                {assignment.campaign && assignment.campaign.name}:{' '}
                                <span>{assignment.name && assignment.name}</span>
                            </div>
                            <div className="actionDropDown">
                                <Select
                                    className="select"
                                    clearable={false}
                                    value={currentView}
                                    placeholder=""
                                    searchable={false}
                                    options={[
                                        { value: 1, label: 'My Post' },
                                        { value: 2, label: 'Assignment Details' },
                                    ]}
                                    onChange={(selected) => {
                                        this.setState({ currentView: selected.value });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="breadCrumb">
                    <div className="crumbContainer">
                        <div className="homeImage" onClick={() => this.props.history.push({ pathname: '/talentOpportunities', state: parentStates})} >
                            <img src="/images/home.svg" alt="home" />
                        </div>
                        <div className="slash">/</div>
                        <div className="crumbLink" onClick={() => this.props.history.push({ pathname: '/opportunities', state: parentStates})} >
                            Opportunities
                        </div>
                        <div className="slash">/</div>
                        <div className="crumbLink" onClick={() =>
                            this.props.history.push({
                                pathname: `/assignments/${  this.assignmentId  }/draftList`,
                                search: this.isTalentAgent ? `?influencerId=${this.influencerId}` : '',
                            })
                        }>
                            <div className="assignmentLabel">{assignment.campaign && assignment.campaign.name}:{' '}{assignment.name && assignment.name}</div>
                            <div className="slash">/</div>
                            <div className="assignmentLabel">{currentView === 1 ? "My Post" : "Assignment Details"}</div>
                        </div>
                        <div className="slash">/</div>
                        <div className="crumbLink inactive">{postType && Lookup.getRateType(postType).label}</div>
                    </div>
                </div>
                <div className={cx("contentContainer", {extraContentTop: addExtraTop})}>
                    {!readOnlyModeForEditor && this.state.draftPost && <div className="headerTitle">
                        <div className="composerHeader composerHeaderSaved">
                            <div className="title">
                                <div>
                                    <img
                                        src="/images/ic-back-b.svg"
                                        onClick={() =>
                                            this.props.history.push({
                                                pathname: `/assignments/${  this.assignmentId  }/draftList`,
                                                search: this.isTalentAgent ? `?influencerId=${this.influencerId}` : '',
                                            })
                                        }
                                    />
                                </div>
                                <div>
                                    <span>{postType && Lookup.getRateType(postType).label}</span>
                                    <span className="breaker">&nbsp;|&nbsp;</span>
                                    <span className="status">
                                        {postStatus && Lookup.getStatusType(postStatus).ilabel}
                                    </span>
                                </div>
                            </div>
                            {!readOnlyModeForEditor && (
                                <div className="buttons">
                                    <div className="spinner" />
                                    <span id="indicator" />
                                    <button
                                        className={cx("composerButton submit", {disabled: !editorData || !saveAllowed})}
                                        onClick={editorData && saveAllowed ? () => this.submitDraft('NEEDS_REVIEW', true) : () => {}}
                                    >
                                        Submit to Brand
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>}
                    <div className="contentDiv">
                        {postStatus === NEEDS_REVIEW ? null : this.renderContent()}
                    </div>
                </div>
                <FeedbackOnTheTopImproved
                    show={this.state.showPostInReviewMessage}
                    message="Draft is being reviewed. Please check back later."
                    closeFunction={() => this.closePostInReviewMessage()}

                />
                {showVideo && videoPreview && videoPreview.original && (
                    <div className="video-preview">
                        <div className="video-preview-wrapper">
                            <div className="video-preview-actions">
                                <button
                                    className="download-btn"
                                    onClick={() => this.downloadVideo(videoPreview.resourceId)}
                                >
                                    <img
                                        src='/images/email-assets/ic-invited-assignment.png'
                                        alt="download"
                                    />
                                    Download
                                </button>
                                <div className="close-btn" onClick={() => this.setState({ showVideo: false, videoPreview: null })} />
                            </div>
                            {videoPreview.isValidVideo == true ?
                                <video
                                    src={videoPreview.original}
                                    controls
                                    autoPlay
                                    width={videoWidth}
                                >
                                    <source src={videoPreview.original} type="video/mp4" />
                                    <source src={videoPreview.original} type="video/ogg" />
                                    <source src={videoPreview.original} type="video/webm" />
                                    Your browser doesn't support HTML5 video tag.
                                </video>
                                : videoPreview.isValidImage === true ?
                                    <div className="image-preview-wrapper">
                                        <ImageGallery
                                            items={this.createImages(videoPreview)}
                                            showPlayButton={false}
                                            showFullscreenButton={false}
                                            showThumbnails={false}
                                        // onSlide={this.onSlide}
                                        />
                                    </div>
                                    : <div className="not-valid-video">
                                        Cannot play the file in browser. Please download the file to view it.
                                    </div>}
                            {videoPreview.isValidVideo == true && <div className="videoOptions">
                                <Select className="drop-up"
                                    clearable={false}
                                    searchable={false}
                                    value={videoPreview.selected}
                                    single
                                    disabled={!videoPreview.options.length}
                                    placeholder="Quality"
                                    options={videoPreview.options}
                                    onChange={(option) => this.optionsChange(option, videoPreview)}
                                />
                            </div>}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        storeProfile: (profile) => {
            dispatch(storeUserProfile(profile));
        },
    };
};

export default connect(null, mapDispatchToProps)(AssignmentComposeEdit);
