import Format from '../utils/Format'

let networkListPost = ['twitter',
    'facebook',
    'instagram',
    'youtube',
    'blog',
    'linkedin',
    'pin',
    'reddit',
    'snapchat',
    'tiktok',
    'tumblr',
    'twitch',
];

let networksPost = {
    twitter :  {
        color : '#1CA1F2',
        rate : 'SOCIAL_SHARE'
    },
    facebook : {
        color : '#2E487D',
        rate : 'FACEBOOK_POST'
    },
    instagram : {
        color : '#D32588',
        rate : ['INSTAGRAM_POST', 'INSTAGRAM_STORY']
    },
    youtube : {
        color : '#D61300',
        rate: 'YOUTUBE'
    },
    blog : {
        color :'#FF6300',
        rate : 'BLOG_POST'
    },
    share : {
        color :'#FF6300',
        rate : 'SOCIAL_SHARE'
    },
    tiktok : {
        color :'#121212',
        rate : 'TIKTOK_POST'
    },
    twitch : {
        color :'#59399B',
        rate : 'TWITCH_POST'
    },
    pin : {
        color :'#CC2127',
        rate : 'PIN_POST'
    },
    snapchat : {
        color :'#FFE400',
        rate : 'SNAPCHAT_POST'
    },
    linkedin : {
        color :'#0077B5',
        rate : 'LINKEDLN_POST'
    },
    tumblr : {
        color :'#323E4D',
        rate : 'TUMBLR_POST'
    },
    reddit : {
        color :'#FF5700',
        rate : 'REDDIT_POST'
    },
}

let networkList = ['twitter',
    'facebook',
    'instagram',
    'youtube',
    'blog',
    'tiktok',
    'snapchat',
];

let networks = {
    twitter :  {
        color : '#1CA1F2',
        rate : 'SOCIAL_SHARE'
    },
    facebook : {
        color : '#2E487D',
        rate : 'FACEBOOK_POST'
    },
    instagram  : {
        color : '#D32588',
        rate : ['INSTAGRAM_POST', 'INSTAGRAM_STORY']
    },
    youtube : {
        color : '#D61300',
        rate: 'YOUTUBE'
    },
    blog  : {
        color :'#FF6300',
        rate : 'BLOG_POST'
    },
    tiktok : {
        color :'#121212',
        rate : 'TIKTOK_POST'
    },
    snapchat : {
        color :'#FFE400',
        rate : 'SNAPCHAT_POST'
    },
}

export default class SocialNetworks {

    static list() {
        return networkList.sort();
    }

    static getColor(n) {
        return networks[n.toLowerCase()].color;
    }

    static getLabel(n) {
        let label = networks[n.toLowerCase()].label;
        if (label) {
            return label;
        } else {
            return n.substr(0,1).toUpperCase() + n.substr(1);
        }
    }

    static getRateTypes(n) {
        let network = networks[n.toLowerCase()];
        return (!!network) ? network.rate : 0;
    }

    static getNetworkOptionListPost() {
        let retval = [];
        for (let id of networkListPost) {
            let label = networksPost[id].label || id;
            let color = networksPost[id].color;
            retval.push({
                id : id.toUpperCase(),
                label : Format.capitalize(label),
                color: color,
                image : '/images/social-' + id + '.svg'
            })
        }
        return retval;
    }

    static getNetworkOptionList() {
        let retval = [];
        for (let id of networkList) {
            let label = networks[id].label || id;
            let color = networks[id].color;
            retval.push({
                id : id.toUpperCase(),
                label : Format.capitalize(label),
                color: color,
                image : '/images/social-' + id + '.svg'
            })
        }
        return retval;
    }

    static getNetwork(id) {
        let label = (networksPost[id.toLowerCase()] && networksPost[id.toLowerCase()].label) || id;
        let color = (networksPost[id.toLowerCase()] && networksPost[id.toLowerCase()].color) || '#fff';
        let retval = {
                id : id.toUpperCase(),
                label : Format.capitalize(label),
                color: color,
                image : '/images/social-' + id.toLowerCase() + '.svg'
        }
        return retval;
    }

    static updateNetworks ( userNetworks, changedKey, network) {
        let retval = [];
        let changed = false;
        for (let n of userNetworks) {
            if (n.networkType.toLowerCase() === changedKey.toLowerCase()) {
                if (network) {
                    retval.push(network);
                    changed = true;
                }
                continue;
            }
            retval.push(n)
        };
        if (!changed && network) {
            retval.push(network);
        }

        return retval;
    }
}
