import React from 'react'

import './onBoard.scss';
import SocialNetworks from '../../modules/influencer/SocialNetworks'
import ConnectToSocial from '../shared/connectToSocial/ConnectToSocial'
import SocialConnectionStatus from '../shared/connectToSocial/SocialConnectionStatus';
import ProfileConnectToSocial from '../shared/connectToSocial/ProfileConnectToSocial';

// props
// - profile (do we need it?)
// - networks
// - changeFunction takes a network key and an userNetwork object (or null)

export default class NetworksStep extends React.Component {

    constructor (props, context) {
        super(props, context);

        this.handleNetworkStatusChange = this.handleNetworkStatusChange.bind(this);

        this.state = {
            stepValid: this.props.networks.length > 0
        };

        this.networkMap = {};
        for (let networkKey of SocialNetworks.list()) {
            this.networkMap[networkKey] = {
                key : networkKey
            }
        }
    }

    handleNetworkStatusChange(name, newStatus) {
        let f = this.props.changeFunction;
        if (typeof f === 'function') {
            f(name, newStatus);
        }

        this.setState({
            stepValid: this.props.networks.length > 0
        })
    }

    getNetworkStats(network) {
        let result = {};
        let addedNetworks = this.props.networks;
        if (addedNetworks && (addedNetworks.length>0)) {
            addedNetworks.forEach(addedNetwork => {
                if (network.toLowerCase() === addedNetwork.networkType.toLowerCase()) {
                    result = addedNetwork;
                }
            });
        }
        return result;
    }

    render () {
        const { stepValid } = this.state;
        let connectBoxes = [];
        for (let network of SocialNetworks.list()) {
            let stats = this.getNetworkStats(network.toString());
            //console.log(stats);
            connectBoxes.push(
                <ConnectToSocial
                    key={network}
                    networkKey={network}
                    statusChangeFunction={this.handleNetworkStatusChange}
                    redirectSuffix={'redirect?page=onboard'}
                    networkStats={stats}
                    profile={this.props.profile}
                />
            );
        }

        return (
            <div className="networksStep">
                <SocialConnectionStatus/>

                <div className="stepTitle">
                    Connect Your Networks
                </div>
                <div className="stepDescription">
                The more social networks you add increases brand partnership opportunities</div>

                {!stepValid &&
                    <div className="main-error">
                        Please connect at least 1 social accounts
                    </div>
                }

                <div className="boxes">
                    { connectBoxes }
                </div>
            </div>
        );
    }
}
