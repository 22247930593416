import React from 'react';
import cx from 'classnames';
import TopNavigation from "../../shared/topNavigation/TopNavigation";
import './PermissionPage.scss'
import Toggle from "../../shared/toggle";
import Checkbox from "../../shared/checkbox/CheckBoxWithLabel";
import {withRouter} from "react-router-dom";
import Api from "../../../modules/Api";
import PleaseWait from "../../shared/pleaseWait/PleaseWaitWhite";
import PdfViewer from '../../shared/pdfViewer/PdfViewer';
import ApiError from '../../shared/apiError/ApiError';
import ProfileHelper from '../../../modules/utils/ProfileHelper';
import {TALENT_MANAGER_STR, INFLUENCER_STR} from '../../../constants/authorities';

class PermissionPage extends React.Component {

    constructor(props) {
        super(props);
        this.openContract = this.openContract.bind(this);
        this.closeContractView = this.closeContractView.bind(this);
    }

    state = {
        permissions: {
            editInfo: false,
            editSummary: false,
            editRates: false,
            editPortfolio: false,
            manageAssignment: false,
            viewAssignment: false,
        },
        terms: false,
        tmData: {
            id: null,
            firstname: '',
            lastname: '',
            pictureUrl: '',
            email: ''
        },
        tmInfo: null,
        apiError: null,
        loading: false,
        profile: null,
    };

    componentDidMount() {
        this.handleGetData();
        ProfileHelper.getCurrentLoginUserProfile()
            .then(profile => this.setState({profile}))
    }

    handleGetData = () => {
        const inviteId = this.props.match.params.managerId;
        this.setState({loading: true});
        try {
            // get permissions
            Api.getManagementData().then((res) => {
                const invite = res.find(i => i.id == inviteId);
                this.setState({inviteData: invite});
                // get TM
                Api.getTMData(invite.talentManagerId).then((response) => {
                    this.setState({tmData: response, loading: false})
                });
            })
        } catch (e) {
            this.setState({loading: false, apiError: e});
        }
    };

    handlePermissionChange = (name, value) => {
        if (name === 'viewAssignment' && !value) {
            this.setState({permissions: {
                ...this.state.permissions,
                [name]: value,
                manageAssignment: false,
            }});
            return;
        }
        if (name === 'declineAccept') {
            if (!this.state.permissions.viewAssignment) return;
            this.setState({permissions: {
                ...this.state.permissions,
                manageAssignment: value,
            }});
            return;
        }
        this.setState({permissions: {
            ...this.state.permissions,
            [name]: value
        }});

    };

    handleTermsChange = (value) => {
        this.setState({terms: value})
    };

    pushBack = () => {
        this.props.history.push('/profile/permissions/');
    };

    handleAccept = () => {
        Api.acceptTM(this.state.inviteData.id, this.state.permissions)
            .then(() => {
                this.pushBack();
            })
            .catch(err => {
                this.setState({apiError: err})
            })
    };

    openContract() {
        this.setState({ isContractOpen: true });
    }

    closeContractView() {
        this.setState({ isContractOpen: false });
    }

    render () {
        const {
            loading,
            permissions: {
                editInfo,
                editSummary,
                editRates,
                editPortfolio,
                viewAssignment,
                manageAssignment
            },
            tmData: {
                firstname,
                lastname,
                pictureUrl,
                email
            },
            apiError,
            profile
        } = this.state;
        const addExtraTop = profile && (profile.role === TALENT_MANAGER_STR || profile.role === INFLUENCER_STR) && !profile.accountVerified;

        return (
            <div className="permission-page">
                <TopNavigation  />
                {this.state.isContractOpen &&
                    <PdfViewer pdfUrl="/images/docs/PlatformPrivacyAgreement.pdf" onClose={() => this.closeContractView()} />
                }
                {loading ? <PleaseWait show={this.state.loading}/> :
                    <>
                    <ApiError
                            show={apiError}
                            error={apiError}
                            cancelFunction={() => this.setState({apiError: null})}
                        />
                    <div className={cx("blue-header", {extraTop: addExtraTop})}>
                            <div className="contentDiv">
                            <div className="controlContainer">Manager Permission</div>
                        </div>
                        </div>
                    <div className="permission-container">
                            <div className="permission-content">
                            <h3>Talent Manager</h3>
                            <div className="manager-info permission-list">
                                    <div className="profile-pic">
                                    <img src={pictureUrl} alt=""/>
                                </div>
                                    <div className="profile-info">
                                    <div className="manager-name">{`${firstname} ${lastname}`}</div>
                                    <div className="manager-email">{email}</div>
                                </div>
                                </div>
                            <h3>Permission</h3>
                            <div className="permissions-container">
                                    <div className="permission-block">
                                    <h3 className="permission-title">PROFILE</h3>
                                    <div className="permission-row">
                                            <div className="permission-label">Edit Info</div>
                                            <div className="checkbox-holder">
                                            <Toggle value={editInfo} handleChange={this.handlePermissionChange} name="editInfo" />
                                        </div>
                                        </div>
                                    <div className="permission-row">
                                            <div className="permission-label">Edit Summary</div>
                                            <div className="checkbox-holder">
                                            <Toggle value={editSummary} handleChange={this.handlePermissionChange} name="editSummary" />
                                        </div>
                                        </div>
                                    <div className="permission-row">
                                            <div className="permission-label">Edit Rates</div>
                                            <div className="checkbox-holder">
                                            <Toggle value={editRates} handleChange={this.handlePermissionChange} name="editRates" />
                                        </div>
                                        </div>
                                    <div className="permission-row">
                                            <div className="permission-label">Edit Portfolio</div>
                                            <div className="checkbox-holder">
                                            <Toggle value={editPortfolio} handleChange={this.handlePermissionChange} name="editPortfolio" />
                                        </div>
                                        </div>
                                </div>
                                    <div className="permission-block">
                                    <h3 className="permission-title">Opportunities </h3>
                                    <div className="permission-row">
                                            <div className="permission-label">View Assignment</div>
                                            <div className="checkbox-holder">
                                            <Toggle value={viewAssignment} handleChange={this.handlePermissionChange} name="viewAssignment" />
                                        </div>
                                        </div>
                                    <div className="permission-row">
                                            <div className="permission-label">
                                            <div>Manage Assignment:</div>
                                            <div style={{fontWeight: 'normal'}}>Accept, Negotiate and Decline</div>
                                            <div style={{fontWeight: 'normal'}}>Sign Contract</div>
                                            <div style={{fontWeight: 'normal'}}>Submit content</div>
                                        </div>
                                            <div className="checkbox-holder">
                                            <Toggle value={manageAssignment} handleChange={this.handlePermissionChange} name="manageAssignment" />
                                        </div>
                                        </div>
                                </div>
                                    <div className="terms-container">
                                    <Checkbox
                                            checked = {this.state.terms}
                                            changeFunction = {this.handleTermsChange}
                                            label="I have read and agree to the"
                                        />
                                    <div className="terms-message" onClick={this.openContract}>
                                            Term & Conditions
                                        </div>
                                </div>
                                </div>
                            <div className="manager-card-bottom permission-bottom">
                                    <button onClick={this.pushBack} className="button cancel" >Cancel</button>
                                    <button onClick={this.handleAccept} disabled={!this.state.terms} className={`button ${!this.state.terms ? 'disabled' : ''}`} >Accept</button>
                                </div>
                        </div>
                        </div>
                </>
                }
            </div>
        )
    }
}

export default withRouter(PermissionPage);
