import React from 'react'

let minCount = 120;
let maxCount = 400;

let addMessage = (state) => {
    let summary = state.summary;
    let characterCount = summary.length;
    let okFlag = true;
    let message = '';
    if (characterCount < minCount && characterCount>0) {
        message = characterCount + ' (Minimum ' + minCount+' characters)'
        okFlag = false;
    }
    else if (characterCount > maxCount) {
        message = characterCount + ' (>' + maxCount+ ')'
        okFlag = false;
    }
    else{
        message = characterCount;
    }
    state.message = message;
    state.okFlag = okFlag;
}

export default class Summary extends React.Component {
    constructor( props,context) {
        super(props.context);

        this.state = {
            summary : '',
            summaryLoaded : false,
            characterCount : 0
        };
    }

    handleSummaryChange = (e) => {
        let value = e.target.value;
        let newState = { summary : value};
        addMessage(newState);
        this.setState(newState, () => {
            this.props.setEnableSave(this.state.okFlag||this.state.summary==='')
        })
    }

    renderedSummaryDisplay = () => {
        return (
            <div className="displayMode">
                { this.state.summary }
            </div>)
    }

    renderedSummaryEditing = () => {
        return (
            <div>
                <textarea
                    value={this.state.summary ? this.state.summary : ''}
                    onChange={this.handleSummaryChange}
                    rows={4} maxLength={400}
                    placeholder="Write about yourself"
                />
                <div className="messageContainer">
                    <div>
                        { this.state.message }
                    </div>
                </div>
            </div>
        )
    }

    static getDerivedStateFromProps(props, state) {
        let newState = {};

        // first load
        if (!state.summaryLoaded && props.profile) {
            newState.summary = props.profile.summary;
            newState.summaryLoaded = true;
        }

        // getting out of local editing
        if ( state.storedEditMode && !props.editMode) {
            if (props.saveFlag) {
                let newProfile = props.profile;
                newProfile.summary = state.summary;
                if (props.isTalentManager) {
                    props.changeFunction({summary: state.summary});
                } else {
                    props.changeFunction(newProfile);
                }
            } else {
                newState.summary = props.profile.summary
            }
        }
        newState.storedEditMode = props.editMode;
        return newState;
    }

     render() {
        if (!this.props.profile) {
            return null;
        }
        return (
            <div className="profileSummary">
                {/*<div className="pieceTitle2">Summary</div>*/}
                { !this.props.editMode && this.renderedSummaryDisplay() }
                { this.props.editMode && this.renderedSummaryEditing() }
            </div>
        )
    }
}
