import React from 'react';

import { connect } from 'react-redux';
import Lookup from '../../modules/Lookup';
import Select from 'react-select';
import './campaign.scss';
import { Motion, spring } from 'react-motion';
import Api from '../../modules/Api';
import PleaseWaitPartial from '../shared/pleaseWait/PleaseWaitPartial';
import { SUPER_ADMIN_STR, BRAND_OWNER_STR, AGENT_STR, DisplayRoleNames } from '../../constants/authorities';
import MultiSelect from '../shared/multiSelect/MultiSelect';
import Tooltip from '../shared/tooltip/Tooltip';

// Props :
// - show
// - cancelFunction
// - proceedFunction - takes a CampaignList object

// function OptionalCampaignInput() {
//     return (
//         <span className="optional-text" style={{ fontWeight: 'normal'}}>
//             &nbsp;
//             (Optional)
//         </span>
//     )
// }

class CreateCampaign extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleBrandChange = this.handleBrandChange.bind(this);
        this.handleProductChange = this.handleProductChange.bind(this);
        this.handleCategoryChange = this.handleCategoryChange.bind(this);
        this.handleContactChange = this.handleContactChange.bind(this);
        this.handleBudgetChange = this.handleBudgetChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handlePanelClick = this.handlePanelClick.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleLogoChange = this.handleLogoChange.bind(this);
        this.cancelChanges = this.cancelChanges.bind(this);
        this.proceedWithCreation = this.proceedWithCreation.bind(this);
        this.handleSignerChange = this.handleSignerChange.bind(this);

        this.state = {
            rerenderCount: 1,
            clickCount: 1,
            readyToProceed: false,
            loadingAgents: false,
            currencyList: [],
            currency: 'USD',
        };

        this.isStartedMainSetup = false;
        this.isNewCampaign = !this.props.campaign;
        this.isEditingCampaign = !this.isNewCampaign;

        this.initProperties();
    }

    initProperties() {
        const contactlist = [];
        if (this.isEditingCampaign) {
            this.props.campaign.contacts.forEach((contact) => {
                contactlist.push(contact.id);
            });
            this.setState({ currency: this.props.campaign.currencyCode });
        }
        this.campaign = this.isEditingCampaign
            ? {
                  name: this.props.campaign.name,
                  brandId: this.props.campaign.agencyBrand.brandId,
                  productNames: this.props.campaign.productNames,
                  categoryNames: this.props.campaign.categoryNames,
                  contacts: contactlist,
                  signer: this.props.campaign.signer ? this.props.campaign.signer.id : '',
                  budget: Number(this.props.campaign.budget) > 20000000 ? 20000000 : this.props.campaign.budget || 0,
              }
            : {
                  name: '',
                  brandId: '',
                  productNames: [],
                  categoryNames: [],
                  contacts: contactlist,
                  signer: '',
                  budget: 0,
              };
        this.budgetInput = this.isEditingCampaign ? this.props.campaign.budget || 0 : '';
    }

    cancelChanges() {
        this.isStartedMainSetup = false;
        const f = this.props.cancelFunction;
        if (f && typeof f === 'function') {
            f();
        }
    }

    proceedWithCreation() {
        const { currency } = this.state;
        if (this.state.readyToProceed) {
            const f = this.props.proceedFunction;
            if (typeof f === 'function') {
                this.campaign.currencyCode = currency;
                f(this.campaign);
            }
        }
    }

    handleLogoChange(file) {
        this.campaign.base64Photo = file;
        this.handleChange();
    }

    handleClick() {
        this.setState({
            clickCount: this.state.clickCount + 1,
        });
    }

    handlePanelClick() {
        this.setState({
            clickCount: this.state.clickCount + 1,
        });
    }

    handleChange() {
        const isAcceptableString = function (x) {
            if (typeof x !== 'string') {
                return false;
            }
            return x.length !== 0;
        };

        let ready = true;
        for (const key of ['name']) {
            if (!isAcceptableString(this.campaign[key])) {
                ready = false;
            }
        }
        if (!this.campaign.productNames?.length) {
            ready = false;
        }
        if (!this.campaign.categoryNames?.length) {
            ready = false;
        }
        if (!this.campaign.contacts) {
            ready = false;
        } else if (!Array.isArray(this.campaign.contacts) || this.campaign.contacts.length === 0) {
            ready = false;
        }
        if (!this.campaign.signer) {
            ready = false;
        }
        if (this.campaign.budget === 0) {
            ready = false;
        }
        if (!/^[0-9]+(.[0-9]{2})?$/.test(this.campaign.budget)) {
            ready = false;
        }
        this.setState({
            readyToProceed: ready,
        });
    }

    handleNameChange(e) {
        this.campaign.name = e.target.value;
        this.handleChange();
    }

    handleBrandChange(e) {
        this.campaign.brandId = e.value;
        this.updateContactList(this.campaign.brandId);
        this.setState({ currency: e.currencyCode });
        this.handleChange();
    }

    handleProductChange(e) {
        this.campaign.productNames = e;
        this.handleChange();
    }

    handleCategoryChange(e) {
        this.campaign.categoryNames = e;
        this.handleChange();
    }

    handleContactChange(value) {
        if (value && value.length > 0) {
            this.campaign.contacts = value.split(',');
        } else {
            this.campaign.contacts = null;
        }
        this.handleChange();
    }

    handleSignerChange = (value) => {
        this.campaign.signer = value || '';
        this.handleChange();
    };

    handleBudgetChange(e) {
        const value = e.target.value.replace(/,/gi, '');
        const reg = /^\d+$/;
        if (e.target.value === '') {
            this.campaign.budget = 0;
            this.budgetInput = '';
            this.handleChange();
        }
        if (reg.test(value)) {
            if (value <= 20000000) {
                const re = /(?=(?!\b)(\d{3})+$)/g;
                this.budgetInput = value.replace(re, ',');
                this.campaign.budget = value;
                this.handleChange();
            }
        }
    }

    componentDidUpdate(prevProps) {
        const self = this;
        if (self.props.show !== prevProps.show) {
            if (self.props.show === true) {
                this.initProperties();
            }
        }
        // if (this.props.profile !== prevProps.profile) {
        this.mainSetup();
        // }
    }

    updateContactList(brandId) {
        this.campaign.contacts = [];
        // show Progress
        this.setState({ loadingAgents: true });
        Api.getBrandAgents(brandId, [BRAND_OWNER_STR, SUPER_ADMIN_STR, AGENT_STR])
            .then((contacts) => {
                const contactsList = [];
                const brandOwnerList = [];
                if (Array.isArray(contacts) && contacts.length > 0) {
                    for (const contact of contacts) {
                        const name = `${contact.firstname} ${contact.lastname} (${
                            DisplayRoleNames[contact.role] || contact.role
                        })`;
                        contactsList.push({ value: contact.id, label: name });
                        if ([SUPER_ADMIN_STR, BRAND_OWNER_STR].includes(contact.role)) {
                            brandOwnerList.push({ value: contact.id, label: name });
                        }

                        if (
                            (this.isNewCampaign &&
                                contact.role === SUPER_ADMIN_STR &&
                                this.props.profile.id === contact.id) ||
                            (this.isEditingCampaign &&
                                this.props.campaign.contacts.length > 0 &&
                                this.props.campaign.contacts.find(
                                    (camp_contact) => camp_contact.agent && camp_contact.agent.id === contact.id,
                                ))
                        ) {
                            this.campaign.contacts.push(contact.id);
                        }
                    }
                }
                this.setState({ contactList: contactsList, brandOwnerList, loadingAgents: false }, this.handleChange);
            })
            .catch(() => {
                this.setState({ contactList: [], brandOwnerList: [], loadingAgents: false }, this.handleChange);
            });
    }

    getBrandOptions() {
        const { countryList } = this.state;
        const { brands } = this.props;
        if (!brands || brands.length === 0 || !Array.isArray(brands)) return [];
        const brandSort = brands.sort((a, b) => a.brandName.localeCompare(b.brandName));
        const discoveryItems = ['edit:plan', 'create:plan', 'delete:plan', 'view:plan'];
        return brandSort
            .filter((ab) => !ab.isArchived)
            .map((ab) => {
                let hasEvery = 0;
                discoveryItems.forEach((item) => {
                    if (ab.accessRights?.includes(item)) {
                        hasEvery += 1;
                    }
                });
                const isDiscovery = ab.accessRights?.length === 4 && hasEvery === 4;
                const label = countryList?.find((x) => x.value === ab.countryCode)?.label;
                return {
                    currencyCode: ab.defaultCurrencyCode,
                    value: ab.brandId,
                    brandName: ab.brandName,
                    disabled: isDiscovery,
                    label: (
                        <div className="brandOption">
                            {ab.brandLogo ? (
                                <img src={ab.brandLogo} alt={ab.brandName} />
                            ) : (
                                <div className="brandPlaceholderLogo">
                                    {ab.brandName ? ab.brandName[0].toUpperCase() : ''}
                                </div>
                            )}
                            <span>
                                {ab.brandName} - {label}
                            </span>
                        </div>
                    ),
                };
            });
    }

    mainSetup() {
        const { profile } = this.props;
        if (profile && this.isStartedMainSetup === false) {
            this.isStartedMainSetup = true;
            this.categoryList = [];
            const map = Lookup.getTopicMap(false);
            for (const key in map) {
                if (map.hasOwnProperty(key)) {
                    this.categoryList.push({
                        value: map[key].id,
                        label: map[key].label,
                    });
                }
            }
            this.categoryList.sort((a, b) => a.label.localeCompare(b.label));
            this.state.contactList = [];
            if (this.isEditingCampaign) {
                this.updateContactList(this.campaign.brandId);
            }

            this.setState({
                rerenderCount: this.state.rerenderCount + 1,
            });

            if (this.isEditingCampaign) {
                this.handleChange();
            }
        }
    }

    componentDidMount() {
        this.mainSetup();
        Api.getCurrencyList().then((result) => {
            const { data } = result;
            const currencyList = [];
            data.forEach((x) => {
                const currency = { label: `${x.code} (${x.symbol})`, value: x.code };
                currencyList.push(currency);
            });
            this.setState({ currencyList });
        });
        Api.getBrandCountry().then((result) => {
            const { data } = result;
            const countryList = [];
            data.forEach((x) => {
                const country = { label: x.name, value: x.code, currencyCode: x.currencyCode };
                countryList.push(country);
            });
            this.setState({ countryList });
        });
    }

    changeCurrency = (e) => {
        this.setState({ currency: e }, this.handleChange);
    };

    filterOption = (candidate, input) => {
        return candidate.brandName.toLowerCase().includes(input.toLowerCase());
    };

    render() {
        const { currencyList, currency } = this.state;
        const style = {};
        if (!this.props.show) {
            style.visibility = 'hidden';
            style.opacity = 0;
        } else {
            style.visibility = 'visible';
            style.opacity = 1;
            style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
        }
        const createButtonStyle = {};
        if (!this.state.readyToProceed) {
            createButtonStyle.backgroundColor = '#CECECE';
            createButtonStyle.color = '#7C7C7C';
        }
        return (
            <>
                {this.campaign && (
                    <div className="campaignModal" style={style} onClick={this.handleClick}>
                        <Motion
                            style={{
                                x: spring(this.props.show ? 0 : -200, { stiffness: 200, damping: 18 }),
                                y: spring(1, { stiffness: 200, damping: 30 }),
                            }}
                        >
                            {({ x, y }) => (
                                <div
                                    className="dialog"
                                    ref={(e) => {
                                        this.dialogPanel = e;
                                    }}
                                    style={{
                                        opacity: `${y}`,
                                        WebkitTransform: `translate3d(0,${x}px, 0)`,
                                        transform: `translate3d(0,${x}px, 0)`,
                                    }}
                                >
                                    {this.isNewCampaign ? (
                                        <div className="title">New Campaign</div>
                                    ) : (
                                        <div className="title">Edit Campaign</div>
                                    )}
                                    <div className="inputArea">
                                        <div>
                                            <div className="label">Campaign Name</div>
                                            <input
                                                className="form-input"
                                                value={this.campaign.name}
                                                onChange={this.handleNameChange}
                                            />
                                        </div>
                                        <div className="label">Brand</div>
                                        <Select
                                            className="form-dropdown dropdown brand"
                                            clearable={false}
                                            searchable
                                            disabled={this.isEditingCampaign}
                                            value={this.campaign.brandId}
                                            single
                                            placeholder="Select"
                                            options={this.getBrandOptions()}
                                            onChange={this.handleBrandChange}
                                            filterOption={this.filterOption}
                                        />
                                        <div className="tip">
                                            Can't find your brand?
                                            <Tooltip
                                                tooltipText="Your brand most likely does not have campaign management access. If you’d like to take advantage of our campaign management services, contact us to get started"
                                                image="/images/greenQuestion.svg"
                                            />
                                        </div>
                                        <div className="brandAndProduct">
                                            <div>
                                                <div className="label">Product Name</div>
                                                <MultiSelect
                                                    values={this.campaign.productNames}
                                                    onChange={this.handleProductChange}
                                                    valueLimit={10}
                                                    inputCharLimit={51}
                                                />
                                            </div>
                                            <div>
                                                <div className="label">Product Category</div>
                                                <MultiSelect
                                                    values={this.campaign.categoryNames}
                                                    onChange={this.handleCategoryChange}
                                                    valueLimit={10}
                                                    inputCharLimit={51}
                                                />
                                            </div>
                                        </div>

                                        <div className="label">Contact(s)</div>
                                        <div
                                            className="hideContainer"
                                            id="profilePhotoContainer"
                                            style={{ position: 'relative' }}
                                        >
                                            <PleaseWaitPartial
                                                show={this.state.loadingAgents}
                                                container={document.getElementById('profilePhotoContainer')}
                                            />
                                            <Select
                                                className="form-dropdown dropdown"
                                                clearable={false}
                                                value={this.campaign.contacts}
                                                multi
                                                simpleValue
                                                placeholder="Select Contacts"
                                                options={this.state.contactList}
                                                onChange={this.handleContactChange}
                                            />
                                        </div>

                                        <div className="label">Default Contract Signer</div>
                                        <div>
                                            <Select
                                                className="form-dropdown dropdown"
                                                clearable={false}
                                                value={this.campaign.signer}
                                                single
                                                simpleValue
                                                placeholder="Select Agent"
                                                options={this.state.brandOwnerList}
                                                onChange={this.handleSignerChange}
                                            />
                                        </div>
                                        <div>
                                            <div className="label budget">Campaign Budget</div>
                                            <div className="currencyWrapper">
                                                <Select
                                                    className="form-dropdown dropdown currency"
                                                    clearable={false}
                                                    value={currency}
                                                    single
                                                    simpleValue
                                                    placeholder="Select currency"
                                                    options={currencyList}
                                                    onChange={this.changeCurrency}
                                                    searchable={false}
                                                />
                                                <input
                                                    className="form-input budget"
                                                    value={this.budgetInput}
                                                    onChange={this.handleBudgetChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="footer">
                                        <div className="cancelButton" onClick={this.cancelChanges}>
                                            CANCEL
                                        </div>
                                        <div
                                            className="submitButton"
                                            style={createButtonStyle}
                                            onClick={this.proceedWithCreation}
                                        >
                                            {this.isNewCampaign ? 'CREATE' : 'UPDATE'}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Motion>
                    </div>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        brands: state.agent.brands,
        profile: state.global.loggedInUserProfile,
    };
};

export default connect(mapStateToProps, null)(CreateCampaign);
