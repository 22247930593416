import React, { Component } from 'react'
import cx from 'classnames';
import './AssignmentCompose.scss'
import Select from 'react-select';
import { connect } from "react-redux";
import DraftComposer from "../../shared/draftComposer/draftComposer";
import Api from "../../../modules/Api"
import Lookup from "../../../modules/Lookup"
import PleaseWait from '../../shared/pleaseWait/PleaseWaitWhite'
import ApiError from '../../shared/apiError/ApiError';
import { storeUserProfile } from '../../../store/global/globalActions';
import AssignmentDetails from './AssignmentADetails';
import UserDraftPostResources from "../../campaign/draftComment/UserDraftPostResources";
import ProfileHelper from '../../../modules/utils/ProfileHelper';
import { TALENT_MANAGER_STR, INFLUENCER_STR, VIEWER_STR } from '../../../constants/authorities';

class AssignmentCompose extends Component {
    constructor(props){
        super(props);
        this.state={
            assignment: '',
            currentView: 1,
            editorData: '',
            profile: null,
            resources: [],
            showDownloading: false,
            type: null,
            onUploadCancel: null,
            waiting: false,
        }

        this.showApiError = this.showApiError.bind(this);
        this.removeVideo = this.removeVideo.bind(this);
        this.addVideo = this.addVideo.bind(this);
        this.downloadVideo = this.downloadVideo.bind(this);
        this.assignmentId = this.props.match.params.uid;
        const params = new URLSearchParams(this.props.location.search);
        this.influencerId = params.get('influencerId');
        this.isTalentAgent = false;
        this.influencerUid = "";
    }

    componentDidMount() {
        ProfileHelper.getCurrentLoginUserProfile().then((profile) => {
            this.props.storeProfile(profile);
            this.influencerUid = profile.uid;
            this.setState({ profile });

            if (this.influencerId) {
                // Talent agent, already populated from params
                this.isTalentAgent = true;
            } else {
                // Influencer is logged in, this.influencerId is not coming from params, get influencer id from profile object
                this.influencerId = profile.id;
            }

            if (profile && profile.role === TALENT_MANAGER_STR) {
                Api.getTMAssignment(this.assignmentId, this.influencerId)
                    .then(
                        res=>{
                            this.influencerUid = res.user.uid;
                            this.setState({assignment: res.assignment, waiting: false})
                        },
                        err=>{this.setState({showApiError : true, apiError : err, waiting: false
                        })})
            } else {
                Api.getAssignmentUser(this.assignmentId, profile.uid).then(
                    (res) => {
                        this.setState({
                            assignment: res.assignment,
                            waiting: false
                        })
                    },
                    (error) => {
                        this.setState({
                            showApiError : true,
                            apiError : error,
                            waiting: false
                        })
                    }
                );
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const { assignment } = this.state;
        if ((!prevState.assignment && assignment) || (prevState.assignment && assignment && prevState.assignment.id !== assignment.id)) {
            this.setState({type: this.props.match.params.type});
        }
    }

    submitDraft = async (status) => {
        const {
            editorData: article,
            resources,
            type,
            assignment
        } = this.state;
        const postType = type || assignment.types[0];
        const resourcesList = resources.map(a => a.resourceId);
        // const isChoreIdPresent = this.props.match.params.choreId && this.props.match.params.choreId != "null";
        const payload = {
            url: '',
            type: postType,
            title: `[${this.state.assignment.name}] [${Lookup.getRateType(postType).label}] Draft`,
            itemType: "POST",
            status,
            content: article,
            resources: resourcesList,
            // assignmentChoreId: isChoreIdPresent ? this.props.match.params.choreId : ""
        }
        let redirectUrl = `/assignments/${this.assignmentId}/draftList`;

        if (this.influencerId && this.isTalentAgent) {
            redirectUrl = `${redirectUrl}?influencerId=${this.influencerId}`;
        }

        if ((postType !== 'BLOG_POST' || this.state.title !== '') && this.influencerId) {
            try {
                const postCreateResponse = await Api.createDraftPost(this.assignmentId, payload, this.influencerUid);
                await Promise.all(resources.map(r => Api.userDraftPostResourceAction(postCreateResponse.id, r.resource.id, "create")));
                this.props.history.push(redirectUrl);
            } catch (error) {
                let errorFinal = error.message;
                let apiErrTitle;
                if (error.originalMessage && error.originalMessage.startsWith("Insufficient privileges")) {
                    apiErrTitle = "Permission Denied";
                    errorFinal = "You do not have access to perform this, please contact your creator."
                }
                this.setState({
                    showApiError: true,
                    apiErrTitle,
                    apiError: errorFinal,
                    waiting: false,
                });
            }
        }
    }

    showApiError(error) {
        this.setState({
            showApiError: true,
            apiError: error,
        });
    }

    saveEditorData = editorData => {
        this.setState({ editorData });
    }

    handleVideosUpload = async ({ target: { files } }) => {
        if (files.length > 0) {
            for (const file of files) {
                try {
                    // if (file.size > 5368709120) {
                    //     this.setState({
                    //         showApiError: true,
                    //         apiErrTitle: 'The file is too large',
                    //         apiError: 'Allowed maximum size is 5GB. Please upload a smaller file',
                    //     });
                    // } else {
                    const fileDetails = {};
                    if (file && file.name) {
                        fileDetails.name = file.name;
                        fileDetails.type = file.type;
                        fileDetails.uploadedPercentage = 1;
                    }
                    fileDetails.tempResourceId = new Date().toString() + fileDetails.name;
                    // fileDetails.url = response.url;

                    this.setState((prevState) => {
                        // enable download progress UI
                        return {
                            ...prevState,
                            resources: [...prevState.resources, fileDetails],
                            showUploading: true
                        }
                    }, () => {
                        this.uploadFileGradually(file, fileDetails.tempResourceId, false);
                    });
                    // }
                } catch (error) {
                    console.log(error);
                }
            }
        }
    };

    uploadFileGradually(file, tempResourceId, publicRead) {
        const updateFileProgress = (progressPercentage) => {
            const resourceList = this.state.resources;
            if (progressPercentage > 0) {
                const resIndex = resourceList.findIndex(item => item.tempResourceId == tempResourceId);
                if (resIndex > -1 && resourceList[resIndex]) {
                    resourceList[resIndex].uploadedPercentage = progressPercentage;
                    resourceList[resIndex].isComplete = false;
                    this.setState({
                        resources: resourceList
                    });
                }
            }
        }

        const updateFileFinished = (response) => {
            if (response) {
                const resourceList = this.state.resources;
                const resIndex = resourceList.findIndex(item => item.tempResourceId == tempResourceId);
                if (resIndex > -1 && resourceList[resIndex]) {
                    resourceList[resIndex].uploadedPercentage = 100;
                    resourceList[resIndex].isComplete = true;
                    resourceList[resIndex].resourceId = response.id;
                    resourceList[resIndex].url = response.url;
                    this.setState((prevState) => {
                        return {
                            ...prevState,
                            resources: resourceList
                        }
                    });
                }
            }
        }


        const updateOnError = (response) => {
            if (response && response.message) {
                this.setState({ showApiError: true, apiError: response.message });
            }
        }

        this.setState({
            uploadedPercentage: 1
        })

        Api.postS3FileGradually(file,
            (progressPercentage) => updateFileProgress(progressPercentage),
            publicRead,
            (fn) => this.setState({onUploadCancel: fn})
        ).then((response) => {
            updateFileFinished(response);
            this.setState({ onUploadCancel: null });
        }).catch((err) => {
            updateOnError(err);
            this.setState({ onUploadCancel: null });
        })
    }

    removeVideo = (resourceId) => {
        if (this.state.onUploadCancel)
            this.state.onUploadCancel();

        const newResources = this.state.resources.filter(r => r.resource.id != resourceId);
        this.setState({resources: newResources});
    }

    addVideo = (resource) => {
        const newResources = [...this.state.resources];
        const userName = this.state.profile ? (`${this.state.profile.firstname  } ${  this.state.profile.lastname}`) : "";
        newResources.push({resource, status: "ACTIVE", creator: userName});
        this.setState({resources: newResources});
    }

    downloadVideo = (resourceId) => {
        const resourceList = this.state.resources
        const resIndex = resourceList.findIndex(item => item.resource.id == resourceId);
        if (this.state.showDownloading == false) {
            // Api.getS3File(url, name); // download without progress bar
            this.setState({
                // enable download progress UI
                showDownloading: true
            }, () => {
                this.downloadFileListGradually(resIndex, resIndex + 1);
            });
        }
    }

    downloadFileListGradually(fileCountStart, fileCountEnd) {
        let currentFileCount = fileCountStart;

        const isCancelFileDownload = () => {
            return this.state.showDownloading == false;
        }

        const updateFileProgress = (fileNumber, progressPercentage) => {
            if (isCancelFileDownload()) {
                return true;
            }
            if (progressPercentage > 0) {
                const downloadList = this.state.resources;
                downloadList[fileNumber].percentage = progressPercentage;
                downloadList[fileNumber].isComplete = false;
                this.setState({
                    resources: downloadList,
                    showDownloading: true,
                });
            }

        }

        const updateFileFinished = (fileNumber, response) => {
            const downloadList = this.state.resources;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', downloadList[fileNumber].resource.name);
            document.body.appendChild(link);
            link.click();

            downloadList[fileNumber].percentage = 100;
            downloadList[fileNumber].isComplete = true;
            this.setState({
                resources: downloadList,
                showDownloading: true,
            });

            currentFileCount++
            downloadFile(currentFileCount);
        }

        const downloadFile = (currentFileCount) => {
            const downloadList = this.state.resources;
            if (downloadList.length > 0 && currentFileCount > -1 && downloadList.length > currentFileCount && fileCountEnd > currentFileCount) {
                // update to show that new file is downloading in progress
                updateFileProgress(currentFileCount, 1);
                // Call gradual download
                Api.getS3FileGradually(downloadList[currentFileCount].resource.url,
                    (progressPercentage) => updateFileProgress(currentFileCount, progressPercentage),
                    (response) => updateFileFinished(currentFileCount, response));
            } else {
                this.setState({
                    showDownloading: false
                })
            }
        }

        downloadFile(currentFileCount);
    }

    render() {
        const {
            assignment,
            currentView,
            title,
            profile,
            resources,
            type
        } = this.state;
        const resourcesCount = resources.length;
        const currentContent = this.state.editorData;
        const haveImage = false;
        const isContentEmpty = !currentContent.length; // && !haveImage;
        const actionDisabled = isContentEmpty;
        const addExtraTop = profile && (profile.role === TALENT_MANAGER_STR || profile.role === INFLUENCER_STR) && !profile.accountVerified;

        if (!type)
            return null;

        return(
            <div className="assignmentCompose">
                <PleaseWait show={this.state.waiting}/>
                <ApiError show={this.state.showApiError} error={this.state.apiError}
                    errTitle={this.state.apiErrTitle}
                    cancelFunction={() => {
                        this.setState({ showApiError: false, waiting: false, apiErrTitle:'' });
                    }}
                />
                <div className={cx("listHeaderContainer", {extraHeader: addExtraTop})}>
                    <div className="listHeader" >
                        <div className="contentDiv">
                            <div className="goBack">
                                {assignment.campaign&&assignment.campaign.name}: <span>{assignment.name&&assignment.name}</span>
                            </div>
                            <div className="actionDropDown">
                                <Select className="select" clearable={false} value={currentView} placeholder="" searchable={false}
                                    options={[
                                        { value: 1, label: 'My Post' },
                                        { value: 2, label: 'Assignment Details' }
                                    ]} onChange = {(selected)=>{this.setState({currentView:selected.value })}}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={cx("contentContainer", {extraContentTop: addExtraTop})}>
                    <div className="headerTitle">
                        <div className="composerHeader composerHeaderNew">
                            <div className="title">
                                <div>
                                    <img
                                        src="/images/ic-back-b.svg"
                                        onClick={() =>
                                            this.props.history.push({
                                                pathname: `/assignments/${  this.assignmentId  }/draftList`,
                                                search: this.isTalentAgent ? `?influencerId=${this.influencerId}` : '',
                                            })
                                        }
                                    />
                                </div>
                                <div>
                                    <span>{Lookup.getRateType(type).label}{Lookup.getRateType(type).label.indexOf(' Post') < 0  && ' Post'}</span>
                                    <span className="breaker">&nbsp;|&nbsp;</span>
                                    <span className="draft">Draft</span>
                                </div>
                            </div>
                            <div className="buttons">
                                <button
                                    className="composerButton save"
                                    onClick={() => this.submitDraft('NEW')}
                                    disabled={actionDisabled}
                                >
                                    Save Draft
                                </button>
                                <button
                                    className={cx("composerButton submit", {disabled: actionDisabled})}
                                    onClick={() => this.submitDraft('NEEDS_REVIEW')}
                                    disabled={actionDisabled}
                                >
                                    Submit to Brand
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="contentDiv">
                        {currentView === 1 &&
                        <div className="leftSection">
                            <div className="composerContainer">
                                <div className="assignment-compose-media-container" style={{marginLeft: "35px"}}>
                                    <UserDraftPostResources resourceList={resources}
                                        refreshResources={() => {}}
                                        showApiError={this.showApiError}
                                        showVideos={(resource, index) => this.downloadVideo(resource.resource.id)}
                                        onRemoveVideo={this.removeVideo}
                                        onAddVideo={this.addVideo}
                                        thumbnailOverlayAdditionalStyles={{top: "85px"}}
                                        withDelete
                                        isBrandViewer={profile && profile.role === VIEWER_STR}
                                    />
                                </div>
                                {isContentEmpty && <div className="inputNotice"><img alt="" src="/images/ic-small-warning.svg" />Please input {Lookup.getRateType(type).label} Description</div>}
                                {
                                    <div className="inputNotice">
                                        <img src="/images/ic-small-warning.svg" alt=""/>
                                        Track Changes and Comment features are not available in new posts.
                                    </div>
                                }
                                <div className="composerEditContainer composerEditContainerInfo">
                                    <DraftComposer
                                        title={title}
                                        onTitleChange={(e) => this.setState({ title: e.target.value })}
                                        onChange={this.onChange}
                                        assignmentId={this.assignmentId}
                                        saveEditorData={this.saveEditorData}
                                        user={profile}
                                        assignment={assignment}
                                    />
                                </div>
                            </div>
                        </div>
                        }

                        {currentView === 2 &&
                        <div className="leftSection">
                            <AssignmentDetails location={this.props.location} assignment={assignment} uid={this.uid} profile={profile} />
                        </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        storeProfile: (profile) => {
            dispatch(storeUserProfile(profile))
        }
    }
};

export default connect(null, mapDispatchToProps)(AssignmentCompose)
