/* eslint-disable dot-notation */
import React from 'react'
import Select from "react-select";
import cx from 'classnames';

import Format from '../../../modules/utils/Format';
import Api from '../../../modules/Api'
import InfluencerInput from '../../shared/influencerInput/InfluencerInput';
import LocationInput from '../../shared/locationInput/LocationInput';
import Checkbox from '../../shared/checkbox/Checbox';
import '../onBoard.scss';
import PdfViewer from '../../shared/pdfViewer/PdfViewer';
import ProfilePhoto from './ProfilePhoto';
import { states, provinces } from '../../../constants/country';

// props :
// - profile
// - changeFunction
// - continueFunction
// - address (with formattedAddress)



export default class ProfileStep extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.fieldChange = this.handleFieldChange.bind(this);
        this.handleLocationChange = this.handleLocationChange.bind(this);
        this.handleTermsAndConditionsChange = this.handleTermsAndConditionsChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.fromProfileToState = this.fromProfileToState.bind(this);
        this.fromStateToProfile = this.fromStateToProfile.bind(this);
        this.state = {
            isAcceptedTermsAndConditions: false,
            isContractOpen: false,
            photoVersion: 1,
            provinces: null,
            shippingCountry: props.profile?.shippingCountry?.toUpperCase() || 'US',
            countryList: [],
            loading: false,
            clearState: 1,
            stateList: []
        }

        this.emptyPic = "/images/ic-camera-green.svg";

        this.plainFields = [
            {
                key: 'firstname',
                label: 'First Name',
                required: true,
                validate (txt) {
                    if (!Format.validateFirstName(txt)) {
                        return 'Please, enter a valid first name'
                    }
                }
            },
            {
                key: 'lastname',
                label: 'Last Name',
                required: true,
                validate (txt) {
                    if (!Format.validateFirstName(txt)) {
                        return 'Please, enter a valid last name'
                    }
                }
            }
        ];

        this.shippingFields = [
            {
                key: 'shippingStreet',
                label: 'Street Address',
                required: true,
                validate: null
            },
            {
                key: 'shippingApartment',
                label: '',
                required: false,
                validate: null
            },
            {
                key: 'shippingCity',
                label: 'City',
                required: true,
                validate: null
            },
            {
                key: 'shippingState',
                label: 'State',
                required: true,
                validate: null
            },
            {
                key: 'shippingZip',
                label: 'ZIP Code',
                required: true,
                validate: null
            },
            {
                key: 'shippingCountry',
                label: 'country',
                required: true,
                validate: null
            }
        ];
        this.stateOptions = [];
    }

    updateStateOptions = () => {
        const { currentInfo } = this.state
        this.setState({loading: true});
        this.stateOptions.splice(0);
        if (currentInfo) {
            Api.getRegions(currentInfo?.id).then(response => {
                const { data } = response
                data.regions.forEach(x => {
                    this.stateOptions.push({label: x.abbreviation, value: x.abbreviation})
                })
                this.setState({loading: false}, this.handleChange);
            })
        }
    }

    openContract() {
        this.setState({ isContractOpen: true });
    }

    closeContractView() {
        this.setState({ isContractOpen: false });
    }

    handleLocationChange(address) {
        if (this.props.address.formattedAddress === address.formattedAddress) {
            // not an actual change
            return;
        }
        const newState = {};
        for (const field of LocationInput.getAddressProperties()) {
            newState[field] = address[field];
        }
        this.props.address.formattedAddress = this.state.formattedAddress;
        this.setState(newState, this.handleChange);
    }

    handleTermsAndConditionsChange() {
        const newAccepted = !this.state.isAcceptedTermsAndConditions;
        this.setState({
            isAcceptedTermsAndConditions: newAccepted
        }, this.handleChange)
        this.props.changeTermsAndConditionsState(newAccepted);
    }

    validateFields() {
        const { isAcceptedTermsAndConditions } = this.state;
        const { setProfileStepValidStatus } = this.props;
        const isEmptyString = (value) => {
            if (typeof value !== 'string') {
                return true;
            }

            return value.length === 0;
        }
        const isShippingValid = this.isShippingAddressValid();
        let isPlainFieldsValid = true;
        this.plainFields.forEach(field => {
            if (field.required) {
                const value = this.state[field.key];
                const validateFunc = typeof field.validate === 'function' ? field.validate : isEmptyString;
                if (validateFunc(value)) {
                    isPlainFieldsValid = false;
                }
            }
        })
        setProfileStepValidStatus(isShippingValid && isPlainFieldsValid && isAcceptedTermsAndConditions);
    }

    isShippingAddressValid () {
        const { currentInfo } = this.state;
        let isValid = true;
        this.shippingFields.forEach(field => {
            const value = this.state[field.key] || null;
            if (!value || value === "") {
                if (field.required) {
                    if ((field.key === 'shippingState' && currentInfo?.regionRequired) || (field.key==="shippingZip" && currentInfo?.zipRequired)) {
                        isValid = false;
                    }
                    if (field.key !== 'shippingState' && field.key !== 'shippingZip') {
                        isValid = false;
                    }
                }
            }
        })

        return isValid;
    }

    handleChange() {
        const f = this.props.continueFunction;
        this.props.profile.shippingCountry = this.state.shippingCountry;
        if (typeof f === 'function') {
            this.fromStateToProfile();
            this.validateFields();
            f(this.props.profile, true);
        }
    }

    handleFieldChange(field, newValue) {
        const newState = {};
        newState[field] = newValue;
        newState.photoVersion = this.state.photoVersion + 1;
        this.setState(newState, this.handleChange);
    }

    handleUrlChange = (pictureUrl) => {
        this.props.profile.pictureUrl = pictureUrl;
        this.handleFieldChange('pictureUrl', pictureUrl)
    }

    fromStateToProfile() {
        for (const field of this.plainFields) {
            this.props.profile[field.key] = this.state[field.key];
        }
        for (const field of this.shippingFields) {
            this.props.profile[field.key] = this.state[field.key];
        }
        this.props.profile.pictureUrl = this.state.pictureUrl;
        for (const field of LocationInput.getAddressProperties()) {
            this.props.profile[field] = this.state[field]
        }
    }

    fromProfileToState() {
        const newState = { stateFilled: true }
        for (const field of this.plainFields) {
            newState[field.key] = this.props.profile[field.key];
        }
        for (const field of this.shippingFields) {
            if (field.key === "shippingCountry") {
                newState[field.key] = this.props.profile[field.key]?.toUpperCase();
            } else {
                newState[field.key] = this.props.profile[field.key];
            }
        }
        for (const field of LocationInput.getAddressProperties()) {
            newState[field] = this.props.profile[field];
        }
        newState.paymentEmail = this.props.profile.email;
        newState.pictureUrl = this.props.profile.pictureUrl;
        if (!newState.shippingCountry) {
            newState.shippingCountry = "US"
        }
        if (newState.shippingCountry === "CANADA" || newState.shippingCountry === "USA") {
            newState.shippingCountry = newState.shippingCountry === "USA" ? "US" : "CA"
        }
        this.setState(newState);
    }

    componentDidMount() {
        this.fromProfileToState();
        Api.getCountries().then(response => {
            const { data } = response
            const newList = []
            let currentInfo = null;
            data.forEach(x => {
                const newItem = {
                    label: x.name,
                    value: x.code,
                    regionRequired: x.addressFormat.isRegionRequired,
                    zipRequired: !x.isPostalCodeOptional && x.addressFormat.isPostalCodeRequired,
                    cityLabel: x.cityLabel,
                    regionLabel: x.regionLabel,
                    postalCodeLabel: x.postalCodeLabel,
                    id: x.id
                }
                newList.push(newItem);
                if(x.code === this.state.shippingCountry) {
                    currentInfo = newItem
                }
            })
            this.setState({countryList: newList, currentInfo}, this.updateStateOptions);
        })
    }

    handleStateSelection = (selected) => {
        this.setState({
            shippingState: selected.value,
            shippingUnchanged: false
        }, this.handleChange);
    }

    handleCountrySelection = (selected) => {
        if (selected.value !== this.state.shippingCountry) {
            const { setProfileStepValidStatus } = this.props;
            const { clearState } = this.state;
            setProfileStepValidStatus(false, true)
            this.setState({
                shippingCountry: selected.value,
                shippingState: '',
                shippingZip: '',
                shippingStreet: '',
                shippingCity: '',
                shippingApartment: '',
                clearState: clearState + 1,
                currentInfo: selected
            }, function() {
                this.updateStateOptions();
            });
        }
    }

    handleAddressChange = e => {
        const { photoVersion } = this.state;
        if (e.country) {
            const newState = {};
            let {city} = e;
            if (this.stateOptions.length === 0) {
                city = e.city.length !== 0 ? e.city : e.state;
            }
            newState.shippingCity = city;
            const stateItem = this.stateOptions.find(x => x.value === e.state)
            newState.shippingState = stateItem?.value;
            newState.shippingZip =  e.zipCode.replace(/ /g,'');
            newState.shippingStreet = e.addressOne.trim();
            newState.photoVersion = photoVersion + 1;
            this.setState(newState, this.handleChange);
        }
    }

    handleChangeWithoutSelect = e => {
        this.setState({shippingStreet: e.target.value.trim()}, this.handleChange)
    }

    renderShipping() {
        const { shippingCountry, countryList, loading, clearState, currentInfo, shippingZip } = this.state;
        const { scrollContainer } = this.props;
        const Regionlabel = currentInfo?.regionLabel
        const isHidden = !loading && this.stateOptions.length === 0;
        return (
            <div className="shipping">
                <div className="shipping-title">Shipping address</div>
                <div className="shipping__state-select country">
                    <Select
                        className="select select-country"
                        clearable={false}
                        label="label"
                        value={this.state.shippingCountry}
                        placeholder="Country"
                        options={countryList}
                        onChange={this.handleCountrySelection}
                        searchable
                        autocomplete="country-name"
                    />
                </div>
                <div className="shipping__top">
                    <LocationInput original={ {formattedAddress: this.state.shippingStreet}} changeFunction={this.handleAddressChange} useAddress 
                        country={shippingCountry} placeholder="Street Address" fancy clearState={clearState} scrollContainer={scrollContainer}
                        handleChangeWithoutSelect={this.handleChangeWithoutSelect}/>
                </div>
                <InfluencerInput
                    label="Apartment (optional)"
                    placeholder="Apartment (optional)"
                    valueChangeFunction={(value) => this.handleFieldChange('shippingApartment', value)}
                    key="shippingApartment"
                    initialValue={this.state['shippingApartment']}
                    validateFunction={null}
                    autocomplete="address-line2"
                    updatedValue={this.state['shippingApartment']}
                    clearState={clearState}
                />
                <div className="shipping__bottom">
                    <div>
                        <InfluencerInput
                            label="City"
                            placeholder="City"
                            valueChangeFunction={(value) => {
                                this.handleFieldChange('shippingCity', value);
                            }}
                            key="shippingCity"
                            initialValue={this.state['shippingCity']}
                            validateFunction={null}
                            autocomplete="address-level2"
                            updatedValue={this.state['shippingCity']}
                            clearState={clearState}
                        />
                    </div>
                    <div className={cx("shipping__zipCode", "stretch")}>
                        <InfluencerInput
                            label={currentInfo?.postalCodeLabel || (currentInfo?.zipRequired ? 'ZIP code' : 'ZIP code (optional)')}
                            placeholder={currentInfo?.postalCodeLabel || (currentInfo?.zipRequired ? 'ZIP code' : 'ZIP code (optional)')}
                            valueChangeFunction={(value) => {
                                this.handleFieldChange('shippingZip', value);
                            }}
                            key="shippingZip"
                            initialValue={shippingZip}
                            validateFunction={null}
                            autocomplete="postal-code"
                            updatedValue={shippingZip}
                            clearState={clearState}
                        />
                    </div>
                </div>
                {!isHidden && 
                <div className="shipping__bottom"><div className="region"><div className="shipping__state-select">
                    <Select
                        className="select select-borderless"
                        clearable={false}
                        label="label"
                        value={this.state['shippingState']}
                        placeholder={Regionlabel}
                        options={this.stateOptions}
                        onChange={this.handleStateSelection}
                        searchable
                        autocomplete="address-level1"
                    />
                </div></div></div>}
            </div>
        )
    }

    termsAndConditions() {
        return <div className="term-and-conditions">
            <div className="term-conditions-check">
                <div className="termCheckBox">
                    <Checkbox type="checkbox" checked={this.state.isAcceptedTermsAndConditions}
                        changeFunction={() => this.handleTermsAndConditionsChange()} />
                </div>
                <div className="term-text-container">
                    <div className="term-text-prefix">I agree to the <span className="term-text-suffix-link" onClick={() => this.openContract()}>Terms and Conditions</span></div>
                </div>
            </div>
        </div>
    }

    render() {
        if (!this.state.stateFilled) {
            return null;
        }

        const plainFields = [];
        for (const field of this.plainFields) {
            let {label} = field;
            let autocomplete = "off";
            if (field.key === 'lastname') {
                label = 'Last Name';
                autocomplete = "family-name"
            } else if (field.key === 'firstname') {
                autocomplete = "given-name"
            }

            plainFields.push(
                <InfluencerInput
                    required={field.required}
                    label={label}
                    placeholder={field.placeholder}
                    valueChangeFunction={function (value) {
                        self.handleFieldChange(field.key, value);
                    }}
                    key={field.key}
                    initialValue={this.state[field.key]}
                    validateFunction={field.validate}
                    autocomplete={autocomplete}
                />)
        }


        let self = this;

        return (
            <div className="profileStep" style={{position:'relative',marginBottom:100}}>
                {this.state.isContractOpen &&
                    <PdfViewer pdfUrl="/images/docs/PlatformPrivacyAgreement.pdf" onClose={() => this.closeContractView()} />
                }
                <div className="stepTitle">
                    Complete Your Profile
                </div>
                <div className="stepDescription">
                    Confirm your profile information
                </div>
                <div className="fields">
                    <ProfilePhoto
                        profile={this.props.profile}
                        photoVersion={`${  this.props.photoVersion  }.${  this.state.photoVersion}`}
                        changeUrlFunction={this.handleUrlChange}
                    />

                    {plainFields}

                    {this.renderShipping()}

                </div>

                {this.termsAndConditions()}
            </div>
        );
    }
}
