import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import SavedSearches from '../influencers2/savedSearches/savedSearches';
import './creatorsTab.scss';
import ImportFromBase from './ImportFromBase';
import SavedSearchDropDown from './SavedSearchDropdown';

const SavedSearchesTab = ({
    plansData,
}) => {
    const [showSavedSearch, setShowSavedSearch] = useState(false);

    const handleAddSavedSearch = () => {
        setShowSavedSearch(true);
    }

    const onImport = (data) => {
        const listIds = data.map(d => d.id);
        // setIsLoading(true);
        // Api.addUsersPlanByExclusion(plansData.id, { listIds })
        //     .then(res => {
        //         setIsLoading(false);
        //         setShowExclusion(false);
        //         onChange();
        //         setSuccess('Success! Added creators to plan');
        //     })
        //     .catch(err => {
        //         setIsLoading(false);
        //         handleApiError(err);
        //     })
    };

    return (
        <div className='creatorsTabContainer'>
            <div className='saveSearchHeader'>
                <div className='headerTitle'>Saved searches</div>
                <div className='addSavedSearchButton' role='button' onClick={handleAddSavedSearch}>
                    <img src="/images/ic-add-w.svg" alt="plus icon" />
                    Saved search
                    {showSavedSearch && <div className='importContainer'>
                        <ImportFromBase
                            headerLabel='Saved search'
                            onCancel={() => setShowSavedSearch(false)}
                            onImport={onImport}
                            height={48}
                            Dropdown={SavedSearchDropDown}
                        />
                    </div>}
                </div>
            </div>
            <SavedSearches planDetails={plansData} />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {

    }
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SavedSearchesTab));