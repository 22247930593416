export const getNetworkByChannel = (channel) => {
    if (
        channel === 'INSTAGRAM_POST' ||
        channel === 'INSTAGRAM_STORY' ||
        channel === 'instagramPost' ||
        channel === 'instagramStory'
    ) {
        return 'instagram';
    }
    if (channel.indexOf('SocialShare')>-1){
        return channel.replace('SocialShare','')
    }
    return channel;
};

export const getNetworkNameByChannel = (channel) => {
    if (channel === 'INSTAGRAM_POST' || channel === 'instagramPost') {
        return 'IG Post';
    } if (channel === 'INSTAGRAM_STORY' || channel === 'instagramStory') {
        return 'IG Story';
    } if (channel === 'YOUTUBE' || channel === 'youtube') {
        return 'YouTube';
    } if (channel.indexOf('SocialShare')>-1) {
        return channel.replace('SocialShare',' Social Share');
    } if (channel) {
        return channel.toLowerCase();
    }
    return channel;
};

export const getBrandedEngagements = (analysisData, summaryData) => {
    const saveShares = summaryData?.totalShareCount + summaryData?.totalSaveCount;
    const brandedComments = analysisData?.brandedCommentCount ? Object.values(analysisData?.brandedCommentCount).reduce((a, b) => a + b, saveShares ? saveShares : 0) : 0;
    return brandedComments;
}
