import { call, put, select } from 'redux-saga/effects';
import { allStatusFilter } from './reducer';
import { setStatusSelectData } from './actionCreators';
import { getNextSelectedOptions } from '../helpers';

export default function* onStatusSelect({ selectValue, updateSaga }) {
    const { statusSelectOptions } = yield select(({ selects }) => ({
        statusSelectOptions: selects.status.selectOptions,
    }));

    const nextSelectedStatuses = getNextSelectedOptions(selectValue, statusSelectOptions, allStatusFilter);

    yield put(setStatusSelectData({
        selectedOptions: nextSelectedStatuses,
    }));

    // trigger specific data update if needed
    if (typeof updateSaga === 'function') {
        yield call(updateSaga);
    }
}
