import React, { useState, useEffect, useRef, useLayoutEffect, useCallback } from 'react';
import './pageDropDown.scss';
import cx from 'classnames';
import PleaseWaitPartial from '../shared/pleaseWait/PleaseWaitPartial'


const PageDropDown = ({
    placeHolder = '',
    options = [],
    height,
    onChange = () => null,
    disabled = false,
    onLoadMore = () => null,
    loading = false,
    selected,
    multi,
    searchable,
    onSearch = () => null
}) => {
    const [showMenu, setShowMenu] = useState(false);
    const [selectedValue, setSelectedValue] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const menuContainerRef = useRef();

    // useEffect(() => {
    //     // const handler = () => setShowMenu(false);
    //     window.addEventListener('click', handler);
    //     return () => {
    //         window.removeEventListener('click', handler);
    //     }
    // }, []);

    useLayoutEffect(() => {
        if (showMenu) {
            menuContainerRef.current && menuContainerRef.current.addEventListener('scroll', handleScroll);
            setTimeout(() => {
                window.addEventListener('click', handler);
            }, 100);
        }else {
            menuContainerRef.current && menuContainerRef.current.removeEventListener('scroll', handleScroll);
            window.removeEventListener('click', handler);
        }
    }, [showMenu, menuContainerRef]);

    const handleScroll = () => {
        if (!loading
            && menuContainerRef.current
            && (menuContainerRef.current.scrollTop + menuContainerRef.current.offsetHeight >= menuContainerRef.current.scrollHeight)) {
            console.log('scroll to end')
            onLoadMore();
        }
    }

    const handler = useCallback(() => {
        setShowMenu(false);
    }, []);

    const onDelete = (e, index) => {
        e.stopPropagation();
        const update = [...selectedValue];
        update.splice(index, 1);
        setSelectedValue(update);
        onChange(update);
    }

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
        onSearch(e.target.value);
        if (!showMenu) {
            setShowMenu(true);
        }
    };

    const handleInputClick = () => {
        !disabled && setShowMenu(!showMenu);
    };

    const getDisplay = () => {
        const value = inputValue.length > 0 ? inputValue
            : (multi ? '' : selectedValue.length > 0 ? selectedValue[0].label : '');
        const placeholder = value === '' && selectedValue.length === 0 ? placeHolder : '';
        return (
            <div className='valueList' onClick={handleInputClick}>
                {multi && selectedValue.map((v, i) => <div key={`${v.value}`} className='valueContainer'>
                    {v.label}
                    <img src="/images/ic-close-d.svg" alt={`${v.value}`} onClick={(e) => onDelete(e, i)} />
                </div>)}
                <input
                    placeholder={placeholder}
                    autoFocus
                    maxLength={255}
                    value={value}
                    disabled={!searchable}
                />
            </div>
        )
    };

    const onItemClick = (option) => {
        if (multi) {
            const update = [...selectedValue];
            update.push(option);
            setSelectedValue(update);
            onChange(update);
        } else {
            setSelectedValue([option]);
            onChange(option);
        }
        // setInputValue('');
        setShowMenu(false);
    };

    return (
        <div onClick={handleInputClick} className={cx('pageDropDownContainer', { disabled: disabled }, { active: showMenu })} style={height ? { height: height } : {}}>
            <div className='pageDropDownInput'>
                <div className='pageDropDownValue'>{getDisplay()}</div>
                <img src='/images/arrow-down.svg' className={cx({ up: showMenu })} />
            </div>
            {showMenu && (
                <div
                    className='pageDropDownMenu'
                    id='pageDropDownMenu'
                    // style={height ? { top: height } : {}}
                    ref={menuContainerRef}
                >
                    <PleaseWaitPartial
                        show={loading}
                        container={menuContainerRef.current}
                        customStyles={{ height: 'none' }}
                    />
                    {options.map((option) => {
                        const disabled = selected ? selected.some(s => s.value === option.value)
                            : selectedValue.some(s => s.value === option.value);
                        return <div
                            onClick={() => disabled ? null : onItemClick(option)}
                            key={option.value}
                            className={cx('pageDropDownItem', { disabled })}>
                            <span>{option.label}</span>
                        </div>
                    })}
                    {options.length === 0 && inputValue.length > 0 && <div
                        className='pageDropDownItem'>
                        <span>No Results</span>
                    </div>}
                </div>
            )}
        </div>
    )

}

export default PageDropDown;