import React from 'react';
import './FollowersChart.scss';
import { AreaChart, Area, XAxis, YAxis, Tooltip } from 'recharts';

const FollowersChart = ({totalFollowers, dateStatistics}) => {
    const followersStatisticsData = dateStatistics.map((pointInTime) => {
        const date = new Date(pointInTime.timestamp);
        return {
            followers: pointInTime.followers,
            month: date.toLocaleString('default', { month: 'short' }),
        };
    });
    const allData = dateStatistics.map(data => data.followers)
    const minimum = Math.min(...allData);
    const maximum = Math.max(...allData);
    const delta = Math.abs(maximum - minimum)
    const increment = delta/10
    const ymax = maximum + (increment * 2)
    const ymin = minimum - (increment * 2)
    return (
        <div className="folowersChartContainer">
            <div className="chartWrapper">
                {!!totalFollowers && 
                    <div className="totalFollowersContainer">
                        <span className="totalFollowersText">Total Followers</span>
                        <span className="totalFollowersNumber">{totalFollowers.toLocaleString()}</span>
                    </div>
                }
                <AreaChart width={690} height={160} data={followersStatisticsData} margin={{ top: 10, right: 10, bottom: 0, left: 25 }}>
                    <defs>
                        <linearGradient id="followersLine" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#D22689" stopOpacity={0.3} />
                            <stop offset="40%" stopColor="#D22689" stopOpacity={0.1} />
                            <stop offset="95%" stopColor="#D22689" stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <XAxis dataKey="month" angle={-45} tickMargin={15} height={45} tickLine={false} />
                    <YAxis hide domain={[ymin, ymax]} />
                    <Area dataKey="followers" stroke="#D22689" fill="url(#followersLine)" dot isAnimationActive={false} />
                    <Tooltip formatter={(value) => value?.toLocaleString() || 0} />
                </AreaChart>
            </div>
        </div>
    );
};

export default FollowersChart;
