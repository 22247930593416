import moment from 'moment';
import { get } from 'lodash';
import { EXPIRED } from '../constants/statusTypes';

/*
    Once Influencer accepted the contract - (s)he should sign the contract and pick draft (if required) and
    publish dates from the corresponding date ranges. And there is a case when Influencer doesn't sign the contract
    or pick the dates before the assignment has expired. In that case we should show an error with a warning
    to reach a contact (signed contract and not completed assignment could have some legal consequences).

    The expiration originally should be triggered on backend on the level of DB. But it seems to be triggered
    only once per day. It may lead to the cases when the date has expired, but the status wasn't updated yet.
    And the incorrect data will be shown on the UI.
    So we will do the check on our own.
 */

class ExpirationCheckService {
    constructor(assignment, assignmentId, assignmentStatus) {
        this.assignment = assignment;
        this.assignmentId = Number(assignmentId);
        this.assignmentStatus = assignmentStatus;
    }

    get status() {
        return this.assignmentStatus;
    }

    validateAssignment = () => {
        const id = get(this.assignment, 'id', -1);

        return id === this.assignmentId;
    }

    getLatestFlightEndDate = () => {
        const assignmentFlights = get(this.assignment, 'assignmentFlights', []);
        if (assignmentFlights.length === 0) {
            return '';
        }
        const sortedFlights = assignmentFlights.sort((left, right) => {
            const lflightEndDate = !left.perChannelDateRanges ? left.flightEndDate : (left.channelFlightDates && left.channelFlightDates.length > 0 ?
                left.channelFlightDates[0].endFlightDate : null);
            const rflightEndDate = !right.perChannelDateRanges ? right.flightEndDate : (right.channelFlightDates && right.channelFlightDates.length > 0 ?
                right.channelFlightDates[0].endFlightDate : null);
            moment(lflightEndDate).isAfter(moment(rflightEndDate))
        });

        return !sortedFlights[0].perChannelDateRanges ? sortedFlights[0].flightEndDate : (sortedFlights[0].channelFlightDates.length > 0 ? sortedFlights[0].channelFlightDates[0].endFlightDate : null);
    }

    checkExpiration = () => {
        return false;
        // const now = moment();
        // const latestFlightEndDate = this.getLatestFlightEndDate();

        // if (!latestFlightEndDate) {
        //     return false;
        // }
        // return moment(latestFlightEndDate).set("hour", "23").set("minute", "59").isBefore(now);
    }

    changeAssignmentStatusToExpired = () => {
        this.assignmentStatus = EXPIRED;
    }

    init = () => {
        // just to make sure it's the correct assignment
        // const assignmentIsValid = this.validateAssignment();

        // if (!assignmentIsValid) return;

        // do nothing if it's EXPIRED
        // if (this.assignmentStatus === EXPIRED) return;

        // const assignmentHasExpired = this.checkExpiration();

        // if (assignmentHasExpired) {
        //     this.changeAssignmentStatusToExpired();
        // }
    }
}

export default ExpirationCheckService;
