import { call, put, select } from 'redux-saga/effects';
import {
    setFavoritesListLoading,
    setData,
    setError,
} from '../actionCreators';
import Api from '../../../modules/Api';
import { sortFavoritesList } from '../helpers';

export default function* favoritesListSaga() {
    yield put(setFavoritesListLoading(true));

    const { favorites } = yield select(({ general }) => ({
        favorites: general.favorites,
    }));

    try {
        const favoritesList = yield call(Api.getFavorites);
        yield put(setData({
            favorites: {
                ...favorites,
                favoritesList: sortFavoritesList(favoritesList),
            },
        }));
    } catch (error) {
        yield put(setError({
            favoritesList: error.message,
        }));
    } finally {
        yield put(setFavoritesListLoading(false));
    }
}
