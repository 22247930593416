import React, { useState, useEffect } from 'react';
import './successBanner.scss';
import cx from 'classnames';

const SuccessBanner = ({
    show = false,
    message,
    closeFunction = () => null
}) => {

    useEffect(() => {
        if (show) {
            const timer = setTimeout(closeFunction, 5000);

            return () => {
                if (timer)
                    clearTimeout(timer);
            }
        }

    }, [show]);
    return (
        <div className={cx('successBannerContainer', { active: show })}>
            <div>
                <img src="/images/ic-success-w.svg" style={{ height: 40, width: 40 }} />
                <span>{message}</span>
                <img src="/images/ic-close-w.svg"
                    style={{ height: 18, width: 18, position: 'absolute', right: 20, marginRight: 0 }}
                    onClick={closeFunction}
                />
            </div>

        </div>
    )
}

export default SuccessBanner;