import React, {useState, useEffect, useRef} from 'react';

const KeywordGroupDetails = ({keywordGroup, onUpdate, readOnly}) => {

    const [keywordInputValue, setKeywordInputValue] = useState("");
    const inputEl = useRef(null);

    const onAddKeywordPress = () => {
        let newKeywordGroup = {...keywordGroup};
        newKeywordGroup.keywords.push(keywordInputValue);
        onUpdate(newKeywordGroup, false);
        setKeywordInputValue("")
        inputEl.current.focus();
    }

    const onDeleteKeyword = (keyword) => {
        let newKeywordGroup = {...keywordGroup};
        newKeywordGroup.keywords = newKeywordGroup.keywords.filter(k => k !== keyword);
        onUpdate(newKeywordGroup, true);
    }

    const isAddButtonDisabled = () => {
        return !keywordInputValue || keywordInputValue.length === 0 || keywordGroup.keywords.includes(keywordInputValue);
    }

    const onInputKeyPress = (e) => {
        if (!isAddButtonDisabled() && e.keyCode == 13)
            onAddKeywordPress();
    }

    const renderAddKeywordHeader = () => {
        return (
            <div className="inputHeader">
                <div className="title">
                    <span className="titleMain">
                        Add Keywords
                    </span>
                    <span className="titleSecondary">
                        Add Keywords to your Group
                    </span>
                </div>
                <div className="interaction">
                    <input  className="keywordInput"
                            ref={inputEl}
                            placeholder="Add Keywords..."
                            value={keywordInputValue}
                            onChange={e => setKeywordInputValue(e.target.value)}
                            onKeyDown={onInputKeyPress}
                            maxLength={100}/>
                    <div className="addButton" style={isAddButtonDisabled() ? {opacity: "0.5", cursor: "auto"} : null} onClick={isAddButtonDisabled() ? null : onAddKeywordPress}>
                        Add
                    </div>
                </div>
                {(isAddButtonDisabled() && keywordInputValue !== "") &&
                    <span className="errorInfo">
                        {"Keyword already exists in this group"}
                    </span>
                }
            </div>
        )
    }

    const renderKeywords = () => {
        if (!keywordGroup || !keywordGroup.keywords || keywordGroup.keywords.length === 0)
            return (
                <div className="emptyListInfo">
                    No Keywords were added to this group yet...
                </div>
            );

        return (
            <div className="keywords">
                {keywordGroup.keywords.map(k => renderKeyword(k))}
            </div>
        )
    }

    const renderKeyword = (keyword) => {
        return (
            <div key={"keyword-item-" + keyword} className="keyword">
                <span>
                    {keyword}
                </span>
                {!readOnly && <img src="/images/ic-close-d.svg" alt="" onClick={() => onDeleteKeyword(keyword)} />}
            </div>
        )
    }

    if (!keywordGroup)
        return null;

    return (
        <div className="contentDiv brandDiv">
            <div className="keywordGroupDetails">
                {!readOnly && renderAddKeywordHeader()}
                {renderKeywords()}
            </div>
        </div>
    )
}

export default KeywordGroupDetails;
