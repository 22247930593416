export const getKwFilters = (keywords, checkedSearch) => {
    if (checkedSearch.length && keywords.length) {
        return checkedSearch.map((chk) => ({ operator: 'any', filterId: chk, keywords }));
    } else {
        return [];
    }
};

export const getNameLastNameText = (firstname, lastname) => {
    const resultArr = [];
    if (firstname) {
        resultArr.push(firstname);
    }
    if (lastname) {
        resultArr.push(lastname);
    }
    return resultArr.join(' ');
};

export const getActiveChannelName = (publicChannelSelect) => {
    return Object.entries(publicChannelSelect)
        .find(([channelName, isActive]) => isActive)[0]
        ?.toUpperCase();
};
