import React from 'react';
import './noDataCard.scss';

const NoDataCard = ({
    message,
    icon
}) => {

    return (
        <div className='noDataContainer'>
            <img src={icon} alt={message} />
            <div>{message}</div>
        </div>
    );
}

export default NoDataCard;