import React from "react";
import { Auth } from 'aws-amplify';

import FeedbaclOnTheTop from '../../shared/feedbackOnTheTop/FeedbackOnTheTop'
import ErrorMessageBox from '../../shared/errorMessageBox/ErrorMessageBox'
import './password.scss';
import MFASettings from "../../shared/multiFactorAuth/MFASettings";

const delay = 1000;

export default class Password extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            passwordErrors : [],
            confirmErrors : [],
            enabled : false
        };

        this.passwordTimeout = null;
        this.confirmTimeout = null;
    }

    updateEnabled = () => {
        let filled = (fieldname) => {
            let value = this.state[fieldname];
            if (typeof value !== 'string') {
                return false;
            }
            if (value.trim().length === 0) {
                return false;
            }
            return true;
        };

        let enabled = true;

        if (!filled('oldPasswordEntered')) {
            enabled = false;
        }
        if (!filled('newPasswordEntered')) {
            enabled = false;
        }
        if (!filled('confirmEntered')) {
            enabled = false;
        }
        if (this.state.confirmErrors.length > 0) {
            enabled = false;
        }
        if (this.state.passwordErrors.length > 0) {
            enabled = false;
        }
        this.setState({
            enabled : enabled
        })
    }

    checkNewPasswordSyntax= () => {
        let value = this.state.newPasswordEntered;
        let passwordErrors = [];
        if (value.length < 8) {
            passwordErrors.push('Password must be at least 8 characters long');
        }
        if (!(value.match(/[A-Z]+/))) {
            passwordErrors.push('Password must contain at least one uppercase letter');
        }
        if (!(value.match(/[0-9]+/))) {
            passwordErrors.push('Password must contain at least one number');
        }
        this.setState({passwordErrors : passwordErrors}, this.updateEnabled);
    }

    handleNewPasswordChange = (e) => {
        this.setState({
            newPasswordEntered : e.target.value,
            passwordErrors : [],
            enabled : false
        })
        clearTimeout(this.passwordTimeout);
        this.passwordTimeout = setTimeout( this.checkNewPasswordSyntax, delay);
    }

    handleOldPasswordChange = (e) => {
        this.setState({
            oldPasswordEntered : e.target.value
        }, this.updateEnabled)
    }

    checkConfirm = () => {
        let confirmErrors = [];
        if (this.state.confirmEntered !== this.state.newPasswordEntered) {
            confirmErrors.push('Passwords don\'t match');
        }
        this.setState({
            confirmErrors : confirmErrors
        }, this.updateEnabled)
    }

    clearFields = () => {
        let fields = ['oldPassword', 'newPassword', 'confirm'];
        let newState = { enabled : false,
            passwordErrors : [],
            confirmErrors : [],
        }
        for (let field of fields) {
            newState[field + 'Entered'] = '';
            this[field].value = '';
        }
        this.setState(newState);
    }

    handleConfirmChange = (e) => {
        this.setState({
            confirmEntered : e.target.value,
            confirmErrors : [],
            enabled : false
        });
        clearTimeout(this.confirmTimeout);
        this.confirmTimeout = setTimeout( this.checkConfirm, delay);
    }

    changePassword = () => {
        if (!this.state.enabled) {
            return;
        }
        let oldPassword = this.state.oldPasswordEntered;
        let newPassword = this.state.newPasswordEntered;
        Auth.currentAuthenticatedUser().then(
            user => {
                    Auth.changePassword(user, oldPassword, newPassword).then(
                        (res) => {
                            this.setState({
                                showSuccessFeedback : true
                            })
                            this.clearFields();
                        },
                        (err) => {
                            this.setState({
                                errorMessage : err.message
                            })
                        }
                    )
                }
            )
    }

    render() {
        let passwordErrors = [];
        if (Array.isArray(this.state.passwordErrors)) {
            let key = 1;
            for (let error of this.state.passwordErrors) {
                passwordErrors.push(<div key={key++}>{error}</div>)
            }
        }
        let confirmErrors = [];
        if (Array.isArray(this.state.confirmErrors)) {
            let key = 1;
            for (let error of this.state.confirmErrors) {
                confirmErrors.push(<div key={key++}>{error}</div>)
            }
        }
        let buttonClass = "button";
        if (this.state.enabled) {
            buttonClass += " enabled"
        }

        return (<div className="password">
            <FeedbaclOnTheTop
                show = {this.state.showSuccessFeedback}
                closeFunction = { () => { this.setState({ showSuccessFeedback: false})}}
                message = "Password successfully changed"
            />
            <ErrorMessageBox
                show = {this.state.errorMessage}
                message = {this.state.errorMessage}
                closeFunction = { () => { this.setState({ errorMessage: null})}}
            />
            <div className="passwordPanel">
                <div className="inputPanel">
                    <div className="label">
                        Current Password
                    </div>
                    <input type="password" onChange={this.handleOldPasswordChange}
                           ref={ (e) => {this.oldPassword = e}}
                    />
                    <div className="spacer"/>
                    <div className="label">
                        New Password
                    </div>
                    <input type="password" onChange={this.handleNewPasswordChange}
                           ref={ (e) => {this.newPassword = e}}
                    />
                    { passwordErrors.length > 0 &&
                        <div className="errors">
                            { passwordErrors }
                        </div>
                    }
                    <div className="spacer"/>
                    <div className="label">
                        Confirm Password
                    </div>
                    <input type="password" onChange={this.handleConfirmChange}
                           ref={ (e) => {this.confirm = e}}
                    />
                    { confirmErrors.length > 0 &&
                        <div className="errors">
                            { confirmErrors }
                        </div>
                    }
                </div>
                <div className="controlPanel">
                    <div className={buttonClass} onClick={this.changePassword}>
                        SAVE
                    </div>
                    <div className="button cancel" onClick={this.clearFields}>
                        CANCEL
                    </div>
                    <div style={{clear:'both'}}/>
                </div>
            </div>
            <div className="mfaPanel">
                <MFASettings/>
            </div>
        </div>)
    }
}