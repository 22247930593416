import { takeLatest } from 'redux-saga/effects';
import {
    BEFORE_ADD_TO_CAMPAIGN,
    FINISH_INVITE,
    GET_DATA,
    LIST_SPLASH_FILTER_CHANGE,
    PAGE_CHANGE,
    PAGE_LIMIT_CHANGE,
    SELECTION_CHANGE,
    SEARCH_FILTERS_CHANGE,
    SELECT_ALL,
    SORT_FILTER_CHANGE,
    ORDER_TYPE_CHANGE,
    REFRESH_SEARCH,
} from './actions';
import influencerSearchDataInitSaga from './sagas/influencerSearchDataInitSaga';
import pageChangeSaga from './sagas/pageChangeSaga';
import pageLimitChangeSaga from './sagas/pageLimitChangeSaga';
import selectAllSaga from './sagas/selectAllSaga';
import sortDisplayFilterChangeSaga from './sagas/sortDisplayFilterChangeSaga';
import listsSplashFilterChangeSaga from './sagas/listsSplashFilterChangeSaga';
import searchFiltersChangeSaga from './sagas/searchFiltersChangeSaga';
import selectionChangeSaga from './sagas/selectionChangeSaga';
import beforeAddToCampaignSaga from './sagas/beforeAddToCampaignSaga';
import afterAddToCampaignSaga from './sagas/afterAddToCampaignSaga';
import sortOrderChangeSaga from './sagas/sortOrderChangeSaga';
import influencersSaga from './sagas/influencersSaga';

export default function* influencerSearch() {
    yield takeLatest(BEFORE_ADD_TO_CAMPAIGN, beforeAddToCampaignSaga);
    yield takeLatest(FINISH_INVITE, afterAddToCampaignSaga);
    yield takeLatest(GET_DATA, influencerSearchDataInitSaga);
    yield takeLatest(LIST_SPLASH_FILTER_CHANGE, listsSplashFilterChangeSaga);
    yield takeLatest(PAGE_CHANGE, pageChangeSaga);
    yield takeLatest(PAGE_LIMIT_CHANGE, pageLimitChangeSaga);
    yield takeLatest(SEARCH_FILTERS_CHANGE, searchFiltersChangeSaga);
    yield takeLatest(SELECT_ALL, selectAllSaga);
    yield takeLatest(SELECTION_CHANGE, selectionChangeSaga);
    yield takeLatest(SORT_FILTER_CHANGE, sortDisplayFilterChangeSaga);
    yield takeLatest(ORDER_TYPE_CHANGE, sortOrderChangeSaga);
    yield takeLatest(REFRESH_SEARCH, influencersSaga);
}
