import React from 'react';
import { connect } from 'react-redux';
import { storeUserProfile } from '../../store/global/globalActions';
import LocationInput from '../shared/locationInput/LocationInput';
import Location from './piecesInMain/Location';
import ProfilePhoto from './ProfilePhoto';
import CreatorProfilePhoto from './CreatorProfilePhoto';
import Format from '../../modules/utils/Format';
import DropdownSelect from '../shared/selectBox/DropdownSelect';
import Api from '../../modules/Api';
import PleaseWaitPartial from '../shared/pleaseWait/PleaseWaitPartial';
import PleaseWait from '../shared/pleaseWait/PleaseWaitWhite';
// it seems components and modules imported below have cycle dependencies (potential memory leak)
import ReachAndRateComponent from './reachAndRate/ReachAndRateComponent';
import DisplayInfluencerCategories from '../shared/displayInfluencerCategories/DisplayInfluencerCategories';
import EditablePiece from './editablePiece/EditablePiece';
import Lookup from '../../modules/Lookup';
import './creatorProfileBasic.scss';
import DisplayTopics from '../shared/displayTopics/DisplayTopics';
import ProfileRates from '../influencers/detailPage/influencerProfileBU/ProfileRates';
import Rate from './piecesInMain/Rate';
import ApiError from '../shared/apiError/ApiError';


// The piece with the influencer photo, name etc (left side box)
// Props :
// - profile
// - editMode
// - changeCount
// - handleLocalEditChange
// - currentLocal
// - changeFunction

class CreatorProfileBasic extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.handleLocalEditChange = this.handleLocalEditChange.bind(this);
        this.renderedDisplay = this.renderedDisplay.bind(this);
        this.processProps = this.processProps.bind(this);
        this.fromStateToProfile = this.fromStateToProfile.bind(this);

        this.copyFields = [
            'firstname',
            'lastname',
            'shortSummary',
            'paymentEmail',
            'topics',
            'brands',
            'foods',
            'hobbies',
        ];
        // in addition to the above we have the the address fields to take care of

        this.state = {
            localEdit: false,
            locationChangeCounter: 1,
            stateFilled: false,
            loading: false,
            rateLocal: false,
            rateSave: false,
            uploadProgressPercentage: null,
            onUploadCancel: null,
            apiError: null,
        };
    }

    fromProfileToState = (profile) => {
        const {
            country,
            city,
            state,
            pictureUrl,
        } = profile;
        const newState = {
            stateFilled: true,
            pictureUrl,
            address: {
                country,
                city,
                state,
            },
        };
        for (const key of this.copyFields) {
            newState[key] = profile[key] || '';
        }

        this.setState(newState);
    }

    fromStateToProfile() {
        for (const field of LocationInput.getAddressProperties()) {
            this.props.profile[field] = this.state.address[field] || '';
        }
        for (const key of this.copyFields) {
            this.props.profile[key] = this.state[key];
        }
    }

    processProps(props) {
        if (props.profile && typeof this.state.firstname !== 'string') {
            // profile appearing the first time
            this.fromProfileToState(props.profile);
        }
    }

    handleLocalEditChange = (editMode, saveFlag) => {
        const { profile } = this.props;
        if (!editMode) {
            if (saveFlag) {
                this.fromStateToProfile();
                this.handleProfileChange(profile);
            } else {
                // cancel edit
                this.fromProfileToState(profile);
            }
        }

        this.setState({
            localEdit: editMode,
        });

        const { handleLocalEditChange } = this.props;
        if (typeof handleLocalEditChange === 'function') {
            handleLocalEditChange(editMode);
        }
        if (!saveFlag) {
            this.setState({ imageFile: null });
        }
    }

    savePhoto = (file) => {
        this.setState({
            loading: true,
        });

        const {
            profile: { id },
            storeProfile,
        } = this.props;

        Api.updateUserPhoto(id, file)
            .then((res) => {
                storeProfile(res);

                this.setState({
                    imageFile: null,
                    loading: false,
                });
            })
            .catch(err => this.setState({loading: false, apiError: err}));
    }

    handleProfileChange = async (profile) => {
        const {
            imageFile,
            windowUrl,
        } = this.state;
        const newProfile = { ...profile };
        newProfile.windowUrl = windowUrl;
        const { changeFunction } = this.props;

        if (imageFile) {
            try {
                const imageUpload = await Api.uploadFile(
                    imageFile,
                    false,
                    (percentage) => this.setState({uploadProgressPercentage: percentage}),
                    (fn) => this.setState({onUploadCancel: fn})
                );
                if (imageUpload && typeof imageUpload === 'object' && imageUpload.url) {
                    newProfile.pictureUrl = imageUpload.url;
                    // storeProfile(newProfile);
                    this.setState({
                        // imageFile: null,
                    })
                }
            } catch(error) {
                this.setState({
                    apiError: error
                })
            }
            this.setState({uploadProgressPercentage: null, onUploadCancel: null})
        }

        if (typeof changeFunction === 'function') {
            changeFunction(newProfile);
        }
    }

    handleLocationChange = (address, fields) => {
        const newState = {
            address: {},
        };

        for (const field of fields) {
            newState.address[field] = address[field];
        }

        const { locationChangeCounter } = this.state;
        newState.locationChangeCounter = locationChangeCounter + 1;

        this.setState(newState);
    }

    handleFirstNameChange = (e) => {
        const value = e.target.value || '';
        let errorMessage = null;

        if (value.length === 0) {
            errorMessage = 'First name must be filled';
        } else if (!Format.validateFirstName(value)) {
            errorMessage = 'Invalid first name';
        }

        this.setState({
            firstname: value,
            firstNameClass: errorMessage ? 'checkedInputError' : '',
            firstNameError: errorMessage,
        });
    }

    handleLastNameChange = (e) => {
        const value = e.target.value || '';

        this.setState({
            lastname: value,
            lastNameClass: value.length > 0 ? '' : 'checkedInputError',
        });
    }

    handleSummaryChange(e) {
        this.setState({
            shortSummary: e.target.value,
        });
    }

    handleEmailChange = (e) => {
        let value = e.target.value || '';
        value = value.trim();

        this.setState({
            paymentEmail: value,
            paymentEmailClass: value.length > 0 ? '' : 'checkedInputError',
        });
    }

    handleCategoryChange = (categories) => {
        this.setState({
            topics: categories,
            categoriesClass: categories.length > 1 ? '' : 'checkedInputError',
        });
    }

    saveFileState = (file) => {
        this.setState({
            imageFile: file,
        });
    }

    renderedEdit = () => {
        const {
            address,
            categoriesClass,
            firstname,
            firstNameClass,
            firstNameError,
            lastname,
            lastNameClass,
            paymentEmail,
            paymentEmailClass,
            topics,
        } = this.state;
        const {
            profile: { userNetworks },
        } = this.props;
        const index = userNetworks.findIndex((item) => item.networkType === 'blog');

        return (
            <div className="editBasics">
                <div className="label">
                    First Name
                </div>
                <div className={firstNameClass}>
                    <input
                        value={firstname}
                        onChange={this.handleFirstNameChange}
                    />
                    <div className="errorMessage">
                        {firstNameError}
                    </div>
                </div>
                <div className={lastNameClass}>
                    <div className="label">
                        Last Name
                    </div>
                    <input
                        value={lastname}
                        onChange={this.handleLastNameChange}
                    />
                    <div className="errorMessage">
                        Last name must be filled
                    </div>
                </div>
                <div className="label" style={{ marginBottom: 8 }}>
                    Category
                </div>

                <div className={categoriesClass}>
                    <DropdownSelect
                        clearable={false}
                        multi
                        simpleValue
                        value={topics}
                        options={Lookup.getTopicOptions()}
                        onChange={this.handleCategoryChange}
                        maxLimit={6}
                    />
                    <div className="errorMessage">
                        Must select 2 to 6 Categories
                    </div>
                </div>

                <div className="label">
                    Location
                </div>

                <Location
                    profile={address}
                    changeFunction={this.handleLocationChange}
                />

                <div className={paymentEmailClass} style={{ display: 'none' }}>
                    {/* hidden as of https://jira.hubub.com/browse/INFMKT-1094 */}
                    <div className="label">
                        Paypal Email
                    </div>
                    <input
                        value={paymentEmail}
                        onChange={this.handleEmailChange}
                    />
                    <div className="errorMessage">
                        Paypal Email must be filled
                    </div>
                </div>

            </div>
        );
    }

    renderedDisplay = () => {
        const notEmpty = (property) => {
            if (typeof this.state[property] !== 'string') {
                return false;
            }

            if (this.state[property].trim().length === 0) {
                return false;
            }

            return true;
        };

        const {
            address: {
                country,
                city,
                state,
            },
            firstname,
            lastname,
            topics,
        } = this.state;
        const showName = notEmpty('firstname') || notEmpty('lastname');
        const showAddress = country;
        const name = `${firstname} ${lastname}`;
        return (
            <div className="displayBasics">
                {showName && (
                    <div className="name">
                        {name}
                    </div>
                )}
                {showAddress && (
                    <div className="location">
                        <div>{city},&nbsp;</div>
                        <div>{state},&nbsp;</div>
                        <div>{country}</div>
                    </div>
                )}
                {topics.length > 0 && (
                    <div className="topics">
                        <DisplayTopics topics={topics} short />
                    </div>
                )}

            </div>
        );
    }

    componentDidMount() {
        this.processProps(this.props);
    }

    componentWillReceiveProps(newProps) {
        this.processProps(newProps);
    }

    renderedRateDisplay() {
        const { profile } = this.props;
        return (
            <div className="rateSection">
                <ProfileRates profile={profile} />
            </div>
        )
    }

    // renderedRateEdit() {
    //     return (
    //         <Rate
    //         activeRatesOption={activeRatesOption}
    //         isTalentManager={isTalentManager}
    //         editMode={true}
    //         saveFlag={rateSave}
    //         profile={profile}
    //         changeFunction={this.handleProfileChange}
    //         errorStatusFunction={this.setRateError}
    //     />

    //     )
    // }

    render() {
        const {
            categoriesClass,
            firstNameClass,
            lastNameClass,
            paymentEmailClass,
            imageFile,
            loading,
            localEdit,
            stateFilled,
            apiError,
        } = this.state;
        const {
            currentLocal,
            editMode,
            profile,
            resetFunction,
            isTalentManager,
        } = this.props;

        if (!stateFilled) {
            return null;
        }

        const {
            management,
        } = profile;

        const permissions = {
            editInfo: management ? management.permissionsSet.editInfo : true,
        };

        const disableSave = firstNameClass === 'checkedInputError'
            || lastNameClass === 'checkedInputError'
            || paymentEmailClass === 'checkedInputError'
            || categoriesClass === 'checkedInputError';

        return (
            <div>
                <div
                    className="creatorProfileBasics"
                    onClick={resetFunction}
                    id="profileBasicsContainer"
                >
                    <PleaseWait show={!!this.state.uploadProgressPercentage}
                        progressPercentage={this.state.uploadProgressPercentage}
                        onCancel={this.state.onUploadCancel}
                    />
                    <PleaseWaitPartial
                        show={loading}
                        container={document.getElementById('profileBasicsContainer')}
                    />
                    <ApiError
                        show={apiError}
                        error={apiError}
                        cancelFunction={() => this.setState({apiError: null})}
                    />
                    <div className="fields">
                        <EditablePiece
                            editMode={permissions.editInfo && editMode}
                            handleLocalEditChange={this.handleLocalEditChange}
                            disableEdit={currentLocal !== null}
                            disableSave={disableSave}
                            isTalentManager={isTalentManager}
                        >
                            <div style={{ height: 30 }} />
                            <CreatorProfilePhoto
                                updatedPhoto={imageFile}
                                isEdit={localEdit && !isTalentManager}
                                profile={profile}
                                updatePhotoFunction={(file) => this.saveFileState(file)}
                            />
                            {localEdit ? this.renderedEdit() : this.renderedDisplay()}
                            <div style={{ height: 22 }} />
                        </EditablePiece>
                    </div>
                    {/* <div className="fields">
                        <EditablePiece
                            editMode={editMode}
                            handleLocalEditChange={this.handleLocalEditChange}
                            disableEdit={currentLocal !== null}
                            disableSave={disableSave}
                        >
                            {localEdit ? this.renderedRateEdit() : this.renderedRateDisplay()}
                            <div style={{ height: 18 }} />
                        </EditablePiece>
                    </div>
 */}
                </div>
                {/* <ReachAndRateComponent
                    profile={profile}
                /> */}
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        storeProfile: (profile) => {
            dispatch(storeUserProfile(profile));
        },
    };
};

export default connect('', mapDispatchToProps)(CreatorProfileBasic);
