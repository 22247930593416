import React, {Component} from 'react';
import Modal from 'react-modal';
import _ from 'lodash';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Select from 'react-select';
import Api from '../../../modules/Api';
import './customizeContractPopup.scss';
import RadioBox from '../radiobox/RadioBox';
import ApiError from '../apiError/ApiError';
import {validateUploadContract, handleSaveUploadedContract} from './contractHelper';
import deleteIcon from '../../../assets/images/ic_close.svg';

const specialUploadContractTag = 'individualUploadedCustomizeContract';

export default class CustomizeContractPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customizeContractOption: -1,
            uploadFileStep: false,
            signer: '',
            uploadFilename: '',
            error: null,
            uploadFileObj: null,
            showCurrentContract: false,
        };
        this.refUploadContract = undefined;
    }

    componentDidUpdate(prevProps) {
        const {contractSelectedTemplate, showCustomizeContractPopup} = this.props;
        if (!prevProps.showCustomizeContractPopup && showCustomizeContractPopup) {
            if (contractSelectedTemplate && contractSelectedTemplate.data && contractSelectedTemplate.data[specialUploadContractTag]) {
                this.setState({
                    uploadFilename: contractSelectedTemplate.name ? contractSelectedTemplate.name : '',
                    signer: contractSelectedTemplate.data.preset_AgentSigner ? contractSelectedTemplate.data.preset_AgentSigner : '',
                    customizeContractOption: 1,
                });
            } else {
                this.setState({
                    uploadFilename: '',
                    uploadFileObj: null,
                    signer: '',
                    customizeContractOption: 0,
                });
            }
        }
    }

    cancelCustomizeContractPopup = () => {
        const {contractSelectedTemplate, closeFunction} = this.props;
        if (contractSelectedTemplate.name) {
            // restore the uploadFilename on cancel
            this.setState({
                uploadFilename: contractSelectedTemplate.name,
                signer: contractSelectedTemplate.data && contractSelectedTemplate.data.preset_AgentSigner ? contractSelectedTemplate.data.preset_AgentSigner : '',
                // showCustomizeContractPopup: false, 
                uploadFileStep: false, 
                error: null,
            }, function() {
                if (closeFunction) {
                    closeFunction();
                }
            });
        } else {
            this.closeCustomizeContractPopup();
        }
    }

    closeCustomizeContractPopup = (needToRefresh = false) => {
        const {closeFunction} = this.props;
        this.setState({ 
            uploadFileStep: false, 
            error: null, 
        }, function() {
            if (closeFunction) {
                closeFunction(needToRefresh);
            }
        });
    }

    continueUpload = () => {
        const {assignmentId, assignmentUserId, contractSelectedTemplate, openTemplateFunction} = this.props;
        const {uploadFileObj, customizeContractOption, signer} = this.state;
        if (!this.state.uploadFileStep) {
            if (customizeContractOption === 1) {
                this.setState({uploadFileStep: true})
            } else {
                if (openTemplateFunction) {
                    openTemplateFunction();
                }
                this.closeCustomizeContractPopup();
            }
        } else if (uploadFileObj) {
            handleSaveUploadedContract(assignmentId, assignmentUserId, uploadFileObj, "agent-user", signer)
                .then(res => {
                    this.closeCustomizeContractPopup(true);
                })
                .catch(err => {
                    this.setState({error: err})
                })
        } else if (contractSelectedTemplate && 
            contractSelectedTemplate.data && 
            contractSelectedTemplate.data.preset_AgentSigner && 
            signer.value !== contractSelectedTemplate.data.preset_AgentSigner.value) {
            // previously uploaded the docx, now changing the signer
            Api.getAssignmentUserContractTemplate(assignmentUserId)
                .then(data => {
                    const content = [];
                    content.push(data);
                    const tempFile = new File(content, contractSelectedTemplate.name);
                    handleSaveUploadedContract(assignmentId, assignmentUserId, tempFile, "agent-user", signer)
                        .then(response => {
                            this.closeCustomizeContractPopup();
                        })
                        .catch(err => {
                            this.setState({error: err});
                        })
                })
                .catch(err => {
                    this.setState({error: err});
                })
        } else {
            // nothing changed
            this.closeCustomizeContractPopup();
        }
    }

    chooseUploadFile = () => {
        if (!this.refUploadContract) {
            return;
        }
        
        const reader = new FileReader();
        const file = this.refUploadContract.files[0];
        reader.onload = () => {
            this.selectedFile(reader.result, file);
        }
        reader.readAsDataURL(file);
    }

    onClickChooseFile = () => {
        if (this.refUploadContract) {
            this.refUploadContract.click();
        }
    }

    handleSignerChange = (value) => {
        this.setState({signer: value})
    }

    onDropFile = (event) => {
        event.stopPropagation();
        event.preventDefault();

        if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
            const reader = new FileReader();
            const file = event.dataTransfer.files[0];
            reader.onload = () => {
                this.selectedFile(reader.result, file);
            }
            reader.readAsDataURL(file);
        }
    }

    onDragEnter = (event) => {
        event.stopPropagation();
    }

    onDragOver = (event) => {
        event.stopPropagation();
        event.preventDefault();
    }

    selectedFile = (dataUrl, file) => {
        if (!file.name.toLowerCase().trim().endsWith('.docx')) {
            this.setState({
                error: 'Supporting .docx format only',
                uploadFilename: '',
                uploadFileObj: null,
            });
            return;
        }
        // must be less than 10MB, as the limitation on the GET API /assignmentuser/${assignmentUserID}/contract/template
        if (file.size >= 10*1000*1000) {
            this.setState({
                error: 'File too big (> 10MB)',
                uploadFilename: '',
                uploadFileObj: null,
            });
            return;
        }
        // validate the uploaded contract must contain the mandatory tags
        validateUploadContract(dataUrl)
            .then(() => {
                this.setState({uploadFilename: file.name, uploadFileObj: file})
            })
            .catch (err => {
                this.setState({error: err, uploadFilename: '', uploadFileObj: null})
            })
    }

    render() {
        const {customizeContractOption, uploadFileStep, signer, uploadFilename, error,  } = this.state;
        const { signerList, contractSelectedTemplate, showCustomizeContractPopup } = this.props;
        let disableCustomizeTemplate = false;
        if (contractSelectedTemplate && contractSelectedTemplate.data && contractSelectedTemplate.data[specialUploadContractTag]) {
            disableCustomizeTemplate = true;
        }

        return (
            <Modal
                closeTimeoutMS={300}
                overlayClassName="customizeContractOverlay"
                className="customizeContractModal"
                isOpen={showCustomizeContractPopup}
                contentLabel="Customize Contract"
                onRequestClose={this.cancelCustomizeContractPopup}
            >
                <div className={cx("customizeContractContainer", {overlayBase: error})}>
                    <ApiError
                        show={!!error}
                        cancelFunction={() => this.setState({ error: null, uploadFilename: ''})}
                        error={error}
                    />
                    <div className="customizeContractOptionHeader" style={{paddingLeft: '35px', paddingRight: '35px'}}>
                        <div className="customizeContractTitle">Customize Contract</div>
                    </div>
                    { !uploadFileStep ?
                        <div style={{paddingLeft: '35px', paddingRight: '35px'}}>
                            <div className="customizeContractTitle" style={{paddingTop: '30px', paddingBottom: '10px'}}>Options</div>
                            <div className="customizeContractOptionContainer">
                                <RadioBox
                                    selected={customizeContractOption === 0}
                                    clickFunction={() => this.setState({customizeContractOption: 0})}
                                    disabled={disableCustomizeTemplate}
                                >
                                    <div className="customizeContractTitle">Customize Template</div>
                                    <div className="optionDescription">Customize template for creator</div>
                                </RadioBox>
                            </div>
                            <div className="customizeContractOptionContainer" style={{marginTop: '12px', marginBottom: '50px'}}>
                                <RadioBox
                                    selected={customizeContractOption === 1}
                                    clickFunction={() => this.setState({customizeContractOption: 1})}
                                >
                                    <div className="uploadDocContainer">
                                        <div>
                                            <div className="customizeContractTitle">Upload Document</div>
                                            <div className="optionDescription">Upload custom contract for creator</div>
                                        </div>
                                        { uploadFilename !== '' &&
                                            <div className="uploadDocInfo">
                                                <div className="customizeContractHint">File already uploaded</div>
                                                <div className="uploadedFileNameContainer">
                                                    <span>
                                                        {uploadFilename}
                                                    </span>
                                                    <img 
                                                        src={deleteIcon} 
                                                        className="deleteFileIcon" 
                                                        alt="delete" 
                                                        onClick={() => this.setState({uploadFilename: '', uploadFileObj: null})}
                                                    />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </RadioBox>
                            </div>
                        </div>
                        :
                        <div style={{paddingLeft: '35px', paddingRight: '35px', paddingTop: '30px', paddingBottom: '43px'}}>
                            {
                                uploadFilename === '' ?
                                    <div 
                                        className="customizeContractUploadArea"
                                        onDrop={this.onDropFile} 
                                        onDragOver={this.onDragOver} 
                                        onDragEnter={this.onDragEnter}
                                    >
                                        <div className="customizeContractUploadTitle">Draft file to upload</div>
                                        <div className="customizeContractUploadDescription">Supporting .docx format only</div>
                                        <div className="customizeContractUploadDescription" style={{color: '#e50043'}}>
                                            Please be sure to tag the document where signature and sign date are required using the fields below (case sensitive). 
                                        </div>
                                        <div className="customizeContractUploadDescription">
                                            <span>CreatorSignHere</span>, <span>CreatorSignDate</span>, <span>AgentSignHere</span>, <span>AgentSignDate</span>
                                        </div>
                                        <div className="customizeContractUploadDescription" style={{color: '#e50043', fontSize: '12px', lineHeight: '16px'}}>
                                            Tip: Set the font color of these strings to match the document background, make them invisible to recipients.
                                        </div>
                                        <div className="customizeContractUploadChooseFileBtn" onClick={this.onClickChooseFile}>
                                            Choose File
                                            <input 
                                                type='file' 
                                                ref={ref => this.refUploadContract = ref}
                                                onClick={(e) => e.target.value = ''}
                                                onChange={this.chooseUploadFile}
                                                accept=".docx"
                                                multiple={false}
                                            />
                                        </div>
                                    </div>
                                    :
                                    <div className="customizeContractUploadArea">
                                        {uploadFilename}
                                        <div className="customizeContractHint">file has been selected</div>
                                    </div>
                            }
                            <div className="customizeContractSignerLabel">Contract Signer</div>
                            <Select
                                className="dropdown customizeContractSignerBox "
                                clearable={false}
                                searchable={false}
                                value={signer}
                                single
                                placeholder="Select Agent"
                                options={signerList || []}
                                onChange={this.handleSignerChange}
                            />
                        </div>
                    }
                    <div className="customizeContractOptionBottom">
                        <div 
                            className="cancelBtn" 
                            onClick={this.cancelCustomizeContractPopup}
                        >
                            Cancel
                        </div>
                        <div 
                            className={cx("cancelBtn continueBtn", {disabled: uploadFileStep && (uploadFilename === '' || !signer)})}
                            onClick={this.continueUpload}
                        >
                            Continue
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

CustomizeContractPopup.propTypes = {
    contractSelectedTemplate: PropTypes.object,
    assignmentId: PropTypes.number,
    assignmentUserId: PropTypes.number,
    signerList: PropTypes.array,
    showCustomizeContractPopup: PropTypes.bool,
    closeFunction: PropTypes.func.isRequired,
    openTemplateFunction: PropTypes.func.isRequired,
}

CustomizeContractPopup.defaultProps = {
    contractSelectedTemplate: {},
    assignmentId: null,
    assignmentUserId: null,
    signerList: [],
    showCustomizeContractPopup: false,
}
