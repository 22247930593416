import React, { useState } from 'react';
import './dropdownMenu.scss';

export default function DropdownMenu({ separatorIdx, children }) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="dropdown-menu">
            <img src="/images/ic-more.svg" alt="Dropdown menu icon" onClick={() => setIsOpen((prev) => !prev)} />
            {isOpen && (
                <div className="options-wrapper">
                    {React.Children.map(children, (child, idx) => {
                        return child ? (
                            <>
                                {idx === separatorIdx && <div className="operation-separator" />}
                                {React.cloneElement(child, {
                                    className: `${child?.props?.className} operation-selection`.trim(),
                                })}
                            </>
                        ) : null;
                    })}
                </div>
            )}
        </div>
    );
}
