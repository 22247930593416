import React, {useState} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames'
import './ListTab.scss';
import FavouriteList from "./FavoriteLists"
import AddToFavorite from '../../favourite/AddToFavorite';
import AddToExclusion from '../../favourite/AddToExclusion';
import AddToPlan from '../../favourite/AddToPlan';

const ListTab = ({
    type,
    revert,
    favoriteData,
    inviteList,
    show,
    setSuccess,
    searchFilters
}) => {
    const infoToUse = [1]
    return (
        <div className={cx("listTab", {show: show})} id="addSectionOpen">
            {/* <div className="topSection">
                <img onClick={revert} src="/images/BackBlack.svg" alt="back arrow" />
                <div className="titleLabel">Add to {type}</div>
                <div className="filler"/>
            </div> */}
            {/* {type && <FavouriteList infoToUse={infoToUse} show={true} userDetails={favoriteData} type={type} inviteList={inviteList}
            setSuccess={setSuccess} searchFilters={searchFilters}/>} */}
            {type === 'Favourite lists' && show && <AddToFavorite usersSelected={inviteList} onBack={revert} setSuccess={setSuccess}/>}
            {type === 'Exclusion lists' && show && <AddToExclusion usersSelected={inviteList} onBack={revert} setSuccess={setSuccess}/>}
            {type === 'Plans' && show && <AddToPlan usersSelected={inviteList} onBack={revert} setSuccess={setSuccess}/>}
        </div>
    )
};

ListTab.propTypes = {

};

export default ListTab;
