import React, {useState, useEffect} from 'react';
import PaginationNew from '../shared/pagination/PaginationNew';
import Api from '../../modules/Api';
import KeywordGroupRow from "./KeywordGroupRow";

const KeywordGroupList = ({keywordGroups, pagination, onPaginationChange}) => {

    const headers = [
        {label: "Name", sortField: "groupName"},
        {label: "Keywords", sortField: "keywords"},
        {label: "Last Edited", sortField: "updatedOn"}
    ]

    const changeSort = (newValue) => {
        onPaginationChange({sortField: newValue, sortDirection: "ASC"});
    }

    const changeSortDirection = (sortField) => {
        let newValue;
        if (pagination.sortDirection === "ASC")
            newValue = "DESC";
        else
            newValue = "ASC";

        onPaginationChange({sortField: sortField, sortDirection: newValue});
    }

    const renderHeader = () => {
        return (
            <div className="tableHeader">
                {headers.map(h => renderHeaderLabel(h))}
            </div>
        );
    }

    const renderHeaderLabel = (header) => {
        let customStyle = (header.sortField === pagination.sortField && pagination.sortDirection === "ASC") ? {transform: "translateX(3px) rotate(180deg) translateY(-1px)"} : null;

        return (
            <div key={"header-label-" + header.label} className="column" style={header.sortField === pagination.sortField ? {fontWeight: "bold"} : null}>
                <span onClick={() => changeSort(header.sortField)}>{header.label}</span>
                <img style={customStyle} onClick={() => changeSortDirection(header.sortField)} src={"/images/ic_down14x14.svg"} />
            </div>
        );
    }

    const handlePageChange = (chosenPage) => {
        onPaginationChange({page: chosenPage});
    }

    const limitChangeFunction = (limit) => {
        onPaginationChange({page: 1, pageLimit: limit});
    }

    if (!keywordGroups || keywordGroups.length === 0 || !pagination.totalRecords || pagination.totalRecords === 0)
        return (
            <div className="emptyListInfo">
                No Keyword groups available...
            </div>
        );

    return (
        <>
            <div className="all-users-page brands-list">
                <div className="contentDiv brandDiv" >
                     {renderHeader()}
                </div>
            </div>
            <div className="contentDiv brandDiv scrollableContent">
                {keywordGroups.map(keywordGroup => <KeywordGroupRow key={keywordGroup.id} keywordGroup={keywordGroup} />)}
                <PaginationNew
                    total={pagination.totalRecords}
                    limit={pagination.pageLimit}
                    current={pagination.page}
                    handlePageChange={handlePageChange}
                    limitChangeFunction={limitChangeFunction}
                />
            </div>
        </>
    )
}

export default KeywordGroupList;
