import React from 'react';
import { debounce } from 'lodash';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import Select from 'react-select';
import cx from 'classnames';
import Api from '../../../modules/Api';
import ApiError from '../../shared/apiError/ApiError';
import { fetchFavorites } from '../../../store/actions/favoriteActions';
import './discoverPanel.scss';
import './filterOperation.scss';
import Checkbox from '../../shared/checkbox/Checbox';
import FilterMenu from './FilterMenu';
import Lookup from '../../../modules/Lookup';
import FavoriteMenu from './FavoriteMenu';
import { KeywordsInput } from './KeywordsInput';
import { VIEWER_STR } from '../../../constants/authorities';
import PopUpDialogueBase from '../../shared/popUpDialogueBase/PopUpDialogueBase';
import { getActiveChannelName, getKwFilters } from './DiscoverPanel.helpers';
import { LOOKUP_FILTER_GROUP } from '../../../constants/lookup-constants';
import { sortFilterChange } from '../../../store/campaign/influencerSearch/actionCreators';
import { bestMatch, followersPublic } from '../../../store/campaign/influencerSearch/reducer';
import { bindActionCreators } from 'redux';

const handleOptions = [
    { value: 'Instagram', label: 'Instagram' },
    { value: 'Tiktok', label: 'Tiktok' }
];

class DiscoverPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // filters: [],
            showFilter: false,
            showSelectOption: false,
            keywords: [],
            checkedKeywordFilterId: null,
            showFavorite: false,
            allFiltersData: {
                type: props.searchFilters?.type || 'CONNECTED',
                requiredChannels: [],
                commonFilters: [],
                connectedFilters: [],
                audienceFilters: [],
            },
            keywordFilters: [],
            commonFilters: [],
            connectedFilters: [],
            audienceFilters: [],
            favFiltersOptions: [],
            addedFilters: { CONNECTED: [], PUBLIC: [] },
            selectedFilters: { CONNECTED: [], PUBLIC: [] },
            channelSelect: {
                CONNECTED: {
                    blog: false,
                    facebook: false,
                    instagram: false,
                    tiktok: false,
                    twitter: false,
                    youtube: false,
                },
                PUBLIC: {
                    instagram: true,
                    tiktok: false,
                },
            },
            isKeywordInputFocused: false,
            height: 60,
            showRequestInstagramProfile: false,
            instagramHandle: '',
            showInvalidHandle: false,
            showConfirmRequestProfile: false,
            showRequestReceived: false,
            existingProfile: null,
            showApiError: false,
            apiError: null,
            handleSelected: 'Instagram',
        };

        this.searchBarRef = React.createRef();
    }

    componentDidMount() {
        this.props.fetchFavorites();
        const {searchFilters} = this.props;
        let height = 60;
        if (this.searchBarRef && this.searchBarRef.current) {
            height = this.searchBarRef.current.clientHeight + 24;
        }

        const activePublicChannel = getActiveChannelName(this.state.channelSelect.PUBLIC);

        const keywordFilters = Lookup.getGroupFilters(LOOKUP_FILTER_GROUP.KEYWORDS, searchFilters?.type || 'CONNECTED');

        const defaultSelectedKeywordFilter =
            keywordFilters.find((keywordFilter) => (keywordFilter.label === 'Creator Name' || keywordFilter.label === "Creator's name/bio")) || keywordFilters[0];

        this.setState({
            height,
            keywordFilters,
            checkedKeywordFilterId: defaultSelectedKeywordFilter?.id,
            // HERE
            commonFilters: {
                CONNECTED: Lookup.getGroupFilters(LOOKUP_FILTER_GROUP.COMMON, 'CONNECTED'),
                PUBLIC: Lookup.getGroupFilters(LOOKUP_FILTER_GROUP.COMMON, 'PUBLIC', activePublicChannel),
            },
            //
            connectedFilters: {
                CONNECTED: Lookup.getGroupFilters(LOOKUP_FILTER_GROUP.CONNECTED, 'CONNECTED'),
                PUBLIC: Lookup.getGroupFilters(LOOKUP_FILTER_GROUP.CONNECTED, 'PUBLIC', activePublicChannel),
            },
            audienceFilters: {
                CONNECTED: Lookup.getGroupFilters(LOOKUP_FILTER_GROUP.AUDIENCE, 'CONNECTED'),
                PUBLIC: Lookup.getGroupFilters(LOOKUP_FILTER_GROUP.AUDIENCE, 'PUBLIC', activePublicChannel),
            },
        });
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.hideSelectOptions);
    }

    showSelectOptions = (e) => {
        e.stopPropagation();
        this.setState({ showSelectOption: true });
        window.addEventListener('click', this.hideSelectOptions);
    };

    hideSelectOptions = () => {
        this.setState({ showSelectOption: false });
    };

    clear = (key, waiting) => {
        const { filters } = this.state;
        let newFilters = [];
        if (Array.isArray(key)) {
            newFilters = filters.filter((item) => !key.includes(item.filterId));
        } else {
            newFilters = filters.filter((item) => item.filterId !== key);
        }
        this.setState({ filters: newFilters }, !waiting ? this.applyFilter : null);
    };

    applyFilter = () => {
        const f = this.props.changeFunction;
        const { allFiltersData, favFiltersOptions, keywords, checkedKeywordFilterId } = this.state;

        // remove the formattedAddress when searching
        const filtersData = JSON.parse(JSON.stringify(allFiltersData));
        if (filtersData.commonFilters.length > 0) {
            const location = filtersData.commonFilters.find(
                (cf) => cf.filterId === 4 && cf.json && cf.json.hasOwnProperty('formattedAddress'),
            );
            if (location) {
                delete location.json.formattedAddress;
            }
        }

        if (filtersData.audienceFilters.length > 0) {
            const audienceData = [];
            filtersData.audienceFilters.map((f) => {
                const filterOptionIds = [];
                const customRanges = [];
                f.data.filterOptions.map((fo) => {
                    customRanges.push({
                        field: fo.customValue,
                        maxValue: fo.maxValue,
                        minValue: fo.minValue,
                    });
                    filterOptionIds.push(fo.id);
                });
                audienceData.push({
                    filterId: f.filterId,
                    customRanges,
                    filterOptionIds,
                });
            });
            filtersData.audienceFilters = audienceData;
        }

        const keywordFilters = getKwFilters(keywords, [checkedKeywordFilterId]);

        if (typeof f === 'function') {
            f({ ...filtersData, keywordFilters, favorites: favFiltersOptions });
        }
    };

    handleKeywordChange = (keywords, triggerByDelete) => {
        this.setState({ keywords });

        setTimeout(() => {
            if (this.props.setSearchBarHeight && this.searchBarRef && this.searchBarRef.current) {
                this.props.setSearchBarHeight(this.searchBarRef.current.clientHeight);
                this.setState({ height: this.searchBarRef.current.clientHeight + 24 });
            }
        }, 100);
    };

    handleKeywordFilterCheckboxClick = (id) => {
        const { checkedKeywordFilterId } = this.state;

        const isThisFilterChecked = checkedKeywordFilterId === id;
        if (!isThisFilterChecked) {
            this.setState({ checkedKeywordFilterId: id });
        }
    };

    searchResult = () => {
        const { checkedKeywordFilterId } = this.state;
        if (!checkedKeywordFilterId) {
            return;
        }
        this.applyFilter();
    };

    handleEnter = debounce(() => {
        this.searchResult();
    }, 500);

    activateFavoriteFilter = (list) => {
        if (!list) return;

        const { favFiltersOptions } = this.state;

        const favorite = [];
        const favIndex = favFiltersOptions.indexOf(list.id);
        if (favIndex === -1) {
            favorite.push(list.id);
        }

        this.setState({ favFiltersOptions: favorite }, this.applyFilter);
    };

    favoriteDeleted = () => {
        this.setState({ favFiltersOptions: [] }, this.applyFilter);
    };

    onFiltersUpdate = (
        filters,
        channels,
        source,
        selectedFilters,
        availableCommonFilters,
        availableConnectedFilters,
        availableAudienceFilters,
        search,
    ) => {
        // If source type is changed - update keyword checkbox options
        let keywordFilters = this.state.keywordFilters;
        let checkedKeywordFilterId = this.state.checkedKeywordFilterId;
        if (source !== this.props.searchFilters.type) {
            keywordFilters = Lookup.getGroupFilters(LOOKUP_FILTER_GROUP.KEYWORDS, source);

            const isSelectedKeywordFilterAvailable = keywordFilters.find((filter) => filter.id === checkedKeywordFilterId);

            if (!isSelectedKeywordFilterAvailable) {
                const defaultSelectedKeywordFilter =
                    keywordFilters.find((keywordFilter) => keywordFilter.label === 'Social Hashtag') || keywordFilters[0];
                checkedKeywordFilterId = defaultSelectedKeywordFilter.id;
            }

            this.props.actions.sortFilterChange({ sort: source === 'PUBLIC' ? followersPublic : bestMatch, preventRefresh: true });
        }

        const activePublicChannel = getActiveChannelName(this.state.channelSelect.PUBLIC);

        const commonFilters = Lookup.getGroupFilters(LOOKUP_FILTER_GROUP.COMMON, source, activePublicChannel);
        const connectedFilters = Lookup.getGroupFilters(LOOKUP_FILTER_GROUP.CONNECTED, source, activePublicChannel);
        const audienceFilters = Lookup.getGroupFilters(LOOKUP_FILTER_GROUP.AUDIENCE, source, activePublicChannel);

        const connected = [];
        const common = [];
        const audience = [];

        filters[source].map((f) => {
            if (commonFilters.find((c) => c.id === f.filterId)) {
                return common.push(f);
            }
            if (connectedFilters.find((c) => c.id === f.filterId)) {
                return connected.push(f);
            }
            if (audienceFilters.find((c) => c.id === f.filterId)) {
                return audience.push(f);
            }
        });

        const requiredChannels = Object.entries(channels[source])
            .filter(([_, isChecked]) => isChecked)
            .map(([channelName]) => channelName.toUpperCase());

        const filtersData = {
            type: source,
            requiredChannels,
            connectedFilters: connected,
            commonFilters: common,
            audienceFilters: audience,
        };

        this.setState(
            {
                keywordFilters,
                checkedKeywordFilterId,
                allFiltersData: filtersData,
                addedFilters: filters,
                channelSelect: channels,
                selectedFilters,
                commonFilters: availableCommonFilters,
                connectedFilters: availableConnectedFilters,
                audienceFilters: availableAudienceFilters,
            },
            search ? this.applyFilter : null,
        );
    };

    handleInstagramHandleNameChange = (e) => {
        let { value } = e.target;
        if (!value.startsWith('@')) {
            value = `@${value}`;
        }
        let showInvalidHandle = false;
        if (value === '@' || value.length > 100) {
            showInvalidHandle = true;
        }
        this.setState({
            instagramHandle: value,
            showInvalidHandle,
        });
    };

    continueRequestInstagramProfile = () => {
        this.setState({
            showRequestInstagramProfile: false,
            showConfirmRequestProfile: true,
        });
    };

    submitRequestProfile = () => {
        const { instagramHandle, handleSelected } = this.state;

        Api.sendPublicProfileRequest({
            networkType: handleSelected.toUpperCase(),
            socialHandle: instagramHandle.charAt(0) === '@' ? instagramHandle.substring(1) : instagramHandle,
        })
            .then((res) => {
                if (res.data) {
                    this.setState({
                        showConfirmRequestProfile: false,
                        existingProfile: res.data,
                    });
                } else {
                    this.setState({
                        showConfirmRequestProfile: false,
                        showRequestReceived: true,
                    });
                }
            })
            .catch((err) => {
                this.setState({ showApiError: true, apiError: err });
            });
    };

    gotoExistingProfile = () => {
        const { existingProfile } = this.state;
        const { gotoProfile } = this.props;

        if (existingProfile && gotoProfile) {
            // const linkToDetails = `/influencerDetailPage/${existingProfile.id}?Public`;
            // window.open(linkToDetails, '_blank');
            gotoProfile(existingProfile);
        }

        this.setState({
            existingProfile: null,
            instagramHandle: '',
        });
    };

    renderSearchSelections() {
        const { keywordFilters, checkedKeywordFilterId } = this.state;

        if (keywordFilters.length > 0) {
            return keywordFilters.map((filter) => {
                return (
                    <div key={filter.id} onClick={() => this.handleKeywordFilterCheckboxClick(filter.id)}>
                        <div className={cx('radioButtonCircle', { selected: checkedKeywordFilterId === filter.id })} />

                        {filter.label}
                    </div>
                );
            });
        }
        return null;
    }

    onHandleChange = (val) => {
        const { handleSelected } = this.state;
        if (val.value !== handleSelected) {
            this.setState({ handleSelected: val.value });
        }
    };

    renderRequestInstagramProfile = () => {
        const {
            showRequestInstagramProfile,
            instagramHandle,
            showInvalidHandle,
            showConfirmRequestProfile,
            showRequestReceived,
            existingProfile,
            handleSelected,
        } = this.state;
        const message =
            'You can now request detailed audience insights for public accounts that we currently don’t have on our platform. We are only supporting Instagram and Tiktok at this time. We will be adding other social networks soon.';
        const inputLabel = `${handleSelected} Handle Name`;
        const confirmMessage =
            `Are you sure you want to create ` +
            (handleSelected === 'Instagram' ? 'an' : 'a') +
            ` ${handleSelected} public profile for ${instagramHandle}?`;
        const existingMessage =
            `We already have ` +
            (handleSelected === 'Instagram' ? 'an' : 'a') +
            ` ${handleSelected} account with this handle ${instagramHandle}`;
        const body = (
            <div className="requestMessageContainer">
                <div className="message">{message}</div>
                <div className="handleSelect">
                    <div className="selectLabel">Choose Social Network</div>
                    <Select
                        className="select sort-select"
                        clearable={false}
                        searchable={false}
                        value={handleSelected}
                        single
                        placeholder="Select"
                        closeMenuOnSelect={false}
                        options={handleOptions}
                        onChange={this.onHandleChange}
                    />
                </div>
                <div className="handleInputContainer">
                    <div className="handleLabel">{inputLabel}</div>

                    <input
                        className=""
                        placeholder={`@${handleSelected} Handle Name`}
                        type="string"
                        onChange={(e) => this.handleInstagramHandleNameChange(e)}
                        value={instagramHandle}
                    />
                    {showInvalidHandle && <div className="invalidHandle">Enter a valid handle</div>}
                </div>
            </div>
        );
        const style = { height: 'fit-content', width: '500px' };

        return showRequestInstagramProfile ? (
            <PopUpDialogueBase
                show={showRequestInstagramProfile}
                title="Request Public Profile"
                message={body}
                disableProceed={!instagramHandle || instagramHandle === '@'}
                proceedLabel="Submit Request"
                windowStyle={style}
                proceedFunction={this.continueRequestInstagramProfile}
                cancelFunction={() =>
                    this.setState({ showRequestInstagramProfile: false, instagramHandle: '', handleSelected: 'Instagram' })
                }
            />
        ) : showConfirmRequestProfile ? (
            <PopUpDialogueBase
                show={showConfirmRequestProfile}
                title="Request Public Profile"
                message={confirmMessage}
                proceedLabel="Create"
                windowStyle={{ minHeight: 'auto' }}
                proceedFunction={this.submitRequestProfile}
                cancelFunction={() => this.setState({ showConfirmRequestProfile: false, instagramHandle: '', handleSelected: 'Instagram' })}
            />
        ) : showRequestReceived ? (
            <PopUpDialogueBase
                show={showRequestReceived}
                title="Request Received"
                message="We have received your request and we will notify you via email once the profile is available for viewing."
                proceedLabel="OK"
                hideCancel
                windowStyle={{ minHeight: 'auto' }}
                proceedFunction={() => this.setState({ showRequestReceived: false, instagramHandle: '', handleSelected: 'Instagram' })}
            />
        ) : (
            <PopUpDialogueBase
                show={existingProfile !== null}
                title="Public Profile Exists"
                message={existingMessage}
                proceedLabel="Go to Profile"
                windowStyle={{ minHeight: 'auto' }}
                proceedFunction={this.gotoExistingProfile}
                cancelFunction={() => this.setState({ existingProfile: null, instagramHandle: '' })}
            />
        );
    };

    render() {
        const {
            showFilter,
            showFavorite,
            showSelectOption,
            checkedKeywordFilterId,
            keywords,
            allFiltersData,
            keywordFilters,
            showRequestInstagramProfile,
            connectedFilters,
            commonFilters,
            audienceFilters,
            favFiltersOptions,
            selectedFilters,
            channelSelect,
            addedFilters,
            isKeywordInputFocused,
            height,
            showApiError,
            apiError,
        } = this.state;
        const { disableMultipleInvite, profile } = this.props;

        const isPublicSource = this.state.allFiltersData.type === 'PUBLIC';

        const overlayStyle = {
            overlay: {
                top: height + (disableMultipleInvite ? 60 : 180),
            },
        };

        const panelStyle = {
            top: disableMultipleInvite ? 60 : 120,
        };

        const searchLabel = `Search ${keywordFilters.find((kf) => kf.id === checkedKeywordFilterId)?.label}`;
        return (
            <div className="discoverPanel" style={panelStyle}>
                <div className="discoverPanelContainer">
                    <div className="discoverPanelSearchBox" style={isKeywordInputFocused ? { backgroundColor: '#00998C' } : null}>
                        <div ref={this.searchBarRef} className="discoverPanelSearchSelect" onClick={this.showSelectOptions}>
                            <div className="searchSelectLabel">{searchLabel}</div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path
                                    fill="#999"
                                    fillRule="nonzero"
                                    d="M16.46 9.27a.889.889 0 0 1 1.276 0 .938.938 0 0 1 0 1.305l-5.097 5.154a.888.888 0 0 1-1.278 0l-5.096-5.154a.936.936 0 0 1 0-1.305.889.889 0 0 1 1.276 0l4.46 4.227 4.458-4.227z"
                                />
                            </svg>
                            <div
                                className={cx('searchSelectOptions', { hidden: !showSelectOption })}
                                style={
                                    this.searchBarRef && this.searchBarRef.current
                                        ? { top: `${this.searchBarRef.current.clientHeight + 1}px` }
                                        : null
                                }
                            >
                                <div className="subSelection">{this.renderSearchSelections()}</div>
                            </div>
                        </div>
                        <div className="keywords">
                            <KeywordsInput
                                data={keywords}
                                onChange={this.handleKeywordChange}
                                // onEnter={this.handleEnter}
                                changeIsFocused={(value) => this.setState({ isKeywordInputFocused: value })}
                            />
                        </div>
                        <img src="/images/ic-mag.svg" alt="" onClick={this.searchResult} />
                    </div>
                    <div className="discoverPanelBtnGroup">
                        {this.props.profile && this.props.profile.role !== VIEWER_STR && !isPublicSource && (
                            <div
                                className={cx('discoverPanelBtn', { toggled: showFavorite })}
                                onClick={() =>
                                    this.setState({
                                        showFavorite: !showFavorite,
                                        showFilter: false,
                                        showRequestInstagramProfile: false,
                                        showConfirmRequestProfile: false,
                                    })
                                }
                            >
                                <img src="/images/ic-heart-g.svg" alt="" />
                                <span>Favorites</span>
                            </div>
                        )}
                        <div
                            className={cx('discoverPanelBtn', { toggled: showFilter })}
                            onClick={() =>
                                this.setState({
                                    showFavorite: false,
                                    showFilter: !showFilter,
                                    showRequestInstagramProfile: false,
                                    showConfirmRequestProfile: false,
                                })
                            }
                        >
                            <img src="/images/ic-filters.svg" alt="" />
                            <span>All Filters</span>
                        </div>
                        {/* <div
                            className={cx('discoverPanelBtn', { toggled: showRequestInstagramProfile })}
                            onClick={() =>
                                this.setState({
                                    showFavorite: false,
                                    showFilter: false,
                                    showRequestInstagramProfile: !showRequestInstagramProfile,
                                    showConfirmRequestProfile: false,
                                })
                            }
                        >
                            <span>Request Public Profile</span>
                        </div> */}
                    </div>
                </div>
                <Modal
                    isOpen={showFilter}
                    className="filterMenu"
                    style={overlayStyle}
                    overlayClassName={`filterMenuOverlay${disableMultipleInvite ? ' disableMultipleInvite' : ''}`}
                >
                    <FilterMenu
                        commonFilters={commonFilters}
                        connectedFilters={connectedFilters}
                        audienceFilters={audienceFilters}
                        onUpdate={this.onFiltersUpdate}
                        selectedFilters={selectedFilters}
                        allFiltersData={allFiltersData}
                        channelSelect={channelSelect}
                        addedFilters={addedFilters}
                        disableMultipleInvite={disableMultipleInvite}
                    />
                </Modal>
                <Modal
                    isOpen={showFavorite}
                    style={overlayStyle}
                    className="favoriteMenu"
                    overlayClassName={`filterMenuOverlay${disableMultipleInvite ? ' disableMultipleInvite' : ''}`}
                >
                    <FavoriteMenu
                        activateFilterFunction={this.activateFavoriteFilter}
                        selectedFav={favFiltersOptions}
                        favoriteDeleted={this.favoriteDeleted}
                    />
                </Modal>
                <ApiError
                    show={showApiError}
                    error={apiError}
                    cancelFunction={() => this.setState({ showApiError: false, apiError: null })}
                />
                {this.renderRequestInstagramProfile()}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchFavorites: () => dispatch(fetchFavorites()),
        actions: bindActionCreators(
            {
                sortFilterChange,
            },
            dispatch,
        ),
    };
};

export default connect(null, mapDispatchToProps)(DiscoverPanel);
