import React from 'react';
import Select from 'react-select';
import Checkbox from '../../../../shared/checkbox2022/Checkbox';
import './filters.scss';

function CreatorFilter({ creators, setCreators, updateData }) {
    const numSelectedCreators = creators.filter((creator) => creator.selected).length;
    const allSelected = numSelectedCreators === creators.length;

    const selectValue = allSelected
        ? { label: `All (${creators.length})` }
        : numSelectedCreators
          ? { label: `Selected (${numSelectedCreators})` }
          : { label: 'None' };

    const handleClick = (option) => {
        const allCreators = creators.reduce(
            (list, curr) => [...list, curr.value === option.value ? { ...curr, selected: !curr.selected } : curr],
            [],
        );
        const exclCreators = allCreators.filter((creator) => !creator.selected).map((creator) => creator.value);

        setCreators(allCreators);
        updateData(undefined, exclCreators);
    };

    const handleSelectAll = () => {
        const allCreators = creators.map((creator) => ({ ...creator, selected: !allSelected }));
        const exclCreators = allCreators.filter((creator) => !creator.selected).map((creator) => creator.value);
        setCreators(allCreators);
        updateData(undefined, exclCreators);
    };
    const getCheckboxValue = (option) => creators.find((creator) => creator.value === option.value).selected;

    const menuRenderer = (props) => (
        <div>
            <div className="filter-option" onClick={handleSelectAll}>
                <Checkbox size={20} checked={allSelected} changeFunction={handleSelectAll} controlled fixedSize />
                All ({creators.length})
            </div>
            <div className="line-break"></div>
            {props.options.map((option) => (
                <div className="filter-option" onClick={() => handleClick(option)} key={option.label}>
                    <Checkbox
                        size={20}
                        checked={getCheckboxValue(option)}
                        onClick={() => handleClick(option)}
                        changeFunction={() => handleClick(option)}
                        controlled
                        fixedSize
                    />
                    {option.avatar ? (
                        <img className="profile-icon" src={option.avatar} />
                    ) : (
                        <img className="profile-icon" src="/images/ic-avatar-blank-d.svg" />
                    )}
                    {option.label}
                </div>
            ))}
        </div>
    );

    const valueRenderer = (props) => (
        <div>
            <span className="filter-placeholder">Creators:</span>
            {props.label}
        </div>
    );

    return (
        <Select
            className="sentiment-analytics-filter creator-filter"
            searchable={false}
            value={selectValue}
            options={creators}
            menuRenderer={menuRenderer}
            valueRenderer={valueRenderer}
            clearable={false}
            menuIsOpen
        />
    );
}

export default CreatorFilter;
