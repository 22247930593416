import * as React from 'react';
import './tagsInput.scss';
import cx from 'classnames';

export const TagsInput = ({
    data,
    placeHolder,
    maxLength = 255,
    disabled = false,
    onChange,
    onEnter,
    changeIsFocused,
}) => {
    const [disable, setDisable] = React.useState(disabled);
    const [isFocused, setIsFocused] = React.useState(false);
    const [currentData, setData] = React.useState(data || []);

    const inputRef = React.createRef();

    const handleChange = () => {};

    React.useLayoutEffect(() => {
        setData(data || []);
    }, [data]);

    React.useEffect(() => {
        setDisable(disabled);
    }, [disabled]);

    const handleKeyUp = (keyCode) => {
        if (keyCode === 13) {
            if (inputRef.current.value.length > 0) {
                const update = Object.assign([], currentData);
                update.push(inputRef.current.value);
                setData(update);
                inputRef.current.value = '';
                if (onChange) {
                    onChange(update);
                }
            }
            if (onEnter) {
                onEnter();
            }
        }
    };

    const onBlur = () => {
        if (inputRef.current.value.length > 0) {
            const update = Object.assign([], currentData);
            update.push(inputRef.current.value);
            setData(update);
            inputRef.current.value = '';
            if (onChange) onChange(update);
        }
        setIsFocused(false);
        if (changeIsFocused) changeIsFocused(false);
    };

    const onFocus = () => {
        setIsFocused(true);
        if (changeIsFocused) changeIsFocused(true);
    };

    const onDelete = (index) => {
        const update = Object.assign([], currentData);
        update.splice(index, 1);
        setData(update);
        inputRef.current.value = '';
        if (onChange) {
            onChange(update, true);
        }
    };

    const handleClick = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    return (
        <div
            className={cx('tagsInputContainer')}
            // onMouseOver={() => handleClick()}
            onClick={() => handleClick()}
            style={isFocused ? { borderColor: '#666666'} : null}
        >
            <div className="labelContainer">
                {currentData.map((d, i) => {
                    return (
                        <div key={i} className="inputLabel">
                            {d}
                            <img src="/images/ic-close-d.svg" alt="" onClick={() => onDelete(i)} />
                        </div>
                    );
                })}
                <input
                    placeholder={placeHolder}
                    ref={inputRef}
                    autoFocus
                    // type={inputType}
                    maxLength={maxLength}
                    onChange={handleChange}
                    // value={''}
                    disabled={disable}
                    onKeyUp={({ keyCode }) => handleKeyUp(keyCode)}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    // onMouseOver={() => handleClick()}
                />
            </div>
        </div>
    );
};
