import React from 'react';
import cx from 'classnames';
import './listHeader.scss';


const ListHeader = ({
    orders = {},
    sortBy = 'name',
    onChange,
    titles = {}
}) => {
    return (
        <div className='listHeaderBox'>
            <div className='contentWrapper'>
                <div className='listContainer'>
                    {Object.entries(titles).map(([key, value]) => {
                        return <div
                            className={cx('headerTitle', `${key}`)}
                            key={`${key} ${value}`}
                            onClick={() => orders[key] ? onChange(key) : null}
                            role='button'
                        >
                            <span className={cx({ no: sortBy !== key })}>{value}</span>
                            {orders[key] && <img
                                src="/images/ic_down14x14.svg"
                                className={cx({ up: orders[key] === 'desc', no: sortBy !== key })}
                                alt={`sort-${  value}`}
                            />}
                        </div>
                    })}
                </div>
            </div>
        </div>
    )
}

export default ListHeader;