import {
    FAVORITES_CREATE,
    FAVORITES_READ,
    FAVORITES_UPDATE,
    FAVORITES_DELETE,
    FAVORITE_LOADING,
    FAVORITE_ERROR,
    FAVORITE_SUCCESS,
} from '../types';

import { FAVORITE_REDUCER } from './_Names';
export const name = FAVORITE_REDUCER;

let sortLists =   (a,b) => {
    if ( a.combined) {
        return -1;
    }
    if (b.combined) {
        return 1;
    }
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
    }
    if (b.name.toLowerCase() < a.name.toLowerCase()) {
        return 1;
    }
    return 0;
}

export const favoriteReducer = ( state= {} , action) => {
    switch(action.type){
        case FAVORITE_LOADING:
            return {
                ...state,
                favoriteLoading: action.isLoading
            }
        case FAVORITE_ERROR:
            return {
                ...state,
                favoriteError: action.err
            }
        case FAVORITE_SUCCESS:
            return {
                ...state,
                successFavorites: action.success
            }

        case FAVORITES_UPDATE: {
            let favoriteLists = state.favoriteData;
            let allFavUsers = {};
            let newLists = [];
            let ix = -1;  // catch the index for All Favorites
            for (let i=0; i<favoriteLists.length; i++) {
                let list = favoriteLists[i];
                if (list.combined) {
                    ix = i;
                    newLists.push(list);
                    continue;
                } else if (list.id === action.favorite.id) {
                    list = action.favorite;
                }
                for ( let u of list.users) {
                    allFavUsers[u] = 1;
                }
                newLists.push(list);
            }
            if (ix >= 0) {
                let userlist = [];
                for ( let u in allFavUsers) {
                    userlist.push(u);
                }
                newLists[ix].users = userlist;
            }

            newLists.sort( sortLists);

            let newCount = state.favoriteChangeCount + 1;

            return {
                ...state,
                favoriteData: newLists,
                favoriteChangeCount : newCount
            }
        }
        case FAVORITES_DELETE:
            return{
                ...state,
                favoriteData: state.favoriteData.filter(favorite => favorite.id !== action.favoriteId)
            }
        case FAVORITES_CREATE:
            return{
                ...state,
                favoriteData: [...state.favoriteData, {...action.favorite, completed: true}]
            }
        case FAVORITES_READ: {
            let newList = action.favorite;
            newList.sort(sortLists);

            return {
                ...state,
                favoriteData: newList
            }
        }
        default:
            return state;
    }
}