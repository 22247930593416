import React from 'react'
import './feedbackOnTheTop.scss'
import BreakOutOfDom from '../modalWithSingleAction/BreakOutOfDom'

// props :
// - show
// - closeFunction
// - message
// - interval (not implemented)
//
// It's better than the other one in that you can put it anywhere, it will render outside of the application DOM structure
// (that's what the BreakOutOfDom component does_

export default class FeedbackOnTheTop extends React.Component {

    constructor (props, context) {
        super(props, context)

        this.state = {
        }
    }

    componentWillUnmount() {
        clearTimeout(this.state.timeout);
    }

    static getDerivedStateFromProps(props, state) {
        let newState = {}
        if ( props.show && !state.show) {
            state.timeout = setTimeout(props.closeFunction, 5000);
        }
        newState.show = props.show;
        return newState;
    }

    render () {
        if (!this.props.show) {
            return null;
        }
        return (
            <BreakOutOfDom>
                <div className="feedbackOnTheTop" style={{display:'block'}} onClick={this.props.closeFunction}>
                    <div>
                        <img src="/images/ic-success-w.svg" style={{height: 40, width: 40}}/>
                        {this.props.message}
                    </div>
                </div>
            </BreakOutOfDom>
        );
    }
}
