import React from 'react';
import Modal from 'react-modal';
import Session, { PROP_NETWORK_NAME, PROP_CONNECTION_ERROR, PROP_REDIRECTED } from "../../../modules/utils/Session";
import FeedbackOnTheTop from '../feedbackOnTheTop/FeedbackOnTheTopImproved'
import ErrorMessageBox from '../errorMessageBox/ErrorMessageBoxIntl'
import Format from '../../../modules/utils/Format'

/**
 * This component is used by both profile (Connect.js) and on-boarding (NetworkStep.js),
 * to display the result (error or success) after adding a social network
 *
 * AFter trying to connect to a network the profile / onboard page will reload. At that point this component
 * will evaluate session variables to produce an error / success message. After that the component should be silent.
 * */
export default class SocialConnectionStatus extends React.Component {

    constructor( props,context) {
        super(props.context);

        let network = Session.get(PROP_NETWORK_NAME);
        if (typeof network === 'string') {
            network = Format.capitalize(network);
        }
        const redirected = Session.get(PROP_REDIRECTED);
        const errMsg = Session.get(PROP_CONNECTION_ERROR);

        Session.remove(PROP_CONNECTION_ERROR);
        Session.remove(PROP_NETWORK_NAME);
        Session.remove(PROP_REDIRECTED);

        this.state = {
            showMessage: redirected,
            network,
            errMsg
        };
    }

    // finishAddingNetwork = () => {
    //   this.resetSession();
    //   /* the rendering of error or success dialog done based on */
    //   /* "showSuccessMessage" and "showErrorMessage" determined inside render method */
    //   /* and determined only when showMessage is set to true */
    //   this.setState({showMessage:false});
    // };

    // resetSession = () => {
    // };

    // getDisplayMessage = (network, err) => {
    //   try {
    //     switch (err) {
    //       case "NO_CHANNELS_FOUND":
    //         return `No Youtube channels found!`;
    //       case "NO_PAGES_FOUND":
    //         return `No Facebook business pages found!`;
    //       case "USER_CANCELLED":
    //         return `Adding network ${network} cancelled!`;
    //       default:
    //         if (err.startsWith("RECENT_POSTS::")) {
    //           let recentPostsErrObj = JSON.parse(err.substr("RECENT_POSTS::".length));
    //           if (recentPostsErrObj && (typeof recentPostsErrObj.error !== "undefined")) {
    //             return `Error getting ${network} recent posts: ${recentPostsErrObj.error}`;
    //           }
    //           return `Error getting ${network} recent posts`;
    //         }
    //         break;
    //     }
    //     let errObj = JSON.parse(err);
    //     if (errObj && (typeof errObj.status !== "undefined")) {
    //       let msg = errObj.message;
    //       switch (errObj.status) {
    //         case 400:
    //           msg = (!!msg) ? msg.substring(msg.lastIndexOf('.')+1) : "Bad Request";
    //           if (!!msg) {
    //             msg = msg.trim();
    //           }
    //           return `${msg}`;
    //         case 401:
    //           return `Unauthorized id token`;
    //       }
    //     }
    //   } catch {
    //     return `Error connecting to ${network}`
    //   }
    // };

    render() {
        if (!this.state.showMessage) {
            return null;
        }

        let successMsg = null;
        let showErrorMessage = false;
        const showSuccessMessage = this.state.network && !this.state.errMsg;
        if (showSuccessMessage) {
            successMsg = `${this.state.network} Connected! This network has been added to your profile.`;
            showErrorMessage = false;
        } else {
            showErrorMessage = !!this.state.errMsg;
        }
        return (
            <div>
                <FeedbackOnTheTop
                    show={showSuccessMessage}
                    message={successMsg}
                    closeFunction={() => {
                        this.setState({showMessage: false});
                    }}
                />
                <ErrorMessageBox
                    show={showErrorMessage}
                    apiMsg={this.state.errMsg}
                    messageId="influencerProfile.failedToConnect"
                    closeFunction={() => {
                        this.setState({showMessage: false});
                    }}
                />
            </div>
        )
    }
}
