import React from 'react'
import ReactDOM from 'react-dom'
import Select from 'react-select'
import cx from 'classnames';

import Format from '../../../modules/utils/Format'
import Api from '../../../modules/Api'
import FeedbackOnTheTop from '../../shared/feedbackOnTheTop/FeedbackOnTheTop'
import ApiError from "../../shared/apiError/ApiError";
import FileUpload from './FileUpload'
import CheckedInput from './CheckedInput'
import { states, provinces, countryOptions} from '../../../constants/country';
import ProfileHelper from '../../../modules/utils/ProfileHelper';
import LocationInput from '../../shared/locationInput/LocationInput'

const addressFields = ['street','apt','city','state','zip','country'];

const fields = {
    'street' : {
        placeholder: "Street Name",
        apiField: 'shippingStreet'
    },
    'apt' : {
        placeholder: "Apt, Suite, Blog (optional)",
        apiField: 'shippingApartment',
        optional: true,
    },
    'city' : {
        placeholder : "City",
        apiField : 'shippingCity'
    },
    'state' : {
        placeholder : 'State',
        apiField : 'shippingState'
    },
    'zip' : {
        placeholder : 'ZIP Code',
        apiField : 'shippingZip'
    },
    'country' : {
        placeholder : 'Country',
        apiField : 'shippingCountry'
    },
    'pse' : {
        placeholder: 'Personal Service Entity',
        label: 'Personal Service Entity',
        apiField: 'pseInfo',
    },
    'pseName' : {
        placeholder: 'Name',
        label: 'Name',
    },
    'pseStreet' : {
        placeholder: "Street Name",
        apiField: 'pseStreet'
    },
    'pseApt' : {
        placeholder: "Apt, Suite, Blog (optional)",
        apiField: 'pseApartment',
        optional: true,
    },
    'pseCity' : {
        placeholder : "City",
        apiField : 'pseCity'
    },
    'pseState' : {
        placeholder : 'State',
        apiField : 'pseState'
    },
    'pseZip' : {
        placeholder : 'ZIP Code',
        apiField : 'pseZip'
    },
    'pseCountry' : {
        placeholder: 'Country',
        apiField: 'pseCountry',
    },
    'wformUrl' : {
        label : 'W9 / W8 Form',
        apiField : 'wformUrl'
    },
    'paymentEmail' : {
        label : 'Paypal Account',
        apiField : 'paymentEmail',
        placeholder : 'Paypal Email Address'
    },
    'taxNumber' : {
        label : 'Sales Tax Number',
        apiField : 'taxNumber',
        placeholder : 'Sales Tax Number'
    }
}

export default class Shipping extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.stateOptions = [];
        this.pseStateOptions = [];

        this.state = {
            // firstTime : true,
            shippingUnchanged : true,
            showPSEInfo: props.profile.personalServiceEntity,
            canSavePSEAddress: false,
            apiError: null,
            paymentEmail: props.profile.paymentEmail || '',
            taxNumber: props.profile.taxNumber || '',
            pseName: props.profile.personalServiceEntity ? props.profile.personalServiceEntity.name : '',
            pseStreet: props.profile.personalServiceEntity ? props.profile.personalServiceEntity.addressOne : '',
            pseCity: props.profile.personalServiceEntity ? props.profile.personalServiceEntity.city : '',
            pseApt: props.profile.personalServiceEntity ? props.profile.personalServiceEntity.addressTwo : '',
            pseZip: props.profile.personalServiceEntity ? props.profile.personalServiceEntity.zipCode : '',
            pseState: props.profile.personalServiceEntity ? props.profile.personalServiceEntity.state : '',
            pseCountry: props.profile.personalServiceEntity ? props.profile.personalServiceEntity.country.toUpperCase() : '',
            originPseName: props.profile.personalServiceEntity ? props.profile.personalServiceEntity.name : '',
            originPseStreet: props.profile.personalServiceEntity ? props.profile.personalServiceEntity.addressOne : '',
            originPseCity: props.profile.personalServiceEntity ? props.profile.personalServiceEntity.city : '',
            originPseApt: props.profile.personalServiceEntity ? props.profile.personalServiceEntity.addressTwo : '',
            originPseZip: props.profile.personalServiceEntity ? props.profile.personalServiceEntity.zipCode : '',
            originPseState: props.profile.personalServiceEntity ? props.profile.personalServiceEntity.state : '',
            originPseCountry: props.profile.personalServiceEntity ? props.profile.personalServiceEntity.country.toUpperCase() : '',
            countryList: [],
            loading: false,
            pseloading: false
        }
    }

    componentDidMount() {
        const { profile } = this.props;
        const { pseCountry } = this.state;
        const newState = {}
        for ( const field  in fields) {
            if (!field.startsWith('pse')) {
                const fieldValue = profile[fields[field].apiField] || ''
                if (field === "country" && (fieldValue === "Canada" || fieldValue === "USA")) {
                    newState[field] = fieldValue === "USA" ? "US" : "CA"
                } else if(field === "country") {
                    newState[field] = fieldValue.toUpperCase();
                } else {
                    newState[field] = fieldValue;
                }
            }
        }
        Api.getCountries().then(response => {
            const { data } = response
            const newList = []
            data.forEach(x => {
                const newItem = {
                    label: x.name,
                    value: x.code,
                    regionRequired: x.addressFormat.isRegionRequired,
                    zipRequired: !x.isPostalCodeOptional && x.addressFormat.isPostalCodeRequired,
                    cityLabel: x.cityLabel,
                    regionLabel: x.regionLabel,
                    postalCodeLabel: x.postalCodeLabel,
                    id: x.id
                }
                newList.push(newItem);
                if(x.code === newState.country) {
                    newState.currentInfo = newItem
                }
                if (pseCountry !== "" && pseCountry === x.code) {
                    newState.currentPseInfo = newItem
                }
            })
            newState.countryList = newList
            this.setState(newState, function() {
                this.updateStateOptions();
                this.updateStateOptions('pse');
                // this.stateOptions and this.pseStateOptions are not in state, so need to render after update
                this.forceUpdate();
            });
        })
    }

    updateStateOptions = (value) => {
        const {currentInfo, currentPseInfo } = this.state;
        if (value && value === 'pse' && currentPseInfo) {
            this.pseStateOptions.splice(0);
            this.setState({pseloading: true});
            Api.getRegions(currentPseInfo?.id).then(response => {
                const { data } = response
                data.regions.forEach(x => {
                    this.pseStateOptions.push({label: x.abbreviation, value: x.abbreviation})
                })
                this.setState({pseloading: false});
            })
        } else if (currentInfo) {
            this.stateOptions.splice(0);
            this.setState({loading: true});
            Api.getRegions(currentInfo.id).then(response => {
                const { data } = response
                data.regions.forEach(x => {
                    this.stateOptions.push({label: x.abbreviation, value: x.abbreviation})
                })
                this.setState({loading: false}, this.checkAddressFields);
            })
        }
    }

    checkAddressFields = () => {
        const { currentInfo, shippingUnchanged } = this.state;
        let saveOk = true;
        if (shippingUnchanged) {
            saveOk = false;
        }
        for (const field of addressFields) {
            const value = this.state[field];
            const { optional } = fields[field];
            if (!optional && !this.isFieldValid(value)) {
                if (field !== "state" && field !== "zip") {
                    saveOk = false;
                    break;
                }
                if (field === "state" && currentInfo?.regionRequired) {
                    saveOk = false;
                    break;
                } 
                if (field === "zip" && currentInfo?.zipRequired) {
                    saveOk = false;
                    break;
                }
            }
        }
        this.setState({
            canSaveAddress : saveOk
        })
    }

    isFieldValid = (value) => value && typeof value === 'string' && value.length;

    handleStateSelection = (selected) => {
        this.setState({
            state : selected.value,
            shippingUnchanged : false
        }, this.checkAddressFields)
    }

    handleCountrySelection = (selected) => {
        const { country } = this.state;
        if (selected.value !== country) {
            const newState = {};
            newState.country = selected.value;
            newState.state = '';
            newState.shippingUnchanged = false;
            newState.addressone = ''
            newState.city = ''
            newState.zip = ''
            newState.apt = ''
            newState.street = ''
            newState.currentInfo = selected
            this.setState(newState, function() {
                this.updateStateOptions();
            });
        }
    }

    renderedAddressField = (fieldname, style) => {
        const { currentInfo } = this.state;
        const changeHandler = (e) => {
            const {value} = e.target;
            const newState = {};
            newState[fieldname] = value;
            newState.shippingUnchanged = false;
            this.setState(newState, this.checkAddressFields);
            // todo: perhaps zip code should be numeric only
        }
        let placeHolder = fields[fieldname].placeholder
        if (fieldname === "city" && currentInfo?.cityLabel) {
            placeHolder = currentInfo.cityLabel
        }
        if (fieldname === "zip") {
            if (!currentInfo?.zipRequired) {
                placeHolder = 'ZIP code (optional)'
            }
            if (currentInfo?.postalCodeLabel) {
                placeHolder = currentInfo.postalCodeLabel
            }
        }
        return (
            <input
                placeholder = {placeHolder}
                style = {style}
                value = {this.state[fieldname]}
                onChange = {changeHandler}
            />)
    }

    cancelAddress = () => {
        const { profile } = this.props;
        const { countryList } = this.state
        const newState = {}
        for ( const field of addressFields) {
            newState[field] = this.props.profile[fields[field].apiField] || '';
            const fieldValue = profile[fields[field].apiField] || ''
            if (field === "country" && (fieldValue === "Canada" || fieldValue === "USA")) {
                newState[field] = fieldValue === "USA" ? "US" : "CA"
            } else if(field === "country") {
                newState[field] = fieldValue.toUpperCase();
            } else {
                newState[field] = fieldValue;
            }
        }
        const countryToChange = countryList.find(x => x.value === newState.country)
        this.handleCountrySelection(countryToChange);
        this.setState(newState);
    }

    saveAddress = () => {
        if (!this.state.canSaveAddress) {
            return;
        }
        for ( const field of addressFields) {
            this.props.profile[fields[field].apiField] = this.state[field]
        }
        this.saveProfile('Shipping Address Successfully Saved');
    }

    cancelPSEAddress = () => {
        const { countryList, originPseCountry } = this.state;
        let countryValue = originPseCountry;
        if(originPseCountry === "Canada" || originPseCountry === "USA") {
            countryValue =  originPseCountry === "USA" ? "US" : "CA";
        }
        const countryToChange = countryList.find(x => x.value === countryValue)
        this.handlePSECountrySelection(countryToChange)
        this.setState({
            pseName: this.state.originPseName,
            pseStreet: this.state.originPseStreet,
            pseCity: this.state.originPseCity,
            pseState: this.state.originPseState,
            pseApt: this.state.originPseApt,
            pseZip: this.state.originPseZip,
            pseCountry: this.state.originPseCountry,
        })
    }

    savePSEAddress = () => {
        const { pseName, pseStreet, pseCity, pseState, pseZip, pseApt, pseCountry } = this.state;
        const { onUpdate } = this.props;
        const pse = {};
        pse.pseName = pseName;
        pse.pseAddressOne = pseStreet;
        if (pseApt) {
            pse.pseAddressTwo = pseApt;
        }
        pse.pseCity = pseCity;
        pse.pseState = pseState;
        pse.pseZipCode = pseZip;
        pse.pseCountry = pseCountry;
        pse.pse = true;

        Api.updateUserProfileData(this.props.profile.id, pse)
            .then(res => {
                this.setState({
                    originPseName: this.state.pseName,
                    originPseStreet: this.state.pseStreet,
                    originPseCity: this.state.pseCity,
                    originPseState: this.state.pseState,
                    originPseApt: this.state.pseApt,
                    originPseZip: this.state.pseZip,
                    originPseCountry: this.state.pseCountry,
                    apiError: null,
                })
                if (onUpdate) {
                    onUpdate(res)
                }
            })
            .catch (err => {
                this.setState({
                    apiError: err,
                })
            })
    }

    hasPSEValueChanged = () => {
        const { originPseName, originPseStreet, originPseCity, originPseState, originPseApt, originPseZip, originPseCountry,
            pseName, pseStreet, pseCity, pseState, pseApt, pseZip, pseCountry} = this.state;

        if (originPseName === pseName && originPseStreet === pseStreet && originPseCity === pseCity &&
            originPseState === pseState && originPseZip === pseZip && originPseApt === pseApt && originPseCountry === pseCountry) {
            return false;
        }
        return true;
    }

    handlePSEStateSelection = (selected) => {
        this.setState({
            pseState : selected.value,
        })
    }

    handlePSECountrySelection = (selected) => {
        const { pseCountry } = this.state
        if (selected.value !== pseCountry) {
            this.setState({
                pseCountry: selected.value,
                pseState: '',
                pseAddressOne: '',
                pseCity: '',
                pseZip: '',
                pseApt: '',
                currentPseInfo: selected,
                pseStreet: ''
            }, () => {
                this.updateStateOptions('pse');
            })
        }
    }

    saveProfile = (message) => {
        // todo: Api / redux save call
        // this.props.profile.shippingCountry = "USA";
        return ProfileHelper.saveProfile(this.props.profile).then(
            (savedProfile) => {
                this.setState({
                    shippingUnchanged : true
                }, this.checkAddressFields )
                if (typeof message === 'string') {
                    ReactDOM.render(
                        <FeedbackOnTheTop
                            show
                            closeFunction = {this.closeShippingSuccess}
                            message = {message}
                        />,
                        document.getElementById('messageContainer'))
                }
                return savedProfile;
            }
        )
    }

    handleFormUpload = (url) => {
        this.props.profile[fields.wformUrl.apiField] = url;
        this.saveProfile('Form successfully uploaded').then(savedProfile => {
            this.setState({wformUrl : savedProfile.wformUrl});
        })
    }

    handleFieldChange = (key, newValue) => {
        const fname = `${key  }Check`;
        const error = this[fname](newValue);
        const message = `${  fields[key].label  } successfully saved ( ${  newValue  } )`;
        if (!error) {
            this.props.profile[key] = newValue;
            this.saveProfile(message);
        }
    }

    paymentEmailCheck = (tx) => {
        let error = null;
        if (!Format.validateEmail(tx)) {
            error = 'Please, enter a valid email address'
        }
        this.setState({ paymentEmailError : error})
        return error;
    }

    taxNumberCheck = (tx) => {
        const error = null;
        return error;
    }

    closeShippingSuccess = () => {
        ReactDOM.render(null,
            document.getElementById('messageContainer'))
    }

    changePSE = (value) => {
        const { onUpdate } = this.props;
        if (this.state.originPseName) {
            const data = {};
            data.pse = value;
            data.pseName = this.state.originPseName;
            Api.updateUserProfileData(this.props.profile.id, data)
                .then((res) => {
                    if (!value) {
                        this.setState({
                            pseName: '',
                            pseStreet: '',
                            pseCity: '',
                            pseApt: '',
                            pseState: '',
                            pseZip: '',
                            pseCountry: '',
                            originPseStreet: '',
                            originPseCity: '',
                            originPseApt: '',
                            originPseState: '',
                            originPseZip: '',
                            originPseCountry: '',
                            showPSEInfo: value,
                            apiError: null,
                        })
                        if (onUpdate) {
                            onUpdate(res)
                        }
                    } else {
                        this.setState({
                            showPSEInfo: value,
                            apiError: null,
                        })
                    }
                })
                .catch((err) => {
                    this.setState({
                        apiError: err
                    });
                })
        } else {
            this.setState({
                showPSEInfo: value,
            })
        }
    }

    handleChange = e => {
        const {city, state} = e;
        const item = this.stateOptions.find(x => x.value === state)
        this.setState({city, state: item?.value || null, zip: e.zipCode.replace(/ /g,''), street: e.addressOne.trim(), shippingUnchanged: false}, this.checkAddressFields)
    }

    handleChangeExtra = e => {
        let {city} = e;
        if (this.pseStateOptions.length === 0) {
            city = e.city.length !== 0 ? e.city : e.state;
        }
        this.setState({pseCity: city, pseState: e.state, pseZip: e.zipCode.replace(/ /g,''), pseStreet: e.addressOne.trim()})
    }

    handleChangeWithoutSelect = e => {
        this.setState({street: e.target.value.trim(), shippingUnchanged: false}, this.checkAddressFields)
    }

    handleChangeWithoutSelectPse = e => {
        this.setState({pseStreet: e.target.value.trim()})
    }
    

    render() {
        const { showPSEInfo, pseStreet, pseName, pseCity, pseState, pseZip, pseCountry, apiError, paymentEmail, taxNumber, country, countryList,
            loading, pseloading, currentInfo, currentPseInfo, canSaveAddress, street, wformUrl, state } = this.state;
        const { scrollContainer } = this.props;
        let saveButtonClass = 'button';
        if (!canSaveAddress) {
            saveButtonClass += ' disabled';
        }
        let savePSEButton = 'button';
        const hasNoChanges = this.hasPSEValueChanged();
        if ((!pseName || !pseStreet || !pseCity || !pseCountry || !hasNoChanges) || 
        (!pseZip && (pseCountry === "ca" || pseCountry === "us")) || (!pseState && this.pseStateOptions.length !== 0)) {
            savePSEButton += " disabled";
        }
        if ((!pseZip && currentPseInfo?.zipRequired) || (!pseState && currentPseInfo?.regionRequired)) {
            savePSEButton += " disabled";
        }
        const formUrl = wformUrl;

        const uploadLabel = !formUrl ? 'Upload Form' : 'Replace';
        const Regionlabel = currentInfo?.regionLabel || "Region"
        const PseRegionlabel = currentPseInfo?.regionLabel || "Region"
        const isHidden = !loading && this.stateOptions.length === 0;
        const pseIsHidden = !pseloading && this.pseStateOptions.length === 0;
        return (
            <div>
                <ApiError show={!!apiError} error={apiError} cancelFunction={() => this.setState({apiError: null})} />
                <div className="contentTitle">Shipping</div>
                <div className="shippingAddressBox">
                    <div className="inputBoxes">
                        <div className="shippingInput">
                            <div>
                                <Select
                                    className="select"
                                    clearable={false}
                                    value={country}
                                    placeholder="Country"
                                    options={countryList}
                                    onChange={this.handleCountrySelection}
                                    searchable
                                />
                            </div>
                        </div>
                        <div className="shippingInput">
                            <div className="cityState">
                                <LocationInput original={ {formattedAddress: street}} changeFunction={this.handleChange} useAddress
                                    country={country} scrollContainer={scrollContainer} handleChangeWithoutSelect={this.handleChangeWithoutSelect} />
                            </div>
                        </div>
                        <div className="shippingInput">
                            { this.renderedAddressField('apt', { width: '100%'})}
                        </div>
                        <div className="shippingInput">
                            <div className="cityState">
                                <div>
                                    { this.renderedAddressField('city', { width: '100%'})}
                                </div>
                            </div>
                        </div>
                        {!isHidden && <div className="shippingInput">
                            <div className="cityState">
                                <div >
                                    <Select
                                        className="select"
                                        clearable={false}
                                        value={state}
                                        placeholder={Regionlabel}
                                        options={this.stateOptions}
                                        onChange={this.handleStateSelection}
                                        searchable
                                    />
                                </div>
                            </div>
                        </div>}
                        <div className="shippingInput">
                            { this.renderedAddressField('zip', { width: '100%'})}
                        </div>
                        <div className="addressControls">
                            <div className={saveButtonClass} onClick={this.saveAddress}>
                                Save
                            </div>
                            <div className="button cancel" onClick={this.cancelAddress}>
                                Reset
                            </div>
                        </div>
                    </div>
                    <div className="addressLabel">
                        Shipping Address
                    </div>
                    <div style={{clear:'both'}}/>
                </div>

                <div className="pseContainer">
                    <div className="bigInputBox">
                        <div style={{whiteSpace:'nowrap'}}>
                            { fields.pse.label}
                        </div>
                        <div>
                            <div key={fields.pse.label} className="pseOptions">
                                <label className="pseOptionContainer">YES
                                    <input type="radio" id="yes" name="pseOptions" checked={showPSEInfo} onChange={() => this.changePSE(true)}/>
                                    <span className="customizeRadio"/>
                                </label>
                                <label className="pseOptionContainer">NO
                                    <input type="radio" id="no" name="pseOptions" checked={!showPSEInfo} onChange={() => this.changePSE(false)}/>
                                    <span className="customizeRadio"/>
                                </label>
                            </div>
                        </div>

                    </div>
                    <div className={cx({"active" : showPSEInfo, "inactive": !showPSEInfo})}>
                        <div className="shippingAddressBox pseInfo">
                            <div>
                                <div className="inputBoxes pseName">
                                    <div className="shippingInput">
                                        <input
                                            placeholder = {fields.pseName.placeholder}
                                            style = {{width: '100%'}}
                                            value = {this.state.pseName}
                                            onChange = {(event) => {
                                                const newState = {};
                                                newState.pseName = event.target.value;
                                                this.setState(newState)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="addressLabel">
                                    Name
                                </div>
                            </div>
                            <div>
                                <div className="inputBoxes pseAddress">
                                    <div className="shippingInput">
                                        <div className="cityState">
                                            <div>
                                                <Select
                                                    className="select"
                                                    clearable={false}
                                                    value={this.state.pseCountry}
                                                    placeholder="Country"
                                                    options={countryList}
                                                    onChange={this.handlePSECountrySelection}
                                                    searchable
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="shippingInput">
                                        <div className="cityState">
                                            <LocationInput original={ {formattedAddress: pseStreet}}
                                                changeFunction={this.handleChangeExtra} useAddress country={pseCountry} placeholder="Street Name"
                                                scrollContainer={scrollContainer} handleChangeWithoutSelect={this.handleChangeWithoutSelectPse} />
                                        </div>
                                    </div>
                                    <div className="shippingInput">
                                        <input
                                            placeholder = {fields.pseApt.placeholder}
                                            style = {{width: '100%'}}
                                            value = {this.state.pseApt}
                                            onChange = {(event) => {
                                                this.setState({pseApt: event.target.value})
                                            }}
                                        />
                                    </div>
                                    <div className="shippingInput">
                                        <div className="cityState">
                                            <div>
                                                <input
                                                    placeholder = {fields.pseCity.placeholder}
                                                    style = {{width: '100%'}}
                                                    value = {this.state.pseCity}
                                                    onChange = {(event) => {
                                                        this.setState({pseCity: event.target.value})
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {!pseIsHidden && <div className="shippingInput">
                                        <div className="cityState">
                                            <div >
                                                <Select
                                                    className="select"
                                                    clearable={false}
                                                    value={this.state.pseState}
                                                    placeholder={PseRegionlabel}
                                                    options={this.pseStateOptions}
                                                    onChange={this.handlePSEStateSelection}
                                                    searchable
                                                />
                                            </div>
                                        </div>
                                    </div>}
                                    <div className="shippingInput">
                                        <input
                                            placeholder = {currentPseInfo?.postalCodeLabel || (currentPseInfo?.zipRequired ? 'ZIP code' : 'ZIP code (optional)')}
                                            style = {{width: '100%'}}
                                            value = {this.state.pseZip}
                                            onChange = {(event) => {
                                                this.setState({pseZip: event.target.value})
                                            }}
                                        />
                                    </div>
                                    <div className="addressControls">
                                        <div className={savePSEButton} onClick={this.savePSEAddress}>
                                            Save
                                        </div>
                                        <div className="button cancel" onClick={this.cancelPSEAddress}>
                                            Reset
                                        </div>
                                    </div>
                                </div>
                                <div className="addressLabel">
                                    Address
                                </div>
                            </div>
                            <div style={{clear:'both'}}/>
                        </div>
                    </div>
                </div>

                <div className="bigInputBox">
                    <div style={{whiteSpace:'nowrap'}}>
                        {fields.paymentEmail.label}
                    </div>
                    <div>
                        <CheckedInput
                            initialValue={paymentEmail}
                            changeFunction={
                                (value) => this.handleFieldChange('paymentEmail', value)
                            }
                            errorMessage = {this.state.paymentEmailError}
                            placeholder = {fields.paymentEmail.placeholder}
                        />
                    </div>
                </div>

                <div className="bigInputBox">
                    <div style={{whiteSpace:'nowrap'}}>
                        { fields.wformUrl.label}
                    </div>
                    <div className="formBoxSection">
                        { formUrl &&
                            <div className="link">
                                <a href={formUrl} target="_blank" className="link">Download</a>
                            </div>
                        }
                        <FileUpload handleUpload={this.handleFormUpload} label={uploadLabel} accept=".pdf" />
                    </div>
                </div>

                <div className="bigInputBox">
                    <div style={{whiteSpace:'nowrap'}}>
                        {fields.taxNumber.label}
                    </div>
                    <div>
                        <CheckedInput
                            initialValue={taxNumber}
                            changeFunction={
                                (value) => this.handleFieldChange('taxNumber', value)
                            }
                            errorMessage = {this.state.taxNumberError}
                            placeholder = {fields.taxNumber.placeholder}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
