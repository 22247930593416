import React from 'react';
import PropTypes from 'prop-types';
import style from './styles.module.scss';
import SubmitButton from '../SubmitButton';

const Footer = ({ onCancel, onSubmit, isSubmitDisabled, submitButtonTitle }) => (
    <div className={style.footer}>
        <div
            className={style.cancelButton}
            onClick={onCancel}
        >
            Cancel
        </div>
        <SubmitButton
            isDisabled={isSubmitDisabled}
            onClick={onSubmit}
        >
            { submitButtonTitle }
        </SubmitButton>
    </div>
);

Footer.propTypes = {
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    isSubmitDisabled: PropTypes.bool,
    submitButtonTitle: PropTypes.string,
};

export default Footer;
