// import Auth from './Auth'
import axios from 'axios';
import FileSaver from 'file-saver';
import * as HoneyBadger from 'honeybadger-js';
import moment from 'moment';
import { BRAND_OWNER_STR, SUPER_ADMIN_STR } from '../constants/authorities';
import { authUser } from '../libs/awsLib';
import getConfigs from '../libs/publicConfigs';
import { history } from '../store/configureStore';
import Format from './utils/Format';
import objectSerialize from './utils/objectSerialize';

const fromServerConfig = getConfigs();
const config = fromServerConfig || process.env.uiconfig;

const API_MS_URL = process.env.API_MS_URL || config.API_MS_URL;

const API_DOCUSIGN_URL = process.env.API_DOCUSIGN_URL || config.API_DOCUSIGN_URL;
const cAPI_URL = process.env.APP_URL || config.APP_URL;
const cAPI_URL_noSlash =
    cAPI_URL && cAPI_URL.length > 0 && cAPI_URL.endsWith('/') ? cAPI_URL.substring(0, cAPI_URL.length - 1) : cAPI_URL;

const DEFAULT_ERROR = 'An error occurred. Please try again later.';
const CONTENTTYPE_JSON = 'application/json';
const SKIP_TEXT_HONEYBADGER = ['Failed to get Signer Status', 'No Contract Envelope', 'Default Template Not Set'];

class Api {
    // max chunk size 5MB
    static maxFileChunkSize = 100 * 1024 * 1024;

    static async handleError(error, callback, requestOptions) {
        const obj = { ...error };
        let displayErrorMessage;
        switch (error.status) {
            case 400:
                displayErrorMessage = 'Bad request due to invalid data';
                break;
            case 401:
                displayErrorMessage = 'Unauthorized. Please contact your admin.';
                break;
            case 403:
                displayErrorMessage = 'Looks like you may not have the permissions. Please contact your admin.';
                break;
            case 404:
                displayErrorMessage = "We couldn't find the page you’re looking for";
                break;
            case 405:
                displayErrorMessage = 'The request method is not supported';
                break;
            case 408:
                displayErrorMessage = 'Request timeout';
                break;
            case 423:
                // haven't verified account
                if (!window.location.pathname.includes('/login')) {
                    history.push({ pathname: '/login', state: { accountLocked: true } });
                }
                displayErrorMessage = 'Account is locked';
                break;
            case 500:
                displayErrorMessage = 'The server has encountered an error';
                break;
            case 502:
                displayErrorMessage = 'Bad gateway';
                break;
            case 503:
                displayErrorMessage = 'Service unavailable';
                break;
            case 504:
                displayErrorMessage = 'Network communication error or timeout';
                break;
            default:
                displayErrorMessage = DEFAULT_ERROR;
                break;
        }
        if (error.status === undefined) {
            displayErrorMessage = DEFAULT_ERROR;
        }
        obj.originalMessage = error.message;
        obj.message = displayErrorMessage;
        obj.exception = error.exception;
        if (error && (error.message || error.status || error.statusText)) {
            const errorObj = {};
            let text;
            let exception
            try {
                console.log('Error:', error);
                text = await error.text();
                try {
                    // attempt to parse as json first and get the message from json object
                    const errorJson = JSON.parse(text);
                    if (errorJson && errorJson.message) {
                        text = errorJson.message;
                        exception = errorJson.exception;
                    }
                } catch (e) {
                    // ignore
                }
            } catch (err) {
                // ignore
            }

            if (text) {
                errorObj.text = text;
                obj.text = text;
                if (!obj.originalMessage) {
                    obj.originalMessage = text;
                }
            }
            if (exception) {
                obj.exception = exception;
            }
            if (error.error !== undefined) {
                obj.error = error.error;
                errorObj.error = error.error;
                if (!obj.originalMessage && typeof error.error === 'string') {
                    obj.originalMessage = error.error;
                }
            }
            if (error.url) {
                errorObj.url = error.url;
                obj.url = error.url;
            }
            if (error.message) {
                errorObj.message = error.message;
            }
            if (error.status) {
                errorObj.status = error.status;
                obj.status = error.status;
            }
            if (error.statusText) {
                errorObj.statusText = error.statusText;
                obj.statusText = error.statusText;
            }
            if (error.ok !== undefined) {
                errorObj.ok = error.ok;
                obj.ok = error.ok;
            }
            if (error.type) {
                errorObj.type = error.type;
                obj.type = error.type;
            }
            if (error.redirected !== undefined) {
                errorObj.redirected = error.redirected;
                obj.redirected = error.redirected;
            }
            if (typeof error === 'object' && obj.originalMessage) {
                console.log(`error message: ${obj.originalMessage} status: ${obj.status}`);
            }
            // send it to honeybadger if text is not in the array of SKIP_TEXT_HONEYBADGER
            if (!Object.is(errorObj, {}) && !SKIP_TEXT_HONEYBADGER.includes(text)) {
                HoneyBadger.beforeNotify(function (notice) {
                    notice.context = { ...notice.context, error: errorObj, requestOptions: requestOptions || {} };
                });
                HoneyBadger.notify('Api call', {
                    name: 'Api error',
                    message: 'Diagnose message',
                });
            }
        }

        callback(obj);
    }

    static addSuggestion(assignmentId, suggestion) {
        return Api.postJson(`/api/assignment/${assignmentId}/post/suggestion-data`, suggestion);
    }

    static requestPublic(body) {
        return Api.postJson(`/api/user/public-profile-request`, body);
    }

    static updateSuggestion(assignmentId, suggestion) {
        return Api.putJson(`/api/assignment/${assignmentId}/post/suggestion-data`, suggestion);
    }

    static getAssignmentSuggestion(assignmentId, suggestionId) {
        return Api.getJson(`/api/assignment/${assignmentId}/post/suggestion-data/byDataId/${suggestionId}`);
    }

    static getTransactionPublic(userId) {
        return Api.getJson(`/api/v2/campaign/post-transaction-log/public/${userId}`);
    }

    static getTransactionConnected(userId) {
        return Api.getJson(`/api//v2/campaign/post-transaction-log/connected/${userId}`);
    }

    static getPublicRecord(desc, num, page, sortBy) {
        return Api.getJson(`/api/user/public-profile-requests?desc=${desc}&num=${num}&page=${page}&sortBy=${sortBy}`);
    }

    static addComment(assignmentId, comment) {
        return Api.postJson(`/api/assignment/${assignmentId}/post/suggestion-comment`, comment);
    }

    static removeComment(assignmentId, commentId) {
        return Api.delete(`/api/assignment/${assignmentId}/post/suggestion-comment/${commentId}`);
    }

    static getCommentsThread(assignmentId, threadId) {
        return Api.getJson(`/api/assignment/${assignmentId}/post/suggestion-comment?threadId=${threadId}`);
    }

    static updateComment(assignmentId, comment) {
        return Api.putJson(`/api/assignment/${assignmentId}/post/suggestion-comment`, comment);
    }

    static getPostCollaborators(assignmentId, postId) {
        return Api.getJson(`/api/assignment/${assignmentId}/post/${postId}/collaborators`);
    }

    static getCurrencyList() {
        return Api.getJson('/api/currency/list');
    }
    static getBrandCountry() {
        return Api.getJson('/api/brandcountry/list');
    }
    static getCurrencyCode(currencyCode) {
        return Api.getJson(`/api/currency/${currencyCode}`);
    }

    // static postMarkRead(markReadData) {
    //     return Api.postJson('/api/messaging/read', markReadData);
    // }

    /** End Messages */

    /** Favorites */
    static getFavorites() {
        return Api.getJson('/api/v2/bookmark/list?page=1&num=50&limit=50');
    }

    static createFavorites(bookmark) {
        return Api.postJson('/api/v2/bookmark', bookmark);
    }

    static saveFavorites(bookmarkId, bookmark) {
        return Api.putJson(`/api/v2/bookmark/${bookmarkId}`, bookmark);
    }

    static deleteFavorites(bookmarkId) {
        return Api.delete(`/api/v2/bookmark/${bookmarkId}`);
    }

    // static getFavoriteDetails(bookmarkId) {
    //     return Api.getJson(`/api/v2/bookmark/${bookmarkId}`);
    // }

    /** End Favorites */
    /** New Favorite */
    static getFavoriteDetails(favId) {
        return Api.getJson(`/api/favourites/${favId}`);
    }

    static getUserFavorites(userId, userType) {
        return Api.getJson(`/api/favourites/enrolledlist/${userId}${userType}` ? `?userType=${userType}` : '');
    }

    static updateFavorite(favId, data) {
        return Api.putJson(`/api/favourites/${favId}`, data);
    }

    static deleteFavorite(favId) {
        return Api.delete(`/api/favourites/${favId}`);
    }

    static createFavorite(data) {
        return Api.postJson('/api/favourites/new', data);
    }

    static addUsersFavorite(favId, data) {
        return Api.postJson(`/api/favourites/${favId}/add`, data);
    }

    static removeUsersFavorite(favId, data) {
        return Api.deleteJson(`/api/favourites/${favId}/remove`, data);
    }

    static copyFavorite(favId) {
        return Api.postJson(`/api/favourites/${favId}/copy`);
    }

    static searchFavorite(param = {}) {
        return Api.postJson(`/api/favourites/search`, param);
    }

    /** End new Favorite */
    /** Plans */
    static getPlanDetails(planId) {
        return Api.getJson(`/api/plan/${planId}`);
    }

    static updatePlan(planId, data) {
        return Api.putJson(`/api/plan/${planId}`, data);
    }

    static deletePlan(planId) {
        return Api.delete(`/api/plan/${planId}`);
    }

    static createPlan(data) {
        return Api.postJson('/api/plan/new', data);
    }

    static addUsersPlan(planId, data) {
        return Api.postJson(`/api/plan/${planId}/creator/add`, data);
    }

    static addUsersPlanByList(planId, data) {
        return Api.postJson(`/api/plan/${planId}/creator/addbylist`, data);
    }

    static addUsersToPlanExclusion(planId, data) {
        return Api.postJson(`/api/plan/${planId}/excluded/add`, data);
    }

    static addUsersPlanByExclusion(planId, data) {
        return Api.postJson(`/api/plan/${planId}/excluded/addbylist`, data);
    }

    static removeUsersPlan(planId, data) {
        return Api.deleteJson(`/api/plan/${planId}/creator/remove`, data);
    }

    static removeExcludedUsersPlan(planId, data) {
        return Api.deleteJson(`/api/plan/${planId}/excluded/remove`, data);
    }

    static searchPlans(param = {}) {
        return Api.postJson(`/api/plan/search`, param);
    }

    static getUserPlanList(userId, userType) {
        const query = userType ? `?userType=${userType}` : '';
        return Api.getJson(`/api/plan/enrolled/${userId}${query}`);
    }

    static createPlanNote(planId, param = {}) {
        return Api.postJson(`/api/plan/${planId}/creator/comment`, param);
    }

    static getPlanNotes(planId, type, userId, publicUserType) {
        return Api.getJson(
            `/api/plan/${planId}/creator/comment?type=${type}&userId=${userId}${
                publicUserType ? '&publicUserType=' + publicUserType : ''
            }`,
        );
    }

    static getPlanLikes(planId, userId, type) {
        return Api.getJson(`/api/plan/${planId}/creator/${userId}/likes${type ? '?userType=' + type : ''}`);
    }

    static getPlanDislikes(planId, userId, type) {
        return Api.getJson(`/api/plan/${planId}/creator/${userId}/dislikes${type ? '?userType=' + type : ''}`);
    }

    static createPlanLike(planId, param = {}) {
        return Api.postJson(`/api/plan/${planId}/creator/like`, param);
    }

    static createPlanDislike(planId, param = {}) {
        return Api.postJson(`/api/plan/${planId}/creator/dislike`, param);
    }

    static deletePlanStatus(planId, param = {}) {
        return Api.deleteJson(`/api/plan/${planId}/creator/status`, param);
    }

    /** End Plans */
    /** Exclusion */
    static getExclusions(params = {}) {
        const page = params.page || 1;
        const num = params.num || 10;
        const sortBy = params.sortBy || 'name';
        const desc = params.desc ? true : false;
        const search = params.search || '';
        return Api.getJson(
            `/api/user/exclusion?page=${page}&num=${num}&sortBy=${sortBy}&desc=${desc}&search=${search}`,
        );
    }

    static getExclusionDetails(id) {
        return Api.getJson(`/api/user/exclusion/${id}`);
    }

    static createExclusion(payload) {
        return Api.postJson('/api/user/exclusion', payload);
    }

    static saveExclusion(id, payload) {
        return Api.putJson(`/api/user/exclusion/${id}`, payload);
    }

    static duplicateExclusion(id) {
        return Api.putJson(`/api/user/exclusion/${id}/copy`);
    }

    static deleteExclusion(id) {
        return Api.delete(`/api/user/exclusion/${id}`);
    }

    static addUsersExclusion(id, data) {
        return Api.putJson(`/api/user/exclusion/${id}/addUsers`, data);
    }

    static removeUsersExclusion(id, data) {
        return Api.putJson(`/api/user/exclusion/${id}/removeUsers`, data);
    }

    /** End Exclusion */
    static pgGetLoginUrl(name) {
        return Api.getJson(`/api2/ssoRedirect/${name}`);
    }

    static pgGetToken(code) {
        return Api.postJson(`/api2/oauth2/${code}`, { code });
    }

    static registerUser(body) {
        return Api.postJson(`/api/user/`, body);
    }

    static registerUserNew(payload) {
        return Api.postJson('/api2/register', payload);
    }

    static forgotPassword(email) {
        return Api.postJson(`/api2/forgotpassword/${email}`);
    }

    static updateUserPhoto(userId, file) {
        const url = `/api/user/${userId}/profilepic`;
        const formData = new FormData();
        formData.append('file', file);
        return Api.jsonRequestFormData(url, 'PUT', formData);
    }

    static saveProfile(data, id = null, managerPermission = 'EDIT_INFO') {
        if (id === null) {
            return Api.putJson(`/api/user/${data.id}?managerPermission=${managerPermission}`, data);
        }
        return Api.putJson(`/api/user/${id}?managerPermission=${managerPermission}`, data);
    }

    static getProfile(id) {
        if (id) {
            return Api.getJson(`/api/user/${id}`);
        }
        return Api.getJson(`/api/user/`);
    }

    static getShortProfile() {
        return Api.getJson(`/api/user?shortType=true`);
    }

    static getTenantList() {
        return Api.getJson(`/api/tenant/mylist`);
    }

    static switchTenant(id) {
        return Api.postJson(`/api/tenant/switch/${id}`);
    }

    static updateShippingAddress(userId, address) {
        return Api.putJson(`/api/user/${userId}`, address);
    }

    static updateUserProfileData(userId, data) {
        return Api.putJson(`/api/user/${userId}`, data);
    }

    static updateBrandAddress(brandId, data) {
        return Api.putJson(`/api/user/brand/${brandId}`, data);
    }

    static addBrandCountry(parentId, data) {
        return Api.postJson(`/api/user/brand/parent/${parentId}/country`, data);
    }

    static createBrand(data) {
        return Api.postJson('/api/user/brand/', data);
    }

    static getBrand(brandId) {
        return Api.getJson(`/api/user/brand/${brandId}`);
    }

    static getCountries() {
        return Api.getJson(`/api/location/list`);
    }

    static getRegions(id) {
        return Api.getJson(`/api/location/${id}`);
    }

    static getUserBrands(data, advertiserIds) {
        const {
            pageNumber = 1,
            filter = '',
            status = '',
            num = 10,
            sortBy = ['createdDate', true],
            searchString,
        } = data;
        let url = `/api/user/brand/list?page=${pageNumber}&num=${num}&sortBy=${sortBy[0]}&desc=${sortBy[1]}${filter}${status}`;
        if (advertiserIds) {
            advertiserIds.forEach((id) => (url += `&advertiserId=${id}`));
        }
        if (searchString) {
            url += `&searchString=${searchString}`;
        }
        return Api.getJson(url);
    }

    static getBrandCompetitorsData(competitorIds, dateFrom, dateTo, countryIds, hashtags, keywords) {
        const dateFilter = `&dateFrom=${dateFrom}&dateTo=${dateTo}`;
        const countryFilter = countryIds?.length ? `&countryId=${countryIds}` : '';
        const hashtagsFilter = hashtags?.length ? `&hashtags=${hashtags.join(',')}` : '';
        const keywordsFilter = keywords?.length ? `&keywords=${keywords.join(',')}` : '';
        let url = `/api/v2/brand-competitive-analysis?competitorBrandUids=${competitorIds.join(
            ',',
        )}${dateFilter}${countryFilter}${hashtagsFilter}${keywordsFilter}`;
        return Api.getJson(url);
    }

    static archiveBrand(brandId) {
        return Api.delete(`/api/user/brand/${brandId}`);
    }

    static unarchiveBrand(brandId) {
        return Api.putJson(`/api/user/brand/${brandId}`, { isArchived: false });
    }

    static archiveCampaign(campaignId) {
        return Api.putJson(`/api/campaign/${campaignId}/archive`, { id: campaignId });
    }

    static unarchiveCampaign(campaignId) {
        return Api.putJson(`/api/campaign/${campaignId}/unarchive`, { id: campaignId });
    }

    static getAgentBrands() {
        return Api.getJson(`/api/user/brand/mylist`);
    }

    static getAllDomainData() {
        return Api.getJson(`/api/domain/`)
            .then((dataDomains) => {
                const fakeTranslate = function (txt) {
                    let retval = '';
                    if (txt.toLowerCase() === 'n/a') {
                        retval = 'N/A';
                    } else {
                        const pieces = txt.split('_');
                        for (const piece of pieces) {
                            if (retval.length > 0) {
                                retval += ' ';
                            }
                            retval += Format.capitalize(piece);
                        }
                    }
                    return retval;
                };
                for (const ix in dataDomains) {
                    if (!dataDomains.hasOwnProperty(ix)) {
                        continue;
                    }
                    for (const option of dataDomains[ix]) {
                        option.label = fakeTranslate(option.description ? option.description : option.value);
                        if (Array.isArray(option.subtopics)) {
                            for (const subOption of option.subtopics) {
                                subOption.label = fakeTranslate(subOption.value);
                            }
                        }
                    }
                }
                return dataDomains;
            })
            .catch((err) => {
                console.log(err);
            });
    }

    static triggerRecentPostsUpdate(userId, network) {
        const url = `/api/social_network/${network}/user/${userId}/recent`;
        return Api.getJson(url);
    }

    static getFacebookPageList(userId, idToken) {
        const url = `/api/social_network/facebook/user/${userId}/page_list?idToken=${idToken}`;
        return Api.getJson(url);
    }

    static getFacebookPageListByAccessToken(accessToken) {
        const url = `/api/social_network/instagram/user/page_list?accessToken=${accessToken}`;

        return Api.getJson(url);
    }

    static getInstagramAccountId(pageId, accessToken) {
        const url = `/api/social_network/instagram/user/${pageId}/business_account?accessToken=${accessToken}`;

        return Api.getJson(url);
    }

    static getYoutubeChannel(userId, idToken) {
        const url = `/api/social_network/youtube/user/${userId}/channel?idToken=${idToken}`;
        return Api.getJson(url);
    }

    static addUserNetwork(userId, networkType, idToken = null, customDetails = {}, accessToken = null) {
        const url = `/api/user/${userId}/network`;
        const payload = {
            networkType,
            idToken,
            accessToken,
            networkDetails: customDetails,
        };

        return Api.postJson(url, payload);
    }

    static createAssignment(assignmentObject) {
        const url = '/api/assignment';
        return Api.postJson(url, assignmentObject);
    }

    static getAssignment(id) {
        const url = `/api/assignment/${id}`;
        return Api.getJson(url);
    }

    static getTMAssignment(assignmentId, influencerId) {
        const url = `/api/assignment/talent-manager/${assignmentId}/influencer/${influencerId}`;
        return Api.getJson(url);
    }

    static getAssignmentList(campaignId, type, page, num) {
        let url = `/api/campaign/${campaignId}/assignments`;
        url += page ? `?page=${page}` : '?page=1';
        url += num ? `&num=${num}` : '&num=50';
        if (type) {
            url += `&type=${type}`;
        }
        return Api.getJson(url);
    }

    // get assignment opportunity
    static getAssignmentOpportunityList(option, desc, currentPage, statusFilter) {
        const order = desc ? '&desc=desc' : '';
        return Api.getJson(
            `/api/assignment/opportunities?page=${currentPage}&type=${statusFilter}&sortBy=${option}${order}`,
        );
    }

    // get assignment opportunity
    static getTMAssignmentOpportunityList(option, desc, currentPage, statusFilter) {
        const order = desc ? '&desc=desc' : '';
        return Api.getJson(
            `/api/assignment/talent-manager/opportunities?page=${currentPage}&type=${statusFilter}&sortBy=${option}${order}`,
        );
    }

    // get assignment opportunity by influencer id
    static getTMAssignmentOpportunityListByInfluencer(option, desc, currentPage, statusFilter, influencerId) {
        const order = desc ? '&desc=desc' : '';
        return Api.getJson(
            `/api/assignment/talent-manager/opportunities/influencer/${influencerId}?page=${currentPage}&type=${statusFilter}&sortBy=${option}${order}`,
        );
    }

    // accept or decline opportunity
    static changeOpportunityStatus(assignmentId, uid, input) {
        const url = `/api/assignment/${assignmentId}/user/${uid}`;
        return Api.putJson(url, input);
    }

    // accept or decline opportunity
    static TMChangeOpportunityStatus(assignmentId, influencerId, input) {
        const url = `/api/assignment/talent-manager/${assignmentId}/influencer/${influencerId}`;
        return Api.putJson(url, input);
    }

    static updateAssignmentDateChange(assignmentId, requestId, status) {
        const url = `/api/assignment/${assignmentId}/slot-change-request/${requestId}/status`;
        return Api.putJson(url, { status });
    }

    static requestAssignmentDateChange(assignmentId, influencerId, changeRequest) {
        const url = `/api/assignment/${assignmentId}/slot-change-request/`;

        return Api.postJson(url, changeRequest);
    }

    static submitDraft(uId, paylod) {
        const url = `/api/contents/${uId}/submit_draft`;
        return Api.putJson(url, paylod);
    }

    static submitPublish(uId, paylod) {
        const url = `/api/contents/${uId}/submit_published`;
        return Api.putJson(url, paylod);
    }

    static addBlog(userId, networkType, blog) {
        const url = `/api/user/${userId}/add-blog`;
        const paylod = {
            networkType,
            blogUrl: blog.url,
            blogName: blog.name,
            reach: blog.reach,
            googleAnalyticsViewId: blog.googleAnalyticsViewId,
        };
        return Api.postJson(url, paylod);
    }

    static addTiktok(userId, networkType, data) {
        const url = `/api/user/${userId}/add-tiktok`;
        return Api.postJson(url, { ...data, ...{ networkType } });
    }

    static removeUserNetwork(userId, networkId) {
        const url = `/api/user/${userId}/network/${networkId}`;
        return Api.delete(url);
    }

    static getSocialNetworkStats(userId, networkType) {
        const url = `/api/social_network/${networkType}/user/${userId}`;
        return Api.postJson(url);
    }

    static saveMediaKit(userId, mediaKit) {
        if (mediaKit.id) {
            // update the existing
            return Api.updateMediaKit(userId, mediaKit.id, mediaKit);
        }
        // adding new
        return Api.addNewMediaKit(userId, mediaKit);
    }

    static tmSaveMediaKit(userId, mediaKit) {
        if (mediaKit.id) {
            // update the existing
            return Api.tmUpdateMediaKit(userId, mediaKit.id, mediaKit);
        }
        // adding new
        return Api.tmAddNewMediaKit(userId, mediaKit);
    }

    static addNewMediaKit(userId, mediaKit) {
        const url = `/api/user/${userId}/media-kit`;
        return Api.postJson(url, mediaKit);
    }

    static updateMediaKit(userId, kitId, mediaKit) {
        const url = `/api/user/${userId}/media-kit/${kitId}`;
        return Api.putJson(url, mediaKit);
    }

    static deleteMediaKit(userId, kitId) {
        const url = `/api/user/${userId}/media-kit/${kitId}`;
        return Api.delete(url);
    }

    static tmAddNewMediaKit(userId, mediaKit) {
        const url = `/api/talent-manager/${userId}/media-kit`;
        return Api.postJson(url, mediaKit);
    }

    static tmUpdateMediaKit(userId, kitId, mediaKit) {
        const url = `/api/talent-manager/${userId}/media-kit/${kitId}`;
        return Api.putJson(url, mediaKit);
    }

    static tmDeleteMediaKit(userId, kitId) {
        const url = `/api/talent-manager/${userId}/media-kit/${kitId}`;
        return Api.delete(url);
    }

    static getInfluencers({ source = 'CONNECTED', body }) {
        return Api.postJson(`/api/v2/search/?source=${source}`, body);
    }

    static autoSave(body) {
        return Api.postJson(`/api/search/saved/agent/autosave`, body);
    }

    static postUserPermissions(permissions) {
        return Api.postJson(`/url`, permissions);
    }

    static getFilters(source = 'CONNECTED') {
        return Api.getJson(`/api/v2/search/filters?source=${source}`);
    }

    static searchFilterOptions({ filterId, keyword, limit = 10, ignore }) {
        const searchParams = new URLSearchParams({ filterId, keyword, limit }).toString();
        if (!keyword && !ignore) return Promise.resolve([]);

        return Api.getJson(`/api/v2/search/options?${searchParams}`);
    }

    static getBrandAgents(brandId, roles) {
        let arr;
        let parameters;
        if (roles && Array.isArray(roles)) {
            arr = roles;
        } else {
            // default to brand owner and super admin
            arr = [BRAND_OWNER_STR, SUPER_ADMIN_STR];
        }
        arr.forEach((role) => {
            if (parameters) {
                parameters += `&roles=${role}`;
            } else {
                parameters = `roles=${role}`;
            }
        });
        const url = encodeURI(`/api/agent/${brandId}/agent-list?${parameters}`);
        return Api.getJson(url);
    }

    /**
     * page - integer for page

     num - integer for page size

     type - all | past | current

     key - campaign name pattern. if not show up or with null/empty value, then no name filter

     sortby - date | name which decide which properties will be used for sort

     desc - sort order. If it show up then DESC order, otherwise ASC order.

     original parameter list : (current, num, type, sortBy, sortDirectionDesc) {
     */

    // advertisers
    static getAdvertisers(page, pageSize, sortBy = null, isArchived = false, search = null) {
        let url = `/api/user/advertiser/list?page=${page}&num=${pageSize}&isArchived=${isArchived}`;

        if (search) {
            url = `${url}&searchString=${search}`;
        }

        if (sortBy) {
            url = `${url}&sortBy=${sortBy[0]}&desc=${sortBy[1]}`;
        }

        return Api.getJson(url);
    }

    static removeInfluencerFromAssignment(assignmentId, influencerId) {
        return Api.delete(`/api/assignment/${assignmentId}/user/${influencerId}`);
    }

    static removePublicInfluencerFromAssignment(assignmentId, influencerId) {
        return Api.delete(`/api/assignment/${assignmentId}/public-user/${influencerId}`);
    }

    static getAdvertiser(advertiserId) {
        return Api.getJson(`/api/user/advertiser/${advertiserId}`);
    }

    static archiveAdvertiser(advertiserId) {
        return Api.delete(`/api/user/advertiser/${advertiserId}`);
    }

    static addAdvertiser(data) {
        return Api.postJson('/api/user/advertiser', data);
    }

    static updateAdvertiser(data, advertiserId) {
        return Api.putJson(`/api/user/advertiser/${advertiserId}`, data);
    }

    static getMyAdvertisers() {
        return Api.getJson('/api/user/advertiser/mylist');
    }
    // end

    static getCampaignList(params) {
        let searchString = '';
        for (const key in params) {
            searchString += `${key}=${encodeURIComponent(params[key])}&`;
        }
        const url = `/api/campaign/list?${searchString}`;
        return Api.getJson(url);
    }

    static getCampaignPosts(params) {
        let searchString = '';
        for (const key in params) {
            console.log('adding', key, params);
            searchString += `${key}=${params[key]}&`;
        }
        const url = `/api/campaign/published_post/list?${searchString}`;
        return Api.getJson(url);
    }

    static getAutoSave() {
        return Api.getJson(`/api/search/saved/agent/autosave/last`);
    }

    static getCampaign(campaignId) {
        return Api.getJson(`/api/campaign/${campaignId}`);
    }

    static addCampaign(campaignData) {
        const url = `/api/campaign`;
        return Api.postJson(url, campaignData);
    }

    static updateCampaign(campaignId, campaignData) {
        const url = `/api/campaign/${campaignId}`;
        return Api.putJson(url, campaignData);
    }

    static getCampaignSentimentOverview(assignmentIds, dateFrom, dateTo) {
        const searchParams = new URLSearchParams({
            ...(dateFrom ? { from: dateFrom } : []),
            ...(dateTo ? { to: dateTo } : []),
        }).toString();

        const url = `/api/v2/campaign-sentiment/overview/${assignmentIds}${searchParams ? `?${searchParams}` : ''}`;
        return Api.getJson(url);
    }

    static getCampaignSentiment(assignmentIds, platforms, dateFrom, dateTo, excludedCreators, excludedPosts) {
        const searchParams = new URLSearchParams({
            ...(platforms?.length ? { platforms } : []),
            ...(dateFrom ? { from: dateFrom } : []),
            ...(dateTo ? { to: dateTo } : []),
            ...(excludedCreators?.length ? { excluded_creators: excludedCreators } : []),
            ...(excludedPosts?.length ? { excluded_posts: excludedPosts } : []),
        }).toString();

        const url = `/api/v2/campaign-sentiment/analysis/${assignmentIds}${searchParams ? `?${searchParams}` : ''}`;
        return Api.getJson(url);
    }

    static getCampaignSentimentFilters(assignmentIds, platforms, dateFrom, dateTo) {
        const searchParams = new URLSearchParams({
            ...(platforms?.length ? { platforms } : []),
            ...(dateFrom ? { from: dateFrom } : []),
            ...(dateTo ? { to: dateTo } : []),
        }).toString();

        const url = `/api/v2/campaign-sentiment/filters/${assignmentIds}${searchParams ? `?${searchParams}` : ''}`;
        return Api.getJson(url);
    }

    static getCampaignSentimentComments(
        assignmentIds,
        platforms,
        dateFrom,
        dateTo,
        excludedCreators,
        excludedPosts,
        sortBy,
        sortOrder,
        languages,
        relevancy,
        sentiments,
        keywords,
        perPage,
        page,
    ) {
        const searchParams = new URLSearchParams({
            ...(platforms?.length ? { platforms } : []),
            ...(dateFrom ? { from: dateFrom } : []),
            ...(dateTo ? { to: dateTo } : []),
            ...(excludedCreators?.length ? { excluded_creators: excludedCreators } : []),
            ...(excludedPosts?.length ? { excluded_posts: excludedPosts } : []),
            ...(sortBy ? { sort_by: sortBy } : []),
            ...(sortOrder ? { sort_order: sortOrder } : []),
            ...(languages?.length ? { languageCodes: languages } : []),
            ...(relevancy?.length ? { relevancy } : []),
            ...(sentiments?.length ? { sentiments } : []),
            ...(keywords?.length ? { keywords } : []),
            ...(sortOrder ? { sort_order: sortOrder } : []),
            ...(perPage ? { per_page: perPage } : []),
            ...(page ? { page } : []),
        }).toString();

        const url = `/api/v2/campaign-sentiment/comments/${assignmentIds}${searchParams ? `?${searchParams}` : ''}`;
        return Api.getJson(url);
    }

    static getCampaignSentimentCommentsLanguages(
        assignmentIds,
        platforms,
        dateFrom,
        dateTo,
        excludedCreators,
        excludedPosts,
        keywords,
    ) {
        const searchParams = new URLSearchParams({
            ...(platforms?.length ? { platforms } : []),
            ...(dateFrom ? { from: dateFrom } : []),
            ...(dateTo ? { to: dateTo } : []),
            ...(excludedCreators?.length ? { excluded_creators: excludedCreators } : []),
            ...(excludedPosts?.length ? { excluded_posts: excludedPosts } : []),
            ...(keywords?.length ? { keywords } : []),
        }).toString();

        const url = `/api/v2/campaign-sentiment/commentFilters/${assignmentIds}${
            searchParams ? `?${searchParams}` : ''
        }`;
        return Api.getJson(url);
    }

    static updateCommentSentiment({ campaignId, commentId, sentiment, topics, categories, products }) {
        const url = `/api/v2/campaign-sentiment/comment/${commentId}`;
        return Api.putJson(url, { campaignId, feedback: sentiment, topics, categories, products });
    }

    static getPostAssets({ postUrls, dateFrom, dateTo }) {
        const payload = {
            ...(postUrls ? { urls: postUrls } : {}),
            ...(dateFrom ? { from: dateFrom } : {}),
            ...(dateTo ? { to: dateTo } : {}),
        };

        const url = `/api/v2/campaign/post-assets`;
        return Api.postJson(url, payload);
    }

    static updateAssignment(assignmentId, assignmentData) {
        const url = `/api/assignment/${assignmentId}`;
        return Api.putJson(url, assignmentData);
    }

    static deleteAssignmentDefaultContract(assignmentId) {
        const url = `/api/assignment/${assignmentId}/contract`;
        return Api.delete(url);
    }

    static addAssignmentResource(assignmentId, data) {
        const url = `/api/assignment/${assignmentId}/resource/`;
        return Api.postJson(url, data);
    }

    static generateResourceRecord = async (bucket, fileName, key, mimeType) => {
        const url = '/api/resource/froms3object';
        return Api.postJson(url, { bucket, fileName, key, mimeType });
    };

    static uploadToPreSignUrl = async (preSignUrl, file, publicRead) => {
        const headers = {
            'Content-Type': Api.getFileContentType(file),
        };
        if (publicRead) {
            // TODO NEED TO TEST WITH PRIVATE BUCKET IN FUTURE
            // headers['x-amz-acl'] = 'public-read'
        }

        // do upload stuff
        const options = { headers, method: 'PUT', body: file };
        return fetch(preSignUrl, options)
            .then((data) => {
                if (data && data.status === 200 && (data.statusText || '').toUpperCase() === 'OK') {
                    return data.statusText;
                }

                console.error('preSignUrl uploaded fail');
                return Promise.reject(`Upload failed: ${data.statusText}`);
            })
            .catch((err) => {
                console.error('preSignUrl upload err', err);
                return Promise.reject('Upload Error');
            });
    };

    static getResourcePreSignUrl = async () => {
        const url = '/api/resource/gen_presigned_url';
        return Api.postJson(url, {});
    };

    static startPreSignUploadFlow = async (file, publicRead) => {
        // step 1 - get the presign url
        const preSignUrl =
            (
                (await this.getResourcePreSignUrl().catch((e) => {
                    console.log('failed to get pre-sign url', e);
                    return {};
                })) || {}
            ).url || '';

        if (preSignUrl) {
            // step 2 - upload using presign url
            const result = await this.uploadToPreSignUrl(preSignUrl, file, publicRead);
            if (result !== 'OK') {
                return result;
            }

            // TODO BACKEND SHOULD PROVIDE THESE INSTEAD from getResourcePreSignUrl, NOT SAFE TO PARSE MANUALLY
            const urlObj = new URL(preSignUrl);
            const paths = urlObj.pathname.split('/');
            paths.shift();
            const fileKey = paths.length > 0 ? paths.pop() : '';
            const bucket = paths.join('/');
            // TODO END

            if (bucket && fileKey) {
                // step 3 - create resource ms record when successful uploaded to aws s3
                return await this.generateResourceRecord(bucket, file.name, fileKey, Api.getFileContentType(file));
            }

            return Promise.reject('Could not parse pre-sign URL');
        }
        return Promise.reject('No pre-sign url');
    };

    static uploadResource(file, publicRead, folderPath, contentType, onUploadProgress, onUploadCancel) {
        if (file.size > this.maxFileChunkSize)
            return this.postS3MultipartFileGradually(file, onUploadProgress, onUploadCancel);
        return this.startPreSignUploadFlow(file, publicRead);
    }

    static uploadFile(file, publicRead, onUploadProgress, onUploadCancel) {
        if (file.size > this.maxFileChunkSize)
            return this.postS3MultipartFileGradually(file, onUploadProgress, onUploadCancel);
        return this.preSignUpLoad(file, publicRead, onUploadProgress);
    }

    static preSignUpLoad = async (file, publicRead, onUploadProgress) => {
        // step 1 - get the presign url
        const preSignUrl =
            (
                (await this.getResourcePreSignUrl().catch((e) => {
                    console.log('failed to get pre-sign url', e);
                    return {};
                })) || {}
            ).url || '';

        if (preSignUrl) {
            // step 2 - upload using presign url
            const result = await this.uploadToPreSignUrl(preSignUrl, file, publicRead);
            if (result !== 'OK') {
                return result;
            }

            const urlObj = new URL(preSignUrl);

            if (onUploadProgress) onUploadProgress(99);
            return Promise.resolve({ url: `${urlObj.origin}${urlObj.pathname}` });
        }
        return Promise.reject('No pre-sign url');
    };

    static downloadAssignmentResource(assignmentId, resourceId) {
        const url = `/api/assignment/${assignmentId}/resource/${resourceId}`;
        return Api.getFile(url);
    }

    static getInfluencerShippingAddress(payload) {
        const url = `/api/campaign/${payload.campaignId}/shipping/addresses?influencerIds=${payload.params.influencerIds}`;
        return Api.getFile(url);
    }

    static getInfluencerWFormUrl(payload) {
        const url = `/api/user/w-form/download?influencerIds=${payload}`;
        return Api.getJson(url);
    }

    static deleteCampaign(campaignId) {
        const url = `/api/campaign/${campaignId}`;
        return Api.delete(url);
    }

    static deleteAssignmentResource(assignmentId, resourceId) {
        const url = `/api/assignment/${assignmentId}/resource/${resourceId}`;
        return Api.delete(url);
    }

    static createNewAgent(data) {
        return Api.postJson(`/api/agent`, data);
    }

    static updateAgent(id, data) {
        return Api.putJson(`/api/agent/${id}`, data);
    }

    static getAgent(id) {
        return Api.getJson(`/api/agent/${id}`);
    }

    static getAgentList(num = 10) {
        return Api.getJson(`/api/agent/search?num=${num}`);
    }

    // Get All Assignment
    static getAllAssignments() {
        return Api.getJson(`/api/assignment/getall`);
    }

    // Get Campaign Assignment
    static getCampaignAssignment(campaignId, num = 50) {
        return Api.getJson(`/api/campaign/${campaignId}/assignments?num=${num}`);
    }

    // Get time slot
    static getTimeSlot(assignmentId) {
        return Api.getJson(`/api/calendar_slot?assignmentIds=${assignmentId}`);
    }

    // Create a new assignment
    static createNewAssignmentFlight(assignmentId, data) {
        return Api.postJson(`/api/assignment/${assignmentId}/flight/`, data);
    }

    // Create a new assignment
    static updateAssignmentFlight(assignmentId, assignmentFlightId, data) {
        return Api.putJson(`/api/assignment/${assignmentId}/flight/${assignmentFlightId}/`, data);
    }

    // Get Assignment Flight details
    static getAssignmentFlight(assignmentId) {
        return Api.getJson(`/api/assignment/${assignmentId}/flight/`);
    }

    // Create new slot
    static createNewCalendarSlot(info) {
        return Api.postJson(`/api/calendar_slot/`, info);
    }

    // Delete slot
    static deleteCalendarSlot(slotId) {
        return Api.delete(`/api/calendar_slot/${slotId}`);
    }

    // Move slot
    static moveCalendarSlot(slotId, input) {
        const url = `/api/calendar_slot/${slotId}/move`;
        return Api.putJson(url, input);
    }

    static addPublishedPost(assignmentId, userId, body) {
        return Api.postJson(`/api/assignment/${assignmentId}/public-user/${userId}/posts`, body);
    }

    static publicPayment(assignmentId, userId, body) {
        return Api.postJson(`/api/assignment/${assignmentId}/public-user/${userId}/payment-approval`, body);
    }

    static addAssignmentUser(info, assignmentId, userId) {
        return Api.postJson(`/api/assignment/${assignmentId}/user/${userId}`, info);
    }

    static removeAssignmentUser(assignmentId, userId) {
        return Api.putJson(`/api/assignment/${assignmentId}/user/${userId}`, { action: 'DECLINE' });
    }

    static proposeRate(assignmentId, userId, rates, comment = '', dates = []) {
        return Api.putJson(`/api/assignment/${assignmentId}/user/${userId}`, {
            rates,
            dates,
            comment,
            action: 'NEGOTIATE',
        });
    }

    static TMProposeRate(assignmentId, influencerId, rates, comment = '', dates = []) {
        return Api.putJson(`/api/assignment/talent-manager/${assignmentId}/influencer/${influencerId}`, {
            rates,
            dates,
            comment,
            action: 'NEGOTIATE',
        });
    }

    static getCampaignPublishedPosts(payload) {
        const { campaignId, params } = payload;
        let serializedParams = objectSerialize(params);
        if (serializedParams) {
            serializedParams = `?${serializedParams}`;
        }
        return Api.getJson(`/api/campaign/${campaignId}/published_post/list${serializedParams}`);
    }

    static getCampaignDrafts(payload) {
        const { campaignId, params } = payload;
        let serializedParams = objectSerialize(params);
        if (serializedParams) {
            serializedParams = `&${serializedParams}`;
        }
        return Api.getJson(`/api/campaign/${campaignId}/post/list?type=DRAFT${serializedParams}`);
    }

    static async getAllCampaignDrafts(campaignId, selectedAssignmentIds) {
        let page = 1;
        let totalPages = null;
        const pageLimit = 10;
        const draftsCollection = [];
        do {
            const payload = {
                campaignId,
                params: {
                    num: pageLimit,
                    page,
                    assignmentIds: selectedAssignmentIds,
                },
            };
            const pageResponse = await Api.getCampaignDrafts(payload);
            draftsCollection.push.apply(draftsCollection, pageResponse.results);
            page += 1;
            totalPages = Math.ceil(pageResponse.meta.totalCount / pageLimit);
        } while (page <= totalPages);
        return draftsCollection;
    }

    static async getAllCampaignPublishedPosts(campaignId, selectedAssignmentIds) {
        let page = 1;
        let totalPages = null;
        const pageLimit = 10;
        const draftsCollection = [];
        do {
            const payload = {
                campaignId,
                params: {
                    num: pageLimit,
                    page,
                    assignmentIds: selectedAssignmentIds,
                },
            };
            const pageResponse = await Api.getCampaignPublishedPosts(payload);
            draftsCollection.push.apply(draftsCollection, pageResponse.results);
            page += 1;
            totalPages = Math.ceil(pageResponse.meta.totalCount / pageLimit);
        } while (page <= totalPages);
        return draftsCollection;
    }

    static appoveCampaignVerifyPost(campaignId, userId) {
        return Api.putJson(`/api/campaign/${campaignId}/contents/${userId}/approve_published`);
    }

    static approveDraft(assignmentId, draftId) {
        const url = `/api/assignment/${assignmentId}/post/${draftId}/approve`;
        return Api.putJson(url);
    }

    static updateDraftStatus(assignmentId, draftId, status) {
        const url = `/api/assignment/${assignmentId}/post/${draftId}/update-status`;
        return Api.putJson(url, status);
    }

    // create a draft/post
    static createDraftPost(assignmentId, payload, influencerUid = null) {
        let url = `/api/assignment/${assignmentId}/post`;
        if (influencerUid) {
            url = `${url}?infUid=${influencerUid}`;
        }
        return Api.postJson(url, payload);
    }

    // get a draft/post
    static getDraftPost(assignmentId, postId, influencerUid = null) {
        let url = `/api/assignment/${assignmentId}/post/${postId}`;
        if (influencerUid) {
            url = `${url}?infUid=${influencerUid}`;
        }
        return Api.getJson(url);
    }

    static updateDraftPost(assignmentId, postId, body, influencerUid = null) {
        let url = `/api/assignment/${assignmentId}/post/${postId}`;
        if (influencerUid) {
            url = `${url}?infUid=${influencerUid}`;
        }
        return Api.putJson(url, body);
    }

    static PublishDraftPost(assignmentId, postId, influencerUid = null) {
        let url = `/assignment/${assignmentId}/post/${postId}/publish`;
        if (influencerUid) {
            url = `${url}?infUid=${influencerUid}`;
        }
        return Api.putJson(url);
    }

    static PublishPost(payload) {
        const url = `/api/social_network/post_published`;
        return Api.postJson(url, payload);
    }

    static PublishSinglePost(payload) {
        const url = `/api/social_network/single_post_published`;
        return Api.postJson(url, payload);
    }

    // create a draft/post comment
    static createDraftPostComment(postId, payload) {
        const url = `/api/userpost/${postId}/comment`;
        return Api.postJson(url, payload);
    }

    // get a draft/post comment
    static getDraftPostComment(postId) {
        const url = `/api/userpost/${postId}/comment/list`;
        return Api.getJson(url);
    }

    static resolveDraftPostComment(postId, commentId) {
        const url = `/api/userpost/${postId}/comment/${commentId}/resolve`;
        return Api.putJson(url);
    }

    static uploadAnalyticScreenshots(uuid, form) {
        const url = `/api/social_network/published-posts/${uuid}/upload-analytics-screenshots`;
        return Api.putFile(url, form);
    }

    static updateAnalyticScreenshots(uuid, data) {
        const url = `/api/social_network/published-posts/${uuid}/update-analytics-screenshots`;
        return Api.putJson(url, data);
    }

    static getUserPayments() {
        return Api.getJson('/api/payments');
    }

    static getContractPreview(resourceUrl) {
        return Api.postJson(`/api/signing/template/resource`, { url: resourceUrl });
    }

    static contractTemplateGetList(advertiserId) {
        return Api.getJson(`/api/contract_template/list/${advertiserId}`);
    }

    static contractTemplateAdd(data) {
        return Api.postJson('/api/contract_template', data);
    }

    static contractTemplateUpdate(data) {
        return Api.putJson('/api/contract_template', data);
    }

    static contractMapppingAssignmentAdd(assignmentID, resourceId, data) {
        return Api.postJson(`/api/assignment/${assignmentID}/contract`, { resourceId, data });
    }

    static contractMapppingAssignmentUpdate(assignmentID, resourceId, data) {
        return Api.putJson(`/api/assignment/${assignmentID}/contract`, { resourceId, data });
    }

    static contractMapppingAssignmentGetFile(assignmentID) {
        return Api.getFile(`/api/assignment/${assignmentID}/contract/template`);
    }

    static getAssignmentUser(assignmentID, uid) {
        return Api.getJson(`/api/assignment/${assignmentID}/user/${uid}`);
    }

    static getAssignmentUsersPublic(assignmentID) {
        return Api.getJson(`/api/assignment/${assignmentID}/public-user/`);
    }

    static getAssignmentUserPublic(assignmentID, id) {
        return Api.getJson(`/api/assignment/${assignmentID}/public-user/${id}`);
    }

    static getAssignmentUserContractTemplate(assignmentUserID) {
        return Api.getFile(`/api/assignmentuser/${assignmentUserID}/contract/template`);
    }

    static contractMapppingAssignmentUserAdd(assignmentUserID, resourceId, data) {
        return Api.postJson(`/api/assignmentuser/${assignmentUserID}/contract`, { resourceId, data });
    }

    static contractMapppingAssignmentUserUpdate(assignmentUserID, resourceId, data, status) {
        return Api.putJson(`/api/assignmentuser/${assignmentUserID}/contract`, { resourceId, data, status });
    }

    static contractOfflineUpload(assignmentUserID, contractResourceId, rates, contractValue) {
        return Api.putJson(`/api/assignmentuser/${assignmentUserID}/contract/offline`, {
            contractResourceId,
            rates,
            contractValue,
        });
    }

    static contractValueUpdate(assignmentUserID, contractValue) {
        return Api.putJson(`/api/assignmentuser/${assignmentUserID}/contract/offline/value`, { contractValue });
    }

    static contractOfflineDownload(assignmentUserID, resourceId) {
        return Api.getFile(`/api/assignmentuser/${assignmentUserID}/contract/offline_signed?id=${resourceId}`);
    }

    static contractOfflineDownloadUrl(assignmentUserID, resourceId) {
        return Api.getJson(`/api/assignmentuser/${assignmentUserID}/contract/offline_signed_url?id=${resourceId}`);
    }

    static contractOfflineDownloadUrlPublic(assignmentId, userid, contractId) {
        return Api.getJson(`/api//assignment/${assignmentId}/public-user/${userid}/contract/${contractId}`);
    }

    static contractGenerateContractLink(assignmentID, uid, forcedGenerate, returnPath) {
        return Api.getJson(
            `/api/signing/contract/generatelink/${assignmentID}/${uid}?forced=${forcedGenerate}&returnPath=${returnPath}&returnUrl=${cAPI_URL_noSlash}${returnPath}`,
        );
    }

    static contractGenerateReviewContractLink(assignmentID, uid) {
        return Api.getFileRequest(`/api/signing/contract/generatereviewlink/${assignmentID}/${uid}`);
    }

    static contractGenerateTMReviewContractLink(assignmentID, uid) {
        return Api.getFileRequest(`/api/signing/contract/talent-manager/generatereviewlink/${assignmentID}/${uid}`);
    }

    static talentManagerContractGenerateContractLink(assignmentID, influencerID, forcedGenerate, returnPath) {
        return Api.getJson(
            `/api/signing/contract/talent-manager/generatelink/${assignmentID}/${influencerID}?forced=${forcedGenerate}&returnPath=${returnPath}&returnUrl=${cAPI_URL_noSlash}${returnPath}`,
        );
    }

    static contractEnvelopeStatus(assignmentID, uid) {
        return Api.getJson(`/api/signing/contract/envelope/status/${assignmentID}/${uid}`);
    }

    static contractEnvelopeFile(assignmentID, uid) {
        return Api.getFile(`/api/signing/contract/envelope/getDoc/${assignmentID}/${uid}`);
    }

    static contractEnvelopeFileUrl(assignmentID, uid) {
        return Api.getJson(`/api/signing/contract/envelope/getDoc_url/${assignmentID}/${uid}`);
    }

    static getInfluencerEngagementRecent(influencerID) {
        return Api.getJson(`/api/assignment/engagement/influencer/${influencerID}?recent=true`);
    }

    static getPublicInfluencerDetails(influencerUID, platform = 'INSTAGRAM', planId, campaignId) {
        return Api.getJson(
            `/api/v2/influencer/${influencerUID}?network_type=${platform}${planId ? `&planId=${planId}` : ''}${
                campaignId ? `&campaignId=${campaignId}` : ''
            }`,
        );
    }

    static getInfluencerEngagement(influencerID, params) {
        let searchString = '';
        if (params) {
            for (const key in params) {
                searchString += `${key}=${params[key]}&`;
            }
            if (searchString.charAt(searchString.length - 1) === '&') {
                searchString = searchString.slice(0, -1);
            }
        }
        return Api.getJson(`/api/assignment/engagement/influencer/${influencerID}?${searchString}`);
    }

    static exportInfluencerEngagements(influencerID, year) {
        let searchString = '';
        if (year && year !== 'all') {
            searchString = `?year=${year}`;
        }
        const url = `/api/assignment/engagement/influencer/${influencerID}/export${searchString}`;
        return Api.getFile(url);
    }

    static searchUserList(searchKey, params) {
        let searchString = '';
        if (params) {
            for (const key in params) {
                searchString += `${key}=${params[key]}&`;
            }
            if (searchString.charAt(searchString.length - 1) === '&') {
                searchString = searchString.slice(0, -1);
            }
        }

        const url = `/api/agent/search?searchString=${searchKey}&${searchString}`;
        return Api.getJson(url);
    }

    static deleteUser(userId) {
        const url = `/api/campaign/list ${userId}`;
        return Api.delete(url);
    }

    static approveAutoDetectedPost(connected, postId) {
        const url = `/api/v2/auto-detected-post/approve`;
        return Api.postJson(url, { connected, postId });
    }

    static markSelectedInfluencersAsPaid(payload) {
        const promises = [];
        const results = {};
        Object.keys(payload).forEach((assignmentID) => {
            const UIDs = payload[assignmentID];
            const updatePromise = Api.putJson(`/api/assignment/${assignmentID}/pay-selected`, UIDs)
                .then((res) => {
                    results[assignmentID] = res;
                    console.log(
                        `Assignment users updated to PAID status for uids: ${res} for assignment: ${assignmentID}`,
                    );
                })
                .catch((err) => {
                    results.error = `Failed updating uids: ${UIDs} for assignment: ${assignmentID} with error: ${err}`;
                });
            promises.push(updatePromise);
        });
        return Promise.all(promises).then((responses) => {
            return results;
        });
    }

    static inviteInfluencer(email) {
        const url = `/api/management`;
        return Api.postJson(url, { email });
    }

    static getTMusers(params) {
        let searchString = '';
        for (const key in params) {
            searchString += `${key}=${params[key]}&`;
        }
        searchString = searchString.substring(0, searchString.length - 1);
        const url = `/api/management/users?${searchString}`;
        return Api.getJson(url);
    }

    static getTMuserInfo(influencerId) {
        const url = `/api/management/user/${influencerId}`;
        return Api.getJson(url);
    }

    static getManagementData() {
        const url = `/api/management`;
        return Api.getJson(url);
    }

    static getTMData(id) {
        const url = `/api/user/talent-manager/${id}`;
        return Api.getJson(url);
    }

    static acceptTM(id, permissions) {
        const url = `/api/management/accept/${id}`;
        return Api.putJson(url, permissions);
    }

    static editPermissions(id, permissions) {
        const url = `/api/management/${id}`;
        return Api.putJson(url, permissions);
    }

    static declineTM(id) {
        const url = `/api/management/decline/${id}`;
        return Api.putJson(url);
    }

    static removeTM(id) {
        const url = `/api/management/discard/${id}`;
        return Api.putJson(url);
    }

    static getInfluencerAnalytic() {
        const url = `/api/assignment/analytics/post-analytics`;
        return Api.getJson(url);
    }

    static getCampaignAnalyticSummary(campaignId, assignments, start, end) {
        const timezoneOffset = moment().format('Z') || '';
        const url = `/api/assignment/analytics/campaign-summary/${campaignId}?assignmentIds=${assignments}&start=${start}&end=${end}&timezoneOffset=${encodeURIComponent(
            timezoneOffset,
        )}`;
        return Api.getJson(url);
    }

    static getCampaignAnalyticByChannel(campaignId, assignments, start, end) {
        const timezoneOffset = moment().format('Z') || '';
        const url = `/api/assignment/analytics/channel-summary/${campaignId}?assignmentIds=${assignments}&start=${start}&end=${end}&timezoneOffset=${encodeURIComponent(
            timezoneOffset,
        )}`;
        return Api.getJson(url);
    }

    static getCampaignAnalyticByInfluencer(campaignId, assignments, start, end) {
        const timezoneOffset = moment().format('Z') || '';
        const url = `/api/assignment/analytics/influencers-reach/${campaignId}?assignmentIds=${assignments}&start=${start}&end=${end}&timezoneOffset=${encodeURIComponent(
            timezoneOffset,
        )}`;
        return Api.getJson(url);
    }

    static getCampaignAnalyticByPost(campaignId, assignments, start, end) {
        const assignmentIds = assignments.toString();
        const timezoneOffset = moment().format('Z') || '';
        const url = `/api/assignment/analytics/post-analytics-by-campaign/${campaignId}?assignmentIds=${assignmentIds}&start=${start}&end=${end}&timezoneOffset=${encodeURIComponent(
            timezoneOffset,
        )}`;
        return Api.getJson(url);
    }

    static postJson(url, data) {
        return Api.jsonRequest(url, 'post', data);
    }

    static putJson(url, data) {
        return Api.jsonRequest(url, 'put', data);
    }

    static getJson(url) {
        return Api.jsonRequest(url, 'get');
    }

    static deleteJson(url, data) {
        return Api.jsonRequest(url, 'delete', data);
    }

    static delete(url) {
        return Api.request(url, 'delete');
    }

    static getCampaignDoc() {
        return Api.getJson('api/user/api-docs');
    }

    static deleteAssignment(id) {
        const url = `/api/assignment/${id}`;
        return Api.delete(url);
    }

    static getInstagramRaw(link) {
        return fetch(`/misc/getInstagram/${link}`);
    }

    static getInstagramPhoto(link) {
        return fetch(`/misc/getInstagramPhoto/${link}`);
    }

    static getInstagram(link) {
        return new Promise((success, f) => {
            Api.getInstagramRaw(link).then((res) => {
                res.json().then((data) => {
                    success(data);
                });
            });
        });
    }

    static getResource(link) {
        return fetch(`/misc/resource/${link}`);
    }

    static getCompressedResources(files) {
        return fetch(`/misc/compressedResources`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ files }),
        });
    }

    /**
     * This is for downloading images which is using the response.blob
     * @param {*} link
     */
    static getExternalResource(link) {
        return new Promise((resolve, reject) => {
            fetch(link)
                .then((res) => {
                    res.blob()
                        .then((blob) => {
                            try {
                                const reader = new FileReader();
                                reader.readAsDataURL(blob);
                                reader.onloadend = function (e) {
                                    resolve(e.target.result);
                                };
                                reader.onerror = reject;
                            } catch (e) {
                                reject(e);
                            }
                        })
                        .catch((error) => {
                            reject(error);
                        });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }

    static request(url, method, data, contentType, requireJSONResponse = true, extraOptions = {}) {
        return new Promise(async (resolve, failure) => {
            const token = await authUser();
            const options = {
                ...extraOptions,
                headers: {
                    Authorization: token || '',
                },
                method,
            };
            if (contentType) {
                options.headers['Content-Type'] = contentType;
            }
            if (data) {
                options.body = data;
            }
            fetch(url, options)
                .then(
                    (response) => {
                        if (response.status === 200) {
                            if (contentType === CONTENTTYPE_JSON && requireJSONResponse) {
                                response.json().then(
                                    (json) => {
                                        return resolve(json);
                                    },
                                    () => {
                                        return resolve();
                                    },
                                );
                            } else {
                                return resolve(response);
                            }
                        } else {
                            Api.handleError(response, failure, options);
                        }
                    },
                    (rejectErr) => {
                        Api.handleError(rejectErr, failure, options);
                    },
                )
                .catch((e) => {
                    Api.handleError(e, failure, options);
                });
        });
    }

    static jsonRequest(url, method, data) {
        return Api.request(url, method, JSON.stringify(data), CONTENTTYPE_JSON);
    }

    static reGenerateUrl(url) {
        if (window.location.hostname === 'localhost') {
            return url;
        }
        let newUrl = url;
        if (url.startsWith('/api/')) {
            if (
                newUrl.startsWith('/api/domain') ||
                newUrl.startsWith('/api/user') ||
                newUrl.startsWith('/api/tenant') ||
                newUrl.startsWith('/api/search') ||
                newUrl.startsWith('/api/bookmark') ||
                // || newUrl.startsWith('/api/messaging')
                newUrl.startsWith('/api/agent') ||
                newUrl.startsWith('/api/management') ||
                newUrl.startsWith('/api/talent-manager') ||
                newUrl.startsWith('/api/register') ||
                newUrl.startsWith('/api/forgotpassword')
            ) {
                newUrl = `${API_MS_URL}${newUrl.substring(4)}`;
            } else if (newUrl.startsWith('/api/signing')) {
                newUrl = `${API_DOCUSIGN_URL}${newUrl.substring(4)}`;
            }
        }

        return newUrl;
    }

    static getFileRequest(url) {
        return new Promise((resolve, reject) => {
            Api.request(url, 'GET', null, CONTENTTYPE_JSON, false)
                .then((response) => {
                    return resolve(response.blob());
                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    static jsonRequestFormData(url, method, data) {
        return Api.request(url, method, data, CONTENTTYPE_JSON);
    }

    static postFile(form, url, returnType) {
        return new Promise(async function (resolve, failure) {
            Api.request(url, 'POST', form, null, false)
                .then((response) => {
                    if (!response.ok) {
                        Api.handleError(response, failure);
                        return;
                    }
                    let resPromise;
                    if (returnType === 'blob') {
                        resPromise = response.blob();
                    } else {
                        resPromise = response.json();
                    }
                    resPromise.then((data) => {
                        return resolve(data);
                    });
                })
                .catch((error) => {
                    failure(error);
                });
        });
    }

    static putFile(url, form, returnType) {
        return new Promise(async function (resolve, failure) {
            Api.request(url, 'PUT', form, null, false)
                .then((response) => {
                    if (!response.ok) {
                        Api.handleError(response, failure);
                        return;
                    }
                    let resPromise;
                    if (returnType === 'blob') {
                        resPromise = response.blob();
                    } else {
                        resPromise = response.json();
                    }
                    resPromise.then((data) => {
                        return resolve(data);
                    });
                })
                .catch((error) => {
                    failure(error);
                });
        });
    }

    static getS3FileSize(url) {
        return new Promise(async (resolve, failure) => {
            let fileSize = '';
            const http = new XMLHttpRequest();
            http.open('HEAD', url, false); // false = Synchronous
            http.send(null); // it will stop here until this http request is complete
            if (http.status === 200) {
                fileSize = http.getResponseHeader('content-length');
            }

            resolve(fileSize);
        });
    }

    static uploadToPreSignUrlGradually = async (preSignUrl, file, publicRead, updateFileProgress) => {
        return new Promise(async (resolve, failure) => {
            if (publicRead) {
                // TODO NEED TO TEST WITH PRIVATE BUCKET IN FUTURE
                // headers['x-amz-acl'] = 'public-read'
            }
            const optionHeaders = {
                'Content-Type': Api.getFileContentType(file),
            };

            const uploadRequest = axios.CancelToken.source();

            axios({
                url: preSignUrl,
                method: 'PUT',
                cancelToken: uploadRequest.token,
                data: file,
                headers: optionHeaders,
                onUploadProgress: (progressEvent) => {
                    const totalLength = progressEvent.lengthComputable
                        ? progressEvent.total
                        : progressEvent.target.getResponseHeader('content-length') ||
                          progressEvent.target.getResponseHeader('x-decompressed-content-length');
                    if (totalLength !== null) {
                        const progressPercentage = Math.round((progressEvent.loaded * 100) / totalLength);
                        const isBreak = updateFileProgress(progressPercentage);
                        if (isBreak && uploadRequest) {
                            uploadRequest.cancel('Canceled upload');
                        }
                    }
                },
            })
                .then((response) => {
                    if (response && response.status === 200 && (response.statusText || '').toUpperCase() === 'OK') {
                        resolve('OK');
                    } else {
                        failure(`Upload failed: ${data.statusText}`);
                    }
                })
                .catch((err) => {
                    console.error('preSignUrl upload err', err);
                    failure('Upload Error');
                });
        });
    };

    static postS3FileGradually(file, updateFileProgress, publicRead, onUploadCancel) {
        if (file.size > this.maxFileChunkSize)
            return this.postS3MultipartFileGradually(file, updateFileProgress, onUploadCancel);
        return this.postS3SingleFileGradually(file, updateFileProgress, publicRead);
    }

    static postS3SingleFileGradually(file, updateFileProgress, publicRead) {
        return new Promise(async (resolve, failure) => {
            // step 1 - get the presign url
            const preSignUrl =
                (
                    (await this.getResourcePreSignUrl().catch((e) => {
                        console.log('failed to get pre-sign url', e);
                        return {};
                    })) || {}
                ).url || '';

            if (preSignUrl) {
                // step 2 - upload using presign url
                const result = await this.uploadToPreSignUrlGradually(preSignUrl, file, publicRead, (val) =>
                    updateFileProgress(val),
                );
                if (result !== 'OK') {
                    return result;
                }

                // TODO BACKEND SHOULD PROVIDE THESE INSTEAD from getResourcePreSignUrl, NOT SAFE TO PARSE MANUALLY
                const urlObj = new URL(preSignUrl);
                const paths = urlObj.pathname.split('/');
                paths.shift();
                const fileKey = paths.length > 0 ? paths.pop() : '';
                const bucket = paths.join('/');
                // TODO END

                if (bucket && fileKey) {
                    // step 3 - create resource ms record when successful uploaded to aws s3
                    const resReturn = await this.generateResourceRecord(
                        bucket,
                        file.name,
                        fileKey,
                        Api.getFileContentType(file),
                    );
                    // update that file is finished and has been downloaded
                    resolve(resReturn);
                }

                failure('Could not parse pre-sign URL');
            } else {
                failure('No pre-sign url');
            }
        });
    }

    static getS3FileGradually(url, updateFileProgress, updateFileFinished, errorCallback) {
        const downloadRequest = axios.CancelToken.source();
        const options = {
            url,
            method: 'GET',
            responseType: 'blob',
            headers: {
                'Content-type': CONTENTTYPE_JSON,
                'Cache-Control': 'no-cache',
            },
            cancelToken: downloadRequest.token,
            onDownloadProgress: (progressEvent) => {
                const totalLength = progressEvent.lengthComputable
                    ? progressEvent.total
                    : progressEvent.target.getResponseHeader('content-length') ||
                      progressEvent.target.getResponseHeader('x-decompressed-content-length');
                if (totalLength !== null) {
                    const progressPercentage = Math.round((progressEvent.loaded * 100) / totalLength);
                    const isBreak = updateFileProgress(progressPercentage);
                    if (isBreak && downloadRequest) {
                        downloadRequest.cancel('Canceled download');
                    }
                }
            },
        };
        axios(options)
            .then((response) => {
                if (response.status === 200) {
                    // update that file is finished and has been downloaded
                    updateFileFinished(response);
                } else if (errorCallback) {
                    Api.handleError(response, errorCallback, options);
                }
            })
            .catch((e) => {
                if (errorCallback) {
                    Api.handleError(e, errorCallback, options);
                }
            });
    }

    static postS3MultipartFileGradually(file, updateFileProgress, onUploadCancel) {
        const chunkSize = this.maxFileChunkSize;
        const partsCount = Math.ceil(file.size / chunkSize);

        let multipartUploadInit;
        return Api.postJson(`/api/resource/multipart?count=${partsCount}`, {})
            .then((multipartUploadInitResponse) => {
                if (
                    !multipartUploadInitResponse ||
                    !multipartUploadInitResponse.parts ||
                    multipartUploadInitResponse.parts.length === 0
                )
                    return Promise.reject('Failed to get pre-sign urls!');

                multipartUploadInit = multipartUploadInitResponse;
                return this.uploadFilePartsOneByOneWithRetry(
                    multipartUploadInit,
                    file,
                    Api.getFileContentType(file),
                    updateFileProgress,
                    onUploadCancel,
                );
            })
            .then((partsUploadResponse) => {
                if (!partsUploadResponse || partsUploadResponse.length === 0)
                    return Promise.reject('Failed to upload all file parts!');

                const multipartUploadFinishRequest = {};
                multipartUploadFinishRequest.uploadId = multipartUploadInit.uploadId;
                multipartUploadFinishRequest.bucketName = multipartUploadInit.bucketName;
                multipartUploadFinishRequest.key = multipartUploadInit.key;
                multipartUploadFinishRequest.mimeType = Api.getFileContentType(file);
                multipartUploadFinishRequest.fileName = file.name;
                multipartUploadFinishRequest.parts = multipartUploadInit.parts.map((p) => ({
                    ...p,
                    etag: this.getETagByPartNumber(p.partNumber, partsUploadResponse),
                }));

                return Api.putJson('/api/resource/multipart', multipartUploadFinishRequest);
            })
            .catch((error) => {
                console.log('Error while performing a multipart upload: ', error);
            });
    }

    static getETagByPartNumber(partNumber, partsUploadResponse) {
        const partByNumber = partsUploadResponse.find((partRes) => partRes.config.headers.partNumber == partNumber);
        return partByNumber && partByNumber.headers ? partByNumber.headers.etag.replaceAll('"', '') : null;
    }

    static onMultipartUploadCancellation(uploadRequest, multipartUploadInit) {
        uploadRequest.cancel('Upload canceled!');
        Api.delete(`/api/resource/multipart?key=${multipartUploadInit.key}&uploadId=${multipartUploadInit.uploadId}`);
    }

    static async uploadFilePartsOneByOneWithRetry(
        multipartUploadInit,
        file,
        fileType,
        updateFileProgress,
        onUploadCancel,
    ) {
        const partsInfo = multipartUploadInit.parts;
        const chunkSize = this.maxFileChunkSize;
        const uploadRequest = axios.CancelToken.source();
        const errorMsg = 'Could not upload file part! ';
        if (!partsInfo || partsInfo.length === 0) return Promise.reject(errorMsg);

        async function uploadPart(partInfo, updateFileProgress) {
            return await axios({
                url: partInfo.preSignedUrl,
                method: 'PUT',
                cancelToken: uploadRequest.token,
                data: Api.getFilePartContent(partInfo.partNumber, file),
                headers: {
                    'Content-Type': fileType,
                    partNumber: partInfo.partNumber,
                },
                onUploadProgress: async (progressEvent) => {
                    const isLastPart = partsInfo[partsInfo.length - 1].partNumber === partInfo.partNumber;
                    const lastPartLength = file.size % chunkSize;
                    const regularPartLength = (file.size - lastPartLength) / (partsInfo.length - 1);
                    const progressLoaded = isLastPart
                        ? progressEvent.loaded / lastPartLength
                        : progressEvent.loaded / regularPartLength;
                    const lastPartFullPercentage = (lastPartLength / file.size) * 100;
                    const singlePartFullPercentage = (100 - lastPartFullPercentage) / (partsInfo.length - 1);
                    const partFullPercentage = isLastPart ? lastPartFullPercentage : singlePartFullPercentage;
                    const progressCompletedByPreviousParts =
                        (((partInfo.partNumber - 1) * regularPartLength) / file.size) * 100;
                    const totalProgress = progressCompletedByPreviousParts + partFullPercentage * progressLoaded;
                    const isBreak = updateFileProgress(parseInt(totalProgress.toFixed(2)));

                    if (onUploadCancel && uploadRequest && multipartUploadInit)
                        onUploadCancel(() => Api.onMultipartUploadCancellation(uploadRequest, multipartUploadInit));

                    if (isBreak && uploadRequest && multipartUploadInit)
                        Api.onMultipartUploadCancellation(uploadRequest, multipartUploadInit);
                },
            });
        }

        const results = [];
        for (let i = 0; i < partsInfo.length; i++) {
            try {
                const result = await uploadPart(partsInfo[i], updateFileProgress);
                if (result) results.push(result);
            } catch (error) {
                try {
                    const result = await uploadPart(partsInfo[i], updateFileProgress);
                    if (result) results.push(result);
                    else return Promise.reject(errorMsg);
                } catch (error) {
                    return Promise.reject(errorMsg + error);
                }
            }
        }

        return Promise.resolve(results);
    }

    static getFilePartContent(partNumber, file) {
        const chunkSize = this.maxFileChunkSize;
        const indexValue = partNumber - 1;
        return file.slice(chunkSize * indexValue, chunkSize * indexValue + chunkSize);
    }

    static getS3File(url, name) {
        return new Promise((resolve, failure) => {
            const extraOptions = {
                responseType: 'blob',
            };
            Api.request(url, 'GET', null, CONTENTTYPE_JSON, false, extraOptions)
                .then((response) => {
                    const resPromise = response.blob();
                    resPromise.then((data) => {
                        FileSaver.saveAs(data, name);
                        return resolve();
                    });
                })
                .catch((error) => {
                    failure.apply(error);
                });
        });
    }

    static getS3FileBlob(url) {
        return new Promise(async (resolve, failure) => {
            const extraOptions = {
                responseType: 'blob',
            };
            Api.request(url, 'GET', null, CONTENTTYPE_JSON, false, extraOptions)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    failure(error);
                });
        });
    }

    static getFile(url) {
        return new Promise(function (resolve, failure) {
            Api.request(url, 'GET')
                .then((response) => {
                    if (!response.ok) {
                        Api.handleError(response, failure);
                        return;
                    }
                    if (response.status === 200) {
                        const resPromise = response.blob();
                        resPromise.then((data) => {
                            return resolve(data);
                        });
                    } else {
                        Api.handleError(response, failure);
                    }
                })
                .catch((error) => {
                    failure(error);
                });
        });
    }

    static inviteAgent(payload) {
        return Api.jsonRequest('/api/agent', 'post', payload);
    }

    static removeAgent(uid) {
        return new Promise(function (s, f) {
            s('bravo');
        });
    }

    static updateRoles(uid, roles) {
        return new Promise(function (s, f) {
            s('bravo');
        });
    }

    static getFileContentType(file) {
        if (file.type && file.type.length > 0) return file.type;
        if (file.name && file.name.toLowerCase().includes('.heic')) return 'image/heic';
        return 'application/octet-stream';
    }

    static getKeywordGroupBrandsInfo(page, pageLimit, sortField, sortDirection) {
        page = page || 1;
        pageLimit = pageLimit || 10;
        sortField = sortField || 'brandName';
        sortDirection = sortDirection || 'ASC';
        return Api.getJson(
            `/api/search/keyword-group/brands?page=${page}&pageLimit=${pageLimit}&sortField=${sortField}&sortDirection=${sortDirection}`,
        );
    }

    static getKeywordGroups(brandId, page, pageLimit, sortField, sortDirection) {
        page = page || 1;
        pageLimit = pageLimit || 10;
        sortField = sortField || 'groupName';
        sortDirection = sortDirection || 'ASC';
        return Api.getJson(
            `/api/search/keyword-group?brandId=${brandId}&page=${page}&pageLimit=${pageLimit}&sortField=${sortField}&sortDirection=${sortDirection}`,
        );
    }

    static createKeywordGroup(data) {
        return Api.postJson(`/api/search/keyword-group`, data);
    }

    static updateKeywordGroup(data) {
        return Api.putJson(`/api/search/keyword-group`, data);
    }

    static deleteKeywordGroup(keywordGroupId) {
        return Api.delete(`/api/search/keyword-group?keywordGroupId=${keywordGroupId}`);
    }

    static searchKeywordGroups(searchKeyword, page, pageLimit, sortField, sortDirection) {
        page = page || 1;
        pageLimit = pageLimit || 150;
        sortField = sortField || 'groupName';
        sortDirection = sortDirection || 'ASC';
        const params = searchKeyword ? `&searchKeyword=${searchKeyword}` : '';
        return Api.getJson(
            `/api/search/keyword-group/search?page=${page}&pageLimit=${pageLimit}&sortField=${sortField}&sortDirection=${sortDirection}${params}`,
        );
    }

    static sendInsightRequest(data) {
        return Api.putJson(`/api/socialdata/audience_insight`, data);
    }

    static sendPublicProfileRequest(data) {
        return Api.putJson(`/api/socialdata/public_profile`, data);
    }

    static getAudienceInsightsData(handle, networkType, id) {
        return Api.getJson(
            `/api/v2/audience-insights/${encodeURIComponent(
                handle,
            )}?network_type=${networkType?.toUpperCase()}&id=${id}`,
        );
    }

    static getPublicSourceAudienceInsightsData(uid, networkType = 'INSTAGRAM') {
        return Api.getJson(`/api/v2/audience-insights/${uid}?network_type=${networkType?.toUpperCase()}`);
    }

    static getUserPost(data) {
        return Api.postJson('/api/v2/search/userpost', data);
    }

    static getDraftPostComments(postId, page, pageLimit, sortField, sortDirection) {
        page = page || 1;
        pageLimit = pageLimit || 10;
        sortField = sortField || 'updatedOn';
        sortDirection = sortDirection || 'DESC';
        return Api.getJson(
            `/api/userDraftPostComments/list/${postId}?page=${page}&pageLimit=${pageLimit}&sortField=${sortField}&sortDirection=${sortDirection}`,
        );
    }

    static addDraftPostComments(data) {
        return Api.postJson(`/api/userDraftPostComments`, data);
    }

    static deleteDraftPostComment(id) {
        return Api.delete(`/api/userDraftPostComments/${id}`);
    }

    static updateDraftPostComment(id, data) {
        return Api.putJson(`/api/userDraftPostComments/${id}`, data);
    }

    static getDraftPostResourceComments(postId, resourceId, page, pageLimit, sortField, sortDirection) {
        page = page || 1;
        pageLimit = pageLimit || 10;
        sortField = sortField || 'updatedOn';
        sortDirection = sortDirection || 'DESC';
        return Api.getJson(
            `/api/userDraftPostResourceComments/list/${postId}/${resourceId}?page=${page}&pageLimit=${pageLimit}&sortField=${sortField}&sortDirection=${sortDirection}`,
        );
    }

    static addDraftPostResourceComments(data) {
        return Api.postJson(`/api/userDraftPostResourceComments`, data);
    }

    static deleteDraftPostResourceComment(id) {
        return Api.delete(`/api/userDraftPostResourceComments/${id}`);
    }

    static updateDraftPostResourceComment(id, data) {
        return Api.putJson(`/api/userDraftPostResourceComments/${id}`, data);
    }

    static async getAllUserDraftPostResources(userDraftPostId) {
        // get max 1000 records
        return Api.getJson(`/api/draftpostresource/${userDraftPostId}/list?size=1000`);
    }

    static async getAllUserDraftPostResourceLogs(userDraftPostId) {
        // get max 1000 records
        return Api.getJson(`/api/draftpostresource/${userDraftPostId}/actionlog/list?size=1000`);
    }

    static userDraftPostResourceAction(userDraftPostId, resourceId, action) {
        return Api.postJson(`/api/draftpostresource/${userDraftPostId}/resource/${resourceId}/${action}`);
    }

    static nudgeUsers(userId, customMessage, assignmentId) {
        const data = {
            userId,
            customMessage,
            assignmentId,
        };
        return Api.postJson(`/api/assignment/nudge`, data);
    }

    static getContents(obj) {
        const retObj = {};
        try {
            if (typeof obj === 'object') {
                for (const p in obj) {
                    if (typeof p === 'object') {
                        const sub = Api.getKeyValuePairs(p);
                        retObj = { ...retObj, ...sub };
                    } else {
                        retObj[`${p}`] = `${obj[p]}`;
                    }
                }
            }
        } catch (err) {}
        return retObj;
    }

    static inviteInfluencers(assignmentId, data) {
        return Api.postJson(`/api/assignment/${assignmentId}/invite_influencers`, data);
    }

    static inviteInfluencersPublic(assignmentId, data) {
        return Api.postJson(`/api/assignment/${assignmentId}/invite_public_influencers`, data);
    }

    static inviteInfluencerUpdate(userId, data) {
        return Api.putJson(`/api/assignmentuser/${userId}/invite_update`, data);
    }

    static updateOtherDocuments(assignmentUserId, data) {
        return Api.putJson(`/api/assignmentuser/${assignmentUserId}/other-documents`, data);
    }

    static inviteInfluencerUpdatePublic(assignmentId, publicUserid, data) {
        return Api.putJson(`/api/assignment/${assignmentId}/public-user/${publicUserid}`, data);
    }

    static publishedPostPublicUpdate(assignmentId, publicUserid, data) {
        return Api.putJson(`/api/assignment/${assignmentId}/public-user/${publicUserid}/posts`, data);
    }

    static publishedPostCreatorUpdate(postId, data) {
        return Api.putJson(`/api/v2/campaign/connected-user/post-url/${postId}`, data);
    }

    static publishedPostCreatorArchive(postId, data) {
        return Api.putJson(`/api/v2/campaign/connected-user/post/archive/${postId}`, data);
    }

    static revokeInfluencer(userId) {
        return Api.postJson(`/api/assignmentuser/${userId}/invite_revoke`);
    }

    static accountVerify(code) {
        return Api.postJson(`/api2/account_verify/confirm`, { code });
    }

    static accountVerifyRequest(email) {
        const data = {
            email,
        };
        return Api.postJson(`/api2/account_verify/request`, data);
    }

    static getRecentPosts({ userIdOrUid, networkType, sourceType }) {
        return Api.getJson(
            `/api/v2/search/recentposts/${userIdOrUid}/${networkType}${sourceType ? `?source=${sourceType}` : ''}`,
        );
    }

    static getTopPosts({ userUid, networkType }) {
        return Api.getJson(`/api/v2/search/topposts/${userUid}/${networkType}`);
    }

    static getAgentSavedSearches(params = {}) {
        const page = params.page || 1;
        const num = params.num || 10;
        const sortBy = params.sortBy || 'updatedOn';
        const desc = params.desc ? true : false;
        const search = params.search || '';
        return Api.getJson(
            `/api/search/saved/agent?page=${page}&num=${num}&sortBy=${sortBy}&desc=${desc}&search=${search}`,
        );
    }

    static createAgentSavedSearch(data) {
        return Api.postJson('/api/search/saved/agent', data);
    }

    static getAgentSavedSearchById(id) {
        return Api.getJson(`/api/search/saved/agent/${id}`);
    }

    static updateAgentSavedSearchById(id, data) {
        return Api.putJson(`/api/search/saved/agent/${id}`, data);
    }

    static deleteAgentSavedSearch(id) {
        return Api.delete(`/api/search/saved/agent/${id}`);
    }

    static initializeSavedSearchSynchronization() {
        Api.postJson('/api/search/saved/agent/sync')
            .then(() => {
                console.log('Agent Saved Search Synchronization has been completed successfully!');
                return Api.postJson('/api/search/saved/plan/sync');
            })
            .then(() => {
                console.log('Plan Saved Search Synchronization has been completed successfully!');
            })
            .catch((e) => {
                console.log('Saved Search Synchronization has failed! Error: ' + JSON.stringify(e));
            });
    }

    static getPlanSavedSearches(params = {}) {
        const page = params.page || 1;
        const num = params.num || 10;
        const sortBy = params.sortBy || 'name';
        const desc = params.desc ? true : false;
        const search = params.search || '';
        const planId = params.planId || '';
        return Api.getJson(
            `/api/search/saved/plan?planId=${planId}&page=${page}&num=${num}&sortBy=${sortBy}&desc=${desc}&search=${search}`,
        );
    }

    static createPlanSavedSearch(planId, data) {
        return Api.postJson(`/api/search/saved/plan?planId=${planId}`, data);
    }

    static getPlanSavedSearchById(id) {
        return Api.getJson(`/api/search/saved/plan/${id}`);
    }

    static updatePlanSavedSearchById(id, data) {
        return Api.putJson(`/api/search/saved/plan/${id}`, data);
    }

    static deletePlanSavedSearch(id) {
        return Api.delete(`/api/search/saved/plan/${id}`);
    }

    static getTopicTensorSuggestions(filterId, data) {
        return Api.postJson(`/api/v2/search/filter/${filterId}/suggestion`, data);
    }

    static getPlanSummary(planID) {
        return Api.getJson(`/api/v2/plan/summary/${planID}`);
    }

    static getUniqueReach(planID) {
        return Api.getJson(`/api/v2/plan/overlap/${planID}`);
    }

    static validateCompetitiveBrand(brandHandle) {
        const params = new URLSearchParams();
        params.append('socialHandle', brandHandle);

        return Api.getJson(`/api/v2/competitor-brand/validate/?${params.toString()}`);
    }

    static getBrandCompetitors(brandId) {
        return Api.getJson(`/api/user/brand/${brandId}/competitors`);
    }
}

export default Api;
