export default class PendoHelper {
    static pendoInit(profile) {
        let visitorId = '';
        if (window.location.hostname === 'localhost') {
            visitorId = 'localhost-';
        } else if (window.location.hostname === 'secure.koalifyed.com') {
            visitorId = 'prod-';
        } else if (window.location.hostname === 'koalifyed-staging-ui.stgtchinfra.com') {
            visitorId += 'staging-';
        } else if (window.location.hostname === 'koalifyed-development-ui.stgtchinfra.com/') {
            visitorId = 'dev-';
        }
        visitorId += profile.uid;
        if (pendo && pendo.isReady && pendo.isReady() && pendo.getVisitorId() === visitorId) {
            console.log('already init, return')
            return;
        }
        pendo.initialize({
            visitor: {
                id: visitorId,
                email: profile.email,
                full_name: profile.firstname + profile.lastname,
                role: profile.role
                // id: 'VISITOR-UNIQUE-ID'   // Required if user is logged in, default creates anonymous ID
                // email:        // Recommended if using Pendo Feedback, or NPS Email
                // full_name:    // Recommended if using Pendo Feedback
                // role:         // Optional

                // You can add any additional visitor level key-values here,
                // as long as it's not one of the above reserved names.
            },

            account: {
                id: (profile.activeTenant && profile.activeTenant.id) || 'ACCOUNT-UNIQUE-ID', // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
                name: (profile.activeTenant && profile.activeTenant.name) || 'name'
                // is_paying:    // Recommended if using Pendo Feedback
                // monthly_value:// Recommended if using Pendo Feedback
                // planLevel:    // Optional
                // planPrice:    // Optional
                // creationDate: // Optional

                // You can add any additional account level key-values here,
                // as long as it's not one of the above reserved names.
            }
        });
        // console.log("pendoisready", pendo.isReady())
    }

    static pendoTrack = (name, data) => {
        if (pendo && pendo.isReady && pendo.isReady()) {
            // console.log("PendoEvents check: ", name, data);
            return pendo.track(name, data);
        }
        // setTimeout(function() {
        //   pendoTrack(name, data);
        // }, 500);
    }
}