import React from 'react';
import Select from 'react-select';
import Checkbox from '../../../../shared/checkbox2022/Checkbox';
import './filters.scss';

function ChannelFilter ({ channels, setChannels }) {
  const numSelectedChannels = channels.filter((channel) => channel.selected).length;
  const allSelected = numSelectedChannels === channels.length;

  const selectValue = allSelected
    ? { label: `All (${channels.length})` }
    : numSelectedChannels
      ? { label: `Selected (${numSelectedChannels})` }
      : { label: 'None' };

  const handleClick = (option) => {
    const selChannels = channels.reduce((list, curr) => [...list, curr.value === option.value ? { ...curr, selected: !curr.selected } : curr], []);
    setChannels(selChannels);
  }

  const handleSelectAll = () => setChannels((prev) => prev.map((channel) => ({ ...channel, selected: !allSelected })));
  const getCheckboxValue = (option) => channels.find((channel) => channel.value === option.value).selected;

  const menuRenderer = (props) => (
    <div>
      <div className="filter-option" onClick={handleSelectAll}>
        <Checkbox size={20} checked={allSelected} changeFunction={handleSelectAll} controlled fixedSize />
        All ({channels.length})
      </div>
      <div className="line-break"></div>
      {props.options.map((option) => (
        <div className="filter-option" onClick={() => handleClick(option)} key={option.label}>
          <Checkbox
            size={20}
            checked={getCheckboxValue(option)}
            onClick={() => handleClick(option)}
            changeFunction={() => handleClick(option)}
            controlled
            fixedSize
          />
          {option.label}
        </div>
      ))}
    </div>
  );

  const valueRenderer = (props) => (
    <div>
      <span className="filter-placeholder">Channel:</span>
      {props.label}
    </div>
  );

  return (
    <Select
      className="sentiment-analytics-filter"
      searchable={false}
      value={selectValue}
      options={channels}
      menuRenderer={menuRenderer}
      valueRenderer={valueRenderer}
      clearable={false}
    />
  );
};

export default ChannelFilter;
