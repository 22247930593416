
const ContractAutoMapFields = [
    { value: "@CustomEntry", label: "@Custom Entry" },
    { value: "@campaignName", label: "@Campaign Name" },
    { value: "@campaignBrand", label: "@Campaign Brand" },
    { value: "@campaignProductName", label: "@Campaign Product Name" },
    { value: "@assignmentName", label: "@Assignment Name" },
    { value: "@AssignmentDueDate", label: "@Assignment Due Date" },
    { value: "@AssignmentSummary", label: "@Assignment Summary" },
    { value: "@AssignmentDeliverables", label: "@Assignment Deliverables" },
    { value: "@AssignmentHashtags", label: "@Assignment Hashtags" },
    { value: "@AssignmentPromoText", label: "@Assignment PromoText" },
    { value: "@agentName", label: "@Agent Name" },
    { value: "@agentEmail", label: "@Agent Email" },
    { value: "@pseApplicable", label: "@PSE Applicable" },
    { value: "@pseName", label: "@PSE Name" },
    { value: "@pseAddress", label: "@PSE Address" },
    { value: "@influencerName", label: "@Creator Name" },
    { value: "@influencerEmail", label: "@Creator Email" },
    { value: "@influencerFormattedAddress", label: "@Creator Address" },
    { value: "@assignmentUserTotalRate", label: "@Rate TOTAL FEE" },
    { value: "@AgreedRate-BLOG_POST", label: "@Rate BLOG POST" },
    { value: "@AgreedRate-FACEBOOK_POST", label: "@Rate FACEBOOK POST" },
    { value: "@AgreedRate-INSTAGRAM_POST", label: "@Rate INSTAGRAM POST" },
    { value: "@AgreedRate-INSTAGRAM_STORY", label: "@Rate INSTAGRAM STORY" },
    { value: "@AgreedRate-SOCIAL_SHARE", label: "@Rate SOCIAL SHARE" },
    { value: "@AgreedRate-TIKTOK_POST", label: "@Rate TIKTOK POST" },
    { value: "@AgreedRate-YOUTUBE", label: "@Rate YOUTUBE" },
];

export default ContractAutoMapFields;
