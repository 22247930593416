import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import Tippy from '@tippyjs/react';
import SentimentByTopic from './SentimentByTopic';
import CommentsTable from './CommentsTable';
import ChannelFilter from './Filters/ChannelFilter';
import CreatorFilter from './Filters/CreatorFilter';
import PostFilter from './Filters/PostFilter';
import { getNetworkByChannel } from '../Analytics.helper';
import ReachIcon from '../../../shared/ReachIcon/ReachIcon';
import PaginationNew from '../../../shared/pagination/PaginationNew';
import ProfileImage from '../../../shared/FallbackImage/FallbackImage';
import PleaseWait from '../../../shared/pleaseWait/PleaseWait';
import PleaseWaitWhite from '../../../shared/pleaseWait/PleaseWaitWhite';
import ExportDropdown from './ExportDropdown/ExportDropdown';
import PieChartCard from './PieChartCard';
import { channelIcons } from './helpers';
import { formatBigNumber, formatPercentageToWhole } from '../../../../modules/utils/helpers';
import Api from '../../../../modules/Api';
import EmptyCard from './EmptyCard';
import './analyticsBySentiment.scss';
import './table.scss';

const channelsFilter = [
    {
        label: 'Instagram',
        value: 'INSTAGRAM',
        selected: true,
    },
    {
        label: 'TikTok',
        value: 'TIKTOK',
        selected: true,
    },
    {
        label: 'YouTube',
        value: 'YOUTUBE',
        selected: true,
    },
];

const defaultPosts = {
    instagram: { posts: [] },
    tiktok: { posts: [] },
    youtube: { posts: [] },
};

export function AnalyticsBySentiment({ campaignInfo, startDate, endDate, showError }) {
    const analyticsDataReq = useRef(null);
    const filtersReq = useRef(null);

    const { campaignId, assignmentIds } = campaignInfo;
    const [channels, setChannels] = useState(channelsFilter);
    const [creators, setCreators] = useState([]);
    const [posts, setPosts] = useState(defaultPosts);
    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);

    const [analysisData, setAnalysisData] = useState(null);

    const [commentFilters, setCommentFilters] = useState(null);

    const selectedChannels = channels.filter((channel) => channel.selected).map((channel) => channel.value);
    const excludedCreators = creators.filter((creator) => !creator.selected).map((creator) => creator.value);
    const excludedPosts = [...posts.instagram.posts, ...posts.tiktok.posts, ...posts.youtube.posts]
        .filter((post) => !post.selected)
        .map((post) => post.id);

    const isChannelSelected = (channel) => selectedChannels.find((ch) => ch === channel);

    const relevancyOptions = analysisData
        ? [
              ...analysisData?.productPerception.classes.map((option) => ({
                  label: option,
                  value: option,
                  type: 'PRODUCT',
              })),
              ...analysisData?.categoryPerception.classes.map((option) => ({
                  label: option,
                  value: option,
                  type: 'CATEGORY',
              })),
              ...Object.keys(analysisData?.topicPerception).map((option) => ({
                  label: option,
                  value: option,
                  type: 'TOPIC',
              })),
          ]
        : [];

    const detailsData = [
        ...(isChannelSelected('INSTAGRAM')
            ? [
                  {
                      channel: 'Instagram',
                      comments: analysisData?.instagramCommentsCount,
                      commenters: analysisData?.instagramUniqueCommentCount,
                      brandedComments: analysisData?.brandedCommentCount.INSTAGRAM,
                      brandedCommentRatio: analysisData?.brandedCommentRatio.INSTAGRAM,
                  },
              ]
            : []),
        ...(isChannelSelected('TIKTOK')
            ? [
                  {
                      channel: 'TikTok',
                      comments: analysisData?.tiktokCommentsCount,
                      commenters: analysisData?.tiktokUniqueCommentCount,
                      brandedComments: analysisData?.brandedCommentCount.TIKTOK,
                      brandedCommentRatio: analysisData?.brandedCommentRatio.TIKTOK,
                  },
              ]
            : []),
        ...(isChannelSelected('YOUTUBE')
            ? [
                  {
                      channel: 'YouTube',
                      comments: analysisData?.youtubeCommentsCount,
                      commenters: analysisData?.youtubeUniqueCommentCount,
                      brandedComments: analysisData?.brandedCommentCount.YOUTUBE,
                      brandedCommentRatio: analysisData?.brandedCommentRatio.YOUTUBE,
                  },
              ]
            : []),
    ];

    const getAnalysisData = async (
        selChannels = selectedChannels,
        exclCreators = excludedCreators,
        exclPosts = excludedPosts,
    ) => {
        setLoading(true);

        if (selChannels?.length === 0) {
            analyticsDataReq.current = null;
            setAnalysisData(null);
            setLoading(false);
            return;
        }

        const fetchData = Api.getCampaignSentiment(
            assignmentIds,
            selChannels,
            startDate,
            endDate,
            exclCreators,
            exclPosts,
        );

        analyticsDataReq.current = fetchData;

        await fetchData
            .then((res) => {
                if (analyticsDataReq.current !== fetchData) return;
                setAnalysisData(res);
            })
            .finally(() => setLoading(false));
    };

    const getFiltersData = async () => {
        if (selectedChannels?.length === 0) {
            filtersReq.current = null;
            setCreators([]);
            setPosts(defaultPosts);
            return;
        }
        const fetchFilters = Api.getCampaignSentimentFilters(assignmentIds, selectedChannels, startDate, endDate).then(
            (res) => {
                const creators = res.creatorFilters.map((creator) => ({
                    label: creator.creatorName,
                    value: creator.creatorId,
                    avatar: creator.creatorAvatarUrl,
                    selected: true,
                }));

                const posts = res.postFilters
                    .map((post) => ({
                        id: post.id,
                        userHandle: post.handle,
                        image: post.thumbnailUrl,
                        date: moment(post.createdOn).format('MMM DD, YYYY'),
                        content: post.text,
                        likes: post.likeCount,
                        comments: post.commentCount,
                        platform: post.platform,
                        avatar: post.creatorAvatarUrl,
                        selected: true,
                    }))
                    .filter(
                        (post) =>
                            post.platform === 'INSTAGRAM' || post.platform === 'TIKTOK' || post.platform === 'YOUTUBE',
                    )
                    .reduce((data, post) => {
                        return post.platform
                            ? {
                                  ...data,
                                  [post.platform.toLowerCase()]: {
                                      posts: [...data[post.platform.toLowerCase()].posts, post],
                                  },
                              }
                            : data;
                    }, defaultPosts);

                return { creators, posts };
            },
        );

        filtersReq.current = fetchFilters;

        await fetchFilters.then(({ creators, posts }) => {
            if (filtersReq.current !== fetchFilters) return;
            setCreators(creators);
            setPosts(posts);
        });
    };

    useEffect(() => {
        getAnalysisData();
        getFiltersData();
    }, [assignmentIds?.length, selectedChannels.length, startDate, endDate]);

    return (
        <div className="analytics-by-sentiment">
            <PleaseWaitWhite show={pageLoading} top={200} />
            <div className="section-title-line">
                <div>
                    <div className="section-title">By Sentiment</div>
                    <div className="section-subtitle">
                        Available for Instagram, TikTok and YouTube only.{' '}
                        {analysisData && `Last updated: ${moment(analysisData.lastUpdatedOn).format('MMM DD, YYYY')}.`}
                        <span className="label-tooltip">
                            <Tippy
                                className="text-tooltip"
                                content="Each posts are updated independently based on publish date & time.  The last updated date displayed is the lastest updated date from all the posts that falls within the filtered date range."
                            >
                                <img src="/images/tooltipUser.svg" />
                            </Tippy>
                        </span>
                    </div>
                </div>
                <div className="section-filters">
                    <ChannelFilter channels={channels} setChannels={setChannels} />
                    <CreatorFilter creators={creators} setCreators={setCreators} updateData={getAnalysisData} />
                    <PostFilter posts={posts} setPosts={setPosts} updateData={getAnalysisData} />
                    <ExportDropdown
                        campaignInfo={campaignInfo}
                        startDate={startDate}
                        endDate={endDate}
                        channels={channels}
                        creators={creators}
                        posts={posts}
                        analysisData={analysisData}
                        commentFilters={commentFilters}
                        setLoading={setPageLoading}
                        showError={showError}
                    />
                    <Tippy
                        className="text-tooltip"
                        content="You can download the comment sentiment summary and comment list as CSV files. Please note due to the large volume of comments in some posts, the comment list CSV may not be available currently. Please adjust the date filters to reduce the number of comments displayed to be under 2000 to enable the comment list CSV download."
                    >
                        <img src="/images/tooltipUser.svg" />
                    </Tippy>
                </div>
            </div>
            <div className="content-grid">
                <PleaseWait show={loading} />
                <OverallCard
                    totalCommentCount={analysisData?.totalCommentCount}
                    totalUniqueCommentCount={analysisData?.totalUniqueCommentCount}
                />
                <DetailsCard detailsData={detailsData} />
                <OverallPerceptionCard overallData={analysisData?.productRelatedPerception} />
                <ProductPerceptionCard productData={analysisData?.productPerception} />
                <CategoryPerceptionCard categoryData={analysisData?.categoryPerception} />
                <SentimentByTopic topicData={analysisData?.topicPerception} />
                <OverallCommentsCard commentData={analysisData?.overallCommentPerception} />
                <CommentSummaryByAI summaries={analysisData?.summaries || []} />
            </div>
            <CommentsTable
                campaignId={campaignId}
                assignmentIds={assignmentIds}
                platforms={selectedChannels}
                excludedCreators={excludedCreators}
                excludedPosts={excludedPosts}
                startDate={startDate}
                endDate={endDate}
                setCommentFilters={setCommentFilters}
                updateAnalysisData={getAnalysisData}
                relevancyOptions={relevancyOptions}
            />
        </div>
    );
}

function OverallCard({ totalCommentCount, totalUniqueCommentCount }) {
    return (
        <div className="card">
            <div className="card-title">Overall</div>
            <div className="overall-content">
                <div className="overall-col">
                    <div className="icon-wrapper">
                        <img src="/images/comments.svg" />
                    </div>
                    <div className="col-subtitle">Total comments</div>
                    <div className="col-value">
                        {!isNaN(totalCommentCount) ? formatBigNumber(totalCommentCount) : '-'}
                    </div>
                </div>
                <div className="overall-col">
                    <div className="icon-wrapper">
                        <img src="/images/commenters.svg" />
                    </div>
                    <div className="col-subtitle">Unique commenters</div>
                    <div className="col-value">
                        {!isNaN(totalUniqueCommentCount) ? formatBigNumber(totalUniqueCommentCount) : '-'}
                    </div>
                </div>
            </div>
        </div>
    );
}

function DetailsCard({ detailsData }) {
    return (
        <div className="card sentiment-details">
            <div className="card-title">Details</div>
            <table className="details-table">
                <tbody>
                    <tr className="table-header">
                        <th className="details-channel-col">
                            <div>Channel</div>
                        </th>
                        <th className="details-commenters-col">
                            <div>
                                Unique
                                <br />
                                Commenters
                            </div>
                        </th>
                        <th className="details-branded-comments-col">
                            <div>
                                Branded
                                <br />
                                Comments
                            </div>
                            <div className="label-tooltip">
                                <Tippy
                                    className="text-tooltip"
                                    content="Positive and Neutral comments that are relevant to the campaign category."
                                >
                                    <img src="/images/tooltipUser.svg" />
                                </Tippy>
                            </div>
                        </th>
                        <th className="details-comments-col">
                            <div>
                                Total
                                <br />
                                Comments + Replies
                            </div>
                        </th>
                        <th className="details-comments-ratio-col">
                            <div>
                                Branded
                                <br />
                                Comments Ratio
                            </div>
                            <div className="label-tooltip">
                                <Tippy
                                    className="text-tooltip"
                                    content="Branded Comments divided by total number of comments."
                                >
                                    <img src="/images/tooltipUser.svg" />
                                </Tippy>
                            </div>
                        </th>
                    </tr>
                    {detailsData.map((channelData) => (
                        <tr key={channelData.channel} className="details-data-row">
                            <td className="details-channel-col">
                                <ReachIcon
                                    network={getNetworkByChannel(channelData.channel)}
                                    value={1}
                                    size={18}
                                    padding={4}
                                    noToolTip
                                />
                                {channelData.channel}
                            </td>
                            <td className="details-commenters-col">
                                <div className="number-col">
                                    {channelData.commenters ? formatBigNumber(channelData.commenters) : '0'}
                                </div>
                            </td>
                            <td className="details-branded-comments-col">
                                <div className="number-col">
                                    {channelData.brandedComments ? formatBigNumber(channelData.brandedComments) : '0'}
                                </div>
                            </td>
                            <td className="details-comments-col">
                                <div className="number-col">
                                    {channelData.comments ? formatBigNumber(channelData.comments) : '0'}
                                </div>
                            </td>
                            <td className="details-comments-ratio-col">
                                <div className="number-col">
                                    {channelData.brandedCommentRatio
                                        ? `${formatPercentageToWhole(channelData.brandedCommentRatio * 100)}%`
                                        : '0'}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export function OverallPerceptionCard({ overallData, handleLink, cardRef }) {
    const overallPerceptionData = [
        {
            category: 'Positive',
            value: overallData?.positiveCount || 0,
            color: '#75d551',
        },
        {
            category: 'Negative',
            value: overallData?.negativeCount || 0,
            color: '#fd625e',
        },
        {
            category: 'Neutral',
            value: overallData?.neutralCount || 0,
            color: '#ffbe16',
        },
    ];

    const total = overallPerceptionData.reduce((prev, curr) => (prev += curr.value), 0) || 0;

    return (
        <PieChartCard
            cardTitle="Overall Perception"
            tooltipText="Sentiment of comments referring to the product or category defined within the campaign from selected posts. Total may not always equal 100% due to rounding."
            chartWidth={435}
            chartHeight={230}
            chartData={overallPerceptionData}
            total={total}
            totalLabel="total mentions"
            handleLink={handleLink}
            cardRef={cardRef}
        />
    );
}

function ProductPerceptionCard({ productData }) {
    const productPerceptionData = [
        {
            category: 'Positive',
            value: productData?.positiveCount || 0,
            color: '#75d551',
        },
        {
            category: 'Negative',
            value: productData?.negativeCount || 0,
            color: '#fd625e',
        },
        {
            category: 'Neutral',
            value: productData?.neutralCount || 0,
            color: '#ffbe16',
        },
    ];
    const total = productPerceptionData.reduce((prev, curr) => (prev += curr.value), 0) || 0;

    return (
        <PieChartCard
            cardTitle="Product Perception"
            tooltipText="Sentiment of comments referring to the product defined within the campaign from selected posts. Total may not always equal 100% due to rounding."
            chartWidth={435}
            chartHeight={230}
            chartData={productPerceptionData}
            total={total}
            totalLabel="mentions"
            highlightedText={productData?.classes.join(', ')}
        />
    );
}

function CategoryPerceptionCard({ categoryData }) {
    const categoryPerceptionData = [
        {
            category: 'Positive',
            value: categoryData?.positiveCount || 0,
            color: '#75d551',
        },
        {
            category: 'Negative',
            value: categoryData?.negativeCount || 0,
            color: '#fd625e',
        },
        {
            category: 'Neutral',
            value: categoryData?.neutralCount || 0,
            color: '#ffbe16',
        },
    ];
    const total = categoryPerceptionData.reduce((prev, curr) => (prev += curr.value), 0) || 0;

    return (
        <PieChartCard
            cardTitle="Category Perception"
            tooltipText="Sentiment of comments referring to the category defined within the campaign from selected posts. Total may not always equal 100% due to rounding."
            chartWidth={435}
            chartHeight={230}
            chartData={categoryPerceptionData}
            total={total}
            totalLabel="mentions"
            highlightedText={categoryData?.classes.join(', ')}
        />
    );
}

function OverallCommentsCard({ commentData }) {
    const commentSentimentData = [
        {
            category: 'Positive',
            value: commentData?.positiveCount || 0,
            color: '#75d551',
        },
        {
            category: 'Negative',
            value: commentData?.negativeCount || 0,
            color: '#fd625e',
        },
        {
            category: 'Neutral',
            value: commentData?.neutralCount || 0,
            color: '#ffbe16',
        },
    ];
    const total = commentSentimentData.reduce((prev, curr) => (prev += curr.value), 0) || 0;

    return (
        <PieChartCard
            cardTitle="Comment Sentiment"
            tooltipText="Overall sentiment of all comments from selected posts. Total may not always equal 100% due to rounding."
            chartWidth={435}
            chartHeight={290}
            chartData={commentSentimentData}
            total={total}
            totalLabel="comments"
        />
    );
}

function CommentSummaryByAI({ summaries }) {
    const summaryCardRef = useRef(null);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const summariesSlice = summaries.slice((page - 1) * perPage, page * perPage);

    useEffect(() => {
        setPage(1);
    }, [summaries]);

    return (
        <div ref={summaryCardRef} className="card comment-summary-by-ai">
            <div className="card-title">
                <div>Post Comment Summary by AI</div>
                <div className="info-tooltip-icon">
                    <Tippy
                        className="text-tooltip"
                        content="Summary of the most popular comments per post from selected channel, creators and posts within the campaign. It's only available for posts with more than 50 comments."
                    >
                        <img src="/images/tooltipUser.svg" />
                    </Tippy>
                </div>
            </div>
            <div className="comment-summary-content">
                {summariesSlice.length ? (
                    <>
                        <table className="comment-summary-table">
                            <tbody>
                                <tr className="table-header">
                                    <th className="creator-col">
                                        <div>Creator</div>
                                    </th>
                                    <th className="type-col">
                                        <div>Type</div>
                                    </th>
                                    <th className="summary-col">
                                        <div>Summary</div>
                                    </th>
                                    <th className="link-col">
                                        <div>Link</div>
                                    </th>
                                </tr>
                                {summariesSlice.map((summary) => (
                                    <tr key={summary.postUrl} className="summary-row">
                                        <td className="creator-col">
                                            <div className="profile-info">
                                                <ProfileImage
                                                    className="profile-image"
                                                    url={summary.avatarUrl}
                                                    alternativeUrl="/images/ic-avatar-blank-d.svg"
                                                />
                                                <div className="username-label">{summary.username}</div>
                                            </div>
                                        </td>
                                        <td className="type-col">
                                            <img className="channel-logo" src={channelIcons[summary.networkType]} />
                                        </td>
                                        <td className="summary-col">
                                            <div className={summary.summary ? '' : 'empty-summary'}>
                                                {summary.summary ?? 'No summary was found.'}
                                            </div>
                                        </td>
                                        <td className="link-col">
                                            <a href={summary.postUrl} target="_blank">
                                                <img className="link-icon" src="/images/link.svg" />
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="pagination-panel">
                            <PaginationNew
                                total={summaries.length}
                                limit={perPage}
                                current={page}
                                parentContainer={summaryCardRef.current}
                                handlePageChange={(page) => {
                                    setPage(page);
                                    summaryCardRef.current.scrollIntoView({ behavior: 'smooth' });
                                }}
                                limitChangeFunction={(limit) => {
                                    setPage(1);
                                    setPerPage(limit);
                                    summaryCardRef.current.scrollIntoView({ behavior: 'smooth' });
                                }}
                            />
                        </div>
                    </>
                ) : (
                    <EmptyCard title="No Summaries Available" subtitle="Post(s) has no summaries to display." />
                )}
            </div>
        </div>
    );
}

export default AnalyticsBySentiment;
