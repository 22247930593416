import React from 'react'
import Select from 'react-select'
import './dropdownSelect.scss'

// props
export default class DropdownSelect extends React.Component {

    constructor (props, context) {
        super(props, context);

        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        let selected='';
        if (this.props.value) {
            if (this.props.simpleValue) {
                if (Array.isArray(this.props.value)) {
                    selected=this.props.value.toString();
                } else {
                    selected=this.props.value;
                }
            } else if (Array.isArray(this.props.value)) {
                selected=this.props.value;
            } else if (typeof this.props.value ==='object') {
                selected=this.props.value;
            } else {
                const sArray=this.props.options.find(option=>{return option.value==this.props.value});
                if (this.props.single) {
                    selected=sArray[0];
                } else {
                    selected=sArray;
                }
            }
        }
        this.state = {
            selected,
            menuOpen: false
        }
    }

    handleClick(e) {
        if (this.state.menuOpen) {
            this.selectNode.handleMouseDownOnArrow(e);
        }
        const f = this.props.onClick;
        const self = this;
        if (typeof f === 'function') {
            let element=e.target;
            while (Boolean(element) && element.className.indexOf('Select ')<0) {
                element=element.parentElement;
            }
            f(this.Element);
        }
        this.setState({menuOpen:!this.state.menuOpen});
    }

    handleChange(value) {
        const{simpleValue} = this.props;

        const passValue=(simpleValue)?value.toString().split(','):value;
        const f = this.props.onChange;
        if (typeof f === 'function') {
            f(passValue);
        }
        this.setState({selected:value, menuOpen: false});
    }

    reset(value) {
        const{simpleValue,single}=this.props;
        const selectedValue = value ? ((simpleValue||single)?value.toString():value) : ((simpleValue||single)?'':[]);
        this.setState({selected: selectedValue, menuOpen: false});
    }

    render() {
        const{options, simpleValue, maxLimit, id, multi, clearable,placeholder, className}=this.props;
        const{selected}=this.state;

        const dsClass='selectBox';

        const optionList=[];
        if (maxLimit) {
            const scnt = (simpleValue)?selected.split(',').length:selected.length;
            options.forEach(option=>{

                let disabled = false;
                if (scnt>=maxLimit){
                    if (simpleValue) {
                        disabled = (`${selected},`).indexOf(`${option.value},`) < 0;
                    } else {
                        disabled = selected.findIndex(sv=>sv.value == option.value) < 0;
                    }
                }
                option.disabled=disabled;
                optionList.push(option);
            });
        }
        return (
            <div className={dsClass} onClick={this.handleClick} id={id} ref={e=>this.Element=e}>
                <Select className={className}
                    clearable={clearable}
                    backspaceRemoves={false}
                    searchable
                    value={selected}
                    multi={multi}
                    simpleValue={simpleValue}
                    placeholder={placeholder}
                    options={(maxLimit)?optionList:options}
                    onChange={this.handleChange}
                    ref={e=>this.selectNode=e}
                />
            </div>
        )
    }
}
