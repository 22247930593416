import React, { useState, useEffect, useRef } from 'react';
import cx from 'classnames';
import './deliverableItem.scss';
import Lookup from '../../../../modules/Lookup'
import ReachIcon from "../../../shared/ReachIcon/ReachIcon";
import InputBox from './InputBox';

export const DeliverableItem = ({
    type = '',
    value,
    keyId = '',
    onChange = () => null,
}) => {
    return (
        <div key={keyId} className='deliverableItemContainer'>
            <div className='channelInfo'>
                <ReachIcon network={Lookup.getDeliverableType(type).network} value={1} size={28} padding={6} />
                <span>{Lookup.getDeliverableType(type).label}</span>
            </div>
            <InputBox value={value} onChange={onChange} label='Deliverables' />
        </div>
    )
}