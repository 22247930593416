import * as React from 'react';
import './KeywordsInput.scss';
import cx from 'classnames';

export const KeywordsInput = ({
    data,
    placeHolder,
    maxLength = 255,
    disabled = false,
    onChange,
    onEnter,
    changeIsFocused,
    isInfluencerFilterInput,
    sideFilter,
    isFirstKeywordOnly
}) => {
    const [disable, setDisable] = React.useState(disabled);
    const [isFocused, setIsFocused] = React.useState(false);
    const [currentData, setData] = React.useState(data || []);

    const inputRef = React.createRef();

    const handleChange = () => {};

    React.useLayoutEffect(() => {
        setData(data || []);
    }, [data]);

    React.useEffect(() => {
        setDisable(disabled);
    }, [disabled]);

    const handleKeyUp = (keyCode) => {
        if (keyCode === 13) {
            if (inputRef.current.value.length > 0) {
                let update = Object.assign([], currentData);
                if (isFirstKeywordOnly && update.length > 0) {
                    update = ([inputRef.current.value]);
                } else {
                    update.push(inputRef.current.value);
                }
                // setData(update);
                inputRef.current.value = '';
                if (onChange) {
                    onChange(update);
                }
            }
            if (onEnter) {
                onEnter();
            }
        }
    };

    const onBlur = () => {
        if (inputRef.current.value.length > 0) {
            let update = Object.assign([], currentData);
            if (isFirstKeywordOnly && update.length > 0) {
                update = ([inputRef.current.value]);
            }else {
                update.push(inputRef.current.value);
            }
            // setData(update);
            inputRef.current.value = '';
            if (onChange) onChange(update);
        }
        setIsFocused(false);
        if (changeIsFocused) changeIsFocused(false);
    };

    const onFocus = () => {
        setIsFocused(true);
        if (changeIsFocused) changeIsFocused(true);
    };

    const onDelete = (index) => {
        const update = Object.assign([], currentData);
        update.splice(index, 1);
        // setData(update);
        inputRef.current.value = '';
        if (onChange) {
            onChange(update, true);
        }
    };

    const handleClick = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };
    return (
        <>
        <div
            className={cx('keywordsInputContainer2022', { influencerFilterInput: isInfluencerFilterInput })}
            onMouseOver={() => handleClick()}
            onClick={() => handleClick()}
        >
            <div className="labelContainer">
                <input
                    placeholder={placeHolder}
                    ref={inputRef}
                    autoFocus
                    // type={inputType}
                    maxLength={maxLength}
                    onChange={handleChange}
                    // value={''}
                    disabled={disable}
                    onKeyUp={({ keyCode }) => handleKeyUp(keyCode)}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    onMouseOver={() => handleClick()}
                />
            </div>
        </div>
        {sideFilter && <div className="filterMultiOptions">
            {currentData.map((item, index) => {
                const closeIcon = <div onClick={() => onDelete(index)} className='filterBar-closeIcon'>+</div>;
                return <div key={item + index + 'filtermulti'}>{closeIcon} <div className="multiOptionLabel">{item}</div></div>
            })}
        </div>}
        </>
    );
};
