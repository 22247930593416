import React from 'react';
import { withRouter } from 'react-router';
import { get } from 'lodash';
import './style.scss';
import { BRAND_OWNER, BRAND_OWNER_STR, SUPER_ADMIN, SUPER_ADMIN_STR } from '../../../../constants/authorities';

class BrandRow extends React.Component {
    state = {
        isShowMoreOpen: false,
        countryList: [],
        currencyList: []
    };

    toggleShowMore = () => {
        this.setState({isShowMoreOpen: true})
    };

    handleBlur = () => {
        this.setState({isShowMoreOpen: false})
    };

    handleOpenModal = () => {
        const { brand, handleSetBrandToEdit } = this.props;

        handleSetBrandToEdit(brand);
    };

    handleBrandCountryOpenModal = () => {
        const { brand, handleAddBrandCountry } = this.props;
        handleAddBrandCountry(brand);
    };

    // handleRedirect = () => {
    //     const {brand} = this.props;
    //     this.props.history.push(`adminBrands/${brand.id}`);
    // };

    handleArchive = () => {
        const {
            brand: { id },
            onArchiveBrand,
        } = this.props;

        onArchiveBrand(id);
    };

    handleUnarchive = () => {
        const {
            brand: { id },
            onUnarchiveBrand,
        } = this.props;

        onUnarchiveBrand(id);
    };

    renderDots = () => {
        const { isShowMoreOpen } = this.state;
        const {
            role,
            brandsStatusValue,
        } = this.props;

        if (role && (role === SUPER_ADMIN_STR || role === BRAND_OWNER_STR) && brandsStatusValue === 'active') {
            return (
                <td onMouseOver={this.toggleShowMore}
                    onMouseLeave={this.handleBlur}
                    className="dots"
                >
                    <div className={"action"}>
                        {isShowMoreOpen ? 
                            <div className="popup">
                                {role === SUPER_ADMIN_STR && <div onClick={this.handleBrandCountryOpenModal}>Add Country</div>}
                                <div onClick={this.handleOpenModal}>Edit Brand</div>
                                {role === SUPER_ADMIN_STR && <div onClick={this.handleArchive}>Archive</div>}
                            </div>
                        : ''}
                    </div>
                </td>
            );
        } else if (role && (role === SUPER_ADMIN_STR) && brandsStatusValue !== 'active') {
            return (
                <td onMouseOver={this.toggleShowMore}
                    onMouseLeave={this.handleBlur}
                    className="dots"
                >
                    <div className={"action"}>
                        {isShowMoreOpen ? 
                            <div className="popup">
                                {role === SUPER_ADMIN_STR && <div onClick={this.handleUnarchive}>Unarchive</div>}
                            </div>
                        : ''}
                    </div>
                </td>
            );
        } else {
            return null;
        }
    };

    render () {
        const {
            brand,
            countryList, 
            currencyList
        } = this.props;
        const countryLabel = countryList.find(x => x.value === brand.countryCode)?.label
        const currencyLabel = currencyList.find(x => x.value === brand.defaultCurrencyCode)?.label
        return (
            <>
                <td>
                    <div className="user-info-holder">
                        <div className="image-holder">
                            {get(brand, 'logo') ? <img src={brand.logo} className='brand-logo' alt="logo" /> : brand.brand[0]}
                        </div>
                        <div className="user-info">
                            <div className="user-name">{brand.brand}</div>
                        </div>
                    </div>
                </td>
                <td className="country">{countryLabel}</td>
                <td className="currency">{currencyLabel}</td>
                <td className="brands">{brand.advertiser}</td>
                <td className="campaign">{brand.campaigns}</td>
                <td className="email">
                    <span className="email-row">
                        {brand.email}
                    </span>
                </td>
                <td className="role">{brand.owner.join(', ')}</td>
                <td className="date">{brand.date}</td>
                {this.renderDots()}
            </>
        )
    }
}

export default withRouter(BrandRow);
