import React from 'react';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import Select from 'react-select';
import moment from 'moment';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import cx from 'classnames';
import Modal from 'react-modal';
import { FilePond, registerPlugin } from 'react-filepond';
import Format from '../../../modules/utils/Format';
import Api from '../../../modules/Api';
import Lookup from '../../../modules/Lookup';
import ReachIcon from '../../shared/ReachIcon/ReachIcon';
import PleaseWait from '../../shared/pleaseWait/PleaseWaitWhite';
import PleaseWaitPartial from '../../shared/pleaseWait/PleaseWaitPartial';
import ApiError from '../../shared/apiError/ApiError';
import NoResults from '../../shared/noResults/NoResults';
import DropdownMenu from '../../influencerAssignments/DropdownMenu/DropdownMenu';
import './posts.scss';
import { safeIsUrl } from '../../../modules/CommonServices';
import Pagination from '../../shared/pagination/Pagination';
import PaymentModal from './PaymentModal';
import ModalWarningWithAction from '../../shared/modalWithSingleAction/ModalWarningWithAction';
import { DRAFT, PUBLISHED } from '../../../constants/statusTypes';
import UploadedImages from '../../influencerAssignments/UploadedImages/UploadedImages';
import Checkbox from '../../shared/checkbox/Checbox';
import exportContentHelper from './exportContentHelper';
import CreateDraftModal from './CreateDraftModal';
import { AGENT_STR, BRAND_OWNER_STR, SUPER_ADMIN_STR, VIEWER_STR } from '../../../constants/authorities';
import { apiKey } from 'honeybadger-js';
import { API } from 'aws-amplify';
import AutoDetectedPopup from './AutoDetectedPopup/AutoDetectedPopup';
import AutoDetectedPosts from './AutoDetectedPosts/AutoDetectedPosts';
import UpdatePostURLModal from './UpdatePostURLModal/UpdatePostURLModal';
import TransactionLog from './TransactionLog/TransactionLog';
import { listSplashFilterChange } from '../../../store/campaign/influencerSearch/actionCreators';
import { lte } from 'lodash';

const JSZip = require('jszip');

// const permissions = ['ROLE_AGENT', 'ROLE_SUPER_ADMIN', 'ROLE_BRAND_OWNER'];
const sortFunc = (a, b) => {
    if (a.userFirstName < b.userFirstName) {
        return -1;
    }
    if (a.userFirstName > b.userFirstName) {
        return 1;
    }
    return 0;
};
const setPostListArray = (list) => {
    const arrayKeys = [
        'assignmentId',
        'assignmentName',
        'assignmentTypes',
        'assignmentUser',
        'assignmentUserId',
        'userEmail',
        'userId',
        'userFirstName',
        'userLastName',
        'userPictureUrl',
        'authenticityRating',
        'deliverable',
    ];
    const postArray = [];
    let akey = 0;
    let ukey = 0;
    let posts = [];
    let item = {};
    if (list?.length > 0) {
        list.forEach((l) => {
            const newItem = !(akey === l.assignmentId && ukey === l.assignmentUserId);
            const post = {};
            const nItem = {};
            Object.keys(l).forEach((key) => {
                if (arrayKeys.indexOf(key) > -1) {
                    if (newItem) {
                        nItem[key] = l[key];
                    }
                } else {
                    post[key] = l[key];
                }
            });

            if (newItem && posts.length > 0) {
                item.posts = posts;
                postArray.push(item);
                item = nItem;
                posts = [];
                posts.push(post);
            } else {
                if (newItem) {
                    item = nItem;
                }
                posts.push(post);
            }
            akey = l.assignmentId;
            ukey = l.assignmentUserId;
        });
        item.posts = posts;
        postArray.push(item);
    }
    return postArray;
};

const formatAssignmentRes = (list) => {
    const a = [];
    for (let i = 0; i < list.length; i++) {
        const b = {
            value: list[i].id,
            label: list[i].name,
        };
        a.push(b);
    }
    return a;
};

const showDate = (dateString) => {
    return moment.utc(dateString).local().format('MMM D, YYYY');
};

const parseJson = (str) => {
    try {
        return JSON.parse(str);
    } catch (e) {
        return '';
    }
};

export const postTypeMap = {
    BLOG_POST: {
        network: 'blog',
        label: 'Blog',
    },
    FACEBOOK_POST: {
        network: 'facebook',
        label: 'Facebook',
    },
    TIKTOK_POST: {
        network: 'tiktok',
        label: 'TikTok',
    },
    TWITTER_POST: {
        network: 'twitter',
        label: 'Twitter',
    },
    INSTAGRAM_POST: {
        network: 'instagram',
        label: 'Instagram Post',
    },
    INSTAGRAM_STORY: {
        network: 'instagram',
        label: 'Instagram Story',
    },
    YOUTUBE: {
        network: 'youtube',
        label: 'YouTube',
    },
    SOCIAL_SHARE: {
        network: 'share',
        label: 'Social Share',
    },
    GENERAL: {
        network: 'blog',
        label: 'Per Campaign',
    },
};

class Posts extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.handleSelectAssignments = this.handleSelectAssignments.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.markInfluencerAsPaid = this.markInfluencerAsPaid.bind(this);

        this.campaignId = Number(this.props.campaignId);
        this.assignmentId = Number(this.props.match.params.assignmentId);
        this.state = {
            assignments: [],
            selectedAssignment: ['ALL'],
            postStatus: 'DRAFT',
            draftMeta: {},
            publishedMeta: {},
            campaign: {},
            filter: '',
            sortBy: 'name',
            drafts: null,
            posts: null,
            draftlist: [],
            postlist: [],
            retrievalInProgress: false,
            showActions: '',
            currentPage: 1,
            paymentModalId: null,
            publishedModal: false,
            errorModal: {
                isOpen: false,
                titleId: undefined,
                messageId: undefined,
            },
            hasPublic: false,
            openAnalyticScreen: {},
            exportListInProgress: [], // this is the list for selected users after click "Export content" button
            selectedUsersForExport: [], // this is used for selected before click "Export content" button
            exportInProgress: false,
            downloadingArray: [],
            openExport: false,
            openNoForm: false,
            transactionLogData: null,
            updateURLModalData: null,
            isCreateDraftModalVisible: false,
            displayIndexes: { draft: [], published: [] },
            publicUserList: [],
            currentPublicUser: null,
            publishedPosts: [],
            isUploading: false,
            edittedUser: null,
            publicPayment: null,
            currentBrandEmail: null,
            transactionList: []
        };

        this.fetchAllPosts = debounce(this.fetchAllPosts.bind(this), 250);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.zip = new JSZip();
    }

    componentDidMount() {
        const {
            campaign,
            campaignId,
            match: { params },
        } = this.props;
        this.campaignId = Number(campaignId);
        this.assignmentId = Number(params.assignmentId);
        this.props.setNavigationFunction(this.assignmentId);
        Api.getAssignment(params.assignmentId).then((res) => {
            const publicUserList = [];
            if (res.publicUsers) {
                res.publicUsers.forEach((user) => {
                    publicUserList.push({
                        label: user.creatorName,
                        value: user.id,
                    });
                });
            }
            if (res.publicUsers && (!res.users || res.users.length === 0)) {
                this.setState(
                    {
                        hasPublic: true,
                        postStatus: PUBLISHED,
                        campaign,
                        publicUserList,
                    },
                    () => this.fetchAllPosts(undefined, true),
                );
            } else {
                this.setState(
                    {
                        hasPublic: res.publicUsers,
                        campaign,
                        publicUserList,
                    },
                    () => this.fetchAllPosts(undefined, true),
                );
            }
        });
    }

    componentWillUnmount() {
        exportContentHelper.unMount();
    }

    openAnalyticSlider = (postKey) => {
        const { openAnalyticScreen } = this.state;
        openAnalyticScreen[postKey] = true;
        this.setState({ openAnalyticScreen });
    };

    closeAnalyticSlider = (postKey) => {
        const { openAnalyticScreen } = this.state;
        openAnalyticScreen[postKey] = false;
        this.setState({ openAnalyticScreen });
    };

    exportForm = (id) => {
        this.setState({ retrievalInProgress: true });
        Api.getInfluencerWFormUrl(id)
            .then((res) => {
                if (res && res.url) {
                    const updateFileProgress = (progressPercentage) => {
                        if (progressPercentage > 0) {
                            this.setState({ downloadProgressPercentage: progressPercentage });
                        }
                    };

                    const updateFileFinished = (response) => {
                        // handle old data with no name & extensions
                        const ext = response.data && response.data.type ? Format.mimeToExt(response.data.type) : '';
                        const fileName = res.name === 'File' ? `File${ext}` : res.name;
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', fileName); // or any other extension
                        document.body.appendChild(link);
                        link.click();
                        this.setState({ downloadProgressPercentage: null, retrievalInProgress: false });
                    };

                    updateFileProgress(1);
                    // Call gradual download
                    Api.getS3FileGradually(
                        res.url,
                        (progressPercentage) => updateFileProgress(progressPercentage),
                        (response) => updateFileFinished(response),
                    );
                } else {
                    this.setState({ retrievalInProgress: false, downloadProgressPercentage: null });
                }
            })
            .catch((err) => {
                this.setState({ retrievalInProgress: false, downloadProgressPercentage: null });
            });
    };

    downloadVideo = (urlFile, name) => {
        const { downloadingArray } = this.state;
        if (!downloadingArray.find(({ url }) => url === urlFile)) {
            downloadingArray.push({ url: urlFile, percentage: 0 });
            this.setState({ downloadingArray });
        } else {
            return null;
        }
        const updateFileProgress = (progressPercentage) => {
            if (progressPercentage > 0) {
                const target = downloadingArray.find(({ url }) => url === urlFile);
                target.percentage = progressPercentage;
                this.setState({ downloadingArray });
            }
        };

        const updateFileFinished = (response) => {
            // handle old data with no name & extensions
            const ext = response.data && response.data.type ? Format.mimeToExt(response.data.type) : '';
            const fileName = name === 'File' ? `File${ext}` : name;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName); // or any other extension
            document.body.appendChild(link);
            link.click();
            const removeList = downloadingArray.filter((item) => item.url !== urlFile);
            this.setState({ downloadingArray: removeList });
        };

        updateFileProgress(1);
        // Call gradual download
        Api.getS3FileGradually(
            urlFile,
            (progressPercentage) => updateFileProgress(progressPercentage),
            (response) => updateFileFinished(response),
        );
    };

    renderChoreIndex(chore, assignmentId) {
        const { campaign } = this.props;
        const { assignments } = campaign;
        const assignment = assignments.find((a) => a.id === assignmentId);
        if (
            !campaign ||
            !assignments ||
            assignments.length <= 0 ||
            !assignment ||
            !assignment.chores ||
            assignment.chores.length <= 0
        )
            return '';

        const choresForType = assignment.chores.filter((ch) => ch.type === chore.type);
        const count = choresForType.length;
        const choreIndex = choresForType.findIndex((ch) => ch.id === chore.id);

        return ` (${choreIndex + 1}/${count}) `;
    }

    renderSinglePost(post, assignmentId, userId, postStatus) {
        const { openAnalyticScreen } = this.state;
        const { profileAccount } = this.props;
        const review = (target) => {
            const postId = post.id ? post.id : post.postId;
            if (postId) {
                this.props.history.push({
                    pathname: `/assignment/${assignmentId}/post/${postId}`,
                    state: { target: `${target}/_${postId}` },
                });
            }
        };
        let postKey = '';
        let postTitle = '';
        let postType = '';
        let postText = '';
        let postUrl = '';
        let mainBtnAction = '';
        let mainBtnText = 'Edit & View';
        let draftOverDue = -1;
        let draftDueDate = null;
        let postResources = [];
        let displayIndex = '';

        if (postStatus === DRAFT) {
            postKey = post.id;
            postType = post.type;
            if (post.status === 'APPROVED') {
                mainBtnAction = 'view';
                mainBtnText = 'View';
            } else if (post.status === 'NEEDS_REVIEW' || post.status === 'IN_REVIEW') {
                mainBtnAction = 'edit';
                mainBtnText = 'Edit & View';
            } else {
                mainBtnAction = 'view';
                mainBtnText = 'View';
            }
            if (
                post.assignmentUser &&
                post.assignmentUser.assignmentSlots &&
                Array.isArray(post.assignmentUser.assignmentSlots)
            ) {
                const dIndex = post.assignmentUser.assignmentSlots.findIndex((s) => s.type === DRAFT);
                if (dIndex > -1) {
                    draftDueDate = post.assignmentUser.assignmentSlots[dIndex].dueDate;
                    if (post.status !== 'APPROVED') {
                        draftOverDue = moment().isAfter(draftDueDate, 'day')
                            ? 1
                            : moment(draftDueDate).isSame(moment(), 'day')
                              ? 0
                              : -1;
                    }
                }
            }
            displayIndex = post.displayIndex || '';
        } else {
            displayIndex = post.displayIndex || '';
            postKey = post.userPublishedPostId || `${assignmentId}-${userId}-${post.postType}`;
            postType = post.postType;
            postTitle =
                post.content && Boolean(post.content.title) && post.content.title.toLowerCase() !== 'title'
                    ? post.content.title
                    : '';
            postText = post.text ? post.text.replace(/(<([^>]+)>)/gi, '') : '';

            if (postText === '') {
                postText = post.caption || 'No Preview Available';
            }

            if (post.screenshotUrls) {
                postResources = postResources.concat(post.screenshotUrls);
            }
            if (post.screencastUrls) {
                postResources = postResources.concat(post.screencastUrls);
            }

            if (post.publicResources) {
                post.publicResources.forEach((item) => {
                    postResources.push(JSON.stringify(item));
                });
            }

            postUrl = safeIsUrl(post.link) ? post.link : post.originalUrl;
        }

        const permission =
            profileAccount &&
            (profileAccount.role === AGENT_STR ||
                profileAccount.role === SUPER_ADMIN_STR ||
                profileAccount.role === BRAND_OWNER_STR ||
                profileAccount.role === VIEWER_STR);

        // RENDERED POST
        return (
            <div className={cx('postContent', postStatus.toLowerCase())} key={postKey}>
                <div className="content" onMouseLeave={() => this.setState({ showActions: '' })}>
                    <div className="title">
                        <div className="icon">
                            <img src={Lookup.getRateType(postType).img} alt={Lookup.getRateType(postType).label} />
                        </div>
                        <span>
                            {Lookup.getRateType(postType).label +
                                (Lookup.getRateType(postType).label.indexOf(' Post') < 0 ? ' Post' : '')}
                        </span>
                    </div>
                </div>
                <div className="dateSection">
                    {postStatus === PUBLISHED && post.connectionType !== 'PUBLIC' && (
                        <div className="publishDate date">
                            <span>Submitted On:</span>
                            <div>{showDate(post.createdOn)}</div>
                            <UploadedImages
                                isOpen={openAnalyticScreen[postKey]}
                                items={post.analyticsScreenshotUrls || []}
                                onClose={() => this.closeAnalyticSlider(postKey)}
                                deleteDisable
                            />
                        </div>
                    )}
                    {post.analyticsScreenshotUrls && post.analyticsScreenshotUrls.length > 0 && (
                        <div className="postAnalytics">
                            <span>Post Analytics:</span>
                            <span className="screenshots" onClick={() => this.openAnalyticSlider(postKey)}>
                                {post.analyticsScreenshotUrls.length} screenshots
                            </span>
                        </div>
                    )}
                    {postStatus === PUBLISHED && postResources.length > 0 && (
                        <div id="files-uploaded">
                            <div className="files-uploaded-text">Uploaded files:</div>
                            <div className="files-uploaded-container">
                                {post &&
                                    postResources.map((fileItem) => {
                                        const file = parseJson(fileItem) || {
                                            resourceId: fileItem,
                                            url: fileItem,
                                            name: 'File',
                                        };
                                        const target =
                                            this.state.downloadingArray.find(({ url }) => url === file.url) || {};
                                        return (
                                            <div
                                                key={file.resourceId}
                                                onClick={() => this.downloadVideo(file.url, file.name)}
                                                className={
                                                    target.percentage &&
                                                    target.percentage > 0 &&
                                                    target.percentage < 100
                                                        ? 'video-name video-downloading'
                                                        : 'video-name'
                                                }
                                            >
                                                <p>{file.name}</p>
                                                {target.percentage > 0 && target.percentage < 100 && (
                                                    <p className="video-percentage">
                                                        Downloading {target.percentage ? target.percentage : 0}%
                                                    </p>
                                                )}
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    )}
                </div>
                {postStatus === PUBLISHED && <div className="linkWrapper">
                    {post.connectionType !== 'PUBLIC' && post.publishType === "MANUAL" && <div onClick={() => review('view')}>View draft</div>}
                    {postUrl && <div>
                            <a href={postUrl.indexOf('://') > 0 ? postUrl : `http://${postUrl}`} target="_blank">
                                View post
                            </a>
                        </div>}
                </div>}
                {get(post, 'sharedOn.length') > 0 &&
                    get(post, 'sharedOn', []).map((shared) => {
                        return (
                            <div className="shared" key={`${postKey}_${shared.network}`}>
                                <div className="title">
                                    <ReachIcon network={shared.network.toLowerCase()} value={1} size="16" />
                                    {shared.network === 'YOUTUBE'
                                        ? 'YouTube Shares'
                                        : `${shared.network.toLowerCase()} Shares`}
                                </div>
                                <div className="postLink">
                                    <a
                                        href={shared.url.indexOf('://') > 0 ? shared.url : `http://${shared.url}`}
                                        target="_blank"
                                    >
                                        View post
                                    </a>
                                </div>
                            </div>
                        );
                    })}
                {postStatus === DRAFT && (
                    <div className="actions draft">
                        <div>
                            <span
                                className="status"
                                style={{ backgroundColor: Lookup.getStatusType(post.status).color }}
                            >
                                {post.status === 'APPROVED' && (
                                    <img src={Lookup.getStatusType(post.status).img} alt="" />
                                )}
                                {Lookup.getStatusType(post.status).label}
                            </span>
                        </div>
                        <div className="date">
                            <span>Draft Submitted</span>
                            {showDate(post.submittedDate)}
                            {draftOverDue > -1 && (
                                <div className="dueStatusNotice">
                                    {draftOverDue === 0 ? 'Draft Due ' : 'Draft Past Due '}
                                    {showDate(draftDueDate)}
                                </div>
                            )}
                        </div>
                        <div className="buttons">
                            {permission && (
                                <div className="button review" onClick={() => review(mainBtnAction)}>
                                    {mainBtnText}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    }

    onPayFluencer = (isUserPaid, list, isPublic) => () => {
        const { assignmentId, uid, assignmentPublicUserId } = list;
        if (!isUserPaid) this.markInfluencerAsPaid(assignmentId, isPublic ? assignmentPublicUserId : uid, isPublic);
    };

    onOpenModal = (list, index) => () => {
        this.setState({ paymentModalId: list.assignmentUserId, paymentModelIndex: index });
    };

    onCloseModal = () => {
        this.setState({ paymentModalId: null, paymentModelIndex: null });
    };

    onUpdateCampaign = (updatedCampaign) => {
        this.setState({ campaign: updatedCampaign });
    };

    handleSelectExport = (item) => {
        const tempList = this.state.selectedUsersForExport;
        const pos = tempList.findIndex(
            (existItem) => existItem.assignmentId === item.assignmentId && existItem.userId === item.userId,
        );
        if (pos === -1) {
            tempList.push(item);
        } else {
            tempList.splice(pos, 1);
        }
        this.setState({
            selectedUsersForExport: tempList,
        });
    };

    setPaymentPublic = (user) => {
        this.setState({ publicPayment: user });
    };

    getTransactions = (isPublic, list) => {
        const { posts } = list;
        const func = isPublic ? Api.getTransactionPublic : Api.getTransactionConnected;
        const id = isPublic ? list.assignmentPublicUserId : list.assignmentUserId
        func(id).then(items => {
            if(items.length > 0) {
                this.setState({ transactionLogData: items, transactionList: list });
            } else {
                this.setState({ transactionLogData: posts, transactionList: list });
            }
        })
    }

    renderPost(index, list, showAssignmentTitle, postStatus) {
        const {
            showActions,
            paymentModalId,
            paymentModelIndex,
            campaign,
            exportListInProgress,
            exportInProgress,
            selectedUsersForExport,
        } = this.state;
        const { profileAccount } = this.props;
        const self = this;
        const boxClass = 'postLine';
        const totalReach = 0;
        const showList = [];
        let allItems = {};
        let currentType = null;
        list.posts.forEach((post) => {
            const showItem = this.renderSinglePost(post, list.assignmentId, list.userId, postStatus);
            const typeTouse = postStatus === PUBLISHED ? post.postType : post.type;
            if (post.deliverableCount && currentType !== typeTouse) {
                currentType = post.type;
                let highestIndex = 0;
                list.posts.forEach((item) => {
                    const itemTypeToUse = postStatus === PUBLISHED ? item.postType : item.type;
                    if (itemTypeToUse === typeTouse) {
                        highestIndex += 1;
                    }
                })
                showList.push(<div className={postStatus === PUBLISHED ? "deliverableLabelPublish" : "deliverableLabelDraft"}>Submitted: {highestIndex}           Required: {post.deliverableCount}</div>)
            }
            if (showItem) {
                showList.push(showItem);
            }
        });
        const highestIndex = Object.values(allItems).reduce((a, b) => a + b, 0);
        if (list?.autoDetectedPosts?.length > 0)
            showList.push(
                <AutoDetectedPosts
                    key="auto-detected-posts"
                    posts={list.autoDetectedPosts}
                    refetchData={this.fetchAllPosts}
                />,
            );

        const isUserPaid = list.assignmentUserStatus === 'PAID';
        const permission =
            profileAccount &&
            (profileAccount.role === AGENT_STR ||
                profileAccount.role === SUPER_ADMIN_STR ||
                profileAccount.role === BRAND_OWNER_STR);
        let linkToDetails = `/influencerDetailPage/${list.userId}`;

        const { assignmentId, assignmentUserId, assignmentPublicUserNetworkType } = list;
        const posInExportList = selectedUsersForExport.findIndex(
            (existItem) => existItem.assignmentId === list.assignmentId && existItem.userId === list.userId,
        );
        const tag = `${list.assignmentId}_${list.userId}`;
        const showWait =
            exportInProgress && postStatus === DRAFT
                ? exportListInProgress.findIndex(
                      (existItem) => existItem.assignmentId === list.assignmentId && existItem.userId === list.userId,
                  )
                : -1;
        const isPublic =
            list.posts?.[0]?.connectionType === 'PUBLIC' || list.autoDetectedPosts?.[0]?.connectionType === 'PUBLIC';
        let payText = isUserPaid ? 'Payment Completed' : 'Pay Now';
        let isModalOpen =
            index === paymentModelIndex && postStatus === PUBLISHED && assignmentUserId === paymentModalId;
        if (isPublic) {
            payText = isUserPaid ? 'Payment Approved' : 'Approve Payment';
            isModalOpen = index === paymentModelIndex && assignmentUserId === paymentModalId;
            linkToDetails = `/publicInfluencerDetailsPage/${
                list.userId
            }/${assignmentPublicUserNetworkType.toUpperCase()}`;
        }
        let showChangeURL = true;
        if (!isPublic) {
            if (list?.autoDetectedPosts?.length === 0) {
                showChangeURL = false
            }
        }
        return (
            <React.Fragment key={index}>
                <div className="assignmentUser">
                    {showAssignmentTitle && <div className="assignmentTitle">{list.assignmentName} </div>}
                    <div
                        className={cx(boxClass, { publishBox: postStatus === PUBLISHED })}
                        style={showWait !== -1 ? { position: 'relative' } : {}}
                    >
                        <div>
                            <div className="userInfoContainer">
                                <div className="exportCheckboxContainer">
                                    {postStatus !== PUBLISHED && (
                                        <Checkbox
                                            size={16}
                                            changeFunction={() => this.handleSelectExport(list)}
                                            checked={posInExportList !== -1}
                                        />
                                    )}
                                </div>
                                <div className="userInfo">
                                    <div className={cx('firstLine', { draftMode: postStatus === DRAFT })}>
                                        <div className="photo">
                                            {list.authenticityRating && (
                                                <div className="score">
                                                    <img src="/images/Score.svg" />
                                                    <span>{Math.round(list.authenticityRating)}</span>
                                                </div>
                                            )}
                                            {list.authenticityRating && (
                                                <div className="online">
                                                    <img src="/images/Verified.svg" />
                                                </div>
                                            )}
                                            <img src={Format.influencerPhotoSrc(list.userPictureUrl)} />
                                        </div>

                                        <div className="userName">
                                            <Link to={linkToDetails} target="_blank">
                                                {`${list.userFirstName}${
                                                    list.userLastName ? ` ${list.userLastName}` : ''
                                                }`}
                                            </Link>
                                        </div>
                                    </div>
                                    {postStatus === PUBLISHED && (
                                        <div className="reach">
                                            <span>Total Followers: </span>
                                            {Format.expressInK(Number(totalReach))}
                                        </div>
                                    )}
                                    <div className="creatorLabel">
                                        {isPublic ? 'Public Creator' : 'Connected Creator'}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`${postStatus.toLowerCase()}Info`}>
                            {showList}
                            {postStatus === PUBLISHED && (
                                <div className="actions published">
                                    <div>
                                        <div className="button rate">Rate Creator</div>
                                    </div>
                                    <div className="buttons">
                                        <div className="button paymentInfo">
                                            Review Payment Info
                                            <img src="/images/ic-bown-b.svg" alt="" />
                                        </div>
                                        <div
                                            className={isUserPaid || !permission ? 'button invalid' : 'button pay'}
                                            onClick={
                                                isUserPaid || !permission
                                                    ? () => 'default'
                                                    : this.onOpenModal(list, index)
                                            }
                                        >
                                            {payText}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        {permission && (
                            <span className="dropdown-menu-wrapper">
                                <DropdownMenu separatorIdx={postStatus !== DRAFT && 1}>
                                    <div>
                                        <a
                                            href={
                                                isPublic
                                                    ? `/publicInfluencerDetailsPage/${
                                                          list.userId
                                                      }/${assignmentPublicUserNetworkType.toUpperCase()}`
                                                    : `/influencerDetailPage/${list.userId}`
                                            }
                                            target="_blank"
                                        >
                                            View Profile
                                        </a>
                                    </div>
                                    {postStatus !== DRAFT && showChangeURL && (
                                        <div onClick={() => this.setState({ updateURLModalData: list })}>
                                            Update Post URL
                                        </div>
                                    )}
                                    {postStatus !== DRAFT && <div
                                        className="operation-selection"
                                        onClick={() => this.getTransactions(isPublic, list)}
                                    >
                                        Transaction Log
                                    </div>}
                                </DropdownMenu>
                            </span>
                        )}
                        <div className={cx('waitOverlap', { hide: showWait === -1 })}>
                            <div className="waitContainer" ref={(e) => (this[tag] = e)}>
                                <PleaseWaitPartial show={showWait !== -1} container={this[tag]} />
                            </div>
                        </div>
                    </div>
                </div>
                {isModalOpen && (
                    <PaymentModal
                        post={list}
                        onCloseModal={this.onCloseModal}
                        onSubmit={this.onPayFluencer(isUserPaid, list, isPublic)}
                        onUpdateCampaign={this.onUpdateCampaign}
                        campaign={campaign}
                        updateEmail={(currentBrandEmail) => this.setState({ currentBrandEmail })}
                    />
                )}
            </React.Fragment>
        );
    }

    handleSelectAssignments(value) {
        if (value.find((assignment) => assignment.value === 'ALL')) {
            if (this.state.selectedAssignment[0] !== 'ALL') {
                value = ['ALL'];
            } else if (value.length > 1) {
                const index = value.findIndex((assignment) => assignment.value === 'ALL');
                value.splice(index, 1);
            }
        }
        if (value.length === 0 || value.length === this.state.assignments.length - 1) {
            value = ['ALL'];
        }
        this.setState(
            {
                selectedAssignment: value,
                retrievalInProgress: true,
            },
            async () => {
                await this.fetchAllPosts(false);
            },
        );
    }

    handleFilter(value) {
        const filter = value ? value.value : '';
        this.setState({ filter, currentPage: 1 }, () => {
            this.fetchAllPosts(true);
        });
    }

    handleSorting(value) {
        const sortBy = value ? value.value : '';
        this.setState(
            {
                sortBy,
                currentPage: 1,
            },
            () => {
                this.fetchAllPosts(true);
            },
        );
    }

    postTypeChangeHandler(postStatus) {
        this.setState(
            {
                postStatus,
                sortBy: '',
                filter: '',
                currentPage: 1,
                retrievalInProgress: true,
                selectedUsersForExport: [],
            },
            () => {
                this.fetchAllPosts(true);
            },
        );
    }

    handlePageChange(nextPage) {
        this.setState(
            {
                currentPage: nextPage,
            },
            () => {
                this.fetchAllPosts(true);
                const elements = document.getElementsByClassName('campaignWorkBody');
                if (elements && elements.length > 0) {
                    elements[0].scrollTop = 0;
                }
            },
        );
    }

    /** Warning modal */
    onSetWarningModal = (titleId, messageId) => {
        this.setState({
            errorModal: {
                isOpen: true,
                titleId,
                messageId,
            },
        });
    };

    onCloseWarningModal = () => {
        this.setState({
            errorModal: {
                isOpen: false,
                titleId: undefined,
                messageId: undefined,
            },
        });
    };

    /** --- */

    markPayloadAsPaid = async (payload, self) => {
        let results;
        try {
            results = await Api.markSelectedInfluencersAsPaid(payload);
        } catch (err) {
            this.onSetWarningModal('Error', err.message);
            return;
        }

        if (results.error) {
            this.onSetWarningModal('posts.noSubscriptionTitle', 'posts.noSubscriptionMessage');
            return;
        }

        if (results) {
            const postList = self.state.postlist;

            if (postList) {
                postList.forEach((clist, i) => {
                    const { assignmentId } = clist;
                    const uids = results[assignmentId];

                    if (uids && uids.includes(clist.uid)) {
                        clist.assignmentUserStatus = 'PAID';
                    }
                });
            }
            self.setState({ postlist: postList });
        }

        this.setState({ paymentModalId: null });
    };

    markInfluencerAsPaid(assignmentId, uid, isPublic) {
        const { currentBrandEmail } = this.state;
        const payload = {};
        payload[assignmentId] = [uid];
        if (!isPublic) {
            this.markPayloadAsPaid(payload, this);
        } else {
            const publicPayload = { billingContactEmail: currentBrandEmail };
            Api.publicPayment(assignmentId, uid, publicPayload).then((res) => {
                this.onCloseModal();
                this.fetchAllPosts();
            });
        }
    }

    fetchAllPosts(isCurrent, initial) {
        this.setState({ retrievalInProgress: true });
        const self = this;
        Api.getAssignmentList(Number(this.campaignId))
            .then((data) => {
                const dataHandle = (data) => {
                    const res = data.results;
                    const assignments = [];
                    let { selectedAssignment } = self.state;
                    if (Array.isArray(res) && res.length > 0) {
                        res.sort((a, b) => (a.id > self.assignmentId ? a.id - b.id : b.id - a.id));
                        res.forEach((assignment) => {
                            if (Number(assignment.id) === self.assignmentId) {
                                assignments.unshift({
                                    value: assignment.id,
                                    label: assignment.name,
                                });
                            } else {
                                assignments.push({
                                    value: assignment.id,
                                    label: assignment.name,
                                });
                            }
                        });
                    }
                    assignments.unshift({ value: 'ALL', label: 'All Assignments' });
                    if (selectedAssignment.length === 0) {
                        selectedAssignment = ['ALL'];
                    }
                    const getDraftList = (payload) => {
                        return new Promise((resolve, reject) => {
                            Api.getCampaignDrafts(payload)
                                .then((response) => {
                                    const draftPosts = response.results || [];
                                    const draftMeta = response.meta || {};
                                    const returnRes = [];
                                    if (Array.isArray(draftPosts) && draftPosts.length > 0) {
                                        if (!self.state.sortBy) {
                                            draftPosts.sort((a, b) => {
                                                return Number(b.assignmentId) === Number(a.assignmentId)
                                                    ? Number(b.assignmentUserId) - Number(a.assignmentUserId)
                                                    : Number(b.assignmentId) - Number(a.assignmentId);
                                            });
                                        }
                                        const tempList = this.state.displayIndexes.draft;
                                        draftPosts.forEach((draft) => {
                                            const found = tempList.find(
                                                (t) =>
                                                    t.id === draft.id && t.assignmentUserId === draft.assignmentUserId,
                                            );
                                            if (!found) {
                                                const obj = {};
                                                obj.id = draft.id;
                                                obj.type = draft.type;
                                                obj.assignmentUserId = draft.assignmentUserId;
                                                obj.index =
                                                    tempList.filter(
                                                        (t) =>
                                                            t.type === draft.type &&
                                                            t.assignmentUserId === draft.assignmentUserId,
                                                    ).length + 1;
                                                // eslint-disable-next-line no-param-reassign
                                                draft.displayIndex = draft.deliverable
                                                    ? ` (${obj.index}/${draft.deliverable})`
                                                    : '';
                                                draft.index = obj.index
                                                draft.deliverableCount = draft.deliverable;
                                                tempList.push(obj);
                                            } else if (!draft.displayIndex) {
                                                // eslint-disable-next-line no-param-reassign
                                                draft.displayIndex = draft.deliverable
                                                    ? ` (${found.index}/${draft.deliverable})`
                                                    : '';
                                                draft.index = found.index
                                                draft.deliverableCount = draft.deliverable;
                                            }
                                        });
                                        const tempIndexes = this.state.displayIndexes;
                                        tempIndexes.draft = tempList;
                                        self.setState({
                                            displayIndexes: tempIndexes,
                                        });
                                    }
                                    resolve({
                                        posts: draftPosts,
                                        meta: draftMeta,
                                    });
                                })
                                .catch((err) => {
                                    reject(err);
                                });
                        });
                    };

                    const getPublishedList = (payload) => {
                        return new Promise((resolve, reject) => {
                            Api.getCampaignPublishedPosts(payload)
                                .then((response) => {
                                    const publishedPosts = response.results || [];
                                    const publishedMeta = response.meta || {};
                                    const tempList = this.state.displayIndexes.published;
                                    publishedPosts.forEach((userPosts) => {
                                        if (Array.isArray(userPosts.posts)) {
                                            userPosts.posts.forEach((post) => {
                                                const found = tempList.find(
                                                    (t) =>
                                                        t.id === post.postId &&
                                                        t.assignmentUserId === userPosts.assignmentUserId,
                                                );
                                                if (!found) {
                                                    const obj = {};
                                                    obj.id = post.postId;
                                                    obj.type = post.postType;
                                                    obj.assignmentUserId = userPosts.assignmentUserId;
                                                    obj.index =
                                                        tempList.filter(
                                                            (t) =>
                                                                t.type === post.postType &&
                                                                t.assignmentUserId === userPosts.assignmentUserId,
                                                        ).length + 1;
                                                    // eslint-disable-next-line no-param-reassign
                                                    post.displayIndex = post.deliverable
                                                        ? ` (${obj.index}/${post.deliverable})`
                                                        : '';
                                                    post.index = obj.index
                                                    post.deliverableCount = post.deliverable
                                                    tempList.push(obj);
                                                } else if (!post.displayIndex) {
                                                    // eslint-disable-next-line no-param-reassign
                                                    post.displayIndex = post.deliverable
                                                        ? ` (${found.index}/${post.deliverable})`
                                                        : '';
                                                    post.index = found.index
                                                    post.deliverableCount = post.deliverable
                                                }
                                            });
                                        }
                                    });
                                    const tempIndexes = this.state.displayIndexes;
                                    tempIndexes.published = tempList;
                                    self.setState({
                                        displayIndexes: tempIndexes,
                                    });

                                    resolve({
                                        posts: publishedPosts,
                                        meta: publishedMeta,
                                    });
                                })
                                .catch((err) => {
                                    reject(err);
                                });
                        });
                    };
                    const allAssignmentsSelected =
                        self.state.selectedAssignment.length === 1 && self.state.selectedAssignment[0] === 'ALL';
                    const selectedAssignmentIds = allAssignmentsSelected
                        ? assignments
                              .filter((assignment) => assignment.value !== 'ALL')
                              .map((assignment) => assignment.value)
                        : self.state.selectedAssignment
                              .filter((assignment) => assignment !== 'ALL')
                              .map((assignment) => assignment.value);

                    const payload = {
                        campaignId: self.campaignId,
                        params: {
                            num: 20,
                            page: self.state.currentPage,
                            sortby: self.state.sortBy,
                            filter: self.state.filter,
                            assignmentIds: selectedAssignmentIds,
                        },
                    };
                    const getAllData = async () => {
                        let publishedPosts;
                        let draftsData = {};
                        const newStateObj = {
                            assignments,
                            selectedAssignment,
                        };
                        if (isCurrent) {
                            if (self.state.postStatus === DRAFT) {
                                try {
                                    draftsData = await getDraftList(payload);
                                    newStateObj.drafts = draftsData.posts || null;
                                    newStateObj.draftMeta = draftsData.meta || null;
                                } catch (e) {
                                    console.log(e);
                                }
                            } else {
                                try {
                                    publishedPosts = await getPublishedList(payload);
                                    newStateObj.posts = publishedPosts.posts || null;
                                    newStateObj.publishedMeta = publishedPosts.meta || null;
                                } catch (e) {
                                    console.log(e);
                                }
                            }
                        } else {
                            try {
                                draftsData = await getDraftList(payload);
                                newStateObj.drafts = draftsData.posts || null;
                                newStateObj.draftMeta = draftsData.meta || null;
                            } catch (e) {
                                console.log(e);
                            }
                            try {
                                publishedPosts = await getPublishedList(payload);
                                newStateObj.posts = publishedPosts.posts || null;
                                newStateObj.publishedMeta = publishedPosts.meta || null;
                            } catch (e) {
                                console.log(e);
                            }
                        }
                        self.setState(newStateObj, self.getCurrentLists);
                    };
                    getAllData();
                };
                if (initial) {
                    const assignList = formatAssignmentRes(data.results);
                    const selectedAssignments =
                        assignList.find(({ value }) => value === Number(self.assignmentId)) || 'ALL';
                    self.setState(
                        {
                            assignments: [{ value: 'ALL', label: 'All assignments' }].concat(assignList),
                            selectedAssignment: [selectedAssignments],
                        },
                        () => dataHandle(data),
                    );
                } else {
                    dataHandle(data);
                }
            })
            .catch((err) => {
                const f = this.props.apiErrFunction;
                if (typeof f === 'function') {
                    f(err);
                }
            });
    }

    getCurrentLists(selected) {
        const { drafts, posts, postStatus, selectedUsersForExport } = this.state;
        const selectedAssignment = selected || this.state.selectedAssignment;

        if (postStatus === DRAFT) {
            const exportListInProgress = exportContentHelper.getExportListInProgress();
            exportContentHelper.updateCallbacks(this.exportSuccessCallback, this.exportErrorCallback);
            const selectedUsers = [...selectedUsersForExport];
            for (let i = 0; i < exportListInProgress.length; i++) {
                const posInSelectedUser = selectedUsers.findIndex(
                    (item) =>
                        item.assignmentId === exportListInProgress[i].assignmentId &&
                        item.userId === exportListInProgress[i].userId,
                );
                const posInDraftList = drafts.findIndex(
                    (item) =>
                        item.assignmentId === exportListInProgress[i].assignmentId &&
                        item.userId === exportListInProgress[i].userId,
                );
                if (posInDraftList !== -1 && posInSelectedUser === -1) {
                    selectedUsers.push(exportListInProgress[i]);
                }
            }
            this.setState({
                draftlist: drafts,
                postlist: posts,
                selectedAssignment,
                retrievalInProgress: false,
                exportListInProgress,
                exportInProgress: exportListInProgress.length > 0,
                selectedUsersForExport: selectedUsers,
            });
        } else {
            this.setState({
                draftlist: drafts,
                postlist: posts,
                selectedAssignment,
                retrievalInProgress: false,
            });
        }
    }

    getCurrentFilterList = (postStatus) => {
        if (postStatus === DRAFT) {
            return [
                { value: 'needsReview', label: 'NEEDS REVIEW' },
                { value: 'inReview', label: 'IN PROGRESS' },
                { value: 'editsSent', label: 'EDITS SENT' },
                { value: 'approved', label: 'APPROVED' },
            ];
        }
        return [
            { value: 'notPaid', label: 'Not Paid' },
            { value: 'paid', label: 'Paid' },
        ];
    };

    getSortList = (postStatus) => {
        if (postStatus === DRAFT) {
            return [
                { value: 'name', label: 'Name' },
                { value: 'draftDate', label: 'Draft Submitted' },
            ];
        }
        return [
            { value: 'name', label: 'Name' },
            { value: 'publishDate', label: 'Publish Date' },
        ];
    };

    closeApiError = () => {
        this.setState({ showApiError: false });
    };

    exportSuccessCallback = () => {
        this.setState({
            exportInProgress: false,
            exportListInProgress: [],
        });
    };

    exportErrorCallback = (err) => {
        this.setState({
            exportInProgress: false,
            exportListInProgress: [],
        });
        this.apiError(err);
    };

    handleExport = () => {
        const self = this;
        const { selectedUsersForExport, exportListInProgress } = this.state;
        const newExportListInProgress = [...exportListInProgress];
        for (let i = 0; i < selectedUsersForExport.length; i++) {
            const pos = newExportListInProgress.findIndex(
                (item) =>
                    item.assignmentId === selectedUsersForExport[i].assignmentId &&
                    item.userId === selectedUsersForExport[i].userId,
            );
            if (pos === -1) {
                newExportListInProgress.push(selectedUsersForExport[i]);
            }
        }
        this.setState(
            {
                exportInProgress: true,
                exportListInProgress: newExportListInProgress,
            },
            () => {
                exportContentHelper.startExport(
                    newExportListInProgress,
                    self.exportSuccessCallback,
                    self.exportErrorCallback,
                );
            },
        );
    };

    getSelectedUsersByWFormUploaded(hasWFormUploaded = true) {
        const { selectedUsersForExport } = this.state;
        if (!selectedUsersForExport || selectedUsersForExport.length <= 0) return [];

        return hasWFormUploaded
            ? selectedUsersForExport.filter((u) => u.posts[0].wformUploaded)
            : selectedUsersForExport.filter((u) => !u.posts[0].wformUploaded);
    }

    handleFormExport = () => {
        const noFormSelectedUsers = this.getSelectedUsersByWFormUploaded(false);
        const selectedUsersWithForms = this.getSelectedUsersByWFormUploaded(true);
        if (noFormSelectedUsers.length > 0) {
            this.setState({ openNoForm: true });
        } else if (selectedUsersWithForms.length > 0) {
            this.exportForm(selectedUsersWithForms.map((u) => u.userId));
        }
    };

    continueDownload = () => {
        const downloadArray = this.getSelectedUsersByWFormUploaded(true);
        if (downloadArray.length > 0) {
            this.exportForm(downloadArray.map((u) => u.userId));
        }
        this.setState({ openNoForm: false });
    };

    apiError = (err) => {
        this.setState({ showApiError: true, apiError: err });
    };

    openExportWindow = () => {
        const { openExport } = this.state;
        this.setState({ openExport: !openExport });
    };

    closeExport = () => {
        this.setState({ openExport: false });
    };

    selectAllHandler = () => {
        const { selectedUsersForExport, postlist, draftlist, postStatus } = this.state;
        const draftSetList = setPostListArray(draftlist);
        const draftShowList = [];
        const publishedShowList = [];
        const self = this;
        if (draftSetList.length > 0) {
            let assignmentId = 0;
            draftSetList.forEach((clist, i) => {
                draftShowList.push(self.renderPost(i, clist, clist.assignmentId !== assignmentId, DRAFT));
                assignmentId = clist.assignmentId;
            });
        }
        if (postlist.length > 0) {
            let assignmentId = 0;
            postlist.forEach((clist, i) => {
                publishedShowList.push(self.renderPost(i, clist, clist.assignmentId !== assignmentId, PUBLISHED));
                assignmentId = clist.assignmentId;
            });
        }
        const currentList = postStatus === DRAFT ? draftSetList : postlist;
        const allSelectedStatus = selectedUsersForExport.length === currentList.length;
        if (allSelectedStatus || selectedUsersForExport.length) {
            this.setState({
                selectedUsersForExport: [],
            });

            return;
        }
        this.setState({
            selectedUsersForExport: currentList,
        });
    };

    closeError = () => {
        this.setState({ openNoForm: false });
    };

    changePublicUser = (e) => {
        const publishedPosts = [
            {
                url: '',
                urlValid: true,
                preparedFile: [],
                uploadProgressPercentage: null,
                onUploadCancel: null,
                type: null,
            },
        ];
        this.setState({ currentPublicUser: e.value, publishedPosts });
    };

    addNewPublishedPost = () => {
        const { publishedPosts } = this.state;
        publishedPosts.push({
            url: '',
            urlValid: true,
            preparedFile: [],
            uploadProgressPercentage: null,
            onUploadCancel: null,
            type: null,
        });
        this.setState({ publishedPosts });
    };

    setOnUploadCancel = (fn, abort, index) => {
        const onUploadCancel = () => {
            const { publishedPosts } = this.state;
            if (fn) fn();
            if (abort) abort();
            publishedPosts[index].uploadProgressPercentage = null;
            publishedPosts[index].onUploadCancel = null;
            this.setState({ publishedPosts });
        };
        this.setState({ onUploadCancel });
    };

    renderFilepond = (index) => {
        const { publishedPosts } = this.state;
        return (
            <FilePond
                allowMultiple
                maxFiles={20}
                labelTapToCancel=""
                labelTapToUndo="Tap to remove file"
                oninitfile={() => {
                    this.setState({ isUploading: true });
                }}
                onprocessfiles={() => {
                    this.setState({ isUploading: false });
                }}
                server={{
                    url: '',
                    revert: (uniqueFileId, load, error) => {
                        const copyPost = publishedPosts[index];
                        copyPost.preparedFile = copyPost.preparedFile.filter((item) => item.id != uniqueFileId);
                        publishedPosts[index] = copyPost;
                        this.setState({ publishedPosts });
                        load();
                    },
                    process: async (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
                        return Api.uploadResource(
                            file,
                            true,
                            null,
                            null,
                            (val) => progress(1, val, 100),
                            (fn) => this.setOnUploadCancel(fn, abort, index),
                        )
                            .then((data) => {
                                const copyPost = publishedPosts[index];
                                if (data && data.id) {
                                    load(data.id);
                                    copyPost.preparedFile.push({ ...data, size: file.size });
                                    copyPost.uploadProgressPercentage = null;
                                    copyPost.onUploadCancel = null;
                                    publishedPosts[index] = copyPost;
                                    this.setState({ publishedPosts });
                                } else {
                                    copyPost.uploadProgressPercentage = null;
                                    copyPost.onUploadCancel = null;
                                    publishedPosts[index] = copyPost;
                                    this.setState({ publishedPosts });
                                }
                            })
                            .then((items) => {
                                return {
                                    abort: () => {
                                        const copyPost = publishedPosts[index];
                                        if (copyPost.onUploadCancel) {
                                            copyPost.onUploadCancel = null;
                                            publishedPosts[index] = copyPost;
                                            this.setState({ publishedPosts });
                                        }
                                        // This function is entered if the user has tapped the cancel button
                                        copyPost.uploadProgressPercentage = null;
                                        copyPost.onUploadCancel = null;
                                        publishedPosts[index] = copyPost;
                                        this.setState({ publishedPosts });
                                        // Let FilePond know the request has been cancelled
                                        abort();
                                    },
                                };
                            });
                    },
                }}
                name="file"
                labelIdle='Drag & Drop your file or <span class="filepond--label-action">Browse</span>'
            />
        );
    };

    urlChange = (e, index) => {
        const { publishedPosts } = this.state;
        const postCopy = publishedPosts[index];
        postCopy.url = e.target.value;
        const youtubeRegex1 = /\bhttps:\/\/m\.youtube\.com\/watch\?v=(.)/;
        const youtubeRegex2 = /\bhttps:\/\/www\.youtube\.com\/watch\?v=(.)/;
        const youtubeRegex3 = /\bhttps:\/\/youtu\.be\/(.)/;
        const instagramRegex1 = /\bhttps?:\/\/?(?:www\.)?instagram\.com\/(?:p|reel|reels)\/([^/?#&]+)\/[^/]*$/;
        const instagramRegex2 = /\bhttps:\/\/www\.instagram\.com\/stories\/[^/]*\/[^/]*\/[^/]*$/;
        const tikTokRegex1 = /\bhttps:\/\/www\.tiktok\.com\/@[^/]*\/video\/[^/]*$/;
        const tikTokRegex2 = /\bhttps:\/\/vm\.tiktok\.com\/[^/]*\/[^/]*$/;
        const arrayForRegex = [
            youtubeRegex1.test(e.target.value),
            youtubeRegex2.test(e.target.value),
            youtubeRegex3.test(e.target.value),
            instagramRegex1.test(e.target.value),
            instagramRegex2.test(e.target.value),
            tikTokRegex1.test(e.target.value),
            tikTokRegex2.test(e.target.value),
        ];
        postCopy.urlValid = arrayForRegex.includes(true);
        const indexUsed = arrayForRegex.findIndex((element) => element === true);
        if (indexUsed !== -1) {
            if (indexUsed <= 2) {
                postCopy.type = 'YOUTUBE';
            } else if (indexUsed <= 4) {
                postCopy.type = 'INSTAGRAM';
            } else {
                postCopy.type = 'TIKTOK';
            }
        } else {
            postCopy.type = null;
        }
        publishedPosts[index] = postCopy;
        this.setState({ publishedPosts });
    };

    removePublishedPost = (index) => {
        const { publishedPosts } = this.state;
        delete publishedPosts[index];
        this.setState({ publishedPosts });
    };

    publishedPostSection = (index) => {
        const { publishedPosts } = this.state;
        return (
            <div key={`publishedSection${index}`} className="publishedPostSection">
                <div className="titleSection">
                    <div className="title">Published Post</div>
                    {index > 0 && (
                        <div className="remove" onClick={() => this.removePublishedPost(index)}>
                            Remove
                        </div>
                    )}
                </div>
                <div className="labelSection">
                    <div className="label">Post URL</div>
                    <div className="required">*Required</div>
                </div>
                <input
                    className="urlInput"
                    placeholder="www.url.com"
                    onChange={(e) => this.urlChange(e, index)}
                    value={publishedPosts[index].url}
                />
                {!publishedPosts[index].urlValid && <div className="error">Incorrect URL</div>}
                <div className="urlMessage">Enter Instagram, TitTok or YouTube URL</div>
                <div className="labelSection">
                    <div className="label">
                        Upload Media{' '}
                        {publishedPosts[index].preparedFile.length > 0 &&
                            `(${publishedPosts[index].preparedFile.length})`}
                    </div>
                </div>
                {this.renderFilepond(index)}
            </div>
        );
    };

    submitStatusUpdate = () => {
        const { publishedPosts, currentPublicUser } = this.state;
        const body = [];
        const errors = [];
        publishedPosts.forEach((post) => {
            const resourceIds = [];
            post.preparedFile.forEach((file) => {
                resourceIds.push(file.id);
            });
            const postItem = {
                contentUrl: post.url,
                networkType: post.type,
                postType: post.type === 'INSTAGRAM' || post.type === 'TIKTOK' ? `${post.type}_POST` : post.type,
                resourceIds,
            };
            if (!post.type) {
                errors.push('please check all your urls');
            }
            body.push(postItem);
        });
        if (errors.length === 0) {
            this.setState({ isUploading: true });
            Api.addPublishedPost(this.assignmentId, currentPublicUser, body)
                .then((res) => {
                    this.setState(
                        { publishedModal: false, publishedPosts: [], currentPublicUser: null, isUploading: false },
                        () => this.fetchAllPosts(undefined, true),
                        this.resetPublishedPost,
                    );
                })
                .catch((e) => {
                    this.setState({ isUploading: false });
                    if (e && e.originalMessage) {
                        this.onSetWarningModal('Error', e.originalMessage);
                    }
                });
        } else {
            this.onSetWarningModal('Error', errors[0]);
        }
    };

    resetPublishedPost = () => {
        this.setState({ publishedPosts: [], currentPublicUser: null });
    };

    editUrl = (value, index, networktype) => {
        const { edittedUser } = this.state;
        const userCopy = { ...edittedUser };
        const postCopy = [...edittedUser.posts];
        const postIndexCopy = { ...postCopy[index] };
        postIndexCopy.originalUrl = value;
        const youtubeRegex1 = /\bhttps:\/\/m\.youtube\.com\/watch\?v=(.)/;
        const youtubeRegex2 = /\bhttps:\/\/www\.youtube\.com\/watch\?v=(.)/;
        const youtubeRegex3 = /\bhttps:\/\/youtu\.be\/(.)/;
        const instagramRegex1 = /\bhttps?:\/\/?(?:www\.)?instagram\.com\/(?:p|reel|reels)\/([^/?#&]+)\/[^/]*$/;
        const instagramRegex2 = /\bhttps:\/\/www\.instagram\.com\/stories\/[^/]*\/[^/]*\/[^/]*$/;
        const tikTokRegex1 = /\bhttps:\/\/www\.tiktok\.com\/@[^/]*\/video\/[^/]*$/;
        const tikTokRegex2 = /\bhttps:\/\/vm\.tiktok\.com\/[^/]*\/[^/]*$/;
        if (networktype === 'instagram') {
            postIndexCopy.hasError = !(instagramRegex1.test(value) || instagramRegex2.test(value));
        }
        if (networktype === 'youtube')
            postIndexCopy.hasError = !(
                youtubeRegex1.test(value) ||
                youtubeRegex2.test(value) ||
                youtubeRegex3.test(value)
            );
        if (networktype === 'tiktok') {
            postIndexCopy.hasError = !(tikTokRegex1.test(value) || tikTokRegex2.test(value));
        }
        postIndexCopy.editted = true;
        postCopy[index] = postIndexCopy;
        userCopy.posts = postCopy;
        this.setState({ edittedUser: userCopy });
    };

    render() {
        const {
            draftlist,
            postlist,
            postStatus,
            retrievalInProgress,
            assignments,
            selectedAssignment,
            openNoForm,
            updateURLModalData,
            transactionLogData,
            hasPublic,
            publicUserList,
            currentPublicUser,
            publishedPosts,
            edittedUser,
            isUploading,
        } = this.state;
        const {
            filter,
            sortBy,
            draftMeta,
            publishedMeta,
            selectedUsersForExport,
            exportInProgress,
            openExport,
            publishedModal,
            transactionList
        } = this.state;
        const draftSetList = setPostListArray(draftlist);
        const filters = this.getCurrentFilterList(postStatus);
        const sortList = this.getSortList(postStatus);
        const metaData = postStatus === DRAFT ? draftMeta : publishedMeta;
        const draftPostsQty = draftMeta.totalCount ? draftMeta.totalCount : 0;
        const publishedPostsQty = publishedMeta.totalCount ? publishedMeta.totalCount : 0;
        const totalPages = metaData.totalCount ? Math.ceil(metaData.totalCount / metaData.pageSize) : false;
        const self = this;
        const draftShowList = [];
        const publishedShowList = [];

        if (draftSetList.length > 0) {
            let assignmentId = 0;
            draftSetList.forEach((clist, i) => {
                draftShowList.push(self.renderPost(i, clist, clist.assignmentId !== assignmentId, DRAFT));
                assignmentId = clist.assignmentId;
            });
        }
        if (postlist.length > 0) {
            let assignmentId = 0;
            postlist.forEach((clist, i) => {
                publishedShowList.push(self.renderPost(i, clist, clist.assignmentId !== assignmentId, PUBLISHED));
                assignmentId = clist.assignmentId;
            });
        }
        const currentList = postStatus === DRAFT ? draftSetList : postlist;
        const allSelectedStatus = selectedUsersForExport.lengt && selectedUsersForExport.length === currentList.length;
        const isPartialSelected = selectedUsersForExport.length && !allSelectedStatus;
        const {
            errorModal: { isOpen, titleId, messageId },
        } = this.state;
        const noFormArray = this.getSelectedUsersByWFormUploaded(false);
        let showDraft = this.props.profileAccount.role && this.props.profileAccount.role !== VIEWER_STR;
        if (hasPublic && postStatus === PUBLISHED) {
            showDraft = false;
        }
        let hasErrors = false;
        edittedUser?.posts.forEach((item) => {
            if (item.hasError) {
                hasErrors = true;
            }
        });
        return (
            <div className={cx('campaignPostsList', postStatus.toLowerCase())}>
                <TransactionLog
                    data={transactionLogData}
                    isOpen={!!transactionLogData}
                    close={() => this.setState({ transactionLogData: null })}
                    transactionList={transactionList}
                />
                <UpdatePostURLModal
                    data={updateURLModalData}
                    refetchData={this.fetchAllPosts}
                    isOpen={!!updateURLModalData}
                    close={() => this.setState({ updateURLModalData: null })}
                />
                <Modal overlayClassName="influencerCardOverlay" className="confirmRatesModal" isOpen={openNoForm}>
                    <div className="formError">
                        <div className="title">Missing form</div>
                        <div className="description">The following creator(s) have not uploaded their W8/W9 forms:</div>
                        <ul className="list">
                            {noFormArray.map((user) => (
                                <li key={user.userId}>{`${user.userFirstName} ${user.userLastName}`}</li>
                            ))}
                        </ul>
                        <div className="buttonSection">
                            <div className="cancelButton" onClick={this.closeError}>
                                Cancel
                            </div>
                            <div className="continueButton" onClick={this.continueDownload}>
                                Continue
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal overlayClassName="influencerCardOverlay" className="confirmRatesModal" isOpen={publishedModal}>
                    <div className="publishedPost">
                        <div className="header">Add Published Post</div>
                        <div className={cx('middle', publishedPosts.length > 0 && 'expanded')}>
                            <div className="label">Public Creator</div>
                            <Select
                                className="users"
                                options={publicUserList}
                                value={currentPublicUser}
                                onChange={this.changePublicUser}
                                clearable={false}
                            />
                            {publishedPosts.map((post, index) => this.publishedPostSection(index))}
                            {publishedPosts.length > 0 && publishedPosts.length !== 20 && (
                                <div className="additionalPublishButton" onClick={this.addNewPublishedPost}>
                                    <img src="/images/ic-add-w.svg" alt="add published post" />
                                    Add Published Post
                                </div>
                            )}
                        </div>
                        <div className="buttonSection">
                            <div
                                className="cancel"
                                onClick={() => this.setState({ publishedModal: false }, this.resetPublishedPost)}
                            >
                                Cancel
                            </div>
                            <div
                                className={cx('submit', isUploading && 'disabled')}
                                onClick={!isUploading ? this.submitStatusUpdate : null}
                            >
                                Submit
                            </div>
                        </div>
                    </div>
                </Modal>
                {this.state.isCreateDraftModalVisible && (
                    <CreateDraftModal
                        campaign={this.props.campaign}
                        close={() => this.setState({ isCreateDraftModalVisible: false })}
                        selectedAssignments={selectedAssignment}
                    />
                )}
                <ApiError
                    show={this.state.showApiError}
                    error={this.state.apiError}
                    cancelFunction={this.closeApiError}
                />
                <PleaseWait show={retrievalInProgress} />
                <div className="header">
                    <div className="selectAssignments">
                        <div className="select-box">
                            <div className="label">Assignments:</div>
                            <Select
                                className="select"
                                clearable={false}
                                value={selectedAssignment}
                                multi
                                placeholder=""
                                options={assignments}
                                onChange={(...args) => self.handleSelectAssignments(...args)}
                            />
                        </div>
                    </div>
                    <div className="selectPostType">
                        <div
                            className={postStatus === DRAFT ? 'current' : 'type'}
                            onClick={() => {
                                this.postTypeChangeHandler(DRAFT);
                            }}
                        >
                            <span>Draft ({draftPostsQty})</span>
                            {/* <span className="number">{draftPostsQty}</span> */}
                        </div>
                        <div
                            className={postStatus === PUBLISHED ? 'current' : 'type'}
                            onClick={() => {
                                this.postTypeChangeHandler(PUBLISHED);
                            }}
                        >
                            <span>Published ({publishedPostsQty})</span>
                            {/* <span className="number">{publishedPostsQty}</span> */}
                        </div>
                    </div>
                </div>
                <div className="operation">
                    <div className="selectAllContainer">
                        {postStatus !== PUBLISHED && (
                            <div className="selectAllCheckbox" onClick={this.selectAllHandler}>
                                <Checkbox
                                    id="userCheckbox-selectAll"
                                    size={16}
                                    controlled
                                    partialSelectController
                                    partialSelect={isPartialSelected}
                                    changeFunction={this.selectAllHandler}
                                    checked={allSelectedStatus}
                                />
                                {isPartialSelected || allSelectedStatus
                                    ? `Selected ${selectedUsersForExport.length} creator(s)`
                                    : 'Select All'}
                            </div>
                        )}
                    </div>
                    <div className="actions">
                        <div className="auto-detected-popup-wrapper">
                            <AutoDetectedPopup />
                        </div>
                        {postStatus !== PUBLISHED && (
                            <div className="exportSection" tabIndex={0} onBlur={this.closeExport}>
                                <div
                                    className={cx('exportAddressBtn', {
                                        disabled: selectedUsersForExport.length === 0 || exportInProgress,
                                    })}
                                    onClick={this.openExportWindow}
                                >
                                    <img className="exportIcon" src="/images/Upload.svg" alt="export button" />
                                    <span>Export</span>
                                    <img
                                        className={cx('arrow', { opened: openExport })}
                                        src="/images/ic-bown-b.svg"
                                        alt="arrow"
                                    />
                                </div>
                                {openExport && (
                                    <div className="exportItems">
                                        <div onClick={this.handleExport}>Export Content</div>
                                        <div onClick={this.handleFormExport}>Export W8/W9 form</div>
                                    </div>
                                )}
                            </div>
                        )}
                        <Select
                            className="select filters"
                            clearable
                            isSearchable={false}
                            value={filter}
                            single
                            placeholder="Filters"
                            options={filters}
                            onChange={(...args) => self.handleFilter(...args)}
                        />
                        <Select
                            className="select sort"
                            clearable
                            isSearchable={false}
                            value={sortBy}
                            single
                            placeholder="Sort"
                            options={sortList}
                            onChange={(...args) => self.handleSorting(...args)}
                        />
                    </div>
                    {showDraft && (
                        <div className="createDraftButtonContainer">
                            <div
                                className="createDraftButton"
                                onClick={() => this.setState({ isCreateDraftModalVisible: true })}
                            >
                                <img src="/images/ic-add-w.svg" alt="plus" />
                                Create Draft
                            </div>
                        </div>
                    )}
                    {hasPublic && postStatus === PUBLISHED && (
                        <div className="createDraftButtonContainer">
                            <div className="createDraftButton" onClick={() => this.setState({ publishedModal: true })}>
                                <img src="/images/ic-add-w.svg" alt="plus" />
                                Published Post
                            </div>
                        </div>
                    )}
                </div>
                <div className="postBody">
                    <div className="draftList">{draftShowList.length > 0 && draftShowList}</div>
                    <div className="publishedList">{publishedShowList}</div>
                    {currentList.length === 0 && (
                        <NoResults>No {postStatus.toLowerCase()} posts are yet available.</NoResults>
                    )}
                    <div className="pagination" ref={(e) => (this.paginationContainer = e)}>
                        {totalPages && totalPages > 1 && (
                            <Pagination
                                total={metaData.totalCount}
                                limit={metaData.pageSize}
                                current={metaData.currentPage}
                                parentContainer={this.paginationContainer}
                                handlePageChange={this.handlePageChange}
                            />
                        )}
                    </div>
                </div>
                <ModalWarningWithAction
                    show={isOpen}
                    titleId={titleId}
                    messageId={messageId}
                    actionFunction={this.onCloseWarningModal}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        profileAccount: state.global.loggedInUserProfile,
    };
};

export default connect(mapStateToProps)(Posts);
