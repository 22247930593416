import React from 'react'
import TextInput from './textInput/TextInput'

// props
//  - items : array of strings
//  - changeFunction (takes array of strings)

const HashTagLimit = 20;

export default class ProgramHashtags extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.add = this.add.bind(this);
        this.renderedItem = this.renderedItem.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.delegateChange = this.delegateChange.bind(this);
        this.renderedEditItem = this.renderedEditItem.bind(this);
        this.renderedDisplayItem = this.renderedDisplayItem.bind(this);
        this.inputOver = this.inputOver.bind(this);

        this.state = {
            items: null,
            newlyCreatedIndex: -1,
        };
    }

    inputOver(e, index) {
        const { items } = this.state;
        e.stopPropagation();
        const newArray = []
        items.forEach((item, idx) => {
            if (index !== idx) {
                newArray.push(item)
            } else {
                let strippedItem = item;
                while (strippedItem[0] === "#") {
                    strippedItem = strippedItem.slice(1);
                }
                if (item.length > 0) {
                    newArray.push(strippedItem)
                }
            }
        })
        this.setState({
            newlyCreatedIndex : -1,
            items: newArray
        }, this.delegateChange)
    }

    delegateChange() {
        const { changeFunction } = this.props;
        const { items } = this.state;
        const f = changeFunction;
        if (typeof f === 'function') {
            f(items);
        }
    }

    deleteItem(index) {
        const newItems = [];
        this.state.items.map(function (item, ix) {
            if (ix !== index) {
                newItems.push(item);
            }
        })
        this.setState({
            items: newItems
        }, this.delegateChange)
    }

    handleTextChange(newText, changedIndex) {
        const { items } = this.state;
        const newitems = [];
        items.map(function (item, index) {
            if (index == changedIndex) {
                newitems.push(newText)
            } else {
                newitems.push(item)
            }
        })
        this.setState({
            items: newitems
        })
    }

    add() {
        const newItems = this.state.items;
        const newIndex = this.state.items.length;
        newItems.push('');
        this.setState({
            items: newItems,
            newlyCreatedIndex: newIndex
        })
    }

    renderedItem(item, index) {
        const self = this;

        const changeFunction = function (newVal) {
            self.handleTextChange(newVal, index);
        }

        const deleteFunction = function () {
            self.deleteItem(index);
        }

        const startWithFocus = (this.state.newlyCreatedIndex === index);

        return ( startWithFocus ?
            this.renderedEditItem(item, index, changeFunction, deleteFunction) :
            this.renderedDisplayItem(item, index, deleteFunction)
        )
    }

    renderedDisplayItem(item, index,  deleteFunction) {
        return (
            <div key={index} className="hashtag">
                #{item}
                {this.props.permission && <div className="delete" onClick={deleteFunction}>
                    x
                </div>}
            </div>
        )
    }

    renderedEditItem(item, index, changeFunction, deleteFunction) {
        return(
            <div key={index} className="listItem">
                <div className="number">
                    #
                </div>
                <div>
                    <TextInput
                        disableSpace
                        disabled={!this.props.permission}
                        showInput
                        itemText={item}
                        changeFunction={changeFunction}
                        placeholder="Hash Tag"
                        startWithFocus
                        blurFunction={e => this.inputOver(e, index)}
                    />
                </div>
                <div className="control" onMouseDown={deleteFunction}>
                    <img src="/images/ic-trash.svg"/>
                </div>
            </div>
        )
    }

    componentDidMount() {
        this.setState({
            items : this.props.items
        })
    }

    render() {
        if (!Array.isArray(this.state.items)) {
            return null;
        }
        const displayedItems = [];
        for (let i=0; i<this.state.items.length; i++) {
            if (i !== this.state.newlyCreatedIndex) {
                displayedItems.push(this.renderedItem(this.state.items[i], i));
            }
        }
        const editedItems = [];
        for (let i=0; i<this.state.items.length; i++) {
            if (i === this.state.newlyCreatedIndex) {
                editedItems.push(this.renderedItem(this.state.items[i], i));
            }
        }
        const canAddMore = (displayedItems.length + editedItems.length < HashTagLimit && this.props.permission);

        return (
            <div className="hashTags">
                <div className="fieldTitle">
                    Campaign  Hashtags
                </div>
                <div className="box hideAmBorder" style={{borderColor:'transparent'}}>
                    <div style={{paddingLeft: 30, paddingTop: 5, paddingBottom: 5}}>
                        { displayedItems }
                    </div>
                    { editedItems }
                    { canAddMore &&
                        <div className="controlLink addItem noTop" onClick={this.add}>
                            + Add Hashtag
                        </div>
                    }
                </div>
            </div>
        )
    }
}