import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import TopNavigation from '../shared/topNavigation/TopNavigation';
import './Video.scss';
import ProfileHelper from '../../modules/utils/ProfileHelper';
import { TALENT_MANAGER_STR, INFLUENCER_STR } from '../../constants/authorities';

const Video = () => {
    const [addExtraTop, setAddExtraTop] = useState(false);
    useEffect(() => {
        ProfileHelper.getCurrentLoginUserProfile().then((profile) => {
            if (
                profile &&
                (profile.role === TALENT_MANAGER_STR || profile.role === INFLUENCER_STR) &&
                !profile.accountVerified
            ) {
                setAddExtraTop(true);
            }
        });
    }, []);

    return (
        <div className="video">
            <TopNavigation current="support" />
            <div className="opportunity">
                <div className={cx('opportunityContainer extra', { moreExtra: addExtraTop })}>
                    <div className="contentDiv">
                        <div className="supportPage">
                            <div className="spContent">
                                <h2>Training Videos</h2>
                                <h3>Login:</h3>
                                <a href="/videos/Logging in as a Brand.mp4" target="_blank" rel="noopener noreferrer">
                                Login (Brand)
                                </a>
                                <h3>Get Started:</h3>
                                <a href="/videos/A Tour of the Koalifyed Homepage!.mp4" target="_blank" rel="noopener noreferrer">
                                Koalifyed Homepage
                                </a>
                                <h3>Discover:</h3>
                                <a href="/videos/Favorites & Creating Customized Lists.mp4" target="_blank" rel="noopener noreferrer">
                                Favorites & Creating Customized Lists
                                </a>
                                <a href="/videos/Browsing Creators.mp4" target="_blank" rel="noopener noreferrer">
                                Browsing Creators
                                </a>
                                <h3>Building your Campaign:</h3>
                                <a href="/videos/Adding Creators to Campaign.mp4" target="_blank" rel="noopener noreferrer">
                                Adding Creators to Campaign
                                </a>
                                <a href="/videos/Creating an Assignment.mp4" target="_blank" rel="noopener noreferrer">
                                Creating an Assignment
                                </a>
                                <a href="/videos/Creating a Campaign.mp4" target="_blank" rel="noopener noreferrer">
                                Creating a Campaign
                                </a>
                                <h3>Campaigns:</h3>
                                <a href="/videos/Managing Campaigns.mp4" target="_blank" rel="noopener noreferrer">
                                Managing a Campaign
                                </a>
                                <h3>Plans:</h3>
                                <a href="/videos/How to Create a New Plan.mp4" target="_blank" rel="noopener noreferrer">
                                Creating a Plan
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Video;
