import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { head, get } from 'lodash';
import PropTypes from 'prop-types';
import cx from 'classnames';

import constants from './constants';
import style from './styles.module.scss';
import './select.scss';
import utils from './utils';
import Api from "../../../../../../modules/Api";
import CompetitiveBrandsSelector from './parts/CompetitiveBrandsSelector/CompetitiveBrandsSelector';

const accessOptions = [{label: "Full Access", value: "fullAccess", items: ["edit:plan","create:plan","delete:plan","view:plan","create:campaign","edit:campaign",
    "view:campaign"]}, 
{label: "Discovery & Planning", value:"discoveryPlanning", items: ["edit:plan","create:plan","delete:plan","view:plan"]}]

const Form = (props) => {
    const {
        brandName,
        billingContact,
        onTextChange,
        onSelectChange,
        advertisers,
        advertisersOptions,
        type,
        errors,
        buttonIsClicked,
        onSearchChange,
        countryCode ='US',
        defaultCurrencyCode,
        countryList,
        setCountryList,
        competitiveBrands,
        onCompetitiveBrandsChange,
        limited,
        accessRights
    } = props
    const { selectOnChange, selectOnChangeValue } = utils;

    const { errorsObject } = constants;
    const [currencyArray, setCurrencyArray] = useState([]);

    const getError = (field) => buttonIsClicked && errorsObject[field];
    useEffect(() => {
        Api.getCurrencyList().then(result => {
            const { data } = result;
            const currencyList = []
            data.forEach(x => {
                const currency = {label: `${x.code} (${x.symbol})`, value: x.code, symbol: x.symbol}
                currencyList.push(currency)
            })
            setCurrencyArray(currencyList)
        })
        Api.getBrandCountry().then(result => {
            const { data } = result;
            const countryList = [];
            data.forEach(x => {
                const country = {label: x.name, value: x.code, currencyCode: x.currencyCode}
                countryList.push(country)
            })
            setCountryList(countryList)
        })
    }, []);
    const selectValue = (item) => (
        item && (
            <div className={style.selectValueContainer}>
                {get(item, 'profileImage') && (
                    <img src={get(item, 'profileImage')} alt='profile-image' />
                )}
                <span className={style.valueContainerContent}>{get(item, 'label')}</span>
            </div>
        )
    );
    if (limited) {
        return (
            <div id="brand-modal-form">
                <div className={style.inputWrapper}>
                    <label className={style.inputLabel}>Billing Contact</label>
                    <input
                        name='billingContact'
                        onChange={onTextChange(true)}
                        placeholder='Billing Contact'
                        type='text'
                        value={billingContact}
                        className={style.input}
                    />
                    <div className={style.warningMessage}>
                        {getError(errors.billingContact) && (
                            <span className={style.warning}>{getError(errors.billingContact)}</span>
                        )}
                    </div>
                </div>
                <CompetitiveBrandsSelector
                    type={type}
                    style={style}
                    error={getError(errors.competitiveBrands)}
                    brands={competitiveBrands}
                    onChange={onCompetitiveBrandsChange}
                />

            </div>
        )
    }
    const advertiserFilteredOptions = advertisersOptions ? advertisersOptions.filter(a => !a.isArchived) : [];
    return (
        <div id="brand-modal-form">
            <div className={style.inputWrapper}>
                <label className={style.inputLabel}>Brand Name</label>
                <input
                    name='brandName'
                    onChange={onTextChange(false)}
                    type='text'
                    className={style.input}
                    placeholder='Brand Name'
                    value={brandName}
                    disabled={type === "edit" || type === "add_country"}
                />
                <div className={style.warningMessage}>
                    {getError(errors.brandName) && (
                        <span className={style.warning}>{getError(errors.brandName)}</span>
                    )}
                </div>
            </div>
            <div className={style.inputRow}>

                <div className={style.inputWrapper}>
                    <label className={style.inputLabel}>Country</label>
                    <Select
                        placeholder='Select Country'
                        options={countryList}
                        onChange={selectOnChangeValue('countryCode', onSelectChange)}
                        className="countryAndCurrency"
                        clearable={false}
                        value={countryCode}
                        disabled={type === "edit"}
                        searchable
                    />
                    <div className={style.warningMessage}>
                        {getError(errors.countryCode) && (
                            <span className={style.warning}>{getError(errors.countryCode)}</span>
                        )}
                    </div>
                </div>
                <div className={style.inputWrapper}>
                    <label className={style.inputLabel}>Currency</label>
                    <Select
                        placeholder='Select Currency'
                        options={currencyArray}
                        onChange={selectOnChangeValue('defaultCurrencyCode', onSelectChange)}
                        className="countryAndCurrency"
                        single
                        clearable={false}
                        value={defaultCurrencyCode}
                    />
                    <div className={style.warningMessage}>
                        {getError(errors.defaultCurrencyCode) && (
                            <span className={style.warning}>{getError(errors.defaultCurrencyCode)}</span>
                        )}
                    </div>
                </div>
            </div>
            <div className={style.inputRow}>

                <div className={style.inputWrapper}>
                    <label className={style.inputLabel}>Advertiser</label>
                    <Select
                        multi
                        valueRenderer={() => selectValue(head(advertisers))}
                        value={advertisers === undefined ? null : advertisers}
                        placeholder='Select advertiser'
                        options={advertiserFilteredOptions}
                        disabled={type === 'edit'}
                        onChange={selectOnChange('advertisers', onSelectChange, 'single')}
                    />
                    <div className={style.warningMessage}>
                        {getError(errors.advertisers) && (
                            <span className={style.warning}>{getError(errors.advertisers)}</span>
                        )}
                    </div>
                </div>
                <div className={style.inputWrapper}>
                    <label className={style.inputLabel}>Access Level</label>
                    <Select
                        placeholder='Select'
                        options={accessOptions}
                        onChange={selectOnChangeValue('accessRights', onSelectChange)}
                        className="countryAndCurrency"
                        single
                        clearable={false}
                        value={accessRights}
                    />
                    <div className={style.warningMessage}>
                        {getError(errors.accessRights) && (
                            <span className={style.warning}>{getError(errors.accessRights)}</span>
                        )}
                    </div>
                </div>
            </div>
            <div className={style.divider} />
            <div className={style.inputWrapper}>
                <label className={style.inputLabel}>Billing Contact</label>
                <input
                    name='billingContact'
                    onChange={onTextChange(true)}
                    placeholder='Billing Contact'
                    type='text'
                    value={billingContact}
                    className={style.input}
                />
                <div className={style.warningMessage}>
                    {getError(errors.billingContact) && (
                        <span className={style.warning}>{getError(errors.billingContact)}</span>
                    )}
                </div>
            </div>
            <CompetitiveBrandsSelector
                type={type}
                style={style}
                error={getError(errors.competitiveBrands)}
                brands={competitiveBrands}
                onChange={onCompetitiveBrandsChange}
            />

        </div>
    )
}

Form.propTypes = {
    brandName: PropTypes.string,
    billingContact: PropTypes.string,
    onTextChange: PropTypes.func,
    onSelectChange: PropTypes.func,
    advertisersOptions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            label: PropTypes.string,
            profileImage: PropTypes.string,
            value: PropTypes.string,
        })
    ),
    type: PropTypes.string,
    // brandOwnersOptions: PropTypes.arrayOf(
    //     PropTypes.shape({
    //         id: PropTypes.number,
    //         label: PropTypes.string,
    //         profileImage: PropTypes.string,
    //         value: PropTypes.string,
    //     })
    // ),
    advertisers: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string,
            profileImage: PropTypes.string,
        })), PropTypes.array]),
    // brandOwners: PropTypes.oneOfType([
    //     PropTypes.arrayOf(PropTypes.shape({
    //         value: PropTypes.string,
    //         label: PropTypes.string,
    //         profileImage: PropTypes.string,
    //     })), PropTypes.array]),
    errors: PropTypes.shape({
        brandName: PropTypes.string,
        billingContact: PropTypes.string,
        advertisers: PropTypes.string,
        // brandOwners: PropTypes.string,
    }),
    buttonIsClicked: PropTypes.bool,
};

export default Form;
