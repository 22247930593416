export const agentAdminMenu = [
    {   label: 'Profile',
        url: '/agentAdmin/profile'
    },
    {   label: 'User Management',
        url: '/adminManagement'
    },
    {   label: 'Keyword Groups',
        url: '/keywordGroups'
    },
    // {   label: 'Admin',
    //     url: '/agentAdmin/admin'
    // },
    // {   label: 'Payments',
    //     url: '/agentAdmin/payment'
    // },
    // {   label : 'Resources',
    //     url: '/agentAdmin/resources'
    // },
    {   label: 'Support',
        url: '/support',
        showNotification: false,
    },
    {   label: 'Admin Settings',
        url: '/publicRecord'
    },
    {
        label : 'Logout'
    }
];
