import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { connect } from 'react-redux';
import * as Honeybadger from 'honeybadger-js';
import { Motion, spring } from 'react-motion';
import Api from '../../modules/Api';
import PleaseWait from '../shared/pleaseWait/PleaseWaitWhite';
import ApiError from '../shared/apiError/ApiError';
import TopNavigation from '../shared/topNavigation/TopNavigation';
import CampaignNavigation from './CampaignNavigation';
import AssignmentBody from './assignment/AssignmentBody';
import CampaignInfluencers from './campaignInfluencers/CampaignInfluencers';
import CampaignInfluencerSearch from './campaignInfluencerSearch/CampaignInfluencerSearch';
import CampaignInfluencerInviteEdit from './campaignInfluencerSearch/CampaignInfluencerInviteEdit';
import CampaignInfluencerAdd from './campaignInfluencerSearch/CampaignInfluencerAdd';
import Posts from './posts/posts';
import Analytics from './analytics/Analytics';
import Compliance from './compliance/Compliance';
import UploadTest from './UploadTest';
import ModalWarningWithAction from '../shared/modalWithSingleAction/ModalWarningWithAction';
import { storeUserProfile } from '../../store/global/globalActions';
import ProfileHelper from '../../modules/utils/ProfileHelper';
import './campaign.scss';
import { AGENT_STR, BRAND_OWNER_STR, SUPER_ADMIN_STR } from '../../constants/authorities';

// Props :
// const permissions = ['ROLE_AGENT', 'ROLE_SUPER_ADMIN', 'ROLE_BRAND_OWNER'];

class CampaignWorkPage extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.showApiError = this.showApiError.bind(this);

        this.state = {
            waitingForCampaign: true,
            waitingForAssignment: false,
            agentIsNotPartOfContactListForCampaign: false,
            campaign: null,
            campaignId: null,
            currentNavigation: '',
            showApiError: false,
            apiErrorMessage: null,
        };
    }

    showApiError(error) {
        this.setState({
            waitingForCampaign: false,
            waitingForAssignment: false,
            showApiError: true,
            apiError: error,
        });
    }

    // verifyCampaignContactList(campaign) {
    //     if (campaign && this.props.profile) {
    //         const userId = this.props.profile.id;
    //         if (
    //             this.props.profile.roles.find(
    //                 (role) =>
    //                     role.brands && role.brands.find((brand) => brand.brandId === campaign.agencyBrand.brandId),
    //             ) ||
    //             campaign.contacts.find((contact) => {
    //                 return contact.agent.id == userId;
    //             }) ||
    //             this.props.profile.authorities.includes('ROLE_SUPER_ADMIN')
    //         ) {
    //             return campaign;
    //         }
    //         this.setState({
    //             agentIsNotPartOfContactListForCampaign: true,
    //         });

    //     }

    //     return null;
    // }

    goBackToCampaign = () => {
        if (this.props.match && this.props.match.params && this.props.match.params.campaignId) {
            const campaignLink = `/campaign/${this.props.match.params.campaignId}`;
            this.props.history.push(campaignLink);
        }
    };

    componentDidMount() {
        const { campaignId } = this.props.match.params;
        if (!this.props.profile) {
            const self = this;
            ProfileHelper.getCurrentLoginUserProfile().then(function (profile) {
                self.props.storeProfile(profile);
                Api.getCampaign(Number(campaignId))
                    .then((result) => {
                        Honeybadger.setContext({
                            campaign: result,
                        });
                        self.setState({
                            campaign: result, // self.verifyCampaignContactList(result),
                            waitingForCampaign: false,
                        });
                    })
                    .catch((error) => {
                        self.showApiError(error);
                    });
            });
        } else {
            Api.getCampaign(Number(campaignId))
                .then((result) => {
                    Honeybadger.setContext({
                        campaign: result,
                    });
                    this.setState({
                        campaign: result, // this.verifyCampaignContactList(result),
                        waitingForCampaign: false,
                    });
                })
                .catch((error) => {
                    this.showApiError(error);
                });
        }
    }

    showLoadingScreen = () => {
        this.setState({ waitingForAssignment: true });
    };

    hideLoadingScreen = () => {
        this.setState({ waitingForAssignment: false });
    };

    render() {
        const { profile } = this.props;
        const { campaign } = this.state;
        const fullPath = (tail) => {
            return `${this.props.match.url}/${tail}`;
        };
        const assignmentChosen = (assignmentId) => {
            this.setState({
                currentNavigation: CampaignNavigation.getAssignmentLink(),
                assignmentId,
            });
        };
        const influencersChosen = (assignmentId) => {
            this.setState({
                currentNavigation: CampaignNavigation.getInfluencersLink(),
                assignmentId,
            });
        };
        const analyticsChosen = (assignmentId) => {
            this.setState({
                currentNavigation: CampaignNavigation.getAnalyticsLink(),
                assignmentId,
            });
        };
        const postChosen = (assignmentId) => {
            this.setState({
                currentNavigation: CampaignNavigation.getPostLink(),
                assignmentId,
            });
        };
        const searchChosen = (assignmentId) => {
            // ever used?
            this.setState({
                currentNavigation: CampaignNavigation.getSearchLink(),
                assignmentId,
            });
        };
        const complianceChosen = (assignmentId) => {
            this.setState({
                currentNavigation: CampaignNavigation.getComplianceLink() || 4,
                assignmentId,
            });
        };

        let bodyClass = 'campaignWorkBody';
        if (this.state.currentNavigation == CampaignNavigation.getSearchLink()) {
            bodyClass += ' search';
        }

        const showInvalidPermissionsMessage =
            this.state.waitingForCampaign == false && this.state.agentIsNotPartOfContactListForCampaign == true;
        const permission =
            profile &&
            (profile.role === AGENT_STR || profile.role === SUPER_ADMIN_STR || profile.role === BRAND_OWNER_STR);
        return (
            <div>
                <div id="campaignApiError" />

                <PleaseWait show={this.state.waitingForCampaign || this.state.waitingForAssignment} />
                <TopNavigation current="campaign" />
                {this.state.currentNavigation !== CampaignNavigation.getSearchLink() && (
                    <CampaignNavigation
                        permission={permission}
                        currentNavigation={this.state.currentNavigation}
                        campaign={this.state.campaign}
                        campaignId={this.props.match.params.campaignId}
                        assignmentId={this.state.assignmentId}
                    />
                )}

                <ApiError
                    show={this.state.showApiError}
                    error={this.state.apiError}
                    cancelFunction={() =>
                        this.setState({
                            waitingForCampaign: false,
                            waitingForAssignment: false,
                            showApiError: false,
                            apiError: null,
                        })
                    }
                />
                <Motion defaultStyle={{ x: 0, y: 0.01 }} style={{ x: spring(0), y: spring(1) }}>
                    {({ x, y }) => (
                        <div
                            className={bodyClass}
                            style={{
                                opacity: `${y}`,
                                // WebkitTransform: `translate3d(0, ${x}px, 0)`,
                                transform: `translate3d(0, ${x}px, 0)`,
                            }}
                        >
                            {this.state.campaign ? (
                                <div className="contentDiv">
                                    <Switch>
                                        <Route
                                            path={fullPath('assignment/:assignmentId')}
                                            render={(props) => {
                                                return (
                                                    <AssignmentBody
                                                        {...props}
                                                        setNavigationFunction={assignmentChosen}
                                                        campaignId={this.props.match.params.campaignId}
                                                        advertiserId={this.state.campaign.agency.id}
                                                        campaign={this.state.campaign}
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            path={fullPath('influencerSearch/:assignmentId')}
                                            render={(props) => {
                                                return (
                                                    <CampaignInfluencerSearch
                                                        {...props}
                                                        setNavigationFunction={searchChosen}
                                                        campaignId={this.props.match.params.campaignId}
                                                        budget={campaign && campaign.budget ? campaign.budget : -1}
                                                        currencyCode={campaign && campaign.currencyCode}
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            path={fullPath('influencerAdd/:assignmentId')}
                                            render={(props) => {
                                                return (
                                                    <CampaignInfluencerAdd
                                                        {...props}
                                                        setNavigationFunction={searchChosen}
                                                        campaign={this.props.match.params.campaignId}
                                                        budget={campaign && campaign.budget ? campaign.budget : -1}
                                                        currencyCode={campaign && campaign.currencyCode}
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            path={fullPath('influencerInviteEdit/:assignmentId/user/:userId')}
                                            render={(props) => {
                                                return (
                                                    <CampaignInfluencerInviteEdit
                                                        {...props}
                                                        setNavigationFunction={searchChosen}
                                                        campaignId={this.props.match.params.campaignId}
                                                        budget={campaign && campaign.budget ? campaign.budget : -1}
                                                        currencyCode={campaign && campaign.currencyCode}
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            path={fullPath('influencers/:assignmentId')}
                                            render={(props) => {
                                                return (
                                                    <CampaignInfluencers
                                                        {...props}
                                                        campaign={this.state.campaign}
                                                        campaignId={Number(this.props.match.params.campaignId)}
                                                        setNavigationFunction={influencersChosen}
                                                        apiErrFunction={this.showApiError}
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            path={fullPath('posts/:assignmentId')}
                                            render={(props) => {
                                                return (
                                                    <Posts
                                                        {...props}
                                                        campaign={this.state.campaign}
                                                        campaignId={this.props.match.params.campaignId}
                                                        setNavigationFunction={postChosen}
                                                        apiErrFunction={this.showApiError}
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            path={fullPath('verify/:assignmentId')}
                                            render={(props) => {
                                                return (
                                                    <Posts
                                                        {...props}
                                                        campaign={this.state.campaign}
                                                        campaignId={this.props.match.params.campaignId}
                                                        setNavigationFunction={verifyChosen}
                                                        apiErrFunction={this.showApiError}
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            path={fullPath('analytics/:assignmentId')}
                                            render={(props) => {
                                                return (
                                                    <Analytics
                                                        {...props}
                                                        campaign={this.state.campaign}
                                                        campaignId={this.props.match.params.campaignId}
                                                        setNavigationFunction={analyticsChosen}
                                                        showLoadingScreen={this.showLoadingScreen}
                                                        hideLoadingScreen={this.hideLoadingScreen}
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            path={fullPath('compliance/:assignmentId')}
                                            render={(props) => {
                                                return (
                                                    <Compliance
                                                        {...props}
                                                        campaign={this.state.campaign}
                                                        campaignId={this.props.match.params.campaignId}
                                                        setNavigationFunction={complianceChosen}
                                                        showLoadingScreen={this.showLoadingScreen}
                                                        hideLoadingScreen={this.hideLoadingScreen}
                                                    />
                                                );
                                            }}
                                        />
                                        <Route
                                            path={fullPath('test')}
                                            render={(props) => {
                                                return <UploadTest />;
                                            }}
                                        />
                                    </Switch>
                                </div>
                            ) : (
                                <ModalWarningWithAction
                                    show={showInvalidPermissionsMessage}
                                    titleId="campaign.noPermissionTitle"
                                    messageId="campaign.noPermissionMessage"
                                    actionFunction={this.goBackToCampaign}
                                />
                            )}
                        </div>
                    )}
                </Motion>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.global.loggedInUserProfile,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        storeProfile: (profile) => {
            dispatch(storeUserProfile(profile));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignWorkPage);
