import React from 'react';
import './noConnected.scss';

export default class NoConnected extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showWarning: false
        }
    }

    render() {
        return (
            <div className="noConnectedContainer">
                <div    className="noConnectedLabel"
                        onClick={() => this.setState({ showWarning: !this.state.showWarning })}
                        onMouseLeave={() => this.setState({showWarning: false})}
                        onMouseEnter={() => this.setState({showWarning: true})}>
                    Not Connected
                    <img src="/images/ic-warning.svg" alt="" />
                </div>
                {this.state.showWarning && <div className="noConnectedDisplay">
                    This is not a <span>connected account</span> and will display results for a maximum of <span>25 top posts</span>.
                </div>}
            </div>
        )
    }
}
