import React from 'react'

import LocationInput from '../../shared/locationInput/LocationInput'

export default class Location extends React.Component {

    constructor (props, context) {
        super(props, context);

        this.handleChange = this.handleChange.bind(this);
        this.state ={
            address : null
        };
    }

    handleChange(address, fields) {
        let f = this.props.changeFunction;
        if (typeof f === 'function') {
            f(address, fields);
        }
    }

    componentDidMount() {
        this.setState({
            address : this.props.profile
        })
    }

    render() {
        if (!this.state.address) {
            return null;
        }
        return (
            <div>
                <LocationInput
                    original={this.state.address}
                    changeFunction={this.handleChange}/>
            </div>
        )
    }
}

