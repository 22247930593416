import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import Docxtemplater from 'docxtemplater';
import Api from '../../../modules/Api';
import AssignmentContractType from '../../../constants/AssignmentContractType';

// for uploaded contract
const requiredTags = ['CreatorSignHere', 'CreatorSignDate', 'AgentSignHere', 'AgentSignDate'];

export const getDefaultContract = (assignment) => {
    const hasDfContract = assignment && assignment.defaultContract;
    const contractSelected = { id: '', name: '', resource: {}, data: null};
    if (hasDfContract && assignment.assignmentContractType === AssignmentContractType.CONTRACT_TEMPLATE) {
        const dfContract = assignment.defaultContract;
        contractSelected.id = dfContract.data.contractTemplateId;
        contractSelected.name = dfContract.data.contractTemplateName;
        contractSelected.resource = dfContract.resource;
        contractSelected.data = JSON.parse(dfContract.data.mapping);
    }

    return contractSelected;
}

export const handleContractSave = (res, internal_SelectedTemplate, level, assignmentId, assignmentUserID) => {

    return new Promise(async (resolve) => {
        try {
            const dataOptions = {
                mapping: JSON.stringify(res),
                contractTemplateId: internal_SelectedTemplate.resource.id,
                contractTemplateName: internal_SelectedTemplate.name,
            };
        
            const newSelectedData = {
                data: res,
                name: internal_SelectedTemplate.name,
                id: internal_SelectedTemplate.id,
                resourceId: internal_SelectedTemplate.resource.id
            };
            let updateResult = true;
        
            // for saving a contract template at the assignment level
            if(level ==='assignment') {
                Api.contractMapppingAssignmentAdd(assignmentId, internal_SelectedTemplate.resource.id, dataOptions)
                    .then(
                        async (response) => {
                            return resolve(updateResult);
                        },
                        async (err) => {
                            updateResult = await ApiContractMappingAssignmentHelper(assignmentId, internal_SelectedTemplate, dataOptions, newSelectedData);
                            return resolve(updateResult);
                        }
                    )
                    .catch(async (err) => {
                        updateResult = await ApiContractMappingAssignmentHelper(assignmentId, internal_SelectedTemplate, dataOptions, newSelectedData);
                        return resolve(updateResult);
                    })
            }
        
            // for editing a contract settings at the assignment user level for a specific user
            if(level ==='agent-user') {
                Api.contractMapppingAssignmentUserAdd(assignmentUserID, internal_SelectedTemplate.resource.id, dataOptions)
                    .then(
                        async (response) => {
                            return resolve(updateResult);
                        },
                        async (err) => {
                            updateResult = await ApiContractMappingAssignmentUserHelper(assignmentUserID, internal_SelectedTemplate, dataOptions, newSelectedData);
                            return resolve(updateResult);
                        }
                    )
                    .catch(async (err) => {
                        updateResult = await ApiContractMappingAssignmentUserHelper(assignmentUserID, internal_SelectedTemplate, dataOptions, newSelectedData);
                        return resolve(updateResult);
                    })
            }
        } catch (error) {
            console.log(`handleContractSave. Error. ${  error}`);
            resolve(false);
        }
    });
};

const ApiContractMappingAssignmentHelper = (assignmentId, internal_SelectedTemplate, dataOptions, newSelectedData) => {
    return new Promise((resolve) => {
        Api.contractMapppingAssignmentUpdate(assignmentId, internal_SelectedTemplate.resource.id, dataOptions)
            .then(
                (res) => {
                    return resolve(true);
                },
                (err) => {
                    console.log('failed update', err);
                    return resolve(false);
                }
            )
            .catch((err) => {
                console.log('failed update', err);
                return resolve(false);
            })
    });
}

const ApiContractMappingAssignmentUserHelper = (assignmentUserID, internal_SelectedTemplate, dataOptions, newSelectedData) => {
    return new Promise((resolve) => {
        Api.contractMapppingAssignmentUserUpdate(assignmentUserID, internal_SelectedTemplate.resource.id, dataOptions, "PENDING")
            .then(
                (res) => {
                    return resolve(true);
                },
                (err) => {
                    console.log('failed update', err);
                    return resolve(false);
                }
            )
            .catch((err) => {
                console.log('failed update', err);
                return resolve(false);
            })
    });
}

// since this is validating the uploaded doc whether contains the mandatory tags for docusign
// if not, pop up the error and ask user to select another file
export const validateUploadContract = (dataUrl) => {
    return new Promise((resolve, reject) => {
        try {
            PizZipUtils.getBinaryContent(dataUrl, function(err, content) {
                if (err) {
                    return reject(err);
                }
                const zip = new PizZip(content);
                const doc = new Docxtemplater().loadZip(zip);
                const fullText = doc.getFullText();
                let missingTagNumber = 0;
                const prefix = 'The required string';
                const end = ' not found in the document.';
                let missingTagMsg = '';
                if (!fullText) {
                    return reject('The selected file is empty');
                }
                for (let i = 0; i < requiredTags.length; i++) {
                    if (!fullText.includes(requiredTags[i])) {
                        missingTagNumber += 1;
                        missingTagMsg = `${missingTagMsg + (missingTagNumber > 1 ? ', ' : '')  }"${  requiredTags[i]  }"`;
                    }
                }
                if (missingTagNumber === 0) {
                    return resolve();
                }
                if (missingTagNumber === 1) {
                    missingTagMsg = `${prefix  } ${  missingTagMsg  } was${  end}`;
                } else if (missingTagNumber > 1) {
                    missingTagMsg = `${prefix  }s ${  missingTagMsg  } were${  end}`;
                }
                return reject(missingTagMsg);
            })
        }catch(err) {
            console.log('Failed to validate the uploaded document', err);
            return reject('Failed to validate the uploaded document');
        }
    })
}

export const handleSaveUploadedContract = (assignmentId, assignmentUserID, file, level, signer) => {
    return new Promise((resolve, reject) => {
        Api.uploadResource(
            file, 
            false,
            null,
            null,
            progress => {},
            fn => {}
        ).then(res => {
            if (res && res.id) {
                const obj = {};
                obj.resource = res;
                obj.id = res.id;
                obj.name = file.name;
                const mappingData = {};
                mappingData.individualUploadedCustomizeContract = true;
                mappingData.preset_AgentSigner = signer;
                handleContractSave(mappingData, obj, level, assignmentId, assignmentUserID)
                    .then(response => {
                        return resolve(response);
                    })
                    .catch(err => {
                        console.log(err);
                        reject(err);
                    })
            }
        }).catch(err => {
            console.log(err);
            reject(err);
        })
    })
}
