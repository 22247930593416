import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { CSVLink } from 'react-csv';
import cx from 'classnames';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { render } from 'react-dom';
import AnalyticsChannelDisplay from './AnalyticsChannelDisplay';
import { getNetworkNameByChannel } from './Analytics.helper';
import { ByChannelReport } from './PDFReport';
import { formatBigNumber } from '../../../modules/utils/helpers';
import Summary from '../../influencerProfile/piecesInMain/Summary';

export default class AnalyticsByChannel extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            sortBy: 'name',
            desc: 'true',
            exportOpen: false,
            loadingPDF: false,
        };
        this.byChannelChart = React.createRef();
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.hideDropdownMenu);
    }

    showDropdownMenu = (e) => {
        e.stopPropagation();
        const { exportOpen } = this.state;
        this.setState({ exportOpen: !exportOpen });
        window.addEventListener('click', this.hideDropdownMenu);
    };

    hideDropdownMenu = () => {
        this.setState({ exportOpen: false });
    };

    exportPDF = () => {
        const { dataByChannel, campaignInfo, showLoadingScreen, hideLoadingScreen } = this.props;
        if (!this.state.loadingPDF) {
            showLoadingScreen();
            this.setState({ loadingPDF: true }, () => {
                const imgSrc = this.byChannelChart.current.chartInstance.toBase64Image();
                this.createAndDownloadPDF(
                    <ByChannelReport info={campaignInfo} channel={{ img: imgSrc }} dataByChannel={dataByChannel} />,
                    () => {
                        this.setState({ loadingPDF: false });
                        hideLoadingScreen();
                    },
                );
            });
        }
    };

    createAndDownloadPDF = (pdfContent, callback) => {
        const { startDate, endDate } = this.props;
        setTimeout(() => {
            const link = (
                <div id="export-report">
                    <PDFDownloadLink document={pdfContent} fileName={`ByChannel-${startDate}-${endDate}.pdf`}>
                        {({ loading }) => {
                            if (!loading) {
                                setTimeout(() => {
                                    document.getElementById('export-report').children[0].click();
                                    callback();
                                    document.getElementById('root').removeChild(document.getElementById('export-report-container'));
                                }, 3);
                            }
                        }}
                    </PDFDownloadLink>
                </div>
            );
            const elem = document.createElement('div');
            elem.id = 'export-report-container';
            document.getElementById('root').appendChild(elem);
            render(link, elem);
        }, 50);
    };

    render() {
        const { sortBy, desc, exportOpen } = this.state;
        const {
            campaignInfo,
            dataByChannel: { summary, details },
            startDate,
            endDate,
        } = this.props;
        const labels = [];
        const reachData = [];
        const viewData = [];
        let summaryKeys = [];
        if (summary) {
            summaryKeys = Object.keys(summary);
            summaryKeys.forEach((item) => {
                const label = getNetworkNameByChannel(item);
                if (label) {
                    labels.push(label.replace(/^\w/, (c) => c.toUpperCase()));
                }
                reachData.push(summary[item].reach||0);
                viewData.push(summary[item].impressions||0);
            });
        }

        const userData = {
            labels,
            datasets: [
                {
                    label: 'Followers',
                    backgroundColor: '#72C255',
                    borderWidth: 0,
                    barThickness:35,
                    data: reachData,
                    minBarLength:1
                },
                {
                    label: 'Views',
                    backgroundColor: '#44D7B6',
                    borderWidth: 0,
                    barThickness:35,
                    data: viewData,
                    minBarLength:1
                },
            ],
        };

        const headers = [
            { label: 'Campaign', key: 'campaign' },
            { label: 'Selected Assignments', key: 'selectedAssignments' },
            { label: 'Channel', key: 'channel' },
            { label: 'Creator', key: 'author' },
            { label: 'Publish Date', key: 'publishDate' },
            { label: 'Followers', key: 'reach' },
            { label: 'Views', key: 'impressions' },
            { label: 'Engagement', key: 'engagement' },
            { label: 'Likes', key: 'likes' },
            { label: 'Comments', key: 'comments' },
            { label: 'Saves', key: 'saves' },
            { label: 'Shares', key: 'shares' },
        ];

        let csv = [];
        if (summary && details) {
            summaryKeys = Object.keys(summary);
            summaryKeys.forEach((item, index) => {
                csv.push(
                    Object.assign(summary[item], {
                        campaign: `${index === 0 ? campaignInfo.campaignName : ''}`,
                        selectedAssignments: `${index === 0 ? campaignInfo.assignments : ''}`,
                        channel: getNetworkNameByChannel(item),
                    }),
                );
                csv = csv.concat(details[item]);
            });
        } else {
            csv.push({
                campaign: `${campaignInfo.campaignName}`,
                selectedAssignments: `${campaignInfo.assignments}`,
            });
        }
        return (
            <div className="analyticsOverview">
                <div>
                    <div className="sectionTitleLine">
                        <div className="sectionTitle">Channel Summary</div>
                        <div className="exportSection">
                            {campaignInfo.currency && <div className="currency">Currency: <div>{` ${campaignInfo.currency.code} (${campaignInfo.currency.symbol})`}</div></div>}
                            <div className="exportBtn" onClick={this.showDropdownMenu}>
                                Export
                                <img src="/images/ic-dropdown-d.svg" />
                            </div>
                            {exportOpen && (
                                <div className="exportDropdown">
                                    <CSVLink
                                        data={csv}
                                        headers={headers}
                                        filename={`ByChannel-${startDate}-${endDate}.csv`}
                                        target="_blank"
                                    >
                                        .csv
                                    </CSVLink>
                                    <div onClick={() => this.exportPDF()}>.pdf</div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="channelChart 1" id="byChannelChart">
                        <Bar ref={this.byChannelChart} data={userData} width={1280} height={300} options={graphOption} />
                    </div>
                </div>
                <div>
                    <div className="channelTableHeader">
                        <div className="summary" onClick={this.sortByName}>
                            <span>Channel Summary</span>
                        </div>

                        <div className="publish">
                            <span>Submitted Date</span>
                        </div>

                        <div className="reach" onClick={this.sortByRate}>
                            <span>Followers</span>
                        </div>
                        <div className="views" onClick={this.sortByDate}>
                            <span>Views</span>
                        </div>

                        <div className="engagement" onClick={this.sortByStatus}>
                            <span>Engagement</span>
                        </div>
                        <div className="likes" onClick={this.sortByStatus}>
                            <span>Likes</span>
                        </div>
                        <div className="comments" onClick={this.sortByStatus}>
                            <span>Comments</span>
                        </div>
                        <div className="shares" onClick={this.sortByStatus}>
                            <span>Shares</span>
                        </div>
                        <div className="saves" onClick={this.sortByStatus}>
                            <span>Saves</span>
                        </div>
                        {/* <div className="branded" onClick={this.sortByStatus}>
                            <span>Branded Engagements</span>
                        </div> */}
                        <div className="postLink" />
                    </div>
                    {summaryKeys.map((media) => {
                        return <AnalyticsChannelDisplay data={summary[media]} details={details[media]} key={media} channel={media} />;
                    })}
                </div>
            </div>
        );
    }
}

AnalyticsByChannel.propTypes = {
    dataByChannel: PropTypes.shape({
        details: PropTypes.shape({
            blog: PropTypes.array,
            facebook: PropTypes.array,
            instagramPost: PropTypes.array,
            instagramStory: PropTypes.array,
            twitter: PropTypes.array,
            youtube: PropTypes.array,
        }),
        summary: PropTypes.shape({
            blog: PropTypes.object,
            facebook: PropTypes.object,
            instagramPost: PropTypes.object,
            instagramStory: PropTypes.object,
            twitter: PropTypes.object,
            youtube: PropTypes.object,
        }),
    }),
    campaignInfo: PropTypes.shape({
        campaignName: PropTypes.string,
        assignments: PropTypes.array,
        startDate: PropTypes.string,
        endDate: PropTypes.string,
    }),
    showLoadingScreen: PropTypes.func,
    hideLoadingScreen: PropTypes.func,
};

AnalyticsByChannel.defaultProps = {
    dataByChannel: {
        details: {
            blog: [],
            facebook: [],
            instagramPost: [],
            instagramStory: [],
            twitter: [],
            youtube: [],
        },
        summary: {
            blog: {},
            facebook: {},
            instagramPost: {},
            instagramStory: {},
            twitter: {},
            youtube: {},
        },
    },
    campaignInfo: {},
    showLoadingScreen: () => {},
    hideLoadingScreen: () => {},
};

const graphOption = {
    maintainAspectRatio: false,
    tooltips: { enabled: true, position: 'nearest' },
    layout: {
        padding: {
            left: 40,
            right: 50,
            top: 25,
            bottom: 25,
        },
    },
    legend: {
        align: 'end',
        labels: {
            boxWidth: 14,
            fontColor: 'black',
            fontFamily: "'Nunito Sans', sans-serif",
            fontSize: 14,
        },
    },
    animation: {
        duration: 0,
    },
    scales: {
        yAxes: [
            {
                barPercentage: 1,
                gridLines: {
                    zeroLineColor: '#F0F0F0',
                    color: '#F0F0F0',
                    tickMarkLength: 0,
                },
                ticks: {
                    callback(val, index) {
                        return formatBigNumber(val);
                    },
                    maxTicksLimit:5,
                    precision: 0,
                    beginAtZero: true,
                    fontColor: 'rgba(0,0,0,0.4)',
                    fontSize: 14,
                    fontFamily: 'Nunito Sans, sans-serif',
                    padding: 5,
                },
            },
        ],
        xAxes: [
            {
                gridLines: {
                    zeroLineWidth: 0,
                    zeroLineColor: 'transparent',
                    color: 'transparent',
                    drawBorder: false,
                },
                ticks: {
                    fontColor: '#414141',
                    fontSize: 14,
                    fontFamily: 'Nunito Sans, sans-serif',
                    padding: 5,
                    fontWeight: 'bold',
                },
            },
        ],
    },
};
