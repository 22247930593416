import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Api from '../../../modules/Api';

const SaveNotifyWindow = ({ campaignId, show, cancelFunc, saveFunc, saveNotifyFunc }) => {
    if (!show || !campaignId) {
        return null;
    }
    const [userList, setUserList] = useState([]);
    const [userOptions, setUserOptions] = useState([]);

    useEffect(() => {
        const getAgents = async () => {
            const options = [];
            const campaign = await Api.getCampaign(campaignId);
            if (campaign.contacts && campaign.contacts.length > 0) {
                campaign.contacts.forEach((item) => {
                    options.push({
                        value: item.id,
                        label: `${item.firstname} ${item.lastname}`,
                    });
                });
            }
            setUserOptions(options);
        };
        getAgents();
    }, []);

    const handleUserSelect = (e) => {
        const newUserList = [...userList];
        const elem = newUserList.find((item) => item.value === e.value);
        if (elem) return;
        newUserList.push(e);
        setUserList(newUserList);
    };

    const removeUser = (user) => {
        const newUserList = userList.filter((item) => item.value !== user.value);
        setUserList(newUserList);
    };

    const cancelClick = () => {
        setUserList([]);
        cancelFunc();
    };

    return (
        <div className="confirmModal confirmContinueModal">
            <div className="saveNotifyWindow">
                <div className="upperPart">
                    <div className="modalTitle">Save & Notify</div>
                    <div className="message">
                        <span>Users</span> - Select the people you want to notify that the content is ready for their review
                    </div>
                    <Select className="select" clearable={false} searchable options={userOptions} onChange={handleUserSelect} />
                    {userList.length > 0 && (
                        <div className="userSelected">
                            {userList.map((user) => {
                                return (
                                    <div key={user.value} className="singleUser">
                                        <div className="userInfo">
                                            <div className="img-holder">
                                                {user.logo ? <img src={user.logo} alt={user.value} /> : user.label[0]}
                                            </div>
                                            <div className="userName">{user.label}</div>
                                        </div>
                                        <div className="removeBtn" onClick={() => removeUser(user)} />
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    <div className="btnGroup">
                        <div className="modalBtn" onClick={cancelClick}>
                            Cancel
                        </div>
                        <div className="modalBtn continue" onClick={() => saveNotifyFunc(userList)}>
                            Save & Send
                        </div>
                    </div>
                </div>
                <div className="bottomPart">
                    <div className="bottomText">Not ready to send?</div>
                    <div className="modalBtn" onClick={saveFunc}>
                        Save for later
                    </div>
                </div>
            </div>
        </div>
    );
};

SaveNotifyWindow.propTypes = {
    campaignId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    show: PropTypes.bool.isRequired,
    cancelFunc: PropTypes.func.isRequired,
    saveFunc: PropTypes.func.isRequired,
    saveNotifyFunc: PropTypes.func.isRequired,
};

SaveNotifyWindow.defaultProps = {
    campaignId: '',
};

export default SaveNotifyWindow;
