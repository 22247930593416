import React, { useState, useLayoutEffect, useRef, useEffect } from 'react';
import cx from 'classnames';
import './importFromBase.scss';
import PleaseWaitPartial from '../shared/pleaseWait/PleaseWaitPartial';

const ImportFromBase = ({
    onBack,
    Dropdown,
    headerLabel = 'Add creators from favorite lists',
    onImport = () => null,
    onCancel = () => null,
    loading = false,
    height = 36,
}) => {
    const [dataList, setDataList] = useState([]);
    const containerRef = useRef();

    const onCreateClick = () => {
        onImport(dataList);
    };

    const onViewClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };

    const onDelete = (e) => {
        const update = dataList.filter(d => d.id !== e.id);
        setDataList(update);
    };

    const onChange = (e) => {
        const update = [...dataList];
        if (!update.find(u => u.id === e.id)) {
            update.push(e);
            setDataList(update);
        }
    };

    return (
        <div className="importFromBaseContainer" onClick={onViewClick}>
            {typeof onBack === 'function' && <div className='importFromBaseHeader'>
                <img src="/images/ic-back-b.svg" onClick={onBack} />
            </div>}
            <div className='importFromBaseContentContainer' ref={containerRef}>
                <PleaseWaitPartial
                    show={loading}
                    container={containerRef.current}
                    customStyles={{ height: 'none' }}
                />
                <div className='labelText'>{headerLabel}</div>
                <div className="dropDownContainer">
                    <Dropdown onChange={onChange} selected={dataList} height={height}/>
                </div>
                <div className='importFromListContainer'>
                    {dataList.map(data => <div key={`import from: ${data.name}`} className='importFromBaseItem'>
                        <img src="/images/ic-close-x.svg" onClick={() => onDelete(data)} />
                        {data.name}
                    </div>)}
                </div>
                <div className='bottomContainer'>
                    <div className='cancelButton' onClick={onCancel}>Cancel</div>
                    <div
                        className={cx('createButton', { disabled: dataList.length === 0 })}
                        onClick={() => dataList.length === 0 ? null : onCreateClick()}
                    >
                        Import
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ImportFromBase;
