import { select, put, call } from 'redux-saga/effects';
import Api from '../../../../modules/Api';
import { setData, setApiError, setInfluencersLoading, setShowPleaseWait } from '../actionCreators';

const joinedFilterId = 6;

export default function* influencersSaga() {
    const { currentPage, pageLimit, searchFilters, sort, sortOrder } = yield select(({ campaignReducer }) => ({
        currentPage: campaignReducer.influencerSearch.currentPage,
        pageLimit: campaignReducer.influencerSearch.displayFilters.pageLimit.active.value,
        searchFilters: campaignReducer.influencerSearch.searchFilters,
        sort: campaignReducer.influencerSearch.displayFilters.sort.active.value,
        sortOrder: campaignReducer.influencerSearch.displayFilters.sortOrder.active,
    }));

    const planId = window.location.pathname
        .match(/influencers\/plan\/\d+/g)?.[0]
        ?.split('/')
        .slice(-1)?.[0];
    const campaignId = window.location.pathname
        .match(/campaignWork\/\d+/g)?.[0]
        ?.split('/')
        .slice(-1)?.[0];

    const payload = {
        ...searchFilters,
        ...(campaignId ? { campaignId: Number(campaignId) } : {}),
        ...(planId ? { planId: Number(planId) } : {}),
        ...{
            limit: pageLimit,
            page: currentPage,
            sort,
            sortOrder,
        },
    };

    try {
        yield put(setInfluencersLoading(true));
        yield put(setShowPleaseWait(true));

        const source = payload.type;

        if (source === 'PUBLIC') {
            payload.favorites = [];
            if (!payload.requiredChannels) {
                payload.requiredChannels = ["INSTAGRAM"]
            }
        }

        const response = yield call(Api.getInfluencers, { source, body: payload });

        const {
            meta: { totalCount },
            results: influencers,
        } = response;

        yield put(
            setData({
                influencers,
                totalCount,
            }),
        );
    } catch (error) {
        yield put(setApiError(error.message));
    } finally {
        yield put(setInfluencersLoading(false));
        yield put(setShowPleaseWait(false));
    }
}
