import React from 'react'
import FileUpload from '../../../shared/fileUpload/FileUpload'

// props
// - profile
// - article
// - isNew (flag)
// - changeFunction takes the edited article
// - deleteFunction takes the artice
// - imageVersion

const fields = ['id', 'title', 'mediaUrl', 'description', 'screenshotUrl', 'picFile'];

export default class MediaArticleInput extends React.Component {
    constructor( props,context) {
        super(props.context);

        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.renderedScreenshotInput = this.renderedScreenshotInput.bind(this);
        this.delegateChange = this.delegateChange.bind(this);
        this.delete = this.delete.bind(this);
        this.selectImageAction = this.selectImageAction.bind(this);

        this.state = {
            propsFilled: false,
            changeTriggerHovered : false,
            fileUploadCounter : 1
        }

        this.imageNode = null;

        for (let field of fields) {
            this.state[field] = '';
        }
    }

    delete() {
        let f = this.props.deleteFunction;
        if (typeof f === 'function') {
            f(this.props.article);
        }
    }

    handleSelectedImage = (file) => {
        let url = window.URL.createObjectURL(file);
        this.setState({
            picUrl: url,
            picFile: file
        }, this.delegateChange);
    }

    selectImageAction = () => {
        this.setState({
            fileUploadCounter : this.state.fileUploadCounter + 1
        })
    }

    renderedScreenshotInput() {

        let fileUpload =
            <FileUpload
                handleSelectedFile={ this.handleSelectedImage }
                clickCounter = {this.state.fileUploadCounter}
                accept=".jpg, .jpeg, .png, .svg"
            />

        return (
            <div onClick={this.selectImageAction} className="screenshot">
                {this.state.picUrl &&
                <div>
                    <div className="changeTrigger">
                        Upload
                    </div>
                    { fileUpload }
                    <img src={this.state.picUrl}/>
                </div>
                }
                {!this.state.picUrl &&
                <div className="firstImage">
                    { fileUpload }
                    <div className="prompt">
                        Add an image
                    </div>
                </div>
                }
            </div>
        )
    }

    handleFieldChange(key, e) {
        let newState = {};
        newState[key] = e.target.value;
        this.setState(newState, this.delegateChange);
    }

    delegateChange() {
        let f = this.props.changeFunction;
        if (typeof f !== 'function') {
            return;
        }
        let article = {};
        for (let p of fields) {
            article[p] = this.state[p];
        }
        if (this.props.isNew) {
            article.id = '';
        }
        f(article);
    }

    static getDerivedStateFromProps(props, state) {
        let newState = {}
        if (props.article && !state.propsFilled) {
            newState.propsFilled = true;

            for (let field of fields) {
                newState[field] = props.article[field] || '';
            }
            newState.picUrl = props.article.screenshotUrl;
            // newState.imageVersion = props.imageVersion;
        }
        return newState;
    }

    render() {
        if (!this.props.profile) {
            return null;
        }

        let self = this;

        return (
            <div className="editPiece">
                <div className="newPiece">
                    {this.props.isNew ? 'New Media Kit' : 'Edit Media Kit'}
                </div>
                <div className="mediaFields">
                    {/* Title */}
                    <div>
                        <div>
                            Title
                        </div>
                        <div>
                            <input
                                value={this.state.title}
                                onChange={ function(e) {
                                    self.handleFieldChange('title',e);
                                }}
                                type="text"
                            />
                        </div>
                    </div>

                    {/* Description */}
                    <div>
                        <div>
                            Description
                        </div>
                        <div>
                            <textarea
                                placeholder="Please enter less than 250 characters"
                                value={this.state.description}
                                onChange={function(e){
                                    self.handleFieldChange('description', e)
                                }}
                                maxLength={250}
                            >

                            </textarea>
                        </div>
                    </div>

                    {/* mediaUrl */}
                    <div>
                        <div>
                            URL
                        </div>
                        <div>
                            <input
                                value={this.state.mediaUrl}
                                onChange={ function(e) {
                                    self.handleFieldChange('mediaUrl',e);
                                }}
                                type="text"
                            />
                        </div>
                    </div>
                    <div>
                        <div>
                            Image
                        </div>
                        <div>
                            { this.renderedScreenshotInput()}
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}