import React from 'react'
import './tiktokDialog.scss';
import ModalDialog from '../modalDialog/ModalDialog';
import Format from '../../../modules/utils/Format'

export default class TiktokDialog extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            userName: '',
            profileUrl: '',
            followers: '',
            urlError: null,
            urlOk: false,
            nameError: null,
            followersError: null,
        }

        this.handleUserNameChange = this.handleUserNameChange.bind(this);
        this.handleProfileUrlChange = this.handleProfileUrlChange.bind(this);
        this.handleFollowersChange = this.handleFollowersChange.bind(this);
        this.handleUserNameFocus = this.handleUserNameFocus.bind(this);
        this.handleUserNameBlur = this.handleUserNameBlur.bind(this);
        this.handleUserNameKeyUp = this.handleUserNameKeyUp.bind(this);
        this.handleUserNameClick = this.handleUserNameClick.bind(this);
        this.handleUserNameCursorPositionHelper = this.handleUserNameCursorPositionHelper.bind(this);
        this.handleFollowersBlur = this.handleFollowersBlur.bind(this);
        this.canConnectTiktok = this.canConnectTiktok.bind(this);
        this.proceedWithTiktok = this.proceedWithTiktok.bind(this);
        this.handleFollowerFocus = this.handleFollowerFocus.bind(this);
    }

    proceedWithTiktok() {
        let f = this.props.proceedWithTiktok;
        if (typeof f === 'function') {
            f({
                tiktokUsername: this.state.userName,
                tiktokUrl: this.state.profileUrl,
                reach: this.state.followers.replace(/,/gi, ''),
            })
        }
    }

    canConnectTiktok() {
        let reach = parseInt(this.state.followers.replace(/,/gi, ''));
        this.setState({
            readyToConnect:
                this.state.userName.length > 1 && this.state.userName[0] === '@'
                && this.state.profileUrl.length > 0
                && (reach >= 1 && reach <= 1000000000)
                && this.state.urlOk
                && !this.state.nameError
                && !this.state.urlError
                && !this.state.followersError
        })
    }

    // Prevent cursor from being prior to '@' sign for tiktok input
    handleUserNameCursorPositionHelper = (e) => {
        if (e) {
            if (e.target.selectionStart == 0) {
                e.target.selectionStart = 1;
            }
        }
    }

    handleUserNameKeyUp(e) {
        this.handleUserNameCursorPositionHelper(e);
    }
    
    handleUserNameClick(e) {
        this.handleUserNameCursorPositionHelper(e);
    }

    handleUserNameChange(e) {
        let value = e.target.value;
        let newValue = (value === '') ? '@' : value;
        newValue =  '@' + newValue.replaceAll("@", '');

        this.setState({
            userName: newValue
        }, this.canConnectTiktok);
    }

    handleUserNameFocus() {
        if (this.state.userName === '') {
            this.setState({
                userName: '@'
            }, this.canConnectTiktok)
        }
    }

    handleUserNameBlur() {
        if (this.state.userName === '@') {
            this.setState({
                userName: ''
            }, this.canConnectTiktok)
        }
    }

    handleFollowersBlur() {
        let value = parseInt(this.state.followers.replace(/,/gi, ''));
        if (value < 1 || value > 1000000000) {
            this.setState({
                followersError: "Please enter a valid TikTok follower count"
            }, this.canConnectTiktok)
        }
    }

    handleFollowerFocus() {
        this.setState({
            followersError: null
        })
    }

    checkUrl = () => {
        this.canConnectTiktok();
        let urlOk = Format.validateUrl(this.state.profileUrl) && this.state.profileUrl.includes('tiktok');
        this.setState({
            urlError: urlOk ? null : 'Please enter a valid TikTok profile URL',
            urlOk: urlOk
        }, this.canConnectTiktok);
    }

    handleProfileUrlChange(e) {
        let value = e.target.value;
        this.setState({
            profileUrl: value,
            urlError: null,
            urlOk: false
        }, this.checkUrl);
    }

    handleFollowersChange(e) {
        let reach = e.target.value.replace(/,/gi, '');
        let reg = /^\d+$/;
        if (e.target.value === '') {
            this.setState({
                followers: ''
            }, this.canConnectTiktok);
        }
        if (reg.test(reach)) {
            const re = /(?=(?!\b)(\d{3})+$)/g;
            this.setState({
                followers: reach.replace(re, ',')
            }, this.canConnectTiktok);
        }
    }

    render() {
        return (
            <React.Fragment>
                <ModalDialog
                    show={this.props.show}
                    title="Connecting TikTok"
                    proceedButtonLabel={"Continue"}
                    cancelButtonLabel={"Cancel"}
                    proceedFunction={this.proceedWithTiktok}
                    cancelFunction={this.props.cancelTiktok}
                    closeButton={true}
                    footerColor={"#F7F7F7"}
                    readyToProceed={this.state.readyToConnect}
                // backBtn={this.backBtn}
                // backBtnPressed={this.backBtnPressed}
                // dontProceed={this.state.isPixel}
                >
                    <div className="tiktokDialogContainer">
                        <div className="label">
                            TikTok Username
                        </div>
                        <input
                            placeholder="@username"
                            value={this.state.userName}
                            onChange={this.handleUserNameChange}
                            onFocus={this.handleUserNameFocus}
                            onBlur={this.handleUserNameBlur}
                            onKeyUp={this.handleUserNameKeyUp}
                            onClick={this.handleUserNameClick}
                        />
                        {this.state.nameError && <div className="tiktokValidationError">
                            {this.state.nameError}
                        </div>}
                        <div className="spacer" />
                        <div className="label">
                            TikTok Profile URL
                        </div>
                        <input
                            placeholder="https://www.tiktok.com/@username"
                            // value={this.state.profileUrl}
                            onBlur={this.handleProfileUrlChange}
                        />
                        {this.state.urlError && <div className="tiktokValidationError">
                            {this.state.urlError}
                        </div>}
                        <div className="spacer" />
                        <div className="label">
                            Followers
                        </div>
                        <input
                            // type="number"
                            placeholder="100,000"
                            maxLength={12}
                            value={this.state.followers}
                            onChange={this.handleFollowersChange}
                            onBlur={this.handleFollowersBlur}
                            onFocus={this.handleFollowerFocus}
                        />
                        {this.state.followersError && <div className="tiktokValidationError">
                            {this.state.followersError}
                        </div>}
                        <div className="spacer" />
                    </div>
                </ModalDialog>
            </React.Fragment>
        )
    }
}
