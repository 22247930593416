import React from 'react';

import '../../campaignDetails/campaignDetails.scss';
import ModalDialogCamp from '../../../shared/modalDialog/ModalDialogCampaign';
import './createAssignment.scss';

export default class CopyAssignmentPopup extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.handleNameChange = this.handleNameChange.bind(this);
        this.saveAssignment = this.saveAssignment.bind(this);
        this.processProps = this.processProps.bind(this);

        this.copyFields = ['draftReminder', 'missedReminder', 'name', 'numberOfFrames', 'postReminder', 'types']
        this.defaultValues = {
            draftReminder : false,
            missedReminder : false,
            postReminder : false,
            types : [],
            numberOfFrames : 0
        };

        this.state = {
            name: '',
            types: null,
            numberOfPosts: 0,
            draftReminder: false,
            postReminder: false,
            missedReminder: false,
            assignmentToCopy: -1,
            copyAssets: false,
            copyInfluencers: false,
            canSave: true
        };
        this.assignmentObject = {};
    }

    handleNameChange(e) {
        this.setState({ name: e.target.value }, this.updateObject)
    }

    updateObject() {
        if (this.props.assignment) {
            this.assignmentObject.id = this.props.assignment.id;
        }

        this.assignmentObject.campaignId = this.props.campaign.id;
        this.assignmentObject.copy = {};

        if (this.state.assignmentToCopy > 0) {
            this.assignmentObject.copy.assignmentId = this.state.assignmentToCopy;
            this.assignmentObject.copy.copyAssets = this.state.copyAssets;
            this.assignmentObject.copy.copyInfluencers = this.state.copyInfluencers;
        }

        for (const field of this.copyFields) {
            this.assignmentObject[field] = this.state[field];
        }

        const canSave = this.state.name.length > 0;
        this.setState({ canSave });
    }

    saveAssignment() {
        if (!this.state.canSave) {
            return;
        }
        const saveObject = {};

        for (const ix in this.assignmentObject) {
            saveObject[ix] = this.assignmentObject[ix];
        }

        const {saveFunction} = this.props;

        if (typeof saveFunction === 'function') {
            saveFunction(saveObject);
        }
    }

    processProps(props) {
        const {assignment} = props;
        const newState = {};
        for (const field of this.copyFields) {
            newState[field] = assignment ? assignment[field] : (this.defaultValues[field] || '');
        }
        newState.name = newState.name ? `${newState.name} (Сopy)` : '';
        this.setState(newState, this.updateObject);
    }

    componentDidUpdate(prevProps) {
        const {assignment} = this.props;
        if (assignment && assignment.id && prevProps.assignment && prevProps.assignment.id !== assignment.id) {
            this.processProps(this.props);
        }
    }

    componentDidMount() {
        if (this.props.assignment) {
            this.processProps(this.props);
        }
    }

    render() {
        return (
            <div className="createAssignmentPopup">
                <ModalDialogCamp
                    show={this.props.show}
                    title="Duplicate Assignment"
                    cancelFunction={this.props.cancelFunction}
                    proceedFunction={this.saveAssignment}
                    proceedButtonLabel="Copy"
                    readyToProceed={this.state.canSave}
                    fadeIn
                >
                    <div className="subtitle">
                        All assignment details will be copy over
                    </div>
                    <div className="label">
                        Assignment Name
                    </div>
                    <div>
                        <input onChange={this.handleNameChange} value={this.state.name}/>
                    </div>
                </ModalDialogCamp>
            </div>
        )
    }
}
