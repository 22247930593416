import React from 'react'
import './extended.scss'
import Profile from './Profile'
import Media from './media/Media'
import PastBrands from './pastBrands/PastBrands'
import RecentPosts from './recentPosts/RecentPosts'


// props
// - show
// - details

export default class PermissionsExtended extends React.Component {

    constructor (props, context) {
        super(props, context);

        this.state = {
            currentMenuPoint : -1,
            content: null
        };
    }

    render () {
        const {
            editInfo,
            editPortfolio,
            editRates,
            editSummary,
            manageAssignment,
            viewAssignment
        } = this.props.details.management.permissionsSet;

        let className = "extended " +
            (this.props.show ? 'showing' : 'hidden');

        return (
            <div className={className}>
                <div>
                    <div className="topBar permissions-bar">
                        Permission Settings
                    </div>
                </div>
                <div className="content permissions">
                    <div className="block-holder">
                        <h3>Profile</h3>
                        <div className="profile">
                            <div className="row">
                                <div className="title">Edit Info</div>
                                <div className={`status ${editInfo && 'allow'}`}>{editInfo ? 'Allow' : 'Not Allow'}</div>
                            </div>
                            <div className="row">
                                <div className="title">Edit Summary</div>
                                <div className={`status ${editSummary && 'allow'}`}>{editSummary ? 'Allow' : 'Not Allow'}</div>
                            </div>
                            <div className="row">
                                <div className="title">Edit Rates</div>
                                <div className={`status ${editRates && 'allow'}`}>{editRates ? 'Allow' : 'Not Allow'}</div>
                            </div>
                            <div className="row">
                                <div className="title">Edit Portfolio</div>
                                <div className={`status ${editPortfolio && 'allow'}`}>{editPortfolio ? 'Allow' : 'Not Allow'}</div>
                            </div>
                        </div>
                    </div>
                    <div className="block-holder">
                        <h3>Opportunities</h3>
                        <div className="opportunities ">
                            <div className="row">
                                <div className="title">View Assignment</div>
                                <div className={`status ${viewAssignment && 'allow'}`}>{viewAssignment ? 'Allow' : 'Not Allow'}</div>
                            </div>
                            <div className="row">
                                <div className="title">Manage Assignment</div>
                                <div className={`status ${manageAssignment && 'allow'}`}>{manageAssignment ? 'Allow' : 'Not Allow'}</div>
                            </div>
                            {/*<div className="row">*/}
                            {/*    <div className="title">Negotiate</div>*/}
                            {/*    <div className="status allow">Allow</div>*/}
                            {/*</div>*/}
                            {/*<div className="row">*/}
                            {/*    <div className="title">Accept Assignment</div>*/}
                            {/*    <div className="status allow">Allow</div>*/}
                            {/*</div>*/}
                            {/*<div className="row">*/}
                            {/*    <div className="title">Select Availability</div>*/}
                            {/*    <div className="status allow">Allow (inherit)</div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
