import React from 'react';
import cx from 'classnames';
import TopNavigation from "../../shared/topNavigation/TopNavigation";
import './style.scss'
import Modal from "react-modal";
import OneInfluencer from "../../oneInfluencer/OneInfluencer";
import {connect} from "react-redux";

import {startLoadingLookupTables}  from '../../../store/global/globalActions'
import Select from "react-select";
import Api from "../../../modules/Api";
import PleaseWait from "../../shared/pleaseWait/PleaseWaitWhite";
import ApiError from "../../shared/apiError/ApiError";
import PaginationNew from "../../shared/pagination/PaginationNew";
import PendingInfluencer from "../../oneInfluencer/pendingInfluencer";
import FeedbackOnTheTop from "../../shared/feedbackOnTheTop/FeedbackOnTheTop";
import ProfileHelper from '../../../modules/utils/ProfileHelper';
import { TALENT_MANAGER_STR, INFLUENCER_STR } from '../../../constants/authorities';

const pageLimitOptions = [
    { value:'10', label: '10 per page'},
    { value:'20', label: '20 per page'},
    { value:'50', label: '50 per page'}
];
const filterOptions = [
    { value:'firstname,asc', label: 'Name (A-Z)'},
    { value:'firstname,desc', label: 'Name (Z-A)'},
];

const debounce = (fn, delay) => {
    let timeoutId;
    return function(...args) {
        clearInterval(timeoutId);
        timeoutId = setTimeout(() => fn.apply(this, args), delay);
    };
};

class TalentManagementPage extends React.Component {
    state = {
        sortType: 'active',
        usersSort: '',
        showModal: false,
        emailError: false,
        email: '',
        inviteWasSent: false,
        inviteError: '',
        users: [],
        loading: false,
        currentPage: 0,
        searchKey: '',
        totalCount: 0,
        pageSize: 10,
        profile: null,
    };

    componentDidMount() {
        if (!this.props.lookupTablesLoading  && !this.props.lookupTables) {
            this.props.getLookupTables();
        }
        this.getUsersList();
        ProfileHelper.getCurrentLoginUserProfile()
            .then(profile => this.setState({profile}))
    }

    handleDeleteUser = (id) => {
        Api.removeTM(id).then(() => {
            this.getUsersList()
        })
    };

    handleChange = (e) => {
        const regex = /^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,20}$/;
        const isValid = regex.test(e.target.value);
        if (!isValid) {
            this.setState({emailError: 'Email is not valid'});
        } else {
            this.setState({emailError: false});
        }
        this.setState({email: e.target.value})
    };

    toggleSort = (sortType) => {
        this.setState({sortType, currentPage : 0}, () => {this.getUsersList()})
    };

    handleSearchChange = (e) => {
        this.setState({
            searchKey: e.target.value,
            currentPage : 1
        });
        this.searchUsers(e.target.value)
    };

    searchUsers = debounce((value) => {
        this.setState({currentPage:0}, () => {
            this.getUsersList()
        });
    }, 500);

    createParamsForApi = () => {
        const retval = {
            pageNumber : this.state.currentPage <= 0 ? 1 : this.state.currentPage,
            pageSize : this.state.pageSize,
            status : this.state.sortType === 'all' ? '' : this.state.sortType,
            // sort : this.state.usersSort
        };
        if ( typeof (this.state.searchKey) === 'string' && (this.state.searchKey).length > 0) {
            retval.name = this.state.searchKey;
        }
        return retval;
    };

    getUsersList = () => {
        this.setState({ loading: true });
        Api.getTMusers(this.createParamsForApi(this.state)).then(
            (res) => {
                this.setState({
                    loading: false,
                    users : res.content,
                    currentPage: res.pageable.pageNumber + 1,
                    totalCount: res.total,
                })
            }).catch( (error) => {
            console.log('error',error)
            this.showApiError(error);
        });
    };

    handlePageChange = (newCurrentPage) => {
        this.setState({
            currentPage : newCurrentPage
        }, () => {this.getUsersList()});
    };

    handlePageSizeChange = (val) => {
        this.setState({pageSize: val, currentPage: 0}, this.getUsersList)
    };

    handleSortChange = (opt) => {
        this.setState({usersSort: opt.value, currentPage : 0}, ()=>{
            this.getUsersList()
        })
    };

    showApiError = (error) => {
        this.setState({
            waitingForUsers: false,
            showApiError: true,
            apiError : error
        })
    };

    handleOpenModal = (val) => {
        this.setState({showModal: val})
    };

    handleCloseModal = () => {
        this.setState({ showModal: false, inviteWasSent: false, inviteError: '' });
    };

    handleInvite = () => {
        Api.inviteInfluencer(this.state.email).then((res) => {
            this.setState({ inviteWasSent: true });
        }).catch((err) => {
            this.setState({ inviteError: err.originalMessage ? err.originalMessage : err.message });
        });
    };

    showSuccess = () => {
        this.setState({
            showSuccess : true
        }, () => {
            this.getUsersList();
            setTimeout(() => {
                this.closeSuccess()
            }, 500)
        })
    };

    closeSuccess = () => {
        this.setState({
            showSuccess : false
        })
    };

    render () {
        const {sortType, emailError, inviteError, inviteWasSent, email, users, loading, profile} = this.state;
        const addExtraTop = profile && (profile.role === TALENT_MANAGER_STR || profile.role === INFLUENCER_STR) && !profile.accountVerified;
        return (
            <div className="admin-management-page talent-management">
                <TopNavigation  />
                <ApiError show={this.state.showApiError} error={this.state.apiError}
                    cancelFunction={()=>this.setState({waitingForUsers:false,showApiError:false })}/>
                <FeedbackOnTheTop
                    show={this.state.showSuccess}
                    closeFunction = {this.closeSuccessMessage}
                    message = "Invite successfully send"
                />
                <div className={cx("blue-header", {extraTop: addExtraTop})}>
                    <div className="contentDiv">
                        <div className="controlContainer">
                            <div className="left-container">
                                <h1>Management</h1>
                                <ul>
                                    <li
                                        onClick={() => this.toggleSort('all')}
                                        className={sortType === 'all' ? 'active' : 'inactive'}
                                    >All</li>
                                    <li
                                        onClick={() => this.toggleSort('active')}
                                        className={sortType === 'active' ? 'active' : 'inactive'}
                                    >Active</li>
                                    <li
                                        onClick={() => this.toggleSort('inactive')}
                                        className={sortType === 'inactive' ? 'active' : 'inactive'}
                                    >Inactive</li>
                                    <li
                                        onClick={() => this.toggleSort('pending')}
                                        className={sortType === 'pending' ? 'active' : 'inactive'}
                                    >Pending</li>
                                </ul>
                            </div>
                            <div className="right-container">
                                <button onClick={() => this.handleOpenModal(true)} className="invite-button"><span className="plus-icon">+</span> Invite Creator</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="subheaderContainer">
                    <div className="subheader">
                        <div className="search">
                            <div className="stringInput">
                                <input
                                    id = 'keyword'
                                    // value={keyword}
                                    placeholder = "Search…"
                                    autoComplete="off"
                                    onChange={this.handleSearchChange}
                                />
                            </div>
                        </div>
                        <div className="right-panel">
                            <div className="filter-select">
                                <Select className="select"
                                    clearable={false}
                                    searchable={false}
                                    value={this.state.usersSort}
                                    single
                                    placeholder="Select"
                                    options={filterOptions}
                                    onChange={this.handleSortChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {
                    loading ?
                        <PleaseWait show={this.state.loading}/>
                        :
                        this.props.lookupTables ?
                            users.length ? users.map((user, i) => {
                                if (user.management.status === 'ACTIVE') {
                                    return (
                                        <div key={i}>
                                            <OneInfluencer handleDeleteUser={this.handleDeleteUser} details={user} showPermissions showExpandIcon />
                                        </div>
                                    )
                                } 
                                return (
                                    <div key={i}>
                                        <PendingInfluencer showApiError={this.showApiError} showSuccess={this.showSuccess} handleDeleteUser={this.handleDeleteUser} details={user} showExpandIcon />
                                    </div>
                                )
                                
                            }) : ''
                            : ''
                }
                <PaginationNew
                    total={this.state.totalCount}
                    limit={this.state.pageSize}
                    current={this.state.currentPage}
                    parentContainer={this.container}
                    handlePageChange={this.handlePageChange}
                    limitChangeFunction={this.handlePageSizeChange}
                />
                <Modal
                    isOpen={this.state.showModal}
                    overlayClassName="invite-modal-overlay"
                    className="invite-modal permission-page agentAdmin new-user-form"
                    onRequestClose={this.handleCloseModal}
                    shouldCloseOnOverlayClick
                    ariaHideApp={false}
                >
                    <div className="modal-header">Invite Creator</div>
                    <div className="inputPanel">
                        {inviteWasSent ? <h3>Invite was sent</h3>
                            :
                            <div className="input-holder">
                            <div className="label">
                                    Email
                                </div>
                            <input type="email" onChange={this.handleChange} placeholder="email@address.com"/>
                            { (emailError || inviteError) &&
                                <div className="errors">
                                    <div>{emailError || inviteError}</div>
                                </div>
                                }
                        </div>}
                    </div>
                    <div className="manager-card-bottom new-user-form-bottom">
                        <button onClick={this.handleCloseModal} className="button cancel">{inviteWasSent ? 'Close' : 'Cancel'}</button>
                        {!inviteWasSent && <button disabled={!email || emailError} onClick={this.handleInvite} className="button">Send</button>}
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getLookupTables: () => {
            dispatch(startLoadingLookupTables())
        }
    }
};

const mapStateToProps= (state) => {
    return{
        brands: state.agent.brands,
        agent : state.agent.list,
        lookupTablesLoading : state.global.lookupTablesLoading,
        lookupTables : state.global.lookupTables
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TalentManagementPage)
