import React from 'react';
import Modal from 'react-modal';
import { default as TextTooltip } from '../../shared/tooltip/Tooltip';
import { TopCreatorsTable } from './CompetitiveAnalysis';
import './topCreatorsModal.scss';

export default function TopCreatorsModal({ isOpen, handleClose, topCreators }) {
    return (
        <Modal overlayClassName="modalDialog" className="top-creators-modal" isOpen={isOpen}>
            <div className="modal-title">
                Top Creators
                <div className="info-tooltip-icon">
                    <TextTooltip tooltipText="Most followed creators based on filter criteria" image="/images/tooltipUser.svg" />
                </div>
            </div>
            <div className="creators-panel">
                <TopCreatorsTable topCreators={topCreators} />
            </div>
            <div className="modal-footer">
                <button className="cancel-btn" onClick={handleClose}>
                    Close
                </button>
            </div>
        </Modal>
    );
}
