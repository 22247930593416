import combineEvents from '../../combineEvents';
import {
    OPEN_MODAL,
    CLOSE_MODAL,
    SET_DATES,
    SET_ERROR,
} from './actions';

const initialState = {
    assignmentId: null,
    assigneeId: null,
    showModal: false,
    assignmentDates: [],
    error: null,
};

export default combineEvents({
    [OPEN_MODAL]: (state, assignmentId, assigneeId) => ({
        ...state,
        showModal: true,
        // reset error on modal open
        error: null,
        assignmentId,
        assigneeId,
    }),
    [CLOSE_MODAL]: (state) => ({
        ...initialState,
        // reset modal, but keep error message
        error: state.error,
    }),
    [SET_DATES]: (state) => ({
        ...state,
    }),
    [SET_ERROR]: (state, error) => ({
        ...state,
        error,
    }),
}, initialState);
