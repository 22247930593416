import React from 'react';
import { connect } from 'react-redux';
import './CreatorDetails.scss';
import moment from 'moment';
import classNames from 'classnames';
import VideoThumbnail from 'react-video-thumbnail';
import { safeIsUrl } from '../../modules/CommonServices';
import PleaseWaitWhite from '../shared/pleaseWait/PleaseWaitWhite';
import CreatorDetailsTab from './CreatorDetailsTab';
import ApiError from '../shared/apiError/ApiError';
import Api from '../../modules/Api';
import { startLoadingLookupTables } from '../../store/global/globalActions';
import InfluencerPhoto from './InfluencerPhoto';
import Format from '../../modules/utils/Format';
import FavoriteLists from './favoriteLists/FavoriteLists';
import DisplayTabContent from '../influencerProfile/piecesInMain/about/DisplayTabContent';
import Lookup from '../../modules/Lookup';
import { aboutKeyList, moreKeyList } from '../../modules/influencer/ProfileFields';
import MetricsItem from './MetricsItem';
import ProfileRates from '../influencers/detailPage/influencerProfileBU/ProfileRates';
import Portfolio from '../influencers/detailPage/influencerProfileBU/piecesInMain/Portfolio';
import RecentPost from '../influencers/detailPage/influencerProfileBU/piecesInMain/RecentPost';
import { setShowMissingChannelsError } from '../../store/campaign/influencerSearch/actionCreators';
import {
    AGENT,
    AGENT_STR,
    BRAND_OWNER,
    BRAND_OWNER_STR,
    SUPER_ADMIN,
    SUPER_ADMIN_STR,
    TALENT_MANAGER,
} from '../../constants/authorities';
import Audience from './Audience2';
import { LoadingDialogue } from '../shared/loadingDialogue/loadingDialogue';
import FlagAvatar from '../shared/flagAvatar/FlagAvatar';
import { getNameLastNameText } from '../influencers/discoverPanel/DiscoverPanel.helpers';
import { NETWORKS_INSIGHTS_SUPPORTED_BY_IQ_SOCIAL } from '../../constants/lookup-constants';
import FavoritePopup from '../../components/favourite/FavoritePopup';
import PlanNoteComponent from '../plans/planNoteComponentDetails';
import CompetitorPosts from './competitorPosts/CompetitorPosts';

// const permissions = [AGENT, BRAND_OWNER, SUPER_ADMIN];
const tabs = {
    internal: ['Summary', 'Rates', 'Metrics', 'Recent posts', 'Media kit', 'History'],
    external: ['Details', 'Top posts'],
};
const maxPosts = 25;
const maxNameLength = 60;
class CreatorDetails extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
        let tab;
        if (props.showProfileSidePanel && props.profile.id) {
            tab = tabs.internal[2]; // Metrics
        } else {
            tab = tabs[props.profile.id ? 'internal' : 'external'][0];
        }
        this.state = {
            leftMarginTop: 0,
            profile: props.profile,
            showFavorites: false,
            currentTab: tab,
            summaryMore: false,
            creator: props.profile.id ? 'internal' : 'external',
            recent: [],
            apiError: null,
            showSocialAudienceDetails: null,
            favlist: this.props.favList || [],
        };

        this.favoriteButton = this.favoriteButton.bind(this);
        this.onChangeTabs = this.onChangeTabs.bind(this);
        this.onProfileClick = this.onProfileClick.bind(this);
        this.addToCampaign = this.addToCampaign.bind(this);
        this.hideActions = this.hideActions.bind(this);
        this.onInsight = this.onInsight.bind(this);
        this.onOpenProfileHistory = this.onOpenProfileHistory.bind(this);
    }

    fetchPublicInfluencerDetails = async () => {
        try {
            this.setState({ loading: true });
            const { userNetworks, uid } = this.props.profile;

            let networkType = 'instagram';
            if (userNetworks[0]?.networkType === 'tiktok') {
                networkType = 'tiktok';
            } else if (userNetworks[0]?.networkType === 'youtube') {
                networkType = 'youtube';
            }

            if (networkType) {
                const profileResponse = await Api.getPublicInfluencerDetails(
                    uid,
                    networkType.toUpperCase(),
                    this.props.planId,
                    this.props.campaignId,
                );

                // Temporary networkType solution until BE fix it
                profileResponse.userNetworks[0].networkType = networkType;
                this.setState({ profile: profileResponse });
            }
        } catch (error) {
            console.log(error);
            this.setState({ apiError: error });
        } finally {
            if (this._isMounted) {
                this.setState({ loading: false });
            }
        }
    };

    componentDidMount() {
        const {
            lookupTables,
            lookupTablesLoading,
            getLookupTables,
            profile: { id, uid },
        } = this.props;
        this._isMounted = true;

        if (!lookupTablesLoading && !lookupTables) {
            getLookupTables();
        }

        window.addEventListener('click', this.hideActions);

        if (id) {
            Api.getInfluencerEngagementRecent(id)
                .then((response) => {
                    if (this._isMounted) {
                        this.setState({ recent: response.content || [] });
                    }
                })
                .catch((err) => {
                    if (this._isMounted) {
                        this.setState({ recent: [], apiError: err });
                    }
                });
        } else if (uid) {
            this.fetchPublicInfluencerDetails();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener('click', this.hideActions);
    }

    hideActions() {
        this.setState({
            showActions: false,
            showFavorites: false,
        });
    }

    closeError = () =>
        this.setState({
            apiError: null,
        });

    checkHeart() {
        const { details } = this.props;
        const { userNetworks } = details;
        const { favlist } = this.state;
        let result = false;
        userNetworks.forEach((network) => {
            if (network.favorite_list_ids?.length > 0) {
                result = true;
            }
        });
        if (favlist.length > 0) {
            result = true;
        }
        return result;
    }

    onProfileClick(vetting) {
        if (this.props.profile.id != null) {
            const linkToDetails = `/influencerDetailPage/${this.props.profile.id}${
                this.state.creator === 'external' ? '?Public' : '?Connected'
            }${vetting ? '&vetting' : ''}`;
            window.open(linkToDetails, '_blank');
        }
        if (this.props.profile.uid != null && this.props.profile.id == null) {
            const linkToDetails = `/publicInfluencerDetailsPage/${
                this.props.profile.uid
            }/${this.props.profile.userNetworks[0].networkType.toUpperCase()}${
                this.state.creator === 'external' ? '?Public' : '?Connected'
            }${vetting ? '&vetting' : ''}`;
            window.open(linkToDetails, '_blank');
        }
    }

    onOpenProfileHistory() {
        const linkToDetails = `/influencerDetailPage/${this.props.profile.id}?History`;
        window.open(linkToDetails, '_blank');
    }

    addToCampaign() {
        const { inviteFunction, profile } = this.props;
        if (typeof inviteFunction === 'function') {
            inviteFunction(profile.uid);
        }
    }

    onInsight = async (item) => {
        const {
            profile: { id, uid },
        } = this.props;
        try {
            this.setState({ loading: true });
            const { profile } = this.state;
            let res = null;

            if (profile.id) {
                res = await Api.getAudienceInsightsData(item.socialHandle, item.networkType, id);
                res.public = false;
            } else if (profile.uid) {
                res = await Api.getPublicSourceAudienceInsightsData(profile.uid, item.networkType);
                res.public = true;
            }
            this.setState({ loading: false, showSocialAudienceDetails: res });
        } catch (err) {
            this.setState({ loading: false, apiError: err });
        }
    };

    favoriteButton = (e) => {
        e.stopPropagation();
        e.preventDefault();
        this.setState({
            showFavorites: !this.state.showFavorites,
            // showActions: false,
        });
    };

    onChangeTabs(tab) {
        this.setState({
            currentTab: tab,
        });
    }

    createTopics(topics, max) {
        if (!topics) {
            return;
        }

        this.map = Lookup.getTopicMap();
        const lists = [];
        let index = 0;
        for (const id of topics) {
            const topic = this.map[id];
            if (!topic) {
                continue;
            }
            if (!topic.parentId) {
                if (!max || index < max) {
                    lists.push({
                        id: topic.id,
                        value: topic.value,
                        label: topic.label,
                        subs: [],
                    });
                }
                index++;
            }
        }
        for (const id of topics) {
            const topic = this.map[id];
            if (!topic) {
                continue;
            }
            if (topic.parentId) {
                for (const main of lists) {
                    if (topic.parentId === main.id) {
                        main.subs.push(topic);
                    }
                }
            }
        }

        return lists;
    }

    onPostClick = (post) => {
        if (safeIsUrl(post.link)) {
            window.open(post.link, '_blank');
        }
    };

    renderCompetitorPosts() {
        return this.state.profile.competitiveBrandAssociations?.length ? (
            <CompetitorPosts brands={this.state.profile.competitiveBrandAssociations} />
        ) : null;
    }

    renderSummary() {
        const { summaryMore, profile } = this.state;

        const { summary } = profile;

        const categories = this.createTopics(profile.topics);

        const keyLists = {
            about: ['age_readonly'].concat(aboutKeyList),
            more: moreKeyList,
        };

        return (
            <div className="summaryContainer">
                <div className="summaryLabel">
                    Summary
                    <span>{summary}</span>
                </div>
                <div className="categoryLabel">Categories</div>
                <div className="categoryList">
                    {categories?.map((cate) => {
                        return <div key={cate.label}>{cate.label}</div>;
                    })}
                </div>
                <div className="aboutLabel">About</div>
                <div className="profileAbout">
                    <DisplayTabContent show keys={keyLists.about} profile={profile} />
                </div>
                {summaryMore && (
                    <>
                        <div className="aboutLabel">More details</div>
                        <div className="profileAbout">
                            <DisplayTabContent show keys={keyLists.more} profile={profile} />
                        </div>
                    </>
                )}
                <div className="moreDetails" onClick={() => this.setState({ summaryMore: !summaryMore })}>
                    {summaryMore ? 'Less details' : 'More details'}
                </div>
            </div>
        );
    }

    renderDetails() {
        const { profile } = this.state;
        return <CreatorDetailsTab profile={profile} />;
    }

    renderRates() {
        const { profile } = this.state;
        const { currency, symbol, currencyList } = this.props;
        return (
            <div className="ratesContainer">
                <ProfileRates profile={profile} currency={currency} symbol={symbol} currencyList={currencyList} />
            </div>
        );
    }

    renderMediaKit() {
        const { profile } = this.state;

        return (
            <div className="mediaKitContainer">
                <Portfolio profile={profile} />
            </div>
        );
    }

    renderMetrics() {
        const { profile, showSocialAudienceDetails } = this.state;
        const selectedNetwork = showSocialAudienceDetails?.networkType;
        const totalFollowers =
            profile.userNetworks.find((network) => network.networkType === selectedNetwork)?.reach || 0;

        return (
            <div className="metricsContainer">
                {(!showSocialAudienceDetails || showSocialAudienceDetails.public) &&
                    profile.userNetworks &&
                    profile.userNetworks?.length > 0 &&
                    profile.userNetworks.map((item, index) => {
                        return (
                            <MetricsItem
                                data={item}
                                expanded={index === 0}
                                insights={
                                    NETWORKS_INSIGHTS_SUPPORTED_BY_IQ_SOCIAL.includes(item.networkType) &&
                                    item.socialHandle
                                }
                                onInsight={this.onInsight}
                                key={index}
                                isConnectedInfluencer={Boolean(profile.id)}
                                profile={profile}
                            />
                        );
                    })}
                {showSocialAudienceDetails && (
                    <Audience
                        totalFollowers={totalFollowers}
                        audience={showSocialAudienceDetails.insightsData}
                        label="CREATOR METRICS"
                        type={
                            showSocialAudienceDetails.networkType
                                ? showSocialAudienceDetails.networkType.toLowerCase()
                                : ''
                        }
                        handle={
                            showSocialAudienceDetails.socialHandle ? `@${showSocialAudienceDetails.socialHandle}` : ''
                        }
                        goBack={() => this.setState({ showSocialAudienceDetails: null })}
                        status={showSocialAudienceDetails.status}
                        isConnectedInfluencer={Boolean(profile.id)}
                        profile={profile}
                    />
                )}
            </div>
        );
    }

    renderHistory() {
        const { recent } = this.state;
        return (
            <div className="historyContainer">
                {/* <div className="noContent">No history with this creator</div> */}
                <div className="historyTitle">
                    Engagement in the last 3 months
                    <span>Creator’s engagement available to agents only</span>
                </div>
                {recent?.map((eng, index) => {
                    return (
                        <div key={index} className="historyItemContainer">
                            <div className="headerContainer">
                                <InfluencerPhoto url={eng.brandImage} />
                                <div className="header">
                                    {eng.brandName}
                                    <span>{eng.campaignName}</span>
                                </div>
                            </div>
                            <div className="contentContainer">
                                <div>
                                    <div className="label">Assignment:</div>
                                    <div className="value">{eng.assignmentName}</div>
                                </div>
                                <div>
                                    <div className="label">Type:</div>
                                    <div className="value">{eng.typeAsString}</div>
                                </div>
                                <div>
                                    <div className="label">Payment:</div>
                                    <div className="value">{`$${Format.expressInK(eng.totalRate, 2)}`}</div>
                                </div>
                                <div>
                                    <div className="label">Completed:</div>
                                    <div className="value">{moment(eng.date).format('MMM DD')}</div>
                                </div>
                            </div>
                        </div>
                    );
                })}
                <div className="viewAllHistory" onClick={this.onOpenProfileHistory}>
                    <div className="viewHistory">View all history</div>
                </div>
            </div>
        );
    }

    renderRecentPost() {
        const { profile } = this.state;

        return (
            <div className="recentPostWrapper">
                <RecentPost profile={profile} showHashTags={true} />
            </div>
        );
    }
    renderTopPost() {
        const { profile } = this.state;

        return (
            <div className="recentPostWrapper">
                <RecentPost isTopPost={true} profile={profile} showHashTags={true} isPublicInfluencer={!profile.id} />
            </div>
        );
    }

    changeHeart = (favs) => {
        const { setFavList } = this.props;
        if (setFavList) {
            setFavList(favs);
        }
        this.setState({ favlist: favs });
    };

    render() {
        const {
            loggedInUserProfile,
            lookupTables,
            favoriteData,
            showPermissions,
            sourceTypeFilter,
            currency,
            symbol,
            planId,
            profile: propProfile,
            openSide,
            closeButton,
            updateTab,
        } = this.props;
        const { apiError, currentTab, creator, showFavorites, loading, profile, favlist } = this.state;

        if (!lookupTables || !profile) {
            return <PleaseWaitWhite show={!lookupTables} />;
        }
        const permission =
            loggedInUserProfile &&
            (loggedInUserProfile.role === AGENT_STR ||
                loggedInUserProfile.role === SUPER_ADMIN_STR ||
                loggedInUserProfile.role === BRAND_OWNER_STR);
        const isFavorite = this.checkHeart();
        const heart = classNames('favoriteButton', { heart: isFavorite }, { open: showFavorites });

        let totalReach = 0;
        let engagement = 0;
        let totalFollowers = 0;

        if (Array.isArray(profile.userNetworks) && profile.userNetworks.length > 0) {
            totalReach = profile.userNetworks.map((un) => Number(un.reach)).reduce((a, b) => a + b);
            let totalEngagements = profile.userNetworks.reduce((a, b) => {
                a += Number(b.engagementRate) ? Number(b.engagementRate) : 0;
                return a;
            }, 0);
            engagement = (totalEngagements / profile.userNetworks.length) * 100;
            totalFollowers = profile.userNetworks.map((un) => Number(un.reach)).reduce((a, b) => a + b);
        }

        const name = getNameLastNameText(profile.firstname, profile.lastname);

        const address =
            (profile.city ? `${profile.city}, ` : '') +
            (profile.state ? `${profile.state}, ` : '') +
            (profile.country ? profile.country : '');
        const instagramNetwork = profile.userNetworks.find((un) => un.networkType === 'instagram');
        const isVettingDisabled =
            (profile.type === 'PUBLIC_SOCIAL_USER' && !instagramNetwork) ||
            (!profile.id && profile.uid && !instagramNetwork);
        return (
            <div className="creatorDetails">
                <LoadingDialogue show={loading} container="bottomSectionId" />
                <ApiError show={!!apiError} error={apiError} cancelFunction={this.closeError} />
                <div className="creatorDetailsContainer">
                    {openSide && (
                        <div className="leftSide">
                            <PlanNoteComponent
                                planId={planId}
                                type={Boolean(propProfile.id) ? 'CONNECTED' : 'PUBLIC'}
                                creator={propProfile}
                                profile={loggedInUserProfile}
                                updateTab={updateTab}
                            />
                        </div>
                    )}
                    <div className="inner">
                        {closeButton}
                        <div className="topSection">
                            <div className="infoContainer">
                                <div className="photo">
                                    {profile.authenticityRating && (
                                        <div className="score">
                                            <img src="/images/ic-score.svg" />
                                            <span>{Math.round(profile.authenticityRating)}</span>
                                        </div>
                                    )}
                                    {propProfile.competitiveBrandAssociations?.length ? (
                                        <FlagAvatar>
                                            <InfluencerPhoto
                                                url={profile.pictureUrl}
                                                firstName={profile?.firstname}
                                                lastName={profile?.lastname}
                                            />
                                        </FlagAvatar>
                                    ) : (
                                        <InfluencerPhoto
                                            url={profile.pictureUrl}
                                            firstName={profile?.firstname}
                                            lastName={profile?.lastname}
                                        />
                                    )}
                                </div>
                                <div className="infoWrapper">
                                    <div className="infoDataContainer">
                                        <div>
                                            {name}
                                            <span>{address}</span>
                                        </div>
                                        {creator !== 'internal' && (
                                            <div className="valueContainer">
                                                <div>
                                                    {creator === 'internal'
                                                        ? Format.expressInK(totalReach, 2)
                                                        : Format.expressInK(totalFollowers, 2)}
                                                    <span>
                                                        {creator === 'internal' ? 'Total Followers' : 'Followers'}
                                                    </span>
                                                </div>
                                                <div className="divider" />
                                                <div>
                                                    {Format.percentage2(
                                                        this.state.creator === 'external'
                                                            ? engagement
                                                            : engagement / 100,
                                                    )}
                                                    <span>Engagement</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div
                                        className={classNames('infoActionsContainer', {
                                            external: creator !== 'internal',
                                        })}
                                    >
                                        {!isVettingDisabled && (
                                            <div className="vetUserButton" onClick={() => this.onProfileClick(true)}>
                                                Vet User
                                            </div>
                                        )}
                                        {Boolean(profile.email) && (
                                            <div>
                                                <a href={`mailto:${profile.email}`}>Contact</a>
                                            </div>
                                        )}
                                        {!showPermissions && permission && (
                                            <div className={heart} onClick={this.favoriteButton}>
                                                <img id="noneHeart" src="/images/ic-heart-g.svg" />
                                                <img id="Heart" src="/images/ic-heart.svg" />
                                                {/* <FavoriteLists
                                                    show={this.state.showFavorites}
                                                    closeFunction={() => {
                                                        this.setState({ showFavorites: false });
                                                    }}
                                                    userDetails={profile}
                                                    isOnList={isFavorite}
                                                /> */}
                                                {this.state.showFavorites && (
                                                    <FavoritePopup
                                                        usersSelected={profile}
                                                        favList={favlist}
                                                        onChange={this.changeHeart}
                                                    />
                                                )}
                                            </div>
                                        )}
                                        <div className="favoriteButton" onClick={() => this.onProfileClick(false)}>
                                            <img src="/images/icon-open.svg" />
                                        </div>
                                        {/* <div className="favoriteButton" onClick={this.onProfileClick}>
                                            {isVettingDisabled ? 'Profile' : 'Profile & Vetting'}
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="navigationContainer">
                                <div className="tabsContainer">
                                    {tabs[creator].map((tab) => {
                                        return (
                                            <div
                                                onClick={() => this.onChangeTabs(tab)}
                                                className={`tab ${currentTab === tab && 'active'}`}
                                                key={tab}
                                            >
                                                {tab}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <div id="bottomSectionId" className="bottomSection">
                            {currentTab === 'Summary' && this.renderCompetitorPosts()}
                            {currentTab === 'Summary' && this.renderSummary()}
                            {currentTab === 'Rates' && this.renderRates()}
                            {currentTab === 'Metrics' && this.renderMetrics()}
                            {currentTab === 'Recent posts' && this.renderRecentPost()}
                            {currentTab === 'Top posts' && this.renderTopPost()}

                            {currentTab === 'Media kit' && this.renderMediaKit()}
                            {currentTab === 'Details' && this.renderDetails()}
                            {currentTab === 'History' && this.renderHistory()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    getLookupTables: () => dispatch(startLoadingLookupTables()),
    setShowMissingChannelsError: (show) => dispatch(setShowMissingChannelsError(show)),
});

const mapStateToProps = (state) => ({
    loggedInUserProfile: state.global.loggedInUserProfile,
    lookupTablesLoading: state.global.lookupTablesLoading,
    lookupTables: state.global.lookupTables,
    showMissingChannelsError: state.campaignReducer.influencerSearch.showMissingChannelsError,
});

export default connect(mapStateToProps, mapDispatchToProps)(CreatorDetails);
