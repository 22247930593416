import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

// Props
// - campaign
// - currentNavigation

const ASSIGNMENT_LINK = 0;
const INFLUENCERS_LINK = 1;
const POST_LINK = 2;
const ANALYTICS_LINK = 3;
const COMPLIANCE_LINK = 4;
const SEARCH_LINK = 7;

class CampaignNavigation extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.renderedLink = this.renderedLink.bind(this);
        this.goCampaign = this.goCampaign.bind(this);
        // this.processProps = this.processProps.bind(this);
        this.createUrl = this.createUrl.bind(this);

        this.navigation = [
            { label: 'Assignment' },
            // { label: 'Calendar' },
            { label: 'Creators', folder: 'influencers' },
            { label: 'Posts', folder: 'posts' },
            { label: 'Analytics' },
            { label: 'Compliance' },
        ];
    }

    componentDidMount() {
        const urlPieces = window.location.pathname.split('/');
        const assignmentId = urlPieces[urlPieces.length - 1];
        this.assignmentId = assignmentId;
    }

    createUrl(navigation) {
        // should have the assignmentId in the url or else there is a loss
        // of focus of which assignmentId is currently selected. add 'assignment/:assignmentId/'
        let { folder } = navigation;
        if (!folder) {
            folder = navigation.label.toLowerCase();
        }
        const url = `/campaignWork/${this.props.campaignId}/${folder}/${this.assignmentId}`;
        return url;
    }

    goCampaign() {
        const campaignLink = `/campaign/${this.props.campaignId}`;
        this.props.history.push(campaignLink);
    }

    renderedLink(nav, index) {
        const self = this;
        const clickHandler = function () {
            self.props.history.push(self.createUrl(nav));
        };
        let addedClass = '';
        if (index === this.props.currentNavigation) {
            addedClass = 'active';
        }
        return (
            <div key={index}>
                <div onClick={clickHandler} className={addedClass}>
                    {nav.label}
                </div>
            </div>
        );
    }

    acceptedChatPages() {
        const allowedList = [
            1, // Calendar
            2, // Influencers
            3, // Posts
        ];

        for (let i = 0; i < allowedList.length; i++) {
            if (this.props.currentNavigation === allowedList[i]) {
                return true;
            }
        }
        return false;
    }

    render() {
        if (!this.props.campaign) {
            return null;
        }

        const links = [];
        for (const nav of this.navigation) {
            links.push(this.renderedLink(nav, links.length));
        }
        const { campaign } = this.props;
        return (
            <div className="campaignNavigation">
                <div className="campaignLink">
                    <div className="contentDiv">
                        <div className="contentTable" style={{ pointer: 'cursor' }}>
                            <img
                                onClick={this.goCampaign}
                                src="/images/ic-back-gray.svg"
                                alt="back"
                                className="backIcon"
                            />
                            <div style={{ width: '9px' }} />
                            {campaign && <div>{campaign.name}</div>}
                        </div>
                    </div>
                </div>
                <div className="detailLinks">
                    <div className="contentDiv">
                        <div className="navTable">{links}</div>
                    </div>
                </div>
            </div>
        );
    }

    static getAssignmentLink() {
        return ASSIGNMENT_LINK;
    }

    static getInfluencersLink() {
        return INFLUENCERS_LINK;
    }

    static getPostLink() {
        return POST_LINK;
    }

    static getAnalyticsLink() {
        return ANALYTICS_LINK;
    }

    static getSearchLink() {
        return SEARCH_LINK;
    }

    static getComplianceLink() {
        return COMPLIANCE_LINK;
    }
}

const mapDispatchToProps = (dispatch) => {
    return {};
};

const mapStateToProps = (state) => {
    return {
        campaignState: state.campaign,
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CampaignNavigation));
