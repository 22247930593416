import { select, put } from 'redux-saga/effects';
import { setData } from '../actionCreators';
import { createInviteLists } from '../helpers';

export default function* selectAllSaga() {
    const {
        influencers,
        alreadyInvitedInfluencers,
        requiredAssignmentChannels,
        existInviteList,
    } = yield select(({ campaignReducer, general }) => ({
        influencers: campaignReducer.influencerSearch.influencers,
        alreadyInvitedInfluencers: general.assignmentInfo.alreadyInvitedUsers,
        requiredAssignmentChannels: general.assignmentInfo.channels,
        existInviteList: campaignReducer.influencerSearch.inviteList,
    }));

    const { inviteList, inappropriateInviteList } = createInviteLists(
        influencers,
        alreadyInvitedInfluencers,
        requiredAssignmentChannels,
        existInviteList,
    );
    yield put(setData({
        inviteList,
        inappropriateInviteList,
    }));
}
