import React from 'react';

import Connect from './Connect'
import SocialNetworks from '../../../../modules/influencer/SocialNetworks';
import Format from '../../../../modules/utils/Format';
import ProfileConnectToSocial from '../../../shared/connectToSocial/ProfileConnectToSocial';
import ModalAlertWithAction from '../../../shared/modalWithSingleAction/ModalAlertWithAction.js'
import SocialConnectionStatus from '../../../shared/connectToSocial/SocialConnectionStatus';

export default class ConnectEdit extends React.Component {
    constructor(props,context) {
        super(props, context);

        this.state = {
            connectionCount : 0,
            totalReach : 0,
            showAlertModal: false
        }
    }

    canContinue = () => {
        return (this.state.connectionCount >= 1)
    }

    cont = () => {
        if (this.canContinue()) {
            this.props.continueFunction();
        }
    }

    renderTotals = () => {
        const { profile } = this.props;
        let totalReach = 0;

        if (Array.isArray(profile.userNetworks) && profile.userNetworks.length > 0) {
            totalReach = profile.userNetworks.map((un) => Number(un.reach)).reduce((a, b) => a + b);
        }

        return (
            <div className="metricsBoxTitle">
                <div className="metricsBoxTitleText">Connected Channels</div>
                <div className="metricsBoxTitleItem">
                    <div className="metricsBoxTitleNum">{Format.expressInK(totalReach, 2)}</div>
                    <div className="metricsBoxTitleLabel">Total Followers</div>
                </div>
            </div>
        )
    }

    handleStatusChange = (networkKey,network) => {
        let userNetworks = SocialNetworks.updateNetworks(this.props.profile.userNetworks, networkKey, network);
        this.props.profile.userNetworks = userNetworks;
        this.setState(ConnectEdit.getDerivedStateFromProps(this.props, this.state))
    }

    renderNetworks = () => {
        let column1  = [];
        let column2 = [];
        // let length1 = Math.ceil(SocialNetworks.list().length/2);
        let length1 = SocialNetworks.list().length;
        for ( let networkKey of SocialNetworks.list()) {
            let box =
                <ProfileConnectToSocial
                    key={networkKey + (new Date())}
                    networkKey={networkKey}
                    networkStats={ this.state.networksMap[networkKey.toLowerCase()]}
                    statusChangeFunction={this.handleStatusChange}
                    redirectSuffix={'redirect?page=profile'}
                    profile={this.props.profile}
                />;
            if (column1.length >= length1) {
                column2.push(box);
            } else {
                column1.push(box);
            }
        }
        return (
            <div className="edit">
                <div className="boxes">
                    { column1 }
                </div>
                <div className="spacer">
                </div>
                <div className="boxes">
                    { column2 }
                </div>
            </div>
        )
    }

    renderContinue = () => {
        let buttonClass = this.canContinue() ? 'continueButton' : 'continueLabel'
        return (<div style={{textAlign:'right'}}>
            <div className={buttonClass} onClick={this.cont}>
                Done
            </div>
        </div>)
    }

    closeAlertModal = () => {
        this.setState({ showAlertModal: false })
    }

    static getDerivedStateFromProps(props, state) {
        let newState = {};
        let oldConnectionCount = state.connectionCount;
        newState.networks = props.profile.userNetworks;
        newState.networksMap = {};
        for (let network of props.profile.userNetworks) {
            let key = network.networkType.toLowerCase();
            newState.networksMap[key] = network;
        }
        let networksMap = state.networksMap || newState.networksMap;
        newState.connectionCount = Connect.getConnectionCount(networksMap);
        if (newState.connectionCount < oldConnectionCount && newState.connectionCount<1) {
            newState.showAlertModal = true;
            newState.alertMessageId = 'influencerProfile.noConnectionErrorMessage'
        }
        newState.totalReach = Connect.getTotalReach(networksMap);
        return newState;
    }

    render() {
        return (
            <div>
                <SocialConnectionStatus/>
                <ModalAlertWithAction
                    titleId = 'errorExclamation'
                    messageId = {this.state.alertMessageId}
                    actionFunction = {this.closeAlertModal}
                    show = {this.state.showAlertModal}
                />
                { this.renderTotals() }
                { this.renderNetworks() }
                { this.renderContinue() }
            </div>
        )
    }
}
