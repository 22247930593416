import React from 'react';
import PropTypes from 'prop-types';
import { HorizontalBar } from 'react-chartjs-2';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { render } from 'react-dom';
import cx from 'classnames';
import AnalyticsinfluencerDisplay from './AnalyticsinfluencerDisplay';
import { CSVLink } from 'react-csv';
import { ByInfluencerReport } from './PDFReport';

const graphOption2 = {
    maintainAspectRatio: false,
    tooltips: { enabled: true, position: 'nearest' },
    layout: {
        padding: {
            left: 40,
            right: 60,
            top: 25,
            bottom: 25,
        },
    },
    legend: {
        display: false,
    },
    animation: {
        duration: 0,
    },
    scales: {
        xAxes: [
            {
                barPercentage: 1,
                gridLines: {
                    zeroLineColor: '#F0F0F0',
                    color: '#F0F0F0',
                    tickMarkLength: 0,
                },
                ticks: {
                    precision: 0,
                    beginAtZero: true,
                    fontColor: 'rgba(0,0,0,0.4)',
                    fontSize: 14,
                    fontFamily: 'Nunito Sans, sans-serif',
                    padding: 5,
                },
            },
        ],
        yAxes: [
            {
                gridLines: {
                    zeroLineWidth: 0,
                    zeroLineColor: 'transparent',
                    color: 'transparent',
                    drawBorder: false,
                },
                ticks: {
                    fontColor: '#414141',
                    fontSize: 14,
                    fontFamily: 'Nunito Sans, sans-serif',
                    padding: 5,
                },
            },
        ],
    },
};

export default class AnalyticsByInfluencer extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            sortBy: 'name',
            desc: 'true',
            exportOpen: false,
            loadingPDF: false,
        };
        this.byInfluencerChart = React.createRef();
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.hideDropdownMenu);
    }

    showDropdownMenu = (e) => {
        e.stopPropagation();
        const { exportOpen } = this.state;
        this.setState({ exportOpen: !exportOpen });
        window.addEventListener('click', this.hideDropdownMenu);
    };

    hideDropdownMenu = () => {
        this.setState({ exportOpen: false });
    };

    exportPDF = () => {
        const { dataByInfluencer, campaignInfo, showLoadingScreen, hideLoadingScreen } = this.props;
        if (!this.state.loadingPDF) {
            showLoadingScreen();
            this.setState({ loadingPDF: true }, () => {
                const imgSrc = this.byInfluencerChart.current.chartInstance.toBase64Image();
                this.createAndDownloadPDF(
                    <ByInfluencerReport
                        info={campaignInfo}
                        influencer={{ img: imgSrc }}
                        dataByInfluencer={dataByInfluencer}
                    />,
                    () => {
                        this.setState({ loadingPDF: false });
                        hideLoadingScreen();
                    },
                );
            });
        }
    };

    createAndDownloadPDF = (pdfContent, callback) => {
        const { startDate, endDate } = this.props;
        setTimeout(() => {
            const link = (
                <div id="export-report">
                    <PDFDownloadLink document={pdfContent} fileName={`ByInfluencer-${startDate}-${endDate}.pdf`}>
                        {({ loading }) => {
                            if (!loading) {
                                setTimeout(() => {
                                    document.getElementById('export-report').children[0].click();
                                    callback();
                                    document
                                        .getElementById('root')
                                        .removeChild(document.getElementById('export-report-container'));
                                }, 3);
                            }
                        }}
                    </PDFDownloadLink>
                </div>
            );
            const elem = document.createElement('div');
            elem.id = 'export-report-container';
            document.getElementById('root').appendChild(elem);
            render(link, elem);
        }, 50);
    };

    render() {
        const {
            campaignInfo,
            dataByInfluencer: { influencers, highestReach },
            startDate,
            endDate,
        } = this.props;
        const { sortBy, desc, exportOpen } = this.state;
        const labels = [];
        const reachData = [];
        if (highestReach) {
            highestReach.forEach(({ name, totalReach }) => {
                labels.push(name);
                reachData.push(totalReach);
            });
        }
        const userData = {
            labels,
            datasets: [
                {
                    label: 'Total Reach',
                    backgroundColor: '#72C255',
                    borderWidth: 0,
                    barThickness: 30,
                    data: reachData,
                },
            ],
        };

        const headers = [
            { label: 'Campaign', key: 'campaign' },
            { label: 'Selected Assignments', key: 'selectedAssignments' },
            { label: 'Creator', key: 'name' },
            { label: 'Facebook', key: 'facebookReach' },
            { label: 'IG Post', key: 'instagramPostReach' },
            { label: 'IG Stories', key: 'instagramStoryReach' },
            { label: 'Twitter', key: 'twitterReach' },
            { label: 'YouTube', key: 'youtubeReach' },
            { label: 'Total Followers', key: 'totalReach' },
            { label: 'Shares', key: 'shares' },
            { label: 'Saves', key: 'saves' },
        ];

        const csv = influencers ? influencers.slice(0) : [{ campaign: '', selectedAssignments: '' }];
        if (csv[0]) {
            csv[0].campaign = campaignInfo.campaignName;
            csv[0].selectedAssignments = campaignInfo.assignments;
        } else {
            csv.push({ campaign: campaignInfo.campaignName, selectedAssignments: campaignInfo.assignments });
        }
        return (
            <div className="analyticsOverview">
                <div>
                    <div className="sectionTitleLine">
                        <div className="sectionTitle">Top Creators</div>
                        <div className="exportSection">
                            {campaignInfo.currency && (
                                <div className="currency">
                                    Currency:{' '}
                                    <div>{` ${campaignInfo.currency.code} (${campaignInfo.currency.symbol})`}</div>
                                </div>
                            )}
                            <div className="exportBtn" onClick={this.showDropdownMenu}>
                                Export
                                <img src="/images/ic-dropdown-d.svg" />
                            </div>
                            {exportOpen && (
                                <div className="exportDropdown">
                                    <CSVLink
                                        data={csv}
                                        headers={headers}
                                        filename={`ByInfluencer-${startDate}-${endDate}.csv`}
                                        target="_blank"
                                    >
                                        .csv
                                    </CSVLink>
                                    <div onClick={() => this.exportPDF()}>.pdf</div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="influencerChart" id="byInfluencerChart">
                        <HorizontalBar
                            ref={this.byInfluencerChart}
                            data={userData}
                            width={1280}
                            height={300}
                            options={graphOption2}
                        />
                    </div>
                </div>
                <div className="tableHeader">
                    <div className="channelTableInfluencer">
                        <div className="influencer" onClick={this.sortByName}>
                            <span>Creator</span>
                        </div>

                        <div className="facebook">
                            <span>Facebook</span>
                        </div>

                        <div className="igPost" onClick={this.sortByRate}>
                            <span>Instagram</span>
                        </div>

                        <div className="twitter" onClick={this.sortByStatus}>
                            <span>Twitter</span>
                        </div>
                        <div className="tiktok" onClick={this.sortByStatus}>
                            <span>TikTok</span>
                        </div>
                        <div className="youtube" onClick={this.sortByStatus}>
                            <span>YouTube</span>
                        </div>
                        <div className="reach" onClick={this.sortByStatus}>
                            <span>Total Followers</span>
                        </div>
                    </div>
                </div>
                {influencers &&
                    influencers.map((data, index) => {
                        return <AnalyticsinfluencerDisplay key={index} data={data} />;
                    })}
            </div>
        );
    }
}

AnalyticsByInfluencer.propTypes = {
    dataByInfluencer: PropTypes.shape({
        highestReach: PropTypes.arrayOf(
            PropTypes.shape({
                facebookReach: PropTypes.number,
                id: PropTypes.number,
                instagramPostReach: PropTypes.number,
                instagramStoryReach: PropTypes.number,
                name: PropTypes.string,
                totalReach: PropTypes.number,
                twitterReach: PropTypes.number,
                youtubeReach: PropTypes.number,
            }),
        ),
        influencers: PropTypes.arrayOf(
            PropTypes.shape({
                facebookReach: PropTypes.number,
                id: PropTypes.number,
                instagramPostReach: PropTypes.number,
                instagramStoryReach: PropTypes.number,
                name: PropTypes.string,
                totalReach: PropTypes.number,
                twitterReach: PropTypes.number,
                youtubeReach: PropTypes.number,
            }),
        ),
    }),
    campaignInfo: PropTypes.shape({
        campaignName: PropTypes.string,
        assignments: PropTypes.array,
        startDate: PropTypes.string,
        endDate: PropTypes.string,
    }),
    showLoadingScreen: PropTypes.func,
    hideLoadingScreen: PropTypes.func,
};

AnalyticsByInfluencer.defaultProps = {
    dataByInfluencer: {
        influencers: [
            {
                facebookReach: 0,
                id: 1,
                instagramPostReach: 0,
                instagramStoryReach: 0,
                name: null,
                totalReach: 0,
                twitterReach: 0,
                youtubeReach: 0,
            },
        ],
        highestReach: [
            {
                facebookReach: 0,
                id: 1,
                instagramPostReach: 0,
                instagramStoryReach: 0,
                name: null,
                totalReach: 0,
                twitterReach: 0,
                youtubeReach: 0,
            },
        ],
    },
    campaignInfo: {},
    showLoadingScreen: () => {},
    hideLoadingScreen: () => {},
};
