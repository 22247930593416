import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import './translationTooltip.scss';

const TooltipContent = ({ text }) => {
  return (
    <div className="tooltip-container">
      <div className="tooltip-header">
        <img src="/images/translation-icon.svg" />
        Translated to English
      </div>
      {text ? <div>{text}</div> : <div className="missing-translation">No translation was found.</div>}
    </div>
  );
};

const TranslationTooltip = ({ children, text }) => {
  return (
    <Tippy
      animation={false}
      interactive={false}
      placement="top"
      arrow
      render={(attrs) => (
        <div {...attrs} className="tooltip-wrapper">
          <TooltipContent text={text} />
          <div className="tooltip-arrow" id="arrow" data-popper-arrow="" />
        </div>
      )}
    >
      {children}
    </Tippy>
  );
};

export default TranslationTooltip;