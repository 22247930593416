import React, { useState, useEffect, useLayoutEffect, forwardRef, useImperativeHandle } from 'react';
import cx from 'classnames';
import './addNotes.scss';
import { NotesCard } from './components/NotesCard';

const AddNotes = ({
    profiles = [],
    onDelete = () => null,
    onChange = () => null,
    currency
}, ref) => {
    const [creators, setCreators] = useState([]);

    useEffect(() => {
        const creators = JSON.parse(JSON.stringify(profiles));
        setCreators(creators || []);
    }, [profiles]);

    const onChangeHandle = (val, index) => {
        const update = Object.assign([], creators);
        update[index] = val;
        setCreators(update);
    }

    const onDeleteHandle = (index) => {
        const update = Object.assign([], creators);
        update.splice(index, 1);
        setCreators(update);
    }

    useImperativeHandle(ref, () => ({
        onNext: () => {
            return creators;
        }
    }));
    let allPublic = true
    profiles.forEach(item => {
        if (item.id) {
            allPublic = false;
    }})
    return (
        <div ref={ref} className='addNotesContainer'>
            <div className='labelContainer'>
                {allPublic ? 'Review' : 'Add Notes'}
                {!allPublic && <span>Personalize your invitation to creators</span>}
            </div>
            <div className='creatorsContainer'>
                {creators.map((profile, index) => {
                    return <NotesCard
                        profile={profile}
                        onChange={val => onChangeHandle(val, index)}
                        onDelete={() => onDeleteHandle(index)}
                        keyId={`NotesCard_${profile.id}`}
                        key={`NotesCard_${profile.id ? profile.id : profile.uid}`}
                        hideDelete={!(creators.length > 1)}
                        currency={currency}
                    />
                })}
            </div>
        </div>
    )
}

export default forwardRef(AddNotes);