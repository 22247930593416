import React, { PureComponent } from 'react';
import './login.scss';
import { Auth } from 'aws-amplify';
import classNames from 'classnames';
import { connect } from 'react-redux';
import * as HoneyBadger from 'honeybadger-js';
import { Link } from 'react-router-dom';
import { Motion, spring } from 'react-motion';
import { isSafari } from 'react-device-detect';
import FloatingLabelInput from '../shared/FloatingLabelInput/FloatingLabelInput';
import { storeUserProfile, resetTenantList } from '../../store/global/globalActions';
import { resetAgentBrands } from '../../store/agent/actions';
import Api from '../../modules/Api';
import PleaseWait from '../shared/pleaseWait/PleaseWaitWhite';
import ApiError from '../shared/apiError/ApiError';
import { logout } from '../../libs/awsLib';
import WarningModal from '../shared/warningModal/warningModal';
import ProfileHelper from '../../modules/utils/ProfileHelper';
import { TALENT_MANAGER_STR } from '../../constants/authorities';
import LoadingButton from '../shared/loadingButton/LoadingButton';
import PopUpDialogueBase from '../shared/popUpDialogueBase/PopUpDialogueBase';

// const config = require('../../../../server/config/index.json');

// const PG_SSO_DOMAIN = process.env.PG_SSO_DOMAIN || config.PG_SSO_DOMAIN;
// const PG_REDIRECT_URI = process.env.PG_SSO_REDIRECT_URI || config.PG_SSO_REDIRECT_URI;
// const PG_CLIENT_ID = process.env.PG_SSO_CLIENT_ID || config.PG_SSO_CLIENT_ID;

class LoginPage extends PureComponent {
    constructor(props, context) {
        super(props, context);
        // console.log('login', props);

        this.selected = this.selected.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleConfirmationSubmit = this.handleConfirmationSubmit.bind(this);
        this.validateConfirmationForm = this.validateConfirmationForm.bind(this);
        this.handleSignInSubmit = this.handleSignInSubmit.bind(this);
        this.handleSignUpSubmit = this.handleSignUpSubmit.bind(this);
        this.handleForgotPwd = this.handleForgotPwd.bind(this);
        this.handleResetPwd = this.handleResetPwd.bind(this);
        this.handleNewBUPwdUpdate = this.handleNewBUPwdUpdate.bind(this);
        this.validateSignInForm = this.validateSignInForm.bind(this);
        this.validateSignUpForm = this.validateSignUpForm.bind(this);
        this.validateConfirmationForm = this.validateConfirmationForm.bind(this);
        this.validateResetPwd = this.validateResetPwd.bind(this);
        this.validateForgetPwd = this.validateForgetPwd.bind(this);
        this.selected = this.selected.bind(this);
        this.handleSignupPasswordChange = this.handleSignupPasswordChange.bind(this);
        this.proceedSucessfulLogin = this.proceedSucessfulLogin.bind(this);
        this.replaceNonCharacterValue = this.replaceNonCharacterValue.bind(this);
        this.backButtonClickHandler = this.backButtonClickHandler.bind(this);
        this.handleEnterTOTPToken = this.handleEnterTOTPToken.bind(this);

        this.passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{12,}$/;
        this.emailRegex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,20}$/;
        this.nameRegex = /^[^0-9\s]{2,16}/;

        this.state = {
            selectedTab: this.props.location.selectedTab ? this.props.location.selectedTab : 'login',
            email: '',
            password: '',
            password2: '',
            firstName: '',
            lastName: '',
            confirmationCode: '',
            newUser: null,
            forgotPwd: '',
            ssoName: '',
            newBUPassword: '',
            verificationCode: '',
            err: '',
            waiting: false,
            isActive: true,
            showWarning: false,
            loading: false,
            showAccountIsLocked: false,
            showSuccessfulSentVerificationLinkMessage: false,
        };
        this.pageHashLocation = window.location.hash.replace(/^#\/?|\/$/g, '');
    }

    async componentDidMount() {
        const { location, history } = this.props;
        if (location.state && location.state.accountLocked) {
            try {
                history.replace();
                const profile = await ProfileHelper.getCurrentLoginUserProfile();
                logout();
                if (typeof resetAgentBrands === 'function') resetAgentBrands();
                if (typeof resetTenantList === 'function') resetTenantList();
                if (profile && profile.email) {
                    this.setState({ showAccountIsLocked: true, email: profile.email.trim().toLowerCase() });
                } else {
                    this.setState({ showAccountIsLocked: true });
                }
            } catch (err) {
                logout();
                this.setState({ showAccountIsLocked: true });
            }
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedTab !== this.state.selectedTab && this.state.selectedTab === 'resetPwd')
            this.handleSignupPasswordChange('newBUPassword', '');
    }

    validateConfirmationForm() {
        return this.state.confirmationCode.length > 4;
    }

    validateSignInForm() {
        return this.state.email.length > 0 && this.emailRegex.test(this.state.email) && this.state.password.length > 0;
    }

    validateForgetPwd() {
        return this.state.email.length > 0 && this.emailRegex.test(this.state.email);
    }

    validateResetPwd() {
        const pwd = this.passwordRegex.test(this.state.forgotPwd);
        return this.state.verificationCode.length > 4 && this.state.forgotPwd === this.state.forgotPwd2 && pwd;
    }

    validateNewBUPwdUpdate() {
        const { newBUPassword, newBUPassword2, firstName, lastName, requireName } = this.state;
        const pwd = this.passwordRegex.test(newBUPassword);
        const nameValid =
            firstName.length > 0 &&
            this.nameRegex.test(firstName) &&
            lastName.length > 0 &&
            this.nameRegex.test(lastName);
        return requireName
            ? newBUPassword === newBUPassword2 && pwd && nameValid
            : newBUPassword === newBUPassword2 && pwd;
    }

    validateSignUpForm() {
        const { email, firstName, lastName, password, password2 } = this.state;
        const validateResult = {};
        validateResult.result = false;
        validateResult.error = '';
        if (
            email.trim() === '' &&
            firstName.trim() === '' &&
            lastName.trim() === '' &&
            password === '' &&
            password2 === ''
        ) {
            return validateResult;
        }
        if (firstName.length === 0) {
            if (firstName.trim() !== '') validateResult.error = 'Please enter the required first name.';
            return validateResult;
        }
        if (!this.nameRegex.test(firstName)) {
            if (firstName.trim() !== '') validateResult.error = 'First name does not match required format.';
            return validateResult;
        }
        if (lastName.length === 0) {
            if (lastName.trim() !== '') validateResult.error = 'Please enter the required last name.';
            return validateResult;
        }
        if (!this.nameRegex.test(lastName)) {
            if (lastName.trim() !== '') validateResult.error = 'Last name does not match required format.';
            return validateResult;
        }
        if (email.length === 0) {
            if (email.trim() !== '') validateResult.error = 'Please enter the required email.';
            return validateResult;
        }
        if (!this.emailRegex.test(email)) {
            if (email.trim() !== '') validateResult.error = 'Email does not match required format.';
            return validateResult;
        }
        if (password.length === 0) {
            if (password !== '')
                validateResult.error =
                    'Please enter the password of at least 12 characters long with at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character.';
            return validateResult;
        }
        if (!this.passwordRegex.test(password)) {
            if (password.trim() !== '') validateResult.error = 'Password does not match required format.';
            return validateResult;
        }
        if (password !== password2) {
            if (password2 !== '') validateResult.error = "Passwords don't match.";
            return validateResult;
        }
        validateResult.result = true;
        return validateResult;
    }

    notifyLog = (error) => {
        HoneyBadger.beforeNotify(function (notice) {
            notice.context = { ...notice.context, error };
        });
        HoneyBadger.notify('Auth call', {
            name: 'Auth error',
            message: 'Diagnose message',
        });
    };

    handleSignUpSubmit(event) {
        event.preventDefault();
        const { email, firstName, lastName, password } = this.state;
        const { hash } = this.props.location;
        const { history, storeProfile } = this.props;
        this.setState({ loading: true });

        Auth.signUp({
            username: email.trim().toLowerCase(),
            password,
            attributes: {
                email: email.trim().toLowerCase(),
                given_name: firstName,
                family_name: lastName,
                'custom:signup_type': hash === '#talent' ? 'TALENT_MANAGER' : 'INFLUENCER',
            },
        })
            .then(async (res) => {
                if (res && res.userSub) {
                    try {
                        // successfully create the account in user pool, now calling API to create account on backend db
                        await Api.registerUserNew({
                            email,
                            type: hash === '#talent' ? 'TALENT_MANAGER' : 'INFLUENCER',
                            username: res.userSub,
                        });

                        // sign in to get the access token
                        await Auth.signIn(email.trim().toLowerCase(), password);

                        const profile = await ProfileHelper.getCurrentLoginUserProfile();
                        if (typeof storeProfile === 'function') {
                            storeProfile(profile);
                        }
                        this.setState(
                            {
                                err: '',
                                loading: false,
                                password: '',
                                password2: '',
                                email: '',
                                firstName: '',
                                lastName: '',
                            },
                            () => {
                                history.push('/onboard');
                            },
                        );
                    } catch (error) {
                        this.setState({ apiError: error.text ? error.text : error.message, loading: false });
                        this.notifyLog(error);
                    }
                    return;
                }
                // should not come here
                this.setState({ err: 'Unable to get the sub from sign up response', loading: false });
                this.notifyLog({ error: 'Unable to get the sub from sign up response', data: res });
            })
            .catch(async (err) => {
                if (err.code === 'UsernameExistsException') {
                    this.setState({
                        err: 'An account with the given email already exists. Please sign in or sign up with a different email.',
                        loading: false,
                    });
                    return;
                }
                this.setState({ err: err.text ? err.text : err.message, loading: false });
                this.notifyLog(err);
            });
    }

    proceedSucessfulLogin(user) {
        const challengeName = user && user.challengeName;
        if (challengeName) {
            switch (challengeName) {
                case 'NEW_PASSWORD_REQUIRED':
                    let requireName = false;
                    if (user.challengeParam && user.challengeParam.userAttributes) {
                        const attr = user.challengeParam.userAttributes;
                        if (!attr.given_name || !attr.family_name) {
                            requireName = true;
                        }
                    }
                    this.setState({ newUser: user, selectedTab: 'pwdUpdate', err: '', waiting: false, requireName });
                    break;
                case 'SOFTWARE_TOKEN_MFA':
                    this.setState({
                        newUser: user,
                        selectedTab: 'enterTOTPToken',
                        err: '',
                        waiting: false,
                        isActive: false,
                    });
                    break;
                default:
                    this.setState({ err: `Unhandled Challenge: ${challengeName}` });
                    break;
            }
        } else {
            ProfileHelper.getCurrentLoginUserProfile()
                .then((shortProfile) => {
                    this.props.storeProfile(shortProfile);
                    let nextStep = '/profile';
                    // let type = `#${(shortProfile.type).toLowerCase()}`;

                    // return to where the user redirect from
                    const { location } = this.props;
                    if (location && location.state && location.state.from && location.state.from.pathname) {
                        if (location.state.from.pathname.length > 0) {
                            nextStep = location.state.from.pathname;
                        }
                    }

                    if (shortProfile.type === 'AGENT') {
                        nextStep = '/home';
                    }
                    if (shortProfile.role === TALENT_MANAGER_STR) {
                        nextStep = '/talentManagement';
                    }
                    this.setState({
                        err: '',
                        waiting: false,
                    });
                    // console.log('nextStep', nextStep);
                    if (isSafari) {
                        this.setState({
                            showWarning: true,
                            nextStep,
                        });
                    } else {
                        this.props.history.push(nextStep);
                    }
                })
                .catch((err) => {
                    let noUser = false;
                    if (err.error === 'Not Found') {
                        noUser = true;
                    }

                    if (noUser) {
                        Api.registerUser().then(() => {
                            this.setState(
                                {
                                    waiting: false,
                                },
                                () => this.props.history.push('/onboard'),
                            );
                        });
                    } else if (err.status === 423) {
                        this.setState({
                            waiting: false,
                            showAccountIsLocked: true,
                        });
                    } else if (err.agentMessage) {
                        this.setState({
                            err: err.agentMessage,
                            waiting: false,
                        });
                    } else {
                        this.setState({
                            waiting: false,
                            apiError: err,
                        });
                    }
                });
        }
    }

    async handleSignInSubmit(event) {
        event.preventDefault();
        this.setState({
            waiting: true,
        });
        const self = this;
        const { email, password } = this.state;
        Auth.signIn(email.trim().toLowerCase(), password).then(self.proceedSucessfulLogin, function (err) {
            if (err.code === 'UserNotConfirmedException') {
                Auth.resendSignUp(email.trim().toLowerCase())
                    .then((cognitoUser) => {
                        self.setState({
                            isActive: false,
                            newUser: cognitoUser,
                            err: 'Resent Confirmation Email',
                            waiting: false,
                        });
                    })
                    .catch((err) => {
                        self.setState({
                            err: err.message,
                            waiting: false,
                        });
                    });
            } else {
                self.setState({
                    err: err.message,
                    waiting: false,
                });
            }
        });
    }

    getNewConfirmationCode2 = () => {
        const { email } = this.state;
        Auth.resendSignUp(email.trim().toLowerCase())
            .then((cognitoUser) => {})
            .catch((err) => {
                this.setState({
                    err: err.message,
                });
            });
    };

    async handleConfirmationSubmit(event) {
        event.preventDefault();
        const { email, confirmationCode, password } = this.state;
        this.setState({
            waiting: true,
        });
        const confirmCodeTrimmed = confirmationCode && confirmationCode.trim();
        try {
            await Auth.confirmSignUp(email.trim().toLowerCase(), confirmCodeTrimmed);
            await Auth.signIn(email.trim().toLowerCase(), password);
            const { hash } = this.props.location;
            if (hash === '#talent') {
                await Api.registerUser({ role: 'talent_manager' });
                logout(async () => {
                    await Auth.signIn(email.trim().toLowerCase(), password);
                    this.props.history.push(`/talentManagement`);
                });
            } else {
                await Api.registerUser({ role: 'influencer' });
                await logout(async () => {
                    await Auth.signIn(email.trim().toLowerCase(), password);
                    this.props.history.push(`/onboard`);
                });
            }
            this.setState({
                err: '',
                waiting: false,
            });
        } catch (err) {
            this.setState({
                err: err.message,
                waiting: false,
            });
        }
    }

    handleChange(id, value) {
        this.setState({
            [id]: value,
            formErrors: [],
            err: '',
        });
    }

    async handleForgotPwd(event) {
        event.preventDefault();
        const that = this;
        const { email } = that.state;
        try {
            await Api.forgotPassword(email.trim().toLowerCase());
            that.setState({
                selectedTab: 'resetPwd',
                isActive: false,
                err: '',
            });
        } catch (err) {
            that.setState({
                err: 'Thank you! If you have an account with us, we will send you an email to reset your password.',
            });
        }
    }

    async handleResetPwd(event) {
        event.preventDefault();
        const { verificationCode, forgotPwd, forgotPwd2, email } = this.state;
        const that = this;
        if (forgotPwd !== forgotPwd2) {
            this.setState({ err: "Passwords don't match" });
            return;
        }

        this.setState({
            waiting: true,
        });

        try {
            await Auth.forgotPasswordSubmit(email.trim().toLowerCase(), verificationCode, forgotPwd);
            await Auth.signIn(email.trim().toLowerCase(), forgotPwd);
            that.proceedSucessfulLogin();
        } catch (err) {
            that.setState({
                err: err.message,
                waiting: false,
            });
        }
    }

    async handleEnterTOTPToken(event) {
        event.preventDefault();
        const { newUser, totpToken } = this.state;
        this.setState({
            waiting: true,
        });

        try {
            const loggedUser = await Auth.confirmSignIn(newUser, totpToken, 'SOFTWARE_TOKEN_MFA');
            this.proceedSucessfulLogin();
        } catch (err) {
            console.log(err);
            this.setState({
                err: err.message,
                waiting: false,
            });
        }
    }

    async handleNewBUPwdUpdate(event) {
        event.preventDefault();
        const { newUser, firstName, lastName, newBUPassword, newBUPassword2, requireName } = this.state;
        // const that = this;
        if (newBUPassword !== newBUPassword2) {
            this.setState({ err: "Passwords don't match" });
            return;
        }

        const requiredAttr = requireName ? { family_name: lastName.trim(), given_name: firstName.trim() } : {};

        this.setState({
            waiting: true,
        });

        try {
            const loggedUser = await Auth.completeNewPassword(newUser, newBUPassword, requiredAttr);
            this.proceedSucessfulLogin(loggedUser);
        } catch (err) {
            if (err.code === 'NotAuthorizedException')
                err.message = 'Your session has expired. Please refresh this page.';
            this.setState({
                err: err.message,
                waiting: false,
            });
        }
    }

    handleSSOLogin = async () => {
        const { ssoName } = this.state;
        if (ssoName) {
            const name = ssoName.toLowerCase().trim();
            if (name === 'pg.com') {
                const res = await Api.pgGetLoginUrl(name);
                if (res && res.redirectUrl) {
                    if (isSafari) {
                        this.setState({
                            showWarning: true,
                            nextStep: res.redirectUrl,
                            ssoLogin: true,
                        });
                    } else {
                        await logout(() => {
                            window.location.href = res.redirectUrl;
                        });
                    }
                }
            }
        }
    };

    selected(event) {
        const tagClass = event.target.classList[1];
        const tagText = document.getElementsByClassName(tagClass)[0].innerHTML;
        if (tagText === 'log in') {
            this.setState({
                selectedTab: 'login',
                isActive: true,
                newUser: null,
                err: '',
            });
        } else {
            this.handleSignupPasswordChange('password', '');
            this.setState({
                selectedTab: 'signup',
                isActive: true,
                newUser: null,
                err: '',
            });
        }
    }

    resendVerificationLink = () => {
        const { email } = this.state;
        if (email) {
            Api.accountVerifyRequest(email.trim().toLowerCase())
                .then((res) => {
                    if (res.statusCode === 200) {
                        this.setState({ showSuccessfulSentVerificationLinkMessage: true, apiError: undefined });
                    } else {
                        const e = { message: res.error, originalMessage: res.data };
                        this.setState({ apiError: e });
                    }
                })
                .catch((err) => {
                    this.setState({ apiError: err });
                });
        }
    };

    handleResendOnLock = () => {
        this.setState(
            {
                showAccountIsLocked: false,
            },
            this.resendVerificationLink,
        );
    };

    renderEnterTOTPToken() {
        const { selectedTab, err } = this.state;
        const tabClass = classNames('loginFields', { enterTOTPToken: selectedTab === 'enterTOTPToken' });
        return (
            <form onSubmit={this.handleEnterTOTPToken}>
                <Motion
                    defaultStyle={{ y: 0.01, maxHeight: 0 }}
                    style={{
                        y: spring(1, { stiffness: 50, damping: 10 }),
                        maxHeight: spring(150, { stiffness: 50, damping: 10 }),
                    }}
                >
                    {({ y, maxHeight }) => (
                        <div
                            className={tabClass}
                            style={{
                                maxHeight: `${maxHeight}px`,
                                opacity: `${y}`,
                            }}
                        >
                            <div className="fl-text">Multi Factor Authentication</div>
                            <FloatingLabelInput
                                pattern={/\d{6}$/}
                                type="tel"
                                errorMsg="Please enter a valid TOTP Token."
                                id="totpToken"
                                placeholder="Enter TOTP Token"
                                handleChange={this.handleChange}
                                autoFocus
                            />
                            {err !== '' ? <div className="error">{err}</div> : ''}
                        </div>
                    )}
                </Motion>
                <div className="submit-value-container">
                    <button
                        type="submit"
                        className="submit-value"
                        disabled={!(this.state.totpToken && this.state.totpToken.length === 6)}
                    >
                        Confirm Login
                    </button>
                </div>
            </form>
        );
    }

    renderForgotPwd() {
        const { selectedTab, err } = this.state;
        const tabClass = classNames(
            'loginFields',
            { signup: selectedTab === 'signup' },
            { login: selectedTab === 'login' },
            { forgot: selectedTab === 'forgot' },
        );
        return (
            <form onSubmit={this.handleForgotPwd}>
                <Motion
                    defaultStyle={{ y: 0.01, maxHeight: 0 }}
                    style={{
                        y: spring(1, { stiffness: 50, damping: 10 }),
                        maxHeight: spring(200, { stiffness: 50, damping: 10 }),
                    }}
                >
                    {({ y, maxHeight }) => (
                        <div
                            className={tabClass}
                            style={{
                                maxHeight: `${maxHeight}px`,
                                opacity: `${y}`,
                            }}
                        >
                            <div className="fl-text">Reset your password:</div>
                            <FloatingLabelInput
                                pattern={this.emailRegex}
                                type="email"
                                errorMsg="Please enter a valid email address."
                                id="email"
                                placeholder="Email Address"
                                handleChange={this.handleChange}
                            />
                            {err !== '' ? <div className="error">{err}</div> : ''}
                        </div>
                    )}
                </Motion>
                <div className="submit-value-container">
                    <button type="submit" className="submit-value" disabled={!this.validateForgetPwd()}>
                        send email
                    </button>
                </div>
            </form>
        );
    }

    renderSignIn() {
        const { selectedTab, err } = this.state;
        const tabClass = classNames(
            'loginFields',
            { signup: selectedTab === 'signup' },
            { login: selectedTab === 'login' },
        );
        return (
            <form onSubmit={this.handleSignInSubmit}>
                <Motion
                    defaultStyle={{ y: 0.01, maxHeight: 50 }}
                    style={{
                        y: spring(1, { stiffness: 80, damping: 30 }),
                        maxHeight: spring(250, { stiffness: 50, damping: 10 }),
                    }}
                >
                    {({ y, maxHeight }) => (
                        <div
                            className={tabClass}
                            style={{
                                opacity: `${y}`,
                                maxHeight: `${maxHeight}px`,
                            }}
                        >
                            <FloatingLabelInput
                                pattern={this.emailRegex}
                                type="email"
                                errorMsg="Please enter a valid email address."
                                id="email"
                                placeholder="Email Address"
                                handleChange={this.handleChange}
                            />
                            <FloatingLabelInput
                                pattern={/.*/}
                                type="password"
                                errorMsg="."
                                id="password"
                                placeholder="Password"
                                handleChange={this.handleChange}
                            />
                            <div className="loginOptionsLine">
                                {this.pageHashLocation === 'agent' && (
                                    <div
                                        className="forgotPwd"
                                        onClick={() =>
                                            this.setState({
                                                selectedTab: 'ssoLogin',
                                                isActive: false,
                                                err: '',
                                            })
                                        }
                                        role="button"
                                    >
                                        Log in via SSO
                                        <span>|</span>
                                    </div>
                                )}
                                <div
                                    className="forgotPwd"
                                    onClick={() =>
                                        this.setState({
                                            selectedTab: 'forgot',
                                            isActive: false,
                                            err: '',
                                        })
                                    }
                                >
                                    Forgot Password?
                                </div>
                            </div>
                            {err !== '' && <div className="error">{err}</div>}
                        </div>
                    )}
                </Motion>
                <div className="submit-value-container">
                    <button type="submit" className="submit-value" disabled={!this.validateSignInForm()}>
                        Log in
                    </button>
                </div>
            </form>
        );
    }

    renderConfirmationForm() {
        const { selectedTab, err } = this.state;
        const tabClass = classNames(
            'loginFields',
            { signup: selectedTab === 'signup' },
            { login: selectedTab === 'login' },
        );
        return (
            <form onSubmit={this.handleConfirmationSubmit}>
                <Motion
                    defaultStyle={{ y: 0.01, maxHeight: 0 }}
                    style={{
                        y: spring(1, { stiffness: 50, damping: 10 }),
                        maxHeight: spring(190, { stiffness: 50, damping: 10 }),
                    }}
                >
                    {({ y, maxHeight }) => (
                        <div
                            className={tabClass}
                            style={{
                                opacity: `${y}`,
                                maxHeight: `${maxHeight}px`,
                            }}
                        >
                            <div className="fl-text">Please check your email:</div>
                            <FloatingLabelInput
                                pattern={/[0-9]{4,8}$/}
                                type="tel"
                                errorMsg="Please enter a valid confirmation code."
                                id="confirmationCode"
                                placeholder="Confirmation Code"
                                handleChange={this.handleChange}
                            />
                            {err && (
                                <div className="error">
                                    {err}
                                    {err.indexOf('Invalid verification code') >= 0 && (
                                        <div
                                            style={{
                                                paddingTop: 10,
                                                paddingBottom: 10,
                                                cursor: 'pointer',
                                                color: '#0065da',
                                            }}
                                            onClick={this.getNewConfirmationCode2()}
                                        >
                                            Send new confirmation code
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </Motion>
                <div className="submit-value-container">
                    <button type="submit" className="submit-value" disabled={!this.validateConfirmationForm()}>
                        Submit
                    </button>
                </div>
            </form>
        );
    }

    replaceNonCharacterValue(pwd) {
        const value = pwd.split('');
        const result = value.map((value) => {
            if (/\W/.test(value)) {
                return `\\${value}`;
            }
            return value;
        });
        return new RegExp(`^${result.join('')}$`);
    }

    renderSignUp() {
        const { selectedTab, loading, err, passwordErrors } = this.state;
        let renderedPasswordErrors1 = [];
        if (passwordErrors && passwordErrors.length > 0) {
            renderedPasswordErrors1 = passwordErrors.map((value, index) => <li key={index}>{value}</li>);
        }

        const tabClass = classNames(
            'loginFields',
            { signup: selectedTab === 'signup' },
            { login: selectedTab === 'login' },
        );
        const enableSubmitButton = this.validateSignUpForm().result;
        const validateError = this.validateSignUpForm().error;
        const updateNewBUPwd = this.replaceNonCharacterValue(this.state.password);
        return (
            <form onSubmit={this.handleSignUpSubmit}>
                <Motion
                    defaultStyle={{ y: 0.01, maxHeight: 80 }}
                    style={{
                        y: spring(1, { stiffness: 40, damping: 10 }),
                        maxHeight: spring(440, { stiffness: 40, damping: 10 }),
                    }}
                >
                    {({ x, y, maxHeight }) => (
                        <div
                            className={tabClass}
                            style={{
                                opacity: `${y}`,
                                maxHeight: `${maxHeight}px`,
                            }}
                        >
                            <FloatingLabelInput
                                pattern={this.nameRegex}
                                type="text"
                                errorMsg="Please enter a valid first name."
                                id="firstName"
                                placeholder="First Name"
                                handleChange={this.handleChange}
                            />
                            <FloatingLabelInput
                                pattern={this.nameRegex}
                                type="text"
                                errorMsg="Please enter a valid last name."
                                id="lastName"
                                placeholder="Last Name"
                                handleChange={this.handleChange}
                            />
                            <FloatingLabelInput
                                pattern={this.emailRegex}
                                type="email"
                                errorMsg="Please enter a valid email address."
                                id="email"
                                placeholder="Email Address"
                                handleChange={this.handleChange}
                            />
                            <FloatingLabelInput
                                pattern={this.passwordRegex}
                                errorMsg={renderedPasswordErrors1}
                                type="password"
                                id="password"
                                placeholder="Password"
                                handleChange={this.handleSignupPasswordChange}
                                showErrorOnMount
                            />
                            <FloatingLabelInput
                                pattern={updateNewBUPwd}
                                type="password"
                                errorMsg={"Passwords don't match"}
                                id="password2"
                                placeholder="Confirm Password"
                                handleChange={this.handleChange}
                            />
                            {err !== '' || validateError ? (
                                <div className="error signuperror">{err || validateError}</div>
                            ) : (
                                ''
                            )}
                        </div>
                    )}
                </Motion>
                <div className="submit-value-container">
                    <LoadingButton
                        isLoading={loading}
                        type="submit"
                        className="submit-value"
                        disabled={!enableSubmitButton}
                    >
                        Submit
                    </LoadingButton>
                </div>
            </form>
        );
    }

    renderPwdUpdate() {
        const { err, requireName, passwordErrors } = this.state;
        let renderedPasswordErrors1 = [];
        if (passwordErrors && passwordErrors.length > 0) {
            renderedPasswordErrors1 = passwordErrors.map((value, index) => <li key={index}>{value}</li>);
        }
        const updateNewBUPwd = this.replaceNonCharacterValue(this.state.newBUPassword);
        return (
            <form onSubmit={this.handleNewBUPwdUpdate}>
                <Motion
                    defaultStyle={{ y: 0.01, maxHeight: 0 }}
                    style={{
                        y: spring(1, { stiffness: 50, damping: 10 }),
                        maxHeight: spring(550, { stiffness: 50, damping: 10 }),
                    }}
                >
                    {({ y, maxHeight }) => (
                        <div
                            className="loginFields resetPwd"
                            style={{
                                opacity: `${y}`,
                                maxHeight: `${maxHeight}px`,
                            }}
                        >
                            <FloatingLabelInput
                                pattern={this.passwordRegex}
                                type="password"
                                errorMsg={renderedPasswordErrors1}
                                id="newBUPassword"
                                placeholder="New Password"
                                handleChange={this.handleSignupPasswordChange}
                                liveValidation
                                showErrorOnMount
                            />
                            <FloatingLabelInput
                                pattern={updateNewBUPwd}
                                type="password"
                                errorMsg={"Passwords don't match"}
                                id="newBUPassword2"
                                placeholder="Re-type New Password"
                                handleChange={this.handleChange}
                                liveValidation
                            />
                            {requireName && (
                                <>
                                    <FloatingLabelInput
                                        pattern={/[A-Za-z]{2,16}/}
                                        type="text"
                                        errorMsg="Please enter a valid first name."
                                        id="firstName"
                                        placeholder="First Name"
                                        handleChange={this.handleChange}
                                    />
                                    <FloatingLabelInput
                                        pattern={/[A-Za-z]{2,16}/}
                                        type="text"
                                        errorMsg="Please enter a valid last name."
                                        id="lastName"
                                        placeholder="Last Name"
                                        handleChange={this.handleChange}
                                    />
                                </>
                            )}
                            {err !== '' ? <div className="error">{err}</div> : ''}
                        </div>
                    )}
                </Motion>
                <div className="submit-value-container">
                    <button type="submit" className="submit-value" disabled={!this.validateNewBUPwdUpdate()}>
                        Complete Sign-Up
                    </button>
                </div>
            </form>
        );
    }

    renderSignUpComplete = () => {
        return (
            <>
                <div className="loginFields signUpComplete">
                    <div className="msgTitle">Success</div>
                    <div>Your account has been created. Please check your email for temporary password.</div>
                </div>
                <div className="submit-value-container">
                    <button className="submit-value" onClick={() => this.setState({ selectedTab: 'login' })}>
                        CONTINUE
                    </button>
                </div>
            </>
        );
    };

    renderForgetComplete = () => {
        return (
            <>
                <div className="loginFields signUpComplete">
                    <div className="msgTitle">Success</div>
                    <div>Your password has been reset. Please check your email for temporary password.</div>
                </div>
                <div className="submit-value-container">
                    <button className="submit-value" onClick={() => this.setState({ selectedTab: 'login' })}>
                        CONTINUE
                    </button>
                </div>
            </>
        );
    };

    renderSSOLogin() {
        return (
            <div>
                <Motion
                    defaultStyle={{ y: 0.01, maxHeight: 0 }}
                    style={{
                        y: spring(1, { stiffness: 50, damping: 10 }),
                        maxHeight: spring(550, { stiffness: 50, damping: 10 }),
                    }}
                >
                    {({ y, maxHeight }) => (
                        <div
                            className={classNames('loginFields', 'ssoLogin')}
                            style={{
                                opacity: `${y}`,
                                maxHeight: `${maxHeight}px`,
                            }}
                        >
                            <div className="fl-text">Please enter the domain name for SSO:</div>
                            <FloatingLabelInput
                                pattern={/[a-z0-9.-]+\.[a-z]{2,4}$/}
                                type="string"
                                id="ssoName"
                                placeholder="SSO Name"
                                handleChange={this.handleChange}
                            />
                        </div>
                    )}
                </Motion>
                <div className="submit-value-container">
                    <button className="submit-value" onClick={this.handleSSOLogin}>
                        Continue
                    </button>
                </div>
            </div>
        );
    }

    onWarningClosed = async () => {
        const { nextStep, ssoLogin } = this.state;
        this.setState({
            showWarning: false,
        });
        if (nextStep) {
            if (ssoLogin) {
                await logout(() => {
                    window.location.href = nextStep;
                });
            } else {
                this.props.history.push(nextStep);
            }
        }
    };

    handleSignupPasswordChange(key, value) {
        const passwordErrors = [];
        if (value.length < 12) {
            passwordErrors.push('Password must be at least 12 characters long');
        }
        if (!value.match(/[a-z]+/)) {
            passwordErrors.push('Password must contain at least one lowercase letter');
        }
        if (!value.match(/[A-Z]+/)) {
            passwordErrors.push('Password must contain at least one uppercase letter');
        }
        if (!value.match(/[0-9]+/)) {
            passwordErrors.push('Password must contain at least one number');
        }
        if (!value.match(/[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`]+/)) {
            passwordErrors.push('Password must contain at least one special character');
        }
        this.setState({ passwordErrors });
        this.handleChange(key, value);
    }

    backButtonClickHandler() {
        this.setState((state) => {
            return {
                isActive: !state.isActive,
                newUser: null,
                selectedTab: state.selectedTab === 'forgot' ? 'login' : 'signup',
            };
        });
    }

    render() {
        const {
            selectedTab,
            isActive,
            showWarning,
            waiting,
            apiError,
            email,
            showAccountIsLocked,
            showSuccessfulSentVerificationLinkMessage,
        } = this.state;
        const loginClass = classNames('navigationChoice loginC', { selected: selectedTab === 'login' });
        const signupClass = classNames('navigationChoice signupC', { selected: selectedTab === 'signup' });
        const loginNavigation = classNames('loginNavigation', { active: isActive });
        const warningTitle = 'Browser not supported';
        const warningMessage =
            'Our platform does not fully support Safari for some features. Please use Chrome for best experience.';
        return (
            <div className="loginBackground">
                <PleaseWait show={waiting} />
                <ApiError
                    show={apiError}
                    error={apiError}
                    cancelFunction={() => {
                        this.setState({ apiError: null });
                    }}
                />
                <WarningModal
                    show={showWarning}
                    title={warningTitle}
                    message={warningMessage}
                    closeButton="OK"
                    closeFunction={this.onWarningClosed}
                />
                <PopUpDialogueBase
                    show={showAccountIsLocked}
                    title="Account is Locked"
                    cancelLabel="Resend Verification Link"
                    proceedLabel="OK"
                    message="Your account has been locked. Please verify your account."
                    proceedFunction={() => this.setState({ showAccountIsLocked: false })}
                    cancelFunction={this.handleResendOnLock}
                    cancelButtonStyle={{ whiteSpace: 'nowrap' }}
                    hideCancel={email === ''}
                    windowStyle={{ width: 'unset' }}
                />
                <PopUpDialogueBase
                    show={showSuccessfulSentVerificationLinkMessage}
                    title="Verification Link Sent"
                    proceedLabel="OK"
                    message={`The verification link has been sent to ${email.trim().toLowerCase()}`}
                    proceedFunction={() => this.setState({ showSuccessfulSentVerificationLinkMessage: false })}
                    hideCancel
                />
                <div className="loginContent">
                    <Motion
                        defaultStyle={{ x: 120, y: 0.01 }}
                        style={{
                            x: spring(0, { stiffness: 130, damping: 30 }),
                            y: spring(1, { stiffness: 130, damping: 30 }),
                        }}
                    >
                        {({ x, y }) => (
                            <div
                                className="loginBox"
                                style={{
                                    opacity: `${y}`,
                                    WebkitTransform: `translate3d(0,${x}px, 0)`,
                                    transform: `translate3d( 0, ${x}px,0)`,
                                }}
                            >
                                <div className="logoImageContainer">
                                    <Link to="/" className="logoAnchor">
                                        <img className="logoImage" src="/images/prophet.png" alt="logo" />
                                    </Link>
                                </div>

                                {this.pageHashLocation !== 'agent' && (
                                    <div className={loginNavigation}>
                                        <div className="underneathLine" />
                                        <div className={loginClass} onClick={this.selected} role="button">
                                            log in
                                        </div>

                                        <div className={signupClass} onClick={this.selected} role="button">
                                            sign up
                                        </div>
                                    </div>
                                )}

                                <div className="loginBody">
                                    {selectedTab === 'login' && this.renderSignIn()}
                                    {selectedTab === 'signup' &&
                                        this.pageHashLocation !== 'agent' &&
                                        this.renderSignUp()}
                                    {selectedTab === 'forgot' && this.renderForgotPwd()}
                                    {selectedTab === 'resetPwd' && this.renderForgetComplete()}
                                    {selectedTab === 'pwdUpdate' && this.renderPwdUpdate()}
                                    {selectedTab === 'enterTOTPToken' && this.renderEnterTOTPToken()}

                                    {selectedTab === 'signUpComplete' && this.renderSignUpComplete()}
                                    {selectedTab === 'ssoLogin' && this.renderSSOLogin()}
                                </div>
                            </div>
                        )}
                    </Motion>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        storeProfile: (profile) => {
            dispatch(storeUserProfile(profile));
        },
    };
};

export default connect(null, mapDispatchToProps)(LoginPage);
