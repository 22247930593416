import ModalDialog from './ModalDialog'

/**
 * This is identical to ModalDialog except for:
 *    on ModalDialogEx only the proceedFunction is called when pressed PROCEED button
 *    (unlike on the ModalDialog where first cancelFunction is called and then proceedFunction is called)
 */

export default class ModalDialogEx extends ModalDialog {

  constructor(props, context) {
    super(props, context);

    this.proceed = this.proceed.bind(this);
  }

  proceed() {
    if (this.props.readyToProceed) {
      let f = this.props.proceedFunction;
      if (typeof f === 'function') {
        f();
      }
    }
  }

}