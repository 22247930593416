import { call, put, select } from 'redux-saga/effects';
import {
    lookupTablesLoaded,
    setLookupTablesLoading,
    setData,
    setError,
} from '../actionCreators';
import Api from '../../../modules/Api';

export default function* lookupTablesSaga() {
    yield put(setLookupTablesLoading(true));

    try {
        const lookupTables = yield call(Api.getAllDomainData);

        const { lookup } = yield select(({ general }) => ({
            lookup: general.lookup,
        }));

        yield put(lookupTablesLoaded(lookupTables));
        yield put(setData({
            lookup: {
                ...lookup,
                lookupTables,
            },
        }));
    } catch (error) {
        yield put(setError({
            lookupTables: error.message,
        }));
    } finally {
        yield put(setLookupTablesLoading(false));
    }
}
