import { missingChannelsErrorMessage } from './reducer';

export const influencerBodySelector = (state) => {
    const {
        campaignReducer: {
            influencerSearch,
        },
        general,
        global,
        modals: {
            missingChannels: { isOpen },
        },
        FAVORITE_REDUCER: {
            favoriteData,
        }
    } = state;

    const showPleaseWaitWhite = !(general.lookup.lookupFilters.length
        && Object.keys(general.lookup.lookupTables).length);

    const {
        inviteList,
        inappropriateInviteList,
    } = influencerSearch;
    // filter the correct invitations
    const appropriateInviteList = Object.values(inviteList).reduce((acc, invite) => {
        if (!Object.prototype.hasOwnProperty.call(inappropriateInviteList, invite.uid)) {
            acc.push(invite);
        }

        return acc;
    }, []);

    return ({
        alreadyInvitedUsers: general.assignmentInfo.alreadyInvitedUsers,
        apiError: influencerSearch.apiError,
        appropriateInviteList,
        assignmentId: general.assignmentInfo.assignmentId,
        assignmentName: general.assignmentInfo.assignmentName,
        campaignId: general.assignmentInfo.campaignId,
        currentPage: influencerSearch.currentPage,
        favorites: general.favorites.favoritesList,
        favoriteData,   // favoriteActions/favoriteReducer store data in here, not general.favorites.favoritesList
        favoriteChangeCount: general.favorites.favoriteChangeCount,
        influencers: influencerSearch.influencers,
        inviteInProgress: influencerSearch.inviteInProgress,
        inviteList: Object.values(influencerSearch.inviteList),
        missingChannelsErrorMessage,
        pageLimit: influencerSearch.displayFilters.pageLimit.active.value,
        pageLimitOptions: influencerSearch.displayFilters.pageLimit.options,
        permissions: influencerSearch.permissions,
        profile: global.loggedInUserProfile,
        searchFilters: influencerSearch.searchFilters,
        showDiscovery: influencerSearch.showDiscovery,
        showInviteSuccess: influencerSearch.showInviteSuccess,
        showMissingChannelsError: influencerSearch.showMissingChannelsError,
        showMissingChannelsModal: isOpen,
        showNoResults: !influencerSearch.showPleaseWait && influencerSearch.totalCount === 0,
        showPleaseWait: influencerSearch.showPleaseWait,
        showPleaseWaitWhite,
        sort: influencerSearch.displayFilters.sort.active.value,
        sortOrder: influencerSearch.displayFilters.sortOrder.active,
        sortOptions: influencerSearch.displayFilters.sort.options,
        totalCount: influencerSearch.totalCount,
        currency: influencerSearch.displayFilters.currency,
        symbol: influencerSearch.displayFilters.symbol,
    });
};

export const campaignInfluencerSearchSelector = (state) => {
    const { general } = state;
    return {
        assignmentId: general.assignmentInfo.assignmentId,
        campaignId: general.assignmentInfo.campaignId,
        searchId: general.assignmentInfo.searchId,
        error: general.errors.assignmentInfo
    };
};
