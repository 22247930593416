import React from "react";
import moment from 'moment';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TopNavigation from '../shared/topNavigation/TopNavigation';
import RecentList from './recentLists'
import "./homeDashboard.scss"
import Api from '../../modules/Api';
import ApiError from "../shared/apiError/ApiError";
import { updateSavedSearches } from '../../modules/utils/CommonUtils';
import Lookup from '../../modules/Lookup2022';
import { getData } from '../../store/campaign/influencerSearch/actionCreators';


class HomePage extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            campaignList: [],
            campaignPost: [],
            apiError: null,
            savedSearches: [],
            planList:[],
            searchableOptions: [],
            hasLast: false,
            countryList: []
        }
    }

    componentDidMount() {
        const { actions: { getData }, lookupFilters } = this.props;
        const param = { page: 1, num: 10, type: 'current', sortby: 'date', desc: 'desc'}
        const param2 = { page: 1, num: 10}
        if (lookupFilters) {
            this.callSavedSearches();
        } else {
            getData();
        }
        Api.getCampaignList(param).then(
            (result) => {
                const campaignList = this.filterArchivedAssignments(result.results);
                this.setState({
                    campaignList
                })
            }).catch( (error) => {
            this.setState({apiError: error});
        });
        Api.getCampaignPosts(param2).then(
            (result) => {
                const postArray = []
                for (const resultItem of result.results) {
                    for (const postItem of resultItem.posts) {
                        postItem.assignmentName = resultItem.assignmentName;
                        postItem.name = `${resultItem.userFirstName} ${resultItem.userLastName}`;
                        postItem.userPictureUrl = resultItem.userPictureUrl;
                        postItem.assignmentId = resultItem.assignmentId;
                        postArray.push(postItem)
                    }
                }
                this.setState({
                    campaignPost: postArray
                })
            }).catch( (error) => {
            this.setState({apiError: error});
        });
        Api.searchPlans({"direction":"DESC","keyword":"","page":0,"size":10,"sortBy":"UpdatedOn"})
            .then(res => {
                this.setState({planList: res.data})
            })
            .catch(error => {
                this.setState({apiError: error});
            })
        Api.getBrandCountry().then(result => {
            const { data } = result;
            const countryList = [];
            data.forEach(x => {
                const country = {label: x.name, value: x.code, currencyCode: x.currencyCode}
                countryList.push(country)
            })
            this.setState({countryList})
        })
    }

    componentDidUpdate (prevProps) {
        const { lookupFilters } = this.props;
        if (!prevProps.lookupFilters && lookupFilters) {
            this.callSavedSearches()
        }
    }


    callSavedSearches = () => {
        const { searchableOptions } = this.state;
        const num = 8;
        const page = 1;
        const temp = [...searchableOptions];
        const pFilters = Lookup.getAllFilters('PUBLIC');
        const cFilters = Lookup.getAllFilters('CONNECTED');
        Api.getAutoSave()
            .then(res => {
                if (res && res.param) {
                    const contents = updateSavedSearches(pFilters, cFilters, [res]);
                    contents.searches.forEach(s => {
                        if (s?.param?.selectedFilters) {
                        s?.param.selectedFilters.forEach(ps => {
                            if (ps.searchable && temp.find(so => so.id === ps.id) === undefined) {
                                Api.searchFilterOptions({filterId: ps.id, keyword: '', limit: 99999, ignore: true})
                                    .then(res => {
                                        if (temp.find(so=> so.id === ps.id) === undefined) {
                                            temp.push({id: ps.id, options: res});
                                            this.setState({searchableOptions: temp})
                                        }
                                    })
                                    .catch(err => {
                                        setApiError(err);
                                    })
                            }
                        })
                        } 
                    })
                    this.setState({hasLast: contents.searches[0]})
                }
            })
            .catch(error => {
                this.setState({apiError: error});
            })
        Api.getAgentSavedSearches({num, page, desc: true})
            .then(res => {
                const list = [];
                const contents = updateSavedSearches(pFilters, cFilters, res.content);
                contents.searches.forEach(s => {
                    if (s?.param?.selectedFilters) {
                    s?.param.selectedFilters.forEach(ps => {
                        if (ps.searchable && temp.find(so => so.id === ps.id) === undefined) {
                            Api.searchFilterOptions({filterId: ps.id, keyword: '', limit: 99999, ignore: true})
                                .then(res => {
                                    if (temp.find(so=> so.id === ps.id) === undefined) {
                                        temp.push({id: ps.id, options: res});
                                        this.setState({searchableOptions: temp})
                                    }
                                })
                                .catch(err => {
                                    setApiError(err);
                                })
                        }
                    })
                    } 
                })
                if (Array.isArray(contents.searches)) {
                    contents.searches.forEach(s => {
                        const obj = {};
                        obj.name = s.name;
                        obj.keywords = s?.param?.keywordFilters?.length || 0;
                        obj.filters = (s?.param?.commonFilters?.length || 0) + (s?.param?.audienceFilters?.length || 0) + (s?.param?.connectedFilters?.length || 0);
                        obj.isNew = !!obj?.numNewUsersFound;
                        obj.param = s.param
                        list.push(obj);
                    })
                }
                this.setState({savedSearches: list});
            })
            .catch(error => {
                this.setState({apiError: error});
            })
    }

    filterArchivedAssignments(campaignList) {
        const newList = [];
        if (!campaignList || campaignList.length === 0) {
            return newList;
        }
        campaignList.forEach((campaign) => {
            if (campaign.assignments) {
                const assignments = campaign.assignments.filter(assignment => !assignment.archived)
                campaign.assignments = assignments;
            }
            newList.push(campaign);
        });
        return newList;
    }

    continue = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const { history } = this.props;
        const { hasLast, searchableOptions } = this.state;
        if (hasLast) {
            const data = Object.assign(hasLast.param, {searchableOptions: _.isEmpty(searchableOptions) ? null : searchableOptions});
            history.push({
                pathname: `/influencers`,
                state: {
                    search: {
                        type: hasLast.param.type ?? 'PUBLIC',
                        ...data
                    }
                }
            });
        } else {
            history.push({
                pathname: `/influencers`,
            });
        }
    }

    render() {
        const { authCheckData, history } = this.props;
        const { campaignList, campaignPost, apiError, savedSearches, planList, searchableOptions, hasLast, countryList } = this.state;
        return (
            <div className="homeDash">
                <TopNavigation
                    current="home"
                    clickHandlerForCurrent={this.triggerRerender}
                />
                <ApiError
                    show={apiError !== null}
                    error={apiError}
                    cancelFunction={() => this.setState({apiError: null})}
                />
                <div className="homePage">
                    <div className="homeContainer">
                        <div className="firstLine">
                            <div className="introduction">
                                <div className="welcome">Welcome, {authCheckData.user.firstname}!</div>
                                <div className="date">{moment(new Date()).format('dddd, LL')}</div>
                            </div>
                            <div onClick={this.continue} className="search">{hasLast? "Continue with your last search" : "Start a new search"}<img src="/images/Back.svg" alt="search arrow" /></div>
                        </div>
                        <div className="recentItem">Recent items</div>
                        <div className="listItems">
                            <RecentList name="Saved searches" list={savedSearches} history={history} searchableOptions={searchableOptions}/>
                            {window.plans && <RecentList name="Plans" list={planList} countryList={countryList} />}
                            <RecentList name="Campaigns" list={campaignList} countryList={countryList} />
                            <RecentList name="Posts" list={campaignPost}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        lookupFilters: state.filter.lookupFilters,
    }
}

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({
        getData
    }, dispatch),
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomePage));
