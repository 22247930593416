import React from 'react'

import Format from '../../../modules/utils/Format'
import './media.scss'

// props
// - mediaArticle

export default class MediaArticle extends React.Component {
    constructor (props, context) {
        super(props, context);
    }

    render() {
        let article = this.props.mediaArticle;
        return (
            <div className="articleContainer">
                <div className="article">
                    <div className="image">
                        <img src={article.screenshotUrl}/>
                    </div>
                    <div className="mediaSeparatorVertical"/>
                    <div>
                        <div className="title">
                            { article.title}
                        </div>
                        <div className="mediaPiecesSeparator"/>
                        <div className="articleDescription">
                            { article.description }
                        </div>
                        <div className="mediaPiecesSeparator"/>
                        <div className="url">
                            <a href={Format.webAddress(article.mediaUrl)} target="_blank">{article.mediaUrl}</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}