import React from 'react'
import cx from 'classnames';
import './toolTip.scss'
// props :
// - tooltipText

export default class Tooltip extends React.Component {

    constructor (props, context) {
        super(props, context)
    }

    render () {
        const { image, lessWidth, hoverImage } = this.props
        return (
            <div className={cx("toolTip", lessWidth && "lessWidth", hoverImage && "hoverMode")} id={this.props.tooltipText}>
                <img className="originalImage" src={image || "/images/tooltip.svg"} alt="tooltip" />
                {hoverImage && <img className="hoverImage" src={hoverImage} alt="tooltip" />}
            </div>
        );
    }
}
