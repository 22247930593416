import React from 'react'

import TopNavigation from '../../shared/topNavigation/TopNavigation'
import ApiError from '../../shared/apiError/ApiError'
import './influencerDetails.scss'
import Profile from './influencerProfileBU/Profile'
import CreatorProfile from '../../influencerProfile/CreatorProfile';


import Api from '../../../modules/Api'
import {connect} from "react-redux";
import { matchPath } from 'react-router';
import ProfileHelper from '../../../modules/utils/ProfileHelper'
import { TALENT_MANAGER_STR } from '../../../constants/authorities'

class InfluencerDetailPage extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.showApiError = this.showApiError.bind(this);

        this.state = {
            profile : null,
            showApiError : false,
            apiError: null,
        }
    }

    showApiError(error) {
        this.setState({
            showApiError: true,
            apiError : error
        })
    }

    componentDidMount() {
        const isRouteToPublicInfluencerPage = matchPath(this.props.location.pathname, {
            path: '/publicInfluencerDetailsPage/:influencerUid/:networkType',
        });

        if (this.props.authCheckData && this.props.authCheckData.user && this.props.authCheckData.user.role && 
            this.props.authCheckData.user.role === TALENT_MANAGER_STR) {
            Api.getTMuserInfo(this.props.match.params.influencerId).then(profile=> {
                this.setState({
                    profile
                })
            }).catch((error) => {
                this.showApiError(error);
            });
        } else {
            if (Boolean(isRouteToPublicInfluencerPage)) {
                Api.getPublicInfluencerDetails(
                    this.props.match.params.influencerUid,
                    this.props.match.params.networkType,
                )
                    .then((profile) => {
                        this.setState({ profile });
                    })
                    .catch((error) => {
                        this.showApiError(error);
                    });
            } else {
                ProfileHelper.getSpecifiedUserProfile(this.props.match.params.influencerId).then(profile=> {
                    // strictly temp
                    // for (let net of profile.userNetworks) {
                    //     net.networkReach = 200000;
                    // }
                    this.setState({
                        profile
                    })
                }).catch((error) => {
                    this.showApiError(error);
                });
            }
        }

    }

    render() {
        const isRouteToPublicInfluencerPage = matchPath(this.props.location.pathname, {
            path: '/publicInfluencerDetailsPage/:influencerUid/:networkType',
        });

        const { loggedInUserProfile, location: {search} } = this.props;
        const {profile} = this.state;
        const tag = search && search.length > 0 ? search.slice(1).split("&")[0] : undefined;
        let profileTab = tag;
        if(search.slice(1).split("&")[1] === "vetting") {
            profileTab = 'Vetting';
        } else {
            profileTab = 'Profile';
        }
        
        return (
            <div className="influencerDetails influencerProfileBU">
                <TopNavigation
                    current={`${loggedInUserProfile && loggedInUserProfile.role && loggedInUserProfile.role === TALENT_MANAGER_STR ? 'talentManagement' : 'influencers'}`}
                />
                <ApiError show={this.state.showApiError} error={this.state.apiError}
                    cancelFunction={()=>this.setState({showApiError:false, apiError: null })}/>
                {profile && this.props.authCheckData && this.props.authCheckData.user && 
                this.props.authCheckData.user.role && this.props.authCheckData.user.role !== TALENT_MANAGER_STR && 
                    <Profile
                        profile={profile}
                        editMode={false}
                        tab={profileTab}
                        origin={tag}
                        isPublicInfluencer={Boolean(isRouteToPublicInfluencerPage)}
                    />
                }
                {this.props.authCheckData && this.props.authCheckData.user && this.props.authCheckData.user.role && 
                this.props.authCheckData.user.role === TALENT_MANAGER_STR && this.state.profile &&
                    <CreatorProfile
                        profile = {this.state.profile}
                        editMode
                        isTalentManager
                    />
                }
            </div>
        )
    }
}


const mapStateToProps= (state) => {
    return{
        loggedInUserProfile: state.global.loggedInUserProfile,
    }
};

export default connect(mapStateToProps, {})(InfluencerDetailPage)
