import Api from '../../../../modules/Api';


export async function competitiveBrandsValidator(brands) {
        const results = await Promise.all(brands.map(async (brand) => {
            const validation = await Api.validateCompetitiveBrand(brand.name);
            return {
                ...brand,
                uid: validation.uid,
                error: !validation.valid,
                valid: validation.valid,
                errorMessage: validation.error
            }
        }))

        return {
            brands: results,
            valid: results.every(brand => brand.valid)
        }
}
