import React from 'react';
import { connect } from 'react-redux';
import _, { get } from 'lodash';
import Select from 'react-select';
import cx from 'classnames';
import moment from 'moment';
import FileSaver from 'file-saver';
import {Motion, spring} from 'react-motion'
import PleaseWaitWhite from '../../../shared/pleaseWait/PleaseWaitWhite';
import ApiError from '../../../shared/apiError/ApiError';
import Api from '../../../../modules/Api';
import { startLoadingLookupTables } from '../../../../store/global/globalActions';
import InfluencerPhoto from '../../../oneInfluencerNew/InfluencerPhoto';
import Format from '../../../../modules/utils/Format';
import EngagementParams from './EngagementParams';
// imported below components have cycle dependencies
import ProfileBasics from './ProfileBasics';
import ProfileMainPanel from './ProfileMainPanel';
import EditableProfileMainPanel from '../../../influencerProfile/ProfileMainPanel';
import SecondaryNavigation from './secondaryNavigation/SecondaryNavigationNew';
import ProfileRates from './ProfileRates';
import HeadingBack from '../HeadingBack';
// =================================================
import ErrorMessageBox from '../../../shared/errorMessageBox/ErrorMessageBox';
import { missingChannelsErrorMessage } from '../../../../store/campaign/influencerSearch/reducer';
import { setShowMissingChannelsError } from '../../../../store/campaign/influencerSearch/actionCreators';
import { AGENT_STR, BRAND_OWNER_STR, SUPER_ADMIN_STR, TALENT_MANAGER_STR } from '../../../../constants/authorities';

import PaginationNew from '../../../shared/pagination/PaginationNew'
import VettingMain from '../../vetting/VettingMain';
import NoConnected from '../../vetting/NoConnected';
import ProfileHelper from '../../../../modules/utils/ProfileHelper';

// const permissions = [AGENT, BRAND_OWNER, SUPER_ADMIN];
const dateOptions = [
    { value:'all', label: 'All'},
    { value:'2020', label: '2020'},
    { value:'2019', label: '2019'},
    { value:'2018', label: '2018'}
];
const maxNameLength = 60;
class Profile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            leftMarginTop: 0,
            currentTab: props.tab ? props.tab : "Profile",
            profile: null,
            changeCount: 1, // used to delegate profile changes to ProfileBasics
            currentLocal: null, // the piece that is getting edited; the rest can't be in local edit mode
            engagements: [],
            totalCount: 0,
            year: 'all',
            isExporting: false,
            pageSize: 10,
            currentPage: 1,
        };
        this.narrowContainer = React.createRef();
    }

    componentDidMount() {
        const { lookupTables, lookupTablesLoading, getLookupTables, profile, loggedInUserProfile } = this.props;

        if (!lookupTablesLoading && !lookupTables) {
            getLookupTables();
        }

        this.setState(EngagementParams.initializeStateFields);
        if (profile && loggedInUserProfile && loggedInUserProfile.role !== TALENT_MANAGER_STR) {
            this.getEngagementList();
        }
    }

    componentDidUpdate(prevProps) {
        const { profile, loggedInUserProfile } = this.props;

        if (!_.isEqual(profile, prevProps.profile) && loggedInUserProfile && loggedInUserProfile.role !== TALENT_MANAGER_STR) {
            this.getEngagementList();
        }
    }

    getEngagementList = () => {
        const { profile } = this.props;
        if (profile && profile.id !== null) {
            Api.getInfluencerEngagement(profile.id, EngagementParams.createParamsForApi(this.state))
                .then(response => {
                    this.setState({ engagements: response.content || [], totalCount: response.totalElements || 0, })
                })
                .catch(err => {
                    this.setState({ engagements: [], totalCount: 0, showApiError: true, apiError: err })
                })
        }
    }

    closeError = () =>
        this.setState({
            showApiError: false,
            apiError: null,
        });

    renderedScrollSummary = () => {
        const { loggedInUserProfile, profile } = this.props;
        const role = get(loggedInUserProfile, 'role');
        let name = `${profile.firstname ? profile.firstname : ''} ${profile.lastname ? profile.lastname : ''}`;
        if (name.length > maxNameLength) {
            name = `${name.substring(0, maxNameLength - 1)  }...`;
        }

        return role && role === TALENT_MANAGER_STR ? (
            <HeadingBack show profile={profile} />
        ) : (
            <div className="influencerName">{name}</div>
        );
    };

    notePageMarks = (pageMarks) => {
        this.setState({
            pageMarks,
        });
    };

    setScrollTop = (newScrollTop) => {
        const stepDuration = 5;
        const steps = 20;
        let stepsWalked = 0;
        const { scrollTop: oldScrollTop } = this.container;
        const nextStep = () => {
            stepsWalked += 1;
            const interimScrollTop = oldScrollTop + (stepsWalked * (newScrollTop - oldScrollTop)) / steps;
            this.container.scrollTop = interimScrollTop;

            if (stepsWalked < steps) {
                setTimeout(nextStep, stepDuration);
            }
        };

        // this.container.scrollTop = newScrollTop;

        nextStep();
        this.delegateScrollChange(newScrollTop);
    };

    resetLeft = () =>
        this.setState({
            leftMarginTop: 0,
        });

    handleContainerScroll = () => {
        this.delegateScrollChange(this.container.scrollTop);
    };

    delegateScrollChange = (newTop) => {
        this.setState({
            showScrollSummary: newTop > 100,
            scrollTop: newTop,
        });
    };

    onChangeTabs = (tab) => {
        this.setState({
            currentTab: tab,
        })
    }

    // NEW
    handleProfileChange = (newProfile) => {
        const { changeCount } = this.state;
        const {
            profile: { id },
            storeProfile,
        } = this.props;

        this.setState({
            profile: newProfile,
            changeCount: changeCount + 1,
        });

        // let windowUrl = newProfile.windowUrl;
        ProfileHelper.saveProfile(newProfile, id).then(
            (result) => {
                // result.windowUrl = windowUrl;
                storeProfile(result);
            },
            (error) => {
                this.setState({
                    showApiError: true,
                    apiError: error,
                });
            },
        );
    };

    handleMainEditChange = (editModeFlag, local) => {
        this.setState({
            currentLocal: editModeFlag ? local : null,
        });
    };

    getScrollTop = () => this.container.scrollTop;

    closeMissingChannelsErrorMessage = () => {
        const { setShowMissingChannelsError } = this.props;

        setShowMissingChannelsError(false);
    }

    exportHistory = () => {
        const { year, isExporting} = this.state;
        const { profile } = this.props;
        if (!isExporting && profile) {
            this.setState({ isExporting: true });
            Api.exportInfluencerEngagements(profile.id, (year && year !== 'all') ? year : '')
                .then(response => {
                    this.setState({ isExporting: false });
                    FileSaver.saveAs(response, `Engagements.csv`);
                })
                .catch(err => {
                    this.setState({ isExporting: false, showApiError: true, apiError: err });
                })

        }
    }

    setDate = (value) => {
        this.setState({ year: value.value }, this.getEngagementList);
    }

    setSortBy = (value) => {
        if (this.state.sortBy === value) {
            this.flipSort(value);
            return;
        }

        this.setState({sortBy: value, currentPage: 1}, this.getEngagementList)
    }

    flipSort = (sortBy) => {
        const newState = {};
        const fieldName = `${sortBy}SortDirection`;
        newState[fieldName] = this.state[fieldName] === 'desc' ? 'asc' : 'desc';
        newState.currentPage = 1;
        this.setState(newState, this.getEngagementList);
    }

    handlePageChange = (newCurrentPage) => {
        this.setState({
            currentPage : newCurrentPage
        }, this.getEngagementList)
    }

    handleCurrentChange = (isCurrent) => {
        this.setState({
            currentOrPast : isCurrent ? 'current' : 'past',
            currentPage : 1
        }, this.getEngagementList);
    }

    handleLimitChange = (limit) => {
        this.setState({
            pageSize: limit,
            currentPage : 1
        }, this.getEngagementList);
    }

    handleSearchChange = (search) => {
        this.setState({
            searchKey: search,
            currentPage : 1
        }, this.getEngagementList);
    }

    getProfileTabs = () => {
        const { profile, isPublicInfluencer } = this.props;
        const instagramNetwork = profile.userNetworks.find(un => un.networkType === 'instagram');

        const isVettingDisabled = profile.type === 'PUBLIC_SOCIAL_USER' && !instagramNetwork;

        if (isPublicInfluencer && !instagramNetwork) return ['Profile'];

        if (isPublicInfluencer) return ['Profile', 'Vetting'];

        if (isVettingDisabled) return ['Profile', 'History'];

        return ['Profile', 'Vetting', 'History'];
    }

    render() {
        const {
            loggedInUserProfile,
            lookupTables,
            profile,
            showMissingChannelsError,
            isPublicInfluencer
        } = this.props;
        const { apiError,
            currentLocal,
            showApiError,
            showScrollSummary,
            currentTab,
            engagements,
            brandSortDirection,
            campaignSortDirection,
            assignmentSortDirection,
            year,
            paymentSortDirection,
            sortBy,
            dateSortDirection,
            totalCount,
            pageSize,
            currentPage,
        } = this.state;
        if (!lookupTables || !profile) {
            return <PleaseWaitWhite show={!lookupTables} />;
        }
        const permission = loggedInUserProfile &&
            (loggedInUserProfile.role === AGENT_STR || loggedInUserProfile.role === SUPER_ADMIN_STR || loggedInUserProfile.role === BRAND_OWNER_STR);

        return (
            <div className="profile">
                <ApiError show={showApiError} error={apiError} cancelFunction={this.closeError} />
                <div className="container">
                    <ErrorMessageBox
                        show={showMissingChannelsError}
                        message={missingChannelsErrorMessage}
                        closeFunction={this.closeMissingChannelsErrorMessage}
                    />
                    <SecondaryNavigation
                        permission={permission}
                        inviteProfile={profile}
                        disableAddToCampaign={(loggedInUserProfile && loggedInUserProfile.role === TALENT_MANAGER_STR) || (profile && profile.type === 'PUBLIC_SOCIAL_USER')}
                        disableContact={profile && profile.type === 'PUBLIC_SOCIAL_USER'}
                    >
                        {this.renderedScrollSummary()}
                    </SecondaryNavigation>
                    {loggedInUserProfile && loggedInUserProfile.role !== TALENT_MANAGER_STR &&
                    <div className="profileTabs">
                        <div className="tabsContainer">
                            <div className="tabsWrapper">
                                {this.getProfileTabs().map(tab => {
                                    return (
                                        <div
                                            onClick={() => this.onChangeTabs(tab)}
                                            className={`tab ${currentTab === tab && 'active'}`}
                                            role='button'
                                        >
                                            {tab}
                                        </div>
                                    )
                                })}
                            </div>
                            { (profile && profile.type === "PUBLIC_SOCIAL_USER" ) || isPublicInfluencer &&
                                <div className="noConnected">
                                    <NoConnected/>
                                </div>
                            }
                        </div>
                    </div>}
                    <div
                        className="narrowContainer"
                        style={{
                            minWidth: 0,
                        }}
                        ref={this.narrowContainer}
                    >
                        {currentTab === 'Profile' && (
                            <>
                                <div
                                    className="leftSection"
                                    style={
                                        loggedInUserProfile && loggedInUserProfile.role === TALENT_MANAGER_STR
                                            ? { marginTop: '15px' }
                                            : !showScrollSummary
                                                ? {}
                                                : { marginTop: '15px' }
                                    }
                                >
                                    {lookupTables && (
                                        <div className="basicSection">
                                            {loggedInUserProfile && loggedInUserProfile.role === TALENT_MANAGER_STR ? (
                                                <ProfileBasics show profile={profile} />
                                            ) : (
                                                <ProfileBasics profile={profile} show />
                                            )}
                                        </div>
                                    )}
                                    {profile.id !== null && (
                                        <div className="rateSection">
                                            <ProfileRates profile={profile} />
                                        </div>
                                    )}
                                </div>
                                <div
                                    ref={(elem) => {
                                        this.container = elem;
                                    }}
                                    className={`rightSection ${loggedInUserProfile && loggedInUserProfile.role === TALENT_MANAGER_STR && 'editable-right'}`}
                                    onScroll={this.handleContainerScroll}
                                >
                                    <div
                                        ref={(elem) => {
                                            this.right = elem;
                                        }}
                                        id="mainPanelScroll"
                                    >
                                        {lookupTables && (
                                            <>
                                                {loggedInUserProfile && loggedInUserProfile.role === TALENT_MANAGER_STR ? (
                                                    <div className={cx("narrowContainer", {talentMangaer: true})}>
                                                        <div>
                                                            <EditableProfileMainPanel
                                                                className="profileMainPanel-editable"
                                                                editMode
                                                                currentLocal={currentLocal}
                                                                isTalentManager={loggedInUserProfile && loggedInUserProfile.role === TALENT_MANAGER_STR}
                                                                profile={profile}
                                                                changeFunction={this.handleProfileChange}
                                                                handleLocalEditChange={this.handleMainEditChange}
                                                                delegatePageMarksFunction={this.notePageMarks}
                                                                setScrollTop={this.setScrollTop}
                                                                getScrollTop={this.getScrollTop}
                                                            />
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <ProfileMainPanel editMode profile={profile} isPublicInfluencer={isPublicInfluencer} />
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                        {currentTab === "Vetting" && (
                            <div className="vettingContainer">
                                <VettingMain
                                    profile={profile}
                                    parentContainer={this.narrowContainer}
                                />
                            </div>
                        )}
                        {currentTab === 'History' && (
                            <div className="historyContainer">
                                <div className="historyHeader">
                                    <div>Creator’s engagement available to agents only</div>
                                    <div>
                                        <div className="exportBtn" onClick={this.exportHistory} role='button'>
                                            <img src="/images/ic-export.svg" alt='export' />
                                            Export
                                        </div>
                                        <div className="selectContainer">
                                            <Select className="select"
                                                clearable={false}
                                                searchable={false}
                                                value={year}
                                                single
                                                // placeholder="Page size"
                                                options={dateOptions}
                                                onChange={this.setDate}
                                            />
                                            <div className="label">Year:</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="historyTableContainer">
                                    <div className="historyTableHeader">
                                        <div className="name" onClick={() => this.setSortBy('brand')} role='button'>
                                            <span>Name</span>
                                            <img src="/images/ic_down14x14.svg" className={cx({ up: brandSortDirection === 'desc', no: this.state.sortBy !== 'brand' })} alt="sortbrand"/>
                                        </div>
                                        <div className="campaign" onClick={() => this.setSortBy('campaign')} role='button'>
                                            <span>Campaign</span>
                                            <img src="/images/ic_down14x14.svg" className={cx({ up: campaignSortDirection === 'desc', no: this.state.sortBy !== 'campaign' })} alt="sortcampaign"/>
                                        </div>
                                        <div className="assignment" onClick={() => this.setSortBy('assignment')} role='button'>
                                            <span>Assignment</span>
                                            <img src="/images/ic_down14x14.svg" className={cx({ up: assignmentSortDirection === 'desc', no: this.state.sortBy !== 'assignment' })} alt="sortassignment"/>
                                        </div>
                                        <div className="type">
                                            <span>Type</span>
                                        </div>
                                        <div className="payment" onClick={() => this.setSortBy('payment')} role='button'>
                                            <span>Payment</span>
                                            <img src="/images/ic_down14x14.svg" className={cx({ up: paymentSortDirection === 'desc', no: sortBy !== 'payment' })} alt='payment'/>
                                        </div>
                                        <div className="date" onClick={() => this.setSortBy('date')} role='button'>
                                            <span>Completed</span>
                                            <img src="/images/ic_down14x14.svg" className={cx({ up: dateSortDirection === 'desc', no: sortBy !== 'date' })} alt='completed'/>
                                        </div>
                                    </div>
                                    <div className="listPanel" ref={e => this.container = e}>
                                        <div className="contentDiv">
                                            {
                                                (Array.isArray(engagements) && engagements.length > 0)
                                                    ? <Motion defaultStyle={{ x: 20, y: 0 }} style={{ x: spring(0), y: spring(1) }}>
                                                        {({ x, y }) =>
                                                            <div style={{
                                                                opacity: `${y}`,
                                                                WebkitTransform: `translate3d(0,${x}px, 0)`,
                                                                transform: `translate3d(0,${x}px, 0)`, transition: 'none'
                                                            }}>
                                                                {engagements.map((engagement, index) =>
                                                                    (<div key={index} className="engagementCard">
                                                                        <div className="brandName">
                                                                            <InfluencerPhoto url={engagement.brandImage} />
                                                                            {engagement.brandName}
                                                                        </div>
                                                                        <div>{engagement.campaignName}</div>
                                                                        <div>{engagement.assignmentName}</div>
                                                                        <div className="type">{engagement.typeAsString}</div>
                                                                        <div>{`$${Format.expressInK(engagement.totalRate, 2)}`}</div>
                                                                        <div>{moment(engagement.date).format("MMM DD")}</div>
                                                                    </div>)
                                                                )}
                                                                <PaginationNew
                                                                    total={totalCount}
                                                                    limit={pageSize}
                                                                    current={currentPage}
                                                                    parentContainer={this.container}
                                                                    handlePageChange={this.handlePageChange}
                                                                    limitChangeFunction={this.handleLimitChange}
                                                                />
                                                            </div>
                                                        }
                                                    </Motion>
                                                    :
                                                    <div style={{ marginTop: '20px' }}>
                                                        No past engagement.
                                                    </div>
                                            }
                                            <div style={{ height: 30 }} />
                                        </div>
                                    </div>

                                </div>

                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    getLookupTables: () => dispatch(startLoadingLookupTables()),
    setShowMissingChannelsError: (show) => dispatch(setShowMissingChannelsError(show)),
});

const mapStateToProps = (state) => ({
    loggedInUserProfile: state.global.loggedInUserProfile,
    lookupTablesLoading: state.global.lookupTablesLoading,
    lookupTables: state.global.lookupTables,
    showMissingChannelsError: state.campaignReducer.influencerSearch.showMissingChannelsError,
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
