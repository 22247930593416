import React from 'react'
import {withRouter} from "react-router";
import InfluencerPhoto from '../../oneInfluencerNew/InfluencerPhoto'

const maxNameLength = 60;
class ScrollSummary extends React.Component {
    constructor( props,context) {
        super(props.context);

        this.state = {};
    }

    goBack = () => {
        this.props.history.push('/talentManagement');
    };

    render() {
        if (!this.props.profile) {
            return null;
        }
        const {profile} = this.props;
        let name = `${this.props.profile.firstname  } ${  this.props.profile.lastname}`;
        if (name.length > maxNameLength) {
            name = `${name.substring(0, maxNameLength - 1)  }...`;
        }

        return (
            <div className="scrollSummary heading-back">
                <div className="heading-container">
                    <img className="back" src="/images/ic-long-back.svg" onClick={this.goBack} alt="" className="back" />
                    <div>
                        <InfluencerPhoto url={profile.pictureUrl} firstName={profile?.firstname} lastName={profile?.lastname} />
                    </div>
                    <div className="heading-info">
                        <div className="name">{name}</div>
                        <div className="separator">|</div>
                        <div className="location">Profile</div>
                    </div>
                </div>
            </div>)
    }
}

export default withRouter(ScrollSummary);