import React, { useEffect, useMemo, useRef, useState } from 'react';
import Select from 'react-select';
import * as S from './CompetitiveBrandsSelector.style'
import CloseIcon from '../../../../../../../../assets/icons/CloseIcon';


const DEFAULT_INFO_TEXT = 'Compare up to 10 competing brands'

const CompetitiveBrandsSelector = ({
    style,
    type,
    error,
    brands,
    onChange
}) => {

    const [handleInput, setHandleInput] = useState("")
    const inputRef = useRef();

    // converts input to pill
    const onHandleSubmit = async (ev) => {
        ev.preventDefault()

        const trimmedInput = handleInput.trim();

        if(!!brands.find(b => b.name === trimmedInput)) return;

        if(trimmedInput) {
            onChange([...brands, {
                name: trimmedInput,
                error: false,
                errorMessage: null,
                valid: undefined,
                uid: null
            }])
            setHandleInput("")
        }
    }

    // remove brand
    const onHandleRemove = (name) => (ev) => {
        ev.stopPropagation();
        onChange(brands.filter(b => b.name !== name))
    }

    // focus input on container click
    const onWrapperClick = () => { // () => void
        if(inputRef && inputRef.current) inputRef.current.focus()
    }

    const hasErrors = useMemo(() => { // boolean
        return brands.some(brand => brand.error)
    }, [brands])

    const errorMessage = useMemo(() => { // string | false
        const notValidHandles = brands.filter(b => b.error).map(b => b.name);
        if(notValidHandles.length) {
            return `${notValidHandles.join(', ')} does not exist or has less than 1000 followers. Please ensure the competitive brand entered matches the social handle exactly as they appear in Instagram.`
        }
        return false
    }, [brands])

    return (
        <>
            <div className={style.inputWrapper}>
                <label className={style.inputLabel}>Competitive Brands (Instagram social handles)</label>

                <S.InputWrapper onSubmit={onHandleSubmit} onClick={onWrapperClick} error={hasErrors}>
                    {brands.map(h => (
                        <S.Tag.Wrapper error={h.error}>
                            <S.Tag.Text>{h.name}</S.Tag.Text>
                            <S.Tag.CloseButton type="button" onClick={onHandleRemove(h.name)}>
                                <CloseIcon />
                            </S.Tag.CloseButton>
                        </S.Tag.Wrapper>
                    ))}
                    {(brands.length < 10) && <S.Input
                        type='text'
                        value={handleInput}
                        onChange={(e) => setHandleInput(e.target.value)}
                        ref={inputRef}
                        onBlur={onHandleSubmit}
                    />}
                </S.InputWrapper>

                <S.InfoMessage error={hasErrors}>
                    {errorMessage ? errorMessage : DEFAULT_INFO_TEXT}
                </S.InfoMessage>

                <div className={style.warningMessage}>
                    {error && (
                        <span className={style.warning}>{error}</span>
                    )}
                </div>
            </div>
        </>
    )
}

export default CompetitiveBrandsSelector
