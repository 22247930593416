import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import TopNavigation from '../shared/topNavigation/TopNavigation';
import './Playbook.scss';
import ProfileHelper from '../../modules/utils/ProfileHelper';
import { TALENT_MANAGER_STR, INFLUENCER_STR } from '../../constants/authorities';

const Playbook = () => {
    const [addExtraTop, setAddExtraTop] = useState(false);
    useEffect(() => {
        ProfileHelper.getCurrentLoginUserProfile().then((profile) => {
            if (
                profile &&
                (profile.role === TALENT_MANAGER_STR || profile.role === INFLUENCER_STR) &&
                !profile.accountVerified
            ) {
                setAddExtraTop(true);
            }
        });
    }, []);

    return (
        <div className="playbook">
            <TopNavigation current="support" />
            <div className="opportunity">
                <div className={cx('opportunityContainer extra', { moreExtra: addExtraTop })}>
                    <div className="contentDiv">
                        <div className="supportPage">
                            <div className="spContent">
                                <h2>Playbook</h2>
                                <a href="/playbook/CREATOR-SUBMITTING-DRAFTS-AND-PUBLISHED-CONTENT-FEB-2021-1.pdf" target="_blank" rel="noopener noreferrer">
                                Creator: Submitting Drafts & Published Posts
                                </a>
                                <a href="/playbook/CREATOR-SETTING-TALENT-MANAGER-PERMISSIONS.pdf" target="_blank" rel="noopener noreferrer">
                                Creator: Providing Permissions to Talent Manager
                                </a>
                                <a href="/playbook/CREATOR-–-GETTING-STARTED-1.pdf" target="_blank" rel="noopener noreferrer">
                                Creator: Getting Started
                                </a>
                                <a href="/playbook/TALENT-MANAGER-WORKFLOW-.pdf" target="_blank" rel="noopener noreferrer">
                                Talent Manager Workflow
                                </a>
                                <a href="/playbook/BRAND-REVIEWING-DRAFTS-AND-PUBLISHED-CONTENT-1.pdf" target="_blank" rel="noopener noreferrer">
                                Reviewing Drafts and Published Content
                                </a>
                                <a href="/playbook/BRAND-INVITES-AND-NEGOTIATION.pdf" target="_blank" rel="noopener noreferrer">
                                Recruiting Creators & Negotiation
                                </a>
                                <a href="/playbook/BRAND-CAMPAIGN-AND-ASSIGNMENT-CREATION-1.pdf" target="_blank" rel="noopener noreferrer">
                                Creating Campaigns & Assignments
                                </a>
                                <a href="/playbook/KoalifyedOnboardingDeckTraining.pdf" target="_blank" rel="noopener noreferrer">
                                Brand Onboarding Deck
                                </a>
                                <a href="/playbook/Campaign-Planning-Forecasting.pdf" target="_blank" rel="noopener noreferrer">
                                Campaign Planning & Forecasting
                                </a>
                                <a href="/playbook/Competitive-Brand-Analysis.pdf" target="_blank" rel="noopener noreferrer">
                                Campaign Competitive Analysis
                                </a>
                                <a href="/playbook/Campaign-Adding-Public-Creators-to-Assignment.pdf" target="_blank" rel="noopener noreferrer">
                                Campaign – Adding Public Creators to Assignment
                                </a>
                                <a href="/playbook/Campaign-Analytics.pdf" target="_blank" rel="noopener noreferrer">
                                Campaign Analytics
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Playbook;
