import React from 'react';
import cx from 'classnames';
import NumberInput from '../NumberInput/NumberInput';
import './SelectedOptionRow.scss';

const SelectedOptionRow = ({ onRemove, label, onBlur, multipleSelect, maxValue, minValue, weighted, initialValue }) => {
    return (
        <div className="selected-option-row">
            <div className="itemContainer">
                {multipleSelect && (
                    <>
                        <div onClick={onRemove} className="filterBar-closeIcon">
                            +
                        </div>
                        <div className="label">{label}</div>
                    </>
                )}

                {weighted && (
                    <div className={cx('number-input-part', { 'full-width': !multipleSelect })}>
                        <NumberInput
                            className="filter-menu-input"
                            onBlur={onBlur}
                            placeholder="%"
                            maxValue={maxValue}
                            minValue={minValue}
                            isWeighted={weighted}
                            initialValue={initialValue}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default SelectedOptionRow;
