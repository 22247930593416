import React from 'react'
import './influencerSettings.scss'
import cx from 'classnames';
import TopNavigation from '../../shared/topNavigation/TopNavigation'
import ApiError from "../../shared/apiError/ApiError";
import Shipping from './Shipping'
import SettingsMenu from './SettingsMenu'
import PasswordChange from './PasswordChange';
import ProfileHelper from '../../../modules/utils/ProfileHelper';
import {PASSWORD, SHIPPING}  from './menuPoints';
import { TALENT_MANAGER_STR, INFLUENCER_STR } from '../../../constants/authorities';

export default class InfluencerSettings extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            profile : null,
            menuSelection : -1,
            apiError: null,
        }
    }

    menuSelection = (selection) => {
        this.setState({menuSelection: selection})
    }

    componentDidMount() {
        // todo : delegate profile retrieval to redux store
        this.setState({
            waiting: true
        })
        const self = this;
        ProfileHelper.getCurrentLoginUserProfile(true)
            .then((profile) => {
                self.setState({
                    profile,
                    waiting: false,
                })
            })
            .catch((error) => {
                self.setState({
                    apiError: error,
                    waiting: false,
                });
            });
    }

    render() {
        const { apiError, profile, pseInfo, menuSelection } = this.state;
        if (!profile) {
            if (apiError) {
                return (
                    <ApiError show={!!apiError} error={apiError} cancelFunction={() => this.setState({apiError: null})} />
                );
            }
            return null;
        }
        const addExtraTop = profile && (profile.role === TALENT_MANAGER_STR || profile.role === INFLUENCER_STR) && !profile.accountVerified;
        return (<div className="influencerSettings">
            <TopNavigation/>
            <div className={cx("topBlue", {extraTopBlue: addExtraTop})}>
                <div>Account Settings</div>
            </div>
            <div className={cx("bigContainer", {extra: addExtraTop})} style={{overflow:'auto'}} ref={(e) => {
                        this.scrollContainer = e
                }}>
                <div>
                    <SettingsMenu
                        selectionFunction = {this.menuSelection}
                    />
                    <div className="content">
                        { menuSelection == SHIPPING &&
                            <Shipping profile = {profile} onUpdate={profile => this.setState({profile})} scrollContainer={this.scrollContainer}/>
                        }
                        { menuSelection == PASSWORD &&
                            <PasswordChange profile = {profile}/>
                        }
                    </div>
                </div>
            </div>
        </div>)
    }
}
