import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import './addCreatorsButton.scss';
import ImportFromBase from './ImportFromBase';
import FavoriteDropDown from './FavoriteDropdown';


const selections = ['Favorite lists', 'New search'];

const AddCreatorsButton = ({
    planId,
    plansData,
    onImport = () => null,
    history
}) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [showFavorite, setShowFavorite] = useState(false);

    useEffect(() => {
        window.addEventListener('click', handler);
        return () => {
            window.removeEventListener('click', handler);
        }
    }, [])

    const handler = () => {
        setShowDropdown(false);
        setShowFavorite(false);
    }

    const handleAddCreators = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setShowDropdown(!showDropdown);
    }

    const handleSelection = (e, i) => {
        e.stopPropagation();
        e.preventDefault();
        if (i === 0) {
            setShowFavorite(true);
        } else {
            setShowDropdown(false);
            history.push({
                pathname: `/influencers/plan/${planId}`,
                state: {
                    plansData
                }
            });
        }
    }

    const toImport = (e) => {
        setShowFavorite(false);
        setShowDropdown(false);
        onImport(e);
    }

    return (
        <div className='addCreatorsButtonContainer' onClick={handleAddCreators} role='button'>
            <img src='/images/ic-add-w.svg' alt='addcreator' />
            Creators
            {showDropdown && <div className='dropdown'>
                {selections.map((s, i) => {
                    return (
                        <div
                            className='selection'
                            key={`dropdown-${i}`}
                            onClick={(e) => handleSelection(e, i)}
                            role='button'
                        >
                            {s}
                        </div>
                    )
                })}
            </div>}
            {showFavorite && <div className='importContainer'>
                <ImportFromBase
                    onBack={() => setShowFavorite(false)}
                    onCancel={() => setShowFavorite(false)}
                    onImport={toImport}
                    height={48}
                    Dropdown={FavoriteDropDown}
                />
            </div>}
        </div>
    );
}

export default withRouter(AddCreatorsButton);