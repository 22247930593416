import {LOOKUP_TABLES_LOADING, LOOKUP_TABLES_LOADED, STORE_PROFILE, STORE_TENANTS, RESET_TENANTS, ADD_INVITATIONS} from "./globalActionTypes";

export default function globalReducer(state = {}, action) {
    switch (action.type) {
        case LOOKUP_TABLES_LOADING : {
            return Object.assign({}, state, {
                lookupTablesLoading : action.flag
            })
        }
        case LOOKUP_TABLES_LOADED : {
            let lookupTables = null;
            if (action.summary ==='Y') {
                lookupTables = {};
                for (let ix in action.tables) {
                    lookupTables[ix] = action.tables[ix];
                    /*if (ix !== 'yesno') {
                        lookupTables[ix].sort(
                            (a, b) => {
                                if (ix === 'topic') {

                                }
                                if (a.label.toLowerCase() === 'other' || a.label > b.label) {
                                    return 1;
                                }
                                if (b.label.toLowerCase() === 'other' || b.label > a.label) {
                                    return -1;
                                }
                                return 0;
                            }
                        )
                    }*/
                }
            }
            return Object.assign({}, state, {
                lookupTablesLoading : false,
                lookupTables : lookupTables
            })
        }
        case STORE_PROFILE : {
            return Object.assign({}, state, {
                loggedInUserProfile : action.profile,
                profilePictureVersion : (state.profilePictureVersion + 1)
            })
        }
        case STORE_TENANTS : {
            return Object.assign({}, state, {
                tenantList: action.tenantList,
            })
        }
        case RESET_TENANTS: {
            return Object.assign({}, state, {
                tenantList: null,
            })
        }
        case ADD_INVITATIONS : {
            // incpming invitations object :
            // - invites
            // - campaignId
            // - campaignName
            // - assignmentId
            // - assignmentName
            // stored :
            //  - userInvitations  <userId> => [ campaignName, assignmentName ]
            let userInvitations = {};
            if (state.userInvitations) {
                for (let i in state.userInvitations) {
                    userInvitations[i] = state.userInvitations[i];
                }
            }
            for (let invite of action.invitations.invites) {
                let key = invite.uid;
                let list = userInvitations[key] || [];
                list.push({
                    campaignId : action.invitations.assignment.campaignId,
                    assignmentId : action.invitations.assignment.assignmentId,
                    campaignTitle : action.invitations.assignment.campaignTitle,
                    assignmentTitle : action.invitations.assignment.assignmentTitle
                })
                userInvitations[key] = list;
            }
            return Object.assign({}, state, {
                userInvitations : userInvitations
            })
        }
        default:
            return state;
    }
}