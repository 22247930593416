import React from 'react'
import './mediaKit.scss'

import EditableMediaArticle from './EditableMediaArticle'

const newArticleId = -33;
const MAX_NUMBER = 8;

// props
// - profile
// - editMode
// - handleLocalEditChange
// - disableEdit

export default class MediaKit extends React.Component {
    constructor(props, context) {
        super(props.context);
        this.addArticle = this.addArticle.bind(this);
        this.handleLocalEditChange = this.handleLocalEditChange.bind(this);
        this.deleteArticle = this.deleteArticle.bind(this);
        this.mediaChange = this.mediaChange.bind(this);

        this.state = {
            articleEdited: null,
            imageVersion: Math.round(Date.now()/(5*1000))
        }
    }

    mediaChange() {
        const kits = this.props.profile.mediaKits;
        this.setState({
            mediaKits: kits
        })
    }

    deleteArticle() {
        // just making sure that the rest of the articles can be edited again
        this.setState({
            articleEdited: null
        })
    }

    handleLocalEditChange(editMode, saveFlag, articleId) {
        this.setState({
            articleEdited: editMode ? articleId : null
        })
        const f = this.props.handleLocalEditChange;
        if (typeof f === 'function') {
            f(editMode, saveFlag);
        }
    }

    addArticle() {
        const newArticle = {
            id: newArticleId,
            title: '',
            blurb: '',
            imageUrl: null,
            url: ''
        }
        this.setState({
            articleEdited: newArticle
        })
    }

    componentDidMount() {
        this.setState({
            mediaKits: this.props.profile.mediaKits
        })
    }

    render() {
        if (!this.state.mediaKits) {
            return null;
        }
        const kits = this.state.mediaKits;
        const { isTalentManager } = this.props;
        const self = this;

        const articles = [];
        for (let i = 0; i < kits.length; i++) {
            const article = kits[i];
            let firstDisplay = false;
            let lastDisplay = false;
            const edited = (article.id === this.state.articleEdited);
            if (!edited && (i === 0 || kits[i - 1].id === this.state.articleEdited)) {
                firstDisplay = true;
            }
            if (!edited && (i === kits.length - 1 || kits[i + 1].id === this.state.articleEdited)) {
                lastDisplay = true;
            }
            const disable = (this.state.articleEdited && this.state.articleEdited !== article.id);

            articles.push(<EditableMediaArticle
                isTalentManager={isTalentManager}
                profile={this.props.profile}
                article={article}
                key={article.id}
                editMode={this.props.editMode}
                disableEdit={disable}
                handleLocalEditChange={this.handleLocalEditChange}
                deleteFunction={this.deleteArticle}
                first={firstDisplay}
                last={lastDisplay}
                changeFunction={this.mediaChange}
                imageVersion={this.state.imageVersion}
            />)
        }
        const article = this.state.articleEdited;

        if (article && article.id === newArticleId) {
            articles.push(<EditableMediaArticle
                isTalentManager={isTalentManager}
                profile={this.props.profile}
                article={article}
                key={article.id}
                editMode={this.props.editMode}
                handleLocalEditChange={this.handleLocalEditChange}
                isNew
                disableEdit={this.props.disableEdit || this.state.articleEdited !== null}
                changeFunction={this.mediaChange}
                imageVersion={this.state.imageVersion}
            />)
        }

        const addButtonStyle = {};
        if (!this.props.editMode) {
            addButtonStyle.display = 'none';
        }
        if (this.state.articleEdited) {
            addButtonStyle.opacity = 0.4;
        }

        return (
            <div className="profileMediaKit">
                {this.props.isTalentManager && !this.props.editMode &&
                    <div className="denied-container">
                        <img src="/images/ic-lock.svg"
                            className="pencil"
                        // style={pencilStyle}
                        />
                        <div>Permission Denied</div>
                    </div>
                }
                <div className="pieceTitle">
                    Media kit
                </div>
                {kits.length === 0 && <div className="noKit">No media kit</div>}
                {articles}
                {this.state.mediaKits.length < MAX_NUMBER && <div
                    className="addNewMedia"
                    onClick={this.addArticle}
                    style={addButtonStyle}
                >
                    <img src="/images/ic-plus-circle-black.svg" />
                    Add media kit
                </div>}

            </div>
        );
    }
}