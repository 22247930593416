import combineEvents from '../../combineEvents';
import { AGENT, BRAND_OWNER, SUPER_ADMIN } from '../../../constants/authorities';
import {
    CLEAR_ALL,
    INFLUENCERS_LOADING,
    SET_API_ERROR,
    SET_DATA,
    SET_PAGE_LIMIT,
    SET_SEARCH_FILTERS,
    SET_SHOW_DISCOVERY,
    SET_SORT_FILTER,
    SET_ORDER_TYPE,
    SET_PLEASE_SHOW_WAIT,
    SHOW_MISSING_CHANNELS_ERROR,
    SORT_FILTER_CHANGE,
    ORDER_TYPE_CHANGE,
    RESET_DATA,
    INVITE_PAGE_CHANGE,
    CLEAR_CURRENT_PAGE,
    SET_CURRENCY
} from './actions';

// matching display filters
export const bestMatch = { value: 'bestMatch', label: 'Best Match' };
export const nameAZ = { value: 'nameAZ', label: 'Name' };
export const highestTotalReach = { value: 'highestTotalReach', label: 'Total Followers' };
export const engagementRate = { value: 'instagramEngagementRate', label: 'Engagement Rate (IG)' };
export const blogReach = { value: 'blogReach', label: 'Blog Reach' };
export const facebookReach = { value: 'facebookReach', label: 'Facebook Reach' };
export const instagramReach = { value: 'instagramReach', label: 'Instagram Reach' };
export const twitterReach = { value: 'twitterReach', label: 'Twitter Reach' };
export const youtubeReach = { value: 'youtubeReach', label: 'YouTube Reach' };

// Public source type sorts
export const followersPublic = { value: 'followers', label: 'Followers' };
export const engagementPublic = { value: 'engagements', label: 'Engagement' };
export const engagementRatePublic = { value: 'engagementRate', label: 'Engagement Rate' };

// page limit display filters
export const tenPerPage = { value: 10, label: '10 per page' };
export const twentyPerPage = { value: 20, label: '20 per page' };
export const fiftyPerPage = { value: 50, label: '50 per page' };

export const defaultInfluencerPicture = '/images/ic-avatar-blank-d.svg';

export const missingChannelsErrorMessage = `Ooops! None of your creators could be invited.
    Please select creators with required channels!`;

export const initialState = {
    apiError: null,
    currentPage: 1,
    influencersLoading: false,
    influencers: [],
    inviteList: {},
    inviteInProgress: false,
    inappropriateInviteList: {},
    displayFilters: {
        sort: {
            options: [
                bestMatch,
                nameAZ,
                highestTotalReach,
                engagementRate,
                blogReach,
                facebookReach,
                instagramReach,
                twitterReach,
                youtubeReach,
            ],
            active: bestMatch,
        },
        pageLimit: {
            options: [tenPerPage, twentyPerPage, fiftyPerPage],
            active: twentyPerPage,
        },
        sortOrder: {
            active: 'desc',
        },
        currency: "USD",
        symbol: "$"
    },
    missingChannelsError: null,
    searchFilters: {
        type: 'PUBLIC',
        requiredChannels: ['INSTAGRAM'],
        commonFilters: [],
        connectedFilters: [],
        audienceFilters: [],
    },
    showDiscovery: false,
    showInviteSuccess: false,
    showMissingChannelsError: false,
    showPleaseWait: false,
    permissions: [AGENT, BRAND_OWNER, SUPER_ADMIN],
    totalCount: 0,
};

export default combineEvents(
    {
        [CLEAR_ALL]: (state) => ({
            ...state,
            inviteList: {},
            inappropriateInviteList: {},
        }),
        [INVITE_PAGE_CHANGE]: (state) => ({
            ...state,
            inappropriateInviteList: {},
        }),
        [CLEAR_CURRENT_PAGE]: (state, { inviteList }) => ({
            ...state,
            inviteList,
            inappropriateInviteList: {},
        }),
        [INFLUENCERS_LOADING]: (state, { influencersLoading }) => ({
            ...state,
            influencersLoading,
        }),
        [SET_API_ERROR]: (state, { error }) => ({
            ...state,
            apiError: error,
        }),
        [SET_DATA]: (state, { payload }) => ({
            ...state,
            ...payload,
        }),
        [SET_PAGE_LIMIT]: (state, { pageLimit }) => ({
            ...state,
            displayFilters: {
                ...state.displayFilters,
                pageLimit: {
                    ...state.displayFilters.pageLimit,
                    active: pageLimit,
                },
            },
        }),
        [SET_SEARCH_FILTERS]: (state, { newFilters }) => ({
            ...state,
            searchFilters: newFilters,
        }),
        [SET_SHOW_DISCOVERY]: (state, { showDiscovery }) => ({
            ...state,
            showDiscovery,
        }),
        [SET_SORT_FILTER]: (state, { active }) => ({
            ...state,
            displayFilters: {
                ...state.displayFilters,
                sort: {
                    ...state.displayFilters.sort,
                    active,
                },
            },
        }),
        [SET_ORDER_TYPE]: (state, { active }) => ({
            ...state,
            displayFilters: {
                ...state.displayFilters,
                sortOrder: {
                    ...state.displayFilters.sortOrder,
                    active,
                },
            },
        }),
        [SET_PLEASE_SHOW_WAIT]: (state, { value }) => ({
            ...state,
            showPleaseWait: value,
        }),
        [SET_CURRENCY]: (state, { value }) => ({
            ...state,
            displayFilters: {
                ...state.displayFilters,
                currency: value.value,
                symbol: value.symbol,
            },
        }),
        [SHOW_MISSING_CHANNELS_ERROR]: (state, { show }) => ({
            ...state,
            showMissingChannelsError: show,
        }),
        [SORT_FILTER_CHANGE]: (state, { active: { sort } }) => ({
            ...state,
            displayFilters: {
                ...state.displayFilters,
                sort: {
                    ...state.displayFilters.sort,
                    sort,
                },
            },
        }),
        [ORDER_TYPE_CHANGE]: (state, { active }) => ({
            ...state,
            displayFilters: {
                ...state.displayFilters,
                sortOrder: {
                    ...state.displayFilters.sortOrder,
                    active,
                },
            },
        }),
        [RESET_DATA]: (state) => ({
            ...state,
            ...initialState,
            searchFilters: {
                type: 'PUBLIC',
                requiredChannels: ['INSTAGRAM'],
                commonFilters: [],
                connectedFilters: [],
            },
        }),
    },
    initialState,
);
