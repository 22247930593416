import { call, put } from 'redux-saga/effects';
import influencersSaga from './influencersSaga';
import {
    clearAll,
    setData,
    setShowDiscovery,
} from '../actionCreators';

const ageFilterId = 2;
const joinedFilterId = 6;

// This saga handles filters change when one of the splash images is clicked.
export default function* listsSplashFilterChangeSaga({ filter, setSearchPanelFilter }) {
    const { filterId } = filter;

    yield put(clearAll());
    yield put(setShowDiscovery(false));

    if (filterId !== ageFilterId) {
        // due to complex (hardcoded) nature of the project this peace wasn't refactored yet
        // so, unfortunately, we need to call the SearchPanel class method here (as for now)
        // todo. this is not working
        if (setSearchPanelFilter) {
            setSearchPanelFilter(filter);
        }
    }

    if (filterId !== joinedFilterId) {
        yield put(setData({
            searchFilters: [filter],
            currentPage: 1,
        }));

        yield call(influencersSaga);
    }
}
