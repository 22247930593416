import React from 'react'
import _ from 'lodash';
import  './campaignList.scss'
import PendoHelper from '../../../modules/utils/PendoHelper';

// Props
// - startCreationFunction

export default class ListHeader extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.renderedFilter = this.renderedFilter.bind(this);
        this.renderedFilters = this.renderedFilters.bind(this);
        this.handleCreateClick = this.handleCreateClick.bind(this);

        this.state = {
            currentFilter: 'All Campaigns',
            countActive: 6,
            countArchived: 22
        }
    }

    handleCreateClick() {
        const f = this.props.startCreationFunction;
        if (typeof f === 'function') {
            f();
        }
    }

    searchChange = _.debounce((value) => {
        PendoHelper.pendoTrack('Campaign_Search_Query', { value });
        this.props.searchChangeFunction(value);
    }, 500)

    renderedFilter(label) {
        let className="filter";
        if (label === this.state.currentFilter) {
            className += ' active';
        }
        const self = this;
        const clickHandler = function() {
            self.setState({ currentFilter : label})
        }

        return (
            <div className={className} onClick={clickHandler}>
                { label }
            </div>
        )
    }

    renderedFilters() {
        return (
            <div className="filters">
                { this.renderedFilter('All Campaigns') }
            </div>
        )
    }

    render() {
        const { activeTab, changeArchivedTab } = this.props;
        return (
            <div className="listHeaderContainer">
                <div className="listHeader" >
                    <div className="contentDiv">
                        <div className="controlContainer">
                            <div>
                                {this.renderedFilters()}
                            </div>
                            <div className="buttonCell">
                                <div className="listSearch">
                                    <img src="/images/ic-mag.svg" />
                                    <input
                                        defaultValue={this.props.initialValue}
                                        placeholder="Search Campaign"
                                        onChange={(e) => this.searchChange(e.target.value)}
                                    />
                                </div>
                                {
                                    this.props.permission ?
                                        <div className="createButton controlButton" onClick={this.handleCreateClick}>
                                            <img src="/images/ic-plus-circle-w.svg"/>
                                            <span>New Campaign</span>
                                        </div> : ''
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="filter-subheader">
                    <div className="contentDiv">
                        <div className="header">
                            <div className="left-block">
                                <div
                                    onClick={() => changeArchivedTab(false)}
                                    className={`tab ${!activeTab && 'active'}`}
                                >
                                    Active
                                </div>
                                <div
                                    onClick={() => changeArchivedTab(true)}
                                    className={`tab ${activeTab && 'active'}`}
                                >
                                    Archive
                                </div>
                            </div>
                        </div>
                    </div>
                        
                </div>
            </div>
        )
    }
}
