import React, { Component } from 'react';
import Modal from 'react-modal';
import './ConfirmRates.scss';
import Api from '../../../../../modules/Api';
import ApiError from '../../../../shared/apiError/ApiError';
import PleaseWait from '../../../../shared/pleaseWait/PleaseWaitWhite';

const mappings = {
    INSTAGRAM_POST: 'Instagram Post',
    FACEBOOK_POST: 'Facebook Post',
    YOUTUBE: 'YouTube Post',
    TIKTOK_POST: 'TikTok Post',
    BLOG_POST: 'Blog Post',
    TWITTER_POST: 'Twitter Post',
    SOCIAL_SHARE: 'Social Share',
    INSTAGRAM_STORY: 'Instagram Story',
};

export default class ConfirmRates extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpenModal: false,
            assignment: null,
            campaign: null,
            waiting: true,
            showApiError: false,
            apiError: null,
        };
    }

    componentDidMount() {
        this.getCampaignInfo();
    }

    getCampaignInfo = async () => {
        try {
            const result = this.props.campaign
                ? this.props.campaign
                : await Api.getCampaign(Number(this.props.campaignId));
            const assignment = result.assignments.find((a) => a.id === this.props.assignmentId);
            this.setState({
                campaign: result,
                assignment: assignment,
                waiting: false,
            });
        } catch (error) {
            this.setState({
                showApiError: true,
                apiError: error,
                waiting: false,
            });
        }
    };

    openModal = () => {
        this.setState({
            isOpenModal: true,
        });
    };

    closeModal = (cb) => {
        this.setState(
            {
                isOpenModal: false,
            },
            () => {
                if (typeof cb === 'function') {
                    cb();
                }
            },
        );
    };

    closeError = () => {
        this.setState({ showApiError: false, waiting: false });
    };

    render() {
        const { user, rates = {} } = this.props;
        const { isOpenModal, waiting, showApiError, apiError, campaign, assignment } = this.state;

        const totalRate = Object.values(rates).reduce((acc, rateValue) => {
            return acc + rateValue;
        }, 0);
        console.log(rates);
        return (
            <Modal
                closeTimeoutMS={300}
                overlayClassName="confirmRatesOverlay"
                className="confirmRatesModal"
                isOpen={isOpenModal}
            >
                <div className="container">
                    <div className="title">Review and Confirm</div>
                    <div className="content">
                        <PleaseWait show={waiting} />
                        <ApiError show={showApiError} error={apiError} cancelFunction={this.closeError} />
                        {campaign && (
                            <div className="campaign-info">
                                <div className="campaign-info-item">
                                    <strong>Campaign</strong>
                                    <span>{campaign.name}</span>
                                </div>
                                <div className="campaign-info-item">
                                    <strong>Assignment</strong>
                                    <span>{assignment && assignment.name}</span>
                                </div>
                                <div className="campaign-info-item">
                                    <strong>Type</strong>
                                    <span>
                                        {/* {assignment && assignment.types.map((type, index) => `${type + ' '}`)} */}
                                        {Object.keys(rates).map((type, index) => `${mappings[type] + ' '}`)}
                                    </span>
                                </div>
                            </div>
                        )}
                        {user && (
                            <div className="user-info">
                                <div
                                    className="user-info-img"
                                    style={{ backgroundImage: `${'url(' + user.pictureUrl + ')'}` }}
                                ></div>
                                <div className="user-info-holder">
                                    <div className="user-info-row">
                                        <strong className="user-info-name">
                                            {user.firstname} {user.lastname}
                                        </strong>
                                    </div>
                                    <div className="user-info-row">
                                        <div className="user-info-networks multiply">
                                            {rates &&
                                                Object.entries(rates).map(([key, value]) => (
                                                    <div className="user-info-networks-item" key={key}>
                                                        <div className="network-name">{mappings[key]}</div>
                                                        <div className="network-reach">${value}</div>
                                                    </div>
                                                ))}
                                        </div>
                                        <div className="user-info-total-reach">
                                            <div className="total-title">Total proposed cost</div>
                                            <div className="total-reach">${totalRate}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="footer">
                    <span className="close" onClick={this.closeModal}>
                        Cancel
                    </span>
                    <span className="signlink" onClick={() => this.closeModal(this.props.handleOpenContractStatus)}>
                        Sign Contract
                    </span>
                </div>
            </Modal>
        );
    }
}
