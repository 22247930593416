import { call, put, select } from 'redux-saga/effects';
import {
    convertRateTypesIntoChannels,
    getCampaignAndAssignmentIdsFromRoute,
} from '../helpers';
import Api from '../../../modules/Api';
import {
    setAssignmentInfoLoading,
    setData,
    setError,
} from '../actionCreators';
import { setShowDiscovery } from '../../campaign/influencerSearch/actionCreators';

// Later this saga could be made a bit more generic.
// For that purpose we need to add next props:
// * request function
// * selector for response
// * additional params (if assignmentId is not enough) for request function
export default function* assignmentInfoSaga() {
    const {
        assignmentId,
        campaignId,
    } = yield select(({ router }) => getCampaignAndAssignmentIdsFromRoute(router));

    if (!(assignmentId && campaignId)) {
        // if it's the /influencers page - show splash
        yield put(setShowDiscovery(true));

        return;
    }

    yield put(setAssignmentInfoLoading(true));

    try {
        const {
            assignmentFlights,
            name: assignmentName,
            users: alreadyInvitedUsers,
            types = [],
        } = yield call(Api.getAssignment, assignmentId);
        yield put(setData({
            assignmentInfo: {
                assignmentId,
                campaignId,
                searchId: assignmentId,
                assignmentName,
                alreadyInvitedUsers,
                channels: convertRateTypesIntoChannels(types),
            },
        }));
    } catch (error) {
        yield put(setError({
            assignmentInfo: error.message,
        }));
    } finally {
        yield put(setAssignmentInfoLoading(false));
    }
}
