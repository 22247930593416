import combineEvents from '../combineEvents';
import {
    ASSIGNMENT_INFO_LOADING,
    FAVORITES_LIST_LOADING,
    LOOKUP_FILTERS_LOADING,
    LOOKUP_TABLES_LOADING,
    SET_DATA,
    SET_ERROR,
    RESET_DATA,
} from './actions';

export const initialState = {
    assignmentInfo: {
        assignmentInfoLoading: false,
        alreadyInvitedUsers: [],
        channels: [],
        assignmentId: null,
        campaignId: null,
        searchId: null,
    },
    favorites: {
        favoritesListLoading: false,
        favoritesList: [],
        favoriteChangeCount: 1,
    },
    lookup: {
        lookupFilters: {
            filters: [],
            groups: [],
        },
        lookupFiltersLoading: false,
        lookupTables: {},
        lookupTablesLoading: false,
    },
    errors: {
        assignmentInfo: null,
        favoritesList: null,
        lookupFilters: null,
        lookupTables: null,
    },
};

export default combineEvents({
    [ASSIGNMENT_INFO_LOADING]: (state, { assignmentInfoLoading }) => ({
        ...state,
        assignmentInfo: {
            ...state.assignmentInfo,
            assignmentInfoLoading,
        },
    }),
    [FAVORITES_LIST_LOADING]: (state, { favoritesListLoading }) => ({
        ...state,
        favorites: {
            ...state.favorites,
            favoritesListLoading,
        },
    }),
    [LOOKUP_FILTERS_LOADING]: (state, { lookupFiltersLoading }) => ({
        ...state,
        lookup: {
            ...state.lookup,
            lookupFiltersLoading,
        },
    }),
    [LOOKUP_TABLES_LOADING]: (state, { lookupTablesLoading }) => ({
        ...state,
        lookup: {
            ...state.lookup,
            lookupTablesLoading,
        },
    }),
    [SET_DATA]: (state, { payload }) => ({
        ...state,
        ...payload,
    }),
    [SET_ERROR]: (state, { error }) => ({
        ...state,
        errors: {
            ...state.errors,
            ...error,
        },
    }),
    [RESET_DATA]: (state) => ({
        ...state,
        ...initialState,
    }),
}, initialState);
