import React, { useState } from 'react';
import moment from 'moment';
import { FlagIcon } from '../../shared/flagAvatar/FlagAvatar'
import './competitorPosts.scss';

const POST_LIMIT = 5;

export default function CompetitorPosts({ brands }) {
    const [isExpanded, setIsExpanded] = useState(false);
    const isAllVisible = brands?.length > POST_LIMIT;

    return (
        <div className="competitor-posts-wrapper">
            <div className="competitor-posts-panel">
                <div className="competitor-posts-title">
                    <FlagIcon fillColor="#CF2C2C" size={18} />
                    Flagged creator
                </div>

                <div className="competitor-posts-info">This creator has an Instagram post that is part of your competitor brand's campaign.</div>

                <table>
                    <thead>
                        <tr>
                            <th>Competitor</th>
                            <th>Last Post</th>
                        </tr>
                    </thead>
                    <tbody>
                        {brands?.slice(0, isExpanded ? brands.length : POST_LIMIT).map(brand => (
                            <tr>
                                <td>
                                    <div className="competitor-column">
                                        <img src={brand.avatarUrl} className='brand-logo' />
                                        @{brand.handle}
                                    </div>
                                </td>
                                <td>
                                    <div className='brand-last-post'>
                                        {moment(brand.postDate).format('MMM DD, YYYY')}
                                        <a className="brand-link" href={brand.postUrl} target='_blank'>View Post</a>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                {isAllVisible && (
                    <button className="see-mote-btn" onClick={() => setIsExpanded(prev => !prev)}>
                        {isExpanded ? 'Less' : 'See More'}
                        <img src="/images/arrow-down.svg" alt="see more" className={isExpanded ? 'see-less' : ''} />
                    </button>
                )}
            </div>
        </div>
    );
}