import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import DropdownSelectWithLargetData from '../../shared/selectBox/DropdownSelectWithLargetData';
import InputRange from './InputRange';
import './customRangeInputs.scss';

const CustomRangeInputs = ({
    filterValue,
    label,
    item,
    placeholder,
    multi,
    onChange,
    isSingleInput = false,
    isMinOnly = false,
    searchable = true,
    closeOnSelect = true,
    clearable = false,
    currentItems = null
}) => {
    const [addDropdownOptions, setAddDropdownOptions] = useState([]);
    const [availableDropdownOptions, setAvailableDropdownOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [showAddOption, setShowAddOption] = useState(false);
    const [errors, setError] = useState([]);
    useEffect(() => {
        if (filterValue) {
            const options = [];
            if (filterValue.filterOptions && Array.isArray(filterValue.filterOptions)) {
                filterValue.filterOptions.forEach((v) => {
                    const t = { ...v };
                    t.value = v.customValue;
                    options.push(t);
                });
            }
            options.sort((a, b) => {
                if (a.label > b.label) {
                    return 1;
                }
                if (a.label < b.label) {
                    return -1;
                }
                return 0;
            });
            const optionsWithValue = options.filter((o) => {
                if (o.minValue || o.maxValue) {
                    return true
                }
                if (selectedOptions.find(item => item.id === o.id)) {
                    return true
                }
                return false
            });
            const availableOptions = options.filter((o) => {
                return !optionsWithValue.find(item => item.id === o.id)
            });
            setAddDropdownOptions(options);
            setAvailableDropdownOptions(availableOptions);
            setSelectedOptions(optionsWithValue);
        }
    }, [filterValue]);
    useEffect(() => {
        if(currentItems && currentItems.length !== selectedOptions.length) {
            setSelectedOptions(currentItems)
        }
    }, [currentItems?.length])
    useEffect(() => {
        const tempAvailableOptions = addDropdownOptions.filter((e) => {
            const res = selectedOptions.find((s) => s.value === e.value);
            return res === undefined;
        });
        if (!isSingleInput) {
            setAvailableDropdownOptions(tempAvailableOptions);
        }
    }, [addDropdownOptions, selectedOptions]);

    const updateData = (filterOptions, addNew = false) => {
        setSelectedOptions(filterOptions);
        if (onChange && !addNew) {
            const temp = { ...filterValue };
            temp.filterOptions = filterOptions;
            onChange(temp);
        }
    };

    const handleChange = (changedItem) => {
        if (changedItem && changedItem.value) {
            if (!selectedOptions.find((e) => e.value === changedItem.value)) {
                let temp = [];
                if (!isSingleInput) {
                    temp = [...selectedOptions]
                    temp.push(changedItem);
                } else {
                    temp = [changedItem]
                }
                updateData(temp, true);
            }
        }
    };

    const handleRequestClose = () => {
        setShowAddOption(false);
    };

    const handleOnAdd = () => {
        if (!showAddOption && availableDropdownOptions.length === 0) {
            return;
        }
        setShowAddOption(!showAddOption);
    };

    const handleInputChange = (field, range) => {
        const temp = [...selectedOptions];
        const idx = temp.findIndex((e) => e.value === field.value);
        if (idx !== -1 && Array.isArray(range) && range.length === 2) {
            const tempField = temp[idx];
            tempField.minValue = range[0];
            tempField.maxValue = range[1];
            temp.splice(idx, 1, tempField);
            updateData(temp);
        }
    };

    const removeItem = (removedItem) => {
        const temp = selectedOptions.filter((e) => e.value !== removedItem.value);
        updateData(temp);
    };

    const isOnlyMinInputVisible = isSingleInput && selectedOptions[0]?.value === 'gte';
    const isOnlyMaxInputVisible = isSingleInput && selectedOptions[0]?.value === 'lte';
    const range = { from: 0, to: 100 };
    const setErrorFromInput = (err, index) => {
        const newItem = {err, index}
        setError(newItem)
    }
    const displayError = !!(errors.err && errors.err.find((err) => err !== ''));
    return (
        <div className="customRangeInputs">
            <DropdownSelectWithLargetData
                id={item}
                clearable={!!clearable}
                multi={!!multi}
                closeOnSelect={!!closeOnSelect}
                placeholder={isSingleInput ? 'Select' : 'Add multiselect'}
                options={availableDropdownOptions}
                onChange={handleChange}
                searchable={!!searchable}
                requestCloseFunc={handleRequestClose}
                isSingleInput={isSingleInput}
            />
            {/* <div className="option">{label}</div> */}
            {selectedOptions.map((selected, index) => {
                return (
                    <div  key={`${selected.label}_${index}`}>
                    <div className="itemContainer">
                        {!isSingleInput && <>
                            <div onClick={() => removeItem(selected)} className='filterBar-closeIcon'>+</div>
                            <div className="label">{selected.label}</div>
                        </>}
                        <div className={cx("inputContainer", isSingleInput && "wideContainer")}>
                            {/* <div className="label">{selected.label}</div> */}
                            <InputRange
                                isOnlyMaxInputVisible={isOnlyMaxInputVisible}
                                isOnlyMinInputVisible={isOnlyMinInputVisible || isMinOnly}
                                id={`${selected.value}_${index}`}
                                rangeObject={range}
                                unlimitMax={false}
                                options={[selected.minValue || '', selected.maxValue || '']}
                                changeFunction={(r) => handleInputChange(selected, r)}
                                type="percentage"
                                noTo
                                index={index}
                                setError={setErrorFromInput}
                            />
                        </div>
                    </div>
                    {displayError && (
                    <div className="errorContainer">
                        {errors.index === index && errors.err && errors.err.map((err) => (
                            <div key={err}>{err}</div>
                        ))}
                    </div>
                )}
                    </div>
                );
            })}
        </div>
    );
};

export default CustomRangeInputs;
