import React from 'react'
import { withRouter } from 'react-router'
import {connect} from 'react-redux';
import cx from 'classnames'

import classNames from 'classnames';
import Checkbox from '../shared/checkbox/Checbox'
import Format from '../../modules/utils/Format'
import ColorBlockGraph from './ColorBlockGraph'
import Extended from './Extended'
import DisplayTopics from '../shared/displayTopics/DisplayTopics'
import RateIcon from '../shared/rateIcon/RateIcon'
import Actions from './actions/Actions'
import FavoriteLists from './favoriteLists/FavoriteLists';
import InfluencerPhoto from './InfluencerPhoto'
import ModalDialog from '../shared/modalDialog/ModalDialog'
// import './oneInfluencer.scss'
import PermissionsExtended from "./permissionsExtended";
import RatesService from '../../services/ratesService';

class OneInfluencer extends React.Component {

    constructor (props, context) {
        super(props, context);

        this.renderedRate = this.renderedRate.bind(this);
        this.flipExtended = this.flipExtended.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleClickOnName = this.handleClickOnName.bind(this);
        this.showActions = this.showActions.bind(this);
        this.hideActions = this.hideActions.bind(this);
        // this.renderedInvitations = this.renderedInvitations.bind(this);
        this.favoriteButton = this.favoriteButton.bind(this);
        this.favButton = this.favButton.bind(this);

        this.state = {
            showExtended : false,
            showActions : false,
            showFavorites: false,
            heart: false,
            rating:'',
            online:false,
            showDeleteConfirmation: false
        }
    }
    /*
    renderedInvitations() {
        let invitations = null;
        if (this.props.userInvitations) {
            invitations = this.props.userInvitations[this.props.details.uid];
        }

        if (Array.isArray(invitations) && invitations.length > 0) {
            let listText = '';
            for (let invite of invitations) {
                if (listText.length > 0){
                    listText += ', '
                }
                listText += invite.campaignTitle + ' - ' + invite.assignmentTitle
            }
            return (
                <div className="invitations">
                    <b>Invited to&nbsp;&nbsp;</b>
                    { listText }
                </div>
            )
        } else {
            return null;
        }
    } */

    showActions(e) {
        e.stopPropagation();
        e.preventDefault();
        this.setState({
            showActions : !this.state.showActions,
            showFavorites: false
        })
    }

    hideActions() {
        this.setState({
            showActions : false,
            showFavorites: false
        })
    }

    favoriteButton(e){
        e.stopPropagation();
        e.preventDefault();
        this.setState({
            showFavorites: !this.state.showFavorites,
            showActions : false
        })
    }

    favButton(value){
        this.setState({
            heart: value
        });
    }

    flipExtended() {
        this.setState({
            showExtended : !this.state.showExtended
        })
    }

    handleSelect(flag) {
        const f = this.props.selectFunction;
        if (typeof f === 'function') {
            f(this.props.details.uid, flag);
        }
    }

    handleClickOnName() {
        const linkToDetails = `/influencerDetailPage/${ this.props.details.id}`;
        this.props.history.push(linkToDetails);
    }

    renderedRate(rate) {
        const rateDollar = this.props.details.rates[rate];

        return (
            <div className="rateDisplay" key={rate}>
                <RateIcon rateKey={rate} size={25} iconSize={17} padding={4} value={rateDollar}/>
                { (Number(rateDollar)>0)?Format.expressInDollars(rateDollar): ' - '}
            </div>
        )
    }

    setWidth = () => {
        this.setState({boxWidth : this.coreElement.offsetWidth})
    }

    componentDidMount() {
        window.addEventListener('click',this.hideActions)
        window.addEventListener('resize', this.setWidth)
        this.setWidth()
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.hideActions);
        window.removeEventListener('resize', this.setWidth);
    }

    checkHeart(detailsId){
        if (Array.isArray(this.props.favoriteLists)) {
            for (const f of this.props.favoriteLists) {
                if (Array.isArray(f.users)) {
                    if (f.users.indexOf(detailsId) >= 0) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    createRenderedRates = () => {
        const { details } = this.props;
        const ratesService = new RatesService(details);
        const rates = ratesService.filterRatesListByActiveTab();
        const renderedRates = rates.reduce((acc, rate) => {
            const renderedRate = this.renderedRate(rate);
            acc.push(renderedRate);

            return acc;
        }, []);

        return renderedRates;
    };

    renderConfirmationModal = () => {
        return (
            <ModalDialog    show={this.state.showDeleteConfirmation}
                title="Remove creator"
                proceedButtonLabel="YES"
                readyToProceed
                closeButton
                proceedFunction={() => this.props.handleDeleteUser(this.props.details.management.id)}
                cancelFunction={() => this.setState({showDeleteConfirmation: false})}
            >
                <div className="deleteCampaign">
                    <div className="label">Are you sure that you want to remove this creator?</div>
                </div>
            </ModalDialog>
        );
    };

    render () {
        const {details, invited, inviteDisabled, checked, inviteFunction, showPermissions, showExpandIcon, permission} = this.props;
        const index=details.userNetworks.findIndex((item)=>{return item.networkType==='blog'})
        let totalReach = 0;
        let instagramNetwork = null;
        if (Array.isArray(details.userNetworks) && details.userNetworks.length > 0) {
            totalReach = details.userNetworks.map( un =>  Number(un.reach) ).reduce((a,b)=>a+b);
            instagramNetwork = details.userNetworks.find(un => un.networkType === 'instagram');
        }

        const metricStyle = {};
        metricStyle.height = this.state.metricHeight;

        const rates = this.createRenderedRates();

        const isFavorite = this.checkHeart(this.props.details.id, this.props.favoriteData);
        const heart = classNames('favoriteButton', {'heart': isFavorite}, {'open': this.state.showFavorites} );

        let displayIGEngagementRate = false;
        if (instagramNetwork && instagramNetwork.engagementRate) {
            displayIGEngagementRate = true;
        }

        return (
            <div className="oneInfluencer">
                {this.renderConfirmationModal()}
                <div
                    className={this.props.checked?"core checked":"core"}
                    onClick={this.flipExtended}
                    onMouseLeave={this.hideActions}
                    ref={(e)=> {this.coreElement = e} }
                >
                    <div className="coreFields">
                        {/* checkbox cell */}
                        {
                            showExpandIcon ?
                                <div className="expand-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        className={cx({'right':!this.state.showExtended},{'down':this.state.showExtended})}
                                    >
                                        <path fill={this.state.showExtended ? '#4AA129' : "#666666"} fillRule="nonzero" d="M16.46 9.27a.889.889 0 0 1 1.276 0 .938.938 0 0 1 0 1.305l-5.097 5.154a.888.888 0 0 1-1.278 0l-5.096-5.154a.936.936 0 0 1 0-1.305.889.889 0 0 1 1.276 0l4.46 4.227 4.458-4.227z"/>
                                    </svg>
                                </div>
                                :
                                <>
                                    <div className="checkbox"
                                        title={ inviteDisabled ? 'This creator can`t be invited (not enough connections or missing rates' : ''}
                                    >
                                        { !inviteDisabled && permission &&
                                        <Checkbox size={22} changeFunction={this.handleSelect} checked={checked} />
                                        }

                                    </div>
                                    <div className="checkSpacer"/>
                                </>
                        }
                        <div className="infl-info-container">
                            {/* photo cell */}
                            <div className="photo" onClick={this.handleClickOnName}>
                                {details.authenticityRating &&
                                <div className="score">
                                    <img src="/images/ic-score.svg"/>
                                    <span>{Math.round(details.authenticityRating)}</span>
                                </div>
                                }
                                <InfluencerPhoto url={details.pictureUrl} firstName={details?.firstname} lastName={details?.lastname} />
                            </div>

                            {/* cell with name and address */}
                            <div className="nameAndAddress">
                                <div className="upperHalf">
                                    <div className="name" onClick={this.handleClickOnName}>
                                        { `${details.firstname } ${  details.lastname}`}
                                    </div>
                                    {index>-1 && <div className="summary">
                                        <a href={details.userNetworks[index].networkUserId.indexOf("//")>= 0?details.userNetworks[index].networkUserId:`//${details.userNetworks[index].networkUserId}`} target="_blank">
                                            {details.userNetworks[index].networkDetails
                                                ? details.userNetworks[index].networkDetails['blog-name']
                                                : details.userNetworks[index].networkUserId}
                                        </a>
                                    </div>}
                                    <div className="topics">
                                        <DisplayTopics topics={details.topics} max={6} short/>
                                    </div>
                                </div>
                                <div className="location">
                                    <img src="/images/ic-location.svg"/>
                                    <div>{details.city},&nbsp;</div>
                                    <div>{details.state},&nbsp;</div>
                                    <div>&nbsp;{details.country}</div>
                                </div>
                            </div>
                        </div>

                        <div>

                            {/* graph cell */}
                            <div className="graph" ref={(node)=>{this.graphNode = node}}>
                                {/* <div className="metrics">
                                    <div>
                                        <div>Total Followers</div>
                                        <div>{ Format.expressInK(totalReach,2) }</div>
                                    </div>
                                    {displayIGEngagementRate && <div>
                                        <div>IG Engagement Rate</div>
                                        <div className="green">{instagramNetwork.engagementRate * 100}%</div>
                                    </div>}
                                </div> */}
                                <ColorBlockGraph
                                    userNetworks={details.userNetworks}
                                    boxWidth={this.state.boxWidth}
                                />
                            </div>

                            {/* rates */}
                            <div className="rates">
                                {!showPermissions && <div className={heart} onClick={this.favoriteButton}>
                                    <img id="noneHeart" src="/images/ic-heart-g.svg"/>
                                    <img id="Heart" src="/images/ic-heart.svg"/>
                                </div>}
                                <FavoriteLists
                                    show={this.state.showFavorites}
                                    closeFunction={()=>{
                                        this.setState({ showFavorites : false})
                                    }}
                                    userDetails={details}
                                    isOnList={isFavorite}
                                />

                                <div className="moreButton" onClick={this.showActions}>
                                    <img src="/images/ic-more.svg"/>
                                    <Actions profile={details}
                                        removeInfluencer={() => this.setState({showDeleteConfirmation: true})}
                                        permission={permission}
                                        tmAction={showPermissions}  // showPermissions is an attr for talent manager page.
                                        show={this.state.showActions}
                                        inviteFunction={(inviteDisabled || Boolean(invited)) ? null : inviteFunction}
                                    />
                                </div>
                                <div className="top">
                                    <div>
                                        Rates
                                    </div>
                                </div>
                                <div className="table">
                                    {rates}
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* end of coreFIelds */}

                    {/* { this.renderedInvitations() } */}
                    {Boolean(invited) &&
                        <div className="invitations">
                            <span>{invited}</span>
                        </div>
                    }
                </div>

                {
                    showPermissions ?
                        <PermissionsExtended
                            show={this.state.showExtended}
                            details={details}
                        /> :
                        <Extended
                            show={this.state.showExtended}
                            details={details}
                        />
                }
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        refreshAssignmentList : (campaignId) => {
            dispatch(refreshAssignmentList(campaignId));
        },
        /* addInvitationInSession : (invites) => {
            dispatch(addInvitationsInSession(invites));
        } */
    }
};

const mapStateToProps= (state) => {
    return{
        // userInvitations : state.global.userInvitations,
        favoriteLists : state.FAVORITE_REDUCER.favoriteData
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OneInfluencer))
