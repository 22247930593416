import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import DropdownSelectWithLargetData from '../../shared/selectBox/DropdownSelectWithLargetData';
import InputRange from './InputRange';
import './customRangeInputs.scss';

const CustomRangeInputs = ({
    filterValue,
    label,
    item,
    placeholder,
    multi,
    onChange,
    isSingleInput = false,
    isMinOnly = false,
    searchable = true,
    closeOnSelect = true,
    clearable = false,
}) => {
    const [addDropdownOptions, setAddDropdownOptions] = useState([]);
    const [availableDropdownOptions, setAvailableDropdownOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [showAddOption, setShowAddOption] = useState(false);
    useEffect(() => {
        if (filterValue) {
            const options = [];
            if (filterValue.filterOptions && Array.isArray(filterValue.filterOptions)) {
                filterValue.filterOptions.forEach((v) => {
                    const t = { ...v };
                    t.value = v.customValue;
                    options.push(t);
                });
            }
            options.sort((a, b) => {
                if (a.label > b.label) {
                    return 1;
                }
                if (a.label < b.label) {
                    return -1;
                }
                return 0;
            });
            const optionsWithValue = options.filter((o) => o.minValue || o.maxValue);
            const availableOptions = options.filter((o) => !o.minValue && !o.maxValue);
            setAddDropdownOptions(options);
            setAvailableDropdownOptions(availableOptions);
            setSelectedOptions(optionsWithValue);
        }
    }, [filterValue]);

    useEffect(() => {
        const tempAvailableOptions = addDropdownOptions.filter((e) => {
            const res = selectedOptions.find((s) => s.value === e.value);
            return res === undefined;
        });
        setAvailableDropdownOptions(tempAvailableOptions);
    }, [addDropdownOptions, selectedOptions]);

    const updateData = (filterOptions, addNew = false) => {
        setSelectedOptions(filterOptions);
        if (onChange && !addNew) {
            const temp = { ...filterValue };
            temp.filterOptions = filterOptions;
            onChange(temp);
        }
    };

    const handleChange = (changedItem) => {
        if (changedItem && changedItem.value) {
            if (!selectedOptions.find((e) => e.value === changedItem.value)) {
                const temp = [...selectedOptions];
                temp.push(changedItem);
                updateData(temp, true);
            }
        }
    };

    const handleRequestClose = () => {
        setShowAddOption(false);
    };

    const handleOnAdd = () => {
        if (!showAddOption && availableDropdownOptions.length === 0) {
            return;
        }
        setShowAddOption(!showAddOption);
    };

    const handleInputChange = (field, range) => {
        const temp = [...selectedOptions];
        const idx = temp.findIndex((e) => e.value === field.value);
        if (idx !== -1 && Array.isArray(range) && range.length === 2) {
            const tempField = temp[idx];
            tempField.minValue = range[0];
            tempField.maxValue = range[1];
            temp.splice(idx, 1, tempField);
            updateData(temp);
        }
    };

    const removeItem = (removedItem) => {
        const temp = selectedOptions.filter((e) => e.value !== removedItem.value);
        updateData(temp);
    };

    const isOnlyMinInputVisible = isSingleInput && selectedOptions[0]?.value === 'gte';
    const isOnlyMaxInputVisible = isSingleInput && selectedOptions[0]?.value === 'lte';

    const range = { from: 0, to: 100 };

    return (
        <div className="customRangeInputs">
            <div className="option">{label}</div>
            {selectedOptions.map((selected, index) => {
                return (
                    <div className="itemContainer" key={`${index}`}>
                        <img
                            src="/images/ic-remove.svg"
                            className="removeItem"
                            onClick={() => removeItem(selected)}
                            alt={`removeitem_${index}`}
                        />
                        <div className="inputContainer">
                            <div className="label">{selected.label}</div>
                            <InputRange
                                isOnlyMaxInputVisible={isOnlyMaxInputVisible}
                                isOnlyMinInputVisible={isOnlyMinInputVisible || isMinOnly}
                                id={`${selected.value}_${index}`}
                                rangeObject={range}
                                unlimitMax={false}
                                options={[selected.minValue || '', selected.maxValue || '']}
                                changeFunction={(r) => handleInputChange(selected, r)}
                                type="percentage"
                            />
                        </div>
                    </div>
                );
            })}
            {!isOnlyMinInputVisible && !isOnlyMaxInputVisible && (
                <>
                    <div className="addOption">
                        <span onClick={handleOnAdd} className={cx({ disabled: !showAddOption && availableDropdownOptions.length === 0 })}>
                            {showAddOption ? `Close ${filterValue.label}` : `Add ${filterValue.label}`}
                        </span>
                    </div>
                    {showAddOption && (
                        <DropdownSelectWithLargetData
                            id={item}
                            clearable={!!clearable}
                            multi={!!multi}
                            closeOnSelect={!!closeOnSelect}
                            placeholder={placeholder || ''}
                            options={availableDropdownOptions}
                            onChange={handleChange}
                            searchable={!!searchable}
                            requestCloseFunc={handleRequestClose}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default CustomRangeInputs;
