import React from 'react';

import Format from '../../../modules/utils/Format';
import './rateIcon.scss';
// import below has cycle dependencies (potential memory leak)
import Lookup from '../../../modules/Lookup';

// - rateKey
// - size
// - missing (flag)

export default function RateIcon({
    bgColor,
    iconSize,
    missing,
    padding,
    rateKey,
    size,
    value,
    valueTip,
    countTip
}) {
    const rateType = Lookup.getRateType(rateKey);
    const containerStyle = {
        width: Number(size),
        height: Number(size),
        boxSizing: 'border-box',
        padding: Number(padding) || 0.25 * Number(size),
        marginTop: -0.1 * Number(size),
    };

    if (bgColor) {
        containerStyle.backgroundColor = bgColor;
    }

    const imageStyle = {
        width: Number(iconSize) || Number(size) / 2,
        height: Number(iconSize) || Number(size) / 2,
        objectFit: 'contain',
        margin: 0,
        verticalAlign: 'top',
    };

    let tooltips = rateType ? rateType.label : '';

    if (valueTip) {
        tooltips += ` ${Format.expressInDollars(Number(value))}`;
    }

    if (countTip) {
        tooltips += ` x ${value}`;
    }

    let className = 'rateIcon';

    if (missing) {
        className += ' missing';
    } else if (!value) {
        className += ' nullValue';
    } else if (countTip) {
        className += ' countTip';
    }

    return (
        <div className={className} style={containerStyle} id={tooltips}>
            <img style={imageStyle} src={rateType ? rateType.img : ''} />
        </div>
    );
}
