import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import cx from 'classnames';
import Modal from 'react-modal';
import classNames from 'classnames';
import Checkbox from '../shared/checkbox/Checbox';
import Format from '../../modules/utils/Format';
import ColorBlockGraph from './ColorBlockGraph';
import Extended from './Extended';
import RateIcon from '../shared/rateIcon/RateIcon';
import Actions from './actions/Actions';
import FavoriteLists from './favoriteLists/FavoriteLists';
import InfluencerPhoto from './InfluencerPhoto';
import ModalDialog from '../shared/modalDialog/ModalDialog';
import './oneInfluencer.scss';
import PermissionsExtended from './permissionsExtended';
import CreatorDetails from './CreatorDetails';
import ReachIcon from '../shared/ReachIcon/ReachIcon';
import { ACTIVE_TAB } from '../../constants/rateTypes';
import { getNameLastNameText } from '../influencers/discoverPanel/DiscoverPanel.helpers';

class OneInfluencer extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.renderedRate = this.renderedRate.bind(this);
        this.flipExtended = this.flipExtended.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleClickOnName = this.handleClickOnName.bind(this);
        this.showActions = this.showActions.bind(this);
        this.hideActions = this.hideActions.bind(this);
        this.favoriteButton = this.favoriteButton.bind(this);
        this.handleCloseSlide = this.handleCloseSlide.bind(this);

        this.state = {
            showExtended: false,
            showActions: false,
            showFavorites: false,
            showDeleteConfirmation: false,
            showCreator: false,
        };
    }

    favoriteButton(e) {
        e.stopPropagation();
        e.preventDefault();
        this.setState({
            showFavorites: !this.state.showFavorites,
            showActions: false,
        });
    }

    flipExtended() {
        this.setState({
            showCreator: true,
        });
    }

    handleCloseSlide() {
        this.setState(
            {
                showCreator: false,
            },
            function () {
                if (this.props.closeProfileSidePanel) {
                    this.props.closeProfileSidePanel();
                }
            },
        );
    }

    handleSelect(flag) {
        const f = this.props.selectFunction;
        if (typeof f === 'function') {
            f(this.props.details.uid, flag);
        }
    }

    handleClickOnName() {
        const linkToDetails = `/influencerDetailPage/${this.props.details.id}`;
        this.props.history.push(linkToDetails);
    }

    renderedRate(rate) {
        const rateDollar = this.props.details.rates[rate];

        return (
            <div className="rateDisplay" key={rate}>
                <RateIcon rateKey={rate} size={20} iconSize={14} padding={3} value={rateDollar} />
                {Number(rateDollar) > 0 ? Format.expressInDollars(rateDollar) : ' - '}
            </div>
        );
    }

    setWidth = () => {
        this.setState({ boxWidth: this.coreElement.offsetWidth });
    };

    componentDidMount() {
        window.addEventListener('click', this.hideActions);
        window.addEventListener('resize', this.setWidth);
        this.setWidth();
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.hideActions);
        window.removeEventListener('resize', this.setWidth);
    }

    checkHeart(detailsId) {
        const { favoriteLists } = this.props;
        if (Array.isArray(favoriteLists)) {
            const match = favoriteLists.find((f) => Array.isArray(f.users) && f.users.indexOf(detailsId) >= 0);
            return match !== undefined;
        }
        return false;
    }

    createRenderedRates = () => {
        const { details } = this.props;
        const rates = Object.keys(details.rates || {})
            .filter((rate) => rate !== ACTIVE_TAB && rate !== 'GENERAL')
            .sort();

        if (Object.keys(details.rates || {}).includes('GENERAL')) {
            rates.push('GENERAL');
        }

        const renderedRates = rates.reduce((acc, rate) => {
            const renderedRate = this.renderedRate(rate);
            acc.push(renderedRate);

            return acc;
        }, []);

        return renderedRates;
    };

    showActions(e) {
        e.stopPropagation();
        e.preventDefault();
        this.setState({
            showActions: !this.state.showActions,
            showFavorites: false,
        });
    }

    hideActions() {
        this.setState({
            showActions: false,
            showFavorites: false,
        });
    }

    renderConfirmationModal = () => {
        return (
            <ModalDialog
                show={this.state.showDeleteConfirmation}
                title="Remove creator"
                proceedButtonLabel="YES"
                readyToProceed
                closeButton
                proceedFunction={() => this.props.handleDeleteUser(this.props.details.management.id)}
                cancelFunction={() => this.setState({ showDeleteConfirmation: false })}
            >
                <div className="deleteCampaign">
                    <div className="label">Are you sure that you want to remove this creator?</div>
                </div>
            </ModalDialog>
        );
    };

    renderCreatorSlide = (details) => {
        const { showCreator } = this.state;
        const { showProfileSidePanel } = this.props;
        const closeButton = <div className="closeButton" onClick={this.handleCloseSlide}>
        <img id="closeButton" src="/images/ic-close-g.svg" />
    </div>
        return (
            <Modal
                closeTimeoutMS={300}
                isOpen={showCreator || showProfileSidePanel}
                overlayClassName="creatorOverlay"
                className="creatorModal"
                shouldCloseOnOverlayClick
                onRequestClose={this.handleCloseSlide}
            >
                <div className="container">
                    <div className="closeButton" onClick={this.handleCloseSlide}>
                        <img id="closeButton" src="/images/ic-close-g.svg" />
                    </div>
                    <CreatorDetails
                        {...this.props}
                        profile={details}
                        editMode={false}
                        handleScrollTopChangeFunction={this.handleScrollTopChange}
                        showProfileSidePanel={showProfileSidePanel}
                    />
                </div>
            </Modal>
        );
    };

    getSocialHandle(details) {
        if (!details || !details.userNetworks || details.userNetworks.length <= 0 || !details.userNetworks[0].socialHandle) return null;

        return details.userNetworks[0].socialHandle;
    }

    getAccountVerify(details) {
        if (!details || !details.userNetworks || details.userNetworks.length <= 0 || !details.userNetworks[0].accountVerified) return null;

        return details.userNetworks[0].accountVerified;
    }

    render() {
        const {
            details,
            invited,
            inviteDisabled,
            checked,
            inviteFunction,
            showPermissions,
            showExpandIcon,
            permission,
            showProfileSidePanel,
            sourceTypeFilter,
        } = this.props;
        const { showCreator, boxWidth } = this.state;

        const isConnectedInfluencer = Boolean(details.id);

        let totalReach = 0;
        let posts;
        let instagramNetwork = null;
        let defaultNetwork = null;
        let totalFollowers = null;
        let totalEngagments = 0;
        if (Array.isArray(details.userNetworks) && details.userNetworks.length > 0) {
            totalReach = details.userNetworks.map((un) => Number(un.reach)).reduce((a, b) => a + b);
            posts = details.userNetworks[0].posts;
            instagramNetwork = details.userNetworks.find((un) => un.networkType === 'instagram');
            if (!isConnectedInfluencer) {
                // the first one is default for public user
                defaultNetwork = details.userNetworks[0].networkType;
                totalFollowers = details.userNetworks.map((un) => Number(un.reach)).reduce((a, b) => a + b);
                totalEngagments = details.userNetworks[0]?.engagementRate;
            }
        }

        const rates = this.createRenderedRates();
        const isFavorite = this.checkHeart(this.props.details.id, this.props.favoriteData);
        const heart = classNames('favoriteButton', { heart: isFavorite }, { open: this.state.showFavorites });

        const name = getNameLastNameText(details.firstname, details.lastname);

        let displayIGEngagementRate = false;
        if (instagramNetwork && typeof instagramNetwork.engagementRate === 'number' && instagramNetwork.engagementRate > 0) {
            displayIGEngagementRate = true;
        }

        return (
            <div className="oneInfluencer">
                {this.renderConfirmationModal()}
                {(showCreator || showProfileSidePanel) && this.renderCreatorSlide(details)}
                <div
                    className={cx('core', { сhecked: this.props.checked })}
                    onClick={this.flipExtended}
                    onMouseLeave={this.hideActions}
                    ref={(e) => {
                        this.coreElement = e;
                    }}
                >
                    <div className={cx('coreFields', { 'coreFields-small': !isConnectedInfluencer })}>
                        {/* checkbox cell */}
                        {showExpandIcon ? (
                            <div className="expand-icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    className={cx({ right: !this.state.showExtended }, { down: this.state.showExtended })}
                                >
                                    <path
                                        fill={this.state.showExtended ? '#4AA129' : '#666666'}
                                        fillRule="nonzero"
                                        d="M16.46 9.27a.889.889 0 0 1 1.276 0 .938.938 0 0 1 0 1.305l-5.097 5.154a.888.888 0 0 1-1.278 0l-5.096-5.154a.936.936 0 0 1 0-1.305.889.889 0 0 1 1.276 0l4.46 4.227 4.458-4.227z"
                                    />
                                </svg>
                            </div>
                        ) : (
                            <>
                                <div
                                    className="checkbox"
                                    title={inviteDisabled ? 'This creator can`t be invited (not enough connections or missing rates' : ''}
                                >
                                    {!inviteDisabled && permission && (
                                        <Checkbox size={22} changeFunction={this.handleSelect} checked={checked} />
                                    )}
                                </div>
                                <div className="checkSpacer" />
                            </>
                        )}
                        <div className="infl-info-container">
                            {/* photo cell */}
                            <div className={cx('photo', { 'photo-small': !isConnectedInfluencer })}>
                                {details.authenticityRating && (
                                    <div className="score">
                                        <img src="/images/ic-score.svg" />
                                        <span>{Math.round(details.authenticityRating)}</span>
                                    </div>
                                )}
                                <InfluencerPhoto url={details.pictureUrl} firstName={details.firstname} lastName={details.lastname}/>
                            </div>

                            {/* cell with name and address */}
                            <div className="nameAndAddress">
                                <div className="name">{name}</div>
                                {!isConnectedInfluencer && (
                                    <div className="socialInfo">
                                        <span>{this.getSocialHandle(details)}</span>
                                        {this.getAccountVerify(details) && <img src="/images/ic-ins-verified.svg" />}
                                    </div>
                                )}
                                {isConnectedInfluencer && (
                                    <div className="location">
                                        {details.city && <div>{details.city},&nbsp;</div>}
                                        {details.state && <div>{details.state},&nbsp;</div>}
                                        {details.country && <div>&nbsp;{details.country}</div>}
                                    </div>
                                )}
                            </div>
                        </div>
                        {isConnectedInfluencer && (
                            <div>
                                {/* graph cell */}
                                <div
                                    className="graph"
                                    ref={(node) => {
                                        this.graphNode = node;
                                    }}
                                >
                                    <div className="metrics">
                                        <div className="totalReach">
                                            <div>Total Followers:</div>
                                            <div>{Format.expressInK(totalReach, 2)}</div>
                                        </div>
                                        {displayIGEngagementRate && (
                                            <div className="engagementRate" style={{ width: boxWidth < 800 ? 340 - 150 : 380 - 150 }}>
                                                <div>IG Engagement Rate:</div>
                                                <div className="green">{Format.percentage(isConnectedInfluencer ? instagramNetwork.engagementRate : instagramNetwork.engagementRate * 100)}</div>
                                            </div>
                                        )}
                                    </div>
                                    <ColorBlockGraph userNetworks={details.userNetworks} boxWidth={boxWidth} />
                                </div>

                                {/* rates */}
                                <div className="rates">
                                    {!showPermissions && permission && (
                                        <div className={heart} onClick={this.favoriteButton}>
                                            <img id="noneHeart" src="/images/ic-heart-g.svg" />
                                            <img id="Heart" src="/images/ic-heart.svg" />
                                        </div>
                                    )}
                                    <FavoriteLists
                                        show={this.state.showFavorites}
                                        closeFunction={() => {
                                            this.setState({ showFavorites: false });
                                        }}
                                        userDetails={details}
                                        isOnList={isFavorite}
                                    />

                                    {isConnectedInfluencer && (
                                        <div className="moreButton" onClick={this.showActions}>
                                            <img src="/images/ic-more.svg" />
                                            <Actions
                                                profile={details}
                                                removeInfluencer={() => this.setState({ showDeleteConfirmation: true })}
                                                permission={permission}
                                                tmAction={showPermissions} // showPermissions is an attr for talent manager page.
                                                show={this.state.showActions}
                                                inviteFunction={inviteDisabled || Boolean(invited) ? null : inviteFunction}
                                            />
                                        </div>
                                    )}
                                    <div className="top">
                                        <div>Rates</div>
                                    </div>
                                    <div className="table">{rates}</div>
                                </div>
                            </div>
                        )}
                        {!isConnectedInfluencer && (
                            <div className="infoWrapper">
                                <FavoriteLists
                                    show={this.state.showFavorites}
                                    closeFunction={() => {
                                        this.setState({ showFavorites: false });
                                    }}
                                    userDetails={details}
                                    isOnList={isFavorite}
                                />

                                <div className="dataWrapper">
                                    <div className="dataInfo">
                                        <ReachIcon network={defaultNetwork || 'instagram'} value={1} size={15} padding={6} />
                                        <div>
                                            Followers:
                                            <span>{Format.expressInK(totalFollowers, 2)}</span>
                                        </div>
                                        {defaultNetwork === 'tiktok' && totalReach >= 0 && (
                                            <div>
                                                Avg Views:
                                                <span>{Format.expressInK(totalReach, 2)}</span>
                                            </div>
                                        )}
                                        {totalEngagments !== null && (
                                            <div>
                                                Engagement Rate:
                                                <span>{`${Format.percentage2(isConnectedInfluencer ? totalEngagments : totalEngagments * 100)}`}</span>
                                            </div>
                                        )}
                                        {defaultNetwork === 'tiktok' && (
                                            <div>
                                                View Rate:
                                                <span>
                                                    {Format.percentage(
                                                        totalReach && totalFollowers ? (totalReach / totalFollowers) * 100 : 0,
                                                        2,
                                                    )}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* end of coreFIelds */}

                    {Boolean(invited) && (
                        <div className="invitations">
                            <span>{invited}</span>
                        </div>
                    )}
                </div>

                {showPermissions ? (
                    <PermissionsExtended show={this.state.showExtended} details={details} />
                ) : (
                    <Extended show={this.state.showExtended} details={details} />
                )}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        refreshAssignmentList: (campaignId) => {
            dispatch(refreshAssignmentList(campaignId));
        },
    };
};

const mapStateToProps = (state) => {
    return {
        favoriteLists: state.FAVORITE_REDUCER.favoriteData,
        sourceTypeFilter: state.campaignReducer.influencerSearch.searchFilters.type,
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OneInfluencer));
