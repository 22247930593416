import React from 'react'

import MediaArticle from './MediaArticle'
import './media.scss'

// props
// - mediaKit

export default class Media extends React.Component {

    constructor (props, context) {
        super(props, context);
    }

    render () {
        if (!Array.isArray(this.props.mediaKit) || this.props.mediaKit.length === 0) {
            return <div style={{paddingBottom:15,color:'#999', fontSize:'16px'}}>No portfolio</div>
        }
        let couples = [];
        let couple = [];
        for (let article of this.props.mediaKit) {
            if ( couple.length >= 3) {
                couples.push(
                    <div className="coupleContainer" key={couples.length}>
                        { couple[0]}
                        { couple[1]}
                        { couple[2]}
                    </div>
                );
                couple = [];
            }
            if (couple.length === 1) {
                couple.push(
                    <div style={{width:30}}
                    />)
            }
            couple.push(
                <MediaArticle
                    mediaArticle={article}
                />
            );
        }
        couples.push(
            <div className="coupleContainer" key={couples.length}>
                { couple[0]}
                { couple[1]}
                { couple.length > 2 && couple[2]}
            </div>);



        return (

            <div className="mediaCollection">
                { couples }
            </div>
        );
    }
}
