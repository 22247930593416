import React from 'react'

import Api from '../../../../modules/Api'
import PleaseWaitPartial from '../../../shared/pleaseWait/PleaseWaitPartial'
import MediaArticle from './MediaArticleDisplay'
import MediaArticleInput from './MediaArticleInput'
import EditablePiece from '../../editablePiece/EditablePiece'
import ProfileHelper from '../../../../modules/utils/ProfileHelper'
import ApiError from '../../../shared/apiError/ApiError'

// Props :
// - profile
// - article
// - editMode
// - isNew
// - deleteFunction
// - disableEdit

// Component chain :
//      ProfileMainPanel, in that an array of
//      EditableMediaArticle (handle the EditablePiece part) , displaying
//      either MediaArticle from oneInfluencer or MediaArticleInput
//
//  Saving changes doesn't touch the Profile record so the Api calls all happen
//  in this component

export default class EditableMediaArticle extends React.Component {
    constructor( props,context) {
        super(props.context);

        this.handleLocalEditChange = this.handleLocalEditChange.bind(this);
        this.handleArticleChange = this.handleArticleChange.bind(this);
        this.handleArticleDelete = this.handleArticleDelete.bind(this);

        this.state = {
            localEditMode : false,
            saveFlag : false,
            isNew : false,
            article : null,
            deleted : null,
            complete : false,
            imageVersion : props.imageVersion,
            showSpinner : false,
            uploadProgressPercentage: null,
            onUploadCancel: null,
            apiError: null,
        };
    }

    handleArticleChange(article) {
        const valueFilled = function(key) {
            if (!article[key]) {
                return false;
            }
            if (typeof article[key] !== 'string') {
                return false;
            }
            if (article[key].trim().length === 0) {
                return false;
            }
            return true;
        }

        const complete = valueFilled('title') && valueFilled('mediaUrl') && valueFilled('description');

        this.setState({
            article,
            complete
        })
    }

    handleArticleDelete() {
        const self = this;
        if (this.props.isTalentManager) {
            Api.tmDeleteMediaKit(this.props.profile.id, this.props.article.id).then(function(){
                for (let i=0; i<self.props.profile.mediaKits.length; i++) {
                    if (self.props.profile.mediaKits[i].id === self.props.article.id) {
                        self.props.profile.mediaKits.splice(i,1);
                    }
                }
                self.setState({
                    deleted : true
                })
                const f = this.props.deleteFunction;
                if (typeof f === 'function') {
                    f();
                }
            })
                .catch(err => {
                    this.setState({apiError: err})
                })
        } else {
            Api.deleteMediaKit(this.props.profile.id, this.props.article.id).then(function(){
                for (let i=0; i<self.props.profile.mediaKits.length; i++) {
                    if (self.props.profile.mediaKits[i].id === self.props.article.id) {
                        self.props.profile.mediaKits.splice(i,1);
                    }
                }
                self.setState({
                    deleted : true
                })
                const f = this.props.deleteFunction;
                if (typeof f === 'function') {
                    f();
                }
            })
                .catch(err => {
                    this.setState({apiError: err})
                })
        }
    }

    save = async (articleToSave) => {
        if (!articleToSave) {
            return;
        }
        this.setState({
            showSpinner : true
        });
        const newArticle = { ...articleToSave};
        if (articleToSave && typeof articleToSave.picFile === 'object') {
            try {
                const imageUpload = await Api.uploadFile(
                    articleToSave.picFile,
                    false,
                    (percentage) => this.setState({uploadProgressPercentage: percentage}),
                    (fn) => this.setState({onUploadCancel: fn})
                )
                if (imageUpload && typeof imageUpload === 'object' && imageUpload.url) {
                    newArticle.screenshotUrl = imageUpload.url;
                }
            }catch(err) {
                this.setState({apiError: err});
            }
        }
        delete newArticle.picFile;
        if (this.props.isTalentManager) {
            if (newArticle.id === "") {
                delete newArticle.id;
            }
            Api.tmSaveMediaKit(this.props.profile.id, newArticle).then(
                (savedArticle) => {
                    if (this.props.isNew) {
                        this.props.profile.mediaKits.push(savedArticle);
                    } else {
                        const newMedia = [];
                        for (const a of this.props.profile.mediaKits) {
                            if (a.id !== savedArticle.id) {
                                newMedia.push(a);
                            } else {
                                newMedia.push(savedArticle);
                            }
                        }
                        this.props.profile.mediaKits = newMedia;
                        this.setState({
                            imageVersion : this.state.imageVersion + 1,
                            showSpinner : false,
                            uploadProgressPercentage: null,
                            onUploadCancel: null
                        })
                    }
                    this.props.changeFunction();
                })
                .catch(err => {
                    this.setState({apiError: err})
                })
        } else {
            Api.saveMediaKit(this.props.profile.id, newArticle).then(
                (savedArticle) => {
                    if (this.props.isNew) {
                        this.props.profile.mediaKits.push(savedArticle);
                    } else {
                        const newMedia = [];
                        for (const a of this.props.profile.mediaKits) {
                            if (a.id !== savedArticle.id) {
                                newMedia.push(a);
                            } else {
                                newMedia.push(savedArticle);
                            }
                        }
                        this.props.profile.mediaKits = newMedia;
                        this.setState({
                            imageVersion : this.state.imageVersion + 1,
                            showSpinner : false
                        })
                    }
                    // force to update as the media-kit updated
                    ProfileHelper.getCurrentLoginUserProfile(true);
                    this.props.changeFunction();
                })
                .catch(err => {
                    this.setState({apiError: err})
                })
        }

    }

    handleLocalEditChange(localEditMode, saveFlag) {
        if (!localEditMode && saveFlag) {
            this.save(this.state.article);
        }
        this.setState({
            localEditMode,
            saveFlag
        })
        const f = this.props.handleLocalEditChange;
        if (typeof f === 'function') {
            f(localEditMode, saveFlag, this.props.article.id);
        }
    }

    componentDidMount() {
        if (this.props.isNew) {
            if (this.mainDiv) {
                this.mainDiv.scrollIntoView();
            }
            this.setState({
                localEditMode : true,
                isNew : true
            });
        }
    }

    render() {
        if (!this.props.article) {
            return null;
        }
        if (this.state.deleted) {
            return null;
        }

        const showEdit = this.props.editMode && this.state.localEditMode;

        let className= "media";
        if (this.props.first) {
            className += " first";
        }
        if (this.props.last) {
            className += " last"
        }

        const updateLabel = this.state.isNew ? "Add": "Update";
        const {apiError} = this.state;

        return (
            <div>
                <div ref={ e => { this.mainDiv = e}} className="pieceBox"
                    style={{position:'relative',marginTop:showEdit ? 25 : 0,marginBottom:showEdit ? 25 :0}}>
                    <PleaseWaitPartial
                        show = {this.state.showSpinner}
                        container = {this.mainDiv}
                        progressPercentage={this.state.uploadProgressPercentage}
                        onCancel={this.state.onUploadCancel}
                    />
                    <ApiError
                        show={apiError}
                        error={apiError}
                        cancelFunction={() => this.setState({apiError: null})}
                    />
                    <EditablePiece
                        editMode={this.props.editMode}
                        handleLocalEditChange={this.handleLocalEditChange}
                        initialEditMode={this.state.isNew}
                        disableEdit={this.props.disableEdit}
                        deleteFunction={this.handleArticleDelete}
                        updateButtonLabel = {updateLabel}
                        disableSave = {!this.state.complete}
                    >

                        {  showEdit &&
                        <MediaArticleInput
                            profile={this.props.profile}
                            article={this.props.article}
                            isNew={this.state.isNew}
                            changeFunction={this.handleArticleChange}
                            deleteFunction={this.handleArticleDelete}
                            imageVersion = {this.state.imageVersion}
                        />
                        }

                        { !showEdit &&
                        <div className={className}>
                            <MediaArticle
                                mediaArticle= {this.props.article}
                                imageVersion = {this.state.imageVersion}
                            />
                        </div>
                        }

                    </EditablePiece>
                </div>
                { !this.props.last &&
                    <div className="articleDivider"><div /></div>
                }
            </div>
        )
    }
}
