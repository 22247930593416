import React from 'react';
import Format from '../../../../../modules/utils/Format';

const Portfolio = ({ profile }) => {
    if (!profile) {
        return null;
    }
    const mediaKits = profile && profile.mediaKits;
    if (mediaKits?.length > 0) {
        return (
            <div className="portfolio">
                {mediaKits.map((media) => {
                    return (
                        <div key={media.id}>
                            <div>
                                <div className="imageContainer">
                                    {media && (
                                        <img
                                            src={media.screenshotUrl ? media.screenshotUrl : '/images/noImage.svg'}
                                            style={
                                                media.screenshotUrl
                                                    ? {}
                                                    : { width: 54, height: 54, marginLeft: 102, marginTop: 48, marginBottom: 48 }
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                            <div>
                                <div className="title">{media.title}</div>
                                <div className="description">{media.description}</div>
                                <a href={Format.webAddress(media.mediaUrl)} target="_blank" className="link">
                                    {media.mediaUrl}
                                </a>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    } else {
        return <div className="pieceNoContent">No media kit yet.</div>;
    }
};

export default Portfolio;
