export const FILTERS_KEYWORDS = {
    SOCIAL_HANDLE: 'social_handle',
    SOCIAL_HASHTAG: 'social_hashtag',
    TOPIC: 'topic',
    CREATORS_NAME_AND_BIO: "name_and_bio",
    KEYWORDS: 'post',
    CREATOR_NAME: 'name',
    EMAIL_ADDRESS: 'email',
    WEBSITE_NAME: 'mediakit_title',
    PORTFOLIO_DESCRIPTION: 'mediakit_description',
    PERSONAL_SUMMARY_OR_BIO: 'personal_summary',
    RECENT_POST_CAPTION: 'recent_posts',
};

export const FILTERS_AUDIENCE = {
    AUDIENCE_CREDIBILITY: 'audience_credibility',
    AUDIENCE_CITY: 'audience_city',
    AUDIENCE_COUNTRY: 'audience_country',
    AUDIENCE_LOOK_A_LIKE: 'audience_relevance',
    AUDIENCE_GENDER: 'audience_gender',
    AUDIENCE_BRAND_AFFINITY: 'audience_brand',
    AUDIENCE_LANGUAGE: 'audience_language',
    AUDIENCE_AGE: 'audience_age',
    AUDIENCE_BRAND_AFFINITY_CATEGORY: 'audience_brand_category',
    AUDIENCE_STATE_US: 'audience_state',
    AUDIENCE_CREDIBILITY_GRADE: 'audience_credibility_grade',
    GENDER: 'audience_gender',
    AGE: 'audience_age',
    COUNTRY: 'audience_country',
    STATE_US: 'audience_state',
    CITY_WORLDWIDE: 'audience_city',
    FAMILY_STATUS: 'audience_familyStatus',
    ETHNICITY: 'audience_ethnicity',
    NATIVE_LANGUAGE: 'audience_language',
    BRAND_AFFINITIES: 'audience_brandAffinities',
};

export const FILTERS_COMMON = {
    TOTAL_LIKES_GROWTH: 'total_likes_growth',
    BRAND_CATEGORY: 'brand_category',
    CITY_WORLDWIDE: 'creator_city',
    HAS_AUDIENCE_DATA: 'has_audience_data',
    NUMBER_OF_FOLLOWERS: 'total_followers',
    FOLLOWERS_GROWTH: 'followers_growth',
    BRAND_AFFINITY: 'brand',
    TOTAL_VIEWS_GROWTH: 'total_views_growth',
    LANGUAGE: 'language',
    GENDER: 'gender',
    WITH_CONTACTS: 'with_contact',
    ENGAGEMENT_RATE: 'engagement_rate',
    BRAND_PARTNERSHIP: 'brand_partnership',
    ACCOUNT_TYPE: 'account_type',
    POST_COUNT: 'posts_count',
    VERIFIED_ACCOUNTS_ONLY: 'is_verified',
    NUMBER_OF_ENGAGEMENTS: 'engagements',
    HAS_SPONSORED_POSTS: 'has_ads',
    REEL_PLAYS: 'reels_plays',
    COUNTRY: 'creator_country',
    EXCLUDE_PRIVATE_ACCOUNTS: 'exclude_private_accounts',
    LAST_POSTED: 'last_posted',
    STATE_US: 'creator_state',
    CREATOR_LOOK_A_LIKE: 'relevance',
    NUMBER_OF_VIEWS: 'views',
    AGE: 'age',
    NUMBER_FOLLOWING: 'total_following',
    NUMBER_OF_POSTS: 'total_posts',
    LOCATION: 'location',
    ENGAGEMENT_RATE_IG: 'engagement_rate',
};

export const FILTERS_CONNECTED = {
    CATEGORIES: 'category',
    AGE: 'age',
    GENDER: 'gender',
    ETHNICITY: 'ethnicity',
    RATES: 'rate',
    CHILDREN: 'children',
    DATE_JOINED: 'joined',
    LANGUAGES_SPOKEN: 'language',
    RELATIONSHIP_STATUS: 'relationship_status',
    OCCUPATION: 'occupation',
    BIRTH_DATE: 'birthday',
    POLITICAL_AFFILIATIONS: 'political_affiliation',
    POLITICALLY_ACTIVE: 'politically_active',
    RELIGION: 'religion',
    ANNIVERSARY_DATE: 'anniversary',
    ACTIVE_MEMBERSHIPS: 'active_memberships',
    AGENT_NAME_OR_COMPANY: 'agent_name',
    FAVORITE_BRANDS: 'brands',
    FAVORITE_COLOR: 'favorite_color',
    FAVORITE_FOODS: 'foods',
    HOBBIES: 'hobbies',
    PETS_TYPE: 'pets',
};
