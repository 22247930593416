import React, { createRef } from 'react';
import _ from 'lodash';
import { Warning } from 'phosphor-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InfluencersBody from '../../influencers2/InfluencersBody';
import HeaderWithCampaignAndAssignments from '../headerWithCampaignAndAssignments/HeaderWithCampaignAndAssignments';
import { getData, searchFilterChange, resetData } from '../../../store/campaign/influencerSearch/actionCreators';
import { campaignInfluencerSearchSelector } from '../../../store/campaign/influencerSearch/selectors';
import './campaignInfluencerSearch.scss';
import { resetData as resetGeneralData, setData } from '../../../store/general/actionCreators';
import { InviteSteps } from './components/InviteSteps';
import { InviteControl } from './components/InviteControl';
import InviteDeliverables from './InviteDeliverables';
import InviteRates from './InviteRates';
import PopUpDialogueBase from '../../shared/popUpDialogueBase/PopUpDialogueBase';
import AddNotes from './AddNotes';
import Api from '../../../modules/Api';
import ApiError from '../../shared/apiError/ApiError';
import { LoadingDialogue } from '../../shared/loadingDialogue/loadingDialogue';
import Lookup from '../../../modules/Lookup'


const newSteps = [
    { value: 'choose', label: 'Choose Creators' },
    { value: 'channels', label: 'Deliverables & Channels' },
    // { value: 'contracts', label: 'Contracts' },
    { value: 'rates', label: 'Rates' },
    { value: 'notes', label: 'Notes' },
];

const newStepsPublic = [
    { value: 'choose', label: 'Choose Creators' },
    { value: 'channels', label: 'Deliverables & Channels' },
    // { value: 'contracts', label: 'Contracts' },
    { value: 'rates', label: 'Rates' },
    { value: 'notes', label: 'Review' },
];

const createCreators = (data) => {
    return Object.values(data).map(d => {
        const returnBody = {
            ...d,
            name: `${d.firstname ? `${d.firstname}` : ''}${d.lastname ? ` ${  d.lastname}` : ''}`,
            rates: {},
            deliverables: {},
            personalRates: d.rates
        }
        if (returnBody.name === '') {
            returnBody.name = d?.userNetworks[0]?.socialHandle.startsWith("@") ? d?.userNetworks[0]?.socialHandle : `@${d?.userNetworks[0]?.socialHandle}`
        }
        return returnBody
    })
}

const createDeliverables = () => {
    const data = {};
    Lookup.getRateTypeList2().forEach(key => {
        data[key] = '';
    });
    return data;
}

class CampaignInfluencerSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            setNavigationFunctionIsCalled: false,
            steps: newSteps,
            curStep: 0,
            creators: [],
            disableNext: true,
            showPopup: false,
            showApiError: false,
            apiError: null,
            loading: false,
            allDeliverables: {},
            ratesSelectedOptions: {
                rateOption: 0,
                subOption: 0
            },
        };

        this.onNext = this.onNext.bind(this);
        this.deliverableRef = createRef();
        this.ratesRef = createRef();
        this.addNotesRef = createRef();
    }

    componentDidMount() {
        const { searchFilterChange, getData, type, currencyCode} = this.props;
        // only connected creators can be invited
        const filters = Lookup.getAllFilters(type);
        const currentLookupFilters = Lookup.getAllFilters();
        if (type !== 'CONNECTED' || !_.isEqual(currentLookupFilters, filters)) {
            const searchData = {
                type,
            }
            searchFilterChange(searchData);
            const updateLookupFiltersData = {
                lookup: {
                    ...currentLookupFilters,
                    lookupFilters: filters || []
                }
            }
            setData(updateLookupFiltersData)
        }
        getData();
        Api.getCurrencyCode(currencyCode).then(result => {
            this.setState({currency: result.data})
        })
    }

    componentDidUpdate(prevProps, prevState) {
        const { setNavigationFunctionIsCalled } = this.state;
        const { assignmentId, setNavigationFunction, inviteList } = this.props;

        if (assignmentId && !setNavigationFunctionIsCalled) {
            setNavigationFunction(assignmentId);
            this.setState({ setNavigationFunctionIsCalled: true, })
        }

        if (inviteList && !_.isEqual(inviteList, prevProps.inviteList)){
            this.setState({
                creators: createCreators(inviteList),
                allDeliverables: createDeliverables()
            })
        }
    }

    componentWillUnmount() {
        const { resetData, resetGeneralData } = this.props;
        resetData();
        setTimeout(() => {
            resetGeneralData();
        }, 50);

    }

    // static getDerivedStateFromProps(props, state) {
    //     const { setNavigationFunctionIsCalled, creators } = state;
    //     const { assignmentId, setNavigationFunction, inviteList } = props;

    //     if (assignmentId && !setNavigationFunctionIsCalled) {
    //         // this function should be called once assignmentId is set to hide CampaignNavigation in the parent
    //         setNavigationFunction(assignmentId);

    //         return {
    //             ...state,
    //             setNavigationFunctionIsCalled: true,
    //         };
    //     }

    //     if (inviteList && !_.isEmpty(inviteList)) {
    //         return {
    //             ...state,
    //             creators: createCreators(inviteList)
    //         }
    //     }else if (creators.length > 0) {
    //         return {
    //             ...state,
    //             creators: []
    //         }
    //     }

    //     return state;
    // }

    onBack = () => {
        const { steps, curStep } = this.state;
        if (steps[curStep].value === 'channels') {
            const data = this.deliverableRef.current.onNext();
            return data ? this.setState({ creators: data.creators, allDeliverables: data.allDeliverables,  curStep: this.state.curStep - 1, disableNext: true }) : null;
        }
        if (steps[curStep].value === 'rates') {
            const data = this.ratesRef.current.onSaveData(true);
            return data ? 
                this.setState({ 
                    creators: data.creators || [],
                    ratesSelectedOptions: data.selectedOptions || {},
                    curStep: curStep - 1, 
                }) 
                : null;
        } 
        this.setState({
            curStep: curStep - 1,
        })
    
    }

    handleSubmit = (data) => {
        const { match: { params: { assignmentId } }, campaign } = this.props;
        const { assignmentName } = this.state;
        const self = this;
        const connectedUsers = []
        const publicUsers = []
        data.map(d => {
            if (d.id) {
                connectedUsers.push(d)
            } else {
                publicUsers.push(d)
            }
        })
        const payloadConnected = connectedUsers.map(d => {
            const rates = {};
            Object.keys(d.rates).map(key => {
                if (d.rates[key] !== '') {
                    const parsed = Number.parseInt(d.rates[key]);
                    rates[key] = (Number.isNaN(parsed) ? 0 : parsed)
                }
            })

            const deliverables = {};
            Object.keys(d.deliverables).map(key => {
                if (d.deliverables[key] !== '' && d.deliverables[key] !== '0') {
                    const parsed = Number.parseInt(d.deliverables[key]);
                    deliverables[key] = (Number.isNaN(parsed) ? 0 : parsed)
                }
            })

            return {
                influencerId: d.id,
                rates,
                deliverables,
                notes: d.notes
            }
        })
        const payloadPublic = publicUsers.map(d => {
            const rates = {};
            Object.keys(d.rates).map(key => {
                if (d.rates[key] !== '') {
                    const parsed = Number.parseInt(d.rates[key]);
                    rates[key] = (Number.isNaN(parsed) ? 0 : parsed)
                }
            })

            const deliverables = {};
            Object.keys(d.deliverables).map(key => {
                if (d.deliverables[key] !== '' && d.deliverables[key] !== '0') {
                    const parsed = Number.parseInt(d.deliverables[key]);
                    deliverables[key] = (Number.isNaN(parsed) ? 0 : parsed)
                }
            })
            const body = {
                creatorName: d.name,
                networkType: d.userNetworks[0].networkType,
                socialHandle: d.userNetworks[0].socialHandle,
                avatarUrl: d.pictureUrl,
                networkUserId: d.uid
            }
            if (Object.keys(deliverables).length > 0) {
                body.deliverables = deliverables
            }
            if (Object.keys(rates).length > 0) {
                body.rates = rates
            }
            return body
        })
        this.setState({ loading: true })
        if (connectedUsers.length > 0 && publicUsers.length === 0) {
            Api.inviteInfluencers(assignmentId, payloadConnected)
                .then(res => {
                    self.props.history.goBack();
                })
                .catch(err => {
                    this.setState({
                        apiError: err,
                        showApiError: true,
                        loading: false,
                    })
                })
        } else if (publicUsers.length > 0 && connectedUsers.length === 0) {
            Api.inviteInfluencersPublic(assignmentId, payloadPublic)
                .then(res => {
                    self.props.history.goBack();
                })
                .catch(err => {
                    this.setState({
                        apiError: err,
                        showApiError: true,
                        loading: false,
                    })
                })
        } else {
            Api.inviteInfluencers(assignmentId, payloadConnected)
                .then(res => {
                    Api.inviteInfluencersPublic(assignmentId, payloadPublic).then(res => {
                        self.props.history.goBack();
                    }).catch(err => {
                        this.setState({
                            apiError: err,
                            showApiError: true,
                            loading: false,
                        })
                    })
                })
                .catch(err => {
                    this.setState({
                        apiError: err,
                        showApiError: true,
                        loading: false,
                    })
                })
        }
    }

    onNext = () => {
        const { curStep, steps } = this.state;

        if (steps[curStep].value === 'channels') {
            const data = this.deliverableRef.current.onNext();
            return data ? this.setState({ creators: data.creators, allDeliverables: data.allDeliverables,  curStep: this.state.curStep + 1, disableNext: true }) : null;
        }
        if (steps[curStep].value === 'rates') {
            const data = this.ratesRef.current.onSaveData();
            return data ? 
                this.setState({ 
                    creators: data.creators || [],
                    ratesSelectedOptions: data.selectedOptions || {},
                    curStep: this.state.curStep + 1, 
                    disableNext: true 
                }) 
                : null;
        }
        if (steps[curStep].value === 'notes') {
            const data = this.addNotesRef.current.onNext();            
            return data ? this.handleSubmit(data) : null;
        }
        
        this.setState({
            curStep: curStep + 1,
            disableNext: true,
        })
    }

    onCreatorsChange = (valid) => {
        this.setState({ disableNext: !valid});
    }

    handleCancel = () => {
        this.setState({ showPopup: true});
    }

    checkNextButton = () => {
        const { steps, curStep, disableNext, creators } = this.state;
        let disableNextButton = false;
        let nextLabel = 'Next';
        if (steps[curStep].value === 'choose') {
            disableNextButton = _.isEmpty(creators);
        }

        if (steps[curStep].value === 'notes') {
            nextLabel = 'Submit';
        }

        return { disableNextButton, nextLabel };
    }

    renderContent() {
        const { curStep, creators, steps, allDeliverables, ratesSelectedOptions, currency } = this.state;
        switch (steps[curStep].value) {
        case 'choose':
            return <InfluencersBody />
        case 'channels':
            return <InviteDeliverables
                ref={ref => ref ? this.deliverableRef.current = ref : null}
                profiles={creators}
                allDeliverables={allDeliverables}
                onChange={this.onCreatorsChange}
            />
        case 'rates':
            return <InviteRates
                ref={ref => ref ?  this.ratesRef.current = ref : null}
                profiles={creators}
                selectedOptions={ratesSelectedOptions}
                currency={currency}
            />
        case 'notes':
            return <AddNotes
                ref={ref => ref ? this.addNotesRef.current = ref : null}
                profiles={creators}
                currency={currency}
            />
        default:
            return null;
        }
    }

    render() {
        const {
            assignmentId,
            campaignId,
            searchId,
            budget,
            type
        } = this.props;
        const { steps, curStep, showPopup, showApiError, apiError, loading } = this.state;

        const loaded = assignmentId && campaignId && searchId;
        if (!loaded) return null;
        
        const { disableNextButton, nextLabel }= this.checkNextButton();
        return (
            <div className="cifSearch influencers2022">
                <div className='stepsContainer'>
                    <InviteSteps selected={curStep} data={type === "PUBLIC" ? newStepsPublic : steps} />
                </div>
                <HeaderWithCampaignAndAssignments
                    assignmentId={assignmentId}
                    campaignId={campaignId}
                    AllAssignment={!searchId === assignmentId}
                    onCancel={this.handleCancel}
                />
                <div className='contentContainer' >
                    {this.renderContent()}
                </div>
                <div className='bottomContainer'>
                    <InviteControl
                        hidePre={curStep === 0}
                        disableNext={disableNextButton}
                        nextLabel={nextLabel}
                        onBack={this.onBack}
                        onNext={this.onNext} 
                        budget={budget}
                    />
                </div>
                <PopUpDialogueBase
                    show={showPopup}
                    icon={<Warning color="#111111" size={40} weight="fill" />}
                    message={'Are you sure you want to cancel and lose all the progress you\'ve made?'}
                    proceedLabel="Yes"
                    cancelLabel="No"
                    proceedFunction={() => this.props.history.goBack()}
                    cancelFunction={() => this.setState({ showPopup: false })}
                />
                <ApiError
                    show={showApiError}
                    error={apiError}
                    cancelFunction={() =>
                        this.setState({ showApiError: false, apiError: null })
                    }
                />
                <LoadingDialogue
                    show={loading}
                />
            </div>
        );
    }
}

CampaignInfluencerSearch.propTypes = {
    assignmentId: PropTypes.number,
    campaignId: PropTypes.number,
    searchId: PropTypes.number,
    getData: PropTypes.func,
    searchFilterChange: PropTypes.func,
    setNavigationFunction: PropTypes.func,
    resetData: PropTypes.func,
    resetGeneralData: PropTypes.func,
    budget: PropTypes.number,
    inviteList: PropTypes.object,
    type: PropTypes.string,
    setData: PropTypes.func,
};

const mapStateToProps = (state) => {
    return {
        inviteList: state.campaignReducer.influencerSearch.inviteList,
        type: state.campaignReducer.influencerSearch.searchFilters.type,
        ...campaignInfluencerSearchSelector(state)
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getData: () => {dispatch(getData())},
        searchFilterChange: (newFilters) => {dispatch(searchFilterChange(newFilters))},
        resetData: () => {dispatch(resetData())},
        resetGeneralData: () =>{dispatch(resetGeneralData())},
        setData: (newData) => {dispatch(setData(newData))}
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignInfluencerSearch);
