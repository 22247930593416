import React from 'react';

import SocialNetworks from '../../../../modules/influencer/SocialNetworks';
import Format from '../../../../modules/utils/Format';
import ReachIcon from '../../../shared/ReachIcon/ReachIcon';
import ConnectEdit from './ConnectEdit'
import MetricsItem from '../../../oneInfluencerNew/MetricsItem';


// props
// - editMode
// - profile

export default class Connect extends React.Component {
    constructor( props,context) {
        super(props.context);

        this.renderedSocialDisplay = this.renderedSocialDisplay.bind(this);
        this.renderedSocialEditing = this.renderedSocialEditing.bind(this);
        this.renderedNetworkDisplay = this.renderedNetworkDisplay.bind(this);

        this.state = {
            networksMap : {},
            totalReach : 0,
            totalConnections : 0,
            showAlertMessage : false,
            alertMessageId : null,
            canContinue : true
        };
    }

    closeAlert = () => {
        this.setState ({
            showAlertMessage : false
        })
    }

    static getTotalReach = (networksMap) => {
        let total = 0;
        if (networksMap) {
            for (const n in networksMap) {
                const net = networksMap[n];
                if (net && net.reach) {
                    total += Number(net.reach);
                }
            }
        }
        return total;
    }

    static getConnectionCount = (networksMap) => {
        let connectionCount = 0;
        if (networksMap) {
            for (const n in networksMap) {
                connectionCount++
            }
        }
        return connectionCount;
    }

    handleStatusChange = (networkKey,network) => {
        const userNetworks = SocialNetworks.updateNetworks(this.props.profile.userNetworks, networkKey, network);
        this.props.profile.userNetworks = userNetworks;
        this.setState(Connect.getDerivedStateFromProps(this.props, this.state))
    }

    renderedSocialEditing() {
        return (
            <ConnectEdit
                profile = {this.props.profile}
                continueFunction = {this.props.continueFunction}
            />
        )
    }

    renderedNetworkDisplay(networkKey) {
        let reach = 0;
        const net = this.state.networksMap[networkKey.toLowerCase()];
        if (net) {
            reach = net.reach
        }
        return (
            <div key={networkKey} className="followers">
                <ReachIcon network={networkKey.toLowerCase()} value={1} size={14} padding={7}/>
                { this.state.networksMap[networkKey]  &&
                    <div className="reachValue">
                        { Format.expressInK(reach) || 0}
                    </div>
                }
            </div>
        )
    }

    renderedSocialDisplay() {
        const { profile } = this.props;
        let totalReach = 0;

        if (Array.isArray(profile.userNetworks) && profile.userNetworks.length > 0) {
            totalReach = profile.userNetworks.map((un) => Number(un.reach)).reduce((a, b) => a + b);
        }

        return (
            <>
                <div className="metricsBoxTitle">
                    <div className="metricsBoxTitleText">Connected Channels</div>
                    <div className="metricsBoxTitleItem">
                        <div className="metricsBoxTitleNum">{Format.expressInK(totalReach, 2)}</div>
                        <div className="metricsBoxTitleLabel">Total Followers</div>
                    </div>
                </div>
                {profile.userNetworks &&
                    profile.userNetworks.length > 0 &&
                    profile.userNetworks.map((item, index) => {
                        return <MetricsItem data={item} expanded={index === 0} key={item.networkType}/>;
                    })}

            </>
        )
    }

    static getDerivedStateFromProps(props, state) {
        const newState = {};
        const oldConnections = state.connections;
        if (props.profile) {
            newState.networks = props.profile.userNetworks;
            newState.networksMap = {};
            for ( const network of props.profile.userNetworks) {
                const key = network.networkType.toLowerCase();
                newState.networksMap[key] = network;
            }
            newState.connections = Connect.getConnectionCount(newState.networksMap);
            newState.reach = Connect.getTotalReach(newState.networksMap)
        }
        return newState;
    }

    render() {
        if (!this.props.profile) {
            return null;
        }
        return (
            <div className="profileSocial">
                {   this.props.editMode && this.renderedSocialEditing()
                }
                {   !this.props.editMode && this.renderedSocialDisplay()
                }
            </div>)
    }
}
