import React from 'react';
import PropTypes from 'prop-types';
import style from './styles.module.scss';
import SubmitButton from '../SubmitButton';
import classes from 'classnames';

const Footer = ({ onCancel, onSubmit, onFormExport, assignmentPublicUserId }) => (
    <div className={style.footer}>
        {!assignmentPublicUserId && <div
            className={classes(style.exportButton, {[style.disabled]: !onFormExport})}
            onClick={onFormExport}
        >
            Export W8/W9 form
        </div>}
        <div
            className={style.cancelButton}
            onClick={onCancel}
        >
            Cancel
        </div>
        <SubmitButton
            onClick={onSubmit}
        >
            Approve
        </SubmitButton>
    </div>
);
Footer.propTypes = {
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
};

export default Footer;
