import React from 'react'
import Checkbox from '../../../../shared/checkbox/Checbox'

// props
// - assignment
// - changeFunction  takes fieldName / flag


const  reminders = [
    {   key: 'acceptAlert',
        label : 'Creator accepts invite'
    },
    {   key : 'declineAlert',
        label : 'Creator declines invite'
    },
    {   key : 'draftAlert',
        label : 'Creator completes their draft'
    },
    {   key : "postReminder",
        label : "Creator publishes their post"
    },
    // {   key : "expireAlert",
    //     label : "An invite expires"
    // }
    // {   key : 'verifyAlert',
    //     label : 'Influencer verify alert'
    // }
]


export default class EmailNotifications extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            valuesLoaded : false
        }
    }

    renderedReminder = (reminder) => {
        return this.renderedCheckboxLine(reminder.key, reminder.label);
    }

    renderedCheckboxLine = (key, label) => {
        const changeFunction = (newValue) => {
            const newState = {};
            newState[key] = newValue;
            this.setState(newState);
            this.props.changeFunction(key, newValue);
        }
        return (
            <div key={key} className="checkboxLine">
                <div>
                    <Checkbox
                        checked={this.state[key]}
                        size={20}
                        isDisabled={!this.props.permission}
                        changeFunction={changeFunction}
                    />
                </div>
                <div>
                    { label}
                </div>
            </div>
        )
    }

    static getDerivedStateFromProps(props, state) {
        if (state.valuesLoaded) {
            return {};
        }
        if (typeof props.assignment !== 'object') {
            return {};
        }
        const newState = {
            valuesLoaded : true
        }
        for (const reminder of reminders) {
            const {key} = reminder;
            newState[key] = props.assignment[key]
        }
        return newState;
    }

    render() {
        const renderedReminders = [];
        for (const r of reminders) {
            renderedReminders.push(this.renderedReminder(r));
        }

        return (
            <div>
                <i>Alert me when</i>
                <div>
                    { renderedReminders }
                </div>
            </div>
        )
    }
}
