import React from "react";

import LeftMenu from '../LeftMenu'
import ProfileDetails from './ProfileDetails'
import Password from './Password'


const menuPoints = [
    { key: 'details', label: 'Profile Details'},
    { key: 'password', label: 'Password'}
]

export default class Profile extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
        }
    }

    navigate = (key) => {
        let title = '';
        for (let point of menuPoints) {
            if (point.key === key) {
                title = point.label;
            }
        }
        if (key === 'password') {
            title = 'Change Password'
        }
        this.setState({
            current : key,
            title: title
        })
    }

    componentDidMount() {
        this.navigate('details')
    }

    render() {
        return (<div className="profile">
            <div className="panels">
                <div>
                    <LeftMenu
                        points = { menuPoints}
                        currentKey = {this.state.current}
                        changeFunction = {this.navigate}
                    />
                </div>
                <div className="featureDiv">
                    <div className="featureTitle">{this.state.title}</div>
                    { this.state.current === 'details' && <ProfileDetails/>
                    }
                    { this.state.current === 'password' && <Password/>
                    }
                </div>
            </div>
        </div>)
    }
}