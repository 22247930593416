import {SET_ASSIGNMENTS_FOR_CAMPAIGN,
    CHANGE_ASSIGNMENT_SELECTION,
    ASSIGNMENTS_CHANGE} from './campaignActionTypes'

import Api from "../../../modules/Api";
import {store} from "../../../index";

const freshCopy = (o) => {
    let retval = {};
    for (let ix in o) {
        retval[ix] = o[ix];
    }
    return retval;
};
const allOption = {value: 'ALL', label: 'All Assignments'}

const fixSelectedOptions = (optionSelection, all) => {
    // The elements in the optionSelection array are in the order of the selections that got them there.
    // The user wanted to select all the assignment if that is the very last choice or if there's no choice
    //  (they got all deleted)
    let allSelected = true;
    for (let i=0; i<optionSelection.length; i++) {
        allSelected = (optionSelection[i].value === 'ALL');
    }
    if (allSelected) {
        return [all];
    }
    let newSelection = [];
    for (let option of optionSelection) {
        if (option.value !== 'ALL') {
            newSelection.push(option);
        }
    }
    return newSelection;
}

export const setAssignmentSelection = ( campaignId, selection) => {
    return (dispatch) => {
        let campaignStore = store.getState().campaign;
        let assignmentsObject = campaignStore['assignments' + campaignId];
        let newObject = freshCopy(assignmentsObject);
        newObject.assignmentOptionSelection = fixSelectedOptions(selection, assignmentsObject.assignmentOptions[0]);
        let updateAction = {
            type: SET_ASSIGNMENTS_FOR_CAMPAIGN,
            campaignId: newObject.campaignId,
            campaignName: newObject.campaignName,
            assignmentsObject: newObject
        }
        dispatch(updateAction);
    }
}

export const selectSingleAssignment = (assignment, campaignId) => {
    return (dispatch) => {
        let campaignStore = store.getState().campaign;
        let assignmentsObject = campaignStore['assignments' + campaignId];
        let newObject = freshCopy(assignmentsObject);
        let value = assignment.id;
        let label = assignment.name;
        newObject.assignmentOptionSelection = [{value: value, label: label}]
        newObject.campaignName = assignment.campaign.name;
        let updateAction = {
            type: SET_ASSIGNMENTS_FOR_CAMPAIGN,
            campaignId: newObject.campaignId,
            campaignName: newObject.campaignName,
            assignmentsObject: newObject,
            single: true
        }
        dispatch(updateAction);
    }
}

const processNewAssignmentList = (assignmentObject, newList, dispatch) => {
    let newObject = freshCopy(assignmentObject);
    newObject.status = 'ready';
    newObject.assignments = [];
    newObject.assignmentOptions = [allOption];
    newObject.assignmentOptionSelection = [allOption];
    for ( let assignment of newList) {
        assignment.picked = false;
        for (let selection of assignmentObject.assignmentOptionSelection) {
            if (selection.value == assignment.id) {
                assignment.picked = true;
            }
        }
        newObject.assignments.push(assignment);
        let assignmentOption = {label: assignment.name, value: assignment.id};
        newObject.assignmentOptions.push(assignmentOption);
        if (assignment.picked) {
            newObject.assignmentOptionSelection.push(assignmentOption);
        }
    }
    let updateAction = {
        type : SET_ASSIGNMENTS_FOR_CAMPAIGN,
        campaignId : newObject.campaignId,
        campaignName: newObject.campaignName,
        assignmentsObject : newObject
    }
    dispatch(updateAction);
};

export const refreshAssignmentList = (campaignId) => {
    return (dispatch) => {
        if (!campaignId) {
            return;
        }
        let campaignStore = store.getState().campaign;
        let assignmentsObject = campaignStore['assignments' + campaignId];
        if (typeof assignmentsObject !== 'object') {
            return;
        }
        let newObject = freshCopy(assignmentsObject);
        newObject.status = 'dirty';
        let updateAction = {
            type : SET_ASSIGNMENTS_FOR_CAMPAIGN,
            campaignId : newObject.campaignId,
            campaignName: newObject.campaignName,
            assignmentsObject : newObject
        }
        dispatch(updateAction);
    }
}

export const getAssignmentList = (campaignId,type) => {
    return (dispatch) => {
        if (!campaignId) {
            return;
        }
        let campaignStore = store.getState().campaign;
        let assignmentsObject = campaignStore['assignments' + campaignId];
        if ( typeof assignmentsObject !== 'object') {  // first call
            assignmentsObject = {
                campaignId : campaignId,
                status : 'dirty',
                assignments : [],
                assignmentOptions : [allOption],
                assignmentOptionSelection : [allOption]
            }
            let createNewEntryAction = {
                type : SET_ASSIGNMENTS_FOR_CAMPAIGN,
                campaignId : campaignId,
                assignmentsObject : assignmentsObject
            }
            dispatch(createNewEntryAction);
        }
        if (assignmentsObject.status === 'dirty') {
            let newObject = freshCopy(assignmentsObject);
            newObject.status = 'loading';
            let markProgressAction = {
                type : SET_ASSIGNMENTS_FOR_CAMPAIGN,
                campaignId : campaignId,
                assignmentsObject : newObject
            }
            dispatch(markProgressAction);
            Api.getAssignmentList(Number(campaignId),type).then(
                (result) => {
                    processNewAssignmentList(assignmentsObject, result.results, dispatch)
                }
            )
        }
    }
}
