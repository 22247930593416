const selector = ({ campaignReducer, modals }) => {
    const {
        missingChannels: { isOpen },
    } = modals;
    const {
        influencerSearch: {
            inviteList,
            inappropriateInviteList,
        },
    } = campaignReducer;

    const appropriateInviteList = Object.values(inviteList).reduce((acc, invite) => {
        if (!Object.prototype.hasOwnProperty.call(inappropriateInviteList, invite.uid)) {
            acc.push(invite);
        }

        return acc;
    }, []);

    return {
        appropriateInviteList,
        inappropriateInviteList: Object.values(inappropriateInviteList),
        isOpen,
    };
};

export default selector;
