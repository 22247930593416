import React from 'react'

import Lookup from '../../../modules/Lookup'
import './displayInfluencerCategories.scss'

// props : - categories (array of ids)

export default class DisplayInfluencerCategories extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            categories : null
        };
    }

    static getDerivedStateFromProps(props, state) {
        return {
            categories : props.categories
        }
    }

    render() {
        let map = Lookup.getTopicMap(false);
        let list = [];
        for (let cat of this.state.categories ) {
            let opt = map['' + cat];
            if (opt) {
                let comma = (list.length > 0 ) ? ', ' : '';
                list.push(
                    <span key={opt.id}>
                        {comma}
                        {opt.label}
                    </span>)
            }
        }
        return (
            <span className="displayInfluencerCategories">
                { list }
            </span>
        )
    }
}

