import React from 'react'
import './modalDialog.scss'
import Modal from 'react-modal'

// Props :
// - show
// - title
// - proceedButtonLabel (defaults to Create)
// - cancelButtonLabel (defaults to Cancel)
// - readyToProceed
// - proceedFunction
// - cancelFunction
// - cancelButtonFunction
// - cancelButtonReady
// - justOkFlag  if all we want is to display something
// - closeButton flag if you want a Cancel Dialog x on the right top
// - optionalIcon  icon url
// - optionalIconClickHandler function
// The component's children will be interpreted as the content of the input panel


export default class ModalDialogCamp extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.proceed = this.proceed.bind(this);
        this.cancel = this.cancel.bind(this);

        this.state = {
        };
    }

    proceed() {
        if (this.props.readyToProceed) {
            if (!this.props.checkBeforeProceed) {
                this.cancel();
            }
            const f = this.props.proceedFunction;
            if (typeof f === 'function') {
                f();
            }
        }
    }

    cancel() {
        const f = this.props.cancelFunction;
        if (typeof f === 'function') {
            f();
        }
    }

    render() {
        const style={};
        if (!this.props.show) {
            style.display = "none";
        }

        const proceedButtonClass = this.props.readyToProceed?'button2 proceed controlButton':'button2 proceed controlButton disabled';
        const cancelButtonClass = (typeof this.props.cancelButtonFunction === 'function' && !this.props.cancelButtonReady) ?'button2 cancel cancelButton disabled':'button2 cancel cancelButton';

        let proceedLabel = 'CREATE';
        if (this.props.proceedButtonLabel) {
            proceedLabel = this.props.proceedButtonLabel;
        }
        let cancelLabel = 'CANCEL';
        if (this.props.cancelButtonLabel) {
            cancelLabel = this.props.cancelButtonLabel;
        }
        return(
            <div>
                <Modal isOpen={this.props.show} contentLabel="Loading Modal" closeTimeoutMS={300}
                    overlayClassName="modalDialog" className="dialog" ariaHideApp={false}>
                    <div ref={ e => {this.dialogPanel = e}}
                        style={{ maxHeight: this.state.panelMaxHeight }}>
                        <div className="closeContainer">
                            { (this.props.closeButton || this.props.cancelButtonFunction) && <img className="close" src="/images/ic-close-b-d.svg" onClick={this.cancel} />}
                        </div>
                        <div className="title">
                            { this.props.title}
                        </div>
                        <div className="inputArea">
                            { this.props.children}
                        </div>
                        <div className="footer">

                            { this.props.optionalIcon && this.props.optionalIconFunction &&
                                <div className="optional-icon">
                                    <img src={this.props.optionalIcon ? this.props.optionalIcon : "/images/ic-delete-greyDarker.svg"} onClick={this.props.optionalIconFunction}/>
                                </div>
                            }

                            { !this.props.justOk && <div className={cancelButtonClass}
                                style={this.props.cancelButtonStyle}
                                onClick={typeof this.props.cancelButtonFunction === 'function' ? (this.props.cancelButtonReady? this.props.cancelButtonFunction: null) : this.cancel}
                            >
                                {cancelLabel}
                            </div>
                            }
                            { !this.props.justOk && <div className={proceedButtonClass}
                                style={this.props.proceedButtonStyle}
                                onClick={this.proceed}
                            >
                                {proceedLabel}
                            </div>
                            }
                            { this.props.justOk && <div className="button2 cancel cancelButton"
                                style={this.props.cancelButtonStyle}
                                onClick={this.cancel}
                            >
                                OK
                            </div>
                            }

                            {this.props.extraFooterLabel &&
                            <div style={{display: 'inline-block', paddingLeft: '20px'}}>
                                {this.props.extraFooterLabel}
                            </div>
                            }

                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}
