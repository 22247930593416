import React, { useState, useEffect, Fragment } from 'react';
import './recentPost.scss';
import moment from 'moment';
import Select from 'react-select';
import Api from '../../../../../modules/Api';
import SocialNetworks from '../../../../../modules/influencer/SocialNetworks';
import ReachIcon from '../../../../shared/ReachIcon/ReachIcon';
import { safeIsUrl } from '../../../../../modules/CommonServices';
import { LoadingDialogue } from '../../../../shared/loadingDialogue/loadingDialogue';
import { loadThumbnailError } from '../../../../../modules/utils/CommonUtils';

const MAX_POSTS_COUNT = 25;

const availableType = ['instagram', 'twitter', 'youtube', 'tiktok'];

const RecentPost = ({ profile: { userNetworks, id, uid,  } = { userNetworks: [] }, showHashTags, isTopPost, isPublicInfluencer }) => {
    const [postOption, setOption] = useState('');
    const [postOptions, setOptions] = useState([]);
    const [postData, setPostData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hashTags, setHashTags] = useState([]);

    const isMounted = React.useRef(true);

    useEffect(() => {
        createPostOptions();
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (postOption !== '') {
            fetchRecentPosts();
            const network = userNetworks?.find((ne) => ne.networkType === postOption);
            const hashTags = network.hashTags || [];
            setHashTags(hashTags);
        }
    }, [postOption]);

    const createPostOptions = () => {
        const networkOptions = SocialNetworks.getNetworkOptionList();

        const postOptions = [];

        if (userNetworks?.length > 0) {
            userNetworks.map(({ networkType }) => {
                if (availableType.indexOf(networkType) === -1) return;

                const nw = networkOptions.find((no) => {
                    return no.id === networkType.toUpperCase();
                });
                let networkName = networkType;
                if(networkType === "youtube") {
                    networkName = "YouTube"
                }
                if(networkType === "tiktok") {
                    networkName = "TikTok"
                } 
                postOptions.push({
                    value: networkType,
                    label: (
                        <div className="optionItem">
                            {nw && nw.id && <ReachIcon noToolTip network={nw.id} value={1} size={11} padding={4} />}
                            <span>{networkName[0].toUpperCase() + networkName.slice(1)}</span>
                        </div>
                    ),
                });
            });
        }

        if (postOptions.length > 0) {
            setOptions(postOptions);
            setOption(postOptions[0].value);
        }
    };

    const fetchRecentPosts = async () => {
        try {
            setLoading(true);
            let res = null;

            if (isTopPost) {
                res = await Api.getTopPosts({
                    userUid: uid,
                    networkType: SocialNetworks.getLabel(postOption).toUpperCase(),
                });
            } else {
                const username = userNetworks.find(network => network.networkType === postOption)?.socialHandle;
                res = await Api.getRecentPosts({
                    userIdOrUid: username || id || uid,
                    networkType: SocialNetworks.getLabel(postOption).toUpperCase(),
                });
            }

            if (isMounted.current) {
                setPostData(res);
            }
        } catch (error) {
            setPostData([]);
            console.log('error:', error);
        } finally {
            if (isMounted.current) {
                setLoading(false);
            }
        }
    };

    const handlePostChange = (option) => {
        if (option.value !== postOption) {
            setOption(option.value);
        }
    };

    const onPostClick = (post) => {
        if (!/^https?:\/\//i.test(post.link)) {
            post.link = `http://${  post.link}`;
        }
        if (safeIsUrl(post.link)) {
            window.open(post.link, '_blank');
        }
    };
    let networkName = postOption.charAt(0).toUpperCase() + postOption.slice(1);
    if(postOption === "youtube") {
        networkName = "YouTube"
    }
    if(postOption === "tiktok") {
        networkName = "TikTok"
    } 
    return (
        <div id="recentPostContainerId" className="recentPostContainer">
            <LoadingDialogue show={loading} container="recentPostContainerId" />
            {!isPublicInfluencer ? (
                <Select
                    className="selectRecentPost"
                    clearable={false}
                    searchable={false}
                    value={postOption}
                    single
                    disabled={postOptions.length === 0}
                    // placeholder="Select"
                    options={postOptions}
                    onChange={handlePostChange}
                />
            ) : <div className="optionItem">
                {postOption && <ReachIcon noToolTip network={postOption} value={1} size={11} padding={4} />}
                <span>{networkName}</span>
            </div>}
            <div className="postsContainer">
                {postData.length > 0 &&
                    postData.map((post, index) => {
                        if (index >= MAX_POSTS_COUNT) {
                            return null;
                        }
                        return (
                            <Fragment key={post.link}>
                                {(postOption === 'instagram' || (isTopPost && postOption === 'tiktok')) && (
                                    <div className={`postCard ${postOption}`} onClick={() => onPostClick(post)} key={post.id}>
                                        <img src={post.thumbnail ? post.thumbnail : '/images/ic-default-post.svg'} className="thumbnail" onError={loadThumbnailError} />
                                        {post.created_time && (
                                            <div className="dateTime">
                                                {moment(post.created_time).format('MMM DD, YYYY')} at{' '}
                                                {moment(post.created_time).format('HH:MMA')}
                                            </div>
                                        )}

                                        {post.text && <div className="title">{post.text}</div>}
                                        
                                        <div className="dataWrapper">
                                            {post?.like_count > 0 && (
                                                <div className="data">
                                                    <img src="/images/ic-heart-fill.svg" />
                                                    {post.like_count.toLocaleString()}
                                                </div>
                                            )}
                                            {post?.comments_count > 0 && (
                                                <div className="data">
                                                    <img src="/images/ic-comment.svg" />
                                                    {post.comments_count.toLocaleString()}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}

                                {postOption === 'twitter' && (
                                    <div className={`postCard ${postOption}`} onClick={() => onPostClick(post)}>
                                        <img src="/images/ic-tweet.svg" />
                                        <img src={post.thumbnail ? post.thumbnail : '/images/ic-default-post.svg'} className="thumbnail" onError={loadThumbnailError} />
                                        {post.created_time && (
                                            <div className="dateTime">
                                                {moment(parseInt(post.created_time)).format('MMM DD')} at{' '}
                                                {moment(parseInt(post.created_time)).format('HH:MMA')}
                                            </div>
                                        )}
                                        {post.text && <div className="title">{post.text}</div>}
                                    </div>
                                )}

                                {postOption === 'youtube' && (
                                    <div className={`postCard ${postOption}`} onClick={() => onPostClick(post)}>
                                        <img src={post.thumbnail ? post.thumbnail : '/images/ic-default-post.svg'} className="thumbnail" onError={loadThumbnailError} />
                                        {post.text && <div className="title">{post.text}</div>}
                                    </div>
                                )}

                                {postOption === 'facebook' && (
                                    <div className={`postCard ${postOption}`} onClick={() => onPostClick(post)}>
                                        <img src={post.thumbnail ? post.thumbnail : '/images/ic-default-post.svg'} className="thumbnail" onError={loadThumbnailError} />
                                        {post.created_time && (
                                            <div className="dateTime">
                                                {moment(parseInt(post.created_time)).format('MMM DD')} at{' '}
                                                {moment(parseInt(post.created_time)).format('HH:MMA')}
                                            </div>
                                        )}
                                        {post.text && <div className="title">{post.text}</div>}
                                    </div>
                                )}

                                {!isTopPost && postOption === 'tiktok' && (
                                    <div className={`postCard ${postOption}`} onClick={() => onPostClick(post)}>
                                        <img src={post.thumbnail ? post.thumbnail : '/images/ic-default-post.svg'} className="thumbnail" onError={loadThumbnailError} />
                                        {post.text && <div className="title">{post.text}</div>}
                                    </div>
                                )}
                            </Fragment>
                        );
                    })}
                {postData.length === 0 && !loading && (
                    <div className="noContent">We are unable to fetch recent posts at the moment. Please try again later</div>
                )}
            </div>
            {showHashTags && Boolean(hashTags.length) && (
                <div className="postHashTags">
                    <div className="hashTagsLabel">
                        Hashtags
                        {/* <span>Recent hashtags that were recently use in the last 6 instagram posts.</span> */}
                    </div>
                    <div className="hashTagsContainer">
                        {hashTags &&
                            hashTags.map((hash) => {
                                return <div key={hash}>{hash}</div>;
                            })}
                    </div>
                </div>
            )}
        </div>
    );
};

export default RecentPost;
