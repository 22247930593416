import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import Api from '../../../modules/Api';
import ConfirmModal from '../../shared/confirmModal/ConfirmModal'

const ManageMediaModal = ({ close, userDraftPostId, resources, archivedResources, refreshResources, showVideos, showApiError,
    withRestore, withDelete, profile }) => {

    const menuOptions = {
        selected: "Selected",
        archived: "Archived",
        log: "Log"
    }

    const [chosenOption, setChosenOption] = useState(menuOptions.selected);
    const [restoreResourceId, setRestoreResourceId] = useState(null);
    const [deleteResourceId, setDeleteResourceId] = useState(null);
    const [logList, setLogList] = useState([]);

    useEffect(() => {
        Api.getAllUserDraftPostResourceLogs(userDraftPostId).then(response => {
            if (response && response.content) {
                setLogList(response.content.sort((a,b) => moment(a.createdOn).isBefore(moment(b.createdOn))));
            }
        }).catch((error) => {
            console.log(`User Draft post Resource Logs failed to fetch with error: ${  error}`);
            if (showApiError)
                showApiError("Resource Logs failed to fetch.")
        });
    }, [chosenOption, archivedResources, resources]);

    const renderMenu = () => {
        return (
            <div className="modal-menu">
                <div    className={chosenOption === menuOptions.selected ? "modal-menu-item active-menu-item" : "modal-menu-item"}
                    onClick={chosenOption === menuOptions.selected ? null : () => setChosenOption(menuOptions.selected)}>
                    {menuOptions.selected} ({resources.length})
                </div>
                <div    className={chosenOption === menuOptions.archived ? "modal-menu-item active-menu-item" : "modal-menu-item"}
                    onClick={chosenOption === menuOptions.archived ? null : () => setChosenOption(menuOptions.archived)}>
                    {menuOptions.archived} ({archivedResources.length})
                </div>
                <div    className={chosenOption === menuOptions.log ? "modal-menu-item active-menu-item" : "modal-menu-item"}
                    onClick={chosenOption === menuOptions.log ? null : () => setChosenOption(menuOptions.log)}>
                    {menuOptions.log} ({logList.length})
                </div>
            </div>
        );
    }

    const getActionLabel = (rawLabel) => {
        switch (rawLabel) {
        case "CREATE":
            return "Uploaded";
        case "ARCHIVE":
            return "Archived";
        case "RESTORE":
            return "Restored";
        case "DELETE":
            return "Deleted";
        default:
            return "";
        }
    }

    const renderResourcesLogList = () => {
        const renderResourceLog = (log, index) => {
            const isLast = index === logList.length - 1;
            const createdOn = moment.utc(log.createdOn);
            createdOn.local();
            return (
                <div   key={log.resource.id + Math.random()}
                    className="resource-row"
                    style={isLast ? {borderBottom: "none"} : null}>
                    <div className="resource-name not-clickable">
                        {log.resource.name}
                    </div>
                    <div className="resource-action">
                        {getActionLabel(log.action)}
                    </div>
                    <div className="resource-full-date">
                        {createdOn.format("MMM DD, YYYY @ HH:mm A")}
                    </div>
                </div>
            )
        }

        return (
            <div className="resources-list">
                <div   key="resource-list-header-row-logs"
                    className="resource-row resource-list-header-row">
                    <div className="resource-name">
                        File name
                    </div>
                    <div className="resource-action">
                        Action
                    </div>
                    <div className="resource-date">
                        Date & Time
                    </div>
                </div>
                {logList && logList.map(renderResourceLog)}
            </div>
        );
    }

    const onResourceDelete = (resourceId) => {
        setDeleteResourceId(null);
        Api.userDraftPostResourceAction(userDraftPostId, resourceId, withDelete ? "delete" : "archive").then(res => {
            refreshResources();
        }).catch((error) => {
            console.log(`Failed to archive resource with error: ${  error}`);
            if (showApiError)
                showApiError("Failed to archive resource.")
        });
    }

    const onResourceRestore = (resourceId) => {
        setRestoreResourceId(null);
        Api.userDraftPostResourceAction(userDraftPostId, resourceId, "restore").then(res => {
            refreshResources();
        }).catch((error) => {
            console.log(`Failed to restore resource with error: ${  error}`);
            if (showApiError)
                showApiError("Failed to restore resource.")
        });
    }

    const renderResourcesList = (resourcesToRender) => {
        const renderResource = (resource, index) => {
            const isLast = index === resourcesToRender.length - 1;
            return (
                <div   key={resource.resource.id + Math.random()}
                    className="resource-row"
                    style={isLast ? {borderBottom: "none"} : null}>
                    <div className="resource-name" onClick={showVideos ? () => showVideos(resource, index) : null}>
                        {resource.resource.name}
                    </div>
                    <div className="resource-date">
                        {moment(resource.updatedOn).format("MMM DD, YYYY")}
                    </div>
                    <div className="resource-author">
                        {resource.creator}
                    </div>
                    <div className="resource-delete-icon">
                        {(resource.status === "ACTIVE" && (!withDelete || (profile && profile.uid === resource.creatorUid))) &&
                            <img src="/images/ic-trash-dark.svg" onClick={() => setDeleteResourceId(resource.resource.id)} />
                        }
                    </div>
                    { (resource.status === "ARCHIVED" && withRestore) &&
                        <div className="resource-restore-btn" onClick={() => setRestoreResourceId(resource.resource.id)}>
                            Restore
                        </div>
                    }
                </div>
            )
        }

        return (
            <div className="resources-list">
                <div   key="resource-list-header-row"
                    className="resource-row resource-list-header-row">
                    <div className="resource-name">
                        File name
                    </div>
                    <div className="resource-date">
                        Uploaded on
                    </div>
                    <div className="resource-author">
                        Uploaded by
                    </div>
                    <div className="resource-delete-icon" />
                </div>
                {resourcesToRender && resourcesToRender.map(renderResource)}
            </div>
        );
    }

    const renderList = () => {
        switch (chosenOption) {
        case menuOptions.log:
            return (logList && logList.length > 0) ? renderResourcesLogList() : renderNoRecordsInfo();
        case menuOptions.archived:
            return (archivedResources && archivedResources.length > 0) ? renderResourcesList(archivedResources) : renderNoRecordsInfo();
        case menuOptions.selected:
        default:
            return (resources && resources.length > 0) ? renderResourcesList(resources) : renderNoRecordsInfo();
        }
    }

    const renderNoRecordsInfo = () => {
        return (
            <div className="no-records-info">
                No records
            </div>
        );
    }

    return (
        <div className="confirmModal confirmContinueModal" onClick={close}>
            <ConfirmModal
                show = {restoreResourceId}
                actionText="RESTORE"
                proceedFunction = {() => onResourceRestore(restoreResourceId)}
                cancelFunction = {() => setRestoreResourceId(null)}
                message = "Are you sure you want to restore this resource?"
            />
            <ConfirmModal
                show = {deleteResourceId}
                proceedFunction = {() => onResourceDelete(deleteResourceId)}
                cancelFunction = {() => setDeleteResourceId(null)}
                message = {`Are you sure you want to ${withDelete ? "delete" : "archive"} this resource?`}
                actionText={withDelete ? "DELETE" : "ARCHIVE"}
            />
            <div className="dialog" onClick={event => event.stopPropagation()} style={{padding: "0", borderRadius: "3px"}}>
                <div className="manage-media-modal">
                    <div className="modal-title">
                        Manage Media
                    </div>
                    {renderMenu()}
                    <div className="modal-list">
                        {renderList()}
                    </div>
                    <div className="modal-footer">
                        <div className="modalBtn" onClick={close}>
                            Close
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ManageMediaModal;
