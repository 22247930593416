import React from 'react';
import { Link } from 'react-router-dom';
import { Motion, spring } from 'react-motion';
import './homePageNew.scss';

export default class HomePageNew extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="home">
                <Motion
                    defaultStyle={{ x: 120, y: 0.01 }}
                    style={{
                        x: spring(0, { stiffness: 130, damping: 30 }),
                        y: spring(50, { stiffness: 130, damping: 30 }),
                    }}
                >
                    {({ x, y }) => (
                        <div
                            className="content"
                            style={{
                                opacity: `${y}`,
                                WebkitTransform: `translate3d(0,${x}px, 0)`,
                                transform: `translate3d( 0, ${x}px,0)`,
                            }}
                        >
                            <div className="back">
                                <a href="https://www.koalifyed.com/">
                                    <img src="/images/ic-back.svg" alt="back" className="backIcon" />
                                </a>
                                Back to home
                            </div>
                            <div className="box">
                                <div className="logoImageContainer">
                                    <img className="logoImage" src="/images/prophet.png" alt="Koalified" />
                                </div>
                                <div className="title">Continue to Login or Sign Up as:</div>
                                <div className="optionContainer">
                                    <div onClick={() => this.props.history.push('/login#user')}>Creator</div>
                                    <div onClick={() => this.props.history.push('/login#talent')}>Talent Manager</div>
                                    <div onClick={() => this.props.history.push('/login#agent')}>Brand</div>
                                </div>
                            </div>
                        </div>
                    )}
                </Motion>
            </div>
        );
    }
}
