import {
    cloneDeep,
    get,
    intersection,
    isFinite,
} from 'lodash';
import {
    BLOG_POST,
    FACEBOOK_POST,
    GENERAL,
    INSTAGRAM_POST,
    INSTAGRAM_STORY,
    PER_CHANNEL,
    PER_POST,
    YOUTUBE,
    SOCIAL_SHARE,
    TWITTER_POST,
    TIKTOK_POST,
} from '../constants/rateTypes';

class RatesService {
    constructor(profile) {
        this.ratesList = [
            BLOG_POST,
            FACEBOOK_POST,
            GENERAL,
            INSTAGRAM_POST,
            INSTAGRAM_STORY,
            YOUTUBE,
            SOCIAL_SHARE,
            TWITTER_POST,
            TIKTOK_POST
        ];
        this.perChannel = PER_CHANNEL;
        this.perPost = PER_POST;

        this.profile = cloneDeep(profile);
    }

    filterRatesListByActiveTab = () => {
        const activeTab = this.getInfluencerActiveRatesOption();
        const perChannelFilter = (rate) => rate !== GENERAL;
        const perPostFilter = (rate) => rate === GENERAL;
        const filterCallback = activeTab === this.perChannel
            ? perChannelFilter
            : perPostFilter;

        return this.ratesList.filter(filterCallback);
    };

    filterRatesListByAssignmentRates = (assignmentTypes) => {
        const activeRatesOption = this.getInfluencerActiveRatesOption();
        const ratesList = this.filterRatesListByActiveTab();

        if (activeRatesOption === PER_POST) {
            return ratesList;
        }

        const filteredList = intersection(ratesList, assignmentTypes);

        return filteredList;
    };

    getRatesList = () => this.ratesList;

    getInfluencerActiveRatesOption = () => {
        /*
            ACTIVE_TAB due to backend specifics could be 0 or 1
            0 is for perPost
            1 is for perChannel
         */
        const activeTab = get(this.profile, 'rates.ACTIVE_TAB', null);

        /*
            ACTIVE_TAB was added just today (2020-08-13). So we have no chance to know which tab is
            active for profiles being edited before this date. But ACTIVE_TAB is being used for totalCost
            calculation on backend. And in the case the value is absent (or not set yet) - the way
            totalCost is calculated is decided on perPost (rates.GENERAL) is set or not. So we stick to the
            same approach.
         */
        if (!isFinite(activeTab)) {
            const perPostRate = get(this.profile, 'rates.GENERAL', null);

            return isFinite(perPostRate) ? this.perPost : this.perChannel;
        }

        return activeTab ? this.perChannel : this.perPost;
    };

    getActiveTabFromActiveRatesOption = (activeRatesOption) => {
        /*
            service can't be used for retrieval of activeRatesOption due to it's used to initialize
            rates edit tabs based on the value came from backed. After that it is stored in component's state.
        */

        return activeRatesOption === PER_POST ? 0 : 1;
    };

    calculateTotalRateByActiveRatesOption = () => {
        const { rates } = this.profile;

        if (!rates) return 0;

        const activeRateOption = this.getInfluencerActiveRatesOption();
        const totalRate = activeRateOption === PER_POST
            ? get(rates, GENERAL)
            : Object.values(rates).reduce((acc, rateValue) => {
                return rateValue !== GENERAL ? acc + rateValue : acc;
            }, 0);

        return totalRate;
    };

    static getActiveTabFromPerCampaign = (perCampaign) => {
        return perCampaign ? 0 : 1;
    };
}

export default RatesService;
