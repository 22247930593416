import React, { Component } from 'react';
import Modal from 'react-modal';
import Select from 'react-select';

export default class AddModal extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            name: '',
            brand: '',
            favorite: '',
            exclude: '',
            saved: '',
            tags: [],
            tagValue: ''
        };
        this.inputRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if (this.props.plan && !prevProps.plan) {
            this.setState({
                name: this.props.plan.name,
                brand: this.props.plan.brand,
                tags: this.props.plan.tags,
            });
        }
    }

    clearState = () => {
        this.setState({
            name: '',
            brand: '',
            favorite: '',
            exclude: '',
            saved: '',
            tags: [],
            tagValue: ''
        });
    }

    close = () => {
        const { onCancel } = this.props;
        this.clearState();

        onCancel();
    }

    accept = () => {
        this.clearState();
        if (typeof onAccept === 'function') {
            onAccept(data);
        }
    }

    handleDropdownChange = (type, e) => {
        this.setState({[type]: e.value})
    }

    handleKeyUp = (keyCode) => {
        const { tags } = this.state;
        if (keyCode === 13) {
            if (this.inputRef.current.value.length > 0) {
                tags.push(this.inputRef.current.value)
                this.setState({tags, tagValue: ''})
            }
        }
    };

    handleClick = () => {
        if (this.inputRef.current) {
            this.inputRef.current.focus();
        }
    };

    closeTag = (e, i) => {
        const { tags } = this.state;
        tags.splice(i, 1)
        this.setState({tags})
    }

    render() {
        const {
            isOpen,
            plan,
        } = this.props;
        const { name, brand, favorite, exclude, saved, tags, tagValue } = this.state;
        const enabled = name.trim().length !== 0 && brand.trim().length !== 0;
        const fake = [
            { value: 'olay', label: 'Olay' },
            { value: 'test', label: 'test' }
        ]
        return (
            <Modal
                overlayClassName="modalDialog"
                className="dialog create-advertisers-modal"
                isOpen={isOpen}
            >
                <div className="container">
                    <div className="planTitle">
                        {plan ? 'Edit Plan' : 'New Plan'}
                    </div>
                    <div className="content">
                        <div className="inputArea">
                            <div className="input-holder">
                                <div className="label withreq"><div className="labelName">Name</div><div className="req">*required</div></div>
                                <input
                                    type="text"
                                    defaultValue={name}
                                    onChange={e => this.setState({name: e.target.value})}
                                    maxLength={255}
                                />
                            </div>
                            <div className="input-holder dropdown">
                                <div className="label withreq"><div className="labelName">Brand</div><div className="req">*required</div></div>
                                <Select

                                placeholder='Select Brand'
                                options={fake}
                                onChange={e => this.handleDropdownChange('brand', e)}
                                value={brand}
                                clearable={false}
                                />
                            </div>
                            <div className="input-holder">
                                <div className="label">Tags</div>
                                <div className="inputCustom" onClick={this.handleClick}>
                                    <div className="labelContainer">
                                        {tags.map((tag, i) => {
                                            return (
                                                <div className="tagItem" key={"tag" + i}>
                                                    {tag}
                                                    <div onClick={e => this.closeTag(e, i)} className="filterBar-closeIcon">+</div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <input
                                    onChange={e => this.setState({tagValue: e.target.value})}
                                    ref={this.inputRef}
                                    onKeyUp={({ keyCode }) => this.handleKeyUp(keyCode)}
                                    value={tagValue}/>
                                </div>
                            </div>
                            {!plan && <div className="input-holder dropdown">
                                <div className="label">Add creators from favourite lists</div>
                                <Select
                                placeholder='Select favourite list'
                                options={fake}
                                onChange={e => this.handleDropdownChange('favorite', e)}
                                value={favorite}
                                clearable={false}
                                />
                            </div>}
                            {!plan && <div className="input-holder dropdown">
                                <div className="label">Exclude creators from plan based on exclusion list</div>
                                <Select
                                placeholder='Select exclusion list'
                                options={fake}
                                onChange={e => this.handleDropdownChange('exclude', e)}
                                value={exclude}
                                clearable={false}
                                />
                            </div>}
                            {!plan && <div className="input-holder dropdown">
                                <div className="label">Add saved searches to Plan</div>
                                <Select
                                placeholder='Select save searches'
                                options={fake}
                                onChange={e => this.handleDropdownChange('saved', e)}
                                value={saved}
                                clearable={false}
                                />
                            </div>}
                        </div>
                    </div>
                </div>
                <div className="planFooter">
                    <button
                        onClick={this.close}
                        className="planButtonCancel"
                    >
                        Сancel
                    </button>
                    <button
                        onClick={this.accept}
                        className='planButtonCreate'
                        disabled={!enabled}
                    >
                        Create
                    </button>
                </div>
            </Modal>
        )
    }
}
