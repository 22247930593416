import cx from 'classnames';
import htmlDocx from 'html-docx-js/dist/html-docx';
import FileSaver from "file-saver";
import moment from 'moment';
import Api from '../../../modules/Api';

const exportZipFileName = "draftContent.zip";
const JSZip = require('jszip');
var exportList = [];
var zip = new JSZip();
var successCallback = undefined;
var errorCallback = undefined;

/**
 * The helper class for exporting content
 */
export default class exportContentHelper {
    static getExportListInProgress() {
        return exportList || [];
    }

    static updateCallbacks(success, error) {
        successCallback = success;
        errorCallback = error;
    }

    static startExport(list, success, error) {
        successCallback = success;
        errorCallback = error;
        exportContentHelper.processExportList(list)
            .then(() => {
                exportList = [];
                successCallback();
                let zipData = zip.generate({type: "blob"})
                // console.log('saving zip')
                FileSaver.saveAs(zipData, exportZipFileName);
                exportContentHelper.unMount();
            })
            .catch(err => {
                exportList = [];
                errorCallback(err);
                exportContentHelper.unMount();
            })
    }

    static processExportList(list) {
        return new Promise(async (resolve, reject) => {
            try {
                let i = 0;
                for (i = 0; i < list.length; i++) {
                    let existPos = exportList.findIndex(item => item.assignmentId === list[i].assignmentId && item.userId === list[i].userId);
                    if (existPos === -1) {
                        exportList.push(list[i]);
                    }
                }
                for (i = 0; i < exportList.length; i++) {
                    let influencer = exportList[i];
                    // already processed or in processing. Agent click "Export content" button after selected the new influencers
                    if (influencer.processing) {
                        continue;
                    }
                    exportList[i].processing = true;
                    try {
                        for (let k = 0; k < influencer.posts.length; k++) {
                            let post = influencer.posts[k];
                            let userFullName = (influencer.userFirstName ? influencer.userFirstName.charAt(0).toUpperCase() + influencer.userFirstName.slice(1) : '') +
                                (influencer.userLastName ? influencer.userLastName.charAt(0).toUpperCase() + influencer.userLastName.slice(1) : '');
                            let fileName = influencer.assignmentName + "_" + userFullName + ".docx";
                            let parser = new DOMParser();
                            let submittedDate = exportContentHelper.showDate(post.submittedDate);
                            const contentToUse = typeof post.content === "string" ? post.content : post.content.content;
                            let filterContent = await exportContentHelper.parseCKEditorContent(contentToUse);
                            let content = `
                                <p style="font-size: 16px; font-weight: 'bold'">${influencer.assignmentName}</p>
                                <p style="font-size: 16px">${influencer.userFirstName} ${influencer.userLastName}</p>
                                <p style="font-size: 14px">${post.type}</p>
                                <p style="font-size: 14px">Status: ${post.status}</p>
                                <p>Draft submitted: ${submittedDate}</p>` + filterContent;
                            let contentDocument = parser.parseFromString(content, "text/html");
                            await exportContentHelper.convertImagesToBase64(contentDocument);
                            let blob = htmlDocx.asBlob(`<!DOCTYPE html>` + contentDocument.documentElement.outerHTML);
                            await exportContentHelper.downloadDraft(blob, influencer.assignmentName, post.type, userFullName, fileName);
        
                            if (post.resources && post.resources.length > 0) {
                                for(let m = 0; m < post.resources.length; m++) {
                                    let resource = post.resources[m];
                                    await exportContentHelper.downloadVideos(resource.url, influencer.assignmentName, post.type, userFullName, resource.name);
                                }
                            }
                        }
    
                    } catch (error) {
                        console.log(error);
                    }
                }
                resolve();
            } catch (err) {
                reject(err);
            }
        })
    }

    static showDate(dateString) {
        return moment(dateString).format('MMM D, YYYY');
    };

    static convertImagesToBase64(contentDocument) {
        return new Promise(async (resolve, reject) => {
            var regularImages = contentDocument.querySelectorAll("img");
            for (let i = 0; i < regularImages.length; i++) {
                let imgElement = regularImages[i];
                if (imgElement.src) {
                    try { 
                        let data = await Api.getExternalResource(imgElement.src);
                        let parts = data.split(';base64,')
                        imgElement.src = 'data:image/png;base64,' + parts[1];
                        try {
                            let size = await exportContentHelper.getImageSize(imgElement.src);
                            imgElement.width = "400";
                            imgElement.height = (Math.floor(400 / size.width * size.height)).toString();
                        } catch(e) {

                        }
                    } catch (err) {
                        return reject(err);
                    }
                } 
            }
            resolve();
        })
    }

    static getImageSize(imageData) {
        return new Promise((resolve, reject) => {
            try {
                var img = new Image();
                img.addEventListener("load", function(){
                    resolve({width: this.naturalWidth, height: this.naturalHeight});
                });
                img.src = imageData;
            } catch (err) {
                reject(err);
            }
        })
    }

    static downloadDraft(blob, assignmentName, type, userName, fileName) {
        return new Promise((resolve, reject) => {
            try {
                let reader = new FileReader();
                reader.readAsArrayBuffer(blob)
                reader.onloadend = function (e) {
                    zip.file(exportContentHelper.handleCharactersInName(assignmentName) + "/" + exportContentHelper.handleCharactersInName(userName) + "/" + type + "/" + exportContentHelper.handleCharactersInName(fileName), e.target.result);
                    // console.log('added ' + (assignmentName + "/" + userName + "/" + type + "/" + fileName) + " to zip")
                    resolve();
                }
                reader.onerror = reject;
            } catch(err) {
                reject(err);
            }
        })
    }

    static downloadVideos(videoUrl, assignmentName, type, userName, fileName) {
        return new Promise(async (resolve, reject) => {
            try {
                let res = await Api.getS3FileBlob(videoUrl);
                res.blob()
                    .then(data => {
                        let reader = new FileReader();
                        // if over 300MB, then download it directly w/o put in the zip to avoid out of memory
                        if (data.size >= 300 * 1024 * 1024) {
                            try {
                                FileSaver.saveAs(data, exportContentHelper.handleCharactersInName(assignmentName) + "-" + exportContentHelper.handleCharactersInName(userName) + "-" + type + "-" + exportContentHelper.handleCharactersInName(fileName));
                                // console.log('saved video directly as too big. ' + self.handleCharactersInName(assignmentName) + "-" + userName + "-" + type + "-" + self.handleCharactersInName(fileName))
                                resolve();
                            } catch(err) {
                                reject(err);
                            }
                        } else {
                            reader.readAsArrayBuffer(data)
                            reader.onloadend = function(e) {
                                zip.file(exportContentHelper.handleCharactersInName(assignmentName) + "/" + exportContentHelper.handleCharactersInName(userName) + "/" + type + "/" + exportContentHelper.handleCharactersInName(fileName), e.target.result);
                                // console.log('added video ' + (assignmentName + "/" + userName + "/" + type + "/" + fileName) + ' to zip')
                                resolve();
                            }
                            reader.onerror = reject;
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })
            } catch(err) {  
                reject(err);
            }
        })
    }

    static handleCharactersInName(name) {
        if (!name) {
            return '';
        }
        return name.replace(/[/\\?%*:|"<>]/g, '-');
    }

    static parseCKEditorContent(input) {
        return new Promise((resolve, reject) => {
            try {
                if (!input) {
                    return resolve('');
                }
                // filter the delete suggestions
                const deleteSuggestionStartPattern = 'suggestion-type="deletion" type="start"></suggestion>';
                const deleteSuggestionEndPattern = 'suggestion-type="deletion" type="end"></suggestion>';
                const insertSuggestionStartPattern = 'suggestion-type="insertion" type="start"></suggestion>';
                const insertSuggestionEndPattern = 'suggestion-type="insertion" type="end"></suggestion>';
                const suggestionStartTag = '<suggestion id=';
                let output = input;
                let temp = input;
                let posStart = temp.indexOf(deleteSuggestionStartPattern);
                while (posStart >= 0) {
                    let p1 = temp.substring(0, posStart).lastIndexOf(suggestionStartTag);
                    if (p1 > 0) {
                        output = temp.substring(0, p1);
                    }
                    let p2 = temp.indexOf(deleteSuggestionEndPattern);
                    if (p2 > 1) {
                        let p3 = temp.substring(posStart, p2).lastIndexOf(suggestionStartTag);
                        if (p3 >= 0) {
                            let dataInDeleteBlock = temp.substring(posStart, p2).substring(deleteSuggestionStartPattern.length, p3);
                            // insertion start or end inside the deletion block
                            let ts1 = dataInDeleteBlock.indexOf(insertSuggestionStartPattern);
                            while (ts1 >= 0) {
                                let b1 = dataInDeleteBlock.substring(0, ts1).lastIndexOf(suggestionStartTag);
                                if (b1 >= 0) {
                                    let remainer = dataInDeleteBlock.substring(ts1 + insertSuggestionStartPattern.length);
                                    let ts2 = remainer.indexOf(insertSuggestionEndPattern);
                                    if (ts2 === -1) {
                                        output += dataInDeleteBlock.substring(b1, ts1 + insertSuggestionStartPattern.length);
                                        break;
                                    } else {
                                        output += dataInDeleteBlock.substring(b1, ts1 + insertSuggestionStartPattern.length + ts2 + insertSuggestionEndPattern.length);
                                        dataInDeleteBlock = dataInDeleteBlock.substring(b1 + ts1 + insertSuggestionStartPattern.length + ts2 + insertSuggestionEndPattern.length);
                                        ts1 = dataInDeleteBlock.indexOf(insertSuggestionStartPattern);
                                    }
                                }
                            }
                            ts1 = dataInDeleteBlock.indexOf(insertSuggestionEndPattern);
                            while (ts1 >= 0) {
                                let b1 = dataInDeleteBlock.substring(0, ts1).lastIndexOf(suggestionStartTag);
                                if (b1 >= 0) {
                                    let remainer = dataInDeleteBlock.substring(ts1 + insertSuggestionEndPattern.length);
                                    let ts2 = remainer.indexOf(insertSuggestionStartPattern);
                                    if (ts2 === -1) {
                                        output += dataInDeleteBlock.substring(b1, ts1 + insertSuggestionEndPattern.length);
                                        break;
                                    } else {
                                        output += dataInDeleteBlock.substring(b1, ts1 + insertSuggestionEndPattern.length + ts2 + insertSuggestionStartPattern.length);
                                        dataInDeleteBlock = dataInDeleteBlock.substring(b1 + ts1 + insertSuggestionEndPattern.length + ts2 + insertSuggestionStartPattern.length);
                                        ts1 = dataInDeleteBlock.indexOf(insertSuggestionEndPattern);
                                    }
                                }
                            }
                        }
                        output += temp.substring(p2 + deleteSuggestionEndPattern.length);
                        temp = output;
                        posStart = temp.indexOf(deleteSuggestionStartPattern);
                    } else {
                        // should not go here
                        output += temp.substring(p1);
                        break;
                    }
                }

                // console.log('test. ' + output);
                resolve(output);
            } catch (err) {
                reject(err);
            }
        })
    }
    static unMount () {
        exportList = [];
        zip = new JSZip();
        successCallback = undefined;
        errorCallback = undefined;
    }

}