import React from 'react'
import _ from 'lodash';
import Lookup from '../../../../modules/Lookup'
import RateIcon from '../../../shared/rateIcon/RateIcon'
import { assignemntStatusType } from '../../campaignDetails/assignemntStatusType';

// Props
// - types [ {type, numberOfPosts } ]
// - numberOfFrames
// - changeFunction takes : array of types, number of frames, readyToSave flag

export default class SelectAssignmentTypes extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.newType = this.newType.bind(this);
        this.readTypeList = this.readTypeList.bind(this);
        this.renderedType = this.renderedType.bind(this);
        this.renderedChoice = this.renderedChoice.bind(this);
        this.select = this.select.bind(this);
        this.deleteType = this.deleteType.bind(this);
        this.delegateChanges = this.delegateChanges.bind(this);
        this.handlePostsNumberChange = this.handlePostsNumberChange.bind(this);

        this.allTypeChoices = [];
        Lookup.getRateTypeList2().map((id) => {
            const choice = Lookup.getRateType(id);
            choice.taken = false;
            choice.id = id;
            this.allTypeChoices.push(choice);
        });

        this.state = {
            availableTypes : this.allTypeChoices,
            types: props.types ? props.types : []
        }
    }

    componentDidMount() {
        this.readTypeList();
    }

    componentDidUpdate(prevProps) {
        const {types} = this.props;
        if ( !_.isEqual(prevProps.types, types) || (prevProps.types && prevProps.types.length !== types.length))
            this.setState({types});
    }

    delegateChanges() {
        if (typeof this.props.changeFunction !== 'function')
            return;

        this.props.changeFunction(this.state.types, this.state.types.length !== 0);
    }

    select(choice, index) {
        const newList = [];
        const newValue = choice ? choice.id : null;
        this.state.types.forEach( (t, ix) => {
            if (ix === index) {
                t.type = newValue;
                if (t.type === 'INSTAGRAM_STORY') {
                    t.framesInfo = [];
                    for (let i = 1; i <= t.count; i++) {
                        t.framesInfo.push({
                            parentType: t.type,
                            framesCount: 1
                        });
                    }
                }
            }
            newList.push(t);
        })

        this.setState({types: newList}, this.readTypeList);
    }

    deleteType(index) {
        const newList = this.state.types;
        newList.splice(index,1);
        this.setState({types: newList}, this.readTypeList);
    }

    renderedChoice(cKey, clickHandler) {
        const c = Lookup.getRateType(cKey);
        return (
            <div className="renderedChoice" key={cKey} title={c.label} onClick={clickHandler}>
                <RateIcon rateKey={cKey} value={1} size={30}/>
            </div>
        )
    }

    renderFrameInfo(frameInfo, frameInfoIndex, parentIndex) {
        const {types} = this.state;
        const totalFrames = types[parentIndex].count;

        return (
            <div className="frameInfo" key={parentIndex + frameInfoIndex}>
                <div className="frameInfoLabel">
                    {`${frameInfoIndex + 1}/${totalFrames} - ${Lookup.getRateType(frameInfo.parentType).label}`}
                </div>
                <div>
                    <input
                        className="frameInfoInput number active"
                        placeholder="frames"
                        type="number"
                        onChange={(e) => this.handleFramesNumberChange(e, parentIndex, frameInfoIndex)}
                        value={frameInfo.framesCount ? frameInfo.framesCount : ""}
                    />
                </div>
            </div>
        );
    }

    handleFramesNumberChange(event, parentIndex, index) {
        const {types} = this.state;
        let {value} = event.target;
        if (value >= 100)
            value = 99;
        else if (value !== '' && value <= 0)
            value = 1;

        const newTypes = types;
        newTypes[parentIndex].framesInfo[index].framesCount = value;
        this.setState({types: newTypes}, () => this.delegateChanges());
    }

    handlePostsNumberChange(event, index, type) {
        const {types} = this.state;
        let {value} = event.target;
        if (value >= 100)
            value = 99;
        else if (value !== '' && value <= 0)
            value = 1;

        const newTypes = types;
        newTypes[index].count = value;

        if (type === 'INSTAGRAM_STORY') {
            newTypes[index].framesInfo = [];
            for (let i = 1; i <= value; i++) {
                newTypes[index].framesInfo.push({
                    parentType: type,
                    framesCount: 1
                });
            }
        }

        this.setState({types: newTypes}, this.delegateChanges);
    }

    renderedType(t, index) {
        const {availableTypes, types} = this.state;
        return (
            <div key={index}>
                <div className="typeRow">
                    <div className="typeBox">
                        { !t.type &&
                            <div className="selections">
                                <div style={{color: '#c2c2c2', paddingRight: 15}}>
                                    Select Type&nbsp;&nbsp;
                                </div>
                                {availableTypes.map(choice => this.renderedChoice(choice.id, () => this.select(choice, index)))}
                            </div>
                        }
                        { t.type &&
                            <div className="selected">
                                <div>
                                    {this.renderedChoice(t.type)}
                                </div>
                                <div>
                                    { Lookup.getRateType(t.type).label }
                                </div>
                            </div>
                        }
                    </div>
                    {/* { t.type &&
                        <div>
                            <input
                                className={"postsCountInput number active"}
                                placeholder="# of posts"
                                type="number"
                                onChange={(e) => this.handlePostsNumberChange(e, index, t.type)}
                                value={this.state.types[index].count ? this.state.types[index].count : ""}
                            />
                        </div>
                    } */}
                    <div>
                        <div className={`deleteIcon${  t.type ? " active" : ""}`} onClick={() => this.deleteType(index)}>
                            <img src="/images/ic-close-x.svg"/>
                        </div>
                    </div>
                </div>
                {/* {(t.type === 'INSTAGRAM_STORY') &&
                    <div className="framesInfo">
                        {t.framesInfo && t.framesInfo.map((fi, innerIndex) => this.renderFrameInfo(fi, innerIndex, index))}
                    </div>
                } */}
            </div>
        )
    }

    newType() {
        const newList = this.state.types;
        newList.push({
            type : null,
            count : 1
        });
        this.setState({
            types : newList
        })
    }

    readTypeList() {
        if (this.state.types.length === 0)
            this.newType();

        const availableTypes = [];
        this.allTypeChoices.map( choice => {
            const found = this.state.types.find(function(c){
                return (c.type == choice.id);
            })
            if (!found) {
                availableTypes.push(choice);
            }
        })
        this.setState({availableTypes}, this.delegateChanges)
    }

    render() {
        if (!Array.isArray(this.state.types) )
            return null;

        const typeRows = [];
        const styleForAdd = {};
        this.state.types.map( (t,index) => {
            typeRows.push(this.renderedType(t, index));
            if (!t.type) {
                styleForAdd.display = "none";
            }
        })
        if (this.state.availableTypes.length === 0) {
            styleForAdd.display = "none";
        }

        return (
            <div className="selectAssignmentTypes">
                { typeRows }
                <div className="typeRow" style={styleForAdd}>
                    {this.props.statusOfAssignment == assignemntStatusType.default &&
                        <div className="addButton" onClick={this.newType}>
                            + Add Type
                        </div>
                    }
                </div>
            </div>
        )
    }
}
