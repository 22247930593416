import React from 'react'
import ReactDOM from 'react-dom'
import FileSaver from 'file-saver'
import Format from '../../../../modules/utils/Format';
import Api from '../../../../modules/Api'
import ApiError from '../../../shared/apiError/ApiError'
import ErrorMessageBox from '../../../shared/errorMessageBox/ErrorMessageBox'
import PleaseWaitPartial from '../../../shared/pleaseWait/PleaseWaitPartial'
import FeedbackOnTheTop from '../../../shared/feedbackOnTheTop/FeedbackOnTheTop'
import FileUpload from '../../../shared/fileUpload/FileUpload'
import './additionalResources.scss';

// props
// - assigmnentId
// - existingResources   - array of Resource objects, all the resources
// - apiErrorFunction

const acceptedExtensions  = ["pdf", "png", "jpg", "jpeg", "doc", "docx"];
const docIcon = '/images/doctypeIcons/ic-doc.svg';
const pdfIcon = '/images/doctypeIcons/ic-pdf.svg';
const imageIcon = '/images/doctypeIcons/ic-png.svg';
const typeDetails = {
    'doc' : { icon: docIcon},
    'docx' : { icon: docIcon},
    'pdf' : { icon: pdfIcon}
}
for (const ext of acceptedExtensions)  {
    if (!typeDetails[ext]) {
        typeDetails[ext] = { icon: imageIcon, image : true}
    }
}

export default class AdditionalResources extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.renderExisting = this.renderExisting.bind(this);
        this.renderUpload = this.renderUpload.bind(this);
        this.handleDelete = this.handleDelete.bind(this);

        this.state = {
            existingResources : null,
            resourcesLoaded : false,
            loading: false,
            name : null, // of file being uploaded
            type : null,  // of file being uploaded
            successMessage : null,
            fileUploadCounter : 1,
            uploadProgressPercentage: null,
            onUploadCancel: null
        };
    }

    renderedSpinner = () => {
        if (!this.state.loading || !this.containerElement) {
            return null;
        }
        return (
            <div className="loading">
                <div className="dots">
                    <PulseLoader color="#4AA129" size="16px" margin="4px"/>
                </div>
            </div>
        )
    }

    handleDelete(resource) {
        const self = this;
        this.setState({loading: true});
        Api.deleteAssignmentResource(this.props.assignmentId, resource.resourceId).then(
            function(){
                const newList = [];
                for (const existing of self.state.existingResources) {
                    if (existing.resourceId != resource.resourceId) {
                        newList.push(existing);
                    }
                }
                self.setState({ existingResources: newList, loading: false})
                self.showSuccessMessage('Successfully Deleted');
            },
            function(err) {
                self.setState({loading: false})
                self.showApiError(err);
            }
        )
    }

    send = (file) => {
        this.setState({loading: true});
        Api.uploadResource(
            file,
            null,
            null,
            null,
            progress => this.setState({uploadProgressPercentage: progress}),
            fn => this.setState({onUploadCancel: fn})
        ).then(
            (res)=>{
                if (res) {
                    Api.addAssignmentResource(this.props.assignmentId, {resourceId : res.id, type: 'additional'}).then((res) => {
                        const newList = this.state.existingResources;
                        newList.push(res);
                        this.setState({
                            existingResources: newList,
                            loading: false,
                            uploadProgressPercentage: null,
                            onUploadCancel: null,
                        });
                        this.showSuccessMessage('Successfully Added');
                    })
                        .catch(err => {
                            this.setState({loading: false, uploadProgressPercentage: null, onUploadCancel: null});
                            this.showApiError(err)
                        });
                } else {
                    this.setState({
                        uploadProgressPercentage: null,
                        onUploadCancel: null,
                        loading: false
                    });
                }
            },
            (err)=>{
                this.setState({loading: false, uploadProgressPercentage: null, onUploadCancel: null});
                if ( err.toString().indexOf('413') >= 0) {
                    this.showErrorMessage('File too big');
                } else {
                    this.showApiError(err);
                }
            },
        )
    }



    closeFixed = () => {
        ReactDOM.render(null, document.getElementById('messageContainer'));
    }

    showApiError = (err) => {
        ReactDOM.render(<ApiError
            show
            cancelFunction = {this.closeFixed}
            error={err}
        />, document.getElementById('messageContainer'));
    }

    showSuccessMessage = (message) => {
        ReactDOM.render(
            <FeedbackOnTheTop
                show
                closeFunction = { this.closeFixed}
                message = {message}
            />
            , document.getElementById('messageContainer'))
    }

    showErrorMessage = (message) => {
        ReactDOM.render(
            <ErrorMessageBox
                message={message}
                show
                closeFunction={this.closeFixed}
            />, document.getElementById('messageContainer'))
    }

    handleFileChange = (file) => {
        this.setState({
            name : file.name,
            type : file.type,
        })

        this.send(file);
    }

    picForFile = (filename) => {
        let pic = imageIcon;
        const namePieces = filename.split('.');
        const ext = namePieces[namePieces.length -1];
        if (typeDetails[ext]) {
            pic = typeDetails[ext].icon;
        }
        return pic;
    }

    renderExisting(existing, key) {
        const self = this;

        const getExisting = function () {
            // Api.downloadAssignmentResource(self.props.assignmentId, existing.resourceId).then(function (res) {
            //     FileSaver.saveAs(res, existing.name)
            // });
            self.setState({loading: true})
            const updateFileProgress = (progressPercentage) => {
                if (progressPercentage > 0) {
                    self.setState({uploadProgressPercentage: progressPercentage})
                }
            };
    
            const updateFileFinished = (response) => {
                // handle old data with no name & extensions
                const ext = response.data && response.data.type ? Format.mimeToExt(response.data.type) : '';
                const fileName = existing.name==='File' ? `File${ext}` : existing.name;
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName); // or any other extension
                document.body.appendChild(link);
                link.click();
                self.setState({ uploadProgressPercentage: null, loading: false });
            };
    
            updateFileProgress(1);
            // Call gradual download
            Api.getS3FileGradually(existing.url,
                (progressPercentage) => updateFileProgress(progressPercentage),
                (response) => updateFileFinished(response));
    
        }

        const deleteFunction = function(e) {
            e.stopPropagation();
            self.handleDelete(existing);
        }

        const pic = this.picForFile(existing.name);

        return (
            <div key={key} className="resource" onClick={getExisting}>
                <div className="icon">
                    <img src={pic}/>
                </div>
                <div  className="name" title={existing.name}>
                    { existing.name }
                </div>
                {this.props.permission && <div onClick={deleteFunction} className="deleteIcon">
                    <img src="/images/ic-x-414141.svg" title="Delete"/>
                </div>}
            </div>
        )
    }

    renderNew(resource) {
        const pic = this.picForFile(resource.file.name);
        return(
            <div key={resource.key} className="resource">
                <div className="icon">
                    <img src={pic}/>
                </div>
                <div className="name" title={resource.file.name}>
                    { resource.file.name }
                </div>
            </div>
        )
    }

    renderUpload() {
        const accepted = acceptedExtensions.map((e) =>{
            return `.${  e}`
        }).join();

        return (
            <div className="upload" onClick = { () => {this.setState({ fileUploadCounter : this.state.fileUploadCounter + 1})}}>
                <FileUpload
                    accept = {accepted}
                    handleSelectedFile = { this.handleFileChange }
                    clickCounter = { this.state.fileUploadCounter}
                />
                + Add Files
            </div>
        )
    }

    static getDerivedStateFromProps(props, state) {
        const newState = {};
        if (!state.resourcesLoaded) {
            newState.existingResources = props.existingResources;
            newState.resourcesLoaded = true;
        }
        return newState;
    }

    componentWillUnmount() {
        // this.closeApiError();
    }

    render() {
        if (!Array.isArray(this.state.existingResources)) {
            return null;
        }
        const renderedExisting = [];
        let key=1;
        for (const existing of this.state.existingResources) {
            renderedExisting.push(this.renderExisting(existing, key++))
        }
        const renderedNew = [];
        if (Array.isArray(this.state.newResources)) {
            for (const newResource of this.state.newResources) {
                renderedNew.push(this.renderNew(newResource));
            }
        }
        const canAddMore = ( renderedExisting.length + renderedNew.length ) < 10 && this.props.permission;
        return (
            <div className="additionalResources" style={{position:'relative'}} ref={(e=>this.container=e)}>
                <PleaseWaitPartial
                    show={this.state.loading}
                    container={this.container}
                    progressPercentage={this.state.uploadProgressPercentage}
                    onCancel={this.state.onUploadCancel}
                />
                { canAddMore && this.renderUpload() }
                { renderedExisting }
            </div>
        )
    }
}
