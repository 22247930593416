import React from "react";

function CloseIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            fill="none"
            viewBox="0 0 10 10"
        >
            <path
                fill="#666"
                fillRule="evenodd"
                d="M8.727.334L5 4.061 1.274.334l-.94.94L4.06 5.001.333 8.727l.94.94L5 5.941l3.727 3.726.94-.94L5.94 5.001l3.727-3.727-.94-.94z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default CloseIcon;
