import React from 'react'
import ModalWithAction from './ModalWithAction'

import './modalWithSingleAction.scss'

// props :
// - titleId  (into the translation file)
// - messageId
// - messageValues
// - buttonTextId  (defaults to OK)
// - actionFunction
// - isDisposable  (defaults to false)
// - show

export default class ModalAlertWithAction extends React.Component {

    constructor(props, context) {
        super(props, context);
    }

    render() {
        return (
            <ModalWithAction
                show={this.props.show}
                titleId={this.props.titleId}
                messageId={this.props.messageId}
                messageValues={this.props.messageValues}
                buttonText={this.props.buttonText}
                actionFunction={this.props.actionFunction}
                flavor="warning"
            />
        );
    }
}

// todo
// - animation
// - isDisposable
// - folder structure
