import React, { useState, useEffect } from 'react';
import './inputBox.scss';

const isNumber = (val) => /^[-]?\d+$/.test(val);

const InputBox = ({
    label,
    value,
    keyString = '',
    type = 'number',
    currency,
    maxLength = 3,
    editable = true,
    onChange = () => null,
    newBox
}) => {
    const [data, setData] = useState('');

    useEffect(() => {
        setData(currency ? `${currency}${value || ''}` : value || '');
    }, [value, currency]);

    const handleChange = (e) => {
        let newValue = e.target.value;
        if (currency) {
            newValue = newValue.replace(currency, '');
        }
        if (type === 'number' && ((newValue !== '' && !isNumber(newValue)) || newValue.length > maxLength)) {
            return;
        } 
        if (type === 'currency') {
            newValue = newValue.replace(/[^0-9]/g, "");
            if (newValue.length > maxLength) {
                return;
            }
        }

        onChange(newValue);
    }
    return (
        <div key={keyString ? keyString : 'test'} className={newBox ? 'inputBoxContainerNew' : 'inputBoxContainer'}>
            <span>{label}</span>
            <input
                value={data}
                disabled={!editable}
                onChange={handleChange}
                type='text'
            />
        </div>
    )

}

export default InputBox;