import React from 'react';
import { NavLink, Route, Switch } from 'react-router-dom';
import TopNavigation from '../shared/topNavigation/TopNavigation';
import AssignmentCompose from './AssignmentCompose/AssignmentCompose';
import AssignmentComposeEdit from './AssignmentCompose/AssignmentComposeEdit';
import AssignmentComposeList from './AssignmentComposeList/AssignmentComposeList';

export default class InfluencerAssignments extends React.PureComponent {
    render() {
        return (
            <div>
                <TopNavigation current="opportunities" />
                <div>
                    <Switch>
                        <Route
                            exact
                            path={`${this.props.match.url}/:uid/draftList`}
                            component={AssignmentComposeList}
                        />
                        <Route
                            exact
                            path={`${this.props.match.url}/:uid/compose/:type`}
                            component={AssignmentCompose}
                        />
                        <Route
                            exact
                            path={`${this.props.match.url}/:uid/post/:postId/edit`}
                            component={AssignmentComposeEdit}
                        />
                        <Route
                            exact
                            path={`${this.props.match.url}/:uid/post/:postId/view/:type/:assignmentUserId`}
                            render={(props) => <AssignmentComposeEdit readonly {...props} />}
                        />
                    </Switch>
                </div>
            </div>
        );
    }
}
