import React, { useEffect, useState, useLayoutEffect } from 'react';
import './newEditModal.scss';
import cx from 'classnames';
import Modal from 'react-modal';
import { TagsInput } from './TagsInput';


const NewEditModal = ({
    onClose = () => null,
    onSubmit = () => null,
    show,
    data,
    listData,
    label,
    placeholder,
}) => {
    const [name, setName] = useState('');
    const [tags, setTags] = useState([]);
    const [nameError, setNameError] = useState(null);
    const [validated, setValidated] = useState(false);

    useEffect(() => {
        if (data) {
            setName(data.name);
            setTags(data.tags || []);
        } else {
            setName('');
            setTags([]);
        }
    }, [data]);

    useLayoutEffect(() => {
        setValidated(name.length > 0 && !nameError && checkUpdate());
    }, [name, tags, nameError]);

    const checkUpdate = () => {
        if (!data) {
            return true;
        }else {
            return name !== data.name || !(data.tags.length === tags.length && !tags.some(t => !data.tags.includes(t)));
        }
    }

    const checkNewListName = (tx) => {
        if (tx.length === 0) {
            return 'Your List Name must be at least 1 letters'
        }
        if (tx.length > 255) {
            return 'Maximum 255 letters'
        }
        // if (isDuplicate(tx)) {
        //     return 'Name is already in use'
        // }
        return null;
    };

    // const isDuplicate = (name) => {
    //     if (!Array.isArray(listData)) {
    //         return false;
    //     }
    //     for (let list of listData) {
    //         if (list.combined) {
    //             continue;
    //         }
    //         if (list.name.toLowerCase() === name.toLowerCase()) {
    //             return true;
    //         }
    //     }
    //     if (name.toLowerCase() === allFavoritesName.toLowerCase()) {
    //         return true;
    //     }
    //     return false;
    // };

    const handleSubmit = () => {
        const payload = {
            name,
            tags
        };
        if (data && data.id) {
            payload.id = data.id;
            payload.description = data.description;
        }
        onSubmit(payload);
        setName('')
        setTags([])
    };

    const onCancel = () => {
        setNameError(null);
        setValidated(false);
        onClose();
    }

    const onNameChange = (e) => {
        setName(e.target.value);
        setNameError(checkNewListName(e.target.value));
    }

    const onTagsChange = (value) => {
        setTags(value);
    }

    return (
        <Modal
            isOpen={show}
            overlayClassName="modalDialog"
            className="dialog"
        >
            <div
                className='newEditModalContainer'
                id='newEditModalContainer'
            >
                <div className='newEditModalHeader'>
                    {`${data ? 'Edit' : 'New'} ${label} list`}
                </div>
                <div className='newEditModalContent'>
                    <span >Name</span>
                    <div className='newEditNameInput'>
                        <input
                            name={placeholder}
                            onChange={onNameChange}
                            type='text'
                            maxLength={255}
                            placeholder={placeholder}
                            value={name}
                        />
                        <div className='nameError'>
                            {nameError}
                        </div>
                    </div>
                    <span>Tags</span>
                    <TagsInput
                        data={tags}
                        onChange={onTagsChange}
                    />
                </div>
                <div className='newEditModalFooter'>
                    <div className='newEditModalFooterCancelButton' onClick={onCancel}>Cancel</div>
                    <div
                        className={cx('newEditModalFooterSubmitButton', { disabled: !validated })}
                        onClick={() => validated ? handleSubmit() : null}>
                        {`${data ? 'Update' : 'Create'}`}
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default NewEditModal;
