import React from 'react';
import './style.scss';
import Lookup from "../../../../modules/Lookup";
import {SUPER_ADMIN_STR} from '../../../../constants/authorities';


class UserRow extends React.Component {
    state = {
        isShowMoreOpen: false
    };

    toggleShowMore = () => {
        this.setState({isShowMoreOpen: true})
    };

    handleBlur = () => {
        this.setState({isShowMoreOpen: false})
    };

    handleRedirect = () => {
        this.props.handleSetUserToEdit(this.props.user)
    };

    toggleRemoveModal = () => {
        const {user} = this.props;
        this.props.setUserToDelete(user);
        this.props.handleOpenRemoveModal();
    };


    render () {
        const {isShowMoreOpen} = this.state;
        const {user, disableEdit} = this.props;
        // const userBrands= [];
        // if (user && user.roles[0] && user.roles[0].brands) {
        //     user.roles[0].brands.forEach(item => userBrands.push(item.brandName.toLowerCase()))
        // }
        return (
            <>
                <td>
                    <div className="user-info-holder">
                        <div className="image-holder">
                            {/* {user.pictureUrl ? <img src={user.pictureUrl} alt="" /> : user.firstname[0]} */}
                            {user.firstname[0]}
                        </div>
                        <div className="user-info">
                            <div className="user-name">{user.firstname} {user.lastname}</div>
                            {
                                user.pending ?
                                    <div className="pending-status">Pending Invitation</div>
                                    : ''
                            }
                        </div>
                    </div>
                </td>
                <td className="email">{user.email}</td>
                <td className="role">{user.role && Lookup.getAgentRole(user.role)}</td>
                {/* <td className="brands">{userBrands.join(', ')}</td> */}
                {
                    user.role !== SUPER_ADMIN_STR && !disableEdit ?
                        <td onMouseOver={this.toggleShowMore} onMouseLeave={this.handleBlur}><div className="action">
                            {
                                isShowMoreOpen ?
                                    <div className="popup">
                                        <div onClick={this.handleRedirect}>Edit User</div>
                                        {/* <div onClick={this.handleRedirect}>Revoke All Brands Access</div> */}
                                        {/* <div onClick={this.toggleRemoveModal}>Remove</div> */}
                                    </div>
                                    :''
                            }
                        </div></td> : <td/>
                }
            </>
        )
    }
}

export default UserRow
