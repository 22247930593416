import React from "react";

export default class Terms extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        return (<div className="terms">
            Terms
        </div>)
    }
}