import React from 'react';
import cx from 'classnames';
import TopNavigation from "../../shared/topNavigation/TopNavigation";
import './PermissionPage.scss'
import {withRouter} from "react-router-dom";
import EditPermission from "./EditPermission";
import Api from "../../../modules/Api";
import PleaseWait from "../../shared/pleaseWait/PleaseWaitWhite";
import ManagementStatus from "../../../constants/managementStatus";
import ProfileHelper from '../../../modules/utils/ProfileHelper';
import {TALENT_MANAGER_STR, INFLUENCER_STR} from '../../../constants/authorities';

class PermissionCard extends React.Component {

    emptyTM = {
        id: null,
        firstname: '',
        lastname: '',
        pictureUrl: '',
        email: ''
    };

    state = {
        accepted: false,
        loading: false,
        pendingManagersList: [],
        inviteData: {
            id: '',
            talentManagerId: '',
            influencerId: '',
            status: "",
            permissionsSet: {}
        },
        activeTalentManager: this.emptyTM,
        profile: null,
    };

    componentDidMount() {
        this.handleGetData();
        ProfileHelper.getCurrentLoginUserProfile()
            .then(profile => this.setState({profile}))
    }

    handleGetData = () => {
        this.setState({loading: true});
        Api.getManagementData().then(res => {
            if (!res)
                return Promise.reject();

            const activeRecord = res.find(r => r.status === ManagementStatus.ACTIVE);

            if (activeRecord) {
                this.setState({inviteData: activeRecord});
                return Api.getTMData(activeRecord.talentManagerId).then(response => {
                    this.setState({pendingManagersList: [], activeTalentManager: response, loading: false});
                });
            } 
            const pendingRecords = res.filter(r => r.status === ManagementStatus.PENDING);
            return Promise.all(pendingRecords.map(pr => {
                return Api.getTMData(pr.talentManagerId);
            })).then(talentManagers => {
                const pendingManagersData = talentManagers.map(managerData => {
                    managerData.inviteData = pendingRecords.find(r => r.talentManagerId === managerData.id);
                    return managerData;
                });
                this.setState({pendingManagersList: pendingManagersData, activeTalentManager: this.emptyTM, loading: false})
            });
            
        }).catch(e => {
            this.setState({loading: false});
        });
    };

    pushBack = () => {
        this.props.history.push('/profile/permissions/');
    };

    renderOnePendingTalentManager(singleManager) {

        const handleSave = () => {
            this.props.history.push(`/profile/permissions/${singleManager.inviteData.id}`);
        };

        const declineTMPermission = () => {
            Api.declineTM(singleManager.inviteData.id).then((res) => {
                this.handleGetData();
            })
        };

        return (
            <div key={singleManager.id} className="manager-card" style={{marginBottom: "20px"}}>
                <div className="manager-info">
                    <div className="profile-pic">
                        <img src={singleManager.pictureUrl} alt=""/>
                    </div>
                    <div className="profile-info">
                        <div className="manager-name">
                            {`${singleManager.firstname} ${singleManager.lastname}`}
                        </div>
                        <div className="manager-email">
                            {singleManager.email}
                        </div>
                        <div className="manager-message">
                            {`${singleManager.firstname} ${singleManager.lastname} is requesting to be your Talent Manager`}
                        </div>
                    </div>
                </div>
                <div className="manager-card-bottom">
                    <div className="button cancel" onClick={declineTMPermission}>
                        Decline
                    </div>
                    <div className='button' onClick={handleSave}>
                        Accept
                    </div>
                </div>
            </div>
        );
    }

    renderPendingTalentManagerList() {
        const { pendingManagersList, profile } = this.state;
        const addExtraTop = profile && (profile.role === TALENT_MANAGER_STR || profile.role === INFLUENCER_STR) && !profile.accountVerified;
        return (
            <>
                <div className={cx("blue-header", {extraTop: addExtraTop})}>
                    <div className="contentDiv">
                        <div className="controlContainer">Manager Permission</div>
                    </div>
                </div>
                <div className="managers-container">
                    <div className="manager-list">
                        <h3>Talent Manager</h3>
                        {pendingManagersList.map(pm => this.renderOnePendingTalentManager(pm))}
                    </div>
                </div>
            </>
        );
    }

    renderContent() {
        const { loading, activeTalentManager, pendingManagersList, inviteData } = this.state;
        if (loading)
            return <PleaseWait show={this.state.loading}/>

        if (!activeTalentManager.id && pendingManagersList.length === 0)
            return this.renderNoInvitationsInfo();

        return !activeTalentManager.id ?
            this.renderPendingTalentManagerList()
            :
            <EditPermission clearInvitationData={this.handleGetData}
                pushBack={this.pushBack}
                tmData={activeTalentManager}
                inviteData={inviteData}/>;
    }

    renderNoInvitationsInfo() {
        const {profile} = this.state;
        const addExtraTop = profile && (profile.role === TALENT_MANAGER_STR || profile.role === INFLUENCER_STR) && !profile.accountVerified;
        return (
            <>
                <div className={cx("blue-header", {extraTop: addExtraTop})}>
                    <div className="contentDiv">
                        <div className="controlContainer">
                            Manager Permission
                        </div>
                    </div>
                </div>
                <div className={cx("no-data-container", {extraNoDataContentTop: addExtraTop})}>
                    <h2 className="no-data">
                        No invitations
                    </h2>
                </div>
            </>
        );
    }

    render () {
        return (
            <div className="permission-card">
                <TopNavigation  />
                {this.renderContent()}
            </div>
        )
    }
}

export default withRouter(PermissionCard);
