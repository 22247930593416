import React from 'react'

import './confirmModal.scss'

// Props :
// - show
// - message
// - proceedFunction
// - cancelFunction
// - actionText (defaults to DELETE

export default class ConfirmModal extends React.Component {

    constructor(props, context) {
        super(props, context);
    }

    render() {
        if (!this.props.show) {
            return null;
        }
        let actionText = "DELETE";
        if (this.props.actionText) {
            actionText = this.props.actionText;
        }
        return (
            <div className="confirmModal" onClick={event => event.stopPropagation()}>
                <div className="dialog">
                    <div className="message">
                        {this.props.message}
                    </div>
                    <div>
                        <div className="button cancel" onClick={this.props.cancelFunction}>
                            CANCEL
                        </div>
                        <div className="button" onClick={this.props.proceedFunction}>
                            { actionText }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
