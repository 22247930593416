import React from 'react'
import Checkbox from './Checbox'
import './checkboxWithLabel.scss'

export default class CheckBoxWithLabel extends Checkbox {

  constructor (props, context) {
    super(props, context);
  }

  //   handleCheckBoxClick(e) {
  //   if (!this.props.isDisabled) {
  //     this.handleClick(e);
  //   }
  // }

  render () {
    let className = "iCheckbox";
    if (!this.state.checked) {
      className += " hidden";
    }
    let divStyle = {};
    let imgStyle = {};
    if (this.props.size) {
      divStyle.width = this.props.size;
      divStyle.height = this.props.size;
      imgStyle.width = this.props.size-2;
      imgStyle.height = this.props.size-2;
    }
    return (
      <label onClick={(e) => this.props.isDisabled ? null : this.handleClick(e)}>
        <div className={[className, this.props.isDisabled ? 'checkbox-disabled' : ''].join(" ")} onClick={(e) => this.props.isDisabled ? null : this.handleClick(e)} style={divStyle}>
          <img src="/images/ic-checkmark3.svg" style={imgStyle}/>
        </div>
        <span className={"lblForiCheckbox"}>{this.props.label}</span>
      </label>
    );
  }

}