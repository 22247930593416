import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';
import Api from '../../../modules/Api';
import cx from 'classnames';
import './TopicTensorInput.scss';

const TopicTensorInput = ({
    data,
    placeHolder,
    maxLength = 255,
    disabled = false,
    maxSuggestions = 10,
    platform,
    selectedFilterId,
    onSuggestionAction,
    isLookalike,
    sideFilter,
    showApiError,
    apiError,
    isFirstKeywordOnly,
    selectedKeywords
}) => {
    const [enteredKeyword, setEnteredKeyword] = useState('');
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [currentData, setData] = useState(data || []);
    const [suggestionsList, setSuggestionsList] = useState([]);
    const [isNoSuggestions, setIsNoSuggestions] = useState(false);

    const inputRef = useRef(null);
    const topicTensorContainerRef = useRef(null);

    const onGetTopicSuggestions = async (data) => {
        setIsNoSuggestions(false);
        setSuggestionsList([]);

        try {
            const response = await Api.getTopicTensorSuggestions(selectedFilterId, data);
            if (response && isLookalike) {
                setSuggestionsList(
                    response.map((item) => {
                        return { key: item.json.username, value: item.json.username };
                    }),
                );
            } else if (response) {
                setSuggestionsList(response);
            }
        } catch (err) {
            if (err?.status === 204) {
                setIsNoSuggestions(true);
            } else {
                showApiError(true);
                apiError(err);
            }
        }
    };

    useLayoutEffect(() => {
        setData(data || []);
    }, [data]);

    const onChangeHandler = (e) => {
        const inputValue = e.target.value.replace(/\s/g, '');
        setEnteredKeyword(inputValue);
    };

    const getSuggestionsHandler = (enteredKeyword) => {
        if (!enteredKeyword || !Boolean(enteredKeyword.length)) {
            return;
        }
        const payload = {
            keyword: enteredKeyword,
            limit: maxSuggestions,
            platform: platform.toLowerCase(),
        };

        onGetTopicSuggestions(payload);
    };

    const onAddSuggestion = (selectedSuggestion) => {
        let update = Object.assign([], currentData);
        const currentKeywords = selectedKeywords && selectedKeywords.length > 0 ? selectedKeywords[0].keywords : [];
        if (isFirstKeywordOnly && currentKeywords.length > 0) {
            update = ([selectedSuggestion]);
            setData(update);
            if (onSuggestionAction) {
                onSuggestionAction(update);
            }
        } else if(!currentKeywords.includes(selectedSuggestion)) {
            update.push(selectedSuggestion);
            setData(update);
            if (onSuggestionAction) {
                onSuggestionAction([selectedSuggestion]);
            }
        }
    };

    const onDelete = (index) => {
        const update = Object.assign([], currentData);
        update.splice(index, 1);
        setData(update);
        if (onSuggestionAction) {
            onSuggestionAction(update, true);
        }
    };

    useEffect(() => {
        if (enteredKeyword && Boolean(enteredKeyword.length)) {
            const inputTimeout = setTimeout(() => {
                getSuggestionsHandler(enteredKeyword);
            }, 500);

            return () => clearTimeout(inputTimeout);
        }
    }, [enteredKeyword]);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (topicTensorContainerRef.current && !topicTensorContainerRef.current.contains(e.target)) {
                setShowSuggestions(false);
            } else if (
                topicTensorContainerRef.current &&
                topicTensorContainerRef.current.contains(e.target) &&
                ((Boolean(enteredKeyword?.length) && Boolean(suggestionsList?.length)) || isNoSuggestions)
            ) {
                setShowSuggestions(true);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [topicTensorContainerRef, enteredKeyword, suggestionsList, isNoSuggestions]);

    useEffect(() => {
        setShowSuggestions(
            Boolean((Boolean(enteredKeyword?.length) && suggestionsList && Boolean(suggestionsList.length)) || isNoSuggestions),
        );
    }, [enteredKeyword, suggestionsList, isNoSuggestions]);

    return (
        <>
            <div
                className={cx('topicTensorContainer', { sideFilter: sideFilter })}
                key={selectedFilterId}
                onClick={() => inputRef.current.focus()}
                ref={topicTensorContainerRef}
            >
                <div className="topicTensorInputWrapper">
                    <div className="inputContainer">
                        <input
                            ref={inputRef}
                            type="text"
                            placeholder={placeHolder}
                            maxLength={maxLength}
                            disabled={disabled}
                            value={enteredKeyword}
                            onChange={onChangeHandler}
                        />
                    </div>
                </div>
                {showSuggestions && (
                    <div className="suggestionsContainer" key={`${selectedFilterId}-suggestions`}>
                        {isNoSuggestions ? (
                            <div className="suggestionItem">
                                <span className="noSuggestionsMessage">
                                    No suggestions found
                                </span>
                            </div>
                        ) : (
                            Boolean(suggestionsList.length) &&
                            suggestionsList.map((suggestion) => (
                                <div
                                    className="suggestionItem"
                                    key={suggestion.value}
                                    onClick={() => onAddSuggestion(suggestion.value)}
                                >
                                    <div className="topic">{suggestion.value}</div>
                                    <div className="add">+ Add</div>
                                </div>
                            ))
                        )}
                    </div>
                )}
            </div>
            {sideFilter && (
                <div className="filterMultiOptions">
                    {currentData.map((item, index) => {
                        const closeIcon = (
                            <div onClick={() => onDelete(index)} className="filterBar-closeIcon">
                                +
                            </div>
                        );
                        return (
                            <div key={item + index + 'filtermulti'}>
                                {closeIcon} <div className="multiOptionLabel">{item}</div>
                            </div>
                        );
                    })}
                </div>
            )}
        </>
    );
};

export default TopicTensorInput;
