import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    saveFavorites,
    createFavorites,
    allFavoritesName
} from '../../../store/actions/favoriteActions';

/*
    props
    - show
    - editedList  (when it's name editing)
    - closeFunction
    - userId
 */

class CreateOrEditFavoriteList extends Component {

    constructor(props) {
        super(props);

        this.cleanState = {
            listNameError: null,
            listNameCanBeSaved: false,
            newName: '',
            originalName: '',
            showStored: false,
            loadingStored: false
        }

        this.state = this.cleanState;

        this.timeout = null;
    }

    // returns error message or null
    checkNewListName = (tx) => {
        let inUseMessage = 'Name is already in use'

        if (tx.length < 8) {
            return 'Your List Name must be at least 8 letters'
        }
        for (let list of this.props.favoriteLists) {
            if (this.props.editedList && this.props.editedList.id === list.id) {
                continue;
            }
            if (!list.combined && list.name.toLowerCase() === tx.toLowerCase()) {
                return inUseMessage;
            }
        }
        if (tx.toLowerCase() === allFavoritesName.toLowerCase()) {
            return inUseMessage;
        }
        return null;
    }

    saveButtonHandler = () => {
        if (!this.state.listNameCanBeSaved) {
            return;
        }
        if (!this.props.editedList) {
            // create new list with the current influencer in it
            this.props.createFavorites({
                name: this.state.newName,
                users: [this.props.userId]
            });
        } else {
            let newFav = this.props.editedList;
            newFav.name = this.state.newName;
            this.props.saveFavorites(newFav.id, newFav);
        }
    }

    handleNewListNameChange = (e) => {
        let delay = 500;
        clearTimeout(this.timeout);
        let newName = e.target.value;
        this.setState({
            listNameError: null,
            newName : newName
        })
        this.timeout = setTimeout(() => {
            let errorMessage = this.checkNewListName(newName);
            this.setState({
                listNameError: errorMessage,
                listNameCanBeSaved: (newName.length > 0 && !errorMessage),
            })
        }, delay)
    }

    static getDerivedStateFromProps(props, state) {
        let newState = {};
        if ( state.loadingStored && !props.loading) {
            // api finished
            props.closeFunction();
        }
        if (!state.showStored && props.show) {
            // just opening
            if (props.editedList) {
                newState.newName = props.editedList.name;
                newState.originalName = props.editedList.name;
            }
        }
        newState.loadingStored = props.loading;
        newState.showStored = props.show;
        return newState;
    }

    render() {
        if (!this.props.show) {
            return null;
        }
        let saveButtonClassName = "button";
        if (!this.state.listNameCanBeSaved) {
            saveButtonClassName += " disabled";
        }
        return (
            <div onClick={(e)=>{e.stopPropagation()}}
                 style={{
                     backgroundColor: '#f6f6f6',
                     paddingTop: 10
                 }}>
                <div className="newNameInput"><div>
                    <input
                        placeholder="Name for list"
                        onChange={this.handleNewListNameChange}
                        value={this.state.newName}
                    />
                    <div className="inputError">
                        {this.state.listNameError}
                    </div>
                </div></div>
                <div className="buttons">
                    <div className={saveButtonClassName} onClick={this.saveButtonHandler}>
                        { this.props.editedList ? 'Save' : 'Create' }
                    </div>
                    <div className="button cancel" onClick={this.props.closeFunction}>Cancel</div>
                    <div style={{clear:'both'}}/>
                </div>
            </div>

        );
    }
}

function mapStateToProps(state){
    return {
        favoriteLists : state.FAVORITE_REDUCER.favoriteData,
        loading: state.FAVORITE_REDUCER.favoriteLoading
    }
}

function mapDispatchToProps(dispatch){
    return {
        saveFavorites: (favId, fav) => dispatch(saveFavorites(favId, fav)),
        createFavorites: fav => dispatch(createFavorites(fav))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrEditFavoriteList);
