import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import cx from 'classnames';
import { postTypeMap } from '../posts';
import { StarsIcon } from '../AutoDetectedPopup/AutoDetectedPopup';
import PostUpdateSuccessModal from '../PostUpdateSuccessModal/PostUpdateSuccessModal';
import Api from '../../../../modules/Api';
import './updatePostURLModal.scss';

export default function UpdatePostURLModal({ isOpen, close, data, refetchData, assignmentId, userId, isUserCreator }) {
    const [isSuccessOpen, setIsSuccessOpen] = useState(false);

    const originalPosts = data
        ? [
              ...(isUserCreator ? [{ ...data?.post, isAutoDetected: data?.isAutoDetected }] : data?.posts || []),
              ...(data?.autoDetectedPosts?.map((post) => ({ ...post, isAutoDetected: true })) || []),
          ]
        : [];

    const [posts, setPosts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const creatorName = `${data?.userFirstName} ${data?.userLastName ?? ''}`;
    const creatorType =
        data?.posts?.[0]?.connectionType === 'PUBLIC' || data?.autoDetectedPosts?.[0]?.connectionType === 'PUBLIC'
            ? 'Public creator'
            : 'Connected creator';

    useEffect(() => {
        setPosts(originalPosts);
    }, [data]);

    const isDataChanged =
        posts.find((p) => p.isArchived) ||
        posts.find(
            (p) =>
                p.originalUrl !==
                originalPosts.find((origP) => p.userPublishedPostId === origP.userPublishedPostId)?.originalUrl,
        );

    const allUrls = posts.map((p) => p.originalUrl);
    const containsDuplicates = new Set(allUrls).size !== allUrls.length;
    const isEmpty = !posts.reduce((prev, curr) => (prev += curr.isArchived ? 0 : 1), 0);

    const creatorSubmit = () => {
        const post = posts[0];
        const updateFunc = post.isArchived ? Api.publishedPostCreatorArchive : Api.publishedPostCreatorUpdate;
        const body = post.isArchived ? undefined : { url: post.originalUrl };

        setIsLoading(true);
        updateFunc(post.userPublishedPostId, body)
            .then(() => {
                setIsLoading(false);
                close();
                setIsSuccessOpen(true);
            })
            .catch(() => setIsLoading(false));
    };

    const onSubmit = () => {
        if (isUserCreator) {
            creatorSubmit();
            return;
        }

        const body = [];
        posts.forEach((post) => {
            if (post.isArchived) {
                body.push({
                    isArchived: true,
                    publicUserPublishedPostId: post.userPublishedPostId,
                });
            }

            const isUrlUpdated =
                post.originalUrl !==
                originalPosts.find((p) => p.userPublishedPostId === post.userPublishedPostId).originalUrl;

            if (isUrlUpdated) {
                body.push({
                    contentUrl: post.originalUrl,
                    publicUserPublishedPostId: post.userPublishedPostId,
                });
            }
        });

        setIsLoading(true);
        if (data.assignmentPublicUserId || userId) {
            Api.publishedPostPublicUpdate(data.assignmentId || assignmentId, data.assignmentPublicUserId || userId, body)
            .then(() => {
                setIsLoading(false);
                close();
                setIsSuccessOpen(true);
            })
            .catch(() => setIsLoading(false));
        } else {
            const promises = []
            body.forEach(item => {
                if (item.isArchived) {
                    promises.push(Api.publishedPostCreatorArchive(item.publicUserPublishedPostId))
                } else {
                    promises.push(Api.publishedPostCreatorUpdate(item.publicUserPublishedPostId, {url: item.contentUrl}))
                }
            })
            Promise.all(promises).then(() => {
                setIsLoading(false);
                close();
                setIsSuccessOpen(true);
            }).catch(() => setIsLoading(false));
        }
    };

    return (
        <>
            <Modal overlayClassName="modalDialog" className="auto-detected-update-modal" isOpen={isOpen}>
                <h3 className="modal-title">Update Post URL</h3>

                {!isUserCreator && (
                    <>
                        <div className="creator-wrapper">
                            <span className="creator-label">Creator</span>
                            <div className="creator-name-wrapper">
                                <span className="name">{creatorName}</span>
                                <span className="creator-type">({creatorType})</span>
                            </div>
                        </div>

                        <div className="post-url-wrapper">
                            <div className="post-url-label">Post URL</div>
                        </div>
                    </>
                )}

                {isEmpty && <div className="empty-label">No post URL found.</div>}

                <div className="post-url-list">
                    {posts.map((post) => (
                        <PostUrlSection
                            key={post.id || post.userPublishedPostId}
                            post={post}
                            posts={posts}
                            updatePosts={setPosts}
                        />
                    ))}
                </div>

                <div className="modal-footer">
                    <button className="close-btn" onClick={close}>
                        Close
                    </button>
                    <button
                        className="update-btn"
                        onClick={onSubmit}
                        disabled={!isDataChanged || containsDuplicates || isLoading}
                    >
                        Update
                    </button>
                </div>
            </Modal>
            <PostUpdateSuccessModal
                isOpen={isSuccessOpen}
                close={() => {
                    setIsSuccessOpen(false);
                    refetchData();
                }}
            />
        </>
    );
}

function PostUrlSection({ post, posts, updatePosts }) {
    const isAutoDetected = post.isAutoDetected;
    const isDuplicate = posts.find(
        (p) => p.originalUrl === post.originalUrl && p.userPublishedPostId !== post.userPublishedPostId,
    );

    const handleArchive = () => {
        updatePosts((prev) =>
            prev.map((p) => (p.userPublishedPostId === post.userPublishedPostId ? { ...p, isArchived: true } : p)),
        );
    };

    const handleUrlChange = (val) => {
        updatePosts((prev) =>
            prev.map((p) => (p.userPublishedPostId === post.userPublishedPostId ? { ...p, originalUrl: val } : p)),
        );
    };

    if (post.isArchived) return null;

    return (
        <div className={cx('post-url-section', isAutoDetected && 'auto-detected-url')}>
            {isAutoDetected && (
                <div className="auto-detect-link">
                    <StarsIcon />
                    Auto-detect link
                </div>
            )}
            <div className="post-channel-wrapper">
                <div className="post-channel">{postTypeMap[post.postType]?.label}</div>
                {isAutoDetected && (
                    <button className="archive-btn" onClick={handleArchive}>
                        Archive
                    </button>
                )}
            </div>
            <div className="post-url-input-label">
                <div className="post-url-label">Post URL</div>
                <div className="required-label">*Required</div>
            </div>
            <input
                className={cx('url-input', isDuplicate && 'input-invalid')}
                value={post.originalUrl || ''}
                onChange={(e) => handleUrlChange(e.target.value)}
            />
            {isDuplicate && <p className="error-msg">This URL is already in use. Please validate again.</p>}
        </div>
    );
}
