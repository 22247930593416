import React from 'react';
import ReachIcon from '../../shared/ReachIcon/ReachIcon';
import PropTypes from 'prop-types';
import { getNetworkByChannel, getNetworkNameByChannel } from './Analytics.helper';
import moment from "moment";
import Format from '../../../modules/utils/Format';

export default class AnalyticspostDisplay extends React.Component {
    render() {
        const {
            data: {
                name,
                channel,
                publishDate,
                reach,
                views,
                engagement,
                likes,
                comments,
                postType,
                influencerName,
                url,
                saves,
                shares,
                brandedCommentCount
            },
        } = this.props;
        const brandedEngagement = (brandedCommentCount || 0) + (shares || 0) + (saves || 0)
        const brandedEngagementPercent = brandedEngagement/comments
        return (
            <div className="tablePost">
                <div className="post">
                    <div>{influencerName || '-'}</div>
                </div>
                <div className="channel">
                    {channel && <ReachIcon network={getNetworkByChannel(channel)} value={1} size={8} padding={4} noToolTip />}
                </div>
                <div className="type">{postType.toLowerCase() || '-'}</div>
                <div className="date">{publishDate ? moment(publishDate).format("YYYY-MM-DD") : '-'}</div>
                <div className="reach">{reach ? Format.expressInK(reach) : '0'}</div>
                <div className="views">{views ? Format.expressInK(views) : '0'}</div>
                <div className="engagement">{engagement ? Format.expressInK(engagement) : '0'}</div>
                <div className="brandedengagement">{Format.expressInK(brandedEngagement) }</div>
                <div className="brandedengagementpercent">{Format.percentage2(brandedEngagementPercent * 100)}</div>
                <div className="likes">{likes ? Format.expressInK(likes) : '0'}</div>
                <div className="comments">{comments ? Format.expressInK(comments) : '0'}</div>
                <div className="shares">{shares ? Format.expressInK(shares) : '0'}</div>
                <div className="saves">{saves ? Format.expressInK(saves) : '0'}</div>
                <div className="postLink"><a href={url} target="_blank"><img src="/images/link.svg" /></a></div>
            </div>
        );
    }
}

AnalyticspostDisplay.propTypes = {
    data: PropTypes.shape({
        name: PropTypes.string,
        channel: PropTypes.string,
        publishDate: PropTypes.string,
        url: PropTypes.string,
        reach: PropTypes.number,
        views: PropTypes.number,
        engagement: PropTypes.number,
        likes: PropTypes.number,
        comments: PropTypes.number,
        postType: PropTypes.string,
        influencerName: PropTypes.string,
    }),
};

AnalyticspostDisplay.defaultProps = {
    data: {
        name: '',
        channel: '',
        publishDate: '',
        url: '',
        reach: null,
        views: null,
        engagement: null,
        likes: null,
        comments: null,
        postType: '',
        influencerName: '',
    },
};
