import React, {useState, useEffect} from 'react';
import Modal from 'react-modal';
import { FilePond } from 'react-filepond';
import {Scrollbars} from "react-custom-scrollbars"
import Spinner from '../spinner';
import Api from '../../../../modules/Api';
import 'filepond/dist/filepond.min.css';
import './otherDocumentsModal.scss';
import OtherDocument from './OtherDocument';
import Format from '../../../../modules/utils/Format';

const OtherDocumentsModal = ({ isOpen, contractStatusData, assignmentId, uid, onClose }) => {

    const [assignmentUser, setAssignmentUser] = useState(null);
    const [preparedFiles, setPreparedFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [resourceToDelete, setResourceToDelete] = useState(null);

    useEffect(() => {
        if (isOpen)
            loadAssignment();
    }, [isOpen]);

    const loadAssignment = async () => {
        setIsLoading(true);
        if (!contractStatusData.isPublic) {
            Api.getAssignmentUser(assignmentId, uid).then((data) => {
                setAssignmentUser(data);
                setIsLoading(false);
            }).catch((err) => {
                console.error('Error while fetching AssignmentUser: ', err);
                setIsLoading(false);
            });
        }  else {
            Api.getAssignmentUserPublic(assignmentId, uid).then((data) => {
                setAssignmentUser(data);
                setIsLoading(false);
            }).catch((err) => {
                console.error('Error while fetching AssignmentPublicUser: ', err);
                setIsLoading(false);
            });
        }
    }

    const downloadFile = (res) => {
        const updateFileFinished = (response) => {
            const ext = response.data && response.data.type ? Format.mimeToExt(response.data.type) : '';
            const fileName = res.name==='File' ? `File${ext}` : res.name;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        };
        Api.getS3FileGradually(res.url, () => {}, (response) => updateFileFinished(response));
    }

    const onSave = () => {
        let currentOtherDocumentIds = [];
        if (assignmentUser && assignmentUser.otherDocuments && assignmentUser.otherDocuments.length > 0) {
            currentOtherDocumentIds = assignmentUser.otherDocuments.map(d => d.id);
        }

        if (preparedFiles && preparedFiles.length > 0) {
            const promises = [];
            preparedFiles.forEach((fileItem) => {
                const file = fileItem.file;
                promises.push(Api.uploadResource(file, true, null, null, () => {}, () => {}));
            });
            setIsLoading(true);
            Promise.all(promises).then((responses) => {
                const newIds = responses.map(r => r.id);
                onOtherDocumentsUpdate(currentOtherDocumentIds.concat(newIds));
            }).catch((e) => {
                console.error("File upload error: " + e);
                setIsLoading(false);
            });
        }
    }

    const onDelete = () => {
        let currentOtherDocumentIds = [];
        if (assignmentUser && assignmentUser.otherDocuments && assignmentUser.otherDocuments.length > 0) {
            currentOtherDocumentIds = assignmentUser.otherDocuments.map(d => d.id);
        }
        if (resourceToDelete && currentOtherDocumentIds && currentOtherDocumentIds.length > 0 && currentOtherDocumentIds.includes(resourceToDelete.id)) {
            currentOtherDocumentIds = currentOtherDocumentIds.filter(id => id !== resourceToDelete.id);
            onOtherDocumentsUpdate(currentOtherDocumentIds);
            setResourceToDelete(null);
        }
    }

    const onOtherDocumentsUpdate = (currentOtherDocumentIds) => {
        setIsLoading(true);
        if (!contractStatusData.isPublic) {
            Api.updateOtherDocuments(assignmentUser.id, currentOtherDocumentIds).then((data) => {
                setAssignmentUser(data);
                setIsLoading(false);
                setPreparedFiles([]);
            }).catch((err) => {
                console.error('Error while updating AssignmentPublicUser: ', err);
                setIsLoading(false);
            });
        } else {
            Api.inviteInfluencerUpdatePublic(assignmentId, uid, {otherDocumentsResourceIds: currentOtherDocumentIds}).then((data) => {
                setAssignmentUser(data);
                setIsLoading(false);
                setPreparedFiles([]);
            }).catch((err) => {
                console.error('Error while updating AssignmentPublicUser: ', err);
                setIsLoading(false);
            });
        }
    }

    const renderFileList = () => {
        if (!assignmentUser || !assignmentUser.otherDocuments || assignmentUser.otherDocuments.length === 0)
            return null;

        return (
            <div className="otherDocumentsInnerContentDocumentList">
                {assignmentUser.otherDocuments.map(od => 
                    <OtherDocument 
                        key={od.id} 
                        resource={od} 
                        onDelete={() => setResourceToDelete(od)}
                        onDownload={() => downloadFile(od)}/>
                )}
            </div>
        );
    }

    const renderFilepond = () => {
        return (
            <FilePond
                allowMultiple={true}
                acceptedFileTypes={[
                    'application/pdf',
                    'application/msword',
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                    'application/vnd.ms-excel',
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'text/csv',
                    'application/vnd.ms-powerpoint',
                    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                    'text/plain',
                    'image/jpeg',
                    'image/png',
                    'image/gif',
                    'application/zip'
                  ].join(', ')}
                onupdatefiles={setPreparedFiles}
                name="file"
                labelIdle='Drag & Drop Your Document File or <span class="filepond--label-action">Browse</span>'
            />
        );
    };

    const renderDeleteConfirmation = () => {
        if (!resourceToDelete)
            return null;

        return (
            <div className="saveWarning">
                <div className="sWContent">
                    <div className="sWMsg">
                        <span>Confirm</span>
                        <p>
                            Please confirm the removal of document {resourceToDelete.name}.
                        </p>
                        <p>
                            This operation is irreversable.
                        </p>
                    </div>
                    <div className="sWBar">
                        <div className="button cancel" style={{marginRight: 0}} onClick={() => setResourceToDelete(null)}>Cancel</div>
                        <div className='button save' style={{marginRight: 0}} onClick={onDelete}>Confirm</div>
                    </div>
                </div>
            </div>
        );
    }

    const renderContent = () => {
        if (!assignmentUser && isLoading) {
            return (
                <div className="otherDocumentsContainer">
                    <Spinner className="otherDocumentsFade" label="Loading"/>
                </div>
            );
        } else if (!assignmentUser) {
            return "Data fetch error!";
        } else {
            const currency = (assignmentUser.assignment && assignmentUser.assignment.campaign && assignmentUser.assignment.campaign.currencyCode) 
            ? assignmentUser.assignment.campaign.currencyCode 
            : null;
            return (
                <div className="otherDocumentsContainer">
                    <div className="otherDocumentsTitle">
                        Other Documents
                    </div>
                    <div className="otherDocumentsContent">
                        <Scrollbars style={{ width:'100%', height: '500px' }}>
                            <div className="otherDocumentsInnerContent">
                                <div className="otherDocumentsInnerContentAssignmentInfo">
                                    <div className="otherDocumentsInnerContentLabels">
                                        <div className="otherDocumentsInnerContentLabel">
                                            Campaign:
                                        </div>
                                        <div className="otherDocumentsInnerContentLabel">
                                            Assignment:
                                        </div>
                                        <div className="otherDocumentsInnerContentLabel">
                                            Creator:
                                        </div>
                                        { currency &&   
                                            <div className="otherDocumentsInnerContentLabel">
                                                Currency:
                                            </div>
                                        }
                                    </div>
                                    <div className="otherDocumentsInnerContentValues">
                                        <div className="otherDocumentsInnerContentValue">
                                            {contractStatusData.campaignName ? contractStatusData.campaignName : "-"}
                                        </div>
                                        <div className="otherDocumentsInnerContentValue">
                                            {contractStatusData.assignmentName ? contractStatusData.assignmentName : "-"}
                                        </div>
                                        <div className="otherDocumentsInnerContentValue">
                                            {contractStatusData.userName ? contractStatusData.userName : "-"}
                                        </div>
                                        { currency &&                                      
                                            <div className="otherDocumentsInnerContentValue">
                                                {currency}
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="otherDocumentsInnerContentFileUpload">
                                    <div className="otherDocumentsInnerContentFileUploadTitle">
                                        Upload Documents
                                    </div>
                                    <div className="otherDocumentsWarning">Click Save to finish adding the additional document. The file will be lost if the window is closed without saving it.</div>
                                    {isLoading ? <Spinner className="otherDocumentsFade" label="Loading"/> : renderFilepond()}
                                </div>
                                {renderFileList()}
                            </div>
                        </Scrollbars>
                    </div>
                    <div className="otherDocumentsInnerContentButtons">
                        <div className="button cancel" onClick={onClose}>
                            Cancel
                        </div>
                        { preparedFiles && preparedFiles.length > 0 && !isLoading && 
                            <div className="button save" onClick={onSave}>
                                Save
                            </div>
                        }
                    </div>
                    {renderDeleteConfirmation()}
                </div>
            );
        }
    }
    
    return (
        <Modal  closeTimeoutMS={300}
                overlayClassName="otherDocumentsOverlay"
                className="otherDocumentsModal"
                isOpen={isOpen}>
            {renderContent()}
        </Modal>
    );
}

export default OtherDocumentsModal;