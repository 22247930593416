import React, { useState } from 'react';
import Checkbox from '../../../shared/checkbox2022/Checkbox';
import Api from '../../../../modules/Api';
import { FILE_FORMATS } from '../helpers';
import PostAssetsModal from '../PostAssetsModal/PostAssetsModal';
import PleaseWait from '../../../shared/pleaseWait/PleaseWaitWhite';
import { handleFileDownload } from '../../../shared/DownloadLink/DownloadLink';
import InfluencerPhoto from '../../../oneInfluencer2022/InfluencerPhoto';
import './complianceTable.scss';

const headers = [{ title: 'Name' }, { title: 'Channel' }, { title: 'Post' }];

const channelIcons = {
    INSTAGRAM_POST: {
        icon: '/images/IG.svg',
        title: 'Instagram post',
    },
    FACEBOOK_POST: {
        icon: '/images/FB.svg',
        title: 'Facebook post',
    },
    YOUTUBE: {
        icon: '/images/rate-video.svg',
        title: 'YouTube post',
    },
    TIKTOK_POST: {
        icon: '/images/ic-rate-tiktok.svg',
        title: 'TikTok post',
    },
    BLOG_POST: {
        icon: '/images/rateIcons3/Blog_list.svg',
        title: 'Blog post',
    },
    TWITTER_POST: {
        icon: '/images/rate-twitter.svg',
        title: 'Twitter post',
    },
    SOCIAL_SHARE: {
        icon: '/images/rate-share.svg',
        title: 'Social Share',
    },
    INSTAGRAM_STORY: {
        icon: '/images/rateIcons3/Blog_story.svg',
        title: 'Instagram Story',
    },
};

export default function ComplianceTable({ selectedIds, toggleSelectRow, data }) {
    const [isDownloading, setIsDownloading] = useState(false);

    const [postData, setPostData] = useState(null);
    const openAssetsModal = (postData) => setPostData(postData);

    const groupedAssignmentsData = data
        .filter((user) => user.posts.length || user.autoDetectedPosts.length)
        .reduce((prev, curr) => {
            return {
                ...prev,
                [curr.assignmentId]: {
                    ...prev[curr.assignmentId],
                    assignmentName: curr.assignmentName,
                    users: [...(prev[curr.assignmentId]?.users || []), curr],
                },
            };
        }, []);

    return (
        <div className="assignments-tables-wrapper">
            {Object.keys(groupedAssignmentsData).map((assignmentId) => (
                <div key={assignmentId} className="compliance-table-wrapper">
                    <div className="assignment-title">{groupedAssignmentsData[assignmentId].assignmentName}</div>

                    <div className="compliance-table-header">
                        <div />
                        {headers.map((header, idx) => (
                            <div key={idx} className="header-column">
                                {header.title}
                            </div>
                        ))}
                    </div>
                    <div className="compliance-table-rows">
                        {groupedAssignmentsData[assignmentId].users.map((row) => (
                            <ComplianceRow
                                key={row.assignmentPublicUserId}
                                data={row}
                                isSelected={selectedIds.find((id) => id === row.id)}
                                toggleSelectRow={toggleSelectRow}
                                openAssetsModal={openAssetsModal}
                                setIsDownloading={setIsDownloading}
                            />
                        ))}
                    </div>
                </div>
            ))}
            <PostAssetsModal isOpen={!!postData} close={() => setPostData(null)} postData={postData} />
            <PleaseWait show={isDownloading} top={157} />
        </div>
    );
}

function ComplianceRow({ data, toggleSelectRow, isSelected, openAssetsModal, setIsDownloading }) {
    const userName = `${data.userFirstName} ${data.userLastName ?? ''}`;
    const userType =
        data.posts?.[0]?.connectionType === 'PUBLIC' || data.autoDetectedPosts?.[0]?.connectionType === 'PUBLIC'
            ? 'Public Creator'
            : 'Connected Creator';

    const handleDownloadAssets = (postUrl) => {
        setIsDownloading(true);
        Api.getPostAssets({ postUrls: [postUrl] })
            .then((res) => {
                const allAssets = res
                    .map((post) => post.media)
                    .flat()
                    .map((media) => ({ url: media.url, format: FILE_FORMATS[media.mediaType] }));
                handleFileDownload(allAssets, 'assets', setIsDownloading);
            })
            .catch((e) => console.error(e));
    };
    return (
        <div className="compliance-table-row">
            <div className="select-row">
                {/* <Checkbox
                    size={20}
                    checked={isSelected}
                    changeFunction={(isChecked) => toggleSelectRow(!isChecked, data.id)}
                    controlled
                    fixedSize
                /> */}
            </div>

            <div className="user-wrapper">
                <InfluencerPhoto
                    className="user-picture"
                    url={data.userPictureUrl}
                    firstName={data.userFirstName}
                    lastName={data.userLastName}
                />
                <div className="name-wrapper">
                    <div className="user-name">{userName}</div>
                    <div className="user-type">{userType}</div>
                </div>
            </div>

            <div className="user-posts">
                {!!data &&
                    [...data.posts, ...data.autoDetectedPosts].map((post) => (
                        <div className="post-row" key={post.userPublishedPostId}>
                            <div className="channel-wrapper">
                                <img src={channelIcons[post.postType]?.icon} className="channel-icon" />
                                <span className="channel-label">{channelIcons[post.postType]?.title}</span>
                            </div>

                            <div className="post-info">
                                {(post.postType === "INSTAGRAM_POST" || post.postType === "YOUTUBE" || post.postType === "TWITTER_POST" || post.postType === "INSTAGRAM_STORY" || post.postType === "TIKTOK_POST") && <div
                                    className="post-info-link"
                                    onClick={() =>
                                        openAssetsModal({
                                            user: { userName, userType, userPictureUrl: data.userPictureUrl },
                                            postUrl: post.originalUrl,
                                        })
                                    }
                                >
                                    Post Assets
                                </div>}
                                <a
                                    href={post.originalUrl}
                                    target="__blank"
                                    className="post-info-link"
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    Post URL
                                </a>
                            </div>

                            {/* {idx === 0 && (
                            <DownloadLink
                                files={allFiles}
                                fileName="assets"
                            >
                                <div className="download-wrapper">
                                    <button className="download-btn">Download All</button>
                                </div>
                            </DownloadLink>
                        )} */}
                        </div>
                    ))}
            </div>
        </div>
    );
}
