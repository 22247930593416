import {
    EXCLUSIONS_CREATE,
    EXCLUSIONS_READ,
    EXCLUSIONS_UPDATE,
    EXCLUSIONS_DELETE,
    EXCLUSION_LOADING,
    EXCLUSION_ERROR,
    EXCLUSION_SUCCESS
} from '../types';

import { EXCLUSION_REDUCER } from './_Names';
export const name = EXCLUSION_REDUCER;

export const exclusionReducer = ( state= {} , action) => {
    switch(action.type){
        case EXCLUSION_LOADING:
            return {
                ...state,
                exclusionLoading: action.isLoading
            }
        case EXCLUSION_ERROR:
            return {
                ...state,
                exclusionError: action.err
            }
        case EXCLUSION_SUCCESS:
            return {
                ...state,
                successExclusions: action.success
            }
        case EXCLUSIONS_UPDATE: {
            let exclusionLists = state.exclusionData;
            let newLists = [];
            exclusionLists.forEach(ex => {
                if(ex.id === action.exclusion.id) {
                    newLists.push(action.exclusion);
                }else {
                    newLists.push(ex);
                }
            })

            return {
                ...state,
                exclusionData: newLists,
            }
        }
        case EXCLUSIONS_DELETE:
            return{
                ...state,
                exclusionData: state.exclusionData.filter(exclusion => exclusion.id !== action.exclusionId)
            }
        case EXCLUSIONS_CREATE:
            return{
                ...state,
                exclusionData: [...state.exclusionData, {...action.exclusion, completed: true}]
            }
        case EXCLUSIONS_READ: {
            return {
                ...state,
                exclusionData: action.exclusions
            }
        }
        default:
            return state;
    }
}