import React from 'react';
import Tippy from '@tippyjs/react';
import { ResponsiveContainer, BarChart, Bar, LabelList, XAxis, YAxis } from 'recharts';
import { formatPercentageToWhole } from '../../../../modules/utils/helpers';
import EmptyCard from './EmptyCard';
import './analyticsBySentiment.scss';
import './table.scss';

function SentimentByTopic({ topicData }) {
    const sentimentByTopicData = topicData
        ? Object.keys(topicData)
              .map((topicName) => ({
                  name: topicName,
                  positive: topicData[topicName].positiveCount,
                  neutral: topicData[topicName].neutralCount,
                  negative: topicData[topicName].negativeCount,
                  total:
                      topicData[topicName].positiveCount +
                      topicData[topicName].neutralCount +
                      topicData[topicName].negativeCount,
              }))
              .sort((a, b) => {
                  if (a.total < b.total) {
                      return 1;
                  } else if (a.total > b.total) {
                      return -1;
                  }
                  return 0;
              })
              .slice(0, 5)
        : [];

    const graphHeight = 62 * sentimentByTopicData.length;
    const categoryCharLimit = 22;

    const getTotal = (columnName) => {
        const column = sentimentByTopicData.find((col) => col.name === columnName);
        return column.positive + column.neutral + column.negative;
    };

    const customTick = ({ y, payload }) => {
        const categoryText =
            payload.value.length > categoryCharLimit
                ? `${payload.value.slice(0, categoryCharLimit)}...`
                : payload.value;
        return (
            <g transform={`translate(${0},${y})`}>
                <text x={0} y={0} textAnchor="start" fill="#666" style={{ fontWeight: 'bold' }} dy={5}>
                    {categoryText}
                </text>
            </g>
        );
    };

    const customLabel = (props, isPositive = false, isNeutral = false, isNegative = false) => {
        const { x, y, value, name, width, height } = props;
        const total = getTotal(name);
        const translateY = isNeutral && width < 70 ? y + height : y - height - 2;
        let translateX = width < 50 ? x + 50 : x + width;

        return value ? (
            <g transform={`translate(${translateX},${translateY})`}>
                <text x={0} y={0} dy={14} textAnchor="end" fill="#666" fontSize={14}>
                    <tspan style={{ fill: 'black', fontWeight: 'bold' }}>
                        {formatPercentageToWhole((value / total) * 100)}%
                    </tspan>
                    <tspan style={{ fill: '#7C7C7C' }}> ({value})</tspan>
                </text>
            </g>
        ) : null;
    };

    const totalCustomLabel = (props) => {
        const { x, y, name, width, height } = props;
        const total = getTotal(name);

        return (
            <text x={x + width + 8} y={y} dy={height - 5} fill="#7C7C7C">
                {total}
            </text>
        );
    };

    const valueAccessor =
        (attribute) =>
        ({ payload }) =>
            payload[attribute];

    return (
        <div className="card sentiment-by-topic">
            <div className="card-title">
                <div>Sentiment By Topic</div>
                <div className="info-tooltip-icon">
                    <Tippy
                        className="text-tooltip"
                        content="Sentiment of comments from selected posts within the campaign aggregated by the five most popular topics. Total may not always equal 100% due to rounding."
                    >
                        <img src="/images/tooltipUser.svg" />
                    </Tippy>
                </div>
            </div>
            <div className="sentiment-by-topic-content">
                {sentimentByTopicData.length ? (
                    <ResponsiveContainer width="100%" height={graphHeight} minWidth="100%">
                        <BarChart
                            data={sentimentByTopicData}
                            layout="vertical"
                            margin={{
                                top: 20,
                                right: 80,
                                left: 160,
                                bottom: 5,
                            }}
                        >
                            <XAxis type="number" domain={[0, 'dataMax']} hide />
                            <YAxis type="category" dataKey="name" tick={customTick} axisLine={false} tickLine={false} />
                            <Bar
                                dataKey="positive"
                                barSize={18}
                                stackId="a"
                                fill="#75d551"
                                radius={[3, 0, 0, 3]}
                                isAnimationActive={false}
                            >
                                <LabelList
                                    dataKey="positive"
                                    position="top"
                                    content={(props) => customLabel(props, true)}
                                    valueAccessor={valueAccessor('0')}
                                />
                            </Bar>
                            <Bar dataKey="neutral" barSize={18} stackId="a" fill="#ffbe16" isAnimationActive={false}>
                                <LabelList
                                    dataKey="neutral"
                                    position="top"
                                    content={(props) => customLabel(props, false, true)}
                                    valueAccessor={valueAccessor('1')}
                                />
                            </Bar>
                            <Bar
                                dataKey="negative"
                                barSize={18}
                                stackId="a"
                                fill="#fd625e"
                                radius={[0, 3, 3, 0]}
                                isAnimationActive={false}
                            >
                                <LabelList
                                    dataKey="negative"
                                    position="top"
                                    content={(props) => customLabel(props, false, false, true)}
                                    valueAccessor={valueAccessor('2')}
                                />
                                <LabelList dataKey="negative" position="right" content={totalCustomLabel} />
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                ) : (
                    <EmptyCard title="No Topic Available" subtitle="Post(s) has no topics to display." />
                )}
            </div>
        </div>
    );
}

export default SentimentByTopic;
