import React, {useState, useEffect} from 'react';
import './KeywordGroups.scss';
import moment from "moment";
import qs from "qs";
import TopNavigation from "../shared/topNavigation/TopNavigation";
import BreadCrumbs from "./BreadCrumbs";
import BrandList from "./BrandList";
import CreateKeywordGroupModal from "./CreateKeywordGroupModal";
import RenameKeywordGroupModal from "./RenameKeywordGroupModal";
import KeywordGroupDetails from "./KeywordGroupDetails";
import KeywordGroupList from "./KeywordGroupList";
import Api from '../../modules/Api';
import PleaseWaitWhite from "../shared/pleaseWait/PleaseWaitWhite";
import { VIEWER_STR } from '../../constants/authorities';

const KeywordGroupsPage = (props) => {

    const [isFetching, setIsFetching] = useState(false);
    const [isCreateGroupModalVisible, setIsCreateGroupModalVisible] = useState(false);
    const [isEditGroupModalVisible, setIsEditGroupModalVisible] = useState(false);
    const [brands, setBrands] = useState(null);
    const [keywordGroups, setKeywordGroups] = useState(null);
    const [keywordGroupId, setKeywordGroupId] = useState((props.match && props.match.params) ? props.match.params.keywordGroupId : null);
    const [brandId, setBrandId] = useState((props.match && props.match.params) ? props.match.params.brandId : null);
    const [isMoreButtonMenuOpen, setIsMoreButtonMenuOpen] = useState(false);

    const querySearchParams = qs.parse(props.history.location.search.replace("?",""));
    const kgPagination = querySearchParams.keywordGroupsPagination;
    const [keywordGroupsPagination, setKeywordGroupsPagination] = useState({
        page: kgPagination && kgPagination.page ? parseInt(kgPagination.page) : 1,
        pageLimit: kgPagination && kgPagination.pageLimit ? parseInt(kgPagination.pageLimit) : 10,
        sortField: kgPagination && kgPagination.sortField ? kgPagination.sortField : "groupName",
        sortDirection: kgPagination && kgPagination.sortDirection ? kgPagination.sortDirection : "ASC"
    });

    const bPagination = querySearchParams.brandsPagination;
    const [brandsPagination, setBrandsPagination] = useState({
        page: bPagination && bPagination.page ? parseInt(bPagination.page) : 1,
        pageLimit: bPagination && bPagination.pageLimit ? parseInt(bPagination.pageLimit) : 10,
        sortField: bPagination && bPagination.sortField ? bPagination.sortField : "brandName",
        sortDirection: bPagination && bPagination.sortDirection ? bPagination.sortDirection : "ASC"
    });

    const isReadOnly = () => {
        return props.authCheckData && props.authCheckData.user && props.authCheckData.user.role === VIEWER_STR;
    }

    useEffect(() => {
        fetchBrands();
    }, [brandsPagination.page, brandsPagination.pageLimit, brandsPagination.sortField, brandsPagination.sortDirection]);

    useEffect(() => {
        if (brandId)
            fetchKeywordGroups();
    }, [brandId, keywordGroupsPagination.page, keywordGroupsPagination.pageLimit, keywordGroupsPagination.sortField, keywordGroupsPagination.sortDirection]);

    const fetchBrands = (page, pageLimit, sortBy, sortDirection) => {
        setIsFetching(true);
        Api.getKeywordGroupBrandsInfo(
            brandsPagination.page,
            brandsPagination.pageLimit,
            brandsPagination.sortField,
            brandsPagination.sortDirection
        ).then(response => {
            setBrands(response.content);
            onBrandsPaginationChange({totalRecords: response.totalElements});
            setIsFetching(false);
        }).catch(error => {
            setIsFetching(false);
            console.log("Error while fetching brands: ", error);
        });
    };

    const fetchKeywordGroups = () => {
        setIsFetching(true);
        Api.getKeywordGroups(
            brandId,
            keywordGroupsPagination.page,
            keywordGroupsPagination.pageLimit,
            keywordGroupsPagination.sortField,
            keywordGroupsPagination.sortDirection
        ).then(response => {
            setKeywordGroups(response.content);
            onKeywordGroupsPaginationChange({totalRecords: response.totalElements});
            setIsFetching(false);
        }).catch(error => {
            setIsFetching(false);
            console.log("Error while fetching keyword groups: ", error);
        });
    };

    const onKeywordGroupCreate = () => {
        fetchBrands();
        if (brandId)
            fetchKeywordGroups();
    }

    const onUpdateKeywordGroup = (body, withRefresh) => {
        setIsFetching(true);
        Api.updateKeywordGroup(body).then(response => {
            if (withRefresh) {
                const newKeywordGroups = [...keywordGroups];
                const elementIndex = newKeywordGroups.findIndex(kg => kg.id == response.id);
                newKeywordGroups[elementIndex] = response;
                setKeywordGroups(newKeywordGroups);
            }
            setIsFetching(false);
        }).catch(error => {
            setIsFetching(false);
            console.log("Error while updating keyword group: ", error);
        });
    }

    const onDeleteKeywordGroup = (keywordGroup) => {
        setIsFetching(true);
        setIsMoreButtonMenuOpen(false);
        Api.deleteKeywordGroup(keywordGroup.id).then(response => {
            setIsFetching(false);
            props.history.push({pathname: `/keywordGroups/${  keywordGroup.agencyBrandId}`, search: props.history.location.search});
        }).catch(error => {
            setIsFetching(false);
            console.log("Error while deleting keyword group: ", error);
        });
    }

    const onDuplicateKeywordGroup = (keywordGroup) => {
        setIsFetching(true);
        setIsMoreButtonMenuOpen(false);
        delete keywordGroup.id;
        keywordGroup.groupName += "- Duplicate"
        Api.createKeywordGroup(keywordGroup).then(response => {
            const newKeywordGroups = [...keywordGroups];
            newKeywordGroups.push(response);
            setKeywordGroups(newKeywordGroups);
            setIsFetching(false);
            props.history.push(`/keywordGroups/${  keywordGroup.agencyBrandId  }/${  response.id}`);
        }).catch(error => {
            setIsFetching(false);
            console.log("Error while updating keyword group: ", error);
        });
    }

    const onKeywordGroupsPaginationChange = (newData) => {
        const newPagination = {...keywordGroupsPagination, ...newData};
        setKeywordGroupsPagination(newPagination);
        const searchString = qs.stringify({keywordGroupsPagination: newPagination});
        props.history.push({search: `?${searchString}`})
    }

    const onBrandsPaginationChange = (newData) => {
        const newPagination = {...brandsPagination, ...newData};
        setBrandsPagination(newPagination);
        const searchString = qs.stringify({brandsPagination: newPagination});
        props.history.push({search: `?${searchString}`})
    }

    const renderHeaders = () => {
        let headerText ="Group Manager";
        if (keywordGroupId && keywordGroups && keywordGroups.length > 0) {
            const contextKeywordGroup = keywordGroups.find(kg => kg.id === keywordGroupId);
            if (contextKeywordGroup)
                headerText = contextKeywordGroup.groupName;
        }

        return (
            <>
                <TopNavigation />
                <div className="keywordGroupsNavigation">
                    <div className="title">
                        {headerText}
                    </div>
                    {renderHeaderButtons()}
                </div>
            </>
        );
    }

    const renderHeaderButtons = () => {
        if (keywordGroupId && keywordGroups && keywordGroups.length > 0) {
            const selectedKeywordGroup = keywordGroups.find(kg => kg.id === keywordGroupId);
            return renderKeywordScreenHeaderButtons(selectedKeywordGroup)
        } if (isReadOnly())
            return null
        return (
            <div className="controlButton" onClick={() => setIsCreateGroupModalVisible(true)}>
                <img src="/images/ic-plus-circle-w.svg"/>
                <span>Create Group</span>
            </div>
        );
    }

    const renderKeywordScreenHeaderButtons = (selectedKeywordGroup) => {
        if (!selectedKeywordGroup)
            return null;

        return (
            <div className="rightHeaderInfo">
                <span className="dateInfo">
                    Last Edited {moment(selectedKeywordGroup.updatedOn).format("MM/DD/YY")}
                </span>
                {isReadOnly() ? null :
                <>
                        <div className="moreButton" onClick={() => setIsMoreButtonMenuOpen(!isMoreButtonMenuOpen)}>
                        <span>•••</span>
                    </div>
                        <div style={isMoreButtonMenuOpen ? {display: "flex"} : {display: "none"}} className="moreButtonMenu">
                        <span onClick={() => {setIsEditGroupModalVisible(true); setIsMoreButtonMenuOpen(false)}}>
                                Rename
                            </span>
                        <span onClick={() => onDuplicateKeywordGroup(selectedKeywordGroup)}>
                                Duplicate
                            </span>
                        <span className="deleteButton" onClick={() => onDeleteKeywordGroup(selectedKeywordGroup)}>
                                Delete
                            </span>
                    </div>
                    </>
                }
            </div>
        );
    }

    const renderContent = () => {
        if (keywordGroupId && keywordGroups && keywordGroups.length > 0)
            return <KeywordGroupDetails keywordGroup={keywordGroups.find(kg => kg.id == keywordGroupId)} onUpdate={onUpdateKeywordGroup} readOnly={isReadOnly()}/>
        if (brandId)
            return <KeywordGroupList keywordGroups={keywordGroups} pagination={keywordGroupsPagination} onPaginationChange={onKeywordGroupsPaginationChange}/>;
        return <BrandList brands={brands} pagination={brandsPagination} onPaginationChange={onBrandsPaginationChange}/>;
    }

    return (
        <div className="keywordGroups">
            {renderHeaders()}
            <div className="content">
                <BreadCrumbs    keywordGroups={keywordGroups}
                    brands={brands}
                    keywordGroupId={keywordGroupId}
                    brandId={brandId}/>
                {renderContent()}
                <PleaseWaitWhite show={isFetching} />
            </div>
            {isCreateGroupModalVisible &&
                <CreateKeywordGroupModal    brands={brands}
                    close={() => setIsCreateGroupModalVisible(false)}
                    initialBrandId={brandId}
                    onCreate={onKeywordGroupCreate}/>
            }
            {(isEditGroupModalVisible && keywordGroupId && keywordGroups && keywordGroups.length > 0) &&
                <RenameKeywordGroupModal    close={() => setIsEditGroupModalVisible(false)}
                    keywordGroup={keywordGroups.find(kg => kg.id == keywordGroupId)}
                    onUpdate={onKeywordGroupCreate}/>
            }
        </div>
    )
}

export default KeywordGroupsPage;
