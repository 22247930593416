import React from "react";

import LeftMenu from '../LeftMenu'
import Invoice from './Invoice'
import Contract from './Contract'
import Terms from './Terms'


const menuPoints = [
    { key: 'invoice', label: 'Invoice Template'},
    { key: 'contract', label: 'Contract Template'},
    { key: 'terms', label: 'Terms Template'}
]

export default class Profile extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
        }
    }

    navigate = (key) => {
        let title = '';
        for (let point of menuPoints) {
            if (point.key === key) {
                title = point.label;
            }
        }
        this.setState({
            current : key,
            title: title
        })
    }

    componentDidMount() {
        this.navigate('invoice')
    }

    render() {
        return (<div className="profile">
            <div className="panels">
                <div>
                    <LeftMenu
                        points = { menuPoints}
                        currentKey = {this.state.current}
                        changeFunction = {this.navigate}
                    />
                </div>
                <div className="featureDiv">
                    <div className="featureTitle">
                        { this.state.title}
                    </div>
                    { this.state.current === 'invoice' && <Invoice/>
                    }
                    { this.state.current === 'contract' && <Contract/>
                    }
                    { this.state.current === 'terms' && <Terms/>
                    }
                </div>
            </div>
        </div>)
    }
}