import React from 'react';
import { SingleDatePicker, isInclusivelyAfterDay } from 'react-dates';
import moment from 'moment';
import AdditionalResources from './additionalResources/AdditionalResources';
import Contract from '../../shared/contract/contract';
import EmailNotifications from './createAssigment/emailNotifications/EmailNotifications';
import Api from '../../../modules/Api';
import DraftRequiredSelection from './DraftRequiredSelection';
import { getDefaultContract } from '../../shared/contract/contractHelper';
import AssignmentContractType from '../../../constants/AssignmentContractType';
import ApiError from '../../shared/apiError/ApiError';

// props
// - assigment record
// - apiErrorFunction

const fakeResources = [
    {   mimeType : "pdf",
        name: "somethingElse.doc",
        owner: {
        },
        resourceId : 2,
        type: "additional",
        url : 'whatevver'
    },
    {   mimeType : "pdf",
        name: "somethingElse.pdf",
        owner: {
        },
        resourceId : 2,
        type: "additional",
        url : 'whatevver'
    },
    {   mimeType : "pdf",
        name: "somethingElse.png",
        owner: {
        },
        resourceId : 2,
        type: "additional",
        url : 'whatevver'
    }


];

const fake = false;

export default class AssignmentResources extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            contractDataListing: [],
            contractDataSelected: { id: '', name: '', resource: {}, data: null },
            apiError: null,
            datePicker: false,
            dueDate: this.props.assignment.dueDate
        };
    }

    delegateChange = (newAssignmentObject) =>  {
        const updated = {};
        for (const ix in newAssignmentObject) {
            if (ix !== 'resources') {
                updated[ix] = newAssignmentObject[ix];
            }
        }
        Api.updateAssignment(newAssignmentObject.id, updated).then(
            function(success){
            })
            .catch(err => {this.setState({apiError: err})})
    }

    delegateDraftRequiredSelectionChange = (newDraftRequireState) => {
        const tempAssignment = this.props.assignment;
        tempAssignment.draftRequired = newDraftRequireState;
        Api.updateAssignment(this.props.assignment.id, tempAssignment)
            .then((success) => {
                // console.log(success);
                // TODO: Success message?
            })
            .catch(err => {this.setState({apiError: err})})
    }

    static getDerivedStateFromProps(props, state) {
        let resources = state.resources || null;
        if (props.assignment && !Array.isArray(state.resources)) {
            resources = props.assignment.resources;
            if (fake ) {
                for (const resource of fakeResources) {
                    resources.push(resource);
                }
            }
        }
        return {resources};
    }

    updateDueDate = (date) => {
        Api.updateAssignment(this.props.assignment.id, { dueDate: date })
            .then((success) => {

            })
            .catch(err => { this.setState({ apiError: err }) })
    }

    componentDidMount() {
        const self = this;
        const { advertiserId } = this.props;
        Api.contractTemplateGetList(advertiserId)
            .then(
                (res) => {
                    // console.log('api success', res);
                    if(Array.isArray(res)) {
                        const reMappedRes = res.map((item, idx) => {
                            return {
                                id: item.id,
                                name: item.description,
                                resource: item.resource,
                                dataMap: item.data,
                            }
                        });
                        const contractSelected = getDefaultContract(self.props.assignment);

                        self.setState({
                            contractDataListing: reMappedRes,
                            contractDataSelected: contractSelected
                        });
                    }
                },
                (err) => {
                    // console.log('api fail');
                    console.log(err);
                }
            )
            .catch((err) => {
                console.log(err);
            });

    }

    closeCallback = (saved, contract) => {
        if (saved && contract) {
            // window.location.reload();
            this.setState({ contractDataSelected: contract })
        }
    }

    contractDeleted = () => {
        this.setState({ contractDataSelected: { id: '', name: '', resource: {}, data: null } })
    }

    handleDateChange = (value) => {
        if (moment(value).isValid()) {
            const dueDate = moment(value).format('YYYY-MM-DD');
            this.setState({ dueDate });
            this.updateDueDate(dueDate);
        }
    }

    render() {
        const {apiError, datePicker, dueDate} = this.state;
        if (typeof this.state.resources !== 'object') {
            return null;
        }
        const yearArray = [];
        for (let i = moment().year() - 10; i < moment().year() + 30; i++) {
            yearArray.push(i);
        }

        const isOutsideRange = day => {
            return !isInclusivelyAfterDay(day, moment());
        };
        
        return (
            <div className="assignmentResources">
                <ApiError
                    show={apiError}
                    error={apiError}
                    cancelFunction={() => this.setState({apiError: null})}
                />
                <div className="sideBlocks">
                    <div className="title">
                        Contract / Agreement
                    </div>
                    <Contract
                        permission={this.props.permission}
                        isViewer={this.props.isViewer}
                        contractTemplates={this.state.contractDataListing}
                        selectedTemplate={this.state.contractDataSelected}
                        closeCallback={this.closeCallback}
                        deleteCallback={this.contractDeleted}
                        level="assignment"
                        assignmentId={this.props.assignment.id}
                        isAssignmentOffline={this.props.assignment.assignmentContractType === AssignmentContractType.OFFLINE_CONTRACT}
                        hasInvitedCreators={this.props.hasInvitedCreators}
                        delegateAssignmentChangeFunction={this.props.delegateAssignmentChangeFunction}
                    />
                </div>
                <div className="sideBlocks">
                    <div className="title">
                        Due Date
                    </div>
                    <div className='datePicker'>
                        <div className='icon'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path
                                    fill="#999"
                                    fillRule="nonzero"
                                    d="M16.46 9.27a.889.889 0 0 1 1.276 0 .938.938 0 0 1 0 1.305l-5.097 5.154a.888.888 0 0 1-1.278 0l-5.096-5.154a.936.936 0 0 1 0-1.305.889.889 0 0 1 1.276 0l4.46 4.227 4.458-4.227z"
                                />
                            </svg>
                        </div>
                        <SingleDatePicker
                            placeholder="Choose Date"
                            numberOfMonths={1}
                            onDateChange={value => this.handleDateChange(value)}
                            focused={datePicker} // PropTypes.bool
                            onFocusChange={({ focused }) => this.setState({ datePicker: focused })} // PropTypes.func.isRequired
                            id="due_date" // PropTypes.string.isRequired,
                            date={moment(dueDate).isValid() ? moment(dueDate) : null}
                            // displayFormat="mm/dd/yyyy"
                            isOutsideRange={isOutsideRange}
                            showDefaultInputIcon
                            inputIconPosition="before"
                            renderMonthElement={({ month, onMonthSelect, onYearSelect }) => (
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <div>
                                        <select
                                            value={month.month()}
                                            onChange={(e) => {
                                                onMonthSelect(month, e.target.value);
                                            }}
                                        >
                                            {moment.months().map((label, value) => (
                                                <option key={value} value={value}>
                                                    {label}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div>
                                        <select
                                            value={month.year()}
                                            onChange={(e) => {
                                                onYearSelect(month, e.target.value);
                                            }}
                                        >
                                            {yearArray.map((year) => (
                                                <option key={year} value={year}>
                                                    {year}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            )}
                        />
                    </div>
                </div>
                <div className="sideBlocks">
                    <div className="title">
                        Draft Required?
                    </div>
                    <div className="subTitle">For connected creators only</div>
                    <DraftRequiredSelection
                        draftRequired={this.props.assignment.draftRequired}
                        permission={this.props.permission}
                        assignmentId={this.props.assignment.id}
                        delegateChange={(newDraftRequireState) => this.delegateDraftRequiredSelectionChange(newDraftRequireState)}
                    />
                </div>
                <div className="sideBlocks">
                    <div className="title">
                        Email Notifications
                    </div>
                    <div className="subTitle">For connected creators only</div>
                    <EmailNotifications
                        assignment = {this.props.assignment}
                        permission={this.props.permission}
                        changeFunction = { (key, value) => {
                            const {assignment} = this.props;
                            assignment[key] = value;
                            this.delegateChange(assignment);
                        }}
                    />
                </div>
                <div className="sideBlocks">
                    <div className="title" style={{paddingBottom:0}}>
                        Additional Resources
                    </div>
                    <AdditionalResources
                        permission={this.props.permission}
                        assignmentId={this.props.assignment.id}
                        existingResources={this.state.resources}
                        apiErrorFunction={this.props.apiErrorFunction}
                    />

                </div>
            </div>
        )
    }
}
