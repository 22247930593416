import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import cx from 'classnames';
import './creatorsTab.scss';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PleaseWaitWhite from '../shared/pleaseWait/PleaseWaitWhite';
import ApiError from '../shared/apiError/ApiError';
import Api from '../../modules/Api';
import Checkbox from '../shared/checkbox2022/Checkbox';
import OneInfluencer from "../oneInfluencer2022/OneInfluencer";
import ImportFromBase from './ImportFromBase';
import NoContentCard from './NoContentCard';
import ExclusionDropDown from './ExclusionDropdown';


const ExcludedTab = ({
    lookupTables,
    plansData,
    onChange = () => null,
    setSuccess = () => null,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [apiError, setApiError] = useState();
    const [errTitle, setErrTitle] = useState('');
    const [showExclusion, setShowExclusion] = useState(false);
    const [allSelected, setAllSelected] = useState(false);
    const [connectedUsers, setConnectedUsers] = useState([]);
    const [publicUsers, setPublicUsers] = useState([]);
    const [selectedCount, setSelectCount] = useState(0);

    useLayoutEffect(() => {
        let totalSelected = 0;
        if (connectedUsers.length > 0) {
            totalSelected += connectedUsers.reduce((acc, u) => {
                if (u.checked) {
                    acc++
                }
                return acc;
            }, 0);
        };

        if (publicUsers.length > 0) {
            totalSelected += publicUsers.reduce((acc, u) => {
                if (u.checked) {
                    acc++
                }
                return acc;
            }, 0);
        }

        setSelectCount(totalSelected);
        if (totalSelected === (connectedUsers.length + publicUsers.length) && !allSelected) {
            setAllSelected(true);
        } else {
            setAllSelected(false);
        }
    }, [connectedUsers, publicUsers]);

    useEffect(() => {
        if (plansData && plansData.excludedInfluencers && plansData.excludedInfluencers.length > 0) {
            const connected = plansData.excludedInfluencers.map(user => {
                return {
                    checked: false,
                    details: user,
                    id: user.id || user.uid,
                    invited: '',
                }
            })
            setConnectedUsers(connected || []);
        } else {
            setConnectedUsers([]);
        };

        if (plansData && plansData.excludedIqUsers && plansData.excludedIqUsers.length > 0) {
            const iqs = plansData.excludedIqUsers.map(user => {
                return {
                    checked: false,
                    details: user,
                    id: user.id || user.uid,
                    invited: '',
                }
            })
            setPublicUsers(iqs || []);
        } else {
            setPublicUsers([]);
        };
    }, [plansData]);

    const CancelError = () => {
        setApiError(null);
        setErrTitle('');
    };

    const selectAllHandler = (status) => {
        if (connectedUsers.length > 0) {
            const connected = connectedUsers.map(user => ({ ...user, checked: status }));
            setConnectedUsers(connected);
        }
        if (publicUsers.length > 0) {
            const iqs = publicUsers.map(user => ({ ...user, checked: status }));
            setPublicUsers(iqs)
        }
    };

    const selectionChange = (uid, flag) => {
        let update;
        if (connectedUsers.find(user => user.details.uid === uid)) {
            update = [...connectedUsers];
            update.find(user => user.details.uid === uid).checked = flag;
            setConnectedUsers(update);
        } else {
            update = [...publicUsers];
            update.find(user => user.details.uid === uid).checked = flag;
            setPublicUsers(update);
        }
    };

    const onRemove = () => {
        const connectedUserIds = connectedUsers.filter(user => user.checked).map(u => u.details.id);
        const publicIds = [];
        publicUsers.forEach(user => {
            if (user.checked) {
                publicIds.push({
                    userId: user.details.uid,
                    userType: user.details.userNetworks[0].networkType
                })
            }
        })
        if (plansData && ((connectedUserIds && connectedUserIds.length > 0) || publicIds.length > 0)) {
            setIsLoading(true);
            const payload = {
                connectedUserIds,
                publicUsers: publicIds
            }
            Api.removeExcludedUsersPlan(plansData.id, payload)
                .then(res => {
                    setIsLoading(false);
                    onChange();
                })
                .catch(err => {
                    setIsLoading(false);
                    handleApiError(err);
                })
        }
    };

    const handleApiError = (err) => {
        setApiError(err);
        if (err && err.originalMessage) {
            setErrTitle(err.originalMessage);
        }
    };

    const onImport = (data) => {
        const listIds = data.map(d => d.id);
        setIsLoading(true);
        Api.addUsersPlanByExclusion(plansData.id, { listIds })
            .then(res => {
                setIsLoading(false);
                setShowExclusion(false);
                onChange();
                if (res.messageData?.ignoredPublicUsers || res.messageData?.ignoredConnectedUsers) {
                    const totalCount = (res.messageData?.ignoredPublicUsers ? res.messageData?.ignoredPublicUsers.length : 0)
                        + (res.messageData?.ignoredConnectedUsers ? res.messageData?.ignoredConnectedUsers.length : 0);
                    if (totalCount > 5) {
                        setSuccess(`Success! ${totalCount} creators are not added as they were already on the plan's creator list.`);
                    } else {
                        let nameList = '';
                        if (res.messageData?.ignoredConnectedUsers?.length > 0) {
                            nameList = res.messageData.ignoredConnectedUsers.reduce((acc, u) => {
                                const user = plansData.influencers.find(i => i.id === u);
                                if (user) {
                                    const name = `${user.firstname ? user.firstname : ''} ${user.lastname ? user.lastname : ''}`;
                                    acc += (acc.length > 0 ? (', ' + name) : name);
                                }
                                return acc;
                            }, nameList);
                        }

                        if (res.messageData?.ignoredPublicUsers?.length > 0) {
                            nameList = res.messageData.ignoredPublicUsers.reduce((acc, u) => {
                                const user = plansData.iqUsers.find(i => i.uid === u.userId);
                                if (user) {
                                    const name = `${user.firstname ? user.firstname : ''} ${user.lastname ? user.lastname : ''}`;
                                    acc += (acc.length > 0 ? (', ' + name) : name);
                                }
                                return acc;
                            }, nameList);
                        }

                        setSuccess(`Success! ${nameList} are not added as they were already on the plan's creator list.`);
                    }
                } else {
                    setSuccess('Success! Added creators to plan exclusion list.');
                }
            })
            .catch(err => {
                setIsLoading(false);
                handleApiError(err);
            })
    };

    const handleAddExclusion = () => {
        setShowExclusion(true);
    };

    const excludeFunction = (e, user) => {
      e.stopPropagation();
      e.preventDefault();

      setIsLoading(true);
      const isConnectedInfluencer = Boolean(user.id);
      const payload = {};
      if (isConnectedInfluencer) {
          payload.connectedUserIds = [user.id];
      } else {
          payload.publicUsers = [{
              userId: user.uid,
              userType: user.userNetworks[0].networkType,
          }];
      };

      Api.removeExcludedUsersPlan(plansData.id, payload)
        .then(res => {
          setIsLoading(false);
          onChange();
          setSuccess('Success! remove creator from plan exclusion');
        })
        .catch(err => {
            setIsLoading(false);
            handleApiError(err);
        })
    };

    return (
        <div className='creatorsTabContainer'>
            <PleaseWaitWhite
                show={isLoading}
            />
            <ApiError
                show={apiError}
                errTitle={errTitle}
                error={apiError}
                cancelFunction={CancelError}
            />
            <div className='userListHeader'>
                <div className="selectAllCheckbox">
                    {(publicUsers.length > 0 || connectedUsers.length > 0) && <Checkbox
                        size={16}
                        changeFunction={selectAllHandler}
                        checked={allSelected}
                        isDisabled={(connectedUsers.length + publicUsers.length) === 0}
                    />}
                    {(publicUsers.length > 0 || connectedUsers.length > 0) && <span>{selectedCount === 0 ? `Showing ${connectedUsers.length + publicUsers.length} creator${(connectedUsers.length + publicUsers.length) > 1 ? 's' : ''}`
                        : `Selected ${selectedCount} creator${selectedCount > 1 ? 's' : ''}`}</span>}
                </div>
                <div className='topRightSection'>
                    {selectedCount > 0 && <div className='planCreatorsButtonContainer' onClick={onRemove}>
                        <span>Remove</span>
                    </div>}
                    <div className='addExclusionButtonContainer' onClick={handleAddExclusion} role='button'>
                        <img src='/images/ic-add-w.svg' alt='addExclusion' />
                        Exclusion list
                        {showExclusion && <div className='importContainer'>
                            <ImportFromBase
                                headerLabel='Exclusion list'
                                onCancel={() => setShowExclusion(false)}
                                onImport={onImport}
                                height={48}
                                Dropdown={ExclusionDropDown}
                            />
                        </div>}
                    </div>
                </div>
            </div>
            <div className='userListWrapper'>
                {lookupTables && connectedUsers.length > 0 && <>
                    <div className='userListLabel'>Connected Creators</div>
                    {connectedUsers.map(
                        ({ checked, details, id, invited }) => (
                            <OneInfluencer
                                key={id}
                                checked={checked}
                                details={details}
                                invited={invited}
                                hideMore
                                permission
                                excludeFunction={(e) => excludeFunction(e, details)}
                                selectFunction={selectionChange} // changeSelection
                                planId={plansData && plansData.id}
                            />
                        )
                    )}
                </>}
                {lookupTables && publicUsers.length > 0 && <>
                    <div className='userListLabel'>
                        Public Creators
                    </div>
                    {publicUsers.map(
                        ({ checked, details, id, invited }) => (
                            <OneInfluencer
                                key={id}
                                checked={checked}
                                details={details}
                                invited={invited}
                                hideMore
                                permission
                                excludeFunction={(e) => excludeFunction(e, details)}
                                selectFunction={selectionChange} // changeSelection
                                planId={plansData && plansData.id}
                            />
                        )
                    )}
                </>}
                {publicUsers.length === 0 && connectedUsers.length === 0 && <NoContentCard message='You have no creators. Start by adding creators to the plan' />}
                <div style={{ height: 45 }} />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        lookupTables: state.global.lookupTables,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExcludedTab));