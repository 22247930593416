import React from 'react'

import InfluencerInput from '../influencerInput/InfluencerInput'
import './locationInput.scss'

// props :
// - original : object with properties listed in addressProperties
// - changeFunction : takes object with the same properties
// - fancy (flag) use InfluencerInput

const addressProperties  = [
    'city',
    'state',
    'country',
    'addressOne',
    'addressTwo',
    'zipCode',
    'formattedAddress'];

export default class LocationInput extends React.Component {

    constructor (props, context) {
        super(props, context);

        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);

        this.state = {
            fancyInputContent : null,
            updatedSinceFocus : false,
        }

    }

    handleFocus() {
        this.setState({
            updatedSinceFocus : false,
            valueAtFocus : this.inputNode.value
        })
    }

    handleBlur() {
        this.inputNode.blur()
    }

    reset() {
        this.inputNode.value = '';
    }

    componentDidMount() {
        const { scrollContainer, fancy, placeholder } = this.props;
        if (scrollContainer) {
            scrollContainer.addEventListener('scroll', this.handleBlur);
        }
        if (fancy) {
            this.inputNode = this.inputContainer.getElementsByTagName('input')[0];
            this.inputNode.placeholder = '';
        } else {
            this.inputNode.placeholder = placeholder || '';
        }

        const self = this;
        const fillInAddress = function() {
            const place = self.autocomplete.getPlace();
            if (!place || !place.address_components) {
                return;
            }
            self.setState({
                updatedSinceFocus: true
            })
            const f = self.props.changeFunction;
            if (typeof f === 'function') {
                const rawAddress = {};
                place.address_components.forEach(component => {
                    rawAddress[component.types[0]] = {
                        short: component.short_name,
                        long: component.long_name,
                    }
                });
                const address = {
                    city : (rawAddress.locality && rawAddress.locality.long) || (rawAddress.sublocality_level_1 && rawAddress.sublocality_level_1.long),
                    state : rawAddress.administrative_area_level_1 && rawAddress.administrative_area_level_1.short,
                    country : rawAddress.country && rawAddress.country.short,
                    addressOne : `${(rawAddress.street_number && rawAddress.street_number.long) || ''    } ${  (rawAddress.route && rawAddress.route.long) || ''}`,
                    zipCode : rawAddress.postal_code && rawAddress.postal_code.short,
                    formattedAddress : place.formatted_address
                };
                if (self.props.fancy) {
                    self.setState({
                        fancyInputContent : self.props.useAddress ? address.addressOne : place.formatted_address
                    })
                }
                if (self.props.useAddress) {
                    self.inputNode.value = address.addressOne;
                }
                for (const property of addressProperties) {
                    if (!address[property]) {
                        address[property] = '';
                    }
                }
                f(address, addressProperties);
            }

        }
        this.autocomplete = new google.maps.places.Autocomplete(this.inputNode, {types: ['geocode']});
        this.autocomplete.addListener('place_changed', fillInAddress);
        if (self.props.country) {
            this.autocomplete.setComponentRestrictions({ country: self.props.country });
        }
    }

    componentDidUpdate(prevProps) {
        const { useAddress, original, country } = this.props
        if (prevProps.country !== country) {
            this.autocomplete.setComponentRestrictions({ country });
            if (prevProps.country) {
                this.inputNode.value = '';
            }
        }
        if (useAddress && (prevProps.original.formattedAddress !== original.formattedAddress)) {
            this.inputNode.value = original.formattedAddress;
        }
    }

    componentWillUnmount() {
        const { scrollContainer } = this.props;
        if (scrollContainer) {
            scrollContainer.removeEventListener('scroll', this.handleBlur);
        }
    }

    static getAddressProperties() {
        return addressProperties;
    }

    render() {
        const self = this;
        const { handleChangeWithoutSelect } = this.props
        return (
            <div className="locationInput">
                {!this.props.fancy &&
                <input
                    id = {this.props.id?this.props.id:'location_field'}
                    ref={(e) => {
                        this.inputNode = e
                    }}
                    defaultValue={this.props.original.formattedAddress}
                    onFocus={this.handleFocus}
                    style={{overflow: 'hidden'}}
                    onChange={handleChangeWithoutSelect || (() => {})}
                />
                }
                {this.props.fancy &&
                <div ref={(e) => {
                    this.inputContainer = e
                }}
                >
                    <InfluencerInput
                        initialValue = {this.props.original.formattedAddress}
                        label = "Location"
                        valueChangeFunction = { () => {
                            this.setState({
                                fancyInputContent : null
                            })
                            const blankAddress = {};
                            for (const ix in this.props.original ) {
                                blankAddress[ix] ='';
                            }
                            this.props.changeFunction(blankAddress);
                        }}
                        updatedValue = {this.state.fancyInputContent}
                        clearState={this.props.clearState}
                        handleChangeWithoutSelect={handleChangeWithoutSelect || (() => {})}
                    />
                </div>
                }
            </div>
        )
    }
}
