import React from 'react';
import _, { intersection } from 'lodash';
// import moment from 'moment';
import Lookup from '../../../modules/Lookup'
import Api from '../../../modules/Api'
import ModalDialogCamp from '../modalDialog/ModalDialogCampaign'
import RateInput from '../../oneInfluencer/rateBox/RateInput'
import Contract from "../contract/contract";
import './ProposeRate.scss'
import RatesService from '../../../services/ratesService';
import { GENERAL } from '../../../constants/channels';
import CustomizeContractPopup from '../contract/CustomizeContractPopup';

const maxLength = 2000;
const slotMax = {
    AGENT: 4,
    USER: 3
};
export default class ProposeRate extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.handleRateInput = this.handleRateInput.bind(this);
        this.handleCommentChange = this.handleCommentChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.sendRate = this.sendRate.bind(this);
        this.processHandler = this.processHandler.bind(this);

        const {user, player, slots, acceptReady} = this.props;
        const sortedSlots = Array.isArray(slots) && slots.length > 0 ? Object.assign([], slots).sort((s1, s2)=> {return (Number(s1.id) - Number(s2.id))}) : [];
        const lastSlot = sortedSlots.length > 0 ? sortedSlots[sortedSlots.length - 1] : {};
        const lastPlayer = lastSlot.player || player;
        const slotData = this.getSlotsDataForUserAndAgent(sortedSlots);
        this.finalRate = player==='USER' && slotData.userNegotiationCount >= slotMax.USER;
        this.acceptReady = (!(player === lastPlayer) && acceptReady) || this.finalRate;
        this.state = {
            readyToProceed : this.acceptReady,
            newRateDisabled: player === lastPlayer || 
                (player === 'USER' && slotData.userNegotiationCount >= slotMax.USER) || 
                (player === 'AGENT' && slotData.agentNegotiationCount >= slotMax.AGENT) ||
                (lastPlayer==='AGENT' && lastSlot.playerAction === 'ACCEPT'),
            buAccepted: (player !== lastPlayer && lastPlayer==='AGENT' && lastSlot.playerAction === 'ACCEPT'),
            newRate: {},
            newRateReady: false,
            newRateProcess: false,
            totalCost: 0,
            validMsg: {},
            comment: '',
            contractSelectedTemplate: { id: '', name: '', resourceId: '', data: null, resource: {}},
            contractAssignmentUserId: null,
            proceedClicked: 0,
            showCustomizeContractPopup: false,
            showCurrentContract: false,
            sortedSlots,
            slotData
        };

        const ratesService = new RatesService(user);
        const ratesList = ratesService.filterRatesListByActiveTab();
        const totalRate = ratesService.calculateTotalRateByActiveRatesOption();
        const rates = ratesList.reduce((acc, rateType) => {
            acc[rateType] = user.rates[rateType];

            return acc;
        }, {});

        this.userDefault = {
            id: 0,
            player: 'USER',
            rates,
            totalRate
        };
    }

    componentDidMount() {
        // console.log('mount');
        this.setState({proceedClicked: 0}, this.getCurrentContractTemplateMapping());
    }

    componentDidUpdate(prevProps) {
        const {slots} = this.props;
        if (!_.isEqual(prevProps.slots, slots)) {
            const sortedSlots = Array.isArray(slots) && slots.length > 0 ? Object.assign([], slots).sort((s1, s2)=> {return (Number(s1.id) - Number(s2.id))}) : [];
            const slotData = this.getSlotsDataForUserAndAgent(sortedSlots);
            this.setState({
                sortedSlots,
                slotData
            })
        }
    }

    getCurrentContractTemplateMappingFinnaly(hasAssignmentUserDTO, assignmentUserId) {
        // 3.B
        const{aid, user, player} = this.props;
        const self = this;
        if (!hasAssignmentUserDTO) {
            let hasAssignmentDTO = false;
            Api.getAssignment(aid)
                .then(
                    (res) => {
                        console.log('success getAssignment', res);
                        // 4.0, 4.A
                        if (res && res.defaultContract && assignmentUserId) {
                            hasAssignmentDTO = true;
                            console.log('res.defaultContract', res.defaultContract);
                            const cData = res.defaultContract;

                            self.setState({
                                contractSelectedTemplate: {
                                    id: cData.data.contractTemplateId,
                                    name: cData.data.contractTemplateName,
                                    resourceId: cData.resource.id,
                                    data: JSON.parse(cData.data.mapping),
                                    resource: cData.resource,
                                },
                                contractAssignmentUserId: assignmentUserId,
                            });
                        }

                        if (!hasAssignmentDTO) {
                            // 4.B
                            console.log('Default Template Not Set.');
                        }
                    },
                    (err) => {
                        // console.log('failed getAssignment', err);
                        if (!hasAssignmentDTO) {
                            // 4.B
                            console.log('Default Template Not Set.');
                        }
                    }
                )
                .catch((err) => {
                    // console.log('failed getAssignment', err);
                    if (!hasAssignmentDTO) {
                        // 4.B
                        console.log('Default Template Not Set.');
                    }
                })
        }
    }

    getCurrentContractTemplateMapping() {

        // contract flow
        /**
         * 1.0 Open only for Agents
         * 2.0 Get the AssignmentUserDTO
         * 3.0 check if 'contract' property exit
         * 3.A If exist, get contract data and pass to <Contract /> component. STOP
         * 3.B If Not exist, Get the AssignmentDTO
         * 4.0 Check if 'defaultContract' property exist
         * 4.A If exist, get contract data and pass to <Contract /> component. STOP
         * 4.B If Not exist, Throw Error, "Default Template Not Set."
         *
         *
         * * */

        const{aid, user, player} = this.props;
        const self = this;

        // player check START
        // 1.0
        if(player==='AGENT') {

            let assignmentUserId = null;

            // 2.0, 3.0
            let hasAssignmentUserDTO = false;
            Api.getAssignmentUser(aid, user.uid)
                .then(
                    (res) => {
                        // 3.A
                        console.log('success getAssignmentUser', res);
                        if(res && res.id) {
                            assignmentUserId = res.id;
                        }

                        if(res && res.contract && assignmentUserId) {
                            hasAssignmentUserDTO = true;
                            console.log('res.contract', res.contract);
                            const cData = res.contract;
                            self.setState({
                                contractSelectedTemplate: {
                                    id: cData.data.contractTemplateId,
                                    name: cData.data.contractTemplateName,
                                    resourceId: cData.resource.id,
                                    data: JSON.parse(cData.data.mapping),
                                    resource: cData.resource,
                                },
                                contractAssignmentUserId: assignmentUserId,
                            });
                        }
                        this.getCurrentContractTemplateMappingFinnaly(hasAssignmentUserDTO, assignmentUserId);
                    },
                    (err) => {
                        // console.log('failed getAssignmentUser', err);
                        this.getCurrentContractTemplateMappingFinnaly(hasAssignmentUserDTO, assignmentUserId);
                    }
                )
                .catch((err) => {
                    // console.log('failed getAssignmentUser', err);
                    this.getCurrentContractTemplateMappingFinnaly(hasAssignmentUserDTO, assignmentUserId);
                })
        }
        // player check END

    }

    getRateItemData = (slot, assignmentTypes) => {
        const  { rates } = slot;
        let { totalRate } = slot;
        let ratesList = rates ? Object.keys(rates) : [];

        if (assignmentTypes && !Object.prototype.hasOwnProperty.call(rates, GENERAL)) {
            /*
                We need this for influencer (creator) default rates block. By default totalRate is equal to
                the sum of rates (GENERAL for perCampaign and all set up channels for perChannel).
                So we take the intersection for influencer (creator) rates and the ones in assignment and
                recalculate totalRate based on this intersection to display the correct data
             */
            ratesList = intersection(ratesList, assignmentTypes);
            totalRate = ratesList.reduce((acc, rateType) => {
                if (typeof rates[rateType] === 'number') {
                    return acc + rates[rateType];
                }
                return acc;
            }, 0);
        }

        return { ratesList, totalRate };
    }

    getRatesShow(slot, index, assignmentTypes) {
        const{ user } = this.props;
        const {slotData} = this.state;
        const { ratesList, totalRate } = this.getRateItemData(slot, assignmentTypes);
        const itemClass= `rateItem ${ slot.player.toLowerCase()}`;
        const beforeUserStartIdx = index !== -1 && (slotData.userNegotiationStartIdx === -1 || index <= slotData.userNegotiationStartIdx);
        const reachToSlotMax = slot.player === 'AGENT' ? slotData.agentNegotiationCount >= slotMax.AGENT : slotData.userNegotiationCount >= slotMax.USER;
        
        return (
            <div className={itemClass} key={index}>

                { slot.player === 'AGENT' ?
                    <div className="rateTitle">{beforeUserStartIdx ? "Invited Rate" : (slot.playerAction === 'ACCEPT'?"Accepted the rate":"Proposed New Rate")} {reachToSlotMax && index === slotData.lastAgentSlotIdx && " - Final Rate"}</div> :
                    <div className="rateTitle">
                        <img src={(user.pictureUrl)?user.pictureUrl : '/images/ic-avatar-blank-d.svg'}/> {user.firstname} {user.lastname}'s {index===-1 && 'default'} rate {reachToSlotMax && index === slotData.lastUserSlotIdx && " - Final Rate"}
                    </div>
                }
                <div className="ratesList">
                    {ratesList && ratesList.length > 0 ?
                        ratesList.map((rate, i) => {
                            return (
                                <div className="rate" key={i}>
                                    <div>{Lookup.getRateType(rate).label}</div>
                                    <div>{slot.rates[rate] !== undefined && Number(slot.rates[rate])>=0 ? `$${  new Intl.NumberFormat({currency:'CAD'}).format(slot.rates[rate])}`: 'N/A'}</div>
                                </div>
                            )}
                        ) : 
                        <div className="rate"/>
                    }
                    <div className="total">
                        <div>Total Cost</div>
                        <div>${new Intl.NumberFormat({currency:'CAD'}).format(totalRate)}</div>
                    </div>
                    {Boolean(slot.comment) && <div className="comment">{slot.comment}</div>}
                </div>
            </div>
        )
    }

    getSlotsDataForUserAndAgent = (slots) => {
        // since brand owner is able to edit the rate before creator starts negotiation,
        // so needs to count the slots properly by count all these before creator starts as 1
        const res = {};
        res.agentNegotiationCount = 0;
        res.userNegotiationCount = 0;
        res.userNegotiationStartIdx = -1;
        res.lastAgentSlotIdx = -1;
        res.lastUserSlotIdx = -1;
        if (slots.length === 0) {
            return res;
        }
        const userStartIdx = slots.findIndex(s => s.player === 'USER');
        if (userStartIdx !== -1) {
            const userSlots = slots.slice(userStartIdx).filter(s => s.player === 'USER');
            res.userNegotiationCount = userSlots.length;
            res.agentNegotiationCount = slots.length - userStartIdx + 1 - res.userNegotiationCount;
            res.userNegotiationStartIdx = userStartIdx;
        } else {
            res.agentNegotiationCount = 1;
        }
        for (let i = slots.length - 1; i >= 0 && (res.lastAgentSlotIdx === -1 || res.lastUserSlotIdx === -1); i--) {
            if (res.lastAgentSlotIdx === -1 && slots[i].player === 'AGENT') {
                res.lastAgentSlotIdx = i;
            } else if (res.lastUserSlotIdx === -1 && slots[i].player === 'USER') {
                res.lastUserSlotIdx = i;
            }
        }
        return res;
    }

    processHandler(newRate) {
        const proceedClicked = this.state.proceedClicked+1;
        this.setState({proceedClicked});
        // console.log(proceedClicked);
        if (proceedClicked === 1) {
            if (newRate){
                this.sendRate();
            } else {
                const f = this.props.proceedFunction;
                if (typeof f === 'function') {
                    f();
                }
            }
        }
    }

    sendRate() {
        const{aid, user, player} = this.props;
        const{newRate, comment} = this.state;
        // console.log(this.props, this.state)
        const dates = [];
        if (this.props.isTalentManager) {
            Api.TMProposeRate(aid, user.id, newRate, comment, dates).then((response)=>{
                if (player==='USER') {
                    this.setState({
                        newRateDisabled: true,
                        newRateReady: false,
                        newRateProcess: false,
                        totalCost: 0,
                        newRate: {},
                        validMsg: {},
                        comment: ''
                    });
                }
                const f = this.props.sentNewRate;
                if (typeof f === 'function') {
                    f();
                }

            }).catch(err => {
                const f = this.props.apiErrFunction;
                if (typeof f === 'function') {
                    f(err);
                }
            });
        } else {
            Api.proposeRate(aid, user.uid, newRate, comment, dates).then((response)=>{
                if (player==='USER') {
                    this.setState({
                        newRateDisabled: true,
                        newRateReady: false,
                        newRateProcess: false,
                        totalCost: 0,
                        newRate: {},
                        validMsg: {},
                        comment: ''
                    });
                }
                const f = this.props.sentNewRate;
                if (typeof f === 'function') {
                    f();
                }

            }).catch(err => {
                const f = this.props.apiErrFunction;
                if (typeof f === 'function') {
                    f(err);
                }
            });
        }
    }

    handleRateInput(value, type, errMsg) {
        const {newRate, validMsg} = this.state;
        newRate[type] = Number(value);
        validMsg[type] = errMsg
        this.setState({newRate, validMsg}, this.handleChange);
    }

    handleCommentChange(e) {
        this.setState({
            comment : e.target.value
        })
    }

    handleChange() {
        const {newRate} = this.state;
        const newRateReady = true;
        const totalCost = Object.values(newRate).reduce((acc, rateValue) => {
            return acc + rateValue;
        }, 0);

        this.setState({
            newRateReady,
            totalCost,
        });
    }

    componentWillUnmount(){
        this.setState({
            newRateDisabled: true,
            newRateReady: false,
            newRateProcess: false,
            totalCost: 0,
            newRate: {},
            validMsg: {},
            comment: '',
            proceedClicked: 0,
            sortedSlots: [],
            slotData: {
                userNegotiationCount: 0, 
                agentNegotiationCount: 0, 
                userNegotiationStartIdx: -1,
                lastAgentSlotIdx: -1,
                lastUserSlotIdx: -1
            },
        });
    }

    openTemplate = () => {
        this.setState({
            showCurrentContract: true,
            openContract: false,
            showCustomizeContractPopup: false,
        });
    }

    closeCustomizeContractPopup = (needToRefresh) => {
        this.setState({showCustomizeContractPopup: false}, function() {
            if (needToRefresh) {
                this.getCurrentContractTemplateMapping();
            }
        })
    }

    render() {
        const {atypes, player, aid, user, signerList} = this.props;
        const {
            newRateDisabled, buAccepted, newRateReady, newRateProcess, newRate, validMsg, comment, readyToProceed,
            contractSelectedTemplate, contractAssignmentUserId, totalCost, showCustomizeContractPopup, showCurrentContract,
            sortedSlots, slotData
        } = this.state;
        const willReachToSlotMax = player === 'AGENT' ? slotData.agentNegotiationCount === slotMax.AGENT - 1 : slotData.userNegotiationCount === slotMax.USER - 1;
        const historyList = [this.getRatesShow(this.userDefault, -1, atypes)];
        sortedSlots.forEach((slot, i)=>{
            historyList.push(this.getRatesShow(slot, i))
        })

        const proceedButtonLabel = newRateReady ? 'PROPOSE' : player === 'AGENT' ? 'ACCEPT' : 'CANCEL'

        const proposeSlot = sortedSlots.length > 0 ? sortedSlots[sortedSlots.length - 1] : {};
        const { ratesList } = this.getRateItemData(proposeSlot);

        const self= this;
        const message = `${comment.length}/${maxLength} characters`;
        return (
            <ModalDialogCamp
                show={this.props.show}
                title="View New Suggested Rate"
                proceedButtonLabel={proceedButtonLabel}
                cancelButtonLabel = "DECLINE"
                readyToProceed={newRateReady || (readyToProceed||buAccepted)}
                closeButton
                proceedFunction={() => this.processHandler(newRateReady)}
                cancelFunction={()=>this.setState({proceedClicked:0},this.props.cancelFunction)}
                cancelButtonFunction={()=>this.setState({proceedClicked:0},this.props.cancelButtonFunction)}
                cancelButtonReady = {!newRateDisabled || this.finalRate || buAccepted}
                extraFooterLabel = {
                    player === 'AGENT' && contractAssignmentUserId &&!newRateDisabled ?
                        <div className="contractDetails">
                            <Contract
                                permission
                                selectedTemplate={contractSelectedTemplate}
                                level="agent-user"
                                assignmentId={aid}
                                assignmentUserID={contractAssignmentUserId}
                                customizeContractCallback={() => this.setState({showCustomizeContractPopup: true})}
                            />
                        </div>
                        : ''
                }

            >
                <div className="proposeRate">
                    <div className="detailsList">
                        {historyList}

                        <div className="rateItem agent">
                            <div className="rateTitle">
                                {newRateDisabled ?
                                    (this.finalRate ? 'Final Rate, please Accept or Decline' : buAccepted?'Accepted Your Rate, please Accept or Decline':'Waiting for Response') :
                                    newRateProcess ? 'Propose a New Rate' :
                                    <div className="button" onClick={()=>{self.setState({newRateProcess: true, proceedClicked: 0, readyToProceed: false})}} >PROPOSE A NEW RATE</div>
                                }
                                {willReachToSlotMax && ' - Final Rate' }
                            </div>
                            {newRateProcess && Array.isArray(atypes) && atypes.length > 0 &&
                                <div className="ratesList">
                                    <div className="cancel" onClick={() => {self.setState({newRateReady: false, newRate: {}, totalCost: 0, newRateProcess: false, comment: '', proceedClicked: 0, readyToProceed: this.acceptReady})}}>
                                        <img src="/images/ic-close-b.svg" /> Cancel
                                    </div>
                                    {ratesList && ratesList.map((type,i) => {
                                        const inValid = validMsg.hasOwnProperty(type) && validMsg[type];
                                        let rateInputClass = "rate input";
                                        rateInputClass += inValid?" invalid":"";
                                        return (
                                            <div className={rateInputClass} key={i}>
                                                <div>{Lookup.getRateType(type).label}</div>
                                                <RateInput
                                                    rateValue={newRate[type]?newRate[type]:''}
                                                    inputClass="rateInput"
                                                    changeFunction={(value, errMsg) => self.handleRateInput(value, type, errMsg)}
                                                />
                                                {inValid && <div className="inValidNotice">{validMsg[type]}</div>}
                                            </div>
                                        )})
                                    }
                                    <div className="total">
                                        <div>Total Cost</div>
                                        <div>${totalCost >= 0 && new Intl.NumberFormat({currency:'CAD'}).format(Number(totalCost))}</div>
                                    </div>
                                    <div className="comment">
                                        <textarea resize="false" maxLength={maxLength} placeholder="Add a note" value={comment} onChange={this.handleCommentChange} />
                                        <span className="messageContainer">{message}</span>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {player === 'AGENT' && contractAssignmentUserId && !newRateDisabled &&
                    <CustomizeContractPopup
                        showCustomizeContractPopup={showCustomizeContractPopup}
                        assignmentId={aid}
                        assignmentUserId={contractAssignmentUserId}
                        signerList={signerList || []}
                        contractSelectedTemplate={contractSelectedTemplate}
                        closeFunction={(needToRefresh) => this.closeCustomizeContractPopup(needToRefresh)}
                        openTemplateFunction={this.openTemplate}
                    />
                }
                {player === 'AGENT' && showCurrentContract && 
                    <Contract
                        permission
                        selectedTemplate={contractSelectedTemplate}
                        level="agent-user"
                        assignmentId={aid}
                        assignmentUserID={contractAssignmentUserId}
                        userId={user.id}
                        styleType="customizeContractInDropDown"
                        closeCallback={() => this.setState({showCurrentContract: false})}
                        showCurrentContract={showCurrentContract}
                    />
                }
            </ModalDialogCamp>

        )
    }
}
