import React, { useEffect, useState } from "react";
import './sortButton.scss';
import cx from 'classnames';

const SortButton = ({
    type = 'asc',
    disabled = false,
    onChange = () => null,
}) => {

    const onClick = () => {
        let newOrder = type === 'asc' ? 'desc' : 'asc';
        onChange(newOrder);
    }

    return (
        <div className={cx("SortButtonContainer", {disabled})} onClick={() => disabled ? null : onClick()}>
            {type === 'asc' ? <img src="/images/ic_sort_asc_icon.svg"/> 
            : <img src="/images/ic_sort_desc_icon.svg"/> }
        </div>
    )
}
export default SortButton;