import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import SplitPane from 'react-split-pane';
import { Scrollbars } from 'react-custom-scrollbars';
import DOMPurify from 'dompurify';
import Select from 'react-select';
import Fade from 'react-reveal/Fade';
import style from './contractSettings.module.scss';
import Spinner from './spinner';
import ContractAutoMapFields from './ContractMappingTypes';
import Api from '../../../modules/Api';
import { BRAND_OWNER_STR, SUPER_ADMIN_STR, DisplayRoleNames } from '../../../constants/authorities';
// import { Creatable } from 'react-select';

const cx = classNames.bind(style);
const nullFunc = () => {};
const emptyArray = [];
const emptyObject = {};

export default class ContractSettings extends React.PureComponent {

    static propTypes = {
        resource: PropTypes.object.isRequired,
        assignmentId: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.number.isRequired,
        ]),
        name: PropTypes.string.isRequired,
        data: PropTypes.any,
        saveBtnLabel: PropTypes.string,
        saveCallback: PropTypes.func,
        cancelCallback: PropTypes.func,
    };

    static defaultProps = {
        resource: {},
        name: '',
        saveBtnLabel: 'Save Defaults',
        saveCallback: nullFunc,
        cancelCallback: nullFunc,
    };

    constructor(props) {
        super(props);
        this.previewTemplateRef = React.createRef();
        this.state = {
            previewData: null,
            previewError: null,
            selectedOptions: props.data || emptyObject,
            selectOptions: ContractAutoMapFields,
            missingMappingFields: emptyArray,
            missingCustomEntryList: emptyArray,
            presetFields: []
        };

        this.previewFetching = false;
        this.domRenderUpdatOnce = false;

    }

    componentDidMount() {
        this.previewTemplateRef.current.addEventListener("click", this.handleScrollTopOnclick);
        const { resource, assignmentId } = this.props;
        if (resource && resource.url && resource.url.length > 0) {
            this.loadData(resource, assignmentId);
        }
    }

    componentWillUnmount() {
        this.previewTemplateRef.current.removeEventListener("click", this.handleScrollTopOnclick);
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.resource && this.props.resource.id !== prevProps.resource.id && this.props.resource.url && this.props.resource.url.length > 0) {
            this.loadData(this.props.resource);
        }

        if(!this.domRenderUpdatOnce && this.state.previewData) {
            this.domRenderUpdatOnce = true;
            this.updateAllDisplayMapping();
        }
    }

    updateAllDisplayMapping() {
        const self = this;
        // update the document preview display mapping
        const pdata = self.props.data;
        const { selectOptions } = this.state;

        const mappedSelOptions = emptyObject;
        selectOptions.forEach(({value, label}) =>{
            mappedSelOptions[value] = label;
        });

        if( (typeof pdata === "object") && (pdata !== null) )
        {
            Object.keys(pdata).map((item) => {
                if( pdata[item].value === '@CustomEntry') {
                    self.UpdateHighlightElementLabel(item, pdata[item].customTextEntry);
                } else {
                    const displayValue = mappedSelOptions[pdata[item].value] ? mappedSelOptions[pdata[item].value] : pdata[item].value;
                    self.UpdateHighlightElementLabel(item, displayValue);
                }
            });
        }
    }

    loadData = async (resource, assignmentId) => {
        if (!this.previewFetching) {

            this.previewFetching = true;
            const resultPreviewData = await Api.getContractPreview(resource.url)
                .then(
                    (res) => {
                        // console.log('api success');
                        if(res.fields) {
                            const decodeDiv = window.document.createElement('textarea');
                            res.fields = res.fields.map((item) => {
                                decodeDiv.innerHTML = item.label;
                                const decodedLabel = decodeDiv.firstChild.nodeValue;
                                return {
                                    field: item.field,
                                    label: decodedLabel,
                                    class: item.class
                                }
                            })
                        }

                        return res;
                    },
                    () => {
                        // console.log('api fail');console.log(err);
                        return null
                    }
                )
                .catch((err) => {
                    console.log(err);
                    return null
                })

            // TODO optimize process to get brandId.
            //  options:
            //   1 - ask backend dev to create a shorter endpoint call using assignmentId only,
            //   2 - or pass in from all component usage locations.
            //   3 - or refactor to pull from redux store, IF reliable
            const agentSignersList = []
            let agentSignerDefault = ''
            const assignmentData = await Api.getAssignment(assignmentId).catch(() => {})
            const { campaign } = assignmentData || {}
            const { id: campaignId } = campaign|| {}
            if(campaignId) {
                const campaignData = await Api.getCampaign(campaignId).catch(() => {})
                const { agencyBrand } = campaignData || {}
                const { brandId } = agencyBrand || {}
                agentSignerDefault = campaignData.signer ? campaignData.signer.id : '';
                if (brandId) {
                    const allBrandAgents = await Api.getBrandAgents(brandId).catch(() => {})
                    if(Array.isArray(allBrandAgents)) {
                        allBrandAgents.filter(a => [SUPER_ADMIN_STR, BRAND_OWNER_STR].includes(a.role) ).forEach(a => {
                            if(a.id) {
                                agentSignersList.push({
                                    value: a.id,
                                    label: `${a.firstname} ${a.lastname} (${DisplayRoleNames[a.role] || a.role})`
                                })
                            }
                        })
                    }
                }
            }

            const presetAgentSigner = {
                id: "preset_AgentSigner",
                lbl: "Agent Signer",
                options: agentSignersList,
                defaultValue: agentSignerDefault
            }

            this.setState(prevState =>{
                const presetFields = [presetAgentSigner]; // add other preset fileds here
                const defaultStateOptions = { ...prevState.selectedOptions};
                // set the defaults for the preset fields if they dont exist
                presetFields.forEach(f => {
                    const { id, lbl, defaultValue} = f;
                    defaultStateOptions[id] = defaultStateOptions[id] || {value: defaultValue, customTextEntry: '', mappingKey: lbl};
                })

                return {
                    ...prevState,
                    previewData : resultPreviewData,
                    presetFields,
                    selectedOptions: defaultStateOptions
                }
            })
        }
    };

    handleScrollTopOnclick = (e) => {
        if (this.scrollbars && e && e.target && e.target.className.startsWith('templateHighlight')){
            const scrollDist = e.target.offsetTop - 70;
            // this.scrollbars.scrollTop(scrollDist < 0 ? 0 : scrollDist);
            this.scrollbars.view.scroll({
                top: scrollDist < 0 ? 0 : scrollDist,
                left: 0,
                behavior: 'smooth'
            });


            const classTag = e.target.className.replace('templateHighlight', '').trim();
            this.handleScrollTopFieldPanel(classTag);
        }
    };

    handleScrollTopFieldPanel = (classtag) => {
        const ftag = `fieldTag-${classtag}`;
        const feles = this.scrollbarsFieldsList.container.getElementsByClassName(ftag);
        const fele = feles && feles.length > 0 ? feles[0] : null;

        if(fele) {
            this.scrollbarsFieldsList.view.scroll({
                top: fele ? fele.offsetTop : 0,
                left: 0,
                behavior: 'smooth'
            });
        }

    };

    findHighlightElement = (className) => {
        const self =this;
        const ele = window.document.getElementsByClassName(className);
        if (this.scrollbars && ele.length > 0 ){
            const scrollDist = ele[0].offsetTop - 70;
            setTimeout(() => {
                if (!self.scrollbars || !self.scrollbars.view) {
                    return;
                }
                self.scrollbars.view.scroll({
                    top: scrollDist < 0 ? 0 : scrollDist,
                    left: 0,
                    behavior: 'smooth'
                });
                if (ele[0] && ele[0].classList) {
                    ele[0].classList.add("bright");
                    setTimeout(() => {
                        if (ele[0] && ele[0].classList) {
                            ele[0].classList.remove("bright");
                        }
                    }, 1500);
                }
            }, 300);
        }

        const newState = this.getErrorClassState(className);
        this.setState(newState);

    };

    UpdateHighlightElementLabel = (className, label) => {
        const ele = [...window.document.getElementsByClassName(className)];
        ele.forEach( function(element) {
            element.innerHTML = label.replace(/[\u00A0-\u9999<>&]/gim, (i) => { return `&#${i.charCodeAt(0)};`; });
        });

    };

    handleSelectedOptionChange = (props , classNameId, defaultLabel) => {
        let label;
        let value = '';
        if(!props) {
            label = `[${defaultLabel}]`;
        } else {
            label = `[${props.label}]`;
            value = props.value;

            if(props.className === 'Select-create-option-placeholder') {
                label = props.label;
            }
        }

        const currentStateOptions = { ...this.state.selectedOptions};
        currentStateOptions[classNameId] = {value, customTextEntry: '', mappingKey: defaultLabel};
        this.setState({
            selectedOptions: currentStateOptions
        }, this.UpdateHighlightElementLabel(classNameId, label));
    };

    handleSelectedOptionTextEntryChange = (event, classNameId) => {
        const customTextEntry = event.target.value && event.target.value.length > 0 ? event.target.value : '';
        const currentStateOptions = { ...this.state.selectedOptions};
        currentStateOptions[classNameId] = {
            value: currentStateOptions[classNameId].value,
            customTextEntry,
            mappingKey: currentStateOptions[classNameId].mappingKey
        };
        const newState = this.getErrorClassState(classNameId);
        newState.selectedOptions = currentStateOptions;
        this.setState(newState, this.UpdateHighlightElementLabel(classNameId, customTextEntry));

        // const self = this;
        // if (self.state.typingTimeout) {
        //     clearTimeout(self.state.typingTimeout);
        // }
        //
        // self.setState({
        //     name: event.target.value,
        //     typing: false,
        //     typingTimeout: setTimeout(function () {
        //         console.log(event.target.value, className);
        //     }, 5000)
        // });
    };

    getErrorClassState = (classNameId) => {

        const { missingMappingFields, missingCustomEntryList } = this.state;

        const m1 = missingMappingFields.indexOf(classNameId);
        const m2 = missingCustomEntryList.indexOf(classNameId);

        // something was removed
        if (m1 !== -1 || m2 !== -1) {
            const newState = {};
            if(m1 !== -1) {
                newState.missingMappingFields = missingMappingFields.filter(cnId => cnId !== classNameId);
            }
            if(m2 !== -1) {
                newState.missingCustomEntryList = missingCustomEntryList.filter(cnId => cnId !== classNameId);
            }
            return newState;
        } 
        return {};
        
    };

    promptTextCreator = (label) => {
        return `Enter text "${label}"`;
    };

    onSaveButtonClick = () => {
        const { saveCallback} = this.props;
        const { previewData, selectedOptions, presetFields } = this.state;
        const missingMappingList = [];
        const missingCustomEntryList = [];
        if (!previewData) {
            return saveCallback(selectedOptions);
        }
        // this checks all dynamic fields against the generated class name identifier
        previewData.fields.forEach((item) => {
            if(!selectedOptions[item.class] || !selectedOptions[item.class].value ) {
                missingMappingList.push(item.class);
            }
            if(selectedOptions[item.class] && selectedOptions[item.class].value === '@CustomEntry' && selectedOptions[item.class].customTextEntry === '') {
                missingCustomEntryList.push(item.class);
            }
        });
        // this checks all pre-set fields against the preset id
        presetFields.forEach((p) => {
            const presetSelected = selectedOptions[p.id]
            if(!presetSelected || (presetSelected && !presetSelected.value)) {
                missingMappingList.push(p.id);
            }
            if(presetSelected && presetSelected.value === '@CustomEntry' && presetSelected.customTextEntry === '') {
                missingCustomEntryList.push(p.id);
            }
        });

        if(missingMappingList.length > 0 || missingCustomEntryList.length > 0) {
            // console.log(missingMappingList);
            this.setState({
                missingMappingFields: missingMappingList,
                missingCustomEntryList
            }, () => {
                // scroll to missing fields if have any
                if(this.state.missingMappingFields.length > 0){
                    this.handleScrollTopFieldPanel(this.state.missingMappingFields[0]);
                } else if(this.state.missingCustomEntryList.length > 0){
                    this.handleScrollTopFieldPanel(this.state.missingCustomEntryList[0]);
                }
            })
        } else {
            // console.log('call save here');
            saveCallback(selectedOptions);
        }

        //
        // if(previewData && previewData.fields) {
        //     let previewDataFieldsClone = previewData.fields.map(function(v) {return JSON.parse(JSON.stringify(v));});
        //     previewDataFieldsClone = previewDataFieldsClone.map((item, idx) => {
        //         const mapJson = selectedOptions[item.class];
        //         item.mappingKey = mapJson && mapJson.value ? mapJson.value : null;
        //         item.customTextEntry = mapJson && mapJson.customTextEntry ? mapJson.customTextEntry : null;
        //         return item;
        //     });
        //
        //     saveCallback(previewDataFieldsClone);
        // }


    };

    render() {
        const { name, saveBtnLabel, cancelCallback } = this.props;
        const { previewData, selectedOptions, selectOptions, missingMappingFields, missingCustomEntryList, presetFields } = this.state;
        const dangerHtml = emptyObject;
        if(previewData && previewData.result) {
            dangerHtml.__html = DOMPurify.sanitize(this.state.previewData.result);
        }

        return (
            <div className={style.contractSettings}>
                <SplitPane split="vertical" minSize={100} defaultSize={460} maxSize={-50}>
                    <div className={style.cConfig}>
                        <div className={style.cConfigContent}>
                            <div className={style.ccHeader}>
                                <div className={style.ccTitle}>{name}</div>
                            </div>

                            <div className={style.ccContent}>
                                <Scrollbars
                                    ref={sf => { this.scrollbarsFieldsList = sf; }}
                                    autoHeight
                                    autoHeightMin={100}
                                    autoHeightMax="100%"
                                    autoHide
                                    autoHideTimeout={1000}
                                    autoHideDuration={200}
                                >
                                    <div className={style.ccFields}>
                                        {!previewData && <Spinner label="Loading"/>}
                                        <div className="mandatoryFields">
                                            {presetFields.map(f => {
                                                const { id, lbl, options, defaultValue } = f
                                                return <div key={id} className="presetFieldList">
                                                    <div className={style.fieldLabel}>{lbl}</div>
                                                    <Select
                                                        className={cx(style.fieldSel, missingMappingFields.includes(id) ? style.missingSel : '')}
                                                        name="form-field-name"
                                                        arrowRenderer={this.arrowRenderer}
                                                        value={ selectedOptions[id] && selectedOptions[id].value ? selectedOptions[id].value : (defaultValue || '') }
                                                        onChange={(opts) => this.handleSelectedOptionChange(opts, id, lbl)}
                                                        options={options}
                                                        promptTextCreator={this.promptTextCreator}
                                                    />

                                                    <Fade top collapse distance="5px" when={(missingMappingFields.includes(id))}>
                                                        <div className={style.fieldRequiredLbl}>
                                                            <span>* required</span>
                                                        </div>
                                                    </Fade>
                                                </div>
                                            })}
                                        </div>

                                        {previewData && previewData.fields &&
                                                previewData.fields.map((item, idx) => {

                                                    // deep copy
                                                    // let newOptions = JSON.parse(JSON.stringify(selectOptions));
                                                    // newOptions = newOptions.map((item) => {
                                                    //     item['className'] = item.class;
                                                    //     return item;
                                                    // });

                                                    const showCustField = (!!selectedOptions[item.class] && selectedOptions[item.class].value === '@CustomEntry');

                                                    return <div key={idx} className={cx(style.fieldItem, `fieldTag-${item.class}`)}>
                                                        <div className={style.fieldLabel} onClick={() => this.findHighlightElement(item.class)}>{item.label}</div>
                                                        <Select
                                                            className={cx(style.fieldSel, missingMappingFields.includes(item.class) ? style.missingSel : '')}
                                                            name="form-field-name"
                                                            arrowRenderer={this.arrowRenderer}
                                                            value={ selectedOptions[item.class] && selectedOptions[item.class].value ? selectedOptions[item.class].value : '' }
                                                            onChange={(opts) => this.handleSelectedOptionChange(opts, item.class, item.label)}
                                                            options={selectedOptions[item.class] && selectedOptions[item.class].options ? selectedOptions[item.class].options : selectOptions}
                                                            promptTextCreator={this.promptTextCreator}
                                                            onFocus={() => this.findHighlightElement(item.class)}
                                                        />

                                                        <Fade top collapse distance="5px" when={showCustField}>
                                                            <div
                                                                className={cx(style.fieldManualSel, missingCustomEntryList.includes(item.class) ? style.missingSel : '')}>
                                                                <input
                                                                    value={(selectedOptions[item.class] ? selectedOptions[item.class].customTextEntry : '')}
                                                                    onChange={(event) => this.handleSelectedOptionTextEntryChange(event, item.class)}
                                                                    type="text"
                                                                    placeholder="Enter Custom Terms"/>
                                                            </div>
                                                        </Fade>

                                                        <Fade top collapse distance="5px" when={(missingMappingFields.includes(item.class) ||  missingCustomEntryList.includes(item.class))}>
                                                            <div className={style.fieldRequiredLbl}>
                                                                <span>* required</span>
                                                            </div>
                                                        </Fade>

                                                    </div>
                                                })
                                        }

                                    </div>
                                </Scrollbars>
                            </div>

                            <div className={style.ccFooter}>
                                <div className={cx(style.ccBtn, style.ccCancel)} onClick={ cancelCallback }><span>Cancel</span></div>
                                <div className={cx(style.ccBtn, style.ccSave)} onClick={ this.onSaveButtonClick }><span>{saveBtnLabel}</span></div>
                            </div>
                        </div>
                    </div>

                    <div className={style.cPreview}>
                        <div ref={this.previewTemplateRef} className={style.cPreviewContent}>

                            <Scrollbars
                                ref={s => { this.scrollbars = s; }}
                                autoHide
                                autoHideTimeout={1000}
                                autoHideDuration={200}
                            >

                                {!previewData && <Spinner label="Loading"/>}

                                {previewData && previewData.result && <div>
                                    <div
                                        className={style.generated}
                                        dangerouslySetInnerHTML={dangerHtml} />
                                </div>}

                            </Scrollbars>

                        </div>
                    </div>
                </SplitPane>
            </div>
        )
    }
}
