import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import '../campaignInfluencers.scss';
import Lookup from '../../../../modules/Lookup';
import ConfirmRates from './ConfirmRates/ConfirmRates';
import NudgeModal from './NudgeModal';
import { AGENT_STR, VIEWER_STR } from '../../../../constants/authorities';
import FeedbackOnTheTopImproved from '../../../shared/feedbackOnTheTop/FeedbackOnTheTopImproved';
import RateIcon from '../../../shared/rateIcon/RateIcon'


const statuses = {
    accepted: 'ACCEPTED',
    completed: 'COMPLETED',
    expired: 'EXPIRED',
    negotiate: 'NEGOTIATE',
    pending: 'PENDING',
    declined: 'DECLINED',
    paid: 'PAID',
    revoked: 'REVOKED'
};

const getLastNegotiationsSlot = negotiations => negotiations.sort(
    (s1, s2) => Number(s2.id) - Number(s1.id)
)[0];

const getStatusDateInfo = ({
    contractStatus,
    firstName,
    negotiations,
    status,
    statusDateTime,
    totalAssignmentRate,
}) => {
    const { accepted, completed, expired, negotiate } = statuses;
    let statusDate = moment.utc(statusDateTime).local().format('MMM DD');
    let showRate = '';

    if (status === negotiate && negotiations.length) {
        const { player, playerAction, totalRate } = getLastNegotiationsSlot(negotiations);
        showRate = new Intl.NumberFormat({ currency: 'CAD' }).format(Number(totalRate));

        if (player === AGENT_STR) {
            statusDate = playerAction === 'ACCEPT' ? 'Accepted the rate of ' : 'Proposed a new rate of ';

            if (playerAction === 'ACCEPT') {
                showRate = totalAssignmentRate;
            }
        } else {
            statusDate = `${firstName} has changed the rate to `;
        }
    }

    if ((status === accepted || status === completed) && !contractStatus) {
        statusDate = 'Please complete the Contract';
    }

    return {
        statusDate,
        showRate,
    };
};

const getDatesInfo = ({
    contractStatus,
    firstName,
    negotiations = [],
    status,
    statusDateTime,
    totalAssignmentRate,
}) => {
    const { statusDate, showRate } = getStatusDateInfo({
        contractStatus,
        firstName,
        negotiations,
        status,
        statusDateTime,
        totalAssignmentRate,
    });

    return {
        statusDate,
        showRate,
    };
};

const getShowChangeRequestButton = (assignmentSlotChangeRequests, showButton) => {
    if (!showButton) return false;

    const { pending } = statuses;

    if (!assignmentSlotChangeRequests || !assignmentSlotChangeRequests.length) {
        return false;
    }

    const newestRequest = assignmentSlotChangeRequests.slice(-1)[0];

    return newestRequest.status === pending;
};


export default function AssignmentInfo(props) {
    const { accepted, completed, negotiate, declined, revoked } = statuses;
    const {
        assignmentSlotChangeRequests,
        contractStatus,
        firstName,
        negotiations,
        showButton,
        status,
        statusDateTime,
        totalRate,
        handleOpenContractStatus,
        startPropose,
        campaignId,
        campaign,
        assignmentId,
        user,
        rates,
        deliverables = {},
        totalReach,
        profile,
        symbol
    } = props;
    const isChangeRequest = getShowChangeRequestButton(assignmentSlotChangeRequests, showButton);
    const {
        statusDate,
        showRate,
    } = getDatesInfo({
        contractStatus,
        firstName,
        negotiations,
        status,
        statusDateTime,
        totalAssignmentRate: totalRate,
    });
    // const showStatus = status === statuses.negotiate ? statuses.pending : status;
    const showStatus = status;

    const { label, ovalColor } = Lookup.getStatusType(showStatus);
    let confirmRatesModal = null;
    const colorOval = {
        backgroundColor: ovalColor
    }
    const [nudgeModalUserData, setNudgeModalUserData] = useState(null);
    const [notificationMessage, setNotificationMessage] = useState(null);
    return (
        <>
            <FeedbackOnTheTopImproved
                show={!!notificationMessage}
                message={notificationMessage}
                closeFunction={() => setNotificationMessage(null)}
            />
            <NudgeModal
                isOpen={!!nudgeModalUserData}
                close={() => setNudgeModalUserData(null)}
                user={nudgeModalUserData}
                setNotificationMessage={setNotificationMessage}
                assignmentId={assignmentId}/>
            <ConfirmRates
                ref={(modal) => { confirmRatesModal = modal; }}
                handleOpenContractStatus={handleOpenContractStatus}
                campaignId={campaignId}
                campaign={campaign}
                assignmentId={assignmentId}
                user={user}
                rates={rates}
                totalReach={totalReach} />
            <div className="status">
                <div>
                    <div className="statusItem" key={label}>
                        <div className="label" style={colorOval}>
                            {label}
                        </div>
                        {!isChangeRequest &&
                            <div className={isChangeRequest ? "desc noPaddingLeft" : "desc"}>
                                {statusDate}
                                {Boolean(showRate) && <span>${showRate}</span>}
                            </div>
                        }
                    </div>
                    <div className="buttonsContainer">
                        {(status === accepted || status === completed) && !contractStatus && profile && profile.role !== VIEWER_STR &&
                            <div className="view">
                                <div
                                    className="button"
                                    onClick={() => confirmRatesModal.openModal()}
                                    role='button'
                                >
                                    Sign
                                </div>
                            </div>
                        }
                        {status === negotiate && profile && profile.role !== VIEWER_STR &&
                            <div className="view">
                                <div
                                    className="button"
                                    onClick={startPropose}
                                    role='button'
                                >
                                    View
                                </div>
                            </div>
                        }
                        {(profile && profile.role !== VIEWER_STR && status !== declined && status !== revoked && !user.isPublic) &&
                            <div
                                className="nudgeButton"
                                onClick={() => setNudgeModalUserData(user)}
                                role='button'
                            >
                                Nudge
                            </div>
                        }
                    </div>
                </div>
                <div className='deliverablesSum'>{Object.entries(deliverables).map(([key, value], index) => {
                    return (
                        value > 0 ? <div key={index}>
                            <RateIcon rateKey={key} countTip value={value} size={28} />
                        </div> : null
                    )
                })}</div>
            </div>
            <div className="cost">
                <div>
                    <div className="label">Total Cost</div>
                    <div className="value">{symbol || '$'}{new Intl.NumberFormat({ currency: campaign.currencyCode || 'CAD' }).format(Number(totalRate))}</div>
                </div>
            </div>
        </>
    );
}

AssignmentInfo.propTypes = {
    assignmentSlotChangeRequests: PropTypes.arrayOf(
        PropTypes.shape({
            assigneeId: PropTypes.number,
            assignmentId: PropTypes.number,
            createdOn: PropTypes.string,
            draftDate: PropTypes.string,
            id: PropTypes.string,
            publishDate: PropTypes.string,
            status: PropTypes.string,
            updatedOn: PropTypes.string,
        })
    ),
    contractStatus: PropTypes.bool,
    firstName: PropTypes.string,
    status: PropTypes.string,
    statusDateTime: PropTypes.string,
    showButton: PropTypes.bool,
    totalRate: PropTypes.number,
    handleOpenContractStatus: PropTypes.func,
    startPropose: PropTypes.func,
    profile: PropTypes.object,
};
