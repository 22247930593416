import {Auth} from 'aws-amplify';
import moment from 'moment';

export async function authUser() {
    try {
        const ssoToken = localStorage.getItem('sso_access_token');
        if (ssoToken) {
            const expireTime = localStorage.getItem('sso_access_token');
            if (moment().isAfter(expireTime)) {
                return false;
            }
            // return window.location.hostname ==='localhost' ? `ssoAuth ${ssoToken}` : ssoToken;
            return `ssoAuth ${ssoToken}`;
        }
        // console.log((await Auth.currentSession()).getIdToken().getJwtToken());
        return (await Auth.currentSession()).getIdToken().getJwtToken();
    } catch (e) {
       // console.log(e);
        return false;
    }
}

export function logout(cb){
    Auth.signOut().then(() => {
        cb();
    }).catch(err => console.log(err))
}
