import React from 'react';
import cx from 'classnames';

// props
// - url
// - className

const TIMEOUT = 5 * 1000 * 60; // five mins

const defaultPhoto = '/images/ic-avatar-blank-d.svg';

export default class InfluencerPhoto extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            version: '',
            unableLoadPhoto: false,
        };

        this.timeout = null;
    }

    updatePhoto = () => {
        this.setState({ version: this.state.version + 1 });
        this.timeout = setTimeout(this.updatePhoto, TIMEOUT);
    };

    componentDidMount() {
        this.setState({
            version: new Date().getTime(),
        });
        this.timeout = setTimeout(this.updatePhoto, TIMEOUT);
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    loadImageError = () => {
        this.setState({ unableLoadPhoto: true });
    };

    render() {
        const { unableLoadPhoto } = this.state;
        const { firstName, lastName, url, className } = this.props;
        if (unableLoadPhoto || !url || url === defaultPhoto) {
            if (!firstName && !lastName) {
                return <img src={defaultPhoto} alt={url} />;
            }
            const firstLetterOfFirstName = firstName ? firstName[0] : '';
            const firstLetterOfLastName = lastName ? lastName[0] : '';
            const initial = firstLetterOfFirstName || firstLetterOfLastName;
            return <div className={cx('defaultImage', className)}>{initial}</div>;
        }
        return <img src={url} onError={this.loadImageError} alt={url} className={className} />;
    }
}
