const prefix = '@@INFLUENCER_SEARCH';

export const BEFORE_ADD_TO_CAMPAIGN = `${prefix}__BEFORE_ADD_TO_CAMPAIGN`;
export const CLEAR_ALL = `${prefix}__CLEAR_ALL`;
export const FINISH_INVITE = `${prefix}__FINISH_INVITE`;
export const GET_DATA = `${prefix}__GET_DATA`;
export const INFLUENCERS_LOADING = `${prefix}__INFLUENCERS_LOADING`;
export const LIST_SPLASH_FILTER_CHANGE = `${prefix}__LIST_SPLASH_FILTER_CHANGE`;
export const PAGE_CHANGE = `${prefix}__PAGE_CHANGE`;
export const PAGE_LIMIT_CHANGE = `${prefix}__PAGE_LIMIT_CHANGE`;
export const SEARCH_FILTERS_CHANGE = `${prefix}__SEARCH_FILTERS_CHANGE`;
export const SELECT_ALL = `${prefix}__SELECT_ALL`;
export const SELECTION_CHANGE = `${prefix}__SELECTION_CHANGE`;
export const SET_API_ERROR = `${prefix}__SET_API_ERROR`;
export const SET_DATA = `${prefix}__SET_DATA`;
export const SET_PAGE_LIMIT = `${prefix}__SET_PAGE_LIMIT`;
export const SET_PLEASE_SHOW_WAIT = `${prefix}__SET_PLEASE_SHOW_WAIT`;
export const SET_CURRENCY = `${prefix}__SET_CURRENCY`;
export const SET_SEARCH_FILTERS = `${prefix}__SET_SEARCH_FILTERS`;
export const SET_SHOW_DISCOVERY = `${prefix}__SET_SHOW_DISCOVERY`;
export const SET_SORT_FILTER = `${prefix}__SET_SORT_FILTER`;
export const SET_ORDER_TYPE = `${prefix}__SET_ORDER_TYPE`;
export const SHOW_MISSING_CHANNELS_ERROR = `${prefix}__SHOW_MISSING_CHANNELS_ERROR`;
export const SORT_FILTER_CHANGE = `${prefix}__SORT_FILTER_CHANGE`;
export const ORDER_TYPE_CHANGE = `${prefix}__ORDER_TYPE_CHANGE`;
export const RESET_DATA = `${prefix}__RESET_DATA`;
export const INVITE_PAGE_CHANGE = `${prefix}__INVITE_PAGE_CHANGE`;
export const CLEAR_CURRENT_PAGE = `${prefix}__CLEAR_CURRENT_PAGE`;
export const REFRESH_SEARCH = `${prefix}__REFRESH_SEARCH`;
