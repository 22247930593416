import React from 'react'
import TopNavigation from "../../shared/topNavigation/TopNavigation";
import './style.scss'
import AddNewUserForm from "./addNewUserForm";
import AllUsers from "./allUsers";
import EditUser from "./editUser";
import Modal from "react-modal";
import {getAgentBrands} from "../../../store/agent/actions";
import {connect} from "react-redux";


class AdminManagementPage extends React.Component {
    state = {
        permissions: {
            info: false,
            summary: false,
            rates: false,
            portfolio: false,
            assigment: false,
            acceptAssigment: false,
            declineAssigment: false,
            negotiate: false
        },
        activeNav: 'all',
        userToEdit: null,
        removeModalIsOpen: false,
    };

    componentDidMount() {
        if (!this.props.brands) {
            this.props.getAgentBrands();
        }
    }

    handleNavClick = (name) => {
        this.setState({activeNav: name, userToEdit: null})
    };

    handleSetUserToEdit = (user) => {
        this.setState({userToEdit: user})
    };

    setUserToDelete = (user) => {
        this.setState({userToDelete: user})
    };

    handleOpenRemoveModal = () => {
        this.setState({removeModalIsOpen: true})
    };

    handleCloseRemoveModal = () => {
        this.setState({removeModalIsOpen: false, userToDelete: null})
    };

    handleRemoveUser = () => {
        // Api.deleteUser(userToDelete.id)
        this.handleCloseRemoveModal()
    };


    render () {
        const {activeNav, userToEdit} = this.state;
        const { brands, authCheckData } = this.props;
        return (
            <div className="admin-management-page">
                <TopNavigation  />
                <div className="blue-header admin-header underline">
                    <div className="contentDiv">
                        <div className="controlContainer">User Management</div>
                    </div>
                </div>
                <div className="admin-management-container">
                    <div className="admin-management-sidebar">
                        <ul className="admin-management-navigation">
                            <li className={activeNav === 'all' ? 'active' : ''} onClick={() => this.handleNavClick('all')}>All Users</li>
                            <li className={activeNav === 'add' ? 'active' : ''} onClick={() => this.handleNavClick('add')}>Add Users</li>
                        </ul>
                    </div>
                    <div className="admin-management-content-holder">
                        <div className="admin-management-content">
                            <Modal isOpen={this.state.removeModalIsOpen}
                                overlayClassName="invite-modal-overlay"
                                className="invite-modal create-brand-modal permission-page agentAdmin new-user-form"
                                onRequestClose={this.handleCloseRemoveModal}
                                shouldCloseOnOverlayClick
                                ariaHideApp={false}
                            >
                                <div className="modal-header">Are you sure?</div>
                                <div className="manager-card-bottom new-user-form-bottom">
                                    <button onClick={this.handleCloseRemoveModal} className="button cancel">Cancel</button>
                                    <button onClick={this.handleRemoveUser} className="button">Delete</button>
                                </div>

                            </Modal>
                            {
                                userToEdit ? <EditUser handleSetUserToEdit={this.handleSetUserToEdit}
                                    returnToAll={() => this.handleNavClick('all')}
                                    user={userToEdit}
                                    brands={brands} 
                                    role={authCheckData.user.role} /> :
                                    activeNav === 'add' ?
                                        <AddNewUserForm brands={brands} returnToAll={() => this.handleNavClick('all')} role={authCheckData.user.role} />
                                        :
                                        <AllUsers setUserToDelete={this.setUserToDelete} handleOpenRemoveModal={this.handleOpenRemoveModal} handleSetUserToEdit={this.handleSetUserToEdit} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const mapDispatchToProps = dispatch => {
    return {
        getAgentBrands: () => {
            dispatch(getAgentBrands())
        }
    }
};

const mapStateToProps= (state) => {
    return{
        brands: state.agent.brands
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminManagementPage)