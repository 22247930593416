import React, { useState, useRef, useEffect } from 'react';
import './verifyPage.scss';
import { Motion, spring } from 'react-motion'
import { Link } from 'react-router-dom';
import Api from '../../modules/Api';
import { LoadingDialogue } from '../shared/loadingDialogue/loadingDialogue';
import ApiError from '../shared/apiError/ApiError';


const VerifyPage = ({
    history,
    match: { params: { code } },
}) => {
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [button, setButton] = useState('');
    const [apiError, setApiError] = useState(null);

    useEffect(() => {
        if (code) {
            setLoading(true);
            Api.accountVerify(code)
                .then(res => {
                    setLoading(false);
                    if(res && res.statusCode === 400) {
                        setTitle('Error');
                        setMessage('This verification link is already expired. You can request a new re-verification link after login.');
                        setButton('Login');    
                    }else {
                        setTitle('Success');
                        setMessage('Your account is now verified!');
                        setButton('Login');    
                    }
                })
                .catch(err => {
                    setLoading(false);
                    // setApiError(err);
                    setTitle('Error');
                    setMessage('The server has encountered an error.');
                    setButton('Retry');    
                })
        } else {
            history.push('/');
        }
    }, []);

    const onClickButton = () => {
        if (button === 'Retry') {
            window.location.reload();
        }else {
            history.push('/login');
        }
    }

    return (
        <div className='verifyPageContainer'>
            <Motion defaultStyle={{ x: 120, y: 0.01 }}
                style={{
                    x: spring(0, { stiffness: 130, damping: 30 }),
                    y: spring(1, { stiffness: 130, damping: 30 })
                }}>
                {({ x, y }) =>
                    loading ? <LoadingDialogue show={loading}/> : apiError === null ? <div className="completeBox" style={{
                        opacity: `${y}`,
                        WebkitTransform: `translate3d(0,${x}px, 0)`,
                        transform: `translate3d( 0, ${x}px,0)`,
                    }}>
                        <div className="logoImageContainer">
                            <Link to='/' className="logoAnchor">
                                <img className="logoImage" src="/images/Koalifyed-Logo.png" />
                            </Link>
                        </div>
                        <div className="contentContainer">
                            <div className="msgTitle">{title}</div>
                            <div>{message}</div>
                        </div>
                        <div className="submit-value-container">
                            <button className="submit-value" onClick={onClickButton}>
                                {button}
                            </button>
                        </div>
                    </div> : null
                }
            </Motion>
            <ApiError show={apiError !== null} error={apiError} cancelFunction={() => setApiError(null)}/>
        </div>
    )
}

export default VerifyPage;