import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import TopNavigation from '../shared/topNavigation/TopNavigation'
import "./SupportPage.scss";
import ProfileHelper from '../../modules/utils/ProfileHelper';
import { TALENT_MANAGER_STR, INFLUENCER_STR } from '../../constants/authorities';

const SupportPage = () => {
    const [addExtraTop, setAddExtraTop] = useState(false);
    useEffect(() => {
        ProfileHelper.getCurrentLoginUserProfile()
            .then(profile => {
                if (profile && (profile.role === TALENT_MANAGER_STR || profile.role === INFLUENCER_STR) && !profile.accountVerified) {
                    setAddExtraTop(true);
                }
            })
    }, [])

    return (
        <div className="support-page">
            <TopNavigation current="support" />
            <div className="opportunity">
                <div className={cx("opportunityHeader supportHeader", {extraHeader: addExtraTop})}>
                    <div className="contentDiv">
                        <div className="listedPanelTitle">Support</div>
                    </div>
                </div>
                <div className={cx("opportunityContainer extra", {moreExtra: addExtraTop})}>
                    <div className="contentDiv">
                        <div className="supportPage">
                            <div className="spContent">
                                <h2>Training Library and Helpdesk</h2>
                                <a href="mailto:helpdesk@koalifyed.com" target="_blank" rel="noopener noreferrer">
                                    <div className="spItem">
                                        <h3>Helpdesk</h3>
                                        <p>Having issues with the system? Submit a ticket to us</p>
                                    </div>
                                </a>
                                <a href="/playbook" target="_blank" rel="noopener noreferrer">
                                    <div className="spItem">
                                        <h3>Playbook</h3>
                                        <p>Access step-by-step instructions with screenshots</p>
                                    </div>
                                </a>
                                <a href="/video" target="_blank" rel="noopener noreferrer">
                                    <div className="spItem">
                                        <h3>Training Videos</h3>
                                        <p>Learn more about Koalifyed via training videos</p>
                                    </div>
                                </a>
                                <a href="/faq" target="_blank" rel="noopener noreferrer">
                                    <div className="spItem">
                                        <h3>FAQs</h3>
                                        <p>Find answers to frequently asked questions</p>
                                    </div>
                                </a>
                                <a href="/releasenotes" target="_blank" rel="noopener noreferrer">
                                    <div className="spItem">
                                        <h3>Release Notes</h3>
                                        <p>Overview of features included in each release</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SupportPage
