import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import ComplianceContent from './ComplianceContent/ComplianceContent';
import PleaseWait from '../../shared/pleaseWait/PleaseWaitWhite';
import ApiError from '../../shared/apiError/ApiError';
import Api from '../../../modules/Api';
import './compliance.scss';

const getCampaignAssignments = (campaignId, assignmentId) =>
    Api.getCampaignAssignment(campaignId)
        .then((res) => {
            const assignments = res.results.map((val) => ({ value: val.id, label: val.name }));
            const selectedAssignments = assignments.find(({ value }) => value === Number(assignmentId)) || 'ALL';

            return {
                allAssignments: [{ value: 'ALL', label: 'All assignments' }].concat(assignments),
                selectedAssignments: [selectedAssignments],
            };
        })
        .catch((err) => {
            console.error(err);
            return {
                allAssignments: [{ value: 'ALL', label: 'All assignments' }],
                selectedAssignments: [],
            };
        });

export default function Compliance(props) {
    const {
        match: { params },
        campaignId,
        setNavigationFunction,
    } = props;

    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [usersData, setUsersData] = useState([]);
    const [assignments, setAssignments] = useState({
        allAssignments: [{ value: 'ALL', label: 'All assignments' }],
        selectedAssignments: [{ value: 'ALL', label: 'All assignments' }],
    });

    useEffect(() => {
        const selectedAssigments = getSelectedAssignmentIds();

        if (selectedAssigments.length) {
            setIsLoading(true);
            Api.getCampaignPublishedPosts({
                campaignId,
                params: { sortby: 'name', assignmentIds: selectedAssigments },
            })
                .then(({ results }) => setUsersData(results))
                .finally(() => setIsLoading(false));
        }
    }, [assignments]);

    useEffect(() => {
        const assignmentId = Number(params.assignmentId);
        setNavigationFunction(assignmentId);

        setIsLoading(true);
        getCampaignAssignments(campaignId, assignmentId)
            .then((res) => setAssignments(res))
            .finally(() => setIsLoading(false));
    }, []);

    const handleSelectAssignments = (allSelected) => {
        if (allSelected[0]?.value === 'ALL') allSelected = allSelected.slice(1);

        const containsAll = allSelected.find((selected) => selected.value === 'ALL');
        if (containsAll) {
            setAssignments((prev) => ({ ...prev, selectedAssignments: [{ value: 'ALL', label: 'All assignments' }] }));
            return;
        }

        const allSelectedValues = allSelected.map((selected) => selected.value);
        const selectedAssignments = allSelectedValues.map((selectedVal) =>
            assignments.allAssignments.find((assignment) => assignment.value === selectedVal),
        );

        if (selectedAssignments.length) {
            setAssignments((prev) => ({ ...prev, selectedAssignments }));
        } else {
            setAssignments((prev) => ({ ...prev, selectedAssignments: [{ value: 'ALL', label: 'All assignments' }] }));
        }
    };

    const getSelectedAssignmentIds = () => {
        const isAllSelected = assignments.selectedAssignments.find((a) => a.value === 'ALL');
        if (isAllSelected) {
            return assignments.allAssignments.filter((a) => a.value !== 'ALL').map((a) => a.value);
        } else {
            return assignments.selectedAssignments.map((a) => a.value);
        }
    };

    return (
        <div className="compliance-page">
            <ApiError
                show={isError}
                cancelFunction={() => setIsError(false)}
                errTitle=" "
                error="Oops! We're unable to load some of the data right now. Please try again later."
            />
            <PleaseWait show={isLoading} top={157} />
            <div className="header">
                <div className="selectAssignments">
                    <div className="select-box">
                        <div className="label">Assignment</div>
                        <Select
                            className="select"
                            clearable={false}
                            value={assignments.selectedAssignments}
                            options={assignments.allAssignments}
                            onChange={(selected) => handleSelectAssignments(selected)}
                            placeholder=""
                            multi
                        />
                    </div>
                </div>
            </div>

            <ComplianceContent data={usersData} />
        </div>
    );
}
