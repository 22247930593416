import Api from '../../../modules/Api';


// CKEditor: Return a promise that will be resolved when the file is uploaded.
export default class UploadAdapter {
    constructor(loader) {
        this.loader = loader;
    }

    async upload() {
        try {
            const file = await this.loader.file;
            const response = await Api.uploadResource(file, true);

            return Promise.resolve({ default: response.url });
        } catch (error) {
            return Promise.reject();
        }
    }
}
