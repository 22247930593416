import cx from 'classnames';
import moment from "moment";
import React, { useEffect, useRef, useState } from 'react';
import { COMPLETED, EDITS_SENT, PUBLISHED } from '../../../constants/statusTypes';
import Api from '../../../modules/Api';
import ConfirmModal from '../../shared/confirmModal/ConfirmModal';
import PleaseWaitPartial from '../../shared/pleaseWait/PleaseWaitPartial';
import UserDraftPostResourceComments from './UserDraftPostResourceComments';

const RandomString = () => {return Math.random().toString(36)};

const UserDraftPostResources = ({refreshResources, resourceList, showVideos, downloadAllVideos, onRemoveVideo, onAddVideo,
    openMediaModal, userDraftPostId, showApiError, thumbnailOverlayAdditionalStyles, withDelete, profile, isBrandViewer, draftPostStatus}) => {

    const resources = resourceList ? resourceList.filter(r => r.status === "ACTIVE") : [];

    const displayOptions = {
        thumbnails: "Thumbnails",
        list: "List"
    }

    const [selectedResource, setSelectedResource] = useState(resources[0]?.resource.id);
    const [uploadingDetails, setUploadingDetails] = useState(null);
    const [onUploadCancel, setOnUploadCancel] = useState(null);
    const [displayOption, setDisplayOption] = useState(displayOptions.list);
    const [deleteResourceId, setDeleteResourceId] = useState(null);
    const [isMoreMenuVisible, setMoreMenuVisible] = useState(false);
    const [inputKey, setInputKey] = useState(RandomString());
    const inputReference = useRef(null);
    const dotRef = useRef(null);

    const onResourceDelete = (resourceId) => {
        setDeleteResourceId(null);
        setSelectedResource(null);
        if (userDraftPostId)
            Api.userDraftPostResourceAction(userDraftPostId, resourceId, withDelete ? "delete" : "archive").then(res => {
                refreshResources();
            }).catch((error) => {
                console.log(`Failed to archive resource with error: ${  error}`);
                if (showApiError)
                    showApiError("Failed to archive resource.")
            });
        else if (onRemoveVideo)
            onRemoveVideo(resourceId);
    }

    const handleVideosUpload = async ({ target: { files } }) => {
        if (files.length > 0) {
            for (const file of files) {
                try {
                    // if (file.size > 5368709120 && file.name) {
                    //     showApiError('Allowed maximum size is 5GB. Please upload a smaller file: ' + file.name);
                    // } else {
                    await uploadFileGradually(file);
                    // }
                } catch (error) {
                    showApiError("Error while uploading the file!");
                    console.log(`Error while uploading the file: ${  error}`);
                }
            }
        }
    };

    const resetFileInput = () => {
        inputReference.current = null;
        setInputKey(RandomString());
    }

    const uploadFileGradually = (file) => {
        const updateFileProgress = (progressPercentage) => {
            if (progressPercentage > 0) {
                const fileDetails = {...uploadingDetails};
                fileDetails.name = file.name;
                fileDetails.uploadedPercentage = progressPercentage;
                setUploadingDetails(fileDetails);
            }
        }

        const updateFileFinished = (response) => {
            if (userDraftPostId)
                Api.userDraftPostResourceAction(userDraftPostId, response.id, "create").then(res => {
                    refreshResources();
                    setUploadingDetails(null);
                }).catch((error) => {
                    console.log(`Failed to create resource with error: ${  error}`);
                    if (showApiError)
                        showApiError("Failed to create resource.")
                });
            else if (onAddVideo)
                onAddVideo(response);
        }

        return Api.postS3FileGradually(file,
            (progressPercentage) => updateFileProgress(progressPercentage),
            false,
            null
        ).then((response) => {
            updateFileFinished(response);
            setOnUploadCancel(null);
            setUploadingDetails(null);
            resetFileInput();
        }).catch((err) => {
            showApiError("Error while uploading the file!");
            console.log(`Error while uploading the file: ${  err}`);
            setOnUploadCancel(null);
            setUploadingDetails(null);
            resetFileInput();
        })
    }

    const renderButtons = () => {

        const onDownloadClick = () => {
            setMoreMenuVisible(false);
            downloadAllVideos();
        }

        const onUploadClick = () => {
            if (inputReference.current)
                inputReference.current.click();
            setMoreMenuVisible(false);
        }

        const changeDisplayOption = (displayOption) => {
            setDisplayOption(displayOption);
            setMoreMenuVisible(false);
        }

        const onOpenMediaModal = () => {
            if (isBrandViewer) {
                return;
            }
            openMediaModal();
            setMoreMenuVisible(false);
        }

        const clickoutside = (ref) => {
            useEffect(() => {
                function handleClickOutside(event) {
                    if (ref.current && !ref.current.contains(event.target)) {
                        setMoreMenuVisible(false)
                    }
                }
                // Bind the event listener
                document.addEventListener("mousedown", handleClickOutside);
                return () => {
                // Unbind the event listener on clean up
                    document.removeEventListener("mousedown", handleClickOutside);
                };
            }, [ref]);
        }

        clickoutside(dotRef);

        const renderMoreMenu = () => {
            const activeBtnStyle = {cursor: "auto", color: "#333333"};
            if (isMoreMenuVisible)
                return (
                    <div ref={dotRef} className="more-menu" style={!openMediaModal ? {height: "110px"} : null}>
                        <span className="title">
                            DISPLAY AS
                        </span>
                        <span
                            onClick={displayOption === displayOptions.thumbnails ? null : () => changeDisplayOption(displayOptions.thumbnails)}
                            style={displayOption === displayOptions.thumbnails ? activeBtnStyle : null}
                            role='button'
                        >
                            {displayOptions.thumbnails}
                        </span>
                        <span
                            onClick={displayOption === displayOptions.list ? null : () => changeDisplayOption(displayOptions.list)}
                            style={displayOption === displayOptions.list ? activeBtnStyle : null}
                            role='button'
                        >
                            {displayOptions.list}
                        </span>
                        {openMediaModal &&
                            <span className={cx("media-btn", {disabled: isBrandViewer})} onClick={onOpenMediaModal} role='button'>
                                Manage media
                            </span>
                        }
                    </div>
                )
            return null;
        }

        return (
            <div className="media-header-buttons">
                {downloadAllVideos &&
                    <div className="btn" onClick={onDownloadClick} role='button'>
                        Download all
                    </div>
                }
                <button
                    className={cx("btn", {disabled: isBrandViewer || draftPostStatus === COMPLETED || draftPostStatus === PUBLISHED || draftPostStatus === EDITS_SENT})}
                    disabled={isBrandViewer || draftPostStatus === COMPLETED || draftPostStatus === PUBLISHED || draftPostStatus === EDITS_SENT}
                    onClick={onUploadClick}
                    type="button"
                >
                    <input  type="file"
                        ref={inputReference}
                        key={inputKey}
                        multiple
                        hidden
                        onChange={handleVideosUpload} />
                    Upload files
                </button>
                <div className="more-btn" onClick={() => setMoreMenuVisible(!isMoreMenuVisible)} role='button'>
                    <img src="/images/ic-more.svg" alt='more'/>
                </div>
                {renderMoreMenu()}
            </div>
        );
    }

    const renderResourceThumbnails = () => {
        const thumbnailOverlayAdditionalStyle = thumbnailOverlayAdditionalStyles || {};
        const customStyles = {top: "220px", left: "auto", right: "auto", bottom: "auto", ...thumbnailOverlayAdditionalStyle};
        const renderResource = (resource, index) => {
            const uniqueKey = resource.resource.id;
            let thumbnailUrl = (resource.resource && resource.resource.thumbnailUrl) ? resource.resource.thumbnailUrl : null;
            if (!thumbnailUrl && (
                resource.resource.mimeType.includes("jpg")
                    || resource.resource.mimeType.includes("jpeg")
                    || resource.resource.mimeType.includes("gif")
                    || resource.resource.mimeType.includes("png")
                    || resource.resource.mimeType.includes("webp")
            )) {
                thumbnailUrl = resource.resource.url;
            }

            return (
                <div   key={uniqueKey}
                    id={uniqueKey}
                    className="resource-thumbnail-item"
                    style={(index === 0 && !uploadingDetails) ? {marginLeft: "20px"} : null}
                    onClick={showVideos ? () => showVideos(resource, index) : null}>
                    {(resource.percentage > 0 && resource.percentage < 100) &&
                        <PleaseWaitPartial
                            show
                            container={document.getElementById(uniqueKey)}
                            customStyles={customStyles}
                            progressPercentage={resource.percentage}/>
                    }
                    <div className="resource-thumbnail">
                        <img src={thumbnailUrl || "/images/ic-doc.svg"} className={thumbnailUrl ? "thumbnail-preview" : null} />
                    </div>
                    <div className="resource-name">
                        {resource.resource.name}
                    </div>
                    <div className="resource-date">
                        {moment(resource.updatedOn).format("MMM DD, YYYY")}
                    </div>
                </div>
            )
        }

        return (
            <div className="resources-thumbnails">
                {uploadingDetails &&
                    <div   key="upload-indicator-thumbnail"
                        className="resource-thumbnail-item"
                        style={{marginLeft: "20px"}}>
                        <div className="resource-thumbnail" id="uploading-thumbnail-id">
                            <PleaseWaitPartial  show
                                customStyles={customStyles}
                                container={document.getElementById("uploading-thumbnail-id")}
                                progressPercentage={uploadingDetails.uploadedPercentage}
                                onCancel={onUploadCancel}/>
                        </div>
                        <div className="resource-name">
                            {uploadingDetails.name}
                        </div>
                    </div>
                }
                {resources && resources.map((resource, index) => renderResource(resource, index))}
            </div>
        );
    }

    const renderResourceList = () => {
        const customStyles = {top: "auto", left: "auto", right: "auto", bottom: "auto", flexDirection: "row"};
        const renderResource = (resource, index) => {
            const isLast = index === resources.length - 1;
            const uniqueKey = resource.resource.id;
            const currentResourceId = resource.resource.id;
            const isSelected = currentResourceId === ( selectedResource ? selectedResource : resources[0]?.resource.id);
            return (
                <div   key={uniqueKey}
                    id={uniqueKey}
                    className= { isSelected ? 'resource-row resource-row-selected' : 'resource-row'}
                    onClick={() => setSelectedResource(currentResourceId)}
                    style={isLast ? {borderBottom: "none"} : null}>
                    <div className="resource-name" onClick={showVideos ? () => showVideos(resource, index) : null}>
                        {resource.resource.name}
                    </div>
                    {/* <div className="resource-comments">
                        {resource.commentsCount}
                    </div> */}
                    <div className="resource-date">
                        {moment(resource.updatedOn).format("MMM DD, YYYY")}
                    </div>
                    <div className="resource-author">
                        {resource.creator}
                    </div>
                    <div className="resource-delete-icon">
                        {((!withDelete || !profile || (profile.uid === resource.creatorUid)) && !isBrandViewer) &&
                            <img src="/images/ic-trash-dark.svg" onClick={() => setDeleteResourceId(resource.resource.id)} />
                        }
                    </div>
                    {(resource.percentage > 0 && resource.percentage < 100) &&
                        <PleaseWaitPartial  show
                            container={document.getElementById(uniqueKey)}
                            customStyles={customStyles}
                            progressPercentage={resource.percentage}/>
                    }
                </div>
            )
        }

        return (
            <div className="resources-list">
                {uploadingDetails &&
                    <div   key="upload-indicator-row"
                        className='resource-row'
                        id="upload-indicator-row-id">
                        <div className="resource-name">
                            {uploadingDetails.name}
                        </div>
                        <PleaseWaitPartial  show
                            container={document.getElementById("upload-indicator-row-id")}
                            progressPercentage={uploadingDetails.uploadedPercentage}
                            customStyles={customStyles}
                            onCancel={onUploadCancel}/>
                    </div>
                }
                <div className="resource-row resource-header">
                    <div className="resource-name-header"> Name </div>
                    {/* <div className="resource-comments"> Comments </div> */}
                    <div className="resource-date"> Uploaded on </div>
                    <div className="resource-author"> Uploaded by </div>
                    <div className="resource-delete-icon"> </div>
                </div>
                {resources && resources.map((resource, index) => renderResource(resource, index))}
            </div>
        );
    }

    return (
        <div className="user-draft-post-resources-container">
            <ConfirmModal
                show = {deleteResourceId}
                proceedFunction = {() => onResourceDelete(deleteResourceId)}
                cancelFunction = {() => setDeleteResourceId(null)}
                message = {`Are you sure you want to ${withDelete ? "delete" : "archive"} this resource ?`}
                actionText={withDelete ? "DELETE" : "ARCHIVE"}
            />
            <div className='media-resources'>
                <div className="media-header">
                    <label>
                        {`Media (${resources.length})`}
                    </label>
                    {renderButtons()}
                </div>
                {displayOption === displayOptions.list && renderResourceList()}
                {displayOption === displayOptions.thumbnails && renderResourceThumbnails()}
            </div>
            <div className='media-comments'>
                    <UserDraftPostResourceComments
                        userDraftPostId={userDraftPostId}
                        userDraftPostResourceId={selectedResource ? selectedResource : resources[0]?.resource.id}
                    />
            </div>
        </div>

    )
}

export default UserDraftPostResources;
