import { OPEN_CHAT, CLOSE_CHAT, CHANGE_SELECTED_INFLUENCER } from "../actions/chatActionTypes";

const initialChatState = { isChatOpen: false, activeInfluencerId: -1, activeInfluencerObj: {} };

export default function chatReducer(state = initialChatState, action) {
    switch (action.type) {
        case OPEN_CHAT: {
            return { ...state, isChatOpen: true };
        }
        case CLOSE_CHAT: {
            return { ...state, isChatOpen: false };
        }
        case CHANGE_SELECTED_INFLUENCER: {
            let newActiveInfluencerId = -1;
            if (action.activeInfluencerId === state.activeInfluencerId) {
                typeof state.activeInfluencerId === 'string' ? newActiveInfluencerId = action.activeInfluencerId : newActiveInfluencerId = action.activeInfluencerId + '';
            } else {
                newActiveInfluencerId = action.activeInfluencerId;
            }
            return { ...state, activeInfluencerId: newActiveInfluencerId, activeInfluencerObj: action.activeInfluencerObj };
        }
        default:
            return state;
    }
}