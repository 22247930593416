import {GET_AGENT_LIST, GET_AGENT_BRANDS, RESET_AGENT_BRANDS} from "../actions/types";


export default function agentReducer(state = {}, action) {
    switch (action.type) {
        case GET_AGENT_LIST : {
            return Object.assign({}, state, {
                list : action.list
            })
        }
        case GET_AGENT_BRANDS : {
            return Object.assign({}, state, {
                brands : action.list
            })
        }
        case RESET_AGENT_BRANDS : {
            return Object.assign({}, state, {
                brands : null
            })
        }
        default:
            return state;
    }
}