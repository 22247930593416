import React from 'react'

import SocialNetworks from '../../modules/influencer/SocialNetworks'
import Format from '../../modules/utils/Format'

export default class ColorBlockGraph extends React.Component {

    constructor (props, context) {
        super(props, context);

        this.renderedColorBlock = this.renderedColorBlock.bind(this);
        this.calculateWidths = this.calculateWidths.bind(this);
        
        this.state = {
            width: 340
        }
    }

    calculateWidths(blocks, totalReach) {
        let minWidth = 40;
        let totalWidthReduced = this.state.width;
        let totalReachReduced = totalReach;
        for (let i=blocks.length-1; i>=0; i--) {
            let b = blocks[i];
            let width = (totalWidthReduced * b.reach) / totalReachReduced;
            if (width <= minWidth) {
                width = minWidth;
                totalWidthReduced -= minWidth;
                totalReachReduced -= b.reach;
            }
            b.width = width;
        }
    }

    static getDerivedStateFromProps(props, state) {
        let boxWidth = props.boxWidth;
        let width = 340;
        if (boxWidth < 800) {
            width = boxWidth - 470;
        }
        return {
            width: width
        }
    }

     renderedColorBlock(block) {
        //console.log(block);

        let bgColor = '#eef1f3';
        let realColor = block.color;
        if (realColor) {
            bgColor = realColor;
        }
        let style = {
            width: block.width,
            minWidth : block.width,
            maxWidth : block.width
        };

        let fillerStyle = {
            textAlign: "center",
            backgroundColor: bgColor
        }

        let imageSize = 26;
        let imgStyle = {
            width: imageSize,
            height: imageSize,
        }

        let ks = <div>{Format.expressInK(block.reach)}</div>

        return (
            <div style={style} key={block.id} title={block.label}>
                <div className="filler" style={fillerStyle}>
                    <img style={imgStyle} src={block.image}/>
                    <div className="ks">{ ks }</div>
                </div>
            </div>
        )
    }

    render() {

        let colorBlocks = [];

        let networkOptions = SocialNetworks.getNetworkOptionList();
        
        if (Array.isArray(networkOptions) && Array.isArray(this.props.userNetworks) && this.container) {
            let networks = this.props.userNetworks;

            // for test
            // networks = [
            //     { networkType : 'twitter',
            //         reach:11},
            //     { networkType : 'facebook', reach:33} ,
            //     { networkType : 'youtube', reach:333333333}];

            networks.sort(function(n1, n2){
                return n2.reach - n1.reach;
            })
            let totalReach = 0;
            let blocks = [];
            for (let n of networks) {
                let reach = Number(n.reach);
                if (isNaN(reach) || reach === 0) {
                    continue;
                }
                let o = networkOptions.find( (no) => { return no.id === n.networkType?.toUpperCase()});
                if (o) {
                    o.reach = reach;
                    totalReach += reach;
                    blocks.push(o);
                } else {
                   // console.log('>>>>>>>>>>>>>>>>>>>>>>>>>',n);
                }
            }
            this.calculateWidths(blocks, totalReach);

            for (let block of blocks) {
                colorBlocks.push(this.renderedColorBlock(block));
            }
        }

        return (
            <div className="colorBlocks" ref={ e => { this.container = e }} style={{width:this.state.width}}>
                { colorBlocks }
            </div>
        );
    }
}