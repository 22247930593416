import React, { Component } from 'react';
import get from 'lodash/get';
import head from 'lodash/head';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import Api from '../../../../modules/Api';
import BrandLogoSection from './components/BrandLogoSection';
import constants from './constants';
import utils from '../utils';
import FORM_UTILS from './components/Form/utils';

import Footer from './components/Footer';
import Form from './components/Form';

import ApiError from '../../../shared/apiError/ApiError';
import { competitiveBrandsValidator } from './helpers';

class AddBrandCountryModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            imagePreview: undefined,
            brandName: '',
            billingContact: '',
            advertisers: undefined,
            buttonIsClicked: false,
            errors: {
                brandName: undefined,
                advertisers: undefined,
                billingContact: undefined,
                competitiveBrands: undefined
            },
            isAvatarLoading: false,
            uploadProgressPercentage: null,
            onUploadCancel: null,
            apiError: null,
            countryCode: undefined,
            defaultCurrencyCode: undefined,
            countryList: [],
            competitiveBrands: [],
            currencyList: [],
            accessRights: []
        };
    }

    componentDidMount = async () => {

        const {
            validateEmail,
            validateField,
            validateSelect,
        } = FORM_UTILS;

        const {
            advertiserOptionsFactory,
            convertAdvertiserOption,
            convertBrandOwnerOption,
        } = utils;

        const {
            brand,
            userAdvertisers,
        } = this.props;

        const billingContact = get(brand, 'email', '');

        const brandName = get(brand, 'brand', '');

        const advertisersOptions = advertiserOptionsFactory(userAdvertisers);

        const PREDEFINED_ADVERTISER = get(brand, 'advertiserOption', {});

        const PREDEFINED_BRAND_OWNER = get(brand, 'agents', []);

        const convertedAdvertiser = convertAdvertiserOption(PREDEFINED_ADVERTISER);

        let convertedBrandOwner = [];
        let competitiveBrands = [];

        PREDEFINED_BRAND_OWNER.forEach((brandOwner) => {
            convertedBrandOwner = [...convertedBrandOwner, convertBrandOwnerOption(brandOwner)];
        });

        const advertisers = [convertedAdvertiser];


        if(brand?.id) {
            this.setState({ loading: true });

            try {
                competitiveBrands = await Api.getBrandCompetitors(brand.id).then(result => {
                    return result.map(c => ({
                        name: c.socialHandle,
                        error: false,
                        errorMessage: null,
                        valid: true,
                        uid: c.uid
                    }))
                });
            } catch (error) {
                this.setState({ apiError: error, loading: false });
            }
        }

        Api.getCurrencyList().then(result => {
            const { data } = result;
            const currencyList = []
            data.forEach(x => {
                const currency = {label: `${x.code} (${x.symbol})`, value: x.code, symbol: x.symbol}
                currencyList.push(currency)
            })
            this.setState({currencyList})
        })
        const accessOptions = [{label: "Full Access", value: "fullAccess", items: ["edit:plan","create:plan","delete:plan","view:plan","create:campaign","edit:campaign",
            "view:campaign"]}, 
        {label: "Discovery & Planning", value:"discoveryPlanning", items: ["edit:plan","create:plan","delete:plan","view:plan"]}]
        let foundOption = null
        const accessRights = get(brand, 'accessRights', []);
        accessOptions.forEach(item => {
            const checker1 = item.items.every(v => accessRights.includes(v));
            const checker2 = accessRights.every(v => item.items.includes(v));
            if (checker1 && checker2) {
                foundOption = item.value
            }
        })
        this.setState({
            loading: false,
            billingContact: billingContact || '',
            brandName,
            advertisers,
            imagePreview: get(brand, 'logo', undefined),
            advertisersOptions,
            errors: {
                brandName: validateField(brandName),
                billingContact: validateEmail(billingContact),
                advertisers: validateSelect(advertisers),
                competitiveBrands: undefined
            },
            countryCode: get(brand, 'countryCode', undefined),
            defaultCurrencyCode: get(brand, 'defaultCurrencyCode', undefined),
            competitiveBrands,
            accessRights: foundOption,
        });
    }

    onHandleFile = async (image) => {
        this.setState({ isAvatarLoading: true });
        const { imageObject } = image;
        try {
            const imageResponse = await Api.uploadResource(
                imageObject,
                true,
                null,
                null,
                (percentage) => this.setState({uploadProgressPercentage: percentage}),
                (fn) => this.setState({onUploadCancel: fn})
            );
            this.setState({
                imagePreview: get(imageResponse, 'url'),
                isAvatarLoading: false,
                uploadProgressPercentage: null,
                onUploadCancel: null
            });
        } catch (error) {
            this.setState({
                isAvatarLoading: false,
                uploadProgressPercentage: null,
                onUploadCancel: null
            });
        }
    };

    onSearchChange = (text) => {
        const {
            brandOwnerOptionsFactory,
        } = utils;
        const fields = {
            sortby: "name",
            order: "asc",
            num: 50
        }
        Api.searchUserList(text, fields).then(response => {
            this.setState({ brandOwnersOptions: brandOwnerOptionsFactory(response.results) })
        })
            .catch(err => {
                this.setState({apiError: err});
            })
    }

    setCountryList = list => {
        list.sort((a,b) => a.label.localeCompare(b.label))
        this.setState({countryList: list})
    }

    onSelectChange = ({ target: { name, value } }) => {
        const { countryList } = this.state;
        const {
            validateSelect,
        } = FORM_UTILS;
        this.setState(({ errors }) => ({
            [name]: value,
            errors: {
                ...errors,
                [name]: validateSelect(value),
            },
        }));
        if (name === 'countryCode') {
            const currencyCodeToUse = countryList.find(item => item.value === value)
            this.setState({defaultCurrencyCode: currencyCodeToUse.currencyCode})
        }
    };

    onTextChange = (isEmail) => ({ target: { name, value } }) => {
        const {
            validateField,
            validateEmail,
        } = FORM_UTILS;

        this.setState(({ errors }) => ({
            [name]: value,
            errors: {
                ...errors,
                [name]: isEmail ? validateEmail(value) : validateField(value)
            }
        }));
    };

    validateCompetitiveBrands = async () => {
        try {
            const validation = await competitiveBrandsValidator(
                this.state.competitiveBrands
            );
            this.setState({ competitiveBrands: validation.brands })
            return validation.valid
        } catch (e) {
            this.setState({ apiError: e })
            return false;
        }
    }

    onSubmit = async () => {
        const { validateForm } = FORM_UTILS;
        const { onManipulateBrand, brand } = this.props;
        const { emptyFieldKey, wrongEmailKey } = constants;
        const {
            errors,
            billingContact,
            defaultCurrencyCode,
            countryCode,
            advertisers,
            accessRights,
            brandName,
            imagePreview
        } = this.state;
        this.setState({ buttonIsClicked: true, loading: true });
        const competitiveBrandsValid = await this.validateCompetitiveBrands();
        const errorsValues = Object.values(errors);
        const containsEmptyField = validateForm(emptyFieldKey, errorsValues);
        const containsWrongEmail = validateForm(wrongEmailKey, errorsValues);
        if (containsEmptyField || containsWrongEmail || !competitiveBrandsValid) {
            this.setState({ loading: false })
            return;
        }
        const advertiser = head(advertisers);
        const accessOptions = [{label: "Full Access", value: "fullAccess", items: ["edit:plan","create:plan","delete:plan","view:plan","create:campaign","edit:campaign",
            "view:campaign"]}, 
        {label: "Discovery & Planning", value:"discoveryPlanning", items: ["edit:plan","create:plan","delete:plan","view:plan"]}]
        const access = accessOptions.find(x => x.value === accessRights).items
        const brandParentId = brand.parentBrandId;
        const data = {
            name: brandName,
            billingContractEmail: billingContact,
            advertiserId: get(advertiser, 'id'),
            defaultCurrencyCode,
            countryCode: countryCode || 'US',
            competitorBrands: this.state.competitiveBrands.map(b => ({
                uid: b.uid,
                socialHandle: b.name
            })),
            accessRights: access,
            logoUrl: imagePreview || null,
        }
        this.setState({ loading: false })
        onManipulateBrand(brandParentId, data);
    };

    onCompetitiveBrandsChange = (brands) => {
        this.setState({
            competitiveBrands: brands
        })
    }

    render() {
        const { onCloseModal, show, brand } = this.props;
        const {
            brandName,
            billingContact,
            advertisers,
            advertisersOptions,
            errors,
            buttonIsClicked,
            isAvatarLoading,
            apiError,
            countryCode,
            defaultCurrencyCode,
            countryList,
            competitiveBrands,
            accessRights,
            uploadProgressPercentage,
            onUploadCancel,
            imagePreview
        } = this.state;
        return <Modal
            isOpen={show}
            overlayClassName="modalDialog"
            className="dialog">
            <div className="brandOwnerEdit">
                <ApiError
                    show={apiError}
                    error={apiError}
                    cancelFunction={() => this.setState({apiError: null})}
                />
                <div className="header">Add Brand Country</div>
                <div className="brandForm">
                    <BrandLogoSection
                        isUploading={isAvatarLoading}
                        onChangeFile={this.onHandleFile}
                        imagePreview={imagePreview}
                        brand={brandName}
                        uploadProgressPercentage={uploadProgressPercentage}
                        onUploadCancel={onUploadCancel}
                    />
                    <Form
                        buttonIsClicked={buttonIsClicked}
                        errors={errors}
                        brandName={brandName}
                        billingContact={billingContact}
                        advertisers={advertisers}
                        advertisersOptions={advertisersOptions}
                        type="add_country"
                        onTextChange={this.onTextChange}
                        onSelectChange={this.onSelectChange}
                        onSearchChange={this.onSearchChange}
                        countryCode={countryCode}
                        defaultCurrencyCode={defaultCurrencyCode}
                        countryList={countryList}
                        setCountryList={this.setCountryList}
                        competitiveBrands={competitiveBrands}
                        onCompetitiveBrandsChange={this.onCompetitiveBrandsChange}
                        accessRights={accessRights}
                    />
                </div>
                <Footer
                    isSubmitDisabled={isAvatarLoading}
                    onSubmit={this.onSubmit}
                    onCancel={onCloseModal}
                    submitButtonTitle="Add Country"
                />
            </div>
        </Modal>
    }
}

AddBrandCountryModal.propTypes = {
    brand: PropTypes.shape({
        id: PropTypes.string,
        advertiser: PropTypes.string,
        email: PropTypes.string,
    }),
    userAdvertisers: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
    })),
    onManipulateBrand: PropTypes.func,
    type: PropTypes.string,
    onCloseModal: PropTypes.func,
};

export default AddBrandCountryModal;
