import React from 'react';
import './filterTags.scss';

export const FilterTags = ({ selectedOptions, handleRemove, handleClearFilters }) => {
    return (
        <>
            {selectedOptions.map((option) => (
                <div className="filter-tag" key={`${option.tagType}_${option.value}`}>
                    {option.filterLabel} {option.label}
                    <div className="remove-icon-container" onClick={() => handleRemove(option.tagType, option.value)}>
                        <div className="remove-icon">+</div>
                    </div>
                </div>
            ))}
            <button className="clear-all-button" onClick={handleClearFilters}>
                Clear all
            </button>
        </>
    );
};
