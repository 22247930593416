import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PleaseWait from '../shared/pleaseWait/PleaseWait';
import PleaseWaitWhite from '../shared/pleaseWait/PleaseWaitWhite';
import FeedbackOnTheTop from '../shared/feedbackOnTheTop/FeedbackOnTheTop';
import ApiError from '../shared/apiError/ApiError';
import PaginationNew from '../shared/pagination/PaginationNew';
import ListingTop from './listingTop/ListingTop';
import MissingChannelsModal from '../shared/modals/misingChannels/MissingChannels';
import ErrorMessageBox from '../shared/errorMessageBox/ErrorMessageBox';
import './influencers.scss';
// imports below have cycle dependencies (potential memory leak)
import OneInfluencer from '../oneInfluencerNew/OneInfluencer';
import ListsSplash from './searchPanel/ListsSplash';
import Invite from './invite/Invite';
import Format from '../../modules/utils/Format';

import {
    beforeAddToCampaign,
    clearAll,
    finishInvite,
    listSplashFilterChange,
    pageChange,
    pageLimitChange,
    searchFilterChange,
    selectAll,
    selectionChange,
    setApiError,
    setData,
    setShowMissingChannelsError,
    sortFilterChange,
    orderTypeChange,
    invitePageChange,
    clearCurrentPage,
} from '../../store/campaign/influencerSearch/actionCreators';
import { influencerBodySelector } from '../../store/campaign/influencerSearch/selectors';
import { engagementPublic, engagementRatePublic, followersPublic } from '../../store/campaign/influencerSearch/reducer';
import { setData as setGeneralData } from '../../store/general/actionCreators';
import DiscoverPanel from './discoverPanel/DiscoverPanel';
import { PUBLIC_SOURCE_INFLUENCERS_MAX_COUNT } from '../../constants/lookup-constants';

class Influencers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            favoriteList: null,
            searchBarHeight: null,
        };
    }

    inviteSingle = (id) => {
        const {
            actions: { selectionChange },
        } = this.props;
        const isSelected = true;
        const runInvitation = true;

        selectionChange(id, isSelected, runInvitation);
    };

    unInvite = (id) => {
        const {
            actions: { selectionChange },
        } = this.props;
        const isSelected = false;

        selectionChange(id, isSelected);
    };

    addToCampaignClickHandler = () => {
        const {
            actions: { beforeAddToCampaign },
        } = this.props;

        // check corresponding saga for more detailed explanation
        beforeAddToCampaign();
    };

    listSplashFiltersChange = (filters) => {
        const {
            actions: { listSplashFilterChange, searchFilterChange },
        } = this.props;

        // // todo. this.searchPanel.setFilter is not working
        // // listSplashFilterChange(filters, this.discoveryPanel.setFilter);
        // this.discoveryPanel.setFilter(filters);
        const searchData = {
            type: 'CONNECTED',
            connectedFilters: filters ? [filters] : [],
        };
        searchFilterChange(searchData);
    };

    onFiltersChange = (searchData) => {
        const {
            favoriteData,
            actions: { setData, searchFilterChange },
        } = this.props;

        let favList = null;
        if (searchData && searchData.favorites && searchData.favorites.length > 0) {
            const fav = favoriteData.find((f) => f.id === searchData.favorites[0]);
            if (fav) {
                favList = fav.name;
            }
        }

        this.setState({ favoriteList: favList });

        setData({
            currentPage: 1,
        });
        searchFilterChange(searchData);
    };

    formatInfluencersData = (specificUser) => {
        const { alreadyInvitedUsers, assignmentId, assignmentName, inviteList } = this.props;

        let validCount = 0;

        let users;
        if (!specificUser) {
            users = this.combineFavoriteSelectedUsers();
        } else {
            users = [];
            users.push(specificUser);
        }
        const formattedInfluencers = users.map((influencer) => {
            const { id, uid, userNetworks } = influencer;
            let invited = '';
            let inviteDisabled = false;
            const alreadyInvitedIndex = alreadyInvitedUsers.findIndex(({ user }) => user.uid === influencer.uid);
            const checked = inviteList.findIndex(({ uid }) => uid === influencer.uid) > -1;

            if (alreadyInvitedUsers.length && alreadyInvitedIndex > -1) {
                const { status } = alreadyInvitedUsers[alreadyInvitedIndex];
                invited = `${status}: Assignment [${assignmentName}]`;
            }

            if (assignmentId && alreadyInvitedIndex > -1) {
                inviteDisabled = true;
            }

            if (!invited && userNetworks.length > 1) {
                validCount += 1;
            }

            return {
                checked,
                details: influencer,
                id: id ?? uid,
                invited,
                inviteDisabled,
            };
        });

        return {
            formattedInfluencers,
            validCount,
        };
    };

    combineFavoriteSelectedUsers = () => {
        const { influencers, favoriteData, searchFilters } = this.props;

        const tempList = [...influencers];
        if (searchFilters && searchFilters.length > 0) {
            // it cares about filterId is 33 only which is for favorite
            const favoriteFilter = searchFilters.find((filter) => filter.filterId === 33);
            if (favoriteFilter !== undefined) {
                const favoriteFilterProperties = favoriteFilter.filterProperties;
                // favoriteFilterProperties is an array but currently only select one favorite
                for (let i = 0; i < favoriteFilterProperties.length; i++) {
                    const favoriteItem = favoriteData.find((data) => data.id === favoriteFilterProperties[i]);
                    if (favoriteItem && favoriteItem.users) {
                        for (let m = 0; m < tempList.length; m++) {
                            const index = favoriteItem.users.findIndex((u) => u === tempList[m].id);
                            // if the influencer has been deselected from this favorite list, then remove it from the display list
                            if (index === -1) {
                                tempList.splice(m, 1);
                            }
                        }
                    }
                }
            }
        }
        return tempList;
    };

    closeErrorPopup = () => {
        const {
            actions: { setApiError },
        } = this.props;

        setApiError(null);
    };

    closeFeedbackOnTop = () => {
        const {
            actions: { clearAll, setData },
        } = this.props;

        setData({
            showInviteSuccess: false,
        });
        clearAll();
    };

    noResultAcknowledged = () => {
        const {
            actions: { setData },
            campaignId,
        } = this.props;

        setData({
            showNoResults: false,
            showDiscovery: !campaignId,
        });
    };

    closeInvite = () => {
        const {
            actions: { setData },
        } = this.props;

        setData({
            inviteInProgress: false,
        });
    };

    closeMissingChannelsErrorMessage = () => {
        const {
            actions: { setShowMissingChannelsError },
        } = this.props;

        setShowMissingChannelsError(false);
    };

    handlePageChange = (val) => {
        const {
            actions: { clearAll, pageChange },
        } = this.props;
        invitePageChange();
        pageChange({ nextPage: val });
    };

    handlePageLimitChange = (val) => {
        const {
            searchFilters,
            actions: { clearAll, pageLimitChange, pageChange },
            pageLimitOptions,
            currentPage,
        } = this.props;
        clearAll();

        if (pageLimitOptions) {
            const matchValue = pageLimitOptions.find((item) => item.value === val);
            if (matchValue) {
                const maxPage = PUBLIC_SOURCE_INFLUENCERS_MAX_COUNT / val;
                const isSourceTypePublic = searchFilters.type === 'PUBLIC';

                if (isSourceTypePublic && currentPage > maxPage) {
                    pageChange({ nextPage: maxPage, preventRefresh: true });
                }
                pageLimitChange(matchValue);
            }
        }
    };

    handleSortChange = (val) => {
        const {
            actions: { clearAll, sortFilterChange },
        } = this.props;
        clearAll();
        sortFilterChange({ sort: val });
    };

    handleOrderChange = (val) => {
        const {
            actions: { clearAll, orderTypeChange },
        } = this.props;
        clearAll();
        orderTypeChange(val);
    };

    gotoProfile = (existPublicProfile) => {
        this.setState({ existPublicProfile });
    };

    setSearchBarHeight = (height) => {
        this.setState({ searchBarHeight: height });
    };

    clearCurrentPage = () => {
        const {
            inviteList,
            influencers,
            actions: { clearCurrentPage },
        } = this.props;

        const newInviteList = inviteList.filter((invite) => influencers.find((influencer) => influencer.id === invite.id) === undefined);
        const obj = {};
        newInviteList.forEach((newInvite) => (obj[newInvite.uid] = newInvite));
        clearCurrentPage(obj);
    };

    render() {
        const { favoriteList, existPublicProfile, searchBarHeight } = this.state;
        const {
            actions: { clearAll, finishInvite, selectAll, selectionChange, setApiError },
            apiError,
            appropriateInviteList,
            assignmentId,
            campaignId,
            currentPage,
            favorites,
            inviteInProgress,
            inviteList,
            missingChannelsErrorMessage,
            pageLimit,
            pageLimitOptions,
            permissions,
            profile,
            searchFilters,
            showDiscovery,
            showInviteSuccess,
            showMissingChannelsError,
            showMissingChannelsModal,
            showNoResults,
            showPleaseWait,
            showPleaseWaitWhite,
            sort,
            sortOrder,
            sortOptions,
            totalCount,
            disableMultipleInvite,
            influencers,
            alreadyInvitedUsers,
        } = this.props;
        const isSourceTypePublic = searchFilters.type === 'PUBLIC';
        const sortOptionsBySource = isSourceTypePublic ? [followersPublic, engagementPublic, engagementRatePublic] : sortOptions;
        if (showPleaseWaitWhite) {
            return <PleaseWaitWhite show={showPleaseWaitWhite} />;
        }

        let totalCountLimited = totalCount;
        if (isSourceTypePublic && totalCount > PUBLIC_SOURCE_INFLUENCERS_MAX_COUNT) {
            totalCountLimited = PUBLIC_SOURCE_INFLUENCERS_MAX_COUNT;
        }
        const isLastPublicSourcePage = currentPage * pageLimit === PUBLIC_SOURCE_INFLUENCERS_MAX_COUNT;

        const permission = profile && profile.role && permissions && permissions.includes(`ROLE_${profile.role}`);

        const { formattedInfluencers, validCount } = this.formatInfluencersData();

        const { formattedInfluencers: formattedCreators } = this.formatInfluencersData(existPublicProfile);

        const availableInfluencersQty = formattedInfluencers.reduce((qty, { inviteDisabled }) => {
            qty += !inviteDisabled ? 1 : 0;
            return qty;
        }, 0);

        const startIndex = (currentPage - 1) * pageLimit + 1;
        const endIndex = Math.min(startIndex + pageLimit - 1, totalCount);
        let listingStyle = null;
        if (searchBarHeight) {
            listingStyle = { top: `${searchBarHeight + 24 + 60 + (!disableMultipleInvite ? 60 : 0)}px` };
        } else if (!disableMultipleInvite) {
            listingStyle = { top: '180px' };
        }
        let allSelected = false;
        if (Array.isArray(influencers) && Array.isArray(inviteList) && Array.isArray(alreadyInvitedUsers)) {
            const listWithoutAlreadyInvited = influencers.filter(
                (inf) => undefined === alreadyInvitedUsers.find((alreadyInvite) => alreadyInvite.user.uid === inf.uid),
            );
            allSelected =
                listWithoutAlreadyInvited.find((influencer) => undefined === inviteList.find((invite) => invite.id === influencer.id)) ===
                undefined;
        }
        return (
            <div className={`influencers${disableMultipleInvite ? ' disableMultipleInvite' : ''}`}>
                {inviteList.length && inviteInProgress && (
                    <Invite
                        assignmentId={assignmentId || 0}
                        campaignId={campaignId || 0}
                        inviteList={inviteList}
                        appropriateInviteList={appropriateInviteList}
                        inviteInProgress={inviteInProgress}
                        afterSuccessFunction={finishInvite}
                        apiErrorFunction={setApiError}
                        finishFunction={this.closeInvite}
                        uninviteFunction={this.unInvite}
                    />
                )}
                <FeedbackOnTheTop message="Invite successfully sent." show={showInviteSuccess} closeFunction={this.closeFeedbackOnTop} />

                <ErrorMessageBox
                    show={showMissingChannelsError}
                    message={missingChannelsErrorMessage}
                    closeFunction={this.closeMissingChannelsErrorMessage}
                />

                <PleaseWait show={showPleaseWait} />

                <ApiError show={apiError} error={apiError} cancelFunction={this.closeErrorPopup} />
                <DiscoverPanel
                    searchFilters={searchFilters}
                    changeFunction={this.onFiltersChange}
                    disableMultipleInvite={disableMultipleInvite}
                    profile={this.props.profile}
                    setSearchBarHeight={this.setSearchBarHeight}
                    gotoProfile={this.gotoProfile}
                />
                {!showDiscovery && showNoResults && (
                    <div className="noResult" onClick={!assignmentId ? this.noResultAcknowledged : null}>
                        <div>No creators met your search criteria. Please try again by applying different filters.</div>
                    </div>
                )}
                {!showDiscovery && !showNoResults && (
                    <div
                        className="listing"
                        ref={(e) => {
                            this.listContainer = e;
                        }}
                        style={listingStyle}
                    >
                        <div className="influencerListingContainer">
                            <div className="resultInfoContainer">
                                Showing {Format.formatNumber(startIndex)}-{Format.formatNumber(endIndex)} of{' '}
                                {Format.formatNumber(totalCount)} results
                            </div>
                            <ListingTop
                                isFavoritesVisible={!isSourceTypePublic}
                                permission={permission}
                                sort={sort}
                                sortTypeChange={this.handleSortChange}
                                pageLimit={pageLimit}
                                changePageLimitFunction={this.handlePageLimitChange}
                                selectAllFunction={allSelected ? this.clearCurrentPage : selectAll}
                                validCount={validCount}
                                allSelected={allSelected}
                                selectedCount={inviteList.length}
                                addToCampaignFunction={this.addToCampaignClickHandler}
                                favoriteList={favoriteList}
                                sortOptions={sortOptionsBySource}
                                pageLimitOptions={pageLimitOptions}
                                orderType={sortOrder}
                                orderDisabled={sort === 'bestMatch'}
                                orderTypeChange={this.handleOrderChange}
                            />
                        </div>

                        {formattedInfluencers.map(({ checked, details, id, invited, inviteDisabled }) => (
                            <OneInfluencer
                                key={id}
                                checked={checked}
                                details={details}
                                invited={invited}
                                inviteDisabled={inviteDisabled}
                                permission={permission}
                                favoriteData={favorites}
                                // internal={true}
                                inviteFunction={this.inviteSingle} // invite single
                                selectFunction={selectionChange} // changeSelection
                            />
                        ))}
                        {isSourceTypePublic && isLastPublicSourcePage && (
                            <div className="publicMaxPageLimitMessage">
                                In order to see more relevant results, please use search or additional filters.
                            </div>
                        )}
                        <PaginationNew
                            total={totalCountLimited || 0}
                            limit={pageLimit}
                            current={currentPage}
                            handlePageChange={this.handlePageChange}
                            parentContainer={this.listContainer}
                            limitChangeFunction={this.handlePageLimitChange}
                        />
                    </div>
                )}
                {existPublicProfile &&
                    formattedCreators &&
                    formattedCreators.map(({ checked, details, id, invited, inviteDisabled }) => (
                        <OneInfluencer
                            key={id}
                            checked={checked}
                            details={details}
                            invited={invited}
                            inviteDisabled={inviteDisabled}
                            permission={permission}
                            favoriteData={favorites}
                            // internal={true}
                            inviteFunction={this.inviteSingle} // invite single
                            selectFunction={selectionChange} // changeSelection
                            showProfileSidePanel
                            closeProfileSidePanel={() => this.setState({ existPublicProfile: undefined })}
                        />
                    ))}

                {showDiscovery && (
                    <ListsSplash onClickFunction={this.listSplashFiltersChange} searchBarHeight={this.state.searchBarHeight} />
                )}

                {showMissingChannelsModal && <MissingChannelsModal />}
            </div>
        );
    }
}

const mapStateToProps = (state) => influencerBodySelector(state);
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            beforeAddToCampaign,
            clearAll,
            finishInvite,
            listSplashFilterChange,
            pageChange,
            pageLimitChange,
            searchFilterChange,
            selectAll,
            selectionChange,
            setApiError,
            setData,
            setGeneralData,
            setShowMissingChannelsError,
            sortFilterChange,
            orderTypeChange,
            invitePageChange,
            clearCurrentPage,
        },
        dispatch,
    ),
});

Influencers.propTypes = {
    actions: PropTypes.shape({
        beforeAddToCampaign: PropTypes.func,
        clearAll: PropTypes.func,
        finishInvite: PropTypes.func,
        listSplashFilterChange: PropTypes.func,
        pageChange: PropTypes.func,
        pageLimitChange: PropTypes.func,
        searchFilterChange: PropTypes.func,
        selectAll: PropTypes.func,
        selectionChange: PropTypes.func,
        setApiError: PropTypes.func,
        setData: PropTypes.func,
        setGeneralData: PropTypes.func,
        setShowMissingChannelsError: PropTypes.func,
        sortFilterChange: PropTypes.func,
        orderTypeChange: PropTypes.func,
        invitePageChange: PropTypes.func,
        clearCurrentPage: PropTypes.func,
    }),
    alreadyInvitedUsers: PropTypes.array,
    apiError: PropTypes.object,
    appropriateInviteList: PropTypes.arrayOf(
        PropTypes.shape({
            fields: PropTypes.string,
            limit: PropTypes.number,
            page: PropTypes.number,
            sort: PropTypes.string,
            sortOrder: PropTypes.string,
            filters: PropTypes.arrayOf(PropTypes.string),
        }),
    ),
    assignmentId: PropTypes.number,
    assignmentName: PropTypes.string,
    campaignId: PropTypes.number,
    currentPage: PropTypes.number,
    favorites: PropTypes.arrayOf(PropTypes.object),
    favoriteChangeCount: PropTypes.number,
    influencers: PropTypes.array,
    inviteInProgress: PropTypes.bool,
    inviteList: PropTypes.arrayOf(
        PropTypes.shape({
            fields: PropTypes.string,
            limit: PropTypes.number,
            page: PropTypes.number,
            sort: PropTypes.string,
            sortOrder: PropTypes.string,
            filters: PropTypes.arrayOf(PropTypes.string),
        }),
    ),
    missingChannelsErrorMessage: PropTypes.string,
    pageLimit: PropTypes.number,
    pageLimitOptions: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.number,
            label: PropTypes.string,
        }),
    ),
    permissions: PropTypes.arrayOf(PropTypes.string),
    profile: PropTypes.shape({
        id: PropTypes.number,
        uid: PropTypes.string,
        email: PropTypes.string,
        firstname: PropTypes.string,
        lastname: PropTypes.string,
        pictureUrl: PropTypes.string,
        authorities: PropTypes.array,
        roles: PropTypes.array,
        type: PropTypes.string,
    }),
    showDiscovery: PropTypes.bool,
    showInviteSuccess: PropTypes.bool,
    showMissingChannelsError: PropTypes.bool,
    showMissingChannelsModal: PropTypes.bool,
    showNoResults: PropTypes.bool,
    showPleaseWait: PropTypes.bool,
    showPleaseWaitWhite: PropTypes.bool,
    sort: PropTypes.string,
    sortOrder: PropTypes.string,
    sortOptions: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string,
        }),
    ),
    totalCount: PropTypes.number,
    disableMultipleInvite: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(Influencers);
