import moment from "moment";
export default class Format {

    static influencerPhotoSrc = (src) => {
        if (!src) {
            return '/images/ic-avatar-blank-d.svg'
        }

        // return `${src}?${Math.round(Date.now()/(60*1000))}`;
        return src;

    }

    // express the number plain or as K or as M, depending on
    // how big it is;
    static expressInK(n, decimals) {
        if (isNaN(n)) {
            return null;
        }
        if (n === 0) {
            return '0';
        }

        if (isNaN(decimals)) {
            decimals = 1;
        }

        const mega = 1000000;
        const kilo = 1000;
        let postfix = '';

        let factor = 1;
        for (let i = 0; i < decimals; i++) {
            factor *= 10;
        }
        let chopped = `${Math.round(n * factor)}`;

        const divideFactor = 1;
        if (n >= mega) {
            chopped = `${Math.round((n * factor) / mega)}`;
            postfix = 'M';
        } else if (n >= kilo) {
            chopped = `${Math.round((n * factor) / kilo)}`;
            postfix = 'K';
        }
        let retval = chopped;
        if (decimals > 0) {
            const l = retval.length;
            retval = `${retval.substr(0, l - decimals)}.${retval.substr(l - decimals)}`;
            while (retval.substr(retval.length - 1, 1) === '0') {
                retval = retval.substr(0, retval.length - 1);
            }
            if (retval.substr(retval.length - 1, 1) === '.') {
                retval = retval.substr(0, retval.length - 1);
            }
        }

        return retval + postfix;
    }

    static formatNumber(n) {
        if (isNaN(n)) {
            return null;
        }

        return n.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    static expressInDollars(n, symbol) {
        const sign = symbol || "$"
        if (isNaN(n)) {
            return null;
        }
        return `${sign}${n}`;
    }

    static fileSize(n) {
        if (isNaN(n)) {
            return null;
        }
        if (n === 0) {
            return '0';
        }
        if (n > 1000000) {

            return `${Math.floor(n / 1000000)} mb`;
        }
        if (n > 1000) {
            return `${Math.floor(n / 1000)} kb`;
        }

        return `${n} b`;


    }

    static capitalize(txt) {
        const capitalizedString = '';
        const lower = txt.toLowerCase();
        const exceptions = ['the', 'and', 'or', 'a', 'an', 'in', 'of']
        return lower.split(/\s+/).map((w, index) => {
            if (w === 'diy') {
                return 'DIY'
            }
            if (index === 0 || exceptions.indexOf(w) < 0) {
                return w[0].toUpperCase() + w.slice(1)
            }
            return w;


        }).join(' ');
    }


    static validateEmail(txt) {
        if (txt.length === 0) {
            return true;
        }
        const pattern = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
        return pattern.test(txt);
    }

    // returns error message or null
    static validateInfluencerPassword(txt) {
        if (txt.length === 0) {
            return 'Password is empty'
        }
        if (txt.length < 8) {
            return 'Password must be at least 8 characters'
        }
        const uppercasePattern = /[A-Z]+/;
        if (!uppercasePattern.test(txt)) {
            return 'Password must contain at least 1 uppercase letter'
        }
        const numberPattern = /[0-9]+/;
        if (!numberPattern.test(txt)) {
            return 'Password must contain at least 1 number'
        }
        return null;
    }

    static validateFirstName(txt) {
        if (txt.length === 0) {
            return false;
        }
        const pattern = /^[^0-9\s]{2,}/
        return pattern.test(txt);
    }

    static validateUrl(txt) {
        const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port
            '(\\/[-a-z\\d%@_.~+&:]*)*' + // path
            '(\\?[;&amp;a-z\\d%@_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i');
        return pattern.test(txt);
    }

    static webAddress(url) {
        let retval = url || '';
        if (retval.length > 0 && retval.indexOf('//') < 0) {
            retval = `http://${retval}`;
        }
        return retval;
    }

    static mimeToExt(mimeType) {
        switch (mimeType) {
            case 'video/x-msvideo':
                return '.avi';
            case 'video/x-ms-wmv':
                return '.wmv';
            case 'video/mpeg':
                return '.mpeg';
            case 'video/ogg':
                return '.ogv';
            case 'video/mp4':
                return '.mp4';
            case 'video/x-m4v':
                return '.m4v';
            case 'video/x-flv':
                return '.flv';
            case 'video/x-f4v':
                return '.f4v';
            case 'video/quicktime':
                return '.mov';
            case 'video/3gpp':
                return '.3gp';
            case 'video':
                return '.mp4';
            case 'image/bmp':
                return '.bmp';
            case 'image/gif':
                return '.gif';
            case 'image/jpeg':
                return '.jpeg';
            case 'image/png':
                return '.png';
            case 'image/svg+xml':
                return '.svg';
            case 'image/tiff':
                return '.tiff';
            case 'image/vnd.adobe.photoshop':
                return '.psd';
            case 'image':
                return '.jpeg';
            default:
                return '.txt';
        }
    }

    static highlightKeywords(content, keywords) {
        if (!keywords || !keywords.length > 0)
            return content;
        const allWords = new RegExp(keywords.join("|"), 'gi');
        return content.replace(allWords, '<mark>$&</mark>');

    }

    static numberWithCommas(num) {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    static percentage(num, fixed = 2) {
        let temp = num;
        if (typeof num === 'string') {
            try {
                temp = Number(num);
            } catch (err) {
                return '';
            }
        }
        if (isNaN(temp)) {
            return '';
        }
        return temp >= 100 ? '100%' : (`${temp.toFixed(fixed)}%`);
    }

    static percentage2(num, fixed = 2) {
        let temp = num;
        if (typeof num === 'string') {
            try {
                temp = Number(num);
            } catch (err) {
                return '';
            }
        }
        if (isNaN(temp)) {
            return '';
        }
        return (`${temp.toFixed(fixed)}%`);
    }

    static formatDate = (value) => {
        try {
            if (moment(value).isSame(moment(), 'year')) {
                return moment(value).format('MMM D');
            }
            return moment(value).format('MMM D, YYYY');
        } catch (err) {
            return value;
        }
    }

    static formatDateAlwaysYear = (value) => {
        try {
            return moment(value).format('MMM D, YYYY');
        } catch (err) {
            return value;
        }
    }

    static formatDateFrom = (value) => {
        try {
            const currentTime = moment();
            const dateTime = moment.utc(value)
            const difference = currentTime.diff(dateTime, 'seconds');
            if (difference < 60) {
                return difference + 's'
            } else if (difference < 3600) {
                return Math.floor(difference/60) + 'm'
            } else if (difference < 86400) {
                return Math.floor(difference/3600) + 'h'
            } else {
                if (moment(value).isSame(moment(), 'year')) {
                    return moment(value).format('MMM D');
                }
                return moment(value).format('MMM D, YYYY');
            }
        } catch (err) {
            return value;
        }
    }
}
