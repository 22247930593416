import React from 'react'

export default class Summary extends React.Component {
    constructor( props,context) {
        super(props.context);
    }
    render() {
        if (!this.props.profile) {
            return null;
        }
        return (
            <div className="profileSummary">
                {this.props.profile.summary?this.props.profile.summary:'No Summary.'}
            </div>
        )
    }
}
