import React from 'react'
import './audience2.scss'
import '../common.scss'
import PercentBar from './PercentBar'
import Lookup from '../../modules/Lookup'
import Format from '../../modules/utils/Format'

// props
//  - audience
//  - narrow flag ( showing on the Profile page where there's less space

export default class Audience extends React.Component {

    constructor (props, context) {
        super(props, context);

        this.renderedAudienceRows = this.renderedAudienceRows.bind(this);
        this.renderedColumn = this.renderedColumn.bind(this);

        this.audienceTitles = {
            gender : 'Gender',
            //income : 'Income',
            age : 'Age',
            children : 'Children',
            education: 'Education',
            ethnicity : 'Ethnicity'
        }
    }

    renderedColumn(pieces, ix) {
        let rows = [];
        for (let piece of pieces) {
            if (rows.length > 0) {
                rows.push(
                    <tr key={piece+'separator1'}>
                        <td colSpan="3" className="iSeparator">
                            <div style={{height:1,backgroundColor:'#c2c2c2'}}></div>
                        </td>
                    </tr>
                );
            }
            rows = rows.concat(this.renderedAudienceRows(piece))
        }
        rows.push(
            <tr key={'separator2' + ix}>
                <td colSpan="3" className="iSeparator">
                </td>
            </tr>
        );
        return (
            <div className="columnHolder" key={'column' + ix}>
                <table><tbody className="audienceListing">
                    {rows}
                </tbody></table>
            </div>
        )
    }

    renderedAudienceRows(key) {
        let audienceList = this.props.audience[key];

        let rows = [];

        rows.push(
            <tr key={key}>
                <td colSpan="3" className="title">
                    { this.audienceTitles[key]}
                </td>
            </tr>
        );

        for (let i=0; i<audienceList.length; i++) {
            let ins = audienceList[i];
            let rowKey = key + (i+1);
            let optionLabel = '';
            for (let opt of this.props.options[key]) {
                if (opt.id === ins.optionId) {
                    optionLabel = opt.value;
                }
            }

            rows.push(
                <tr key={rowKey}>
                    <td className="label" style={{paddingRight:20}}>
                        { optionLabel }
                    </td>
                    <td style={{paddingRight:20}}>
                        {ins.percent}%
                    </td>
                    <td>
                        <PercentBar percent={ins.percent} size={24}/>
                    </td>
                </tr>
            )
        }

        return rows;
    }

    renderedMenuPoint(txt, key) {
        let className = '';
        if (key === this.state.currentMenuPoint) {
            className = "current";
        }
        return (
            <div className={className} key={key}>
                { txt }
            </div>
        )
    }

    render () {
        if (typeof this.props.audience !== 'object') {
            return null;
        }
        let columns = [];
        if (this.props.narrow) {
            columns.push(this.renderedColumn(['gender', 'age'],1));
            columns.push(this.renderedColumn(['children','education', 'ethnicity'],2));
        } else {
            columns.push(this.renderedColumn(['gender'],1));
            columns.push(this.renderedColumn(['age','children'],2));
            columns.push(this.renderedColumn(['education', 'ethnicity'],3));
        }

        return (
            <div className="audience">
                <div className="demo">Demographics</div>
                <div className="columns">
                    { columns }
                </div>
            </div>
        );
    }
}
