import React, {Component} from 'react';
import './RichContentEditor.scss';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Api from "../../../modules/Api"
import PleaseWaitPartial from "../pleaseWait/PleaseWaitPartial";
import ErrorMessageBoxIntl from '../errorMessageBox/ErrorMessageBoxIntl'
import ApiError from "../apiError/ApiError"

export default class RichContentEditor extends Component {

    constructor(props) {
        super(props);
        const value = (typeof props.content !== "undefined") ? props.content : '';
        const modules = {
            toolbar:{
                handlers: {
                    image: this.imageHandler.bind(this)
                },
                container: [
                    [{ 'font': [] }],
                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                    ['bold', 'italic', 'underline','strike'],
                    [{ 'script': 'sub'}, { 'script': 'super' }],
                    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                    [{ 'align': [] }],
                    ['link', 'image'],
                    // ['link'],
                    [{ 'color': [] }, { 'background': [] }],
                    ['blockquote', 'code-block']
                ]
            },
            clipboard: {
                matchVisual: false
            }
        };
        this.state = {
            text: value,
            modules,
            waiting:false,
            showError: false,
            errorMessage : false
        }; // You can also pass a Quill Delta here
        this.handleChange = this.handleChange.bind(this);
    }
    //
    // componentDidUpdate(prevProps) {
    //     console.log(this.props.content)
    //   if (this.props.content !== prevProps.content) {
    //     let value = (typeof this.props.content !== "undefined") ? this.props.content : '';
    //     this.setState({ text: value });
    //   }
    // }

    handleChange(value) {
        if (typeof this.props.onChange === "function") {
            this.props.onChange(value);
        }
    }

    showApiError(error) {
        this.setState({
            waiting: false,
            showApiError: true,
            apiError : error
        })
    }

  imageHandler = () => {
      const acceptedExtensions = ['jpg', 'jpeg', 'png', 'svg'];
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.click()
      input.onchange =  () => {
          const file = input.files[0];
          const ext = file.name.match(/\.([^\.]+)$/)[1];
          let wrongType = true;
          let acceptedDisplayed = '';
          for (const accepted of acceptedExtensions) {
              if (acceptedDisplayed.length > 0) {
                  acceptedDisplayed += ', ';
              }
              acceptedDisplayed += `.${  accepted}`;
              if (accepted == ext.toLowerCase()) {
                  wrongType = false;
              }
          }
          if (wrongType) {
              this.setState({
                  showError: true,
                  errorMessage: {
                      messageId: 'fileUpload.fileTypeErrorMessage',
                      messageValues: {
                          allowedExtensions: acceptedDisplayed
                      }
                  }
              });
              return;
          }
          if (file.size > 5250000) {
              this.setState({
                  showError: true,
                  errorMessage: {
                      messageId: 'fileUpload.fileSizeErrorMessage',
                      messageValues: {
                          maxFileSize: '5 mb'
                      }
                  }
              })
              return;
          }
          this.setState({waiting: true})
          Api.uploadResource(file, true).then(
              (res) => {
                  const range = this.quills.editor.getSelection();
                  this.quills.editor.insertEmbed(range.index, 'image', res.url);
                  this.setState({waiting: false})
              },
              (err) => {
                  console.log(err)
                  this.showApiError(err);
              },
          );
      }
  }

  getContent() {
      return this.state.text;
  }

  render() {
      return (
          <div id="summaryEditorContainer">
              <ApiError show={this.state.showApiError} error={this.state.apiError}
                  cancelFunction={()=>this.setState({waiting:false,showApiError:false })}/>
              <ErrorMessageBoxIntl
                  messageId = {this.state.errorMessage.messageId}
                  messageValues = { this.state.errorMessage.messageValues}
                  show = { this.state.showError }
                  closeFunction = {() => this.setState({showError : false})}
              />
              <PleaseWaitPartial show={this.state.waiting} container = { document.getElementById('summaryEditorContainer')}/>
              <ReactQuill
                  ref={(e)=>this.quills=e}
                  className="quillEditor"
                  theme="snow"
                  readOnly = {this.props.readOnly}
                  value={this.props.content || ''}
                  modules={this.state.modules}
                  onChange={this.handleChange}
                  placeholder={this.props.placeholder}
              />
          </div>
      );
  }

}
