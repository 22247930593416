import React from 'react';
import cx from 'classnames';
import {Redirect} from "react-router-dom";
import AnalyticsByPost from '../campaign/analytics/AnalyticsByPost';
import TopNavigation from '../shared/topNavigation/TopNavigation';
import Api from '../../modules/Api';
import ProfileHelper from '../../modules/utils/ProfileHelper';
import { TALENT_MANAGER_STR, INFLUENCER_STR } from '../../constants/authorities';

export default class influencerAnalytics extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            dataByPost: {},
            profile: null,
        };
    }

    componentDidMount() {
        Api.getInfluencerAnalytic()
            .then((res) => {
                this.setState({ dataByPost: res });
            })
            .catch((err) => {
                console.log(err);
            });
        ProfileHelper.getCurrentLoginUserProfile()
            .then(profile => this.setState({profile}))
    }

    render() {
        const { dataByPost, profile } = this.state;
        const onDevOrStg =
            window.location &&
            (window.location.origin.indexOf('http://localhost:3000') > -1 ||
                window.location.origin.indexOf('https://dev-infmkt.stgtchinfra.com') > -1 ||
                window.location.origin.indexOf('https://stg-infmkt.stgtchinfra.com') > -1 ||
                window.location.origin.indexOf('https://koalifyed-development-ui.stgtchinfra.com/') > -1);
        const addExtraTop = profile && (profile.role === TALENT_MANAGER_STR || profile.role === INFLUENCER_STR) && !profile.accountVerified;
        if (!onDevOrStg){
            return <Redirect to={{ pathname: '/profile' }} />
        }
        return (
            <div>
                <TopNavigation current="postAnalytics" />
                <div className="opportunity">
                    <div className={cx("opportunityHeader", {extraHeader: addExtraTop})}>
                        <div className="contentDiv">
                            <div className="listedPanelTitle">Post Analytics</div>
                        </div>
                    </div>
                    <div className={cx("opportunityContainer extra", {moreExtra: addExtraTop})}>
                        <div className="contentDiv">
                            <div className="analyticsPage">
                                <AnalyticsByPost dataByPost={dataByPost} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
