import React from 'react'
import PulseLoader from '../pulseLoader/PulseLoader';
import './pleaseWait.scss'

export default class PleaseWait extends React.Component {

    constructor (props, context) {
        super(props, context);
    }

    render() {
        let style={};
        if (!this.props.show) {
            style.display = 'none'
        }
        if (this.props.transparent) {
            style.backgroundColor = "transparent"
        }
        if (this.props.top) {
            style.top = this.props.top
        }
        return (
            <div className="pleaseWait" style={style}>
                <PulseLoader color="#4AA129" size="16px" margin="4px"/>
            </div>
        );
    }
}
