import React, {Component} from 'react';
import PulseLoader from '../../shared/pulseLoader/PulseLoader';
import {connect} from 'react-redux';
import {
    saveFavorites,
    saveSeveralFavorites,
    createFavorites,
    deleteFavorites,
    allFavoritesName

} from '../../../store/actions/favoriteActions';
import ConfirmModal from '../../shared/confirmModal/ConfirmModal'
import CreateOrEditFavoriteList from './CreateOrEditFavoriteList'

// import './favoriteLists.scss'

/*
 Props
 - show
 - userDetails  ( influencer profile object )
 - closeFunction
 - isOnList - flag, there's at least one list containing the influencer
 */

class FavoriteLists extends Component {
    constructor(props) {
        super(props);

        this.state = {
            creationInProgress : false,
            showFilter : true
        };
    }

    flipCreation = (e) => {
        this.setState({
            creationInProgress : !this.state.creationInProgress
        })
    }

    finishCreation = () => {
        this.setState({
            creationInProgress : false
        })
    }

    renderedSpinner = () => {
        if (!this.props.loading || !this.containerElement) {
            return null;
        }
        let loadStyle = {};
        loadStyle.width = this.containerElement.clientWidth;
        loadStyle.height = this.containerElement.clientHeight;
        loadStyle.paddingTop = this.containerElement.clientHeight/4;
        loadStyle.paddingLeft = 100;
        return (
            <div className="loading">
                <div style={loadStyle}>
                    <PulseLoader color="#4AA129" size="16px" margin="4px"/>
                </div>
            </div>
        )
    }

    removeFromList = (list) => {
        let newUsers = [];
        for (let user of list.users) {
            if ( user !== this.props.userDetails.id) {
                newUsers.push(user);
            }
        }
        this.props.saveFavorites(list.id, {...list, users: newUsers})
    }

    addToList = (list) => {
        let newUsers = list.users;
        newUsers.push(this.props.userDetails.id);
        this.props.saveFavorites(list.id, {...list, users: newUsers})
    }

    renderedList = (list) => {
        if (list.combined) {
            return null;  // All Favorites gets added separately
        }

        if (typeof this.state.filter === 'string' && this.state.filter.length > 0) {
            let filter = this.state.filter.toLowerCase();
            if ( list.name.toLowerCase().indexOf(filter) < 0) {
                return null;
            }
        }

        let selected = list.users.indexOf(this.props.userDetails.id) >= 0;

        let className = "favoriteList";
        if (selected) {
            className += " selected";
        }

        let handleClick = () => {
            if (selected) {
                this.removeFromList(list);
            } else {
                this.addToList(list);
            }
        }

        let handleDelete = (e) => {
            e.stopPropagation();
            this.setState({
                deleteClicked : list
            })
        }

        let deleteList = () => {
            this.props.deleteFavorites(this.state.deleteClicked.id);
            this.setState({
                deleteClicked : null
            })
        }

        let deleteListConfirmMessage = this.state.deleteClicked ?
            'Delete List "' + this.state.deleteClicked.name + '" ?' : ''

        let handleEdit = (e) => {
            e.stopPropagation();
            let newState = {};
            newState['edited' + list.id] = true;
            this.setState(newState);
        }

        return (<div className={className} key={list.id} onClick={handleClick}>
            <ConfirmModal
                show = {this.state.deleteClicked}
                proceedFunction = {deleteList}
                cancelFunction = {() => { this.setState({
                    deleteClicked : null
                })}}
                message = {deleteListConfirmMessage}
            />
            <div className="favList">
                <div className="check">
                    <img src="/images/ic-checkgreen.svg"  alt="" />
                </div>
                <div>
                    {list.name}
                </div>
                <div className="icon" onClick={handleEdit}>
                    <img src="/images/ic-edit-c6.svg"/>
                </div>
                <div className="icon" onClick={handleDelete}>
                    <img src="/images/ic-trash.svg"/>
                </div>
            </div>
            <CreateOrEditFavoriteList
                show={this.state['edited'+list.id]}
                editedList={list}
                closeFunction={() =>{
                    let newState = {};
                    newState['edited' + list.id] = false;
                    this.setState(newState);
                }}
            />
        </div>)
    }

    removeFromAllLists = () => {
        let favoriteLists = [];
        for (let list of this.props.favoriteLists) {
            if (list.combined) {
                continue;
            }
            let newUserList = list.users.filter( (user) => {
                return user !== this.props.userDetails.id
            });
            if (newUserList.length < list.users.length) {
                list.users = newUserList;
                favoriteLists.push(list);
            }
        }
        this.setState({ closeWhenDone : true}, () => {
            this.props.saveSeveralFavorites(favoriteLists);
        })
    }


    renderAllFavorites = () => {
        let handleClick = () => {
            this.removeFromAllLists();
        }
        return (<div className="favoriteList selected"  onClick={handleClick}>
            <ConfirmModal
                show = {this.state.deleteClicked}
                proceedFunction = {this.removeFromAllLists}
                cancelFunction = {() => { this.setState({
                    deleteClicked : null
                })}}
                message = "Remove from All Lists?"
            />
            <div className="favList">
                <div className="check">
                    <img src="/images/ic-checkgreen.svg"  alt="" />
                </div>
                <div>
                    {allFavoritesName}
                </div>
            </div>
        </div>)
    }

    filterLists = (e) => {
        let filterValue = e.target.value;
        this.setState({
            filter : filterValue
        })
    }

    render() {
        if (!this.props.show || !Array.isArray(this.props.favoriteLists)) {
            return null;
        }
        let lists = [];
        for ( let list of this.props.favoriteLists) {
            lists.push( this.renderedList(list));
        }

        return (
            <div
                className="favoriteLists"
                onClick={ (e) => {e.stopPropagation()}}
                ref = {(e) => {this.containerElement = e}}
            >
                { this.renderedSpinner() }

                <div className="title">
                    Add to Favorites + Lists
                </div>

                <div className="divider"/>

                { this.state.showFilter &&
                    <div className="searchBoxContainer">
                        <img src="/images/ic-mag.svg" onClick={this.focusInput}/>
                        <input
                            onChange={this.filterLists}
                            placeholder="Filter List"
                            type="text"
                            id="search"
                            ref={(input) => { this.searchInput = input; }}
                        />
                    </div>
                }

                { Array.isArray(this.props.favoriteLists) && (this.props.favoriteLists.length < 50) &&
                    <div className="createNew" onClick={this.flipCreation}>
                        + Create New List
                    </div>
                }
                <CreateOrEditFavoriteList
                    show={this.state.creationInProgress}
                    closeFunction={this.finishCreation}
                    userId={this.props.userDetails.id}
                />

                <div className="listlist">
                    { /*this.props.isOnList && this.renderAllFavorites()*/ }
                    {lists}
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        favoriteLists : state.FAVORITE_REDUCER.favoriteData,
        loading: state.FAVORITE_REDUCER.favoriteLoading,
    }
}

function mapDispatchToProps(dispatch){
    return {
        saveFavorites: (favId, fav) => dispatch(saveFavorites(favId, fav)),
        saveSeveralFavorites : (favs) => dispatch(saveSeveralFavorites(favs)),
        createFavorites: fav => dispatch(createFavorites(fav)),
        deleteFavorites: id => dispatch(deleteFavorites(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteLists);