import React, { useState } from 'react';
import './sortableTable.scss';
import cx from 'classnames';
import ListCard from './ListCard';


const SortableTable = ({
    columns = [],
    orders = {},
    sortBy = 'name',
    listData = [],
    actions,
    disable,
    onChange,
    onActions,
    onCardClick,
    countryList
}) => {
    return (
        <div className='sortableTableContainer'>
            <div className='sortableTableHeaderContainer'>
                {columns.map((col, index) => (
                    <div
                        className={cx('headerTitle', {sortable: orders[col.key]})}
                        style={{ width: col.width }}
                        key={`${col.key} ${index}`}
                        onClick={() => orders[col.key] ? onChange(col.key) : null}
                        role='button'
                    >
                        <span className={cx({ no: sortBy !== col.key })}>{col.label}</span>
                        {orders[col.key] && <img
                            src="/images/ic_down14x14.svg"
                            className={cx({ up: orders[col.key] === 'DESC', no: sortBy !== col.key })}
                            alt={`sort-${col.label}`}
                        />}
                    </div>
                ))}
            </div>
            <div className='sortableTableListContainer'>
                {listData.map((data, index) => {
                    return (
                        <ListCard
                            columns={columns}
                            onActions={(action) => onActions(action, data)}
                            uniqueKey={`${index}`}
                            data={data}
                            disable={disable}
                            actions={actions}
                            key={`list-${index}`}
                            onClick={() => onCardClick(data)}
                            countryList={countryList}
                        />
                    )
                })}
            </div>
        </div>
    )
}

export default SortableTable;
