import React from 'react'
import './style.scss'
import get from 'lodash/get';
import cx from "classnames";
import Select from 'react-select';
import uniqueId from 'lodash/uniqueId';
import BrandRow from './brandRow';
import PaginationNew from '../../../shared/pagination/PaginationNew';
import PleaseWait from '../../../shared/pleaseWait/PleaseWaitWhite';
import ApiError from '../../../shared/apiError/ApiError';
import { BRAND_OWNER, BRAND_OWNER_STR, SUPER_ADMIN, SUPER_ADMIN_STR } from '../../../../constants/authorities';
import Api from '../../../../modules/Api';

import constants from '../constants';
import utils from '../utils';

const pageLimitOptions = [
    { value:'10', label: '10 per page'},
    { value:'20', label: '20 per page'},
    { value:'50', label: '50 per page'}
];

export default class BrandsList extends React.Component {
    state = {
        currentPage : 1,
        pageSize : 10,
        searchKey: '',
        sortBy: 'brands',
        brandsSort: '',
        campaignsSort: '',
        ownersSort: '',
        advertiserSort: '',
        dateSort: '',
        searchValue: '',
        active: true,
        waitingForBrands: false,
        currencyList:[],
        countryList: []
    };

    componentDidMount() {
        this.setState({
            currentPage : 1,
            pageSize : 10,
            searchKey: '',
            sortBy: 'brands',
            brandsSortDirection: 'asc',
        });
        Api.getCurrencyList().then(result => {
            const { data } = result;
            const currencyList = []
            data.forEach(x => {
                const currency = {label: `${x.code} (${x.symbol})`, value: x.code, symbol: x.symbol}
                currencyList.push(currency)
            })
            this.setState({currencyList})
        })
        Api.getBrandCountry().then(result => {
            const { data } = result;
            const countryList = [];
            data.forEach(x => {
                const country = {label: x.name, value: x.code, currencyCode: x.currencyCode}
                countryList.push(country)
            })
            this.setState({countryList})
        })
    }

    createParamsForApi = (fields) => {
        const retval = {
            page : fields.currentPage,
            num : fields.pageSize,
            type : fields.currentOrPast,
            advertiser : fields.advertiser,
            sortby : fields.sortBy
        };
        if ( typeof (fields.searchKey) === 'string' && (fields.searchKey).length > 0) {
            retval.key = fields.searchKey;
        }
        const directionKey = `${fields.sortBy  }SortDirection`;
        if (fields[directionKey] === 'desc') {
            retval.desc = 'desc';
        }
        return retval;
    };

    setSortBy = (sortField) => {
        const { onSortBy } = this.props;

        onSortBy(sortField);
    };

    handlePageChange = (newCurrentPage) => {
        this.setState({
            currentPage : newCurrentPage
        })
    };

    onHandlePageLimit = (limit) => {
        const { onChangeNumPerPage } = this.props;
        this.setState({
            pageSize: limit,
            currentPage : 1
        });
        const matchValue = pageLimitOptions.find(item => item.value === limit.toString());
        if (matchValue) {
            onChangeNumPerPage(matchValue);
        }
    };

    render () {
        const { advertiserOptionsFactory } = utils;
        const {
            searchValue,
            countryList,
            currencyList
        } = this.state;
        const {
            handleSetBrandToEdit,
            onChangePage,
            advertiserFilterValue,
            onFilterByAdvertiser,
            brandsList,
            pagination,
            isLoading,
            userAdvertisers,
            onChangeBrandsStatus,
            brandsStatusValue,
            onArchiveBrand,
            onUnarchiveBrand,
            role,
            sortBy,
            onFilterByBrand,
            handleAddBrandCountry
        } = this.props;
        const campaignsColor = {};
        const ownersColor = {};
        const advertiserColor = {};
        const brandsColor = {};
        const dateColor = {};
        const emailColor = {}
        const filteredBrands = brandsList.filter((el) => {
            const str = `${el.brand} ${el.owner} ${el.advertiser} ${el.date}`;
            if(str.toLowerCase().includes(searchValue)) return el
        });
        const genericOption = {
            label: 'All advertisers',
            value: '',
        };
        const advertiserOptions = [genericOption, ...advertiserOptionsFactory(userAdvertisers)];
        return (
            <div>
                <div className="blue-header admin-header">
                    <div className="contentDiv">
                        <div className="controlContainer">
                            <div className="left-container">
                                <h1>Brands</h1>
                            </div>
                            
                            <div className="right-container" style={{display: 'flex'}}>
                                <div className="listSearch">
                                    <img src="/images/ic-mag.svg" />
                                    <input
                                        placeholder="Search Brands"
                                        onChange={(e) => onFilterByBrand(e.target.value)}
                                    />
                                </div>
                                <Select
                                    className="select"
                                    searchable
                                    placeholder="Filter..."
                                    value={advertiserFilterValue}
                                    single
                                    options={advertiserOptions}
                                    onChange={onFilterByAdvertiser}
                                />
                                { role &&  role === SUPER_ADMIN_STR && (
                                    <button
                                        onClick={() => this.props.handleOpenModal(true)}
                                        className="invite-button"
                                    >
                                        <img src="/images/ic-plus-circle-w.svg"/>
                                        New Brand
                                    </button>
                                )}
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className="all-users-page brands-list">
                    <PleaseWait
                        show={this.state.waitingForBrands}
                        top={285}
                    />
                    <ApiError show={this.state.showApiError} error={this.state.apiError} cancelFunction={()=>this.setState({waitingForBrands:false,showApiError:false })} />
                    <div className="filter-subheader">
                        <div className="contentDiv">
                            <div className="header">
                                <div className="left-block">
                                    <div
                                        onClick={onChangeBrandsStatus('active')}
                                        className={`tab ${brandsStatusValue === 'active' && 'active'}`}
                                    >
                                        Active
                                    </div>
                                    <div
                                        onClick={onChangeBrandsStatus('archived')}
                                        className={`tab ${brandsStatusValue === 'archived' && 'active'}`}
                                    >
                                        Archive
                                    </div>
                                </div>
                                {/* <div className="right-block">
                                    
                                    <div className="limitOption">
                                        <Select
                                            className="select"
                                            clearable={false}
                                            searchable={false}
                                            value={this.state.pageSize}
                                            single
                                            placeholder="Page size"
                                            options={pageLimitOptions}
                                            onChange={this.onHandlePageLimit}
                                        />
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        
                    </div>
                    <div className="contentDiv brandDiv" >
                        <table className="data-table-brand"
                            ref={(e) => {
                                this.table = e;
                            }}
                        >
                            <tbody>
                                <tr className="table-header subHeader">
                                    <th onClick={() => this.setSortBy('name')} className="user-info-holder">
                                        <div style={brandsColor}>
                                            Brand
                                            <img src="/images/ic_down14x14.svg" 
                                                className={cx({ 'up': get(sortBy, 'name') === false },
                                                    { 'no': get(sortBy, 'name') === undefined })} 
                                            />
                                        </div>
                                    </th>
                                    <th onClick={() => this.setSortBy('countryCode')} className="brands">
                                        <div style={advertiserColor}>
                                            Country
                                            <img src="/images/ic_down14x14.svg" 
                                                className={cx({ 'up': get(sortBy, 'countryCode') === false },
                                                    { 'no': get(sortBy, 'countryCode') === undefined })}
                                            />
                                        </div>
                                    </th>
                                    <th onClick={() => this.setSortBy('defaultCurrencyCode')} className="brands">
                                        <div style={advertiserColor}>
                                            Currency
                                            <img src="/images/ic_down14x14.svg" 
                                                className={cx({ 'up': get(sortBy, 'defaultCurrencyCode') === false },
                                                    { 'no': get(sortBy, 'defaultCurrencyCode') === undefined })}
                                            />
                                        </div>
                                    </th>
                                    <th onClick={() => this.setSortBy('advertiser')} className="brands">
                                        <div style={advertiserColor}>
                                            Advertiser
                                            <img src="/images/ic_down14x14.svg" 
                                                className={cx({ 'up': get(sortBy, 'advertiser') === false },
                                                    { 'no': get(sortBy, 'advertiser') === undefined })}
                                            />
                                        </div>
                                    </th>
                                    <th onClick={() => this.setSortBy('campaigns')} className="campaign">
                                        <div style={campaignsColor}>
                                            Campaigns
                                            <img src="/images/ic_down14x14.svg" 
                                                className={cx({ 'up': get(sortBy, 'campaigns') === false },
                                                    { 'no': get(sortBy, 'campaigns') === undefined })}
                                            />
                                        </div>
                                    </th>
                                    <th onClick={() => this.setSortBy('billingContract')} className="email">
                                        <div style={emailColor}>
                                            Contact
                                            <img src="/images/ic_down14x14.svg" 
                                                className={cx(
                                                    {'up': get(sortBy, 'billingContract') === false },
                                                    { 'no': get(sortBy, 'billingContract') === undefined })}
                                            />
                                        </div>
                                    </th>
                                    <th onClick={() => this.setSortBy('createdBy')} className="role">
                                        <div style={ownersColor}>
                                            Created By
                                            <img src="/images/ic_down14x14.svg" 
                                                className={cx({ 'up': get(sortBy, 'createdBy') === false },
                                                    { 'no': get(sortBy, 'createdBy') === undefined })}
                                            />
                                        </div>
                                    </th>
                                    <th onClick={() => this.setSortBy('createdDate')} className="date">
                                        <div style={dateColor}>
                                            Date Created
                                            <img src="/images/ic_down14x14.svg" 
                                                className={cx({ 'up': get(sortBy, 'createdDate') === false },
                                                    { 'no': get(sortBy, 'createdDate') === undefined })}
                                            />
                                        </div>
                                    </th>
                                    <th className="actions"/>
                                </tr>
                                {
                                    filteredBrands.length ?
                                        filteredBrands.map((brand, i) => (
                                            <tr key={uniqueId()} className="table-row">
                                                <BrandRow
                                                    elemKey={i}
                                                    brand={brand}
                                                    handleSetBrandToEdit={handleSetBrandToEdit}
                                                    handleAddBrandCountry={handleAddBrandCountry}
                                                    onArchiveBrand={onArchiveBrand}
                                                    onUnarchiveBrand={onUnarchiveBrand}
                                                    role={role}
                                                    brandsStatusValue={brandsStatusValue}
                                                    countryList={countryList}
                                                    currencyList={currencyList}
                                                />
                                            </tr>
                                        ))
                                        : null
                                }

                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="contentDiv brandDiv">
                    <PaginationNew
                        total={get(pagination, 'total') || 0}
                        limit={this.state.pageSize}
                        current={get(pagination, 'currentPage')}
                        handlePageChange={onChangePage}
                        limitChangeFunction={this.onHandlePageLimit}
                    />
                </div>
                {isLoading && <PleaseWait show />}
            </div>
        )
    }
}
