import React from "react";
import { withRouter } from "react-router";
import {selectSingleAssignment} from "../../../store/campaign/actions/campaignActions"
import {connect} from 'react-redux';
import Lookup from '../../../modules/Lookup'
import "./campaignDetails.scss";
import { Motion, spring } from "react-motion";
import cx from 'classnames'
import { Scrollbars } from 'react-custom-scrollbars';

// props
// - assignments
// - editFunction

class Assignments extends React.Component {

    renderedAssignment = (a) => {
        const {supplementalAssignments} = this.props;
        if (a === null) {
            return <div className="assignmentBox empty" key={`empty-assignment-${  Math.random()}`} />;
        }
        let className="assignmentBox";
        if (this.props.selectedAssignment && (this.props.selectedAssignment.id == a.id)) {
            className += " selected";
        }

        let types = "";
        a.types.map((t, index) => {
            if (index > 0) {
                if (index === a.types.length - 1) {
                    types += " and ";
                } else {
                    types += ", ";
                }
            }
            types += Lookup.getRateType(t).label + (t === 'INSTAGRAM_STORY' && a.numberOfFrames ? ` (${  a.numberOfFrames  })` : '');
        });

        const self = this;
        const editClickHandler = function() {
            const assignmentObj = a;
            assignmentObj.campaign = {
                // id: assignmentObj.campaignId,
                id: assignmentObj.campaignId,
                name: assignmentObj.campaignName
            };
            const url = `/campaignWork/${a.campaign.id}/assignment/${a.id}`;
            self.props.history.push(url);
            self.props.selectSingleAssignment(a, a.campaign.id);
        }
        const editIconClickHandler = function() {
            self.props.editFunction(a);
        };
        const removeIconClickHandler = function() {
            self.props.removeFunction(a);
        };
        let hasInvitedCreators = false;
        if (supplementalAssignments && Array.isArray(supplementalAssignments)) {
            const match = supplementalAssignments.find(s => s.id === a.id);
            if (match && match.users && match.users.length > 0) {
                hasInvitedCreators = true;
            }
        }

        return (
            <div className={className} key={a.id}>
                <div className="upperPart">
                    <div className="name">
                        { a.name }
                    </div>
                    <div className="value">
                        { types }
                    </div>
                </div>
                <div className="lowerPart">
                    {this.props.permission&& !hasInvitedCreators  &&
                        <div className="icon" onClick={removeIconClickHandler}>
                            <img src="/images/ic-delete.svg" />
                        </div>
                    }

                    {this.props.permission && !hasInvitedCreators &&
                        <div className="icon" onClick={editIconClickHandler}>
                            <img src="/images/ic-edit-w.svg" />
                        </div>
                    }
                    <div>
                        <div className="viewButton" onClick={editClickHandler}>
                            VIEW
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const currentAssignments = [];
        const pastAssignments=[];
        const rawAssignments = this.props.assignments;
        rawAssignments.sort( (a1, a2) => {
            if (a1.name > a2.name) {
                return 1;
            }
            if (a2.name > a1.name) {
                return -1;
            }
            return 0;
        } );
        rawAssignments.map(assignment=>{
            if (assignment.archived) {
                pastAssignments.push(assignment)
            } else {
                currentAssignments.push(assignment)
            }
        });
        const assignments= this.props.current?currentAssignments:pastAssignments

        if (!Array.isArray(assignments)) {
            return null;
        }
        const boxes = [];
        if (Array.isArray(assignments) && assignments.length > 0) {
            for (const a of assignments) {
                boxes.push(this.renderedAssignment(a));
            }
        }

        return (
            <div className="campaignAssignments">
                <div className="panelTitle">
                    <div className="titleText">Assignments</div>
                    <div className="filterStatus">
                        <span className={cx({'current':this.props.current})}
                            onClick={()=>this.props.changeCurrent(true)}>Current</span>
                        <span className={cx({'current':!this.props.current})}
                            onClick={()=>this.props.changeCurrent(false)}>Past</span>
                    </div>
                </div>
                <Motion defaultStyle={{x:20, y:0}} style={{x: spring(0), y: spring(1)}}>
                    {({x, y}) => (
                        <Scrollbars style={{ width:'100%', height: this.props.assignmentHeight }}>
                            <div className="assignmentBoxContainer" style={{ opacity: `${y}`,
                                WebkitTransform: `translate3d(0,${x}px, 0)`,
                                transform: `translate3d(0,${x}px, 0)`, transition:'none'}}>
                                {boxes}
                            </div>
                        </Scrollbars>
                    )}
                </Motion>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        selectSingleAssignment : (campaignId, assignments) => {
            dispatch(selectSingleAssignment(campaignId, assignments))
        }
    }
};

const mapStateToProps= (state) => {
    return {
    }
};

export default  withRouter(connect(mapStateToProps, mapDispatchToProps)(Assignments))
