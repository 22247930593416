import * as Honeybadger from 'honeybadger-js';
import {LOOKUP_TABLES_LOADING, LOOKUP_TABLES_LOADED, STORE_PROFILE, STORE_TENANTS, RESET_TENANTS, ADD_INVITATIONS} from "./globalActionTypes";
import Api from "../../modules/Api";

const fake = false;

export const startLoadingLookupTables = () => {
    let d = null;
    Api.getAllDomainData().then(
        function(res) {
            const tables = res;
            const resolvedAction = {
                type : LOOKUP_TABLES_LOADED,
                summary : 'Y',
                message : '',
                tables
            }
            d(resolvedAction);
        }
    );

    const action = {
        type : LOOKUP_TABLES_LOADING,
        flag : true
    }
    return (dispatch) => {
        d = dispatch;
        return dispatch(action);
    }

}

export const storeUserProfile = (profile) => {
    if (fake) {
        profile.pictureUrl =
            "https://www.northernontario.travel/sites/default/files/styles/cover_image/public/PaintedPhoto-v2.jpeg?itok=KPb8oT0J";
        profile.roles =  [
            {
                roleName:  "ADMIN",
                brands: [
                    {
                        brandName: "OLAY",
                        brandLogo: "http://some.S3.bucket/logo.file.name.olay.png",
                        brandId: 1
                    },
                    {
                        brandName: "SK-II",
                        brandLogo: "http://some.S3.bucket/logo.file.name.sk-ii.png",
                        brandId: 3
                    }
                ]
            },
            {
                roleName:  "EDITOR",
                brands: [
                    {
                        brandName: "PANTENE",
                        brandLogo: "http://some.S3.bucket/logo.file.name.pantene.png",
                        brandId: 2,
                        campaigns: [
                            {
                                campaignName: "Some Pantene ProV Campaign",
                                campaignId: 1212
                            },
                            {
                                campaignName: "Another Pantene Campaign",
                                campaignId: 2345
                            }

                        ]
                    },
                    {
                        brandName: "LITTLE FLOWER",
                        brandLogo: "http://some.S3.bucket/logo.file.name.pantene.png",
                        brandId: 3,
                    }

                ]
            }
        ]

        profile.companyName = `Noemi's Dragonfire Agency`;
        profile.companyAddress = 'Beyond the glass hills and the great ocean';
    }

    const action = {
        type : STORE_PROFILE,
        profile
    }
    return (dispatch) => {
        const userName = [profile.email, profile.firstname, profile.lastname, profile.type].join(', ');
        Honeybadger.setContext({
            userName,
            profile
        });
        return dispatch(action);
    }
}

// export const storeSelectedAssignments = (campaignId, assignments) => {
//     let action = {
//         type : STORE_SELECTED_ASSIGNMENTS,
//         campaignId : campaignId,
//         assignments : assignments
//     }
//     return (dispatch) => {
//         return dispatch(action);
//     }
// }

export const addInvitationsInSession = (invitations) => {
    const action = {
        type : ADD_INVITATIONS,
        invitations
    }
    return (dispatch) => {
        return dispatch(action);
    }

}

export const storeTenantList = (tenantList) => {
    const action = {
        type : STORE_TENANTS,
        tenantList
    }
    return (dispatch) => {
        return dispatch(action);
    }
}

export const resetTenantList = () => {
    const action = {
        type : RESET_TENANTS,
    }
    return (dispatch) => {
        return dispatch(action);
    }
}