import React from 'react'
import CheckedInput from './CheckedInput'
import Format from '../../../modules/utils/Format'
import ErrorMessageBox from '../../shared/errorMessageBox/ErrorMessageBox'
import FeedbackOnTheTop from '../../shared/feedbackOnTheTop/FeedbackOnTheTop'
import { Auth } from 'aws-amplify';
import MFASettings from '../../shared/multiFactorAuth/MFASettings';

// props :
// - profile

export default class SettingsMenu extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            oldPassword : '',
            newPassword : '',
            confirmPassword : '',
            newPasswordError : null,
            confirmPasswordError : null,
            resetCounter : 1,
            passwordChanged : false
        }
    }

    clearState = () => {
        this.setState({
            resetCounter : this.state.resetCounter + 1,
            oldPassword : '',
            newPassword : '',
            confirmPassword : '',
            oldPasswordError : null,
            newPasswordError : null,
            confirmPasswordError : null
        })
    }

    oldPasswordChange = (value) => {
        let error = Format.validateInfluencerPassword(value);
        this.setState({
            oldPassword : value,
            oldPasswordOk : !error,
            oldPasswordError : (value.length>0) ? error : null
        })
    }

    newPasswordChange = (value) => {
        let error = Format.validateInfluencerPassword(value);
        this.setState({
            newPassword : value,
            newPasswordOk : !error,
            newPasswordError : (value.length>0) ? error : null
        })
    }

    confirmPasswordChange = (value) => {
        let error = null;
        if (value !== this.state.newPassword) {
            error = 'Passwords don\'t match'
        }
        this.setState({
            confirmPassword : value,
            confirmPasswordError : value.length > 0 ? error : null,
            confirmPasswordOk : !error
        })
    }

    completePasswordChange = () => {
        if (!this.state.oldPasswordOk || !this.state.newPasswordOk || !this.state.confirmPasswordOk) {
            return;
        }
        Auth.currentAuthenticatedUser()
            .then(user => {
                return Auth.changePassword(user, this.state.oldPassword, this.state.newPassword)
                    .then(data => {
                        this.setState({
                            showSuccess:true,
                            passwordChanged : true,
                            oldPassword : '',
                            newPassword : '',
                            confirmPassword : '',
                            resetCounter : this.state.resetCounter + 1
                        });
                    })
                    .catch(err => {
                        let message = 'No success';
                        if (err && err.message) {
                            message = err.message;
                        }
                        this.setState({errorMessage : message})
                    });
            })
            .catch(err => {
                console.log('bigger fail', err);
            });

    }

    closeErrorMessage = () => {
        this.setState({errorMessage:null});
    }

    closeSuccessMessage = () => {
        this.setState({showSuccess:false});
    }

    render() {
        if (!this.props.profile) {
            return null;
        }

        let completeButtonClass = 'button';
        if (!this.state.oldPasswordOk || !this.state.newPasswordOk || !this.state.confirmPasswordOk) {
            completeButtonClass += ' disabled';
        }

        return (
            <div className="password">
                <ErrorMessageBox
                    show={this.state.errorMessage}
                    closeFunction = {this.closeErrorMessage}
                    message = {this.state.errorMessage}
                />
                <FeedbackOnTheTop
                    show={this.state.showSuccess}
                    closeFunction = {this.closeSuccessMessage}
                    message = "Password successfully changed"
                />

                { this.state.passwordChanged &&
                    <div>
                        <div className="contentTitle">Change Password</div>
                        <div
                            style={{textAlign:'center', marginTop: 100, color: '#4AA129'}}
                            onClick={() => this.setState({passwordChanged: false})}
                        >
                            Change Your Password Again
                        </div>
                    </div>
                }

                { !this.state.passwordChanged &&
                    <div>
                        <div className="contentTitle">Email</div>
                            <div className="email">
                                <div className="inner">
                                    <div>
                                        Email
                                    </div>
                                <div className="value">
                                    { this.props.profile.email }
                                </div>
                            </div>
                        </div>

                        <div className="contentTitle">Change Password</div>
                        <div className="passwordBox">
                            <div className="fields">
                                <div className="label">
                                    Current Password
                                </div>
                                <CheckedInput
                                    initialValue = {this.state.oldPassword}
                                    changeFunction = {this.oldPasswordChange}
                                    errorMessage = {this.state.oldPasswordError}
                                    inputType = "password"
                                    resetCounter = {this.state.resetCounter}
                                />
                                <div className="label">
                                    New Password
                                </div>
                                <CheckedInput
                                    initialValue = {this.state.newPassword}
                                    changeFunction = {this.newPasswordChange}
                                    errorMessage = {this.state.newPasswordError}
                                    inputType = "password"
                                    resetCounter = {this.state.resetCounter}
                                />
                                <div className="label">
                                    Confirm Password
                                </div>
                                <CheckedInput
                                    initialValue = {this.state.confirmPassword}
                                    changeFunction = {this.confirmPasswordChange}
                                    errorMessage = {this.state.confirmPasswordError}
                                    inputType = "password"
                                    resetCounter = {this.state.resetCounter}
                                />
                            </div>
                            <div className="passwordControls">
                                <div className={completeButtonClass} onClick={this.completePasswordChange}>
                                    Save
                                </div>
                                <div className="button cancel" onClick={this.clearState}>
                                    Cancel
                                </div>
                                <div style={{clear:'both'}}/>
                            </div>
                        </div>

                        <MFASettings />
                    </div>

                }

            </div>
        )
    }
}