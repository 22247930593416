import combineEvents from '../../combineEvents';
import {
    SET_DATA,
    SET_ERROR,
} from './actions';

const initialState = {
    campaignAssignmentList: [],
    eventList: [],
    userList: [],
    error: null,
};

export default combineEvents({
    [SET_DATA]: (state, { payload }) => ({ ...state, ...payload }),
    [SET_ERROR]: (state, { message }) => ({
        ...state,
        error: message,
    }),
}, initialState);
