import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { authUser, logout } from '../libs/awsLib';
import { AGENT, AGENT_STR, BRAND_OWNER, BRAND_OWNER_STR, INFLUENCER, INFLUENCER_STR, SUPER_ADMIN, SUPER_ADMIN_STR, TALENT_MANAGER, TALENT_MANAGER_STR, VIEWER, VIEWER_STR } from '../constants/authorities';
import SSOAuth from "../modules/Auth";
import ProfileHelper from '../modules/utils/ProfileHelper';
import PendoHelper from '../modules/utils/PendoHelper';

// Return html code for disclaimer for users trying to login with the same credentials but holding different accounts
const TokenDisclaimer = ({ disclaimer, tokenUserHandler }) => (
    <div className="tokenDisclaimer">
        <div className="disclaimerBox">
            <div className="logoImageContainer">
                <Link to="/" className="logoAnchor">
                    <img className="logoImage" src="/images/Koalifyed-Logo.png" />
                </Link>
            </div>
            <div className="disclaimerField">
                <div className="text">
                    You are already logged in as a <span>{disclaimer}</span>.
                </div>
                <div className="text secondText">Would you like to continue your session, or log out?</div>
                <div className="button-container">
                    <div className="continue" onClick={(e) => tokenUserHandler(e)}>
                        Continue
                    </div>
                    <div className="signOut" onClick={(e) => tokenUserHandler(e)}>
                        Log out
                    </div>
                </div>
            </div>
        </div>
    </div>
);

class AuthCheckWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPath: this.props.location.pathname,
            loggedIn: false,
            loggedInType: '',
            triggerRedirectLogin: false,
            triggerRedirectOnboard: false,
            authCheckTokenObj: false,
            authCheckUserObj: {},
            disclaimer: null,
            tokenUser: null,
        };
        this.tokenUserHandler = this.tokenUserHandler.bind(this);
    }

    componentDidMount() {
        this.checkLoginStatus();
    }

    // Check Token function is only available if the account holder is logined in and iff he tries to switch account using the login url. Depending on what url route they choose they will be prompted with a message as shown in tokenDisclaimer. This function determines the nature of the account holder and whether they have access rights.
    checkToken(token, location, loggedIn) {
        if (loggedIn && location.pathname === '/logout') {
            const tokenUser = token['cognito:groups'] && token['cognito:groups'][0];
            return tokenUser.replace('_', ' ');
        }
        if (loggedIn && (location.hash === '#user' || location.hash === '#agent' || location.pathname === '/login')) {
            const hashNotFound = !location.hash ? '#user' : null;
            const user =
                token['cognito:groups'] && token['cognito:groups'].includes('agents') ? '#agent' : '#user';
            if (!user.includes(location.hash) || !user.includes(hashNotFound)) {
                return user.replace('#', '');
            }
            return null;
        } 
        return null;
        
    }

    async checkLoginStatus() {
        const self = this;

        const { location } = self.props;

        // check token to manage user pages and login status
        const jwtTokenObj = await this.parseToken();
        const ssoToken = localStorage.getItem('sso_access_token');

        // states to update
        const currentPath = self.props.location.pathname;
        const loggedIn = !!jwtTokenObj || !!ssoToken;
        const loggedInType =
            jwtTokenObj && jwtTokenObj['cognito:groups'] && jwtTokenObj['cognito:groups'].includes('agents')
                ? jwtTokenObj['cognito:groups'].includes('internal')
                    ? 'USER'
                    : 'AGENT'
                : 'USER';
        const triggerRedirect = !jwtTokenObj && !ssoToken;
        const tokenUser = self.checkToken(jwtTokenObj, location, loggedIn);

        let triggerRedirectOnboard = false;

        let authCheckUserObj = {};
        // if(self.props.location.pathname.startsWith('/profile') || self.props.location.pathname === '/onboard') {
        // check for onboarding status from profile
        try {
            if (loggedIn) {
                // call API to get profile only has logged in already. Otherwise, not necessary to call as 401 unauthorize error
                const profile = await ProfileHelper.getCurrentLoginUserProfile();
                if (profile && profile.role && profile.role === INFLUENCER_STR) {
                    triggerRedirectOnboard = profile.forceOnboard;
                }
                authCheckUserObj = profile;
                PendoHelper.pendoInit(profile);
            }
        } catch (err) {
            console.error(err);
        }
        // }
        self.setState({
            currentPath,
            loggedIn,
            loggedInType,
            triggerRedirect,
            triggerRedirectOnboard,
            authCheckTokenObj: loggedIn ? jwtTokenObj : false,
            authCheckUserObj,
            disclaimer: tokenUser,
        });

        // Auth.currentAuthenticatedUser()
        //     .then(user => console.log(user))
        //     .catch(err => console.log(err));

        // Api.getShortProfile().then((resolved) => {
        //         console.log('resolved', this.props.location.pathname, resolved);
        //         let onboard = false;
        //         if (resolved && resolved.forceOnboard) {
        //             onboard = resolved.forceOnboard
        //         }
        //
        //         self.setState({
        //             currentPath: self.props.location.pathname,
        //             loggedIn: true,
        //             loggedInType: resolved.type,
        //             triggerRedirect: false,
        //             triggerRedirectOnboard: onboard
        //         });
        //
        //     },
        //     (rejected) => {
        //         // console.log('rejected');
        //         self.setState({
        //             currentPath: self.props.location.pathname,
        //             loggedIn: false,
        //             loggedInType: '',
        //             triggerRedirect: true,
        //             triggerRedirectOnboard: false
        //         });
        //     });
    }

    async parseToken() {
        const token = await authUser();
        if (!token) {
            return false;
        } 
        // this.setState({loggedIn: true});
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
        
    }

    // Handler to switch between choices that the account holder will do iff they try to change accounts.
    tokenUserHandler(e) {
        e.preventDefault();
        const self = this;
        if (e.target.className === 'continue') {
            self.setState({ disclaimer: null });
            const { authCheckUserObj } = this.state;
            if (authCheckUserObj && authCheckUserObj.role === INFLUENCER_STR) {
                this.props.history.push('/profile');
            } else if (authCheckUserObj && authCheckUserObj.role === TALENT_MANAGER_STR) {
                this.props.history.push('/talentManagement');
            } else {
                this.props.history.push('/influencers');
            }
        } else if (e.target.className === 'signOut') {
            const ssoToken = localStorage.getItem('sso_access_token');
            ProfileHelper.reset();
            if (ssoToken) {
                SSOAuth.pgLogoutUser(() => {
                    self.setState(({disclaimer}) => ({
                        disclaimer: null,
                        tokenUser: disclaimer,
                    }));
                });
            }else {
                logout(() => {
                    self.setState(({disclaimer}) => ({
                        disclaimer: null,
                        tokenUser: disclaimer,
                    }));
                });
            }
        }
    }

    render() {
        if (this.state.disclaimer) {
            return <TokenDisclaimer disclaimer={this.state.disclaimer} tokenUserHandler={this.tokenUserHandler} />;
        } if (this.state.tokenUser) {
            return <Redirect to={{ pathname: '/' }} />;
        }

        let samePath = true;
        if (this.state.currentPath !== this.props.location.pathname) {
            samePath = false;
            this.checkLoginStatus();
        }

        const { privateComponent: Component, authorizedType, location } = this.props;
        const {authCheckUserObj, loggedIn, authCheckTokenObj, triggerRedirectOnboard, triggerRedirect} = this.state;

        const authCheckData = {
            token: authCheckTokenObj,
            user: authCheckUserObj,
        };
        // ROLE_AGENT,  ROLE_BRAND_OWNER, ROLE_INFLUENCER, ROLE_SUPER_ADMIN, ROLE_TALENT_MANAGER, ROLE_VIEWER

        if (samePath && loggedIn && authCheckUserObj && authCheckUserObj.role) {
            if (authCheckUserObj.role === INFLUENCER_STR) {
                // logged in ROLE_INFLUENCER
                if (!authorizedType.includes(INFLUENCER)) {
                    // redirect to profile page if not an ROLE_INFLUENCER
                    return <Redirect to={{ pathname: '/profile', state: { from: location } }} />;
                }
                if (triggerRedirectOnboard && location.pathname !== '/onboard') {
                    // if not on onboard page, check if onboarding required
                    return <Redirect to={{ pathname: '/onboard', state: { from: location } }} />;
                } 
                if (!triggerRedirectOnboard && location.pathname === '/onboard') {
                    // if on onboard page, check if onboarding already done
                    return <Redirect to={{ pathname: '/profile', state: { from: location } }} />;
                } 
                // send provide route component
                return <Component {...this.props} authCheckData={authCheckData} />;
                
            } if (
                authCheckUserObj.role === AGENT_STR ||
                authCheckUserObj.role === VIEWER_STR ||
                authCheckUserObj.role === BRAND_OWNER_STR
            ) {
                // logged in AGENT , 'ROLE_VIEWER', ROLE_SUPER_ADMIN, ROLE_BRAND_OWNER
                if (
                    !authorizedType.includes(AGENT) ||
                    !authorizedType.includes(VIEWER) ||
                    !authorizedType.includes(BRAND_OWNER)
                ) {
                    // redirect to influencers page if not an AGENT...
                    return <Redirect to={{ pathname: '/influencers', state: { from: this.props.location } }} />;
                } 
                // send provide route component
                return <Component {...this.props} authCheckData={authCheckData} />;
                
            } if (authCheckUserObj.role === TALENT_MANAGER_STR) {
                // logged in ROLE_TALENT_MANAGER
                if (!authorizedType.includes(TALENT_MANAGER)) {
                    // redirect to influencers page if not an ROLE_TALENT_MANAGER
                    return <Redirect to={{ pathname: '/talentManagement', state: { from: this.props.location } }} />;
                } 
                // send provide route component
                return <Component {...this.props} authCheckData={authCheckData} />;
                
            } if (authCheckUserObj.role === SUPER_ADMIN_STR) {
                // logged in ROLE_TALENT_MANAGER
                if (!authorizedType.includes(SUPER_ADMIN)) {
                    // redirect to influencers page if not an ROLE_TALENT_MANAGER
                    return <Redirect to={{ pathname: '/influencers', state: { from: this.props.location } }} />;
                } 
                // send provide route component
                return <Component {...this.props} authCheckData={authCheckData} />;
                
            } 
            return null;
            
        } if (samePath && triggerRedirect && location && location.pathname === '/logout') {
            return <Redirect to={{ pathname: '/' }} />;
        } if (samePath && triggerRedirect && location && location.pathname !== '/login') {
            // if not logged in and not on login page, send to login page,
            return <Redirect to={{ pathname: '/login', state: { from: location } }} />;
        } 
        if (location && location.pathname === '/login') {
            // if on login page, return the page
            return <Component {...this.props} />;
        } 
        return null;
            
        
    }
}

export default AuthCheckWrapper;
