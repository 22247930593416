import React from 'react';
import './assignmentTableHeader.scss';
import GeneralTooltip from '../../../../shared/generalTooltip/GeneralTooltip';

export default function AssignmentTableHeader() {
    return (
        <div className="assignment-table-header">
            <div className="column-label">Assignment Type</div>
            <div className="column-label">
                Post Analytics
                <GeneralTooltip text="Some text goes here." />
            </div>
            <div className="column-label">Submitted on</div>
            <div className="column-label">Status</div>
        </div>
    );
}
