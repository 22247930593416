import React, { Component } from 'react'
import cx from 'classnames';
import './draftCreate.scss'
import Select from 'react-select';
import { connect } from "react-redux";
import DraftComposer from "../../shared/draftComposer/draftComposer";
import Api from "../../../modules/Api"
import Lookup from "../../../modules/Lookup"
import PleaseWait from '../../shared/pleaseWait/PleaseWaitWhite'
import TopNavigation from '../../shared/topNavigation/TopNavigation';
import UserDraftPostResources from "../draftComment/UserDraftPostResources";
import ApiError from '../../shared/apiError/ApiError';
import {VIEWER_STR} from '../../../constants/authorities';

class DraftCreate extends Component {
    constructor(props){
        super(props);
        this.state={
            isFetching: false,
            assignmentUser: null,
            postType: this.props.match.params.postType,
            title: "",
            editorData: '',
            resources: [],
            showDownloading: false,
            onUploadCancel: null,
            assignmentId: this.props.match.params.assignmentId,
            influencerUid: this.props.match.params.influencerUid,
            // assignmentChoreId: this.props.match.params.choreId,
        }
        this.showApiError = this.showApiError.bind(this);
        this.removeVideo = this.removeVideo.bind(this);
        this.addVideo = this.addVideo.bind(this);
        this.downloadVideo = this.downloadVideo.bind(this);
    }

    componentDidMount() {
        const {assignmentId, influencerUid, postType} = this.state;
        this.setState({isFetching: true});
        Api.getAssignmentUser(assignmentId, influencerUid).then(response => {
            // const assignmentChore = response.assignment.chores.find(ch => ch.id === this.state.assignmentChoreId);
            const {label} = Lookup.getRateType(postType);
            this.setState({
                assignmentUser: response,
                isFetching: false,
                title: label + (label.indexOf(' Post') < 0 ? ' Post' : "")
            });
        }).catch(error => {
            console.log(`API call error: ${  error}`);
            this.setState({isFetching: false});
            this.showApiError(error);
        })
    }

    showApiError(error) {
        this.setState({
            showApiError: true,
            apiError: error,
        });
    }

    submitDraft = async () => {
        const {
            editorData: article,
            resources,
            assignmentUser,
            postType,
            title,
            // assignmentChoreId,
            influencerUid
        } = this.state;
        const {assignment} = assignmentUser;
        const resourcesList = resources.map(a => a.resourceId);
        const payload = {
            url: '',
            type: postType,
            title,
            itemType: "POST",
            status: "NEEDS_REVIEW",
            content: article,
            resources: resourcesList,
            // assignmentChoreId
        }

        Api.createDraftPost(assignment.id, payload, influencerUid).then((response) => {
            if (!resources || resources.length === 0)
                return Promise.resolve();

            return Promise.all(resources.map(r => Api.userDraftPostResourceAction(response.id, r.resource.id, "create")));
        }).then((r) => {
            this.props.history.push(`/campaignWork/${  assignment.campaign.id  }/posts/${  assignment.id}`);
        }).catch(error => {
            console.log(`Submit error: ${  error}`);
            this.showApiError(error);
        })
    }

    saveEditorData = editorData => {
        this.setState({ editorData });
    }

    handleVideosUpload = async ({ target: { files } }) => {
        if (files.length > 0) {
            for (const file of files) {
                try {
                    // if (file.size > 5368709120) {
                    //     this.setState({
                    //         showApiError: true,
                    //         apiErrTitle: 'The file is too large',
                    //         apiError: 'Allowed maximum size is 5GB. Please upload a smaller file',
                    //     });
                    // } else {
                    const fileDetails = {};
                    if (file && file.name) {
                        fileDetails.name = file.name;
                        fileDetails.type = file.type;
                        fileDetails.uploadedPercentage = 1;
                    }
                    fileDetails.tempResourceId = new Date().toString() + fileDetails.name;
                    // fileDetails.url = response.url;

                    this.setState((prevState) => {
                        // enable download progress UI
                        return {
                            ...prevState,
                            resources: [...prevState.resources, fileDetails],
                            showUploading: true
                        }
                    }, () => {
                        this.uploadFileGradually(file, fileDetails.tempResourceId, false);
                    });
                    // }
                } catch (error) {
                    console.log(error);
                }
            }
        }
    };

    uploadFileGradually(file, tempResourceId, publicRead) {
        const updateFileProgress = (progressPercentage) => {
            const resourceList = this.state.resources;
            if (progressPercentage > 0) {
                const resIndex = resourceList.findIndex(item => item.tempResourceId == tempResourceId);
                if (resIndex > -1 && resourceList[resIndex]) {
                    resourceList[resIndex].uploadedPercentage = progressPercentage;
                    resourceList[resIndex].isComplete = false;
                    this.setState({
                        resources: resourceList
                    });
                }
            }
        }

        const updateFileFinished = (response) => {
            if (response) {
                const resourceList = this.state.resources;
                const resIndex = resourceList.findIndex(item => item.tempResourceId == tempResourceId);
                if (resIndex > -1 && resourceList[resIndex]) {
                    resourceList[resIndex].uploadedPercentage = 100;
                    resourceList[resIndex].isComplete = true;
                    resourceList[resIndex].resourceId = response.id;
                    resourceList[resIndex].url = response.url;
                    this.setState((prevState) => {
                        return {
                            ...prevState,
                            resources: resourceList
                        }
                    });
                }
            }
        }

        this.setState({
            uploadedPercentage: 1
        })

        Api.postS3FileGradually(file,
            (progressPercentage) => updateFileProgress(progressPercentage),
            publicRead,
            (fn) => this.setState({onUploadCancel: fn})
        ).then((response) => {
            updateFileFinished(response);
            this.setState({ onUploadCancel: null });
        }).catch((err) => {
            this.showApiError(err);
            this.setState({ onUploadCancel: null });
        })
    }

    removeVideo = (resourceId) => {
        if (this.state.onUploadCancel)
            this.state.onUploadCancel();

        const newResources = this.state.resources.filter(r => r.resource.id != resourceId);
        this.setState({resources: newResources});
    }

    addVideo = (resource) => {
        const newResources = [...this.state.resources];
        const userName = this.props.profile ? (`${this.props.profile.firstname  } ${  this.props.profile.lastname}`) : "";
        newResources.push({resource, status: "ACTIVE", creator: userName});
        this.setState({resources: newResources});
    }

    downloadVideo = (resourceId) => {
        const resourceList = this.state.resources
        const resIndex = resourceList.findIndex(item => item.resource.id == resourceId);
        if (this.state.showDownloading == false) {
            // Api.getS3File(url, name); // download without progress bar
            this.setState({
                // enable download progress UI
                showDownloading: true
            }, () => {
                this.downloadFileListGradually(resIndex, resIndex + 1);
            });
        }
    }

    downloadFileListGradually(fileCountStart, fileCountEnd) {
        let currentFileCount = fileCountStart;

        const isCancelFileDownload = () => {
            return this.state.showDownloading == false;
        }

        const updateFileProgress = (fileNumber, progressPercentage) => {
            if (isCancelFileDownload()) {
                return true;
            } 
            if (progressPercentage > 0) {
                const downloadList = this.state.resources;
                downloadList[fileNumber].percentage = progressPercentage;
                downloadList[fileNumber].isComplete = false;
                this.setState({
                    resources: downloadList,
                    showDownloading: true,
                });
            }
            
        }

        const updateFileFinished = (fileNumber, response) => {
            const downloadList = this.state.resources;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', downloadList[fileNumber].resource.name);
            document.body.appendChild(link);
            link.click();

            downloadList[fileNumber].percentage = 100;
            downloadList[fileNumber].isComplete = true;
            this.setState({
                resources: downloadList,
                showDownloading: true,
            });

            currentFileCount++
            downloadFile(currentFileCount);
        }

        const downloadFile = (currentFileCount) => {
            const downloadList = this.state.resources;
            if (downloadList.length > 0 && currentFileCount > -1 && downloadList.length > currentFileCount && fileCountEnd > currentFileCount) {
                // update to show that new file is downloading in progress
                updateFileProgress(currentFileCount, 1);
                // Call gradual download
                Api.getS3FileGradually(downloadList[currentFileCount].resource.url,
                    (progressPercentage) => updateFileProgress(currentFileCount, progressPercentage),
                    (response) => updateFileFinished(currentFileCount, response));
            } else {
                this.setState({
                    showDownloading: false
                })
            }
        }

        downloadFile(currentFileCount);
    }

    render() {
        const {
            isFetching,
            assignmentUser,
            title,
            resources,
            postType,
            showApiError,
            apiError,
        } = this.state;
        const {profile, history} = this.props;
        const isContentEmpty = !this.state.editorData.length;

        if (isFetching)
            return <PleaseWait show/>;
        if (!assignmentUser || !postType)
            return null;

        const {assignment} = assignmentUser;

        return(
            <div className="assignmentCompose">
                <ApiError
                    show={showApiError}
                    error={apiError}
                    cancelFunction={() => this.setState({showApiError: false, apiError: null})}
                />
                <TopNavigation current="campaign" />
                <div className="listHeaderContainer">
                    <div className="listHeader" >
                        <div className="contentDiv">
                            <div className="goBack">
                                {assignment.campaign && assignment.campaign.name}: <span>{assignment.name && assignment.name}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="contentContainer">
                    <div className="headerTitle">
                        <div className="composerHeader composerHeaderNew">
                            <div className="title">
                                <div>
                                    <img
                                        src="/images/ic-back-b.svg"
                                        onClick={() => history.push({pathname: `/campaignWork/${  assignment.campaign.id  }/posts/${  assignment.id}`})}
                                    />
                                </div>
                                <div>
                                    <span>{title}</span>
                                    <span className="breaker">&nbsp;|&nbsp;</span>
                                    <span className="draft">Draft</span>
                                </div>
                            </div>
                            <div className="buttons">
                                <button
                                    className={cx("composerButton submit", {disabled: isContentEmpty})}
                                    onClick={() => this.submitDraft()}
                                    disabled={isContentEmpty}
                                >
                                    Submit to Brand
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="contentDiv">
                        <div className="leftSection">
                            <div className="composerContainer">
                                <div className="assignment-compose-media-container" style={{marginLeft: "35px"}}>
                                    <UserDraftPostResources resourceList={resources}
                                        refreshResources={() => {}}
                                        showApiError={this.showApiError}
                                        showVideos={(resource, index) => this.downloadVideo(null, null, index)}
                                        onRemoveVideo={this.removeVideo}
                                        onAddVideo={this.addVideo}
                                        thumbnailOverlayAdditionalStyles={{top: "85px"}}
                                        isBrandViewer={profile && profile.role === VIEWER_STR}
                                    />
                                </div>
                                {isContentEmpty && <div className="inputNotice"><img alt="" src="/images/ic-small-warning.svg" />Please input {Lookup.getRateType(postType).label} Description</div>}
                                <div className="inputNotice">
                                    <img src="/images/ic-small-warning.svg" alt=""/>
                                    Track Changes and Comment features are not available in new posts.
                                </div>
                                <div className="composerEditContainer composerEditContainerInfo">
                                    <DraftComposer
                                        title={title}
                                        onTitleChange={(e) => this.setState({ title: e.target.value })}
                                        onChange={this.onChange}
                                        assignmentId={assignment.id}
                                        saveEditorData={this.saveEditorData}
                                        user={profile}
                                        assignment={assignment}
                                        showWarning={this.showWarning}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.global.loggedInUserProfile,
    };
};

export default connect(mapStateToProps)(DraftCreate);

