import React from 'react'
import SocialNetwork from '../../../modules/influencer/SocialNetworks'
import Format from '../../../modules/utils/Format'
import './reachIcon.scss'
import cx from 'classnames'

// - rateKey
// - size

export default class ReachIcon extends React.Component {

    constructor (props, context) {
        super(props, context);
    }

    render () {
        const {noToolTip}=this.props;
        const network = (typeof this.props.network === 'string' )? SocialNetwork.getNetwork(this.props.network) : this.props.network;
        const size=Number(this.props.size);
        const value=Number(this.props.value);
        const padding = Number(this.props.padding);
        const bgval =(this.props.bgColor)?this.props.bgColor:network.color;
        const bgvalue=`${parseInt(`0x${bgval.substr(1,2)}`).toString()},${parseInt(`0x${bgval.substr(3,2)}`).toString()},${parseInt(`0x${bgval.substr(5,2)}`).toString()}`;
        const bgalpha = (value>0)? '1' : '0.5';
        const containerStyle = {
            width: size,
            height: size,
            boxSizing: 'unset',
            padding: padding || 0.2*size,
            marginTop: -0.1*size,
            backgroundColor: `rgba(${  bgvalue  },${bgalpha  })`
        };

        const imageStyle = {
            width: size,
            height: size,
            objectFit : 'contain',
            margin : 0,
            verticalAlign:'top'
        }
        let tooltips = network.label;
        if (tooltips === "Youtube") {
            tooltips = "YouTube"
        }
        if (tooltips === "Tiktok") {
            tooltips = "TikTok"
        }
        if (this.props.valueTip) {
            tooltips += ` ${ Format.expressInK(Number(this.props.value))}`;
        }

        return (
            <div className={cx("reachIcon",{"nullValue":value<=0}, {noToolTip}, {"bigSize":size>20})} style={containerStyle} id={tooltips}>
                <img style={imageStyle} src={network.image} />
            </div>
        );
    }
}
