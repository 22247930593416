import decode from 'jwt-decode';
import auth0 from 'auth0-js';
import getConfigs from '../../libs/publicConfigs';
// const config = require('../config/index.json');
// const config = require('../../../../server/config/public.json');
const fromServerConfig = getConfigs();
const config = fromServerConfig || process.env.uiconfig;
import axios from 'axios';

//const AUDIENCE = 'VOupo65zvqa2r1JGwiPpmz-o8JkseRgC';

/**
 * Sample call: https://skate-account.auth0.com/authorize?response_type=id_token&state=1111
 * &connection=facebook&client_id=VOupo65zvqa2r1JGwiPpmz-o8JkseRgC&scope=openid profile
 * &nonce=123456&redirect_uri=http://10.10.68.140:3000/redirect?page=onboard&con=facebook
 * */

let auth = new auth0.WebAuth({
  clientID: config.Auth0.CLIENT_ID,
  domain: config.Auth0.CLIENT_DOMAIN
});

export function promptLogin(redirectPageSuffix, connection, host) {
  let randomKey = connection+"|"+Math.random()*1000000000;

  /* Refine redirect host protocol */
  host = host.replace("http://", "https://");
  let hostUrl = host.toString().startsWith("https://")?host:"https://"+host;
  hostUrl = hostUrl.endsWith("/")?hostUrl:hostUrl+"/";

  let auth0connectionName = (connection==="google")?"google-oauth2":connection;
  let respType = ((connection==="facebook")||(connection==="youtube")) ? "token " : "";
  respType += config.Auth0.RESPONSE_TYPE;

  auth.authorize({
    responseType: respType,
    redirectUri: hostUrl + redirectPageSuffix + "&con=" + auth0connectionName,
    connection: auth0connectionName,
    scope: config.Auth0.SCOPE,
    state: "1111",
    nonce: randomKey
  });
}

export function getFacebookPage() {
  let id_token = getIdToken();
  let jwt = decode(id_token);
  let token = getAccessToken();
  let userId = extractNetworkUserId(jwt.sub, "facebook");
  let url = "https://graph.facebook.com/v3.1/"+userId+"/accounts";
  return axios.get(url, {
    headers: { Authorization : `Bearer ${token}`}
  });
}

function extractNetworkUserId(sub, networkTypeValue) {
  //assuming sub = networkSub + "|" + networkUserId lets strip off prefix
  let prefix = networkTypeValue + "|";
  return sub.substring(prefix.length);
}


export function getIdToken() {
  return getParameterByName('id_token');
}

export function getAccessToken() {
  return getParameterByName('access_token');
}

export function getCode() {
    return getParameterByName('code');
  }

// Helper function that will allow us to extract the access_token and id_token
function getParameterByName(name) {
  let match = new RegExp('[#&]' + name + '=([^&]*)').exec(window.location.hash);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

function getTokenExpirationDate(encodedToken) {
  const token = decode(encodedToken);
  if (!token.exp) { return null; }

  const date = new Date(0);
  date.setUTCSeconds(token.exp);

  return date;
}

function isTokenExpired(token) {
  const expirationDate = getTokenExpirationDate(token);
  return expirationDate < new Date();
}
