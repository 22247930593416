import styled, { css } from 'styled-components';

export const InputWrapper = styled.form(({ error }) => css`
    display: flex;
    flex-wrap: wrap;
    padding: 10px 20px;
    width: 100%;
    border-radius: 4px;
    border: solid 1px #D9D9D9;
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    color: #444444;
    font-weight: 300;
    box-sizing: border-box;
    gap: 6px;
    min-height: 50px;

    ${error ? css`
        border-color: red;
        background: transparent;
    ` : ''}
`)

export const Tag = {
    Wrapper: styled.div(({ error }) => css`
        background: #E3E4E5;
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 6px 8px;
        gap: 8px;
        border: 1px solid transparent;

        ${error ? css`
            border-color: red;
            background: transparent;
            color: red !important;
        ` : ''}
    `),
    Text: styled.div`
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
    `,
    CloseButton: styled.button`
        background: transparent;
        cursor: pointer;
        border: none;
        &:hover {
            opacity: 0.7;
        }
    `
}

export const Input = styled.input`
    flex: 1;
    min-width: 100px;
    border: none;

`

export const InfoMessage = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    font-family: "Nunito Sans", sans-serif;
    color: #7C7C7C;
    margin: 4px 0;

    ${(p) => p.error ? css`
        border-color: red;
        background: transparent;
        color: red !important;
    ` : ''}
`
