import React, { useState } from 'react';
import ComplianceTable from '../ComplianceTable/ComplianceTable';
import Checkbox from '../../../shared/checkbox2022/Checkbox';
import './complianceContent.scss';

const MOCK_POSTS = [
    {
        id: 1,
        name: 'Peter Madsen',
        channel: 'INSTAGRAM',
    },
    {
        id: 2,
        name: 'Peter Madsen',
        channel: 'INSTAGRAM',
    },
];

export default function ComplianceContent({ data, campaignId, assignmentIds }) {
    const [selectedIds, setSelectedIds] = useState([]);
    const allSelected = selectedIds.length === MOCK_POSTS.length;

    const toggleSelectAll = (isChecked) => {
        setSelectedIds(!isChecked ? MOCK_POSTS.map((val) => val.id) : []);
    };

    const toggleSelectRow = (isChecked, rowId) => {
        if (isChecked) {
            setSelectedIds((prev) => [...prev, rowId]);
        } else {
            setSelectedIds((prev) => prev.filter((id) => id != rowId));
        }
    };

    return (
        <div className="compliance-content-wrapper">
            {/* <div className="select-all-wrapper">
                <Checkbox
                    size={20}
                    checked={allSelected}
                    changeFunction={toggleSelectAll}
                    controlled
                    fixedSize
                    id="selectAll"
                    name="selectAll"
                />
                <span className="select-all-label">
                    {selectedIds.length === 0 ? 'Select all' : `Selected ${selectedIds.length}`}
                </span>
            </div> */}
            {!!data?.length ? (
                <ComplianceTable
                    selectedIds={selectedIds}
                    toggleSelectRow={toggleSelectRow}
                    data={data}
                    campaignId={campaignId}
                    assignmentIds={assignmentIds}
                />
            ) : (
                <div className="no-data-label">No data found.</div>
            )}
        </div>
    );
}
