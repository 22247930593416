import { put, select } from 'redux-saga/effects';
import { setData } from '../actionCreators';
import { openModal } from '../../../modals/missingChannelsModal/actionCreators';
/*
    this saga defines:
    * to show modal with influencers that couldn't be invited
    * to show invite popup
 */
export default function* beforeAddToCampaignSaga() {
    const {
        inappropriateInviteList,
    } = yield select(({ campaignReducer }) => ({
        inappropriateInviteList: Object.values(campaignReducer.influencerSearch.inappropriateInviteList),
    }));

    if (inappropriateInviteList.length) {
        yield put(openModal());
    } else {
        yield put(setData({
            inviteInProgress: true,
        }));
    }
}
