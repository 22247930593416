import { OPEN_CHAT, CLOSE_CHAT, CHANGE_SELECTED_INFLUENCER } from '../../../store/chat/actions/chatActionTypes';

export const changeChatStatusAction = (isChatOpen) => {
    let action = {
        type: isChatOpen ? OPEN_CHAT : CLOSE_CHAT
    }

    return (dispatch) => {
        return dispatch(action);
    }
}

export const changeSelectedInfluencerAction = (activeInfluencerId, activeInfluencerObj = {}) => {
    let action = {
        type: CHANGE_SELECTED_INFLUENCER,
        activeInfluencerId: activeInfluencerId,
        activeInfluencerObj: activeInfluencerObj
    }

    return (dispatch) => {
        return dispatch(action);
    }
}
