import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getData, resetData, searchFilterChange } from '../../store/campaign/influencerSearch/actionCreators';
import { resetData as resetGeneralData } from '../../store/general/actionCreators';
// import below have cycle dependencies (potential memory leak)
import InfluencersBody from './InfluencersBody';
import TopNavigation from '../shared/topNavigation/TopNavigation';

class Influencers extends React.Component {
    componentDidMount() {
        const {
            actions: { getData, searchFilterChange },
        } = this.props;
        // the filter defaults PUBLIC
        const searchData = {
            type: "CONNECTED",
            connectedFilters: []
        }
        searchFilterChange(searchData);
        getData();
    }

    componentWillUnmount() {
        const {
            actions: { resetData, resetGeneralData },
        } = this.props;
        resetData();
        setTimeout(() => {
            resetGeneralData();
        }, 50);
    }

    render() {
        return (
            <div className="influencers">
                <TopNavigation
                    current="influencers"
                    clickHandlerForCurrent={this.triggerRerender}
                />
                <InfluencersBody disableMultipleInvite />
            </div>
        );
    }
}

Influencers.propTypes = {
    actions: PropTypes.shape({
        getData: PropTypes.func,
        resetData: PropTypes.func,
        searchFilterChange: PropTypes.func,
        resetGeneralData: PropTypes.func,
    }),
};

const mapStateToProps = (state) => state;
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({
        getData,
        resetData,
        searchFilterChange,
        resetGeneralData
    }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Influencers);
