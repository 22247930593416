import React from 'react';
import PropTypes from 'prop-types';
import PulseLoader from '../pulseLoader/PulseLoader';
import Modal from 'react-modal';
import './pleaseWait.scss';

// props :
// - show  (if the component displays)

const PleaseWait = ({ show, top, progressPercentage, onCancel }) => {
    if (!show) {
        return null;
    }

    const style = {
        content: {
            top: top || '',
        },
    };

    return (
        <Modal overlayClassName="pleaseWaitOverlay" isOpen={show} className="pleaseWaitWhite" style={style}>
            <div className="spacer" />
            <PulseLoader color="#4AA129" size="16px" margin="4px" />
            {progressPercentage && <div>{progressPercentage + " %"}</div>}
            {onCancel && <div className="cancelButton" onClick={onCancel}>CANCEL</div>}
        </Modal>
    );
};

PleaseWait.propTypes = {
    show: PropTypes.bool.isRequired,
    top: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

PleaseWait.defaultProps = {
    top: null,
};

export default PleaseWait;
