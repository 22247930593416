import React, { useEffect, useState, useRef } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Label } from 'recharts';
import moment from 'moment';
import Api from '../../../modules/Api';
import TopCreatorsModal from './TopCreatorsModal';
import { formatBigNumber, formatPercentageToWhole } from '../../../modules/utils/helpers';
import { default as TextTooltip } from '../../shared/tooltip/Tooltip';
import PaginationNew from '../../shared/pagination/PaginationNew';
import PleaseWaitWhite from '../../shared/pleaseWait/PleaseWaitWhite';
import PleaseWait from '../../shared/pleaseWait/PleaseWait';
import ApiError from '../../shared/apiError/ApiError';
import BrandModal from '../../superAdmin/Brands/BrandModal';
import './competetiveAnalysis.scss';
import './table.scss';

export const graphColors = ['#FD625E', '#62B3FA', '#44B41A', '#FFBE16', '#00BBAB', '#8E1CFF', '#FF1CB0', '#3CDAFA', '#3CFACA', '#3CFA65'];

const CompetitiveAnalysisTab = ({
    brandData,
    competitorColors,
    competitorsData,
    duration,
    onBrandDataInvalidation,
    isCompetitorsLoading,
    isFiltersActive,
}) => {
    const [apiError, setApiError] = useState();
    const [errTitle, setErrTitle] = useState('');

    const getCompetitorColor = (uid) => competitorColors.find((data) => data.uid === uid)?.color;
    const getCompetitorData = (uid) => {
        const data = competitorsData?.competitorBrands.find((brand) => brand.uid === uid);
        return data ? { socialHandle: data.socialHandle, pictureUrl: data.pictureUrl } : [];
    };

    const competitorBrands =
        competitorsData?.competitorBrands
            .sort((a, b) => b.totalFollowers - a.totalFollowers)
            .slice(0, 10)
            .map((brand) => ({ ...brand, color: getCompetitorColor(brand.uid) })) || [];
    const brandPostSummaries =
        competitorsData?.brandPostSummaries
            .sort((a, b) => b.totalPosts - a.totalPosts)
            .slice(0, 10)
            .map((brand) => ({
                ...brand,
                color: getCompetitorColor(brand.uid),
                ...getCompetitorData(brand.uid),
            })) || [];
    const topCreators = competitorsData?.topCreators.sort((a, b) => b.followers - a.followers) || [];
    const hashtags = competitorsData?.popularHashtags.sort((a, b) => b.count - a.count).slice(0, 5) || [];
    const topPosts = competitorsData?.topPosts.sort((a, b) => b.stat.likes - a.stat.likes) || [];
    const overtimeData = competitorBrands
        .map((brand) => ({
            brandName: brand.socialHandle,
            color: brand.color,
            data: competitorsData?.viewsOvertime.find((data) => data.competitorBrandUid === brand.uid),
        }))
        .filter((lineData) => lineData.data);

    const CancelError = () => {
        setApiError(null);
        setErrTitle('');
    };

    if (!brandData?.brandCompetitors.length)
        return (
            <>
                <PleaseWaitWhite show={isCompetitorsLoading} />
                <EmptyPanel brandData={brandData} onBrandDataInvalidation={onBrandDataInvalidation} />
            </>
        );

    return (
        <div className="tab-content">
            <PleaseWait show={isCompetitorsLoading} top={isFiltersActive ? '315px' : '265px'} />
            <ApiError show={apiError} errTitle={errTitle} error={apiError} cancelFunction={CancelError} />
            <div className="inner-wrapper">
                <div className="header-row">
                    <div className="header-title">Competitive Analysis</div>
                    <div className="header-subtitle">Available for Instagram only</div>
                </div>
                <AccountsPanel competitorBrands={competitorBrands} />
                <PostSummaryPanel brandPostSummaries={brandPostSummaries} />
                <OvertimePanel overtimeData={overtimeData} competitorColors={competitorColors} duration={duration} />
                <div className="panel-container">
                    <TopCreatorsPanel topCreators={topCreators} />
                    <HashtagsPanel hashtags={hashtags} />
                </div>
                <TopPostsPanel topPosts={topPosts} />
            </div>
        </div>
    );
};

const AccountsPanel = ({ competitorBrands }) => {
    const [sort, setSort] = useState();

    const sortedBrands = sort
        ? [...competitorBrands].sort((a, b) => {
              if (a[sort.parameterName] < b[sort.parameterName]) return sort.order === 'ASC' ? -1 : 1;
              if (a[sort.parameterName] > b[sort.parameterName]) return sort.order === 'ASC' ? 1 : -1;
              return 0;
          })
        : competitorBrands;

    const handleHeaderClick = (parameterName) =>
        setSort((prev) =>
            !prev || prev?.parameterName !== parameterName
                ? { parameterName, order: 'DESC' }
                : prev?.order === 'DESC'
                ? { ...prev, order: 'ASC' }
                : undefined,
        );

    const getClassName = (parameterName) => (sort?.parameterName === parameterName ? `sort-active ${sort.order === 'ASC' ? 'sort-arrow-asc' : ''}` : '');

    return (
        <div className="summary-panel">
            <div className="panel-name">
                <div>Brand Accounts</div>
                <div className="info-tooltip-icon">
                    <TextTooltip
                        tooltipText="Analytical data aggregated by a competitive brand, based on filter criteria"
                        image="/images/tooltipUser.svg"
                    />
                </div>
            </div>
            {sortedBrands.length ? (
                <>
                    <table>
                        <tbody>
                            <tr className="table-header">
                                <th className="summary-handle-col" />
                                <th className="summary-name-col" onClick={() => handleHeaderClick('socialHandle')}>
                                    <div>
                                        Name
                                        <SortArrow className={getClassName('socialHandle')} />
                                    </div>
                                </th>
                                <th className="summary-total-followers-col" onClick={() => handleHeaderClick('totalFollowers')}>
                                    <div>
                                        Total Followers
                                        <SortArrow className={getClassName('totalFollowers')} />
                                    </div>
                                </th>
                                <th className="summary-real-followers-col" onClick={() => handleHeaderClick('estimatedRealFollowers')}>
                                    <div>
                                        Est. Real Followers
                                        <SortArrow className={getClassName('estimatedRealFollowers')} />
                                    </div>
                                </th>
                                <th className="summary-engagement-col selected" onClick={() => handleHeaderClick('engagement')}>
                                    <div>
                                        Engagement
                                        <SortArrow className={getClassName('engagement')} />
                                    </div>
                                </th>
                                <th className="summary-eng-rate-col" onClick={() => handleHeaderClick('engagementRate')}>
                                    <div>
                                        Eng. Rate
                                        <SortArrow className={getClassName('engagementRate')} />
                                    </div>
                                </th>
                                <th className="summary-credibility-col" onClick={() => handleHeaderClick('audienceCredibility')}>
                                    <div>
                                        Credibility
                                        <SortArrow className={getClassName('audienceCredibility')} />
                                    </div>
                                </th>
                            </tr>
                            {sortedBrands.map((brand) => (
                                <tr key={brand.uid} className="summary-data-row">
                                    <td className="summary-handle-col">
                                        <div className="handle" style={{ backgroundColor: brand.color }} />
                                    </td>
                                    <td className="summary-name-col">
                                        <img className="brand-logo" src={brand.pictureUrl} />
                                        {brand.socialHandle}
                                    </td>
                                    <td className="summary-total-followers-col">
                                        <div className="number-col">
                                            {brand.totalFollowers ? formatBigNumber(brand.totalFollowers) : '-'}
                                        </div>
                                    </td>
                                    <td className="summary-real-followers-col">
                                        <div className="number-col">
                                            {!isNaN(brand.estimatedRealFollowers) ? formatBigNumber(brand.estimatedRealFollowers) : '-'}
                                        </div>
                                    </td>
                                    <td className="summary-engagement-col">
                                        <div className="number-col">{!isNaN(brand.engagement) ? formatBigNumber(brand.engagement) : '-'}</div>
                                    </td>
                                    <td className="summary-eng-rate-col">
                                        <div className="number-col">
                                            {!isNaN(brand.engagementRate) ? `${formatPercentageToWhole(brand.engagementRate * 100)}%` : '-'}
                                        </div>
                                    </td>
                                    <td className="summary-credibility-col">
                                        <div className="number-col">
                                            {!isNaN(brand.audienceCredibility)
                                                ? `${formatPercentageToWhole(brand.audienceCredibility * 100)}%`
                                                : '-'}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            ) : (
                <div className="empty-data-label">There is no data to display.</div>
            )}
        </div>
    );
};

const PostSummaryPanel = ({ brandPostSummaries }) => {
    const [sort, setSort] = useState();

    const sortedBrands = sort
        ? [...brandPostSummaries].sort((a, b) => {
              if (a[sort.parameterName] < b[sort.parameterName]) return sort.order === 'ASC' ? -1 : 1;
              if (a[sort.parameterName] > b[sort.parameterName]) return sort.order === 'ASC' ? 1 : -1;
              return 0;
          })
        : brandPostSummaries;

    const handleHeaderClick = (parameterName) =>
        setSort((prev) =>
            !prev || prev?.parameterName !== parameterName
                ? { parameterName, order: 'DESC' }
                : prev?.order === 'DESC'
                ? { ...prev, order: 'ASC' }
                : undefined,
        );

    const getClassName = (parameterName) => (sort?.parameterName === parameterName ? `sort-active ${sort.order === 'ASC' ? 'sort-arrow-asc' : ''}` : '');

    return (
        <div className="summary-panel">
            <div className="panel-name">
                <div>Sponsored Post Summary</div>
                <div className="info-tooltip-icon">
                    <TextTooltip
                        tooltipText="Summary of sponsored posts analytical data by competitor brands, based on filter criteria"
                        image="/images/tooltipUser.svg"
                    />
                </div>
            </div>
            {sortedBrands.length ? (
                <>
                    <table>
                        <tbody>
                            <tr className="table-header">
                                <th className="summary-handle-col" />
                                <th className="post-name-col" onClick={() => handleHeaderClick('socialHandle')}>
                                    <div>
                                        Name
                                        <SortArrow className={getClassName('socialHandle')} />
                                    </div>
                                </th>
                                <th className="post-total-posts-col" onClick={() => handleHeaderClick('totalPosts')}>
                                    <div>
                                        Total Posts
                                        <SortArrow className={getClassName('totalPosts')} />
                                    </div>
                                </th>
                                <th className="post-engagement-col" onClick={() => handleHeaderClick('engagement')}>
                                    <div>
                                        Engagement
                                        <SortArrow className={getClassName('engagement')} />
                                    </div>
                                </th>
                                <th className="post-views-col" onClick={() => handleHeaderClick('views')}>
                                    <div>
                                        Views
                                        <SortArrow className={getClassName('views')} />
                                    </div>
                                </th>
                                <th className="post-eng-rate-col" onClick={() => handleHeaderClick('engagementRateByViews')}>
                                    <div>
                                        Eng. Rate by Views
                                        <SortArrow className={getClassName('engagementRateByViews')} />
                                    </div>
                                </th>
                            </tr>
                            {sortedBrands.map((brand) => (
                                <tr key={brand.uid} className="summary-data-row">
                                    <td className="summary-handle-col">
                                        <div className="handle" style={{ backgroundColor: brand.color }} />
                                    </td>
                                    <td className="summary-name-col">
                                        <img className="brand-logo" src={brand.pictureUrl} />
                                        {brand.socialHandle}
                                    </td>
                                    <td className="post-total-posts-col">
                                        <div className="number-col">{brand.totalPosts ? formatBigNumber(brand.totalPosts) : '-'}</div>
                                    </td>
                                    <td className="post-engagement-col">
                                        <div className="number-col">{formatBigNumber(brand.engagement)}</div>
                                    </td>
                                    <td className="post-views-col">
                                        <div className="number-col">{formatBigNumber(brand.views)}</div>
                                    </td>
                                    <td className="post-eng-rate-col">
                                        <div className="number-col">
                                            {brand.engagementRateByViews
                                                ? `${formatPercentageToWhole(brand.engagementRateByViews * 100)}%`
                                                : '-'}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            ) : (
                <div className="empty-data-label">There is no data to display.</div>
            )}
        </div>
    );
};

const OvertimePanel = ({ overtimeData, duration }) => {
    const [isViewsSelected, setIsViewsSelected] = useState(true);

    const lines = overtimeData.map((brand) => ({
        ...brand,
        data: brand.data.overtimePoints.map((dataPoint) => ({
            date: dataPoint.date,
            views: dataPoint.views,
            engagements: dataPoint.engagements,
        })),
    }));

    const daysRange = moment(duration.value.endDate).diff(duration.value.startDate, 'days');
    const tickXFormat = daysRange < 32 ? 'DD' : 'M/YY';

    const dates = lines?.[0]?.data
        .map((dataPoint) => dataPoint.date)
        .reduce((prev, curr) => {
            const formattedDate = moment(curr).format(tickXFormat);
            if (daysRange > 31 && daysRange < 30 * 6) {
                // Week format
                if (!prev.find((date) => moment(date).format(tickXFormat) === formattedDate)) {
                    prev.push(curr);
                } else {
                    // Push only Monday
                    if (moment(curr).day() === 1) prev.push(curr);
                }
                return prev;
            }
            // Days/Month format
            if (!prev.find((date) => moment(date).format(tickXFormat) === formattedDate)) prev.push(curr);
            return prev;
        }, []);

    const getTickX = (tick, index) => {
        if (daysRange < 32) return moment(tick).format('DD');
        if (daysRange > 31 && daysRange < 30 * 6) {
            const skipTick = index - 1 >= 0 && moment(dates[index - 1]).format('M/YY') === moment(dates[index]).format('M/YY');
            if (skipTick) return '';
        }
        return moment(tick).format('M/YY');
    };

    return (
        <div className="overtime-panel">
            <div className="panel-name panel-name-border">
                <div className="overtime-panel-name-cont">
                    <div>Over time</div>
                </div>
                <div className="info-tooltip-icon">
                    <TextTooltip
                        tooltipText={
                            isViewsSelected
                                ? 'Total of reels views for a competitive brand at a given point in time, based on filter criteria'
                                : 'Total of likes and comments for a competitive brand at a given point in time, based on filter criteria'
                        }
                        image="/images/tooltipUser.svg"
                    />
                </div>
                <div className="overtime-button-panel">
                    <button
                        className={`overtime-button left-button ${isViewsSelected ? 'selected' : ''}`}
                        onClick={() => setIsViewsSelected(true)}
                    >
                        Total Views
                    </button>
                    <button
                        className={`overtime-button right-button ${isViewsSelected ? '' : 'selected'}`}
                        onClick={() => setIsViewsSelected(false)}
                    >
                        Engagement
                    </button>
                </div>
            </div>
            <div className={lines.length ? 'overtime-graph' : 'overtime-empty'}>
                {lines.length ? (
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart
                            width={500}
                            height={300}
                            data={lines[1]}
                            margin={{
                                top: 5,
                                right: 5,
                                left: 0,
                                bottom: 25,
                            }}
                        >
                            <CartesianGrid strokeArray="3 3" />
                            <XAxis dataKey="date" allowDuplicatedCategory={false} tickFormatter={getTickX} tickLine={false} ticks={dates}>
                                <Label position="bottom" style={{ textAnchor: 'middle', fontWeight: '700', fontSize: 13 }}>
                                    Post date
                                </Label>
                            </XAxis>
                            <YAxis tickFormatter={(tick) => formatBigNumber(tick)}>
                                <Label
                                    style={{
                                        textAnchor: 'middle',
                                        fontWeight: '700',
                                        fontSize: 13,
                                    }}
                                    position="left"
                                    angle={270}
                                    offset={-5}
                                >
                                    {isViewsSelected ? 'Total Views' : 'Engagements'}
                                </Label>
                            </YAxis>
                            <Tooltip
                                formatter={(value) => `${formatBigNumber(value)} ${isViewsSelected ? 'views' : 'engagements'}`}
                                labelFormatter={(date) => `Date: ${moment(date).format('DD/MM/YY')}`}
                            />
                            {lines.map((line) => (
                                <Line
                                    dot={false}
                                    type="monotone"
                                    dataKey={isViewsSelected ? 'views' : 'engagements'}
                                    data={line.data}
                                    name={line.brandName}
                                    key={line.brandName}
                                    stroke={line.color}
                                    strokeWidth={2}
                                />
                            ))}
                        </LineChart>
                    </ResponsiveContainer>
                ) : (
                    <div className="empty-data-label">There is no data to display.</div>
                )}
            </div>
        </div>
    );
};

const TopCreatorsPanel = ({ topCreators }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <div className="creators-panel">
            <div className="panel-name">
                <div>Top Creators</div>
                <div className="info-tooltip-icon">
                    <TextTooltip tooltipText="Most followed creators based on filter criteria" image="/images/tooltipUser.svg" />
                </div>
            </div>
            {topCreators.length ? (
                <>
                    <TopCreatorsTable topCreators={topCreators.slice(0, 5)} />
                    <button className="view-all-btn" onClick={() => setIsModalOpen(true)}>
                        View All
                    </button>
                    <TopCreatorsModal topCreators={topCreators} isOpen={isModalOpen} handleClose={() => setIsModalOpen(false)} />
                </>
            ) : (
                <div className="empty-data-label">There is no data to display.</div>
            )}
        </div>
    );
};

export const TopCreatorsTable = ({ topCreators }) => {
    const [sort, setSort] = useState();

    const sortedCreators = sort
        ? [...topCreators].sort((a, b) => {
              if (a[sort.parameterName] < b[sort.parameterName]) return sort.order === 'ASC' ? -1 : 1;
              if (a[sort.parameterName] > b[sort.parameterName]) return sort.order === 'ASC' ? 1 : -1;
              return 0;
          })
        : topCreators;

    const handleHeaderClick = (parameterName) =>
        setSort((prev) =>
            !prev || prev?.parameterName !== parameterName
                ? { parameterName, order: 'DESC' }
                : prev?.order === 'DESC'
                ? { ...prev, order: 'ASC' }
                : undefined,
        );

    const getClassName = (parameterName) => (sort?.parameterName === parameterName ? `sort-active ${sort.order === 'ASC' ? 'sort-arrow-asc' : ''}` : '');

    const handleImgError = (e) => (e.currentTarget.src = '/images/ic-avatar-blank-d.svg');

    return (
        <table>
            <tbody>
                <tr className="table-header">
                    <th className="creators-name-col" onClick={() => handleHeaderClick('username')}>
                        <div>
                            Name
                            <SortArrow className={getClassName('username')} />
                        </div>
                    </th>
                    <th className="creators-total-followers-col" onClick={() => handleHeaderClick('followers')}>
                        <div>
                            Total Followers
                            <SortArrow className={getClassName('followers')} />
                        </div>
                    </th>
                    <th className="creators-engagement-col" onClick={() => handleHeaderClick('engagements')}>
                        <div>
                            Engagement
                            <SortArrow className={getClassName('engagements')} />
                        </div>
                    </th>
                    <th className="creators-eng-rate-col" onClick={() => handleHeaderClick('engagementRate')}>
                        <div>
                            Eng. Rate
                            <SortArrow className={getClassName('engagementRate')} />
                        </div>
                    </th>
                </tr>
                {sortedCreators.map((creator) => (
                    <tr key={creator.userId} className="creators-data-row">
                        <td className="creators-name-col">
                            <div
                                className="creator-link"
                                onClick={() => window.open(`/publicInfluencerDetailsPage/${creator.userId}/INSTAGRAM?Public`, '_blank')}
                            >
                                <img className="creator-icon" src={creator.picture} onError={handleImgError} />
                                {`@${creator.username}`}
                                {creator.verified && (
                                    <div className="verified-icon">
                                        <img src="/images/ic-ins-verified.svg" />
                                    </div>
                                )}
                            </div>
                        </td>
                        <td className="creators-total-followers-col">
                            <div className="number-col">{!isNaN(creator.followers) ? formatBigNumber(creator.followers) : '-'}</div>
                        </td>
                        <td className="creators-engagement-col">
                            <div className="number-col">{!isNaN(creator.engagements) ? formatBigNumber(creator.engagements) : '-'}</div>
                        </td>
                        <td className="creators-eng-rate-col">
                            <div className="number-col">
                                {!isNaN(creator.engagementRate) ? `${formatPercentageToWhole(creator.engagementRate * 100)}%` : '-'}
                            </div>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

const HashtagsPanel = ({ hashtags }) => {
    const maxHashcountMax = hashtags[0]?.count;
    return (
        <div className="hashtags-panel">
            <div className="panel-name panel-name-border">
                <div>Popular Hashtags</div>
                <div className="info-tooltip-icon">
                    <TextTooltip tooltipText="Most popular hashtags based on filter criteria" image="/images/tooltipUser.svg" />
                </div>
            </div>
            {hashtags.length ? (
                <>
                    {hashtags.map((hashtag) => (
                        <React.Fragment key={hashtag.value}>
                            <div className="hashtag-info">
                                <div className="hashtag-name">#{hashtag.value}</div>
                                <div>{formatBigNumber(hashtag.count)}</div>
                            </div>
                            <div className="count-bar-container">
                                <div className="count-bar count-bar-background" />
                                <div className="count-bar" style={{ width: `${(hashtag.count / maxHashcountMax) * 100}%` }} />
                            </div>
                        </React.Fragment>
                    ))}
                </>
            ) : (
                <div className="empty-data-label">There is no data to display.</div>
            )}
        </div>
    );
};

const TopPostsPanel = ({ topPosts }) => {
    const [pageLimit, setPageLimit] = useState(8);
    const [currentPage, setCurrentPage] = useState(1);
    const listRef = useRef(null);

    const postsSlice = topPosts.slice((currentPage - 1) * pageLimit, currentPage * pageLimit);

    const pageLimitOptions = [
        { value: '8', label: '8 per page' },
        { value: '16', label: '16 per page' },
        { value: '48', label: '48 per page' },
    ];

    return (
        <div className="posts-panel">
            <div className="panel-name panel-name-border">
                <div>Top Posts</div>
                <div className="info-tooltip-icon">
                    <TextTooltip tooltipText="Most popular sponsored posts based on filter criteria" image="/images/tooltipUser.svg" />
                </div>
            </div>
            {postsSlice.length ? (
                <div className="post-cards" ref={listRef}>
                    {postsSlice.map((post, idx) => (
                        <PostCard post={post} key={idx} />
                    ))}
                </div>
            ) : (
                <div className="empty-data-label">There is no data to display.</div>
            )}
            <PaginationNew
                total={topPosts.length}
                limit={pageLimit}
                current={currentPage}
                options={pageLimitOptions}
                parentContainer={listRef.current}
                handlePageChange={(page) => setCurrentPage(page)}
                limitChangeFunction={(limit) => setPageLimit(limit)}
            />
        </div>
    );
};

const PostCard = ({ post }) => {
    const [photoExists, setPhotoExists] = useState(true);

    return (
        <a className="post-card" href={post.link} target="_blank">
            <div className="post-creator-name">
                <img className="creator-icon" src={post.user.picture} />@{post.user.username}
                {post.user.verified && (
                    <div className="verified-icon">
                        <img src="/images/ic-ins-verified.svg" />
                    </div>
                )}
            </div>
            {photoExists ? (
                <img className="post-photo" src={post.thumbnail} onError={() => setPhotoExists(false)} alt="" />
            ) : (
                <div className="post-photo">
                    <div className="no-photo-text">This post no longer exists</div>
                </div>
            )}
            <div className="post-date">{moment(post.createdOn).format('MMM DD, YYYY [at] h:mm A z')}</div>
            <div className="post-text">{post.text}</div>
            <div className="post-info">
                <div className="info-col">
                    <div className="info-title">Likes</div>
                    <div className="info-data">{formatBigNumber(post.stat.likes)}</div>
                </div>
                <div className="info-col">
                    <div className="info-title">Comments</div>
                    <div className="info-data">{formatBigNumber(post.stat.comments)}</div>
                </div>
            </div>
        </a>
    );
};

const EmptyPanel = ({ brandData, onBrandDataInvalidation }) => {
    const [showEditModal, setShowEditModal] = useState(false);
    const [userAdvertisers, setUserAdvertisers] = useState([]);
    const [apiError, setApiError] = useState(null);

    const formattedBrandData = brandData
        ? {
              id: brandData?.brandId,
              logo: brandData?.brandLogo,
              brand: brandData?.brandName,
              advertiser: brandData?.advertiser?.name,
              advertiserOption: brandData?.advertiser,
              email: brandData?.billingContractEmail,
              countryCode: brandData?.countryCode,
              defaultCurrencyCode: brandData?.defaultCurrencyCode,
              createdBy: brandData?.createdBy,
              date: brandData?.createdOn,
              campaigns: brandData?.campaigns,
              brandCompetitors: brandData?.brandCompetitors,
          }
        : null;

    const handleBrandSave = async (brandId, data) => {
        try {
            await Api.updateBrandAddress(brandId, data);
            setShowEditModal(false);
            onBrandDataInvalidation(brandId);
        } catch (error) {
            setApiError(error);
        }
    };

    useEffect(() => {
        let mounted = true;

        Api.getMyAdvertisers()
            .then((res) => {
                if (mounted) setUserAdvertisers(res);
            })
            .catch((err) => setApiError(err));

        return () => (mounted = false);
    }, []);
    return (
        <div className="empty-panel">
            <ApiError error={apiError} />
            <div className="empty-icon">
                <img src="/images/ic-no-note.svg" />
            </div>
            <div className="empty-title">No competitors found</div>
            <div className="empty-subtitle">Unlock your analysis by adding competitors under brand details</div>
            {formattedBrandData && <button onClick={() => setShowEditModal(true)}>Edit Brand</button>}
            {formattedBrandData && <BrandModal
                brand={formattedBrandData}
                type="edit"
                userAdvertisers={userAdvertisers}
                // userBrandOwners={userBrandOwners}
                onManipulateBrand={handleBrandSave}
                onCloseModal={() => setShowEditModal(false)}
                show={showEditModal}
            />}
        </div>
    );
};

const SortArrow = ({ className }) => (
    <div className={className}>
        <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 4.83366L8 1.05588L7.05882 0.166993L4 3.05588L0.941177 0.166992L0 1.05588L4 4.83366Z"
                fill='#B1B1B1'
            />
        </svg>
    </div>
);

export default CompetitiveAnalysisTab;
