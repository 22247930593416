import React from 'react';
import './CreatorDetailsTab.scss';
import cx from 'classnames';
import Api from '../../modules/Api';
import ApiError from '../shared/apiError/ApiError';
import MetricsItem from './MetricsItem';
import ReachIcon from '../shared/ReachIcon/ReachIcon';
import PopUpDialogueBase from '../shared/popUpDialogueBase/PopUpDialogueBase';
import Audience from './Audience2';
import { LoadingDialogue } from '../shared/loadingDialogue/loadingDialogue';
import PublicUserInsightsRequest from './publicUserInsightsRequest';
import { NETWORKS_INSIGHTS_SUPPORTED_BY_IQ_SOCIAL } from '../../constants/lookup-constants';

class CreatorDetailsTab extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            networkSelected: null,
            insightsData: null,
            status: null,
            loading: false,
            showApiError: false,
            apiError: null,
            showNoAudienceData: false,
        };

        this.onInsight = this.onInsight.bind(this);
    }

    onInsight = (item) => {
        this.setState(
            {
                networkSelected: item,
                loading: true,
            },
            async () => {
                try {
                    const { profile } = this.props;
                    let res = null;

                    if (profile.id) {
                        res = await Api.getAudienceInsightsData(item.socialHandle, item.networkType);
                    } else {
                        res = await Api.getPublicSourceAudienceInsightsData(profile.uid, item.networkType);
                    }
                    this.setState({ status: res.status, insightsData: res, showNoAudienceData: false });
                } catch (error) {
                    this.setState({ showNoAudienceData: true });
                } finally {
                    this.setState({ loading: false });
                }
            },
        );
    };

    requestSuccess = (res) => {
        if (res.statusCode === 200) {
            this.setState({ status: 'inProgress' });
        }
    };

    requestFailure = (err) => {
        this.setState({ showApiError: true, apiError: err });
    };

    renderInsights() {
        const { networkSelected: data, insightsData, status, showNoAudienceData } = this.state;
        const { profile } = this.props;
        return (
            <PublicUserInsightsRequest
                data={data}
                insightsData={insightsData}
                status={status}
                closeFunction={() => this.setState({ networkSelected: null })}
                sentRequestSuccess={this.requestSuccess}
                sentRequestFailure={this.requestFailure}
                showNoAudienceData={showNoAudienceData}
                profile={profile}
            />
        );
    }

    render() {
        const { profile } = this.props;
        const { networkSelected, loading, showApiError, apiError } = this.state;

        // Add view rate to public source type tiktok influencers only
        if (!profile.id) {
            profile.userNetworks = profile.userNetworks.map((item) => {
                if (item.networkType === 'tiktok') {
                    return {
                        ...item,
                        viewRate: item.reach && item.followings ? (item.reach / item.followings) * 100 : 0,
                    };
                }
                return item;
            });
        }
        return (
            <div className="CreatorDetailsTabContainer">
                <LoadingDialogue show={loading} container="bottomSectionId" />
                <ApiError
                    show={showApiError}
                    error={apiError}
                    cancelFunction={() => this.setState({ showApiError: false, apiError: null })}
                />
                {networkSelected ? (
                    this.renderInsights()
                ) : (
                    <>
                        {profile.userNetworks &&
                            profile.userNetworks.length > 0 &&
                            profile.userNetworks.map((item, index) => {
                                return (
                                    <MetricsItem
                                        key={`CreatorDetailsTab MetricsItem${index}`}
                                        data={item}
                                        expanded={index === 0}
                                        insights={
                                            NETWORKS_INSIGHTS_SUPPORTED_BY_IQ_SOCIAL.includes(item.networkType) &&
                                            item.socialHandle
                                        }
                                        onInsight={this.onInsight}
                                        isConnectedInfluencer={Boolean(profile.id)}
                                    />
                                );
                            })}
                        <div className="bioContainer">
                            <div className="bioData">
                                Bio
                                {profile.summary && <span>{profile.summary}</span>}
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    }
}

export default CreatorDetailsTab;
