import React from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import Summary from './piecesInMain/Summary';
import RecentPost from './piecesInMain/RecentPost';
import Portfolio from './piecesInMain/Portfolio';
import { fetchFavorites, getListsForInfluencer } from '../../../../store/actions/favoriteActions';
import DisplayTabContent from '../../../influencerProfile/piecesInMain/about/DisplayTabContent';
import { aboutKeyList, moreKeyList } from '../../../../modules/influencer/ProfileFields';
import MetricsItem from '../../../oneInfluencerNew/MetricsItem';
import Api from '../../../../modules/Api';
import ApiError from '../../../shared/apiError/ApiError';
import { LoadingDialogue } from '../../../shared/loadingDialogue/loadingDialogue';
import Audience from '../../../oneInfluencerNew/Audience2';
import PublicUserInsightsRequest from '../../../oneInfluencerNew/publicUserInsightsRequest';
import { NETWORKS_INSIGHTS_SUPPORTED_BY_IQ_SOCIAL } from '../../../../constants/lookup-constants';
import BrandPartnership from '../../../oneInfluencer2022/BrandPartnership';
import './profileMainPanel.scss';

const tabs = [
    { name: 'summary', label: 'Summary' },
    { name: 'rates', label: 'Rates' },
    { name: 'metrics', label: 'Metrics' },
    { name: 'posts', label: 'Recent posts' },
    { name: 'media', label: 'Media kit' },
    // { name: 'history', label: 'History'},
];
class ProfileMainPanel extends React.Component {
    constructor(props, context) {
        super(props.context);

        this.state = {
            summaryMore: false,
            currentTab: 'summary',
            loading: false,
            apiError: undefined,
            showSocialAudienceDetails: undefined,
            brandPartnershipDetails: null,
            showBrandPartnershipDetails: false,
        };

        this.refs = {};
        // this.mediaKit = React.createRef();
    }

    renderedList(list) {
        let userFound = false;
        const { id } = this.props.profile;
        for (const u of list.users) {
            if (u === id) {
                userFound = true;
                break;
            }
        }
        if (!userFound) {
            return null;
        }
        return (
            <div key={list.id} className="basicValue">
                {list.name}
            </div>
        );
    }

    componentDidMount() {
        const { profile } = this.props;
        this.props.fetchFavorites();

        const igNetwork =
            profile && profile.userNetworks && profile.userNetworks.find((un) => un.networkType === 'instagram');
        if (igNetwork) {
            this.setState({ loading: true });
            Api.getPublicInfluencerDetails(profile.uid)
                .then((response) => {
                    if (!response || (!response.interests && !response.brandAffinity)) {
                        this.setState({ brandPartnershipDetails: null, loading: false });
                    } else {
                        this.setState({
                            loading: false,
                            brandPartnershipDetails: {
                                interests: response.interests,
                                brandAffinity: response.brandAffinity,
                                userNetworks: response.userNetworks,
                                socialHandle: igNetwork.socialHandle,
                            },
                        });
                    }
                })
                .catch(() => {
                    this.setState({ brandPartnershipDetails: null, loading: false });
                });
        }
    }

    static getDerivedStateFromProps(props, state) {
        const newState = {};
        if (props.favoriteData) {
            const lists = props.getListsForInfluencer(props.profile.id);
            newState.lists = lists;
        }
        return newState;
    }

    onChangeTabs = (tab) => {
        this.setState({ currentTab: tab });
        if (this[tab]) {
            this[tab].scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    };

    onInsight = (item) => {
        this.setState(
            {
                loading: true,
                selectedSocialItem: undefined,
            },
            () => {
                const { profile } = this.props;
                if (profile.id) {
                    Api.getAudienceInsightsData(item.socialHandle, item.networkType, profile.id)
                        .then((res) => {
                            this.setState({ loading: false, showSocialAudienceDetails: res, selectedSocialItem: item });
                        })
                        .catch((err) => {
                            this.setState({ loading: false, apiError: err });
                        });
                } else {
                    Api.getPublicSourceAudienceInsightsData(profile.uid, item.networkType)
                        .then((res) => {
                            this.setState({ loading: false, showSocialAudienceDetails: res, selectedSocialItem: item });
                        })
                        .catch((err) => {
                            this.setState({ loading: false, apiError: err });
                        });
                }
            },
        );
    };

    requestSuccess = (res) => {
        const { showSocialAudienceDetails } = this.state;
        if (res.statusCode === 200 && showSocialAudienceDetails) {
            showSocialAudienceDetails.status = 'inProgress';
            this.setState({ showSocialAudienceDetails });
        }
    };

    requestFailure = (err) => {
        this.setState({ apiError: err });
    };

    render() {
        const {
            summaryMore,
            apiError,
            showSocialAudienceDetails,
            loading,
            selectedSocialItem,
            brandPartnershipDetails,
            showBrandPartnershipDetails,
        } = this.state;
        const { profile, isPublicInfluencer } = this.props;
        if (!profile) {
            return null;
        }

        const keyLists = {
            about: ['age_readonly'].concat(aboutKeyList),
            more: moreKeyList,
        };

        let favoriteLists = null;
        const lists = this.props.favoriteData;
        if (Array.isArray(lists)) {
            if (lists.length === 0) {
                favoriteLists = <div className="noBrand">N / A</div>;
            } else {
                favoriteLists = [];
                for (const list of lists) {
                    favoriteLists.push(this.renderedList(list));
                }
            }
        }
        let totalReach = 0;

        if (Array.isArray(profile.userNetworks) && profile.userNetworks.length > 0) {
            totalReach = profile.userNetworks.map((un) => Number(un.reach)).reduce((a, b) => a + b);
        }
        return (
            <div className="profileMainPanel" id="profileMainPanel">
                {/* <div className="navigationContainer">
                    <div className="tabsContainer">
                        {tabs.map(tab => {
                            return (
                                <div
                                    onClick={() => this.onChangeTabs(tab.name)}
                                    className={`tab ${currentTab === tab.name && 'active'}`}
                                >
                                    {tab.label}
                                </div>
                            )
                        })}
                    </div>
                </div> */}
                <ApiError
                    show={!!apiError}
                    error={apiError}
                    cancelFunction={() => this.setState({ apiError: undefined })}
                />
                <LoadingDialogue show={loading} container="profileMainPanel" />
                {showBrandPartnershipDetails && (
                    <Modal
                        isOpen={showBrandPartnershipDetails}
                        overlayClassName="modalDialog"
                        className="dialog scrollAuto"
                        onRequestClose={() => this.setState({ showBrandPartnershipDetails: false })}
                    >
                        <div className="backMenu" onClick={() => this.setState({ showBrandPartnershipDetails: false })}>
                            <img src="/images/icon-arrow-left.svg" alt="back" />
                            CREATOR METRICS
                        </div>
                        <BrandPartnership
                            instagramHandle={brandPartnershipDetails.socialHandle}
                            interests={brandPartnershipDetails.interests}
                            brandAffinity={brandPartnershipDetails.brandAffinity}
                        />
                    </Modal>
                )}
                {showSocialAudienceDetails && (
                    <Modal
                        isOpen={!!showSocialAudienceDetails}
                        overlayClassName="modalDialog"
                        className="dialog scrollAuto"
                        onRequestClose={() =>
                            this.setState({ showSocialAudienceDetails: undefined, selectedSocialItem: undefined })
                        }
                    >
                        {profile.type === 'PUBLIC_SOCIAL_USER' ? (
                            <PublicUserInsightsRequest
                                data={selectedSocialItem}
                                insightsData={showSocialAudienceDetails}
                                status={showSocialAudienceDetails.status}
                                closeFunction={() =>
                                    this.setState({
                                        showSocialAudienceDetails: undefined,
                                        selectedSocialItem: undefined,
                                    })
                                }
                                sentRequestSuccess={this.requestSuccess}
                                sentRequestFailure={this.requestFailure}
                            />
                        ) : (
                            <Audience
                                audience={showSocialAudienceDetails.insightsData}
                                label="CREATOR METRICS"
                                type={
                                    showSocialAudienceDetails.networkType
                                        ? showSocialAudienceDetails.networkType.toLowerCase()
                                        : ''
                                }
                                handle={
                                    showSocialAudienceDetails.socialHandle
                                        ? `@${showSocialAudienceDetails.socialHandle}`
                                        : ''
                                }
                                goBack={() =>
                                    this.setState({
                                        showSocialAudienceDetails: undefined,
                                        selectedSocialItem: undefined,
                                    })
                                }
                                status={showSocialAudienceDetails.status}
                                totalFollowers={totalReach}
                            />
                        )}
                    </Modal>
                )}
                <div className="influencerProfileContainer">
                    <div
                        className="influencerProfileBox"
                        ref={(ref) => {
                            this.summary = ref;
                        }}
                    >
                        <div className="pieceTitle">{isPublicInfluencer ? 'Bio' : 'Summary'}</div>
                        <div id="profileSummaryTop">
                            <Summary profile={profile} />
                        </div>
                        {!isPublicInfluencer && (
                            <>
                                <hr />
                                <div className="pieceTitle">About</div>
                                <div className="profileAbout">
                                    <DisplayTabContent show keys={keyLists.about} profile={profile} />
                                </div>
                                {summaryMore && (
                                    <>
                                        <hr />
                                        <div className="pieceTitle">More details</div>
                                        <div className="profileAbout">
                                            <DisplayTabContent show keys={keyLists.more} profile={profile} />
                                        </div>
                                    </>
                                )}
                                <div
                                    className="moreDetails"
                                    onClick={() => this.setState({ summaryMore: !summaryMore })}
                                >
                                    {summaryMore ? 'Less details' : 'More details'}
                                </div>
                            </>
                        )}
                    </div>
                    <div className="influencerProfileBox metricsBox" ref={(ref) => (this.metrics = ref)}>
                        {profile.userNetworks &&
                            profile.userNetworks.length > 0 &&
                            profile.userNetworks.map((item, index) => {
                                return (
                                    <MetricsItem
                                        data={item}
                                        expanded={index === 0}
                                        insights={
                                            NETWORKS_INSIGHTS_SUPPORTED_BY_IQ_SOCIAL.includes(item.networkType) &&
                                            item.socialHandle
                                        }
                                        onBrandPartnershipInsights={
                                            brandPartnershipDetails
                                                ? () => this.setState({ showBrandPartnershipDetails: true })
                                                : null
                                        }
                                        onInsight={this.onInsight}
                                        key={index}
                                        isConnectedInfluencer={Boolean(profile.id)}
                                    />
                                );
                            })}
                    </div>
                    <div className="influencerProfileBox 123" ref={(ref) => (this.posts = ref)}>
                        <div className="pieceTitle" id="mediaLink">
                            {profile.id ? 'Recent' : 'Top'} Posts
                        </div>
                        <RecentPost
                            profile={this.props.profile}
                            isPublicInfluencer={isPublicInfluencer}
                            isTopPost={!Boolean(this.props.profile.id)}
                        />
                    </div>
                    {!isPublicInfluencer && (
                        <div className="influencerProfileBox" ref={(ref) => (this.media = ref)}>
                            <div className="pieceTitle" id="portfolioLink">
                                Media kit
                            </div>
                            <div>
                                <Portfolio profile={this.props.profile} />
                            </div>
                        </div>
                    )}
                    {/* <div className="influencerProfileBox" ref={ref => this['history'] = ref}>
                        <div className="pieceTitle" id="historyLink">
                            History
                        </div>
                        <div className="pieceNoContent">No history with this creator</div>
                    </div> */}
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getLookupFilters: () => {
            dispatch(startLoadingLookupFilters());
        },
        getLookupTables: () => {
            dispatch(startLoadingLookupTables());
        },
        fetchFavorites: () => dispatch(fetchFavorites()),
        getListsForInfluencer: (id) => getListsForInfluencer(id),
    };
};

const mapStateToProps = (state) => {
    return {
        favoriteData: state.FAVORITE_REDUCER.favoriteData,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileMainPanel);
