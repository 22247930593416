import React from 'react';
import PropTypes from 'prop-types';
import InfluencerCard from './InfluencerCard/InfluencerCard';
import './campaignInfluencers.scss';

const getAssignmentTitle = (users) => {
    if (!users || !users.length) {
        return '';
    }

    return `#1 ${users[0].assignmentName}`;
};

export default function Influencers(props) {
    const {
        campaignAssignmentId,
        campaignId,
        campaign,
        notReady,
        users,
        profile,
        permission,
        handleOpenContractStatus,
        handleOpenContractUpload,
        startPropose,
        checkboxChangeHandler,
        selectedInfluencers,
        removeUserFromAssignment,
        player,
        signerList,
        onEditing = () => null,
        symbol,
        updateUserFromAssignment,
        handleOpenOtherDocuments
    } = props;
    const title = getAssignmentTitle(users);
    users.sort((a,b) => {
        if (!a.user.isPublic && !b.user.isPublic) {
            return a.user.user.firstname.localeCompare(b.user.user.firstname);
        } else if(a.user.isPublic && !b.user.isPublic) {
            return 1;
        } else if (!a.user.isPublic && b.user.isPublic) {
            return -1;
        } else {
            return a.user.creatorName.localeCompare(b.user.creatorName);
        }
    })
    return (
        <div className="influencers">
            {title &&
            <div className="assignmentTitle" style={{ opacity: notReady ? 0.5 : 1 }}>
                {title} &nbsp;
                {/* below is a strange chunk of code. The whole influencers block was moved here from CampaignInfluencers */}
                {/* and according to it's logic - the chunk below won't be render in any case. Probably the fix is required */}
                {notReady &&
                <span> No Flights Added</span>
                }
            </div>
            }
            {
                users.map(user => {
                    const {
                        user: {
                            id: userId,
                            user: {
                                wformUploaded
                            }
                        },
                        assignment,
                        assignmentTypes,
                        contractStatus,
                        negotiations,
                        assignmentName
                    } = user;
                    const isSelected = !!selectedInfluencers.find(({ id }) => id === userId);
                    return <InfluencerCard
                        player={player}
                        key={userId}
                        campaignId={campaignId}
                        campaign={campaign}
                        assignmentId={assignment}
                        assignmentTypes={assignmentTypes}
                        assignmentName={assignmentName}
                        campaignAssignmentId={campaignAssignmentId}
                        contractStatus={contractStatus}
                        details={user.user}
                        profile={profile}
                        negotiations={negotiations}
                        permission={permission}
                        handleOpenContractStatus={handleOpenContractStatus}
                        handleOpenContractUpload={handleOpenContractUpload}
                        handleOpenOtherDocuments={handleOpenOtherDocuments}
                        startPropose={startPropose}
                        isSelected={isSelected}
                        selectedInfluencers={selectedInfluencers}
                        checkboxChangeHandler={checkboxChangeHandler}
                        removeUserFromAssignment={removeUserFromAssignment}
                        updateUserFromAssignment={updateUserFromAssignment}
                        wformUploaded={wformUploaded}
                        signerList={signerList}
                        handleEditing={() => onEditing(user)}
                        symbol={symbol}
                    />
                })
            }
        </div>
    );
}

Influencers.propTypes = {
    campaignAssignmentId: PropTypes.string,
    campaignId: PropTypes.number,
    notReady: PropTypes.string,
    users: PropTypes.array,
    permission: PropTypes.bool,
    handleOpenContractStatus: PropTypes.func,
    handleOpenContractUpload: PropTypes.func,
    handleOpenOtherDocuments: PropTypes.func,
    startPropose: PropTypes.func,
};
