import React, { useState } from 'react';
import Modal from 'react-modal';
import Api from '../../../../../modules/Api';
import ApiError from '../../../../shared/apiError/ApiError';
import TranslationTooltip from '../TranslationTooltip/TranslationTooltip';
import { getLanguageName, channelIcons, TextContainer } from '../helpers';
import MultiSelect from './MultiSelect/MultiSelect';
import CreatableSelect from './CreatableSelect/CreatableSelect';
import './editCommentModal.scss';

const RELEVANCY_TAG_LIMIT = 10;
const sentimentValues = ['POSITIVE', 'NEUTRAL', 'NEGATIVE'];

function EditCommentModal({ isOpen, handleClose, comment, campaignId, updateData, relevancyOptions }) {
    const [apiError, setApiError] = useState(null);

    const filteredRelevancyOptions = relevancyOptions.filter((option) => option.type !== 'TOPIC');
    const filteredTopicOptions = relevancyOptions.filter((option) => option.type === 'TOPIC');

    return (
        <>
            <Modal overlayClassName="modalDialog" className="edit-comment-modal" isOpen={isOpen}>
                <ModalForm
                    handleClose={handleClose}
                    comment={comment}
                    campaignId={campaignId}
                    updateData={updateData}
                    handleError={(error) => setApiError(error)}
                    relevancyOptions={filteredRelevancyOptions}
                    topicOptions={filteredTopicOptions}
                />
            </Modal>
            <ApiError show={!!apiError} error={apiError} cancelFunction={() => setShowError(false)} />
        </>
    );
}

function ModalForm({ handleClose, comment, campaignId, updateData, handleError, relevancyOptions, topicOptions }) {
    const [isLoading, setIsLoading] = useState(false);

    const predictedRelevancyValues = [
        ...(comment?.predictedCategories || []).map((category) => ({
            label: category,
            value: category,
            type: 'CATEGORY',
        })),
        ...(comment?.predictedProducts || []).map((product) => ({ label: product, value: product, type: 'PRODUCT' })),
    ];

    const predictedTopicValues = (comment?.predictedTopics || []).map((topic) => ({
        label: topic,
        value: topic,
        type: 'TOPIC',
    }));

    const activeRelevancyValues = [
        ...(comment?.categories || []).map((category) => ({ label: category, value: category, type: 'CATEGORY' })),
        ...(comment?.products || []).map((product) => ({ label: product, value: product, type: 'PRODUCT' })),
    ].map((option) => ({
        ...option,
        selected: true,
    }));

    const activeTopicValues = (comment?.topics || [])
        .map((topic) => ({ label: topic, value: topic, type: 'TOPIC' }))
        .map((option) => ({
            ...option,
            selected: true,
        }));

    const getDefaultRelevancyOptions = () => {
        const allOptions = [...predictedRelevancyValues, ...relevancyOptions];
        const values = allOptions.map((option) => option.value);
        const filtered = allOptions
            .filter((option, idx) => !values.includes(option.value, idx + 1))
            .map((option) =>
                activeRelevancyValues.find(
                    (activeOption) => activeOption.type === option.type && activeOption.value === option.value,
                )
                    ? { ...option, selected: true }
                    : { ...option, selected: false },
            );
        return filtered;
    };

    const getDefaultTopicOptions = () => {
        const allOptions = [...predictedTopicValues, ...topicOptions];
        const values = allOptions.map((option) => option.value);
        const filtered = allOptions
            .filter((option, idx) => !values.includes(option.value, idx + 1))
            .map((option) =>
                activeTopicValues.find((activeOption) => activeOption.value === option.value)
                    ? { ...option, selected: true }
                    : { ...option, selected: false },
            );
        return filtered;
    };

    const [allRelevancyValues, setAllRelevancyValues] = useState(getDefaultRelevancyOptions());
    const [allTopicValues, setAllTopicValues] = useState(getDefaultTopicOptions());

    const handleSubmit = (event) => {
        event.preventDefault();
        const newSentiment = event.target.elements.sentiment.value;
        const relevancyValues = allRelevancyValues.filter((option) => option.selected);
        const topicValues = allTopicValues.filter((option) => option.selected);

        const topics = topicValues.map((option) => option.value);
        const [categories, products] = ['CATEGORY', 'PRODUCT'].map((type) =>
            relevancyValues.filter((option) => option.type === type).map((option) => option.value),
        );

        Api.updateCommentSentiment({
            campaignId,
            commentId: comment.id,
            sentiment: newSentiment,
            topics,
            categories,
            products,
        })
            .then(() => {
                setIsLoading(true);
                updateData();
            })
            .catch((error) => handleError(error))
            .finally(() => {
                setIsLoading(false);
                handleClose();
            });
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="modal-title">Edit Relevancy & Sentiment</div>
            <div className="details-section">
                <InfoSection comment={comment} />
                <RelevancySection
                    originalValues={predictedRelevancyValues}
                    allValues={allRelevancyValues}
                    setAllValues={setAllRelevancyValues}
                />
                <TopicsSection
                    originalValues={predictedTopicValues}
                    allValues={allTopicValues}
                    setAllValues={setAllTopicValues}
                />
                <SentimentSection comment={comment} />
            </div>
            <div className="modal-footer">
                <button className="cancel-btn" onClick={handleClose}>
                    Cancel
                </button>
                <button className="update-btn" type="submit" disabled={isLoading}>
                    Update
                </button>
            </div>
        </form>
    );
}

function InfoSection({ comment }) {
    return (
        <div className="post-info-section">
            <div className="post-header">
                <div className="post-channel">
                    <img src={channelIcons[comment.platform]} />
                    {comment.platform.toLowerCase()}
                </div>
                <div className="post-language-section">
                    Language:
                    <span className="post-language">{getLanguageName(comment.detectedTextLanguage) ?? '-'}</span>
                </div>
            </div>
            <div className="post-text">
                <TextContainer comment={comment} showOriginal />
            </div>
            <div className="post-footer">
                <div className="post-likes">{`${comment.likes ?? '-'} likes`}</div>
                {!!comment.replies?.length && (
                    <div className="post-replies">{`${comment.replies.length ?? '-'} replies`}</div>
                )}
                {!!comment.translatedText && (
                    <TranslationTooltip text={comment.translatedText}>
                        <div className="post-translation">See translation</div>
                    </TranslationTooltip>
                )}
                <a className="post-link" href={comment.url} target="_blank">
                    <img src="/images/link.svg" />
                    View Post
                </a>
            </div>
        </div>
    );
}

function RelevancySection({ originalValues, allValues, setAllValues }) {
    const activeValues = allValues.filter((option) => option.selected);
    const sortedValues = originalValues.sort((a, b) => (b.selected ? 1 : a.selected ? -1 : 0));

    const handleValuesChange = (newValues) => {
        const selectedValues = newValues.map((option) => ({ ...option, selected: true }));
        setAllValues(selectedValues);
    };

    return (
        <div className="relevancy-section">
            <div className="relevancy-title">Relevancy</div>
            <div className="original-relevancy-title">Original relevancy tags</div>
            <div className="relevancy-tags-wrapper">
                {sortedValues.length ? (
                    sortedValues.map((option) => (
                        <div key={option.value} className="relevancy-tag disabled-tag">
                            {option.label}
                        </div>
                    ))
                ) : (
                    <div className="empty-relevancy">No relevancy options found.</div>
                )}
            </div>
            <div className="relevancy-override-title">
                Relevancy tags override
                <span className="required-field">*Required</span>
            </div>
            <CreatableSelect
                name="relevancy"
                values={activeValues}
                setValues={handleValuesChange}
                maxValues={RELEVANCY_TAG_LIMIT}
            />
        </div>
    );
}

function TopicsSection({ originalValues, allValues, setAllValues }) {
    const activeValues = allValues.filter((option) => option.selected);
    const nonActiveValues = allValues.filter((option) => !option.selected);

    const sortedValues = originalValues.sort((a, b) => (b.selected ? 1 : a.selected ? -1 : 0));

    const handleValuesChange = (newValues) => {
        const selectedValues = newValues.map((option) => ({ ...option, selected: true }));
        const updatedValues = allValues.map(
            (option) =>
                selectedValues.find((newVal) => newVal.value === option.value) ?? { ...option, selected: false },
        );
        setAllValues(updatedValues);
    };

    return (
        <div className="relevancy-section">
            <div className="relevancy-title">Topics</div>
            <div className="original-relevancy-title">Original topic tags</div>
            <div className="relevancy-tags-wrapper">
                {sortedValues.length ? (
                    sortedValues.map((option) => (
                        <div key={option.value} className="relevancy-tag disabled-tag">
                            {option.label}
                        </div>
                    ))
                ) : (
                    <div className="empty-relevancy">No topic options found.</div>
                )}
            </div>
            <div className="relevancy-override-title">
                Topic tags override
                <span className="required-field">*Required</span>
            </div>
            <MultiSelect
                name="relevancy"
                values={activeValues}
                options={nonActiveValues}
                setValues={handleValuesChange}
            />
        </div>
    );
}

function SentimentSection({ comment }) {
    let { feedback, sentiment } = comment;
    const dafaultSentiment = feedback || sentiment;

    return (
        <div className="sentiment-section">
            <div className="sentiment-title">Sentiment</div>
            <fieldset>
                <ul className="sentiment-selection">
                    {sentimentValues.map((option) => (
                        <li key={option}>
                            <input
                                type="radio"
                                id={option}
                                name="sentiment"
                                value={option}
                                defaultChecked={dafaultSentiment === option}
                            />
                            <label htmlFor={option} className="option-label">
                                {option.toLowerCase()}
                            </label>
                            {sentiment === option && <span className="orig-selection">Original Selection</span>}
                        </li>
                    ))}
                </ul>
            </fieldset>
        </div>
    );
}

export default EditCommentModal;
