import React, { Component } from 'react';
import { debounce } from 'lodash';
import cx from 'classnames';
import './style.scss';
import TopNavigation from '../shared/topNavigation/TopNavigation';
import PlansItem from './PlansItem';
import Api from '../../modules/Api';
import AddModal from './Modals/AddModal';
import PaginationNew from '../shared/pagination/PaginationNew';
import PleaseWait from '../shared/pleaseWait/PleaseWaitWhite';
import { SUPER_ADMIN, SUPER_ADMIN_STR } from '../../constants/authorities';
import ProfileHelper from '../../modules/utils/ProfileHelper';
import ApiError from '../shared/apiError/ApiError';


export default class Plans extends Component {
    constructor(props) {
        super(props);

        this.state = {
            plans: null,
            isAddModalOpen: false,
            filters: {
                name: false,
                totalBrands: false,
                createdBy: false,
                createdDate: true,
            },
            selectedSortBy: 'createdDate',
            selectedPlan: null,
            showArchived: false,
            page: 1,
            totalCount: 0,
            pageSize: 10,
            search: null,
            shouldEdit: false,
            isArchived: false,
            profile: null,
            loading: true,
            apiError: null,
        };
    }

    getPlans = async () => {
        try {
            const fakeData = [{logo: null, brand: 'olay', name: 'Winter Strong Hair Plan', creators: 25, tags: ['hair of the day'], 
            createdBy: 'Jean Obrien', createdOn: 'Feb 28', modifiedOn: 'Feb 28'}]
            this.setState({
                loading: false,
                plans: fakeData,
                page: 1,
                totalCount: 2,
            });
        } catch (error) {
            console.log(error);
        }
    }

    componentDidMount = async () => {
        const {
            page,
            pageSize,
        } = this.state;

        const profile = await ProfileHelper.getCurrentLoginUserProfile();
        await this.getPlans();

        this.setState({ profile });
    }

    handleModal = () => {
        this.setState({
            isAddModalOpen: !this.state.isAddModalOpen,
        });
    }

    setStateForFilters = async (filterName) => {
        const {
            page,
            pageSize,
            filters,
            isArchived,
            search,
        } = this.state;

        this.setState({
            filters: {
                ...filters,
                [filterName]: !filters[filterName],
            },
            selectedSortBy: filterName,
            loading: true,
        }, async() => {
            await this.getPlans(
                page,
                pageSize,
                [filterName, !filters[filterName]],
                isArchived,
                search
            );
        });
    }

    handleFiltersChange = async (param) => {
        await this.setStateForFilters(param);
    }

    closeAddModal = () => {
        this.setState({
            isAddModalOpen: false,
            selectedPlan: null,
            shouldEdit: false,
        });
    }

    acceptAddModal = async (data) => {
        const {
            shouldEdit,
            selectedPlan,
            plans,
            page,
            pageSize,
            selectedSortBy,
            filters,
            isArchived,
            search,
        } = this.state;
        if (!shouldEdit) {
            try {
                const payLoad = {
                    name: data.advertiserName
                }
                if (data.logo) {
                    const response = await Api.uploadResource(data.logo, true);
                    if (response && response.url) {
                        payLoad.logoUrl = response.url
                    }
                }
                await Api.addAdvertiser(payLoad);
                this.setState({
                    isAddModalOpen: false,
                    selectAdvertiser: null,
                }, async() => {
                    await this.getPlans(
                        page,
                        pageSize,
                        [selectedSortBy, filters[selectedSortBy]],
                        isArchived,
                        search
                    );
                });
            } catch (error) {
                console.log(error);
                this.setState({
                    loading: false,
                    isAddModalOpen: false,
                    selectAdvertiser: null,
                });
                if (error && error.originalMessage) {
                    this.setState({ apiError: error.originalMessage})
                }
            }
        } else {
            this.setState({
                isAddModalOpen: false,
                loading: true,
            });
            try {
                const name = data.advertiserName;
                let logoUrl = '';
                let response = null;

                if (!data.logo) {
                    logoUrl = selectedPlan.logoUrl;
                } else {
                    response = await Api.uploadResource(data.logo, true);
                    logoUrl = response.url;
                }

                const plan = await Api.updateAdvertiser(
                    { name, logoUrl },                    
                    selectedPlan.id
                );
                const index = plans.findIndex((adv) => adv.id === selectedPlan.id);
                const updatedList = [...plans];
                updatedList[index] = plan;
                this.setState({
                    shouldEdit: false,
                    isAddModalOpen: false,
                    selectedPlan: null,
                    plans: [...updatedList],
                    loading: false,
                });
                
            } catch (error) {
                console.log(error);
                this.setState({
                    shouldEdit: false,
                    isAddModalOpen: false,
                    selectedPlan: null,
                    loading: false,
                });
                if (error && error.originalMessage) {
                    this.setState({ apiError: error.originalMessage})
                }
            }
        }
    };

    onSearch = debounce(async (value) => {
        const {
            pageSize,
            filters,
            selectedSortBy,
            isArchived,
        } = this.state;
        this.setState({
            search: value,
            loading: true,
        }, async () => {
            await this.getPlans(
                1,
                pageSize,
                [selectedSortBy, filters[selectedSortBy]],
                isArchived,
                value
            );
        });
    }, 500);

    handleEditOpen = (advertiser) => {
        this.setState({
            selectedPlan: advertiser,
            isAddModalOpen: true,
            shouldEdit: true,
        });
    }

    handleAddNew = () => {
        this.setState({
            isAddModalOpen: true,
            shouldEdit: false,
        })
    }

    archiveAdvertiser = async (id) => {
        const { plans } = this.state;
        await Api.archiveAdvertiser(id);
        const index = plans.findIndex(({ id: advertiserId }) => advertiserId === id);
        const newAdvertisers = [...plans];
        newAdvertisers.splice(index, 1);

        this.setState({
            plans: [...plans],
        });
    }

    moveToBrands = (id) => {
        this.props.history.push(`/adminBrands?advertiserId=${id}`);
    }

    render = () => {
        const { plans, isAddModalOpen, filters, selectedPlan, showArchived, isArchived }=this.state;
        const{ page, totalCount, pageSize, shouldEdit, profile, loading, selectedSortBy } = this.state;

        return (
            <>
                <ApiError
                    show={!!this.state.apiError}
                    error={this.state.apiError}
                    cancelFunction={() => this.setState({apiError: null})}
                />
                <div className="planPage">
                    <TopNavigation current="/plans" />
                    <div className="blue-header admin-header">
                        <div className="contentDiv">
                            <div className="controlContainer">
                                <div className="left-container">
                                    <h1>Plans</h1>
                                </div>
                                <div className="right-container">
                                    <div className="userSearch">
                                        <img src="/images/ic-mag.svg" />
                                        <input
                                            className="search"
                                            type="text"
                                            placeholder="Search Plan"
                                            onChange={(event) => this.onSearch(event.target.value)}
                                        />
                                    </div>
                                    <button
                                        onClick={() => this.handleAddNew()}
                                        className="invite-button"
                                    >
                                        <img src="/images/whitePlus.svg" />
                                        New Plans
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="all-users-page advertisers-list">
                        <PleaseWait
                            show={loading}
                            top={200}
                        />
                        <div className="contentDiv advertiserdDiv">
                            <table className="data-table-plans">
                                <tbody>
                                    <tr className="table-header">
                                        <th
                                            className="user-info-holder"
                                            onClick={() => this.handleFiltersChange('brand')}
                                        >
                                            Brand
                                            <img src="/images/ic_down14x14.svg" className={cx({up: !filters.name, no: selectedSortBy !== 'brand'})} />
                                        </th>
                                        <th
                                            className="names"
                                            onClick={() => this.handleFiltersChange('name')}
                                        >
                                            Name
                                            <img src="/images/ic_down14x14.svg" className={cx({up: !filters.totalBrands, no: selectedSortBy !== 'name'})} />
                                        </th>
                                        <th
                                            className="creators"
                                            onClick={() => this.handleFiltersChange('creator')}
                                        >
                                            Creators
                                            <img src="/images/ic_down14x14.svg" className={cx({up: !filters.totalBrands, no: selectedSortBy !== 'creator'})} />
                                        </th>
                                        <th
                                            className="tags"
                                            onClick={() => this.handleFiltersChange('tags')}
                                        >
                                            tags
                                            <img src="/images/ic_down14x14.svg" className={cx({up: !filters.totalBrands, no: selectedSortBy !== 'tags'})} />
                                        </th>
                                        <th
                                            className="role"
                                            onClick={() => this.handleFiltersChange('createdBy')}
                                        >
                                            Created By
                                            <img src="/images/ic_down14x14.svg" className={cx({up: !filters.createdBy, no: selectedSortBy !== 'createdBy'})} />
                                        </th>
                                        <th
                                            className="date"
                                            onClick={() => this.handleFiltersChange('createdDate')}
                                        >
                                            Created On
                                            <img src="/images/ic_down14x14.svg" className={cx({up: !filters.createdDate, no: selectedSortBy !== 'createdDate'})} />
                                        </th>
                                        <th
                                            className="date"
                                            onClick={() => this.handleFiltersChange('modifedDate')}
                                        >
                                            Modified on
                                            <img src="/images/ic_down14x14.svg" className={cx({up: !filters.createdDate, no: selectedSortBy !== 'modifedDate'})} />
                                        </th>
                                        <th className="actions"/>
                                    </tr>
                                    {
                                        plans && plans.map((plan) => (
                                            <PlansItem
                                                key={plan.id}
                                                plan={plan}
                                                handleEditOpen={this.handleEditOpen}
                                                handleArchive={this.archiveAdvertiser}
                                                profile={profile}
                                                moveToBrandsWithFilter={this.moveToBrands}
                                                isArchived={isArchived}
                                            />
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <AddModal
                    isOpen={isAddModalOpen}
                    onCancel={this.closeAddModal}
                    onAccept={this.acceptAddModal}
                    shouldEdit={shouldEdit}
                    plan={selectedPlan}
                />
            </>
        )
    }
}
