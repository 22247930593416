import React from 'react'
import FileSaver from 'file-saver'
import Api from "../../../modules/Api"
import './resourcesDownload.scss'
import PleaseWaitPartial from '../../shared/pleaseWait/PleaseWaitPartial'
import Format from '../../../modules/utils/Format';
// props :
//  - resources
const acceptedExtensions  = ["pdf", "png", "jpg", "jpeg", "doc", "docx"];
let docIcon = '/images/doctypeIcons/ic-doc.svg';
let pdfIcon = '/images/doctypeIcons/ic-pdf.svg';
let imageIcon = '/images/doctypeIcons/ic-png.svg';
const typeDetails = {
    'doc' : { icon: docIcon},
    'docx' : { icon: docIcon},
    'pdf' : { icon: pdfIcon}
}
for (let ext of acceptedExtensions)  {
    if (!typeDetails[ext]) {
        typeDetails[ext] = { icon: imageIcon, image : true}
    }
}
export default class ResourceDownload extends React.Component {

    constructor (props, context) {
        super(props, context);
        this.state = {
            loading: false,
            uploadProgressPercentage: null
        }
    }
    picForFile = (filename) => {
        let pic = imageIcon;
        let namePieces = filename.split('.');
        let ext = namePieces[namePieces.length -1];
        if (typeDetails[ext]) {
            pic = typeDetails[ext].icon;
        }
        return pic;
    }
    renderResource(resource,assignmentId) {
        let self = this;

        let getExisting = function () {
            self.setState({loading: true});
            const updateFileProgress = (progressPercentage) => {
                if (progressPercentage > 0) {
                    self.setState({uploadProgressPercentage: progressPercentage})
                }
            };
    
            const updateFileFinished = (response) => {
                // handle old data with no name & extensions
                const ext = response.data && response.data.type ? Format.mimeToExt(response.data.type) : '';
                const fileName = resource.name==='File' ? `File${ext}` : resource.name;
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName); // or any other extension
                document.body.appendChild(link);
                link.click();
                self.setState({ uploadProgressPercentage: null, loading: false });
            };
    
            updateFileProgress(1);
            // Call gradual download
            Api.getS3FileGradually(resource.url,
                (progressPercentage) => updateFileProgress(progressPercentage),
                (response) => updateFileFinished(response));

        }

        let pic = this.picForFile(resource.name);

        return (
                <div key={resource.resourceId} className="existingResource" onClick={getExisting}>
                    <div className="icon">
                        <img src={pic}/>
                    </div>
                    <div  className="name" title={resource.name }>
                        { resource.name }
                    </div>
                </div>
            )
    }
    render () {
        let resourceslist = [];
        if (Array.isArray(this.props.resources) && this.props.resources.length>0) {
            this.props.resources.forEach( resource => {
                return resourceslist.push(this.renderResource(resource, this.props.assignmentId));
            });
        } 

        return (
            <div className="resourceList" ref={(e=>this.container=e)}>
                <PleaseWaitPartial
                    show={this.state.loading}
                    container={this.container}
                    progressPercentage={this.state.uploadProgressPercentage}
                    onCancel={this.state.onUploadCancel}
                />
                { resourceslist.length>0 && resourceslist }
            </div>
        );
    }
}
