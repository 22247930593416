import React, { Component } from 'react';
import Modal from 'react-modal';

export default class AddModal extends Component {
    state = {
        advertiserName: '',
        logo: null,
        imagePreview: null,
    };

    componentDidUpdate(prevProps) {
        if (this.props.advertiser &&
            (!prevProps.advertiser || JSON.stringify(this.props.advertiser) !== JSON.stringify(prevProps.advertiser)) &&
            this.state.advertiserName !== this.props.advertiser.name) {
            this.setState({
                advertiserName: this.props.advertiser.name || '',
            });
        }
    }

    setAdvertiserName = (event) => {
        const { value } = event.target;

        this.setState({
            advertiserName: value,
        });
    }

    handleFile = ({ target }) => {
        const { files } = target;
        const selectedFile = files[0];
        if (!selectedFile) return;

        const reader = new FileReader();
        reader.readAsDataURL(selectedFile);
        reader.onload = () => {
            const { result } = reader;
            const logoObj = selectedFile;
            logoObj.data = result;
            this.setState({
                imagePreview: result,
                logo: logoObj
            });
        }
    }

    clearState = () => {
        this.setState({
            advertiserName: null,
            logo: null,
            imagePreview: null,
        });
    }

    close = () => {
        const { onCancel } = this.props;
        this.clearState();

        onCancel();
    }

    accept = () => {
        const { onAccept, advertiser } = this.props;
        const { advertiserName, logo } = this.state;
        const data = {};
        if (logo)
            data.logo = logo;
        if (!advertiser || advertiser.name !== advertiserName)
            data.advertiserName = advertiserName;

        this.clearState();
        if (typeof onAccept === 'function') {
            onAccept(data);
        }
    }

    render() {
        const {
            isOpen,
            advertiser,
        } = this.props;
        const { imagePreview, advertiserName } = this.state;

        const disabledButton = (!advertiserName || advertiserName.trim().length === 0);
        const clsName = `button2 proceed controlButton${  disabledButton ? " disabled" : ""}`;

        return (
            <Modal
                overlayClassName="modalDialog"
                className="dialog create-advertisers-modal"
                isOpen={isOpen}
            >
                <div className="container">
                    <div className="title">
                        {advertiser ? 'Update' : 'Create'} New Advertiser
                    </div>
                    <div className="content">
                        <div className="photo-holder">
                            <label htmlFor="logo">
                                <div className="photoContainer">
                                    <div>
                                        <div className="fileInput">
                                            { imagePreview || (advertiser && advertiser.logoUrl) ? (
                                                <img
                                                    src={imagePreview || advertiser.logoUrl}
                                                    alt='preview'
                                                />
                                            ) : '' }
                                        </div>
                                    </div>
                                    <div className="plus-icon">+</div>
                                </div>
                            </label>
                            <input
                                type="file"
                                onChange={this.handleFile}
                                id="logo"
                                accept="image/png, image/jpeg, image/gif, image/svg+xml"
                                style={{display: 'none'}}
                            />
                            <div className="label">
                                Logo
                            </div>
                        </div>
                        <div className="inputArea">
                            <div className="input-holder">
                                <div className="label">Advertiser Name</div>
                                <input
                                    type="text"
                                    defaultValue={advertiserName}
                                    onChange={this.setAdvertiserName}
                                    maxLength={255}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <button
                        onClick={this.close}
                        className="button2 cancel cancelButton"
                    >
                        Сancel
                    </button>
                    <button
                        onClick={this.accept}
                        className={clsName}
                        disabled={disabledButton}
                    >
                        { advertiser ? 'Update' : 'Create' }
                    </button>
                </div>
            </Modal>
        )
    }
}
