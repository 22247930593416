import React from 'react'
import TextInput from './textInput/TextInput'
import Lookup from '../../../modules/Lookup'

// props
//  - array of strings
//  - changeFunction

const max200 = (txt) => {
    return Lookup.wordCount(txt) <= 200
}

export default class Checklist extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.add = this.add.bind(this);
        this.renderedItem = this.renderedItem.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.delegateChange = this.delegateChange.bind(this);

        this.typingTimer = null;
        this.doneTypingInterval = 1000;

        this.state = {
            checklist : null,
            newlyCreatedIndex : -1
        };
    }

    delegateChange() {
        let f = this.props.changeFunction;
        if (typeof f === 'function') {
            f(this.state.checklist);
        }
    }

    deleteItem(index) {
        let newChecklist = [];
        this.state.checklist.map(function(item, ix){
            if (ix !== index) {
                newChecklist.push(item);
            }
        })
        this.setState({
            checklist : newChecklist
        }, this.delegateChange)
    }

    handleTextChange(newText, changedIndex) {
        let newChecklist = [];
        this.state.checklist.map(function(item, index){
            if (index == changedIndex) {
                newChecklist.push(newText)
            } else {
                newChecklist.push(item)
            }
        })
        this.setState({
            checklist : newChecklist
        }, () => {
            if (this.typingTimer) {
                clearTimeout(this.typingTimer);
            }
            this.typingTimer = setTimeout(this.delegateChange, this.doneTypingInterval);
        })
    }

    add() {
        let newChecklist = this.state.checklist;
        let newIndex = this.state.checklist.length;
        newChecklist.push('');
        this.setState({
            checklist : newChecklist,
            newlyCreatedIndex : newIndex
        }, this.delegateChange)
    }

    renderedItem(item, index) {
        let startWithFocus = (this.state.newlyCreatedIndex === index);

        return (
            <div key={index} className="listItem">
                <div className="number">
                    { index + 1 }.
                </div>
                <div>
                    <TextInput
                        showInput
                        disabled={!this.props.permission}
                        itemText={item}
                        changeFunction={(newVal) => this.handleTextChange(newVal, index)}
                        placeholder="Write your Requirement"
                        startWithFocus={startWithFocus}
                        validateFunction={max200}
                        invalidMessage = "Maximum 200 words"
                    />
                </div>
                {this.props.permission && <div className="control" onClick={() => this.deleteItem(index)}>
                    <img src="/images/ic-trash.svg"/>
                </div>}
            </div>
        )
    }

    componentDidMount() {
        this.setState({
            checklist : this.props.checklist
        })
    }

    render() {
        if (!Array.isArray(this.state.checklist)) {
            return null;
        }
        let items = [];
        for (let i=0; i<this.state.checklist.length; i++) {
            items.push(this.renderedItem(this.state.checklist[i], i));
        }
        let canAddMore = (items.length < 10 && this.props.permission);
        return (
            <div className="box">
                { items }
                { canAddMore &&
                    <div className="controlLink addItem" onClick={this.add}>
                        + Add Requirement
                    </div>
                }
            </div>
        )
    }
}
