import React from 'react'
import { withRouter } from 'react-router'
import Format from '../../modules/utils/Format';
import TopNavigation from '../shared/topNavigation/TopNavigation'
import CreatorProfile from './CreatorProfile';
import PleaseWait from '../shared/pleaseWait/PleaseWaitWhite';
import ProfileHelper from '../../modules/utils/ProfileHelper';
import ApiError from '../shared/apiError/ApiError'

import './influencerProfile.scss'

class InfluencerProfile extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.renderedNav = this.renderedNav.bind(this);

        this.state = {
            currentPanel : 'profile',
            profile : null,
            waiting: false
        }

        this.panels = [
            { key : 'profile', show: false},
            { key : 'payments', show: true},
            { key : 'assignments', show: true},
            { key : 'opportunities', show: true}
        ]
    }

    componentDidMount() {
        this.setState({
            waiting: true
        })
        ProfileHelper.getCurrentLoginUserProfile().then(
            profile => {
                this.setState({
                    profile,
                    waiting: false
                })
            },
            (error) => {
                this.setState({
                    waiting: false,
                    showApiError : true,
                    apiError : error
                })
            }
        );
    }

    renderedNav(panel) {
        if (!panel.show) {
            return null;
        }

        const {currentPanel} = this.state;
        const className = (currentPanel === panel.key) ? 'current' : '';

        const self = this;
        const clickHandler = function() {
            if ( self.state.currentPanel === panel.key) {
                return;
            }
            self.setState({
                currentPanel : panel.key,
                profile : null
            })
        }

        return <div
            key={ panel.key }
            onClick={clickHandler}
            className={className}
            role='button'
        >
            { Format.capitalize(panel.key) }
        </div>
    }

    render() {
        const panelLinks = [];
        const {waiting, showApiError, apiError, profile, currentPanel} = this.state;
        for (const panel of this.panels) {
            panelLinks.push(this.renderedNav(panel));
        }
        return (
            <div className="influencerProfile">
                <PleaseWait show={waiting}/>
                <div id="modalForBlog" />
                <ApiError
                    show={showApiError}
                    error={apiError}
                    cancelFunction={ () => {
                        this.setState({showApiError: false})
                    }
                    }/>
                <TopNavigation current="profile"/>
                { (currentPanel === 'profile') && profile &&
                    <div id="profileScroll"><CreatorProfile
                        profile = {profile}
                        editMode
                    /></div>
                }
            </div>
        );
    }
}

export default withRouter(InfluencerProfile);