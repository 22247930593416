import React from 'react'

import TopNavigation from '../shared/topNavigation/TopNavigation'
import { Route, Switch } from 'react-router-dom';
import OpportunityList from "./OpportunityList";
import OpportunityView from "./OpportunityView";
import "./InfluencerOpportunity.scss"

export default class InfluencerOpportunity extends React.Component {
    constructor(props) {
        super(props);
    }
    // componentDidMount() {
    //     if (!this.props.lookupTablesLoading  && !this.props.lookupTables) {
    //         this.props.getLookupTables();
    //     }
    // }
    render(){
        // if (!this.props.lookupTables) {
        //     return (
        //         <PleaseWaitWhite show={!this.props.lookupTables}/>
        //     )
        // }
        return (
            <div>
                <TopNavigation current="opportunities" />
                <Switch>
                    <Route exact path={`${this.props.match.url}`} component={OpportunityList}/>
                    <Route exact path={`${this.props.match.url}/:assignmentId`}  component={OpportunityView}/>
                    <Route exact path={`${this.props.match.url}/:assignmentId/influencer/:influencerId`}  component={OpportunityView}/>
                </Switch>
            </div>
        )
    }
}

// const mapDispatchToProps = dispatch => {
//     return {
//         getLookupTables: () => {
//             dispatch(startLoadingLookupTables())
//         }
//     }
// };

// const mapStateToProps= (state) => {
//     return{
//         lookupTablesLoading : state.global.lookupTablesLoading,
//         lookupTables : state.global.lookupTables
//     }
// };

// export default connect(mapStateToProps, mapDispatchToProps)(InfluencerOpportunity)
