import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import cx from 'classnames';
import './rateItem.scss';
import Lookup from '../../../../modules/Lookup'
import InputBox from './InputBox';
import Format from '../../../../modules/utils/Format';
import InfluencerPhoto from '../../../oneInfluencerNew/InfluencerPhoto';

const isNumber = (val) => /^[-]?\d+$/.test(val);

const isValidRate = (rate) => {
    if (rate === undefined)
        return false;

    if (typeof rate === 'string') {
        return rate !== '' && isNumber(rate);
    }if (typeof rate === 'number') {
        return rate >= 0;
    }

    return false;

}

const createRates = (profile) => {
    const data = {};
    Lookup.getRateTypeList2().forEach(key => {
        if (profile && profile.deliverables && profile.deliverables[key]) {
            try {
                if (typeof profile.deliverables[key] === 'string') {
                    const t = Number(profile.deliverables[key].trim());
                    if (t > 0) {
                        data[key] = '';
                    }
                } else if (typeof profile.deliverables[key] === 'number' && profile.deliverables[key] > 0) {
                    data[key] = '';
                }
            } catch (e) {
                console.log(e);
            }
        }
    });
    return data;
}

const RateItem = ({
    profile = {},
    keyString = '',
    hideRemove,
    onDelete = () => null,
    onChange = () => null,
    asTotal = true,
    hideBottom = false,
    currency
}) => {
    const [totalRate, setTotalRate] = useState('');
    const [rateData, setRateData] = useState({});

    useEffect(() => {
        const rData = createRates(profile);
        setRateData({...rData, ...profile.rates});
    }, [profile.rates]);

    const countForTotal = () => {
        if (asTotal) {
            return profile.rates && isValidRate(profile.rates.GENERAL) ? profile.rates.GENERAL : '';
        }
        let total = '';
        if (profile.rates) {
            Object.entries(profile.rates).forEach(([k, value]) => {
                if (k !== 'GENERAL') {
                    if (typeof value === 'string' && value !== '') {
                        total = (total === '' ? 0 : total) + Number(value);
                    } else if (typeof value === 'number') {
                        total = (total === '' ? 0 : total) + value;
                    }
                }
            });
        }
        return total;
    }

    useEffect(() => {
        setTotalRate(countForTotal());
    }, [asTotal, rateData]);

    const onRateChange = (val, k) => {
        let numVal;
        try {
            numVal = val !== '' ? Number(val.replace(/[^0-9]/g, "")) : '';
        } catch (e) {
            console.log(e);
            return;
        }
        const updated = {...rateData};
        updated[k] = numVal;
        setRateData(updated);
        onChange(updated);
    }

    return (
        <div key={keyString} className='rateItemContainer'>
            {!hideRemove && <div 
                className='removeItem'
                onClick={() => onDelete()}
                role='button'
            >
                <img
                    src="/images/ic-remove.svg"
                
                    alt={`removeItem_${keyString}`}
                />
            </div>}
            <div className='itemCardContainer'>
                <div className='topSection'>
                    <InfluencerPhoto url={profile.pictureUrl} firstName={profile?.firstname} lastName={profile?.lastname} />
                    {profile.name}
                </div>
                {!hideBottom &&
                <div className='bottomSection'>
                    <div className='customRatesContainer'>
                        {asTotal ? 
                            <div className='inputContainer'>
                                <InputBox 
                                    keyString={profile.id} 
                                    value={isValidRate(rateData.GENERAL) ? Format.formatNumber(rateData.GENERAL) : ''} 
                                    onChange={val => onRateChange(val, 'GENERAL')} 
                                    label='Total cost' 
                                    maxLength={6}
                                    currency={currency.symbol}
                                    type='currency'
                                />
                            </div>
                            :
                            Object.keys(rateData).map((k) => {
                                if (k === 'GENERAL' || (profile.deliverables && !profile.deliverables[k])) {
                                    return null;
                                }
                                const {label} = Lookup.getDeliverableType(k);
                                const value = isValidRate(rateData[k]) ? Format.formatNumber(rateData[k]) : '';
                                return (
                                    <div key={label} className='inputContainer'>
                                        <InputBox
                                            key={label}
                                            keyString={label} 
                                            value={value} 
                                            onChange={val => onRateChange(val, k)} 
                                            label={label} 
                                            maxLength={6}
                                            currency='$'
                                            type='currency'
                                        />
                                    </div>
                                )
                            })}
                    </div>
                    <div className='proposedBox'>
                        <div>Total proposed</div>
                        <div>{isValidRate(totalRate) ? `${currency.symbol}${  Format.formatNumber(totalRate)}` : currency.symbol}</div>
                    </div>
                </div>
                }
            </div>
        </div>
    )
}

export default RateItem;