import React from 'react'
import Api from '../../../modules/Api'
import ApiError from '../../shared/apiError/ApiError';
import FileUpload from '../../shared/fileUpload/FileUpload'
import PleaseWaitPartial from '../../shared/pleaseWait/PleaseWaitPartial'

// props : handleUpload   takes url
//         label

export default class FileUploadThis extends React.Component {


    constructor(props, context) {
        super(props, context);

        this.SUB_FOLDER_PATH = "influencer/w9"; // make sure no leading or ending slashes "/"
        this.CONTENT_TYPE = "application/pdf";

        this.state = {
            showSpinner : false,
            uploadCount : 1,
            uploadProgressPercentage: null,
            onUploadCancel: null,
            apiError: null,
        }
    }

    send = (file) => {
        this.setState({showSpinner: true});
        Api.uploadResource(
            file,
            false,
            this.SUB_FOLDER_PATH,
            this.CONTENT_TYPE,
            (percentage) => this.setState({uploadProgressPercentage: percentage}),
            (fn) => this.setState({onUploadCancel: fn})
        ).then(
            (res)=>{
                this.setState({
                    showSpinner: false,
                    uploadProgressPercentage: null,
                    onUploadCancel: null
                })
                if (res)
                    this.props.handleUpload(res.url)
            },
            (err)=>{
                if ( err.toString().indexOf('413') >= 0) {
                    this.setState({showSpinner: false, apiError: 'File too big'});
                } else {
                    this.setState({showSpinner: false, apiError: err});
                }
            },
        )
    }

    handleFile = (file) => {
        this.setState({
            name : file.name,
            type : file.type,
        })

        this.send(file);
    }

    handleClick = () => {
        this.setState({uploadCount : this.state.uploadCount + 1})
    }

    render() {
        const accepted = '*';
        const { accept } = this.props;
        const {apiError} = this.state;
        return (
            <div style={{position:'relative'}} ref={ (e) => {this.container = e}}>
                <PleaseWaitPartial
                    show={this.state.showSpinner}
                    container={this.container}
                    progressPercentage={this.state.uploadProgressPercentage}
                    onCancel={this.state.onUploadCancel}
                />
                <ApiError
                    show={apiError}
                    error={apiError}
                    cancelFunction={() => this.setState({apiError: null})}
                />
                <FileUpload
                    accept={accept || "*"}
                    handleSelectedFile={this.handleFile}
                    clickCounter={this.state.uploadCount}
                />
                <div className="link"
                    onClick={this.handleClick}
                >
                    {this.props.label}
                </div>
            </div>
        )
    }
}
