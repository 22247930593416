import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import Api from '../../modules/Api';
import ApiError from '../shared/apiError/ApiError';
import PleaseWaitWhite from '../shared/pleaseWait/PleaseWaitWhite';

const CreateKeywordGroupModal = (props) => {

    if (!props.brands || props.brands.length === 0)
        return null;

    const composedBrands = props.brands.map(br => ({
        value: br.id,
        label: br.brandName
    }));

    const initialBrand = props.initialBrandId ? composedBrands.find(b => b.value == props.initialBrandId) : null;

    const [isFetching, setIsFetching] = useState(false);
    const [groupName, setGroupName] = useState("");
    const [brand, setBrand] = useState(initialBrand);
    const [existingGroups, setExistingGroups] = useState([]);
    const [apiError, setApiError] = useState();

    const handleChangeBrand = async (selectedBrand) => {
        setBrand(selectedBrand);
        try {
            setIsFetching(true);
            let res = await Api.getKeywordGroups(selectedBrand.value, 1, 200);
            if (res.totalElements > 200) {
                res = await Api.getKeywordGroups(selectedBrand.value, 1, res.totalElements);
            }
            setExistingGroups(res.content);
            setIsFetching(false);
        } catch(error) {
            setIsFetching(false);
            setApiError(error);
        }
    }

    useEffect(() => {
        if (initialBrand) {
            handleChangeBrand(initialBrand);
        }
    }, []);

    const onSubmit = () => {
        const data = {
            groupName,
            agencyBrandId: brand.value
        };
        const item = existingGroups.find(group => group.groupName === groupName);
        if (!item) {
            setIsFetching(true);
            Api.createKeywordGroup(data).then(creationResponse => {
                setIsFetching(false);
                props.close();
                props.onCreate(creationResponse);
            }).catch(error => {
                setIsFetching(false);
                setApiError(error);
            });
        } else {
            setApiError('Group with this name already exists, please choose a different name.');
        }
    }

    return (
        <div key="create-modal" className="modalOverlay" role='button' onClick={apiError ? null : props.close}>
            <ApiError
                show={!!apiError}
                error={apiError}
                cancelFunction={() => setApiError(null)}
            />
            <PleaseWaitWhite show={isFetching} />
            <div className="modalContent" role='button' onClick={event => event.stopPropagation()}>
                <div>
                    <div className="modalTitle">
                        Create Group
                    </div>
                    <div className="label">
                        Group Name
                    </div>
                    <input className="groupNameInput" value={groupName} onChange={e => setGroupName(e.target.value)} />
                    <div className="label">
                        Brand
                    </div>
                    <Select
                        clearable={false}
                        value={brand}
                        single
                        placeholder="Select"
                        options={composedBrands}
                        onChange={handleChangeBrand}
                    />
                </div>
                <div id="buttons" className="buttons">
                    <div className="button" role='button' onClick={props.close}>
                        Cancel
                    </div>
                    <div className="button proceedButton" role='button' onClick={onSubmit}>
                        Create Group
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CreateKeywordGroupModal;
