const headerTitles = {
    create: 'New Brand',
    edit: 'Edit Brand',
};

const submitButtonTitles = {
    create: 'Create',
    edit: 'Update',
};

const emptyFieldKey = 'empty_field';

const wrongEmailKey = 'wrong_email';

export default {
    headerTitles,
    emptyFieldKey,
    wrongEmailKey,
    submitButtonTitles,
};
