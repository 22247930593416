import React from 'react';
import PropTypes from 'prop-types';

const ConfirmModalContinue = ({ show, maxWidth, actionText, title, msg, cancelFunc, proceedFunc }) => {
    if (!show) {
        return null;
    }
    const action = actionText || 'Yes';
    return (
        <div className="confirmModal confirmContinueModal">
            <div className="dialog" style={{ maxWidth }}>
                <div className="modalTitle">{title}</div>
                <div className="message">{msg}</div>
                <div>
                    <div className="modalBtn" onClick={cancelFunc}>
                        Cancel
                    </div>
                    <div className="modalBtn continue" onClick={proceedFunc}>
                        {action}
                    </div>
                </div>
            </div>
        </div>
    );
};

ConfirmModalContinue.propTypes = {
    show: PropTypes.bool.isRequired,
    maxWidth: PropTypes.number,
    actionText: PropTypes.string,
    title: PropTypes.string,
    msg: PropTypes.string,
    proceedFunc: PropTypes.func.isRequired,
    cancelFunc: PropTypes.func.isRequired,
};

ConfirmModalContinue.defaultProps = {
    maxWidth: null,
    actionText: '',
    title: '',
    msg: '',
};

export default ConfirmModalContinue;
