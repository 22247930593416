import {
    ASSIGNMENT_INFO_LOADING,
    FAVORITES_LIST_LOADING,
    LOOKUP_FILTERS_LOADING,
    LOOKUP_TABLES_LOADING,
    SET_DATA,
    SET_ERROR,
    RESET_DATA,
} from './actions';
import { LOOKUP_FILTERS_LOADED } from '../filter/actions/types';
import { LOOKUP_TABLES_LOADED } from '../global/globalActionTypes';

export const setAssignmentInfoLoading = (assignmentInfoLoading) => ({
    type: ASSIGNMENT_INFO_LOADING,
    assignmentInfoLoading,
});

export const setFavoritesListLoading = (favoritesListLoading) => ({
    type: FAVORITES_LIST_LOADING,
    favoritesListLoading,
});

export const setLookupFiltersLoading = (lookupFiltersLoading) => ({
    type: LOOKUP_FILTERS_LOADING,
    lookupFiltersLoading,
});

export const setLookupTablesLoading = (lookupTablesLoading) => ({
    type: LOOKUP_TABLES_LOADING,
    lookupTablesLoading,
});

export const setData = (payload) => ({
    type: SET_DATA,
    payload,
});

export const setError = (error) => ({
    type: SET_ERROR,
    error,
});

/*
    These two are needed for backward compatibility.
    Lookup file usage leads to cycle dependencies and in future we need to get rid of it.
    But, as for now, we can't avoid it's deeply nested usage. So, we need to work with its reducer for now
*/
export const lookupFiltersLoaded = (payload) => ({
    type: LOOKUP_FILTERS_LOADED,
    ...payload,
});

export const lookupTablesLoaded = (tables) => ({
    type: LOOKUP_TABLES_LOADED,
    summary: 'Y',
    message: '',
    tables,
});

export const resetData = () => ({
    type: RESET_DATA,
});
