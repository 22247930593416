import React from 'react';
import { injectIntl } from 'react-intl';
import qs from 'query-string';

import Summary from './piecesInMain/Summary';
import Connect from './piecesInMain/connect/Connect';
import PastBrandNames from './piecesInMain/pastBrandNames/PastBrandNames';
import Audience from './piecesInMain/Audience';
import Foods from './piecesInMain/pastBrandNames/Foods';
import Hobbies from './piecesInMain/pastBrandNames/Hobbies';
import { menuLinkIds } from './SecondaryNavigationMenu';
import Format from '../../modules/utils/Format';
import RatesService from '../../services/ratesService';
// import below have cycle dependencies (potential memory leak)
import EditablePiece from './editablePiece/EditablePiece';
import Rate from './piecesInMain/Rate';
import About from './piecesInMain/about/About';
import ProfileInfo from './piecesInMain/about/ProfileInfo';
import Media from './piecesInMain/media/Media';
import MediaKit from './piecesInMain/media/MediaKit'
import RecentPost from '../influencers/detailPage/influencerProfileBU/piecesInMain/RecentPost';
import {
    PER_CHANNEL,
    PER_POST,
} from '../../constants/rateTypes';
import ProfileHelper from '../../modules/utils/ProfileHelper';

// props
// - profile
// - editMode  (of the whole profile page
// - changeFunction
// - handleLocalEditChange - takes a flag and a local piece (like 'rate' etc)
// - currentLocal
// - delegatePageMarksFunction - takes an object with the page mark offset top keyed with the mark's element id
// - setScrollTop
// - getScrollTop

class ProfileMainPanel extends React.Component {
    constructor(props) {
        super(props);

        ProfileMainPanel.getQueryParams = ProfileMainPanel.getQueryParams.bind(this);

        const params = ProfileMainPanel.getQueryParams();
        const editingSocialConnections = !!(params && (typeof params.con !== 'undefined'));

        this.state = {
            // edit flags within the edited component
            rateLocal: false,
            rateSave: false,

            summaryLocal: false,
            summarySave: false,

            aboutLocal: false,
            aboutSave: false,
            aboutError: false,

            socialLocal: editingSocialConnections,

            topicsLocal: false,
            topicsSave: false,
            canAddTopic: false,

            addTopicCount: 1,

            summaryOk: false,
            cancelCount: 1,
            activeRatesOption: null,
            //
            // audienceLocal : false,
            // audienceSave : false,
        };
    }

    setRateError = (flag) => {
        this.setState({
            rateError: flag,
        });
    }

    canAddTopicFunction = (flag) => {
        this.setState({
            canAddTopic: flag,
        });
    }

    addTopic = () => {
        const { addTopicCount } = this.state;

        this.setState({
            addTopicCount: addTopicCount + 1,
        });
    }

    handleProfileChange = (newProfile, managerPermission) => {
        const { changeFunction } = this.props;

        if (newProfile) {
            changeFunction(newProfile, managerPermission);
        }
    }

    static getQueryParams() {
        return qs.parse(document.location.search);
    }

    handleLocalEditChange = (piece, editFlag, saveFlag) => {
        const newVal = {};
        newVal[`${piece}Local`] = editFlag;
        newVal[`${piece}Save`] = !!saveFlag;

        this.setState(newVal);

        const { handleLocalEditChange } = this.props;

        if (typeof handleLocalEditChange === 'function') {
            handleLocalEditChange(editFlag, piece);
        }
        if (piece === 'social' || saveFlag) {
            ProfileHelper.getCurrentLoginUserProfile(true);
        }
    }

    setSummaryOk = (flag) => {
        this.setState({
            summaryOk: flag,
        });
    }

    componentDidMount() {
        this.storeProfileActiveRatesOptionToState();
    }

    storeProfileActiveRatesOptionToState = () => {
        const { profile } = this.props;

        const ratesService = new RatesService(profile);
        const activeRatesOption = ratesService.getInfluencerActiveRatesOption();

        this.setState({
            activeRatesOption,
        });
    }

    toggleProfileActiveRatesOption = (activeRatesOption) => this.setState({
        activeRatesOption,
    })

    onContinue = () => {
        const { cancelCount } = this.state;
        this.setState({
            cancelCount: cancelCount + 1,
        });
        this.handleLocalEditChange('social', false, false);
    };

    render() {
        const {
            activeRatesOption,
            aboutLocal,
            aboutSave,
            aboutError,
            brandsLocal,
            brandsSave,
            cancelCount,
            rateLocal,
            rateSave,
            rateError,
            socialLocal,
            summaryLocal,
            summaryOk,
            summarySave,
            topicsLocal,
        } = this.state;
        const {
            className,
            currentLocal,
            editMode,
            intl: { formatMessage },
            isTalentManager,
            profile,
            getScrollTop,
            setScrollTop,
        } = this.props;
        const {
            management,
            mediaKits,
        } = profile;

        const permissions = {
            editInfo: management ? management.permissionsSet.editInfo : true,
            editPortfolio: management ? management.permissionsSet.editPortfolio : true,
            editRates: management ? management.permissionsSet.editRates : true,
            editSummary: management ? management.permissionsSet.editSummary : true,
        };

        if (!profile) {
            return null;
        }
        const showMediaKit = Array.isArray(mediaKits);

        let topicBoxClass = 'pieceBox';
        if (topicsLocal) {
            topicBoxClass += ' transparent';
        }

        return (
            <div className={`profileMainPanel ${className}`}>
                {/* About */}
                <div className="pieceBox">
                    <EditablePiece
                        editMode={permissions.editInfo && editMode}
                        handleLocalEditChange={
                            (editFlag, saveFlag) => this.handleLocalEditChange('about', editFlag, saveFlag)
                        }
                        isTalentManager={isTalentManager}
                        disableEdit={currentLocal !== null}
                        disableSave={aboutError}
                    >
                        <ProfileInfo
                            editMode={aboutLocal}
                            saveFlag={aboutSave}
                            profile={profile}
                            changeFunction={(data) => this.handleProfileChange(data, "EDIT_INFO")}
                            setScrollTop={setScrollTop}
                            getScrollTop={getScrollTop}
                            setSaveEnabled={(flag) => { this.setState({ aboutError: !flag }) }}
                        />
                    </EditablePiece>
                </div>

                {/* Social Networks - total reach */}
                <div className="pieceBox socialBox">
                    <EditablePiece
                        profile={profile}
                        editMode={editMode && !isTalentManager}
                        showInEditMode={socialLocal}
                        handleLocalEditChange={
                            (editFlag, saveFlag) => this.handleLocalEditChange('social', editFlag, saveFlag)
                        }
                        disableEdit={currentLocal !== null}
                        cancelButtonLabel={Format.capitalize(formatMessage({ id: 'done' }))}
                        cancelButtonStyle={{ margin: 0 }}
                        cancelCount={cancelCount}
                    >
                        <Connect
                            editMode={socialLocal}
                            profile={profile}
                            continueFunction={this.onContinue}
                        />
                    </EditablePiece>
                </div>
                <div className="pieceBox editablePiece" style={{ padding: 30 }}>
                    <div className="pieceTitle" id="mediaLink">
                        Recent Posts
                    </div>
                    <RecentPost
                        profile={profile}
                    />
                </div>
                <div style={{ position: 'relative' }}>
                    {/* <div className="sectionTitle">
                        Portfolio
                    </div> */}

                    {/* Media Kits  nee Portfolio */}
                    <MediaKit
                        isTalentManager={isTalentManager}
                        editMode={permissions.editPortfolio && editMode}
                        profile={profile}
                        handleLocalEditChange={
                            (editFlag, saveFlag) => this.handleLocalEditChange('media', editFlag, saveFlag)
                        }
                        disableEdit={currentLocal !== null}
                    />
                </div>

                {/* <div style={{ display: 'none' }}>
                    <div className="divider transparent" />
                    <EditablePiece
                        editMode={editMode}
                        handleLocalEditChange={
                            (editFlag, saveFlag) => this.handleLocalEditChange('brands', editFlag, saveFlag)
                        }
                        disableEdit={currentLocal !== null}
                    >
                        <PastBrandNames
                            editMode={brandsLocal}
                            saveFlag={brandsSave}
                            profile={profile}
                            changeFunction={this.handleProfileChange}
                        />
                    </EditablePiece>
                </div> */}
            </div>
        );
    }
}

export default injectIntl(ProfileMainPanel);
