import React from 'react';
import ClampText from '../../../shared/clampText/ClampText';

export const channelIcons = {
    INSTAGRAM: '/images/IG.svg',
    YOUTUBE: '/images/rate-video.svg',
    TIKTOK: '/images/ic-rate-tiktok.svg',
};

export const getLanguageName = (code) => {
    if (!code) return null;
    const lang = new Intl.DisplayNames(['en'], { type: 'language' });
    return lang.of(code);
};

export const TextContainer = ({ comment, showOriginal }) => {
    const text = showOriginal ? comment.text : comment.translatedText;
    return text ? <ClampText text={text} /> : <div className="empty-text-label">No translation was found</div>;
};

export const getRelevancyValues = (comment) => [...(comment.categories || []), ...(comment.products || [])];

const isArrayEqual = (arr1, arr2) => arr1.length === arr2.length && arr1.every((val) => arr2.includes(val));

export const getIsRelevancyUpdated = (comment) =>
    !isArrayEqual(comment.predictedCategories || [], comment.categories || []) ||
    !isArrayEqual(comment.predictedProducts || [], comment.products || []);

export const getIsTopicUpdated = (comment) => !isArrayEqual(comment.predictedTopics || [], comment.topics || []);
