import React, { useState, useLayoutEffect, useRef, useEffect } from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import './addToBase.scss';
import PleaseWaitPartial from '../shared/pleaseWait/PleaseWaitPartial'


const AddToBase = ({
    onBack,
    data,
    headerLabel = 'Add to favorite lists',
    createLabel = 'Create list',
    searchPlaceHolder = '',
    createPlaceHolder = '',
    noDataMessage = '',
    onCreate = () => null,
    onChange = () => null,
    onSearch = () => null,
    onLoadMore = () => null,
    loading = false,
    hideBottom = false
}) => {
    const [dataList, setDataList] = useState([]);
    const [creating, setCreating] = useState(false);
    const [newName, setNewName] = useState('');
    const [filter, setFilter] = useState('');
    const containerRef = useRef();
    const listContainerRef = useRef();
    const [searchActivated, setActivation] = useState(false);

    useEffect(() => {
        document.getElementById('addToBaseListContainer').addEventListener('scroll', handleScroll);
        return () => document.getElementById('addToBaseListContainer').removeEventListener('scroll', handleScroll);
    }, []);

    const handleScroll = () => {
        if (!loading
            && listContainerRef.current
            && (listContainerRef.current.scrollTop + listContainerRef.current.offsetHeight === listContainerRef.current.scrollHeight)) {
            console.log('scroll to end')
            onLoadMore();
        }
    }

    useEffect(() => {
        if (data && data.length > 0) {
            setDataList(data);
            setActivation(true)
        } else {
            setDataList([]);
        }
        setCreating(false);
    }, [data]);

    useLayoutEffect(() => {
        onSearch(filter);
    }, [filter]);

    const searchList = (e) => {
        setFilter(e.target.value);
    };

    const onCreateClick = () => {
        setCreating(true);
    };

    const onItemClick = (item) => {
        onChange(item);
    };

    const onViewClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };

    const textChange = (e) => {
        setNewName(e.target.value);
    };

    const toCreate = () => {
        onCreate(newName);
        // setCreating(false);
    };

    return (
        <div className="addToBaseContainer" onClick={onViewClick}>
            <div className='addToBaseHeader'>
                {typeof onBack === 'function' && <img src="/images/ic-back-b.svg" onClick={onBack} />}
                <span>{headerLabel}</span>
            </div>
            <div className='addToBaseContentContainer' ref={containerRef}>
                <PleaseWaitPartial
                    show={loading}
                    container={containerRef.current}
                    customStyles={{ height: 'none' }}
                />
                {searchActivated && <div className="searchBoxContainer">
                    <input
                        onChange={searchList}
                        placeholder={searchPlaceHolder}
                        type="text"
                        id="addToBaseSearch"
                        value={filter}
                    />
                    <img src="/images/ic-mag.svg" onClick={() => null} />
                </div>}
                <div className='addToBaseListContainer' id="addToBaseListContainer" ref={listContainerRef}>
                    {data && data.length > 0 ? dataList.map((item, i) => {
                        return <div
                            key={`item list ${i}`}
                            className={cx('addToBaseItem', { selected: item.selected })}
                            onClick={() => onItemClick(item)}
                        >
                            {item.name}
                            {item.selected && <img src="/images/ic-check.svg" />}
                        </div>
                    }) : <div className='addToBaseNoItem'>
                        <img src="/images/Graphic_Blank.svg" />
                        <span>Oh no!</span>
                        {noDataMessage}
                    </div>}
                </div>
                {!hideBottom && <div className='bottomContainer'>
                    {creating ? <div className='addToBaseCreatingContainer'>
                        <div className="addToBaseInputBoxContainer">
                            <input
                                onChange={textChange}
                                placeholder={createPlaceHolder}
                                type="text"
                                id="addToBaseInput"
                                value={newName}
                            />
                        </div>
                        <div className={cx('createButton', { disabled: newName.length === 0 })} onClick={toCreate}>Create</div>
                    </div> : <div className='createButton' onClick={onCreateClick}>
                        <img src="/images/ic-plus-circle-green.svg" />
                        {createLabel}
                    </div>}
                </div>}
            </div>
        </div>
    )
};

const mapStateToProps = (state) => {
    return {
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddToBase));
