import React from 'react';
import PropTypes from 'prop-types';
import style from './styles.module.scss';

const Header = ({ title }) => (
    <div className={style.header}>
        {title}
    </div>
);

Header.propTypes = {
    title: PropTypes.string,
};

export default Header;
