/* eslint-disable indent */
import React from 'react'
import ReactDOM from 'react-dom'

import { promptLogin } from '../../../modules/influencer/SocialAuthService'
import SocialNetworks from '../../../modules/influencer/SocialNetworks'
import Format from '../../../modules/utils/Format'
import ConfirmModal from "../confirmModal/ConfirmModal"
// import SocialNetworkIcon from '../SocialNetworkIcon'
import ReachIcon from '../ReachIcon/ReachIcon'
import './connectToSocial.scss';
import Api from '../../../modules/Api';
import BlogDialog from './BlogDialog';
import TiktokDialog from './TiktokDialog';
import YoutubeDialog from './YoutubeDialog';
import Session, { PROP_NETWORK_NAME, PROP_REDIRECTED, PROP_CONNECTION_ERROR } from "../../../modules/utils/Session";

// props
// - networkKey
// - networkStats
// - statusChangeFunction takes networkName and networkStats with networkType and reach or
//             (after successful delete)  null
// - profile
// - confirmDelete { confirmFlag ( boolean) , message }  if present, defines a confirm modal for disconnecting
//
//   (make sure server/static/images/social-<name>,svg exists
//   also make sure there's an empty div with id modalForBlog somewhere in the containing
//   component, in the right stacking context (had to do this to avoid the fixed Continue
//   button of the onBoard staying on the top of the modal dialog glass cover_

export default class ConnectToSocial extends React.Component {

  constructor(props, context) {
      super(props, context);

      this.mouseEnter = this.mouseEnter.bind(this);
      this.mouseLeave = this.mouseLeave.bind(this);
      this.clickStatus = this.clickStatus.bind(this);
      this.proceedWithBlog = this.proceedWithBlog.bind(this);
      this.cancelBlog = this.cancelBlog.bind(this);
      this.startBlogDialog = this.startBlogDialog.bind(this);
      this.renderBlogDialog = this.renderBlogDialog;
      this.renderYoutubeDialog = this.renderYoutubeDialog;
      this.proceedWithYoutube = this.proceedWithYoutube.bind(this);
      this.proceedWithTiktok = this.proceedWithTiktok.bind(this);
      this.cancelTiktok = this.cancelTiktok.bind(this);
      this.cancelYoutube = this.cancelYoutube.bind(this);
      this.startYoutubeDialog = this.startYoutubeDialog.bind(this);
      this.isBlog = (this.props.networkKey === 'blog');
      this.isYoutube = (this.props.networkKey === 'youtube');
      this.isTiktok = (this.props.networkKey === 'tiktok');

      const connected = (this.props.networkStats && (typeof this.props.networkStats.networkUserId !== 'undefined'));

      this.state = {
          focused: false,
          networkStatus: connected ? 'connected' : 'virgin',   // other options : connecting, connected, failed
          getReach: connected && ((this.props.networkStats.reach === null) || (this.props.networkStats.reach === 0)),
          blogDialogOpen: false,
          youtubeDialogOpen: false,
          tiktokDialogOpen: false,
      };
  }

  disconnectClickHandler = () => {
    if (this.props.confirmDelete && this.props.confirmDelete.confirmFlag) {
      const message = this.props.confirmDelete.message.replace('$1', this.props.networkKey)
        ReactDOM.render(
            <ConfirmModal
                show
                message={message}
                proceedFunction = {this.disconnect}
                cancelFunction={this.closeConfirm}
            />, document.getElementById('messageContainer')
        )
    } else {
        this.disconnect()
    }
  }

    closeConfirm = () => {
        ReactDOM.render(null, document.getElementById('messageContainer'));
    }

    disconnect = () => {
        this.closeConfirm();
        Api.removeUserNetwork(this.props.profile.id, this.props.networkKey.toUpperCase()).then(
            () => {
              this.setState({
                networkStatus: 'virgin',
                reach : 0
              });
              const f = this.props.statusChangeFunction;
              if (typeof f === 'function') {
                f(this.props.networkKey, null)
              }
          }
        )
    }

  proceedWithBlog(blog) {
    this.setState({
      blogDialogOpen : false
    }, this.renderBlogDialog);

    const self = this;
    Api.addBlog(this.props.profile.id, this.props.networkKey, blog).then(
        function(res) {
            self.setState({
                networkStatus: 'connected',
                reach : res.reach
            });
            const f = self.props.statusChangeFunction;
            if (typeof f === 'function') {
                f(self.props.networkKey,
                    res
                )
            }
        })
  }

  cancelBlog() {
    this.setState({
       blogDialogOpen : false
    }, this.renderBlogDialog)
  }

  startBlogDialog() {
      this.setState({
          blogDialogOpen : true
      }, this.renderBlogDialog)
  }

  renderBlogDialog() {
    // needed cause of z-index
      const dialogElement = document.getElementById('modalForBlog');
      const box = <BlogDialog
          show={this.state.blogDialogOpen}
          proceedWithBlog={this.proceedWithBlog}
          cancelBlog={this.cancelBlog}
          key={`${  new Date()}`}
      />;
      ReactDOM.render(box, dialogElement);
  }

  startTiktokDialog() {
    this.setState({
        tiktokDialogOpen: true
    }, this.renderTiktokDialog)
  }

  renderTiktokDialog() {
    // needed cause of z-index
    const dialogElement = document.getElementById('modalForBlog');
    const box = <TiktokDialog
        show={this.state.tiktokDialogOpen}
        proceedWithTiktok={this.proceedWithTiktok}
        cancelTiktok={this.cancelTiktok}
        key={`${new Date()}`}
    />;
    ReactDOM.render(box, dialogElement);
  }

  proceedWithTiktok(tiktok) {
    this.setState({
        tiktokDialogOpen: false
    }, this.renderTiktokDialog);

    const self = this;
    Api.addTiktok(this.props.profile.id, this.props.networkKey, tiktok).then(
        function (res) {
            self.setState({
                networkStatus: 'connected',
                reach: res.reach
            });
            const f = self.props.statusChangeFunction;
            if (typeof f === 'function') {
                f(self.props.networkKey,
                    res
                )
            }
        })
  }

  cancelTiktok() {
    this.setState({
        tiktokDialogOpen: false
    }, this.renderTiktokDialog)
  }

  renderYoutubeDialog() {
    // needed cause of z-index
      const dialogElement = document.getElementById('modalForBlog');
      const box = <YoutubeDialog
          show={this.state.youtubeDialogOpen}
          proceedWithBlog={this.proceedWithYoutube}
          cancelBlog={this.cancelYoutube}
          key={`${  new Date()}`}
      />;
      ReactDOM.render(box, dialogElement);
  }

  proceedWithYoutube() {
    this.setState({
      youtubeDialogOpen : false,
      networkStatus: 'connecting'
    }, this.renderYoutubeDialog);
    this.initAddingNetwork();
    promptLogin(this.props.redirectSuffix, this.props.networkKey, window.location.host);
  }

  cancelYoutube() {
    this.setState({
      youtubeDialogOpen : false
    }, this.renderYoutubeDialog)
  }

  startYoutubeDialog() {
      this.setState({
          youtubeDialogOpen : true
      }, this.renderYoutubeDialog)
  }

  mouseEnter() {
    this.setState({focused: true});
  }

  mouseLeave() {
    this.setState({focused: false});
  }

    initInstagramConnection() {
        const getRedirectURI = (redirectSuffix) => {
            const host = `https://${window.location.host}/`;
            return encodeURIComponent(`${host}${redirectSuffix}&con=instagram`);
        };
        // todo probably config should be pulled from BE
        const config = {
            appId: '1832412040399170',
            responseType: 'token',
            scope: 'public_profile pages_show_list business_management instagram_basic instagram_manage_insights pages_read_engagement pages_manage_metadata read_insights pages_read_user_content',
            oauthVersion: 'v19.0',
        };
        const redirectURI = getRedirectURI(this.props.redirectSuffix);
        const url = `https://www.facebook.com/${config.oauthVersion}/dialog/oauth?client_id=${config.appId}&redirect_uri=${redirectURI}&response_type=${config.responseType}&scope=${config.scope}&auth_type=rerequest`;

        window.location = url;
    }

    initSnapshotConnection() {
        const getRedirectURI = (redirectSuffix) => {
            const host = `https://${window.location.host}/`;
            return encodeURIComponent(`${host}${redirectSuffix}&con=snapchat`);
        };

        const config = {
            clientId: 'eacf7f65-9928-42b6-8c5d-70ebd25c5ecc',
            responseType: 'code',
            scope: 'snapchat-profile-api snapchat-marketing-api'

        };
        const redirectURI = getRedirectURI(this.props.redirectSuffix);
        const url = `https://accounts.snapchat.com/login/oauth2/authorize?client_id=${config.clientId}&redirect_uri=${redirectURI}&response_type=${config.responseType}&scope=${config.scope}`

        window.location = url;
    }

  clickStatus() {
    if (this.state.networkStatus !== 'virgin' && this.state.networkStatus !== 'failed') {
      return;
    }
    if (this.isBlog) {
      this.startBlogDialog(); // the dialog callback will do the Api call
      return;
    }
    if (this.isTiktok) {
      this.startTiktokDialog(); // the dialog callback will do the Api call
      return;
    }
    if (this.isYoutube) {
      this.startYoutubeDialog(); // the dialog callback will do the Api call
      return;
    }
    if (this.props.networkKey === 'instagram') {
        this.initAddingNetwork();
        this.initInstagramConnection();

        return;
    }

    if (this.props.networkKey === 'snapchat') {
        this.initAddingNetwork();
        this.initSnapshotConnection();
        return;
    }


    this.setState({
        networkStatus: 'connecting',
    });
    this.initAddingNetwork();
    promptLogin(this.props.redirectSuffix, this.props.networkKey, window.location.host);
  }

  initAddingNetwork() {
    Session.set(PROP_NETWORK_NAME, this.props.networkKey);
    Session.remove(PROP_CONNECTION_ERROR);
    Session.remove(PROP_REDIRECTED);
  }

  componentDidMount() {
    if (this.state.getReach) {
      const self = this;
      Api.getSocialNetworkStats(this.props.profile.id, this.props.networkKey).then(res => {
        self.props.networkStats.reach = res.totalReach;
        self.setState({getReach: false});
      }).catch(err => {
        console.log(err);
        self.setState({getReach: false});
      });
    }
    if (this.isBlog) {
      this.renderBlogDialog();
    }
  }

  render() {
    const boxStyle = {};
    if (this.state.focused) {
      boxStyle.borderColor = SocialNetworks.getColor(this.props.networkKey);
    }

    let status = 'Connect account'; const statusStyle = {color:'#4AA129'};
    if (this.state.networkStatus === 'connecting') {
      status = '... Connecting ...';statusStyle.color = '#111111';
    }
    if (this.state.networkStatus === 'failed') {
      status = '!!! failed to connect';statusStyle.color = '#111111';
    }
    if (this.state.networkStatus === 'connected') {
      status = 'Connected';
      statusStyle.color = '#111111'; statusStyle.fontWeight = '600';
    }

    let networkName = this.props.networkKey;
    if (networkName === 'google') {
      networkName += '+';
    }
    if (networkName === 'tiktok') {
      networkName = 'TikTok';
    }

    let reach = '';
    if (this.props.networkStats) {
      if (!this.state.getReach) {
        reach = this.props.networkStats.reach;
        if (reach && Number(reach) >= 0) {
          reach = `${Format.expressInK(reach)   } followers`
        } else {
          reach = '';
        }
      } else {
        reach = <img src="/images/spinner2.svg" style={{width:50, height:50}} />;
      }
    }

    return (
        <div className="connectToSocial"
            style={boxStyle}
            onMouseEnter={this.mouseEnter}
            onMouseLeave={this.mouseLeave}
            onClick={this.clickStatus}
      >

            {/* social avatar */}
            <div style={{padding: 15}}>
                {/* <SocialNetworkIcon
            network={this.props.networkKey}
            size={50}
          /> */}
                <ReachIcon
                    network={this.props.networkKey}
                    value={1}
                    size={24}
                    padding={13}
          />
            </div>

            {/* middle with network name and status */}
            <div className="middle">
                <div className="networkName">
                    {networkName === "youtube" ? "YouTube" : networkName}
                </div>
                <div className="networkStatus">
                    <span style={statusStyle}>{status}</span>
                    {!!reach && <span className="isReach"> | </span>}
                    <span className="reach">{reach}</span>
                </div>
            </div>

            <div className="tail">
                {(this.state.networkStatus === 'connected') &&
                <div className="delete" onClick={this.disconnectClickHandler}>
                    <img src="/images/ic-cancel.svg"/>
                </div>
            }
            </div>
        </div>
    );
  }
}
