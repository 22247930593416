import React from 'react';
import PropTypes from 'prop-types';
import style from './priceSection.module.scss';

const PriceSection = ({ label, price }) => (
    <div className={style.pricesSectionCover}>
        <label className={style.label}>
            {label}
        </label>
        <div className={style.priceBlock}>
            {`$${price}`}
        </div>
    </div>
);

PriceSection.propTypes = {
    label: PropTypes.string,
    price: PropTypes.number,
}

export default PriceSection;