import React from 'react'
import cx from 'classnames';
import './creatorProfile.scss'
import { connect } from 'react-redux';
import CreatorProfileBasic from './CreatorProfileBasic'
import ProfileMainPanel from './ProfileMainPanel'
import CreatorProfileRate from './CreatorProfileRate'
// import SecondaryNavigation from './secondaryNavigation/SecondaryNavigation';
import { startLoadingLookupFilters } from '../../store/filter/actions'
import { startLoadingLookupTables , storeUserProfile } from '../../store/global/globalActions'

// import './influencerProfile.scss'
import ApiError from '../shared/apiError/ApiError'
import PleaseWaitWhite from '../shared/pleaseWait/PleaseWaitWhite';
import SecondaryNavigation from '../influencers/detailPage/influencerProfileBU/secondaryNavigation/SecondaryNavigationNew';
import CreatorHeader from './CreatorHeader';
import ProfileHelper from '../../modules/utils/ProfileHelper';
import {TALENT_MANAGER_STR, INFLUENCER_STR} from '../../constants/authorities';

// props
// - profile
// - editMode
// props provided by the Redux connection
// - getLookupTables  function
// - lookupTablesLoading  flag
// - lookupTables the lookup table set (object keyed with the field to look up)

class CreatorProfile extends React.Component {

    constructor(props, context) {
        super(props.context);

        this.handleModeChange = this.handleModeChange.bind(this);
        this.handleProfileChange = this.handleProfileChange.bind(this);
        this.handleBasicsEditChange = this.handleBasicsEditChange.bind(this);
        this.handleMainEditChange = this.handleMainEditChange.bind(this);
        this.resetLeft = this.resetLeft.bind(this);
        this.setScrollTop = this.setScrollTop.bind(this);
        this.handleContainerScroll = this.handleContainerScroll.bind(this);
        this.delegateScrollChange = this.delegateScrollChange.bind(this);
        this.renderedScrollSummary = this.renderedScrollSummary.bind(this);

        this.state = {
            editMode: true,
            leftMarginTop: 0,
            profile: props.profile,
            changeCount: 1,  // used to delegate profile changes to ProfileBasics
            currentLocal: null,   // the piece that is getting edited; the rest can't be in local edit mode
            currentLoginUserProfile: null,
        };
    }

    // renderedScrollSummary() {
    //     if (!this.state.showScrollSummary) {
    //         return null;
    //     }
    //     return <ScrollSummary profile={this.props.profile} />
    // }

    setScrollTop(newScrollTop) {
        const stepDuration = 5;
        const steps = 15;
        const handle = null;
        let stepsWalked = 0;
        const self = this;
        const oldScrollTop = this.container.scrollTop;
        const nextStep = function () {
            stepsWalked++;
            const interimScrollTop = oldScrollTop +
                stepsWalked * (newScrollTop - oldScrollTop) / steps;
            self.container.scrollTop = interimScrollTop;
            if (stepsWalked < steps) {
                setTimeout(nextStep, stepDuration);
            }
        }
        // this.container.scrollTop = newScrollTop;
        nextStep();
        this.delegateScrollChange(newScrollTop);
    }

    getScrollTop = () => {
        return this.container.scrollTop;
    }

    resetLeft() {
        this.setState({
            leftMarginTop: 0
        })
    }

    handleContainerScroll() {
        this.delegateScrollChange(this.container.scrollTop);
    }

    delegateScrollChange(newTop) {
        this.setState({
            // showScrollSummary : (newTop > 200),
            scrollTop: newTop
        })
    }

    handleBasicsEditChange(editModeFlag) {
        this.setState({
            currentLocal: editModeFlag ? 'basics' : null
        })
    }

    handleMainEditChange(editModeFlag, local) {
        this.setState({
            currentLocal: editModeFlag ? local : null
        })
    }

    handleModeChange(editMode) {
        this.setState({
            editMode
        })
    }

    handleProfileChange(newProfile, managerPermission) {
        const { isTalentManager, profile } = this.props;
        this.setState({
            changeCount: this.state.changeCount + 1
        })
        const self = this;
        const {windowUrl} = newProfile;

        if ( isTalentManager ) {
            const {
                management,
            } = profile;
    
            const permissions = {
                editInfo: management ? management.permissionsSet.editInfo : true,
                editPortfolio: management ? management.permissionsSet.editPortfolio : true,
                editRates: management ? management.permissionsSet.editRates : true,
                editSummary: management ? management.permissionsSet.editSummary : true,
            };

            if (!permissions.editRates) {
                delete newProfile.rates
            }
                
            ProfileHelper.saveProfile(newProfile, profile.id, managerPermission).then(
                function (result) {
                    result.windowUrl = windowUrl;
                    self.setState({profile: {...result, ...{management}}})
                    // self.props.storeProfile(result)
                },
                function (error) {
                    self.setState({
                        showApiError: true,
                        apiError: error
                    })
                });
        }else {
            ProfileHelper.saveProfile(newProfile, null, managerPermission).then(
                function (result) {
                    result.windowUrl = windowUrl;
                    self.props.storeProfile(result)
                },
                function (error) {
                    self.setState({
                        showApiError: true,
                        apiError: error
                    })
                });
        }

    }

    componentDidMount() {
        if (!this.props.lookupTablesLoading && !this.props.lookupTables) {
            this.props.getLookupTables();
        }
        if (!this.props.lookupFiltersLoading && !this.props.lookupFilters) {
            this.props.getLookupFilters();
        }
        ProfileHelper.getCurrentLoginUserProfile()
            .then(cuProfile => {
                this.setState({
                    currentLoginUserProfile: cuProfile,
                    editMode: !!this.props.editMode
                })
            })
            .catch(err => {
                this.setState({
                    editMode: !!this.props.editMode
                })
            })
    }

    renderedScrollSummary = () => {
        const { profile } = this.props;
        return <CreatorHeader show profile={profile} />
    };

    render() {
        const lookupLoading = !this.props.lookupTables || !this.props.lookupFilters;
        const {currentLoginUserProfile} = this.state;
        const addExtraTop = currentLoginUserProfile && (currentLoginUserProfile.role === TALENT_MANAGER_STR || currentLoginUserProfile.role === INFLUENCER_STR) && !currentLoginUserProfile.accountVerified;
        if (lookupLoading) {
            return (
                <PleaseWaitWhite show />
            )
        }
        const leftStyle = {
            // marginTop : this.state.leftMarginTop,
            borderStyle: 'solid',
            borderWidth: 1,
            borderColor: 'transparent'
        }

        return (
            <div className="creatorProfile">
                <div id="profileMessage" />
                <ApiError show={this.state.showApiError} error={this.state.apiError}
                    cancelFunction={() => { this.setState({ showApiError: false }) }} />
                <div 
                    className={cx("container", {extraContainerTop: addExtraTop})}
                    ref={elem => { this.container = elem }} 
                    onScroll={this.handleContainerScroll}
                >
                    {this.props.isTalentManager && <SecondaryNavigation
                        // permission={permission}
                        inviteProfile={this.props.profile}
                        disableAddToCampaign
                    >
                        {this.renderedScrollSummary()}
                    </SecondaryNavigation>}
                    <div className="narrowContainer">
                        <div> {/* table */}
                            <div>
                                <div> {/* overflow : auto */}
                                    {this.props.lookupTables &&
                                        <div style={leftStyle} className="leftContainer">
                                            <CreatorProfileBasic
                                                profile={this.props.profile}
                                                editMode={this.state.editMode}
                                                changeCount={this.state.changeCount}
                                                handleLocalEditChange={this.handleBasicsEditChange}
                                                currentLocal={this.state.currentLocal}
                                                changeFunction={(data) => this.handleProfileChange(data, "EDIT_INFO")}
                                                resetFunction={this.resetLeft}
                                                isTalentManager={this.props.isTalentManager}
                                            />
                                            <CreatorProfileRate
                                                profile={this.state.profile}
                                                editMode={this.state.editMode}
                                                changeFunction={(data) => this.handleProfileChange(data, "EDIT_RATES")}
                                                handleLocalEditChange={this.handleMainEditChange}
                                                currentLocal={this.state.currentLocal}
                                                setScrollTop={this.setScrollTop}
                                                getScrollTop={this.getScrollTop}
                                                isTalentManager={this.props.isTalentManager}
                                            />

                                        </div>
                                    }
                                </div>
                            </div>
                            <div>
                                <div id="mainPanelScroll" ref={elem => { this.right = elem }}> {/* overflow : auto */}
                                    <div>
                                        {this.props.lookupTables &&
                                            <ProfileMainPanel
                                                profile={this.props.profile}
                                                editMode={this.state.editMode}
                                                changeFunction={(data) => this.handleProfileChange(data)}
                                                handleLocalEditChange={this.handleMainEditChange}
                                                currentLocal={this.state.currentLocal}
                                                setScrollTop={this.setScrollTop}
                                                getScrollTop={this.getScrollTop}
                                                isTalentManager={this.props.isTalentManager}
                                            />
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div></div>
                {/* <SecondaryNavigation
                        editMode={this.state.editMode}
                        handleModeChange={this.handleModeChange}
                        setScrollTop={this.setScrollTop}
                        currentScrollTop={this.state.scrollTop}
                    >
                        { this.renderedScrollSummary()}
                    </SecondaryNavigation> */}
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getLookupFilters: () => {
            dispatch(startLoadingLookupFilters())
        },
        getLookupTables: () => {
            dispatch(startLoadingLookupTables())
        },
        storeProfile: (profile) => {
            dispatch(storeUserProfile(profile))
        }
    }
};

const mapStateToProps = (state) => {
    return {
        lookupFiltersLoading: state.filter.lookupFiltersLoading,
        lookupFilters: state.filter.lookupFilters,
        lookupTablesLoading: state.global.lookupTablesLoading,
        lookupTables: state.global.lookupTables
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatorProfile)
