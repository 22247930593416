import React from 'react';
import Format from '../../../modules/utils/Format';

const CONNECTED_PREFIX_FILTER_ID = {
    18: 'creator: ',
    26: 'email: ',
    27: 'personal summary/bio: ',
    38: 'website: ',
    39: 'porfolio desc: ',
    46: '@',
    47: '#',
    50: 'recent post caption: ',
    76: "creator's name/bio: ",
};

const PUBLIC_PREFIX_BY_FILTER_NAME = {
    name_and_bio: "creator's name/bio: ",
    social_hashtag: '#',
    social_handle: '@',
    topic: 'Topic: ',
};

export const getKeywordFilterPrefix = ({ filterId, keywordFilters, source }) => {
    if (source === 'PUBLIC') {
        const match = keywordFilters.find((keywordFilter) => keywordFilter.id === filterId);
        if (match) {
            const filterName = match?.name;
            if (filterName) {
                // Public filter ids are dynamic - get filter prefix by filter name
                return PUBLIC_PREFIX_BY_FILTER_NAME[filterName];
            }
        }
    }
    return CONNECTED_PREFIX_FILTER_ID[filterId];
};

export const getDisplayContent = (
    filter,
    source,
    addedFilterItem,
    keywordFilters,
    noCloseIcon,
    closeIcon,
    removeFilter,
    removeSpecificFilter,
    removekeyword,
    removeFavourite,
    options = null ) => {


    let filterToShow = null;
    let topkey = `topFilter${  filter.id}`;
    if (addedFilterItem) {
        switch (filter.type) {
        case 'WEIGHT':
            filterToShow = (
                <div className="box" key={topkey}>
                    {`${filter.label}: ${addedFilterItem?.customValue}%`}
                    {!noCloseIcon &&
                    <div className="closeIcon" onClick={() => removeFilter(filter, filter.group)}>
                        {closeIcon}
                    </div>
                    }
                </div>
            );
            break;
        case 'FREE_RANGE':
            filterToShow = (
                <div className="box" key={topkey}>
                    {filter.label}:{' '}
                    {Format.expressInK(addedFilterItem?.range_from, 2)}-
                    {Format.expressInK(addedFilterItem?.range_to, 2)}
                    {!noCloseIcon &&
                    <div className="closeIcon" onClick={() => removeFilter(filter, filter.group)}>
                        {closeIcon}
                    </div>
                    }
                </div>
            );
            break;
        case 'OPTION_RANGE':
        case 'OPTION':
            const idItems = addedFilterItem?.filterOptionIds;
            const textArray = [];
            if (idItems) {
                idItems.map((id) => {
                    let opts = filter.filterOptions;
                    if (options) {
                        let t = options.find(o => o.id === addedFilterItem.filterId)
                        if (t && t.options && t.options.length !== 0) {
                            opts = t.options;
                        }
                    }
                    const filterOptionItem = opts.find((item) => item.id == id);
                    // TODO: filter with async option label
                    if (filterOptionItem?.label) {
                        textArray.push(filterOptionItem.label);
                    }
                });
            }
            filterToShow = textArray.map((item, index) => {
                const functionToUse =
                        textArray.length > 1
                            ? () => removeSpecificFilter(filter.id, index, 'OPTION')
                            : () => removeFilter(filter, filter.group);
                let itemToUse = filter.customValue
                if (filter.customRanges && filter.customRanges[index]) {
                    itemToUse = filter.customRanges[index].minValue
                }
                return (
                    <div className="box" key={`${topkey + index  }OPTION`}>
                        {filter.label}: {item} {itemToUse ? itemToUse + "%" : ''}
                        {!noCloseIcon &&
                        <div className="closeIcon" onClick={functionToUse}>
                            {closeIcon}
                        </div>
                        }
                    </div>
                );
            });
            break;
        case 'FREE_TEXT':
            filterToShow = addedFilterItem?.keywords.map((item, index) => {
                const functionToUse =
                        addedFilterItem?.keywords.length > 1
                            ? () => removeSpecificFilter(filter.id, index, 'FREE_TEXT')
                            : () => removeFilter(filter, filter.group);
                return (
                    <div className="box" key={`${topkey + index  }FREE_TEXT`}>
                        {filter.label}: {item}
                        {!noCloseIcon &&
                        <div className="closeIcon" onClick={functionToUse}>
                            {closeIcon}
                        </div>
                        }
                    </div>
                );
            });
            break;
        case 'CUSTOM_VALUE':
            const idItemsCustom = addedFilterItem?.filterOptionIds;
            const customArray = [];
            if (idItemsCustom) {
                idItemsCustom.map((id) => {
                    const filterCustomItem = filter.filterOptions.find((item) => item.id == id);
                    customArray.push(filterCustomItem.label);
                });
            }
            filterToShow = (
                <div className="box" key={topkey}>
                    {filter.label}: {customArray.toString()}{' '}
                    {addedFilterItem?.customValue}%
                    {!noCloseIcon &&
                    <div className="closeIcon" onClick={() => removeFilter(filter, filter.group)}>
                        {closeIcon}
                    </div>
                    }
                </div>
            );
            break;
        case 'DATE_TEXT':
            filterToShow = (
                <div className="box" key={topkey}>
                    {filter.label}:{' '}
                    {addedFilterItem?.keywords.toString()}
                    {!noCloseIcon &&
                    <div className="closeIcon" onClick={() => removeFilter(filter, filter.group)}>
                        {closeIcon}
                    </div>
                    }
                </div>
            );
            break;
        case 'CUSTOM_RANGES':
            let idRangeCustom = addedFilterItem?.data && addedFilterItem.data.filterOptions;
            if (!idRangeCustom) {
                idRangeCustom = addedFilterItem?.customRanges;
            }
            if (Array.isArray(idRangeCustom)) {
                filterToShow = idRangeCustom.map((customRange, index) => {
                    const functionToUse =
                            idRangeCustom.length > 1
                                ? () => removeSpecificFilter(filter.id, index, 'CUSTOM_RANGES')
                                : () => removeFilter(filter, filter.group);
                    let itemShow;
                    if (customRange.minValue == null) {
                        itemShow = null;
                    } else {
                        let newText = '';
                        if (addedFilterItem?.data?.minOnly) {
                            newText = `${customRange.minValue  }%`;
                        } else {
                            newText = `${customRange.minValue  }%-${  customRange.maxValue  }%`;
                        }
                        itemShow = (
                            <>
                                {customRange.label} {newText}
                            </>
                        );
                    }
                    return (
                        <div className="box" key={`${topkey + index  }CUSTOM_RANGES`}>
                            {filter.label}: {itemShow}
                            {!noCloseIcon &&
                            <div className="closeIcon" onClick={functionToUse}>
                                {closeIcon}
                            </div>
                            }
                        </div>
                    );
                });
            }
            break;
        case 'JSON':
            filterToShow = (
                <div className="box" key={topkey}>
                    {filter.label}:{' '}
                    {addedFilterItem.json && addedFilterItem.json.formattedAddress}
                    {!noCloseIcon &&
                    <div className="closeIcon" onClick={() => removeFilter(filter, filter.group)}>
                        {closeIcon}
                    </div>
                    }
                </div>
            );
            break;
        case 'PERCENTAGE_RANGE':
            filterToShow = (
                <div className="box" key={topkey}>
                    {filter.label}:{addedFilterItem?.range_from}%-
                    {addedFilterItem?.range_to}%
                    {!noCloseIcon &&
                    <div className="closeIcon" onClick={() => removeFilter(filter, filter.group)}>
                        {closeIcon}
                    </div>
                    }
                </div>
            );
            break;
        case 'DUAL_RANGE':
            const fromValue = filter.filterOptions.find(item => item.id === addedFilterItem.filterOptionIds[0])
            const toValue = filter.filterOptions.find(item => item.id === addedFilterItem.filterOptionIds[1])
            filterToShow = (
                <div className="box" key={topkey}>
                    {filter.label}:{' '}
                    {fromValue.label}-
                    {toValue.label}
                    {addedFilterItem?.customValue ? ` ${addedFilterItem?.customValue}%` : ''}
                    {!noCloseIcon &&
                    <div className="closeIcon" onClick={() => removeFilter(filter, filter.group)}>
                        {closeIcon}
                    </div>
                    }
                </div>
            );
            break;
        default:
            filterToShow = null;
            break;
        }
    } else if (filter.filterId) {
        topkey = `topFilter${  filter.filterId  }${filter.keywords.join(', ')}`;

        const keywordPrefix = getKeywordFilterPrefix({ filterId: filter.filterId, keywordFilters, source });

        filterToShow = (
            <>
                {filter.keywords.map((keyword, index) => (
                    <div className="box" key={`${topkey}-${index}-${keyword}`}>
                        {keywordPrefix}
                        {keywordSanitization({
                            filterId: filter.filterId,
                            keywordFilters,
                            keyword: keyword,
                        })}
                        {!noCloseIcon && removekeyword &&
                            <div className="closeIcon" onClick={() => removekeyword(filter, keyword)}>
                        {closeIcon}
                            </div>
                        }
                    </div>
                ))}

            </>
        );
    } else if (filter.type === 'favourite') {
        filterToShow = (
            <div className="box" key={topkey}>
                Favourite List: {filter.name}
                {!noCloseIcon && removeFavourite &&
                <div className="closeIcon" onClick={() => removeFavourite(filter)}>
                    {closeIcon}
                </div>
                }
            </div>
        );
    }

    return filterToShow;
}
export const keywordSanitization = ({ filterId, keywordFilters, keyword }) => {
        const filterName = keywordFilters.find((keywordFilter) => keywordFilter.id === filterId)?.name;
        if (keyword.includes(PUBLIC_PREFIX_BY_FILTER_NAME[filterName])) {
            const sanitizationRegEx = new RegExp(PUBLIC_PREFIX_BY_FILTER_NAME[filterName], 'g');
            return keyword.replace(sanitizationRegEx, '');
        } else {
            return keyword;
        }
};
