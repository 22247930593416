import React, { useEffect, useState, useLayoutEffect } from 'react';
import './breadCrumbs.scss';
import cx from 'classnames';


const BreadCrumbs = ({
    data = [],
    history,
}) => {
    return (
        <div className='breadCrumbsContainer'>
            <img src="/images/home-5-fill.png" onClick={() => history.push('/influencers')} />
            {data.map((d, i) => <div key={`breadCrumbs ${i}`}>
                <span>/</span>
                <div className={d.path || d.callback ? 'listLabel' : 'listName'}
                    onClick={() => d.path ? history.push(d.path) : (d.callback ? d.callback() : null)}
                >
                    {d.label}
                </div>
            </div>)}
        </div>
    )
};

export default BreadCrumbs;