import React from 'react';
import moment from 'moment';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic/src/ckeditor';
import BaseEditor from '@ckeditor/track-changes-and-comments-integration';
import CKEditor from '@ckeditor/ckeditor5-react';
import Api from '../../../modules/Api';
import UploadAdapter from './UploadAdapter';
import './editor.scss';
import { NEEDS_REVIEW } from '../../../constants/statusTypes';
import { BRAND_OWNER_STR, INFLUENCER_STR, SUPER_ADMIN_STR, TALENT_MANAGER_STR } from '../../../constants/authorities';

class Editor extends React.Component {
    constructor(props) {
        super (props);

        this.state = {
            suggestions: {},
            sidebarRefLoaded: false
        };
        this.licenseKey = (process.env.uiconfig && process.env.uiconfig.CKEDITOR_LICENSE_KEY) || '';
        this.sidebarElementRef = React.createRef();
        this.autoSave = this.autoSave.bind(this);
        this.getEditorConfig = this.getEditorConfig.bind(this);
    }

    componentDidUpdate() {
        // This is a trick to rerender component when the ref is loaded.
        // Checking if it did load and change state in 1 sec to fire up the componentDidUpdate again.
        // Do this until the ref loads, because the ref change will not invoke the rerender of the component.
        if ( !this.props.isNew && !this.state.sidebarRefLoaded) {
            setTimeout(() => {
                this.setState({sidebarRefLoaded: this.sidebarElementRef && this.sidebarElementRef.current});
            }, 1000)
        }
    }

    createValidInfluencerObject = ({ id, firstName, lastName, avatar }) => {
        const newUser = {};

        newUser.id = id.toString(); // + influencer identifier
        newUser.name = `${firstName} ${lastName}`;
        newUser.avatar = avatar;

        return newUser;
    }

    createValidUserObject = user => {
        const newUser = { ...user };

        newUser.id = user.id.toString();
        newUser.name = `${user.firstname} ${user.lastname}`;
        newUser.avatar = user.pictureUrl;

        return newUser
    }

    initCollaborators = async (plugin) => {
        const {
            user,
            draftPost,
            assignment,
        } = this.props;

        if (draftPost) {
            const collaborators = await Api.getPostCollaborators(assignment.id, draftPost.id);

            collaborators.map((collaborator) => {
                if (collaborator) {
                    const existUser = plugin.getUser(collaborator.id);
                    if (!existUser) {
                        plugin.addUser(collaborator);
                    }
                }
            });
        }

        if (plugin.users.filter((collaborator) => collaborator && Number(collaborator.id) === user.id).length === 0) {
            const existUser = plugin.getUser(user.id);
            if (!existUser) {
                plugin.addUser(this.createValidUserObject(user));
            }
        }
    }

    async refreshThreadComments(editor, threadId) {
        const usersPlugin = editor.plugins.get('Users');
        const response = await Api.getCommentsThread(this.props.assignment.id, threadId);

        if (response) {
            const thread = { threadId };
            thread.comments = response.map(comment => {
                return {
                    ...comment,
                    id: comment.id,
                    authorId: comment.authorId.toString(),
                    createdAt: new Date(moment.utc(comment.createdOn).local()),
                    createdAtLocal: moment.utc(comment.createdOn).local(),
                    updatedOnLocal: moment.utc(comment.updatedOn).local(),
                    data: {
                        author: usersPlugin.users.filter(user => Number(user.id) === comment.authorId),
                    },
                };
            })
            this.props.saveComments(threadId, thread.comments);
            return Promise.resolve(thread);
        } return null;
    }

    onInit = async (editor) => {
        const { user, isNew } = this.props;
        const usersPlugin = editor.plugins.get('Users');
        const trackChangesPlugin = editor.plugins.get('TrackChanges');
        const commentsPlugin = editor.plugins.get('CommentsRepository');
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => new UploadAdapter(loader);

        // Users initialization.
        await this.initCollaborators(usersPlugin);

        // Set the current user.
        usersPlugin.defineMe(user.id.toString());

        // Track changes adapter initialization.
        trackChangesPlugin.adapter = {
            getSuggestion: async id => {
                const suggestion = await Api.getAssignmentSuggestion(this.props.assignment.id, id)

                const sid = suggestion.data.id;
                const uid = suggestion.id;

                suggestion.data.uid = suggestion.id;
                suggestion.id = suggestion.data.id;
                suggestion.originalSuggestionId = suggestion.data.originalSuggestionId;
                suggestion.createdAt = new Date(moment.utc(suggestion.createdAt).local());
                this.setState({
                    suggestions: {
                        ...this.state.suggestions,
                        [sid]: uid,
                    }
                })

                return Promise.resolve( suggestion )
            },
            addSuggestion: async (params) => {
                params.data = { ...params.data, id: params.id };
                params.postId = this.props.postId;
                delete params.id;

                if (params.originalSuggestionId) {
                    params.data.originalSuggetionId = params.originalSuggestionId;
                }

                try {
                    const response = await Api.addSuggestion(this.props.assignment.id, params);

                    if (user && (user.role === BRAND_OWNER_STR || user.role === SUPER_ADMIN_STR)) {
                        try {
                            await Api.updateDraftPost(
                                this.props.assignment.id,
                                this.props.postId,
                                {
                                    content: editor.getData(),
                                    status: NEEDS_REVIEW,
                                }
                            );
                        } catch (error) {
                            console.log(error);
                        }
                    }
                    const uid = response.id;
                    const {id} = response.data;
                    response.id = response.data.id;
                    response.authorId = response.authorId.toString();
                    response.createdAt = new Date(moment.utc(response.createdAt).local());
                    this.setState({
                        suggestions: {
                            ...this.state.suggestions,
                            [id]: uid,
                        }
                    });

                    return Promise.resolve(response);
                } catch (error) {
                    console.log(error);
                }
            },
            updateSuggestion: async ( id, options ) => {
                const suggestion = await trackChangesPlugin.getSuggestion(id);

                suggestion.id = this.state.suggestions[id];
                suggestion.postId = this.props.postId;
                suggestion.data = {
                    id,
                    ...suggestion.data,
                    ...options,
                };
                delete suggestion.authorId;


                try {
                    const response = await Api.updateSuggestion(this.props.assignment.id, suggestion);
                    if (user && (user.role === BRAND_OWNER_STR || user.role === SUPER_ADMIN_STR)) {
                        await Api.updateDraftPost(
                            this.props.assignment.id,
                            this.props.postId,
                            {
                                content: editor.getData(),
                                status: NEEDS_REVIEW,
                            }
                        );
                    }

                    response.data.uid = response.id;
                    response.id = response.data.id;
                    response.authorId = response.authorId.toString();
                    response.originalSuggestionId = response.data.originalSuggestionId;

                    return Promise.resolve(response);
                } catch (error) {
                    return Promise.reject(error);
                }
            },
        };

        // Comments adapter initialization.
        commentsPlugin.adapter = {
            addComment: async comment => {
                comment.postId = this.props.postId;
                comment.data = {};

                try {
                    const response = await Api.addComment(this.props.assignment.id, comment);
                    return Promise.resolve(response);
                } catch (error) {
                    console.log(error);
                }

                return Promise.reject();
            },
            updateComment: async comment => {
                comment.postId = this.props.postId;
                comment.data = {};

                try {
                    const response = await Api.updateComment(this.props.assignment.id, comment);
                    return Promise.resolve(response);
                } catch (error) {
                    console.log(error);
                }

                return Promise.reject();
            },
            removeComment: async comment => {
                try {
                    const response = await Api.removeComment(this.props.assignment.id, comment.commentId);
                    return Promise.resolve(response);
                } catch (error) {
                    console.log(error);
                }

                return Promise.reject();
            },
            getCommentThread: async ({ threadId }) => {
                return this.refreshThreadComments(editor, threadId);
            },
        };

        if (!this.props.isNew && this.props.draftPost) {
            editor.setData(this.props.draftPost.content);
        }

        // if (this.props.isNew) {
        //     const track = editor.commands.get('trackChanges');
        //     const comment = editor.commands.get('addCommentThread');

        //     track.execute = () => {if (this.props.showWarning) this.props.showWarning()};
        //     comment.execute = () => {if (this.props.showWarning) this.props.showWarning()};
        // }

        editor.isReadOnly = this.props.readOnly;

        if (!isNew)
            editor.plugins.get('Annotations').switchTo( 'wideSidebar' );
    }

    onChange = ( event, editor ) => {
        const {user, saveEditorData, postId, readOnly, changeEditState} = this.props;
        if(editor.getData()) {
            const permission = user && (user.role === INFLUENCER_STR || user.role === TALENT_MANAGER_STR);
            if (postId && permission && !readOnly) {
                let element = document.querySelector(".spinner");
                if (element) {
                    element.classList.remove("hide");
                }
                element = document.querySelector("#indicator");
                if (element) {
                    element.classList.remove("saved");
                }
                if (changeEditState) {
                    changeEditState(false)
                }
            }
        }
        if (typeof saveEditorData === 'function') {
            saveEditorData( editor.getData() );
        }
    }

    async autoSave(editor) {
        const { postId, readOnly, influencerUID, assignment, user, draftPost, saveEditorData, changeEditState } = this.props;
        const role = user && user.role && (user.role === INFLUENCER_STR || user.role === TALENT_MANAGER_STR);
        const status = draftPost && draftPost.status;
        const newContent = editor.getData();
        if (role && draftPost) {
            try {
                const response = await Api.updateDraftPost(
                    assignment.id,
                    postId,
                    {
                        content: newContent,
                        status,
                    },
                    influencerUID
                );
                if (!!response && !readOnly) {
                    let element = document.querySelector(".spinner");
                    if (element) {
                        element.classList.add("hide");
                    }
                    element = document.querySelector("#indicator");
                    if (element) {
                        element.classList.add("saved");
                    }
                    if (typeof saveEditorData === 'function') {
                        saveEditorData(newContent);
                    }
                    if (changeEditState) {
                        changeEditState(true)
                    }
                }

                return Promise.resolve(response);
            } catch (error) {
                let element = document.querySelector(".spinner");
                if (element) {
                    element.classList.add("hide");
                }
                if (!readOnly) {
                    element = document.querySelector("#indicator");
                    if (element) {
                        element.classList.add("saved");
                    }
                }
                if (changeEditState) {
                    changeEditState(true)
                }
                return Promise.reject(error);
            }
        } else {
            let element = document.querySelector(".spinner");
            if (element) {
                element.classList.add("hide");
            }
            if (!readOnly) {
                element = document.querySelector("#indicator");
                if (element) {
                    element.classList.add("saved");
                }
                if (typeof saveEditorData === 'function') {
                    saveEditorData(newContent);
                }
            }
            if (changeEditState) {
                changeEditState(true)
            }
            return Promise.resolve();
        }
    }

    getEditorConfig = (readOnly) => {
        const ckEditorConfig = {
            licenseKey: this.licenseKey,
            sidebar: { container: this.sidebarElementRef.current },
            autosave: { save: readOnly ? null : this.autoSave }
        }

        if (readOnly)
            ckEditorConfig.toolbar = null;

        return ckEditorConfig;
    }

    render() {
        const {
            readOnly,
            user,
            isNew,
            assignment,
        } = this.props;

        if (!assignment || !user ) {
            return null
        }

        return (
            <div className={readOnly ? "editor-container read-only-mode" : "editor-container"}>
                <div className="editor" style={isNew ? {maxWidth: "100%"} : null}>
                    { (this.state.sidebarRefLoaded || isNew) &&
                        <CKEditor
                            editor={BaseEditor}
                            config={this.getEditorConfig(readOnly)}
                            onInit={this.onInit}
                            onChange={this.onChange}
                            onBlur={this.onBlur}
                        />
                    }
                </div>
                { !isNew &&
                    <div ref={ this.sidebarElementRef } className="sidebar" />
                }
            </div>
        )
    }
}


export default Editor
