import React from 'react'
import {connect} from 'react-redux';

import Api from '../../../modules/Api'
import {getAssignmentList} from "../../../store/campaign/actions/campaignActions"
import PleaseWait from '../../shared/pleaseWait/PleaseWaitWhite'
import ApiError from '../../shared/apiError/ApiError'
import AssignmentFields from './AssignmentFields'
import AssignmentResources from './AssignmentResources'
import './assigment.scss'
import * as Honeybadger from 'honeybadger-js';
import * as CancelableService from '../../../modules/utils/CancelableService';
import { AGENT_STR, BRAND_OWNER_STR, SUPER_ADMIN_STR, VIEWER_STR } from '../../../constants/authorities';
import { ACCEPTED, COMPLETED, PAID, PENDING } from '../../../constants/statusTypes';

// Props
// - setNavigationFunction  takes assignmentId
// - delegateAssignmentChangeFunction takes assignment object
// const permissions = ['ROLE_AGENT', 'ROLE_SUPER_ADMIN', 'ROLE_BRAND_OWNER'];

class AssignmentBody extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            detailsFilled: false,
            waiting: false,
            assignment: null
        };

        this.assignmentId = null;
        this.promiseList = [];
    }

    apiError =  (err) => {
        this.setState({
            waiting: false,
            showApiError : true,
            apiError : err
        })
    }

    componentDidMount() {
        if (this.props && this.props.match && this.props.match.params && this.props.match.params.assignmentId) {
            this.props.setNavigationFunction(this.props.match.params.assignmentId);
            this.props.getAssignmentList(this.props.campaignId);
            const {assignmentId} = this.props.match.params;
            const {campaign} = this.props;

            this.setState({waiting: true});
            this.promiseList.push(CancelableService.makeCancelable(Api.getAssignment(assignmentId).then(
                (res) => {
                    Honeybadger.setContext({
                        assignment: res
                    })
                    this.setState({
                        waiting: false,
                        assignment : res
                    })
                },
                this.apiError
            )));
        } else {
            console.log("Assignment Id must be provided!");
        }
    }

    componentWillUnmount() {
        CancelableService.cancelAllPromises(this.promiseList);
    }

    closeApiError = () => {
        this.setState({
            showApiError: false
        })
    }

    assignmentChanged = (revisedAssignment) => {
        this.setState({
            assignment: revisedAssignment,
        })
    }

    render() {
        const {assignment, showApiError, apiError, waiting} = this.state;
        const {profile, advertiserId, campaign} = this.props;

        if (!assignment)
            return null;

        const permission = profile && (profile.role === AGENT_STR || profile.role === SUPER_ADMIN_STR || profile.role === BRAND_OWNER_STR);
        const isViewer = profile && (profile.role === VIEWER_STR);
        const hasAcceptedCreators = assignment.users && assignment.users 
            && assignment.users.filter(au => au.status === ACCEPTED || au.status === PAID || au.status === COMPLETED).length > 0;
        return (
            <div className="assignmentBody">
                <ApiError
                    show={showApiError}
                    error={apiError}
                    cancelFunction={this.closeApiError}
                />
                <PleaseWait
                    show={waiting}
                />
                <div>
                    <AssignmentFields
                        assignment={assignment}
                        permission={permission}
                        delegateAssignmentChangeFunction={this.assignmentChanged}
                        campaign={campaign}
                    />
                </div>
                <div>
                    <div>
                        <AssignmentResources
                            permission={permission}
                            isViewer={isViewer}
                            assignment={assignment}
                            apiErrorFunction={this.apiError}
                            userId={profile ? profile.id : null}
                            hasInvitedCreators={hasAcceptedCreators}
                            delegateAssignmentChangeFunction={this.assignmentChanged}
                            advertiserId={advertiserId}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getAssignmentList: (campaignId) => {
            dispatch(getAssignmentList(campaignId))
        },
    }
};

const mapStateToProps= (state) => {
    return {
        campaignState : state.campaign,
        profile: state.global.loggedInUserProfile,
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AssignmentBody)
