import React from 'react'
import { withRouter } from 'react-router'
import {Motion, spring} from 'react-motion'
import {logout} from "../../../libs/awsLib";
import {agentAdminMenu} from '../../agentAdmin/agentAdminMenu'
import SSOAuth from "../../../modules/Auth";
import ProfileHelper from '../../../modules/utils/ProfileHelper';
import { AGENT_STR, BRAND_OWNER_STR, INFLUENCER_STR, SUPER_ADMIN_STR, TALENT_MANAGER_STR, VIEWER_STR } from '../../../constants/authorities';

const notificationStyles = {
    height: '8px',
    width: '8px',
    backgroundColor: '#F16000',
    display: 'inline-block',
    borderRadius: '50%',
    marginBottom: '7px',
    position: 'absolute'
};

const agentDropdown = agentAdminMenu;
const influencerDropdown = [
    {   label : 'Profile',
        url : '/profile'
    },
    {   label: 'Account Settings',
        url: '/profile/settings'
    },
    {   label: 'Manager Permission',
        url: '/profile/permissions',
        showNotification: false,
    },
    {   label: 'Support',
        url: '/support',
        showNotification: false,
    },
    {   label: 'Logout'
    },
];
const userDropdown = [
    {   label : 'Profile',
        url : '/agentAdmin/profile'
    },
    // {   label: 'Payments',
    //     url: '/agentAdmin/payment'
    // },
    {   label: 'Keyword Groups',
        url: '/keywordGroups'
    },
    // {   label : 'Resources',
    //     url: '/agentAdmin/resources'
    // },
    {   label: 'Support',
        url: '/support',
        showNotification: false,
    },
    {   label: 'Logout'
    },
];
const brandUserDropdown = [
    {   label : 'Profile',
        url : '/agentAdmin/profile'
    },
    {   label: 'User Management',
        url: '/adminManagement'
    },
    {   label: 'Keyword Groups',
        url: '/keywordGroups'
    },
    // {   label: 'Payments',
    //     url: '/agentAdmin/payment'
    // },
    // {   label : 'Resources',
    //     url: '/agentAdmin/resources'
    // },
    {   label: 'Support',
        url: '/support',
        showNotification: false,
    },
    {   label: 'Logout'
    },
];
const viewerDropdown = [
    {   label : 'Profile',
        url : '/agentAdmin/profile'
    },
    {   label: 'Keyword Groups',
        url: '/keywordGroups'
    },
    {   label: 'Support',
        url: '/support',
        showNotification: false,
    },
    {   label: 'Logout'
    },
];
const talentManagerDropdown = [
    {   label : 'Profile',
        url : '/agentAdmin/profile'
    },
    {   label: 'Support',
        url: '/support',
        showNotification: false,
    },
    {   label: 'Logout'
    },
];

class RightSideDropdown extends React.Component {

    constructor (props, context) {
        super(props, context);
    }

    renderedDropdownOption (option, pathname) {
        const style={};
        if (option.url === pathname) {
            style.color = '#00BBAB'
        }
        const clickHandler = () => {
            // if ( typeof option.clickHandler === 'function') {
            //     option.clickHandler();
            // }
            if (option.label.toLowerCase() === 'logout') {
                ProfileHelper.reset();
                const gohome = () => {
                    this.props.history.push('/');
                    this.props.resetAgentBrands();
                    this.props.resetTenantList();
                };
                const ssoToken = localStorage.getItem('sso_access_token');
                if (ssoToken) {
                    SSOAuth.pgLogoutUser(gohome);
                }else {
                    logout(gohome);
                }
            // } else if (option.label.toLowerCase() === 'support') {
            //     let windowFeatures = "menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes";
            //     window.open(option.url, "supportPage", windowFeatures);
            } else {
                this.props.history.push(option.url)
            }
        };

        return (
            <li
                onClick={clickHandler}
                key={option.label}
                style={style}
            >
                { option.label }
                {option.showNotification ? <span style={notificationStyles} className="permission-notification"/> : ''}
            </li>)
    }

    render() {
        if (!this.props.show) {
            return null;
        }
        const {pathname} = this.props.location;
        let dropdownPieces;
        const dropdown = [];

        const {profile} = this.props;
        let classForPlate = '';
        let content = '';

        if (profile.role) {
            if (profile.role === SUPER_ADMIN_STR) {
                classForPlate = 'super-admin';
                content = 'SUPER ADMIN';
                dropdownPieces = agentDropdown;
            } else if (profile.role === BRAND_OWNER_STR) {
                classForPlate = 'brand-user';
                content = 'BRAND OWNER';
                dropdownPieces = brandUserDropdown;
            } else if (profile.role === AGENT_STR) {
                classForPlate = 'user-agent-gradient';
                content = 'user';
                dropdownPieces = userDropdown;
            } else if (profile.role === VIEWER_STR) {
                classForPlate = 'viewer';
                content = 'viewer';
                dropdownPieces = viewerDropdown;
            } else if (profile.role === TALENT_MANAGER_STR) {
                classForPlate = 'talent_manager';
                content = 'Talent Manager';
                dropdownPieces = talentManagerDropdown;
            } else if (profile.role === INFLUENCER_STR) {
                classForPlate = 'talent_manager';
                content = 'Creator';
                if (this.props.showNotification) {
                    influencerDropdown[2].showNotification = true;
                }
                dropdownPieces = influencerDropdown;
            }

            for (const option of dropdownPieces) {
                dropdown.push(this.renderedDropdownOption(option, pathname));
            }
        }


        return (
            <Motion defaultStyle={{x: 8, y:0.01}} style={{x: spring(0), y: spring(1)}}>
                {({x, y}) =>
                    <ul className="dropdown-list" style={{
                        opacity: `${y}`,
                        WebkitTransform: `translate3d(0, ${x}px, 0)`,
                        transform: `translate3d(0, ${x}px, 0)`,
                    }}>
                        {
                            classForPlate ?
                                <li className={`user-plate ${classForPlate}`}>{content}</li>
                                : ''
                        }
                        { dropdown }
                    </ul>
                }
            </Motion>
        );
    }
}

export default withRouter(RightSideDropdown);
