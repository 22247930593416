import React, {Component}from 'react';
import {withRouter} from 'react-router-dom';
import moment from 'moment';
import cx from 'classnames'
import Lookup from "../../../modules/Lookup";
import Api from '../../../modules/Api';

class ListedCampaign extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            signer: '',
            isShowMoreOpen: false,
        }
        this.openCampaignDetailsInNewTab = this.openCampaignDetailsInNewTab.bind(this);
    }

    openCampaignDetailsInNewTab(e) {
        const {campaign} = this.props;
        const detailUrl = `/campaign/${  campaign.id}`;
        e.preventDefault();
        window.open(detailUrl, "_blank")
    }

    handleArchive = e => {
        const { campaign, onSuccess, showError, handleUnarchive } = this.props
        e.stopPropagation();
        if (!campaign.isArchived) {
            Api.archiveCampaign(campaign.id).then(result => {
                onSuccess("This campaign has been archived")
            }).catch(err => {
                showError(err)
            });
        } else {
            handleUnarchive(campaign)
        }
    }

    render() {
        const {campaign, countryList} = this.props;
        const { isShowMoreOpen } = this.state;
        const detailUrl = `/campaign/${  campaign.id}`;
        const signer = `${campaign.signer && campaign.signer.firstname || ''  } ${  campaign.signer && campaign.signer.lastname || ''}`
        const self = this;
        let brandLogo = null;
        if (campaign && campaign.agencyBrand && campaign.agencyBrand.brandLogo) {
            brandLogo = campaign.agencyBrand.brandLogo;
        }
        const brandName = campaign.agencyBrand && campaign.agencyBrand.parentBrand && campaign.agencyBrand.parentBrand.brandName || '';
        const countryLabel = countryList?.find(x => x.value === campaign.agencyBrand.countryCode)?.label
        return (
            <div className="listedCampaign"
                style={campaign.isArchived ? {cursor: "default"} : {}}
                onClick={campaign.isArchived ? null : ()=>{self.props.history.push(detailUrl)}}
                onContextMenu={this.openCampaignDetailsInNewTab}>
                <div className="logo" >
                    <div className="brandContainer">
                        {brandLogo && <img src={brandLogo}/>}
                        {!brandLogo && <div className="temp">{brandName[0]}</div>}
                        <div className="campaignLabel">
                            <div className="brand">{ brandName }</div>
                            <div className="country">{countryLabel}</div>
                        </div>
                    </div>
                </div>

                <div className="name">
                    <div className="value">{ campaign.name}</div>
                </div>

                <div className="assignmentName">
                    <Assignments assignments={campaign.assignments}/>
                </div>
                <div className="contact">
                    { campaign.contacts.map((item,i) => {return (<div key={i}>{`${item.firstname || ''} ${item.lastname || ''}`}</div>)})}
                </div>
                <div className="contact">
                    <div>{signer || ''}</div>
                </div>
                <div className="created">{moment.utc(campaign.createdOn).local().format("MMM DD, YYYY")}</div>
                <div className={cx("action")} onMouseEnter={() => this.setState({isShowMoreOpen: true})} onMouseLeave={() => this.setState({isShowMoreOpen: false})}>
                    {
                        isShowMoreOpen ?
                            <div className="popup">
                                <div onClick={this.handleArchive}>{campaign.isArchived ? "Unarchive" : "Archive"}</div>
                            </div>
                            : ''
                    }
                </div>
            </div>
        );
    }
}

class Assignments extends React.Component{
    constructor(props) {
        super(props);
        this.state={
            showMore:false
        }
    }

    render(){
        const {assignments}=this.props;
        if (assignments.length <= 3) {
            return (
                <div>
                    {assignments.map(assignment => {
                        return (
                            <div key={assignment.id}>{assignment.name}: {assignment.types.map((type,i) =>
                                <span key={i}>{Lookup.getRateType(type) ? Lookup.getRateType(type).label : ""}</span>)} </div>
                        )
                    })}
                </div>
            )
        }
        return(
            <div>
                {assignments.map((assignment,i) => {
                    if (this.state.showMore){
                        return (
                            <div key={assignment.id}>{assignment.name}: {assignment.types.map((type,i)=>
                                <span key={i}>{Lookup.getRateType(type) ? Lookup.getRateType(type).label : ""}</span>)} </div>
                        )
                    }
                    if (i < 3) {
                        return (
                            <div key={assignment.id}>{assignment.name}: {assignment.types.map((type,i)=>
                                <span key={i}>{Lookup.getRateType(type) ? Lookup.getRateType(type).label : ""}</span>)} </div>
                        )
                    }
                        
                })}
                <div className={cx('showMore', {'less':this.state.showMore})}
                    onClick={(e)=>{e.stopPropagation();this.setState({showMore:!this.state.showMore})}}>
                    {this.state.showMore?'- ':'+ '} Show {this.state.showMore?'Less': `${assignments.length-3  } More`}
                </div>
            </div>
        )
        
    }
}

export default withRouter(ListedCampaign);
