import React, { Component } from 'react';
import Routes from './routes';
import { addLocaleData, IntlProvider } from 'react-intl';
import en from 'react-intl/locale-data/en';
import messagesEn from './intl/en';
import './App.scss';

//  later const language = navigator.language.split(/[-_]/)[0];  // language without region code

const flatten = (messagesObj) => {
    let retval = {};

    let addMessage = (key, message) => {
        if (typeof message === 'string') {
            retval[key] = message;
            return;
        }
        if (typeof message === 'object') {
            for (let ix in message) {
                addMessage(key + '.' + ix, message[ix]);
            }
        }
    };

    for (let ix in messagesObj) {
        addMessage(ix, messagesObj[ix]);
    }
    return retval;
};

window.onstorage = function (e) {
    // need to refresh page if user relogin in another tab
    if (e.key === 'amplify-signin-with-hostedUI' && e.newValue === 'false') {
        location.reload();
    }
};

class App extends Component {
    constructor(props) {
        super(props);
        window.plans = true;
    }

    render() {
        return (
            <IntlProvider locale="en" messages={flatten(messagesEn)}>
                <div className="App">
                    <div id="messageContainer" />
                    <div id="modalContainer" />
                    <Routes />
                    <div className="global-footer">
                        Powered By <img src="/images/powered.svg" alt="powerbyLogo" />
                    </div>
                </div>
            </IntlProvider>
        );
    }
}

export default App;
