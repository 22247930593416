import moment from 'moment';
import React from 'react';
import Lookup from '../../../../modules/Lookup';
import Tooltip from '../../tooltip/Tooltip';
import './uploadedContract.scss';

const UploadedContract = ({ preparedFile, creatorName, onContractValueChange, contractValue, downloadFunction }) => {
    const tooltipText = "Please input a dollar amount that corresponds to the total amount specified in the contract. If you are uncertain, please enter a value of '0'.";

    const onContractValueChangeAction = (event) => {
        event.preventDefault();
        const value = event.target.value;
        const isNumber = /^[0-9]+(\.[0-9]+)?$/.test(value);
        if ((isNumber || value === "") && onContractValueChange)
            onContractValueChange(value);
    }

    if (!preparedFile)
        return null;

    return (
        <div className="uploadedContractContainer">
            <div className="uploadedContractFileIcon">
                <img src={Lookup.getFileIcon(preparedFile.name)} />
            </div>
            <div className="uploadedContractText">
                <div className="uploadedContractFileName" onClick={downloadFunction}>
                    {preparedFile.name}
                </div>
                <div className="uploadedContractFileInfo">
                    <div className="uploadedContractFileInfoDate">
                        {moment(preparedFile.createdOn).format("MMM DD, YYYY")}
                    </div>
                    {creatorName && 
                        <div className="uploadedContractFileInfoName">
                            {'By: ' + creatorName}
                        </div>
                    }
                </div>
            </div>
            <div className="uploadedContractValue">
                <div className="uploadedContractValueLabel">
                    Contract Value
                    <Tooltip tooltipText={tooltipText} image="/images/tooltipUser.svg" />
                </div>
                <div className="uploadedContractValueInput">
                    <div className="uploadedContractValueInputDollarSign">
                        $
                    </div>
                    <input 
                        id="uploadContractValueInput" 
                        value={contractValue} 
                        onChange={onContractValueChangeAction} 
                        maxLength={7}/>
                </div>
            </div>
        </div>
    );
}

export default UploadedContract;