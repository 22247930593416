import React, { useState, useEffect } from "react";
import cx from "classnames";
import TopNavigation from "../shared/topNavigation/TopNavigation";
import "./ReleaseNotes.scss";
import ProfileHelper from "../../modules/utils/ProfileHelper";
import {
  TALENT_MANAGER_STR,
  INFLUENCER_STR,
} from "../../constants/authorities";

const ReleaseNotes = () => {
  const [addExtraTop, setAddExtraTop] = useState(false);
  useEffect(() => {
    ProfileHelper.getCurrentLoginUserProfile().then((profile) => {
      if (
        profile &&
        (profile.role === TALENT_MANAGER_STR ||
          profile.role === INFLUENCER_STR) &&
        !profile.accountVerified
      ) {
        setAddExtraTop(true);
      }
    });
  }, []);

  return (
    <div className="releaseNotes">
      <TopNavigation current="support" />
      <div className="opportunity">
        <div
          className={cx("opportunityContainer extra", {
            moreExtra: addExtraTop,
          })}
        >
          <div className="contentDiv">
            <div className="supportPage">
              <div className="spContent">
                <h2>Release Notes</h2>
                <h3>Release V3.7.1: 11/25/2023</h3>
                <h6>FIXES:</h6>
                <ul>
                  <li>Security enhancements</li>
                </ul>
                <h3>Release V3.7: 10/21/2023</h3>
                <h6>NEW FEATURES/Enhancements:</h6>
                <ul>
                  <li aria-level="1">
                    Campaigns
                    <ul>
                      <li aria-level="2">
                        Allow Multiple Offline Contract and Addendum Uploads
                      </li>
                      <li aria-level="2">Contract Value Mandatory Field</li>
                      <li aria-level="2">
                        Ability to Provide Creator Feedback on Media Files
                      </li>
                      <li aria-level="2">
                        Sentiment Analysis: Add Topic Column to Comment List
                        <ul>
                          <li aria-level="2">
                            Add View Rate to Campaign Analytics
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li aria-level="1">
                    Discovery and Creator Profiles
                    <ul>
                      <li aria-level="2">
                        Creator Profile Brand Affinity and Interests Metric -
                        Instagram Only
                      </li>
                    </ul>
                  </li>
                </ul>
                <h6>BUG FIXES:</h6>
                <ul>
                  <li aria-level="1">N/a</li>
                </ul>
                <h3>Release V3.6: 9/16/2023</h3>
                <h6>NEW FEATURES/Enhancements:</h6>
                <ul>
                  <li aria-level="1">
                    <span>Comment Sentiment Analysis Updates V2</span>
                    <ul>
                      <li aria-level="2">Top 5 Topics and its Sentiment</li>
                      <li aria-level="2">Comment summary</li>
                    </ul>
                  </li>
                  <li aria-level="1">Instagram Reel Views Metric</li>
                  <li aria-level="1">
                    Discovery Creator Profile Related Social Channels
                  </li>
                </ul>
                <h6>BUG FIXES:</h6>
                <ul>
                  <li aria-level="1">
                    Comment Sentiment Analysis v2
                    <ul>
                      <li aria-level="1">Relevancy Flag, Filters (Fix)</li>
                      <li aria-level="1">Comment Replies Export (Fix)</li>
                    </ul>
                  </li>
                </ul>
                <h3>Release V3.5: 7/28/2023</h3>
                <h6>NEW FEATURES/Enhancements:</h6>
                <ul>
                  <li aria-level="1">
                    <span>Comment Sentiment Analysis Updates</span>
                    <ul>
                      <li aria-level="2">
                        <span>
                          Allow users to manually update comment sentiment and
                          feed data to AI for training purposes
                        </span>
                      </li>
                      <li aria-level="2">
                        <span>
                          Display product/category relevance in comment list
                        </span>
                      </li>
                      <li aria-level="2">
                        <span>
                          Re-run products and/or category relevancy for comments
                          when campaign products and/or category was updated
                        </span>
                      </li>
                      <li aria-level="2">
                        <span>Displaying English translation</span>
                      </li>
                      <li aria-level="2">
                        <span>Additional filters for comments</span>
                      </li>
                    </ul>
                  </li>
                  <li aria-level="1">
                    <span>Competitive Analysis Updates</span>
                    <ul>
                      <li aria-level="2">
                        <span>
                          Display new post summary section which summarizes the
                          stats (number of posts, engagements, etc)
                        </span>
                      </li>
                      <li aria-level="2">
                        <span>
                          Creator red flagging for creators who have campaigns
                          with competitive brands
                        </span>
                        <ul>
                          <li aria-level="3">
                            <span>Also applied to Discovery</span>
                          </li>
                        </ul>
                      </li>
                      <li aria-level="2">
                        <span>View all creators and view creator profile</span>
                      </li>
                    </ul>
                  </li>
                  <li aria-level="1">
                    <span>
                      Discovery and Planning Only Brands (Limited Access Brands)
                    </span>
                    <ul>
                      <li aria-level="2">
                        <span>
                          Allow different levels of access for brands, full vs.
                          discovery &amp; planning only 
                        </span>
                        <ul>
                          <li aria-level="3">
                            <span>
                              Allow existing brands to switch to discovery &amp;
                              planning only which will archive all existing
                              campaigns
                            </span>
                          </li>
                          <li aria-level="3">
                            <span>
                              Add country in brand management as a new entity to
                              which permission can be assigned
                            </span>
                          </li>
                        </ul>
                      </li>
                      <li aria-level="2">
                        <span>Archiving campaigns</span>
                      </li>
                    </ul>
                  </li>
                  <li aria-level="1">
                    <span>Request a Public Profile Updates</span>
                    <ul>
                      <li aria-level="2">
                        <span>Admin view of submitted requests </span>
                      </li>
                    </ul>
                  </li>
                  <li aria-level="1">
                    <span>Campaign Analytics CSV</span>
                    <ul>
                      <li aria-level="2">
                        <span>Add CSV export for campaign analytics</span>
                      </li>
                    </ul>
                  </li>
                  <li aria-level="1">
                    <span>
                      Saves, Shares, and Branded Engagements for TikTok
                    </span>
                    <ul>
                      <li aria-level="2">
                        <span>
                          New data point for TikTok posts - saves, shares, and
                          branded engagements
                        </span>
                      </li>
                    </ul>
                  </li>
                </ul>
                <h6>BUG FIXES:</h6>
                <ul>
                  <li aria-level="1">
                    Campaign Analytics
                    <ul>
                      <li aria-level="1">Populate follower count</li>
                    </ul>
                  </li>
                </ul>
                <h3>Release V3.4.1: 6/17/2023</h3>
                <h6>NEW FEATURES:</h6>
                <ul>
                  <li>N/a</li>
                </ul>
                <h6>BUG FIXES:</h6>
                <ul>
                  <li aria-level="1">
                    Creator unable to save or submit the edited draft post
                  </li>
                  <li aria-level="1">
                    Social Platform Grammar Correction - (YouTube, TikTok
                    naming)
                  </li>
                  <li aria-level="1">
                    Performance Optimization on Comment Sentiment Analysis
                  </li>
                  <li aria-level="1">
                    Sorting not working correctly on the Saved Search screen
                  </li>
                  <li aria-level="1">
                    Saved Searches not displaying the most recent created search
                  </li>
                </ul>
                <h3>Release V3.4: 6/10/2023</h3>
                <h6>NEW FEATURES:</h6>
                <ul>
                  <li aria-level="1">
                    Campaign Analytics: AI Sentiment Analysis
                  </li>
                  <li aria-level="1">
                    Address support for addresses without postal codes
                  </li>
                  <li aria-level="1">
                    Competitive Brand Intelligence &amp; Monitoring
                    <ul>
                      <li aria-level="1">Hashtag and keyword filters</li>
                    </ul>
                  </li>
                  <li aria-level="1">Request a public profile</li>
                  <li aria-level="1">Engagement rate calculated by views</li>
                </ul>
                <h6>BUG FIXES:</h6>
                <ul>
                  <li aria-level="1">N/a</li>
                </ul>
                <h3>Release V3.3.1: 4/28/2023</h3>
                <h6>NEW FEATURES:</h6>
                <ul>
                  <li aria-level="1">N/a</li>
                </ul>
                <h6>BUG FIXES:</h6>
                <ul>
                  <li aria-level="1">
                    Remediation for v3.3 known limitation - Summary Report not
                    refreshing data post changes to creator list
                  </li>
                  <li aria-level="1">Campaign post resubmit error thrown</li>
                  <li aria-level="1">YouTube campaign analytics</li>
                </ul>
                <h3>Release V3.3: 4/21/2023</h3>
                <h6>NEW FEATURES:</h6>
                <ul>
                  <li aria-level="1">
                    <span>
                      Brand viewer role ability to approve content drafts
                    </span>
                  </li>
                  <li aria-level="1">
                    Competitive brand analysis
                    <ul>
                      <li aria-level="1">Country filter</li>
                      <li aria-level="1">Linked top posts</li>
                      <li aria-level="1">Increased number of top posts</li>
                      <li aria-level="1">Statistics by day on graph</li>
                    </ul>
                  </li>
                </ul>
                <h6>BUG FIXES:</h6>
                <ul>
                  <li aria-level="1">N/a</li>
                </ul>
                <h3>Release V3.2: 4/8/2023</h3>
                <h6>NEW FEATURES:</h6>
                <ul>
                  <li aria-level="1">
                    <span>Campaigns for public creators</span>
                  </li>
                  <li aria-level="1">
                    <span>Competitive brand analysis (Beta)</span>
                  </li>
                  <li aria-level="1">
                    <span>Plans PDF export</span>
                  </li>
                  <li aria-level="1">
                    <span>YouTube Discovery (Beta)</span>
                  </li>
                </ul>
                <h6>BUG FIXES:</h6>
                <ul>
                  <li aria-level="1">N/a</li>
                </ul>
                <h3>Release V3.1.2: 3/10/2023</h3>
                <h6>NEW FEATURES:</h6>
                <ul>
                  <li aria-level="1">
                    <span>
                      Plans Creators: Voting &amp; Notes Added to Creator
                      Slide-Out Profile
                    </span>
                  </li>
                  <li aria-level="1">
                    <span>Dynamic Scale for Followers Growth Graph</span>
                  </li>
                </ul>
                <h6>BUG FIXES:</h6>
                <ul>
                  <li aria-level="1">
                    <span>
                      Added missing ‘create favourite list’ and ‘exclusions
                      list’ button
                    </span>
                  </li>
                  <li aria-level="1">
                    <span>Maps API error</span>
                  </li>
                  <li aria-level="1">
                    <span>
                      Credibility score for connect creators not returned
                    </span>
                  </li>
                  <li aria-level="1">
                    <span>Audience Credibility Grade Filter error</span>
                  </li>
                  <li aria-level="1">
                    <span>Topic Tags combined </span>
                  </li>
                </ul>
                <h3>Release V3.1: 2/24/2023</h3>
                <h6>NEW FEATURES:</h6>
                <ul>
                  <li aria-level="1">
                    <span>Plans</span>
                    <ul>
                      <li aria-level="2">
                        <span>Like/Dislike Creator</span>
                      </li>
                      <li aria-level="2">
                        <span>Creator Notes</span>
                      </li>
                      <li aria-level="2">
                        <span>Summary - additional metrics</span>
                      </li>
                    </ul>
                  </li>
                  <li aria-level="1">
                    <span>Updated default sorting on listing pages</span>
                  </li>
                  <li aria-level="1">
                    <span>Discovery currency support (USD &amp; CAD)</span>
                  </li>
                  <li aria-level="1">
                    <span>Re-label reach to followers (where appropriate)</span>
                  </li>
                  <li aria-level="1">
                    <span>Campaigns search, default sorting update</span>
                  </li>
                  <li aria-level="1">
                    <span>Added sponsored engagement rate</span>
                  </li>
                  <li aria-level="1">
                    <span>Whole numbers included in metrics</span>
                  </li>
                </ul>
                <h6>BUG FIXES:</h6>
                <ul>
                  <li aria-level="1">
                    <span>
                      Added missing ‘create favourite list’ and ‘exclusions
                      list’ button
                    </span>
                  </li>
                  <li aria-level="1">
                    <span>Maps API error</span>
                  </li>
                  <li aria-level="1">
                    <span>
                      Credibility score for connect creators not returned
                    </span>
                  </li>
                  <li aria-level="1">
                    <span>Audience Credibility Grade Filter error</span>
                  </li>
                  <li aria-level="1">
                    <span>Topic Tags combined </span>
                  </li>
                </ul>
                <h3>Release V3.0: 1/27/2023</h3>
                <h6>NEW FEATURES:</h6>
                <ul>
                  <li aria-level="1">
                    <span>Homepage Dashboard</span>
                  </li>
                  <li aria-level="1">
                    <span>Discovery Enhancements</span>
                    <ul>
                      <li aria-level="2">
                        <span>Favourites lists for public creators </span>
                      </li>
                      <li aria-level="2">
                        <span>Exclusions lists</span>
                      </li>
                      <li aria-level="2">
                        <span>Saved Searches</span>
                      </li>
                      <li aria-level="2">
                        <span>Re-ordering of Filters</span>
                      </li>
                    </ul>
                  </li>
                  <li aria-level="1">
                    <span>Currency Support </span>
                    <ul>
                      <li aria-level="2">
                        <span>CAD &amp; USD </span>
                      </li>
                    </ul>
                  </li>
                  <li aria-level="1">
                    <span>Creator Global Sign-Up Address Validation</span>
                  </li>
                  <li aria-level="1">
                    <span>New Planning Section:</span>
                    <ul>
                      <li aria-level="2">
                        <span>Curated Creator List </span>
                      </li>
                      <li aria-level="2">
                        <span>
                          Plan Summary - Including Unique Reach, Forecasting,
                          Aggregate Audience Demographics
                        </span>
                      </li>
                    </ul>
                  </li>
                </ul>
                <h6>BUG FIXES:</h6>
                <ul>
                  <li aria-level="1">
                    <span>Engagement Rate UI Fix</span>
                  </li>
                  <li aria-level="1">
                    <span>Total Reach UI Fix</span>
                  </li>
                  <li aria-level="1">
                    <span>Added Error Handling to Social Handle Filter </span>
                  </li>
                  <li aria-level="1">
                    <span>
                      Enabling Sign Up for Domains Longer than 5 Characters
                    </span>
                  </li>
                  <li aria-level="1">
                    <span>
                      Fix For Broken Display Pictures in Profile/Vetting
                    </span>
                  </li>
                </ul>
                <h3>Release V2.3.2: 12/2/2022</h3>
                <h6>NEW FEATURES:</h6>
                <ul>
                  <li>Public Creator Profile &amp; Vetting</li>
                  <li>Increased Filtering Options</li>
                  <li>Discovery UI Redesign</li>
                  <li>Credibility Score for Public Creators</li>
                  <li>Sponsored Engagement Rate</li>
                </ul>
                <h6>BUG FIXES:</h6>
                <ul>
                  <li>
                    Long Brand Names Blocking Dropdown Button (Brands Page)
                  </li>
                  <li>Brand Handle (Removing Brand Error from Assignments)</li>
                  <li>Connected Creator Recent Posts</li>
                  <li>Engagement Rate UI Fix</li>
                  <li>Updating Campaign Name Error</li>
                </ul>
                <h3>Release V2.3.1: 10/21/2022</h3>
                <h6>NEW FEATURES:</h6>
                <ul>
                  <li>Contact Button for Public Accounts (where available)</li>
                </ul>
                <h6>SYSTEM ENHANCEMENTS:</h6>
                <ul>
                  <li>Assignment Save and Submit Button Copy Changes</li>
                </ul>
                <h6>BUG FIXES:</h6>
                <ul>
                  <li>Email Domain Validation Rules Update</li>
                  <li>TikTok Audience UI Fix</li>
                  <li>Brands Page Pagination Dropdown Fix</li>
                  <li>Visit Creator’s Social Media Page Fix</li>
                  <li>Public Account Search Filters Update</li>
                </ul>
                <h3>Release V2.3: 9/9/2022</h3>
                <h6>NEW FEATURES:</h6>
                <ul>
                  <li>
                    Discovery search for public accounts now have access to over
                    133 million Instagram and over 100 million TikTok profiles
                  </li>
                  <li>
                    Audience insights is now available immediately for most
                    public accounts
                  </li>
                  <li>
                    Engagement rate is now available for TikTok public accounts
                  </li>
                  <li>
                    Top posts &amp; reels are now available for Instagram public
                    accounts
                  </li>
                  <li>Top reels are available for TikTok public accounts</li>
                </ul>
                <h6>SYSTEM ENHANCEMENTS:</h6>
                <ul>
                  <li>N/A</li>
                </ul>
                <h6>BUG FIXES:</h6>
                <ul>
                  <li>N/A</li>
                </ul>
                <h3>Release V2.2.1: 5/13/2022</h3>
                <h6>NEW FEATURES:</h6>
                <ul>
                  <li>
                    View TikTok profile information (follower count, videos,
                    recent posts) for all public accounts and connected accounts
                  </li>
                  <li>
                    View TikTok creators’ audience demographics for all
                    Connected accounts:
                    <ul>
                      <li>
                        View audience insights across 11 categories for all
                        connected accounts by default
                      </li>
                    </ul>
                  </li>
                  <li>
                    TikTok filter: ability to filter based on Tiktok social
                    channel and TikTok audience insights
                  </li>
                  <li>
                    Support TikTok Public Accounts on the platform: Ability to
                    request on demand audience insights for public accounts as
                    well as request new public accounts with audience insights
                    that don't exist on the platform. All requests will be
                    fulfilled within 48 hours.
                  </li>
                </ul>
                <h3>Release V2.2.0: 4/22/2022</h3>
                <h6>SYSTEM ENHANCEMENTS:</h6>
                <ul>
                  <li>
                    Defaulting sort option to “Best Match” for all searches
                  </li>
                  <li>
                    Ability to sort by asc/ dsc for the different sort options
                  </li>
                </ul>
                <h6>BUG FIXES:</h6>
                <ul>
                  <li>
                    Search function: The selection of search options glitched at
                    times. This issue has been resolved.
                  </li>
                  <li>
                    Recent posts: Broken thumbnails were shown for recent posts
                    for certain IG users. This issue has been resolved
                  </li>
                </ul>
                <h3>Release V2.1.9: 4/1/2022</h3>
                <h6>NEW FEATURES:</h6>
                <ul>
                  <li>
                    Engagement rate is now available for IG accounts - Connected
                    and Public
                  </li>
                  <li>Ability to filter creators by their engagement rates</li>
                </ul>
                <h3>Release V2.1.8: 3/11/2022</h3>
                <h6>NEW FEATURES:</h6>
                <ul>
                  <li>
                    Enhancing Creator and Talent Manager onboarding experience:
                    <ul>
                      <li>
                        Provide Creators and Talent Managers with the
                        flexibility to verify their account for up to 7 days
                        after signing up. (Earlier, creators/ talent managers
                        had to verify their account immediately in order to
                        continue onboarding.)
                      </li>
                    </ul>
                  </li>
                </ul>
                <h6>SYSTEM ENHANCEMENTS:</h6>
                <ul>
                  <li>
                    Technical enhancements to make the platform more secure
                  </li>
                </ul>
                <h3>Release V2.1.7: 2/18/2022</h3>
                <h6>NEW FEATURES:</h6>
                <ul>
                  <li>
                    Revoking creator invites:
                    <ul>
                      <li>
                        Platform now allows brand users to withdraw or revoke a
                        creator invite if the status is in "Pending" or
                        "Negotiating"
                      </li>
                    </ul>
                  </li>
                </ul>
                <h6>BUG FIXES:</h6>
                <ul>
                  <li>
                    Issue with offline contract not being available when smart
                    contract has been chosen is fixed
                  </li>
                  <li>
                    Rates could not be 0 (zero): This issue has been fixed
                  </li>
                  <li>
                    Brand users can now be listed in "Contacts" in campaigns
                  </li>
                </ul>
                <h3>Release V2.1.6: 1/31/2022</h3>
                <h6>NEW FEATURES:</h6>
                <ul>
                  <li>
                    Assignment Restructuring:
                    <ul>
                      <li>
                        Platform now supports the ability to customize
                        deliverable type and number of deliverables for every
                        creator that is part of an assignment. Earlier, users
                        had to create multiple assignments if the creators had
                        different deliverables for a social media campaign
                      </li>
                      <li>
                        Removal of assignment calendar section with draft and
                        published posts flights. This is being replaced by a
                        single optional due date field.
                      </li>
                      <li>
                        Ability to include optional personalized notes to
                        creators when inviting creators to an assignment
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    <h6>NUDGE</h6>
                    <ul>
                      <li>
                        Brand/ Agency users can send a quick message to creators
                        within an assignment. This message will be relayed to
                        the creator via email
                      </li>
                    </ul>
                  </li>
                </ul>
                <h3>Release V2.1.5: 1/14/2022</h3>
                <h6>SYSTEM ENHANCEMENTS:</h6>
                <ul>
                  <li>Technical upgrade</li>
                </ul>
                <h3>Release V2.1.4: 12/24/21</h3>
                <h6>NEW FEATURES:</h6>
                <ul>
                  <li>
                    Enhanced Discovery Filters:
                    <ul>
                      <li>
                        Ability for brands to filter creators based on audience
                        insights
                      </li>
                      <li>
                        Categories: Gender, Age, Country, State (US), City
                        (worldwide), Family Status, Ethnicity, Native Language,
                        Brand Affinities
                      </li>
                    </ul>
                  </li>
                </ul>
                <h3>Release V2.1.3: 12/17/21</h3>
                <h6>NEW FEATURES:</h6>
                <ul>
                  <li>
                    Enhanced Discovery - Brands and viewers can now view
                    detailed audience insights of the creators
                    <ul>
                      <li>
                        Ability to view creator's audience insights for all
                        connected accounts across social networks - YouTube,
                        Instagram, Facebook, Twitter
                      </li>
                      <li>
                        Ability to request on-demand audience insights within
                        platform for public accounts
                      </li>
                      <li>
                        Ability to add a public Instagram account by their
                        handle name (that does not exist within Koalifyed) and
                        get audience insights for the same
                      </li>
                    </ul>
                  </li>
                </ul>
                <h6>BUG FIXES:</h6>
                <ul>
                  <li>Ability to preview .mov media files</li>
                  <li>
                    Save &amp; notify: dropdown is showing users that are part
                    of contacts list for the assignment
                  </li>
                </ul>
                <h3>Release V2.1.2: 11/19/21</h3>
                <h6>NEW FEATURES:</h6>
                <ul>
                  <li>
                    Multi-tenancy:
                    <ul>
                      <li>
                        Agencies can use the same account to switch between
                        different tenants (advertisers) to manage campaigns
                        separately
                      </li>
                      <li>
                        Only agencies that have access to the tenant can see the
                        various information pertaining to the campaigns
                      </li>
                      <li>Data will not be shared across different tenants</li>
                    </ul>
                  </li>
                </ul>
                <h6>PERFORMANCE IMPROVEMENTS:</h6>
                <ul>
                  <li>
                    Optimized the performance across the entire platform to
                    improve the user experience
                  </li>
                </ul>
                <h3>Release V2.1.1: 07/24/21</h3>
                <h6>BUG FIXES:</h6>
                <ul>
                  <li>
                    Drafts: Creators are able to delete the media files they
                    uploaded.
                  </li>
                </ul>
                <h3>Release V2.1.0 07/16/21</h3>
                <h6>NEW FEATURES:</h6>
                <ul>
                  <li>
                    Content Editor Flexibility:
                    <ul>
                      <li>
                        Brand users can archive and upload content (media files)
                      </li>
                      <li>
                        Brand users can submit drafts on behalf of creators
                      </li>
                    </ul>
                  </li>
                  <li>
                    TikTok Integration
                    <ul>
                      <li>Creators can add TikTok to their profile</li>
                      <li>Brands can request TikTok deliverable</li>
                    </ul>
                  </li>
                  <li>
                    Internal comments for brand users
                    <ul>
                      <li>
                        Allow brands to communicate with other brand users over
                        draft posts
                      </li>
                    </ul>
                  </li>
                </ul>
                <h3>Release V2.9: 06/25/21</h3>
                <h6>NEW FEATURES:</h6>
                <ul>
                  <li>
                    Vetting:
                    <ul>
                      <li>
                        Allow brand users to set up keyword lists at brand level
                      </li>
                      <li>
                        Allow brands users to vet creator’s Instagram profile by
                        searching for those keywords in all their post captions
                      </li>
                    </ul>
                  </li>
                  <li>
                    Contract Management:
                    <ul>
                      <li>
                        All brands to customize contracts by uploading their
                        word document directly into Docusign
                      </li>
                    </ul>
                  </li>
                </ul>
                <h6>BUG FIXES:</h6>
                <ul>
                  <li>
                    Additional resource files and offline contract files over
                    100 mb are now available for download
                  </li>
                </ul>
                <h3>Release V2.8: 06/04/21</h3>
                <h6>NEW FEATURES:</h6>
                <ul>
                  <li>
                    <span>
                      Security Updates for all uploads/downloads across the
                      platform
                    </span>
                  </li>
                </ul>
                <h3>Release V2.7: 05/07/21</h3>
                <h6>BUG FIXES:</h6>
                <ul>
                  <li aria-level="1">
                    <span>
                      Discovery search using email: Searching for creators using
                      full or partial emails has been fixed
                    </span>
                  </li>
                  <li aria-level="1">
                    <span>
                      Publish Items count: The publish items count is displaying
                      correctly now.
                    </span>
                  </li>
                </ul>
                <h3>Release V2.6: 04/23/21</h3>
                <h6>NEW FEATURES:</h6>
                <ul>
                  <li aria-level="1">
                    <span>Content Editor Video Player Overhaul</span>
                    <ul>
                      <li aria-level="2">
                        <span>
                          Content editor now supports the following video types
                          so they can be viewed within the platform
                        </span>
                        <ul>
                          <li aria-level="3">
                            <span>.m4v</span>
                          </li>
                          <li aria-level="3">
                            <span>.wmv</span>
                          </li>
                          <li aria-level="3">
                            <span>.avi</span>
                          </li>
                          <li aria-level="3">
                            <span>.mkv</span>
                          </li>
                          <li aria-level="3">
                            <span>.mov</span>
                          </li>
                          <li aria-level="3">
                            <span>.webm</span>
                          </li>
                          <li aria-level="3">
                            <span>.mp4</span>
                          </li>
                        </ul>
                      </li>
                      <li aria-level="2">
                        <span>
                          Users also have the ability to view the videos in
                          different resolutions based on preference or device
                          being used
                        </span>
                      </li>
                    </ul>
                  </li>
                  <li aria-level="1">
                    <span>Safari Detected</span>
                    <ul>
                      <li aria-level="2">
                        <span>
                          The platform will now detect if Safari is being used
                          to and prompt the user to switch over to Chrome as
                          some features are unsupported by Safari
                        </span>
                      </li>
                    </ul>
                  </li>
                </ul>
                <h3>Release V2.5: 04/16/21</h3>
                <h6>NEW FEATURES:</h6>
                <ul>
                  <li>
                    <span>Campaign Invites/Negotiation</span>
                    <ul>
                      <li aria-level="1">
                        <span>
                          Invite Note and Negotiation note text box limit
                          Expanded to 2000 characters
                        </span>
                      </li>
                      <li aria-level="1">
                        <span>Paragraph formatting added </span>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span>Error Message Updates</span>
                    <ul>
                      <li aria-level="1">
                        <span>
                          Error messages have been updated to present a more
                          streamlined and user friendly message
                        </span>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span>Discovery</span>
                    <ul>
                      <li aria-level="1">
                        <span>Recent posts added</span>
                      </li>
                      <li aria-level="1">
                        <span>
                          Support for keyword and hashtag search in post
                          descriptions
                        </span>
                      </li>
                      <li aria-level="1">
                        <span>Creator profile pictures added</span>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <span>Content Portal</span>
                  </li>
                  <li>
                    <ul>
                      <li aria-level="1">
                        <span>
                          Support for viewing images in media preview carousel{" "}
                        </span>
                      </li>
                    </ul>
                  </li>
                </ul>
                <h6>BUG FIXES:</h6>
                <ul>
                  <li aria-level="1">
                    <span>Customize Contract - Double Call issue resolved</span>
                    <ul>
                      <li aria-level="2">
                        <span>
                          When customizing a contract, a backend call was being
                          called twice resulting in an error in the second call 
                        </span>
                      </li>
                    </ul>
                  </li>
                  <li aria-level="1">
                    <span>Override Billing Contact issue resolved</span>
                    <ul>
                      <li aria-level="2">
                        <span>
                          Agents with USER role were unable to override the
                          billing contact when approving a post for payment
                        </span>
                      </li>
                    </ul>
                  </li>
                  <li aria-level="1">
                    <span>
                      Assignment Brief - Additional Resources not available for
                      Past Assignments
                    </span>
                    <ul>
                      <li aria-level="2">
                        <span>
                          When viewing a completed assignment, Additional
                          Resources were not available to view or download 
                        </span>
                      </li>
                    </ul>
                  </li>
                  <li aria-level="1">
                    <span>
                      Discovery - Filter button hidden with large search query
                      issue resolved 
                    </span>
                    <ul>
                      <li aria-level="2">
                        <span>
                          When adding a long number of search parameters the
                          search box was hiding the filter button 
                        </span>
                      </li>
                    </ul>
                  </li>
                  <li aria-level="1">
                    <span>
                      Discovery - Fixed text overlap issue with long creator
                      name
                    </span>
                    <ul>
                      <li aria-level="2">
                        <span>
                          If creator’s name was longer than the box the text was
                          overlapping on screen
                        </span>
                      </li>
                    </ul>
                  </li>
                  <li aria-level="1">
                    <span>Missing Negotiation log error fixed</span>
                    <ul>
                      <li aria-level="2">
                        <span>
                          Edge case found in rare situations the negotiation log
                          was not saved properly 
                        </span>
                      </li>
                    </ul>
                  </li>
                  <li aria-level="1">
                    <span>
                      Assignment Complete Email - Incorrect Contact name fixed
                    </span>
                    <ul>
                      <li aria-level="2">
                        <span>
                          Contact was not reflected properly in the ‘Assignment
                          Completed’ email notification
                        </span>
                      </li>
                    </ul>
                  </li>
                </ul>
                <h3>Release V2.4: 03/26/21</h3>
                <h6>BUG FIXES:</h6>
                <ul>
                  <li>
                    Contract Type Mismatch issue resolved
                    <ul>
                      <li>
                        When user duplicated an assignment that had an offline
                        contract set up and changed to use the template, there
                        was an error where contract type was still set as
                        Offline causing an error on creator side
                      </li>
                    </ul>
                  </li>
                  <li>
                    Rate Display issue resolved
                    <ul>
                      <li>
                        Some users were seeing Rate set at $0 when using the
                        ‘Set same rate’ option when inviting multiple
                        influencers
                      </li>
                      <li>
                        Some users were not able to set the rate to $0 when
                        using offline contract upload
                      </li>
                    </ul>
                  </li>
                  <li>
                    Cleaned up unnecessary action buttons for Viewer Role
                    <ul>
                      <li>
                        Viewer role was seeing action buttons that are not
                        allowed
                      </li>
                    </ul>
                  </li>
                  <li>
                    Upload issues resolved
                    <ul>
                      <li>
                        Some users reported issue with uploading a profile
                        picture
                      </li>
                      <li>
                        Some users reported an issue with uploading analytics
                        screenshots
                      </li>
                    </ul>
                  </li>
                  <li>
                    Analytics Screenshots missing issue resolved
                    <ul>
                      <li>
                        Users reported issue with seeing analytics screenshots
                        that creators had uploaded.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Discovered the images were only displaying as a preview to
                    creator and not finalizing upload before creator left screen
                  </li>
                  <li>
                    Discovery search bar layout resolved
                    <ul>
                      <li>
                        When users entered multiple or very long search terms
                        the search box did not expand appropriately
                      </li>
                    </ul>
                  </li>
                </ul>
                <h3>Release V2.3: 03/13/21</h3>
                <h6>NEW FEATURES:</h6>
                <ul>
                  <li>
                    Assignment Setup – Skip Contract Template
                    <ul>
                      <li>
                        Users can now choose to skip setting up a contract
                        template if all offline contracts will be used
                      </li>
                    </ul>
                  </li>
                  <li>
                    Creator Profile – Link to YouTube Channel
                    <ul>
                      <li>
                        The link to a Creator’s YouTube channel is now displayed
                        on their profile and in Discovery screens if the Creator
                        has linked their account
                      </li>
                    </ul>
                  </li>
                  <li>
                    Ability to see Drafts after posts have been published
                    <ul>
                      <li>
                        Users are now able to view the Draft content even after
                        the post has been published
                      </li>
                    </ul>
                  </li>
                  <li>
                    Discovery – Keyword + Hashtag Search for Captions
                    <ul>
                      <li>
                        When keywords or hashtags are searched it returns
                        results found in post captions
                      </li>
                    </ul>
                  </li>
                  <li>
                    Creator Profile UI Updates
                    <ul>
                      <li>
                        Creator’s profile UI has been updated to align with
                        newer design elements
                      </li>
                    </ul>
                  </li>
                </ul>
                <h6>BUG FIXES:</h6>
                <ul>
                  <li>
                    Assignment picker bug fixed
                    <ul>
                      <li>
                        Users reported issue with not being able to switch
                        between assignments from the assignment picker
                      </li>
                    </ul>
                  </li>
                  <li>
                    Brand Settings Save Issue fixed
                    <ul>
                      <li>
                        The “Save” button was hidden on the edit-popup on the
                        Brand screen if more than twenty users were assigned as
                        a Brand Owner
                      </li>
                    </ul>
                  </li>
                  <li>
                    Creator Profile – Location Display issue fixed
                    <ul>
                      <li>
                        The City/State/Country details were not displayed
                        properly on the Location section of some Creator’s
                        profiles
                      </li>
                    </ul>
                  </li>
                  <li>
                    Content Editor – Hidden Changes issue fixed
                    <ul>
                      <li>
                        Some tracked changes or comments were not shown properly
                        in the changes panel and could not be replied to or
                        resolved
                      </li>
                    </ul>
                  </li>
                  <li>
                    Agent Contract Download in Firefox issue resolved
                    <ul>
                      <li>
                        When using Firefox as the browser, the contract did not
                        download or open in the proper format
                      </li>
                    </ul>
                  </li>
                </ul>
                <h3>Release V2.0: 02/13/21</h3>
                <h6>NEW FEATURES:</h6>
                <ul>
                  <li>
                    Expanded Influencer Discovery
                    <ul>
                      <li>
                        Now including non Koalifyed accounts!
                        <ul>
                          <li>
                            Find public profiles of influencers you want to work
                            with
                          </li>
                        </ul>
                        <ul>
                          <li>
                            Search and filter by channel (IG only for v1),
                            follower count, etc.
                          </li>
                        </ul>
                        <ul>
                          <li>
                            Additional features to come in subsequent releases
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        Enhanced search and filter capabilities for Koalifyed
                        accounts
                      </li>
                    </ul>
                  </li>
                  <li>
                    User Management
                    <ul>
                      <li>Improved search and sorting capabilities</li>
                    </ul>
                    <ul>
                      <li>UI Updates</li>
                    </ul>
                  </li>
                  <li>
                    Creator Profile
                    <ul>
                      <li>Expanded gender options</li>
                    </ul>
                  </li>
                  <li>
                    Campaign
                    <ul>
                      <li>Added default sorting</li>
                    </ul>
                  </li>
                  <li>
                    SSO
                    <ul>
                      <li>Add logging for SSO</li>
                    </ul>
                  </li>
                  <li>
                    Overall Updates
                    <ul>
                      <li>Removed requirement for minimum rate</li>
                    </ul>
                    <ul>
                      <li>
                        Updated sorting/filtering for entire data set instead of
                        by page
                      </li>
                    </ul>
                  </li>
                </ul>
                <h6>BUG FIXES:</h6>
                <ul>
                  <li>
                    Content Portal
                    <ul>
                      <li>
                        Creator could navigate directly to draft after
                        submitting and make additional edits
                      </li>
                    </ul>
                    <ul>
                      <li>Error exporting draft content</li>
                    </ul>
                  </li>
                  <li>
                    Analytics / Reporting
                    <ul>
                      <li>
                        Total Cost included cost for creators that declined the
                        assignment
                      </li>
                    </ul>
                  </li>
                  <li>
                    Creator Invites
                    <ul>
                      <li>Decline Invite – intermittent issues</li>
                    </ul>
                    <ul>
                      <li>Status frozen when negotiating rates</li>
                    </ul>
                  </li>
                  <li>
                    Brand Management
                    <ul>
                      <li>Issue adding new brand</li>
                    </ul>
                  </li>
                  <li>
                    Calendar
                    <ul>
                      <li>
                        Draft date not updated on tool tip after new date
                        requested/approved
                      </li>
                    </ul>
                    <ul>
                      <li>User could change dates unexpectedly</li>
                    </ul>
                  </li>
                  <li>
                    Talent Manager
                    <ul>
                      <li>
                        Talent Managers not receiving notifications when manager
                        invites are accepted/rejected
                      </li>
                    </ul>
                    <ul>
                      <li>
                        Pagination issue on Management tab (only applicable if
                        TM has &gt; 20 creators)
                      </li>
                    </ul>
                    <ul>
                      <li>
                        Assignment status not updating to completed once posts
                        were submitted
                      </li>
                    </ul>
                    <ul>
                      <li>
                        Intermittent save issues when editing creator profile
                      </li>
                    </ul>
                    <ul>
                      <li>Send for Review button issues</li>
                    </ul>
                    <ul>
                      <li>Creator does not see Talent Manager invite</li>
                    </ul>
                  </li>
                  <li>
                    Assignment
                    <ul>
                      <li>Long post names hiding the Pay Now button</li>
                    </ul>
                  </li>
                </ul>
                <h3>Release V1.2: 10/16/20 – 02/05/21</h3>
                <h6>
                  <strong>NEW FEATURES:</strong>
                </h6>
                <ul>
                  <li>Upload/Download Video Loader</li>
                  <li>Offline Contract Upload</li>
                  <li>Export Draft Content + Media</li>
                  <li>Talent Manager Enhancements</li>
                  <li>Campaign Signer Display improvements</li>
                  <li>Upgrade Facebook/Instagram API to latest version</li>
                  <li>
                    Content Editor – Save &amp; NotifyAllow users to save
                    content and notify users it is ready for review
                  </li>
                  <li>Create email template – Content Ready for review</li>
                  <li>Update link in template to go directly to post</li>
                  <li>
                    Content Editor – Button UpdatesRename ‘Send Edits’ to ‘Send
                    to Creator’
                  </li>
                  <li>
                    Add confirmation pop-ups when ‘Send’ or ‘Approve’ is
                    selected
                  </li>
                  <li>
                    Update status from In Review to In Progress for consistency
                  </li>
                  <li>Move DocuSign button</li>
                  <li>
                    DocuSign enhancements to support Mobile App development
                  </li>
                  <li>API adjustments for Mobile App development</li>
                  <li>
                    Analytics Tab / Reporting – display records when no
                    analytics captured to showcase all posts in assignment
                  </li>
                </ul>
                <h6>
                  <strong>BUG FIXES:</strong>
                </h6>
                <ul>
                  <li>
                    Talent Manager Issues
                    <ul>
                      <li>Issue submitting drafts</li>
                    </ul>
                    <ul>
                      <li>Shipping address formatting issue</li>
                    </ul>
                    <ul>
                      <li>Update T&amp;C link</li>
                    </ul>
                    <ul>
                      <li>Save Profile issue</li>
                    </ul>
                    <ul>
                      <li>Contract Issues</li>
                    </ul>
                    <ul>
                      <li>Issue submitting published links</li>
                    </ul>
                    <ul>
                      <li>Issue requesting new draft/publish date</li>
                    </ul>
                  </li>
                  <li>
                    DocuSign button still enabled after Creator or Talent
                    Manager signs
                  </li>
                  <li>Creator Sign Up – Temporary password issues</li>
                  <li>Total Cost display issues</li>
                  <li>
                    Content Portal issues
                    <ul>
                      <li>Some changes lost</li>
                    </ul>
                    <ul>
                      <li>Freezing while editing</li>
                    </ul>
                    <ul>
                      <li>Timestamp not reflecting local time</li>
                    </ul>
                  </li>
                  <li>
                    Analytics Tab / Reporting Fixes
                    <ul>
                      <li>Twitter Data incomplete</li>
                    </ul>
                    <ul>
                      <li>YouTube Data incomplete</li>
                    </ul>
                    <ul>
                      <li>Shared metric calculation updates</li>
                    </ul>
                    <ul>
                      <li>PDF Export formatting update</li>
                    </ul>
                    <ul>
                      <li>Engagement Rate calculation update</li>
                    </ul>
                  </li>
                  <li>Creator Draft Edit issues</li>
                  <li>Cannot invite creator from Discover tab search</li>
                  <li>
                    Creator can see draft edits before they are sent back by
                    brand/agency
                  </li>
                  <li>Creator cannot upload profile picture</li>
                  <li>Errors downloading offline contract</li>
                  <li>
                    Analytics Tab / Reporting Issues
                    <ul>
                      <li>Total Count not reflecting correctly</li>
                    </ul>
                    <ul>
                      <li>Screen loading issues</li>
                    </ul>
                    <ul>
                      <li>Issue displaying</li>
                    </ul>
                    <ul>
                      <li>Time Zone display issues</li>
                    </ul>
                    <ul>
                      <li>
                        Ignore extra report parameters to ensure data is
                        captured
                      </li>
                    </ul>
                  </li>
                  <li>Pay Now button showing incorrect details</li>
                  <li>Extend Assignment Dates</li>
                  <li>
                    Allow for multiple deliverables on the same channel in an
                    assignment
                  </li>
                  <li>
                    Link to Support Page &amp; Training Library added to user
                    profile
                  </li>
                  <li>
                    Some Published Posts are not available after the previous
                    release
                  </li>
                  <li>Talent Manager issues reviewing contract</li>
                  <li>Talent Manager issues submitting published posts</li>
                </ul>
                <h3>Release V1.1: 09/25/20 – 10/02/20</h3>
                <h6>
                  <strong>NEW FEATURES:</strong>
                </h6>
                <ul>
                  <li>Contract Template – Add dropdown values</li>
                  <li>Influencer Profile – Add PSE Info</li>
                  <li>Content Portal – Video Indicator/Counter</li>
                  <li>Contracts – Allow influencer to download contract</li>
                  <li>
                    Contract – Add Customize Contract option to action menu
                  </li>
                  <li>
                    Contract Notifications – send email to influencer when agent
                    has made changes to contract
                  </li>
                  <li>
                    Opportunities – Accepting Assignment – Add Contract Review
                    step
                  </li>
                  <li>User Profile – Link to Support Page</li>
                  <li>Influencer Sign Up – Update Sign Up Flow</li>
                  <li>
                    Create email template for Published Post – Approved – sent
                    to influencer
                  </li>
                  <li>
                    Create email template for an Influencer’s update 3 days
                    after a Post has been published
                  </li>
                  <li>Create email template for Contract updated</li>
                  <li>Update email notification to send after 1 week</li>
                  <li>
                    Change all “Influencer” copy to “Creator” or “Creators”
                  </li>
                  <li>
                    Implement the trigger for a notification to an Influencer 3
                    days after the Post publication
                  </li>
                  <li>Custom HTML Maintenance page</li>
                  <li>
                    Remove restriction on Twitter connect for assignment invite
                  </li>
                  <li>Update expiration setting to 14 days</li>
                  <li>Create error message if video is &gt; 10MB</li>
                  <li>SAML / SSO Integration for P&amp;G</li>
                  <li>Video Upload Autosave</li>
                  <li>UI Enhancements for Video upload</li>
                </ul>
                <h6>
                  <strong>BUG FIXES:</strong>
                </h6>
                <ul>
                  <li>Campaign – Assignment list showing deleted assignment</li>
                  <li>Expiration notification sent out every hour</li>
                  <li>
                    Content Editor – Issue seeing content after edits made
                  </li>
                  <li>Video Upload Issues</li>
                  <li>Twitter connect Issue</li>
                  <li>YouTube connect issue</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReleaseNotes;
