import React from "react";
import Modal from "react-modal";
import styles from "./AcceptConfirm.module.scss";
import classNames from "classnames/bind";

const cs = classNames.bind(styles);


export default class AcceptConfirm extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    close = () => {
        this.props.onClose();
    }

    accept = () => {
        this.props.onAccept();
    }

    render() {
        return (
            <Modal
                isOpen={this.props.show}
                className={styles.modal}
                overlayClassName={styles.overlay}
            >
                <div className={styles.container}>
                    <div className={styles.modalBody}>
                        <div className={styles.modalTitle}>Accepting Assignment</div>
                        <div className={styles.modalInfo}>
                            By selecting CONFIRM you  are accepting this assignment for the total payment of
                            <span>
                                ${this.props.paymentValue}
                            </span>
                        </div>
                        <div className={styles.buttonBox}>
                            <div
                                className={styles.buttonConfirm}
                                onClick={this.accept}
                            >
                                Confirm
                            </div>
                            <div
                                className={styles.buttonCancel}
                                onClick={this.close}
                            >
                                Cancel
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}
