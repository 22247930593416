import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router';
import cx from 'classnames';
import TopNavigation from '../shared/topNavigation/TopNavigation';
import Profile from './profile/Profile';
import Resources from './resources/Resources';
import { agentAdminMenu } from './agentAdminMenu';
import ProfileHelper from '../../modules/utils/ProfileHelper';
import { TALENT_MANAGER_STR } from '../../constants/authorities';
import './agentAdmin.scss';

class AgentAdmin extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            current: null,
            profile: null,
        };
    }

    componentDidMount() {
        ProfileHelper.getCurrentLoginUserProfile()
            .then(profile => {this.setState({profile})})
            .catch(err => {
                console.log(err);
            })
    }

    pageTitle = () => {
        const url = this.props.location.pathname;
        const title = 'whatever';
        for (const option of agentAdminMenu) {
            if (option.url === url) {
                name = option.label;
            }
        }
        return name;
    };

    render() {
        const {profile} = this.state;
        const addExtraTop = profile && profile.role === TALENT_MANAGER_STR && !profile.accountVerified;
        return (
            <div className="agentAdmin">
                <TopNavigation />
                <div className={cx("agentAdminHeader", {withVerificationBanner: addExtraTop})}>
                    <div className="contentDiv">
                        <div className="pageTitle">{this.pageTitle()}</div>
                    </div>
                </div>
                <div className={cx("adminContent", {extraContentTop: addExtraTop})}>
                    <div className="contentDiv">
                        <Switch>
                            <Route
                                path={`${this.props.match.path  }/profile`}
                                render={(props) => {
                                    return <Profile />;
                                }}
                            />
                            <Route
                                path={`${this.props.match.path  }/resources`}
                                render={(props) => {
                                    return <Resources />;
                                }}
                            />
                            <Route
                                render={() => {
                                    return <div>unknown admin function</div>;
                                }}
                            />
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(AgentAdmin);
