import React from 'react'
import FreeStringArray from '../../../shared/freeStringArray/FreeStringArray'
import FreeStringArrayDisplayed from '../../../shared/freeStringArray/FreeStringArrayDisplayed'

import './brandAndMore.scss'


/*
 props :
 - editMode
 - saveFlag
 - profile
 - changeFunction
 */

export default class PastBrandNames extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            brands : null,
            brandsLoaded : false
        }
    }

    delegateChange = (brands) => {
        this.setState({brands : brands})
    }

    static getDerivedStateFromProps(props, state) {
        //console.log(props.saveFlag, props.profile.brands);
        let newState = {};
        if (!state.brandsLoaded && Array.isArray(props.profile.brands)) {
            newState.brandsLoaded = true;
            newState.brands = props.profile.brands;
        }
        if (state.editMode && !props.editMode) {
            // finishing edit
            if (props.saveFlag) {
                let newProfile = props.profile;
                newProfile.brands = state.brands;
                props.changeFunction(newProfile)
            } else {
                newState.brands = props.profile.brands;
            }
            newState.saveFlag = props.saveFlag;
        }
        newState.editMode = props.editMode;

        return newState;
    }

    render() {
        if (!this.state.brandsLoaded) {
            return null;
        }
        return(
            <div className="pastBrandNames">
                <div className="title">
                    Past Brands I've worked with
                </div>
                { this.props.editMode &&
                    <div className="editNames">
                        <div>Brands</div>
                        <div>
                            <FreeStringArray
                                strings={this.state.brands}
                                changeFunction={this.delegateChange}
                                placeholder="Type keyword and press Enter"
                            />
                        </div>
                    </div>
                }
                { !this.props.editMode &&
                    <FreeStringArrayDisplayed
                        strings={this.props.profile.brands}
                    />
                }

            </div>
        )
    }
}