import React, { Component } from 'react';
import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { SUPER_ADMIN, SUPER_ADMIN_STR } from '../../../constants/authorities';

class AdvertiserItem extends Component {
    state = {
        showDropdown: false,
        imageError: false
    };

    open = () => this.setState({ showDropdown: true });

    close = () => this.setState({ showDropdown: false });

    handleEdit = () => {
        const {
            advertiser,
            handleEditOpen,
        } = this.props;

        handleEditOpen(advertiser);
    }

    archive = () => {
        const {
            handleArchive,
            advertiser: { id },
        } = this.props;

        handleArchive(id);
    }

    moveToBrands = () => {
        const {
            advertiser: { id },
            moveToBrandsWithFilter,
        } = this.props;

        moveToBrandsWithFilter(id);
    }

    setError = () => {
        this.setState({imageError: true})
    }

    render() {
        const { advertiser, profile } = this.props;
        const { showDropdown, imageError } = this.state;
        return (
            <tr className="table-row">
                <td>
                    <div
                        className="user-info-holder"
                        onClick={this.moveToBrands}
                    >
                        <div className="image-holder">
                            {advertiser.logoUrl && !imageError ? <img
                                src={advertiser.logoUrl}
                                alt={advertiser.name}
                                className="logoClass"
                                onError={this.setError}
                            /> : advertiser.name[0]}
                        </div>
                        <div className="user-info">
                            <div className="user-name">
                                { advertiser.name }
                            </div>
                        </div>
                    </div>
                </td>
                <td className="brands">
                    { advertiser.brands }
                </td>
                <td className="role">
                    { advertiser.createdBy && `${advertiser.createdBy.firstname} ${advertiser.createdBy.lastname}` }
                </td>
                <td className="date">
                    { moment.utc(advertiser.createdOn).local().format('MMMM D, YYYY') } 
                </td>
                {
                    profile && profile.role === SUPER_ADMIN_STR &&
                    <td
                        onMouseOver={this.open}
                        onMouseLeave={this.close}
                    >
                        <div className={cx("action", { hidden: advertiser.isArchived })}>
                            {showDropdown && !advertiser.isArchived ? (
                                <div className="popup">
                                    <div onClick={this.handleEdit}>Edit</div>
                                    <div onClick={this.archive}>Archive</div>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    </td>
                }
            </tr>
        )
    }
}


AdvertiserItem.propTypes = {
    advertiser: PropTypes.shape({
        name: PropTypes.string.isRequired,
        brands: PropTypes.number,
        createdBy: PropTypes.object,
        createdOn: PropTypes.string,
    }).isRequired,
};


export default AdvertiserItem;
