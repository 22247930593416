import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import cx from 'classnames';
import './favoriteDetails.scss';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PleaseWaitWhite from '../shared/pleaseWait/PleaseWaitWhite';
import TopNavigation from "../shared/topNavigation/TopNavigation"
import ApiError from '../shared/apiError/ApiError';
import SuccessBanner from "./SuccessBanner";
import Api from '../../modules/Api';
import PopUpDialogueBase from '../shared/popUpDialogueBase/PopUpDialogueBase';
import { Trash } from 'phosphor-react';
import NewEditModal from './NewEditModal';
import AddFavToPlan from './AddFavToPlan';
import Checkbox from '../shared/checkbox2022/Checkbox';
import OneInfluencer from "../oneInfluencer2022/OneInfluencer";
import { startLoadingLookupTables } from '../../store/global/globalActions'
import AddToActions from './AddToActions';
import BreadCrumbs from './BreadCrumbs';

const Actions = [
    { key: 'edit', label: 'Edit' },
    { key: 'duplicate', label: 'Duplicate' },
    { key: 'delete', label: 'Delete' },
    // 'divider',
    // { key: 'plan', label: 'Add to plan' },
];

const FavoriteDetails = ({
    getLookupTables,
    lookupTablesLoading,
    lookupTables,
    location,
    history,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [apiError, setApiError] = useState();
    const [errTitle, setErrTitle] = useState('');
    const [showAction, setShowAction] = useState(false);
    const [showAddToPlan, setShowAddToPlan] = useState(false);
    const [allSelected, setAllSelected] = useState(false);
    const [favoriteData, setFavoriteData] = useState();
    const [breadCrumbs, setBreadCrumbs] = useState([]);
    const [connectedUsers, setConnectedUsers] = useState([]);
    const [publicUsers, setPublicUsers] = useState([]);
    const [showDelete, setShowDelete] = useState(false);
    const [showFavoriteModal, setShowFavoriteModal] = useState(false);
    const [selectedCount, setSelectCount] = useState(0);
    const containerRef = useRef();
    const favoriteId = useRef();
    const planRef = useRef();
    const dotRef = useRef();

    useLayoutEffect(() => {
        let totalSelected = 0;
        if (connectedUsers.length > 0) {
            totalSelected += connectedUsers.reduce((acc, u) => {
                if (u.checked) {
                    acc++
                }
                return acc;
            }, 0);
        };

        if (publicUsers.length > 0) {
            totalSelected += publicUsers.reduce((acc, u) => {
                if (u.checked) {
                    acc++
                }
                return acc;
            }, 0);
        }

        setSelectCount(totalSelected);
        if (totalSelected === (connectedUsers.length + publicUsers.length) && !allSelected) {
            setAllSelected(true);
        } else {
            setAllSelected(false);
        }
    }, [connectedUsers, publicUsers]);

    useLayoutEffect(() => {
        const crumbs = [
            { label: 'Favourite Lists', path: '/favourite' }
        ];
        if (favoriteData && favoriteData.name) {
            crumbs.push({ label: favoriteData.name })
        }
        setBreadCrumbs(crumbs);

        if (favoriteData && favoriteData.influencers && favoriteData.influencers.length > 0) {
            const connected = favoriteData.influencers.map(user => {
                return {
                    checked: false,
                    details: user,
                    id: user.id || user.uid,
                    invited: '',
                }
            })
            setConnectedUsers(connected || []);
        } else {
            setConnectedUsers([]);
        };

        if (favoriteData && favoriteData.iqUsers && favoriteData.iqUsers.length > 0) {
            const iqs = favoriteData.iqUsers.map(user => {
                return {
                    checked: false,
                    details: user,
                    id: user.id || user.uid,
                    invited: '',
                }
            })
            setPublicUsers(iqs || []);
        } else {
            setPublicUsers([]);
        };
    }, [favoriteData]);

    useEffect(() => {
        if (location && location.pathname) {
            try {
                favoriteId.current = location.pathname.split('/')[2];
                fetchData();
            } catch (err) {

            }
        }
        if (!lookupTablesLoading && !lookupTables) {
            getLookupTables();
        }
        // fetchFavorites();
    }, []);

    const fetchData = () => {
        if (favoriteId.current) {
            setIsLoading(true);

            Api.getFavoriteDetails(favoriteId.current)
                .then(res => {
                    setIsLoading(false);
                    setFavoriteData(res);
                })
                .catch(err => {
                    setIsLoading(false);
                    handleApiError(err);
                });

        };
    };

    const CancelError = () => {
        setApiError(null);
        setErrTitle('');
    };

    const cancelDelete = () => {
        setShowDelete(false);
    };

    const closeModal = () => {
        setShowFavoriteModal(false);
    };

    const onSuccess = (res, update) => {
        setShowSuccess(true);
        if (update) {
            setSuccessMessage('Success! Your list has been updated.');
        } else {
            setSuccessMessage('Success! Your list has been created.');
        }
        fetchData();
    };

    const setSuccess = (message) => {
        setShowSuccess(true);
        setSuccessMessage(message);
        setShowAddToPlan(false);
    };

    const onSubmit = (data) => {
        closeModal();
        setIsLoading(true);
        if (data.id) {
            Api.updateFavorite(data.id, data)
                .then(res => {
                    fetchData();
                    onSuccess(res, true);
                })
                .catch(err => {
                    handleApiError(err);
                    setIsLoading(false);
                })
        } else {
            Api.createFavorite(data)
                .then(res => {
                    fetchData();
                    onSuccess(res, false);
                })
                .catch(err => {
                    handleApiError(err);
                    setIsLoading(false);
                })
        }
    };

    const closeSuccessMessage = () => {
        setShowSuccess(false);
        setSuccessMessage('');
    };

    const duplicateFavourite = () => {
        setIsLoading(true);
        Api.copyFavorite(favoriteData.id)
            .then(res => {
                setIsLoading(false);
                setShowSuccess(true);
                setSuccessMessage('Success! Your file has been successfully duplicated.');
            })
            .catch(err => {
                setIsLoading(false);
                handleApiError(err);
            })
    }

    const deleteFavourite = () => {
        setIsLoading(true);
        setShowDelete(false);
        Api.deleteFavorite(favoriteData.id)
            .then(res => {
                setIsLoading(false);
                history.push('/favourite');
            })
            .catch(err => {
                setIsLoading(false);
                handleApiError(err);
            });
    };

    const onClickMore = (e) => {
        setShowAddToPlan(false);
        setShowAction(!showAction);
    };

    const onClickAdd = (e) => {
        setShowAddToPlan(!showAddToPlan);
        setShowAction(false);
    };

    const handleActions = (action, e) => {
        switch (action) {
            case 'plan':
                setShowAddToPlan(true);
                setShowAction(false);
                break;
            case 'edit':
                setShowAction(false);
                setShowFavoriteModal(true);
                break;
            case 'delete':
                setShowAction(false);
                setShowDelete(true);
                break;
            case 'duplicate':
                setShowAction(false);
                duplicateFavourite();
                break;
        };
    };

    const selectAllHandler = (status) => {
        if (connectedUsers.length > 0) {
            const connected = connectedUsers.map(user => ({ ...user, checked: status }));
            setConnectedUsers(connected);
        }
        if (publicUsers.length > 0) {
            const iqs = publicUsers.map(user => ({ ...user, checked: status }));
            setPublicUsers(iqs)
        }
    };

    const selectionChange = (uid, flag) => {
        let update;
        if (connectedUsers.find(user => user.details.uid === uid)) {
            update = [...connectedUsers];
            update.find(user => user.details.uid === uid).checked = flag;
            setConnectedUsers(update);
        } else {
            update = [...publicUsers];
            update.find(user => user.details.uid === uid).checked = flag;
            setPublicUsers(update);
        }
    };

    const onRemove = () => {
        const connectedUserIds = connectedUsers.filter(user => user.checked).map(u => u.details.id);
        const publicIds = [];
        publicUsers.forEach(user => {
            if (user.checked) {
                publicIds.push({
                    userId: user.details.uid,
                    userType: user.details.userNetworks[0].networkType
                })
            }
        })
        if ((connectedUserIds && connectedUserIds.length > 0) || publicIds.length > 0) {
            setIsLoading(true);
            const payload = {
                connectedUserIds,
                publicUsers: publicIds
            }
            Api.removeUsersFavorite(favoriteId.current, payload)
                .then(res => {
                    fetchData();
                })
                .catch(err => {
                    setIsLoading(false);
                    handleApiError(err);
                })
        }
    };

    const generatePayloadForAdd = () => {
        const connectedUserIds = connectedUsers.filter(user => user.checked).map(u => u.details.id);
        const publicIds = [];
        publicUsers.forEach(user => {
            if (user.checked) {
                publicIds.push({
                    userId: user.details.uid,
                    userType: user.details.userNetworks[0].networkType
                })
            }
        })
        if ((connectedUserIds && connectedUserIds.length > 0) || publicIds.length > 0) {
            const payload = {
                connectedUserIds,
                publicUsers: publicIds
            }
            return payload
        }
        return null;
    };

    const onFavClick = (user) => {
        setIsLoading(true);
        const isConnectedInfluencer = Boolean(user.id);
        const payload = {};
        let updates = [];
        if (isConnectedInfluencer) {
            payload.connectedUserIds = [user.id];
            updates = connectedUsers.filter(c => c.id !== user.id);
        } else {
            payload.publicUsers = [{
                userId: user.uid,
                userType: user.userNetworks[0].networkType
            }];
            updates = publicUsers.filter(c => c.details.uid !== user.uid);
        };

        const updateUsers = () => {
            if (isConnectedInfluencer) {
                setConnectedUsers(updates);
            } else {
                setPublicUsers(updates);
            }
        };

        Api.removeUsersFavorite(favoriteId.current, payload)
            .then(res => {
                updateUsers();
                setIsLoading(false);
            })
            .catch(err => {
                setIsLoading(false);
                handleApiError(err);
            })
    };

    const handleApiError = (err) => {
        setApiError(err);
        if (err && err.originalMessage) {
            setErrTitle(err.originalMessage);
        }
    };

    const clickoutside = (ref, func) => {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    func(false)
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
            // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    clickoutside(planRef, setShowAddToPlan);
    clickoutside(dotRef, setShowAction);

    return (
        <div className='favouriteDetailsContainer'>
            <PleaseWaitWhite
                show={isLoading}
            />
            <ApiError
                show={apiError}
                errTitle={errTitle}
                error={apiError}
                cancelFunction={CancelError}
            />
            <TopNavigation current="influencers" />
            <div className='contentContainer' ref={containerRef}>
                <div className='topWrapper'>
                    <div className='topContainer'>
                        <BreadCrumbs history={history} data={breadCrumbs} />
                        <div className='topRightSection'>
                            {/* <div className='favouriteButtonContainer'>
                                <span>Add creator</span>
                            </div> */}
                            {selectedCount > 0 && <div className={cx('favouriteButtonContainer', { active: showAddToPlan })} onClick={onClickAdd}>
                                <span >Add to plan</span>
                            </div>}
                            <div className={cx('favouriteButtonContainer', { active: showAction })} onClick={onClickMore} style={{ width: 36, paddingRight: 8, paddingLeft: 8 }}>
                                <img src="/images/ic-more-black.svg" />
                                {showAction && <AddToActions data={Actions} onActions={handleActions} reference={dotRef} />}
                            </div>
                        </div>
                        {showAddToPlan && <div className='addToPlan' ref={planRef}>
                            <AddFavToPlan favId={favoriteId.current} setSuccess={setSuccess} payload={generatePayloadForAdd} />
                        </div>}
                    </div>
                </div>
                <div className='userListContainer'>
                    <div className='userListHeader'>
                        <div className="selectAllCheckbox">
                            <Checkbox
                                size={16}
                                changeFunction={selectAllHandler}
                                checked={allSelected}
                                isDisabled={(connectedUsers.length + publicUsers.length) === 0}
                            />
                            <span>{selectedCount === 0 ? `Showing ${connectedUsers.length + publicUsers.length} creator${(connectedUsers.length + publicUsers.length) > 1 ? 's' : ''}`
                                : `Selected ${selectedCount} creator${selectedCount > 1 ? 's' : ''}`}</span>
                        </div>
                        {selectedCount > 0 && <div className='removeButton' onClick={onRemove}>
                            Remove
                        </div>}
                    </div>
                    <div className='userListWrapper'>
                        {lookupTables && connectedUsers.length > 0 && <>
                            <div className='userListLabel'>Connected Creators</div>
                            {connectedUsers.map(
                                ({ checked, details, id, invited }) => (
                                    <OneInfluencer
                                        key={id}
                                        checked={checked}
                                        details={details}
                                        invited={invited}
                                        onFavClick={() => onFavClick(details)}
                                        permission={true}
                                        showFavorite={true}
                                        setSuccess={setSuccess}
                                        selectFunction={selectionChange} // changeSelection
                                    />
                                )
                            )}
                        </>}
                        {lookupTables && publicUsers.length > 0 && <>
                            <div className='userListLabel'>
                                Public Creators
                                {/* <span>Public creators cannot be added to assignments. Please first invite them to Koalifyed to become connect creators.</span> */}
                            </div>
                            {publicUsers.map(
                                ({ checked, details, id, invited }) => (
                                    <OneInfluencer
                                        key={id}
                                        checked={checked}
                                        details={details}
                                        invited={invited}
                                        onFavClick={() => onFavClick(details)}
                                        permission={true}
                                        showFavorite={true}
                                        setSuccess={setSuccess}
                                        selectFunction={selectionChange} // changeSelection
                                    />
                                )
                            )}
                        </>}
                        <div style={{ height: 45 }} />
                    </div>
                </div>
            </div>
            <PopUpDialogueBase
                title={'Confirm delete'}
                show={showDelete}
                icon={<Trash color="#d90036" size={40} weight="bold" />}
                message={<div className="favouriteDeleteMessage">Are you sure you want to delete <span>{`"${favoriteData && favoriteData.name || ''}"`}</span> list?</div>}
                cancelFunction={cancelDelete}
                proceedLabel="Delete"
                // loading={isLoading}
                proceedButtonStyle={{ backgroundColor: '#d90036', width: 100 }}
                cancelButtonStyle={{ width: 100 }}
                proceedFunction={deleteFavourite}
            />
            <NewEditModal
                show={showFavoriteModal}
                data={favoriteData}
                onClose={closeModal}
                onSubmit={onSubmit}
                label={'favourite'}
                placeholder={'Favourite name'}
            />
            <SuccessBanner
                show={showSuccess}
                closeFunction={closeSuccessMessage}
                message={successMessage}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        lookupTables: state.global.lookupTables,
        lookupTablesLoading: state.global.lookupTablesLoading,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getLookupTables: () => dispatch(startLoadingLookupTables())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FavoriteDetails));