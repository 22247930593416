import { call } from 'redux-saga/effects';
import Api from '../../../modules/Api';

export const getCampaignAndAssignmentIdsFromRoute = (router) => {
    // this project has a weird data flow. The fastest way to get these two values - from route url
    const {
        location: {
            pathname,
        },
    } = router;
    const pathnameSplit = pathname.split('/');

    return {
        campaignId: Number(pathnameSplit[2]),
        assignmentId: Number(pathnameSplit[4]),
    };
};

export const getSelectedAssignment = (assignments, assignmentId) => assignments.find(
    ({ value }) => value === assignmentId,
);

export const formatAssignments = (campaignAssignmentList, currentAssignments) => {
    const formattedResults = campaignAssignmentList.map(({ id, name }) => ({
        value: id,
        label: name,
    }));

    return [...currentAssignments, ...formattedResults];
};

export const createEventList = (campaignAssignmentList, assignmentId, currentEventList = []) => {
    const currentAssignment = campaignAssignmentList.find(({ id }) => id === assignmentId);

    return [...currentEventList, ...currentAssignment.assignmentFlights];
};

export const getAssignmentOpportunitiesDetailRequestList = (campaignAssignmentList, assignmentId) => {
    const targetAssignment = campaignAssignmentList.find(({ id }) => id === assignmentId);
    const { users } = targetAssignment;

    const requests = users.map(({ user }) => call(Api.getAssignmentUser, assignmentId, user.uid));

    return requests;
};

export const updateEventListWithDateChangeRequestEvents = (eventList, assignmentOpportunityDetails) => {
    // Here we get assignment details for all influencers attached to it.
    // If any of them has sent date change request - we need to add it to eventList to be displayed on the big calendar.
    // We are interested only in the latest PENDING date change request
    // (as for now [2020-06-30] it can also be APPROVED/DECLINED)
    let updatedEventList = [...eventList];

    assignmentOpportunityDetails.forEach(({ assignmentSlotChangeRequests }) => {
        if (!assignmentSlotChangeRequests.length) {
            return;
        }

        const latestDateChangeRequest = assignmentSlotChangeRequests.slice(-1)[0];

        if (latestDateChangeRequest.status === 'PENDING') {
            updatedEventList = [...updatedEventList, latestDateChangeRequest];
        }
    });

    return updatedEventList;
};
