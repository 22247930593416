import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import './postDetails.scss';
import Select from 'react-select';
import moment from 'moment';
import classNames from 'classnames';
import VideoThumbnail from 'react-video-thumbnail';
import Format from '../../../modules/utils/Format';
import { AGENT, BRAND_OWNER, SUPER_ADMIN, TALENT_MANAGER } from '../../../constants/authorities';
import { safeIsUrl } from '../../../modules/CommonServices';
import { loadThumbnailError } from '../../../modules/utils/CommonUtils';

const tabs = ["Comments"];
class PostDetails extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentTab: "Comments",
        };

    }

    closeError = () =>
        this.setState({
            showApiError: false,
        });



    onChangeTabs(tab) {
        this.setState({
            currentTab: tab
        })
    }


    handlePostChange(e) {
        if (e.value !== this.state.postOption) {
            this.setState({ postOption: e.value })
        }
    }

    onPostClick = (post) => {
        if (safeIsUrl(post.link)) {
            window.open(post.link, '_blank');
        }
    }

    handleCommentsSearch = () => {

    }

    handleCommentsSearchChange = () => {

    }

    handleKeyUp = (keyCode) => {
        if (keyCode === 13) {
            this.handleCommentsSearch();
        }
    };


    render() {
        const { post, keywords, profile } = this.props;
        const { currentTab } = this.state;

        return (
            <div className="postDetails">
                <div className="postKeywordsContainer">
                    {keywords.map((keyWord, index) => {
                        return (
                            <div key={`KeyWord${  index}`} >
                                {keyWord}
                            </div>
                        )
                    })}
                </div>
                <div className="postDetailsContainer">
                    <img src={post.thumbnail ? post.thumbnail : '/images/ic-default-post.svg'} className="thumbnail" onError={loadThumbnailError} />
                    {!profile.id && profile.uid &&
                        <div className="linkButton-public" onClick={() => this.onPostClick(post)}>
                            <img id="expand" src="/images/ic-expand.svg" />
                        </div>
                    }
                    <div className="dateTime">{moment(post.created_time).format('MMM DD, YYYY')} at {moment(post.created_time).format('HH:MMA')}</div>
                    <div className="title" dangerouslySetInnerHTML={{ __html: Format.highlightKeywords(post.text, keywords) }} />
                    {(post.thumbnail || post.link) && <div className="dataWrapper">
                        {post.like_count !== null && <div className="data">
                            <img src="/images/ic-heart-fill.svg" />
                            {post.like_count.toLocaleString()}
                        </div>}
                        {post.comments_count !== null && <div className="data">
                            <img src="/images/ic-comment.svg" />
                            {post.comments_count.toLocaleString()}
                        </div>}
                    </div>}
                    {profile.id &&
                    <div className="navigationContainer">
                        <div className="tabsContainer">
                            {tabs.map(tab => {
                                return (
                                    <div
                                        key={tab}
                                        onClick={() => this.onChangeTabs(tab)}
                                        className={`tab ${currentTab === tab && 'active'}`}
                                    >
                                        {tab}
                                    </div>
                                )
                            })}
                        </div>
                        <div className="linkButton" onClick={() => this.onPostClick(post)}>
                            <img id="expand" src="/images/ic-expand.svg" />
                        </div>
                    </div>
                    }
                </div>
                <div className="postCommentsContainer">
                    {/* <div className="searchCommentsWrapper">
                        <img src="/images/ic-search.svg" onClick={this.handleCommentsSearch} />
                        <input
                            placeholder={"Search comments..."}
                            // ref={inputRef}
                            autoFocus={true}
                            onChange={this.handleCommentsSearchChange}
                            onKeyUp={({ keyCode }) => this.handleKeyUp(keyCode)}
                        />
                    </div> */}
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
});

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(PostDetails);
