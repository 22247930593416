import React from 'react';
import './InputRange.scss';
import cx from 'classnames';
import Format from '../../../modules/utils/Format';

export default class InputRange extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.delegateChange = this.delegateChange.bind(this);
        this.handleFromInput = this.handleFromInput.bind(this);
        this.handleToInput = this.handleToInput.bind(this);
        this.handleInput = this.handleInput.bind(this);

        this.state = {
            fromInput: this.props.options[0] ? this.props.options[0] : '',
            toInput: this.props.options[1] ? this.props.options[1] : '',
            range: [],
            unlimitMax: false,
            errNote: ['', ''],
        };
    }

    inputKeyPressHandler = (e) => {
        if (e.key === 'Enter') {
            this.handleFromInput();
        }
    };

    handleInput(i, e) {
        const { fromInput, toInput } = this.state;
        const { rangeObject, unlimitMax, label, id, type } = this.props;
        let minV = Number(rangeObject.from);
        let maxV = Number(rangeObject.to);
        const errNote = ['', ''];
        let cursorPosition = e.target.selectionStart;
        let vlue = e.target.value.trim();
        if (type === 'percentage' && vlue.length > 1 && cursorPosition + 1 > vlue.length) {
            vlue = vlue.substring(0, vlue.length - 1);
            cursorPosition -= 1;
        }
        vlue = vlue.replace(/[\$%\,]/g, '');

        if (vlue.match(/^\d*$/g)) {
            if (i == 0 && Number(vlue) <= Number(this.props.rangeObject.to)) {
                maxV = Number(toInput) ? Number(toInput) : Number(rangeObject.to);
                if (Number(vlue) < minV) {
                    // errNote[0] = `Minimum ${label? label.toLowerCase():''} is ${minV}`;
                    errNote[0] = `Enter ${label ? label.toLowerCase() : ''} between ${minV}-${maxV}`;
                }
                if (Number(vlue) > maxV) {
                    // errNote[1] = `Maximum ${label? label.toLowerCase():''} is ${maxV}`;
                    errNote[0] = `Enter ${label ? label.toLowerCase() : ''} between ${minV}-${maxV}`;
                    if (unlimitMax && Number(vlue) === Number(rangeObject.to)) {
                        errNote[1] = 'Max value would be unlimited';
                    }
                }

                this.setState(
                    {
                        errNote,
                        fromInput: vlue,
                        toInput: unlimitMax && Number(vlue) === Number(rangeObject.to) ? '' : toInput,
                        unlimitMax: unlimitMax && Number(vlue) === Number(rangeObject.to),
                    },
                    function () {
                        if (type === 'percentage') {
                            const fromInput = document.getElementById(`${id}_from`);
                            if (fromInput) {
                                fromInput.focus();
                                fromInput.setSelectionRange(cursorPosition, cursorPosition);
                            }
                        }
                    },
                );
            } else if (i == 1 && Number(vlue) <= Number(this.props.rangeObject.to)) {
                minV = Number(fromInput) ? Number(fromInput) : Number(rangeObject.from);
                if (Number(vlue) > maxV) {
                    // errNote[1] = `Maximum ${label? label.toLowerCase():''} is ${maxV}`;
                    errNote[1] = `Enter ${label ? label.toLowerCase() : ''} between ${minV}-${maxV}`;
                }
                if (Number(vlue) < minV) {
                    // errNote[1] = `Minimum ${label? label.toLowerCase():''} is ${minV}`;
                    errNote[1] = `Enter ${label ? label.toLowerCase() : ''} between ${minV}-${maxV}`;
                }
                this.setState(
                    {
                        errNote,
                        toInput: vlue,
                    },
                    function () {
                        if (type === 'percentage') {
                            const toInput = document.getElementById(`${id}_to`);
                            if (toInput) {
                                toInput.focus();
                                toInput.setSelectionRange(cursorPosition, cursorPosition);
                            }
                        }
                    },
                );
            }
        }
    }

    handleFromInput() {
        const { fromInput, toInput, unlimitMax } = this.state;
        const { rangeObject } = this.props;
        const minV = Number(rangeObject.from);
        const maxV = Number(toInput) ? Number(toInput) : Number(rangeObject.to);
        let fi = Number(fromInput);
        if (!fromInput || fi < minV || fi > maxV) {
            fi = unlimitMax ? fi : minV;
        }

        this.state.range[0] = fi;
        this.state.range[1] = unlimitMax ? '' : maxV;
        this.setState(
            {
                errNote: '',
                fromInput: fi,
                toInput: unlimitMax ? '' : maxV,
            },
            this.delegateChange(),
        );
    }

    handleToInput() {
        const { fromInput, toInput } = this.state;
        const { rangeObject } = this.props;
        const minV = Number(fromInput) ? Number(fromInput) : Number(rangeObject.from);
        const maxV = Number(rangeObject.to);
        let ti = Number(toInput);
        if (!toInput || ti < minV || ti > maxV) {
            ti = maxV;
        }

        this.state.range[0] = minV;
        this.state.range[1] = ti;
        this.setState(
            {
                errNote: '',
                toInput: ti,
            },
            this.delegateChange(),
        );
    }

    delegateChange() {
        const { unlimitMax } = this.state;
        if (isNaN(this.state.range[0])) {
            this.state.range[0] = parseInt(this.props.rangeObject.from);
        }
        if (this.state.range.length === 1 && !unlimitMax) {
            this.state.range[1] = parseInt(this.props.rangeObject.to);
        }
        /* if (this.state.range[0] === parseInt(this.props.rangeObject.from) && this.state.range[1] === parseInt(this.props.rangeObject.to)) {
            this.state.range = [];
        } */

        const f = this.props.changeFunction;
        if (typeof f === 'function') {
            f(this.state.range);
        }
    }

    reset() {
        this.setState({ fromInput: '', toInput: '', range: [], errNote: '' });
    }

    render() {
        const { fromInput, toInput, unlimitMax, errNote } = this.state;
        const { rangeObject, id, label, type, isOnlyMaxInputVisible, isOnlyMinInputVisible } = this.props;
        const displayError = !!(errNote && errNote.find((err) => err !== ''));
        const fv = Format.formatNumber(fromInput);
        const tv = Format.formatNumber(toInput);
        const inputFromValue = fv + (type === 'percentage' && fv ? '%' : '');
        const inputToValue = tv + (type === 'percentage' && tv ? '%' : '');

        return (
            <div className="inputRangeContainer" id={id} key={id}>
                {label && <div className="inputRangeLabel">{label}</div>}
                <div className="rangeContainer">
                    {!isOnlyMaxInputVisible && (
                        <div className={cx({ displayError: errNote && errNote[0] !== '' })}>
                            <input
                                className={cx({ singleRangeInputVisible: isOnlyMinInputVisible })}
                                id={`${id}_from`}
                                value={inputFromValue}
                                placeholder="Min "
                                onChange={this.handleInput.bind(this, 0)}
                                onBlur={this.handleFromInput}
                                onKeyPress={this.inputKeyPressHandler}
                            />
                        </div>
                    )}

                    {!isOnlyMinInputVisible && !isOnlyMaxInputVisible && 'to'}

                    {!isOnlyMinInputVisible && (
                        <>
                            <div className={cx({ displayError: errNote && errNote[1] !== '' })}>
                                <input
                                    className={cx({ singleRangeInputVisible: isOnlyMaxInputVisible })}
                                    id={`${id}_to`}
                                    value={inputToValue}
                                    placeholder="Max "
                                    disabled={unlimitMax}
                                    onChange={this.handleInput.bind(this, 1)}
                                    onBlur={this.handleToInput}
                                    onKeyPress={this.inputKeyPressHandler}
                                />
                            </div>
                        </>
                    )}
                </div>
                {displayError && (
                    <div className="errorContainer">
                        {errNote.map((err) => (
                            <div>{err}</div>
                        ))}
                    </div>
                )}
            </div>
        );
    }
}
