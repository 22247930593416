import React, {useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Trash } from 'phosphor-react';
import cx from 'classnames';
import _ from 'lodash';
import TopNavigation from '../../shared/topNavigation/TopNavigation';
import ListHeader from './ListHeader';
import Format from '../../../modules/utils/Format';
import PopUpDialogueBase from '../../shared/popUpDialogueBase/PopUpDialogueBase';
import {getDisplayContent} from '../discoverPanel/FilterMenu.helpers';
import Lookup from '../../../modules/Lookup2022';
import PaginationNew from '../../shared/pagination/PaginationNew';
import PleaseWaitWhite from '../../shared/pleaseWait/PleaseWaitWhite';
import ApiError from '../../shared/apiError/ApiError';
import { getData } from '../../../store/campaign/influencerSearch/actionCreators';
import './savedSearches.scss';
import Api from '../../../modules/Api';
import { handleEmptyDataInSavedSearch, updateSavedSearches } from '../../../modules/utils/CommonUtils';

const titles = {
    name: 'Name',
    channel: 'Channel',
    keywords: 'Keywords',
    createdOn: 'Created on',
    updatedOn: 'Modified on',
    numNewUsersFound: 'Updates',
};

const defaultOrders = {
    name: 'desc',
    // keywords: 'desc',
    createdOn: 'desc',
    updatedOn: 'desc',
    numNewUsersFound: 'desc',
};

const SavedSearches = ({history, location, planDetails, lookupFilters, getData}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [apiError, setApiError] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const [sortBy, setSortBy] = useState('updatedOn');
    const [orders, setOrders] = useState(defaultOrders);
    const [searches, setSearches] = useState([]);
    const [publicKeywordFilterTemplates, setPublicKeywordFilterTemplates] = useState([]);
    const [connectedKeywordFilterTemplates, setConnectedKeywordFilterTemplates] = useState([]);
    const [showAction, setShowAction] = useState(null);
    const [showDelete, setShowDelete] = useState(null);
    const [publicFilters, setPublicFilters] = useState([]);
    const [connectedFilters, setConnectedFilters] = useState([]);
    const [totalCountLimited, setTotalCountLimited] = useState(0);
    const [pageLimit, setPageLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [inSavedSearchesPage, setInSavedSearchesPage] = useState(true);
    const [searchableOptions, setSearchableOptions] = useState([]);
    const containerRef = useRef();
    const fetchData = () => {
        setIsLoading(true);
        if (planDetails && planDetails.id) {
            Api.getPlanSavedSearches({ 
                num: pageLimit, 
                page: currentPage, 
                planId: planDetails.id, 
                search: searchValue, 
                sortBy,
                desc: orders[sortBy] === 'desc'
            }).then((res) => {
                setIsLoading(false);
                const contents = handleEmptyDataInSavedSearch(res.content);
                setSearches(contents);
                setTotalCountLimited(res.totalElements)
            })
                .catch(err => {
                    setIsLoading(false);
                    setApiError(err);
                })
        } else {
            Api.getAgentSavedSearches({ 
                num: pageLimit, 
                page: currentPage, 
                search: searchValue, 
                sortBy,
                desc: orders[sortBy] === 'desc'
            }).then((res) => {
                setIsLoading(false);
                const contents = handleEmptyDataInSavedSearch(res.content);
                setSearches(contents);
                setTotalCountLimited(res.totalElements)
            })
                .catch(err => {
                    setIsLoading(false);
                    setApiError(err);
                })
        }
    }

    useEffect(() => {
        Api.initializeSavedSearchSynchronization();
        if (location.pathname.toLowerCase().includes('/savedsearches')) {
            setInSavedSearchesPage(true);
        } else {
            setInSavedSearchesPage(false);
        }
        const pFilters = Lookup.getAllFilters('PUBLIC');
        if (Array.isArray(pFilters) && _.isEmpty(pFilters)) {
            // call filter APIs if not present yet
            getData();
        } else {
            setPublicFilters(pFilters);
            const cFilters = Lookup.getAllFilters('CONNECTED');
            setConnectedFilters(cFilters);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [planDetails, pageLimit, currentPage, searchValue, orders]);

    useEffect(() => {
        if (lookupFilters && Array.isArray(searches)) {
            const temp = [...searchableOptions];
            let pFilters;
            let cFilters;
            if (_.isEmpty(publicFilters)) {
                pFilters = Lookup.getAllFilters('PUBLIC');
                setPublicFilters(pFilters);
                cFilters = Lookup.getAllFilters('CONNECTED');
                setConnectedFilters(cFilters);
            } else {
                pFilters = publicFilters;
                cFilters = connectedFilters;
            }
            searches.forEach(s => {
                if (s?.param?.selectedFilters) {
                    s?.param.selectedFilters.forEach(ps => {
                        if (ps.searchable && temp.find(so => so.id === ps.id) === undefined) {
                            Api.searchFilterOptions({filterId: ps.id, keyword: '', limit: 99999, ignore: true})
                                .then(res => {
                                    if (temp.find(so=> so.id === ps.id) === undefined) {
                                        temp.push({id: ps.id, options: res});
                                        setSearchableOptions(temp);
                                    }
                                })
                                .catch(err => {
                                    setApiError(err);
                                })
                        }
                    })
                } 
            })
            const ret = updateSavedSearches(pFilters, cFilters, searches);
            if (ret) {
                setPublicKeywordFilterTemplates(ret.publicKeywordFilterTemplate);
                setConnectedKeywordFilterTemplates(ret.connectedKeywordFilterTemplate);
                setSearches(ret.searches);
            }
        }
    }, [lookupFilters, searches]);

    useEffect(() => {
        const newSearches = [...searches];
        setSearches(newSearches);
    }, [searchableOptions])

    const searchChange = (value) => {
        setSearchValue(value);
    }

    const handleSortChange = (val) => {
        const update = { ...orders };
        if (sortBy === val) {
            if (orders[val]) {
                update[val] = orders[val] === 'asc' ? 'desc' : 'asc';
                setOrders(update);
            }
        } else {
            setSortBy(val);
        }
        if (Array.isArray(searches)) {
            searches.sort((a, b) => {
                if (a[val] > b[val]) {
                    return orders[val] === 'asc' ? 1 : -1;
                }
                if (a[val] < b[val]) {
                    return orders[val] === 'asc' ? -1 : 1;
                }
                return 0;
            })
            setSearches(searches);
        }
    };

    const onHandleMore = (e, index) => {
        if (showAction === null || (showAction !== null && showAction !== index)) {
            setShowAction(index);
        } else {
            setShowAction(null);
        }
        setShowDelete(null);
        e.stopPropagation();
        e.preventDefault();
    }

    const onActionClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
    }

    const handleDelete = (e, index) => {
        e.stopPropagation();
        e.preventDefault();
        setShowDelete(index);
        setShowAction(null);
    }

    const handleEdit = (e, index) => {
        e.stopPropagation();
        e.preventDefault();
        setShowAction(null);
        const data = Object.assign(searches[index]?.param, {searchableOptions: _.isEmpty(searchableOptions) ? null : searchableOptions});
        history.push({
            pathname: `/influencers${planDetails ? `/plan/${planDetails.id}/${searches[index].id}` : ''}`,
            state: {
                search: {
                    type: searches[index]?.type ?? 'PUBLIC',
                    planName: planDetails && planDetails?.name || '',
                    ...data 
                }
            }
        });
    }

    const handlePageChange = (val) => {
        setCurrentPage(val);
    }

    const handlePageLimitChange = (val) => {
        setPageLimit(val);
        setCurrentPage(1);
        setSearches([]);
    }

    const renderFilters = (search, index) => {
        if (!search.param || !search.param.selectedFilters || _.isEmpty(search.param.selectedFilters)) {
            return null;
        }
        const filterNodes = search.param.selectedFilters.map((filter) => {
            let matchItem;
            if (Array.isArray(search.param.commonFilters)) {
                matchItem = search.param.commonFilters.find(s => s.filterId === filter.id);
            }
            if (!matchItem && Array.isArray(search.param.audienceFilters)) {
                matchItem = search.param.audienceFilters.find(s => s.filterId === filter.id);
            }
            if (!matchItem && Array.isArray(search.param.connectedFilters)) {
                matchItem = search.param.connectedFilters.find(s => s.filterId === filter.id);
            }
            let kFilters = [];
            if (search?.param.type === 'PUBLIC') {
                kFilters = publicKeywordFilterTemplates
            } else if (search?.param.type === 'CONNECTED') {
                kFilters = connectedKeywordFilterTemplates
            }

            if (matchItem) {
                const filterToShow = getDisplayContent(
                    filter, 
                    search?.param.type, 
                    matchItem, 
                    kFilters, 
                    true, 
                    null, 
                    null, 
                    null,
                    null,
                    null, 
                    _.isEmpty(searchableOptions) ? null : searchableOptions);
                return filterToShow;
            }
        })
        
        return (
            <>
                <div className='separator' />
                <div className='searchCardTop'>
                    <div className='name filters'>
                        {`Filter${filterNodes.length > 1 ? 's' : ''}(${filterNodes.length ?? 0})`}
                    </div>
                    <div className='keywords'>
                        {filterNodes}
                    </div>
                </div>
            </>
        );
    }

    const renderSearches = () => {
        if (!Array.isArray(searches) || searches.length === 0) {
            return null;
        }
        
        const contents = searches.map((search, index) => {
            let keywords;
            if (search?.param && 
                (!search?.param?.keywordFilters || 
                !Array.isArray(search?.param?.keywordFilters) ||
                search?.param?.keywordFilters.length === 0)) {
                keywords = [];
            } else if (search?.param) {
                const kFilters = search.param.keywordFilters;
                keywords = kFilters.map((filter, i) => {
                    const subKeywords = filter.keywords.map((word, m) => {
                        return (
                            <div className='box' key={`keyword-${i}-${index}-${m}`}>
                                {word}
                            </div>
                        );
                    })
                    return subKeywords;
                })
            }

            if (search?.param?.selectedFilters?.length > 0) {
                const filterNodes = search.param.selectedFilters.map((filter) => {
                    let matchItem;
                    if (Array.isArray(search.param.commonFilters)) {
                        matchItem = search.param.commonFilters.find(s => s.filterId === filter.id);
                    }
                    if (!matchItem && Array.isArray(search.param.audienceFilters)) {
                        matchItem = search.param.audienceFilters.find(s => s.filterId === filter.id);
                    }
                    if (!matchItem && Array.isArray(search.param.connectedFilters)) {
                        matchItem = search.param.connectedFilters.find(s => s.filterId === filter.id);
                    }
                    let kFilters = [];
                    if (search?.param.type === 'PUBLIC') {
                        kFilters = publicKeywordFilterTemplates
                    } else if (search?.param.type === 'CONNECTED') {
                        kFilters = connectedKeywordFilterTemplates
                    }
        
                    if (matchItem) {
                        const filterToShow = getDisplayContent(
                            filter, 
                            search?.param.type, 
                            matchItem, 
                            kFilters, 
                            true, 
                            null, 
                            null, 
                            null,
                            null,
                            null, 
                            _.isEmpty(searchableOptions) ? null : searchableOptions);
                        return filterToShow;
                    }
                });
                keywords = [...keywords, ...filterNodes];
            }

            const channels = search?.param?.requiredChannels?.length ?
                search.param.requiredChannels.reduce((acc, ch) => {
                    acc += `${acc.length ? ', ' : ''}${ch.charAt(0).toUpperCase() + ch.toLowerCase().slice(1)}`;
                    return acc;
                }, '') : 'All';

            const type = search?.param?.type === 'CONNECTED' && 'Connected Creators' || 'Public Creators';

            let updates = '';
            if (search?.numNewUsersFound && search?.numNewUsersFound > 0) {
                updates = 
                    <div className='updatesDesc'>
                        <div />
                        {`${search?.numNewUsersFound} new creator${search?.numNewUsersFound === 1 ? '' : 's'}`}
                    </div>
            }
            let channelName = channels
            if (channelName) {
                if (channelName === "Tiktok") {
                    channelName = "TikTok"
                }
                if(channelName === "Youtube") {
                    channelName = "YouTube"
                }
            }
            return (
                <div className='searchCard' key={`search-${index}`} onClick={(e) => handleEdit(e, index)}>
                    <div className='searchCardTop'>
                        <div className='name'>
                            {search?.name}
                            <span>{type}</span>
                        </div>
                        <div className='channel'>
                            {channelName}
                        </div>
                        <div className='keywords'>
                            {keywords}
                        </div>
                        <div className='createdOn'>{search?.createdOn ? Format.formatDateAlwaysYear(search?.createdOn) : ''}</div>
                        <div className='updatedOn'>{search?.updatedOn ? Format.formatDateAlwaysYear(search?.updatedOn) : ''}</div>
                        <div className='updates'>
                            {updates}
                            <div className="moreButton" onClick={(e) => onHandleMore(e, index)}>
                                <img src="/images/ic-more.svg" />
                            </div>
                            {showAction === index && <div className='actionsContainer' onClick={onActionClick}>
                                <div onClick={(e) => handleEdit(e, index)}>View</div>
                                <div onClick={(e) => handleDelete(e, index)}>Remove</div>
                            </div>}
                        </div>
                    </div>
                    {/* {renderFilters(search, index)} */}
                </div>
            )
        })

        return contents;
    }

    const cancelDelete = () => {
        setShowDelete(null);
    }

    const deleteSearch = (id) => {
        setShowDelete(null);
        if (id !== null) {
            if (planDetails && planDetails.id) {
                Api.deletePlanSavedSearch(id)
                    .then(() => {
                        fetchData();
                    })
                    .catch(e => {
                        setApiError(e);
                    })
            }else {
                Api.deleteAgentSavedSearch(id)
                    .then(() => {
                        fetchData();
                    })
                    .catch(e => {
                        setApiError(e);
                    })
            }
        }
    }

    const renderDeleteWindow = () => {
        if (showDelete === null) {
            return null;
        }
        return (
            <PopUpDialogueBase
                title="Confirm delete"
                show={showDelete !== null}
                icon={<Trash color="#d90036" size={40} weight="bold" />}
                message={<div className="favouriteDeleteMessage">Are you sure you want to delete <span>{`"${searches[showDelete] && searches[showDelete]?.name}"`}</span> list?</div>}
                cancelFunction={cancelDelete}
                proceedLabel="Delete"
                // loading={isLoading}
                proceedButtonStyle={{ backgroundColor: '#d90036', width: 100 }}
                cancelButtonStyle={{ width: 100 }}
                proceedFunction={() => deleteSearch(searches[showDelete]?.id || null)}
            />
        )
    }

    return (
        <div className={cx('savedSearches', {inSavedSearchesPage})}>
            <PleaseWaitWhite
                show={isLoading}
            />
            <ApiError
                show={apiError}
                error={apiError}
                cancelFunction={() => setApiError(null)}
            />
            <TopNavigation
                current={planDetails ? "plans" : "influencers"}
                // clickHandlerForCurrent={triggerRerender}
            />
            <div className={cx('savedSearchesContentContainer', {inSavedSearchesPageForContainer: inSavedSearchesPage})} ref={containerRef}>
                {inSavedSearchesPage && 
                <div className='savedSearchesTop'>
                    <div className='listPageTopBarContainer'>
                        <span>Saved Searches</span>
                        <div className="listSearch">
                            <input
                                defaultValue={searchValue}
                                placeholder="Search list..."
                                onChange={(e) => searchChange(e.target.value)}
                            />
                            <img src="/images/ic-mag.svg" alt='search'/>
                        </div>
                    </div>
                </div>
                }
                <div className={cx('savedSearchesContent', {inSavedSearchesPageForContent: inSavedSearchesPage})}>
                    <ListHeader
                        orders={orders}
                        sortBy={sortBy}
                        onChange={handleSortChange}
                        titles={titles}
                    />
                    {renderSearches()}
                    <PaginationNew
                        total={totalCountLimited || 0}
                        limit={pageLimit}
                        current={currentPage}
                        handlePageChange={handlePageChange}
                        parentContainer={containerRef.current}
                        limitChangeFunction={handlePageLimitChange}
                    />
                </div>
                {renderDeleteWindow()}
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        lookupFilters: state.filter.lookupFilters,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getData: () => {dispatch(getData())}
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SavedSearches));