import flow from 'lodash/flow';
import uniqueId from 'lodash/uniqueId';
import Lookup from '../../../../modules/Lookup';

const calculateTotalAmount = (types) => types.reduce((acc, { price }) => acc += price, 0);

const ratesFactory = (rates) => (
    flow([
        (ratesArray) => Object.keys(ratesArray),
        (ratesKeys) => ratesKeys.map((key) => ({ label: Lookup.getRateType(key).label, price: rates[key], id: uniqueId() }))
    ])(rates)
)

export default {
    calculateTotalAmount,
    ratesFactory,
}
