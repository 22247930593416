import constants from './constants';

const validateEmail = (email) => {
    const { emailValidation } = constants;
    return emailValidation.test(String(email).toLowerCase());
};

export default {
    validateEmail,
};
