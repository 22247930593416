import React from 'react'

let url = 'http://localhost:9292/resource/test'

export default class UploadTess extends React.Component {

    constructor(props, context) {
        super(props, context);
    }

    handleFileChange = () => {
        this.file = this.inputElement.files[0];
    }

    send = () => {
        let formData = new FormData();
        formData.append('file', this.file);
        formData.append('properties', new Blob([JSON.stringify({
            "name": "root",
            "password": "root"
        })], {
            type: "application/json"
        }));

        fetch(url, {
                method: 'POST',
                body: formData,
                headers: {
                    "Content-Type": undefined
                }
            }
        ).then((res) => { console.log(res)})
    }


    render () {
        console.log('rendering');
        return (<div style={{padding:40}}>

            <input
                ref={(e) => {this.inputElement = e}}
                type="file"
                onChange={this.handleFileChange}
            />
            <p></p>
            <div onClick={this.send}>Send</div>
        </div>)
    }


    //
    //     handleFileChange = () => {
    //         let file = this.inputElement.files[0];
    //         //console.log(file, file.size - 5000000);
    //         if (file.size > 5000000) {
    //             this.showErrorMessage("File too big (> 5mb)");
    //             return;
    //         }
    //         let ext = Lookup.fileExtension(file.name);
    //         let accepted = !!acceptedExtensions.find((e) => {return e === ext})
    //         if (!accepted) {
    //             this.showErrorMessage(ext  + ': Wrong file type');
    //             return;
    //         }
    //         this.setState({
    //             name : file.name,
    //             type : file.type
    //         })
    //         let reader = new FileReader();
    //         let resourceKey = 1;
    //         if (Array.isArray(this.state.newResources)) {
    //             resourceKey = this.state.newResources.length + 1;
    //         }
    //
    //         this.setState({ load : true});
    //
    //         let self = this;
    //         reader.addEventListener("load",
    //             function () {
    //                 let content = reader.result;
    //                 let pieces = content.split(';base64,');
    //                 if (pieces.length == 2) {
    //                     self.sendContent(pieces[1]);
    //                 }
    //             }, false);
    //         reader.readAsDataURL(file);
    //     }
    //
    // }
}
