import React from 'react';
import './nocontentcard.scss';

const NoContentCard = ({message}) => {

    return (
        <div className='noContentContainer'>
            <img src='/images/Graphic_Creators with Social@3x.svg' alt='noCreator' />
            <div>{message}</div>
        </div>
    );
}

export default NoContentCard;