import React, {Component} from 'react';
import PulseLoader from '../../shared/pulseLoader/PulseLoader';
import {connect} from 'react-redux';
import cx from 'classnames'
import {
    addFavourites,
    saveSeveralFavorites,
    createFavorites,
    deleteFavorites,
    searchFavorite,

} from '../../../store/actions/favoriteActionsNew';
import ConfirmModal from '../../shared/confirmModal/ConfirmModal'
import CreateOrEditFavoriteList from './CreateOrEditFavoriteList'
import './favoriteLists.scss'
import { CalendarX } from 'phosphor-react';

/*
 Props
 - show
 - userDetails  ( influencer profile object )
 - closeFunction
 - isOnList - flag, there's at least one list containing the influencer
 */

class FavoriteLists extends Component {
    constructor(props) {
        super(props);

        this.state = {
            creationInProgress : false,
            showFilter : true,
            listState: false,
            inputValue: '',
        };
    }

    flipCreation = (e) => {
        this.setState({
            creationInProgress : !this.state.creationInProgress
        })
    }

    finishCreation = () => {
        this.setState({
            creationInProgress : false
        })
    }

    renderedSpinner = () => {
        if (!this.props.loading || !this.containerElement) {
            return null;
        }
        let loadStyle = {};
        loadStyle.width = this.containerElement.clientWidth;
        loadStyle.height = this.containerElement.clientHeight;
        return (
            <div className="loading">
                <div className="loadItems" style={loadStyle}>
                    <PulseLoader color="#4AA129" size="16px" margin="4px"/>
                </div>
            </div>
        )
    }

    addToList = (list) => {
        const { inviteList, setSuccess, searchFilters } = this.props;
        let newUsers = [];
        const currentLength = list.users.length
        inviteList.map(user => {
            if (searchFilters.type === "PUBLIC") {
                const publicUser = {
                    userId: user.uid,
                    userType: searchFilters.requiredChannels[0].toLowerCase()
                }
                newUsers.push(publicUser)
            } else {
                newUsers.push(user.id)
            }
        })
        const totalAdded = newUsers.length - currentLength
        let newItem = {connectedUserIds: newUsers}
        if (searchFilters.type === "PUBLIC") {
            newItem= {publicUsers: newUsers}
        }
        this.props.addFavourites(list.id, newItem)
        setSuccess(`Success! ${totalAdded} creators have been favorited`)
    }

    renderedList = (list) => {
        //TODO: need to rewrite this portion waiting on API to return whether they are part of a list within the search API
        //what list it needs to will depend on user instead of list
        //also will need to use new api points
        const {inviteList} = this.props
        if (list.combined) {
            return null;  // All Favorites gets added separately
        }

        if (typeof this.state.filter === 'string' && this.state.filter.length > 0) {
            let filter = this.state.filter.toLowerCase();
            if ( list.name.toLowerCase().indexOf(filter) < 0) {
                return null;
            }
        }
        let includesAll = 0;
        inviteList.map(item => {
            if (list.users && !list.users.includes(item.id)) {
                includesAll += 1;
            }
        })
        let selected = includesAll === 0;
        let className = "favoriteList";
        if (selected) {
            className += " selected";
        }

        let handleClick = () => {
            if (!selected) {
                this.addToList(list);
            }
        }

        let deleteList = () => {
            this.props.deleteFavorites(this.state.deleteClicked.id);
            this.setState({
                deleteClicked : null
            })
        }

        let deleteListConfirmMessage = this.state.deleteClicked ?
            'Delete List "' + this.state.deleteClicked.name + '" ?' : ''

        return (<div className={className} key={list.id} onClick={handleClick}>
            <ConfirmModal
                show = {this.state.deleteClicked}
                proceedFunction = {deleteList}
                cancelFunction = {() => { this.setState({
                    deleteClicked : null
                })}}
                message = {deleteListConfirmMessage}
            />
            <div className="favList">
                <div>
                    {list.name}
                </div>
                <div className="check">
                    <img src="/images/ic-checkgreen.svg"  alt="" />
                </div>
            </div>
        </div>)
    }

    filterLists = (e) => {
        let filterValue = e.target.value;
        this.setState({
            filter : filterValue
        })
    }

    setListState = bool => {
        this.setState({listState: bool})
    }

    setInputValue = e => {
        this.setState({inputValue: e.target.value})
    }

    createFav = () => {
        const { inputValue } = this.state;
        const { createFavorites, inviteList, successFavoriteList, setSuccess, searchFilters } = this.props;
        const newUsers = [];
        inviteList.map(user => {
            if (searchFilters.type === "PUBLIC") {
                const publicUser = {
                    userId: user.uid,
                    userType: searchFilters.requiredChannels[0].toLowerCase()
                }
                newUsers.push(publicUser)
            } else {
                newUsers.push(user.id)
            }
        })
        let newItem = {name: inputValue, connectedUserIds: newUsers}
        if (searchFilters.type === "PUBLIC") {
            newItem= {name: inputValue, publicUsers: newUsers}
        }
        createFavorites(newItem)
        setSuccess(`Success! You created list ${inputValue}`)
    }

    searchFilter = e => {
        const { searchFavorite } = this.props;
        const params = {
            "direction": "ASC",
            "keyword": e.target.value,
            "sortBy": "name"
        }
        searchFavorite(params)
    }

    render() {
        const { type, infoToUse } = this.props
        const { listState } = this.state;
        if (!this.props.show || !Array.isArray(this.props.favoriteLists)) {
            return null;
        }
        let lists = [];
        for ( let list of this.props.favoriteLists) {
            lists.push( this.renderedList(list));
        }
        if (infoToUse.length === 0) {
            return (
                <div onClick={ (e) => {e.stopPropagation()}} ref = {(e) => {this.containerElement = e}}>
                    { this.renderedSpinner() }
                    <div className="middleSectionNone">
                        <img src="/images/Graphic_Blank.svg" alt="blank"/>
                        <div className="ohNo">Oh no! </div>
                        <div className="desc">It doesn't look like you have any {type.toLowerCase()}, start by creating one</div>
                    </div>
                    <div className="bottomSection">
                        <div onClick={() => this.setListState(true)} className={cx("createButton", {show: !listState})}><img src="/images/whitePlus.svg" />Create list</div>
                        <div className={cx('expandedCreate', {show: listState})}>
                            <input placeholder="Enter list name" onChange={this.setInputValue} />
                            <div className="create" onClick={this.createFav}>Create</div>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div onClick={ (e) => {e.stopPropagation()}} ref = {(e) => {this.containerElement = e}}>
                <div className="favoriteListsTab">
                    { this.renderedSpinner() }
                    <input className="searchInput"placeholder="Search list..." onChange={this.searchFilter} />
                    <div className="listlist">
                        {lists}
                        </div>
                        <div className="bottomSection">
                            <div onClick={() => this.setListState(true)} className={cx("createButton", {show: !listState})}><img src="/images/whitePlus.svg" />Create list</div>
                            <div className={cx('expandedCreate', {show: listState})}>
                                <input placeholder="Enter list name" onChange={this.setInputValue} />
                                <div className="create" onClick={this.createFav}>Create</div>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        favoriteLists : state.FAVORITE_REDUCER.favoriteData,
        loading: state.FAVORITE_REDUCER.favoriteLoading,
    }
}

function mapDispatchToProps(dispatch){
    return {
        addFavourites: (favId, fav, cb) => dispatch(addFavourites(favId, fav, cb)),
        createFavorites: fav => dispatch(createFavorites(fav)),
        deleteFavorites: id => dispatch(deleteFavorites(id)),
        searchFavorite: param => dispatch(searchFavorite(param))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteLists);