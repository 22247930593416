import React from 'react'

import Format from '../../../modules/utils/Format'
import Lookup from '../../../modules/Lookup'
import RateIcon from '../../shared/rateIcon/RateIcon'
import './rateBox.scss';
import RateInput from './RateInput'

// For one  edited rate. Props :
// - rateKey
// - rateValue
// - changeFunction for the rate, takes key, rate and rateOk flag
// - mandatory : flag for marking missing mandatory rate

export default class RateBox extends React.Component {

    constructor (props, context) {
        super(props, context);

        this.handleRateChange = this.handleRateChange.bind(this);

        this.state = {
            rateEdited: false,
            inputClass: 'rateInputPieces empty',
            details : {}}
    }

    handleRateChange(newRate, errorMessage) {
        this.setState({
            rateEdited: true,
            inputClass: 'rateInputPieces',
            errorMessage: errorMessage
        });

        this.props.changeFunction(this.props.rateKey, newRate, !errorMessage);
    }

    static getDerivedStateFromProps(props, state) {
        let newState = {};
        newState.details = Lookup.getRateType(props.rateKey);
        newState.rerenderCount = state.rerenderCount ? state.rerenderCount + 1 : 1;
        let inputClass = 'rateInputPieces';
        let rateValue = props.rateValue;
        let rateFilled = !isNaN(rateValue) && (Number(rateValue) > 0);
        if (!state.rateEdited && !rateFilled) {
            inputClass += ' empty';
        }
        if (props.mandatory) {
            inputClass += ' missing'
        }
        newState.inputClass = inputClass;
        return newState;
    }

    render () {
        let description = this.state.details.rateDescription;
        const { symbol } = this.props;
        return (
            <div>
              <div className="rateBox">
                    <div>
                        <RateIcon size="35" rateKey={this.props.rateKey} value={1}/>
                    </div>
                    <div>
                        <div className={`title${this.props.required ? ' required' : ''}`}>
                            { this.state.details.label }
                        </div>
                        <div className="description">
                            { description }
                        </div>
                    </div>
                    <div>
                        <RateInput
                            rateValue={this.props.rateValue}
                            required={this.props.required}
                            changeFunction={this.handleRateChange}
                            inputClass={this.state.inputClass}
                            suggestion={this.state.details.suggested}
                            alternativeRateInput={this.props.alternativeRateInput}
                            minimumRate={this.props.minimumRate}
                            symbol={symbol}
                        />
                    </div>
                </div>
                <div style={{color:'#e5004d', textAlign:'right', position:'relative', top: -10}}>
                    {this.state.errorMessage}
                </div>
            </div>
    );
    }
}
