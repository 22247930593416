import { call, put, select } from 'redux-saga/effects';
import {
    setSearchFilters,
    setShowDiscovery,
} from '../actionCreators';
import influencersSaga from './influencersSaga';

export default function* searchFiltersChangeSaga({ newFilters }) {
    const { showDiscovery } = yield select(({ campaignReducer }) => ({
        showDiscovery: campaignReducer.influencerSearch.showDiscovery,
    }));
    yield put(setSearchFilters(newFilters));

    // if (newFilters.length && showDiscovery) {
        if (showDiscovery) {
            /*
            This one closes ListsSplash once not empty filter was selected
         */
        yield put(setShowDiscovery(false));
    }

    yield call(influencersSaga);
}
