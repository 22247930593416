import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { withRouter } from 'react-router';
import Api from '../../../modules/Api';
import Lookup from '../../../modules/Lookup';
import PleaseWaitWhite from '../../shared/pleaseWait/PleaseWaitWhite';
import ApiError from '../../shared/apiError/ApiError';

const CreateDraftModal = (props) => {
    const { campaign, close, selectedAssignments, history } = props;
    const [isFetching, setIsFetching] = useState(false);
    const [chosenAssignmentUser, setChosenAssignmentUser] = useState(null);
    const [chosenAssignment, setChosenAssignment] = useState();
    const [deliverables, setDeliverables] = useState();
    const [availableUsers, setAvailableUsers] = useState([]);
    const [chosenPostType, setChosenPostType] = useState();
    const [existingDrafts, setExistingDrafts] = useState(null);
    const [apiError, setApiError] = useState(null);

    if (!campaign || !selectedAssignments || selectedAssignments.length === 0) return null;

    useEffect(() => {
        if (Array.isArray(campaign.assignments)) {
            const matchAssignment = campaign.assignments.find((a) => a.id === selectedAssignments[0].value);
            if (matchAssignment) {
                setIsFetching(true);
                setChosenAssignment(matchAssignment);
                Api.getAssignment(matchAssignment.id)
                    .then((response) => {
                        if (Array.isArray(response.users)) {
                            return composeAvailableUsers(response.users, matchAssignment).then((composedUsers) => {
                                setAvailableUsers(composedUsers);
                                setIsFetching(false);
                            });
                        } else {
                            setIsFetching(false);
                        }
                    })
                    .catch(() => setIsFetching(false));
            }
        }

        document.getElementsByClassName('campaignWorkBody')[0].style.overflowY = 'hidden';
        return () => {
            document.getElementsByClassName('campaignWorkBody')[0].style.overflowY = 'auto';
        };
    }, []);

    useEffect(() => {
        if (chosenAssignmentUser && availableUsers && Array.isArray(availableUsers)) {
            const matchUser = availableUsers.find((u) => u.id === chosenAssignmentUser.id);
            if (matchUser && matchUser.user && matchUser.user.uid) {
                setIsFetching(true);
                Api.getAssignmentUser(chosenAssignment.id, matchUser.user.uid)
                    .then((res) => {
                        setIsFetching(false);
                        if (res && Array.isArray(res.userDraftPosts)) {
                            setExistingDrafts(res.userDraftPosts);
                        }
                        if (res && res.deliverables) {
                            setDeliverables(res.deliverables);
                        }
                    })
                    .catch((err) => {
                        setIsFetching(false);
                        setApiError(err);
                    });
            }
        }
    }, [chosenAssignmentUser]);

    const availablePosts = [];
    if (deliverables) {
        Object.entries(deliverables).forEach(([k, v]) => {
            let draftCount = 0;
            if (Array.isArray(existingDrafts)) {
                // now the userDraftPosts contain both published posts and drafts
                const tempList = existingDrafts.filter((draftPost) => draftPost.type === k);
                draftCount = tempList.length;
            }
            for (let count = 0; count < v - draftCount; count++) {
                const temp = {};
                temp.label = `${Lookup.getRateType(k).label} Post (${count + 1}/${v})`;
                temp.value = k;
                availablePosts.push(temp);
            }
        });
    }

    const composeAvailableUsers = async (users, matchAssignment) => {
        let composedUsers = [];
        for (let u of users) {
            if (u.status === 'ACCEPTED') {
                if (u.contractStatus === 'SIGNED_OFFLINE') {
                    const obj = { ...u };
                    obj.assignmentId = matchAssignment.id;
                    obj.assignmentName = matchAssignment.name;
                    obj.value = u.id;
                    obj.label = `${u.user.firstname} ${u.user.lastname}`;
                    composedUsers.push(obj);
                } else {
                    try {
                        const contract = await Api.contractEnvelopeStatus(Number(matchAssignment.id), u.user.uid);
                        const { signers = [] } = contract;
                        const influencerContract = signers.find(({ email }) => email === u.user.email);
                        const adminContract = signers.findIndex(({ email }) => email === campaign.agent.email);
                        const contractStatus = contract.error ||
                        (influencerContract > -1 && signers[influencerContract].status !== 'completed') ||
                        adminContract < 0 ||
                        (adminContract > -1 && signers[adminContract].status === 'completed');
                        if (influencerContract && influencerContract.status === 'completed' && contractStatus) {
                            const obj = { ...u };
                            obj.assignmentId = matchAssignment.id;
                            obj.assignmentName = matchAssignment.name;
                            obj.value = u.id;
                            obj.label = `${u.user.firstname} ${u.user.lastname}`;
                            composedUsers.push(obj);
                        }
                    } catch (error) {
                        console.log('Error while checking the user contract status: ' + JSON.stringify(error));
                    }
                }
            }
        }

        return composedUsers;
    };

    const onSubmit = () => {
        if (chosenAssignmentUser) {
            history.push({
                pathname: `/assignment/${chosenAssignmentUser.assignmentId}/draftCreate/${chosenAssignmentUser.user.uid}/${chosenPostType.value || ''}`,
            });
        }
    };

    const onClose = () => {
        if (!chosenAssignmentUser && availableUsers.length > 0) {
            setAvailableUsers([]);
        }
        setChosenAssignmentUser(null);
        setExistingDrafts(null);
        setDeliverables(null);
        close();
    };

    return (
        <div id="create-draft-modal" key="create-draft-modal" className="modalOverlay" onClick={onClose}>
            <div className="modalContent" onClick={(event) => event.stopPropagation()}>
                <PleaseWaitWhite show={isFetching} />
                <ApiError show={apiError} error={apiError} cancelFunction={() => setApiError(null)} />
                <div>
                    <div className="modalTitle">Create Post Draft</div>
                    <div className="label">Influencer</div>
                    <Select
                        clearable={false}
                        value={chosenAssignmentUser}
                        single
                        placeholder="Select Influencer"
                        options={availableUsers}
                        onChange={setChosenAssignmentUser}
                    />
                    <>
                        <div className="label">Post</div>
                        <Select
                            clearable={false}
                            value={chosenPostType}
                            single
                            placeholder="Select Post"
                            options={availablePosts}
                            onChange={setChosenPostType}
                        />
                        {chosenAssignmentUser && availablePosts.length === 0 && (
                            <div className="error-message">Chosen influencer already submitted all the drafts.</div>
                        )}
                    </>
                </div>
                <div id="buttons" className="buttons">
                    <div className="button" onClick={onClose} role="button">
                        Cancel
                    </div>
                    <div
                        className="button proceedButton"
                        style={chosenAssignmentUser && chosenPostType ? {} : { opacity: '0.5', cursor: 'auto' }}
                        onClick={onSubmit}
                        role="button"
                    >
                        Create Draft
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(CreateDraftModal);
