import SocialNetworks from './SocialNetworks'

export default class ProfileUtils {

    static getMissingRates = (profile) => {
        let rateOk = (ratekey) => {
            if (! profile.rates[ratekey]) {
                return false;
            }
            if (! profile.rates[ratekey] > 0) {
                return false;
            }
            return true;
        }

        let missingRates = {};

        for ( let network of profile.userNetworks) {
            let rateTypes = SocialNetworks.getRateTypes(network.networkType);
            if (typeof rateTypes === 'string' && !rateOk(rateTypes)){
                missingRates[rateTypes] = true;
            }
            if (Array.isArray(rateTypes)) {
                let rateFound = false;
                for ( let rtype of rateTypes) {
                    if (rateOk(rtype)) {
                        rateFound = true;
                    }
                }
                if (!rateFound) {
                    for ( let rtype of rateTypes) {
                        missingRates[rtype] = true;
                    }
                }
            }
        }

        return missingRates;
    }

    static getMissingRatesFromOtherRates = (profile, rates) => {
        let rateOk = (ratekey) => {
            if (! rates[ratekey]) {
                return false;
            }
            if (! rates[ratekey] > 0) {
                return false;
            }
            return true;
        }

        let missingRates = {};

        for ( let network of profile.userNetworks) {
            let rateTypes = SocialNetworks.getRateTypes(network.networkType);
            if (typeof rateTypes === 'string' && !rateOk(rateTypes)){
                missingRates[rateTypes] = true;
            }
            if (Array.isArray(rateTypes)) {
                let rateFound = false;
                for ( let rtype of rateTypes) {
                    if (rateOk(rtype)) {
                        rateFound = true;
                    }
                }
                if (!rateFound) {
                    for ( let rtype of rateTypes) {
                        missingRates[rtype] = true;
                    }
                }
            }
        }

        return missingRates;
    }


    static hasMissingRates = (profile) => {
        let missing = ProfileUtils.getMissingRates(profile);
        for (let rt in missing) {
            if ( missing[rt]) {
                return true;
            }
        }
        return false;
    }
}
