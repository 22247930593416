import React, { useRef, useState, useEffect } from 'react';
import Select from 'react-select';
import Tippy from '@tippyjs/react';
import Api from '../../../../modules/Api';
import { formatBigNumber } from '../../../../modules/utils/helpers';
import { MAX_EXPORT_COMMENTS } from './ExportDropdown/ExportDropdown';
import { FilterTags } from '../../../shared/FilterTags/FilterTags';
import PaginationNew from '../../../shared/pagination/PaginationNew';
import PleaseWait from '../../../shared/pleaseWait/PleaseWait';
import SortFilter, { sortOptions } from './Filters/SortFilter';
import MultiselectDropdown from '../../../shared/multiselectDropdown/MultiselectDropdown';
import TranslationTooltip from './TranslationTooltip/TranslationTooltip';
import {
    getLanguageName,
    channelIcons,
    TextContainer,
    getRelevancyValues,
    getIsRelevancyUpdated,
    getIsTopicUpdated,
} from './helpers';
import EditCommentModal from './EditCommentModal/EditCommentModal';
import { FlagIcon } from '../../../shared/flagAvatar/FlagAvatar';
import GeneralTooltip from '../../../shared/generalTooltip/GeneralTooltip';
import EmptyCard from './EmptyCard';
import './commentsTable.scss';
import './table.scss';

const sentimentOptions = [
    { label: 'Positive', value: 'POSITIVE', selected: true },
    { label: 'Neutral', value: 'NEUTRAL', selected: true },
    { label: 'Negative', value: 'NEGATIVE', selected: true },
];

const relevancyFilterOptions = [
    { label: 'Products', value: 'products', selected: true },
    { label: 'Category', value: 'categories', selected: true },
    { label: 'Not relevant', value: 'not_relevant', selected: true },
];

function CommentsTable({
    campaignId,
    assignmentIds,
    platforms,
    excludedCreators,
    excludedPosts,
    startDate,
    endDate,
    setCommentFilters,
    updateAnalysisData,
    relevancyOptions,
}) {
    const commentCardRef = useRef(null);

    // Keyword filter
    const [keywordInput, setKeywordInput] = useState('');
    const [keywordFilters, setKeywordFilters] = useState([
        // {
        //   tagType: 'comment',
        //   filterLabel: null,
        //   value: 'abcde',
        //   label: 'abcde',
        // },
    ]);

    // Sort input
    const [sortOption, setSortOption] = useState(sortOptions[0]);

    // Language filter
    const [langOptions, setLangOptions] = useState([]);
    const selectedLanguages = langOptions.filter((lang) => lang.selected);
    const languagesParam =
        selectedLanguages.length === langOptions.length ? [] : selectedLanguages.map((lang) => lang.value);

    // Relevancy filter
    const [relevancy, setRelevancy] = useState(relevancyFilterOptions);
    const selectedRelevancy = relevancy.filter((option) => option.selected);
    const relevancyParam =
        selectedRelevancy.length === relevancyFilterOptions.length
            ? []
            : selectedRelevancy.map((option) => option.value);

    // Sentiment filter
    const [sentiments, setSentiments] = useState(sentimentOptions);
    const selectedSentiments = sentiments.filter((sentiment) => sentiment.selected);
    const sentimentParam =
        selectedSentiments.length === sentiments.length ? [] : selectedSentiments.map((sent) => sent.value);

    const [commentsData, setCommentsData] = useState();
    const comments = commentsData?.comments || [];

    const [loading, setLoading] = useState(false);

    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);

    const [showOriginalLang, setShowOriginalLang] = useState(true);

    // Edit comment
    const [showEditModal, setShowEditModal] = useState(false);
    const [editComment, setEditComment] = useState(null);
    const openEditModal = (comment) => {
        setEditComment(comment);
        setShowEditModal(true);
    };

    const getCommentsData = async () => {
        if (platforms.length === 0) {
            setCommentsData();
            return;
        }

        setLoading(true);
        await Api.getCampaignSentimentComments(
            assignmentIds,
            platforms,
            startDate,
            endDate,
            excludedCreators,
            excludedPosts,
            'likes',
            sortOption.value,
            languagesParam,
            relevancyParam,
            sentimentParam,
            keywordFilters.map((filter) => filter.value),
            perPage,
            page - 1,
        )
            .then((res) => {
                setCommentsData(res);
                setCommentFilters({
                    excludedCreators,
                    excludedPosts,
                    sortBy: 'likes',
                    sortOption: sortOption.value,
                    languages: languagesParam,
                    relevancy: relevancyParam,
                    sentiment: sentimentParam,
                    keywords: keywordFilters.map((filter) => filter.value),
                    perPage: MAX_EXPORT_COMMENTS,
                });
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        getCommentsData();
    }, [
        assignmentIds?.length,
        keywordFilters,
        languagesParam.length,
        relevancyParam.length,
        sentimentParam.length,
        sortOption,
        startDate,
        endDate,
        perPage,
        page,
        platforms.length,
        excludedCreators.length,
        excludedPosts.length,
    ]);

    const getAllLanguages = async () =>
        Api.getCampaignSentimentCommentsLanguages(
            assignmentIds,
            platforms,
            startDate,
            endDate,
            excludedCreators,
            excludedPosts,
            keywordFilters.map((filter) => filter.value),
        );

    const applyLanguageFilter = (languages) => {
        setPage(1);
        setLangOptions(languages);
    };

    useEffect(() => {
        getAllLanguages().then((res) => {
            const formattedLanguages = res.commentLanguageCodes.map((code) => ({
                value: code,
                label: getLanguageName(code),
                selected: true,
            }));
            setLangOptions(formattedLanguages);
        });
    }, [assignmentIds?.length, startDate, endDate, platforms.length, excludedCreators.length, excludedPosts.length]);

    const handleKeywordFilterRemove = (filterType, value) => {
        setPage(1);
        setKeywordFilters((prev) =>
            prev.filter((filter) => (filterType === filter.tagType ? filter.value !== value : filter)),
        );
    };

    const applyKeywordFilter = (e) => {
        e.preventDefault();
        if (!keywordInput) return;

        setPage(1);
        setKeywordFilters((prev) =>
            prev.some((filter) => filter === keywordInput)
                ? prev
                : [
                      ...prev,
                      {
                          tagType: 'comment',
                          filterLabel: null,
                          value: keywordInput,
                          label: keywordInput,
                      },
                  ],
        );
        setKeywordInput('');
    };

    const applySentimentFilter = (sentiments) => {
        setPage(1);
        setSentiments(sentiments);
    };

    const applyRelevancyFilter = (relevancy) => {
        setPage(1);
        setRelevancy(relevancy);
    };

    const handleCommentUpdate = () => {
        getCommentsData();
        updateAnalysisData();
    };

    return (
        <div className="comments-card" ref={commentCardRef}>
            <div className="card-title">
                <div>Comments</div>
                <div className="info-tooltip-icon">
                    <Tippy className="text-tooltip" content="List of comments from selected posts.">
                        <img src="/images/tooltipUser.svg" />
                    </Tippy>
                </div>
            </div>
            <form className="comments-filters" onSubmit={applyKeywordFilter}>
                <div className="keyword-filter-wrapper">
                    <Select
                        className="comments-type-filter"
                        searchable={false}
                        value={{ value: 'comment', label: 'Comment' }}
                        options={[{ value: 'comment', label: 'Comment' }]}
                        clearable={false}
                    />
                    <input
                        className="comments-keyword-filter"
                        placeholder="Enter keyword..."
                        value={keywordInput}
                        onChange={(e) => setKeywordInput(e.target.value)}
                    />
                </div>
                <MultiselectDropdown
                    values={langOptions}
                    setValues={applyLanguageFilter}
                    filterLabel="Languages"
                    dropdownClassName="comments-filter"
                />
                <MultiselectDropdown
                    values={relevancy}
                    setValues={applyRelevancyFilter}
                    filterLabel="Relevancy"
                    dropdownClassName="comments-filter"
                />
                <MultiselectDropdown
                    values={sentiments}
                    setValues={applySentimentFilter}
                    filterLabel="Sentiment"
                    dropdownClassName="comments-filter"
                />
                <SortFilter sortOption={sortOption} setSortOption={setSortOption} />
            </form>
            {!!keywordFilters.length && (
                <div className="comments-active-filters">
                    <FilterTags
                        selectedOptions={keywordFilters}
                        handleRemove={(_, value) => handleKeywordFilterRemove('comment', value)}
                        handleClearFilters={() => setKeywordFilters([])}
                    />
                </div>
            )}
            <div>
                {comments?.length ? (
                    <>
                        <div className="language-tabs">
                            <div
                                className={`tab ${showOriginalLang ? 'selected' : ''}`}
                                onClick={() => setShowOriginalLang(true)}
                            >
                                Original
                            </div>
                            <div
                                className={`tab ${!showOriginalLang ? 'selected' : ''}`}
                                onClick={() => setShowOriginalLang(false)}
                            >
                                Translated
                            </div>
                        </div>
                        <div className="comments-table-content">
                            <table className="comments-table">
                                <tbody>
                                    <tr className="table-header">
                                        <th className="source-col-head"></th>
                                        <th className="comment-col-header">
                                            <div>Comment/Replies</div>
                                        </th>
                                        <th className="language-col">
                                            <div>Language</div>
                                        </th>
                                        <th className="relevancy-col-header">
                                            <div>Relevancy</div>
                                        </th>
                                        <th className="topics-col-header">
                                            <div>Topics</div>
                                        </th>
                                        <th className="sentiment-col">
                                            <div>Sentiment</div>
                                        </th>
                                        <th className="link-col">
                                            <div>Link</div>
                                        </th>
                                    </tr>
                                    {comments.map((comment) => (
                                        <CommentRow
                                            key={comment.id}
                                            comment={comment}
                                            showOriginalText={showOriginalLang}
                                            openEditModal={openEditModal}
                                        />
                                    ))}
                                </tbody>
                            </table>
                            <PleaseWait show={loading} />
                        </div>
                        <div className="pagination-panel">
                            <PaginationNew
                                total={commentsData.commentCount || 0}
                                limit={perPage}
                                current={page}
                                parentContainer={commentCardRef.current}
                                handlePageChange={(page) => {
                                    setPage(page);
                                    commentCardRef.current.scrollIntoView({ behavior: 'smooth' });
                                }}
                                limitChangeFunction={(limit) => {
                                    setPage(1);
                                    setPerPage(limit);
                                    commentCardRef.current.scrollIntoView({ behavior: 'smooth' });
                                }}
                            />
                        </div>
                    </>
                ) : (
                    <EmptyCard
                        title="No Results Found"
                        subtitle="Please try a different keyword or check back later for updates."
                    />
                )}
            </div>
            <EditCommentModal
                isOpen={showEditModal}
                handleClose={() => setShowEditModal(false)}
                comment={editComment}
                updateData={handleCommentUpdate}
                campaignId={campaignId}
                relevancyOptions={relevancyOptions}
            />
        </div>
    );
}

function CommentRow({ comment, showOriginalText, openEditModal }) {
    const [showReplies, setShowReplies] = useState(false);
    const likes = comment.likes ? formatBigNumber(comment.likes) : '0';

    const sortedReplies =
        comment?.replies.sort((a, b) => {
            if (a.likes !== b.likes) {
                return a.likes > b.likes ? -1 : 1;
            } else {
                return a.id > b.id ? -1 : b.id > a.id ? 1 : 0;
            }
        }) || [];

    return (
        <>
            <tr>
                <td className="source-col">
                    <img src={channelIcons[comment.platform]} />
                </td>
                <td className="comment-col">
                    <div className="comment-content">
                        <TextContainer comment={comment} showOriginal={showOriginalText} />
                        <div className="comment-footer">
                            <div className="comment-likes">{`${likes} ${likes === 1 ? 'like' : 'likes'}`}</div>
                            {!!comment.replies.length && (
                                <div className="show-replies-button" onClick={() => setShowReplies((prev) => !prev)}>
                                    {`${comment.replies.length} ${comment.replies.length === 1 ? 'reply' : 'replies'}`}
                                    <img
                                        className={`show-arrow ${showReplies ? 'open' : ''}`}
                                        src="/images/downarrow.png"
                                    />
                                </div>
                            )}
                            {showOriginalText && comment.detectedTextLanguage !== 'en' && (
                                <TranslationTooltip text={comment.translatedText}>
                                    <div className="comment-translation">See translation</div>
                                </TranslationTooltip>
                            )}
                        </div>
                    </div>
                </td>
                <td className="language-col">{getLanguageName(comment.detectedTextLanguage) ?? ''}</td>
                <td className="relevancy-col">
                    {getIsRelevancyUpdated(comment) && (
                        <div className="updated-label">
                            <FlagIcon />
                            Updated
                            <GeneralTooltip text="The products/category relevancy of this comment has been updated." />
                        </div>
                    )}
                    <div className="relevancy-tags">
                        {getRelevancyValues(comment).map((relevancy) => (
                            <div key={relevancy} className="relevancy-tag">
                                {relevancy}
                            </div>
                        ))}
                    </div>
                </td>
                <td className="topics-col">
                    {getIsTopicUpdated(comment) && (
                        <div className="updated-label">
                            <FlagIcon />
                            Updated
                            <GeneralTooltip text="The topics of this comment have been updated." />
                        </div>
                    )}
                    <div className="topics-tags">
                        {(comment.topics || []).map((relevancy, idx) => (
                            <div key={idx} className="topics-tag">
                                {relevancy}
                            </div>
                        ))}
                    </div>
                </td>
                <td className="sentiment-col sentiment-values">
                    {comment.feedback && comment.feedback !== comment.sentiment && (
                        <div className="updated-label">
                            <FlagIcon />
                            Updated
                            <GeneralTooltip text="The sentiment of this comment has been updated." />
                        </div>
                    )}
                    <SentimentTag sentiment={comment.feedback || comment.sentiment} />
                </td>
                <td className="link-col">
                    <a href={comment.url} target="_blank">
                        <img className="link-icon" src="/images/link.svg" />
                    </a>
                </td>
                <td className="edit-col">
                    <GeneralTooltip text="Edit Sentiment & Relevancy">
                        <div className="edit-img" onClick={() => openEditModal(comment)}>
                            <img src="/images/ic-edit-7c.svg" />
                        </div>
                    </GeneralTooltip>
                </td>
            </tr>
            {showReplies && (
                <>
                    {sortedReplies.map((reply) => (
                        <tr key={reply.id} className="reply-row">
                            <td className="source-col"></td>
                            <td className="comment-col">
                                <div className="comment-content">
                                    <TextContainer comment={reply} showOriginal={showOriginalText} />
                                    <div className="comment-footer">
                                        {reply.likes ? (
                                            <div className="comment-likes">{`${reply.likes} ${
                                                reply.likes === 1 ? 'like' : 'likes'
                                            }`}</div>
                                        ) : null}
                                        {showOriginalText && reply.detectedTextLanguage !== 'en' && (
                                            <TranslationTooltip text={reply.translatedText}>
                                                <div className="comment-translation">See translation</div>
                                            </TranslationTooltip>
                                        )}
                                    </div>
                                </div>
                            </td>
                            <td className="language-col">{getLanguageName(reply.detectedTextLanguage) ?? ''}</td>
                            <td className="relevancy-col">
                                {getIsRelevancyUpdated(reply) && (
                                    <div className="updated-label">
                                        <FlagIcon />
                                        Updated
                                        <GeneralTooltip text="The relevancy of this comment has been updated." />
                                    </div>
                                )}
                                <div className="relevancy-tags">
                                    {getRelevancyValues(reply).map((relevancy, idx) => (
                                        <div key={idx} className="relevancy-tag">
                                            {relevancy}
                                        </div>
                                    ))}
                                </div>
                            </td>
                            <td className="topics-col">
                                {getIsTopicUpdated(reply) && (
                                    <div className="updated-label">
                                        <FlagIcon />
                                        Updated
                                        <GeneralTooltip text="The topics of this comment have been updated." />
                                    </div>
                                )}
                                <div className="topics-tags">
                                    {(reply.topics || []).map((relevancy) => (
                                        <div key={relevancy} className="topics-tag">
                                            {relevancy}
                                        </div>
                                    ))}
                                </div>
                            </td>
                            <td className="sentiment-col sentiment-values">
                                {reply.feedback && reply.feedback !== reply.sentiment && (
                                    <div className="updated-label">
                                        <FlagIcon />
                                        Updated
                                        <GeneralTooltip text="The sentiment of this comment has been updated." />
                                    </div>
                                )}
                                <SentimentTag sentiment={reply.feedback || reply.sentiment} />
                            </td>
                            <td className="link-col"></td>
                            <td className="edit-col">
                                <GeneralTooltip text="Edit Sentiment & Relevancy">
                                    <div className="edit-img" onClick={() => openEditModal(reply)}>
                                        <img src="/images/ic-edit-7c.svg" />
                                    </div>
                                </GeneralTooltip>
                            </td>
                        </tr>
                    ))}
                </>
            )}
        </>
    );
}

function SentimentTag({ sentiment }) {
    return (
        <div className="sentiment-tag-wrapper">
            <div className={`sentiment-tag ${sentiment.toLowerCase()}`}>{sentiment.toLowerCase()}</div>
        </div>
    );
}

export default CommentsTable;
