import React from 'react';
import { Redirect } from 'react-router-dom';
import qs from 'query-string';
import moment from 'moment';
import { getIdToken, getAccessToken, getCode } from '../modules/influencer/SocialAuthService';
import Api from '../modules/Api';
import PageSelectionPopup from './onBoard/facebook/PageSelectionPopup';
import PleaseWait from './shared/pleaseWait/PleaseWaitWhite';
import Session, { PROP_CONNECTION_ERROR, PROP_REDIRECTED, PROP_NETWORK_NAME } from '../modules/utils/Session';
import SSOAuth from '../modules/Auth';
import ProfileHelper from '../modules/utils/ProfileHelper';

class RedirectPage extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.setParams = this.setParams.bind(this);
        this.onCancelFacebookConnect = this.onCancelFacebookConnect.bind(this);
        this.triggerRedirect = this.triggerRedirect.bind(this);
        this.getQueryParams = this.getQueryParams.bind(this);
        this.renderRedirect = this.renderRedirect.bind(this);

        this.profile = null;
        this.idToken = null;
        this.connectionType = null;

        this.state = {
            redirect: false,
            showFacebookDlg: false,
            pageList: [],
            params: '',
        };
    }

    componentDidMount() {
        Session.remove(PROP_CONNECTION_ERROR);
        const params = this.getQueryParams();
        this.setState({
            page: params.page,
            con: params.con,
        });

        if (!this.isAddingANetwork(params)) {
            /* Do not add a network */
            if (params.code) {
                Api.pgGetToken(params.code)
                    .then((res) => {
                        if (res && res.access_token) {
                            const expireTime = moment().add(res.expires_in, 's').format('DD MM YYYY hh:mm:ss');
                            SSOAuth.pgLoginUser(res.access_token,expireTime,res.refresh_token);
                            this.props.history.push('/influencers');
                        } else {
                            this.props.history.push('/login');
                        }
                    })
                    .catch((err) => {
                        this.props.history.push('/login');
                    });
            } else {
                this.triggerRedirect();
            }
        } else {
            ProfileHelper.getCurrentLoginUserProfile().then((profile) => {
                this.profile = profile;
                this.idToken = getIdToken();
                this.code = getCode();
                this.accessToken = getAccessToken();
                switch (params.con) {
                case 'facebook':
                    this.connectionType = params.con;
                    this.showFacebookPageSelection(profile, this.idToken);
                    return;
                case 'instagram':
                    this.connectionType = params.con;
                    this.showInstagramGreeting(profile, this.idToken);
                    return;
                case 'snapchat':
                    this.connectionType = params.con;
                    this.showSnapShotGreeting(profile, this.code);
                case 'youtube':
                    this.connectionType = 'oauth2|youtube';
                    this.addYouTubeChannelToProfile(profile, this.idToken);
                    return;
                case 'pinterest':
                    this.connectionType = 'oauth2|pinterest';
                    break;
                case 'twitch':
                    this.connectionType = 'oauth2|twitch';
                    break;
                default:
                    this.connectionType = params.con;
                    break;
                }
                this.addUserNetwork(null);
            });
        }
    }

    isAddingANetwork(params) {
        const networkOnSession = Session.get(PROP_NETWORK_NAME);
        return !!networkOnSession && !!params.con && networkOnSession.toLowerCase() === params.con.toLowerCase();
    }

    async addUserNetwork(customDetails) {
        try {
            await Api.addUserNetwork(
                this.profile.id,
                this.connectionType,
                this.idToken,
                customDetails,
                this.accessToken,
            );
            await ProfileHelper.getCurrentLoginUserProfile(true);
            await RedirectPage.triggerRecentPostsUpdate(this.profile.id, this.state.con);
        } catch (error) {
            Session.set(PROP_CONNECTION_ERROR, `${JSON.stringify(error)}`);
        }

        this.triggerRedirect();
    }

    showSnapShotGreeting = async (profile) => {
        const accessCode = getCode();
        if (accessCode) {
            //TODO: Implement the snapshot connection
        } else {
            const error = `There was a problem with connecting the snapshot account. Please try again.`;
            Session.set(PROP_CONNECTION_ERROR, `${error}`);
            this.triggerRedirect(error);
        }
    };

    showInstagramGreeting = async (profile) => {
        const accessToken = getAccessToken();
        if (accessToken) {
            try {
                const pageList = await Api.getFacebookPageListByAccessToken(accessToken);
                if (pageList && pageList.length > 0) {
                    this.setState({
                        pageList,
                        showFacebookDlg: true,
                    });
                } else {
                    const error = `There was a problem with getting the Facebook pages list. Please make sure that all necessary permissions were granted.`;
                    Session.set(PROP_CONNECTION_ERROR, error);
                    this.triggerRedirect(error);
                }
            } catch (e) {
                const error = `There was a problem with connecting the account. Please try again.`;
                Session.set(PROP_CONNECTION_ERROR, `${(error)}`);
                this.triggerRedirect(error);
            }
        } else {
            const error = `There was a problem with connecting the account. Please try again.`;
            Session.set(PROP_CONNECTION_ERROR, `${error}`);
            this.triggerRedirect(error);
        }
    };

    showFacebookPageSelection(profile, idToken) {
        if (idToken) {
            Api.getFacebookPageList(profile.id, idToken).then((pageList) => {
                if (pageList && pageList.length > 0) {
                    this.setState({
                        pageList,
                        showFacebookDlg: true,
                    });
                } else {
                    const error = `There was a problem with getting the Facebook pages list. Please make sure that all necessary permissions were granted.`;
                    Session.set(PROP_CONNECTION_ERROR, error);
                    this.triggerRedirect(error);
                }
            })
            .catch((err) => {
                const error = `There was a problem with connecting the account. Please try again.`;
                Session.set(PROP_CONNECTION_ERROR, `${error}`);
                this.triggerRedirect(error);
            });
        } else {
            const error = `There was a problem with connecting the account. Please try again.`;
            Session.set(PROP_CONNECTION_ERROR, `${error}`);
            this.triggerRedirect(error);
        }
    }

    onCancelFacebookConnect() {
        Session.set(PROP_CONNECTION_ERROR, `USER_CANCELLED`);
        this.triggerRedirect(`User cancelled facebook page selection`);
    }

    onSelectFacebookPage = async (pageId) => {
        if (this.connectionType === 'instagram') {
            let response = {};
            try {
                response = await Api.getInstagramAccountId(pageId, this.accessToken);
            } catch (error) {
                const errorText = 'There is no Instagram Business Account connected to Facebook page.';
                Session.set(PROP_CONNECTION_ERROR, 'Connection failed. ' + errorText);
                alert(errorText);
                this.triggerRedirect(errorText);

                return;
            }

            if (response && response.id) {
                const pageData = this.state.pageList.find(({ id }) => id === pageId);
                const pageAccessToken = pageData ? pageData.accessToken : null;
                const customDetails = {
                    facebookPageId: pageId,
                    pageAccessToken,
                    igUserId: response.id,
                };
                if (response.username)
                    customDetails.username = response.username;
                this.addUserNetwork(customDetails);
            } else {
                alert('There is no Instagram Business Account connected to Facebook page!');
            }
        } else {
            const pageData = this.state.pageList.find(({ id }) => id === pageId);
            const pageAccessToken = pageData ? pageData.accessToken : getAccessToken();
            const customDetails = {
                pageId,
                pageToken: pageAccessToken,
                title: pageData.name,
                username: pageId
            };
            this.addUserNetwork(customDetails);
        }
    };

    addYouTubeChannelToProfile(profile, idToken) {
        Api.getYoutubeChannel(profile.id, idToken)
            .then((channel) => {
                if (channel) {
                    const customDetails = {
                        channelId: channel.channelId,
                        thumbnail: channel.thumbnail,
                        title: channel.title,
                        username: channel.channelId
                    };
                    this.addUserNetwork(customDetails);
                } else {
                    Session.set(PROP_CONNECTION_ERROR, `NO_CHANNELS_FOUND`);
                    this.triggerRedirect(`NO_CHANNELS_FOUND`);
                }
            })
            .catch((err) => {
                Session.set(PROP_CONNECTION_ERROR, err && err.statusText ? err.statusText : "Unexpected Error");
                this.triggerRedirect(err);
            });
    }

    setParams(queryString) {
        this.setState({
            params: queryString,
        });
    }

    static triggerRecentPostsUpdate(userId, network) {
        switch (network) {
        // case "facebook": - Backend: To be implemented
        case 'youtube':
        case 'twitter':
        case 'instagram':
            return Api.triggerRecentPostsUpdate(userId, network);
            break;
        default:
            break;
        }
    }

    triggerRedirect(err) {
        const params = `?con=${this.state.con}`;
        // if (err) {
        //   params += `&err=${err}`;
        // }
        this.setParams(params);
        Session.set(PROP_REDIRECTED, 'true');
        this.setState({
            redirect: true,
        });
    }

    getQueryParams() {
        return qs.parse(this.props.location.search);
    }

    renderRedirect() {
        if (this.state.redirect) {
            // will redirect immediately
            return <Redirect to={this.state.page + this.state.params} />;
        }
        // waiting for save before redirect - so show progress spinner (if dialog is not shown)
        if (!this.state.showFacebookDlg) {
            return <PleaseWait show />;
        }

    }

    render() {
        const isInstagram = this.connectionType === 'instagram';

        return (
            <div>
                <PageSelectionPopup
                    isInstagram={isInstagram}
                    show={this.state.showFacebookDlg}
                    pages={this.state.pageList}
                    cancelFunction={this.onCancelFacebookConnect}
                    proceedFunction={this.onSelectFacebookPage}
                />
                {this.renderRedirect()}
            </div>
        );
    }
}

export default RedirectPage;
