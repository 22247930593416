import * as React from 'react';
import { debounce } from 'lodash';
import './InputKeywordsBox.scss';
import cx from 'classnames';

export const InputKeywordsBox = ({
    data,
    placeHolder,
    maxLength = 255,
    disabled = false,
    onChange,
    onEnter,
    changeIsFocused
}) => {
    const [disable, setDisable] = React.useState(disabled);
    const [isFocused, setIsFocused] = React.useState(false);
    const [currentData, setData] = React.useState(data ? data : []);

    const inputRef = React.createRef();

    React.useLayoutEffect(() => {
        setData(data ? data : []);
    }, [data]);

    React.useEffect(() => {
        setDisable(disabled);
    }, [disabled]);

    const handleKeyUp = (keyCode) => {
        if (keyCode === 13) {
            if (inputRef.current.value.length > 0) {
                let update = Object.assign([], currentData);
                update.push(inputRef.current.value);
                setData(update);
                inputRef.current.value = '';
                if (onChange) {
                    onChange(update);
                }
            } else {
                if (onEnter) {
                    onEnter();
                }
            }
        }
    };

    const onBlur = () => {
        if (inputRef.current.value.length > 0) {
            let update = Object.assign([], currentData);
            update.push(inputRef.current.value);
            setData(update);
            inputRef.current.value = '';
            if (onChange)
                onChange(update);
        }
        setIsFocused(false);
        if (changeIsFocused)
            changeIsFocused(false);
    }

    const onFocus = () => {
        setIsFocused(true);
        if (changeIsFocused)
            changeIsFocused(true);
    }

    const onDelete = (index) => {
        let update = Object.assign([], currentData);
        update.splice(index, 1);
        setData(update);
        inputRef.current.value = '';
        if (onChange) {
            onChange(update);
        }
    }

    const handleClick = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }

    return (
        <div className="InputKeywordsBoxContainer"
            onMouseOver={!isFocused ? () => handleClick() : null}
            onClick={!isFocused ? () => handleClick() : null}
            style={isFocused ? { backgroundColor: "#F6F7F8" } : null}
        >
            <div className="labelContainer">
                {currentData.map((d, i) => {
                    return <div key={i} className="inputLabel">
                        {d}
                        <div onClick={() => onDelete(i)} className={cx("imageContainer")}>
                            <img src="/images/ic-close-d.svg"
                                alt=""
                                // onClick={() => onDelete(i)}
                            />
                        </div>
                    </div>
                })}
                <input
                    placeholder={placeHolder}
                    ref={inputRef}
                    autoFocus={true}
                    // type={inputType}
                    maxLength={maxLength}
                    // onChange={handleChange}
                    // value={''}
                    disabled={disable}
                    onKeyUp={({ keyCode }) => handleKeyUp(keyCode)}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    onMouseOver={!isFocused ? () => handleClick() : null}
                />
            </div>
        </div>
    );
};
