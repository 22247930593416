import { call, put } from 'redux-saga/effects';
import { setData } from '../actionCreators';
import influencersSaga from './influencersSaga';

export default function* pageChangeSaga({ payload }) {
    let nextPage = 1;
    let preventRefresh = false;

    if (typeof payload === 'number') {
        nextPage = payload;
    } else if (payload?.nextPage) {
        preventRefresh = payload?.preventRefresh;
        nextPage = payload?.nextPage;
    }

    yield put(
        setData({
            currentPage: nextPage,
        }),
    );

    if (preventRefresh) {
        return;
    }

    yield call(influencersSaga);
}
