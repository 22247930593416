import React from 'react';
import './CustomRangeOption.scss';
import cx from 'classnames';
import Select from 'react-select';
import Format from '../../../modules/utils/Format';
import NumberInput from './NumberInput/NumberInput';

export default class CustomRangeOption extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.delegateChange = this.delegateChange.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.handleChange = this.handleChange.bind(this);
        const fromOptions = this.props.filterValue.filterOptions ? this.props.filterValue.filterOptions.filter(item => item.optionGroup === "Min") : ''
        const toOptions = this.props.filterValue.filterOptions ? this.props.filterValue.filterOptions.filter(item => item.optionGroup === "Max") : ''
        const sortedToOption = [...toOptions].sort((a,b) => {
            return (a.customValue === "" ? 999 : a.customValue) - (b.customValue === "" ? 999 : b.customValue);
        })
        this.state = {
            fromOptions: fromOptions,
            toOptions: sortedToOption,
            fromValue: fromOptions !== "" ? fromOptions[0] : "",
            toValue: toOptions !== "" ? sortedToOption.at(-1) : "",
            percentage: null
        };
    }

    handleInput(i,e) {
        const { toOptions, toValue, fromValue, fromOptions } = this.state;
        if (i) {
            // const valuetoSet = fromValue;
            // const reversed = [...fromOptions].reverse()
            // if (e.customValue < fromValue.customValue) {
            //     valuetoSet = reversed.find(item => item.customValue < e.customValue)
            // }
            this.setState({toValue: e}, () => this.delegateChange())
        } else {
            const newToOption = []
            toOptions.forEach(item => {
                if (item.customValue < e.customValue) {
                    item.disabled = true;
                } else {
                    item.disabled = false;
                }
                newToOption.push(item)
            })
            let newtoValue = toValue;
            if (e.customValue > toValue.customValue) {
                let item = toOptions.find(item => item.customValue > e.customValue)
                if (!item) {
                    item = toOptions.find(item => item.customValue === e.customValue)
                }
                newtoValue = item;
            }
            this.setState({fromValue: e, toOption: newToOption, toValue: newtoValue}, () => this.delegateChange())
        }
    }

    handleChange(newValue) {
        this.setState({percentage: newValue}, () => this.delegateChange())
    }

    delegateChange() {
        const { fromValue, toValue, percentage } = this.state;
        const f = this.props.onChange;
        if (typeof f === 'function') {
            f([fromValue, toValue, percentage]);
        }
    }

    render() {
        const { fromInput, toInput, errNote } = this.state;
        const { noTo, setError } = this.props;
        const { filterValue } = this.props;
        const { fromOptions, toOptions, fromValue, toValue } = this.state;
        const displayError = !!(errNote && errNote.find((err) => err !== ''));
        const fv = Format.formatNumber(fromInput);
        const tv = Format.formatNumber(toInput);
        return (
            <div className="inputRangeContainer" id={filterValue.id} key={filterValue.id}>
                <div className="rangeContainerNew">
                        <div className={cx({ displayError: errNote && errNote[0] !== '' })}>
                            <Select
                                options={fromOptions}
                                placeholder={"Min"}
                                onChange={this.handleInput.bind(this, 0)}
                                value={fromValue}
                                clearable={false}
                                searchable={false}
                                getOptionValue={option => option.customValue}
                            />
                        </div>
                    <div className="to">-</div>

                            <div className={cx({ noLeft: noTo, displayError: errNote && errNote[1] !== '' })}>
                                <Select
                                    options={toOptions}
                                    placeholder={"Max"}
                                    onChange={this.handleInput.bind(this, 1)}
                                    value={toValue}
                                    clearable={false}
                                    searchable={false}
                                    getOptionValue={option => option.customValue}
                                />
                            </div>
                </div>
                {filterValue.weighted && (
                    <div className={cx('number-input-part', 'full-width')}>
                        <NumberInput
                            className="filter-menu-input"
                            placeholder="%"
                            maxValue={filterValue.maxValue}
                            minValue={filterValue.minValue}
                            isWeighted={filterValue.weighted}
                            onBlur={this.handleChange}
                        />
                    </div>
                )}
                {displayError && !setError && (
                    <div className="errorContainer">
                        {errNote.map((err) => (
                            <div>{err}</div>
                        ))}
                    </div>
                )}
            </div>
        );
    }
}
