import Api from '../Api';
import FileSaver from 'file-saver';

const exportInfluencerShippingAddress = (campaignId, influencerIds, fileName = 'shipping_address') => {
    const payload = {
        campaignId,
        params: {
            influencerIds: Array.isArray(influencerIds) ? influencerIds : [influencerIds]
        }
    };

    return Api.getInfluencerShippingAddress(payload).then((response) => {
        FileSaver.saveAs(response, `${fileName}.csv`);
    });
};

export default exportInfluencerShippingAddress;
