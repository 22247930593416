class SSOAuth {
    static pgLoginUser(accessToken, expireTime, refreshToken) {
        localStorage.setItem('sso_access_token', accessToken);
        localStorage.setItem('sso_expire_time', expireTime);
        localStorage.setItem('sso_refresh_token', refreshToken);
    }

    static pgLogoutUser(callback=()=>{}) {
        localStorage.removeItem('sso_access_token');
        localStorage.removeItem('sso_expire_time');
        localStorage.removeItem('sso_refresh_token');
        callback();
    }
}

export default SSOAuth;
