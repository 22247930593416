export const PLATFORMS = {
    INSTAGRAM: {
        name: 'Instagram Post',
        logo: '/images/IG.svg',
    },
    YOUTUBE: {
        name: 'YouTube Post',
        logo: '/images/rate-video.svg',
    },
    TIKTOK: {
        name: 'TikTok Post',
        logo: '/images/ic-rate-tiktok.svg',
    },
    OTHER: {
        name: 'Other',
    },
};

export const FILE_FORMATS = {
    VIDEO: 'mp4',
    IMAGE: 'jpg',
    HTML: 'html',
};
