import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import cx from 'classnames';
import './creatorsTab.scss';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { API } from 'aws-amplify';
import PleaseWaitWhite from '../shared/pleaseWait/PleaseWaitWhite';
import ApiError from '../shared/apiError/ApiError';
import Api from '../../modules/Api';
import Checkbox from '../shared/checkbox2022/Checkbox';
import OneInfluencer from '../oneInfluencer2022/OneInfluencer';
import AddCreatorsButton from './AddCreatorsButton';
import NoContentCard from './NoContentCard';
import AddAssignmentModal from './AddAssignmentModal';

const CreatorsTab = (props) => {
    const discoveryItems = ["edit:plan","create:plan","delete:plan","view:plan"]
    const { lookupTables, plansData, currency, onChange = () => null, setSuccess = () => null, history } = props;
    let hasEvery = 0;
    discoveryItems.forEach(item => {
        if (plansData?.brand?.accessRights?.includes(item)) {
            hasEvery += 1;
        }
    })
    const isDiscovery = plansData?.brand?.accessRights?.length === 4 && hasEvery === 4;
    const [isLoading, setIsLoading] = useState(false);
    const [apiError, setApiError] = useState();
    const [errTitle, setErrTitle] = useState('');
    const [showAssignment, setShowAssignment] = useState(false);
    const [allSelected, setAllSelected] = useState(false);
    const [connectedUsers, setConnectedUsers] = useState([]);
    const [publicUsers, setPublicUsers] = useState([]);
    const [selectedCount, setSelectCount] = useState(0);
    const [usersAdded, setUsersAdded] = useState();
    const [assignmentName, setAssignmentName] = useState();
    const [creatorAssignment, openFromCreator] = useState(false);
    const [selectedUser, setSelectedUser] = useState([]);

    const [currencyList, setCurrencyList] = useState([]);
    useEffect(() => {
        if (history) {
            const {
                location: { state },
            } = history;
            if (state) {
                const { message, creators, assignmentName } = state;
                if (creators) {
                    setUsersAdded(creators);
                }
                if (assignmentName) {
                    setAssignmentName(assignmentName);
                }
                if (message) {
                    setSuccess(message);
                    history.replace();
                }
            }
        }
    }, [history]);

    useEffect(() => {
        Api.getCurrencyList().then((result) => {
            const { data } = result;
            const currencyList = [];
            data.forEach((x) => {
                const currency = {
                    label: `${x.code} (${x.symbol})`,
                    value: x.code,
                    symbol: x.symbol,
                    exchangeRateToUsd: x.exchangeRateToUsd,
                };
                currencyList.push(currency);
            });
            setCurrencyList(currencyList);
        });
    }, []);

    useLayoutEffect(() => {
        let totalSelected = 0;
        if (connectedUsers.length > 0) {
            totalSelected += connectedUsers.reduce((acc, u) => {
                if (u.checked) {
                    acc++;
                }
                return acc;
            }, 0);
        }

        if (publicUsers.length > 0) {
            totalSelected += publicUsers.reduce((acc, u) => {
                if (u.checked) {
                    acc++;
                }
                return acc;
            }, 0);
        }

        setSelectCount(totalSelected);
        if (totalSelected === connectedUsers.length + publicUsers.length && !allSelected) {
            setAllSelected(true);
        } else {
            setAllSelected(false);
        }
    }, [connectedUsers, publicUsers]);

    useEffect(() => {
        if (plansData && plansData.influencers && plansData.influencers.length > 0) {
            const connected = plansData.influencers.map((user) => {
                return {
                    checked: false,
                    details: user,
                    id: user.id || user.uid,
                    invited: '',
                    assignment: usersAdded?.length > 0 && usersAdded.find((u) => u.id === user.id) && assignmentName,
                };
            });
            setConnectedUsers(connected || []);
        } else {
            setConnectedUsers([]);
        }

        if (plansData && plansData.iqUsers && plansData.iqUsers.length > 0) {
            const iqs = plansData.iqUsers.map((user) => {
                return {
                    checked: false,
                    details: user,
                    id: user.id || user.uid,
                    invited: '',
                };
            });
            setPublicUsers(iqs || []);
        } else {
            setPublicUsers([]);
        }
    }, [plansData, usersAdded]);

    const CancelError = () => {
        setApiError(null);
        setErrTitle('');
    };

    const closeModal = () => {
        setShowAssignment(false);
        openFromCreator(false);
    };

    const onSubmit = (data) => {
        closeModal();
        history.push({
            pathname: `/campaignWork/${data.campaign.id}/influencerAdd/${data.assignment.value}`,
            state: {
                Creators: data.creators,
                path: `/plans/${plansData.id}`,
                assignmentName: data.assignment.name,
            },
        });
    };

    const onClickAdd = (e) => {
        setShowAssignment(!showAssignment);
    };

    const selectAllHandler = (status) => {
        if (connectedUsers.length > 0) {
            const connected = connectedUsers.map((user) => ({ ...user, checked: status }));
            setConnectedUsers(connected);
        }
        if (publicUsers.length > 0) {
            const iqs = publicUsers.map((user) => ({ ...user, checked: status }));
            setPublicUsers(iqs);
        }
    };

    const selectionChange = (uid, flag) => {
        let update;
        if (connectedUsers.find((user) => user.details.uid === uid)) {
            update = [...connectedUsers];
            update.find((user) => user.details.uid === uid).checked = flag;
            setConnectedUsers(update);
        } else {
            update = [...publicUsers];
            update.find((user) => user.details.uid === uid).checked = flag;
            setPublicUsers(update);
        }
    };

    const onRemove = (user) => {
        const connectedUserIds = connectedUsers.filter((user) => user.checked).map((u) => u.details.id);
        const publicIds = [];
        publicUsers.forEach((user) => {
            if (user.checked) {
                publicIds.push({
                    userId: user.details.uid,
                    userType: user.details.userNetworks[0].networkType,
                });
            }
        });
        if (plansData && ((connectedUserIds && connectedUserIds.length > 0) || publicIds.length > 0) && !user) {
            setIsLoading(true);
            const payload = {
                connectedUserIds,
                publicUsers: publicIds,
            };
            Api.removeUsersPlan(plansData.id, payload)
                .then((res) => {
                    setIsLoading(false);
                    onChange();
                })
                .catch((err) => {
                    setIsLoading(false);
                    handleApiError(err);
                });
        } else if (plansData && user) {
            const connectedIds = [];
            const publicUserIds = [];
            if (user.id) {
                connectedIds.push(user.id);
            } else {
                const publicUserInfo = {
                    userId: user.uid,
                    userType: user.userNetworks[0].networkType,
                };
                publicUserIds.push(publicUserInfo);
            }
            setIsLoading(true);
            const payload = {
                connectedUserIds: connectedIds,
                publicUsers: publicUserIds,
            };
            Api.removeUsersPlan(plansData.id, payload)
                .then((res) => {
                    setIsLoading(false);
                    onChange();
                })
                .catch((err) => {
                    setIsLoading(false);
                    handleApiError(err);
                });
        }
    };

    const getConnectedUsersSelected = () => {
        return connectedUsers.filter((user) => user.checked) || [];
    };

    const getPublicUsersSelected = () => {
        return publicUsers.filter((user) => user.checked) || [];
    };

    const handleApiError = (err) => {
        setApiError(err);
        if (err && err.originalMessage) {
            setErrTitle(err.originalMessage);
        }
    };

    const onImport = (data) => {
        const listIds = data.map((d) => d.id);
        setIsLoading(true);
        // const connectedUserFromData = data.map(d => d.connectedUsers)
        // const publicUserFromData = data.map(d => d.publicUsers)
        // const connectedUserIdList = []
        // connectedUserFromData.map(d => {
        //     d.forEach(item => {
        //         connectedUserIdList.push(item)
        //     })
        // })
        // const publicUserIdList = []
        // publicUserFromData.map(d => {
        //     d.forEach(item => {
        //         publicUserIdList.push(item.userId)
        //     })
        // })
        // const currentConnectedIdList = []
        // connectedUsers.forEach(item => {
        //     currentConnectedIdList.push(item.id)
        // })
        // const currentPublicIdList = []
        // publicUsers.forEach(item => {
        //     currentPublicIdList.push(item.id)
        // })
        // const connectedInterSection = connectedUserIdList.filter(value => currentConnectedIdList.includes(value));
        // const publicInterSection = publicUserIdList.filter(value => currentPublicIdList.includes(value));
        // console.log(connectedInterSection)
        // console.log(publicInterSection)
        Api.addUsersPlanByList(plansData.id, { listIds })
            .then((res) => {
                setIsLoading(false);
                onChange();
                // setSuccess('Success! Added creators to plan');
                if (res.messageData?.ignoredPublicUsers || res.messageData?.ignoredConnectedUsers) {
                    const totalCount =
                        (res.messageData?.ignoredPublicUsers ? res.messageData?.ignoredPublicUsers.length : 0) +
                        (res.messageData?.ignoredConnectedUsers ? res.messageData?.ignoredConnectedUsers.length : 0);
                    if (totalCount > 5) {
                        setSuccess(`Success! ${totalCount} creators are not added as they were already on the plan's exclusion list.`);
                    } else {
                        let nameList = '';
                        if (res.messageData?.ignoredConnectedUsers?.length > 0) {
                            nameList = res.messageData.ignoredConnectedUsers.reduce((acc, u) => {
                                const user = plansData.excludedInfluencers.find((i) => i.id === u);
                                if (user) {
                                    const name = `${user.firstname ? user.firstname : ''} ${user.lastname ? user.lastname : ''}`;
                                    acc += acc.length > 0 ? `, ${  name}` : name;
                                }
                                return acc;
                            }, nameList);
                        }

                        if (res.messageData?.ignoredPublicUsers?.length > 0) {
                            nameList = res.messageData.ignoredPublicUsers.reduce((acc, u) => {
                                const user = plansData.excludedIqUsers.find((i) => i.uid === u.userId);
                                if (user) {
                                    const name = `${user.firstname ? user.firstname : ''} ${user.lastname ? user.lastname : ''}`;
                                    acc += acc.length > 0 ? `, ${  name}` : name;
                                }
                                return acc;
                            }, nameList);
                        }

                        setSuccess(`Success! ${nameList} are not added as they were already on the plan's exclusion list.`);
                    }
                } else {
                    setSuccess('Success! Added creators to plan.');
                }
            })
            .catch((err) => {
                setIsLoading(false);
                handleApiError(err);
            });
    };
    const handleAdd = (user) => {
        openFromCreator(true);
        setSelectedUser([user]);
    };
    const currencyItem = currencyList.find((element) => element.value === currency);
    return (
        <>
            <div className="userListHeader">
                <div className="selectAllCheckbox">
                    {(publicUsers.length > 0 || connectedUsers.length > 0) && (
                        <Checkbox
                            size={16}
                            changeFunction={selectAllHandler}
                            checked={allSelected}
                            isDisabled={connectedUsers.length + publicUsers.length === 0}
                        />
                    )}
                    {(publicUsers.length > 0 || connectedUsers.length > 0) && (
                        <span>
                            {selectedCount === 0
                                ? `Showing ${connectedUsers.length + publicUsers.length} creator${
                                    connectedUsers.length + publicUsers.length > 1 ? 's' : ''
                                }`
                                : `Selected ${selectedCount} creator${selectedCount > 1 ? 's' : ''}`}
                        </span>
                    )}
                </div>
                <div className="topRightSection">
                    {selectedCount > 0 && (
                        <div className="planCreatorsButtonContainer" onClick={() => onRemove()}>
                            <span>Remove</span>
                        </div>
                    )}
                    {(getConnectedUsersSelected().length > 0 || getPublicUsersSelected().length > 0) && !isDiscovery && (
                        <div className={cx('planCreatorsButtonContainer', { active: showAssignment })} onClick={onClickAdd}>
                            <span>Add to assignment</span>
                        </div>
                    )}
                    <AddCreatorsButton planId={plansData && plansData.id} plansData={plansData} onImport={onImport} />
                </div>
            </div>
            <div className="creatorsTabContainer">
                <PleaseWaitWhite show={isLoading} />
                <ApiError show={apiError} errTitle={errTitle} error={apiError} cancelFunction={CancelError} />
                <div className="userListWrapper">
                    {lookupTables && connectedUsers.length > 0 && (
                        <>
                            <div className="firstRow">
                                <div className="userListLabel">Connected Creators</div>
                                {currency && (
                                    <div className="currency">
                                        Currency:{' '}
                                        <div>
                                            {' '}
                                            {currency} ({currencyItem && currencyItem.symbol})
                                        </div>
                                    </div>
                                )}
                            </div>
                            {connectedUsers.map((user) => {
                                const { checked, details, id, invited, assignment } = user;
                                return (
                                    <OneInfluencer
                                        key={id}
                                        checked={checked}
                                        details={details}
                                        invited={invited}
                                        permission
                                        assignmentAdded={assignment}
                                        showFavorite={!checked}
                                        hideMore
                                        // favoriteData={favoriteList}
                                        selectFunction={selectionChange} // changeSelection
                                        planId={plansData && plansData.id}
                                        currency={currency}
                                        currencyList={currencyList}
                                        onRemove={() => onRemove(details)}
                                        openFromCreator={() => handleAdd(user)}
                                        symbol={currencyItem && currencyItem.symbol}
                                        planIdNote={plansData.id}
                                        openSide
                                    />
                                );
                            })}
                        </>
                    )}
                    {lookupTables && publicUsers.length > 0 && (
                        <>
                            <div className="userListLabel">Public Creators</div>
                            {publicUsers.map(({ checked, details, id, invited }) => (
                                <OneInfluencer
                                    key={id}
                                    checked={checked}
                                    details={details}
                                    invited={invited}
                                    permission
                                    showFavorite={!checked}
                                    hideMore
                                    // exclusionData={favoriteList}
                                    selectFunction={selectionChange} // changeSelection
                                    planId={plansData && plansData.id}
                                    onRemove={() => onRemove(details)}
                                    planIdNote={plansData.id}
                                    openSide
                                />
                            ))}
                        </>
                    )}
                    {publicUsers.length === 0 && connectedUsers.length === 0 && (
                        <NoContentCard message="You have no creators. Start by adding creators to the plan" />
                    )}
                    <div style={{ height: 45 }} />
                </div>
                <AddAssignmentModal
                    show={showAssignment || creatorAssignment}
                    creators={creatorAssignment ? selectedUser : [...getConnectedUsersSelected(), ...getPublicUsersSelected()]}
                    publicUsers={getPublicUsersSelected()}
                    connectedUsers={getConnectedUsersSelected()}
                    // warning={getPublicUsersSelected().length > 0 ? `${getPublicUsersSelected().length} public creator${getPublicUsersSelected().length > 1 ? 's' : ''} not included. Only connected creators can be added to assignments` : null}
                    onClose={closeModal}
                    onSubmit={onSubmit}
                />
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        lookupTables: state.global.lookupTables,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreatorsTab));
