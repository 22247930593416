import React from 'react';
import Modal from 'react-modal';
import moment from 'moment';
import cx from 'classnames';

import Api from '../../../modules/Api';

import './recentPosts.scss';
import { loadThumbnailError } from '../../../modules/utils/CommonUtils';

const MAX_POSTS_COUNT = 6;

export default class RecentPosts extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            popUp: false,
            tabs: '',
            networks: [],
            youtubeId: '',
            instagramCard: null,
        };
    }

    componentDidMount() {
        const { userNetworks = [] } = this.props;
        if (!window.instgrm && !document.getElementById('react-instagram-embed-script')) {
            this.injectInsScript();
        }
        if (!window.twttr) {
            this.injectTwitterScript();
        }
        if (userNetworks.length) {
            const networks = [];
            userNetworks.map(({ networkType }) => networks.push(networkType));
            this.setState({ networks }, () => {
                if (networks.find((item) => item === 'instagram')) {
                    this.setState({ tabs: 'instagram' });
                } else if (networks.find((item) => item === 'youtube')) {
                    this.setState({ tabs: 'youtube' });
                } else if (networks.find((item) => item === 'twitter')) {
                    this.setState({ tabs: 'twitter' });
                }
            });
        }
    }

    injectInsScript = () => {
        const s = document.createElement('script');
        s.async = true;
        s.defer = true;
        s.src = `https://platform.instagram.com/en_US/embeds.js`;
        s.id = 'react-instagram-embed-script';
        const { body } = document;
        if (body) {
            body.appendChild(s);
        }
    };

    injectTwitterScript = () => {
        const s = document.createElement('script');
        s.async = true;
        s.defer = true;
        s.src = `https://platform.twitter.com/widgets.js`;
        const { body } = document;
        if (body) {
            body.appendChild(s);
        }
    };

    showMediaPopup = (link, type) => {
        this.setState({ popUp: true });

        if (type === 'twitter') {
            setTimeout(() => {
                // eslint-disable-next-line no-undef
                twttr.widgets.createTweet(link, document.getElementById('container'), {
                    theme: 'light',
                    align: 'center',
                });
            }, 10);
        }
        if (type === 'youtube') {
            this.setState({ youtubeId: link });
        }
        if (type === 'instagram') {
            Api.getInstagram(link).then((r) => {
                this.setState({ instagramCard: r.html }, () => {
                    window.instgrm.Embeds.process();
                });
            });
        }
    };

    renderTwitterPost = () => {
        const { userNetworks = [] } = this.props;
        const twitter = userNetworks.find((item) => item.networkType === 'twitter');
        const posts = twitter && twitter.recentPosts && twitter.recentPosts.posts;
        return (
            <div className="twitter">
                {posts ? (
                    posts.map((post, i) => {
                        if (i < MAX_POSTS_COUNT) {
                            if (post.type === 'text') {
                                return (
                                    <div
                                        className="tweets text"
                                        onClick={() => this.showMediaPopup(post.id, 'twitter')}
                                        key={post.id}
                                    >
                                        <div>
                                            <img src="/images/ic-tweet.svg" />
                                            <div>
                                                <div className="tweet">{post.text}</div>
                                                <div className="time">
                                                    {moment.unix(post.created_time * 0.001).format('MMM DD')}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                            if (post.type === 'image') {
                                return (
                                    <div
                                        className="tweets image"
                                        onClick={() => this.showMediaPopup(post.id, 'twitter')}
                                        key={post.id}
                                    >
                                        <img src={post.thumbnail ? post.thumbnail : '/images/ic-default-post.svg'} onError={loadThumbnailError} />
                                        <div>{moment.unix(post.created_time * 0.001).format('MMM DD')}</div>
                                    </div>
                                );
                            }
                            if (post.type === 'hybrid') {
                                return (
                                    <div
                                        className="tweets hybrid"
                                        onClick={() => this.showMediaPopup(post.id, 'twitter')}
                                        key={post.id}
                                    >
                                        <div>
                                            <img src={post.thumbnail ? post.thumbnail : '/images/ic-default-post.svg'} className="thumbnail" onError={loadThumbnailError} />
                                            <div>
                                                <div className="tweet">{post.text}</div>
                                                <div className="time">
                                                    {moment.unix(post.created_time * 0.001).format('MMM DD')}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                        }
                    })
                ) : (
                    <div className="noPost">No recent twitter post.</div>
                )}
            </div>
        );
    };

    renderInstagramPost = () => {
        const { userNetworks = [] } = this.props;
        const ins = userNetworks.find((item) => item.networkType === 'instagram');
        const posts = ins && ins.recentPosts && ins.recentPosts.posts;
        return (
            <div className="instagram">
                {posts ? (
                    posts.map((post, i) => {
                        if (i < MAX_POSTS_COUNT) {
                            return (
                                <div
                                    className="ins"
                                    onClick={() => this.showMediaPopup(post.link, 'instagram')}
                                    key={post.id}
                                >
                                    <img src={post.thumbnail ? post.thumbnail : '/images/ic-default-post.svg'} className="thumbnail" onError={loadThumbnailError} />
                                    {post.type === 'carousel' ? (
                                        <img src="/images/ins-carousel.png" className="icon" />
                                    ) : post.type === 'video' ? (
                                        <img src="/images/ins-video.png" className="icon" />
                                    ) : null}
                                </div>
                            );
                        }
                    })
                ) : (
                    <div className="noPost">No recent instagram post.</div>
                )}
            </div>
        );
    };

    renderYoutubePost = () => {
        const { userNetworks = [] } = this.props;
        const youtube = userNetworks.find((item) => item.networkType === 'youtube');
        const posts = youtube && youtube.recentPosts && youtube.recentPosts.posts;
        return (
            <div className="youtube">
                {posts ? (
                    posts.map((post, i) => {
                        if (i < MAX_POSTS_COUNT) {
                            return (
                                <div key={post.id} className="tube">
                                    <div className="video" onClick={() => this.showMediaPopup(post.id, 'youtube')}>
                                        <img src={post.thumbnail ? post.thumbnail : '/images/ic-default-post.svg'} className="thumbnail" onError={loadThumbnailError} />
                                        <img src="/images/ic-play.png" className="icon" />
                                    </div>
                                    <div className="title">{post.text}</div>
                                </div>
                            );
                        }
                    })
                ) : (
                    <div className="noPost">No recent youtube post.</div>
                )}
            </div>
        );
    };

    handleClickTab = (tabs) => {
        this.setState({ tabs });
    };

    render() {
        const { userNetworks } = this.props;
        if (!userNetworks) {
            return null;
        }
        const { tabs, networks, youtubeId, instagramCard, popUp } = this.state;
        let socialContent = '';
        switch (tabs) {
        case 'instagram':
            socialContent = this.renderInstagramPost();
            break;
        case 'youtube':
            socialContent = this.renderYoutubePost();
            break;
        case 'twitter':
            socialContent = this.renderTwitterPost();
            break;
        default:
            socialContent = 'No Recent Posts Available.';
        }
        return (
            <div
                className="recentPosts"
                ref={(e) => {
                    this.container = e;
                }}
            >
                {socialContent !== 'No Recent Posts Available.' && (
                    <div className="tabs">
                        {networks && networks.find((item) => item === 'instagram') && (
                            <div
                                className={cx({ current: tabs === 'instagram' })}
                                onClick={() => this.handleClickTab('instagram')}
                            >
                                Instagram
                            </div>
                        )}
                        {networks && networks.find((item) => item === 'youtube') && (
                            <div
                                className={cx({ current: tabs === 'youtube' })}
                                onClick={() => this.handleClickTab('youtube')}
                            >
                                Youtube
                            </div>
                        )}
                        {networks && networks.find((item) => item === 'twitter') && (
                            <div
                                className={cx({ current: tabs === 'twitter' })}
                                onClick={() => this.handleClickTab('twitter')}
                            >
                                Twitter
                            </div>
                        )}
                    </div>
                )}
                <div className="mediaContent">{socialContent}</div>
                <Modal
                    onRequestClose={() => {
                        this.setState({ popUp: false });
                    }}
                    shouldCloseOnOverlayClick
                    className={cx(
                        'socialMediaPopUp',
                        { instagram: tabs === 'instagram' },
                        { twitter: tabs === 'twitter' },
                        { youtube: tabs === 'youtube' },
                    )}
                    overlayClassName="socialMediaPopUpOverlay"
                    isOpen={popUp}
                    contentLabel="Social Media Popup"
                >
                    <div>
                        {tabs === 'twitter' ? <div id="container" /> : null}
                        {tabs === 'youtube' ? (
                            <iframe
                                title="Youtube"
                                width="800"
                                height="528"
                                src={`https://www.youtube.com/embed/${youtubeId}`}
                                frameBorder="0"
                            />
                        ) : null}
                        {tabs === 'instagram' ? <div dangerouslySetInnerHTML={{ __html: instagramCard }} /> : null}
                    </div>
                </Modal>
            </div>
        );
    }
}
