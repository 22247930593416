import React from 'react';
import InternalComments from './internalComments';

const UserDraftPostComments = ({userDraftPostId}) => {

    if (!userDraftPostId)
        return null;

    return (
        <div className="user-draft-post-comments-container">
            <InternalComments
                postId={userDraftPostId}
            />
        </div>
    )
}

export default UserDraftPostComments;
