import React from 'react';
import Select from 'react-select';

import Lookup from '../../modules/Lookup';
import RateBox from '../oneInfluencer/rateBox/RateBox';
import './onBoard.scss';
import DropdownSelect from "../shared/selectBox/DropdownSelect";
import { FormattedMessage } from 'react-intl';
import { API } from 'aws-amplify';
import ApiNew from '../../modules/Api'

export default class RatesStep extends React.Component {

    constructor (props, context) {
        super(props, context);

        this.handleTopicSelectionChange = this.handleTopicSelectionChange.bind(this);
        this.handleRateChange = this.handleRateChange.bind(this);
        this.fromProfileToState = this.fromProfileToState.bind(this);

        this.state = {
            topics: [],
            activeTab: 'channel',
            currency: "USD",
            currencies: [],
            symbol: "$"
        };

        this.tabList = [
            {
                title: 'per channel',
                key: 'channel'
            },
            {
                title: 'per campaign',
                key: 'campaign'
            }
        ];

        this.topicsList = [];
    }

    fromStateToProfile = (profile) => {
        const { topics, currency } = this.state
        let ratesOk = true;
        for (let ix of Lookup.getRateTypeList()) {
            let rateValue = this.state[ix];
            if (rateValue) {
                profile.rates[ix] = rateValue;
                ratesOk = ratesOk && this.state[ix + 'rateOk']
            }
        }
        profile.topics = topics;
        profile.currencyCode = currency;
        this.props.setRatesOk(ratesOk);
    }

    fromProfileToState() {
        const { profile } = this.props
        let newState = { fieldsFilled : true};
        let topics = [];
        let topicOptions = Lookup.getOptionList('topic');
        for (let topic of profile.topics) {
            for (let option of topicOptions) {
                if (option.id === topic) {
                    topics.push(topic);
                }
            }
        }
        for (let ix of Lookup.getRateTypeList()) {
            newState[ix] = profile.rates[ix] || '';
        }
        newState.topics = topics;
        newState.currency = profile.currencyCode;
        this.setState(newState, this.delegateChange);
    }

    delegateChange = () => {
        this.fromStateToProfile(this.props.profile);
        let f = this.props.continueFunction;
        if (typeof f === 'function') {
            f(this.props.profile, true)
        }
    }

    handleTopicSelectionChange (value) {
        // let selectedTopics = value.split(",");
        // this.setState({topics: selectedTopics}, this.delegateChange);
        this.setState({topics: value}, this.delegateChange);
    }

    handleRateChange(key, newRate, rateOk) {
        const newState = { ...this.state };
        newState[key] = Number(newRate);

        if (rateOk) {
            newState[`rateOk${key}`] = rateOk;
            this.props.profile.rates[key] = newRate;
        } else {
            delete newState[`rateOk${key}`];
            delete this.props.profile.rates[key]
        }

        this.setState(newState, this.delegateChange);

    }

    checkIsRateRequired (rateType, requiredRateFields) {
        return !!requiredRateFields.find(requiredRateField => requiredRateField === rateType);
    }

    renderChannelTab () {
        const { symbol } = this.state;
        const rateBoxes = []
        const requiredRateFields = Lookup.getRequiredRateFields(this.props.networks);
        for (const key of Lookup.getRateTypeList()) {
            const isRequired = this.checkIsRateRequired(key, requiredRateFields);
            if (key !== 'GENERAL') {
                rateBoxes.push(<RateBox
                    key={key}
                    rateKey={key}
                    rateValue={this.state[key]}
                    changeFunction={this.handleRateChange}
                    alternativeRateInput={this.props.alternativeRateInput}
                    mandatory={false}
                    required={isRequired}
                    minimumRate={this.props.minimumRate}
                    symbol={symbol}
                />);
            }
        }

        return rateBoxes;
    }

    renderCampaignTab () {
        const { symbol } = this.state;
        const key = 'GENERAL'
        return <RateBox
            key={key}
            rateKey={key}
            rateValue={this.state[key]}
            changeFunction={this.handleRateChange}
            alternativeRateInput={this.props.alternativeRateInput}
            mandatory={false}
            required={true}
            minimumRate={this.props.minimumRate}
            symbol={symbol}
        />
    }

    tabBtnHandler (activeTab) {
        this.setState({
            activeTab
        })
    }

    getTabContent () {
        return this.state.activeTab === 'channel' ? this.renderChannelTab() : this.renderCampaignTab()
    }

    componentDidMount() {
        this.fromProfileToState();
        let topicOptions = Lookup.getOptionList('topic');
        for (let option of topicOptions) {
            this.topicsList.push(Object.assign({value:option.id, label:option.label}));
        }
        ApiNew.getCurrencyList().
        then(result => {
            const { data } = result;
            const currencyList = []
            data.forEach(x => {
                const currency = {label: x.code, value: x.code, symbol: x.symbol}
                currencyList.push(currency)
                if (this.state.currency === x.code) {
                    this.setState({symbol: x.symbol})
                }
            })
            this.setState({currencyList})
        })
    }

    setCurrency = e => {
        this.setState({currency: e.value, symbol: e.symbol}, this.delegateChange)
    }

    render () {
        if (!this.state.fieldsFilled) {
            return null;
        }

        const { activeTab, currency, currencyList } = this.state;

        const tabContent = this.getTabContent();

        const tabNavButtons = this.tabList.map(tab =>
            <div className={`rate-type-btn ${activeTab === tab.key ? ' active' : ''}`} key={tab.key} onClick={() => this.tabBtnHandler(tab.key)}>
                {tab.title}
            </div>
        );
        return (
            <div className="ratesStep">
                <div className="stepTitle">
                    Categories and Rates
                </div>

                <div className="stepDescription">
                    Select the categories that you influence most, and set your rates below
                </div>

                <div className="stepSubtitle">
                    Categories
                </div>
                {  this.state.topics.length < 2 &&
                    <div className="main-error">
                        <FormattedMessage id='onBoard.selectCategories'/>
                    </div>
                }

                <DropdownSelect className={"selectTopic"} clearable = {false} multi = {true} simpleValue = {true}
                                value={this.state.topics}
                                options = {this.topicsList} onChange={this.handleTopicSelectionChange}
                                maxLimit = {6}/>

                <div className="stepSubtitle">
                    Your Rates
                </div>
                <div className="rate-type-container">
                    <div>Enter the rate using:</div>
                    <div className="rate-type-btns">
                        {tabNavButtons}
                    </div>
                </div>
                <div className="currencySelect">
                { !this.props.isRatesValid &&
                    <div className="main-error">
                        Please enter the required rates
                    </div>
                }
                    <Select
                    options={currencyList}
                    placeholder='Select Currency'
                    onChange={e => this.setCurrency(e)}
                    value={currency}
                    clearable={false}
                    />
                </div>
                <div>
                    { tabContent }
                </div>


            </div>
        );
    }
}
