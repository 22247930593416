import React from 'react'
import './feedbackOnTheTop.scss'

// props :
// - show
// - closeFunction
// - message
// - interval (not implemented)

export default class FeedbackOnTheTop extends React.Component {

    constructor (props, context) {
        super(props, context)

        this.state = {
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    static getDerivedStateFromProps(props, state) {
        if ( props.show && !state.show) {
            setTimeout(props.closeFunction, 5000)
        }
        return {
            show : props.show
        }
    }

    render () {
        const {extraTop} = this.props;
        let className="feedbackOnTheTop";
        if (this.state.show) {
            className += ' active';
        }
        if (extraTop) {
            className += ' extra';
        }
        return (
            <div className={className} onClick={this.props.closeFunction}>
                <div>
                    <img src="/images/ic-success-w.svg" style={{height: 40, width: 40}}/>
                    {this.props.message}
                </div>
            </div>
        );
    }
}
