import React from 'react';
import './CreatorDetailsTab.scss';
import Api from '../../modules/Api';
import ApiError from '../shared/apiError/ApiError';
import MetricsItem from './MetricsItem';
import { LoadingDialogue } from '../shared/loadingDialogue/loadingDialogue';
import CompetitorPosts from './competitorPosts/CompetitorPosts';
import PublicUserInsightsRequest from './publicUserInsightsRequest';
import { NETWORKS_INSIGHTS_SUPPORTED_BY_IQ_SOCIAL } from '../../constants/lookup-constants';
import CreatorContacts from './CreatorContacts';
import BrandPartnership from './BrandPartnership';

class CreatorDetailsTab extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            networkSelected: null,
            insightsData: null,
            status: null,
            loading: false,
            showApiError: false,
            apiError: null,
            showNoAudienceData: false,
        };

        this.onInsight = this.onInsight.bind(this);
    }

    onInsight = (item) => {
        this.setState(
            {
                networkSelected: item,
                loading: true,
            },
            async () => {
                try {
                    const { profile } = this.props;
                    let res = null;

                    if (profile.id) {
                        res = await Api.getAudienceInsightsData(item.socialHandle, item.networkType);
                    } else {
                        res = await Api.getPublicSourceAudienceInsightsData(profile.uid, item.networkType);
                    }
                    this.setState({ status: res.status, insightsData: res, showNoAudienceData: false });
                } catch (error) {
                    if (this.props.profile.id) {
                        this.setState({ loading: false, showApiError: true, apiError: error });
                    } else {
                        this.setState({ showNoAudienceData: true });
                    }
                } finally {
                    this.setState({ loading: false });
                }
            },
        );
    };

    requestSuccess = (res) => {
        if (res.statusCode === 200) {
            this.setState({ status: 'inProgress' });
        }
    };

    requestFailure = (err) => {
        this.setState({ showApiError: true, apiError: err });
    };

    renderInsights() {
        const { networkSelected: data, insightsData, status, showNoAudienceData } = this.state;
        const { profile } = this.props;
        return (
            <PublicUserInsightsRequest
                data={data}
                insightsData={insightsData}
                status={status}
                closeFunction={() => this.setState({ networkSelected: null })}
                sentRequestSuccess={this.requestSuccess}
                sentRequestFailure={this.requestFailure}
                showNoAudienceData={showNoAudienceData}
                profile={profile}
            />
        );
    }

    render() {
        const { profile } = this.props;
        const { networkSelected, loading, showApiError, apiError, insightsData } = this.state;
        return (
            <div className="CreatorDetailsTabContainer">
                <LoadingDialogue show={loading} container="bottomSectionId" />
                <ApiError
                    show={showApiError}
                    error={apiError}
                    cancelFunction={() => this.setState({ showApiError: false, apiError: null })}
                />
                {!!profile?.competitiveBrandAssociations?.length && (
                    <CompetitorPosts brands={profile.competitiveBrandAssociations} />
                )}
                <div className="bioContainer">
                    <div className="bioData">
                        Bio
                        {profile.summary && <span>{profile.summary}</span>}
                    </div>
                </div>
                <CreatorContacts
                    contacts={profile.contacts}
                    currentNetwork={networkSelected ? networkSelected.networkType : null}
                />
                {profile.userNetworks &&
                    profile.userNetworks.length > 0 &&
                    profile.userNetworks.map((item, index) => {
                        return (
                            <MetricsItem
                                key={`CreatorDetailsTab MetricsItem${index}`}
                                data={item}
                                expanded={index === 0}
                                insights={
                                    NETWORKS_INSIGHTS_SUPPORTED_BY_IQ_SOCIAL.includes(item.networkType) &&
                                    item.socialHandle
                                }
                                onInsight={this.onInsight}
                                isConnectedInfluencer={Boolean(profile.id)}
                                fireImmediately
                                profile={profile}
                                insightsData={insightsData}
                            />
                        );
                    })}
                {profile && networkSelected && networkSelected.networkType === 'instagram' && (
                    <BrandPartnership interests={profile.interests} brandAffinity={profile.brandAffinity} />
                )}
                {this.renderInsights()}
            </div>
        );
    }
}

export default CreatorDetailsTab;
