import React, { useState, useEffect, useLayoutEffect } from 'react';
import './noteTab.scss';
import cx from 'classnames';
import NoteCreate from './NoteCreate';
import NoDataCard from './NoDataCard';
import Format from '../../../modules/utils/Format';
import NoteAvatar from './NoteAvatar';

const NoteTab = ({
    profile,
    onSave = () => null,
    notes,
    create,
    viewAll,
}) => {
    return (
        <div className='noteTabContainer2'>
            {create && <div className='planNoteTabCreateContainer'>
                <NoteCreate onSave={onSave} profile={profile} />
            </div>}
            {notes.length ? <div className='planNotesListContainer'>
                {notes.map((note, i) => {
                    return (((create && i < 3) || (!create)) && <div className='noteTabItemContainer'>
                        <NoteAvatar picUrl={note.picUrl} firstName={note.agentName} />
                        <div className='noteContentContainer'>
                            <div className='noteInfoContainer'>
                                {note.agentName}
                                <img src='/images/ic-dot.svg' />
                                <span>{Format.formatDateFrom(note.createdOn)}</span>
                            </div>
                            <div className='noteContent'>{note.content}</div>
                        </div>
                    </div>)
                })}
                {create && notes.length > 3 && <div onClick={viewAll} className='viewAll'>View all</div>}
            </div> : create ? null : <NoDataCard message={'There is no note for this creator'} icon={'/images/ic-no-note.svg'} />}
        </div>
    )
}

export default NoteTab;