import React, { Fragment, useState, useEffect } from 'react';
import cx from 'classnames';
import moment from 'moment';
import Modal from 'react-modal';
import DateFilter from './DateFilter/DateFilter';
import Api from '../../../../modules/Api';
import PulseLoader from '../../../shared/pulseLoader/PulseLoader';
import DownloadLink from '../../../shared/DownloadLink/DownloadLink';
import { PLATFORMS, FILE_FORMATS } from '../helpers';
import AssetGallery from './AssetGallery/AssetGallery';
import InfluencerPhoto from '../../../oneInfluencer2022/InfluencerPhoto';
import './postAssetsModal.scss';

export default function PostAssetsModal({ isOpen, close, postData }) {
    const [isLoading, setIsLoading] = useState(true);
    const [postAssets, setPostAssets] = useState(null);

    const postsCount = postAssets?.length || 0;

    useEffect(() => {
        if (postData) {
            setIsLoading(true);
            Api.getPostAssets({ postUrls: [postData.postUrl] })
                .then((res) => setPostAssets(res))
                .finally(() => setIsLoading(false));
        } else {
            setIsLoading(false);
        }
    }, [postData]);

    return (
        <Modal
            closeTimeoutMS={300}
            isOpen={isOpen}
            overlayClassName="wideOverlay"
            className="post-assets-modal"
            onRequestClose={close}
            shouldCloseOnOverlayClick
        >
            <div className="modal-content">
                <SideBar userData={postData?.user} postsCount={postsCount} />
                <MainContent
                    handleModalClose={close}
                    assets={postAssets}
                    setIsLoading={setIsLoading}
                    postsCount={postsCount}
                />
                <LoadingScreen isLoading={isLoading} />
            </div>
        </Modal>
    );
}

function LoadingScreen({ isLoading }) {
    return isLoading ? (
        <div className="loading-screen">
            <PulseLoader color="#4AA129" size="16px" margin="4px" />
        </div>
    ) : null;
}

function SideBar({ userData, postsCount }) {
    return (
        <div className="post-assets-sidebar">
            <div className="post-assets-menu-wrapper">
                <InfluencerPhoto
                    className="user-picture"
                    url={userData?.userPictureUrl}
                    firstName={userData?.userName}
                    lastName={userData?.userName}
                />
                <div className="username">{userData?.userName}</div>
                <div className="user-type">{userData?.userType}</div>

                <div className="menu-links">
                    <div className={cx('menu-link', 'active-link')}>
                        <div className="menu-link-name">Post Assets</div>
                        <div className="menu-link-count">{postsCount}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function MainContent({ handleModalClose, assets, setIsLoading, postsCount }) {
    const [selectedDate, setSelectedDate] = useState({ label: 'All', value: 'ALL' });

    const dataByDate = (assets || []).reduce((prev, curr) => {
        const date = curr.publishedOn.split('T')[0];
        return { ...prev, [date]: [...(prev[date] || []), curr] };
    }, {});

    const dateOptions = [
        { label: 'All', value: 'ALL' },
        ...Object.keys(dataByDate).map((key) => ({
            label: moment(key).format('MMM D, YYYY'),
            value: key,
            count: dataByDate[key].length,
        })),
    ].sort((a, b) => new Date(a.value) - new Date(b.value));

    const shownDates = selectedDate.value === 'ALL' ? Object.keys(dataByDate) : [selectedDate?.value];
    const formattedAssets = shownDates.reduce((prev, date) => {
        const groupedByChannels = Object.groupBy(dataByDate[date], ({ platform }) => platform ?? 'OTHER');
        return { ...prev, [date]: groupedByChannels };
    }, {});

    const sortedDates = Object.keys(formattedAssets).sort((a, b) => new Date(a) - new Date(b));

    const allFiles = Object.keys(formattedAssets).reduce((prev, curr) => {
        const allDatePosts = [];
        Object.keys(formattedAssets[curr]).forEach((channel) =>
            formattedAssets[curr][channel].forEach((post) => post.media.forEach((asset) => allDatePosts.push(asset))),
        );

        return [...prev, ...allDatePosts];
    }, []);

    const isDataEmpty = !Object.keys(formattedAssets).length;

    return (
        <div className="post-assets-content">
            <div className="content-header">
                <div className="header-title-wrapper">
                    <div className="header-title">Post Assets ({postsCount})</div>
                    <img src="/images/ic-close-g.svg" className="close-btn" onClick={handleModalClose} />
                </div>

                <div className="filters">
                    <DateFilter
                        options={dateOptions}
                        selected={selectedDate ?? dateOptions[0]}
                        setSelected={setSelectedDate}
                    />
                    <DownloadLink
                        files={allFiles.map((file) => ({
                            url: file.url,
                            format: FILE_FORMATS[file.mediaType],
                        }))}
                        fileName="assets"
                        setIsLoading={setIsLoading}
                        isDisabled={isDataEmpty}
                    >
                        <button className={cx('download-btn', isDataEmpty && 'btn-disabled')} disabled={isDataEmpty}>
                            <img src="/images/ic-export-bare.svg" />
                            <span>Download All</span>
                        </button>
                    </DownloadLink>
                </div>
            </div>

            {isDataEmpty && <div className="no-data-label">No assets found</div>}

            <div className="post-assets">
                {sortedDates.map((date) => (
                    <Fragment key={date}>
                        <div className="post-assets-date-wrapper">
                            <div className="date">{moment(date).format('MMMM D, YYYY')}</div>
                        </div>
                        {Object.keys(formattedAssets?.[date] || {})
                            .sort((a, b) => {
                                if (a === 'OTHER') return 1;
                                if (b === 'OTHER') return -1;
                                return a.localeCompare(b);
                            })
                            .map((channel) => (
                                <Fragment key={channel}>
                                    <div className="post-assets-channel">
                                        {PLATFORMS[channel]?.logo && (
                                            <img src={PLATFORMS[channel].logo} className="channel-icon" />
                                        )}
                                        <div className="channel-label">{PLATFORMS[channel]?.name}</div>
                                    </div>

                                    <div className="channel-assets">
                                        {(formattedAssets?.[date]?.[channel] || []).map((post) => (
                                            <div key={post.url} className="asset">
                                                <AssetGallery
                                                    files={post.media.map((asset) => ({
                                                        original: asset.url,
                                                        mediaType: asset.mediaType,
                                                    }))}
                                                />
                                                {post.caption && (
                                                    <div className="post-info-wrapper">
                                                        <span className="post-info-label">Caption:</span>
                                                        <span className="post-info">{post.caption}</span>
                                                    </div>
                                                )}
                                                {!!post.taggedUsers?.length && (
                                                    <div className="post-info-wrapper">
                                                        <span className="post-info-label">Tags:</span>
                                                        <span className="post-info">{post.taggedUsers.join(', ')}</span>
                                                    </div>
                                                )}
                                                {post.url && (
                                                    <div className="post-info-wrapper">
                                                        <span className="post-info-label">Link:</span>
                                                        <a
                                                            href={post.url}
                                                            target="__blank"
                                                            className="post-info post-link"
                                                        >
                                                            {post.url}
                                                        </a>
                                                    </div>
                                                )}
                                                <DownloadLink
                                                    files={post.media.map((media) => ({
                                                        url: media.url,
                                                        format: FILE_FORMATS[media.mediaType],
                                                    }))}
                                                    fileName="assets"
                                                    setIsLoading={setIsLoading}
                                                >
                                                    <button className="post-download-btn">
                                                        <img src="/images/ic-export-gray.svg" />
                                                        <span>Download</span>
                                                    </button>
                                                </DownloadLink>
                                            </div>
                                        ))}
                                    </div>
                                </Fragment>
                            ))}
                    </Fragment>
                ))}
            </div>
        </div>
    );
}

function PostImage({ url }) {
    const [loadError, setLoadError] = useState(false);
    return (
        <img
            className={cx('asset-picture', loadError && 'no-picture')}
            src={loadError ? '/images/noImage.svg' : url}
            onError={() => setLoadError(true)}
        />
    );
}
