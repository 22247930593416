import React from 'react';
import Tippy from '@tippyjs/react';
import './generalTooltip.scss';

function GeneralTooltip({ children, text }) {
    return (
        <Tippy className="general-tooltip" content={text} animation={false}>
            {children ?? <div className="tooltip-icon" />}
        </Tippy>
    );
}

export default GeneralTooltip;
