import React from 'react'

import ModalDialogEx from '../../shared/modalDialog/ModalDialogEx';
import Select from 'react-select'
import './PageSelectionPopup.scss'



export default class PageSelectionPopup extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.handlePageSelection = this.handlePageSelection.bind(this);
    this.proceedFunction = this.proceedFunction.bind(this);

    this.state = {
      canSave: false,
      page: -1
    }
  }

  handlePageSelection(selection) {
    this.setState({
      page : selection.value,
      canSave: (selection.value!==-1)
    });
  }

  proceedFunction() {
    let f = this.props.proceedFunction;
    if ((f) && (typeof f==="function")) {
      this.setState({canSave: false}, () => f(this.state.page));
    }
  }

  render() {
    const { show, isInstagram } = this.props;

    if (!show) {
      return null;
    }

    let pageList = [];
    if (Array.isArray(this.props.pages) && this.props.pages.length > 0) {
      for (let page of this.props.pages) {
        pageList.push({value: page.id, label: page.name});
      }
    }

    const title = isInstagram
        ? 'Connect to Instagram'
        : 'Connect to Facebook';
    const label = isInstagram
        ? 'Select the Facebook page that is connected to your instagram account'
        : 'Select the Facebook page you want to connect';

    return (
      <div className="pageSelectionPopup">
        <ModalDialogEx
          show={true}
          title={title}
          cancelFunction={this.props.cancelFunction}
          proceedFunction={this.proceedFunction}
          proceedButtonLabel="CONTINUE"
          readyToProceed={this.state.canSave}
        >
          <div className="label">
              {label}
          </div>

          {/*<SelectBox*/}
            {/*options={pageList}*/}
            {/*changeFunction={this.handlePageSelection}*/}
          {/*></SelectBox>*/}
          <Select className="select" clearable={false} searchable={false}
                  value={this.state.page} single
                  placeholder="Please select a facebook page to connect" options={pageList}
                  onChange={this.handlePageSelection}
          />

        </ModalDialogEx>
      </div>
    );
  }

}
