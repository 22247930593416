import React, {Component} from 'react';
import VirtualizedSelect from 'react-virtualized-select';
import PropTypes from 'prop-types';
import './dropdownSelect.scss';

export default class DropdownSelectWithLargetData extends Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        let selected=(this.props.simpleValue||!!this.props.multi)?'':[];
        if (this.props.value) {
            if (this.props.simpleValue) {
                if (Array.isArray(this.props.value)) {
                    selected=this.props.value.toString();
                } else {
                    selected=this.props.value;
                }
            } else if (Array.isArray(this.props.value)) {
                selected=this.props.value;
            } else if (typeof this.props.value ==='object') {
                selected=this.props.value;
            } else {
                const sArray=this.props.options.find(option=>{return option.value==this.props.value});
                if (this.props.multi) {
                    selected=sArray[0];
                } else {
                    selected=sArray;
                }
            }
        }
        this.state = {
            selected,
            menuOpen: false
        }
        this.selectNode = null;
        this.containerElement = null;
    }

    handleClick(e) {
        const f = this.props.onClick;
        const self = this;
        if (typeof f === 'function') {
            let element=e.target;
            while (Boolean(element) && element.className.indexOf('Select ')<0) {
                element=element.parentElement;
            }
            f(this.Element);
        }
        this.setState({menuOpen:!this.state.menuOpen});
    }

    handleChange(value) {
        const{simpleValue, requestCloseFunc} = this.props;
        const{closeOnSelect} = this.state;

        const passValue=(simpleValue)?value.toString().split(','):value;
        const f = this.props.onChange;
        if (typeof f === 'function') {
            f(passValue);
        }
        this.setState({selected:value, menuOpen: false}, function() {
            if (!closeOnSelect && requestCloseFunc) {
                requestCloseFunc();
            }
        });
    }

    reset(value) {
        const{simpleValue,multi}=this.props;
        const selectedValue = value ? ((simpleValue||!!multi)?value.toString():value) : ((simpleValue||!!multi)?'':[]);
        this.setState({selected: selectedValue, menuOpen: false});
    }

    render() {
        const{options, simpleValue, maxLimit, id, multi, clearable, placeholder, className, closeOnSelect, disabled, searchable, isSingleInput }=this.props;
        const{selected}=this.state;
        const dsClass='selectBox';
        const optionList=[];
        if (maxLimit) {
            const scnt = (simpleValue)?selected.split(',').length:selected.length;
            options.forEach(option=>{

                let disabledVal = false;
                if (scnt>=maxLimit){
                    if (simpleValue) {
                        disabledVal = (`${selected},`).indexOf(`${option.value},`) < 0;
                    } else {
                        disabledVal = selected.findIndex(sv=>sv.value == option.value) < 0;
                    }
                }
                option.disabled=disabledVal;
                optionList.push(option);
            });
        }
        let valueToUse = ''
        if (selected.length !== 0 && isSingleInput) {
            valueToUse = selected
        }
        return (
            <div className={dsClass} onClick={this.handleClick} id={id} ref={e=>this.containerElement=e}>
                <VirtualizedSelect className={className}
                    clearable={!!clearable}
                    backspaceRemoves={false}
                    searchable={!!searchable}
                    value={valueToUse}
                    multi={!!multi}
                    simpleValue={simpleValue}
                    placeholder={placeholder}
                    options={(maxLimit)?optionList:options}
                    onChange={this.handleChange}
                    ref={e=>this.selectNode=e}
                    handleMenuScroll={this.handleMenuScroll}
                    onMenuScrollToBottom={this.onMenuScrollToBottom}
                    closeOnSelect={!!closeOnSelect}
                    disabled={!!disabled}
                    maxHeight={150}
                />
            </div>
        )
    }
}

DropdownSelectWithLargetData.propTypes = {
    options: PropTypes.array.isRequired,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    simpleValue: PropTypes.bool,
    multi: PropTypes.bool,
    id: PropTypes.string || PropTypes.number,
    maxLimit: PropTypes.number,
    clearable: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
    value: PropTypes.string || PropTypes.array || PropTypes.object,
    closeOnSelect: PropTypes.bool,
    searchable: PropTypes.bool,
    requestCloseFunc: PropTypes.func,
}