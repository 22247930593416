import React from "react";

export default class ProfileDetails extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        return (<div className="invoice">
            invoice
        </div>)
    }
}