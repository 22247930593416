import React, {useState, useEffect} from 'react';
import { withRouter } from "react-router";

const BrandInfoRow = ({brand, history}) => {

    if (!brand)
        return null;

    return (
        <div key={`brand-info-row${  brand.brandId}`} className="brandInfo" onClick={() => history.push({pathname: `/keywordGroups/${  brand.id}`, search: history.location.search})}>
            <div className="column">
                <img src={brand.brandImage ? brand.brandImage : "/images/brandLogo.png"}/>
                <span className="brandName">{brand.brandName}</span>
            </div>
            <div className="column">
                <span>{brand.keywordGroupCount}</span>
            </div>
        </div>
    )
}

export default withRouter(BrandInfoRow);
