import React from 'react'
import _ from 'lodash';
import TextInput from './textInput/TextInput'
import SocialNetworks from '../../../modules/influencer/SocialNetworks'
import ReachIcon from '../../shared/ReachIcon/ReachIcon'

// props for helper component SelectNetwork:
// - selectedNetwork (network id, capital as the server expects it
// - selectionChangeFunction

class SelectNetwork extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.renderedChoice = this.renderedChoice.bind(this);

        this.state = {
            selected : null
        }

        this.networks = [];
        const exceptions = { blog : true}
        for (const id of SocialNetworks.list()) {
            if (exceptions[id]) {
                continue;
            }
            const label = SocialNetworks.getLabel(id);
            const color = SocialNetworks.getColor(id);
            this.networks.push ({
                id : id.toUpperCase(),
                label,
                color
            })
        }
    }

    renderedChoice(network) {
        const self = this;
        const mouseEnterHandler = function() {
            self.setState({ hovered : network.id })
        }
        const mouseLeaveHandler = function() {
            const mouseEnterHandler = function() {
                self.setState({ hovered : 'noneHovered' })
            }
        }
        const borderStyle = {};
        if (this.state.hovered === network.id) {
            borderStyle.borderColor = network.color;
        }

        const selectFunction = function() {
            self.setState({
                selected : network.id
            })
            const f = self.props.selectionChangeFunction;
            if (typeof f === 'function') {
                f(network.id)
            }
        }

        return (
            <div
                key={network.id}
                onMouseEnter={mouseEnterHandler}
                onMouseLeave={mouseLeaveHandler}
                style={borderStyle}
                onClick={selectFunction}
            >
                {/* <SocialNetworkIcon network={network.id} size={30}/> */}
                <ReachIcon network={network.id} value={1} size={15} padding={6}/>
            </div>
        )
    }

    componentDidMount() {
        this.setState({
            selected : this.props.selectedNetwork
        })
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.selectedNetwork, this.props.selectedNetwork)) {
            this.setState({
                selected: this.props.selectedNetwork
            })
        }
    }

    render() {
        const self = this;
        const selectionClass = this.state.selected ? '' : 'active';
        const selectPanelContent = [<div key="label" className="selectNetworkAm">Select</div>];
        for (const network of this.networks) {
            selectPanelContent.push(this.renderedChoice(network));
        }
        const noSelection=function() {
            if (!self.props.permission) return
            self.setState({
                selected: null
            })
            const f = self.props.selectionChangeFunction;
            if (typeof f === 'function') {
                f(null)
            }
        }
        return (
            <div className="selectNetwork">
                <div className="selectPanel">
                    <div className={selectionClass}>
                        { selectPanelContent }
                    </div>
                </div>
                { this.state.selected && this.props.selectedNetwork &&
                    <div style={{paddingTop:22}} onClick={noSelection}>
                        <ReachIcon network={this.props.selectedNetwork} value={1} size={15} padding={6}/>
                    </div>
                }
            </div>
        )
    }
}

// props for helper component BrandHandle
// - item  { type, text }
// - index
// - changeFunction
// - deleteItemFunction

class BrandHandle extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.handleNetworkSelection = this.handleNetworkSelection.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.delegate = this.delegate.bind(this);
        this.processProps = this.processProps.bind(this);

        this.state = {
            processed : false
        }
    }

    handleNetworkSelection(selection) {
        this.setState({
            network : selection
        }, this.delegate)
    }

    handleTextChange(newText) {
        this.setState({
            text : newText
        })
    }

    delegate() {
        const { text, network } = this.state;
        const { changeFunction } = this.props;
        const f = changeFunction;
        let textToSave = text;
        while (textToSave[0] === "@") {
            textToSave = textToSave.slice(1);
        }
        this.setState({text: textToSave});
        if (typeof f === 'function' && textToSave.length > 0) {
            f({
                type : network,
                handle : textToSave
            }
            , this.props.index);
        }
    }

    processProps(props) {
        const self = this;
        if (typeof props.item.type === 'undefined') {
            return;
        }
        this.setState({
            network : props.item.type,
            text : props.item.handle || '',
            processed : true
        })
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.item, this.props.item) && this.props.item) {
            this.processProps(this.props);
        }
    }

    componentDidMount() {
        if (this.props.item) {
            this.processProps(this.props);
        }
    }

    render() {
        if (!this.state.processed) {
            return null;
        }
        const textStyle = {};
        if (!this.state.network) {
            textStyle.display="none"
        }

        const self = this;
        const deleteFunction = function() {
            const f = self.props.deleteItemFunction;
            if (typeof f === 'function') {
                f(self.props.index);
            }
        }
        return (
            <div className="listItem" style={{minHeight:66}}>
                <div style={{width:30}} />
                <div style={{padding:0, width: 40}}>
                    <SelectNetwork
                        permission={this.props.permission}
                        selectedNetwork={this.state.network}
                        selectionChangeFunction={this.handleNetworkSelection}
                    />
                </div>
                <div style={{width:30, paddingLeft: 10}}>
                    <div className="number" style={textStyle}>
                        @
                    </div>
                </div>
                <div>
                    {
                        this.state.network &&

                        <TextInput
                            disableSpace
                            disabled={!this.props.permission}
                            showInput
                            itemText={this.state.text || ''}
                            changeFunction={this.handleTextChange}
                            placeholder="Brand Handle"
                            blurFunction={this.delegate}
                        />
                    }
                </div>
                {this.props.permission && <div className="control" onClick={deleteFunction}>
                    <img src="/images/ic-trash.svg"/>
                </div>}

            </div>
        )
    }
}

// props
//  - items : array of strings
//  - changeFunction (takes array of strings)

export default class BrandHandles extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.add = this.add.bind(this);
        this.handleItemChange = this.handleItemChange.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.delegateChange = this.delegateChange.bind(this);

        this.state = {
            items : null,
            newlyCreatedIndex : -1
        };
    }

    delegateChange() {
        const f = this.props.changeFunction;
        if (typeof f === 'function') {
            f(this.state.items);
        }
    }

    deleteItem (index) {
        const newItems = [];
        this.state.items.map(function(item, ix){
            if (ix !== index) {
                newItems.push(item);
            }
        })
        this.setState({
            items : newItems
        }, this.delegateChange)
    }

    handleItemChange(newText, changedIndex) {
        const newitems = [];
        this.state.items.map(function(item, index){
            if (index == changedIndex) {
                newitems.push(newText)
            } else {
                newitems.push(item)
            }
        })
        this.setState({
            items : newitems
        }, this.delegateChange)
    }

    add() {
        const newItems = this.state.items;
        const newIndex = this.state.items.length;
        newItems.push({type:null, handle:null});
        this.setState({
            items : newItems,
            newlyCreatedIndex : newIndex
        }, this.delegateChange)
    }

    componentDidMount() {
        this.setState({
            items : this.props.items
        })
    }

    render() {
        if (!Array.isArray(this.state.items)) {
            return null;
        }
        const items = [];
        for (let i=0; i<this.state.items.length; i++) {
            // items.push(this.renderedItem(this.state.items[i], i));
            items.push(<BrandHandle
                key={i}
                permission={this.props.permission}
                item={this.state.items[i]}
                index={i}
                changeFunction={this.handleItemChange}
                deleteItemFunction={this.deleteItem}
            />)
        }
        const canAddMore = (items.length < 5 && this.props.permission);

        return (
            <div className="brandHandles">
                <div className="fieldTitle">
                    Brand Handles
                </div>
                <div className="box hideAmBorder">
                    { items }
                    { canAddMore &&
                        <div className="controlLink addItem" onClick={this.add}>
                            + Add Brand Handle
                        </div>
                    }
                </div>
            </div>
        )
    }
}
