import moment from 'moment';
import { intersection, isEqual } from 'lodash';
import { defaultInfluencerPicture } from './reducer';

export const checkIsInvited = (alreadyInvitedInfluencers, influencer) => alreadyInvitedInfluencers
    .findIndex(({ user }) => user.uid === influencer.uid) > -1;

export const getInfluencerChannels = (userNetworks) => userNetworks.map(({ networkType }) => networkType);

export const checkHasRequiredChannels = (requiredAssignmentChannels, influencerChannels) => {

    // TODO: Temporary Twitter requirement removal - delete the below line and uncomment isEqual when we want to revert
    return true;

//    return isEqual(
//        intersection(requiredAssignmentChannels, influencerChannels),
//        requiredAssignmentChannels,
//    );
};

/*
    joined filter is based on how long is influencer on the platform. Available options:
    * < 30 days (value: 30)
    * 30-90 days (value: 90)
    * > 90 days (value: 150)
 */

export const buildJoinedFilter = (value) => {
    if (!(value instanceof Number)) {
        value = Number.parseInt(value);
    }
    const fromDay = value;
    const toDay = value - 60;
    const fromDate = value === 150
        ? moment().subtract(toDay, 'days').format('YYYY-MM-DD')
        : moment().subtract(fromDay, 'days').format('YYYY-MM-DD');
    // +1 day is there to avoid time zones differences (because dates are sent in a short, non-ISO, format)
    const toDate = value === 150 ? '' : (value > 30
        ? moment().subtract(toDay, 'days')
            .add(1, 'days')
            .format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD'));

    return {
        filterId: 6,
        operator: 'all',
        range: fromDate ? [toDate, fromDate] : [toDate],
    };
};

/*
    This function was moved here as part of InfluencerBody refactor.
    And I think it works in an incorrect way:
    * the date ranges are managed on front-end without timezones
    * for influencers who joined more than 90 days ago it will always return interval 150-90 days (two months)
    I suppose that all calculations should be on back-end with only picked value (not range) sent as payload
 */
// ==========================================================================================================

/*
    Creates both inviteList and inappropriateInviteList.
    * in invite list can't be added already invited influencers
    * in inappropriateInvite list should be added influencers with incomplete set of required channels
 */
export const createInviteLists = (influencers, alreadyInvitedInfluencers, requiredAssignmentChannels, existInviteList) => {
    const lists = influencers.reduce(({ inviteList, inappropriateInviteList }, influencer) => {
        const nextInviteList = { ...inviteList, ...existInviteList };
        const nextInappropriateInviteList = { ...inappropriateInviteList };
        const {
            uid,
            id,
            pictureUrl,
            firstname,
            lastname,
            rates,
            userNetworks,
        } = influencer;
        const isInvited = checkIsInvited(alreadyInvitedInfluencers, influencer);
        const influencerChannels = getInfluencerChannels(userNetworks);
        const hasRequiredChannels = checkHasRequiredChannels(requiredAssignmentChannels, influencerChannels);

        const invite = {
            id,
            uid,
            rates,
            pictureUrl: pictureUrl || defaultInfluencerPicture,
            name: `${firstname} ${lastname}`,
            firstname,
            lastname,
            userNetworks
        };

        if (!isInvited) {
            nextInviteList[uid] = invite;
        }

        if (!isInvited && !hasRequiredChannels) {
            nextInappropriateInviteList[uid] = invite;
        }

        return {
            inviteList: nextInviteList,
            inappropriateInviteList: nextInappropriateInviteList,
        };
    }, {
        inviteList: {},
        inappropriateInviteList: {},
    });

    return lists;
};
