import React from 'react'
import Tooltip from '../../shared/tooltip/Tooltip'
import Checklist from './Checklist'
import BrandHandles from './BrandHandles'
import ProgramHashtags from './ProgramHashtags'
import Api from '../../../modules/Api';
import 'react-quill/dist/quill.snow.css';
import BrandUrls from './brandUrls/BrandUrls';
import TextInput from './textInput/TextInput'
import EditSummary from './EditSummary'
import SelectSingleAssignment from '../selectAssignments/SelectSingleAssignment'
import ApiError from '../../shared/apiError/ApiError';

const max1024 = (txt) => {
    if (typeof txt === 'string') {
        return txt.length <= 1024;
    }
    return true;
}

// props
// - assignment
// - delegateAssignmentChangeFunction takes assignment object

export default class AssignmentPage extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.handleChecklistChange = this.handleChecklistChange.bind(this);
        this.saveSummary = this.saveSummary.bind(this);
        this.handleBrandHandleChange = this.handleBrandHandleChange.bind(this);
        this.handleHashtagChange = this.handleHashtagChange.bind(this);
        this.handleDisclaimerChange = this.handleDisclaimerChange.bind(this);
        this.handlePromotionalTextChange = this.handlePromotionalTextChange.bind(this);
        this.handleUrlChange = this.handleUrlChange.bind(this);
        this.assigmentToSave = this.assignmentToSave.bind(this);
        this.delegateChange = this.delegateChange.bind(this);

        this.state = {
            apiError: null,
        };

        this.tooltips = {
            summary:    `Provide a detailed overview of the assignment, including brand objectives and ` +
                        `any specific requirements for the creator(s).`,
            checklist:  `Please list every item required of the Creator. ` +
                         `Each item should be listed as a separate row so the Creator is clear on his/her responsibilities.`,
            social :    "Include any social handle(s) or hashtags you'd like the Creator(s) to tag or mention in their post.",
            url:        "Include any URLs you'd like Creator(s) to include in their post, such as a brand website or product page.",
            disclaimer: "Creator(s) must disclose any sponsored content, per guidelines of the Federal Trade Commission."
        }
    }

    delegateChange(newAssignmentObject) {
        const self = this;
        Api.updateAssignment(newAssignmentObject.id, newAssignmentObject).then(
            function(success){
                const f = self.props.delegateAssignmentChangeFunction;
                if (typeof f === 'function'){
                    f(newAssignmentObject)
                }
            })
            .catch(err => {
                this.setState({apiError: err})
            })
    }

    assignmentToSave() {
        const assignmentToSave = {};
        for (const ix in this.props.assignment) {
            assignmentToSave[ix] = this.props.assignment[ix];
        }
        return assignmentToSave;
    }

    handleUrlChange(newItemList) {
        const assignment = this.assignmentToSave();
        assignment.brandUrls = newItemList;
        this.setState({
            urls: newItemList,
        })
        this.delegateChange(assignment);
    }

    handleDisclaimerChange(newDisclaimer) {
        const assignment = this.assignmentToSave();
        assignment.ftcDisclaimer = newDisclaimer;
        this.setState({
            disclaimer: newDisclaimer,
        })
        this.delegateChange(assignment);
    }

    handlePromotionalTextChange(newText) {
        const assignment = this.assignmentToSave();
        assignment.promotionalText = newText;
        this.setState({
            promotionalText: newText,
        })
        this.delegateChange(assignment);
    }

    handleHashtagChange(newItemList) {
        this.setState({hashtags : newItemList});
        const savedHashTags = [];
        newItemList.map((tag)=> {
            savedHashTags.push(`#${  tag}`)
        })
        const assignment = this.assignmentToSave();
        assignment.programHashtags = savedHashTags;
        this.delegateChange(assignment);
    }

    handleBrandHandleChange(newItemList) {
        const filteredItemList = [];
        for (const item of newItemList) {
            if (item.type && item.handle) {
                filteredItemList.push(item);
            }
        }
        this.setState({handles: filteredItemList});
        const assignment = this.assignmentToSave();
        const savedHandles = [];
        filteredItemList.map((handle)=> {
            savedHandles.push({
                type : handle.type,
                handle : `@${  handle.handle}`
            })
        })
        assignment.brandHandles = savedHandles;
        this.delegateChange(assignment);
    }

    handleChecklistChange(newChecklist) {
        const assignment = this.assignmentToSave();
        assignment.checklist = newChecklist;
        this.delegateChange(assignment);
    }

    saveSummary(newSummary) {
        const assignment = this.assignmentToSave();
        assignment.summary = newSummary;
        this.delegateChange(assignment);
    }

    componentDidMount() {
        const incomingHashTags = this.props.assignment.programHashtags;
        const hashTags = [];
        if (Array.isArray(incomingHashTags)) {
            incomingHashTags.map( (tag) => {
                hashTags.push(tag.substring(1));
            })
        }
        const incomingHandles = this.props.assignment.brandHandles;
        const handles = [];
        if (Array.isArray(incomingHandles)) {
            incomingHandles.map( (handle) => {
                let text = '';
                if (typeof handle.handle === 'string'  && handle.handle.indexOf('@') === 0) {
                    text = handle.handle;
                    text = text.substring(1);
                }
                handles.push({
                    type : handle.type,
                    handle : text
                })
            })
        }
        let urls = [];
        const incomingUrls = this.props.assignment.brandUrls;
        if (Array.isArray(incomingUrls)) {
            urls = incomingUrls;
        }
        const self = this;
        const {campaign} = this.props;
        const disclaimer =
            `This is a sponsored conversation written by me on behalf of ${
                campaign && campaign.agencyBrand && campaign.agencyBrand.brandName ? campaign.agencyBrand.brandName : ''
            }. The opinions and text are all mine.`

        const promotionalText = this.props.assignment.promotionalText || ''

        this.setState({
            hashTags,
            handles,
            urls,
            resources : this.props.assignment.resources || [],
            disclaimer : this.props.assignment.ftcDisclaimer || disclaimer,
            promotionalText
        }, function(){
        })
    }

    render() {
        const {assignment} = this.props;
        const socialNetworkOptions = {
            handler : '',
            tags : []
        };
        const handlers = assignment.brandHandlers;
        if (Array.isArray(handlers) && handlers.length > 0) {
            socialNetworkOptions.handler = handlers[0];
        }
        const tags = assignment.programHashtags;
        if (Array.isArray(tags)) {
            socialNetworkOptions.tags = tags;
        }
        const self = this;
        const {permission, campaign} = this.props;
        const {apiError, urls, handles, hashTags} = this.state;
        return (
            <div className="assignmentFields">
                <ApiError
                    show={apiError}
                    error={apiError}
                    cancelFunction={() => this.setState({apiError: null})}
                />
                <SelectSingleAssignment
                    campaignId={campaign.id}
                    noselect
                />
                <div className="fieldTitle">
                    <span>Assignment Summary&nbsp;&nbsp;</span>
                    <Tooltip tooltipText={this.tooltips.summary}/>
                </div>
                <EditSummary
                    permission={permission}
                    originalContent = {assignment.summary}
                    saveFunction = {this.saveSummary}
                />
                <div className="fieldTitle">
                    <span>Requirement Checklist&nbsp;&nbsp;</span>
                    <Tooltip tooltipText={this.tooltips.checklist}/>
                </div>
                <Checklist
                    permission={permission}
                    checklist={this.props.assignment.checklist || []}
                    changeFunction={this.handleChecklistChange}
                />

                <a name="social" /><div className="fieldTitle">
                    <span>Social Network Options&nbsp;&nbsp;</span>
                    <Tooltip tooltipText={this.tooltips.social}/>
                </div>

                <div className="social box">
                    {Array.isArray(handles) &&
                    <BrandHandles
                        permission={permission}
                        items={handles }
                        changeFunction={this.handleBrandHandleChange}
                    />
                    }
                    <div style={{borderBottomStyle:'solid', borderWidth: 1, borderColor:'#d2d2d2'}}/>
                    {Array.isArray(hashTags) &&
                    <ProgramHashtags
                        items={hashTags }
                        permission={permission}
                        changeFunction={this.handleHashtagChange}
                    />
                    }
                </div>

                <div className="fieldTitle">
                    <span>Brand URLs&nbsp;&nbsp;</span>
                    <Tooltip tooltipText={this.tooltips.url}/>
                </div>
                { Array.isArray(urls) &&
                <BrandUrls
                    items={urls}
                    permission={permission}
                    changeFunction={this.handleUrlChange}
                />
                }
                <div className="fieldTitle">
                    Additional Options
                </div>

                {/* <div className="box" style={{padding:0}}>
                    <div className="legalTerms" style={{borderTopStyle: 'none'}}>
                        <div className="listItem" style={{borderTopStyle:'none'}}>
                            <div style={{padding:0}}>
                                <div className="fieldTitle" style={{marginBottom: 5, marginLeft: 0, marginTop: 0}}>
                                    <span>FTC disclaimer&nbsp;&nbsp;</span>
                                    <Tooltip tooltipText={this.tooltips.disclaimer}/>
                                </div>
                                <div>
                                    <TextInput
                                        disabled={!this.props.permission}
                                        itemText={this.state.disclaimer}
                                        changeFunction={this.handleDisclaimerChange}
                                        validateFunction={max1024}
                                        invalidMessage = "Maximum 1024 characters"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="box" style={{padding:0, marginTop:15}}>
                    <div className="legalTerms" style={{borderTopStyle: 'none'}}>
                        <div className="listItem" style={{borderTopStyle:'none'}}>
                            <div style={{padding:0}}>
                                <div className="fieldTitle" style={{marginBottom: 5, marginLeft: 0, marginTop: 0}}>
                                    Promotional Text&nbsp;&nbsp;
                                </div>
                                <div>
                                    <TextInput
                                        disabled={!this.props.permission}
                                        itemText={this.state.promotionalText}
                                        changeFunction={this.handlePromotionalTextChange}
                                        validateFunction={max1024}
                                        invalidMessage = "Maximum 1024 characters"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
