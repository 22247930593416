import _ from 'lodash';
import savedSearches from '../../components/influencers2/savedSearches/savedSearches';

export default class CommonUtils {
    // search containers whether contain any of the values. 
    // returns true if any containers contain at least one value in values
    static containsElement = (containers, values) => {
        if (!containers || !values || containers.length === 0 || values.length === 0) {
            return false;
        }
        return containers.some(c => values.includes(c));
    }
}

export const loadThumbnailError = ({currentTarget}) => {
    if (currentTarget) {
        currentTarget.src='/images/icon_broken_image.svg';
        const rect = currentTarget.getBoundingClientRect();
        currentTarget.style.width = '48px';
        currentTarget.style.height = '48px';
        currentTarget.style.padding = `${(rect.height - 48) / 2}px ${(rect.width - 48) / 2}px`;
        currentTarget.style.backgroundColor = '#ECF4F3';
    }
}

const removeEmptyDataInSavedSearch = (incomeObject) => {
    if (incomeObject) {
        const outputObject = {};
        Object.assign(outputObject, incomeObject)
        for (const [key, value] of Object.entries(incomeObject)) {
            if ((Array.isArray(value) && _.isEmpty(value)) || value === null) {
                delete outputObject[key];
            } else if (key === 'range_to' && value === 9223372036854776000) {
                delete outputObject[key];
                if (outputObject.range_from === 0) {
                    delete outputObject.range_from;
                }
            }
        }
        return outputObject;
    }
    return {};
}

// key/value pair object
export const handleEmptyDataInSavedSearch = (contents) => {
    if (Array.isArray(contents)) {
        const tempContents = [...contents];
        tempContents.forEach((incomeObject) => {
            if (incomeObject.param) {
                if (Array.isArray(incomeObject.param.commonFilters)) {
                    const temp = [];
                    incomeObject.param.commonFilters.forEach(cf => {
                        const newCf = removeEmptyDataInSavedSearch(cf);
                        temp.push(newCf);
                    })
                    incomeObject.param.commonFilters = temp;
                }
                if (Array.isArray(incomeObject.param.audienceFilters)) {
                    const temp = [];
                    incomeObject.param.audienceFilters.forEach(af => {
                        const newAf = removeEmptyDataInSavedSearch(af);
                        temp.push(newAf);
                    })
                    incomeObject.param.audienceFilters = temp;
                }
                if (Array.isArray(incomeObject.param.connectedFilters)) {
                    const temp = [];
                    incomeObject.param.connectedFilters.forEach(af => {
                        const newAf = removeEmptyDataInSavedSearch(af);
                        temp.push(newAf);
                    })
                    incomeObject.param.connectedFilters = temp;
                }
            }
        })
        return tempContents;
    }
    return null;
}

export const updateSavedSearches = (publicFilters, connectedFilters, searches) => {
    const output = {
        searches,
        publicKeywordFilterTemplate: [],
        connectedKeywordFilterTemplate: [],
    }
    if (Array.isArray(searches)) {
        // proceed if filters not being done yet
        if (!publicFilters || _.isEmpty(publicFilters) || !connectedFilters || _.isEmpty(connectedFilters) || !searches || _.isEmpty(searches)) {
            return output;
        }
        // not being done yet
        const alreadyDone = searches.find(s => 
            s.param &&
            ((s.param.audienceFilters.length > 0 || s.param.commonFilters.length > 0 || 
                (s.param?.type === 'CONNECTED' && s.param?.connectedFilters?.length > 0)) && 
                s.param.selectedFilters));
        if (alreadyDone) {
            return output;
        }
        const temp = [...searches];
        let filter;
        const publicCommonFilters = publicFilters.find(p => p.name === 'common');
        const publicAudienceFilters = publicFilters.find(p => p.name === 'audience_insights');
        const publicKeywordFilters = publicFilters.find(p => p.name === 'keyword');
        const connectedCommonFilters = connectedFilters.find(p => p.name === 'common');
        const connectedAudienceFilters = connectedFilters.find(p => p.name === 'audience_insights');
        const connectedKeywordFilters = connectedFilters.find(p => p.name === 'keyword');
        const connectedConnectedFilters = connectedFilters.find(p => p.name === 'connected');

        if (publicKeywordFilters && Array.isArray(publicKeywordFilters.filters)) {
            output.publicKeywordFilterTemplate = publicKeywordFilters?.filters || [];
        } else if (connectedKeywordFilters && Array.isArray(connectedKeywordFilters.filters)) {
            output.connectedKeywordFilterTemplate = connectedKeywordFilters?.filters || [];
        }

        // loop through the saved searches to get the associate with filter templates, then save in selectedFilters
        searches.forEach((search, i) => {
            const filterTemplates = [];
            if (search.param && Array.isArray(search.param.commonFilters)) {
                search.param.commonFilters.forEach(s => {
                    if (search.param.type === 'PUBLIC' && publicCommonFilters && Array.isArray(publicCommonFilters.filters)) {
                        filter = publicCommonFilters.filters.find(p => p.id === s.filterId);
                    } else if (search.param.type === 'CONNECTED' && connectedCommonFilters && Array.isArray(connectedCommonFilters.filters)) {
                        filter = connectedCommonFilters.filters.find(c => c.id === s.filterId)
                    }
                    if (filter) {
                        filter.group = 'common';
                        filterTemplates.push(filter);
                    }
                });
            }
            if (search.param && Array.isArray(search.param.audienceFilters)) {
                filter = null;
                search.param.audienceFilters.forEach(s => {
                    if (search.param.type === 'PUBLIC' && publicAudienceFilters && Array.isArray(publicAudienceFilters.filters)) {
                        filter = publicAudienceFilters.filters.find(p => p.id === s.filterId);
                    } else if (search.param.type === 'CONNECTED' && connectedAudienceFilters && Array.isArray(connectedAudienceFilters.filters)) {
                        filter = connectedAudienceFilters.filters.find(c => c.id === s.filterId)
                    }
                    if (filter) {
                        filter.group = 'insights';
                        filterTemplates.push(filter);
                    }
                });
            }

            if (search.param && search.param.type === 'CONNECTED' && Array.isArray(search.param.connectedFilters)) {
                filter = null;
                search.param.connectedFilters.forEach(s => {
                    if (connectedConnectedFilters && Array.isArray(connectedAudienceFilters.filters)) {
                        filter = connectedConnectedFilters.filters.find(c => c.id === s.filterId)
                    }
                    if (filter) {
                        filter.group = 'connected';
                        filterTemplates.push(filter);
                    }
                });
            }
            if (temp[i].param) {
                temp[i].param.selectedFilters = filterTemplates;
            }
        })
        output.searches = temp;
    }
    return output;
}
