import React from "react";
import './mfaSettings.scss'

import Select from 'react-select'
import ErrorMessageBox from '../errorMessageBox/ErrorMessageBox'
import FeedbackOnTheTop from '../feedbackOnTheTop/FeedbackOnTheTop'
import Checkbox from '../checkbox/CheckBoxWithLabel';
import {getPrefferedMFA, getUser, setupTOTP, verifyTotpToken, setPreferredMFA} from './multiFactorAuthService';
import QRCode from 'qrcode.react';

const MFASetUpStatus = {
  NOT_STARTED: 0,
  SETUP_STARTED: 10,
  RECONFIG_STARTED: 11,
  CODE_REQUESTED: 20,
  CODE_RECEIVED: 30,
  TOTP_TOKEN_SENT: 40,
  TOTP_TOKEN_INVALID: 50,
  TOTP_TOKEN_VERIFIED: 60,
  MFA_TYPE_SET_ERROR: 70,
  SETUP_COMPLETED: 999
};

const MFATypes = {
  SMS: "SMS",
  TOTP: "TOTP",
  NO_MFA: "NOMFA"
};

const mfaTypeOptions = [
  /*{value: MFATypes.SMS, label: MFATypes.SMS},*/
  {value: MFATypes.TOTP, label: MFATypes.TOTP}
];



export default class MFASettings extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      mfaEnabled: false,
      mfaSetUpStatus: MFASetUpStatus.NOT_STARTED,
      mfaType: MFATypes.TOTP,
      totpToken: "",
      mfaUser: null
    };

  }

  async componentDidMount() {
    let mfaUser = await MFASettings.getMFAUser();
    let mfaSetting = await MFASettings.getPreferredMFASetting(mfaUser);
    let mfaEnabled = (mfaSetting && (mfaSetting !== MFATypes.NO_MFA));
    let mfaType = mfaEnabled ? MFASettings.getMFATypeFromSetting(mfaSetting) : MFATypes.TOTP;
    let mfaSetupStatus = mfaEnabled ? MFASetUpStatus.SETUP_COMPLETED : MFASetUpStatus.NOT_STARTED;
    this.setState({
      mfaEnabled: mfaEnabled,
      mfaSetUpStatus: mfaSetupStatus,
      mfaType: mfaType,
      totpToken: "",
      mfaUser: mfaUser
    });
  }

  static async getMFAUser() {
    try {
      let mfaUser = await getUser();
      return mfaUser;
    } catch (e) {
      console.log(e);
    }
    return null;
  }

  static async getPreferredMFASetting(user) {
    if (user) {
      try {
        return await getPrefferedMFA(user);
      } catch (e) {
        console.log(e);
      }
    }
    return null;
  }

  static getMFATypeFromSetting(mfaSetting) {
    switch (mfaSetting) {
      case "SOFTWARE_TOKEN_MFA":
        return MFATypes.TOTP;
      case "SMS_MFA":
        return MFATypes.SMS;
      default:
        return MFATypes.NO_MFA;
    }
  }

  mfaEnabledStateChanged = (enabled) => {
    if (enabled) {
      //start setup for enabling MFA for user
      this.setState({
        mfaEnabled: true,
        mfaSetUpStatus: MFASetUpStatus.SETUP_STARTED
      });
    } else {
      // disable MFA
      this.setState(
        {mfaType: MFATypes.NO_MFA},
        this.sendPreferredMFARequest(this.state.mfaUser, MFATypes.NO_MFA)
      );
    }
  };

  canRequestNewCode = () => {
    return (this.state.mfaSetUpStatus === MFASetUpStatus.SETUP_STARTED)
      || (this.state.mfaSetUpStatus === MFASetUpStatus.RECONFIG_STARTED)
      || (this.state.mfaSetUpStatus === MFASetUpStatus.TOTP_TOKEN_INVALID);
  };

  canVerifyTOTPToken = () => {
    return this.state.totpToken && (this.state.totpToken.length > 0)
      && ((this.state.mfaSetUpStatus === MFASetUpStatus.CODE_RECEIVED)
      || (this.state.mfaSetUpStatus === MFASetUpStatus.TOTP_TOKEN_INVALID)
      || (this.state.mfaSetUpStatus === MFASetUpStatus.MFA_TYPE_SET_ERROR));
  };

  startMFASetup = () => {
    if (this.canRequestNewCode()) {
      this.setState({mfaSetUpStatus: MFASetUpStatus.CODE_REQUESTED}, this.requestMFASetupCode);
    }
  };

  requestMFASetupCode = () => {
    let user = this.state.mfaUser;
    setupTOTP(user).then(code => {
      this.setState({
        mfaUser: user,
        mfaSetUpStatus: MFASetUpStatus.CODE_RECEIVED,
        mfaCode: code
      });
    });
  };

  verifyTOTPToken = () => {
    if (this.canVerifyTOTPToken()) {
      this.setState({mfaSetUpStatus: MFASetUpStatus.TOTP_TOKEN_SENT}, this.sendVerifyRequest);
    }
  };

  sendPreferredMFARequest = (user, mfaType) => {
    setPreferredMFA(user, mfaType).then(res => {
      switch (mfaType) {
        case MFATypes.TOTP:
          this.setState({
            mfaSetUpStatus: MFASetUpStatus.SETUP_COMPLETED,
            successMessage: "MFA enabled and TOTP setup completed"
          });
          break;
        case MFATypes.NO_MFA:
          this.setState({
            mfaEnabled: false,
            mfaSetUpStatus: MFASetUpStatus.NOT_STARTED,
            successMessage: "Two factor authentication disabled!"
          });
          break;
      }
    }).catch(err => {
      console.log(err);
      switch (mfaType) {
        case MFATypes.TOTP:
          this.setState({
            mfaSetUpStatus: MFASetUpStatus.MFA_TYPE_SET_ERROR,
            error_Message: "Provided token is valid. Server error occurred while setting MFA type to 'TOTP'. Please try sending TOTP token again"
          });
          break;
        case MFATypes.NO_MFA:
          this.setState({
            mfaSetUpStatus: MFASetUpStatus.MFA_TYPE_SET_ERROR,
            error_Message: "Server error occurred while disabling MFA"
          });
      }
    });
  };

  sendVerifyRequest = () => {
    let challengeAnswer = this.state.totpToken;
    let user = this.state.mfaUser;
    verifyTotpToken(user, challengeAnswer).then(() => {
      this.setState({mfaSetUpStatus: MFASetUpStatus.TOTP_TOKEN_VERIFIED}, () => {
        this.sendPreferredMFARequest(this.state.mfaUser, MFATypes.TOTP);
      });
    }).catch(err => {
      this.setState({
        mfaSetUpStatus: MFASetUpStatus.TOTP_TOKEN_INVALID,
        error_Message: "Provided token is invalid. Please try sending a valid TOTP token again"
      });
    });
  };

  restartMFASetup = () => {
    this.setState({mfaSetUpStatus: MFASetUpStatus.RECONFIG_STARTED});
  };

  closeErrorMessage = () => {
    this.setState({error_Message:null});
  };

  closeSuccessMessage = () => {
    this.setState({successMessage:null});
  };


  render() {

    let btnStartMFA = (this.canRequestNewCode()) ? 'button' : 'button disabled';
    let canReConfigure = this.state.mfaEnabled && this.state.mfaSetUpStatus === MFASetUpStatus.SETUP_COMPLETED;
    let btnReconfigure = canReConfigure ? 'button reconfigure' : 'button disabled';
    let btnStartMFAText = (this.state.mfaSetUpStatus===MFASetUpStatus.SETUP_STARTED) ? 'Start Setup' : 'Resend Code';
    let btnVerifyTOTPToken = (this.canVerifyTOTPToken()) ? 'button' : 'button disabled';

    return (
      <div>
        <ErrorMessageBox
          show={this.state.error_Message}
          closeFunction = {this.closeErrorMessage}
          message = {this.state.error_Message}
        />
        <FeedbackOnTheTop
          show={this.state.successMessage}
          closeFunction = {this.closeSuccessMessage}
          message = {this.state.successMessage}
        />

        <div className="contentTitle">Multi Factor Authentication</div>
        <div className="mfaSettings">
          <div className="innerDiv">
          <Checkbox
            checked = {this.state.mfaEnabled}
            changeFunction = {this.mfaEnabledStateChanged}
            label={"Enable MFA"}
          />
          {canReConfigure &&
            <div className={btnReconfigure} onClick={this.restartMFASetup}>
              Reconfigure
            </div>
          }
          {this.state.mfaEnabled && this.state.mfaSetUpStatus >= MFASetUpStatus.SETUP_STARTED && this.state.mfaSetUpStatus < MFASetUpStatus.SETUP_COMPLETED &&
            <div>

              <div className="label">
                Select MFA Type
              </div>
              <Select className="select" clearable={false} value={this.state.mfaType} placeholder="Select MFA Type" searchable={false}
                      options={mfaTypeOptions} onChange={(selected) => { this.setState({ mfaType: selected.value }) }} />

              <div className="btnBox">
                <div className={btnStartMFA} onClick={this.startMFASetup}>
                  {btnStartMFAText}
                </div>
              </div>

              {this.state.mfaSetUpStatus >= MFASetUpStatus.CODE_RECEIVED &&
                <div className="codeBox">
                  <div className="label">
                    Code Received
                  </div>
                  <span className="mfaCode">{this.state.mfaCode}</span>
                  <div className="qrCode">
                    <div className="label">
                      QR Code
                    </div>
                    <QRCode value={"otpauth://totp/AWSCognito:"+ this.state.mfaUser.username + "?secret=" + this.state.mfaCode + "&issuer=" + this.state.mfaUser.signInUserSession.idToken.payload.iss}/>
                  </div>

                  <div className="verifyBox">
                    <div className="label">
                      Enter TOTP token from your authenticator application below
                    </div>
                    <input className="inputTOTPToken" value={this.state.totpToken} onChange={(e)=>{this.setState({totpToken: e.target.value})}} />
                    <div className="btnBox">
                      <div className={btnVerifyTOTPToken} onClick={this.verifyTOTPToken}>
                        Verify Token
                      </div>
                    </div>
                  </div>

                </div>
              }

            </div>
          }

          </div>
        </div>
      </div>
    );

  }
}