import React, { Component } from 'react';
import './discoverPanel.scss';
import { connect } from 'react-redux';
import {
    saveFavorites,
    deleteFavorites,
    createFavorites,
    maxNumberOfFavoriteLists,
    allFavoritesName

} from '../../../store/actions/favoriteActions';
import PulseLoader from '../../shared/pulseLoader/PulseLoader';
import FavoriteItem from './FavoriteItem';
import Modal from 'react-modal'


class FavoriteMenu extends Component {

    constructor(props) {
        super(props);

        this.state = {
            lists: null,
            loading: false,
            filter: '',
            showConfirm: false,
            listToDelete: null,
        }
    }

    renderedSpinner = () => {
        if (!this.state.loading || !this.containerElement) {
            return null;
        }
        return (
            <div className="loading">
                <div className="dots">
                    <PulseLoader color="#4AA129" size="16px" margin="4px" />
                </div>
            </div>
        )
    }

    isDuplicate = (name) => {
        if (!Array.isArray(this.props.favoriteData)) {
            return false;
        }
        for (let list of this.props.favoriteData) {
            if (list.combined) {
                continue;
            }
            if (list.name.toLowerCase() === name.toLowerCase()) {
                return true;
            }
        }
        if (name.toLowerCase() === allFavoritesName.toLowerCase()) {
            return true;
        }
        return false;
    }

    saveList = (fav) => {
        if (fav.id) {
            this.props.saveFavorites(fav.id, {description: fav.description, name: fav.name, users: fav.users})
        } else {
            this.props.createFavorites({description: fav.description, name: fav.name, users: fav.users});
        }
    }

    componentDidMount() {
        this.closeFunction = this.props.closeFunction;
        window.addEventListener('click', this.closeFunction);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.closeFunction);
    }

    deleteList = (list) => {
        this.setState({ showConfirm: true, listToDelete: list });
    }

    proceedDelete = () => {
        const { listToDelete } = this.state;
        const { deleteFavorites } = this.props;
        if (listToDelete && deleteFavorites) {
            deleteFavorites(listToDelete.id, true);
        }
        this.setState({ showConfirm: false, listToDelete: null });
    }

    filterLists = (e) => {
        let filterValue = e.target.value;
        this.setState({
            filter : filterValue
        })
    }

    render() {
        const { selectedFav, favoriteDeleted, toggleFavourite } = this.props;
        const { showConfirm, listToDelete } = this.state;
        if (selectedFav && selectedFav.length > 0) {
            let selFav = this.props.favoriteData.find(f => f.id === selectedFav[0]);
            if (!selFav && favoriteDeleted) {
                favoriteDeleted();
            } 
        }
        let lists = [];
        for (let l of this.props.favoriteData) {
            if (l.combined) {
                continue;
            }
            let name = l.name;
            let filter = this.state.filter;
            if (typeof filter === 'string' && filter.length > 0) {
                filter = filter.toLowerCase();
                if (typeof name === 'string' && name.toLowerCase().indexOf(filter) < 0) {
                    continue;
                }
            }
            lists.push(l);
        }
        let columns = [];
        if (Array.isArray(lists)) {
            for (let i = 0; i < lists.length; i++) {
                let comp = <FavoriteItem
                    key={i}
                    list={lists[i]}
                    isDuplicateFunction={this.isDuplicate}
                    saveFunction={this.saveList}
                    loading={this.props.loading}
                    selected={selectedFav.find(f => f == lists[i].id)}
                    activateFilterFunction={this.props.activateFilterFunction}
                    deleteFunction={this.deleteList}
                />
                columns.push(comp);
            }
        }

        return (
            <div className="favoriteItemWrapper"
                onClick={(e) => {
                    e.stopPropagation();
                }}
                ref={(e) => { this.containerElement = e }}
            >
                {toggleFavourite && <div className="closeSection">
                    <div onClick={toggleFavourite}>+</div>
                </div>}
                { this.renderedSpinner()}
                <div className="favoriteMenuHead">
                    <div className="favoriteListTitle">Favorite lists</div>
                    <FavoriteItem
                        list={{ empty: true, description: '', name: '', users: [] }}
                        isDuplicateFunction={this.isDuplicate}
                        saveFunction={this.saveList}
                        loading={this.props.loading}
                        // selected = {selectedFav.find(f => f==lists[i].id )}
                        activateFilterFunction={this.props.activateFilterFunction}
                        deleteFunction={this.deleteList}
                    />
                </div>
                <div className="searchBoxContainer">
                    <input
                        onChange={this.filterLists}
                        placeholder="Filter list"
                        type="text"
                        id="search"
                        ref={(input) => { this.searchInput = input; }}
                    />
                    <img src="/images/ic-mag.svg" onClick={this.focusInput} />
                </div>
                <div className="favoritesList">
                    {columns}
                </div>
                <Modal
                    isOpen={showConfirm}
                    contentLabel="Loading Modal"
                    closeTimeoutMS={300}
                    overlayClassName="confirmDeleteDialogOverlay"
                    className="confirmDeleteDialog"
                    ariaHideApp={false}
                    shouldCloseOnOverlayClick={true}
                    onRequestClose={() => this.setState({ showConfirm: false })}
                >
                    <div className="titleLabel">
                        Confirm delete
                            <span>{`Are you sure you want to delete “${listToDelete ? listToDelete.name : ''}” list?`}</span>
                    </div>
                    <div className="buttons">
                        <div className="cancel" onClick={() => this.setState({ showConfirm: false })}>Cancel</div>
                        <div className="delete" onClick={this.proceedDelete}>Delete</div>
                    </div>
                </Modal>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        favoriteData: state.FAVORITE_REDUCER.favoriteData,
        loading: state.FAVORITE_REDUCER.favoriteLoading,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        saveFavorites: (favId, fav) => dispatch(saveFavorites(favId, fav)),
        deleteFavorites: (favId, refetch) => dispatch(deleteFavorites(favId, refetch)),
        createFavorites: fav => dispatch(createFavorites(fav))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FavoriteMenu);