/** FAVORITES TYPES */
export const FAVORITES_CREATE = 'favorites/favorites_create';
export const FAVORITES_READ = 'favorites/favorites_read';
export const FAVORITES_UPDATE = 'favorites/favorites_update';
export const FAVORITES_DELETE = 'favorites/favorites_delete';
export const FAVORITE_LOADING = 'favorites/favorites_loading';
export const FAVORITE_ERROR = 'favorites/favorites_error';
export const FAVORITE_SUCCESS = 'favorites/favorites_success';
/** END FAVORITE TYPES */
/** EXCLUSIONS TYPES */
export const EXCLUSIONS_CREATE = 'exclusions/exclusions_create';
export const EXCLUSIONS_READ = 'exclusions/exclusions_read';
export const EXCLUSIONS_UPDATE = 'exclusions/exclusions_update';
export const EXCLUSIONS_DELETE = 'exclusions/exclusions_delete';
export const EXCLUSION_LOADING = 'exclusions/exclusions_loading';
export const EXCLUSION_ERROR = 'exclusions/exclusions_error';
export const EXCLUSION_SUCCESS = 'exclusions/exclusions_success';
/** END FAVORITE TYPES */
/** NEW FAVORITES TYPES */
export const FAVORITE_NEW = 'favorites/favorites_new';
export const FAVORITE_DETAILS = 'favorites/favorite_details';
export const FAVORITE_UPDATE = 'favorites/favorite_update';
export const FAVORITE_DELETE = 'favorites/favorite_delete';
export const FAVORITE_ADD_USERS = 'favorites/favorite_add_users';
export const FAVORITE_REMOVE_USERS = 'favorites/favorite_remove_users';
export const FAVORITE_COPY = 'favorites/favorite_copy';
export const FAVORITE_SEARCH = 'favorites/favorite_search';
/** END FAVORITE TYPES */
