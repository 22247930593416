import React, {useState, useEffect} from 'react';
import Select, { Async as AsyncSelect } from 'react-select';

import debouncePromise from 'debounce-promise';
import SelectedOptionRow from '../SelectedOptionRow/SelectedOptionRow';
import { useOptionFilter } from './hooks/useOptionFilter';
import { getAsyncFilterOptions } from './OptionFilter.helpers';

// type: 'OPTION',
// weighted: true,
// multipleSelect: true,
// submit PAYLOAD:
// {
//     filterId: 1,
//     filterOptionIds:[1, 2],
//     customRanges: [{field: 1, minValue: 10},{field: 2, minValue: 22}],
// }
// ------------------------------------------------------------------

// type: 'OPTION',
// weighted: true,
// multipleSelect: false,
// submitPAYLOAD:
// {
//     filterId: 1,
//     filterOptionIds:[1],
//     customValue: 2,
// }

// --------------------------------------------------------------------

// type: 'OPTION',
// weighted: false,
// multipleSelect: false/true,
// submitPAYLOAD:
// {
//     filterId: 1,
//     filterOptionIds:[1,2,3],
// }

const OptionFilter = ({ filter, onSubmit, initialValue, specificNeedChange, processedChange, filterData, setFilterOptions }) => {
    const { options, selectCurrentValue, onOptionRemove, onSelectValueChange, onCustomValueBlur, selectedOptions } = useOptionFilter({
        filter,
        onSubmit,
        specificNeedChange,
        processedChange,
        filterData
    });
    const [customValue, setCustomValue] = useState('');
    useEffect(() => {
        if (initialValue && 
            filter &&
            Array.isArray(initialValue?.filterOptionIds) && 
            initialValue?.filterOptionIds.length > 0 &&
            Array.isArray(filter?.filterOptions)) {
            initialValue?.filterOptionId?.forEach(option => {
                const selected = filter.filterOptions.find(f => f.id === option);
                if (selected) {
                    onSelectValueChange({label: selected.label, value: selected.id});
                    if (initialValue?.customValue) {
                        setCustomValue(initialValue?.customValue);
                    } else if (Array.isArray(initialValue?.customRanges) && 
                        initialValue.customRanges.length > 0 &&
                        initialValue.customRanges[0].minValue) {
                        setCustomValue(initialValue.customRanges[0].minValue);
                    }
                }
            })
        }
    }, []);

    return (
        <div>
            {filter.searchable ? (
                <AsyncSelect
                    defaultOptions
                    loadOptions={debouncePromise(
                        (keyword) => getAsyncFilterOptions({ filterId: filter.id, keyword, selectedOptions, filter, setFilterOptions }),
                        500,
                    )}
                    placeholder={filter?.label}
                    onChange={onSelectValueChange}
                    value={selectCurrentValue}
                    clearable={false}
                />
            ) : (
                <Select
                    options={options}
                    placeholder={filter?.label}
                    onChange={onSelectValueChange}
                    value={selectCurrentValue}
                    clearable={false}
                />
            )}

            {selectedOptions.map((option) => (
                <SelectedOptionRow
                    key={option.value}
                    multipleSelect={filter.multipleSelect}
                    weighted={filter.weighted}
                    label={option.label}
                    onRemove={onOptionRemove(option)}
                    maxValue={filter.maxValue}
                    minValue={filter.minValue}
                    onBlur={onCustomValueBlur(option)}
                    initialValue={customValue}
                />
            ))}
        </div>
    );
};

export default OptionFilter;
