import React, { useState, useEffect, useRef, createRef, useLayoutEffect } from 'react';
import cx from 'classnames';
import ReactDOM from 'react-dom'
import './popUpDialogueBase.scss';
import { LoadingDialogue } from '../../shared/loadingDialogue/loadingDialogue';

const PopUpDialogueBase = ({
    title,
    show,
    icon,
    message,
    windowStyle,
    children,
    hideCancel,
    hideProceed,
    cancelLabel,
    proceedLabel,
    cancelFunction,
    proceedFunction,
    proceedButtonStyle,
    cancelButtonStyle,
    bottomStyle,
    titleStyle,
    loading = false,
    container = "messageContainer"
}) => {
    const el = useRef(document.createElement('div'));
    const [buttonWidth, setButtonWidth] = useState(0);
    const close = () => {
        if (typeof cancelFunction === 'function') {
            cancelFunction();
        }
    };

    const proceed = () => {
        if (typeof proceedFunction === 'function') {
            proceedFunction();
        }
    };

    useLayoutEffect(() => {
        if (show && !hideCancel && !hideProceed) {
            const button1 = document.getElementById("negativeButton");
            const button2 = document.getElementById("positiveButton");
            if (button1 && button2) {
                setButtonWidth(button1.clientWidth > button2.clientWidth ? button1.clientWidth : button2.clientWidth);
            }
        }
    }, [show]);

    useEffect(() => {
        document.getElementById(container).appendChild(el.current);

        return () => {
            document.getElementById(container).removeChild(el.current);
        }
    }, []);

    const renderNode = () => {
        const titleBody = title || '';
        const cancelButton = cancelLabel || "Cancel";
        const proceedButton = proceedLabel || "Ok";
        const messageBody = (typeof message === 'string') ? <div className="message">{message}</div> : message;
        const Icon = icon ? (<div className='iconContainer'>{typeof icon === 'string' ? <img src={icon} /> : icon}</div>) : null;
        const buttonWidthStyle = buttonWidth ? { "width": `${buttonWidth}px` } : {};
        const cButtonStyle = cancelButtonStyle ? { ...buttonWidthStyle, ...cancelButtonStyle } : { ...buttonWidthStyle };
        const pButtonStyle = proceedButtonStyle ? { ...buttonWidthStyle, ...proceedButtonStyle } : { ...buttonWidthStyle };
        const id = `popUpDialogueContainer ${titleBody}`;
        
        return (
            <div className="popUpDialogueContainer">
                {loading && <LoadingDialogue
                    show={loading}
                    container={id}
                />}
                {children || <div id={id} className="dialogueWindow" style={windowStyle || {}}>
                    {Icon && Icon}
                    <div className="title" style={titleStyle || {}}>
                        {titleBody}
                    </div>
                    {messageBody}
                    <div className="bottomSection" style={bottomStyle || {}}>
                        <div className="buttonsWrapper">
                            {!hideCancel &&
                                <div
                                    className="negativeButton"
                                    id="negativeButton"
                                    style={cButtonStyle}
                                    onClick={close}>
                                    {cancelButton}
                                </div>}
                            {!hideProceed &&
                                <div
                                    className="positiveButton"
                                    id="positiveButton"
                                    style={pButtonStyle}
                                    onClick={proceed}>
                                    {proceedButton}
                                </div>}
                        </div>
                    </div>
                </div>}
            </div>
        )

    };

    return (show ? ReactDOM.createPortal(
        renderNode(),
        el.current
    ) : null);
}

export default PopUpDialogueBase;