import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import isEqual from "react-fast-compare";
import Modal from 'react-modal';
import classNames from 'classnames/bind';
import style from './contract.module.scss';
import ContractSettings from './contractSettings';
import PleaseWaitWhite from "../../shared/pleaseWait/PleaseWaitWhite";
import ApiError from "../../shared/apiError/ApiError";
import ModalDialogCampaign from '../modalDialog/ModalDialogCampaign';
import Api from '../../../modules/Api';
import { handleContractSave } from './contractHelper';
import Checkbox from "../checkbox/Checbox";
import AssignmentContractType from '../../../constants/AssignmentContractType';
import PendoHelper from '../../../modules/utils/PendoHelper';

const cx = classNames.bind(style);
Modal.setAppElement('#root');

const actionLabel = 'Add New Contract';

export default class ContractConfig extends React.Component {

    static propTypes = {
        // isVisibleAddScreen : PropTypes.bool,
        selectedTemplate: PropTypes.shape({
            name: PropTypes.string.isRequired,
            id: PropTypes.oneOfType([
                PropTypes.string.isRequired,
                PropTypes.number.isRequired,
            ]),
            resource: PropTypes.object.isRequired,
            data: PropTypes.any
        }),
        contractTemplates : PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                id: PropTypes.oneOfType([
                    PropTypes.string.isRequired,
                    PropTypes.number.isRequired,
                ]),
                resource: PropTypes.object.isRequired,
                data: PropTypes.any
            }),
        ),
        level: PropTypes.oneOf(['assignment', 'agent-user']),
        assignmentId: PropTypes.oneOfType([
            PropTypes.string.isRequired,
            PropTypes.number.isRequired,
        ]),
        assignmentUserID(props, propName, ComponentName) {
            if ((props.level === 'agent-user' && !((typeof(props[propName]) === 'number') || (typeof(props[propName]) === 'string')))) {
                return new Error(`Invalid prop \`${propName}\` of value \`${props[propName]}\` supplied to \`${ComponentName}\`, expected a number`);
            }
        },
        styleType: PropTypes.string,
        openCallback: PropTypes.func,
        closeCallback: PropTypes.func,
        customizeContractCallback: PropTypes.func,
        isAssignmentOffline: PropTypes.bool,
        hasInvitedCreators: PropTypes.bool,
    };

    static defaultProps = {
        // isVisibleAddScreen: false,
        selectedTemplate: { name: '', id: '', resource: {}},
        contractTemplates: [],
        styleType: '',
        openCallback: null,
        closeCallback: null,
        customizeContractCallback: null,
    };

    // map props to states
    static getDerivedStateFromProps(props, state) {
        // console.log('getDerivedStateFromProps', props);
        if (!isEqual(props.selectedTemplate, state.selectedTemplate)) {
            // console.log('update state');
            return {
                selectedTemplate: props.selectedTemplate,
                internal_SelectedTemplate: props.selectedTemplate,
                internal_HoldingTemplate: props.selectedTemplate
            };
        }
        return null;
    }

    constructor(props) {
        super(props);

        this.state = {
            selectedTemplate: props.selectedTemplate, // since slection is driven internally, save copy of initial props passed into this component, if mapping was saved, this would be override with the save data.
            internal_IsVisibleAddScreen: false,
            internal_SelectedTemplate: props.selectedTemplate, // holds the current selection in effect
            internal_HoldingTemplate: props.selectedTemplate, // holds the section being made but not decided yet, on the contract list popup
            internal_IsVisibleConfigScreen: false,
            hadTriggeredSave: false,
            isSaving: false,
            apiError: null,
            showApiError: false,
            isOfflineContractChecked: props.isAssignmentOffline
        }

    }

    /**
     * added to optimize rendering
     * some component using this at the higher level is causing re-rendering 20 times when no props had change
     * its just ridiculous
     * * */
    shouldComponentUpdate(nextProps, nextStates) {

        if (!isEqual(this.state, nextStates)) {
            return true;
        }

        return !isEqual(this.props, nextProps);
    }

    handleAddOpen = () => {
        this.setState(
            { internal_IsVisibleAddScreen : true },
            () => {
                if (this.state.internal_SelectedTemplate.id !== this.state.internal_HoldingTemplate.id) {
                    this.setState({
                        internal_HoldingTemplate : this.state.internal_SelectedTemplate
                    })
                }
                if (this.props.openCallback) {
                    this.props.openCallback();
                }
            }
        );

        PendoHelper.pendoTrack('Campaign_Assignment_Contract_AddNewContract', {});
    };

    handleAddClose = () => {
        this.setState(
            { internal_IsVisibleAddScreen : false },
            function() {
                if (this.props.closeCallback) {
                    this.props.closeCallback();
                }
            });
    };

    handleAddContract = () => {
        this.setState({
            internal_SelectedTemplate: this.state.internal_HoldingTemplate,
            internal_IsVisibleConfigScreen: true
        }, function() {
            if (this.props.openCallback) {
                this.props.openCallback();
            }
        });
    };

    setContractSelection = (template) => {
        this.setState({
            internal_HoldingTemplate : template
        });
    };

    resetSelectedTemplate = () => {
        // console.log('reset');
        Api.deleteAssignmentDefaultContract(this.props.assignmentId)
            .then(
                () => {
                    this.setState({
                        internal_SelectedTemplate: ContractConfig.defaultProps.selectedTemplate,
                        internal_HoldingTemplate: ContractConfig.defaultProps.selectedTemplate
                    }, () => {
                        if(this.props.deleteCallback) {
                            this.props.deleteCallback();
                        }
                    });
                }
            );
    };

    handleConfigOpen = () => {
        this.setState(
            { internal_IsVisibleConfigScreen : true },
            function() {
                if (this.props.openCallback) {
                    this.props.openCallback();
                }
            }
        );
    };

    handleConfigClose = () => {
        const self = this;
        self.setState(
            { internal_IsVisibleConfigScreen : false, internal_SelectedTemplate: this.props.selectedTemplate },
            function() {
                if (this.props.closeCallback) {
                    this.props.closeCallback();
                }
            }
        );
    };

    handleConfigSave = (res) => {
        const self = this;

        // call save json
        // console.log('call save json here', res);
        const { internal_SelectedTemplate } = this.state;
        const { level, assignmentId, assignmentUserID, closeCallback } = this.props;

        this.setState({
            isSaving: true
        })
        // const result = await handleContractSave(res, internal_SelectedTemplate, level, assignmentId, assignmentUserID)
        handleContractSave(res, internal_SelectedTemplate, level, assignmentId, assignmentUserID)
            .then(result => {
                if (result) {
                    const newSelectedData = {
                        data: res,
                        name: internal_SelectedTemplate.name,
                        id: internal_SelectedTemplate.id,
                        resource: internal_SelectedTemplate.resource
                    };
                    self.setState({
                        internal_IsVisibleConfigScreen: false,
                        internal_SelectedTemplate: newSelectedData,
                        hadTriggeredSave: true,
                        isSaving: false
                    }, function () {
                        if (closeCallback) {
                            closeCallback(true, newSelectedData);
                        }
                    });
                } else {
                    this.setState({ isSaving: false })
                }

            })
            .catch(err => {
                this.setState({ isSaving: false, showApiError: true, apiError: err })
            });

    };

    closeError = () => {
        this.setState({
            showApiError: false,
            apiError: null
        })
    }

    handleCustomizeContract = () => {
        if (this.props.customizeContractCallback) {
            this.props.customizeContractCallback();
        } else {
            this.handleConfigOpen();
        }
    }

    componentDidMount() {
        if (this.props.showCurrentContract) {
            // show the current contract directly if it has selected customize template in customize contract popup
            this.handleConfigOpen();
        }
    }

    handleOfflineContractCheckbox = (value) => {
        Api.updateAssignment(
            this.props.assignmentId,
            {assignmentContractType: value ? AssignmentContractType.OFFLINE_CONTRACT : AssignmentContractType.CONTRACT_TEMPLATE}
        ).then((res) => {
            this.setState({ isOfflineContractChecked: value });
            if (this.props.delegateAssignmentChangeFunction)
                this.props.delegateAssignmentChangeFunction(res);
        });

        PendoHelper.pendoTrack('Campaign_Assignment_Contract_OfflineOnly', {});
    };

    render() {
        // console.log('render', this.props.contractTemplates.length, this.state.internal_SelectedTemplate.id

        const {
            internal_IsVisibleAddScreen, internal_SelectedTemplate, internal_HoldingTemplate,
            internal_IsVisibleConfigScreen, selectedTemplate, hadTriggeredSave, isSaving,
            apiError, showApiError
        } = this.state;
        const { contractTemplates, level, userId, assignmentId, styleType, showCurrentContract, isViewer } = this.props;
        const showContractLabel = internal_SelectedTemplate && internal_SelectedTemplate.id !== '';
        const showProceedBtn = internal_HoldingTemplate && internal_HoldingTemplate.id !== '';

        const isAssignmentLevel = level === 'assignment';
        const isAgentUserLevel = level === 'agent-user';

        // console.log('internal_SelectedTemplate', selectedTemplate, internal_SelectedTemplate, internal_HoldingTemplate);

        const isPreviousTemplatePassIn = !selectedTemplate.id;
        const shouldShowTrash = isPreviousTemplatePassIn && !hadTriggeredSave;


        return (
            <div className={style.contract}>
                <PleaseWaitWhite show={isSaving} />
                <ApiError show={showApiError} error={apiError} cancelFunction={this.closeError} />
                {!showContractLabel && this.props.permission && !this.state.isOfflineContractChecked &&
                    <div className={style.addContract} onClick={this.handleAddOpen}>+ {actionLabel}</div>
                }

                {!showContractLabel && (this.props.permission || isViewer ) &&
                    <div className="checkboxLine">
                        <div>
                            <Checkbox
                                checked={this.state.isOfflineContractChecked}
                                size={20}
                                changeFunction={(newState) => {this.handleOfflineContractCheckbox(newState)}}
                                isDisabled={this.props.hasInvitedCreators || isViewer}
                            />
                        </div>
                        <div>
                            Use Offline Contract Only
                        </div>
                    </div>
                }

                {showContractLabel && isAssignmentLevel &&
                    <div className={cx(style.selContract)}>
                        <span>{internal_SelectedTemplate.name}</span>
                        {this.props.permission && <span onClick={this.handleConfigOpen}><img src="/images/ic-edit.svg"/></span>}

                        {/* if there was no previously saved contract data passed in, show trash can */}
                        {this.props.permission &&
                        <span onClick={this.resetSelectedTemplate}><img src="/images/ic-trash.svg"/></span>
                        }

                    </div>
                }

                {showContractLabel && isAgentUserLevel && !showCurrentContract &&
                <div className={cx(style.selContract, style.selContractAgentUser, styleType ? style[styleType] : '')}>
                    <span onClick={this.handleCustomizeContract}>Customize Contract</span>
                </div>
                }

                <ModalDialogCampaign
                    show={ internal_IsVisibleAddScreen }
                    title={actionLabel}
                    proceedButtonLabel="ADD"
                    readyToProceed={showProceedBtn}
                    justOk={false}
                    cancelFunction={this.handleAddClose}
                    proceedFunction={this.handleAddContract}
                    proceedButtonStyle={{backgroundColor:'#176cff',padding: '10px 40px'}}
                    cancelButtonStyle={{padding: '10px 35px'}}
                >
                    <div className={style.popContainer}>
                        <div className={style.popTitle}>Select the contract</div>
                        {contractTemplates.map((item, idx)=> {
                            return <div key={idx} className={style.rowItem}>
                                <div className={style.rItemName}>{item.name}</div>
                                <div className={style.rItemOptions}>
                                    <div className={cx(style.rBtn,style.rItemSel, {rSelected: item.id === internal_HoldingTemplate.id })}
                                        onClick={(() => this.setContractSelection(item))}><span>Select</span></div>
                                </div>
                            </div>;
                        })}
                    </div>
                </ModalDialogCampaign>

                <Modal
                    closeTimeoutMS={300}
                    overlayClassName={style.overlay}
                    className={style.overlayContent}
                    isOpen={internal_IsVisibleConfigScreen}
                    contentLabel="Loading Contract">

                    <ContractSettings
                        resource={internal_SelectedTemplate.resource}
                        assignmentId={assignmentId}
                        name={internal_SelectedTemplate.name + (level === 'assignment' ? ' (Assignment Template)' : ' (Creator Specific)' )}
                        data={internal_SelectedTemplate.data}
                        cancelCallback={this.handleConfigClose}
                        saveCallback={this.handleConfigSave}
                    />
                </Modal>
            </div>
        )
    }
}
