import React from 'react'
import _ from 'lodash';
import Lookup from '../../../modules/Lookup'
import Format from '../../../modules/utils/Format'
import DisplayAudience from '../../oneInfluencer/Audience2'

const options = {
    'age' :
        [
            {   id : 1, value : '<18'
            },
            {   id : 2, value : '18 to 24'
            },
            {   id : 3, value : '25 to 34'
            },
            {   id : 4, value : '35 to 44'
            },
            {   id : 5, value : '45 to 54'
            },
            {   id : 6, value : '55 - 64'
            },
            {   id : 7, value : '>65'
            }
        ],
    gender : [
        {   id : 1, value : 'Male'
        },
        {   id : 2, value : 'Female'
        }
    ],
    children : [
        {   id : 1, value : 'Yes'
        },
        {   id : 2, value : 'No'
        }
    ],
    education:
        [
            {   id : 1, value : 'College'
            },
            {   id : 2, value : 'No College'
            },
            {   id : 3, value : 'Grad school'
            }
        ],
    ethnicity:
    [
        {   id : 1, value : 'African'
        },
        {   id : 2, value : 'Asian'
        },
        {   id : 3, value : 'Caucasian'
        },
        {   id : 4, value : 'Native'
        }
    ]
}



const audience = {
    gender : [
        { optionId : 1, percent: 67 }
    ],
    income : [
        { optionId : 2 , percent : 50},
        { optionId : 3,  percent: 30}
    ],
    age : [
        { optionId : 1,  percent: 20},
        { optionId : 2,  percent: 66},
        { optionId : 3, percent: 21}
    ],
    children : [
        { optionId : 1, percent: 30}
    ],
    education : [
        { optionId : 2, percent: 20},
        { optionId : 3, percent: 30}
    ],
    ethnicity : [
        { optionId : 1, percent: 10},
        { optionId : 2, percent: 20},
        { optionId : 3, percent: 30}
    ]
}

// Props :
// - profile
// - editMode
// - saveFlag
// - changeFunction  (takes updated Profile object)


export default class Audience extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.processProps = this.processProps.bind(this);
        this.renderedEdit = this.renderedEdit.bind(this);
        this.renderedPiece = this.renderedPiece.bind(this);
        this.renderedPercentInput = this.renderedPercentInput.bind(this);
        this.renderedPercent = this.renderedPercent.bind(this);
        this.renderdTotal = this.renderedTotal.bind(this);
        this.renderedColumn = this.renderedColumn.bind(this);
        this.renderedSelection = this.renderedSelection.bind(this);
        this.checkSum = this.checkSum.bind(this);

        this.state = {
            filled : false  // indicates whether the state objects have been built  by processProps

            // fields for percent values will be added, like the option with id 1 for
            // 'age' will have the key age1, value is a percentage entered by the user,
            // or null if the user didn't choose to add  value to that option
            // created by processProps

            // also, fields for error will be added like ageError etc to drive error indication
            // (produced by checkSum)

            // also, fields for total will be added like ageTotal etc as we are displaying it
            // (produced by checkSum)
        }

        this.audienceTitles = {
            gender: 'Gender',
            // income: 'Income',
            age: 'Age',
            children: 'Children',
            education: 'Education',
            ethnicity: 'Ethnicity'
        }
    }

    checkSum(piece) {
        const allOptions =options[piece];
        let total = 0;
        for (const option of allOptions) {
            const percent = this.state[piece + option.id];
            if (percent !== null) {
                total += percent;
            }
        }
        const newState = {};
        newState[`${piece  }Error`] = (total > 100);
        newState[`${piece  }Total`] = total;
        this.setState(newState);
    }

    renderedPercentInput( piece, option, percent) {
        const self = this;

        const handleBoxFocus = function() {
            const newState = {};
            newState[`${piece + option.id  }original`] = Number(percent);
            self.setState(newState);
        }

        const handlePercentChangeSlider = function(e) {
            const newValue = e.target.value;
            const newState = {};
            newState[piece + option.id] = Number(newValue);
            self.setState(newState, function() {self.checkSum(piece)});
        }

        const handlePercentChangeBox = function(e) {
            let newValue = Number(e.target.value);
            if (isNaN(newValue)) {
                newValue = self.state[`${piece + option.id  }original`];
            }
            const newState = {};
            newState[piece + option.id] = newValue;
            self.setState(newState, function() {self.checkSum(piece)});

        }

        const handleDelete = function() {
            const newState = {};
            newState[piece + option.id] = null;
            self.setState(newState, function(){
                self.checkSum(piece);
            })
        }

        return (
            <div key={option.id} className="percentInput">
                <div onClick={handleDelete} className="delete">
                    <img src="/images/deleteIcon.png" style={{width: 18, height: 18}}/>
                </div>
                <div>
                    { option.value }
                </div>
                <div>
                    <input
                        type="range"
                        min="0"
                        max="100"
                        value={this.state[piece + option.id]}
                        onChange={ handlePercentChangeSlider}
                        style={{width: 180, color: 'orange', backgroundColor: 'orange'}}
                    />
                </div>
                <div>
                    <input
                        value={this.state[piece + option.id]}
                        onChange={handlePercentChangeBox}
                        onFocus={handleBoxFocus}
                    />
                </div>
            </div>
        )
    }

    renderedPercent( piece, option, percent) {
        return (
            <div key={option.id} className="percentInput" style={{ color: '#00336d' }}>
                <div />
                <div>
                    { option.value }
                </div>
                <div />
                <div style={{padding: 4}}>
                    { percent }
                </div>
            </div>
        )
    }

    renderedTotal( piece) {
        return (
            <div key="total" className="percentInput" style={{ color: '#00336d' }}>
                <div />
                <div>
                    Total entered
                </div>
                <div />
                <div style={{padding: 4}}>
                    { this.state[`${piece  }Total`] }
                </div>
            </div>
        )
    }

    renderedColumn(pieceList) {
        const pieces = [];
        for (const key of pieceList) {
            pieces.push(this.renderedPiece(key));
        }
        return (
            <div>
                { pieces }
            </div>
        )
    }

    renderedSelection( piece, options) {
        const self = this;
        const changeHandler = function(e) {
            const id = e.target.value;
            if (id === 'none') {
                return;
            }
            const newState = {};
            newState[piece + id] = 0;
            self.setState(newState, function(){
                self.checkSum(piece);
            });
        }

        const optionList = [
            <option key="none" value="none"> ---- </option>
        ];
        for (const o of options) {
            optionList.push(
                <option
                    key={o.id}
                    value={o.id}>
                    {o.value}
                </option>
            );
        }

        return (
            <select className="select-style" onChange={changeHandler}>
                {optionList}
            </select>)
    }

    renderedEdit() {
        const col1 = ['gender', 'income', 'age'];
        const col2 = ['children', 'education', 'ethnicity'];
        return (
            <div className="editAudience">
                <div className="pieceTitle">
                    Audience Insights
                </div>
                <div className="columns">
                    { this.renderedColumn(col1) }
                    { this.renderedColumn(col2) }
                </div>
            </div>
        )
    }

    renderedPiece(piece) {
        const allOptions = options[piece];
        const displayedFilled = [];
        const neglectedOptions = [];
        let displayedTotal = 0;
        for (const option of allOptions) {
            const percent = this.state[piece + option.id];
            if ( percent === null) {
                neglectedOptions.push(option)
            } else {
                displayedTotal += Number(percent);
                displayedFilled.push(this.renderedPercentInput(piece, option, percent))
            }
        }
        if (neglectedOptions.length === 1) {
            displayedFilled.push(this.renderedPercent(piece, neglectedOptions[0], 100 - displayedTotal));
        } else {
            displayedFilled.push(this.renderedTotal(piece));
        }

        let errorStyle = {};
        if (this.state[`${piece  }Error`]) {
            errorStyle = {borderColor : 'red', borderWidth: 2}
        }

        return (
            <div key={piece} className="piece" style={errorStyle}>
                <div className="title">
                    { this.audienceTitles[piece] }
                </div>

                {displayedFilled}

                { neglectedOptions.length > 1 &&
                    <div>
                        Add:&nbsp;&nbsp;
                        {this.renderedSelection(piece, neglectedOptions)}
                    </div>
                }
            </div>)
    }

    processProps(props) {
        if (props.profile && !this.state.filled ) {
            const newState = {filled : true};

            for (const piece in this.audienceTitles) {
                if (!this.audienceTitles.hasOwnProperty(piece)) {
                    continue;
                }
                const allOptions = options[piece];
                // console.log('---------------------', piece, allOptions);
                for (const option of allOptions) {
                    newState[piece + option.id] = null;
                }
                const values = audience[piece];
                for (const choice of values) {
                    if (!choice.calculated) {
                        newState[piece + choice.optionId] = choice.percent;
                    }
                }
            }
            const self = this;
            this.setState(newState, function(){
                for (const ix in self.audienceTitles) {
                    if (!self.audienceTitles.hasOwnProperty(ix)) {
                        continue;
                    }
                    self.checkSum(ix);
                }
            });
        }
    }

    componentDidMount() {
        if (this.props.profile){
            this.processProps(this.props);
        }
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.profile, this.props.profile) && this.props.profile){
            this.processProps(this.props);
        }
        if (!prevProps.editMode && this.props.editMode && this.props.saveFlag) {
            if (this.props.saveFlag) {
                const newAudience = {};
                for (const ix in this.audienceTitles) {
                    let singleUnfilledFlag = null;
                    let singleUnfilledOption = null;
                    if (!this.audienceTitles.hasOwnProperty(ix)) {
                        continue;
                    }
                    const newSelections = [];
                    const allOptions = options[ix];
                    for (const option of allOptions) {
                        const percent = this.state[ix+ option.id];
                        if (percent !== null) {
                            newSelections.push({optionId : option.id, percent})
                        } else if (singleUnfilledOption === null) {
                            singleUnfilledOption = option;
                            singleUnfilledFlag = true;
                        } else {
                            singleUnfilledFlag = false;
                        }
                    }
                    if (singleUnfilledFlag) {
                        newSelections.push({
                            optionId : singleUnfilledOption.id,
                            percent : 100 - this.state[`${ix  }Total`],
                            calculated : true
                        })
                    }
                    newAudience[ix] = newSelections;
                    this.props.profile.audience = newAudience;
                    const f = this.props.changeFunction;
                    if (typeof f === 'function') {
                        f(this.props.profile);
                    }
                }

            } else {
                const self = this;
                this.setState({filled : false}, function(){
                    self.processProps(self.props);
                });
            }
        } else {
        }
    }

    render() {
        if (!this.state.filled) {
            return null;
        }
        return (
            <div className="profileAudience">
                { // this.props.editMode &&
                    //  this.renderedEdit()
                }
                { !this.props.editMode &&
                    <DisplayAudience
                        audience = {audience}
                        narrow
                        options  = {options}
                    />
                }
            </div>
        )
    }
}
