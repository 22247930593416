import React from 'react';
import ResourceComments from './resourceComments';

const UserDraftPostResourceComments = ({userDraftPostId, userDraftPostResourceId}) => {


    return (
        <div className="user-draft-post-resource-comments-container">
            <ResourceComments
                postId={userDraftPostId}
                resourceId = {userDraftPostResourceId}
            />
        </div>
    )
}

export default UserDraftPostResourceComments;
