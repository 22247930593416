import React from 'react';

import SocialNetworks from '../../modules/influencer/SocialNetworks';
import Format from '../../modules/utils/Format';

export default class ColorBlockGraph extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.renderedColorBlock = this.renderedColorBlock.bind(this);
        this.calculateWidths = this.calculateWidths.bind(this);

        this.state = {
            width: 380,
        };
    }

    static getDerivedStateFromProps(props, state) {
        const {boxWidth} = props;
        let width = 380;
        if (boxWidth < 800) {
            width = Math.max(boxWidth - 430, 340);
        }
        return {
            width,
        };
    }

    calculateWidths(blocks, totalReach) {
        const minWidth = 40;
        let totalWidthReduced = this.state.width;
        let totalReachReduced = totalReach;
        for (let i = blocks.length - 1; i >= 0; i--) {
            const b = blocks[i];
            let width = (totalWidthReduced * b.reach) / totalReachReduced;
            if (width <= minWidth) {
                width = minWidth;
                totalWidthReduced -= minWidth;
                totalReachReduced -= b.reach;
            }
            b.width = width;
        }
    }

    renderedColorBlock(block) {
        let bgColor = '#eef1f3';
        const realColor = block.color;
        if (realColor) {
            bgColor = realColor;
        }
        const style = {
            width: block.width,
            minWidth: block.width,
            maxWidth: block.width,
        };

        const fillerStyle = {
            textAlign: 'center',
            backgroundColor: bgColor,
        };

        const imageSize = 14;
        const imgStyle = {
            width: imageSize,
            height: imageSize,
        };

        return (
            <div style={style} key={block.id} title={block.label}>
                <div className="filler" style={fillerStyle}>
                    <img style={imgStyle} src={block.image} />
                    <div className="ks">{Format.expressInK(block.reach)}</div>
                </div>
            </div>
        );
    }

    render() {
        const colorBlocks = [];

        const networkOptions = SocialNetworks.getNetworkOptionList();

        if (Array.isArray(networkOptions) && Array.isArray(this.props.userNetworks) && this.container) {
            const networks = this.props.userNetworks;

            networks.sort(function (n1, n2) {
                return n2.reach - n1.reach;
            });
            let totalReach = 0;
            const blocks = [];
            for (const n of networks) {
                const reach = Number(n.reach);
                if (isNaN(reach) || reach === 0) {
                    continue;
                }
                const o = networkOptions.find((no) => {
                    return no.id === n.networkType?.toUpperCase();
                });
                if (o) {
                    o.reach = reach;
                    totalReach += reach;
                    blocks.push(o);
                }  
            }
            this.calculateWidths(blocks, totalReach);

            for (const block of blocks) {
                colorBlocks.push(this.renderedColorBlock(block));
            }
        }

        return (
            <div
                className="colorBlocks"
                ref={(e) => {
                    this.container = e;
                }}
                style={{ width: this.state.width }}
            >
                {colorBlocks}
            </div>
        );
    }
}
