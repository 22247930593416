import React from 'react'
import './pdfViewer.scss';

// props
// - pdfUrl
// - onClose

export default class PdfViewer extends React.Component {
    constructor(props) {
        super(props);

        this.onClose = this.onClose.bind(this);
    }

    onClose() {
        this.props.onClose();
    }

    render() {
        return <React.Fragment>
            {this.props.pdfUrl && <div className="pdf-viewer pdf-viewer-container">
                <div className="pdf-viewer-preview">
                    <div onClick={() => this.onClose()} className="pdf-viewer-preview-close-container">
                        <img src="/images/ic-close-w.svg" />
                    </div>
                    <div className="pdf-viewer-preview-container">
                        <embed src={this.props.pdfUrl} width="100%" height="100%" />
                    </div>
                </div>
            </div>
            }
        </React.Fragment>
    }
}
