import React, { useState } from 'react';
import './BrandPartnership.scss';
import Tooltip from '../shared/tooltip/Tooltip'
import ReachIcon from '../shared/ReachIcon/ReachIcon';

function BrandPartnership({ instagramHandle, interests, brandAffinity }) {
    const [isExpanded, setIsExpanded] = useState(false);

    if (!interests && !brandAffinity)
        return null;
    

    const renderBrandAffinity = () => {
        if (!brandAffinity || brandAffinity.length === 0) {
            return null;
        }

        if (!isExpanded) {
            brandAffinity = brandAffinity.slice(0, 5);
        }

        return (
            <div className={"brandPartnershipInnerSection"}>
                <div className="brandPartnershipInnerLabel">
                    Brand Affinity
                </div>
                <div className="brandPartnershipInnerContent">
                    {brandAffinity.map(ba => 
                        <div className="brandPartnershipInnerValue">
                            {ba.name}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    const renderInterests = () => {
        if (!interests || interests.length === 0) {
            return null;
        }

        if (!isExpanded) {
            interests = interests.slice(0, 5);
        }

        return (
            <div className={"brandPartnershipInnerSection"}>
                <div className="brandPartnershipInnerLabel">
                    Interests
                </div>
                <div className="brandPartnershipInnerContent">
                    {interests.map(i => 
                        <div className="brandPartnershipInnerValue">
                            {i.name}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    const renderTitle = () => {
        if (!instagramHandle) {
            return (
                <div className="brandPartnershipLabel">
                    Brand Partnership Insights
                </div>
            );
        } else {
            return (
                <div className="brandPartnershipTitle">
                    <ReachIcon network={"instagram"} value={1} size={20} padding={6} />
                    <div className="brandPartnershipTitleContainer">
                        <div className="brandPartnershipTitleText">
                            Instagram Brand Partnership Insights 
                            <Tooltip tooltipText={tooltipText} image="/images/tooltipUser.svg"/>
                        </div>
                        <div className="brandPartnershipTitleHandle">
                            {"@" + instagramHandle}
                        </div>
                    </div>
                </div>
            );
        }
    }

    const tooltipText = "This section offers insights into the creator's brand partnerships, reach, and interests, and it is exclusively available for Instagram.";
    const isExpandable = (interests && interests.length > 5) || (brandAffinity && brandAffinity.length > 5);

    return (
        <div className={"brandPartnershipContainer"}>
            {renderTitle()}
            <div className="brandPartnershipSectionContainer">
                <div className="brandPartnershipSection">
                    {renderBrandAffinity()}
                    {renderInterests()}
                </div>
                {isExpandable && 
                    <div className="brandPartnershipSeeMore" onClick={() => setIsExpanded(!isExpanded)}>
                        <div className="brandPartnershipSeeMoreText">
                            {isExpanded ? 'Less' : 'See More'}
                            <img src="/images/icon-arrow-left-grey-bg.svg" alt=">" className={isExpanded ? "less" : "more"} />
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default BrandPartnership;