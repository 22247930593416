import React from 'react';
import { render } from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { AppContainer } from 'react-hot-loader';
import Amplify from "aws-amplify";
import * as Honeybadger from 'honeybadger-js';
import ErrorBoundary from '@honeybadger-io/react';
import {Provider} from 'react-redux';
import App from './App';
import './components/common.scss';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './components/reactDates.scss';
import getConfigs from './libs/publicConfigs';
import configureStore, { history } from './store/configureStore';
import { hotjar } from 'react-hotjar';
// console.log('printconfig', config);



const honeybadger = Honeybadger.configure({
    apiKey: 'acf8cb4a',
    revision: __webpack_hash__,
    breadcrumbsEnabled: true,
    disabled: window.location.hostname==='localhost'
});

// import config from '../../server/config/public.json
// remove tap delay, essential for MaterialUI to work properly
// injectTapEventPlugin();

const fromServerConfig = getConfigs();
const config = fromServerConfig || process.env.uiconfig;

export const store = configureStore();

Amplify.configure({
    Auth: {
        region: config.REGION,
        userPoolId: config.USER_POOL_ID,
        userPoolWebClientId: config.APP_CLIENT_ID,
        domain : config.DOMAIN,
        clientId:config.APP_CLIENT_ID,
        identityPoolId: config.IDENTITY_POOL_ID
    }
});

if (!!config.HOTJAR_SITE_ID) {
    hotjar.initialize(config.HOTJAR_SITE_ID, 6);
}

render(
    <ErrorBoundary honeybadger={honeybadger}>
        <Provider store={store}>
            <AppContainer>
                <ConnectedRouter history={history}>
                    <App/>
                </ConnectedRouter>
            </AppContainer>
        </Provider>
    </ErrorBoundary>,
    document.getElementById('root')
);

// if (module.hot) {
//     console.log("Hello");
//     module.hot.accept('./App', () => {
//         const newConfigureStore = require('./store/configureStore');
//         const newStore = newConfigureStore.configureStore();
//         // const newHistory = newConfigureStore.history;
//         const NewApp = require('./App').default;
//         render(
//             <AppContainer>
//                 <Router>
//                     <NewApp/>
//                 </Router>
//             </AppContainer>,
//             document.getElementById('react-app')
//         );
//     });
// }
