import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import cx from 'classnames';
import './notesCard.scss';
import { PlusCircle } from 'phosphor-react';
import Format from '../../../../modules/utils/Format'
import InfluencerPhoto from '../../../oneInfluencerNew/InfluencerPhoto';

const calculateTotal = (data) => {
    return Object.values(data).reduce((acc, val) => {
        const parsed = Number.parseInt(val);
        return acc + (Number.isNaN(parsed) ? 0 : parsed);
    }, 0);
}

const maxLength = 2000;

export const NotesCard = ({
    profile = {},
    keyId = '',
    hideDelete = false,
    onDelete = () => null,
    onChange = () => null,
    currency
}) => {
    const [creator, setCreator] = useState({});
    const [total, setTotal] = useState(0);
    const [adding, setAdding] = useState(false);

    const message = `${creator.notes ? creator.notes.length : 0}/${maxLength} characters` ;

    useEffect(() => {
        if (profile) {
            setCreator(profile);
            setTotal(calculateTotal(profile.rates));
            setAdding(profile.notes && profile.notes.length > 0);
        }
    }, [profile]);

    const handleChange = (e) => {
        const notes = e.target.value;
        const update = { ...creator};
        update.notes = notes;
        onChange(update);
    }

    return (
        <div key={keyId} className='notesCardContainer'>
            {!hideDelete && <div className='deleteContainer'>
                <img
                    src="/images/ic-remove.svg"
                    onClick={() => onDelete()}
                    alt={`deleteContainer_${keyId}`}
                />
            </div>}
            <div className='cardItemContainer'>
                <div className='topSection'>
                    <div className='infoContainer'>
                        <InfluencerPhoto url={profile.pictureUrl} firstName={profile?.firstname} lastName={profile?.lastname}/>
                        {profile.name}
                    </div>
                    <div className='totalProposed'>
                        Total proposed
                        <span>{`${currency.symbol}${Format.numberWithCommas(total)}`}</span>
                    </div>
                    {profile.id && !adding && <div className='addNotesButton' onClick={() => setAdding(true)}>
                        <img src="/images/icon_plus_grey.svg" alt="plus button" />
                        <span>Add notes</span>
                    </div>}
                </div>
                {profile.id && <div className='bottomSection'>
                    {adding &&
                        <>
                            <div className='notesAddingContainer'>
                                <div className='label'>Notes to creator</div>
                                <textarea
                                    value={creator.notes}
                                    className="inputArea"
                                    maxLength={maxLength}
                                    onChange={e => handleChange(e)}
                                    rows={4}
                                    autoFocus
                                />
                            </div>
                            <div className="message">{message}</div>
                        </>}
                </div>}
            </div>
        </div>
    )
}