import { all, fork } from 'redux-saga/effects';
import campaignSaga from './campaign/campaignSaga';
import selectsSaga from './selects/saga';

export default function* rootSaga() {
    yield all([
        fork(campaignSaga),
        fork(selectsSaga),
    ]);
}
