import React from 'react'

import EditSection from './EditSection'
import {aboutKeyList, moreKeyList} from '../../../../modules/influencer/ProfileFields'

// props
// - show
// - profile
// - changeFunction
// - editMode
// - setSaveEnabled takes flag

let minCount = 120;
let maxCount = 400;

let addMessage = (state) => {
    let summary = state.summary;
    let characterCount = summary.length;
    let okFlag = true;
    let message = '';
    if (characterCount < minCount && characterCount>0) {
        message = characterCount + ' (Minimum ' + minCount+' characters)'
        okFlag = false;
    }
    else if (characterCount > maxCount) {
        message = characterCount + ' (>' + maxCount+ ')'
        okFlag = false;
    }
    else{
        message = characterCount;
    }
    state.message = message;
    state.okFlag = okFlag;
}

export default class AboutTabContent extends React.Component {
    constructor( props,context) {
        super(props.context);

        this.state = {
            editedProfile : null,
            profileLoaded : false,
            lastSavedProfile : null,
        }
    }

    handleSummaryChange = (e) => {
        let value = e.target.value;
        let newProfile = this.state.editedProfile;
        newProfile.summary = value;
        let message = `${value.length}/${maxCount} characters`;
        this.setState({editedProfile : newProfile, message});
    }

    handleFieldChange = (key, newValue) => {
        let newProfile = this.state.editedProfile;
        newProfile[key] = newValue;
        this.setState({editedProfile : newProfile})
    }

    static getDerivedStateFromProps(props, state) {
        let newState = {};

        // load profile date into state : first time + on cancel
        if (!state.profileLoaded && props.profile){
            let editedProfile = {};
            for (let ix of aboutKeyList) {
                editedProfile[ix] = props.profile[ix]
            }
            for (let ix of moreKeyList) {
                editedProfile[ix] = props.profile[ix]
            }
            editedProfile.summary = props.profile.summary || '';
            editedProfile.id = props.profile.id;

            newState.editedProfile = editedProfile;
            newState.profileLoaded = true;
            newState.message = `${editedProfile.summary.length}/${maxCount} characters`;
        }

        //finish editing
        if (state.storedEdit && !props.editMode) {
            if (props.saveFlag) {
                props.changeFunction(state.editedProfile);
                let lastSaved = {};
                for (let ix in state.editedProfile) {
                    lastSaved[ix] = state.editedProfile[ix];
                }
                newState.lastSavedProfile = lastSaved;
            } else {
                let lastSaved = state.lastSavedProfile;
                if (lastSaved) {
                    let edited = {};
                    for (let ix in lastSaved) {
                        edited[ix] = lastSaved[ix];
                    }
                    newState.editedProfile = edited;
                } else {
                    newState.profileLoaded = false;
                }
            }
        }
        newState.storedEdit = props.editMode;
        return newState;
    }

    render() {
        if (!this.props.show || !this.state.profileLoaded) {
            return null;
        }
        return (
            <div>
                <div className="pieceTitle">Summary</div>
                <textarea
                    value={this.state.editedProfile.summary ? this.state.editedProfile.summary : ''}
                    onChange={this.handleSummaryChange}
                    rows={4} maxLength={400}
                    placeholder="Write about yourself"
                />
                <div className="messageContainer">
                    {this.state.message}
                </div>
                <div className="divider"/>
                <EditSection
                    title="About"
                    profile={this.state.editedProfile}
                    keys={aboutKeyList}
                    fieldChangeFunction={this.handleFieldChange}
                />
                <div className="divider">
                </div>
                <EditSection
                    title="More Details"
                    profile={this.state.editedProfile}
                    keys={moreKeyList}
                    fieldChangeFunction={this.handleFieldChange}
                    setSaveEnabled={this.props.setSaveEnabled}
                />
            </div>
        )
    }
}