import { LOOKUP_FILTERS_LOADING, LOOKUP_FILTERS_LOADED } from '../actions/types';

export default function filterReducer(state = {}, action) {
    switch (action.type) {
        case LOOKUP_FILTERS_LOADING: {
            return Object.assign({}, state, {
                lookupFiltersLoading: action.flag,
            });
        }
        case LOOKUP_FILTERS_LOADED: {
            const { lookupFilters, publicFilters, connectedFilters } = action;
            return Object.assign({}, state, {
                lookupFiltersLoading: false,
                lookupFilters,
                connectedFilters: connectedFilters || [],
                publicFilters: publicFilters || [],
            });
        }
        default:
            return state;
    }
}
