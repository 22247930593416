import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Checkbox from '../../../../shared/checkbox2022/Checkbox';
import { formatBigNumber } from '../../../../../modules/utils/helpers';
import Api from '../../../../../modules/Api';
import './filters.scss';

function PostFilter({ posts, setPosts, updateData }) {
    const [showInstagram, setShowInstagram] = useState(false);
    const [showTiktok, setShowTiktok] = useState(false);
    const [showYoutube, setShowYoutube] = useState(false);

    const allPostOptions = [
        { id: 'all-instagram', value: 'All Instagram Posts', label: 'All Instagram Posts' },
        ...posts['instagram'].posts.map((post) => ({
            ...post,
            value: post.id,
            label: post.value,
            channel: 'instagram',
            selected: post.selected,
        })),
        { id: 'all-tiktok', value: 'All TikTok Posts', label: 'All TikTok Posts' },
        ...posts['tiktok'].posts.map((post) => ({
            ...post,
            value: post.id,
            label: post.value,
            channel: 'tiktok',
            selected: post.selected,
        })),
        { id: 'all-youtube', value: 'All YouTube Posts', label: 'All YouTube Posts' },
        ...posts['youtube'].posts.map((post) => ({
            ...post,
            value: post.id,
            label: post.value,
            channel: 'youtube',
            selected: post.selected,
        })),
    ];

    const totalPosts = posts['instagram'].posts.length + posts['tiktok'].posts.length + posts['youtube'].posts.length;
    const numSelectedPosts = allPostOptions.filter((post) => post.channel && post.selected).length;
    const allSelected = numSelectedPosts === totalPosts;

    const selectValue = allSelected
        ? { label: `All (${totalPosts})` }
        : numSelectedPosts
          ? { label: `Selected (${numSelectedPosts})` }
          : { label: 'None' };

    const handleSelectAll = () => {
        const allPosts = {
            instagram: {
                posts: posts['instagram'].posts.map((post) => ({ ...post, selected: !allSelected })),
            },
            tiktok: {
                posts: posts['tiktok'].posts.map((post) => ({ ...post, selected: !allSelected })),
            },
            youtube: {
                posts: posts['youtube'].posts.map((post) => ({ ...post, selected: !allSelected })),
            },
        };
        const exclPosts = [...allPosts.instagram.posts, ...allPosts.tiktok.posts, ...allPosts.youtube.posts]
            .filter((post) => !post.selected)
            .map((post) => post.id);

        setPosts(allPosts);
        updateData(undefined, undefined, exclPosts);
    };
    const getCheckboxValue = (optionId) => allPostOptions.find((post) => post.id === optionId).selected;

    const handlePostClick = (channel, option) => {
        const allPosts = {
            ...posts,
            [channel]: {
                posts: posts[channel].posts.map((post) => ({
                    ...post,
                    selected: option.id === post.id ? !post.selected : post.selected,
                })),
            },
        };
        const excludedPosts = [...allPosts.instagram.posts, ...allPosts.tiktok.posts, ...allPosts.youtube.posts]
            .filter((post) => !post.selected)
            .map((post) => post.id);

        setPosts(allPosts);
        updateData(undefined, undefined, excludedPosts);
    };

    const handleSelectChannel = (channel, isSelected) => {
        const allPosts = {
            ...posts,
            [channel]: {
                posts: posts[channel].posts.map((post) => ({ ...post, selected: isSelected })),
            },
        };
        const excludedPosts = [...allPosts.instagram.posts, ...allPosts.tiktok.posts, ...allPosts.youtube.posts]
            .filter((post) => !post.selected)
            .map((post) => post.id);

        setPosts(allPosts);
        updateData(undefined, undefined, excludedPosts);
    };

    const menuRenderer = (props) => {
        const instagramPosts = props.options.filter((option) => option.channel === 'instagram');
        const tiktokPosts = props.options.filter((option) => option.channel === 'tiktok');
        const youtubePosts = props.options.filter((option) => option.channel === 'youtube');

        const instagramAllSelected = !instagramPosts.find((post) => !post.selected);
        const tiktokAllSelected = !tiktokPosts.find((post) => !post.selected);
        const youtubeAllSelected = !youtubePosts.find((post) => !post.selected);

        return (
            <div>
                <div className="filter-option" onClick={handleSelectAll}>
                    <Checkbox size={20} checked={allSelected} changeFunction={handleSelectAll} controlled fixedSize />
                    All ({totalPosts})
                </div>
                <div className="line-break-post line-break-all"></div>
                <ChannelOption
                    title="Instagram"
                    channel="instagram"
                    channelPosts={instagramPosts}
                    isChecked={instagramAllSelected}
                    isExpanded={showInstagram}
                    toogleExpand={setShowInstagram}
                    handlePostClick={handlePostClick}
                    getCheckboxValue={getCheckboxValue}
                    handleSelectChannel={handleSelectChannel}
                />
                <div className="line-break-post"></div>
                <ChannelOption
                    title="TikTok"
                    channel="tiktok"
                    channelPosts={tiktokPosts}
                    isChecked={tiktokAllSelected}
                    isExpanded={showTiktok}
                    toogleExpand={setShowTiktok}
                    handlePostClick={handlePostClick}
                    getCheckboxValue={getCheckboxValue}
                    handleSelectChannel={handleSelectChannel}
                />
                <div className="line-break-post"></div>
                <ChannelOption
                    title="YouTube"
                    channel="youtube"
                    channelPosts={youtubePosts}
                    isChecked={youtubeAllSelected}
                    isExpanded={showYoutube}
                    toogleExpand={setShowYoutube}
                    handlePostClick={handlePostClick}
                    getCheckboxValue={getCheckboxValue}
                    handleSelectChannel={handleSelectChannel}
                />
            </div>
        );
    };

    return (
        <Select
            className="sentiment-analytics-filter post-filter"
            searchable={false}
            value={selectValue}
            options={allPostOptions}
            menuRenderer={menuRenderer}
            valueRenderer={valueRenderer}
            clearable={false}
            menuIsOpen
        />
    );
}

const valueRenderer = (props) => (
    <div>
        <span className="filter-placeholder">Posts:</span>
        {props.label}
    </div>
);

function ChannelOption({
    title,
    channel,
    channelPosts,
    isChecked,
    isExpanded,
    toogleExpand,
    handlePostClick,
    getCheckboxValue,
    handleSelectChannel,
}) {
    return (
        <>
            <div
                className="filter-option"
                key={`all-${channel}-posts`}
                onClick={() => channelPosts.length && handleSelectChannel(channel, !isChecked)}
                style={{ color: isExpanded ? '#00998C' : '#333333' }}
            >
                <Checkbox
                    size={20}
                    checked={isChecked}
                    changeFunction={() => channelPosts.length && handleSelectChannel(channel, !isChecked)}
                    controlled
                    fixedSize
                    isDisabled={!channelPosts.length}
                />
                {title} posts ({channelPosts.filter((post) => post.selected).length})
                {channelPosts.length ? (
                    isExpanded ? (
                        <img
                            className="expand-icon"
                            src="/images/Minus.svg"
                            onClick={(e) => {
                                e.stopPropagation();
                                toogleExpand(false);
                            }}
                        />
                    ) : (
                        <img
                            className="expand-icon"
                            src="/images/icon_plus_grey.svg"
                            onClick={(e) => {
                                e.stopPropagation();
                                toogleExpand(true);
                            }}
                        />
                    )
                ) : null}
            </div>
            {isExpanded &&
                channelPosts.map((option, idx) => (
                    <PostOption
                        key={option.value}
                        postidx={idx}
                        numPosts={channelPosts.length}
                        option={option}
                        channel={channel}
                        handlePostClick={handlePostClick}
                        getCheckboxValue={getCheckboxValue}
                    />
                ))}
        </>
    );
}

function PostOption({ postidx, numPosts, option, channel, handlePostClick, getCheckboxValue }) {
    const isInstagram = channel === 'instagram';
    const [instagramPicLink, setInstagramPicLink] = useState(null);
    const [thumbnailExists, setThumbnailExists] = useState(true);

    useEffect(() => {
        if (isInstagram)
            Api.getInstagramPhoto(option.image).then((image) => {
                setInstagramPicLink(image.url);
                setThumbnailExists(true);
            });
    }, []);

    return (
        <div className="filter-option-post" onClick={() => handlePostClick(channel, option)}>
            <div className="dropdown-post">
                <div
                    className="dropdown-post-wrapper"
                    style={{ borderBottom: numPosts - 1 === postidx ? 'none' : '1px solid #E3E4E5' }}
                >
                    <Checkbox
                        size={20}
                        checked={getCheckboxValue(option.id)}
                        changeFunction={() => handlePostClick(channel, option)}
                        controlled
                        fixedSize
                    />
                    {option.avatar ? (
                        <img className="post-profile-icon" src={option.avatar} />
                    ) : (
                        <img className="post-profile-icon" src="/images/ic-avatar-blank-d.svg" />
                    )}
                    <div className="profile-handle">{option.userHandle}</div>
                    {thumbnailExists ? (
                        <img
                            className="post-image"
                            src={instagramPicLink ? instagramPicLink : option.image}
                            onError={(e) => e && setThumbnailExists(false)}
                        />
                    ) : (
                        <div className="post-image empty-image">
                            <div className="empty-image-text">Image failed to load</div>
                        </div>
                    )}
                    <div className="post-content">
                        <div className="post-date">{option.date}</div>
                        <div className="post-text">{option.content}</div>
                    </div>
                    <div className="post-stats">
                        <div className="post-stats-likes">
                            <img src="/images/ic-heart-g.svg" />
                            {formatBigNumber(option.likes)}
                        </div>
                        <div className="post-stats-comments">
                            <img src="/images/ic-comment-g.svg" />
                            {formatBigNumber(option.comments)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PostFilter;
