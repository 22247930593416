import React from 'react';
import { Async as AsyncSelect } from 'react-select';
import debouncePromise from 'debounce-promise';
import Api from '../../../../modules/Api';
import './competitiveAnalysisFilters.scss';

export const CountryFilter = ({ filters, handleSetCountry }) => {
    const getOptions = async (keyword) => {
        let result = [];
        const countryFilterId = 20;

        try {
            const response = await Api.searchFilterOptions({ filterId: countryFilterId, keyword });
            result = response
                .filter((country) => !filters.find(filter => filter.filterType === 'Country' && filter.value === country.customValue))
                .map((country) => ({ value: country.customValue, label: country.label }));
        } catch (error) {
            console.error(error);
        }

        return { options: result };
    };

    const onSelectValueChange = (option) => {
        const isSelectedAlready = filters.find((filter) => filter.filterType === 'Country' && filter.value === option.value);
        if (isSelectedAlready) return;
        handleSetCountry(option);
    };

    return (
        <div className="country-filter">
            <AsyncSelect
                defaultOptions
                loadOptions={debouncePromise((keyword) => getOptions(keyword), 500)}
                placeholder="Country"
                onChange={onSelectValueChange}
                clearable={false}
            />
        </div>
    );
};