import React from "react";
import Modal from "react-modal";
import classNames from "classnames/bind";
import styles from "./Modal.module.scss";
import Select from "react-select";

const cx = classNames.bind(styles);

const states = ['AL','AK','AZ','AR',
    'CA','CO','CT','DE',
    'FL','GA','HI','ID',
    'IL','IN','IA','KS',
    'KY','LA','ME','MD',
    'MA','MI','MN','MS',
    'MO','MT','NE','NV',
    'NH','NJ','NM','NY',
    'NC','ND','OH','OK',
    'OR','PA','RI','SC',
    'SD','TN','TX','UT',
    'VT','VA','WA','WV',
    'WI','WY'];

class ChangeAddressModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.stateOptions = [];
        for (let state of states) {
            this.stateOptions.push({label: state, value: state})
        }

        this.state = {
            address: {
                shippingStreet: { value: '', isRequired: true, },
                shippingApartment: { value: '', isRequired: false, },
                shippingCity: { value: '', isRequired: true, },
                shippingState: { value: '', isRequired: true, },
                shippingZip: { value: '', isRequired: true, },
            },
            hasError: false,
        };
    }

    handleStateSelection = event => {
        const { value } = event;
        const { address } = this.state;
        this.setState({
            address: {
                ...address,
                shippingState: {
                    value,
                    isRequired: address["shippingState"].isRequired,
                },
            },
        });
    }
    handleFieldChange = (event) => {
        const { name, value } = event.target;
        const { address } = this.state;

        this.setState({
            address: {
                ...address,
                [name]: {
                    value,
                    isRequired: address[name].isRequired,
                },
            },
        });
    }

    close = () => {
        const { onClose } = this.props;

        onClose();
    }

    accept = () => {
        const { onAccept } = this.props;
        const { address } = this.state;
        let hasEmptyField = false;

        const data = Object.entries(address).reduce((acc, [key, { value }]) => {
            if (key !== 'shippingApartment' && !value) {
                hasEmptyField = true;
            }
            acc[key] = value;

            return acc;
        }, {});

        if (!hasEmptyField) {
            onAccept(data);
        } else {
            this.setState({
                hasError: true,
            });
        }
    }

    render() {
        return (
            <Modal
                isOpen={this.props.show}
                overlayClassName={styles.overlay}
                className={styles.modal}
            >
                <div className={styles.container}>

                    <div className={styles.modalHeader}>
                        <div className={styles.modalTitle}>
                            Edit Shipping Address
                        </div>
                        <button onClick={this.close} className={styles.closeContainer}>
                            <img className={styles.close} src="/images/ic-close-b-d.svg"/>
                        </button>
                    </div>
                    <div className={styles.modalBody}>
                        <div className={styles.fields}>
                            <div className={styles.fieldLabel}>New Shipping Address</div>
                            <div className={styles.fieldGroup}>
                                <input
                                    type="text"
                                    placeholder="Street address"
                                    name="shippingStreet"
                                    onChange={this.handleFieldChange}
                                    className={this.state.hasError && !this.state.address.shippingStreet.value ? styles.error : ''}
                                />
                            </div>
                            <div className={styles.fieldGroup}>
                                <input
                                    type="text"
                                    placeholder="Apt, Suite, Blog (optional)"
                                    name="shippingApartment"
                                    onChange={this.handleFieldChange}
                                />
                            </div>
                            <div className={styles.fieldRow}>
                                <div className={styles.fieldGroup}>
                                    <input
                                        type="text"
                                        placeholder="City"
                                        name="shippingCity"
                                        onChange={this.handleFieldChange}
                                        className={this.state.hasError && !this.state.address.shippingCity.value ? styles.error : ''}
                                    />
                                </div>
                                <div className={styles.fieldGroup}>
                                    <Select
                                        value={this.state.address.shippingState.value}
                                        className={this.state.hasError && !this.state.address.shippingState.value ? 'stateSelect error' : 'stateSelect'}
                                        clearable={false}
                                        placeholder="State"
                                        options={this.stateOptions}
                                        onChange={this.handleStateSelection}
                                        searchable={true}
                                    />
                                    {/*<input*/}
                                    {/*    type="text"*/}
                                    {/*    placeholder="State"*/}
                                    {/*    name="shippingState"*/}
                                    {/*    onChange={this.handleFieldChange}*/}
                                    {/*    className={this.state.hasError && !this.state.address.shippingState.value ? styles.error : ''}*/}
                                    {/*/>*/}
                                </div>
                                <div className={styles.fieldGroup}>
                                    <input
                                        type="text"
                                        placeholder="ZIP Code"
                                        name="shippingZip"
                                        onChange={this.handleFieldChange}
                                        className={this.state.hasError && !this.state.address.shippingZip.value ? styles.error : ''}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* <div className={styles.checkbox}>
                            <div className={styles.checkboxHolder}>
                                <input
                                    type="checkbox"
                                    className={styles.checkboxCustom}
                                    id="update"
                                />
                                <label htmlFor="update">Update address in the profile</label>
                            </div>
                        </div> */}
                    </div>
                    <div className={styles.modalFooter}>
                        <div
                            className={styles.buttonCancel}
                            onClick={this.close}
                        >
                            Cancel
                        </div>
                        <div
                            className={styles.buttonApply}
                            onClick={this.accept}
                        >
                            Apply
                        </div>
                    </div>

                </div>
            </Modal>
        )
    }
}

export default ChangeAddressModal;