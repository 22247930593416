const objectSerialize = (params) => {
    const concatString = (name, val) => {
        return !!val && val.toString().length ? `${name}=${val}&` : ''
    }
    let str = ''
    for (const [key, value] of Object.entries(params)) {
        if (value) {
            if (Array.isArray(value)) {
                value.forEach(val => {
                    str += concatString(key, val)
                })
            } else {
                str += concatString(key, value)
            }
        }
    }
    return encodeURI(str.substring(0, str.length - 1))
}

export default objectSerialize