import React, { useState, useEffect, useLayoutEffect, forwardRef, useImperativeHandle } from 'react';
import cx from 'classnames';
import './inviteDeliverables.scss';
import { Warning } from 'phosphor-react';
import Lookup from '../../../modules/Lookup'
import { RadioCards } from './components/RadioCards';
import { DeliverableItem } from './components/DeliverableItem';
import { CustomItem } from './components/CustomItem';
import PopUpDialogueBase from '../../shared/popUpDialogueBase/PopUpDialogueBase';

const channelOptions = [
    { value: 'assignment', title: 'For Assignment', message: 'Set deliverables and channels the same for everyone' },
    { value: 'custom', title: 'Custom', message: 'Set deliverables and channels per creator' },
]

const channelOptionsMixed = [
    { value: 'assignment', title: 'For Assignment', message: 'Set deliverables and channels the same for everyone', disabled: true },
    { value: 'custom', title: 'Custom', message: 'Set deliverables and channels per creator' },
]

const createDeliverables = () => {
    const data = {};
    Lookup.getRateTypeList2().forEach(key => {
        data[key] = '';
    });
    return data;
}

const isValueSet = (o) => {
    if (!o) 
        return false;
    return Object.values(o).some(v => v !== '')
}

const InviteDeliverables = ({
    profiles = [],
    editing,
    allDeliverables = {},
    onDelete = () => null,
    onChange = () => null,
}, ref) => {
    const [channelOptionSelected, setChannelOptionSelected] = useState();
    const [totalDeliverables, setTotalDeliverables] = useState(0);
    const [creatorList, setCreatorList] = useState([]);
    const [assignmentDeliverablesData, setAssignmentDeliverablesData] = useState({});
    const [valid, setValid] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [message, setMessage] = useState('');
    let allConnected = true
    profiles.forEach(item => {
        if (!item.id || item.isPublic) {
            allConnected = false;
        }
    })
    let allPublic = true
    profiles.forEach(item => {
        if (item.id && !item.isPublic) {
            allPublic = false;
        }
    })
    useEffect(() => {
        setChannelOptionSelected(editing ? 1 : isValueSet(allDeliverables) ? 0 : (profiles.length > 0 && profiles[0].deliverables && isValueSet(profiles[0].deliverables) ? 1 : 0));
    }, []);

    useEffect(() => {
        const creators = JSON.parse(JSON.stringify(profiles));
        if (channelOptionSelected === 1) {
            setCreatorList(creators);
        }
        if (!allConnected && !allPublic) {
            setChannelOptionSelected(1);
        }
    }, [profiles]);

    useLayoutEffect(() => {
        let valid = totalDeliverables > 0;
        if (channelOptionSelected === 1 && valid) {
            for (let i = 0; i < creatorList.length; i++) {
                const total = Object.values(creatorList[i].deliverables).reduce((a, val) => {
                    const parsed = Number.parseInt(val);
                    return a + (Number.isNaN(parsed) ? 0 : parsed);
                }, 0);

                if (total === 0 && creatorList[i].id) {
                    valid = false;
                    break;
                }
            }
        }
        setValid(valid);
    }, [totalDeliverables, creatorList]);

    useLayoutEffect(() => {
        switch (channelOptionSelected) {
        case 0:
            setAssignmentDeliverablesData(allDeliverables);
            break;
        case 1:
            const creators = JSON.parse(JSON.stringify(profiles));
            setCreatorList(creators);
            break;
        }
    }, [channelOptionSelected]);

    useLayoutEffect(() => {
        const total = Object.values(assignmentDeliverablesData).reduce((acc, val) => {
            const parsed = Number.parseInt(val);
            return acc + (Number.isNaN(parsed) ? 0 : parsed);
        }, 0);
        setTotalDeliverables(total);
    }, [assignmentDeliverablesData]);

    useLayoutEffect(() => {
        const total = creatorList.reduce((acc, creator) => {
            return acc + Object.values(creator.deliverables).reduce((a, val) => {
                const parsed = Number.parseInt(val);
                return a + (Number.isNaN(parsed) ? 0 : parsed);
            }, 0);
        }, 0);
        setTotalDeliverables(total);
    }, [creatorList]);

    const onChannelOptionChange = (index, option) => {
        setChannelOptionSelected(index)
    }

    const onAssignmentDeliverableChange = (val, key) => {
        const updated = { ...assignmentDeliverablesData};
        updated[key] = val;
        setAssignmentDeliverablesData(updated);
    }

    const onCustomDeliverableChange = (val, index) => {
        const updated = Object.assign([], creatorList);
        updated[index].deliverables = val;
        setCreatorList(updated);
    }

    const onCreatorDelete = (index) => {
        const updated = Object.assign([], creatorList);
        updated.splice(index, 1);
        setCreatorList(updated);
    }

    const onPopupCancel = () => {
        setShowPopup(false);
        setMessage('');
    }
    useImperativeHandle(ref, () => ({
        onNext: () => {
            if (!valid && !allPublic) {
                let error = 'Please select at least one deliverable for all connected creators.';
                if (channelOptionSelected === 0) {
                    error = 'Please select at least one deliverable in order to proceed.'
                }
                setShowPopup(true);
                setMessage(error);
                return null;
            }
            if (channelOptionSelected === 0) {
                const updated = JSON.parse(JSON.stringify(profiles));
                
                updated.forEach(item => {
                    if (item.id) {
                        item.deliverables = assignmentDeliverablesData;
                    } else {
                        const keys = Object.keys(assignmentDeliverablesData)
                        const publicDeliverables = {}
                        keys.forEach(ketitem => {
                            if (assignmentDeliverablesData[ketitem] !== '') {
                                publicDeliverables[ketitem] = assignmentDeliverablesData[ketitem]
                            }
                        })
                        item.deliverables = publicDeliverables
                    }
                })
                return { creators: updated, allDeliverables: assignmentDeliverablesData };
            } 
            return { creators: creatorList, allDeliverables: createDeliverables() };
            
        }
    }));
    return (
        <div ref={ref} className='inviteDeliverableContainer'>
            {!editing && <div className='optionsContainer'>
                <div className='optionLabel'>Deliverable & Channel Options</div>
                <div className='options'>
                    <RadioCards
                        data={(allConnected || allPublic) ? channelOptions : channelOptionsMixed}
                        selected={channelOptionSelected}
                        onChange={onChannelOptionChange}
                    />
                </div>
            </div>}
            {!editing && <div className='deliverableLabel'>Enter the amount of deliverables per channel, leave blank if no deliverable required</div>}
            {!editing && <div className='deliverableCountContainer'>
                <div>Total Deliverables</div>
                <div>{totalDeliverables}</div>
            </div>}
            <div className='deliverableEditingContainer'>
                {channelOptionSelected === 0 && Object.keys(assignmentDeliverablesData).map((key, index) => {
                    const value = assignmentDeliverablesData[key];
                    const publicOnly = ['YOUTUBE', 'INSTAGRAM_POST', 'INSTAGRAM_STORY', 'TIKTOK_POST']
                    if (allConnected || (allPublic && publicOnly.includes(key)))
                    return (
                        <DeliverableItem
                            key={key}
                            type={key}
                            keyId={`DeliverableItem ${index}`}
                            value={value}
                            onChange={val => onAssignmentDeliverableChange(val, key)}
                        />
                    )
                })}
                {channelOptionSelected === 1 && creatorList.map((profile, index) => {
                    return (
                        <CustomItem
                            key={profile.uid}
                            keyId={profile.uid}
                            profile={profile}
                            onChange={val => onCustomDeliverableChange(val, index)}
                            onDelete={() => onCreatorDelete(index)}
                            hideDelete={!(creatorList.length > 1)}
                        />
                    )
                })}
            </div>
            <PopUpDialogueBase
                show={showPopup}
                icon={<Warning color="#111111" size={40} weight="fill" />}
                message={message}
                hideProceed
                cancelLabel="Ok"
                cancelFunction={onPopupCancel}
            />
        </div>
    )
};

export default forwardRef(InviteDeliverables);