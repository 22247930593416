import React from 'react'

import ModalDialog from '../modalDialog/ModalDialog';
import CheckBox from '../checkbox/Checbox'
import classNames from 'classnames';
import StagwellPixel from './StagwellPixel';
import PdfViewer from '../pdfViewer/PdfViewer';
import Format from '../../../modules/utils/Format'
import $ from 'jquery';
import getConfigs from '../../../libs/publicConfigs';
const fromServerConfig = getConfigs();
const config = fromServerConfig || process.env.uiconfig;
import Tooltip from '../../shared/tooltip/Tooltip'

// props for helper component RadioBox
// - selected
//  -clickFunction

class RadioBox extends React.Component {

    constructor(props, context) {
        super(props, context);
    }

    render() {
        let radioClass = "radio";

        let radioBox = "radioBox"
        if (this.props.selected) {
            radioClass += " selected";
            radioBox += " selected";
        }
        if (this.props.className) {
            radioBox += " ";
            radioBox += this.props.className;
        }
        let self = this;
        let clickHandler = function () {
            let f = self.props.clickFunction;
            if (typeof f === 'function') {
                f();
            }
        }
        return (
            <div className={radioBox} onClick={clickHandler}>
                <div>
                    <div className={radioClass}>
                        <div />
                    </div>
                </div>
                <div>
                    {this.props.children}
                </div>
            </div>
        )
    }
}

// props
// - selected

const headTag = () => ({ __html: '</head>' });

const clearState = {
    isPixel: false,
    isManual: true,
    privacyOK: false,
    blogName: '',
    blogUrl: '',
    blogReach: '',
    showPixelScreen: false,
    showStored: false,
    isContractOpen: false,
    GAViewId: '',
    GAError: null,
    isGAConnected: false
}

export default class BlogDialog extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.clickManual = this.clickManual.bind(this);
        this.clickPixel = this.clickPixel.bind(this);
        this.handleBlogNameChange = this.handleBlogNameChange.bind(this);
        this.handleBlogUrlChange = this.handleBlogUrlChange.bind(this);
        this.handleBlogReachChange = this.handleBlogReachChange.bind(this);
        this.canConnectBlog = this.canConnectBlog.bind(this);
        this.proceedWithBlog = this.proceedWithBlog.bind(this);
        this.backBtnPressed = this.backBtnPressed.bind(this);
        this.loadGapi = this.loadGapi.bind(this);
        this.handleGARequest = this.handleGARequest.bind(this);
        this.handleGAViewIdChange = this.handleGAViewIdChange.bind(this);

        this.state = clearState;

        this.urlTimeout = null;

        this.pixelCode = `<script>(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*newDate();a=s.createElement(o),m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)})(window,document,'script','https://www.google-analytics.com/analytics.js','ga'); ga('create', 'UA-94046338-1', 'auto'); ga('send', 'pageview');</script>`
    }

    componentDidMount() {
        this.loadGapi();
    }

    privacyClicked = (flag) => {
        this.setState({
            privacyOK: flag
        }, this.canConnectBlog)
    }

    proceedWithBlog() {
        if (this.state.isPixel) {
            // we are displaying the pixel screen
            this.backBtn = true;
            this.proceedButtonLabel = 'Test Connection';
            this.cancelBlog = null;
            this.setState({
                showPixelScreen: true
            })
        } else {
            let f = this.props.proceedWithBlog;
            if (typeof f === 'function') {
                f({
                    name: this.state.blogName,
                    url: this.state.blogUrl,
                    reach: this.state.blogReach.replace(/,/gi, ''),
                    googleAnalyticsViewId: this.state.GAViewId ? this.state.GAViewId : null
                })
                this.backBtn = false;
                this.cancelBlog = this.props.cancelBlog;
                this.proceedButtonLabel = null;
            }
        }
    }

    clickManual() {
        this.setState({
            isPixel: false,
            isManual: true
        }, this.canConnectBlog);
    }

    clickPixel() {
        this.setState({
            isPixel: true,
            isManual: false
        }, this.canConnectBlog);
    }

    canConnectBlog() {
        let pixelFine = this.state.isPixel && this.state.privacyOK;
        let manualFine = this.state.isManual && this.state.blogReach.length > 0;
        this.setState({
            readyToConnect:
                this.state.blogName.length > 0
                && this.state.blogUrl.length > 0
                && this.state.urlOk
                && (pixelFine || manualFine)
        })
    }

    handleBlogNameChange(e) {
        this.setState({
            blogName: e.target.value
        }, this.canConnectBlog);
    }

    checkUrl= () => {
        this.canConnectBlog();
        let delay = 2000;
        this.urlTimeout = setTimeout( () => {
            let urlOk = Format.validateUrl(this.state.blogUrl);
            this.setState({
                urlError :  urlOk ? null : 'Please, enter a valid url',
                urlOk : urlOk
            }, this.canConnectBlog);
        }, delay)
    }

    handleBlogUrlChange(e) {
        clearTimeout(this.urlTimeout);
        let value = e.target.value;
        this.setState({
            blogUrl: value,
            urlError : null,
            urlOk : false
        }, this.checkUrl);
    }

    handleBlogReachChange(e) {
        let reach = e.target.value.replace(/,/gi, '');
        let reg = /^\d+$/;
        if (e.target.value === '') {
            this.setState({
                blogReach: ''
            }, this.canConnectBlog);
        }
        if (reg.test(reach)) {
            const re = /(?=(?!\b)(\d{3})+$)/g;
            this.setState({
                blogReach: reach.replace(re, ',')
            }, this.canConnectBlog);
        }
    }

    backBtnPressed() {
        this.backBtn = false;
        this.proceedButtonLabel = null;
        this.cancelBlog = this.props.cancelBlog;
        this.setState({
            showPixelScreen: false
        })
    }

    myPixelFunction() {
        let copyText = document.getElementById("myPixelCopy");
        copyText.select();
        document.execCommand("copy");
    }

    static getDerivedStateFromProps(props, state) {
        let newState = {};
        if (!state.showStored && state.show) {
            newState = clearState;
        }
        newState.showStored = props.show;
        return newState;
    }

    loadGapi() {
        $.getScript("https://apis.google.com/js/client:platform.js").done(() => {
            return window.gapi.load('auth2', () => window.gapi.auth2.init({client_id: config.GOOGLE_API_CLIENT_ID}));
        });
    }

    handleGARequest() {
        window.gapi.auth2.getAuthInstance().then(gauth => {
            return gauth.signIn({scope: 'https://www.googleapis.com/auth/analytics.readonly'});
        }).then(() => {
            return window.gapi.client.request({
                path: '/v4/reports:batchGet',
                root: 'https://analyticsreporting.googleapis.com',
                method: 'POST',
                body: {
                    reportRequests: [
                        {
                            viewId: this.state.GAViewId,
                            dateRanges: [{
                                startDate: '7daysAgo',
                                endDate: 'today'
                            }],
                            metrics: [{ expression: 'ga:users' }]
                        }
                    ]
                }
            })
        }).then((res) => {
            this.setState({GAError: null, isGAConnected: true});
        }).catch((error) => {
            this.setState({GAError: "Provided ViewID is not correct for selected account."});
            console.log("Google Analytics error: ", error);
        })
    }

    handleGAViewIdChange(e) {
        const value = e.target.value;
        this.setState({GAViewId: value});
    }

    render() {
        return (
            <React.Fragment>
                {this.state.isContractOpen &&
                    <PdfViewer pdfUrl={'/images/docs/PlatformPrivacyAgreement.pdf'} onClose={() => this.setState({ isContractOpen: false })} />
                }
                <ModalDialog
                    show={this.props.show}
                    title="Connecting Your Blog"
                    proceedButtonLabel={this.proceedButtonLabel || "CONTINUE"}
                    proceedFunction={this.proceedWithBlog}
                    cancelFunction={this.props.cancelBlog}
                    closeButton={true}
                    readyToProceed={this.state.readyToConnect && (!this.state.GAViewId || this.state.isGAConnected)}
                    backBtn={this.backBtn}
                    backBtnPressed={this.backBtnPressed}
                    dontProceed={this.state.isPixel}
                >
                    {!this.state.showPixelScreen &&
                        <div>
                            <div className="label">
                                Name of Your Blog
                        </div>
                            <input
                                placeholder="Blog name"
                                value={this.state.blogName}
                                onChange={this.handleBlogNameChange}
                            />

                            <div className="spacer" />

                            <div className="label">
                                Blog URL / Link
                        </div>
                            <input
                                placeholder="www.blog.com"
                                value={this.state.blogUrl}
                                onChange={this.handleBlogUrlChange}
                            />
                            <div style={{marginTop:-4, color:'#e5004d'}}>
                                { this.state.urlError}
                            </div>

                            <div className="spacer" />

                                {/*  as we are not doing pixel now */}
                                <div style={{display:'none'}}>
                                                        <RadioBox
                                    className='higher'
                                    selected={this.state.isPixel}
                                    clickFunction={this.clickPixel}
                                >
                                    <div className="recommanded">RECOMMENDED</div>
                                    Add Influential Pixel to your blog
                                <div style={{ paddingTop: 10, display: this.state.isPixel ? 'block' : 'none' }}>
                                        <CheckBox
                                            size={22}
                                            checked={this.state.privacyOK}
                                            changeFunction={this.privacyClicked}
                                        />
                                        &nbsp; I have read and agree to the&nbsp;
                                        <a style={{ cursor: 'pointer' }} onClick={() => this.setState({ isContractOpen: true })}>Privacy Policy</a>
                                    </div>
                                </RadioBox>
                                </div>
                                <div className="spacer" />
                                <div>
                                    <div className="label">
                                    Blog Reach <span style={{fontWeight: "normal"}}>(manually estimate your reach)</span>
                                        <input
                                            // type="number"
                                            maxLength={12}
                                            value={this.state.blogReach}
                                            onChange={this.handleBlogReachChange} />
                                </div>
                                <div className="spacer" />
                                <div className="label">
                                    Connect Google Analytics
                                    <span style={{marginRight: "5px"}}/>
                                    <Tooltip tooltipText={"Enter your Google Analytics ViewID. You can obtain it at: https://ga-dev-tools.appspot.com/account-explorer/"}/>
                                </div>
                                <div style={{display: "flex", flexDirection: 'row'}}>
                                    <input
                                        value={this.state.GAViewId}
                                        onChange={this.handleGAViewIdChange}
                                        disabled={this.state.isGAConnected}
                                    />
                                    <div
                                        className={`GAbutton ${!this.state.isGAConnected ? "GAbutton-enabled" : ""}`}
                                        onClick={!this.state.isGAConnected ? this.handleGARequest : null}
                                    >
                                        {this.state.isGAConnected ? "CONNECTED" : "CONNECT"}
                                    </div>
                                </div>
                                <div style={{marginTop:-4, color:'#e5004d'}}>
                                    {this.state.GAError}
                                </div>
                            </div>
                        </div>
                    }
                    {this.state.showPixelScreen &&
                        <StagwellPixel myPixelFunction={this.myPixelFunction} pixelCode={this.pixelCode} />
                    }
                </ModalDialog>
            </React.Fragment>
        )
    }
}
