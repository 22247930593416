import React from 'react'
import './media.scss'

let newArticleId = -33;
let MAX_NUMBER = 8;

import EditableMediaArticle from './EditableMediaArticle'

// props
// - profile
// - editMode
// - handleLocalEditChange
// - disableEdit

export default class Media extends React.Component {
    constructor( props,context) {
        super(props.context);
        this.addArticle = this.addArticle.bind(this);
        this.handleLocalEditChange = this.handleLocalEditChange.bind(this);
        this.deleteArticle = this.deleteArticle.bind(this);
        this.mediaChange = this.mediaChange.bind(this);

        this.state = {
            articleEdited : null
        }
    }

    mediaChange() {
        let kits = this.props.profile.mediaKits;
        this.setState({
            mediaKits : kits
        })
    }

    deleteArticle() {
        // just making sure that the rest of the articles can be edited again
        this.setState({
            articleEdited : null
        })
    }

    handleLocalEditChange( editMode, saveFlag, articleId) {
        this.setState({
            articleEdited : editMode ? articleId : null
        })
        let f = this.props.handleLocalEditChange;
        if (typeof f === 'function') {
            f(editMode,'media');
        }
    }

    addArticle() {
        let newArticle = {
            id : newArticleId,
            title : '',
            blurb : '',
            imageUrl : null,
            url : ''
        }
        this.setState({
            articleEdited : newArticle
        })
    }

    componentDidMount() {
        this.setState({
            mediaKits : this.props.profile.mediaKits
        })
    }

    render() {
        if (!this.state.mediaKits) {
            return null;
        }
        let kits = this.state.mediaKits;
        const {isTalentManager} = this.props;
        let self = this;

        let articles = [];
        for (let i=0; i<kits.length; i++) {
            let article = kits[i];
            let firstDisplay = false;
            let lastDisplay = false;
            let edited = (article.id === this.state.articleEdited);
            if ( !edited && (i === 0 || kits[i-1].id === this.state.articleEdited)) {
                firstDisplay = true;
            }
            if ( !edited && (i === kits.length-1 || kits[i+1].id === this.state.articleEdited)) {
                lastDisplay = true;
            }
            let disable = (this.state.articleEdited && this.state.articleEdited !== article.id );

            articles.push(<EditableMediaArticle
                isTalentManager={isTalentManager}
                profile={this.props.profile}
                article = {article}
                key ={article.id}
                editMode={this.props.editMode}
                disableEdit={disable}
                handleLocalEditChange={this.handleLocalEditChange}
                deleteFunction={this.deleteArticle}
                first={firstDisplay}
                last={lastDisplay}
                changeFunction={this.mediaChange}
            />)
        }
        let article = this.state.articleEdited;

        if (article && article.id === newArticleId) {
            articles.push(<EditableMediaArticle
                isTalentManager={isTalentManager}
                profile={this.props.profile}
                article = {article}
                key ={article.id}
                editMode={this.props.editMode}
                handleLocalEditChange={this.handleLocalEditChange}
                isNew = {true}
                disableEdit = {this.props.disableEdit || this.state.articleEdited !== null}
                changeFunction={this.mediaChange}
            />)
        }

        let addButtonStyle = {};
        if (!this.props.editMode) {
            addButtonStyle.display = 'none';
        }
        if (this.state.articleEdited) {
            addButtonStyle.opacity = 0.4;
        }

        return (
            <div className="profileMedia">
                <div className="pieceTitle" id="mediaLink">
                    { this.state.mediaKits.length < MAX_NUMBER &&
                        <div>
                            <div
                                className="addMedia"
                                onClick={this.addArticle}
                                style={addButtonStyle}
                            >
                                Add New
                            </div>
                            <div style={{clear:'both'}}></div>
                        </div>
                    }
                    {
                        this.props.isTalentManager && !this.props.editMode &&
                        <div className="denied-container">
                            <img src="/images/ic_lock.png"
                                 className="pencil"
                                // style={pencilStyle}
                            />
                            <div>Permission Denied</div>
                        </div>
                    }

                </div>

                { articles }

            </div>
        );
    }
}