/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useLayoutEffect, forwardRef, useImperativeHandle } from 'react';
import cx from 'classnames';
import _ from 'lodash';
import './inviteRates.scss';
import { Warning } from 'phosphor-react';
import Lookup from '../../../modules/Lookup'
import { RadioCards } from './components/RadioCards';
import RateItem from './components/RateItem';
import InputBox from './components/InputBox';
import Format from '../../../modules/utils/Format';
import PopUpDialogueBase from '../../shared/popUpDialogueBase/PopUpDialogueBase';

const isNumber = (val) => /^[-]?\d+$/.test(val);

const isValidRate = (rate) => {
    if (rate === undefined)
        return false;

    if (typeof rate === 'string') {
        return rate !== '' && isNumber(rate);
    }else if (typeof rate === 'number') {
        return rate >= 0;
    }

    return false;

}

const rateOptions = [
    { value: 'sameRate', title: 'Same rate', message: 'Set the same rate for all creators' },
    { value: 'creatorRate', title: 'Creator\'s rate', message: 'Set rate using creator\'s standard rates' },
    { value: 'custom', title: 'Custom', message: 'Set individual rates per creator' },
]

const rateOptionsPublic = [
    { value: 'sameRate', title: 'Same rate', message: 'Set the same rate for all creators' },
    { value: 'creatorRate', title: 'Creator\'s rate', message: 'Set rate using creator\'s standard rates', disabled: true },
    { value: 'custom', title: 'Custom', message: 'Set individual rates per creator' },
]

const InviteRates = ({
    profiles = [],
    editing,
    selectedOptions = {},
    onDelete = () => null,
    currency
}, ref) => {
    const [rateOptionSelected, setRateOptionSelected] = useState(editing ? 2 : 0);
    const [previousRateOptionSelected, setPreviousRateOptionSelected] = useState(0);
    const [sameRateSubOption, setSameRateSubOption] = useState(0);
    const [totalCost, setTotalCost] = useState('');
    const [creatorList, setCreatorList] = useState([]);
    const [availableChannels, setAvailableChannels] = useState([]);
    const [showPopup, setShowPopup] = useState();
    let allConnected = true
    profiles.forEach(item => {
        if (!item.id || item.isPublic) {
            allConnected = false;
        }
    })
    let allPublic = true
    profiles.forEach(item => {
        if (item.id && !item.isPublic) {
            allPublic = false;
        }
    })
    const hasValidValue = (value) => {
        try {
            // must greater than 0
            if (value) {
                let temp = value;
                if (typeof value === 'string') {
                    temp = Number(value.trim());
                }
                if (temp > 0) {
                    return true;
                }
            }
        }catch(e) {
            console.log(e);
        }
        return false;
    }

    const updateCreatorsWithDataChanged = (list) => {
        const res = {};
        const channels = [];
        const copyList = Object.assign([], list);
        const ratesList = []
        copyList.forEach(creator => {
            const selectedChannels = [];
            if (creator.deliverables && typeof creator.deliverables === 'object') {
                Object.entries(creator.deliverables).forEach(([key, value]) => {
                    // skip those are already in channels
                    if (hasValidValue(value)) {
                        if (_.isEmpty(channels.filter(channel => !_.isEmpty(channel[key])))) {
                            const obj = {};
                            obj[key] = value;
                            channels.push(obj);
                        }
                        selectedChannels.push(key);
                    }
                });
                const newRates = {};
                if (creator.rates) {
                    Object.entries(creator.rates).forEach(([rk, rv]) => {
                        if (selectedChannels.includes(rk) && isValidRate(rv)) {
                            newRates[rk] = rv;
                            ratesList[rk] = rv;
                        } else if (rk === 'GENERAL') {
                            newRates.GENERAL = rv;
                        }
                    });
                }
                creator.rates = newRates;
            }
        });
        if (sameRateSubOption === 0) {
            copyList.forEach(creator => {
                const selectedChannels = [];
                if (creator.deliverables && typeof creator.deliverables === 'object') {
                    Object.entries(creator.deliverables).forEach(([key, value]) => {
                        // skip those are already in channels
                        if (hasValidValue(value)) {
                            if (_.isEmpty(channels.filter(channel => !_.isEmpty(channel[key])))) {
                                const obj = {};
                                obj[key] = value;
                                channels.push(obj);
                            }
                            selectedChannels.push(key);
                        }
                    });
                    if (creator.rates) {
                        selectedChannels.forEach(key => {
                            if (!isValidRate(creator.rates[key]) && isValidRate(ratesList[key])) {
                                creator.rates[key] = ratesList[key];
                            }
                        })
                    }
                }
            });
        }
        res.channels = channels;
        res.list = copyList;
        return res;
    }

    useEffect(() => {
        const creators = JSON.parse(JSON.stringify(profiles));
        const data = updateCreatorsWithDataChanged(creators);
        setAvailableChannels(data.channels);
        if (!_.isEqual(creators, creatorList)) {
            setCreatorList(data.list);
            
            if (selectedOptions.rateOption === rateOptionSelected &&
                selectedOptions.subOption === sameRateSubOption &&
                rateOptionSelected === 0 && !totalCost) {
                const match = data.list.find(c => c.rates && isValidRate(c.rates.GENERAL));
                if (match) {
                    setTotalCost(match.rates.GENERAL);
                }
            }
        }
    }, [profiles]);

    useEffect(() => {
        setRateOptionSelected(selectedOptions.rateOption);
        setSameRateSubOption(selectedOptions.subOption);
    }, [selectedOptions]);

    useLayoutEffect(() => {
        const data = updateCreatorsWithDataChanged(creatorList);
        setAvailableChannels(data.channels);
        if (!_.isEqual(data.list, creatorList)) {
            setCreatorList(data.list);
        }
    }, [creatorList]);

    const onRateOptionSelected = (index) => {
        setPreviousRateOptionSelected(rateOptionSelected);
        setRateOptionSelected(index);
    }

    const onCustomRateChange = (val, index) => {
        const updated = Object.assign([], creatorList);
        if (updated.length > index && index >= 0) {
            updated[index].rates = val;
            setCreatorList(updated);
        }
    }

    const onCreatorDelete = (index) => {
        const updated = Object.assign([], creatorList);
        updated.splice(index, 1);
        setCreatorList(updated);
    }

    const isInAvailableChannels = (channel) => {
        const match = availableChannels.find(c => c && Object.keys(c).includes(channel));
        return match !== undefined;
    }

    const countTotalCost = () => {
        const returnObj = {};
        returnObj.totalForAllCreators = 0;
        returnObj.totalProposedPerChannel = '';
        // select same rate + total 
        if (rateOptionSelected === 0 && sameRateSubOption === 0) {
            returnObj.totalForAllCreators = creatorList.length * totalCost;
        } else {
            // use same rate, then get total proposed for all channels as well
            const copyAvailableChannels = Object.assign([], availableChannels);
            if (rateOptionSelected === 0) {
                // select per channel
                creatorList.forEach(c => {
                    if (c.rates) {
                        Object.entries(c.rates).forEach(([key, value]) => {
                            if (isInAvailableChannels(key) && typeof value === 'number' && c.deliverables && hasValidValue(c.deliverables[key])) {
                                returnObj.totalForAllCreators += value;
                                const pos = copyAvailableChannels.findIndex(cc => cc[key]);
                                if (pos !== -1) {
                                    returnObj.totalProposedPerChannel = (returnObj.totalProposedPerChannel !== '' ? returnObj.totalProposedPerChannel : 0) + value;
                                    copyAvailableChannels.splice(pos, 1);
                                }
                            }
                        })
                    }
                })
            } else {
                creatorList.forEach(c => {
                    if (c.rates && !_.isEmpty(c.rates)) {
                        if (sameRateSubOption === 0) {
                            returnObj.totalForAllCreators += c.rates.GENERAL || 0;
                        } else {
                            Object.entries(c.rates).forEach(([key, value]) => {
                                if (key !== 'GENERAL' && isInAvailableChannels(key) && typeof value === 'number' && c.deliverables && hasValidValue(c.deliverables[key])) {
                                    returnObj.totalForAllCreators += value;
                                }
                            })
                        }
                    }
                })
            }
        }
        return returnObj;
    }

    const updateIndividualCreatorRate = (creator, channel, rate) => {
        if (typeof rate === 'string') {
            try {
                rate = rate !== '' ? Number(rate.replace(/[^0-9]/g, '')) : '';
            } catch (e) {
                return;
            }
        }
        if (creator) {
            // total
            if (sameRateSubOption === 0) {
                creator.rates = { GENERAL: rate };
            } else if (creator.deliverables && hasValidValue(creator.deliverables[channel])) {
                if (!creator.rates) {
                    creator.rates = {};
                } 
                creator.rates[channel] = rate;
            }
        }
    }

    const updateRate = (channel, rate, creator = null) => {
        const copyList = Object.assign([], creatorList);
        if (creator) {
            const matchItem = copyList.find(c => c.id === creator.id);
            if (matchItem) {
                updateIndividualCreatorRate(matchItem, channel, rate);
            }
        } else {
            copyList.forEach(c => {
                updateIndividualCreatorRate(c, channel, rate);
            })
        }
        setCreatorList(copyList);
    }

    useLayoutEffect(() => {
        const list = Object.assign([], creatorList);
        if (rateOptionSelected === 1) {
            list.forEach(c => {
                const newRates = {};
                availableChannels.forEach(channel => {
                    const keys = Object.keys(channel);
                    if (keys.length > 0) {
                        const key = keys[0];
                        // only add those have been selected channels
                        if (c.deliverables && hasValidValue(c.deliverables[key])) {
                            if (c.personalRates && isValidRate(c.personalRates[key])) {
                                newRates[key]= c.personalRates[key];
                            } else if (c.rates && isValidRate(c.rates[key])) {
                                newRates[key] = c.rates[key];
                            }
                        }
                    }
                })
                if (c.personalRates && c.personalRates.GENERAL) {
                    newRates.GENERAL = c.personalRates.GENERAL;
                } else {
                    newRates.GENERAL = isValidRate(totalCost) ? totalCost : ((isValidRate(c.rates.GENERAL) ? c.rates.GENERAL : ''));
                }
                c.rates = newRates;
            })
            setCreatorList(list);
        } else if (rateOptionSelected === 0 || previousRateOptionSelected === 0) {
            // switch from creator's rate or custom to same rate, or switch from same rate to creator's rate or custom,
            // update all the creators' channel rates with the first match channel rate
            const channelsWithRates = [];
            list.forEach(c => {
                const tempRates = {};
                availableChannels.forEach(channel => {
                    const keys = Object.keys(channel);
                    if (keys.length > 0) {
                        const k = keys[0];
                        if (c.deliverables && hasValidValue(c.deliverables[k])) {
                            if (previousRateOptionSelected === 0 && c.rates && isValidRate(c.rates[k])) {
                                tempRates[k] = c.rates[k];
                            } else if (c.id) {
                                const matchChannel = channelsWithRates.find(cc => cc[k]);
                                if (matchChannel) {
                                    tempRates[k] = matchChannel[k];
                                } else {
                                    const matchCreator = creatorList.find(cc => cc.rates && isValidRate(cc.rates[k]));
                                    if (matchCreator) {
                                        tempRates[k] = matchCreator.rates[k];
                                        const obj = {};
                                        obj[k] = tempRates[k];
                                        channelsWithRates.push(obj);
                                    }
                                }
                            }
                        }
                    }
                })
                if (sameRateSubOption === 0) {
                    if (isValidRate(totalCost)) {
                        tempRates.GENERAL = totalCost;
                    } else if (c.rates && isValidRate(c.rates.GENERAL)) {
                        tempRates.GENERAL = c.rates.GENERAL;
                    } else if (c.id) {
                        const matchGeneral = channelsWithRates.find(ch => isValidRate(ch.GENERAL));
                        if (matchGeneral) {
                            tempRates.GENERAL = matchGeneral.GENERAL;
                        } else {
                            const match2 = creatorList.find(cl => cl.rates && isValidRate(cl.rates.GENERAL));
                            if (match2) {
                                tempRates.GENERAL = match2.rates.GENERAL;
                                const o = {};
                                o.GENERAL = tempRates.GENERAL;
                                channelsWithRates.push(o);
                            }
                        }
                    }
                    if (totalCost === '' && isValidRate(tempRates.GENERAL)) {
                        setTotalCost(tempRates.GENERAL);
                    }
                }
                c.rates = tempRates;
            })
            setCreatorList(list);
        }
    }, [rateOptionSelected])

    const handleTotalWithSameRateChange = (value) => {
        try {
            let rate = value !== '' ? Number(value.replace(/[^0-9]/g, '')) : '';
            // rate = Number(rate);
            updateRate('GENERAL', rate);
            setTotalCost(rate);
        } catch (e) {
            console.log(e);
        }
    }

    const renderTotalWithSameRate = () => {
        return (
            <>
                <InputBox
                    keyString='totalCost'
                    label='Total cost'
                    currency='$'
                    value={isValidRate(totalCost) ? Format.formatNumber(totalCost) : ''}
                    onChange={(value) => handleTotalWithSameRateChange(value)}
                    maxLength={6}
                    type='currency'
                />
                <div className='costBox proposedBox'>
                    <div>Total proposed</div>
                    <div>{isValidRate(totalCost) ? `${currency.symbol}${Format.formatNumber(totalCost)}` : currency.symbol}</div>
                </div>
            </>
        );
    }

    const renderPerChannelWithSameRate = (totalProposed) => {
        return (
            <>
                <div className='channelRatesContainer'>
                    {!_.isEmpty(availableChannels) && availableChannels.map((channel) => {
                        const keys = Object.keys(channel);
                        if (keys.length > 0) {
                            const matchCreator = creatorList.find(c => c.rates && isValidRate(c.rates[keys[0]]))
                            const rateValue = matchCreator ? Format.formatNumber(matchCreator.rates[keys[0]]) : '';
                            return (
                                <InputBox
                                    key={keys[0]}
                                    keyString={keys[0]}
                                    label={Lookup.getDeliverableType(keys[0]).label}
                                    currency={currency.symbol}
                                    value={rateValue}
                                    onChange={(value) => updateRate(keys[0], value)}
                                    maxLength={6}
                                    type='currency'
                                />
                            )
                        }
                        return null;
                    })
                    }
                </div>
                <div className='costBox proposedBox'>
                    <div>Total proposed</div>
                    <div>{isValidRate(totalProposed) ? `${currency.symbol}${Format.formatNumber(totalProposed)}` : currency.symbol}</div>
                </div>
            </>
        );
    }

    const renderSameRateOptions = () => {
        const countResult = countTotalCost();
        return (
            <div className='selectedOptionContainer'>
                <div className={cx('subOptionContainer', { notSameRate: rateOptionSelected !== 0 })}>
                    <div className={cx('subOptionLabel', { hide: editing })}>Set the same rate option</div>
                    <div className='subOptionButtonsContainer'>
                        <div
                            className={cx('', { highlighted: sameRateSubOption === 0 })}
                            onClick={() => setSameRateSubOption(0)}
                            role='button'
                        >
                            Total
                        </div>
                        <div
                            className={cx('', { highlighted: sameRateSubOption === 1 })}
                            onClick={() => setSameRateSubOption(1)}
                            role='button'
                        >
                            Per channel
                        </div>
                    </div>
                </div>
                {rateOptionSelected === 0 ?
                    <div className={cx('subOptionContainer', { sameRateTotal: rateOptionSelected === 0 && sameRateSubOption === 0 })}>
                        {sameRateSubOption === 0 && renderTotalWithSameRate()}
                        {sameRateSubOption === 1 && renderPerChannelWithSameRate(countResult.totalProposedPerChannel)}
                    </div>
                    :
                    <div />
                }
                {!editing && <div className='subOptionContainer highlighted'>
                    <div>Total cost for {creatorList.length} creator{creatorList.length <= 1 ? '' : 's'}:</div>
                    <div>{countResult.totalForAllCreators ? `$${Format.formatNumber(countResult.totalForAllCreators)}` : ''}</div>
                </div>}
            </div>
        );
    }

    useImperativeHandle(ref, () => ({
        onSaveData: (backAction) => {
            let valid = true;
            const result = {};
            result.creators = JSON.parse(JSON.stringify(creatorList));
            // ensure no GENERAL if using Per channel, or only GENERAL if select total
            result.creators.forEach(c => {
                const newRates = {};
                if (c.rates) {
                    Object.entries(c.rates).forEach(([k, val]) => {
                        if (sameRateSubOption === 0 && k === 'GENERAL') {
                            // select total
                            newRates[k] = val;
                        } else if (sameRateSubOption !== 0 && k !== 'GENERAL') {
                            newRates[k] = val;
                        }
                    })
                    c.rates = newRates;
                }
                if (sameRateSubOption === 0 && !isValidRate(newRates.GENERAL) && c.id) {
                    // no general rate if selects total
                    valid = false;
                } else if (sameRateSubOption !== 0) {
                    // selects per channel, then all channels should have rates
                    if (c.deliverables) {
                        Object.entries(c.deliverables).forEach(([k, v]) => {
                            if (!c.rates && c.id) {
                                valid = false;  // should not go here
                            } else if (hasValidValue(v) && !isValidRate(c.rates[k]) && c.id) {
                            // deliverable has value but rates don't have cooresponding rate
                                valid = false;
                            }
                        })
                    }
                }
            })

            if (!valid && !backAction && !allPublic) {
                const error = 'Please provide the required rates for all connected creators.';
                setShowPopup(error);
                return null;
            }
            result.selectedOptions = {rateOption: rateOptionSelected, subOption: sameRateSubOption};
            return result;
        }
    }));

    return (
        <div ref={ref} className='inviteRatesContainer'>
            <div className={cx('optionsContainer', { editing })}>
                {!editing && <div className="rateRow">
                    <div className='optionLabel'>Rate options</div>
                    {currency && <div className="currency">
                        Currency:
                        <div>{` ${currency.code} (${currency.symbol})`}</div>
                    </div>}
                </div>}
                {!editing && <div className='options'>
                    <RadioCards
                        data={(!allConnected) ? rateOptionsPublic : rateOptions}
                        selected={rateOptionSelected}
                        onChange={onRateOptionSelected}
                    />
                </div>}
                {renderSameRateOptions()}
            </div>
            <div className='creatorsContainer'>
                {creatorList.map((profile, index) => {
                    return (
                        <RateItem
                            key={profile.uid}
                            keyString={profile.uid}
                            profile={profile}
                            onChange={val => onCustomRateChange(val, index)}
                            onDelete={() => onCreatorDelete(index)}
                            hideBottom={rateOptionSelected === 0}
                            asTotal={sameRateSubOption === 0}
                            index={index}
                            hideRemove={creatorList.length <= 1}
                            currency={currency}
                        />
                    )
                })}
            </div>
            <PopUpDialogueBase
                show={showPopup}
                icon={<Warning color="#111111" size={40} weight="fill" />}
                message={showPopup}
                hideProceed
                cancelLabel="Ok"
                cancelFunction={() => setShowPopup(null)}
            />
        </div>
    )
};

export default forwardRef(InviteRates);