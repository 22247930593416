import {GET_AGENT_LIST, GET_AGENT_BRANDS, RESET_AGENT_BRANDS} from "./types";
import Api from "../../../modules/Api";

export const getAgentList = () => {
    let d=null;
    Api.getAgentList().then(
        function(res) {
            let action = {
                type : GET_AGENT_LIST,
                list : res
           };
           d(action);
        }
    );
    return (dispatch) => {
        d=dispatch;
    }
};

export const getAgentBrands = () => {
    let d=null;
    Api.getAgentBrands().then(
        function(res) {
            let action = {
                type : GET_AGENT_BRANDS,
                list : res
            };
            d(action);
        });
    return (dispatch) => {
        d=dispatch;
    }
};

export const resetAgentBrands = () => {
     return {
         type : RESET_AGENT_BRANDS,
     }
};
