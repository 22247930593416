import React from "react";
import './agentAdmin.scss'

// props
// - points [] { key, label }
// - currentKey
// - changeFunction

export default class LeftMenu extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            height: 200
        }
    }

    renderedPoint( point) {
        let className = "point";
        if (this.props.currentKey === point.key) {
            className += " selected"
        }

        let clickHandler = () => {
            this.props.changeFunction(point.key)
        }

        return (
            <div className={className} onClick={clickHandler} key={point.key}>
                {point.label}
            </div>)
    }

    componentDidMount() {
        this.setState({
            height: window.innerHeight- 140
        })
        // todo: add window resize
    }

    render() {
        let points = [];
        for (let point of this.props.points) {
            points.push(this.renderedPoint(point));
        }
        return (<div className="leftMenu" style={{minHeight: this.state.height}}>
            <div className="points">
                { points }
            </div>
        </div>)
    }
}