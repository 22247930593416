import React, { useState, useRef } from 'react';
import cx from 'classnames';
import './inviteControl.scss';
import Format from '../../../../modules/utils/Format'

export const InviteControl = ({
    budget,
    hidePre,
    nextLabel = 'Next',
    disableNext,
    onBack = () => null,
    onNext = () => null
}) => {

    return (
        <div className='inviteControlContainer'>
            <div className='budgetContainer'>
                Campaign budget
                <span >${budget > 0 ? Format.numberWithCommas(budget) : ''}</span>
            </div>
            <div className='buttonsContainer'>
                <div className={cx('prevButton', { hide: hidePre })} onClick={onBack}>Previous</div>
                <div className={cx('nextButton', { disabled: disableNext })} onClick={disableNext ? null : onNext}>{nextLabel}</div>
            </div>
        </div>
    )

}