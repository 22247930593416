import React from 'react';
import './MetricsItem.scss';
import className from 'classnames';
import { string } from 'prop-types';
import cx from 'classnames';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import SocialNetworks from '../../modules/influencer/SocialNetworks';
import Format from '../../modules/utils/Format';
import ReachIcon from '../shared/ReachIcon/ReachIcon';
import { safeIsUrl } from '../../modules/CommonServices';
import Tooltip from '../shared/tooltip/Tooltip';

// const dataList = [{key: 'Followers:', value: '1.3K'},{key: 'Following:', value: '1.3K'},{key: 'Posts:', value: '168'},{key: 'Avg. likes:', value: '28K'},{key: 'Avg. comments:', value: '12K'}, ];
export default class MetricsItem extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.onVisitPage = this.onVisitPage.bind(this);
        this.onExpand = this.onExpand.bind(this);
        this.onInsight = this.onInsight.bind(this);
        this.state = {
            expanded: props.expanded,
        };
    }

    static getDerivedStateFromProps(props, state) {
        const { boxWidth } = props;
        let width = 340;
        if (boxWidth < 800) {
            width = boxWidth - 470;
        }
        return {
            width,
        };
    }

    componentDidMount() {
        const { fireImmediately } = this.props;
        if (fireImmediately) {
            this.onInsight();
        }
    }

    onVisitPage(profileLink) {
        const convertedLink = profileLink;
        if (profileLink.includes('vm.tiktok')) {
            convertedLink.replace('vm.tiktok', 'www.tiktok');
        }
        if (safeIsUrl(convertedLink)) {
            window.open(convertedLink, '_blank');
        }
    }

    onInsight() {
        const { onInsight, data } = this.props;
        if (typeof onInsight === 'function') {
            onInsight(data);
        }
    }

    onExpand() {
        this.setState({
            expanded: !this.state.expanded,
        });
    }

    render() {
        const { data, insights, isConnectedInfluencer, fireImmediately, profile, insightsData } = this.props;
        const networkOptions = SocialNetworks.getNetworkOptionList();
        let metrics;
        if (Array.isArray(networkOptions)) {
            metrics = networkOptions.find((no) => {
                return no.id === data?.networkType?.toUpperCase();
            });
        }

        const leftData = [];
        const rightData = [];
        if (data.reach) {
            if (!isConnectedInfluencer || data.networkType === 'instagram') {
                leftData.push({
                    key: 'Followers',
                    value: Format.expressInK(data.reach),
                    tooltip: 'Number of followers',
                });
            }
        }
        if (data.audienceCredibility && data.networkType === 'instagram') {
            leftData.push({
                key: 'Credibility Score',
                value: Format.percentage(Math.round(data.audienceCredibility * 100), 0),
                tooltip: 'Percentage of the audience that can be defined as real people (not bots or fake accounts)',
            });
        }
        if (data.reach && data.audienceCredibility && data.networkType === 'instagram') {
            leftData.push({
                key: 'Estimated Real Followers',
                value: Format.expressInK(data.reach * data.audienceCredibility),
                tooltip: 'Product of credibility score and number of followers',
            });
        }
        if (data.posts && data.networkType === 'tiktok') {
            leftData.push({ key: 'Posts', value: Format.expressInK(data.posts) });
        }
        if (data.posts && data.networkType === 'instagram') {
            leftData.push({ key: 'Posts', value: Format.expressInK(data.posts), tooltip: 'Number of posts' });
        }
        if (data.viewRate) {
            if (data.networkType === 'instagram') {
                leftData.push({
                    key: 'View Rate',
                    value: Format.percentage2(data.viewRate * 100, 2),
                    tooltip: 'Percentage of reels plays by total followers',
                });
            } else {
                leftData.push({
                    key: 'View Rate',
                    value: Format.percentage2(data.viewRate * 100, 2),
                    tooltip: 'Percentage of views by total followers',
                });
            }
        }
        if (data.likes) {
            leftData.push({
                key: 'Average Likes',
                value: Format.expressInK(data.likes),
                tooltip: 'The average sum of likes on the last 30 posts',
            });
        }
        if (data.comments) {
            leftData.push({
                key: 'Average Comments',
                value: Format.expressInK(data.comments),
                tooltip: 'The average sum of comments on the last 30 posts',
            });
        }
        if (data.reach) {
            if (isConnectedInfluencer && data.networkType !== 'instagram') {
                leftData.push({
                    key: 'Average Views',
                    value: Format.expressInK(data.reach),
                    tooltip: 'The average views on the last 30 posts',
                });
            }
        }
        if (data.avgViews) {
            if (data.networkType === 'instagram') {
                leftData.push({
                    key: 'Average Views',
                    value: Format.expressInK(data.avgViews),
                    tooltip: 'The average sum of reels plays on the last 30 posts',
                });
            } else {
                leftData.push({
                    key: 'Average Views',
                    value: Format.expressInK(data.avgViews),
                    tooltip: 'The average views on the last 30 posts',
                });
            }
        }
        if (data.engagements && !isConnectedInfluencer) {
            rightData.push({
                key: 'Engagement',
                value: Format.expressInK(data.engagements),
                tooltip: 'The average sum of likes on the last 30 posts',
            });
        }
        if (data.engagementRate) {
            rightData.push({
                key: 'Engagement Rate:',
                value: Format.percentage2(isConnectedInfluencer ? data.engagementRate : data.engagementRate * 100),
                tooltip:
                    'The average number of likes divided by the number of followers. (It is calculated based on the likes gathered within the last \
                two months, but no more than 500 posts in total. If the influencer does not post often,  posts older than two months may be taken, \
                but no more than 10 in total)',
            });
        }
        if (data.paidPostPerformance && data.engagements) {
            rightData.push({
                key: 'Sponsored Engagement',
                value: Format.expressInK(data.paidPostPerformance * data.engagements),
                tooltip:
                    'Product of sponsored post performance and number of engagements. (Sponsored post performance is a ratio between sponsored and \
                organic posts. Not available for some creators)',
            });
        }
        if (data.paidPostPerformance && data.engagementRate) {
            rightData.push({
                key: 'Sponsored Engagement Rate',
                value: Format.percentage2(
                    isConnectedInfluencer
                        ? data.paidPostPerformance * data.engagementRate
                        : data.paidPostPerformance * data.engagementRate * 100,
                ),
                tooltip:
                    'Product of sponsored post performance and engagement rate. \
                (Sponsored post performance is a ratio between sponsored and organic posts. Not available for some creators)',
            });
        }
        if (data.avgViews && data.engagements) {
            if (data.networkType === 'instagram') {
                rightData.push({
                    key: 'Engagement Rate by Views',
                    value: Format.percentage2((data.engagements / data.avgViews) * 100),
                    tooltip: 'The engagement divided by average reels plays on the last 30 posts',
                });
            } else {
                rightData.push({
                    key: 'Engagement Rate by Views',
                    value: Format.percentage2((data.engagements / data.avgViews) * 100),
                    tooltip: 'Engagement rate by views',
                });
            }
        }
        let metricLabel = null;
        if (data && data.networkType === 'blog' && data.networkUserId) metricLabel = data.networkUserId;
        else if (data && data.accountTitle && (data.networkType === 'youtube' || data.networkType === 'facebook'))
            metricLabel = data.accountTitle;
        else if (data && data.socialHandle)
            if (!data.socialHandle.startsWith('@')) {
                metricLabel = `@${data.socialHandle}`;
            } else {
                metricLabel = data.socialHandle;
            }
        let networkName = data.networkType;
        if (data.networkType === 'youtube') {
            networkName = 'YouTube';
        }
        if (data.networkType === 'tiktok') {
            networkName = 'TikTok';
        }
        const creatorStats = [['Creator Stat Name', 'Value']];
        if (profile) {
            const newLeftSide = [];
            const newRightSide = [];
            const address =
                (profile.city ? `${profile.city}, ` : '') +
                (profile.state ? `${profile.state}, ` : '') +
                (profile.country ? profile.country : '');
            if (data.reach) {
                if (!isConnectedInfluencer || data.networkType === 'instagram') {
                    newLeftSide.push({
                        key: 'Followers',
                        value: data.reach,
                        tooltip: 'Number of followers',
                    });
                }
            }
            if (data.audienceCredibility && data.networkType === 'instagram') {
                newLeftSide.push({
                    key: 'Credibility Score',
                    value: Format.percentage(Math.round(data.audienceCredibility * 100), 0),
                    tooltip:
                        'Percentage of the audience that can be defined as real people (not bots or fake accounts)',
                });
            }
            if (data.reach && data.audienceCredibility && data.networkType === 'instagram') {
                newLeftSide.push({
                    key: 'Estimated Real Followers',
                    value: data.reach * data.audienceCredibility,
                    tooltip: 'Product of credibility score and number of followers',
                });
            }
            if (data.posts && data.networkType === 'tiktok') {
                newLeftSide.push({ key: 'Posts', value: data.posts });
            }
            if (data.posts && data.networkType === 'instagram') {
                newLeftSide.push({ key: 'Posts', value: data.posts, tooltip: 'Number of posts' });
            }
            if (data.viewRate) {
                if (data.networkType === 'instagram') {
                    newLeftSide.push({
                        key: 'View Rate',
                        value: Format.percentage2(data.viewRate * 100, 2),
                        tooltip: 'Percentage of reels plays by total followers',
                    });
                } else {
                    newLeftSide.push({
                        key: 'View Rate',
                        value: Format.percentage2(data.viewRate * 100, 2),
                        tooltip: 'Percentage of views by total followers',
                    });
                }
            }
            if (data.likes) {
                newLeftSide.push({
                    key: 'Average Likes',
                    value: data.likes,
                    tooltip: 'The average sum of likes on the last 30 posts',
                });
            }
            if (data.comments) {
                newLeftSide.push({
                    key: 'Average Comments',
                    value: data.comments,
                    tooltip: 'The average sum of comments on the last 30 posts',
                });
            }
            if (data.reach) {
                if (isConnectedInfluencer && data.networkType !== 'instagram') {
                    newLeftSide.push({
                        key: 'Average Views',
                        value: data.reach,
                        tooltip: 'The average views on the last 30 posts',
                    });
                }
            }
            if (data.avgViews) {
                if (data.networkType === 'instagram') {
                    newLeftSide.push({
                        key: 'Average Views',
                        value: data.avgViews,
                        tooltip: 'The average sum of reels plays on the last 30 posts',
                    });
                } else {
                    newLeftSide.push({
                        key: 'Average Views',
                        value: data.avgViews,
                        tooltip: 'The average views on the last 30 posts',
                    });
                }
            }
            if (data.engagements && !isConnectedInfluencer) {
                newRightSide.push({
                    key: 'Engagement',
                    value: data.engagements,
                    tooltip: 'The average sum of likes on the last 30 posts',
                });
            }
            if (data.engagementRate) {
                newRightSide.push({
                    key: 'Engagement Rate:',
                    value: Format.percentage2(isConnectedInfluencer ? data.engagementRate : data.engagementRate * 100),
                    tooltip:
                        'The average number of likes divided by the number of followers. (It is calculated based on the likes gathered within the last \
                    two months, but no more than 500 posts in total. If the influencer does not post often,  posts older than two months may be taken, \
                    but no more than 10 in total)',
                });
            }
            if (data.paidPostPerformance && data.engagements) {
                newRightSide.push({
                    key: 'Sponsored Engagement',
                    value: data.paidPostPerformance * data.engagements,
                    tooltip:
                        'Product of sponsored post performance and number of engagements. (Sponsored post performance is a ratio between sponsored and \
                    organic posts. Not available for some creators)',
                });
            }
            if (data.paidPostPerformance && data.engagementRate) {
                newRightSide.push({
                    key: 'Sponsored Engagement Rate',
                    value: Format.percentage2(
                        isConnectedInfluencer
                            ? data.paidPostPerformance * data.engagementRate
                            : data.paidPostPerformance * data.engagementRate * 100,
                    ),
                    tooltip:
                        'Product of sponsored post performance and engagement rate. \
                    (Sponsored post performance is a ratio between sponsored and organic posts. Not available for some creators)',
                });
            }
            if (data.avgViews && data.engagements) {
                if (data.networkType === 'instagram') {
                    newRightSide.push({
                        key: 'Engagement Rate by Views',
                        value: Format.percentage2((data.engagements / data.avgViews) * 100),
                        tooltip: 'The engagement divided by average reels plays on the last 30 posts',
                    });
                } else {
                    newRightSide.push({
                        key: 'Engagement Rate by Views',
                        value: Format.percentage2((data.engagements / data.avgViews) * 100),
                        tooltip: 'Engagement rate by views',
                    });
                }
            }
            creatorStats.push(['Creator Name', `${profile.firstname || ''} ${profile.lastname || ''}`]);
            creatorStats.push(['Social Media Channel', `${data.networkType}`]);
            if (data.socialHandle) {
                creatorStats.push([
                    'Social Media Handle',
                    data.socialHandle.startsWith('@') ? data.socialHandle : `@${data.socialHandle}`,
                ]);
            } else {
                creatorStats.push(['Social Media Handle', 'N/A']);
            }
            if (profile.state || profile.country || profile.city) {
                creatorStats.push(['Location', address]);
            }
            if (profile.summary) {
                creatorStats.push(['Bio', `${profile.summary}`]);
            }
            newLeftSide.forEach((item) => {
                creatorStats.push([item.key, item.value]);
            });
            newRightSide.forEach((item) => {
                creatorStats.push([item.key, item.value]);
            });
            if (profile.brandAffinity) {
                profile.brandAffinity.forEach((item) => {
                    creatorStats.push(['Brand Affinity', `${item.name}`]);
                });
            }
            if (profile.interests) {
                profile.interests.forEach((item) => {
                    creatorStats.push(['Interests', `${item.name}`]);
                });
            }
        }
        if (insightsData && insightsData.dateStatistics) {
            insightsData.dateStatistics.forEach((item) => {
                creatorStats.push([
                    `Total Followers - ${moment(item.timestamp).format('MMM YYYY')}`,
                    `${item.followers}`,
                ]);
            });
        }
        return (
            <div className={cx('metricsItemContainer', fireImmediately && 'borderAbove')}>
                <div className="metricsMainSection">
                    {metrics && (
                        <div className="labelContainer">
                            <ReachIcon network={metrics.id} value={1} size={15} padding={6} />
                            {data && data.networkType && <div className="label">{networkName}</div>}
                        </div>
                    )}
                    <div className="actionsContainer">
                        <CSVLink data={creatorStats} filename={`CreatorStats.csv`}>
                            <div className="visitPage">Download Creator Stats</div>
                        </CSVLink>
                        {insights && !fireImmediately && (
                            <div className="insightsButton" onClick={() => this.onInsight()}>
                                Audience Insights
                            </div>
                        )}
                        {data.profileLink && (
                            <div className="visitPage" onClick={() => this.onVisitPage(data.profileLink)}>
                                Visit page
                            </div>
                        )}
                        <div className="expandIcon" onClick={this.onExpand}>
                            {/* <img src="/images/ic-arrow-left.svg" /> */}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                className={className({ right: !this.state.expanded }, { down: this.state.expanded })}
                            >
                                <path
                                    fill={this.state.expanded ? '#333333' : '#999999'}
                                    fillRule="nonzero"
                                    d="M16.46 9.27a.889.889 0 0 1 1.276 0 .938.938 0 0 1 0 1.305l-5.097 5.154a.888.888 0 0 1-1.278 0l-5.096-5.154a.936.936 0 0 1 0-1.305.889.889 0 0 1 1.276 0l4.46 4.227 4.458-4.227z"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
                <div className={className('metricsExpandSection', { expand: this.state.expanded })}>
                    {metricLabel && (
                        <div className="metricsLabelContainer">
                            {metricLabel}
                            <div className="line-separator" />
                        </div>
                    )}
                    {!metricLabel && <div className="metricsFiller" />}
                    <div className="metricsInfoContainer">
                        <div className="leftSides">
                            {leftData.map((item) => {
                                return (
                                    <div key={item.key + item.value}>
                                        <div className="key">
                                            <div>{item.key}</div>
                                            {item.tooltip && (
                                                <Tooltip
                                                    tooltipText={item.tooltip}
                                                    image="/images/tooltipUser.svg"
                                                    lessWidth
                                                />
                                            )}
                                        </div>
                                        <div className="value">{item.value}</div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="rightSides">
                            {rightData.map((item) => {
                                return (
                                    <div key={item.key + item.value}>
                                        <div className="key">
                                            <div>{item.key}</div>
                                            {item.tooltip && (
                                                <Tooltip tooltipText={item.tooltip} image="/images/tooltipUser.svg" />
                                            )}
                                        </div>
                                        <div className="value">{item.value}</div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
