import React from 'react';
import PropTypes from 'prop-types';
import style from './styles.module.scss';
import UploadAvatar from '../UploadAvatar';
import Spinner from "../../../../../shared/contract/spinner";

const BrandLogoSection = (props) => {
    const {
        onChangeFile,
        imagePreview,
        isUploading,
        brand,
        uploadProgressPercentage,
        onUploadCancel
    } = props;

    return (
        <div className={style.brandLogoCover}>
            <UploadAvatar
                handleSelectedFile={onChangeFile}
                imagePreview={imagePreview}
                brand={brand}
            />
            {isUploading && <Spinner progressPercentage={uploadProgressPercentage} onCancel={onUploadCancel}/>}
            <div className={style.uploadLabel}>Brand Logo</div>
        </div>
    );
};

BrandLogoSection.propTypes = {
    handleSelectedFile: PropTypes.func,
    imagePreview: PropTypes.string,
};

export default BrandLogoSection;
