import React, { useState } from 'react';
import Modal from 'react-modal';
import './autoDetectedPopup.scss';

export default function AutoDetectedPopup() {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <div className="auto-detected-link" onClick={() => setIsOpen(true)}>
                <StarsIcon />
                Auto-detected link
                <QuestionMarkIcon />
            </div>
            <Modal overlayClassName="modalDialog" className="auto-detected-info-modal" isOpen={isOpen}>
                <h3 className="modal-title">What is Auto-detect link?</h3>

                <div className="auto-detect-link">
                    <StarsIcon />
                    Auto-detect link
                </div>

                <p className="modal-text">
                    The automated detection for sponsored posts includes validating the social handles, hashtags, and
                    URLs associated with the designated brand and campaigns. If these fields are empty in the
                    assignment, the post cannot be detected. You have the option to add and modify the social handles,
                    hashtags, and URLs for the assignment. Alternatively, if essential details are missing, you can
                    manually upload the post URL or update the auto-detected post URLs directly.
                </p>
                <p className="modal-text">
                    For a public creator, the automatic detection of sponsored posts will be conducted daily upon their
                    addition to the assignment.
                </p>
                <p className="modal-text">
                    For a connected creator, the automated detection of sponsored posts will occur daily once they
                    accept the assignment. This auto-detection process will persist until the post is captured within a
                    three-month period. The auto-detection feature is working for Instagram Posts (Not stories), TikTok,
                    and YouTube only.
                </p>

                <div className="modal-footer">
                    <button className="close-btn" onClick={() => setIsOpen(false)}>
                        Close
                    </button>
                </div>
            </Modal>
        </>
    );
}

export function QuestionMarkIcon() {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            className="question-mark-icon"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.00008 17.3337C4.39758 17.3337 0.666748 13.6028 0.666748 9.00033C0.666748 4.39783 4.39758 0.666992 9.00008 0.666992C13.6026 0.666992 17.3334 4.39783 17.3334 9.00033C17.3334 13.6028 13.6026 17.3337 9.00008 17.3337ZM8.16675 11.5003V13.167H9.83341V11.5003H8.16675ZM9.83342 10.1295C11.2176 9.71231 12.0877 8.34459 11.879 6.91405C11.6703 5.48351 10.4458 4.42136 9.00008 4.41699C7.60973 4.41689 6.41247 5.39792 6.13925 6.76116L7.77425 7.08866C7.90331 6.44297 8.51113 6.00811 9.1639 6.09445C9.81668 6.18079 10.2905 6.75871 10.2473 7.41575C10.2041 8.07278 9.65854 8.58362 9.00008 8.58366C8.53984 8.58366 8.16675 8.95675 8.16675 9.41699V10.667H9.83342V10.1295Z"
                fill="currentColor"
            />
        </svg>
    );
}

export function StarsIcon() {
    return (
        <svg
            width="16"
            height="15"
            viewBox="0 0 16 15"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            className="stars-icon"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.5314 0.684209C12.6334 0.438597 13.0171 0.438597 13.1192 0.684209C13.3311 1.19453 13.6317 1.82165 13.9323 2.12226C14.2347 2.42471 14.8369 2.72718 15.321 2.93934C15.5596 3.0439 15.5596 3.4147 15.321 3.51925C14.8369 3.73138 14.2347 4.03383 13.9323 4.3363C13.6317 4.63689 13.3311 5.26401 13.1192 5.77433C13.0171 6.01995 12.6334 6.01995 12.5314 5.77433C12.3195 5.26401 12.0188 4.63689 11.7183 4.3363C11.4176 4.03569 10.7905 3.73509 10.2802 3.52315C10.0346 3.42115 10.0346 3.03744 10.2802 2.93542C10.7905 2.72347 11.4176 2.42285 11.7183 2.12226C12.0188 1.82165 12.3195 1.19453 12.5314 0.684209ZM7.00832 2.90254C7.10264 2.65386 7.47703 2.65386 7.57134 2.90254C7.9702 3.95436 8.73702 5.78351 9.50385 6.5503C10.2727 7.31912 12.0098 8.08794 12.9994 8.48591C13.2411 8.5831 13.2411 8.94565 12.9994 9.04284C12.0097 9.44081 10.2727 10.2096 9.50385 10.9783C8.73702 11.7452 7.9702 13.5744 7.57134 14.6262C7.47703 14.8748 7.10264 14.8748 7.00832 14.6262C6.60946 13.5744 5.84264 11.7452 5.07582 10.9783C4.309 10.2115 2.47981 9.4448 1.42799 9.04594C1.1793 8.95162 1.1793 8.57723 1.42798 8.48292C2.4798 8.08395 4.309 7.31713 5.07582 6.5503C5.84264 5.78351 6.60946 3.95436 7.00832 2.90254ZM2.43091 2.15531C2.31956 1.91378 1.9287 1.91378 1.81736 2.15531C1.69094 2.42953 1.53853 2.70786 1.38611 2.86027C1.2337 3.01268 0.955365 3.16511 0.681148 3.29153C0.43961 3.40287 0.439621 3.79373 0.681159 3.90507C0.955376 4.03148 1.2337 4.1839 1.38611 4.33631C1.53853 4.48872 1.69094 4.76705 1.81736 5.04126C1.9287 5.2828 2.31956 5.2828 2.43091 5.04126C2.55732 4.76705 2.70974 4.48872 2.86215 4.33631C3.01659 4.18187 3.28828 4.02743 3.55201 3.90005C3.78655 3.78676 3.78655 3.40985 3.55201 3.29655C3.28829 3.16916 3.01659 3.01472 2.86215 2.86027C2.70974 2.70786 2.55732 2.42953 2.43091 2.15531Z"
                fill="currentColor"
            />
        </svg>
    );
}
