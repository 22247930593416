const EMPTY_FIELD_WARNING = 'Field cannot be blank!';

const WRONG_EMAIL_WARNING = 'Wrong email!';

const errorsObject = {
    empty_field: EMPTY_FIELD_WARNING,
    wrong_email: WRONG_EMAIL_WARNING,
}

const emailValidation = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default {
    errorsObject,
    emailValidation,
}
