import React from 'react'
import Select from 'react-select'
import SocialNetworks from '../../../modules/influencer/SocialNetworks'
import ReachIcon from "../ReachIcon/ReachIcon"
import './networkUrlInput.scss'
import { safeIsUrl } from '../../../modules/CommonServices';

// props :
//  - label (string)
//  - inputType (defaults to '')
//  - valueChangeFunction (function taking a string value)
//  - frozenValue  if not empty, freezes the display with the given value (used in filtered dropdown)
//  - initialValue
//  - updatedValue   (the location component will want to change the displayed value)
//  takes 100% of the horizontal space  (unless otherwise styled by the classname influencerInput)

export default class networkUrlInput extends React.Component {

    constructor (props, context) {
        super(props, context);

        this.handleSelectType = this.handleSelectType.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handlePost = this.handlePost.bind(this);
        this.addOneMore = this.addOneMore.bind(this);

        this.state = {
            inputIndex: 0,
            editIndex: -1,
            postUrls: [],
            networkType: '',
            value : ''
        }
        this.networks=[];
        this.currentNetworks=[];
    }

    handleSelectType(value) {
        this.setState({networkType:value}, this.handlePost);
    }

    handlePost(){
        const {itemType, postId} = this.props;
        const {inputIndex,networkType, value} = this.state;
        this.state.postUrls[inputIndex]={itemType, networkType, postId, url: value};
        if (typeof this.props.valueChangeFunction === 'function') {
            this.props.valueChangeFunction(this.state.postUrls);
        }
    }

    handleChange(e) {
        const {value} = e.target;
        this.setState({
            value
        }, this.handlePost);
    }

    handleEdit(e) {
        const {value} = e.target;
        this.state.postUrls[this.state.editIndex].url=value;
        this.handlePost();
    }

    addOneMore() {
        const currentIndex=this.state.inputIndex+1;
        this.buildNetworks();
        this.setState({
            inputIndex: currentIndex,
            editIndex: -1,
            value: ''
        });
    }

    remove(index) {
        const {itemType, postId} = this.props;
        const {postUrls} = this.state;
        let currentIndex=this.state.inputIndex-1;
        postUrls.splice(index,1);
        if (typeof this.props.valueChangeFunction === 'function') {
            this.props.valueChangeFunction(postUrls.length>0?postUrls:[{itemType, networkType:'TWITTER', postId, url: ''}]);
        }
        if (this.state.value==='') {
            postUrls.splice(currentIndex,1); 
        } else {
            currentIndex+=1;
        }
        this.setState({inputIndex: currentIndex, value:'', postUrls},this.buildNetworks());
    }

    buildNetworks(){
        const self=this;
        const currentNetworks = [];
        const networks = [];
        const renderNetwork= function(network) {
            let label = network.label;
            if(network.label === "Pin") {
                label = "Pinterest"
            }
            if (network.label === "Youtube") {
                label = "YouTube"
            }
            if (network.label === "Linkedin") {
                label = "LinkedIn"
            }
            return (
                <div className="iconLabel" key={network.id}><ReachIcon network={network} value={1} bgColor={self.props.bgcolor} size="20" /><div className="label">{label}</div></div>
            )
        }
        SocialNetworks.getNetworkOptionListPost().forEach( network =>{ 
            let index=-1;
            if (self.state.postUrls.length>0) { 
                index = self.state.postUrls.findIndex(posturl=>posturl.networkType===network.id);
            }
            if (index>-1) {   
                currentNetworks.push({index, label: renderNetwork(network)});
            } else {
                networks.push({value: network.id, label: renderNetwork(network) });
            }
        });
         
        if (currentNetworks.length>0) {
            currentNetworks.sort(cn=>cn.index);
        }
         
        this.currentNetworks=currentNetworks;
        this.networks=networks;
        if (networks.length>0) {
            self.setState({networkType:networks[0].value});
        }
    }

    componentDidMount() {
        this.buildNetworks();
    }

    render () {
        const { readOnly } = this.props;
        const self=this;
        
        const renderUrlInput = function(index,networks){
            return (
                <div key={index}>
                    <div className="typeContainer">
                        {self.state.inputIndex===index ?
                            <Select className="select" clearable={false} searchable={false}
                                disabled={readOnly}
                                value={self.state.networkType} placeholder=""
                                onChange={(...args)=>self.handleSelectType(...args)} 
                                simpleValue
                                options={networks}/>
                            :
                            networks
                        }                        
                    </div>
                    <div className="valueContainer">
                        { self.state.inputIndex===index && self.props.placeholder && !self.state.value &&  <div className="placeholder">{self.props.placeholder}</div> }
                        { self.state.inputIndex===index ?
                            <div> 
                                <input
                                    value={self.state.value}
                                    disabled={readOnly}
                                    onChange={self.handleChange}
                                    onFocus={function(){self.setState({editIndex:index})}}
                                    className={ self.state.editIndex===index && !safeIsUrl(self.state.value)?'editInput invalid':'editInput' }
                                /></div>  :
                            <div><input
                                value={self.state.postUrls[index].url}
                                disabled={readOnly}
                                onFocus={function(){self.setState({editIndex:index})}}
                                onChange={self.handleEdit}
                                className={ (!safeIsUrl(self.state.postUrls[index].url) && self.state.postUrls[index].url!=='')?'editInput invalid':'editInput' }
                            />{(safeIsUrl(self.state.value) || self.state.value==='') && <img className="removeIcon" alt="Remove" src="/images/ic-trash.svg" onClick={()=>self.remove(index)} />}</div> }
                        { ((self.state.editIndex===index && self.state.value && !safeIsUrl(self.state.value)) || ( self.state.inputIndex!==index && self.state.postUrls[index] && !safeIsUrl(self.state.postUrls[index].url) ) ) && <div className="invalid placeholder">Invalid URL</div> }
                    </div>
                </div>
            )
        }
       
        return (
            <div className="urlInput">
                {this.currentNetworks.length>0 && this.currentNetworks.map(network=>renderUrlInput(network.index,network.label))}
                {this.state.inputIndex===this.currentNetworks.length && this.networks.length>0 && renderUrlInput(this.state.inputIndex,this.networks) }
             
                {safeIsUrl(this.state.value) && this.networks.length>1 && 
                <div>
                    <div className="typeContainer" />
                    <div className="valueContainer"><div className="addMore" onClick={this.addOneMore}>+ ADD ANOTHER LINK</div></div>
                </div> }
            </div>
        );
    }
}
