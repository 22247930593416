import React from 'react';
import './topNavigation.scss';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import Api from '../../../modules/Api';
import { storeUserProfile } from '../../../store/global/globalActions';
import LoggedInUser from './loggedInUser/LoggedInUser';
import ProfileHelper from '../../../modules/utils/ProfileHelper';
import { INFLUENCER_STR, TALENT_MANAGER_STR } from '../../../constants/authorities';
import ApiError from '../apiError/ApiError';
import PopUpDialogueBase from '../popUpDialogueBase/PopUpDialogueBase';

class TopNavigation extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            profile: undefined,
            apiError: undefined,
            showSuccessfulSentVerificationLinkMessage: false,
            discoveryMenu: false,
        };

        this.renderedNavigation = this.renderedNavigation.bind(this);

        this.navigationPoints = {
            AGENT: [
                { label: 'Home', url: '/home' },
                { label: 'Discovery', url: '/influencers' },
                { label: 'Brands', url: '/adminBrands' },
                { label: 'Plans', url: '/plans' },
                { label: 'Campaigns', url: '/campaign' },
            ],
            VIEWER: [
                { label: 'Home', url: '/home' },
                { label: 'Discovery', url: '/influencers' },
                { label: 'Brands', url: '/adminBrands' },
                { label: 'Plans', url: '/plans' },
                { label: 'Campaigns', url: '/campaign' },
            ],
            BRAND_OWNER: [
                { label: 'Home', url: '/home' },
                { label: 'Discovery', url: '/influencers' },
                { label: 'Brands', url: '/adminBrands' },
                { label: 'Plans', url: '/plans' },
                { label: 'Campaigns', url: '/campaign' },
            ],
            SUPER_ADMIN: [
                { label: 'Home', url: '/home' },
                { label: 'Discovery', url: '/influencers' },
                { label: 'Advertisers', url: '/advertisers' },
                { label: 'Brands', url: '/adminBrands' },
                { label: 'Plans', url: '/plans' },
                { label: 'Campaigns', url: '/campaign' },
            ],
            INFLUENCER: [
                { label: 'Profile', url: '/profile' },
                { label: 'Opportunities', url: '/opportunities' },
            ],
            TALENT_MANAGER: [
                { label: 'Management', url: '/talentManagement' },
                { label: 'Opportunities', url: '/talentOpportunities' },
            ],
        };
    }

    renderedNavigation(navigationPoint) {
        const { discoveryMenu } = this.state;
        let className = 'navPoint';
        const current = navigationPoint.url.indexOf(this.props.current) >= 0;

        if (current) {
            className += ' current';
        }

        const clickHandler = () => {
            if (this.props.clickHandlerForCurrent) {
                this.props.clickHandlerForCurrent();
            }
        };
        if (navigationPoint.label === 'Plans' && !window.plans) {
            return null;
        }
        if (navigationPoint.label !== 'Discovery') {
            return (
                <NavLink to={navigationPoint.url} key={navigationPoint.label}>
                    <div onClick={clickHandler}>
                        <div className={className}>{navigationPoint.label}</div>
                    </div>
                </NavLink>
            );
        }
        return (
            <div
                className="discoverySection"
                onMouseEnter={() => this.setState({ discoveryMenu: true })}
                onMouseLeave={() => this.setState({ discoveryMenu: false })}
                key={navigationPoint.label}
            >
                <div className={className}>
                    {navigationPoint.label} <img src="/images/arrowWhite.svg" alt="down arrow" />
                </div>
                {discoveryMenu && (
                    <div className="discoveryMenu">
                        <NavLink to="/influencers" key="New Search">
                            New Search
                        </NavLink>
                        <NavLink to="/savedsearches" key="Saved Searches">
                            Saved Searches
                        </NavLink>
                        <NavLink to="/favourite" key="Favourite Lists">
                            Favourite Lists
                        </NavLink>
                        <NavLink to="/exclusion" key="Exclusion Lists">
                            Exclusion Lists
                        </NavLink>
                    </div>
                )}
            </div>
        );
    }

    componentDidMount() {
        const { profile } = this.props;
        const self = this;

        if (!profile) {
            ProfileHelper.getCurrentLoginUserProfile().then(function (currentUserProfile) {
                self.props.storeProfile(currentUserProfile);
                self.setState({
                    profile: currentUserProfile,
                });
            });
        } else {
            this.setState({
                profile,
            });
        }
    }

    changeStacking = (menuVisible) => {
        this.setState({ highStacking: menuVisible });
    };

    handleSuccessfullySentVerificationLink = () => {
        this.setState({ showSuccessfulSentVerificationLinkMessage: false });
    };

    handleApiError = () => {
        this.setState({ apiError: undefined });
    };

    resendVerificationLink = () => {
        const { profile } = this.state;
        if (profile) {
            Api.accountVerifyRequest(profile.email)
                .then((res) => {
                    if (res.statusCode === 200) {
                        this.setState({ showSuccessfulSentVerificationLinkMessage: true, apiError: undefined });
                    } else {
                        const e = { message: res.error, originalMessage: res.data };
                        this.setState({ apiError: e });
                    }
                })
                .catch((err) => {
                    this.setState({ apiError: err });
                });
        }
    };

    renderVerificationBanner = () => {
        const { profile } = this.state;

        // the influencer account or talent manager account hasn't been verified yet
        if (
            profile &&
            (profile.role === INFLUENCER_STR || profile.role === TALENT_MANAGER_STR) &&
            !profile.accountVerified
        ) {
            const message = [];
            const mustVerifyMessage = 'You have to verify your account';
            if (profile.createdOn) {
                let remainingDays = '';
                try {
                    const created = new Date(profile.createdOn);
                    const today = new Date();
                    remainingDays = 7 - Math.ceil((today.getTime() - created.getTime()) / (1000 * 60 * 60 * 24));
                    if (remainingDays <= 0) {
                        message.push(mustVerifyMessage);
                    } else {
                        if (remainingDays === 1) {
                            remainingDays += ' Day';
                        } else {
                            remainingDays += ' Days';
                        }
                        message.push('You have ');
                        message.push(<span key="remaingDays">{remainingDays}</span>);
                        message.push(' remaining to verify your account');
                    }
                } catch (err) {
                    console.log(err);
                    message.push(mustVerifyMessage);
                }
            } else {
                message.push(mustVerifyMessage);
            }
            return (
                <div className="verificationBanner">
                    <div className="left">
                        <div className="alertIcon">!</div>
                        <div className="verifyText">Please Verify Your Account</div>
                    </div>
                    <div className="right">
                        <div className="remainingDays">{message}</div>
                        <div className="resendButton" onClick={this.resendVerificationLink} role="button">
                            Resend Verification Link
                        </div>
                    </div>
                </div>
            );
        }
        return null;
    };

    renderSuccessfullySentVerification = () => {
        const { showSuccessfulSentVerificationLinkMessage, profile } = this.state;
        return (
            <PopUpDialogueBase
                show={showSuccessfulSentVerificationLinkMessage}
                title="Verification Link Sent"
                proceedLabel="OK"
                message={`The verification link has been sent to ${profile && profile.email ? profile.email.toLowerCase() : ''}`}
                proceedFunction={this.handleSuccessfullySentVerificationLink}
                hideCancel
            />
        );
    };

    render() {
        const { profile, highStacking, apiError, showSuccessfulSentVerificationLinkMessage } = this.state;
        if (!profile) {
            return null;
        }
        const navigation = [];
        if (profile.role) {
            if (this.navigationPoints[profile.role]) {
                for (const nav of this.navigationPoints[profile.role]) {
                    navigation.push(this.renderedNavigation(nav));
                }
            }
        }

        const fixedStyle = {};
        if (highStacking) {
            fixedStyle.zIndex = 2000;
            // when the top menu is displaying, we need it on the top of everyting so we
            // add a temporary high z indez to the stacking content the top menu appears in
        }

        return (
            <div className="topNavigationSupport" style={fixedStyle}>
                <ApiError show={apiError} error={apiError} cancelFunction={this.handleApiError} />
                {showSuccessfulSentVerificationLinkMessage && this.renderSuccessfullySentVerification()}
                {this.renderVerificationBanner()}
                <div className="topNavigation">
                    <div>
                        <div>
                            <div className="logo">
                                <img src="/images/prophet.svg" alt="koalifyed-logo" />
                            </div>
                            <div className={cx('navigation', profile.type)}>{navigation}</div>
                            <LoggedInUser menuVisibilityChangeFunction={this.changeStacking} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    profile: state.global.loggedInUserProfile,
});

const mapDispatchToProps = (dispatch) => {
    return {
        storeProfile: (profile) => {
            dispatch(storeUserProfile(profile));
        },
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TopNavigation));
