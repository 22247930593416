import React from 'react'
import './loggedInUser.scss';
import { withRouter } from 'react-router';
import cx from 'classnames';
import { connect } from 'react-redux';
import RightSideDropdown from '../RightSideDropdown'
import { resetAgentBrands } from "../../../../store/agent/actions";
import { storeTenantList, resetTenantList } from '../../../../store/global/globalActions';
import Api from "../../../../modules/Api";
import { TALENT_MANAGER, INFLUENCER, INFLUENCER_STR, TALENT_MANAGER_STR } from "../../../../constants/authorities";
import ApiError from "../../apiError/ApiError"


class LoggedInUser extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            profile: null,
            displayMenu: false,
            showNotification: false,
            imageError: false,
            displayTenants: false,
            showApiError: false,
            apiError: null,
        }
        this.tenantClick = this.tenantClick.bind(this)
    }

    showDropdownMenu = () => {
        const newVal = !this.state.displayMenu;
        this.setState({
            displayMenu: newVal,
            displayTenants: false
        });
        this.props.menuVisibilityChangeFunction(newVal);
    }

    showTenantList = () => {
        const newVal = !this.state.displayTenants;
        this.setState({
            displayTenants: newVal,
            displayMenu: false
        })
        this.props.menuVisibilityChangeFunction(newVal);
    }

    tenantClick = (tenant) => {
        Api.switchTenant(tenant.id)
            .then(res => {
                if (res.statusCode === 200) {
                    this.props.history.push('/home');
                    window.location.reload();
                }else {
                    this.setState({
                        apiError: res.error,
                        showApiError: true
                    })
                }
            })
            .catch(err => {
                this.setState({
                    apiError: err,
                    showApiError: true
                })

            })

    }

    closeApiError = () => {
        this.setState({
            showApiError: false,
            apiError: null
        })
    }

    hideDropdownMenu = () => {
        this.setState({
            displayMenu: false,
            displayTenants: false
        })
        this.props.menuVisibilityChangeFunction(false);
    }

    onImageError = () => {
        this.setState({ imageError: true })
    }

    componentDidMount() {
        const { profile, tenantList } = this.props;

        window.addEventListener('click', this.hideDropdownMenu);
        if (profile && profile.role === INFLUENCER_STR) {
            Api.getManagementData().then((res) => {
                if (res && res.length > 0) {
                    const pendingInvites = res.filter(x => x.status === 'PENDING');
                    if (pendingInvites.length > 0)
                        this.setState({ showNotification: true });
                }
            })
        }
        if (profile && profile.role !== INFLUENCER_STR && profile.role !== TALENT_MANAGER_STR && !tenantList) {
            Api.getTenantList().then(res => {
                this.props.storeTenantList(res.data)
            })
                .catch(err => {
                    this.setState({
                        apiError: err,
                        showApiError: true
                    })
                })            
        }

    }

    componentWillUnmount() {
        window.removeEventListener('click', this.hideDropdownMenu)
    }

    static getDerivedStateFromProps(props, state) {
        return {
            profile: props.profile
        }
    }

    render() {
        const { imageError, profile, displayTenants, showApiError, apiError } = this.state;
        const { tenantList } = this.props;
        if (!profile) {
            return null;
        }
        let pictureUrl = '/images/ic-avatar-blank-d.svg';
        const username = `${profile.firstname} ${profile.lastname}`;
        const activeTenant = profile.activeTenant || {};
        if (this.state.profile.pictureUrl) {
            pictureUrl = this.state.profile.pictureUrl;
        }

        const isUser = profile && (profile.role === TALENT_MANAGER_STR || profile.role === INFLUENCER_STR);

        return (
            <div className="loggedInUser" onClick={(e) => { e.stopPropagation() }}>
                <ApiError
                    show={showApiError}
                    error={apiError}
                    // forceUseInputMessage={true}
                    cancelFunction={this.closeApiError}
                />
                <div className="userInfo-wrapper">
                    <div className="user-container" onClick={this.showDropdownMenu}>
                        <div className="user-profile">
                            <div className="user-name">{username}</div>
                            {isUser ? <div className="user-image">
                                {pictureUrl && !imageError && isUser ? (
                                    <img src={pictureUrl} onError={this.onImageError} />
                                ) : (
                                    username[0]
                                )}
                            </div>
                                :
                            <div className="dropdown-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <path
                                            fill="#ffffff"
                                            fillRule="nonzero"
                                            d="M16.46 9.27a.889.889 0 0 1 1.276 0 .938.938 0 0 1 0 1.305l-5.097 5.154a.888.888 0 0 1-1.278 0l-5.096-5.154a.936.936 0 0 1 0-1.305.889.889 0 0 1 1.276 0l4.46 4.227 4.458-4.227z"
                                        />
                                </svg>
                                </div>}
                            {/* <div className="user-dropdown" style={this.state.displayMenu?{transform: 'rotate(180deg)'}:null}/> */}
                            <RightSideDropdown
                                showNotification={this.state.showNotification}
                                resetAgentBrands={this.props.resetAgentBrands}
                                resetTenantList={this.props.resetTenantList}
                                show={this.state.displayMenu}
                                profile={this.state.profile}
                            />
                        </div>
                    </div>
                    {!isUser && tenantList && tenantList.length > 0 && <div className="tenant-container" onClick={this.showTenantList}>
                        <div className="tenant-divider" />
                        <div className="tenant-info">
                            {activeTenant.name}
                            <div className="tenant-logo">
                                {activeTenant.logo ? (
                                    <img src={activeTenant.logo} onError={this.onImageError} />
                                ) : (
                                    activeTenant.name[0]
                                )}
                            </div>
                            <div className="dropdown-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <path
                                        fill="#ffffff"
                                        fillRule="nonzero"
                                        d="M16.46 9.27a.889.889 0 0 1 1.276 0 .938.938 0 0 1 0 1.305l-5.097 5.154a.888.888 0 0 1-1.278 0l-5.096-5.154a.936.936 0 0 1 0-1.305.889.889 0 0 1 1.276 0l4.46 4.227 4.458-4.227z"
                                    />
                                </svg>
                            </div>
                        </div>
                        {displayTenants && <div className="tenant-list">
                            {tenantList.map((tenant, index) => {
                                const selected = activeTenant.id === tenant.id;
                                return <div key={index}
                                    className={cx("tenant-item", { selected })}
                                    onClick={selected ? null : () => this.tenantClick(tenant)}
                                >
                                    <div className="logo">
                                        {tenant.logo ? (
                                            <img src={tenant.logo} onError={this.onImageError} />
                                        ) : (
                                            tenant.name[0]
                                        )}
                                    </div>
                                    {tenant.name}
                                </div>
                            })}
                        </div>}
                    </div>}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.global.loggedInUserProfile,
        tenantList: state.global.tenantList
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        storeTenantList: (tenantList) => {
            dispatch(storeTenantList(tenantList))
        },
        resetAgentBrands: () => {
            dispatch(resetAgentBrands())
        },
        resetTenantList: () => {
            dispatch(resetTenantList())
        }
    }
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoggedInUser));
