function formatFraction(number) {
    const fixed = Number(number?.toFixed(1));
    if (Boolean(fixed % 1)) return fixed.toFixed(1);
    return fixed.toFixed(0);
}

export function formatPercentageToWhole(number) {
    if (number < 0.1) return '< 0.1';
    if (number < 0.5) {
        return number.toFixed(1);
    }
    return number.toFixed(0);
}

// Formats numbers as 100K, 3M,  9B, etc.
export function formatBigNumber(number) {
    if (number < 1000) return formatFraction(number);
    if (number < 1e6) return `${formatFraction(number / 1e3)}K`;
    if (number < 1e9) return `${formatFraction(number / 1e6)}M`;
    return `${formatFraction(number / 1e9)}B`;
}

export const hexToRGB = (hex, alpha = 1) => {
    hex = hex.toUpperCase();

    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};
