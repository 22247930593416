import React from 'react';
import './flagAvatar.scss';

export default function FlagAvatar({ children }) {
    return (
        <div className="avatar-flag-wrapper">
            <FlagIcon />
            {children}
        </div>
    );
}

export function FlagIcon({ fillColor = 'white', size = 14 }) {
    return (
        <div className="flag-icon">
            <svg width={size} height={size} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.75 1.75H12.3305C12.3816 1.74991 12.4319 1.76327 12.4762 1.78873C12.5206 1.81418 12.5574 1.85084 12.5831 1.89504C12.6088 1.93924 12.6225 1.98941 12.6227 2.04054C12.6229 2.09166 12.6096 2.14194 12.5843 2.18633L10.5 5.83333L12.5843 8.897C12.6096 8.9414 12.6229 8.99167 12.6227 9.0428C12.6225 9.09392 12.6088 9.1441 12.5831 9.1883C12.5574 9.23249 12.5206 9.26915 12.4762 9.29461C12.4319 9.32007 12.3816 9.33342 12.3305 9.33333H2.91667V12.8333H1.75V1.75Z" fill={fillColor} />
            </svg>
        </div>
    )
}