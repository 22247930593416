import React from 'react'
import Modal from 'react-modal';
import {Motion, spring} from 'react-motion'
import {connect} from 'react-redux';
import Api from '../../../modules/Api';
import ListHeader from './ListHeader'
import ListHeaderSecondary from './ListHeaderSecondary'
import ListedCampaign from './ListedCampaign'
import CreateCampaign from "../CreateCampaign"
import PaginationNew from '../../shared/pagination/PaginationNew'
import PleaseWait from '../../shared/pleaseWait/PleaseWaitWhite'
import ApiError from '../../shared/apiError/ApiError'
import './campaignList.scss'
import Params from  './CampaignListParams';
import CommonUtils from '../../../modules/utils/CommonUtils';
import { AGENT_STR, BRAND_OWNER_STR, SUPER_ADMIN_STR } from '../../../constants/authorities';
import SuccessBanner from "../../favourite/SuccessBanner";

// campaign list
// props
// - campaignLIst
// - startCreationFunction
// - selectCampaignFunction takes a CampaignList object

// const permissions = ['ROLE_AGENT', 'ROLE_SUPER_ADMIN', 'ROLE_BRAND_OWNER'];

class CampaignList extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.startCreation = this.startCreation.bind(this);
        this.stopCreation = this.stopCreation.bind(this);
        this.createCampaign = this.createCampaign.bind(this);
        this.showApiError = this.showApiError.bind(this);

        this.state = {
            waitingForCampaign: false,
            creationInProgress : false,
            selectedCampaign : null,
            totalCount: 0,
            showSuccess: false,
            successMessage: '',
            isArchived: false,
            unarchivedCampaign: false,
            countryList: []
        };
    }

    componentDidMount() {
        const urlSearchParams = new URLSearchParams(this.props.location.search);
        this.setState(Params.initializeStateFields, () => this.getCampaignList(
            urlSearchParams.get('page'),
            urlSearchParams.get('limit'),
            urlSearchParams.get('search'),
            urlSearchParams.get('isArchived')
        ));
        Api.getBrandCountry().then(result => {
            const { data } = result;
            const countryList = [];
            data.forEach(x => {
                const country = {label: x.name, value: x.code, currencyCode: x.currencyCode}
                countryList.push(country)
            })
            this.setState({countryList})
        })
    }

    showApiError(error, errTitle) {
        this.setState({
            waitingForCampaign: false,
            showApiError: true,
            apiError : error,
            errTitle 
        })
    }
    
    createCampaign(campaignData) {
        const self =this;
        Api.addCampaign(campaignData).then((res)=>{
            // console.log('added',res);
            self.stopCreation();
            self.props.history.push(`campaign/${res.id}`);
        }).catch(function(error) {
            let errorFinal = error.message;
            let errTitle = '';
            if (error.originalMessage && error.originalMessage.includes("campaign_name_agency_id_unique_idx")) {
                errorFinal = "Campaign with this name already exists, please choose a different name."
                errTitle = ' ';
            }

            self.showApiError(errorFinal, errTitle);
        });
    }

    startCreation() {
        this.setState({
            creationInProgress : true
        });
    }

    stopCreation() {
        this.setState({
            creationInProgress : false
        });
    }

    filterByContactList(campaignList) {
        let filteredByContanctListCampaignList = [];
        if (campaignList && this.props.profile) {
            const userId = this.props.profile.id;
            filteredByContanctListCampaignList = campaignList.filter(campaign => {
                return campaign.contacts.find((contact) => {return contact.agent.id == userId})
            })
            return filteredByContanctListCampaignList;
        }
        return [];
    }

    getCampaignList = (page = null, limit = null, search = null) => {
        this.setState({ waitingForCampaign: true });
        const params = Params.createParamsForApi(this.state);
        if (page)
            params.page = page;
        if (limit)
            params.num = limit;
        if (search)
            params.key = search;

        Api.getCampaignList(params).then(
            (result) => {
                const campaignList = this.filterArchivedAssignments(result.results);
                this.setState({
                    waitingForCampaign: false,
                    // campaignList : this.filterByContactList(result.results),
                    campaignList,
                    totalCount: result.meta.totalCount || 0,
                    selectedCampaign: null,
                    currentPage: page ? Number(page) : this.state.currentPage,
                    pageSize: limit || this.state.pageSize,
                    searchKey: search || this.state.searchKey
                })
            }).catch( (error) => {
            this.showApiError(error);
        });
    }

    filterArchivedAssignments(campaignList) {
        const newList = [];
        if (!campaignList || campaignList.length === 0) {
            return newList;
        }
        campaignList.forEach((campaign) => {
            if (campaign.assignments) {
                const assignments = campaign.assignments.filter(assignment => !assignment.archived)
                campaign.assignments = assignments;
            }
            newList.push(campaign);
        });
        return newList;
    }

    handlePageChange = (newCurrentPage) => {
        this.props.history.push({
            search: `?limit=${this.state.pageSize}&page=${newCurrentPage}${this.state.searchKey ? `&search=${this.state.searchKey}` : ''}`
        })
        this.setState({
            currentPage : newCurrentPage
        }, this.getCampaignList)
    }

    handleCurrentChange = (isCurrent) => {
        this.setState({
            currentOrPast : isCurrent ? 'current' : 'past',
            currentPage : 1
        }, this.getCampaignList);
    }

    handleLimitChange = (limit) => {
        this.props.history.push({
            search: `?limit=${limit}&page=1${this.state.searchKey ? `&search=${this.state.searchKey}` : ''}`
        })
        this.setState({
            pageSize: limit,
            currentPage : 1
        }, this.getCampaignList);
    }

    handleSearchChange = (search) => {
        this.props.history.push({
            search: `?limit=${this.state.pageSize}&page=1${search ? `&search=${search}` : ''}`
        })
        this.setState({
            searchKey: search,
            currentPage : 1
        }, this.getCampaignList);
    }

    flipSort = (sortBy) => {
        const newState = {};
        const fieldName = `${sortBy  }SortDirection`;
        newState[fieldName] = this.state[fieldName] === 'desc' ? 'asc' : 'desc';
        newState.currentPage = 1;
        this.setState(newState, this.getCampaignList)
    }

    sortByName = () => {
        this.setSortBy('name');
    }

    sortByDate = () => {
        this.setSortBy('date');
    }

    sortByBrand = () => {
        this.setSortBy('brand');
    }

    sortBySigner = () => {
        this.setSortBy('signer');
    }

    setSortBy = (sortField) => {
        if (this.state.sortBy === sortField) {
            this.flipSort(sortField);
            return;
        }
        const newState = {
            sortBy : sortField,
            currentPage : 1
        };
        this.setState(newState, this.getCampaignList);
    }

    closeSuccess =() => {
        this.setState({successMessage: '', showSuccess: false});
    }

    onSuccess = message => {
        this.setState({successMessage: message, showSuccess: true}, this.getCampaignList);
    }

    changeArchiveTab = bool => {
        this.setState({isArchived: bool}, this.getCampaignList);
    }

    handleUnarchive = campaign => {
        this.setState({unarchivedCampaign: campaign})
    }

    unarchiveCampaign = () => {
        const {unarchivedCampaign} = this.state;
        Api.unarchiveCampaign(unarchivedCampaign.id).then(result => {
            this.onSuccess("This campaign has been unarchived")
        }).catch(err => {
            this.showApiError(err)
        });
        this.setState({unarchivedCampaign: false})
    }

    render() {
        const urlSearchParams = new URLSearchParams(this.props.location.search);
        const list = this.state.campaignList;
        const pageLimitOptions = [
            { value:'10', label: '10 per page'},
            { value:'20', label: '20 per page'},
            { value:'50', label: '50 per page'}
        ];
        const {profile} = this.props;
        const { successMessage, showSuccess, waitingForCampaign, showApiError, errTitle, apiError, creationInProgress, isArchived,
            unarchivedCampaign, countryList } = this.state;
        const permission = profile && (profile.role === AGENT_STR || profile.role === SUPER_ADMIN_STR || profile.role === BRAND_OWNER_STR);
        return (
            <div>
                <Modal
                    overlayClassName="influencerCardOverlay"
                    className="confirmRatesModal"
                    isOpen={unarchivedCampaign}>
                    <div className="messageWindow">
                        <img src="/images/warning.svg" alt="warning"/>
                        <div className="warningTitle">
                            Unarchive Campaign
                        </div>

                        <div className="message">
                            <b>{unarchivedCampaign.name}</b> has been archived, there will be a gap in the analytics. Would you like to continue?
                        </div>
                        <div className="buttonSection">
                            <div className="cancelButton" onClick={() => this.setState({unarchivedCampaign: false})}>
                                Cancel
                            </div>
                            <div className="continueButton" onClick={this.unarchiveCampaign}>
                                Yes, continue
                            </div>
                        </div>
                    </div>
                </Modal>
                <PleaseWait
                    show={waitingForCampaign}
                    top={155}
                />
                <SuccessBanner
                    show={showSuccess}
                    closeFunction={this.closeSuccess}
                    message={successMessage}
                />
                <ApiError show={showApiError} errTitle={errTitle} error={apiError}
                    cancelFunction={()=>this.setState({waitingForCampaign:false,showApiError:false, apiError: '', errTitle: '' })}/>
                <CreateCampaign
                    show={creationInProgress}
                    cancelFunction={this.stopCreation}
                    proceedFunction={this.createCampaign}
                />
                <ListHeader
                    initialValue={urlSearchParams.get('search')}
                    permission={permission}
                    startCreationFunction={this.startCreation}
                    searchChangeFunction={this.handleSearchChange}
                    changeArchivedTab={this.changeArchiveTab}
                    activeTab={isArchived}
                />
                <ListHeaderSecondary
                    currentChangeFunction={this.handleCurrentChange}
                    sortByName={this.sortByName}
                    sortByDate={this.sortByDate}
                    sortByBrand={this.sortByBrand}
                    sortBySigner={this.sortBySigner}
                    nameSort={this.state.nameSortDirection}
                    dateSort={this.state.dateSortDirection}
                    brandSort={this.state.brandSortDirection}
                    signerSort={this.state.signerSortDirection}
                    sortBy={this.state.sortBy}
                    current={this.state.currentOrPast === 'current'}
                />
                <div className="listPanel" style={{marginTop:60}} ref={e=>this.container=e}>
                    <div className="contentDiv">
                        {
                            ( Array.isArray(list) && list.length > 0 )
                                ? <Motion defaultStyle={{x:20, y:0}} style={{x: spring(0), y: spring(1)}}>
                                    {({x, y}) =>
                                        <div style={{ opacity: `${y}`,
                                            WebkitTransform: `translate3d(0,${x}px, 0)`,
                                            transform: `translate3d(0,${x}px, 0)`, transition:'none'}}>
                                            {(list).map(campaign =>
                                                <ListedCampaign
                                                    key={campaign.id}
                                                    campaign={campaign}
                                                    onSuccess={this.onSuccess}
                                                    showError={this.showApiError}
                                                    handleUnarchive={this.handleUnarchive}
                                                    countryList={countryList}
                                                />)}
                                            <PaginationNew
                                                total={this.state.totalCount}
                                                limit={this.state.pageSize}
                                                current={this.state.currentPage}
                                                parentContainer={this.container}
                                                handlePageChange={this.handlePageChange}
                                                limitChangeFunction={this.handleLimitChange}
                                            />
                                        </div>
                                    }
                                </Motion>
                                :
                                (this.state.currentOrPast === 'current')
                                    ? <div style={{marginTop:'20px'}}>
                                        No campaign yet, click the button to create your campaign
                                    </div>
                                    :<div style={{marginTop:'20px'}}>
                                        No past campaign.
                                    </div>
                        }
                        <div style={{height:30}} />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.global.loggedInUserProfile
    }
};

export default connect(mapStateToProps, null) (CampaignList)
