export const ACCEPTED = 'ACCEPTED';
export const APPROVED = 'APPROVED';
export const COMPLETED = 'COMPLETED';
export const DECLINED = 'DECLINED';
export const REVOKED = 'REVOKED';
export const DRAFT = 'DRAFT';
export const EDITS_SENT = 'EDITS_SENT';
export const EXPIRED = 'EXPIRED';
export const IN_REVIEW = 'IN_REVIEW';
export const NEEDS_REVIEW = 'NEEDS_REVIEW';
export const NEGOTIATE = 'NEGOTIATE';
export const NEW = 'NEW';
export const PAID = 'PAID';
export const PENDING = 'PENDING';
export const SUBMITTED = 'SUBMITTED';
export const PUBLISHED = 'PUBLISHED';

// contract table status for signed contract
export const CONTRACT_UPLOADED = 'SIGNED_OFFLINE';
