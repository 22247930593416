import React from 'react'
import _ from 'lodash';
import '../../campaignDetails/campaignDetails.scss';
import ModalDialogCamp from '../../../shared/modalDialog/ModalDialogCampaign'
import Checkbox from '../../../shared/checkbox/Checbox'
import SelectAssignmentTypes from './SelectAssignmentTypes'
import './createAssignment.scss'
import { assignemntStatusType } from '../../campaignDetails/assignemntStatusType';

// Props
// - show
// - saveFunction (takes a new Assignment object w/o campaignId)
// - cancelFunction
// - campaign
// - otherAssignments
// - assignment (if it already exists)

export default class CreateAssignmentPopup extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.renderedReminder = this.renderedReminder.bind(this);
        this.renderedCheckboxLine = this.renderedCheckboxLine.bind(this);
        this.handleCopyFromSelection = this.handleCopyFromSelection.bind(this);
        this.updateObject = this.updateObject.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.saveAssignment = this.saveAssignment.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.processProps = this.processProps.bind(this);
        this.modalIconActionMedium = this.modalIconActionMedium.bind(this);

        // this.copyFields = [ "draftReminder","missedReminder","name", "numberOfFrames","postReminder", "types", "chores"];
        this.copyFields = [ "name", "types"];
        this.defaultValues = {
            draftReminder : false,
            missedReminder : false,
            postReminder : false,
            types : [],
            numberOfFrames : 0
        }

        this.state = {
            name : '',
            types : null,
            numberOfPosts : 0,
            draftReminder : false,
            postReminder : false,
            missedReminder : false,
            assignmentToCopy : -1,
            copyAssets : false,
            copyInfluencers : false,
            canSave : false
        }

        this.reminders = [
            {   key : "draftReminder",
                label : "Send Creator an email reminder to submit their assignment draft"
            },
            {
                key: "postReminder",
                label: "Send Creator an email reminder to publish their post"
            },
            {   key : "missedReminder",
                label : "Send Creator an email reminder when they have missed their schedule draft and/or posting date"
            }
        ]
        this.assignmentObject = {};
    }

    componentDidMount() {
        this.processProps(this.props);
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps, this.props)) {
            this.processProps(this.props);
        }
    }

    handleTypeChange(list, ready){
        this.setState({
            types: list,
            // typesOk: ready
        }, this.updateObject)
    }

    handleNameChange(e) {
        this.setState({ name:  e.target.value}, this.updateObject);
    }

    updateObject() {
        if (this.props.assignment) {
            this.assignmentObject.id = this.props.assignment.id
        }
        this.assignmentObject.campaignId = this.props.campaign.id;
        // this.assignmentObject.copy = {};
        if (this.state.assignmentToCopy > 0 ) {
            this.assignmentObject.copy.assignmentId = this.state.assignmentToCopy;
            this.assignmentObject.copy.copyAssets = this.state.copyAssets;
            this.assignmentObject.copy.copyInfluencers = this.state.copyInfluencers;
        }

        for (const field of this.copyFields) {
            this.assignmentObject[field] = this.state[field];
        }

        const canSave =
            (this.state.name.length > 0
            //  && this.state.typesOk
            )
        this.setState({ canSave });
    }

    handleCopyFromSelection(selection) {
        this.setState({ assignmentToCopy : selection.key}, this.updateObject)
    }

    renderedReminder(reminder) {
        return this.renderedCheckboxLine(reminder.key, reminder.label);
    }

    renderedCheckboxLine(key, label) {
        const self = this;
        const changeFunction = function(newValue){
            const newState = {};
            newState[key] = newValue;
            self.setState(newState, self.updateObject);
        }
        return (
            <div key={key} className="checkboxLine">
                <div>
                    <Checkbox
                        checked={this.state[key]}
                        size={20}
                        changeFunction={changeFunction}
                    />
                </div>
                <div>
                    { label}
                </div>
            </div>
        )
    }

    saveAssignment() {
        if (!this.state.canSave) {
            return;
        }
        let saveObject = {};
        // turn type into types[]
        for (const ix in this.assignmentObject) {
            saveObject[ix] = this.assignmentObject[ix];
        }
        saveObject = this.convertAssignmentChores(saveObject);
        const f = this.props.saveFunction;
        if (typeof f === 'function') {
            f(saveObject);
        }
    }

    convertAssignmentChores(saveObject) {
        if (!saveObject || saveObject.length === 0)
            return;

        const types = [];
        // const chores = [];

        saveObject.types.forEach(type => {
            if (type.type) {
                if (!types.includes(type.type))
                    types.push(type.type);
            }
        });

        saveObject.types = types;
        // saveObject.chores = chores;
        return saveObject;
    }

    processProps(props) {
        const a = props.assignment;
        const newState = {};
        for (const field of this.copyFields) {
            newState[field] = a ? a[field] : (this.defaultValues[field] || '');
        };

        if (props.assignment) {
            const types = this.processTypesForEdit(props.assignment);
            newState.types = types;
        }

        this.setState(newState);
    }

    processTypesForEdit(assignment) {
        if (!assignment || !assignment.types || assignment.types.length === 0)
            return [];

        const types = [];
        assignment.types.forEach(type => {
            types.push({
                type,
                count: 1,
                framesInfo: {
                    parentType: type,
                    framesCount: 1
                }
            })
        })
        return types;
    }

    modalIconActionMedium() {
        const f = this.props.modalIconAction;
        if (typeof f === 'function') {
            f();
        }
    }

    deleteAssignment = () => {
        this.props.deleteFunction();
    }

    render() {
        // if (!this.props.show) {
        //     return null;
        // }
        const reminders = [];
        for (const r of this.reminders) {
            reminders.push(this.renderedReminder(r));
        }

        const {otherAssignments} = this.props;
        const showCopy = (2<1) && otherAssignments && otherAssignments.length > 0 && !this.props.selectedAssignment;
        const assignmentChoices = [{
            key: -1,
            label: 'No, this is a brand new assignment'}
        ];
        if (showCopy) {
            for (const a of otherAssignments) {
                assignmentChoices.push({key: a.id, label: a.name});
            }
        }

        const copyStyle = {paddingTop: 10};
        if (this.state.assignmentToCopy == -1) {
            copyStyle.display = "none";
        }

        const modalTitle = this.props.assignment ? 'Edit Assignment' : 'Create a New Assignment';
        const saveButtonText = this.props.assignment ? 'SAVE' : 'CREATE';
        const deleteIcon = (this.props.assignment && this.props.assignment.id &&
            this.props.statusOfAssignment == assignemntStatusType.default) ? "/images/ic-delete-greyDarker.svg" : null;

        return (
            <div className="createAssignmentPopup">
                <ModalDialogCamp
                    show={this.props.show}
                    title={modalTitle}
                    cancelFunction={this.props.cancelFunction}
                    proceedFunction={this.saveAssignment}
                    proceedButtonLabel={saveButtonText}
                    readyToProceed={this.state.canSave}
                    fadeIn
                    optionalIcon={deleteIcon}
                    optionalIconFunction={this.deleteAssignment}>
                    <div className="label">
                        Assignment Name
                    </div>
                    <div>
                        <input onChange={this.handleNameChange} value={this.state.name}/>
                    </div>
                    <div className="label">
                        Deliverables (optional)
                    </div>
                    <SelectAssignmentTypes
                        types={this.state.types}
                        statusOfAssignment={this.props.statusOfAssignment}
                        numberOfFrames={this.state.numberOfFrames}
                        changeFunction={this.handleTypeChange}
                    />
                </ModalDialogCamp>
            </div>
        )
    }
}
