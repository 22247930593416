import React from 'react';
import './style.scss';
import Select from 'react-select';
import { connect } from 'react-redux';
import Api from '../../../../modules/Api';
import ErrorMessageBox from '../../../shared/errorMessageBox/ErrorMessageBox';
import FeedbackOnTheTop from '../../../shared/feedbackOnTheTop/FeedbackOnTheTop';
import ProfileHelper from '../../../../modules/utils/ProfileHelper';
import PleaseWait from '../../../shared/pleaseWait/PleaseWaitWhite';

const roleOptions = [
    { value: 'BRAND_OWNER', label: 'Brand Owner' },
    { value: 'AGENT', label: 'User' },
    { value: 'VIEWER', label: 'Viewer' },
];

class AddNewUserForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            availableBrands: [],
            selectedBrands: [],
            selectedAdvertisers: [],
            availableAdvertisers: [],
            name: '',
            lastName: '',
            email: '',
            role: this.props.role !== "SUPER_ADMIN" ? 'VIEWER' : '',
            emailError: false,
            showApiError: false,
            apiError: '',
            showWaiting: false,
        };
        this.brandOptions = [];
    }

    componentDidMount() {
        const self = this;
        Api.getBrandCountry().then(result => {
            const { data } = result;
            const countryList = [];
            data.forEach(x => {
                const country = {label: x.name, value: x.code, currencyCode: x.currencyCode}
                countryList.push(country)
            })
            this.setState({countryList}, this.setBrands)
        }).catch(err => {
            this.setBrands();
        })
        ProfileHelper.getCurrentLoginUserProfile()
            .then(res => {
                self.getUserData(res);
            })
            .catch(err => {
                console.log(err);
                this.setState({ apiError: 'Failed to retrieve profile', showApiError: true });
            })
    }

    setBrands = () => {
        const { brands } = this.props;
        const { countryList } = this.state;
        const availableBrands = [];
        if (brands) {
            brands.forEach(item => {
                const countryToFind = countryList.find(x => x.value === item.countryCode)?.label
                availableBrands.push({
                    value: Number(item.brandId),
                    label: <div className="brandLabel">{item.brandName}{countryToFind && <div className="countryLabel"> - {countryToFind}</div>}</div>,
                    logo: item.brandLogo,
                    advertiserId: item.advertiser.id,
                    brandName: item.brandName
                })
            })
            availableBrands.sort((a, b) => {
                return a.brandName.toLowerCase().localeCompare(b.brandName.toLowerCase())
            })
            this.setState({ availableBrands });
        }
    }

    getUserData = (user) => {
        const self = this;
        Api.getMyAdvertisers()
            .then(response => {
                let availableAdvertisers = [];
                try {
                    response.forEach(item => {
                        if (!item.isArchived)
                            availableAdvertisers.push({
                                value: item.id,
                                label: item.name,
                                logo: item.logoUrl,
                            });
                    });
                    availableAdvertisers.sort((a, b) => {
                        return a.label.localeCompare(b.label)
                    })
                    self.setState({availableAdvertisers});
                }catch(error) {
                    console.log(error);
                }
                
            })
            .catch(e => {
                console.log(e);
            })
    }

    handleChange = (e, name) => {
        if (name === 'email') {
            const regex =  /^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,20}$/;
            const isValid = regex.test(e.target.value);
            if (!isValid) {
                this.setState({ emailError: 'Email is not valid' });
            } else {
                this.setState({ emailError: false });
            }
        }
        this.setState({ [name]: e.target.value }, () => this.checkFormValid());
    };

    handleRoleChange = (e) => {
        this.setState({ role: e.value }, () => this.checkFormValid());
    };

    handleBrandChange = (e) => {
        // const brandList = [...this.state.availableBrands];
        const fullList = [...this.state.selectedBrands];
        const elem = fullList.find((item) => item.value === e.value);
        if (elem) return;
        fullList.push({ value: e.value, label: e.label, logo: e.logo });
        this.setState({ selectedBrands: fullList }, () => this.checkFormValid());
    };

    deleteBrand = (i) => {
        const fullList = [...this.state.selectedBrands];
        fullList.splice(i, 1);
        this.setState({ selectedBrands: fullList }, () => this.checkFormValid());
    };

    handleAdvertiserChange = (e) => {
        const {selectedAdvertisers} = this.state;
        const elem = selectedAdvertisers.find(a => a.value === e.value);
        if (elem) return;
        selectedAdvertisers.push(e);
        selectedAdvertisers.sort((a, b) => {
            if (a.label > b.label) return 1;
            if (a.label < b.label) return -1;
            return 0;
        })
        this.setState({
            selectedAdvertisers,
        }, () => this.checkFormValid());
    }

    deleteAdvertiser = (i) => {
        const {selectedAdvertisers, selectedBrands} = this.state;
        const deleteAdvertiserId = selectedAdvertisers[i].value;
        selectedAdvertisers.splice(i, 1);
        const newBrands = selectedBrands.filter(b => b.advertiserId !== deleteAdvertiserId);
        newBrands.sort((a, b) => {
            return a.label.localeCompare(b.label)
        })
        selectedAdvertisers.sort((a, b) => {
            return a.label.localeCompare(b.label)
        })
        this.setState({
            selectedAdvertisers,
            selectedBrands: newBrands,
        }, () => this.checkFormValid());
    }

    checkFormValid = () => {
        const { name, lastName, email, role } = this.state;
        const regex =  /^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,20}$/;
        const isValid = regex.test(email);
        if (name.trim() && lastName.trim() && isValid && role) {
            this.setState({ formValid: true });
        } else {
            this.setState({ formValid: false });
        }
    };

    createNewUser = () => {
        const { name, lastName, email, role, selectedBrands, selectedAdvertisers } = this.state;
        const brandIds = [];
        const advertiserIds = [];
        selectedBrands.forEach(b => brandIds.push(b.value));
        selectedAdvertisers.forEach(a => advertiserIds.push(a.value));
        const payload = {
            firstName: name.trim(),
            lastName: lastName.trim(),
            email: email.trim().toLowerCase(),
            role,
        };
        payload.brandPermissions = [...brandIds];
        payload.advertiserPermissions = [...advertiserIds];
        this.setState({showWaiting: true})
        Api.createNewAgent(payload)
            .then(() => {
                this.setState({ successMessage: true, showWaiting: false });
            })
            .catch((e) => {
                this.setState({ apiError: e.originalMessage ? e.originalMessage : e.message, showApiError: true, showWaiting: false });
            });
    };

    render() {
        const { selectedBrands, availableBrands, selectedAdvertisers, availableAdvertisers, emailError, showWaiting } = this.state;
        const { role } = this.props;
        const advertiserIds = [];
        selectedAdvertisers.forEach(a => advertiserIds.push(a.value));
        const brandIds = [];
        selectedBrands.forEach(b => brandIds.push(b.value));
        const allowSelectBrands = availableBrands.filter(b => advertiserIds.includes(b.advertiserId) && !brandIds.includes(b.value));
        const allowSelectAdvertisers = availableAdvertisers.filter(a => !advertiserIds.includes(a.value));
        return (
            <div className="new-user-form permission-page">
                <ErrorMessageBox
                    show={this.state.showApiError}
                    closeFunction={() => this.setState({ apiError: '', showApiError: false })}
                    message={this.state.apiError}
                />
                <FeedbackOnTheTop
                    show={this.state.successMessage}
                    closeFunction={() => this.setState({ successMessage: false }, () => this.props.returnToAll())}
                    message="User has been invited"
                />
                <PleaseWait show={showWaiting} />
                <h1>Add New User</h1>
                <form className="userForm agentAdmin">
                    <div className="inputPanel name-holder">
                        <div className="input-holder">
                            <div className="label">First Name</div>
                            <input value={this.state.name} type="text" onChange={(e) => this.handleChange(e, 'name')} />
                        </div>
                        <div className="input-holder">
                            <div className="label">Last Name</div>
                            <input
                                value={this.state.lastName}
                                type="text"
                                onChange={(e) => this.handleChange(e, 'lastName')}
                            />
                        </div>
                    </div>
                    <div className="inputPanel">
                        <div className="input-holder">
                            <div className="label">Email</div>
                            <input
                                value={this.state.email}
                                type="text"
                                onChange={(e) => this.handleChange(e, 'email')}
                            />
                            {emailError ? (
                                <div className="errors">
                                    <div>{emailError}</div>
                                </div>
                            ) : (
                                ''
                            )}
                            <div className="label">Role</div>
                            <Select
                                className="select"
                                clearable={false}
                                searchable={false}
                                value={this.state.role}
                                single
                                // placeholder="Select"
                                options={roleOptions}
                                onChange={this.handleRoleChange}
                                disabled={role !== "SUPER_ADMIN"}
                            />
                            <div className="label">Advertiser</div>
                            <Select
                                className="select"
                                clearable={false}
                                searchable={false}
                                // value={this.props.pageLimit}
                                single
                                // placeholder="Select"
                                options={allowSelectAdvertisers}
                                onChange={this.handleAdvertiserChange}
                            />
                            {selectedAdvertisers.length ? (
                                <div className="brands">
                                    {selectedAdvertisers.map((advertiser, i) => {
                                        return (
                                            <div key={i} className="brand-holder">
                                                <div className="brand-content">
                                                    <div className="img-holder">
                                                        {advertiser.logo ? (
                                                            <img src={advertiser.logo} alt={advertiser.value} />
                                                        ) : (
                                                            advertiser.label[0]
                                                        )}
                                                    </div>
                                                    <div className="brand-name">{advertiser.label}</div>
                                                </div>
                                                <div className="del-brand-button" onClick={() => this.deleteAdvertiser(i)} />
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : (
                                ''
                            )}
                            <div className="label">Brand</div>
                            <Select
                                className="select"
                                clearable={false}
                                searchable={false}
                                // value={this.props.pageLimit}
                                single
                                // placeholder="Select"
                                options={allowSelectBrands}
                                onChange={this.handleBrandChange}
                            />
                            {selectedBrands.length ? (
                                <div className="brands">
                                    {selectedBrands.map((brand, i) => {
                                        let logo = null;
                                        if (brand.logo) {
                                            logo = <img src={brand.logo} alt={brand.value} />;
                                        } else if (typeof brand.label === "string") {
                                            logo = brand.label[0]
                                        } else {
                                            logo = brand.label.props.children[0][0]
                                        }
                                        return (
                                            <div key={i} className="brand-holder">
                                                <div className="brand-content">
                                                    <div className="img-holder">
                                                        {logo}
                                                    </div>
                                                    <div className="brand-name">{brand.label}</div>
                                                </div>
                                                <div className="del-brand-button" onClick={() => this.deleteBrand(i)} />
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </form>
                <div className="manager-card-bottom new-user-form-bottom">
                    <button className="button cancel" onClick={this.props.returnToAll}>
                        Cancel
                    </button>
                    <button
                        disabled={!this.state.formValid}
                        className={`button ${!this.state.formValid ? 'disabled' : ''}`}
                        onClick={this.createNewUser}
                    >
                        Invite
                    </button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        brands: state.agent.brands,
    };
};

export default connect(mapStateToProps)(AddNewUserForm);
