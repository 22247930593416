import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import moment from "moment";
import './favouriteList.scss';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PleaseWaitWhite from '../shared/pleaseWait/PleaseWaitWhite';
import TopNavigation from "../shared/topNavigation/TopNavigation"
import ApiError from '../shared/apiError/ApiError';
import PaginationNew from '../shared/pagination/PaginationNew'
import {
    fetchFavorites,
    saveFavorites,
    deleteFavorites,
    createFavorites,
    errorFavoriteList,
    maxNumberOfFavoriteLists,
    allFavoritesName
} from '../../store/actions/favoriteActions';
import SuccessBanner from "./SuccessBanner";
import Api from '../../modules/Api';
import PopUpDialogueBase from '../shared/popUpDialogueBase/PopUpDialogueBase';
import { Trash } from 'phosphor-react';
import NewEditModal from './NewEditModal';
import SortableTable from './SortableTable';
import ListPageTopBar from './ListPageTopBar';

const defaultOrders = {
    name: 'DESC',
    createdOn: 'DESC',
    updatedOn: 'DESC',
};

const titles = [
    { key: 'name', label: 'Name', width: '30%', flag: 'text', style: { color: '#000000', fontWeight: 'bold' } },
    { key: 'totalUserCount', label: 'Creators', width: '10%', flag: 'text' },
    { key: 'tags', label: 'Tags', width: '30%', flag: 'tags' },
    { key: 'createdOn', label: 'Created on', width: '15%', flag: 'date' },
    { key: 'updatedOn', label: 'Modified on', width: '15%', flag: 'date' },
];

const Actions = [
    { key: 'edit', label: 'Edit' },
    { key: 'duplicate', label: 'Duplicate' },
    { key: 'delete', label: 'Delete' },
    // 'divider',
    // { key: 'plan', label: 'Add to plan' },
];

const FavouriteList = ({
    fetchFavorites,
    saveFavorites,
    createFavorites,
    favoriteData,
    favoriteError,
    resetError,
    loading,
    history,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [apiError, setApiError] = useState();
    const [errTitle, setErrTitle] = useState('');
    const [sortBy, setSortBy] = useState('updatedOn');
    const [orders, setOrders] = useState(defaultOrders);
    const [totalCount, setTotalCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [favouriteList, setFavouriteList] = useState([]);
    const [currentFavourite, setCurrentFavourite] = useState(null);
    const [showDelete, setShowDelete] = useState(false);
    const [showFavoriteModal, setShowFavoriteModal] = useState(false);
    const [filterValue, setFilterValue] = useState('');
    const containerRef = useRef();

    useLayoutEffect(() => {
        fetchData();
    }, [sortBy, orders, filterValue, currentPage, pageSize]);

    const fetchData = () => {
        const params = {
            direction: orders[sortBy],
            keyword: filterValue,
            page: currentPage - 1,
            size: pageSize,
            sortBy: sortBy
        }
        setIsLoading(true);
        Api.searchFavorite(params)
            .then(res => {
                if (res.data) {
                    setFavouriteList(res.data);
                }
                if (res.pageInfo) {
                    setTotalCount(res.pageInfo.totalElements);
                }
                setIsLoading(false);
            })
            .catch(err => {
                handleApiError(err);
                setIsLoading(false);
            });
    }

    const updateFavouriteList = () => {
        if (favoriteData && favoriteData.length > 0) {
            let data = Object.assign([], favoriteData);

            if (typeof filterValue === 'string' && filterValue.length > 0) {
                data = data.filter(d => d.name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1)
            }

            const update = data.map(f => ({
                ...f,
                tags: f.tags || [],
                creators: f.users && f.users.length || 0,
                createdOn: moment(f.createdOn).toDate().getTime(),
                updatedOn: moment(f.updatedOn).toDate().getTime(),
            }))
            const forward = orders[sortBy] === 'DESC';
            update.sort((a, b) => (sortBy === 'name' ? a[sortBy].toLowerCase() > b[sortBy].toLowerCase() : a[sortBy] > b[sortBy]) ? (forward ? 1 : -1) : (forward ? -1 : 1));
            setFavouriteList(update);
        } else {
            setFavouriteList([]);
        }
    };

    const handleApiError = (err) => {
        setApiError(err);
        if (err && err.originalMessage) {
            setErrTitle(err.originalMessage);
        }
    };

    const handleSortChange = (val) => {
        if (sortBy === val) {
            if (orders[val]) {
                const update = { ...orders };
                update[val] = orders[val] === 'ASC' ? 'DESC' : 'ASC';
                setOrders(update);
            }
        } else {
            setSortBy(val);
        }
    };

    const handlePageChange = (val) => {
        setCurrentPage(val);
    };

    const handleLimitChange = (val) => {
        setCurrentPage(1);
        setPageSize(val);
    };

    const addNew = () => {
        setShowFavoriteModal(true);
    };

    const CancelError = () => {
        setApiError(null);
        setErrTitle('');
    };

    const filterLists = (e) => {
        setFilterValue(e.target.value);
    };

    const onActions = (action, favourite) => {
        switch (action) {
            case 'delete':
                setShowDelete(true);
                break;
            case 'edit':
                setShowFavoriteModal(true);
                break;
            case 'duplicate':
                duplicateFavourite(favourite);
                break;
            default:
                return;
        }
        setCurrentFavourite(favourite);
    };

    const cancelDelete = () => {
        setShowDelete(false);
        setCurrentFavourite(null);
    };

    const closeModal = () => {
        setShowFavoriteModal(false);
        setCurrentFavourite(null);
    };

    const onSuccess = (res, update) => {
        setShowSuccess(true);
        if (update) {
            setSuccessMessage('Success! Your list has been updated.');
        } else {
            setSuccessMessage('Success! Your list has been created.');
        }
    };

    const onSubmit = (data) => {
        closeModal();
        setIsLoading(true);
        if (data.id) {
            Api.updateFavorite(data.id, data)
                .then(res => {
                    fetchData();
                    onSuccess(res, true);
                })
                .catch(err => {
                    handleApiError(err);
                    setIsLoading(false);
                })
        } else {
            Api.createFavorite(data)
                .then(res => {
                    fetchData();
                    onSuccess(res, false);
                })
                .catch(err => {
                    handleApiError(err);
                    setIsLoading(false);
                })
        }
    };

    const closeSuccessMessage = () => {
        setShowSuccess(false);
        setSuccessMessage('');
    };

    const duplicateFavourite = (favourite) => {
        if (favourite) {
            setIsLoading(true);
            Api.copyFavorite(favourite.id)
                .then(res => {
                    setIsLoading(false);
                    setCurrentFavourite(null);
                    fetchData();
                    setShowSuccess(true);
                    setSuccessMessage('Success! Your file has been successfully duplicated.');
                })
                .catch(err => {
                    setIsLoading(false);
                    setCurrentFavourite(null);
                    handleApiError(err);
                })
        }
    }

    const deleteFavourite = () => {
        if (currentFavourite) {
            setIsLoading(true);
            Api.deleteFavorite(currentFavourite.id)
                .then(res => {
                    setShowDelete(false);
                    setCurrentFavourite(null);
                    fetchData();
                })
                .catch(err => {
                    setIsLoading(false);
                    setShowDelete(false);
                    setCurrentFavourite(null);
                    handleApiError(err);
                });
        } else {
            setShowDelete(false);
            setCurrentFavourite(null);
        }
    };

    const onCardClick = (favorite) => {
        history.push(`/favourite/${favorite.id}`);
    };

    const disableCheck = (data) => {
        return data.totalUserCount === 0;
    }

    return (
        <div className='favouriteListContainer'>
            <PleaseWaitWhite
                show={isLoading}
            />
            <ApiError
                show={apiError}
                errTitle={errTitle}
                error={apiError}
                cancelFunction={CancelError}
            />
            <TopNavigation current="influencers" />
            <div className='contentContainer' ref={containerRef}>
                <ListPageTopBar
                    label={'Favourite Lists'}
                    onChange={filterLists}
                    onButton={addNew}
                    searchPlaceHolder={'Search favourite list...'}
                    buttonLabel={'New favourite'}
                />
                <div className='listTableContainer'>
                    {favouriteList && favouriteList.length > 0 && <SortableTable
                        columns={titles}
                        orders={orders}
                        sortBy={sortBy}
                        onChange={handleSortChange}
                        listData={favouriteList}
                        actions={Actions}
                        onActions={onActions}
                        onCardClick={onCardClick}
                        disable={disableCheck}
                    />}
                    <PaginationNew
                        total={totalCount}
                        limit={pageSize}
                        current={currentPage}
                        parentContainer={containerRef.current}
                        handlePageChange={handlePageChange}
                        limitChangeFunction={handleLimitChange}
                    />
                    <div style={{ height: 45 }} />
                </div>
            </div>
            <PopUpDialogueBase
                title={'Confirm delete'}
                show={showDelete}
                icon={<Trash color="#d90036" size={40} weight="bold" />}
                message={<div className="favouriteDeleteMessage">Are you sure you want to delete <span>{`"${currentFavourite && currentFavourite.name}"`}</span> list?</div>}
                cancelFunction={cancelDelete}
                proceedLabel="Delete"
                loading={isLoading}
                proceedButtonStyle={{ backgroundColor: '#d90036', width: 100 }}
                cancelButtonStyle={{ width: 100 }}
                proceedFunction={deleteFavourite}
            />
            <NewEditModal
                show={showFavoriteModal}
                data={currentFavourite}
                onClose={closeModal}
                onSubmit={onSubmit}
                // listData={favoriteData}
                label={'favourite'}
                placeholder={'Favourite name'}
            />
            <SuccessBanner
                show={showSuccess}
                closeFunction={closeSuccessMessage}
                message={successMessage}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        favoriteData: state.FAVORITE_REDUCER.favoriteData,
        loading: state.FAVORITE_REDUCER.favoriteLoading,
        favoriteError: state.FAVORITE_REDUCER.favoriteError,
        profile: state.global.loggedInUserProfile
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchFavorites: () => dispatch(fetchFavorites()),
        resetError: () => dispatch(errorFavoriteList(null)),
        saveFavorites: (favId, fav, cb) => dispatch(saveFavorites(favId, fav, cb)),
        createFavorites: (fav, cb) => dispatch(createFavorites(fav, cb)),
        deleteFavorites: id => dispatch(deleteFavorites(id))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FavouriteList));