import {
    ASSIGNMENT_SELECT_CHANGE,
    ASSIGNMENT_SELECT_RESET,
    ASSIGNMENT_SELECT_SET_DATA,
} from './actions';

export const assignmentSelectChange = (selectValue, updateSaga) => ({
    type: ASSIGNMENT_SELECT_CHANGE,
    selectValue,
    updateSaga,
});

export const resetAssignmentSelect = () => ({
    type: ASSIGNMENT_SELECT_RESET,
});

export const setAssignmentSelectData = (payload) => ({
    type: ASSIGNMENT_SELECT_SET_DATA,
    payload,
});
