import React, { useState, useLayoutEffect, useRef, useEffect } from 'react';
import './exclusionPopup.scss';
import cx from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import AddToBase from './AddToBase';
import Api from '../../modules/Api';
import ApiError from '../shared/apiError/ApiError';
import SuccessBanner from "./SuccessBanner";


const ExclusionPopup = ({
    onBack,
    setSuccess,
    usersSelected,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [filterValue, setFilterValue] = useState('');
    const [data, setData] = useState();
    const [apiError, setApiError] = useState();
    const [errTitle, setErrTitle] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const currentPage = useRef(1);
    const totalPages = useRef(0);


    useLayoutEffect(() => {
        fetchData();
    }, [filterValue]);

    useLayoutEffect(() => {
        if (data && usersSelected) {
            if (data.results) {
                // const isConnectedInfluencer = Boolean(usersSelected.id);

                // const update = data.results.map(d => {
                //     const selected = isConnectedInfluencer ? d.users.map(u => u.id).includes(usersSelected.id)
                //         : d.publicUsers.map(p => p.userId).includes(usersSelected.uid);
                //     return {
                //         ...d,
                //         selected
                //     }
                // });
                const update = data.results;
                if (currentPage.current === 1) {
                    setDataList(update);
                } else {
                    setDataList([...dataList, ...update]);
                }
            };
            if (data.meta) {
                totalPages.current = Math.ceil(data.meta.totalCount / data.meta.pageSize);
            };
        };
    }, [data, usersSelected]);

    const fetchData = () => {
        const params = {
            desc: false,
            search: filterValue,
            page: currentPage.current,
            num: 20,
            sortBy: 'name'
        }
        setIsLoading(true);
        Api.getExclusions(params)
            .then(res => {
                setData(res);
                setIsLoading(false);
            })
            .catch(err => {
                handleApiError(err);
                setIsLoading(false);
            });
    }

    const onSuccess = (res, update) => {
        setShowSuccess(true);
        if (update) {
            setSuccess('Success! Your list has been updated.')
            setSuccessMessage('Success! Your list has been updated.');
        } else {
            setSuccess('Success! Your list has been created.')
            setSuccessMessage('Success! Your list has been created.');
        }
    };

    const onItemClick = (item) => {
        const update = () => {
            const update = Object.assign([], dataList);
            update.find(d => d.id === item.id).selected = !item.selected;
            setDataList(update);
        };

        if (usersSelected) {
            setIsLoading(true);
            const isConnectedInfluencer = Boolean(usersSelected.id);
            const payload = {};
            if (isConnectedInfluencer) {
                payload.connectedUserIds = [usersSelected.id];
            } else {
                payload.publicUsers = [{
                    iqSocialUserId: usersSelected.uid,
                    userType: usersSelected.userNetworks && usersSelected.userNetworks[0] && usersSelected.userNetworks[0].networkType || '',
                    userName: usersSelected.userNetworks && usersSelected.userNetworks[0] && usersSelected.userNetworks[0].socialHandle || ''
                }];
            }

            if (item.selected) {
                Api.removeUsersExclusion(item.id, payload)
                    .then(res => {
                        update();
                        if (setSuccess)
                            setSuccess(`Success! 1 creator has been removed`);
                        setIsLoading(false);
                    })
                    .catch(err => {
                        setIsLoading(false);
                        handleApiError(err);
                    })
            } else {
                Api.addUsersExclusion(item.id, payload)
                    .then(res => {
                        update();
                        if (setSuccess)
                            setSuccess(`Success! 1 creator has been excluded`);
                        setIsLoading(false);
                    })
                    .catch(err => {
                        setIsLoading(false);
                        handleApiError(err);
                    })
            }
        }
    }

    const toCreate = (name) => {
        setIsLoading(true);
        Api.createExclusion({ name })
            .then(res => {
                currentPage.current = 1;
                fetchData()
                onSuccess(res, false);
                setIsLoading(false);
            })
            .catch(err => {
                handleApiError(err);
                setIsLoading(false);
            })
    };

    const onSearch = (filter) => {
        currentPage.current = 1;
        setFilterValue(filter);
    }

    const handleApiError = (err) => {
        setApiError(err);
        if (err && err.originalMessage) {
            setErrTitle(err.originalMessage);
        }
    };

    const CancelError = () => {
        setApiError(null);
        setErrTitle('');
    };

    const closeSuccessMessage = () => {
        setShowSuccess(false);
        setSuccessMessage('');
    };

    const onLoadMore = () => {
        if (currentPage.current < totalPages.current) {
            currentPage.current++;
            fetchData();
        }
    };

    return (
        <div className='exclusionPopupContainer'>
            <AddToBase
                // hideBottom
                loading={isLoading}
                onBack={onBack}
                data={dataList}
                onCreate={toCreate}
                onChange={onItemClick}
                onSearch={onSearch}
                onLoadMore={onLoadMore}
                headerLabel={'Add to exclusion lists'}
                searchPlaceHolder={'Search list...'}
                createPlaceHolder={'Enter list name'}
                noDataMessage={filterValue ? `It does not look like you have a exclusions list ${filterValue}. Please try again.`
                    : 'It does not look like you have any exclusions lists, start by creating one'}
            />
            <ApiError
                show={apiError}
                errTitle={errTitle}
                error={apiError}
                cancelFunction={CancelError}
            />
            <SuccessBanner
                show={showSuccess}
                closeFunction={closeSuccessMessage}
                message={successMessage}
            />
        </div>
    )
};

const mapStateToProps = (state) => {
    return {

    }
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExclusionPopup));
