import React from 'react';
import './loadingAvatar.scss';

export const LoadingAvatar = ({ loading, onCancel, children }) => {
    return <div className="loadingAvatarContainer">
        {loading && <div className="loadingSpinner">
            <div>a</div>
        </div>}
        <div className="childrenContainer">{children}</div>
    </div>;
};

