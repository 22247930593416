import {
    FAVORITES_CREATE,
    FAVORITES_READ,
    FAVORITES_UPDATE,
    FAVORITES_DELETE,
    FAVORITE_LOADING,
    FAVORITE_ERROR,
    FAVORITE_SUCCESS,
    FAVORITE_ADD_USERS,
    FAVORITE_COPY,
    FAVORITE_DELETE,
    FAVORITE_DETAILS,
    FAVORITE_NEW,
    FAVORITE_REMOVE_USERS,
    FAVORITE_SEARCH,
    FAVORITE_UPDATE
} from '../types';

import {store} from '../../index.jsx';

import Api from '../../modules/Api';

export const allFavoritesName = "All Favorites";

export const maxNumberOfFavoriteLists = 50;

// works properly only after a FAVORITE_READ success
export const getListsForInfluencer = (id) => {
    const favoriteState = store.getState().FAVORITE_REDUCER;
    if (!favoriteState.favoriteData) {
        return null;
    }
    const retval = [];
    for (const list of favoriteState.favoriteData) {
        if (Array.isArray(list.users) && list.users.find((user) => {return user.id == id})) {
            retval.push(list);
        }
    }
    return retval;
}

export const loadingFavoritesList = isLoading =>({
    type: FAVORITE_LOADING,
    isLoading
});

export const saveFavoriteList = favorite => ({
    type: FAVORITES_UPDATE,
    favorite
});

export const deleteFavoriteList = favoriteId => ({
    type: FAVORITES_DELETE,
    favoriteId
});

export const createFavoriteList = favorite => ({
    type: FAVORITES_CREATE,
    favorite
});

export const readFavoriteList = favorite => ({
    type: FAVORITES_READ,
    favorite
});

export const errorFavoriteList = err => ({
    type: FAVORITE_ERROR,
    err
});

export const successFavoriteList = success => ({
    type: FAVORITE_SUCCESS,
    success
});

// CREATE
export const createFavorites = (bookmark, callback) => {
    return (dispatch) => {
        dispatch(loadingFavoritesList(true));
        Api.createFavorites(bookmark).then(
            result =>{
                dispatch(loadingFavoritesList(false));
                dispatch(createFavoriteList({
                    id: result.id,
                    name: result.name,
                    users: result.users
                }))
                if(typeof callback === 'function')
                    callback(result);
            }).catch(err => {
            dispatch(loadingFavoritesList(false));
            dispatch(errorFavoriteList(err))
        })
    }
}

// READ
export const fetchFavorites = () => {
    return (dispatch) => {
        dispatch(loadingFavoritesList(true));
        Api.getFavorites().then(
            result => {
                const list = result.sort( (a,b) => {
                    if (a.combined) {
                        return -1;
                    }
                    if (b.combined) {
                        return 1;
                    }
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (b.name < a.name) {
                        return 1;
                    }
                    return 0;
                })
                dispatch(readFavoriteList(list));
                dispatch(loadingFavoritesList(false));
            }).catch(err => {
            dispatch(loadingFavoritesList(false));
            dispatch(errorFavoriteList(err))
        })
    }
}

// UPDATE
export const saveFavorites = (bookmarkId, bookmark, callback) => {
    const bookmarkIds = bookmark.users.map(user => (user.id)? user.id : user)
    const bookmarkIdsx = bookmarkIds.filter((value, index, self) => { 
        return self.indexOf(value) === index;
    });
    // console.log('Help',{...bookmark, users: bookmarkIdsx});
    return (dispatch) => {
        dispatch(loadingFavoritesList(true));
        Api.searchFavorite(bookmarkId, {...bookmark, users: bookmarkIdsx}).then(
            result =>{
                dispatch(loadingFavoritesList(false));
                dispatch(saveFavoriteList({
                    id: result.id,
                    name: result.name,
                    users: result.users
                }))
                if (typeof callback === 'function')
                    callback(result);
            }).catch(err => {
            dispatch(loadingFavoritesList(false));
            dispatch(errorFavoriteList(err))
        })
    }
}

// UPDATE_SEVERAL
export const saveSeveralFavorites = (bookmarks) => {
    return (dispatch) => {
        dispatch(loadingFavoritesList(true));
        const promises = [];
        for (const bookmark of bookmarks) {
            const users = bookmark.users.map(user => (user.id)? user.id : user);
            const promise = Api.saveFavorites(bookmark.id, {...bookmark, users}).then(
                result => {
                    dispatch(loadingFavoritesList(false));
                    dispatch(saveFavoriteList({
                        id: result.id,
                        name: result.name,
                        users: result.users
                    }))
                }
            );
            promises.push(promise);
        }
        Promise.all(promises).then(
            result =>{
                fetchFavorites();
            }).catch(err => {
            dispatch(loadingFavoritesList(false));
            dispatch(errorFavoriteList(err))
        })
    }
}


// DELETE
export const deleteFavorites = (bookmarkId, refetch) => {
    return (dispatch) => {
        dispatch(loadingFavoritesList(true));
        const promise = Api.deleteFavorites(bookmarkId).then(
            () =>{
                // console.log("DELETE", result)
                if (refetch) {
                    // get the favorites after deletion
                    Api.getFavorites().then(
                        result => {
                            const list = result.sort( (a,b) => {
                                if (a.combined) {
                                    return -1;
                                }
                                if (b.combined) {
                                    return 1;
                                }
                                if (a.name < b.name) {
                                    return -1;
                                }
                                if (b.name < a.name) {
                                    return 1;
                                }
                                return 0;
                            })
                            dispatch(readFavoriteList(list));
                            dispatch(loadingFavoritesList(false));
                        }).catch(err => {
                        dispatch(loadingFavoritesList(false));
                        dispatch(errorFavoriteList(err))
                    })
                } else {
                    dispatch(loadingFavoritesList(false));
                    dispatch(deleteFavoriteList(bookmarkId));
                }
            }).catch(err => {
            dispatch(loadingFavoritesList(false));
            dispatch(errorFavoriteList(err))
        })
    }
}
