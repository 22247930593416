import React from 'react'
import TopNavigation from "../../shared/topNavigation/TopNavigation";
import './style.scss'
import ListHeader from './ListHeader'
import PaginationNew from '../../shared/pagination/PaginationNew';
import Api from '../../../modules/Api';
import Format from '../../../modules/utils/Format';
import PleaseWaitWhite from '../../shared/pleaseWait/PleaseWaitWhite';

class PublicRecord extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            orders: {
                networkType: 'desc',
                socialHandle: 'desc',
                status: 'desc',
                requestAgentName: 'desc',
                lastCheckedDate: 'desc',
                createdOn: 'desc',
                updatedOn: 'desc'
            },
            titles: {
                networkType: 'Network Type',
                socialHandle: 'Social Handle',
                status: 'Status',
                requestAgentName: 'Requested By Agent',
                lastCheckedDate: 'Last Checked Date',
                createdOn: 'Created Date',
                updatedOn: 'Last Status Updated Date'
            },
            sortBy: 'updatedOn',
            pageLimit: 10,
            currentPage: 1,
            totalCountLimited: 0,
            records: [],
            isLoading: true
        };
    }

    componentDidMount() {
        this.getRecords()
    }

    getRecords = () => {
        const {orders, sortBy, pageLimit, currentPage } = this.state;
        Api.getPublicRecord(orders[sortBy] === 'desc', pageLimit, currentPage, sortBy).then(record => {
            const { content, totalElements } = record;
            this.setState({records: content, totalCountLimited: totalElements, isLoading: false})
        })
    }

    sortFunction = sortBy => {
        const { orders } = this.state;
        orders[sortBy] = orders[sortBy] === 'desc' ? 'asc' : 'desc'
        this.setState({sortBy, orders, isLoading: true}, this.getRecords)
    }

    handlePageChange = currentPage => {
        this.setState({currentPage, isLoading: true}, this.getRecords)
    }

    handlePageLimitChange = pageLimit => {
        this.setState({pageLimit, currentPage: 1, isLoading: true}, this.getRecords)
    }

    render () {
        const { orders, titles, sortBy, pageLimit, currentPage, totalCountLimited, records, isLoading } = this.state;
        return (
            <div className="publicRecord" ref={ e => {this.containerRef = e}}>
                <PleaseWaitWhite
                    show={isLoading}
                />
                <TopNavigation  />
                <div className="table">
                    <ListHeader
                        orders={orders}
                        sortBy={sortBy}
                        onChange={this.sortFunction}
                        titles={titles}
                    />
                    <div className="tableBody">
                        {records.map(item => {
                            return <div className="itemRow" key={item.id}>
                                <div className="network">{item.networkType || ''}</div>
                                <div className="handle">{item.socialHandle ? `@${item.socialHandle}` : ''}</div>
                                <div className="status">{item.status || ''}</div>
                                <div className="requestedBy">{item.requestAgentName || ''}</div>
                                <div className="lastChecked">{item.lastCheckedDate ? Format.formatDate(item.lastCheckedDate) : ''}</div>
                                <div className="createdOn">{item.createdOn ? Format.formatDate(item.createdOn) : ''}</div>
                                <div className="lastUpdated">{item.updatedOn ? Format.formatDate(item.updatedOn) : ''}</div>
                            </div>
                        })}
                    </div>
                    <PaginationNew
                        total={totalCountLimited || 0}
                        limit={pageLimit}
                        current={currentPage}
                        handlePageChange={this.handlePageChange}
                        limitChangeFunction={this.handlePageLimitChange}
                    />
                </div>
            </div>
        )
    }
}

export default PublicRecord