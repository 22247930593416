import React from 'react';
import './radiobox.scss';

export default class RadioBox extends React.Component {

    render() {
        let radioClass = "sharedRadio";

        let radioBox = "sharedRadioBox"
        if (this.props.selected) {
            radioClass += " selected";
            radioBox += " selected";
        }
        if (this.props.className) {
            radioBox += " ";
            radioBox += this.props.className;
        }
        if (this.props.disabled) {
            radioBox += " disabled";
        }
        const self = this;
        const clickHandler = function () {
            const f = self.props.clickFunction;
            if (typeof f === 'function') {
                f();
            }
        }
        return (
            <div className={radioBox} onClick={clickHandler}>
                <div>
                    <div className={radioClass}>
                        <div />
                    </div>
                </div>
                <div>
                    {this.props.children}
                </div>
            </div>
        )
    }
}