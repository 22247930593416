import React from 'react'
import './extended.scss'
import Profile from './Profile'
import Media from './media/Media'
import PastBrands from './pastBrands/PastBrands'
import RecentPosts from './recentPosts/RecentPosts'


// props
// - show
// - details

export default class Extended extends React.Component {

    constructor (props, context) {
        super(props, context);

        this.renderedNavigation = this.renderedNavigation.bind(this);
        this.switchContent = this.switchContent.bind(this);
        this.contentForKey = this.contentForKey.bind(this);

        this.menuPoints = [
            'Summary',
            'More Details',
            'Recent Posts',
            'Portfolio'
        ];

        this.state = {
            currentMenuPoint : -1,
            content: null
        }

        this.profileKey = 0;
        this.detailsKey = 1;
        this.recentKey = 2;
        this.mediaKey = 3;
    }

    switchContent(key) {
        if (key === this.state.currentMenuPoint) {
            return;
        }

        let newContent = this.contentForKey(key);

        this.setState({
            currentMenuPoint : key,
            content: newContent
        });
    }

    renderedNavigation(txt, key) {
        let className = '';
        if (key === this.state.currentMenuPoint) {
            className = "current";
        }

        let self = this;
        let clickHandler = function() {
            self.switchContent(key);
        }

        return (
            <div    className={className}
                    key={key}
                    onClick={clickHandler}
                    profile={self.props.details}
            >
                { txt }
            </div>
        )
    }

    contentForKey(key) {
        let content = null;
        if (key === this.profileKey) {
            content = <Profile
                profile = { this.props.details }
            />;
        } else if (key === this.detailsKey){
            content = <Profile
                profile = { this.props.details }
                more = {true}
            />;
        } else if (key === this.recentKey){
            content = <RecentPosts
                userNetworks = {this.props.details.userNetworks}
            />
        } else if (key === this.mediaKey){
            content = <div className="portfolio">
                <Media
                        mediaKit = {this.props.details.mediaKits }
                />
                <div className="separator"/>
                <PastBrands
                    profile={ this.props.details}
                />
            </div>

        }
        return content;
    }

    componentDidMount() {
        this.switchContent(this.profileKey);
    }

    render () {
        let className = "extended " +
            (this.props.show ? 'showing' : 'hidden');
        let menu = [];
        for (let i=0; i<this.menuPoints.length; i++) {
            menu.push(this.renderedNavigation(this.menuPoints[i], i))
        }
        return (
            <div className={className}>
                <div>
                    <div className="topBar">
                        { menu }
                    </div>
                </div>
                <div className="content">
                    { this.state.content}
                </div>
            </div>
        );
    }
}
