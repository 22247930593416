import React from 'react'
import Api from '../../../modules/Api'
import PleaseWaitPartial from '../../shared/pleaseWait/PleaseWaitPartial'
import ApiError from '../../shared/apiError/ApiError'
import FileUpload from '../../shared/fileUpload/FileUpload'

// props
// - profile
// - urlChangeFunction

const emptyPic = "/images/ic-camera-green.svg";

export default class ProfilePhoto extends React.Component {
    constructor(props, context) {
        super(props, context)

        this.state = {
            loading : false,
            version : `${  new Date()}`,
            fileUploadCounter : 1
        };
    }

    savePhoto = async (file) => {
        this.setState({loading: true});
        const reader = new FileReader();
        reader.readAsDataURL(file);
        const self = this;
        reader.onload = function (e) {
            const newUrl = this.result;
            self.setState({imgUrl: newUrl, imageClass: 'full'});
        }
        const imageUpload = await Api.uploadFile(
            file,
            false,
            (percentage) => this.setState({uploadProgressPercentage: percentage}),
            (fn) => this.setState({onUploadCancel: fn})
        );
        if (imageUpload && typeof imageUpload === 'object' && imageUpload.url) {
            this.props.changeUrlFunction(imageUpload.url);
            this.setState({
                loading: false,
            })
        }else {
            this.setState({loading: false, imgUrl: null})
            const apiError = <ApiError
                show
                cancelFunction = {()=> {
                    ReactDOM.render(null, document.getElementById('messageContainer'))
                }}
            />
            ReactDOM.render(apiError, document.getElementById('messageContainer'))
        }

    }

    static getDerivedStateFromProps(props, state) {
        const newState = {};
        if (props.profile) {
            if (props.profile.pictureUrl || state.imgUrl) {
                newState.pictureUrl = props.profile.pictureUrl;
                newState.imageClass = 'full';
            } else {
                newState.pictureUrl = emptyPic;
                newState.imageClass = 'empty';
            }
        }
        return newState;
    }

    render() {
        let {pictureUrl} = this.state;
        if (this.state.imgUrl) {
            pictureUrl = this.state.imgUrl;
        }
        if (!pictureUrl) {
            return null;
        }

        const click =  () => {
            this.setState({
                fileUploadCounter : this.state.fileUploadCounter + 1
            })
        }

        return (
            <div
                className="profilePhoto"
                onClick={click}
                ref={(node) => { this.containerNode = node}}
            >
                <div style={{position:'absolute'}}>
                    <FileUpload
                        accept=".jpg, .jpeg, .png, .svg"
                        handleSelectedFile ={this.savePhoto}
                        clickCounter={this.state.fileUploadCounter}
                    />
                </div>
                <PleaseWaitPartial
                    show={this.state.loading}
                    container={this.containerNode}
                />
                <div className={this.state.imageClass}>
                    <img src={pictureUrl} />
                </div>
            </div>
        )
    }
}