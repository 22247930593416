import React from 'react';
import classNames from 'classnames';
import TopNavigation from '../../shared/topNavigation/TopNavigation';
import './PermissionPage.scss';
import Toggle from '../../shared/toggle';
import ProfileHelper from '../../../modules/utils/ProfileHelper';
import { TALENT_MANAGER_STR, INFLUENCER_STR } from '../../../constants/authorities';
import Modal from 'react-modal';
import Api from '../../../modules/Api';

export default class EditPermission extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            permissions: {
                editInfo: props.inviteData.permissionsSet.editInfo,
                editSummary: props.inviteData.permissionsSet.editSummary,
                editRates: props.inviteData.permissionsSet.editRates,
                editPortfolio: props.inviteData.permissionsSet.editPortfolio,
                viewAssignment: props.inviteData.permissionsSet.viewAssignment,
                manageAssignment: props.inviteData.permissionsSet.manageAssignment,
            },
            editMode: false,
            showModal: false,
            profile: null,
        };
    }

    componentDidMount() {
        ProfileHelper.getCurrentLoginUserProfile()
            .then(profile => this.setState({profile}))
    }

  handlePermissionChange = (name, value) => {
      if (name === 'viewAssignment' && !value) {
          this.setState({
              permissions: {
                  ...this.state.permissions,
                  [name]: value,
                  manageAssignment: false,
              },
          });
          return;
      }
      if (name === 'declineAccept') {
          if (!this.state.permissions.viewAssignment) return;
          this.setState({
              permissions: {
                  ...this.state.permissions,
                  manageAssignment: value,
              },
          });
          return;
      }
      this.setState({
          permissions: {
              ...this.state.permissions,
              [name]: value,
          },
      });
  };

  handleEnableEdit = () => {
      this.setState({ editMode: true });
  };

  handleDisableEdit = () => {
      this.setState({ editMode: false });
  };

  handleOpenModal = (val) => {
      this.setState({ showModal: val });
  };

  handleCloseModal = () => {
      this.setState({ showModal: false });
  };

  handleSave = () => {
      Api.editPermissions(this.props.inviteData.id, this.state.permissions);
      this.handleDisableEdit();
  };

  handleRemoveTM = () => {
      Api.removeTM(this.props.inviteData.id).then(res => {
          this.handleDisableEdit();
          this.props.clearInvitationData();
      });
  };

  render() {
      const {
          editMode,
          permissions: { editInfo, editSummary, editRates, editPortfolio, viewAssignment, manageAssignment },
          profile
      } = this.state;
      const {
          tmData: { firstname, lastname, pictureUrl, email },
      } = this.props;
      const addExtraTop = profile && (profile.role === TALENT_MANAGER_STR || profile.role === INFLUENCER_STR) && !profile.accountVerified;
      return (
          <div className="permission-page">
              <div className={classNames("blue-header", {extraTop: addExtraTop})}>
                  <div className="contentDiv">
                      <div className="controlContainer">Manager Permission</div>
                  </div>
              </div>
              <div className="permission-container">
                  <div className="permission-content">
                      <h3>Talent Manager</h3>
                      <div className="permissions-container">
                          <div className="permission-block edit-premissions-block">
                              <div className="manager-info permission-list edit-permission">
                                  <div className="left">
                                      <div className="profile-pic">
                                          <img src={pictureUrl} alt="" />
                                      </div>
                                      <div className="profile-info">
                                          <div className="manager-name">{`${firstname} ${lastname}`}</div>
                                          <div className="manager-email">{email}</div>
                                      </div>
                                  </div>
                                  <div className="right">
                                      {!editMode ? (
                                          <span onClick={this.handleEnableEdit}>
                                              <img className="pencil" src="/images/ic-edit.svg" />
                                          </span>
                                      ) : (
                                          <div onClick={this.handleOpenModal} className="remove">
                                              Remove Talent Manager
                                          </div>
                                      )}
                                  </div>
                              </div>
                              <h3 className="permission-title">PROFILE</h3>
                              <div className="permission-row">
                                  <div className="permission-label">Edit Info & Summary</div>
                                  {editMode ? (
                                      <div className="checkbox-holder">
                                          <Toggle value={editInfo} handleChange={this.handlePermissionChange} name="editInfo" />
                                      </div>
                                  ) : (
                                      <div className={`toggle-label ${editInfo ? 'checked' : 'not-checked'}`}>{editInfo ? 'Allow' : 'Not Allow'}</div>
                                  )}
                              </div>
                              {/* <div className="permission-row">
                  <div className="permission-label">Edit Summary</div>
                  {editMode ? (
                    <div className="checkbox-holder">
                      <Toggle value={editSummary} handleChange={this.handlePermissionChange} name="editSummary" />
                    </div>
                  ) : (
                    <div className={`toggle-label ${editSummary ? 'checked' : 'not-checked'}`}>{editSummary ? 'Allow' : 'Not Allow'}</div>
                  )}
                </div> */}
                              <div className="permission-row">
                                  <div className="permission-label">Edit Rates</div>
                                  {editMode ? (
                                      <div className="checkbox-holder">
                                          <Toggle value={editRates} handleChange={this.handlePermissionChange} name="editRates" />
                                      </div>
                                  ) : (
                                      <div className={`toggle-label ${editRates ? 'checked' : 'not-checked'}`}>{editRates ? 'Allow' : 'Not Allow'}</div>
                                  )}
                              </div>
                              <div className="permission-row">
                                  <div className="permission-label">Edit Portfolio</div>
                                  {editMode ? (
                                      <div className="checkbox-holder">
                                          <Toggle value={editPortfolio} handleChange={this.handlePermissionChange} name="editPortfolio" />
                                      </div>
                                  ) : (
                                      <div className={`toggle-label ${editPortfolio ? 'checked' : 'not-checked'}`}>
                                          {editPortfolio ? 'Allow' : 'Not Allow'}
                                      </div>
                                  )}
                              </div>
                          </div>
                          <div className="permission-block edit-premissions-block">
                              <h3 className="permission-title">Opportunities </h3>
                              <div className="permission-row">
                                  <div className="permission-label">View Assignment</div>
                                  {editMode ? (
                                      <div className="checkbox-holder">
                                          <Toggle value={viewAssignment} handleChange={this.handlePermissionChange} name="viewAssignment" />
                                      </div>
                                  ) : (
                                      <div className={`toggle-label ${viewAssignment ? 'checked' : 'not-checked'}`}>
                                          {viewAssignment ? 'Allow' : 'Not Allow'}
                                      </div>
                                  )}
                              </div>
                              <div className="permission-row">
                                  <div className="permission-label">
                                      <div>Manage Assignment:</div>
                                      <div style={{ fontWeight: 'normal' }}>Accept, Negotiate and Decline</div>
                                      <div style={{ fontWeight: 'normal' }}>Sign Contract</div>
                                      <div style={{ fontWeight: 'normal' }}>Submit content</div>
                                  </div>
                                  {editMode ? (
                                      <div className="checkbox-holder">
                                          <Toggle value={manageAssignment} handleChange={this.handlePermissionChange} name="manageAssignment" />
                                      </div>
                                  ) : (
                                      <div className={`toggle-label ${manageAssignment ? 'checked' : 'not-checked'}`}>
                                          {manageAssignment ? 'Allow' : 'Not Allow'}
                                      </div>
                                  )}
                              </div>
                          </div>
                          <div className="permission-block permission-legal">
                              By selecting Allow, the creator warrants that he/she is authorizing the designated Talent Manager to act on his/her
                              behalf and agrees to be bound by any decisions made by the Talent Manager.
                          </div>
                      </div>
                      <div className={classNames('manager-card-bottom permission-bottom', editMode && 'editMode')}>
                          {editMode && (
                              <>
                                  <button onClick={this.handleDisableEdit} className="button cancel">
                                      Cancel
                                  </button>
                                  <button onClick={this.handleSave} className="button">
                                      Save
                                  </button>
                              </>
                          )}
                      </div>
                  </div>
              </div>
              <Modal
                  isOpen={this.state.showModal}
                  overlayClassName="invite-modal-overlay"
                  className="invite-modal permission-page agentAdmin new-user-form"
                  onRequestClose={this.handleCloseModal}
                  shouldCloseOnOverlayClick
                  ariaHideApp={false}
              >
                  <div className="modal-header">Remove Talent Manager</div>
                  <div className="inputPanel">
                      <h3>Are you sure?</h3>
                  </div>
                  <div className="manager-card-bottom new-user-form-bottom">
                      <button onClick={this.handleCloseModal} className="button cancel">
                          Cancel
                      </button>
                      <button onClick={this.handleRemoveTM} className="button">
                          Remove
                      </button>
                  </div>
              </Modal>
          </div>
      );
  }
}
