import React from 'react';
import cx from 'classnames';
import Select from 'react-select';
import PropTypes from 'prop-types';
import './PaginationNew.scss';
import PendoHelper from '../../../modules/utils/PendoHelper';

const pageLimitOptions = [
    { value:'10', label: '10 per page'},
    { value:'20', label: '20 per page'},
    { value:'50', label: '50 per page'}
];

export default class PaginationNew extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.handlePageChange = this.handlePageChange.bind(this);
    }

    handlePageChange(e) {
        const { parentContainer } = this.props;
        if (e.target.className.indexOf("disabled") < 0) {
            const currentPage = Number(e.target.id);
            // Add pendo event when using pagination
            PendoHelper.pendoTrack('Discover_Pagination_Select', { pageNumber: currentPage });

            const f = this.props.handlePageChange;
            if (typeof f === "function") {
                f(currentPage);
            }
            if (parentContainer) {
                parentContainer.scrollTo(0, 0);
            } else {
                window.scrollTo(0,0);
            }
        }
    }

    setLimit = (option) => {
        let limit=option.value;
        
        // Add pendo event when using pagination
        PendoHelper.pendoTrack('Discover_Pagination_ShowAlteration', { showNumber: limit });
        
        if (typeof limit === 'string') {
            limit = Number(limit);
        }
        this.props.limitChangeFunction(limit)
    }

    render() {
        const { total, limit, current, pageNumberLimit, options } = this.props;
        // greater than minimum required number in a page, then no need to show
        if (total > 10) {
            const hidePaginationPart = (total < limit);
            const pageNumbers = [];
            const totalPages = Math.ceil(total / limit);
            let startPage = current < pageNumberLimit ? Math.floor((current-1)/pageNumberLimit) * pageNumberLimit  + 1 : Math.floor((current)/pageNumberLimit) * pageNumberLimit;
            if (current % pageNumberLimit === 0) {
                startPage = current;
            }

            const endPage = current < pageNumberLimit ? Math.min(startPage + 10, totalPages + 1) : Math.min(startPage + 11, totalPages + 1)

            for (let i = startPage; i < endPage; i++) {
                pageNumbers.push(i);
            }

            const renderPageNumbers = pageNumbers.map(number => {
                return (
                    <li
                        key={number}
                        id={number}
                        className={(number === current) ? "current" : "pageNumber"}
                        onClick={this.handlePageChange}
                    >
                        {number}
                    </li>
                );
            });
            return (<div className="pageNav">
                <div className="limitOption"><span>Show</span>
                    <Select className="select"
                        clearable={false}
                        searchable={false}
                        value={limit}
                        single
                        placeholder="Page size"
                        options={options || pageLimitOptions}
                        onChange={this.setLimit}
                    />
                </div>
                {!hidePaginationPart && 
                <>
                    <div className="separator" />
                    <ul>
                        {total > 1  &&
                        <li key="prev" id={current - 1} className={`prevButton pageNav${  current === 1 ?" disabled":""}`} onClick={this.handlePageChange}>
                            Previous
                        </li>
                        }
                        {renderPageNumbers}
                        {total > 1 &&
                        <li key="next" id={current + 1} className={`nextButton pageNav${  current === totalPages ?" disabled":""}`} onClick={this.handlePageChange}>
                            Next
                        </li>
                        }
                    </ul>
                </>
                }
            </div>);

        } 
        return null;
        

    }
}

PaginationNew.propTypes = {
    handlePageChange: PropTypes.func.isRequired,
    limitChangeFunction: PropTypes.func.isRequired,
    limit: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    current: PropTypes.number.isRequired,
    pageNumberLimit: PropTypes.number,
}

PaginationNew.defaultProps = {
    handlePageChange: null,
    limitChangeFunction: null,
    limit: 0,
    total: 0,
    current: 0,
    pageNumberLimit: 10,
}