import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import './apiError.scss';
import BreakOutOfDom from '../modalWithSingleAction/BreakOutOfDom';
import { SHOW_UI_DETAILS_IN_ERROR_POPUP } from '../../../constants/uiConfig';

// Props :
// - show
// - error  (eliminated for now as it is not pretty, Sep 19 2018
// - cuteMessage (defaults to 'Please try again later')
// - cancelFunction

const FORCE_OVERRIDE_ERROR_CODES = [504]

export default class ApiError extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.close = this.close.bind(this);

        this.state = {
            expandDetails: false,
        };
    }

    close() {
        const f = this.props.cancelFunction;
        if (typeof f === 'function') {
            f();
        }
    }

    render() {
        const {errTitle, error, show}=this.props;
        const {expandDetails} = this.state;
        const defaultMessage = 'Oops, please try again!';
        if (!show) {
            return null;
        }
        const message = [];
        if (error) {
            if (typeof error === 'string') {
                message.push(error);
            } else {
                // show the message if error doesn't have displayErrorMessage
                const errorCode = error && error.status ? error.status : 0;

                if (!error.message || FORCE_OVERRIDE_ERROR_CODES.includes(errorCode)) {
                    switch (errorCode) {
                    // case 0: // no status code in error
                    case 400:
                        message.push("Bad request due to invalid data.");
                        break;
                    case 401:
                        message.push("Unauthorized. Please contact your admin.");
                        break;
                    case 403:
                        message.push("Looks like you may not have the permissions. Please contact your admin.");
                        break;
                    case 404:
                        message.push("We couldn't find the page you’re looking for.");
                        break;
                    case 405:
                        message.push("The request method is not supported.");
                        break;
                    case 500:
                        message.push("The server has encountered an error.");
                        break;
                    case 504:
                        message.push("System is not able to process the report at this time. Please try again later. If issue is persisting, please contact Koalifyed support");
                        break;
                    default:
                        message.push(defaultMessage);
                        break;
                    }
                } else {
                    message.push(error.message);
                }
            }
            console.log(`message: ${  message  }. [${  error  }]`);
        } else {
            // default error message if no error
            message.push(defaultMessage);
        }
        const errorCode = error && error.status ? error.status : 0;
        const specialError = errorCode === 400 && error.originalMessage && error.originalMessage.includes("updating data")
        const specialError2 = error.exception === "com.stagwelltech.thunder.exception.GeneralCaseRuntimeException"
        return (
            <BreakOutOfDom>
                <div className="apiError">
                    {(!specialError && !specialError2) ? <div className="messageWindow">
                        <img src="/images/error.svg" alt=""/>
                        <div className="title">
                            {errTitle && errTitle.trim() ? errTitle.trim() : 'Error!'}
                        </div>

                        <div className="message">
                            {message}
                        </div>

                        {
                            SHOW_UI_DETAILS_IN_ERROR_POPUP && error && error.originalMessage &&
                            <>
                                <div className="moreDetailsTitle" onClick={() => this.setState({expandDetails: !this.state.expandDetails})}>details</div>
                                <div className="moreDetailsContainer">
                                    <div className={cx("moreDetails", {"expandDetails": expandDetails})}>
                                        {error.originalMessage}
                                    </div>
                                </div>
                            </>
                        }

                        <div className="button" onClick={this.close}>
                            OK
                        </div>
                    </div> : 
                        <div className="messageWindow">
                        <img src="/images/warning.svg" alt="warning"/>
                        <div className="warningTitle">
                                {specialError ? "Updating data" : "System Error"}
                            </div>

                        <div className="message">
                            {specialError ? "We are updating data for this account. Please try again in a few minutes." : "We apologize for the inconvenience, but an unexpected error has occurred. Please try again later. Thank you for your patience and understanding."}
                        </div>

                        <div className="warningButton" onClick={this.close}>
                            Ok
                        </div>
                    </div>
                    }
                </div>
            </BreakOutOfDom>
        )
    }
}

ApiError.propTypes = {
    show: PropTypes.any,
    error: PropTypes.any,
    cancelFunction: PropTypes.func,
    errTitle: PropTypes.string,
};

