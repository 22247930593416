import React, { useEffect, useState, useLayoutEffect } from 'react';
import './addToActions.scss';
import cx from 'classnames';

const AddToActions = ({
    data = [],
    onActions = () => null,
    reference = null
}) => {
    const onActionClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
    };

    return (
        <div className='addToActionsContainer' onClick={onActionClick} ref={reference}>
            {data.map((item, index) => (
                item === 'divider' ? <div key={`addToActions ${item.key} ${index}`} className="divider" /> :
                    <div
                        key={`addToActions ${item.key} ${index}`}
                        onClick={(e) => onActions(item.key, e)}>
                        {item.label}
                    </div>
            ))}
        </div>
    )
};

export default AddToActions;