import React from "react";
import "./recentLists.scss"

export default class RecentList extends React.PureComponent {

    render() {
        const { name, list, history, searchableOptions, countryList } = this.props;
        const links = {"Saved searches": "/savedsearches", "Plans": "/plans", "Campaigns": "/campaign", "Posts": "/posts"}
        const savedSearchFunc = (e, index) => {
            e.stopPropagation();
            e.preventDefault();
            const data = Object.assign(list[index]?.param, {searchableOptions: _.isEmpty(searchableOptions) ? null : searchableOptions});
            history.push({
                pathname: `/influencers`,
                state: {
                    search: {
                        type: list[index].param.type ?? 'PUBLIC',
                        ...data
                    }
                }
            });
        }
        return (
            <div className="recentList">
                <div className="titleSection">
                    <div className="title">{name}</div>
                    {name !== "Posts" && <a className="link" href={links[name]}>View all</a>}
                </div>
                {list.length === 0 && <div className="noList">
                    <img src="/images/Graphic_Blank.svg" alt="blank image" />
                    <div className="noListTitle">No {name}</div>
                    <div className="noListDesc">It doesn’t look like you have any {name}.</div>
                </div>}
                {list.length > 0 && list.map((item, index) => {
                    if (index >= 8) {
                        return null;
                    }
                    switch (name) {
                    case "Saved searches":
                        return <div className="savedSearchHome" key={`saved${  index}`} onClick={e => savedSearchFunc(e, index)}>
                            <div className="savedSearchName">{item.name}</div>
                            <div className="savedSearchItem">{item.keywords > 0 && `Keywords:(${item.keywords})`}
                                {item.keywords > 0 && item.filters > 0 && ' - '}{item.filters > 0 && `Filters:(${item.filters})`}</div>
                            {item.isNew && <div className="newTag">
                                <div className="circle" />
                                <div>new creators</div>
                            </div>}
                        </div>
                    case "Plans":{
                        const countryLabel = countryList.find(x => x.value === item.brand.countryCode)?.label
                        return <a className="itemBox" href={`/plans/${item.id}`} key={`plan${  index}`}>
                            <div className="leftSection">
                                <div className="brand">{item.brand.brandName} - {countryLabel}</div>
                                <div className="name">{item.name}</div>
                            </div>
                            {item.brand.brandImage && <img src={item.brand.brandImage} alt="brand icon" />}
                        </a>
                    }
                    case "Campaigns":{
                        const countryLabel = countryList.find(x => x.value === item.agencyBrand.countryCode)?.label
                        return <a className="itemBox" href={`/campaign/${item.id}`} key={item.id}>
                            <div className="leftSection">
                                <div className="brand">{item.agencyBrand.parentBrand.brandName} - {countryLabel}</div>
                                <div className="name">{item.name}</div>
                                {item.assignments.length > 0 && <div className="assignments">{item.assignments.length} assignments</div>}
                            </div>
                            {item.agencyBrand.brandLogo && <img src={item.agencyBrand.brandLogo} alt="brand icon" />}
                        </a>
                    }
                    case "Posts":
                        const titleItems = ["blog", "facebook", "twitter", "share"];
                        const imageIcons = {blog: "/images/Blog.svg", instagram: "/images/IG.svg", igStories: "/images/IG Stories.svg",
                            facebook: "/images/FB.svg", twitter: "/images/rate-twitter.svg", share: "/images/rate-share.svg",
                            youtube: "/images/rate-video.svg", tiktok: "/images/ic-rate-tiktok.svg"}
                        return <a className="postBox" key={item.postId} href={`/assignment/${item.assignmentId}/post/${item.postId}`}>
                            <div className="postTitle">
                                {item.assignmentName}
                            </div>
                            <div className="bottomSection">
                                <img className="socialIcon" src={imageIcons[item.networkType]} alt="social media icon" />
                                {item.userPictureUrl 
                                    ? <img className="pfp" src={item.userPictureUrl} alt="img" />
                                    : <div className="filler">{item.name[0]}</div>
                                }
                                <div>{item.name}</div>
                            </div>
                        </a>
                    default:
                        return null
                    }
                })}
            </div>
        );
    }
}
