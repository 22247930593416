import React from 'react'
import './settingsMenu.scss'
import {PASSWORD, SHIPPING}  from './menuPoints'

const menu = {};
menu[PASSWORD] = 'Email / Password';
menu[SHIPPING] = 'Shipping / Payment Info'

/*
    props
    - selectionFunction  (takes PASSWORD or SHIPPING
 */

export default class SettingsMenu extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {};
    }

    renderedMenuPoint = (point) => {

        let clickHandler = () => {
            this.select(point);
        }

        let className = (this.state.selected == point) ? "selected" : "";

        return (
            <div key={point}
                 className={className}
                 onClick={clickHandler}
            >
                { menu[point]}
            </div>
        )
    }

    select = (point) => {
        this.setState({
            selected : point
        })
        this.props.selectionFunction(point);
    }

    componentDidMount() {
        this.select(PASSWORD);
    }

    render() {
        let points = [];
        for (let point in menu) {
            points.push(this.renderedMenuPoint(point));
        }
        return (
            <div className="menu">
                { points }
            </div>
        )
    }
}