import React, { useState, useRef } from 'react';
import cx from 'classnames';
import './radioCards.scss';

export const RadioCards = ({
    data,
    selected,
    onChange = () => null
}) => {
    return (
        <div className='radioCardsContainer'>
            {data && data.length > 0 && data.map((item, index) => {
                const active = index === selected;
                const disabled = item.disabled
                return (
                    <div key={item.title} className={cx('cardContainer', { active }, {disabled})} onClick={(active || disabled) ? null : () => onChange(index, item)}>
                        <div className={cx('radioButton', { active })} />
                        <div className='infoContainer'>
                            {item.title}
                            <span>{item.message}</span>
                        </div>
                    </div>
                )
            })}
        </div>
    )

}