import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import get from 'lodash/get';
import FileSaver from 'file-saver';
import services from './services';
import constants from './constants';
import validationSchema from './validationSchema';
import Spinner from '../../../shared/contract/spinner';
import style from './styles.module.scss';
import Api from '../../../../modules/Api';
import Header from './components/Header';
import Footer from './components/Footer';
import BillingSection from './components/BillingSection';
import RatesSection from './components/RatesSection';
import Format from '../../../../modules/utils/Format';

class PaymentModal extends Component {
    state = {
        rates: [],
        isEmailEditable: false,
        email: '',
        emailWarning: '',
        isLoading: false,
    }

    componentDidMount = async () => {
        const {
            post: {
                uid,
                assignmentId,
                userFirstName,
                userLastName,
                userPictureUrl,
                userId,
                assignmentPublicUserId
            },
            updateEmail
        } = this.props;
        this.setState({ isLoading: true });
        try {
            const { campaign } = this.props;
            const response = assignmentPublicUserId ? await Api.getAssignmentUserPublic(assignmentId, assignmentPublicUserId) : await Api.getAssignmentUser(assignmentId, uid);
            const rates = get(response, 'rates');
            const convertedRates = services.ratesFactory(rates);
            if (updateEmail) {
                updateEmail(get(campaign, 'agencyBrand.billingContractEmail'))
            }
            this.setState({
                rates: convertedRates,
                influencerProfile: {
                    userFirstName,
                    userLastName,
                    userPictureUrl,
                    userId,
                },
                email: get(campaign, 'agencyBrand.billingContractEmail'),
                isLoading: false,
                campaignBrandId: Number(get(campaign, 'agencyBrand.brandId')),
            });
        } catch (error) {
            this.setState({ isLoading: false });
            console.log(error);
        }
    }

    onEditEmail = () => {
        this.setState({ isEmailEditable: true });
    };

    onHandleChange = ({ target }) => {
        const { EMAIL_WARNING_MESSAGE } = constants;
        const { validateEmail } = validationSchema;
        const isEmailValid = validateEmail(target.value);
        this.setState({
            [target.name]: target.value,
            emailWarning: isEmailValid ? undefined : EMAIL_WARNING_MESSAGE,
        });
    };

    onSaveNewEmail = async () => {
        const { email, campaignBrandId } = this.state;
        const { campaign, onUpdateCampaign, updateEmail } = this.props;
        try {
            await Api.updateBrandAddress(campaignBrandId, { billingContractEmail: email })
            this.setState({
                isEmailEditable: false,
            })
            const updatedCampaign = {
                ...campaign,
                agencyBrand: {
                    ...campaign.agencyBrand,
                    billingContractEmail: email,
                }
            };
            if (updateEmail) {
                updateEmail(email)
            }
            onUpdateCampaign(updatedCampaign);
        } catch (error) {
            this.setState({ isLoading: false });
            console.log(error);
        }
    };

    exportFormHandler = () => {
        const { post } = this.props;
        Api.getInfluencerWFormUrl(post.userId).then(res => {
            if(res && res.url) {
                const updateFileProgress = (progressPercentage) => {
                    if (progressPercentage > 0) {
                        this.setState({downloadProgressPercentage: progressPercentage})
                    }
                };

                const updateFileFinished = (response) => {
                    // handle old data with no name & extensions
                    const ext = response.data && response.data.type ? Format.mimeToExt(response.data.type) : '';
                    const fileName = res.name==='File' ? `File${ext}` : res.name;
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName); // or any other extension
                    document.body.appendChild(link);
                    link.click();
                    this.setState({ downloadProgressPercentage: null, retrievalInProgress: false });
                };

                updateFileProgress(1);
                // Call gradual download
                Api.getS3FileGradually(res.url,
                    (progressPercentage) => updateFileProgress(progressPercentage),
                    (response) => updateFileFinished(response));

            }else{
                this.setState({retrievalInProgress: false, downloadProgressPercentage: null})
            }
        })
        .catch(err => {
            this.setState({retrievalInProgress: false, downloadProgressPercentage: null})
        })
    }

    render() {
        const {
            onSubmit,
            onCloseModal,
            post
        } = this.props;
        const {
            rates,
            influencerProfile,
            isEmailEditable,
            email,
            emailWarning,
            isLoading,
        } = this.state;

        const { calculateTotalAmount } = services;
        const totalAmount = calculateTotalAmount(rates);
        return (
            isLoading ? (
                <Spinner />
            ) : (
                <Modal
                    overlayClassName={style.overlay}
                    className={style.modal}
                    isOpen
                >
                    <div className={style.cover}>
                        <Header title='Payment' />
                        <div className={style.content}>
                            <BillingSection
                                onEditEmail={this.onEditEmail}
                                isEmailEditable={isEmailEditable}
                                onHandleChange={this.onHandleChange}
                                influencerEmail={email}
                                emailWarningMessage={emailWarning}
                                onSaveEmail={this.onSaveNewEmail}
                            />
                            <RatesSection
                                influencerProfile={influencerProfile}
                                totalAmount={totalAmount}
                                rates={rates}
                                isPublic={post.assignmentPublicUserId}
                            />
                        </div>
                        <Footer assignmentPublicUserId={post.assignmentPublicUserId} onCancel={onCloseModal} onSubmit={onSubmit} onFormExport={post.wformUploaded ? this.exportFormHandler : null}/>
                    </div>
                </Modal>
            )
        )
    }
}

PaymentModal.propTypes = {
    post: PropTypes.shape({
        uid: PropTypes.string,
        assignmentId: PropTypes.number,
        userFirstName: PropTypes.string,
        userLastName: PropTypes.string,
        userPictureUrl: PropTypes.string,
        userEmail: PropTypes.string,
        userId: PropTypes.number,
    }),
    onCloseModal: PropTypes.func,
    onSubmit: PropTypes.func,
}

export default PaymentModal;
