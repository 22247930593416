import Api from '../../../../modules/Api';

export const mapValuesToSelectOptions = (values = []) => {
    return values.map((item) => ({ label: item.label, value: item.id }));
};

export const getAsyncFilterOptions = async ({ filterId, keyword, selectedOptions, filter, setFilterOptions }) => {
    let result = [];
    try {
        const response = await Api.searchFilterOptions({ filterId, keyword });
        result = mapValuesToSelectOptions(response)
            // Remove selected options from available options
            .filter((filterOption) => !selectedOptions.find((selectedOption) => selectedOption.value === filterOption.value));

        // Add filter options to display active searchable filter label in header tags
        const newFilterOptions = response.filter(
            (responseItem) => !filter.filterOptions.find((filterOption) => filterOption.id === responseItem.id),
        );
        setFilterOptions([...filter.filterOptions, ...newFilterOptions])
    } catch (error) {
        console.error(error);
    }

    return { options: result };
};
