import React from 'react'
import ContentEditable from 'react-contenteditable'

import './textInput.scss'

// props
// - itemText
// - changeFunction (takes new text value)
// - placeholder
// - startWithFocus (flag)
// - blurFunction
// - validateFunction ( string => boolean )
// - invalidMessage

export default class TextInput extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            itemText : '',
            lastValud : ''
        }

        this.errorTimeout = null;
    }

    componentDidMount() {
        this.setState({
            itemText : this.props.itemText
        })
        if (this.props.startWithFocus) {
            const editableElement = this.container.getElementsByTagName('div')[0];
            if (!this.props.showInput && editableElement) {
                editableElement.focus();
            } else if (this.props.showInput) {
                const inputElement = this.container.getElementsByTagName('input')[0];
                if (inputElement) {
                    inputElement.focus();
                }
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.itemText !== this.state.itemText) {
            this.setState({
                itemText : this.props.itemText,
                lastValid : this.props.itemText
            })
        }
    }

    handleTextChange = (e) => {
        const newValue = e.target.value;

        if ( typeof this.props.validateFunction === 'function') {
            if (!this.props.validateFunction(newValue)) {
                clearTimeout(this.errorTimeout);
                this.errorTimeout = setTimeout( () => { this.setState({showError:false})}, 5000);
                const editableElement = this.container.getElementsByTagName('div')[0];
                if (editableElement) editableElement.innerText = this.state.lastValid;
                this.setState({
                    itemText : this.state.lastValid || '',
                    showError : true
                })
                return;
            }
        }
        this.setState({
            itemText: newValue,
            lastValid : newValue
        }, () => {
            this.props.changeFunction(newValue);
        });
    }

    handleChange = (e) => {
        if (this.props.disableSpace && this.state.keyCode === 32) return

        const newValue = e.target.value;
        if ( typeof this.props.validateFunction === 'function') {
            if (!this.props.validateFunction(newValue)) {
                clearTimeout(this.errorTimeout);
                this.errorTimeout = setTimeout( () => { this.setState({showError:false})}, 5000);
                this.setState({
                    itemText : this.state.lastValid,
                    showError : true
                })
                return;
            }
        }
        this.setState({
            itemText: newValue,
            lastValid : newValue
        });
        this.props.changeFunction(newValue);
    }

    handleKeyDown = (e) => {
        if (e.which === 13 && typeof this.props.blurFunction === 'function') {
            this.props.blurFunction(e)
        }
        this.setState({
            keyCode: e.which
        })
    }

    render() {
        if (typeof this.state.itemText !== 'string') {
            return null;
        }
        const self = this;

        let blurFunction = function(){};
        if (typeof this.props.blurFunction === 'function') {
            blurFunction = this.props.blurFunction;
        }

        const getFocus = function(){
            const editableElement = self.container.getElementsByTagName('div')[0];
            if (!self.props.showInput && editableElement) editableElement.focus();
        }
        return (
            <div className="assignmentTextInput" ref={ e => {this.container = e}} style={{position:'relative'}}>
                {
                    !this.props.showInput ?
                        <ContentEditable
                            html={this.state.itemText} // innerHTML of the editable div
                            disabled={this.props.disabled}       // use true to disable edition
                            onChange={this.handleTextChange} // handle innerHTML change
                            placeholder={this.props.placeholder}
                            onBlur={blurFunction}
                            onMouseOver={getFocus}
                        />
                        :
                        <input
                            value={this.state.itemText}
                            disabled={this.props.disabled}
                            onKeyDown={this.handleKeyDown}
                            onChange={this.handleChange}
                            placeholder={this.props.placeholder}
                            onBlur={blurFunction}
                            onMouseOver={getFocus}
                        />}
                { this.state.showError &&
                    <div className="errorMessage">
                        { this.props.invalidMessage }
                    </div>
                }
            </div>
        )
    }
}
