import React from 'react'
import Lookup from '../../../../modules/Lookup'
import Age from '../Age'
import { displayProfileField } from '../../../../modules/influencer/ProfileFields'

import './about.scss'

// props
// - show
// - keys
// - profile

export default class AboutTabContent extends React.Component {
    constructor( props,context) {
        super(props.context);
    }

    displayField = (key) => {
        let label = Lookup.getFilterLabel(key.replace(/[A-Z]/g, u=>'_'+u.toLowerCase()));
        return (
            <div key={key} className="field">
                <div>
                    {label}:&nbsp;
                </div>
                <div>
                    {displayProfileField(key, this.props.profile)}
                </div>
            </div>
        )
    }

    render() {
        if (!this.props.show) {
            return null;
        }
        let keys = this.props.keys;
        let col1 = [];
        for (let key of keys) {
            let d = this.displayField(key);
            col1.push(d)
        }

        return (
            <div className="displayColumns">
                <div className="twoColumns">
                    { col1}
                </div>
            </div>
        )
    }
}

