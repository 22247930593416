import React from 'react'

import { aboutKeyList, moreKeyList } from '../../../../modules/influencer/ProfileFields'
import DisplayTabContent from './DisplayTabContent'
import EditAboutSections from './EditAboutSections'
import './profileInfo.scss'
import Summary from '../../../influencers/detailPage/influencerProfileBU/piecesInMain/Summary'


export default class ProfileInfo extends React.Component {
    constructor(props, context) {
        super(props.context);

        this.state = {
            selectedTab: 'about',
            displayedProfile: null,
            summaryMore: false,
        }
    }

    changeFunction = (newProfile) => {
        let displayedProfile = {};
        for (let ix in this.state.displayedProfile) {
            displayedProfile[ix] = this.state.displayedProfile[ix];
            if (newProfile[ix]) {
                displayedProfile[ix] = newProfile[ix];
            }
        }
        this.setState({ displayedProfile: displayedProfile });
        this.props.changeFunction(newProfile);
    }

    clickAbout = () => {
        this.setState({ selectedTab: 'about' })
    }

    clickMore = () => {
        this.setState({ selectedTab: 'more' })
    }

    static getDerivedStateFromProps(props, state) {
        let newState = {};

        if (props.editMode && !state.storedEditMode) {
            // going into edit mode, marking scroll position
            state.lastViewedScrollTop = props.getScrollTop();
        }
        if (!props.editMode && state.storedEditMode) {
            // restoring scroll position
            if (!isNaN(state.lastViewedScrollTop)) {
                props.setScrollTop(state.lastViewedScrollTop);
            }
        }

        // first load
        if (!state.profileLoaded && props.profile) {
            newState.displayedProfile = props.profile;
            newState.profileLoaded = true;
        }
        newState.storedEditMode = props.editMode;
        return newState;
    }

    createDisplay() {
        const { summaryMore, displayedProfile } = this.state;

        const keyLists = {
            about: ['age_readonly'].concat(aboutKeyList),
            more: moreKeyList,
        };

        return (
            <>
                <div className="pieceTitle">Summary</div>
                <div id="profileSummaryTop">
                    <Summary profile={displayedProfile} />
                </div>
                <hr />
                <div className="pieceTitle">About</div>
                <DisplayTabContent show={true} keys={keyLists['about']} profile={displayedProfile} />
                {summaryMore && (
                    <>
                        <hr />
                        <div className="pieceTitle">More details</div>
                        <DisplayTabContent show={true} keys={keyLists['more']} profile={displayedProfile} />
                    </>
                )}
                <div className="moreDetails" onClick={() => this.setState({ summaryMore: !summaryMore })}>
                    {summaryMore ? 'Less details' : 'More details'}
                </div>
            </>
        )
    }

    render() {
        if (!this.props.profile) {
            return null;
        }

        let tabClasses = {
            about: '',
            more: ''
        }
        tabClasses[this.state.selectedTab] = "selected";

        const keyLists = {
            'about': this.props.readOnlyAge ? ['age_readonly'].concat(aboutKeyList) : aboutKeyList,
            'more': moreKeyList
        }

        return (
            <div className="profileInfo" ref={(e) => { this.container = e; }}>
                {!this.props.editMode && this.createDisplay()}
                <EditAboutSections
                    show={this.props.editMode}
                    profile={this.props.profile}
                    changeFunction={this.changeFunction}
                    saveFlag={this.props.saveFlag}
                    editMode={this.props.editMode}
                    setSaveEnabled={this.props.setSaveEnabled}
                />
            </div>
        )

    }
}
