import { call, put, select } from 'redux-saga/effects';
import { defaultInfluencerPicture } from '../reducer';
import { setData } from '../actionCreators';
import { getInfluencerChannels, checkHasRequiredChannels } from '../helpers';
import beforeAddToCampaignSaga from './beforeAddToCampaignSaga';
import { SOCIAL } from '../../../../constants/channels';

export default function* selectionChangeSaga({ id, isSelected, runInvitation }) {
    const {
        influencers,
        inviteList,
        inappropriateInviteList,
        requiredAssignmentChannels,
    } = yield select(({ campaignReducer, general }) => ({
        influencers: campaignReducer.influencerSearch.influencers,
        inviteList: campaignReducer.influencerSearch.inviteList,
        inappropriateInviteList: campaignReducer.influencerSearch.inappropriateInviteList,
        requiredAssignmentChannels: general.assignmentInfo.channels,
    }));

    const {
        id: influencerId,
        firstname,
        lastname,
        pictureUrl,
        email,
        rates,
        userNetworks,
        uid,
    } = influencers.find(({ uid }) => uid === id);
    const invite = {
        id: influencerId,
        name: `${firstname} ${lastname}`,
        pictureUrl: pictureUrl || defaultInfluencerPicture,
        rates,
        uid,
        email,
        firstname,
        lastname,
        userNetworks
    };

    // TODO: remove filteredRequiredAssignmentChannels once Social Share connect is implemented
    /*
        As for now [2020-08-07] Social Share is a placeholder for the social network that is not on the list.
        Once the logic for connecting this channel is implemented - there will be no need in this filtration.
        As for now - it was selected on assignment creation, so it's on the requiredAssignmentChannels and
        will block any attempt to add any influencer (due to the channel is not connected and can't be).
     */
    const filteredRequiredAssignmentChannels = requiredAssignmentChannels.filter((channel) => channel !== SOCIAL);

    const influencerChannels = getInfluencerChannels(userNetworks);
    const hasRequiredChannels = checkHasRequiredChannels(filteredRequiredAssignmentChannels, influencerChannels);

    const updatedInviteList = { ...inviteList };
    const updatedInappropriateInviteList = { ...inappropriateInviteList };

    if (isSelected) {
        // add selected influencer to inviteList
        updatedInviteList[uid] = invite;

        // add influencer to inappropriateInviteList if he lacks required channels
        if (!hasRequiredChannels) {
            updatedInappropriateInviteList[uid] = invite;
        }
    } else {
        // remove unselected influencer from inviteList
        delete updatedInviteList[id];

        // check inappropriateInviteList and remove from there too
        if (Object.prototype.hasOwnProperty.call(updatedInappropriateInviteList, id)) {
            delete updatedInappropriateInviteList[id];
        }
    }

    yield put(setData({
        inviteList: updatedInviteList,
        inappropriateInviteList: updatedInappropriateInviteList,
    }));

    /*
        There is a case when influencer is invited directly with more menu
     */
    if (runInvitation) {
        yield call(beforeAddToCampaignSaga);
    }
}
