import React, { useState } from 'react';
import cx from 'classnames';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import './assetGallery.scss';

/**
 * Represents a list of files with their URLs and formats.
 * @typedef {Object} FileObject
 * @property {Array<{ original: string, format: string }>} files - Array of files with their URLs and formats.
 */

/**
 * @type {FileObject}
 */
export default function AssetGallery({ files }) {
    return (
        <ImageGallery
            renderItem={(asset) => <AssetItem asset={asset} />}
            renderLeftNav={(onClick) => <GalleryNavButton onClick={onClick} isLeft />}
            renderRightNav={(onClick) => <GalleryNavButton onClick={onClick} isRight />}
            items={files}
            showPlayButton={false}
            showFullscreenButton={false}
        />
    );
}

function AssetItem({ asset }) {
    const [loadError, setLoadError] = useState(false);

    if (asset.mediaType === 'VIDEO') {
        return (
            <video controls height={440} width="100%">
                <source src={asset.original} type="video/mp4" />
            </video>
        );
    }

    return (
        <img
            className={cx('asset-picture', loadError && 'no-picture')}
            src={loadError ? '/images/noImage.svg' : asset.original}
            onError={() => setLoadError(true)}
        />
    );
}

function GalleryNavButton({ onClick, isLeft, isRight }) {
    return (
        <button
            className={cx(
                'image-gallery-icon gallery-nav-btn',
                isLeft && 'image-gallery-left-nav left-nav',
                isRight && 'image-gallery-right-nav right-nav',
            )}
            onClick={onClick}
        >
            <GalleryNavIcon />
        </button>
    );
}

function GalleryNavIcon() {
    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="6 8.5 12 7">
            <g stroke="none" strokewidth="1" fill="none" fillrule="evenodd">
                <g>
                    <path
                        d="M12,15.5 L18,9.8333347 L16.5882347,8.5 L12,12.8333333 L7.41176544,8.5 L6,9.83333313 L12,15.5 Z"
                        fill="currentColor"
                    ></path>
                </g>
            </g>
        </svg>
    );
}
