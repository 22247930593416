import React from 'react'
import './style.scss'
import cx from "classnames";
import {connect} from "react-redux";
import UserRow from "./UserRow";
import PaginationNew from '../../../shared/pagination/PaginationNew'
import Api from "../../../../modules/Api";
import PleaseWait from "../../../shared/pleaseWait/PleaseWaitWhite";
import ApiError from "../../../shared/apiError/ApiError";
import ProfileHelper from '../../../../modules/utils/ProfileHelper';
import { BRAND_OWNER_STR, SUPER_ADMIN_STR } from '../../../../constants/authorities';

const debounce = (fn, delay) => {
    let timeoutId;
    return function(...args) {
        clearInterval(timeoutId);
        timeoutId = setTimeout(() => fn.apply(this, args), delay);
    };
};

class AllUsers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage : 1,
            pageSize : 10,
            searchKey: '',
            sortBy: 'name',
            totalCount: 0,
            usersList:[],
            nameSortDirection: 'desc',
            emailSortDirection: 'desc',
            profile: null,
            waitingForUsers: false,
        }
    }

    componentDidMount() {
        this.searchUsers();
        ProfileHelper.getCurrentLoginUserProfile()
            .then(profile => {
                this.setState({profile});
            })
        
    }

    flipSort = (sortBy) => {
        const newState = {};
        const fieldName = `${sortBy}SortDirection`;
        newState[fieldName] = this.state[fieldName] === 'desc' ? 'asc' : 'desc';
        newState.currentPage = 1;
        this.setState(newState, this.searchUsers)
    };

    setSortBy = (sortField) => {
        if (this.state.sortBy === sortField) {
            this.flipSort(sortField);
            return;
        }
        this.setState({
            sortBy : sortField,
            currentPage : 1
        }, this.searchUsers);
    };

    sortUsers = (arr) => {
        const {sortBy} = this.state;
        const fieldName = `${sortBy  }SortDirection`;
        return arr.sort((a, b) => {
            if (this.state[fieldName] === 'desc') {
                return a[sortBy] > b[sortBy] ? -1 : 1
            } 
            return a[sortBy] > b[sortBy] ? 1 : -1
            
        });
    };

    handleSearchChange = (e) => {
        this.setState({
            searchKey: e.target.value,
            currentPage : 1
        }, this.searchUsers);
    };

    showApiError = (error) => {
        this.setState({
            waitingForUsers: false,
            showApiError: true,
            apiError : error
        })
    };

    searchUsers = debounce(() => {
        const { searchKey } = this.state;
        this.setState({ waitingForUsers: true });

        Api.searchUserList(searchKey, this.createParamsForApi(this.state)).then(response => {
            this.setState({
                usersList: response.results,
                totalCount: response.meta.totalCount,
                waitingForUsers: false,
            })
        }).catch(err => {
            this.setState({ waitingForUsers: false });
            this.showApiError(err);
        });
    }, 500)

    createParamsForApi = (fields) => {
        const retval = {
            page : fields.currentPage,
            num : fields.pageSize,
            sortby : fields.sortBy
        };
        // if ( typeof (fields.searchKey) === 'string' && (fields.searchKey).length > 0) {
        //     retval.key = fields.searchKey;
        // }
        const directionKey = `${fields.sortBy}SortDirection`;
        if (fields[directionKey] === 'asc') {
            retval.order = 'asc';
        }
        return retval;
    };

    handlePageChange = (newCurrentPage) => {
        this.setState({
            currentPage : newCurrentPage
        }, function() {
            this.searchUsers();
            const elements = document.getElementsByClassName("admin-management-content-holder");
            if (elements && elements.length > 0) {
                elements[0].scrollTop = 0;
            }       
        })
    };

    handleLimitChange = (limit) => {
        this.setState({
            pageSize: limit,
            currentPage : 1
        }, this.searchUsers);
    }

    render () {
        const {usersList, profile} = this.state;
        const {handleSetUserToEdit, handleOpenRemoveModal, setUserToDelete} = this.props;
        const nameDirectionOpacity = {};
        const nameDirectionColor = { color: "#111111"}
        const emailDirectionOpacity = {};
        const emailDirectionColor = { color: "#111111"}
        const roleDirectionOpacity = {};
        const roleDirectionColor = { color: "#111111"};
        const brandsDirectionOpacity = {};
        const brandsDirectionColor = { color: "#111111"};
        if (this.state.sortBy === 'name') {
            emailDirectionOpacity.opacity = 0.2;
            roleDirectionOpacity.opacity = 0.2;
            brandsDirectionOpacity.opacity = 0.2;
            emailDirectionColor.color = "#999999";
            roleDirectionColor.color = "#999999";
            brandsDirectionColor.color = "#999999";
        } else if (this.state.sortBy === 'email') {
            nameDirectionOpacity.opacity = 0.2;
            roleDirectionOpacity.opacity = 0.2;
            brandsDirectionOpacity.opacity = 0.2;
            nameDirectionColor.color = "#999999";
            roleDirectionColor.color = "#999999";
            brandsDirectionColor.color = "#999999";
        } else if (this.state.sortBy === 'role') {
            nameDirectionOpacity.opacity = 0.2;
            emailDirectionOpacity.opacity = 0.2;
            brandsDirectionOpacity.opacity = 0.2;
            nameDirectionColor.color = "#999999";
            emailDirectionColor.color = "#999999";
            brandsDirectionColor.color = "#999999";
        } else {
            nameDirectionOpacity.opacity = 0.2;
            emailDirectionOpacity.opacity = 0.2;
            roleDirectionOpacity.opacity = 0.2;
            nameDirectionColor.color = "#999999";
            emailDirectionColor.color = "#999999";
            roleDirectionColor.color = "#999999";
        }

        // const sortedUsers = this.sortUsers(usersList);
        return (
            <div className="all-users-page">
                <div className="header">
                    <h1>All Users</h1>
                    <div>
                        <input onChange={this.handleSearchChange} className="search" type="text" placeholder="Search user"/>
                    </div>
                </div>
                <PleaseWait
                    show={this.state.waitingForUsers}
                    top={285}
                />
                <ApiError show={this.state.showApiError} error={this.state.apiError}
                    cancelFunction={()=>this.setState({waitingForUsers:false,showApiError:false })}/>
                <table className="data-table"
                    ref={(e) => {
                        this.table = e;}}>
                    <tbody>
                        <tr className="table-header">
                            <th onClick={() => this.setSortBy('name')} className="user-info-holder">
                                <div style={nameDirectionColor}>
                                    Name
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        style={nameDirectionOpacity}
                                        className={cx({'up':this.state.nameSortDirection==='desc'},{'no':this.state.nameSortDirection===''})}>
                                        <path fill="#666666" fillRule="nonzero" d="M16.46 9.27a.889.889 0 0 1 1.276 0 .938.938 0 0 1 0 1.305l-5.097 5.154a.888.888 0 0 1-1.278 0l-5.096-5.154a.936.936 0 0 1 0-1.305.889.889 0 0 1 1.276 0l4.46 4.227 4.458-4.227z"/>
                                    </svg>
                                </div>
                            </th>
                            <th onClick={() => this.setSortBy('email')} className="email">
                                <div style={emailDirectionColor}>
                                    Email
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        style={emailDirectionOpacity}
                                        className={cx({'up':this.state.emailSortDirection==='desc'},{'no':this.state.emailSortDirection===''})}>
                                        <path fill="#666666" fillRule="nonzero" d="M16.46 9.27a.889.889 0 0 1 1.276 0 .938.938 0 0 1 0 1.305l-5.097 5.154a.888.888 0 0 1-1.278 0l-5.096-5.154a.936.936 0 0 1 0-1.305.889.889 0 0 1 1.276 0l4.46 4.227 4.458-4.227z"/>
                                    </svg>
                                </div>
                            </th>
                            <th className="role">
                                <div style={roleDirectionColor}>
                                    Role
                                </div>
                            </th>
                            <th className="actions"/>
                        </tr>
                        {
                            usersList.length ?
                                usersList.map((user, i) => {
                                    let disableEdit=false;
                                    if (profile && profile.role && user.role && 
                                        ((profile.role === BRAND_OWNER_STR && (user.role === BRAND_OWNER_STR || user.role === SUPER_ADMIN_STR)) ||
                                        (profile.role === SUPER_ADMIN_STR && user.role === SUPER_ADMIN_STR))) {
                                        // not allow to edit user in same or above level
                                        disableEdit = true;
                                    }
                                    return (
                                        <tr key={i} className="table-row">
                                            <UserRow 
                                                setUserToDelete={setUserToDelete} 
                                                handleOpenRemoveModal={handleOpenRemoveModal} 
                                                handleSetUserToEdit={handleSetUserToEdit} 
                                                elemKey={i} 
                                                user={user}
                                                disableEdit={disableEdit}
                                            />
                                        </tr>
                                    )
                                })
                                :null
                        }
                    </tbody>
                </table>
                <PaginationNew
                    total={this.state.totalCount}
                    limit={this.state.pageSize}
                    current={this.state.currentPage}
                    parentContainer={this.container}
                    handlePageChange={this.handlePageChange}
                    limitChangeFunction ={this.handleLimitChange}
                />
            </div>
        )
    }
}
const mapStateToProps= (state) => {
    return{
        brands: state.agent.brands
    }
};

export default connect(mapStateToProps)(AllUsers)