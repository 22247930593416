import React from 'react';
import PropTypes from 'prop-types';
import style from './styles.module.scss';
import get from 'lodash/get';
import PriceSection from '../PriceSection';

const RatesSection = ({ influencerProfile, totalAmount, rates, isPublic }) => (
    <div className={style.ratesSection}>
        <div className={style.ratesSectionUser}>
            <div className={style.creatorLabel}>Creator</div>
            <span className={style.influencerCredentials}>
                {get(influencerProfile, 'userFirstName')} {get(influencerProfile, 'userLastName')}
            </span>
            <span className={style.label}>
                {isPublic ? '(Public Creator)' : '(Connected Creator)'}
            </span>
        </div>
        <div className={style.ratesSectionPrices}>
            <div className={style.ratesLabel}>Assignment Cost</div>
            <div className={style.prices}>
                {rates.map((rate) => (
                    <React.Fragment key={rate.id}>
                        <PriceSection label={rate.label} price={rate.price} />
                    </React.Fragment>
                ))}
            </div>
            <div className={style.totalCost}>
                <span className={style.totalCostTitle}>
                    Total cost
                </span>
                <span className={style.totalCostPrice}>
                    {`$${totalAmount}`}
                </span>
            </div>
        </div>
    </div>
);

RatesSection.propTypes = {
    influencerProfile: PropTypes.shape({
        userFirstName: PropTypes.string,
        userLastName: PropTypes.string,
        userPictureUrl: PropTypes.string,
        userId: PropTypes.number,
    }),
    totalAmount: PropTypes.number,
    rates: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            price: PropTypes.number,
            id: PropTypes.string,
        })
    ),
}

export default RatesSection;
