import React from 'react';
import { render } from 'react-dom';
import PropTypes from 'prop-types';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { CSVLink } from 'react-csv';
import ReachIcon from '../../shared/ReachIcon/ReachIcon';
import AnalyticspostDisplay from './AnalyticspostDisplay';
import { getNetworkByChannel, getNetworkNameByChannel } from './Analytics.helper';
import { ByPostReport } from './PDFReport';
import Tooltip from '../../shared/tooltip/Tooltip';
import Format from '../../../modules/utils/Format';

export default class AnalyticsByPost extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            sortBy: 'name',
            desc: 'true',
            exportOpen: false,
            loadingPDF: false,
        };
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.hideDropdownMenu);
    }

    showDropdownMenu = (e) => {
        e.stopPropagation();
        const { exportOpen } = this.state;
        this.setState({ exportOpen: !exportOpen });
        window.addEventListener('click', this.hideDropdownMenu);
    };

    hideDropdownMenu = () => {
        this.setState({ exportOpen: false });
    };

    exportPDF = (pdfContent) => {
        const { showLoadingScreen, hideLoadingScreen } = this.props;
        if (!this.state.loadingPDF) {
            showLoadingScreen();
            this.setState({ loadingPDF: true }, () => {
                this.createAndDownloadPDF(pdfContent, () => {
                    this.setState({ loadingPDF: false });
                    hideLoadingScreen();
                });
            });
        }
    };

    createAndDownloadPDF = (pdfContent, callback) => {
        const { startDate, endDate } = this.props;
        setTimeout(() => {
            const link = (
                <div id="export-report">
                    <PDFDownloadLink document={pdfContent} fileName={`ByPost-${startDate}-${endDate}.pdf`}>
                        {({ loading }) => {
                            if (!loading) {
                                setTimeout(() => {
                                    document.getElementById('export-report').children[0].click();
                                    callback();
                                    document
                                        .getElementById('root')
                                        .removeChild(document.getElementById('export-report-container'));
                                }, 3);
                            }
                        }}
                    </PDFDownloadLink>
                </div>
            );
            const elem = document.createElement('div');
            elem.id = 'export-report-container';
            document.getElementById('root').appendChild(elem);
            render(link, elem);
        }, 50);
    };

    render() {
        const { sortBy, desc, exportOpen } = this.state;
        const {
            dataByPost,
            dataByPost: { topReach, topViews, topEngagements, posts },
            startDate,
            endDate,
            campaignInfo,
        } = this.props;
        const headers = [
            { label: 'Campaign', key: 'campaign' },
            { label: 'Selected Assignments', key: 'selectedAssignments' },
            { label: 'Post', key: 'name' },
            { label: 'Channel', key: 'channel' },
            { label: 'Publish Date', key: 'publishDate' },
            { label: 'Reach', key: 'reach' },
            { label: 'Views', key: 'views' },
            { label: 'Engagement', key: 'engagement' },
            { label: 'Branded Engagement', key: 'brandedEngagement' },
            { label: 'Branded Engagement %', key: 'brandedEngagementPercent' },
            { label: 'Likes', key: 'likes' },
            { label: 'Comments', key: 'comments' },
            { label: 'Shares', key: 'shares' },
            { label: 'Saves', key: 'saves' },
            { label: 'Creator', key: 'influencerName' },
        ];

        const csv = (posts || []).map((post) => ({
            campaign: campaignInfo.campaignName,
            selectedAssignments: campaignInfo.assignments,
            brandedEngagement: (post.brandedCommentCount || 0) + (post.shares || 0) + (post.saves || 0),
            brandedEngagementPercent: Format.percentage2((((post.brandedCommentCount || 0) + (post.shares || 0) + (post.saves || 0))/post.comments)*100),
            ...post,
        }));

        return (
            <div className="analyticsOverview">
                <div>
                    <div className="sectionTitleLine">
                        <div className="sectionTitle">Top Posts</div>
                        <div className="exportSection">
                            {campaignInfo.currency && (
                                <div className="currency">
                                    Currency:{' '}
                                    <div>{` ${campaignInfo.currency.code} (${campaignInfo.currency.symbol})`}</div>
                                </div>
                            )}
                            <div className="exportBtn" onClick={this.showDropdownMenu}>
                                Export
                                <img src="/images/ic-dropdown-d.svg" />
                            </div>
                            {exportOpen && (
                                <div className="exportDropdown">
                                    <CSVLink
                                        data={csv}
                                        headers={headers}
                                        filename={`ByPost-${startDate}-${endDate}.csv`}
                                        target="_blank"
                                    >
                                        .csv
                                    </CSVLink>
                                    <div
                                        onClick={() =>
                                            this.exportPDF(<ByPostReport info={campaignInfo} post={dataByPost} />)
                                        }
                                    >
                                        .pdf
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="topPost">
                        {/* <div className="topPostCard">
                            <div className="topPostCardUpper">
                                <div>
                                    <img src="/images/ic_click.png" />
                                    Followers
                                </div>
                                <div className="topPostValue">{(topReach && topReach.reach) || '0'}</div>
                            </div>
                            <div className="topPostCardLower">
                                {topReach ? (
                                    <>
                                        <div className="topPostCardLowerText">
                                            <div className="topPostCardLowerFirst">
                                                {topReach.channel && (
                                                    <ReachIcon
                                                        network={getNetworkByChannel(topReach.channel)}
                                                        value={1}
                                                        size={8}
                                                        padding={4}
                                                        noToolTip
                                                    />
                                                )}
                                                <span>
                                                    {getNetworkNameByChannel(topReach.channel)}
                                                    {topReach.postType && `(${topReach.postType.toLowerCase()})`}
                                                </span>
                                                <div className="topPostCardSeparator" />
                                                <div>
                                                    <span>By:&nbsp;</span>
                                                    {topReach.influencerName}
                                                </div>
                                                <div className="topPostCardSeparator" />
                                                <a className="topPostCardLink" href={topReach.url} target="_blank">View post</a>
                                            </div>
                                            <div>{topReach.name}</div>
                                        </div>
                                    </>
                                ) : (
                                    <div className="topPostCardLowerText">
                                        <div>No post yet!</div>
                                    </div>
                                )}
                            </div>
                        </div> */}
                        <div className="topPostCard">
                            <div className="topPostCardUpper">
                                <div>
                                    <img src="/images/ic_eye_a8.svg" />
                                    Views
                                </div>
                                <div className="topPostValue">{(topViews && topViews.views) || '0'}</div>
                            </div>
                            <div className="topPostCardLower">
                                {topViews ? (
                                    <>
                                        <div className="topPostCardLowerText">
                                            <div className="topPostCardLowerFirst">
                                                {topViews.channel && (
                                                    <ReachIcon
                                                        network={getNetworkByChannel(topViews.channel)}
                                                        value={1}
                                                        size={8}
                                                        padding={4}
                                                        noToolTip
                                                    />
                                                )}
                                                <span>
                                                    {getNetworkNameByChannel(topViews.channel)}
                                                    {topViews.postType && `(${topViews.postType.toLowerCase()})`}
                                                </span>
                                                <div className="topPostCardSeparator" />
                                                <div>
                                                    <span>By:&nbsp;</span>
                                                    {topViews.influencerName}
                                                </div>
                                                <div className="topPostCardSeparator" />
                                                <a className="topPostCardLink" href={topViews.url} target="_blank">
                                                    View post
                                                </a>
                                            </div>
                                            <div>{topViews.name}</div>
                                        </div>
                                    </>
                                ) : (
                                    <div className="topPostCardLowerText">
                                        <div>No post yet!</div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="topPostCard">
                            <div className="topPostCardUpper">
                                <div>
                                    <img src="/images/ic-thread-d.svg" />
                                    Engagement
                                </div>
                                <div className="topPostValue">
                                    {(topEngagements && topEngagements.engagement) || '0'}
                                </div>
                            </div>
                            <div className="topPostCardLower">
                                {topEngagements ? (
                                    <>
                                        <div className="topPostCardLowerText">
                                            <div className="topPostCardLowerFirst">
                                                {topEngagements.channel && (
                                                    <ReachIcon
                                                        network={getNetworkByChannel(topEngagements.channel)}
                                                        value={1}
                                                        size={8}
                                                        padding={4}
                                                        noToolTip
                                                    />
                                                )}
                                                <span>
                                                    {getNetworkNameByChannel(topEngagements.channel)}
                                                    {topEngagements.postType &&
                                                        `(${topEngagements.postType.toLowerCase()})`}
                                                </span>
                                                <div className="topPostCardSeparator" />
                                                <div>
                                                    <span>By:&nbsp;</span>
                                                    {topEngagements.influencerName}
                                                </div>
                                                <div className="topPostCardSeparator" />
                                                <a
                                                    className="topPostCardLink"
                                                    href={topEngagements.url}
                                                    target="_blank"
                                                >
                                                    View post
                                                </a>
                                            </div>
                                            <div>{topEngagements.name}</div>
                                        </div>
                                    </>
                                ) : (
                                    <div className="topPostCardLowerText">
                                        <div>No post yet!</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tableHeader">
                    <div className="channelTablePost">
                        <div className="post">
                            <span>Creator/Post</span>
                        </div>

                        <div className="channel">
                            <span>Channel</span>
                        </div>
                        <div className="type">
                            <span>Type</span>
                        </div>
                        <div className="date">
                            <span>Submitted Date</span>
                        </div>
                        <div className="reach">
                            <span>Followers</span>
                        </div>

                        <div className="views">
                            <span>Views</span>
                        </div>
                        <div className="engagement">
                            <span>Engagement</span>
                        </div>
                        <div className="brandedengagement">
                            <span>Branded <Tooltip image="/images/tooltipUser.svg" tooltipText={"Number of Branded Comments + Shares + Saves. (Shares & Saves count available for TikTok only)"}/>Engagement</span>
                        </div>
                        <div className="brandedengagementpercent">
                            <span>Branded <Tooltip image="/images/tooltipUser.svg" tooltipText={"% derived from Branded Engagement divided by count of total comments"}/> Engagement %</span>
                        </div>
                        <div className="likes">
                            <span>Likes</span>
                        </div>
                        <div className="comments">
                            <span>Comments</span>
                        </div>
                        <div className="shares">
                            <span>Shares</span>
                        </div>
                        <div className="saves">
                            <span>Saves</span>
                        </div>
                        {/* <div className="brandedEng">
                            <span>Branded Eng</span>
                        </div> */}
                        <div className="postLink" />
                    </div>
                </div>
                {posts &&
                    posts.map((data, index) => {
                        return <AnalyticspostDisplay key={index} data={data} />;
                    })}
            </div>
        );
    }
}
AnalyticsByPost.propTypes = {
    dataByPost: PropTypes.shape({
        topReach: PropTypes.object,
        topViews: PropTypes.object,
        topEngagements: PropTypes.object,
        posts: PropTypes.arrayOf(PropTypes.object),
    }),
    campaignInfo: PropTypes.shape({
        campaignName: PropTypes.string,
        assignments: PropTypes.array,
        startDate: PropTypes.string,
        endDate: PropTypes.string,
    }),
    showLoadingScreen: PropTypes.func,
    hideLoadingScreen: PropTypes.func,
};
AnalyticsByPost.defaultProps = {
    dataByPost: {
        topReach: {},
        topViews: {},
        topEngagements: {},
        posts: [],
    },
    campaignInfo: {},
    showLoadingScreen: () => {},
    hideLoadingScreen: () => {},
};
