import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Checkbox from '../../shared/checkbox/Checbox';
import './listingTop.scss';
import SortButton from './SortButton';

const ListingTop = ({
    allSelected,
    pageLimit,
    pageLimitOptions,
    permission,
    selectedCount,
    sort,
    sortOptions,
    validCount,
    addToCampaignFunction,
    changePageLimitFunction,
    selectAllFunction,
    sortTypeChange,
    favoriteList,
    orderType,
    orderDisabled,
    orderTypeChange,
    isFavoritesVisible,
}) => (
    <div className="influencerListingTop">
        <div className="left">
            {validCount > 0 && permission && (
                <div className="selectAll">
                    <Checkbox size={22} controlled changeFunction={selectAllFunction} checked={allSelected} />
                    <div>Select All</div>
                </div>
            )}
            {selectedCount > 0 && (
                <div>
                    <span className="counter">{selectedCount}</span>
                    creator(s) selected
                </div>
            )}
        </div>
        <div className="middle">
            {isFavoritesVisible && favoriteList && <div className="favoriteList">Favorite list: {favoriteList}</div>}
        </div>
        <div className="right">
            {/* selectedCount > 0 && (
                <div
                    className="addToCampaignButton"
                    onClick={addToCampaignFunction}
                >
                    Add to Campaign
                </div>
            ) */}
            {/* <div className="limitOption">
                <Select
                    className="select"
                    clearable={false}
                    searchable={false}
                    value={pageLimit}
                    single
                    placeholder="Select"
                    options={pageLimitOptions}
                    onChange={changePageLimitFunction}
                />
            </div> */}
            <Select
                className="select sort-select"
                clearable={false}
                searchable={false}
                value={sort}
                single
                placeholder="Select"
                closeMenuOnSelect={false}
                options={sortOptions}
                onChange={sortTypeChange}
            />
            <div className="buttonContainer">
                <SortButton type={orderType} disabled={orderDisabled} onChange={orderTypeChange} />
            </div>
        </div>
    </div>
);

ListingTop.propTypes = {
    allSelected: PropTypes.bool,
    pageLimit: PropTypes.number,
    pageLimitOptions: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.number,
            label: PropTypes.string,
        }),
    ),
    permission: PropTypes.bool,
    selectedCount: PropTypes.number,
    sort: PropTypes.string,
    sortOptions: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string,
        }),
    ),
    validCount: PropTypes.number,
    addToCampaignFunction: PropTypes.func,
    changePageLimitFunction: PropTypes.func,
    selectAllFunction: PropTypes.func,
    sortTypeChange: PropTypes.func,
};

export default ListingTop;
