import React, { Component } from 'react';
import './InstagramComposer.scss';
import TextareaAutosize from 'react-textarea-autosize';
import { FilePond, registerPlugin } from 'react-filepond';
import { safeIsUrl } from '../../../modules/CommonServices';
import { INSTAGRAM_POST, INSTAGRAM_STORY } from '../../../constants/rateTypes';
import Api from '../../../modules/Api';
import ApiError from "../apiError/ApiError";

export default class InstagramComposer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: null,
            insPublishResources: [],
            url: '',
            caption: '',
            isValidUrl: true,
            isValidFile: true,
            onUploadCancel: null,
            isUploading: false
        };
        this.inputFile = React.createRef();
    }

    handleIGResourceUpload = async ({ target: { files } }) => {
        if (files.length > 0) {
            for (const file of files) {
                try {
                    // if (file.size > 5368709120) {
                    //     this.setState({
                    //         showApiError: true,
                    //         apiErrTitle: 'The file is too large',
                    //         apiError: 'Allowed maximum size is 5GB. Please upload a smaller file',
                    //     });
                    // } else {
                    const fileDetails = {};
                    if (file && file.name) {
                        fileDetails.name = file.name;
                        fileDetails.type = file.type;
                        fileDetails.uploadedPercentage = 1;
                    }
                    fileDetails.tempResourceId = new Date().toString() + fileDetails.name;
                    // fileDetails.url = response.url;

                    this.setState(
                        (prevState) => {
                            // enable download progress UI
                            return {
                                ...prevState,
                                insPublishResources: [...prevState.insPublishResources, fileDetails],
                            };
                        },
                        () => {
                            this.uploadIGFileGradually(file, fileDetails.tempResourceId, false);
                        },
                    );
                    // }
                } catch (error) {
                    console.log(error);
                }
            }
            if (this.inputFile.current) {
                this.inputFile.current.value = null;
            }
        }
    };

    uploadIGFileGradually = (file, tempResourceId, publicRead) => {
        const updateFileProgress = (progressPercentage) => {
            const resourceList = this.state.insPublishResources;
            if (progressPercentage > 0) {
                const resIndex = resourceList.findIndex((item) => item.tempResourceId == tempResourceId);
                if (resIndex > -1 && resourceList[resIndex]) {
                    resourceList[resIndex].uploadedPercentage = progressPercentage;
                    resourceList[resIndex].isComplete = false;
                    this.setState({
                        insPublishResources: resourceList,
                    });
                }
            }
        };
        const updateFileFinished = (response) => {
            if (response) {
                const resourceList = this.state.insPublishResources;
                const resIndex = resourceList.findIndex((item) => item.tempResourceId == tempResourceId);
                if (resIndex > -1 && resourceList[resIndex]) {
                    resourceList[resIndex].uploadedPercentage = 100;
                    resourceList[resIndex].isComplete = true;
                    resourceList[resIndex].resourceId = response.id;
                    resourceList[resIndex].url = response.url;
                    this.setState((prevState) => {
                        return {
                            ...prevState,
                            insPublishResources: resourceList,
                        };
                    });
                }
            }
        };
        const updateOnError = (response) => {
            if (response && response.message) {
                this.setState({ showApiError: true, apiError: response.message });
            }
        };

        this.setState({ uploadedPercentage: 1 });

        Api.postS3FileGradually(
            file,
            (progressPercentage) => updateFileProgress(progressPercentage),
            publicRead,
            (fn) => this.setState({onUploadCancel: fn})
        ).then((response) => {
            updateFileFinished(response);
            this.setState({ onUploadCancel: null });
        }).catch((err) => {
            updateOnError(err);
            const newResourceList = this.state.insPublishResources;
            for (let i = 0; i < newResourceList.length; i++) {
                if (newResourceList[i].tempResourceId == tempResourceId) {
                    newResourceList.splice(i, 1);
                }
            }

            this.setState({ onUploadCancel: null, insPublishResources: newResourceList });
        });
    };

    removeVideo = (resourceId) => {
        if (this.state.onUploadCancel)
            this.state.onUploadCancel();

        const newResourceList = this.state.insPublishResources;
        for (let i = 0; i < newResourceList.length; i++) {
            if (newResourceList[i].resourceId == resourceId) {
                newResourceList.splice(i, 1);
            }
        }
        this.setState((prevState) => {
            return {
                ...prevState,
                insPublishResources: newResourceList,
            };
        });
    };

    onChangeInstagramUrl = (event) => {
        const url = event.target.value;
        const instagramRegex1 = /\bhttps?:\/\/?(?:www\.)?instagram\.com\/(?:p|reel|reels)\/([^/?#&]+)\/[^/]*$/
        const instagramRegex2 = /\bhttps:\/\/www\.instagram\.com\/stories\/[^/]*\/[^/]*\/[^/]*$/
        const isValidUrl = instagramRegex1.test(url) || instagramRegex2.test(url)
        this.setState({
            url,
            isValidUrl,
        });
    };

    onChangeCaption = (event) => {
        this.setState({
            caption: event.target.value,
        });
    };

    publishSinglePost = () => {
        const { caption, url, insPublishResources } = this.state;
        const { assignmentUserId, type, postId, assignmentChoreId, onPublishSinglePost } = this.props;
        const publishIns = insPublishResources.map((item) =>
            JSON.stringify({
                name: item.name,
                url: item.url,
            }),
        );
        const formData = {
            assignmentUserId: Number(assignmentUserId),
            postType: type,
            userPostId: postId,
            contentUrl: url,
            caption,
            screenshots: publishIns,
            assignmentChoreId
        };
        onPublishSinglePost(formData);
    };

    checkResources = (resources) => {
        const { isUploading } = this.state;
        if (!resources || !resources.length) {
            return false;
        }

        if (isUploading) {
            return false
        }

        return true;
    }

    render() {
        const { type, readOnly, typeLabel, statusLabel, cancelFunc } = this.props;
        const { caption, url, isValidUrl, isValidFile, insPublishResources } = this.state;
        const uploadFieldLabel = type === INSTAGRAM_STORY ? 'Upload Story' : 'Upload Post';
        const acceptUploadContentFile = '.jpg,.jpeg,.png,video/mp4,video/x-m4v,video/*';
        const isActiveSubmitLink = this.checkResources(insPublishResources) && isValidUrl && url;

        return (
            <div className="IG-Composer">
                <ApiError
                    show={this.state.showApiError}
                    errTitle={this.state.apiErrTitle}
                    error={this.state.apiError}
                    cancelFunction={() => {
                        this.setState({ showApiError: false, waiting: false, apiErrTitle:'' });
                    }}
                />
                <div className="newContainer">
                    <div className="labelRow">
                        <div className="typeLabel">{typeLabel}</div>
                        <div className="statusLabel">{statusLabel}</div>
                    </div>
                    <div className="requiredRow">
                        <div className="title">Upload Post</div>
                        <div className="req">*required</div>
                    </div>
                    <div className="row">
                        <div className="fl-1">
                            <FilePond
                                acceptedFileTypes='image/*, video/*'
                                allowMultiple
                                maxFiles={20}
                                labelTapToCancel=""
                                labelTapToUndo="Tap to remove file"
                                server={{
                                    url: '',
                                    revert: (uniqueFileId, load, error) => {
                                        let copyPost = insPublishResources
                                        copyPost = copyPost.filter(item => item.id != uniqueFileId)
                                        this.setState({insPublishResources: copyPost})
                                        load();
                                    },
                                    process: async (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
                                        this.setState({ isUploading: true });
                                        return Api.uploadResource(
                                            file,
                                            true,
                                            null,
                                            null,
                                            (val) => progress(1, val, 100),
                                            (fn) => this.setOnUploadCancel(fn, abort, index)
                                        ).then(data => {
                                            const copyPost = insPublishResources
                                            if(data && data.id) {
                                                load(data.id);
                                                copyPost.push({...data, size: file.size})
                                                this.setState({insPublishResources: copyPost, isUploading: false});
                                            } else {
                                                this.setState({insPublishResources: copyPost, isUploading: false});
                                            }
                                        }).then(items => {
                                            return {
                                                abort: () => {
                                                    const copyPost = insPublishResources
                                                    this.setState({insPublishResources: copyPost, isUploading: false});
                                                    // Let FilePond know the request has been cancelled
                                                    abort();
                                                }
                                            };
                                        })
                                    }
                                }}
                                name="file"
                                labelIdle='Drag & Drop your file or <span class="filepond--label-action">Browse</span>'
                            />
                            {/* <label className={`upload-story-btn ${  isValidFile ? '' : 'invalid'}`}>
                                + Upload your Content
                                <input
                                    type="file"
                                    disabled={readOnly}
                                    ref={this.inputFile}
                                    accept={acceptUploadContentFile}
                                    onChange={this.handleIGResourceUpload}
                                    multiple="multiple"
                                />
                            </label>
                            {!isValidFile && <div className="input-error-message">Size of files is large (The maximum size is 2gb)</div>} */}
                        </div>
                    </div>
                    <div className="requiredRow">
                        <div className="title">Post URL</div>
                        <div className="req">*required</div>
                    </div>
                    <div className="row">
                        <div className="fl-2">
                            <input
                                className={`instagram-url ${  isValidUrl ? '' : 'invalid'}`}
                                disabled={readOnly}
                                type="text"
                                value={url}
                                placeholder="Enter your url.."
                                onChange={this.onChangeInstagramUrl}
                            />
                            {!isValidUrl && (
                                <div className="input-error-message">Invalid Instagram URL. Please check the URL you entered and try again.</div>
                            )}
                        </div>
                    </div>
                    <div className="form-row column">
                        <div className="label">Caption</div>
                        <TextareaAutosize disabled={readOnly} minRows="5" className="instagram-caption" onChange={this.onChangeCaption} value={caption} />
                    </div>
                    <div className="buttonSection">
                        <div
                            className="cancelButton"
                            onClick={cancelFunc}
                        >
                            Cancel
                        </div>
                        <div
                            className={`submit-link-btn ${  isActiveSubmitLink ? '' : 'disabled'}`}
                            onClick={this.publishSinglePost.bind(this)}
                        >
                            Submit
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
