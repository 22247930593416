import React, { Component } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    setData,
    setShowMissingChannelsError,
} from '../../../../store/campaign/influencerSearch/actionCreators';
import { closeModal } from '../../../../store/modals/missingChannelsModal/actionCreators';
import missingChannelsModalSelector from '../../../../store/modals/missingChannelsModal/selector';
import style from './MissingChannels.module.scss';

class MissingChannels extends Component {
    constructor(props) {
        super(props);

        this.escKeyCode = 27;
        this.modalCloseTimeout = 300;
        this.modalContentLabel = 'Missing channels';
        this.modalSubTitle = 'Request creator to connect assignment-required channels.';
        this.modalWarning = 'Listed creators will not be invited to the campaign.';
        this.modalButtonLabel = 'Continue';
        this.contactInfluencer = 'Contact Creator';
    }

    componentDidMount() {
        window.addEventListener('keyup', this.handleKeyPress);
    }

    componentWillUnmount() {
        window.removeEventListener('keyup', this.handleKeyPress);
    }

    handleKeyPress = (event) => {
        const {
            actions: { closeModal },
        } = this.props;

        if (event.keyCode === this.escKeyCode) {
            closeModal();
        }
    }

    onContinue = () => {
        const {
            actions: {
                closeModal,
                setData,
                setShowMissingChannelsError,
            },
            appropriateInviteList,
        } = this.props;

        if (appropriateInviteList.length) {
            // if there are influencers with required channels - show invite popup
            closeModal();
            setData({
                inviteInProgress: true,
            });
        } else {
            closeModal();
            setShowMissingChannelsError(true);
        }
    }

    render() {
        const {
            contactInfluencer,
            modalContentLabel,
            modalSubTitle,
            modalButtonLabel,
        } = this;
        const {
            isOpen,
            inappropriateInviteList,
        } = this.props;

        return (
            <Modal
                closeTimeoutMS={this.modalCloseTimeout}
                overlayClassName={style.overlay}
                className={style.modal}
                isOpen={isOpen}
            >
                <div className={style.container}>
                    <div className={style.title}>
                        {modalContentLabel}
                    </div>
                    <div className={style.content}>
                        <div className={style.subtitle}>
                            {modalSubTitle}
                        </div>
                        {
                            inappropriateInviteList.map(({ name, pictureUrl, email }) => (
                                <div
                                    key={`${name}${pictureUrl}`}
                                    className={style.influencer}
                                >
                                    <div className={style.influencerProfile}>
                                        <div className={style.influencerProfileImg}>
                                            <img src={pictureUrl} alt="profilePicture" />
                                        </div>
                                        <div className={style.influencerProfileName}>
                                            {name}
                                        </div>
                                    </div>
                                    <a href={`mailto:${email}`} className={style.influencerProfileContact}>
                                        {contactInfluencer}
                                    </a>
                                </div>
                            ))
                        }
                    </div>
                    <div className={style.footer}>
                        <button
                            type="button"
                            className={style.confirmButton}
                            onClick={this.onContinue}
                        >
                            {modalButtonLabel}
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }
}

MissingChannels.propTypes = {
    actions: PropTypes.shape({
        closeModal: PropTypes.func,
        setData: PropTypes.func,
        setShowMissingChannelsError: PropTypes.func,
    }),
    appropriateInviteList: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            uid: PropTypes.string,
            rates: PropTypes.object,
            pictureUrl: PropTypes.string,
            name: PropTypes.string,
        }),
    ),
    inappropriateInviteList: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            uid: PropTypes.string,
            rates: PropTypes.object,
            pictureUrl: PropTypes.string,
            name: PropTypes.string,
        }),
    ),
    isOpen: PropTypes.bool,
};

const mapStateToProps = (state) => missingChannelsModalSelector(state);

const mapStateDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({
        closeModal,
        setData,
        setShowMissingChannelsError,
    }, dispatch),
});

export default connect(mapStateToProps, mapStateDispatchToProps)(MissingChannels);
