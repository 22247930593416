//TODO: Change the filter after category/topic changes finished on backend
import React from 'react';
import { Motion, spring } from 'react-motion';
import Lookup from '../../../modules/Lookup';
import './listsSplash.scss';

export default class ListsSplash extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = { display: false };
    }

    setFilter = (filterId, option) => {
        const { onClickFunction } = this.props;
        if (typeof onClickFunction === 'function') {
            if (filterId === 'All') {
                onClickFunction();
            }else {
                onClickFunction({ filterId, operator: filterId === 19 ? 'any' : 'all', filterOptionIds: option });
            }
        }
    };

    render() {
        const { display } = this.state;
        return (
            <div className="listsSplash" style={this.props.searchBarHeight ? {top: (this.props.searchBarHeight + 90) + "px"} : null}>
                <div className="title listsSplashChild">
                    <label>Discover Creators</label>
                    <span>Our curated list of trending creators</span>
                </div>
                <Motion
                    defaultStyle={{ x: 50, y: 0 }}
                    style={{
                        x: spring(0, { stiffness: 200, damping: 30 }),
                        y: spring(1, { stiffness: 150, damping: 30 }),
                    }}
                >
                    {({ x, y }) => (
                        <div
                            className="listsCover listsSplashChild"
                            style={{
                                opacity: `${y}`,
                                WebkitTransform: `translate3d(0,${x}px, 0)`,
                                transform: `translate3d(0,${x}px, 0)`,
                            }}
                        >
                            <div
                                className="imgCover leftEdge leftEdgeNarrow"
                                onClick={() => this.setFilter(19, [Lookup.getFilterId('Beauty', 19)])}
                                style={{ visibility: display ? 'visible' : 'hidden', opacity: display ? 1 : 0 }}
                            >
                                <img src="/images/discoveryCover/beauty.png" alt="Beauty" />
                                <label>Beauty</label>
                            </div>
                            <div
                                className="imgCover"
                                onClick={() => this.setFilter(19, [Lookup.getFilterId('Haircare', 19)])}
                                style={{ visibility: display ? 'visible' : 'hidden', opacity: display ? 1 : 0 }}
                            >
                                <img src="/images/discoveryCover/haircare.png" alt="Haircare" />
                                <label>Haircare</label>
                            </div>
                            <div
                                className="imgCover leftEdgeNarrow"
                                onClick={() => this.setFilter(19, [Lookup.getFilterId('Skincare', 19)])}
                                style={{ visibility: display ? 'visible' : 'hidden', opacity: display ? 1 : 0 }}
                            >
                                <img src="/images/discoveryCover/skincare.png" alt="Skincare" />
                                <label>Skincare</label>
                            </div>
                            <div
                                className="imgCover"
                                onClick={() => this.setFilter(19, [Lookup.getFilterId('Personal Care', 19)])}
                                style={{ visibility: display ? 'visible' : 'hidden', opacity: display ? 1 : 0 }}
                            >
                                <img src="/images/discoveryCover/personalcare.png" alt="Personal Care" />
                                <label>Personal Care</label>
                            </div>
                            <div
                                className="imgCover twice leftEdge leftEdgeNarrow"
                                onClick={() => this.setFilter('All')}
                                style={{ visibility: display ? 'visible' : 'hidden', opacity: display ? 1 : 0 }}
                            >
                                <img
                                    src="/images/discoveryCover/allinfluencers.png"
                                    alt="All Creators"
                                    onLoad={() => this.setState({ display: true })}
                                />
                                <label>All Creators</label>
                            </div>
                            <div
                                className="imgCover twice leftEdgeNarrow"
                                onClick={() => this.setFilter(6, [24])}
                                style={{ visibility: display ? 'visible' : 'hidden', opacity: display ? 1 : 0 }}
                            >
                                <img src="/images/discoveryCover/newinfluencer.png" alt="Newly Joined Creators" />
                                <label>Newly Joined Creators</label>
                            </div>
                            <div
                                className="imgCover leftEdge leftEdgeNarrow"
                                onClick={() => this.setFilter(19, [Lookup.getFilterId('Fitness', 19)])}
                                style={{ visibility: display ? 'visible' : 'hidden', opacity: display ? 1 : 0 }}
                            >
                                <img src="/images/discoveryCover/fitness.png" alt="Fitness" />
                                <label>Fitness</label>
                            </div>
                            <div
                                className="imgCover"
                                onClick={() => this.setFilter(19, [Lookup.getFilterId('Makeup', 19)])}
                                style={{ visibility: display ? 'visible' : 'hidden', opacity: display ? 1 : 0 }}
                            >
                                <img src="/images/discoveryCover/makeup.png" alt="Makeup" />
                                <label>Makeup</label>
                            </div>
                            <div
                                className="imgCover leftEdgeNarrow"
                                onClick={() => this.setFilter(19, [Lookup.getFilterId('Nails', 19)])}
                                style={{ visibility: display ? 'visible' : 'hidden', opacity: display ? 1 : 0 }}
                            >
                                <img src="/images/discoveryCover/nail.png" alt="Nails" />
                                <label>Nails</label>
                            </div>
                            <div
                                className="imgCover"
                                onClick={() => this.setFilter(19, [Lookup.getFilterId('Travel', 19)])}
                                style={{ visibility: display ? 'visible' : 'hidden', opacity: display ? 1 : 0 }}
                            >
                                <img src="/images/discoveryCover/travel.png" alt="Travel" />
                                <label>Travel</label>
                            </div>
                        </div>
                    )}
                </Motion>
            </div>
        );
    }
}
