import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import moment from "moment";
import './favouriteList.scss';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PleaseWaitWhite from '../shared/pleaseWait/PleaseWaitWhite';
import TopNavigation from "../shared/topNavigation/TopNavigation"
import ApiError from '../shared/apiError/ApiError';
import PaginationNew from '../shared/pagination/PaginationNew'
import {
    fetchExclusions,
    saveExclusion,
    deleteExclusion,
    createExclusion,
    errorExclusionList,
} from '../../store/actions/exclusionActions';
import SuccessBanner from "./SuccessBanner";
import Api from '../../modules/Api';
import PopUpDialogueBase from '../shared/popUpDialogueBase/PopUpDialogueBase';
import { Trash } from 'phosphor-react';
import NewEditModal from './NewEditModal';
import SortableTable from './SortableTable';
import ListPageTopBar from './ListPageTopBar';

const defaultOrders = {
    name: 'DESC',
    createdOn: 'DESC',
    updatedOn: 'DESC',
};

const titles = [
    { key: 'name', label: 'Name', width: '30%', flag: 'text', style: { color: '#000000', fontWeight: 'bold' } },
    { key: 'totalUserCount', label: 'Creators', width: '10%', flag: 'text' },
    { key: 'tags', label: 'Tags', width: '30%', flag: 'tags' },
    { key: 'createdOn', label: 'Created on', width: '15%', flag: 'date' },
    { key: 'updatedOn', label: 'Modified on', width: '15%', flag: 'date' },
];

const Actions = [
    { key: 'edit', label: 'Edit' },
    { key: 'duplicate', label: 'Duplicate' },
    { key: 'delete', label: 'Delete' },
    // 'divider',
    // { key: 'plan', label: 'Add to plan' },
];

const ExclusionList = ({
    fetchExclusions,
    saveExclusion,
    createExclusion,
    exclusionData,
    exclusionError,
    resetError,
    loading,
    history,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [apiError, setApiError] = useState();
    const [errTitle, setErrTitle] = useState('');
    const [sortBy, setSortBy] = useState('updatedOn');
    const [orders, setOrders] = useState(defaultOrders);
    const [totalCount, setTotalCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [exclusionList, setExclusionList] = useState([]);
    const [currentExclusion, setCurrentExclusion] = useState(null);
    const [showDelete, setShowDelete] = useState(false);
    const [showFavoriteModal, setShowFavoriteModal] = useState(false);
    const [filterValue, setFilterValue] = useState('');
    const containerRef = useRef();


    useLayoutEffect(() => {
        fetchData();
    }, [sortBy, orders, filterValue, currentPage, pageSize]);

    const fetchData = () => {
        const params = {
            desc: orders[sortBy] === 'DESC',
            search: filterValue,
            page: currentPage,
            num: pageSize,
            sortBy: sortBy
        }
        setIsLoading(true);
        Api.getExclusions(params)
            .then(res => {
                if (res.results) {
                    setExclusionList(res.results.map(ex => ({
                        ...ex,
                        totalUserCount: ex.publicUsers.length + ex.users.length,
                    })));
                }
                if (res.meta) {
                    setTotalCount(res.meta.totalCount);
                }
                setIsLoading(false);
            })
            .catch(err => {
                handleApiError(err);
                setIsLoading(false);
            });
    };

    const handleApiError = (err) => {
        setApiError(err);
        if (err && err.originalMessage) {
            setErrTitle(err.originalMessage);
        }
    };

    const updateFavouriteList = () => {
        if (favoriteData && favoriteData.length > 0) {
            let data = Object.assign([], favoriteData);

            if (typeof filterValue === 'string' && filterValue.length > 0) {
                data = data.filter(d => d.name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1)
            }

            const update = data.map(f => ({
                ...f,
                tags: f.tags || [],
                creators: f.users && f.users.length || 0,
                createdOn: moment(f.createdOn).toDate().getTime(),
                updatedOn: moment(f.updatedOn).toDate().getTime(),
            }))
            const forward = orders[sortBy] === 'DESC';
            update.sort((a, b) => (sortBy === 'name' ? a[sortBy].toLowerCase() > b[sortBy].toLowerCase() : a[sortBy] > b[sortBy]) ? (forward ? 1 : -1) : (forward ? -1 : 1));
            setExclusionList(update);
        } else {
            setExclusionList([]);
        }
    };

    const handleSortChange = (val) => {
        if (sortBy === val) {
            if (orders[val]) {
                const update = { ...orders };
                update[val] = orders[val] === 'ASC' ? 'DESC' : 'ASC';
                setOrders(update);
            }
        } else {
            setSortBy(val);
        }
    };

    const handlePageChange = (val) => {
        setCurrentPage(val);
    };

    const handleLimitChange = (val) => {
        setPageSize(val);
    };

    const addNew = () => {
        setShowFavoriteModal(true);
    };

    const CancelError = () => {
        setApiError(null);
        setErrTitle('');
    };

    const filterLists = (e) => {
        setFilterValue(e.target.value);
    };

    const onActions = (action, exclusion) => {
        switch (action) {
            case 'delete':
                setShowDelete(true);
                break;
            case 'edit':
                setShowFavoriteModal(true);
                break;
            case 'duplicate':
                duplicateExclusion(exclusion);
                break;
            default:
                return;
        }
        setCurrentExclusion(exclusion);
    };

    const cancelDelete = () => {
        setShowDelete(false);
        setCurrentExclusion(null);
    };

    const closeModal = () => {
        setShowFavoriteModal(false);
        setCurrentExclusion(null);
    };

    const onSuccess = (res, update) => {
        setShowSuccess(true);
        if (update) {
            setSuccessMessage('Success! Your list has been updated.');
        } else {
            setSuccessMessage('Success! Your list has been created.');
        }
    };

    const onSubmit = (data) => {
        closeModal();
        setIsLoading(true);
        if (data.id) {
            Api.saveExclusion(data.id, data)
                .then(res => {
                    fetchData();
                    onSuccess(res, true);
                })
                .catch(err => {
                    handleApiError(err);
                    setIsLoading(false);
                })
        } else {
            Api.createExclusion(data)
                .then(res => {
                    fetchData();
                    onSuccess(res, false);
                })
                .catch(err => {
                    handleApiError(err);
                    setIsLoading(false);
                })
        }
    };

    const closeSuccessMessage = () => {
        setShowSuccess(false);
        setSuccessMessage('');
    };

    const duplicateExclusion = (exclusion) => {
        if (exclusion) {
            setIsLoading(true);
            Api.duplicateExclusion(exclusion.id)
                .then(res => {
                    setIsLoading(false);
                    setCurrentExclusion(null);
                    fetchData();
                    setShowSuccess(true);
                    setSuccessMessage('Success! Your file has been successfully duplicated.');
                })
                .catch(err => {
                    setIsLoading(false);
                    setCurrentExclusion(null);
                    handleApiError(err);
                })
        }
    }

    const deleteExclusion = () => {
        if (currentExclusion) {
            setIsLoading(true);
            Api.deleteExclusion(currentExclusion.id)
                .then(res => {
                    setShowDelete(false);
                    setCurrentExclusion(null);
                    fetchData();
                })
                .catch(err => {
                    setIsLoading(false);
                    setShowDelete(false);
                    setCurrentExclusion(null);
                    handleApiError(err);
                });
        } else {
            setShowDelete(false);
            setCurrentExclusion(null);
        }
    };

    const onCardClick = (exclusion) => {
        history.push(`/exclusion/${exclusion.id}`);
    };

    const disableCheck = (data) => {
        return data.totalUserCount === 0;
    }

    return (
        <div className='favouriteListContainer'>
            <PleaseWaitWhite
                show={isLoading}
            />
            <ApiError
                show={apiError}
                errTitle={errTitle}
                error={apiError}
                cancelFunction={CancelError}
            />
            <TopNavigation current="influencers" />
            <div className='contentContainer' ref={containerRef}>
                <ListPageTopBar
                    label={'Exclusion lists'}
                    onChange={filterLists}
                    onButton={addNew}
                    searchPlaceHolder={'Search exclusion list...'}
                    buttonLabel={'New exclusion'}
                />
                <div className='listTableContainer'>
                    {exclusionList && exclusionList.length > 0 && <SortableTable
                        columns={titles}
                        orders={orders}
                        sortBy={sortBy}
                        onChange={handleSortChange}
                        listData={exclusionList}
                        actions={Actions}
                        onActions={onActions}
                        onCardClick={onCardClick}
                        disable={disableCheck}
                    />}
                    <PaginationNew
                        total={totalCount}
                        limit={pageSize}
                        current={currentPage}
                        parentContainer={containerRef.current}
                        handlePageChange={handlePageChange}
                        limitChangeFunction={handleLimitChange}
                    />
                    <div style={{ height: 45 }} />
                </div>
            </div>
            <PopUpDialogueBase
                title={'Confirm delete'}
                show={showDelete}
                icon={<Trash color="#d90036" size={40} weight="bold" />}
                message={<div className="favouriteDeleteMessage">Are you sure you want to delete <span>{`"${currentExclusion && currentExclusion.name}"`}</span> list?</div>}
                cancelFunction={cancelDelete}
                proceedLabel="Delete"
                loading={isLoading}
                proceedButtonStyle={{ backgroundColor: '#d90036', width: 100 }}
                cancelButtonStyle={{ width: 100 }}
                proceedFunction={deleteExclusion}
            />
            <NewEditModal
                show={showFavoriteModal}
                data={currentExclusion}
                onClose={closeModal}
                onSubmit={onSubmit}
                // listData={exclusionData}
                label={'exclusion'}
                placeholder={'Exclusion name'}
            />
            <SuccessBanner
                show={showSuccess}
                closeFunction={closeSuccessMessage}
                message={successMessage}
            />
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        exclusionData: state.EXCLUSION_REDUCER.exclusionData,
        loading: state.EXCLUSION_REDUCER.exclusionLoading,
        exclusionError: state.EXCLUSION_REDUCER.exclusionError,
        profile: state.global.loggedInUserProfile
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchExclusions: () => dispatch(fetchExclusions()),
        resetError: () => dispatch(errorExclusionList(null)),
        saveExclusion: (favId, fav, cb) => dispatch(saveExclusion(favId, fav, cb)),
        createExclusion: (fav, cb) => dispatch(createExclusion(fav, cb)),
        deleteExclusion: id => dispatch(deleteExclusion(id))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExclusionList));