import React from "react";
import _ from 'lodash';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { API } from "aws-amplify";
import Modal from 'react-modal';
import Select from 'react-select';
import cx from 'classnames'
import PleaseWait from "../shared/pleaseWait/PleaseWait";
import PleaseWaitWhite from "../shared/pleaseWait/PleaseWaitWhite";
import FeedbackOnTheTop from "../shared/feedbackOnTheTop/FeedbackOnTheTop";
import Api from "../../modules/Api";
import ApiError from "../shared/apiError/ApiError";
import PaginationNew from "../shared/pagination/PaginationNew";
import ListingTop from "./listingTop/ListingTop";
import MissingChannelsModal from "../shared/modals/misingChannels/MissingChannels";
import ErrorMessageBox from "../shared/errorMessageBox/ErrorMessageBox";
import "./influencers.scss";
// imports below have cycle dependencies (potential memory leak)
import OneInfluencer from "../oneInfluencer2022/OneInfluencer";
import Invite from "./invite/Invite";
import SuccessBanner from "../favourite/SuccessBanner";
import PendoHelper from "../../modules/utils/PendoHelper";

import {
    beforeAddToCampaign,
    clearAll,
    finishInvite,
    listSplashFilterChange,
    pageChange,
    pageLimitChange,
    searchFilterChange,
    selectAll,
    selectionChange,
    setApiError,
    setData,
    setShowMissingChannelsError,
    sortFilterChange,
    orderTypeChange,
    refreshSearch,
    invitePageChange,
    clearCurrentPage,
    setCurrency
} from "../../store/campaign/influencerSearch/actionCreators";
import { influencerBodySelector } from "../../store/campaign/influencerSearch/selectors";
import {
    engagementPublic,
    engagementRatePublic,
    followersPublic,
} from "../../store/campaign/influencerSearch/reducer";
import { setData as setGeneralData } from "../../store/general/actionCreators";
import DiscoverPanel from "./discoverPanel/DiscoverPanel";
import { PUBLIC_SOURCE_INFLUENCERS_MAX_COUNT } from "../../constants/lookup-constants";
import Checkbox from "../shared/checkbox2022/Checkbox";
import { handleEmptyDataInSavedSearch } from "../../modules/utils/CommonUtils";

class Influencers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            favoriteList: null,
            searchBarHeight: null,
            commonFilters: [],
            connectedFilters: [],
            allFiltersData: {
                type: "PUBLIC",
                requiredChannels: [],
                commonFilters: [],
                connectedFilters: [],
                audienceFilters: [],
            },
            channelSelect: {
                CONNECTED: {
                    blog: false,
                    facebook: false,
                    instagram: false,
                    tiktok: false,
                    twitter: false,
                    youtube: false,
                },
                PUBLIC: {
                    instagram: true,
                    tiktok: false,
                },
            },
            audienceFilters: [],
            addedFilters: { CONNECTED: [], PUBLIC: [] },
            selectedFilters: { CONNECTED: [], PUBLIC: [] },
            selectAllInfluencersChecked: false,
            editExistingSearch: null,
            successMessage: '',
            plansDetails: null,
            existingSavedSearches: [],
            currencyList: [],
            publicModal: false,
            currentSocial: null,
            handle: null,
            secondModal: false
        };
    }

    componentDidMount() {
        const {planId, actions: { setCurrency }, } = this.props;
        const currencyLocal = JSON.parse(localStorage.getItem('currencySelected'))
        this.getSavedSearches();
        Api.getCurrencyList().then(result => {
            const { data } = result;
            const currencyList = []
            data.forEach(x => {
                const currency = {label: `${x.code} (${x.symbol})`, value: x.code, symbol: x.symbol, exchangeRateToUsd: x.exchangeRateToUsd}
                currencyList.push(currency)
            })
            this.setState({currencyList})
        })
        if (!planId && currencyLocal) {
            setCurrency(currencyLocal)
        }
    }

    getSavedSearches = () => {
        const num = 10;
        const page = 1;
        const { actions: { setApiError }, planId } = this.props;
        if (planId) {
            Api.getPlanSavedSearches({ num, page, planId })
                .then((res) => {
                    if (res.totalElements > num) {
                        Api.getPlanSavedSearches({ num: res.totalElements, page, planId })
                            .then(res2 => {
                                const contents2 = handleEmptyDataInSavedSearch(res2.content);
                                this.setState({ existingSavedSearches: contents2 });
                            })
                            .catch(err2 => {
                                setApiError(err2);
                            })
                    } else {
                        const contents = handleEmptyDataInSavedSearch(res.content);
                        this.setState({ existingSavedSearches: contents });
                    }
                })
                .catch(err => {
                    setApiError(err);
                })
        } else {
            Api.getAgentSavedSearches({ num, page })
                .then(res => {
                    if (res.totalElements > num) {
                        Api.getAgentSavedSearches({ num: res.totalElements, page })
                            .then((res2 => {
                                const contents2 = handleEmptyDataInSavedSearch(res2.content);
                                this.setState({ existingSavedSearches: contents2 });
                            }))
                            .catch(err => {
                                setApiError(err);
                            })
                    } else {
                        const contents = handleEmptyDataInSavedSearch(res.content);
                        this.setState({ existingSavedSearches: contents });
                    }
                })
                .catch(err => {
                    setApiError(err);
                })
        }
    }

    componentDidUpdate(prevProps) {
        const { currency, actions: { setCurrency }, showNoResults } = this.props;
        if (!_.isEqual(prevProps?.editSearch, this.props?.editSearch) && this.props?.editSearch) {
            const {
                actions: { searchFilterChange },
                editSearch
            } = this.props;
            searchFilterChange(editSearch);
            this.setState({editExistingSearch: editSearch});
        }
        if(prevProps.currency !== currency) {
            this.forceUpdate();
        }
        const {planId, plansData, actions: { setApiError }} = this.props;
        if (prevProps?.planId !== planId && planId) {
            setTimeout(() => {
                this.getSavedSearches();
            }, 1000);

            if (plansData) {
                this.setState({
                    plansDetails: plansData
                });
                Api.getBrand(plansData.brand.id).then(res => {
                    const currencyItem = this.state.currencyList.find(item => item.value === res.defaultCurrencyCode)
                    setCurrency(currencyItem)
                })
            } else {
                Api.getPlanDetails(planId)
                    .then(res => {
                        this.setState({plansDetails: res?.data});
                        Api.getBrand(res.data.brand.id).then(res => {
                            const currencyItem = this.state.currencyList.find(item => item.value === res.defaultCurrencyCode)
                            setCurrency(currencyItem)
                        })
                    })
                    .catch(err => {
                        setApiError(err);
                    });
            }
        }
    }

  inviteSingle = (id) => {
      const {
          actions: { selectionChange },
      } = this.props;
      const isSelected = true;
      const runInvitation = true;

      selectionChange(id, isSelected, runInvitation);
  };

  unInvite = (id) => {
      const {
          actions: { selectionChange },
      } = this.props;
      const isSelected = false;

      selectionChange(id, isSelected);
  };

  addToCampaignClickHandler = () => {
      const {
          actions: { beforeAddToCampaign },
      } = this.props;

      // check corresponding saga for more detailed explanation
      beforeAddToCampaign();
  };

  listSplashFiltersChange = (filters) => {
      const {
          actions: { listSplashFilterChange, searchFilterChange },
      } = this.props;

      // // todo. this.searchPanel.setFilter is not working
      // // listSplashFilterChange(filters, this.discoveryPanel.setFilter);
      // this.discoveryPanel.setFilter(filters);
      const searchData = {
          type: "CONNECTED",
          connectedFilters: filters ? [filters] : [],
      };
      searchFilterChange(searchData);
  };

  onFiltersChange = (searchData) => {
      const {
          favoriteData,
          actions: { setData, searchFilterChange },
          currency
      } = this.props;

      let favList = null;
      if (searchData && searchData.favorites && searchData.favorites.length > 0) {
          const fav = favoriteData.find((f) => f.id === searchData.favorites[0]);
          if (fav) {
              favList = fav.name;
          }
      }

      this.setState({ favoriteList: favList });

      setData({
          currentPage: 1,
      });
      searchData.currencyCode = currency
      searchFilterChange(searchData);
      Api.autoSave(searchData)
  };

  formatInfluencersData = (specificUser) => {
      const { alreadyInvitedUsers, assignmentId, assignmentName, inviteList } =
      this.props;

      let validCount = 0;

      let users;
      if (!specificUser) {
          users = this.combineFavoriteSelectedUsers();
      } else {
          users = [];
          users.push(specificUser);
      }
      const formattedInfluencers = users.map((influencer) => {
          const {planId} = this.props;
          const {plansDetails} = this.state;
          const { id, uid, userNetworks } = influencer;
          let invited = "";
          let inviteDisabled = false;
          const alreadyInvitedIndex = alreadyInvitedUsers.findIndex(
              ({ user }) => user.uid === influencer.uid
          );
          const checked = inviteList.findIndex(({ uid }) => uid === influencer.uid) > -1;
          let isAlreadyInPlan = false;

          if (alreadyInvitedUsers.length && alreadyInvitedIndex > -1) {
              const { status } = alreadyInvitedUsers[alreadyInvitedIndex];
              invited = `${status}: Assignment [${assignmentName}]`;
          }

          if (assignmentId && alreadyInvitedIndex > -1) {
              inviteDisabled = true;
          }

          if (!invited && userNetworks.length > 1) {
              validCount += 1;
          }
          if (planId && plansDetails) {
              if (influencer.id && Array.isArray(plansDetails?.influencers)) {
                  isAlreadyInPlan = (plansDetails.influencers.find(p => p.id === influencer.id) !== undefined);
              } else if (influencer.id === null && Array.isArray(plansDetails.iqUsers)) {
                  isAlreadyInPlan = (plansDetails.iqUsers.find(p => p.uid === influencer.uid) !== undefined);
              }
          }

          return {
              checked,
              details: influencer,
              id: id ?? uid,
              invited,
              inviteDisabled,
              isAlreadyInPlan
          };
      });

      return {
          formattedInfluencers,
          validCount,
      };
  };

  combineFavoriteSelectedUsers = () => {
      const { influencers, favoriteData, searchFilters } = this.props;

      const tempList = [...influencers];
      if (searchFilters && searchFilters.length > 0) {
      // it cares about filterId is 33 only which is for favorite
          const favoriteFilter = searchFilters.find(
              (filter) => filter.filterId === 33
          );
          if (favoriteFilter !== undefined) {
              const favoriteFilterProperties = favoriteFilter.filterProperties;
              // favoriteFilterProperties is an array but currently only select one favorite
              for (let i = 0; i < favoriteFilterProperties.length; i++) {
                  const favoriteItem = favoriteData.find(
                      (data) => data.id === favoriteFilterProperties[i]
                  );
                  if (favoriteItem && favoriteItem.users) {
                      for (let m = 0; m < tempList.length; m++) {
                          const index = favoriteItem.users.findIndex(
                              (u) => u === tempList[m].id
                          );
                          // if the influencer has been deselected from this favorite list, then remove it from the display list
                          if (index === -1) {
                              tempList.splice(m, 1);
                          }
                      }
                  }
              }
          }
      }
      return tempList;
  };

  closeErrorPopup = () => {
      const {
          actions: { setApiError },
      } = this.props;

      setApiError(null);
  };

  closeFeedbackOnTop = () => {
      const {
          actions: { clearAll, setData },
      } = this.props;

      setData({
          showInviteSuccess: false,
      });
      clearAll();
  };

  noResultAcknowledged = () => {
      const {
          actions: { setData },
          campaignId,
      } = this.props;

      setData({
          showNoResults: false,
          showDiscovery: !campaignId,
      });
  };

  closeInvite = () => {
      const {
          actions: { setData },
      } = this.props;

      setData({
          inviteInProgress: false,
      });
  };

  closeMissingChannelsErrorMessage = () => {
      const {
          actions: { setShowMissingChannelsError },
      } = this.props;

      setShowMissingChannelsError(false);
  };

  handlePageChange = (val) => {
      const {
          actions: { clearAll, pageChange, setData },
      } = this.props;
      invitePageChange();
      pageChange({ nextPage: val });
      setData({inviteList: {}})
  };

  handlePageLimitChange = (val) => {
      const {
          searchFilters,
          actions: { clearAll, pageLimitChange, pageChange },
          pageLimitOptions,
          currentPage,
      } = this.props;
      clearAll();
      if (pageLimitOptions) {
          const matchValue = pageLimitOptions.find((item) => item.value === val);
          if (matchValue) {
              const maxPage = PUBLIC_SOURCE_INFLUENCERS_MAX_COUNT / val;
              const isSourceTypePublic = searchFilters.type === "PUBLIC";
              pageChange({ nextPage: 1, preventRefresh: true });
              pageLimitChange(matchValue);
          }
      }
  };

  handleCheckboxSelect = (val) => {
      console.log("handleCheckboxSelect");
  };

  handleSortChange = (val) => {
      // Add pendo event
      PendoHelper.pendoTrack('DiscoverFilter_SortChange', { sortType: val.label });

      const {
          actions: { clearAll, sortFilterChange },
      } = this.props;
      clearAll();
      sortFilterChange({ sort: val });
  };

  handleOrderChange = (val) => {
      const {
          actions: { clearAll, orderTypeChange },
      } = this.props;
      clearAll();
      orderTypeChange(val);
  };

  handleCurrencyChange = (val) => {
      const {
          actions: { setCurrency },
          planId
      } = this.props;
      if (!planId) {
          localStorage.setItem('currencySelected', JSON.stringify(val));
      }
      setCurrency(val);
  }

  refreshSearch = () => {
      const {
          actions: { clearAll, refreshSearch },
      } = this.props;
      clearAll();
      refreshSearch();
  }

  refreshPlan = () => {
      const { planId } = this.props;
      if (planId) {
          Api.getPlanDetails(planId)
              .then(res => {
                  this.setState({plansDetails: res?.data});
              })
              .catch(err => {
                  setApiError(err);
              });
      }
  }

  gotoProfile = (existPublicProfile) => {
      this.setState({ existPublicProfile });
  };

  setAdditionalitems = (func) => {
      this.renderFilter = func;
  };

  setTopFilter = (func) => {
      this.renderTopFilter = func;
  };

  setSearchBarHeight = (height) => {
      this.setState({ searchBarHeight: height });
  };

  clearCurrentPage = () => {
      const {
          inviteList,
          influencers,
          actions: { clearCurrentPage },
      } = this.props;

      const newInviteList = inviteList.filter(
          (invite) =>
              influencers.find((influencer) => influencer.id === invite.id) ===
        undefined
      );
      const obj = {};
      newInviteList.forEach((newInvite) => (obj[newInvite.uid] = newInvite));
      clearCurrentPage(obj);
  };

  updateSavedSearch = (id) => {
      const {searchFilters, planId} = this.props;
      const search = {...searchFilters};
      if (_.isEmpty(search.audienceFilters)) {
          delete search.audienceFilters;
      }
      if (_.isEmpty(search.commonFilters)) {
          delete search.commonFilters;
      }
      if (_.isEmpty(search.connectedFilters)) {
          delete search.connectedFilters;
      }
      if (_.isEmpty(search.keywordFilters)) {
          delete search.keywordFilters;
      }
      if (_.isEmpty(search.favorites)) {
          delete search.favorites;
      }
      if (_.isEmpty(search.requiredChannels)) {
          delete search.requiredChannels;
      }
      return planId ? Api.updatePlanSavedSearchById(id, search) : Api.updateAgentSavedSearchById(id, search);
  }

    onSaveSearch = (name, id, successCallback) => {
        const { searchFilters, planId, actions: { setApiError } } = this.props;
        if (id === null) {
            if (planId) {
                Api.createPlanSavedSearch(planId, { isAutoSave: false, name })
                    .then(res => {
                        this.getSavedSearches();
                        this.updateSavedSearch(res.id)
                            .then(result => {
                                this.setState({ successMessage: 'Success! Your search has been saved.' },
                                    successCallback);
                            })
                            .catch(e => {
                                setApiError(e);
                            })
                    })
                    .catch(err => {
                        setApiError(err.originalMessage)
                    })
            } else {
                Api.createAgentSavedSearch({ isAutoSave: false, name })
                    .then(res => {
                        this.getSavedSearches();
                        this.updateSavedSearch(res.id)
                            .then(result => {
                                this.setState({ successMessage: 'Success! Your search has been saved.' },
                                    successCallback);
                            })
                            .catch(e => {
                                setApiError(e);
                            })
                    })
                    .catch(err => {
                        setApiError(err.originalMessage)
                    })
            }
        } else {
            this.updateSavedSearch(id)
                .then(result => {
                    this.setState({ successMessage: 'Success! Your search has been saved.' },
                        successCallback);
                })
                .catch(e => {
                    setApiError(e);
                })
        }
    }

    handleChange = item => {
        if(item.length === 0) {
            this.setState({handle: ''})
        } else if (item[0] !== "@") {
            this.setState({handle: `@${item}`})
        } else {
            this.setState({handle: item})
        }
    }

    submitFunction = () => {
        const { currentSocial, handle } = this.state;
        const {
            actions: {
                setApiError,
            }
        } = this.props
        const body = {platform: currentSocial, socialHandle: handle }
        Api.requestPublic(body).then(response => {
            const { found, profileRequested  } = response;
            if (found) {
                this.setState({secondModal: true})
            } else if(!found && profileRequested) {
                this.setState({publicModal: false, successMessage: 'Success! We have received your request and if this profile has more than 1000 followers and is "open" (not private) it will be added within 3 months.'}, this.closeFunction)
            } else {
                setApiError("Something went wrong, please try again later");
            }
        }).catch(err => {
            setApiError(err);
        })
    }

    closeFunction = () => {
        this.setState({publicModal: false, handle: null, currentSocial: null})
    }

    publicButton = () => (
        <div onClick={() => this.setState({publicModal: true})} className="requestPublic">Request Public Profile</div>
    )

    render() {
        const {
            favoriteList,
            existPublicProfile,
            searchBarHeight,
            selectAllInfluencersChecked,
            editExistingSearch,
            successMessage,
            plansDetails,
            existingSavedSearches,
            currencyList,
            publicModal,
            currentSocial,
            handle,
            secondModal
        } = this.state;
        const {
            actions: {
                clearAll,
                finishInvite,
                selectAll,
                selectionChange,
                setApiError,
                setData
            },
            apiError,
            appropriateInviteList,
            assignmentId,
            campaignId,
            currentPage,
            favorites,
            inviteInProgress,
            inviteList,
            missingChannelsErrorMessage,
            pageLimit,
            pageLimitOptions,
            permissions,
            profile,
            searchFilters,
            showDiscovery,
            showInviteSuccess,
            showMissingChannelsError,
            showMissingChannelsModal,
            showNoResults,
            showPleaseWait,
            showPleaseWaitWhite,
            sort,
            sortOrder,
            sortOptions,
            totalCount,
            disableMultipleInvite,
            influencers,
            alreadyInvitedUsers,
            planId,
            subId,
            currency,
            symbol,
        } = this.props;
        let currentSortFilter = sort;
        const keywordCheck = searchFilters.keywordFilters && searchFilters.keywordFilters.length !== 0;
        const isFilterEmpty = (!searchFilters.audienceFilters || searchFilters.audienceFilters.length === 0) &&
      (!searchFilters.commonFilters || searchFilters.commonFilters.length === 0)  &&
      (!searchFilters.connectedFilters || searchFilters.connectedFilters.length === 0) && !keywordCheck;
        const isSourceTypePublic = searchFilters.type === "PUBLIC";
        const sortOptionsBySource = isSourceTypePublic
            ? [followersPublic, engagementPublic, engagementRatePublic]
            : sortOptions;
        // Options betwen public and connected are different.
        // If current select option is not in sortOptions, than pick the first one as a back failsafe.
        const isOptionInSource = sortOptionsBySource.findIndex(e => e.value === sort);
        if (isOptionInSource == -1) {
            currentSortFilter = sortOptionsBySource[0];
        }
        if (showPleaseWaitWhite) {
            return <PleaseWaitWhite show={showPleaseWaitWhite} />;
        }

        let totalCountLimited = totalCount;
        if (
            isSourceTypePublic &&
      totalCount > PUBLIC_SOURCE_INFLUENCERS_MAX_COUNT
        ) {
            totalCountLimited = PUBLIC_SOURCE_INFLUENCERS_MAX_COUNT;
        }
        const isLastPublicSourcePage =
      currentPage * pageLimit === PUBLIC_SOURCE_INFLUENCERS_MAX_COUNT;

        const permission =
      profile &&
      profile.role &&
      permissions &&
      permissions.includes(`ROLE_${profile.role}`);

        const { formattedInfluencers, validCount } = this.formatInfluencersData();

        const { formattedInfluencers: formattedCreators } =
      this.formatInfluencersData(existPublicProfile);

        const availableInfluencersQty = formattedInfluencers.reduce(
            (qty, { inviteDisabled }) => {
                qty += !inviteDisabled ? 1 : 0;
                return qty;
            },
            0
        );

        const startIndex = (currentPage - 1) * pageLimit + 1;
        const endIndex = Math.min(startIndex + pageLimit - 1, totalCount);
        let listingStyle = null;
        if (searchBarHeight) {
            listingStyle = {
                top: `${
                    searchBarHeight + 24 + 60 + (!disableMultipleInvite ? 60 : 0) + (planId ? 55 : 0)
                }px`,
            };
        } else if (!disableMultipleInvite) {
            listingStyle = { top:  (planId ? "235px" : "180px") };
        } else if (planId) {
            listingStyle = { top: "175px" };
        }
        let allSelected = false;
        if (
            Array.isArray(influencers) &&
      Array.isArray(inviteList) &&
      Array.isArray(alreadyInvitedUsers)
        ) {
            const listWithoutAlreadyInvited = influencers.filter(
                (inf) =>
                    undefined ===
          alreadyInvitedUsers.find(
              (alreadyInvite) => alreadyInvite.user.uid === inf.uid
          )
            );
            allSelected =
        listWithoutAlreadyInvited.find(
            (influencer) =>
                undefined ===
            inviteList.find((invite) => invite.id === influencer.id)
        ) === undefined;
        }
        const socails = [{label: "Instagram", value: "instagram"}, {label: "YouTube", value: "youtube"}, {label: "TikTok", value: "tiktok"}]
        return (
            <div
                className={`influencers${
                    disableMultipleInvite ? " disableMultipleInvite" : ""
                }`}
            >
                {inviteList.length > 0 && inviteInProgress && (
                    <Invite
                        assignmentId={assignmentId || 0}
                        campaignId={campaignId || 0}
                        inviteList={inviteList}
                        appropriateInviteList={appropriateInviteList}
                        inviteInProgress={inviteInProgress}
                        afterSuccessFunction={finishInvite}
                        apiErrorFunction={setApiError}
                        finishFunction={this.closeInvite}
                        uninviteFunction={this.unInvite}
                    />
                )}
                <FeedbackOnTheTop
                    message="Invite successfully sent."
                    show={showInviteSuccess}
                    closeFunction={this.closeFeedbackOnTop}
                />

                <ErrorMessageBox
                    show={showMissingChannelsError}
                    message={missingChannelsErrorMessage}
                    closeFunction={this.closeMissingChannelsErrorMessage}
                />

                <PleaseWait show={showPleaseWait} />

                <ApiError
                    show={apiError}
                    error={apiError}
                    cancelFunction={this.closeErrorPopup}
                />
                <Modal
                    overlayClassName="influencerCardOverlay"
                    className="confirmRatesModal"
                    isOpen={publicModal}>
                    <div className="publicRequest">
                        <div className="titleSection">Request Public Profile</div>
                        <div className="middleSection">
                            <div className="desc">You can now request detailed audience insights for public accounts that we currently do not have on our
                                platform. We are only supporting Instagram, TikTok, and YouTube at this time. We will be adding other social networks
                                soon.</div>
                            <div className="selectionSection">
                                <div className="channel">
                                    <div className="title">Channel</div>
                                    <Select
                                        className="socials"
                                        options={socails}
                                        value={currentSocial}
                                        onChange={e => this.setState({currentSocial: e.value})}
                                        clearable={false}
                                        searchable={false}
                                        placeholder="Select"
                                    />
                                </div>
                                <div>
                                    <div className="title">Handle name</div>
                                    <input placeholder="@handle" maxLength={100} minLength={1} onChange={e=> this.handleChange(e.target.value)} value={handle} />
                                </div>
                            </div>
                        </div>
                        <div className="lastSection">
                            <div className="cancel" onClick={this.closeFunction}>Cancel</div>
                            <div className={cx("submit", (!handle || handle === '' || handle.length > 100 || !currentSocial) && "disabled")} onClick={this.submitFunction}>Submit</div>
                        </div>
                    </div>
                </Modal>
                <Modal
                    overlayClassName="influencerCardOverlay"
                    className="confirmRatesModal"
                    isOpen={secondModal}>
                    <div className="additionalModal">
                        <img src="/images/warning.svg" alt=""/>
                        <div className="title">Social Handle Exists</div>
                        <div className="desc">Public profile with the social handle you have provided already exists. Please change your search criteria
                            and try again.</div>
                        <div className="button" onClick={() => this.setState({secondModal: false})}>Okay</div>
                    </div>
                </Modal>
                <DiscoverPanel
                    searchFilters={searchFilters}
                    changeFunction={this.onFiltersChange}
                    disableMultipleInvite={disableMultipleInvite}
                    profile={this.props.profile}
                    setSearchBarHeight={this.setSearchBarHeight}
                    gotoProfile={this.gotoProfile}
                    setAdditionalitems={this.setAdditionalitems}
                    setTopFilter={this.setTopFilter}
                    editSearch={editExistingSearch}
                    setData={setData}
                    planId={planId}
                    subId={subId}
                    scrollContainer={this.listContainer}
                    currency={currency}
                    symbol={symbol}
                    publicButton={this.publicButton}
                    influencers={formattedInfluencers}
                />
                <SuccessBanner
                    show={successMessage !== ''}
                    closeFunction={() => this.setState({successMessage: ''})}
                    message={successMessage}
                />
                <div
                    className="listingNew"
                    ref={(e) => {
                        this.listContainer = e;
                    }}
                    style={listingStyle}
                >
                    <div className="bothContainer">
                        <div className="filterContainer">
                            {this.renderFilter && this.renderFilter()}
                        </div>
                        <div className="filterListingPlace">
                            {this.renderTopFilter && this.renderTopFilter()}
                            {isFilterEmpty && (
                                <div className="emptyResult">
                                    <div className="title">Hi there! Welcome to PRophet Influence powered by Koalifyed!</div>
                                    <img src="/images/Graphic_Creators with Social@3x.svg" alt="placeholder" />
                                    <div className="placeholder">Start your search by entering keyword or using the filters</div>
                                </div>
                            )}
                            {!isFilterEmpty && showNoResults && (
                                <div className="emptyResult">
                                    <img src="/images/Graphic_Creators with Social@3x.svg" alt="placeholder" />
                                    <div>No creators met your search criteria. Please try again by
                                        applying different filters.</div>
                                </div>
                            )}
                            {!isFilterEmpty && !showNoResults && (
                                <div className="listingContainerNew">
                                    <div className="influencerListingContainerNew">
                                        <ListingTop
                                            startIndex={startIndex}
                                            endIndex={endIndex}
                                            totalCount={totalCount}
                                            isFavoritesVisible={!isSourceTypePublic}
                                            permission={permission}
                                            sort={currentSortFilter}
                                            sortTypeChange={this.handleSortChange}
                                            pageLimit={pageLimit}
                                            changePageLimitFunction={this.handlePageLimitChange}
                                            selectAllFunction={
                                                allSelected ? this.clearCurrentPage : selectAll
                                            }
                                            validCount={validCount}
                                            allSelected={allSelected}
                                            selectedCount={inviteList.length}
                                            addToCampaignFunction={this.addToCampaignClickHandler}
                                            favoriteList={favoriteList}
                                            sortOptions={sortOptionsBySource}
                                            pageLimitOptions={pageLimitOptions}
                                            orderType={sortOrder}
                                            orderDisabled={currentSortFilter === "bestMatch"}
                                            orderTypeChange={this.handleOrderChange}
                                            refreshSearch={this.refreshSearch}
                                            refreshPlan={this.refreshPlan}
                                            favoriteData={favorites}
                                            inviteList={inviteList}
                                            setSuccess={message => this.setState({successMessage: message})}
                                            saveSearchFunction={this.onSaveSearch}
                                            existingSavedSearches={existingSavedSearches}
                                            searchFilters={searchFilters}
                                            planId={planId}
                                            currency={currency}
                                            handleCurrencyChange={this.handleCurrencyChange}
                                            currencyList={currencyList}
                                            publicButton={this.publicButton}
                                        />
                                    </div>

                                    {formattedInfluencers.map(
                                        ({ checked, details, id, invited, inviteDisabled, isAlreadyInPlan }) => (
                                            <OneInfluencer
                                                key={id}
                                                checked={checked}
                                                details={details}
                                                invited={invited}
                                                inviteDisabled={inviteDisabled}
                                                permission={permission}
                                                favoriteData={favorites}
                                                refreshSearch={this.refreshSearch}
                                                // internal={true}
                                                inviteFunction={this.inviteSingle} // invite single
                                                selectFunction={selectionChange} // changeSelection
                                                planId={planId}
                                                isAlreadyInPlan={isAlreadyInPlan}
                                                planName={plansDetails && plansDetails?.name || ''}
                                                setSuccess={message => this.setState({successMessage: message})}
                                                symbol={symbol}
                                                currency={currency}
                                                currencyList={currencyList}
                                                campaignId={campaignId}
                                            />
                                        )
                                    )}
                                    {isSourceTypePublic && isLastPublicSourcePage && (
                                        <div className="publicMaxPageLimitMessage">
                                            In order to see more relevant results, please use search
                                            or additional filters.
                                        </div>
                                    )}
                                    <PaginationNew
                                        total={totalCountLimited || 0}
                                        limit={pageLimit}
                                        current={currentPage}
                                        handlePageChange={this.handlePageChange}
                                        parentContainer={this.listContainer}
                                        limitChangeFunction={this.handlePageLimitChange}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {existPublicProfile &&
          formattedCreators &&
          formattedCreators.map(
              ({ checked, details, id, invited, inviteDisabled }) => (
                  <OneInfluencer
                      key={id}
                      checked={checked}
                      details={details}
                      invited={invited}
                      inviteDisabled={inviteDisabled}
                      permission={permission}
                      favoriteData={favorites}
                      refreshSearch={this.refreshSearch}
                      // internal={true}
                      inviteFunction={this.inviteSingle} // invite single
                      selectFunction={selectionChange} // changeSelection
                      showProfileSidePanel
                      closeProfileSidePanel={() =>
                          this.setState({ existPublicProfile: undefined })
                      }
                      planId={planId}
                      campaignId={campaignId}
                  />
              )
          )}
                {showMissingChannelsModal && <MissingChannelsModal />}
            </div>
        );
    }
}

const mapStateToProps = (state) => influencerBodySelector(state);
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            beforeAddToCampaign,
            clearAll,
            finishInvite,
            listSplashFilterChange,
            pageChange,
            pageLimitChange,
            searchFilterChange,
            selectAll,
            selectionChange,
            setApiError,
            setData,
            setGeneralData,
            setShowMissingChannelsError,
            sortFilterChange,
            orderTypeChange,
            refreshSearch,
            invitePageChange,
            clearCurrentPage,
            setCurrency
        },
        dispatch
    ),
});

Influencers.propTypes = {
    actions: PropTypes.shape({
        beforeAddToCampaign: PropTypes.func,
        clearAll: PropTypes.func,
        finishInvite: PropTypes.func,
        listSplashFilterChange: PropTypes.func,
        pageChange: PropTypes.func,
        pageLimitChange: PropTypes.func,
        searchFilterChange: PropTypes.func,
        selectAll: PropTypes.func,
        selectionChange: PropTypes.func,
        setApiError: PropTypes.func,
        setData: PropTypes.func,
        setGeneralData: PropTypes.func,
        setShowMissingChannelsError: PropTypes.func,
        sortFilterChange: PropTypes.func,
        setCurrency: PropTypes.func,
        orderTypeChange: PropTypes.func,
        invitePageChange: PropTypes.func,
        clearCurrentPage: PropTypes.func,
    }),
    alreadyInvitedUsers: PropTypes.array,
    apiError: PropTypes.object,
    appropriateInviteList: PropTypes.arrayOf(
        PropTypes.shape({
            fields: PropTypes.string,
            limit: PropTypes.number,
            page: PropTypes.number,
            sort: PropTypes.string,
            sortOrder: PropTypes.string,
            filters: PropTypes.arrayOf(PropTypes.string),
        })
    ),
    assignmentId: PropTypes.number,
    assignmentName: PropTypes.string,
    campaignId: PropTypes.number,
    currentPage: PropTypes.number,
    favorites: PropTypes.arrayOf(PropTypes.object),
    favoriteChangeCount: PropTypes.number,
    influencers: PropTypes.array,
    inviteInProgress: PropTypes.bool,
    inviteList: PropTypes.arrayOf(
        PropTypes.shape({
            fields: PropTypes.string,
            limit: PropTypes.number,
            page: PropTypes.number,
            sort: PropTypes.string,
            sortOrder: PropTypes.string,
            filters: PropTypes.arrayOf(PropTypes.string),
        })
    ),
    missingChannelsErrorMessage: PropTypes.string,
    pageLimit: PropTypes.number,
    pageLimitOptions: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.number,
            label: PropTypes.string,
        })
    ),
    permissions: PropTypes.arrayOf(PropTypes.string),
    profile: PropTypes.shape({
        id: PropTypes.number,
        uid: PropTypes.string,
        email: PropTypes.string,
        firstname: PropTypes.string,
        lastname: PropTypes.string,
        pictureUrl: PropTypes.string,
        authorities: PropTypes.array,
        roles: PropTypes.array,
        type: PropTypes.string,
    }),
    showDiscovery: PropTypes.bool,
    showInviteSuccess: PropTypes.bool,
    showMissingChannelsError: PropTypes.bool,
    showMissingChannelsModal: PropTypes.bool,
    showNoResults: PropTypes.bool,
    showPleaseWait: PropTypes.bool,
    showPleaseWaitWhite: PropTypes.bool,
    sort: PropTypes.string,
    sortOrder: PropTypes.string,
    sortOptions: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string,
        })
    ),
    totalCount: PropTypes.number,
    disableMultipleInvite: PropTypes.bool,
    planId: PropTypes.string,
    plansData: PropTypes.any,
    subId: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(Influencers);
