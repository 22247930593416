import {SET_ASSIGNMENTS_FOR_CAMPAIGN} from "../actions/campaignActionTypes";


export default function campaignReducer(state = {}, action) {
    switch (action.type) {
        // case GET_CAMPAIGN_LIST : {
        //     return Object.assign({}, state, {
        //         list : action.list
        //     })
        // }
        case SET_ASSIGNMENTS_FOR_CAMPAIGN : {
            let newProperty = {};
            newProperty['assignments' + action.campaignId] = action.assignmentsObject;
            if ( action.single) {
                newProperty['singleAssignment' + action.campaignId] = action.assignmentsObject.assignmentOptionSelection[0];
            }
            return Object.assign({}, state, newProperty)
        }
        default:
            return state;
    }
}