import React from 'react'
import './errorMessageBox.scss'

// props :
// - show
// - closeFunction
// - message
// - interval defaults to 5000

export default class ErrorMessageBox extends React.Component {

    constructor (props, context) {
        super(props, context)

        this.state = {
            timeout : null,
            interval : 5000
        };
    }

    componentWillUnmount() {
        clearTimeout(this.state.timeout);
    }

    static getDerivedStateFromProps(props, state) {
        let newState = {};
        if ( props.show && !state.show) {
            clearTimeout(state.timeout);
            let interval = state.interval;
            if (props.interval && !isNaN(props.interval)) {
                interval = Number(props.interval);
            }
            let timeout = setTimeout(props.closeFunction, interval);
            newState.timeout = timeout;
        }
        newState.show = props.show;
        return newState;
    }

    render () {
        let className="errorMessageBox";
        if (this.state.show) {
            className += ' active';
        }
        return (
            <div className={className} onClick={this.props.closeFunction}>
                <div>
                    <img src="/images/ic-error.svg" style={{height: 40, width: 40}}/>
                    {this.props.message}
                </div>
            </div>
        );
    }
}
