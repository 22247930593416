import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import './audience2.scss';
import '../common.scss';
import ReachIcon from '../shared/ReachIcon/ReachIcon';
import FollowersChart from './FollowersChart';
import { formatBigNumber, formatPercentageToWhole } from '../../modules/utils/helpers';

// props
//  - audience
//  - type
//  - handle
//  - label
//  - goBack function
//  - totalFollowers
//  - dateStatistics

const DEFAULT_NUMBER_OF_ITEMS_TO_SHOW = 5;
const MAXIMUM_NUMBER_OF_ITEMS_TO_SHOW = 25;

export default class Audience extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            expandStatus: {
                Income: true,
            },
        };
    }

    goBack = () => {
        // this.props.history.goBack();
        this.setState({
            expandStatus: { Income: true },
        });
        if (this.props.goBack) {
            this.props.goBack();
        }
    };

    seeMore = (key) => {
        const { expandStatus } = this.state;
        expandStatus[`${key}`] = !expandStatus[`${key}`];
        this.setState({
            expandStatus,
        });
    };

    renderSection = (key, originalData, expand = false, showMoreButton = true, column = 3) => {
        const contents = [];
        // Make copy of object to prevent object mutations
        const data = JSON.parse(JSON.stringify(originalData));

        const itemsLength = expand ? MAXIMUM_NUMBER_OF_ITEMS_TO_SHOW : DEFAULT_NUMBER_OF_ITEMS_TO_SHOW;
        // default to 3 columns
        const customStyle = column === 3 ? {} : { gridTemplateColumns: `repeat(${column}, ${100 / column}%)` };
        let hasMoreItems = false;
        const isDataEmpty = !data.length;

        if (key === 'Geography') {
            // If we have just countries - show top 15 countries only & split countries data to 3 columns
            const stateData = data.find((dataItem) => dataItem.name === 'State');
            const isStateDataValue = Boolean(stateData?.value?.length);

            const cityData = data.find((dataItem) => dataItem.name === 'City');
            const isCityDataValue = Boolean(cityData?.value?.length);

            const countryDataIndex = data.findIndex((dataItem) => dataItem.name === 'Country');

            if (!isStateDataValue && !isCityDataValue && countryDataIndex !== -1) {
                // split countries data to 3 columns
                const countryArray = data[countryDataIndex].value.slice(0, 15);
                data[countryDataIndex].value = countryArray.slice(0, 5);

                const secondColValue = countryArray.slice(5, 10);
                if (secondColValue.length) {
                    const secondCol = { name: '', value: secondColValue };
                    data.push(secondCol);

                    const thirdColValue = countryArray.slice(10, 15);
                    if (thirdColValue.length) {
                        const thirdCol = {
                            name: '',
                            value: thirdColValue,
                        };
                        data.push(thirdCol);
                    }
                }
            }
        }

        if (data && Array.isArray(data)) {
            for (let i = 0; i < data.length; i++) {
                const oneSetData = [];
                oneSetData.push(<div className="columnName">{data[i].name}</div>);
                if (data[i].value && Array.isArray(data[i].value)) {
                    const formattedData = this.dataHandling(data[i].value);
                    formattedData.slice(0, itemsLength).map((d) => {
                        const percentValue = formatPercentageToWhole(d.value);
                        const actualValue = formatBigNumber(d.value * 0.01 * this.props.totalFollowers);

                        if (d.icon) {
                            oneSetData.push(
                                <div className="cell">
                                    <div className="keyWithIcon">
                                        <img src={d.icon} className="keyIcon" alt={key} />
                                        <div className="key">{d.name}</div>
                                    </div>
                                    <div className="value">
                                        {percentValue}% ({actualValue})
                                    </div>
                                </div>,
                            );
                        } else {
                            oneSetData.push(
                                <div className="cell">
                                    <div className="key">{d.name}</div>
                                    <div className="value">
                                        {percentValue}% <span className="gray">({actualValue})</span>
                                    </div>
                                </div>,
                            );
                        }
                    });
                    if (formattedData.length > DEFAULT_NUMBER_OF_ITEMS_TO_SHOW) {
                        hasMoreItems = true;
                    }
                }
                if (data[i].name !== 'Ethnicity') {
                    contents.push(<div>{oneSetData}</div>);
                }
            }
        }
        return (
            <div className="sectionContainer">
                <div className="sectionName">{key}</div>

                {isDataEmpty ? (
                    <div className="noContentContainer">We are currently unable to provide any data at this time</div>
                ) : (
                    <div
                        className={cx(
                            contents.length > 2 && 'contentContainer',
                            contents.length <= 2 && 'contentContainerDouble',
                        )}
                        style={customStyle}
                    >
                        {contents}
                    </div>
                )}
                {hasMoreItems && showMoreButton && !isDataEmpty && (
                    <div className="seeMoreContainer" onClick={() => this.seeMore(key)}>
                        {expand ? 'Less' : 'See More'}
                        <img
                            src="/images/icon-arrow-left-grey-bg.svg"
                            alt="see more"
                            className={cx({ less: expand })}
                        />
                    </div>
                )}
            </div>
        );
    };

    renderSectionWithSingleItem = (key, data, expand = false, showMoreButton = true, column = 2) => {
        const contents = [];
        const itemsLength = expand ? MAXIMUM_NUMBER_OF_ITEMS_TO_SHOW : DEFAULT_NUMBER_OF_ITEMS_TO_SHOW;
        // default to 2 columns
        const customStyle = { gridTemplateColumns: `repeat(${column}, ${100 / column}%)` };
        let columnName = '';
        let hasMoreItems = false;

        if (data && Array.isArray(data) && data.length > 0) {
            columnName = data[0].name;
            if (data[0].value && Array.isArray(data[0].value)) {
                const formattedData = this.dataHandling(data[0].value);
                formattedData.slice(0, itemsLength).map((d) => {
                    const percentValue = formatPercentageToWhole(d.value);
                    const actualValue = formatBigNumber(d.value * 0.01 * this.props.totalFollowers);
                    if (d.icon) {
                        contents.push(
                            <div className="cell">
                                <div className="keyWithIcon">
                                    <img src={d.icon} className="keyIcon" alt={key} />
                                    <div className="key">{d.name}</div>
                                </div>
                                <div className="value">
                                    {percentValue}% <span className="gray">({actualValue})</span>
                                </div>
                            </div>,
                        );
                    } else {
                        contents.push(
                            <div className="cell">
                                <div className="key">{d.name}</div>
                                <div className="value">
                                    {percentValue}% <span className="gray">({actualValue})</span>
                                </div>
                            </div>,
                        );
                    }
                });
                if (formattedData.length > DEFAULT_NUMBER_OF_ITEMS_TO_SHOW) {
                    hasMoreItems = true;
                }
            }
        }
        return (
            <div className="sectionContainer">
                <div className="sectionName">{key}</div>
                <div className="columnName">{columnName}</div>
                <div className="contentContainer" style={customStyle}>
                    {contents}
                </div>
                {hasMoreItems && showMoreButton && (
                    <div className="seeMoreContainer" onClick={() => this.seeMore(key)}>
                        {expand ? 'Less' : 'See More'}
                        <img
                            src="/images/icon-arrow-left-grey-bg.svg"
                            alt="see more"
                            className={cx({ less: expand })}
                        />
                    </div>
                )}
            </div>
        );
    };

    dataHandling = (audienceValue) => {
        return audienceValue
            .map((item) => {
                if (typeof item.value === 'string') {
                    try {
                        item.value = parseFloat(item.value.replace('%', ''));
                    } catch (err) {
                        item.value = 0.0;
                    }
                }
                return item;
            })
            .sort((a1, a2) => a2.value - a1.value);
    };

    render() {
        const {
            audience: rawAudience,
            type,
            handle,
            label,
            status,
            showNoAudienceData,
            totalFollowers,
            dateStatistics,
            noHeader,
            profile,
        } = this.props;
        // Remove cards and columns without data
        const audience = rawAudience?.map((audienceItem) => ({
            ...audienceItem,
            value: audienceItem?.value?.filter((valueItem) => Boolean(valueItem?.value?.length)),
        }));

        const { expandStatus } = this.state;
        const contents = [];
        if (status && (status.toLowerCase() === 'inprogress' || status.toLowerCase() === 'notrequested')) {
            contents.push(
                <div className="infoMessage">
                    We are still working on retrieving the audience insights. Please check again later.
                </div>,
            );
        } else if ((status && status.toLowerCase() === 'nodata') || showNoAudienceData) {
            contents.push(<div className="infoMessage">There are no audience insights available at this time.</div>);
        } else if (audience && Array.isArray(audience)) {
            audience.map((data) => {
                if (data.name.toLowerCase() === 'income') {
                    contents.push(
                        this.renderSectionWithSingleItem(data.name, data.value, expandStatus[`${data.name}`], false),
                    );
                } else if (data.name.toLowerCase() === 'brand affinities') {
                    contents.push(
                        this.renderSectionWithSingleItem(data.name, data.value, expandStatus[`${data.name}`]),
                    );
                } else if (
                    (data.name.toLowerCase() === 'interests' && type !== 'tiktok' && type !== 'youtube') ||
                    !(data.name.toLowerCase() === 'interests')
                ) {
                    contents.push(this.renderSection(data.name, data.value, expandStatus[`${data.name}`]));
                }
            });
        }
        const audienceItems = [['Category', 'Audience Insight', 'Insight Breakdown', 'Percentage', 'Value']];
        if (profile) {
            audienceItems.push(['Creator Name', '', '', '', `${profile.firstname || ''} ${profile.lastname || ''}`]);
            audienceItems.push(['Social Media Channel', '', '', '', type]);
            audienceItems.push(['Social Media Handle', '', '', '', `@${handle}`]);
        }
        audience.forEach((item) => {
            item.value.forEach((iteml2) => {
                iteml2.value.forEach((iteml3) => {
                    const percentValue = `${formatPercentageToWhole(iteml3.floatValue)}%`;
                    const actualValue = iteml3.floatValue * 0.01 * totalFollowers;
                    audienceItems.push([item.name, iteml2.name, iteml3.name, percentValue, actualValue]);
                });
            });
        });
        return (
            <div className="audience">
                {!noHeader && (
                    <div className="backMenu" onClick={this.goBack}>
                        <img src="/images/icon-arrow-left.svg" alt="back" />
                        {label || ''}
                    </div>
                )}
                <div className="networkInfoContainer">
                    {type && !noHeader && (
                        <ReachIcon
                            network={type.toLowerCase() !== 'oauth2|youtube' ? type : 'Youtube'}
                            value={1}
                            size={14}
                            padding={5}
                            noToolTip
                        />
                    )}
                    <div className="networkInfo">
                        {!contents.length ? (
                            <div className="noPublicInfluencerInsightData">
                                Audience Insights for this account were not found. This may be due to account type being
                                "Private" or deleted.
                            </div>
                        ) : (
                            <div className="networkwithTag">
                                {!noHeader && (
                                    <div className="networkType">
                                        {type.toLowerCase() !== 'oauth2|youtube' ? type : 'Youtube'} Audience Insights
                                    </div>
                                )}
                                {noHeader && <div className="networkType">Audience Insights</div>}
                                {!noHeader && <div className="handle">{handle}</div>}
                            </div>
                        )}
                        {/* <div className="networkHandle">{handle}</div> */}
                    </div>
                    <CSVLink data={audienceItems} filename={`AudienceInsight.csv`}>
                        <div className="visitPage">Download Audience Insights</div>
                    </CSVLink>
                </div>
                {Boolean(dateStatistics.length) && (
                    <FollowersChart totalFollowers={totalFollowers} dateStatistics={dateStatistics} />
                )}
                {contents}
            </div>
        );
    }
}

Audience.propTypes = {
    audience: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    handle: PropTypes.string.isRequired,
    goBack: PropTypes.func.isRequired,
    status: PropTypes.string,
    totalFollowers: PropTypes.number,
    dateStatistics: PropTypes.array,
};

Audience.defaultProps = {
    audience: {},
    type: '',
    handle: '',
    goBack: () => {},
    status: '',
    totalFollowers: 0,
    dateStatistics: [],
};
