import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Format from '../../../../modules/utils/Format';
import '../campaignInfluencers.scss';
import InfluencerPhoto from '../../../oneInfluencerNew/InfluencerPhoto';

export default function ProfileInfo({
    authenticityRating,
    fullName,
    id,
    pictureUrl,
    totalReach,
    firstName,
    lastName,
    isPublic,
    linkToDetails
}) {
    const link = linkToDetails || `/influencerDetailPage/${id}`;

    return (
        <>
            <div className={cx("photo")}>
                {/* avatar badges */}
                {authenticityRating &&
                <>
                    <div
                        className="score"
                        style={{ left: '31px', top: '10px' }}
                    >
                        <img src="/images/Score.svg"/>
                        <span>
                            {Math.round(authenticityRating)}
                        </span>
                    </div>
                    <div
                        className="online"
                        style={{ left: '28px', top: "27px" }}
                    >
                        <img src="/images/Verified.svg"/>
                    </div>
                </>
                }
                {/* ============= */}
                {/* <img src={Format.influencerPhotoSrc(pictureUrl)}/> */}
                <InfluencerPhoto url={Format.influencerPhotoSrc(pictureUrl)} firstName={firstName} lastName={lastName} />
            </div>
            <div className="details">
                <div className="name">
                    <Link to={link} target="_blank">
                        {fullName}
                    </Link>
                    <div className="typeLabel">{isPublic ? "Public Creator" : "Connected Creator"}</div>
                </div>

                {/* <div className="reach">
                <span>Total Reach: </span> {Format.expressInK(Number(totalReach))}
                </div> */}
            </div>
        </>

    );
}

ProfileInfo.propTypes = {
    authenticityRating: PropTypes.number,
    fullName: PropTypes.string,
    id: PropTypes.number,
    pictureUrl: PropTypes.string,
    totalReach: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
};
