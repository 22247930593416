import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import './noteCreateDetails.scss';
import cx from 'classnames';
import NoteAvatar from './NoteAvatar';

const NoteCreate = ({
    onSave = () => null,
    onCancel = () => null,
    profile,
}) => {
    const [note, setNote] = useState('');
    const [showButton, setShowButton] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const inputReference = useRef(null);
    useLayoutEffect(() => {
        if (isFocused || note.length) {
            setShowButton(true);
        } else {
            setShowButton(false);
        }
    }, [isFocused, note]);

    const textChange = (e) => {
        e.target.style.height = (e.target.scrollHeight > e.target.clientHeight) ? (e.target.scrollHeight)+"px" : e.target.clientHeight+"px";
        setNote(e.target.value);
    };

    const onCancelClick = e => {
        if (inputReference && inputReference.current) {
            inputReference.current.style.height = "40px";
        }
        setNote('');
        onCancel();
    };

    const onSaveClick = () => {
        if (inputReference && inputReference.current) {
            inputReference.current.style.height = "40px";
        }
        onSave(note);
        setNote('');
    };

    const onFocus = () => {
        setIsFocused(true);
    };

    const onBlur = () => {
        setIsFocused(false);
    }

    return (
        <div className='noteCreateContainer2'>
            <div className="firstRow">
                <NoteAvatar
                    picUrl={profile?.pictureUrl}
                    firstName={profile?.firstname}
                    lastName={profile?.lastname}
                />
                <div className="noteCreateInputWrapper">
                    <div className="noteCreateInputContainer">
                        <textarea
                            onChange={textChange}
                            placeholder={'Enter your notes'}
                            // type="textarea" 
                            id="addToBaseInput"
                            value={note}
                            // multiple={true}
                            rows={1}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            ref={inputReference}
                        />
                    </div>
                </div>
            </div>
            {showButton && <div className='noteCreateButtonContainer'>
                    <div className='cancelButton' onClick={onCancelClick}>Cancel</div>
                    <div className={cx('saveButton', { enabled: note.length > 0 })} onClick={onSaveClick}>Save</div>
                </div>}
        </div>
    )
}

export default NoteCreate;