import React, { useMemo, useState } from 'react';
import './SummaryAudience.scss';
import Format from '../../modules/utils/Format';

const DataColumn = ({ data }) => {
    const defaultListLength = 5;
    const [isShowMore, setIsShowMore] = useState(false);

    const { name, audienceInsights } = data;

    if (audienceInsights?.length) {
        return (
            <div className="sectionColumn" key={name}>
                <div className="sectionColumnTitle">{name}</div>
                <div className="sectionColumnContent">
                    {audienceInsights.slice(0, isShowMore ? audienceInsights.length : defaultListLength).map((sectionContentItem) => (
                        <div key={sectionContentItem.name} className="sectionColumnRow">
                            <div className="sectionContentName">
                                {/* //TODO: Add brand image when one from BE is ready */}
                                {sectionContentItem.name ? sectionContentItem.name : ''}
                            </div>
                            <div className="sectionContentWeights">
                                <div className="bold">
                                    {sectionContentItem.weight
                                        ? `${sectionContentItem.weight * 100 < 1 ? '<1' : (sectionContentItem.weight * 100).toFixed(0)}%`
                                        : ''}
                                </div>
                                {sectionContentItem.weightedValue ? `(${Format.expressInK(sectionContentItem.weightedValue)})` : '-'}
                            </div>
                        </div>
                    ))}
                    {audienceInsights.length > defaultListLength && (
                        <div className="showMore" onClick={() => setIsShowMore(!isShowMore)} key={`${name}-showMore`}>
                            Show {isShowMore ? 'less' : 'more'}
                        </div>
                    )}
                </div>
            </div>
        );
    } else {
        return (
            <div className="sectionColumn">
                <div className="sectionColumnTitle">{name}</div>
                <div className="sectionColumnContent">
                    <div className="sectionColumnRow">
                        <div className="sectionContentName">No data found</div>
                    </div>
                </div>
            </div>
        );
    }
};

const AUDIENCE_INSIGHTS_ORDER = [
    "GEOGRAPHY",
    "DEMOGRAPHICS",
    "INTERESTS"
]

const SummaryAudience = ({ audienceData }) => {

    const sortedData = useMemo(() => {
        if (!audienceData) return []
        return [...audienceData].sort((a, b) => {
            try {
                const order_a = AUDIENCE_INSIGHTS_ORDER.indexOf(a.aggregatedAudienceInsightsGroupType) + 1 || 99
                const order_b = AUDIENCE_INSIGHTS_ORDER.indexOf(b.aggregatedAudienceInsightsGroupType) + 1 || 99
                return order_a - order_b
            } catch (e) {
                return 0
            }
        })
    }, [audienceData])

    return (
        <div className="summaryAudience">
            {sortedData.map(
                (mainSection) =>
                    Boolean(mainSection.aggregatedAudienceInsights.length) && (
                        <div className="section" key={mainSection?.aggregatedAudienceInsightsGroupType}>
                            <div className="sectionContent">
                                {mainSection.aggregatedAudienceInsightsGroupType && (
                                    <div className="sectionTitle">{mainSection.aggregatedAudienceInsightsGroupType ? mainSection.aggregatedAudienceInsightsGroupType[0].toUpperCase()+mainSection.aggregatedAudienceInsightsGroupType.slice(1).toLowerCase():'N/A'}</div>
                                )}
                                <div className="sectionColumnsWrapper">
                                    {mainSection.aggregatedAudienceInsights?.map((sectionPart) => (
                                        <DataColumn key={`${sectionPart.name}-wrapper`} data={sectionPart} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    ),
            )}
        </div>
    );
};

export default SummaryAudience;
