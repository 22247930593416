import { call, put, select } from 'redux-saga/effects';
import { allAssignmentsFilter } from './reducer';
import { setAssignmentSelectData } from './actionCreators';
import { getNextSelectedOptions } from '../helpers';

export default function* onAssignmentSelectChange({ selectValue, updateSaga }) {
    const { assignmentSelectOptions } = yield select(({ selects }) => ({
        assignmentSelectOptions: selects.assignment.selectOptions,
    }));

    const nextSelectedAssignments = getNextSelectedOptions(selectValue, assignmentSelectOptions, allAssignmentsFilter);

    yield put(setAssignmentSelectData({
        selectedOptions: nextSelectedAssignments,
    }));

    // trigger specific data update if needed
    if (typeof updateSaga === 'function') {
        yield call(updateSaga);
    }
}
