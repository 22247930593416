import toNumber from "lodash/toNumber";
import get from "lodash/get";
import utils from './utils';
import Api from '../../../modules/Api';

const onChangePageLimit = (onChangeStatement, paramsData, advertiserIds) => async (option) => {
    onChangeStatement(() => ({ isLoading: true }));

    const { brandItemsFactory } = utils;

    const { value: amountPerPage } = option;

    const numPerPage = toNumber(amountPerPage);

    const data = { ...paramsData, num: numPerPage }

    try {
        const brands = await Api.getUserBrands(data, advertiserIds)

        const result = brandItemsFactory(get(brands, 'results'));

        const brandsMeta = get(brands, 'meta');

        onChangeStatement(({ filters }) => ({
            brandsList: result,
            pagination: {
                currentPage: 1,
                total: get(brandsMeta, 'totalCount'),
            },
            filters: { ...filters, numPerPage },
            isLoading: false,
        }))
    } catch (error) {
        console.log(error);
        onChangeStatement(() => ({ isLoading: false }));
    }
}

const onChangeBrandsStatus = (onChangeStatement, paramsData, advertiserIds) => async (selectedStatus) => {
    onChangeStatement(() => ({ isLoading: true }));

    const { brandItemsFactory } = utils;

    const brandsStatus = selectedStatus === 'archived';

    const statusQuery = `&isArchived=${brandsStatus}`

    try {
        const brands = await Api.getUserBrands({ ...paramsData, status: statusQuery }, advertiserIds)

        const result = brandItemsFactory(get(brands, 'results'));

        const brandsMeta = get(brands, 'meta');

        onChangeStatement(({ filters }) => ({
            brandsList: result,
            pagination: {
                currentPage: 1,
                total: get(brandsMeta, 'totalCount'),
            },
            filters: {
                ...filters,
                brandsStatusQuery: statusQuery,
                brandsStatusValue: selectedStatus,
            },
            isLoading: false,
        }))
    } catch (error) {
        console.log(error);
        onChangeStatement(() => ({ isLoading: false }));
    }

}

const onChangePage = (onChangeStatement, paramsData, advertiserIds) => async (pageNumber) => {
    onChangeStatement(() => ({ isLoading: true }));

    const { brandItemsFactory } = utils;

    const data = {
        ...paramsData,
        pageNumber,
    }

    try {
        const brands = await Api.getUserBrands(data, advertiserIds);

        const result = brandItemsFactory(get(brands, 'results'));

        onChangeStatement(({ pagination, filters }) => ({
            brandsList: result,
            filters,
            pagination: { ...pagination, currentPage: pageNumber },
            isLoading: false,
        }));
    } catch (error) {
        onChangeStatement(() => ({ isLoading: false }));
        console.log(error);
    }
};


const onChangeFilterVariable = (onChangeStatement, data) => async (selectedOption) => {
    const { brandItemsFactory } = utils;

    const filterValue = get(selectedOption, 'value', '');

    onChangeStatement(() => ({ filterValue }));

    try {
        if (filterValue === 'all' || !filterValue) {
            const brands = await Api.getUserBrands({ ...data, filter: undefined });

            const total = get(brands, 'meta.totalCount');

            const result = brandItemsFactory(get(brands, 'results'));

            onChangeStatement(({ filters }) => ({
                brandsList: result,
                pagination: {
                    total,
                    currentPage: 1,
                },
                filters: {
                    ...filters,
                    advertiserValue: '',
                    advertiserQuery: undefined,
                }
            }));
        }
    } catch (error) {
        onChangeStatement(() => ({ isLoading: false }));
        console.log(error);
    }
};

const onAdvertiserFilter = (onChangeStatement, paramsData, advertiserIds) => async ({ id, value }) => {
    onChangeStatement(() => ({ isLoading: true }));

    const { brandItemsFactory } = utils;

    const advertiserQuery = id ? `&advertiserId=${id}` : undefined;

    try {

        const data = { ...paramsData, filter: advertiserQuery };

        const brands = await Api.getUserBrands(data, id ? null : advertiserIds);

        const total = get(brands, 'meta.totalCount');

        const result = brandItemsFactory(get(brands, 'results'));

        onChangeStatement(({ filters }) => ({
            brandsList: result,
            pagination: {
                total,
                currentPage: 1,
            },
            filters: {
                ...filters,
                advertiserValue: value,
                advertiserQuery,
            },
            isLoading: false,
        }));
    } catch (error) {
        onChangeStatement(() => ({ isLoading: false }));
        console.log(error);
    }

};

const onBrandChange = (onChangeStatement, paramsData, advertiserIds) => async ({ searchString }) => {
    onChangeStatement(() => ({ isLoading: true }));

    const { brandItemsFactory } = utils;

    const advertiserQuery = searchString ? `&searchString=${searchString}` : undefined;

    try {

        const data = { ...paramsData, filter: advertiserQuery };

        const brands = await Api.getUserBrands(data, advertiserIds);

        const total = get(brands, 'meta.totalCount');

        const result = brandItemsFactory(get(brands, 'results'));

        onChangeStatement(({ filters }) => ({
            brandsList: result,
            pagination: {
                total,
                currentPage: 1,
            },
            filters: {
                ...filters,
                advertiserQuery,
                searchString
            },
            isLoading: false,
        }));
    } catch (error) {
        onChangeStatement(() => ({ isLoading: false }));
        console.log(error);
    }

};

const addBrandCountryOperation = (onChangeStatement, brandsList, successCallback, advertiserIds) => async (data, parentBrandId) => {
    onChangeStatement(() => ({ isLoading: true }));
    const { convertBrandItem, brandItemsFactory } = utils;
    let brand = null;
    try {
        brand = await Api.addBrandCountry(parentBrandId, data);
    } catch(error) {
        onChangeStatement(() => ({
            hasError: true,
            errorMessage: error.originalMessage, 
            isLoading: false,
        }));
        return;
    }

    try {
        const brands = await Api.getUserBrands({}, advertiserIds);
        const result = brandItemsFactory(get(brands, 'results'));
        const brandsMeta = get(brands, 'meta');
        onChangeStatement(() => ({
            showModal: false,
            brandsList: result,
            isLoading: false,
            pagination: {
                currentPage: 1,
                total: get(brandsMeta, 'totalCount'),
            },
        }))

        if (successCallback)
            successCallback();
    } catch (error) {
        const { message: errorMessage } = error;
        onChangeStatement(() => ({
            hasError: true,
            errorMessage,
            isLoading: false,
        }));
    }
};

const brandOperations = (onChangeStatement, brandsList, advertiserIds) => async (operationType, data, brandId) => {
    onChangeStatement(() => ({ isLoading: true }));

    const { convertBrandItem, brandItemsFactory } = utils;
    let brand = null;
    try {
        brand = operationType === 'create' ?
            await Api.createBrand(data) :
            await Api.updateBrandAddress(brandId, data);
    } catch(error) {
        onChangeStatement(() => ({
            hasError: true,
            errorMessage: operationType === 'create' && error.originalMessage ? error.originalMessage : error.message, 
            isLoading: false,
        }));
        return;
    }

    try {
        const convertedBrand = convertBrandItem(brand);

        if (operationType === 'create') {

            const brands = await Api.getUserBrands({}, advertiserIds);

            const result = brandItemsFactory(get(brands, 'results'));
            const brandsMeta = get(brands, 'meta');
            onChangeStatement(() => ({
                showModal: false,
                brandsList: result,
                isLoading: false,
                pagination: {
                    currentPage: 1,
                    total: get(brandsMeta, 'totalCount'),
                },
            }))
        } else {
            const copiedBrands = [...brandsList];

            const updatedBrandIndex = brandsList.findIndex((item) => item.id === convertedBrand.id);

            copiedBrands[updatedBrandIndex] = convertedBrand;

            onChangeStatement(() => ({
                showModal: false,
                brandsList: copiedBrands,
                isLoading: false,
            }))
        }
    } catch (error) {
        const { message: errorMessage } = error;

        onChangeStatement(() => ({
            hasError: true,
            errorMessage,
            isLoading: false,
        }));
    }
};

const onInitializeData = (onChangeStatement, userAdvertisers, advertiserIds) => async (advertiserId, onFilterByAdvertiser) => {
    onChangeStatement(() => ({ isLoading: true }));

    const { brandItemsFactory } = utils;

    const brandsResponse = await Api.getUserBrands({}, advertiserIds);

    // in usage for modal select options
    // const advertisersResponse = await Api.getAdvertisers(1, 50);

    // const brandOwnersResponse = await Api.getAgentList(200);

    // // const userAdvertisers = get(advertisersResponse, 'results');
    // const userBrandOwners = get(brandOwnersResponse, 'results');

    const brands = brandItemsFactory(get(brandsResponse, 'results'));
    const brandsMeta = get(brandsResponse, 'meta');

    const predefinedAdvertiser = userAdvertisers.find((advertiser) => advertiser.id === Number(advertiserId));

    // filtered by predefined advertiser
    /** brands can be filtered by a predefined advertiser value */
    const advertiserFilters = {
        advertiserQuery: advertiserId ? `&advertiserId=${advertiserId}` : undefined,
        advertiserValue: get(predefinedAdvertiser, 'name', undefined),
    }
    /** --- */
    onChangeStatement(({ filters }) => ({
        brandsList: brands,
        userAdvertisers,
        // userBrandOwners,
        filterValue: advertiserId ? 'advertiser' : 'all',
        pagination: {
            currentPage: 1,
            total: get(brandsMeta, 'totalCount'),
        },
        filters: {
            ...filters,
            ...advertiserFilters,
        },
        isLoading: false,
    }));

    if (advertiserId) {
        const payload = { id: advertiserId, value: get(predefinedAdvertiser, 'name') }
        onFilterByAdvertiser(payload);
    }
};

export default {
    onChangePage,
    onAdvertiserFilter,
    brandOperations,
    onChangeFilterVariable,
    onChangeBrandsStatus,
    onChangePageLimit,
    onInitializeData,
    onBrandChange,
    addBrandCountryOperation
};
