import React from 'react';
import Select from 'react-select-v5';
import './multiselect.scss';

function MultiSelect({ name, values, options, setValues }) {
    return (
        <Select
            value={values}
            onChange={(newValues) => newValues && setValues(newValues)}
            isMulti
            name={name}
            options={options}
            className="multi-select"
            classNamePrefix="select"
            isClearable={false}
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
        />
    );
}

export default MultiSelect;
