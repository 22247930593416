import React, { useEffect, useState } from 'react';
import Api from '../../modules/Api';
import ApiError from '../shared/apiError/ApiError';
import './SummaryTab.scss';
import PleaseWaitWhite from '../shared/pleaseWait/PleaseWaitWhite';
import SummaryTable from './SummaryTable';
import SummaryAudience from './SummaryAudience';
import { Loading } from 'aws-amplify-react';
import PulseLoader from '../shared/pulseLoader/PulseLoader';

const SummaryTab = ({ setSocials, plansData, setFeatures, setAudience, setUnique, setUniqueError, onChange = () => null }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [uniqueReachIsLoading, setUniqueReachIsLoading] = useState(false);
    const [featureTotals, setFeatureTotals] = useState(null);
    const [platformsData, setPlatformsData] = useState(null);
    const [uniqueData, setUniqueData] = useState(null);
    const [audienceTotals, setAudienceTotals] = useState(null);
    const [apiError, setApiError] = useState();
    const [errTitle, setErrTitle] = useState('');
    const [selectedTab, setSelectedTab] = useState('All Channels');

    const getPlanSummaryData = async () => {
        setIsLoading(true);
        try {
            const response = await Api.getPlanSummary(plansData.id);
            const { featureTotals, platforms, aggregatedAudienceInsightsTotals } = response;
            setFeatureTotals(featureTotals);
            platforms.forEach(item => {
                const newItem = {planSummaryFeatureType: 'ENGAGEMENT_VIEWS'}
                if (item.planSummaryFeatures) {
                    const engagement = item.planSummaryFeatures.find(x => x.planSummaryFeatureType === 'AVG_ENGAGEMENT')
                    const views = item.planSummaryFeatures.find(x => x.planSummaryFeatureType === 'AVG_VIEWS')
                    if (engagement && views && engagement.weightedValue && views.weightedValue) {
                        newItem.weight = engagement.weightedValue/views.weightedValue
                    }
                    item.planSummaryFeatures.push(newItem)
                }
            })
            setPlatformsData(platforms);
            setAudienceTotals(aggregatedAudienceInsightsTotals);
            if (setSocials) {
                setSocials(platforms);
            }
            if (setFeatures) {
                setFeatures(featureTotals);
            }
            if (setAudience) {
                setAudience(aggregatedAudienceInsightsTotals);
            }
        } catch (err) {
            handleApiError(err);
        } finally {
            setIsLoading(false);
        }
    };

    const getUniqueReachData = async () => {
        // setIsLoading(true);
        setUniqueReachIsLoading(true);
        try {
            const response = await Api.getUniqueReach(plansData.id);
            setUniqueData(response);
            if (setUnique) {
                setUnique(response);
            }
        } catch (err) {
            handleApiError(err);
            if (setUniqueError) {
                setUniqueError(true);
            }
        } finally {
            setUniqueReachIsLoading(false);
        }
    };

    useEffect(() => {
        getUniqueReachData();
    }, []);

    const onTabClickHandler = (tab) => {
        setSelectedTab(tab);
    };

    const filterTableDataBySelectedPlatform = (tableData, selectedTab) => {
        if (selectedTab && selectedTab !== 'All Channels') {
            const filteredData = tableData.filter((platformData) => platformData.platform === selectedTab.toLowerCase());
            return filteredData;
        }
        return tableData;
        
    };

    const filterCreatorsByPlatform = (selectedTab, creatorsGroup) => {
        if (selectedTab && selectedTab !== 'All Channels') {
            const filteredCreators = creatorsGroup.filter((creator) =>
                creator?.userNetworks.some((network) => network.networkType === selectedTab),
            );
            return filteredCreators;
        } 
        return [];
        
    };

    const filterAudienceInsightsByPlatform = (selectedTab) => {
        if (selectedTab && selectedTab !== 'All Channels') {
            const filteredPlatform = platformsData.filter((platform) => platform.platform === selectedTab);
            return filteredPlatform[0].aggregatedAudienceInsightsGroups;
        } 
        return audienceTotals;
        
    };

    const handleApiError = (err) => {
        setApiError(err);
        if (err && err.status) {
            setErrTitle(`Error: ${err.status}`);
        }
    };

    const CancelError = () => {
        setApiError(null);
        setErrTitle('');
    };

    useEffect(() => {
        getPlanSummaryData();
    }, []);
    return (
        <>
            <div className="innerTabsWrapper">
                <div className="innerTabsContainer">
                    <div
                        className={`innerTabWrapper ${selectedTab === 'All Channels' ? 'active' : ''}`}
                        onClick={() => onTabClickHandler('All Channels')}
                    >
                        All Channels
                    </div>
                    {platformsData &&
                        platformsData.map((platformData) => (
                            <div
                                key={platformData?.platform}
                                className={`innerTabWrapper ${selectedTab === platformData?.platform ? 'active' : ''}`}
                                onClick={() => onTabClickHandler(platformData?.platform)}
                            >
                                {(platformData?.platform[0].toUpperCase() + platformData?.platform.slice(1)) === "Youtube" ? "YouTube" : (platformData?.platform[0].toUpperCase() + platformData?.platform.slice(1))}
                            </div>
                        ))}
                </div>
                <div className="innerTabsActionButtonsWrapper">
                    <div className="button" onClick={getPlanSummaryData}>
                        <span>Refresh</span>
                    </div>
                    {/* <div className="button button--green">
                        <span>Download PDF</span>
                    </div> */}
                </div>
            </div>
            <div className="tabContent">
                <PleaseWaitWhite show={isLoading} />
                <ApiError show={apiError} errTitle={errTitle} error={apiError} cancelFunction={CancelError} />
                <div className="tablesWrapper">
                    {platformsData && (
                        <SummaryTable
                            selectedTab={selectedTab}
                            tableHeads={[
                                'Platform',
                                'Creators',
                                'Total Followers',
                                'Avg Weighted Cred Score',
                                'Estimated Real Followers',
                                'Avg Weighted Eng. Rate',
                                'Avg Weighted Eng.',
                                'Avg Weighted Spon. Eng. Rate',
                                'Avg Weighted Spon. Eng.',
                                'Avg Weighted View Rate',
                                'Avg Weighted Views',
                                'Avg Weighted Eng. Rate by Views'
                            ]}
                            tableHeadsToolTip={[
                                '',
                                '',
                                'Total number of followers',
                                'Overall credibility score of all creator audience - how likely the audience  includes real person vs bots, weighted by follower count and by channel',
                                '',
                                'Percentage of engagements by total followers, weighted by follower count of creators and by channel.',
                                'Estimated engagements - resulted by applying the Avg weighted engagement rate to the total reach (follower) count',
                                'Percentage of enagagements (likes/comments) by total followers on sponsored posts, weighted by follower count of creators and by channel',
                                'Estimated sponsored engagement - resulted by applying the avg weighted sponsored engagement rate to the total reach (follower) count',
                                'Percentage of views by total followers, weighted by follower count of creators and by channel',
                                'Estimated views calculated by applying the avg weighted view rate to the total reach (follower) count',
                                'Percentage of engagements (likes/comments) by views, weighted by follower count of creators and by channel.'
                            ]}
                            tableSummary={featureTotals}
                            tableData={filterTableDataBySelectedPlatform(platformsData, selectedTab)}
                            isWithSummary
                        />
                    )}

                    <p className="sectionTitle">Unique Followers Analysis on Top Creators</p>

                    <p className="sectionTitle sectionTitle__subtitle">
                        Only Available for Instagram and YouTube. Analysis includes top creators to up to 300 million followers
                    </p>

                    {uniqueReachIsLoading && (
                        <p style={{ padding: 32 }}>
                            <PulseLoader color="#4AA129" size="16px" margin="4px" />
                        </p>
                    )}

                    {uniqueData && (
                        <SummaryTable
                            tableHeads={[
                                'Channel',
                                'Creators',
                                'Total Followers',
                                'Unique Followers',
                                'Avg Weighted Eng. Rate',
                                'Forecasted Unique Eng.',
                                'Avg Weighted Spon. Eng. Rate',
                                'Forecasted Unique Spon. Eng.',
                                'Avg Weighted View Rate',
                                'Forecasted Unique Views',
                            ]}
                            tableHeadsToolTip={[
                                '',
                                '',
                                'Total number of followers',
                                'Number of unique followers resulted by deduplicating all the followers from the list of creators on the plan. If one person is following multiple creators in the plan, system would deduce the count back to one. This would provide an estimated number that is closer to actual unique follower count.',
                                'Percentage of enagagements (likes/comments) by total followers, weighted by follower count of creators and by channel',
                                'Estimated unique engagements calculated by applying the avg weighted engagement rate to the unique reach (unique follower) count',
                                'Percentage of engagements (likes/comments) by total followers on sponsored posts, weighted by follower count of creators and by channel',
                                'Estimated unique sponsored engagement calculated by applying the avg weighted sponsored engagement rate to the unique reach (unique follower) count',
                                'Percentage of views by total followers, weighted by follower count of creators and by channel',
                                'Estimated unique views calculated by applying the avg weighted view rate to the unique reach (unique follower) count',
                            ]}
                            tableData={filterTableDataBySelectedPlatform(uniqueData, selectedTab)}
                            isUnique
                        />
                    )}

                    {Boolean(audienceTotals?.length) && (
                        <>
                            <p className="sectionTitle">Aggregated Audience Insights</p>
                            <SummaryAudience audienceData={filterAudienceInsightsByPlatform(selectedTab)} />
                        </>
                    )}

                    {(plansData.influencers || plansData.iqUsers) && selectedTab !== 'All Channels' && (
                        <>
                            <p className="sectionTitle">Creators</p>
                            <SummaryTable
                                tableHeads={['#', 'Account', 'Total Followers', ' ']}
                                tableHeadsToolTip={['', '', '', '']}
                                selectedTab={selectedTab}
                                planId={plansData.id}
                                tableData={[
                                    ...filterCreatorsByPlatform(selectedTab, plansData?.influencers),
                                    ...filterCreatorsByPlatform(selectedTab, plansData?.iqUsers),
                                ]}
                                isCreatorsTable
                                setIsLoading={setIsLoading}
                                onChange={onChange}
                            />
                        </>
                    )}
                    <div style={{ height: 120 }} />
                </div>
            </div>
        </>
    );
};

export default SummaryTab;
