import React from 'react'
import './pastBrands.scss';

// props
// - profile

export default class PastBrands extends React.Component {
    constructor (props, context) {
        super(props, context);

        this.renderedColumns = this.renderedColumns.bind(this);
        this.renderedColumn = this.renderedColumn.bind(this);
        this.renderedBrand = this.renderedBrand.bind(this);
    }

    renderedBrand(brand, ix) {
        return <div key={ix} className="pastBrand">
            { brand }
        </div>
    }

    renderedColumn(column, ix) {
        let renderedBrands = [];
        for (let i=0; i<column.length; i++) {
            renderedBrands.push(this.renderedBrand(column[i], i));
        }
        return <div key={ix} className="brandColumn">
            {renderedBrands}
        </div>
    }

    renderedColumns(brands) {
        let columns = [ [], [], [], []];
        for (let i=0; i<brands.length; i++) {
            columns[ i%4 ].push(brands[i]);
        }
        let renderedColumns = [];
        for (let i=0; i<4; i++) {
            renderedColumns.push(this.renderedColumn(columns[i], i));
        }
        return renderedColumns;
    }

        render() {
        return null;
        let brands = this.props.profile.brands;
        let haveBrands = Array.isArray(brands) && brands.length > 0;
        let title = 'Past Brands ' + this.props.profile.firstname + ' has worked with';
        return (
            <div className="pastBrands">
                <div className="title">
                    {title}
                </div>
                { !haveBrands && <div style={{color: '#999', fontSize:'16px'}}>None</div> }
                { haveBrands && this.renderedColumns(brands) }
            </div>
        )
    }
}