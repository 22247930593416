import React from 'react'
import RateIcon from "../../../shared/rateIcon/RateIcon";
import { ACTIVE_TAB } from '../../../../constants/rateTypes';
import Format from '../../../../modules/utils/Format';

const getNetworkNameByChannel = (channel) => {
    if (channel === 'INSTAGRAM_POST' || channel === 'instagramPost') {
        return 'IG Post';
    } 
    
    if (channel === 'INSTAGRAM_STORY' || channel === 'instagramStory') {
        return 'IG Story';
    }

    if (channel === "YOUTUBE") {
        return 'YouTube'
    }
    
    if (channel) {
        return channel.replace('_', ' ').toLowerCase();
    }
    return channel;
};

const ProfileRates =({profile, currency, symbol, currencyList}) =>{
    const rateList = Object.keys(profile.rates)
        .filter((rate) => (rate !== ACTIVE_TAB && rate !== 'GENERAL'))
        .sort();

    if (Object.keys(profile.rates).includes('GENERAL')) {
        rateList.push('GENERAL');
    }
    let exchangeRates = 1;
    const findDiff = () => {
        if (currencyList) {
            const profileCurrency = currencyList.find(e => e.value === profile.currencyCode)
            const selectedCurrency = currencyList.find(e => e.value === currency)
            return (profileCurrency && selectedCurrency) ? profileCurrency.exchangeRateToUsd/selectedCurrency.exchangeRateToUsd : 1
        }
        return 1
    }
    if (profile.currencyCode !== currency) {
        exchangeRates = findDiff();
    }
    return (
        <div className="displayRates">
            <div>
                <div className="label">Rates</div>
                {currency && <div className="currencySection">Currency: <span>{currency} ({symbol})</span></div>}
                {rateList.map(item => {
                    return profile.rates[item] !== null ?
                        <div className="rateBlock" key={item}>
                            <RateIcon rateKey={item} size={20} value={Math.round(profile.rates[item] * exchangeRates)} padding={3} iconSize={14} />
                            <div className="rateChannel">{getNetworkNameByChannel(item) === 'general' ? 'Per Campaign' : getNetworkNameByChannel(item)}:</div>
                            <div className="rateValue">
                                {Number(profile.rates[item]) > 0 ? Format.expressInDollars(Math.round(profile.rates[item] * exchangeRates), symbol) : ' - '}
                            </div>
                        </div>
                        :
                        null
                })}
            </div>
        </div>
    );
};

export default ProfileRates
