import React, { Fragment } from 'react';
import { debounce } from 'lodash';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import Select from 'react-select';
import cx from 'classnames';
import { bindActionCreators } from 'redux';
import Api from '../../../modules/Api';
import ApiError from '../../shared/apiError/ApiError';
import { fetchFavorites } from '../../../store/actions/favoriteActions';
import './discoverPanel.scss';
import './filterOperation.scss';
import FilterMenu from './FilterMenu';
import Lookup from '../../../modules/Lookup';
import FavoriteMenu from './FavoriteMenu';
import { KeywordsInput } from './KeywordsInput';
import { VIEWER_STR } from '../../../constants/authorities';
import { FILTERS_KEYWORDS } from '../../../constants/filtersNameConstants';
import PopUpDialogueBase from '../../shared/popUpDialogueBase/PopUpDialogueBase';
import { getActiveChannelName, getKwFilters } from './DiscoverPanel.helpers';
import { LOOKUP_FILTER_GROUP } from '../../../constants/lookup-constants';
import { sortFilterChange } from '../../../store/campaign/influencerSearch/actionCreators';
import { bestMatch, followersPublic } from '../../../store/campaign/influencerSearch/reducer';
import ReachIcon from '../../shared/ReachIcon/ReachIcon';
import PendoHelper from '../../../modules/utils/PendoHelper';
import { keywordSanitization } from './FilterMenu.helpers';
import TopicTensorInput from './TopicTensorInput';

const handleOptions = [
    { value: 'Instagram', label: 'Instagram' },
    { value: 'Tiktok', label: 'Tiktok' }
];

let activeChannelSelectedPublic = 'instagram';
let activeChannelSelectedConnected = '';
let activeCommonFilterSelected = '';
let activeAudienceFilterSelected = '';
let activeKeywordFilterSelectedConnected = [];
let activeKeywordFilterSelectedPublic = [];

class DiscoverPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // filters: [],
            showFilter: false,
            showSelectOption: false,
            showSelectOption2: false,
            keywords: [],
            checkedKeywordFilterId: null,
            showFavorite: false,
            allFiltersData: {
                type: 'PUBLIC',
                requiredChannels: ["INSTAGRAM"],
                commonFilters: [],
                connectedFilters: [],
                audienceFilters: [],
            },
            keywordFilters: [],
            commonFilters: [],
            connectedFilters: [],
            audienceFilters: [],
            favFiltersOptions: [],
            addedFilters: { CONNECTED: [], PUBLIC: [] },
            selectedFilters: { CONNECTED: [], PUBLIC: [] },
            channelSelect: {
                CONNECTED: {
                    blog: false,
                    facebook: false,
                    instagram: false,
                    tiktok: false,
                    twitter: false,
                    youtube: false,
                },
                PUBLIC: {
                    instagram: true,
                    tiktok: false,
                    youtube: false
                },
            },
            isKeywordInputFocused: false,
            height: 60,
            showRequestInstagramProfile: false,
            instagramHandle: '',
            showInvalidHandle: false,
            showConfirmRequestProfile: false,
            showRequestReceived: false,
            existingProfile: null,
            showApiError: false,
            apiError: null,
            handleSelected: 'Instagram',
            selectedKeywords: { CONNECTED: [], PUBLIC: [] },
            disabledSelection: false
        };

        this.searchBarRef = React.createRef();
        this.discoverPanelContainerNewRef = React.createRef();
    }

    updateDataFromEditSearch = () => {
        const {editSearch } = this.props;
        const {selectedFilters, addedFilters, allFiltersData, channelSelect, selectedKeywords} = this.state;
        if (editSearch) {
            if (editSearch.type !== allFiltersData.type) {
                allFiltersData.type = editSearch.type;
            }
            if (editSearch?.keywordFilters && Array.isArray(editSearch.keywordFilters)) {
                editSearch.keywordFilters.forEach((s) => {
                    selectedFilters[editSearch.type].push(s);
                    selectedKeywords[editSearch.type].push(s);
                })
            }
            if (editSearch?.selectedFilters && Array.isArray(editSearch.selectedFilters)) {
                editSearch.selectedFilters.forEach((s) => {
                    selectedFilters[editSearch.type].push(s);
                })
            }
            if (editSearch?.commonFilters && Array.isArray(editSearch.commonFilters)) {
                editSearch.commonFilters.forEach(s => {
                    addedFilters[editSearch.type].push(s);
                    allFiltersData.commonFilters.push(s);
                })
            }
            if (editSearch?.audienceFilters && Array.isArray(editSearch.audienceFilters)) {
                editSearch.audienceFilters.forEach(s => {
                    addedFilters[editSearch.type].push(s);
                    if (allFiltersData?.audienceFilters && Array.isArray(allFiltersData.audienceFilters)) {
                        const f = allFiltersData.audienceFilters.find(af => af.filterId === s.filterId);
                        if (!f) {
                            allFiltersData.audienceFilters.push(s);
                        }
                    }
                })
            }
            if (editSearch?.connectedFilters && Array.isArray(editSearch.connectedFilters)) {
                editSearch.connectedFilters.forEach(s => {
                    addedFilters[editSearch.type].push(s);
                    if (allFiltersData?.connectedFilters && Array.isArray(allFiltersData.connectedFilters)) {
                        const f = allFiltersData.connectedFilters.find(c => c.filterId === s.filterId);
                        if (!f) {
                            allFiltersData.connectedFilters.push(s);
                        }
                    }
                })
            }
            if (editSearch?.requiredChannels && Array.isArray(editSearch.requiredChannels) && editSearch?.requiredChannels.length > 0) {
                allFiltersData.requiredChannels = editSearch.requiredChannels;
                if (editSearch.type && channelSelect[editSearch.type]) {
                    Object.entries(channelSelect[editSearch.type]).forEach(([k, v]) => {
                        channelSelect[editSearch.type][k] = editSearch.requiredChannels.includes(k.toUpperCase())
                    })
                }
            }
            this.setState({
                allFiltersData,
                addedFilters,
                selectedFilters,
                channelSelect,
                selectedKeywords
            });
        }
    }

    componentDidMount() {
        this.props.fetchFavorites();
        const { searchFilters, editSearch, setAdditionalitems, history } = this.props;
        let height = 60;
        if (this.searchBarRef && this.searchBarRef.current) {
            height = this.searchBarRef.current.clientHeight + 24;
        }

        const activePublicChannel = getActiveChannelName(this.state.channelSelect.PUBLIC);

        const keywordFilters = Lookup.getGroupFilters(LOOKUP_FILTER_GROUP.KEYWORDS, searchFilters?.type || 'PUBLIC');

        const defaultSelectedKeywordFilter =
            keywordFilters.find((keywordFilter) => keywordFilter.label === 'Social Hashtag') || keywordFilters[0];
        
        
        const checkedKeywordFilterId = defaultSelectedKeywordFilter?.id;
        const stateToset = {
            height,
            keywordFilters,
            checkedKeywordFilterId,
            // HERE
            commonFilters: {
                CONNECTED: Lookup.getGroupFilters(LOOKUP_FILTER_GROUP.COMMON, 'CONNECTED'),
                PUBLIC: Lookup.getGroupFilters(LOOKUP_FILTER_GROUP.COMMON, 'PUBLIC', activePublicChannel),
            },
            //
            connectedFilters: {
                CONNECTED: Lookup.getGroupFilters(LOOKUP_FILTER_GROUP.CONNECTED, 'CONNECTED'),
                PUBLIC: Lookup.getGroupFilters(LOOKUP_FILTER_GROUP.CONNECTED, 'PUBLIC', activePublicChannel),
            },
            audienceFilters: {
                CONNECTED: Lookup.getGroupFilters(LOOKUP_FILTER_GROUP.AUDIENCE, 'CONNECTED'),
                PUBLIC: Lookup.getGroupFilters(LOOKUP_FILTER_GROUP.AUDIENCE, 'PUBLIC', activePublicChannel),
            }}
        if (searchFilters.type && searchFilters.type === "CONNECTED" && history && history.location.state && history.location.state.disableSelect === true) {
            stateToset.allFiltersData = {
                type: 'CONNECTED',
                requiredChannels: [],
                commonFilters: [],
                connectedFilters: [],
                audienceFilters: []
            }
        }
        if (history && history.location.state && history.location.state.disableSelect === true) {
            stateToset.disabledSelection = true
            let newDefault = keywordFilters.find((keywordFilter) => keywordFilter.label === "Creator's name/bio") || keywordFilters[0];
            if (searchFilters.type && searchFilters.type === "CONNECTED") {
                newDefault = keywordFilters.find((keywordFilter) => keywordFilter.label === "Creator Name") || keywordFilters[0];
            }
            const newId = newDefault?.id;
            stateToset.checkedKeywordFilterId = newId
        }
        this.setState(stateToset);
        this.updateDataFromEditSearch();
        setAdditionalitems(this.renderFilter);
    }

    componentDidUpdate(prevProps) {
        const {  editSearch } = this.props;
        if (!_.isEqual(prevProps?.editSearch, editSearch) && editSearch) {
            this.updateDataFromEditSearch();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.hideSelectOptions);
    }

    toggleFavourite = () => {
        const { showFavorite } = this.state;
        this.setState({
            showFavorite: !showFavorite,
            showFilter: false,
            showRequestInstagramProfile: false,
            showConfirmRequestProfile: false,
        });
    };

    renderFilter = () => {
        const {
            keywordFilters,
            commonFilters,
            connectedFilters,
            audienceFilters,
            selectedFilters,
            allFiltersData,
            channelSelect,
            addedFilters,
            showFavorite
        } = this.state;
        const {editSearch, scrollContainer, currency, symbol, publicButton, influencers} = this.props;
        const { disableMultipleInvite, setTopFilter } = this.props;
        const activeChannel = getActiveChannelName(this.state.channelSelect.PUBLIC);
        const isPublicSource = this.state.allFiltersData.type === 'PUBLIC';
        if (commonFilters.PUBLIC) {
            return (
                <FilterMenu
                    keywordFilters={keywordFilters}
                    commonFilters={commonFilters}
                    connectedFilters={connectedFilters}
                    audienceFilters={audienceFilters}
                    onUpdate={this.onFiltersUpdate}
                    selectedFilters={selectedFilters}
                    allFiltersData={allFiltersData}
                    channelSelect={channelSelect}
                    addedFilters={addedFilters}
                    disableMultipleInvite={disableMultipleInvite}
                    setTopFilter={setTopFilter}
                    removekeyword={this.removekeyword}
                    removeFavourite={this.activateFavoriteFilter}
                    editSearch={editSearch}
                    showApiError={(value) => this.setState({ showApiError: value })}
                    apiError={(value) => this.setState({ apiError: value })}
                    onAddSuggestion={this.handleKeywordChange}
                    platform={activeChannel}
                    scrollContainer={scrollContainer}
                    currency={currency}
                    symbol={symbol}
                    publicButton={(influencers.length === 0 && isPublicSource) ? publicButton : null}
                />
            );
        }
        return null;
    };

    showSelectOptions = (e) => {
        e.stopPropagation();
        this.setState({ showSelectOption: !this.state.showSelectOption });
        window.addEventListener('click', this.hideSelectOptions);
    };

    showSelectOptions2 = (e) => {
        e.stopPropagation();
        this.setState({ showSelectOption2: !this.state.showSelectOption2 });
        window.addEventListener('click', this.hideSelectOptions2);
    };

    hideSelectOptions = () => {
        this.setState({ showSelectOption: false });
    };

    hideSelectOptions2 = () => {
        this.setState({ showSelectOption2: false });
    };

    clear = (key, waiting) => {
        const { filters } = this.state;
        let newFilters = [];
        if (Array.isArray(key)) {
            newFilters = filters.filter((item) => !key.includes(item.filterId));
        } else {
            newFilters = filters.filter((item) => item.filterId !== key);
        }
        this.setState({ filters: newFilters }, !waiting ? this.applyFilter : null);
    };

    applyFilter = () => {
        const f = this.props.changeFunction;
        const { setData } = this.props;
        const { allFiltersData, favFiltersOptions, keywords, checkedKeywordFilterId, keywordFilters, selectedKeywords, selectedFilters } = this.state;
        // remove the formattedAddress when searching
        const filtersData = JSON.parse(JSON.stringify(allFiltersData));
        var selectedFiltersStrHelper = (selectedFilterList, filterGroup) => {
            var selectedFiltersStr = "";

            for (let i = 0; i < selectedFilterList.length; i++) {
                if (selectedFilterList[i].group == filterGroup) {

                    selectedFiltersStr += selectedFilterList[i].label;
                    if (i != (selectedFilterList.length - 1)) {
                        selectedFiltersStr += ',';
                    }
                }
            }

            return selectedFiltersStr;
        }
        if (filtersData.connectedFilters.length > 0) {
            const location = filtersData.connectedFilters.find(
                (cf) => cf.filterId === 4 && cf.json && cf.json.hasOwnProperty('formattedAddress'),
            );
            if (location) {
                delete location.json.formattedAddress;
            }

            var currentCommonFilters = [];
            for (let i = 0; i < allFiltersData.commonFilters.length; i++) {
                currentCommonFilters.push(allFiltersData.commonFilters[i].filterId);
            }
            currentCommonFilters = currentCommonFilters.sort(function (a, b) {
                return a - b;
            });
            currentCommonFilters = currentCommonFilters.map((i) => `#${  i}`);
            currentCommonFilters = currentCommonFilters.toString();

            if (activeCommonFilterSelected != currentCommonFilters) {
                if (allFiltersData.type == 'PUBLIC') {
                    PendoHelper.pendoTrack('DiscoverFilter_CreatorFilterIds_Public', { filterNames: selectedFiltersStrHelper(selectedFilters.PUBLIC, "common") });
                } else if (allFiltersData.type == 'CONNECTED') {
                    PendoHelper.pendoTrack('DiscoverFilter_CreatorFilterIds_Connected', { filterNames: selectedFiltersStrHelper(selectedFilters.CONNECTED, "common") });
                }
            }
            activeCommonFilterSelected = currentCommonFilters;
        }

        const filterValues = allFiltersData.requiredChannels.sort().toString().toLowerCase();
        
        if (allFiltersData.type == 'PUBLIC' && activeChannelSelectedPublic != filterValues) {
            PendoHelper.pendoTrack('DiscoverFilter_SocialPlatforms_Public', { platform: filterValues });
            activeChannelSelectedPublic = filterValues;
        } else if (allFiltersData.type == 'CONNECTED' && activeChannelSelectedConnected != filterValues) {
            let connectedFilterValue = filterValues;
            if (filterValues == '') {
                connectedFilterValue = 'Any Channel';
            }
            PendoHelper.pendoTrack('DiscoverFilter_SocialPlatforms_Connected', { platforms: connectedFilterValue });
            activeChannelSelectedConnected = filterValues;
        }

        if (filtersData.audienceFilters.length > 0) {
            const audienceData = [];
            let currentAudienceFilters = [];
            filtersData.audienceFilters.map((audienceFilter) => {
                // Filter type "WEIGHT" & "OPTION" isWeighted:true  multiple:true&false
                // TODO: check filter type
                if (audienceFilter.customValue !== undefined || audienceFilter.customRanges !== undefined) {
                    audienceData.push({
                        filterId: audienceFilter.filterId,
                        filterOptionIds: audienceFilter.filterOptionIds,
                        customValue: audienceFilter.customValue,
                        customRanges: audienceFilter.customRanges,
                    });
                    return;
                }
                // Filter type "OPTION" multiple:false isWeighted: false
                // TODO: check filter type
                if (audienceFilter.keywords) {
                    const { filterId, keywords } = audienceFilter;

                    audienceData.push({
                        filterId,
                        keywords,
                    });
                }

                // Filter type "OPTION" multiple:true isWeighted: false
                // TODO: check filter type
                if (audienceFilter.filterOptionIds) {
                    const filterOptionIdsNumbers = audienceFilter.filterOptionIds.map((id) => Number(id));
                    audienceData.push({
                        filterId: audienceFilter.filterId,
                        filterOptionIds: filterOptionIdsNumbers,
                    });
                } else if (audienceFilter?.data?.filterOptions) {
                    const filterOptionIds = [];
                    const customRanges = [];
                    const {keywords} = audienceFilter;

                    audienceFilter.data.filterOptions.map((fo) => {
                        customRanges.push({
                            field: fo?.customValue,
                            maxValue: fo?.maxValue,
                            minValue: fo?.minValue,
                        });
                        filterOptionIds.push(fo.id);
                    });

                    audienceData.push({
                        filterId: audienceFilter.filterId,
                        customRanges,
                        keywords,
                        filterOptionIds,
                    });
                }

                currentAudienceFilters.push(audienceFilter.filterId);
            });

            filtersData.audienceFilters = audienceData;

            currentAudienceFilters = currentAudienceFilters.sort(function (a, b) {
                return a - b;
            });
            currentAudienceFilters = currentAudienceFilters.map((i) => `#${  i}`);
            currentAudienceFilters = currentAudienceFilters.toString();

            if (activeCommonFilterSelected != currentAudienceFilters) {
                if (allFiltersData.type == 'PUBLIC') {
                    PendoHelper.pendoTrack('DiscoverFilter_AudienceFilterIds_Public', { filterNames: selectedFiltersStrHelper(selectedFilters.PUBLIC, "insights") });
                } else if (allFiltersData.type == 'CONNECTED') {
                    PendoHelper.pendoTrack('DiscoverFilter_AudienceFilterIds_Connected', { filterNames: selectedFiltersStrHelper(selectedFilters.CONNECTED, "insights") });
                }
            }
            activeAudienceFilterSelected = currentAudienceFilters;
        }

        if (allFiltersData.type == 'CONNECTED' && selectedKeywords.CONNECTED.length > 0) {
            var newlabelFilterParamId = '';
            var newlabelFilterParamLabel = '';
            var newlabelFilterValue = '';
            for (let i = 0; i < selectedKeywords.CONNECTED.length; i++) {
                var labelFilterParam = selectedKeywords.CONNECTED[i].filterId;
                var labelFilterValue = selectedKeywords.CONNECTED[i].keywords.join(", ");

                var isNotFoundKeyword = true;
                for (let t = 0; t < activeKeywordFilterSelectedConnected.length; t++) {
                    if (activeKeywordFilterSelectedConnected[t].labelFilterParam == labelFilterParam
                        && activeKeywordFilterSelectedConnected[t].labelFilterValue == labelFilterValue) {
                            isNotFoundKeyword = false;
                    }
                }

                if (isNotFoundKeyword) {
                    activeKeywordFilterSelectedConnected.push({labelFilterParam: labelFilterParam, labelFilterValue: labelFilterValue });
                    newlabelFilterParamId = labelFilterParam;
                    newlabelFilterValue = labelFilterValue;
                }
            }

            if (newlabelFilterParamId != '') {
                for (let i = 0; i < keywordFilters.length; i++) {
                    if (keywordFilters[i].id == newlabelFilterParamId) {
                        newlabelFilterParamLabel = keywordFilters[i].label;
                    }
                }
                PendoHelper.pendoTrack('DiscoverFilter_FilterKeywords_Search_Connected', { paramLabel: newlabelFilterParamLabel, searchValue: newlabelFilterValue });
            }
        }

        if (allFiltersData.type == 'PUBLIC' && selectedKeywords.PUBLIC.length > 0) {
            var newlabelFilterParamId = '';
            var newlabelFilterParamLabel = '';
            var newlabelFilterValue = '';
            for (let i = 0; i < selectedKeywords.PUBLIC.length; i++) {
                var labelFilterParam = selectedKeywords.PUBLIC[i].filterId;
                var labelFilterValue = selectedKeywords.PUBLIC[i].keywords.join(", ");

                var isNotFoundKeyword = true;
                for (let t = 0; t < activeKeywordFilterSelectedPublic.length; t++) {
                    if (activeKeywordFilterSelectedPublic[t].labelFilterParam == labelFilterParam
                        && activeKeywordFilterSelectedPublic[t].labelFilterValue == labelFilterValue) {
                            isNotFoundKeyword = false;
                    }
                }

                if (isNotFoundKeyword) {
                    activeKeywordFilterSelectedPublic.push({labelFilterParam: labelFilterParam, labelFilterValue: labelFilterValue });
                    newlabelFilterParamId = labelFilterParam;
                    newlabelFilterValue = labelFilterValue;
                }
            }

            if (newlabelFilterParamId != '') {
                for (let i = 0; i < keywordFilters.length; i++) {
                    if (keywordFilters[i].id == newlabelFilterParamId) {
                        newlabelFilterParamLabel = keywordFilters[i].label;
                    }
                }
                PendoHelper.pendoTrack('DiscoverFilter_FilterKeywords_Search_Public', { paramLabel: newlabelFilterParamLabel, searchValue: newlabelFilterValue });
            }
        }

        // keywordFilters.push({operator: 'any', filterId: 46, keywords: ['test']})
        if (typeof f === 'function') {
            f({ ...filtersData, keywordFilters: selectedKeywords[filtersData.type], favorites: favFiltersOptions });
        }
        if (typeof setData === 'function') {
            setData({inviteList: {}})
        }
    };

    isFilterMultiselect = (filterId) => {
        const { keywordFilters } = this.state;
        const currentKeywordFilter = keywordFilters.find((item) => item.id === filterId);
        if (currentKeywordFilter) {
            return currentKeywordFilter.multipleSelect;
        }
        return true;
    };

    handleKeywordChange = (keywords, triggerByDelete) => {
        const { searchFilters } = this.props;
        const source = searchFilters.type;
        const { checkedKeywordFilterId, selectedKeywords, selectedFilters } = this.state;
        const keyworditems = getKwFilters(keywords, [checkedKeywordFilterId]);
        const sanitizedKeywordItems = [
            {
                ...keyworditems[0],
                keywords: [
                    keywordSanitization({
                        filterId: checkedKeywordFilterId,
                        keywordFilters: this.state.keywordFilters,
                        keyword: keywords[0],
                    }),
                ],
            },
        ];

        if (
            sanitizedKeywordItems[0].filterId !== 46 &&
            sanitizedKeywordItems[0].filterId !== 76 &&
            this.isFilterMultiselect(sanitizedKeywordItems[0].filterId)
        ) {
            let existingKeywordsFilter = selectedKeywords[source].find((item) => item.filterId === sanitizedKeywordItems[0].filterId);
            if (!existingKeywordsFilter) {
                selectedKeywords[source] = selectedKeywords[source].concat(sanitizedKeywordItems);
            }
            const keywordsRef = selectedKeywords[source].find((item) => item.filterId === sanitizedKeywordItems[0].filterId);
            let existingFiltersFilter = selectedFilters[source].find(item => item.filterId === sanitizedKeywordItems[0].filterId);
            if(existingFiltersFilter) {
                existingFiltersFilter.keywords = existingFiltersFilter.keywords.concat(sanitizedKeywordItems[0].keywords);
                keywordsRef.keywords = existingFiltersFilter.keywords
            } else {
                selectedFilters[source] = selectedFilters[source].concat(sanitizedKeywordItems);
            }
        } else {
            selectedKeywords[source] = selectedKeywords[source]
                .filter((item) => {
                    if (!item.filterId || item.filterId !== sanitizedKeywordItems[0].filterId) {
                        return true;
                    }
                    return false;
                })
                .concat(sanitizedKeywordItems);
            selectedFilters[source] = selectedFilters[source]
                .filter((item) => {
                    if (!item.filterId || item.filterId !== sanitizedKeywordItems[0].filterId) {
                        return true;
                    }
                    return false;
                })
                .concat(sanitizedKeywordItems);
        }
        this.setState({ selectedKeywords, selectedFilters }, this.applyFilter);
    };

    removekeyword = (filter, keyword) => {
        // Add pendo event
        PendoHelper.pendoTrack('DiscoverFilter_FilterKeywords_Remove', { filterType: filter.filterId, filterText: keyword });

        // WARNING: spaghetti ahead

        const { selectedKeywords, selectedFilters } = this.state;
        const { searchFilters } = this.props;
        const source = searchFilters.type;
        const hasMoreThanOneKeyword = selectedKeywords[source].some(item => {
            if(item.filterId !== filter.filterId) return false
            return Boolean(item.keywords.length > 1)
        })

        const hasMoreThanOneFilterKeyword = selectedFilters[source].some(item => {
            if(item.filterId !== filter.filterId) return false
            return Boolean(item.keywords.length > 1)
        })
        if(keyword && hasMoreThanOneKeyword && hasMoreThanOneFilterKeyword) {
            selectedKeywords[source] = selectedKeywords[source].map(item => {
                if(item.filterId !== filter.filterId) return item;
                return {
                    ...item,
                    keywords: item.keywords.filter(k => k!== keyword)
                }
            })

            selectedFilters[source] = selectedFilters[source].map(item => {
                if(item.filterId !== filter.filterId) return item;
                return {
                    ...item,
                    keywords: item.keywords.filter(k => k!== keyword)
                }
            })

        } else {
            selectedKeywords[source] = selectedKeywords[source].filter((item) => {
                if (item.filterId === filter.filterId && item.keywords[0] === filter.keywords[0]) {
                    return false;
                }
                return true;
            });
            selectedFilters[source] = selectedFilters[source].filter((item) => {
                if (item.filterId && item.filterId === filter.filterId && item.keywords[0] === filter.keywords[0]) {
                    return false;
                }
                return true;
            });
        }
        this.setState({ selectedKeywords, selectedFilters }, this.applyFilter);
    };

    handleKeywordFilterCheckboxClick = (id) => {
        const { checkedKeywordFilterId, keywordFilters, allFiltersData } = this.state;

        const isThisFilterChecked = checkedKeywordFilterId === id;
        if (!isThisFilterChecked) {
            this.setState({ checkedKeywordFilterId: id });
            var labelFilterParam = '';
            for (let i = 0; i < keywordFilters.length; i++) {
                if (keywordFilters[i].id == id) {
                    labelFilterParam = keywordFilters[i].label;
                }
            }

            // Change filter
            if (allFiltersData.type == 'PUBLIC') {
                PendoHelper.pendoTrack('DiscoverFilter_FilterKeywords_Select_Public', { paramId: id, paramLabel: labelFilterParam });
            } else if (allFiltersData.type == 'CONNECTED') {
                PendoHelper.pendoTrack('DiscoverFilter_FilterKeywords_Select_Connected', { paramId: id, paramLabel: labelFilterParam });
            }
        }
    };

    searchResult = () => {
        const { checkedKeywordFilterId } = this.state;
        if (!checkedKeywordFilterId) {
            return;
        }
        this.applyFilter();
    };

    handleEnter = debounce(() => {
        this.searchResult();
    }, 500);

    activateFavoriteFilter = (list) => {
        if (!list) return;
        const { favFiltersOptions, selectedFilters } = this.state;
        const favorite = [];
        const favIndex = favFiltersOptions.indexOf(list.id);
        if (favIndex === -1) {
            favorite.push(list.id);
            list.type = 'favourite';
            selectedFilters.CONNECTED = selectedFilters.CONNECTED.filter((item) => item.type !== 'favourite');
            selectedFilters.CONNECTED.push(list);
        } else {
            selectedFilters.CONNECTED = selectedFilters.CONNECTED.filter((item) => item.type !== 'favourite');
        }

        this.setState({ favFiltersOptions: favorite, selectedFilters }, this.applyFilter);
    };

    favoriteDeleted = () => {
        this.setState({ favFiltersOptions: [] }, this.applyFilter);
    };

    onFiltersUpdate = (
        filters,
        channels,
        source,
        sFilter,
        availableCommonFilters,
        availableConnectedFilters,
        availableAudienceFilters,
        search,
    ) => {
        const selectedFilters = sFilter;
        // If source type is changed - update keyword checkbox options
        let {keywordFilters} = this.state;
        let {checkedKeywordFilterId} = this.state;
        if (source !== this.props.searchFilters.type) {
            keywordFilters = Lookup.getGroupFilters(LOOKUP_FILTER_GROUP.KEYWORDS, source);

            const isSelectedKeywordFilterAvailable = keywordFilters.find((filter) => filter.id === checkedKeywordFilterId);

            if (!isSelectedKeywordFilterAvailable) {
                const defaultSelectedKeywordFilter =
                    keywordFilters.find((keywordFilter) => keywordFilter.label === 'Social Hashtag') || keywordFilters[0];
                checkedKeywordFilterId = defaultSelectedKeywordFilter.id;
            }

            this.props.actions.sortFilterChange({ sort: source === 'PUBLIC' ? followersPublic : bestMatch, preventRefresh: true });
        }

        const activePublicChannel = getActiveChannelName(this.state.channelSelect.PUBLIC);

        const commonFilters = Lookup.getGroupFilters(LOOKUP_FILTER_GROUP.COMMON, source, activePublicChannel);
        const connectedFilters = Lookup.getGroupFilters(LOOKUP_FILTER_GROUP.CONNECTED, source, activePublicChannel);
        const audienceFilters = Lookup.getGroupFilters(LOOKUP_FILTER_GROUP.AUDIENCE, source, activePublicChannel);

        const connected = [];
        const common = [];
        const audience = [];

        filters[source].map((f) => {
            if (commonFilters.find((c) => c.id === f.filterId)) {
                return common.push(f);
            }
            if (connectedFilters.find((c) => c.id === f.filterId)) {
                return connected.push(f);
            }
            if (audienceFilters.find((c) => c.id === f.filterId)) {
                return audience.push(f);
            }
        });

        const requiredChannels = Object.entries(channels[source])
            .filter(([_, isChecked]) => isChecked)
            .map(([channelName]) => channelName.toUpperCase());

        const filtersData = {
            type: source,
            requiredChannels,
            connectedFilters: connected,
            commonFilters: common,
            audienceFilters: audience,
        };
        // scenario when we get rid of all filters
        const { selectedKeywords } = this.state;
        if (selectedFilters[source].length === 0) {
            selectedKeywords[source] = [];
        }
        if (channels.PUBLIC.youtube && source === "PUBLIC") {
            if (checkedKeywordFilterId === 28) {
                checkedKeywordFilterId = keywordFilters[0].id;
            }
            selectedKeywords.PUBLIC = selectedKeywords.PUBLIC.filter(item => item.filterId !== 28);
            selectedFilters.PUBLIC = selectedFilters.PUBLIC.filter(item => item.filterId !== 28);
        }
        this.setState(
            {
                keywordFilters,
                checkedKeywordFilterId,
                allFiltersData: filtersData,
                addedFilters: filters,
                channelSelect: channels,
                selectedFilters,
                commonFilters: availableCommonFilters,
                connectedFilters: availableConnectedFilters,
                audienceFilters: availableAudienceFilters,
                selectedKeywords,
            },
            search ? this.applyFilter : null,
        );
    };

    handleInstagramHandleNameChange = (e) => {
        let { value } = e.target;
        if (!value.startsWith('@')) {
            value = `@${value}`;
        }
        let showInvalidHandle = false;
        if (value === '@' || value.length > 100) {
            showInvalidHandle = true;
        }
        this.setState({
            instagramHandle: value,
            showInvalidHandle,
        });
    };

    continueRequestInstagramProfile = () => {
        this.setState({
            showRequestInstagramProfile: false,
            showConfirmRequestProfile: true,
        });
    };

    submitRequestProfile = () => {
        const { instagramHandle, handleSelected } = this.state;

        Api.sendPublicProfileRequest({
            networkType: handleSelected.toUpperCase(),
            socialHandle: instagramHandle.charAt(0) === '@' ? instagramHandle.substring(1) : instagramHandle,
        })
            .then((res) => {
                if (res.data) {
                    this.setState({
                        showConfirmRequestProfile: false,
                        existingProfile: res.data,
                    });
                } else {
                    this.setState({
                        showConfirmRequestProfile: false,
                        showRequestReceived: true,
                    });
                }
            })
            .catch((err) => {
                this.setState({ showApiError: true, apiError: err });
            });
    };

    gotoExistingProfile = () => {
        const { existingProfile } = this.state;
        const { gotoProfile } = this.props;

        if (existingProfile && gotoProfile) {
            // const linkToDetails = `/influencerDetailPage/${existingProfile.id}?Public`;
            // window.open(linkToDetails, '_blank');
            gotoProfile(existingProfile);
        }

        this.setState({
            existingProfile: null,
            instagramHandle: '',
        });
    };

    renderSearchSelections() {
        const { keywordFilters, checkedKeywordFilterId, allFiltersData, selectedKeywords, selectedFilters } = this.state;
        if (keywordFilters.length > 0) {
            return keywordFilters.map((filter) => {
                if (allFiltersData.type === "PUBLIC" && allFiltersData.requiredChannels[0] === "YOUTUBE" && filter.label === "Hashtag") {
                    return null
                }
                return (
                    <div key={filter.id} onClick={() => this.handleKeywordFilterCheckboxClick(filter.id)}>
                        <div className={cx('radioButtonCircle', { selected: checkedKeywordFilterId === filter.id })} />
                        {filter.label}
                    </div>
                );
            });
        }
        return null;
    }

    changeChannelStatePublic = (channelSocial) => {
        const { channelSelect, connectedFilters, commonFilters, audienceFilters, addedFilters, selectedFilters, keywordFilters } = this.state;
        const newchannel = channelSelect;
        const { searchFilters } = this.props;
        const source = searchFilters.type;
        const activePublicChannel = getActiveChannelName(this.state.channelSelect.PUBLIC);
        // if (activePublicChannel === channelSocial) {
        //     return;
        // }
        if (channelSocial === 'TIKTOK') {
            newchannel.PUBLIC.tiktok = true;
            newchannel.PUBLIC.instagram = false;
            newchannel.PUBLIC.youtube = false;
        } else if (channelSocial === "INSTAGRAM") {
            newchannel.PUBLIC.tiktok = false;
            newchannel.PUBLIC.instagram = true;
            newchannel.PUBLIC.youtube = false;
        } else {
            newchannel.PUBLIC.tiktok = false;
            newchannel.PUBLIC.instagram = false;
            newchannel.PUBLIC.youtube = true;
        }
        connectedFilters[source] = Lookup.getGroupFilters(LOOKUP_FILTER_GROUP.CONNECTED, searchFilters.type, channelSocial);
        commonFilters[source] = Lookup.getGroupFilters(LOOKUP_FILTER_GROUP.COMMON, searchFilters.type, channelSocial);
        audienceFilters[source] = Lookup.getGroupFilters(LOOKUP_FILTER_GROUP.AUDIENCE, searchFilters.type, channelSocial);
        this.onFiltersUpdate(
            addedFilters,
            newchannel,
            searchFilters.type,
            selectedFilters,
            commonFilters,
            connectedFilters,
            audienceFilters,
            true,
        );
    };

    changeChannelStateConnected = (item) => {
        const { channelSelect } = this.state;
        const newchannel = channelSelect;
        const { searchFilters } = this.props;
        let allchecked = 0;
        const keys = Object.keys(newchannel.CONNECTED);
        keys.forEach((key) => {
            if (newchannel.CONNECTED[key]) {
                allchecked += 1;
            }
        });
        if (allchecked === 0 && item === 'all') {
            keys.forEach((key) => {
                newchannel.CONNECTED[key] = true;
            });
        } else if (allchecked > 0 && item === 'all') {
            keys.forEach((key) => {
                newchannel.CONNECTED[key] = false;
            });
        } else {
            newchannel.CONNECTED[item] = !newchannel.CONNECTED[item];
        }
        const { addedFilters, selectedFilters, commonFilters, connectedFilters, audienceFilters } = this.state;
        this.onFiltersUpdate(
            addedFilters,
            newchannel,
            searchFilters.type,
            selectedFilters,
            commonFilters,
            connectedFilters,
            audienceFilters,
            true,
        );
    };

    renderSearchSelections2() {
        const socialmediaPublic = [
            { label: 'Instagram', value: 'INSTAGRAM' },
            { label: 'YouTube', value: 'YOUTUBE' },
            { label: 'TikTok', value: 'TIKTOK' },
        ];
        const activePublicChannel = getActiveChannelName(this.state.channelSelect.PUBLIC);
        const socialmediaConnected = [
            { label: 'Blog', value: 'blog' },
            { label: 'Instagram', value: 'instagram' },
            { label: 'YouTube', value: 'youtube' },
            { label: 'Facebook', value: 'facebook' },
            { label: 'Twitter', value: 'twitter' },
            { label: 'TikTok', value: 'tiktok' },
        ];
        const { channelSelect } = this.state;
        if (this.props.searchFilters.type === 'PUBLIC') {
            return socialmediaPublic.map((filter) => {
                return (
                    <div key={filter.value} onClick={() => this.changeChannelStatePublic(filter.value)}>
                        <div className={cx('radioButtonCircle', { selected: activePublicChannel === filter.value })} />
                        {filter.label}
                    </div>
                );
            });
        }
        let allchecked = 0;
        const keys = Object.keys(channelSelect.CONNECTED);
        keys.forEach((key) => {
            if (channelSelect.CONNECTED[key]) {
                allchecked += 1;
            }
        });
        return (
            <>
                <div key="all" className="all" onClick={() => this.changeChannelStateConnected('all')}>
                    <div className={cx('checkBox', { selected: allchecked === 0 })}>
                        <img src="/images/ic-checkmark3.svg" alt="checkbox" />
                    </div>
                    Any channel
                </div>
                {socialmediaConnected.map((filter) => {
                    return (
                        <div key={filter.value} onClick={() => this.changeChannelStateConnected(filter.value)}>
                            <div className={cx('checkBox', { selected: channelSelect.CONNECTED[filter.value] })}>
                                <img src="/images/ic-checkmark3.svg" alt="checkbox" />
                            </div>
                            {filter.label}
                        </div>
                    );
                })}
            </>
        );

    }

    onHandleChange = (val) => {
        const { handleSelected } = this.state;
        if (val.value !== handleSelected) {
            this.setState({ handleSelected: val.value });
        }
    };

    renderRequestInstagramProfile = () => {
        const {
            showRequestInstagramProfile,
            instagramHandle,
            showInvalidHandle,
            showConfirmRequestProfile,
            showRequestReceived,
            existingProfile,
            handleSelected,
        } = this.state;
        const message =
            'You can now request detailed audience insights for public accounts that we currently don’t have on our platform. We are only supporting Instagram and Tiktok at this time. We will be adding other social networks soon.';
        const inputLabel = `${handleSelected} Handle Name`;
        const confirmMessage =
            `Are you sure you want to create ${
                handleSelected === 'Instagram' ? 'an' : 'a'
            } ${handleSelected} public profile for ${instagramHandle}?`;
        const existingMessage =
            `We already have ${
                handleSelected === 'Instagram' ? 'an' : 'a'
            } ${handleSelected} account with this handle ${instagramHandle}`;
        const body = (
            <div className="requestMessageContainer">
                <div className="message">{message}</div>
                <div className="handleSelect">
                    <div className="selectLabel">Choose Social Network</div>
                    <Select
                        className="select sort-select"
                        clearable={false}
                        searchable={false}
                        value={handleSelected}
                        single
                        placeholder="Select"
                        closeMenuOnSelect={false}
                        options={handleOptions}
                        onChange={this.onHandleChange}
                    />
                </div>
                <div className="handleInputContainer">
                    <div className="handleLabel">{inputLabel}</div>

                    <input
                        className=""
                        placeholder={`@${handleSelected} Handle Name`}
                        type="string"
                        onChange={(e) => this.handleInstagramHandleNameChange(e)}
                        value={instagramHandle}
                    />
                    {showInvalidHandle && <div className="invalidHandle">Enter a valid handle</div>}
                </div>
            </div>
        );
        const style = { height: 'fit-content', width: '500px' };

        return showRequestInstagramProfile ? (
            <PopUpDialogueBase
                show={showRequestInstagramProfile}
                title="Request Public Profile"
                message={body}
                disableProceed={!instagramHandle || instagramHandle === '@'}
                proceedLabel="Submit Request"
                windowStyle={style}
                proceedFunction={this.continueRequestInstagramProfile}
                cancelFunction={() =>
                    this.setState({ showRequestInstagramProfile: false, instagramHandle: '', handleSelected: 'Instagram' })
                }
            />
        ) : showConfirmRequestProfile ? (
            <PopUpDialogueBase
                show={showConfirmRequestProfile}
                title="Request Public Profile"
                message={confirmMessage}
                proceedLabel="Create"
                windowStyle={{ minHeight: 'auto' }}
                proceedFunction={this.submitRequestProfile}
                cancelFunction={() => this.setState({ showConfirmRequestProfile: false, instagramHandle: '', handleSelected: 'Instagram' })}
            />
        ) : showRequestReceived ? (
            <PopUpDialogueBase
                show={showRequestReceived}
                title="Request Received"
                message="We have received your request and we will notify you via email once the profile is available for viewing."
                proceedLabel="OK"
                hideCancel
                windowStyle={{ minHeight: 'auto' }}
                proceedFunction={() => this.setState({ showRequestReceived: false, instagramHandle: '', handleSelected: 'Instagram' })}
            />
        ) : (
            <PopUpDialogueBase
                show={existingProfile !== null}
                title="Public Profile Exists"
                message={existingMessage}
                proceedLabel="Go to Profile"
                windowStyle={{ minHeight: 'auto' }}
                proceedFunction={this.gotoExistingProfile}
                cancelFunction={() => this.setState({ existingProfile: null, instagramHandle: '' })}
            />
        );
    };

    updateSource = (source) => {
        const { addedFilters, channelSelect, selectedFilters, commonFilters, connectedFilters, audienceFilters } = this.state;
        // Add pendo event when transferring between connected or public search type
        PendoHelper.pendoTrack('Discover_PublicOrConnected', { source: source });
        this.onFiltersUpdate(addedFilters, channelSelect, source, selectedFilters, commonFilters, connectedFilters, audienceFilters, true);
    };

    goBackToPlan = () => {
        const {history, planId, subId} = this.props;
        if (planId && subId) {
            history.push({
                pathname: `/plans/${planId}`,
                state: {
                    tab: 'saved',
                }
            });
        } else if (planId) {
            history.push(`/plans/${planId}`);
        }
    }

    render() {
        const {
            showFilter,
            showFavorite,
            showSelectOption,
            checkedKeywordFilterId,
            keywords,
            keywordFilters,
            favFiltersOptions,
            selectedFilters,
            channelSelect,
            height,
            showApiError,
            apiError,
            showSelectOption2,
            addPlanCreatorsContainerStyle,
            disabledSelection,
            selectedKeywords
        } = this.state;
        const { disableMultipleInvite, profile, planId, subId, editSearch } = this.props;
        const isPublicSource = this.state.allFiltersData.type === 'PUBLIC';

        const overlayStyle = {
            overlay: {
                top: height + (disableMultipleInvite ? 60 : 180),
            },
        };

        const panelStyle = {
            top: (disableMultipleInvite ? 60 : 120),
            paddingTop: (planId ? 0 : 10),
        };

        const searchLabel = `${keywordFilters.find((kf) => kf.id === checkedKeywordFilterId)?.label}`;
        const searchName = `${keywordFilters.find((kf) => kf.id === checkedKeywordFilterId)?.name}`;
        const activePublicChannel = getActiveChannelName(this.state.channelSelect.PUBLIC);
        let activeName = ''
        if (activePublicChannel === 'INSTAGRAM') {
            activeName = "Instagram"
        } else if (activePublicChannel === 'TIKTOK') {
            activeName = "Tiktok"
        } else {
            activeName = "Youtube"
        }
        let allchecked = 0;
        const keys = Object.keys(channelSelect.CONNECTED);
        let socialmedia = '';
        keys.forEach((key) => {
            if (channelSelect.CONNECTED[key]) {
                allchecked += 1;
                socialmedia = key;
            }
        });
        const labelMap = {
            blog: 'Blog',
            instagram: 'Instagram',
            youtube: 'YouTube',
            facebook: 'Facebook',
            twitter: 'Twitter',
            tiktok: 'TikTok',
        };
        let connectedLabel = 'Any Channel';
        if (allchecked === 6) {
            connectedLabel = 'All channels';
        } else if (allchecked > 1) {
            connectedLabel = `Channels (${allchecked})`;
        } else if (allchecked === 1) {
            connectedLabel = labelMap[socialmedia];
        }
        const placeholderUsed = isPublicSource ? 'Search for public creators' : 'Search for connected creators';
        let style = {}
        if (this.discoverPanelContainerNewRef.current) {
            style = {width: `${this.discoverPanelContainerNewRef.current.clientWidth}px`};
        }
        return (
            <div className="discoverPanel" style={panelStyle}>
                {
                    planId && subId === '' &&
                    <div
                        className='addPlanCreatorsContainer'
                        style={style}
                    >
                        <div onClick={this.goBackToPlan} role='button'>
                            <img src='/images/ic-back-gray.svg' alt='planback' />
                        </div>
                        Add creators
                    </div>
                }
                {
                    planId && subId !== '' && editSearch && editSearch.planName &&
                    <div
                        className='addPlanCreatorsContainer'
                        style={style}
                    >
                        <div onClick={this.goBackToPlan} role='button'>
                            <img src='/images/ic-back-gray.svg' alt='planback' />
                        </div>
                        {editSearch.planName}
                    </div>
                }
                <div className="discoverPanelContainerNew" ref={this.discoverPanelContainerNewRef}>
                    <div className={cx("discoverPanelPublicPrivate", disabledSelection && "disabled")}>
                        <div
                            className={cx('section', { active: this.props.searchFilters.type === 'PUBLIC' })}
                            onClick={disabledSelection ? () => {} : () => this.updateSource('PUBLIC')}
                        >
                            Public
                        </div>
                        <div
                            className={cx('section', { active: this.props.searchFilters.type === 'CONNECTED' })}
                            onClick={disabledSelection ? () => {} : () => this.updateSource('CONNECTED')}
                        >
                            Connected
                        </div>
                    </div>
                    <div
                        ref={this.searchBarRef2}
                        className={cx('discoverPanelSearchSelect2', { flip: showSelectOption2 })}
                        onClick={this.showSelectOptions2}
                    >
                        {isPublicSource && (
                            <div className="searchSelectLabel">
                                <ReachIcon network={activePublicChannel} value={1} size={10} padding={5} noToolTip />
                                {activeName}
                            </div>
                        )}
                        {!isPublicSource && (
                            <div className="searchSelectLabel">
                                {allchecked === 1 && <ReachIcon network={socialmedia} value={1} size={10} padding={5} noToolTip />}
                                {connectedLabel}
                            </div>
                        )}
                        <img className="plus" src="/images/arrow-down.svg" />
                        <div
                            className={cx('searchSelectOptions', { hidden: !showSelectOption2 })}
                            style={
                                this.searchBarRef2 && this.searchBarRef2.current
                                    ? { top: `${this.searchBarRef2.current.clientHeight + 1}px` }
                                    : null
                            }
                        >
                            <div className="subSelection">{this.renderSearchSelections2()}</div>
                        </div>
                    </div>
                    <div className="discoverPanelSearchBox">
                        <div
                            ref={this.searchBarRef}
                            className={cx('discoverPanelSearchSelect', { flip: showSelectOption })}
                            onClick={this.showSelectOptions}
                        >
                            <div className="searchSelectLabel">{searchLabel}</div>
                            <img className="plus" src="/images/arrow-down.svg" />
                            <div
                                className={cx('searchSelectOptions', { hidden: !showSelectOption })}
                                style={
                                    this.searchBarRef && this.searchBarRef.current
                                        ? { top: `${this.searchBarRef.current.clientHeight + 1}px` }
                                        : null
                                }
                            >
                                <div className="subSelection">{this.renderSearchSelections()}</div>
                            </div>
                        </div>
                        <div className="keywords">
                            { searchName === FILTERS_KEYWORDS.TOPIC ?
                                <TopicTensorInput
                                    data={keywords}
                                    onSuggestionAction={this.handleKeywordChange}
                                    placeHolder={placeholderUsed}
                                    platform={activePublicChannel}
                                    selectedFilterId={checkedKeywordFilterId}
                                    key={checkedKeywordFilterId}
                                    showApiError={(value) => this.setState({ showApiError: value })}
                                    apiError={(value) => this.setState({ apiError: value })}
                                    selectedKeywords={isPublicSource ? selectedKeywords.PUBLIC : isPublicSource.CONNECTED}
                                />
                                :
                                <KeywordsInput
                                    data={keywords}
                                    onChange={this.handleKeywordChange}
                                    placeHolder={placeholderUsed}
                                    changeIsFocused={(value) => this.setState({ isKeywordInputFocused: value })}
                                />
                            }
                        </div>
                    </div>
                </div>
                <Modal
                    isOpen={showFavorite}
                    style={overlayStyle}
                    className="favoriteMenu"
                    overlayClassName={`filterMenuOverlay${disableMultipleInvite ? ' disableMultipleInvite' : ''}`}
                >
                    <FavoriteMenu
                        activateFilterFunction={this.activateFavoriteFilter}
                        selectedFav={favFiltersOptions}
                        favoriteDeleted={this.favoriteDeleted}
                        toggleFavourite={this.toggleFavourite}
                    />
                </Modal>
                <ApiError
                    show={showApiError}
                    error={apiError}
                    cancelFunction={() => this.setState({ showApiError: false, apiError: null })}
                />
                {this.renderRequestInstagramProfile()}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchFavorites: () => dispatch(fetchFavorites()),
        actions: bindActionCreators(
            {
                sortFilterChange,
            },
            dispatch,
        ),
    };
};

export default withRouter(connect(null, mapDispatchToProps)(DiscoverPanel));
