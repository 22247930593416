import React from 'react'
import './editablePiece.scss'
import Rate from "../piecesInMain/Rate";

// props
// - editMode
// - handleLocalEditChange  - takes flags for local edit mode (that is, is this particular piece being edited)
//         editFlag, saveFlag
// - initialEditMode : flag
// - disableEdit : flag
// - disableSave : flag
// - deleteFunction : if specified, a trash can will appear with the controls executing the deleteF on click
// - cancelButtonLabel : defaults to Cancel

export default class EditablePiece extends React.Component {
    constructor( props,context) {
        super(props.context);

        this.startEdit = this.startEdit.bind(this);
        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
        this.propagate = this.propagate.bind(this);

        this.state = {
            localEditMode : !!props.showInEditMode,
            done : false
        };
    }

    startEdit() {
        if (this.props.disableEdit) {
            return;
        }
        this.setState({
            localEditMode : true
        }, this.propagate)
    }

    cancel() {
        this.setState({
            localEditMode : false,
            save : false
        }, this.propagate)
    }

    save() {
        if (this.props.disableSave) {
            return;
        }
        this.setState({
            localEditMode : false,
            save : true,
            done : true
        }, this.propagate)
    }

    propagate() {
        let f = this.props.handleLocalEditChange;

        if (typeof f === 'function') {
            f(this.state.localEditMode, this.state.save);
        }
    }

    componentDidMount() {
        if (this.props.initialEditMode) {
            this.setState({ localEditMode : true})
        }
        if (typeof this.props.deleteFunction === 'function') {
            this.setState({
                showDelete : true
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.initialEditMode !== this.props.initialEditMode && this.props.initialEditMode) {
            this.setState({ localEditMode : true})
        }
        if (prevProps.cancelCount !== this.props.cancelCount && !isNaN(this.props.cancelCount)) {
            if (this.props.cancelCount > prevProps.cancelCount) {
                this.cancel();
            }
        }
    }

    render() {
        const {editMode, isTalentManager} = this.props;
        let editControlStyle = {};
        if (!this.props.editMode) {
            editControlStyle.display = 'none';
        }

        let pencilStyle = {};
        if ( this.state.localEditMode  // already in edit mode
                //|| this.state.done  // it's the done button we show
                || this.props.disableEdit // somebody else is being edited
            ) {
                pencilStyle.display = 'none'
        }

        let doneStyle = {};
        // mocks call for a Done button but for mvp I think that should be ignored
        if ( 2 > 1 ) {
            doneStyle.display = 'none'
        }

        let bottomStyle={};
        if (  !this.props.editMode || !this.state.localEditMode ) {
            bottomStyle.display = 'none'
        }

        let saveButtonStyle = "button done";
        if (this.props.disableSave) {
            saveButtonStyle += " disabled"
        }

        let editPanelStyle={};
        if (  this.state.localEditMode ) {
            editPanelStyle={
                boxShadow:'none', borderColor:'#00BBAB'
            }
        }

        let updateButtonLabel = "Update";
        if (this.props.updateButtonLabel) {
            updateButtonLabel = this.props.updateButtonLabel;
        }
        let hoverablePanelClass='editablePiece hoverablePanel';
        if (!this.props.editMode) {
            hoverablePanelClass="editablePiece hoverablePanel disabledHover"
        }

        let cancelButtonLabel = "Cancel";
        if (this.props.cancelButtonLabel) {
            cancelButtonLabel = this.props.cancelButtonLabel;
        }

        return (
            <div className={hoverablePanelClass} style={editPanelStyle}>
                <div className="tools-container" style={{backgroundColor:'white'}}>
                    <div className="topControl"
                         style={editControlStyle}
                    >
                        <img src="/images/ic-edit-black.svg"
                             className="pencil"
                             style={pencilStyle}
                             onClick={this.startEdit}
                        />
                    </div>

                    <div style={{clear:'both'}}/>
                    {
                        isTalentManager && !editMode &&
                        <div className="denied-container">
                            <img src="/images/ic-lock.svg"
                                 className="pencil"
                                // style={pencilStyle}
                            />
                            <div>Permission Denied</div>
                        </div>
                    }

                    {this.props.children}
                </div>

                <div className="bottomControl"
                     style={bottomStyle}
                >
                    <div>
                    <div> {/* table */}
                        { this.state.showDelete &&
                                <div className="trash" onClick={this.props.deleteFunction}> {/* cell */}
                                    <img src="/images/ic-trash-dark.svg" />
                                </div>
                        }
                        <div> {/* cell */}
                            <div onClick={this.cancel}
                                 className="button cancel"
                                 style={this.props.cancelButtonStyle}
                            >
                                {cancelButtonLabel}
                            </div>
                        </div>
                        <div> {/* cell */}
                            <div onClick={this.save}
                                 className={saveButtonStyle}
                            >
                                { updateButtonLabel }
                            </div>
                        </div>
                    </div>
                    </div>
                    <div style={{clear:'both'}}/>
                </div>
            </div>
        );
    }
}
