import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';
import cx from 'classnames';
import Modal from 'react-modal';
import classNames from 'classnames';
import Checkbox from '../shared/checkbox2022/Checkbox';
import Format from '../../modules/utils/Format';
import ColorBlockGraph from './ColorBlockGraph';
import Extended from './Extended';
import RateIcon from '../shared/rateIcon2022/RateIcon';
import Actions from './actions/Actions';
import FavoriteLists from './favoriteLists/FavoriteLists';
import FavoritePopup from '../favourite/FavoritePopup';
import ExclusionPopup from '../favourite/ExclusionPopup';
import PlanPopup from '../favourite/PlanPopup';
import InfluencerPhoto from './InfluencerPhoto';
import ModalDialog from '../shared/modalDialog/ModalDialog';
import './oneInfluencer.scss';
import PermissionsExtended from './permissionsExtended';
import CreatorDetails from './CreatorDetails';
import ReachIcon from '../shared/ReachIcon/ReachIcon';
import { ACTIVE_TAB ,
    BLOG_POST,
    FACEBOOK_POST,
    GENERAL,
    INSTAGRAM_POST,
    INSTAGRAM_STORY,
    SOCIAL_SHARE,
    TWITTER_POST,
    YOUTUBE,
    TIKTOK_POST,
} from '../../constants/rateTypes';
import { getNameLastNameText } from '../influencers/discoverPanel/DiscoverPanel.helpers';
import Api from '../../modules/Api';
import ApiError from '../shared/apiError/ApiError';
import PlanNoteComponent from '../plans/PlanNoteComponent';
import PendoHelper from '../../modules/utils/PendoHelper';
import FlagAvatar from '../shared/flagAvatar/FlagAvatar';

class OneInfluencer extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.renderedRate = this.renderedRate.bind(this);
        this.flipExtended = this.flipExtended.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleClickOnName = this.handleClickOnName.bind(this);
        this.showActions = this.showActions.bind(this);
        this.hideActions = this.hideActions.bind(this);
        this.favoriteButton = this.favoriteButton.bind(this);
        this.handleCloseSlide = this.handleCloseSlide.bind(this);
        this.addToPlanClick = this.addToPlanClick.bind(this);

        this.state = {
            showExtended: false,
            showActions: false,
            showFavorites: false,
            showExclusion: false,
            showAddToPlan: false,
            showDeleteConfirmation: false,
            showCreator: false,
            favList: [],
            needRefresh: false,
            apiError: null,
            updateState: 0,
            settedFav: false
        };
    }

    favoriteButton(e) {
        e.stopPropagation();
        e.preventDefault();
        const { onFavClick } = this.props
        if (onFavClick) {
            this.setState({
                showActions: false,
            });
            onFavClick();
        } else {
            this.setState({
                showFavorites: !this.state.showFavorites,
                showExclusion: false,
                showAddToPlan: false,
                showActions: false,
            }, this.checkRefresh);
        }
    }

    flipExtended() {
        PendoHelper.pendoTrack('Discover_Influencer_FlipExtended', {});
        this.setState({
            showCreator: true,
        });
    }

    handleCloseSlide() {
        this.setState(
            {
                showCreator: false,
            },
            function () {
                if (this.props.closeProfileSidePanel) {
                    this.props.closeProfileSidePanel();
                }
            },
        );
    }

    handleSelect(flag) {
        const f = this.props.selectFunction;
        if (typeof f === 'function') {
            f(this.props.details.uid, flag);
        }
    }

    handleClickOnName() {
        const linkToDetails = `/influencerDetailPage/${this.props.details.id}`;
        this.props.history.push(linkToDetails);
    }

    renderedRate(rate) {
        let rateDollar = this.props.details.rates[rate];
        const { symbol, details, currency } = this.props;
        if (details.currencyCode !== currency && currency) {
            const ratesTodivide = this.findDiff();
            rateDollar = Math.round(rateDollar * ratesTodivide)
        }
        return (
            <div className="rateDisplay" key={rate}>
                <RateIcon rateKey={rate} size={20} iconSize={14} padding={3} value={rateDollar} />
                <div className={`rateDisplayNumb${  Number(rateDollar) > 0 ? '' : ' rateDisplayNumbGray'}`}>{Number(rateDollar) > 0 ? Format.expressInDollars(rateDollar, symbol) : ' - '}</div>
            </div>
        );
    }

    findDiff() {
        const { currency, details, currencyList } = this.props;
        const profileCurrency = currencyList && currencyList.find(e => e.value === details.currencyCode)
        const selectedCurrency = currencyList && currencyList.find(e => e.value === currency)
        return (profileCurrency && selectedCurrency) ? profileCurrency.exchangeRateToUsd/selectedCurrency.exchangeRateToUsd : 1;
    }

    setWidth = () => {
        this.setState({ boxWidth: this.coreElement.offsetWidth });
    };

    componentDidMount() {
        window.addEventListener('click', this.hideActions);
        window.addEventListener('resize', this.setWidth);
        this.setWidth();
        const { details } = this.props;
        if (details) {
            const favList = details.id ? (details.favorite_list_ids || [])
                : (details.userNetworks && details.userNetworks[0] && details.userNetworks[0].favorite_list_ids || []);
            this.setState({ favList });
        }
    }

    componentDidUpdate() {
        const { details } = this.props;
        const { favList, settedFav } = this.state;
        if (details && !settedFav) {
            const newFavList = details.id ? (details.favorite_list_ids || [])
                : (details.userNetworks && details.userNetworks[0] && details.userNetworks[0].favorite_list_ids || []);
            if (favList.length !== newFavList.length) {
                this.setState({favList: newFavList})
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.hideActions);
        window.removeEventListener('resize', this.setWidth);
    }

    // checkHeart(detailsId) {
    //     const { favoriteLists } = this.props;
    //     if (Array.isArray(favoriteLists)) {
    //         const match = favoriteLists.find((f) => Array.isArray(f.users) && f.users.indexOf(detailsId) >= 0);
    //         return match !== undefined;
    //     }
    //     return false;
    // }

    createRenderedRates = () => {
        const { details } = this.props;
        const ratesList = Object.keys(details.rates || {})
            .filter((rate) => rate !== ACTIVE_TAB && rate !== 'GENERAL')
            // .sort();
        let rates = [];

        const orderRates = [BLOG_POST, YOUTUBE, TIKTOK_POST, INSTAGRAM_POST, FACEBOOK_POST, SOCIAL_SHARE, INSTAGRAM_STORY, TWITTER_POST, GENERAL];

        // if (ratesList.length > 0) {
        //     for (let i = 0; i < orderRates.length; i++) {
        //         rates.push(orderRates[i]);
        //     }
        // }
        if (details.rates) {
            rates = orderRates
        }
        const renderedRates = rates.reduce((acc, rate) => {
            const renderedRate = this.renderedRate(rate);
            acc.push(renderedRate);

            return acc;
        }, []);

        return renderedRates;
    };

    showActions(e) {
        e.stopPropagation();
        e.preventDefault();
        this.setState({
            showActions: !this.state.showActions,
            showFavorites: false,
            showExclusion: false,
            showAddToPlan: false,
        }, this.checkRefresh);
    }

    hideActions() {
        this.setState({
            showActions: false,
            showFavorites: false,
            showExclusion: false,
            showAddToPlan: false,
        }, this.checkRefresh);
    }

    checkRefresh = () => {
        const { showExclusion, needRefresh } = this.state;
        if (!showExclusion && needRefresh && this.props.refreshSearch) {
            this.props.refreshSearch();
            this.setState({ needRefresh: false })
        }
    }

    renderConfirmationModal = () => {
        return (
            <ModalDialog
                show={this.state.showDeleteConfirmation}
                title="Remove creator"
                proceedButtonLabel="YES"
                readyToProceed
                closeButton
                proceedFunction={() => this.props.handleDeleteUser(this.props.details.management.id)}
                cancelFunction={() => this.setState({ showDeleteConfirmation: false })}
            >
                <div className="deleteCampaign">
                    <div className="label">Are you sure that you want to remove this creator?</div>
                </div>
            </ModalDialog>
        );
    };

    addToPlanClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        this.setState({
            showAddToPlan: true,
            showActions: false,
            showFavorites: false,
            showExclusion: false,
        });
    };

    renderCreatorSlide = (details) => {
        const { showCreator, favList } = this.state;
        const { showProfileSidePanel, currency, symbol, planId, openSide } = this.props;
        const modalClass = openSide ? "wideOverlay" : "creatorOverlay"
        const closeButton = <div className="closeButton" onClick={this.handleCloseSlide}>
            <img id="closeButton" src="/images/ic-close-g.svg" />
        </div>
        return (
            <Modal
                closeTimeoutMS={300}
                isOpen={showCreator || showProfileSidePanel}
                overlayClassName={modalClass}
                className="creatorModal"
                shouldCloseOnOverlayClick
                onRequestClose={this.handleCloseSlide}
            >
                <div className="container">
                    <CreatorDetails
                        {...this.props}
                        profile={details}
                        editMode={false}
                        handleScrollTopChangeFunction={this.handleScrollTopChange}
                        showProfileSidePanel={showProfileSidePanel}
                        currency={currency}
                        symbol={symbol}
                        closeButton={closeButton}
                        updateTab={this.updateTab}
                        favList={favList}
                        setFavList={fav => {
                            this.setState({favList: fav, settedFav: true})
                        }}
                    />
                </div>
            </Modal>
        );
    };

    getSocialHandle(details) {
        if (!details || !details.userNetworks || details.userNetworks.length <= 0 || !details.userNetworks[0].socialHandle) return null;

        return details.userNetworks[0].socialHandle;
    }

    getAccountVerify(details) {
        if (!details || !details.userNetworks || details.userNetworks.length <= 0 || !details.userNetworks[0].accountVerified) return null;

        return details.userNetworks[0].accountVerified;
    }

    addToPlan = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const {details, planId, setSuccess} = this.props;
        const payload = {};
        if (details?.id) {
            // connected user
            payload.connectedUserIds = [];
            payload.connectedUserIds.push(details.id);
        } else {
            payload.publicUsers = [];
            payload.publicUsers.push({
                userId: details?.uid || '',
                userType: details && details.userNetworks && details.userNetworks[0] && details.userNetworks[0].networkType || ''
            })
        }
        Api.addUsersPlan(Number(planId), payload)
            .then(() => {
                if (setSuccess) {
                    setSuccess(`Success! Added creator to plan`);
                }
                this.setState({
                    needRefresh: true,
                    showExclusion: false,
                }, this.checkRefresh)
            })
            .catch(err => {
                this.setState({
                    apiError: err
                })
            })
    }

    onExcludeCreator = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const {details, planId, setSuccess} = this.props;
        if (planId) {
            const payload = {};
            if (details?.id) {
                // connected user
                payload.connectedUserIds = [];
                payload.connectedUserIds.push(details.id);
            } else {
                payload.publicUsers = [];
                payload.publicUsers.push({
                    userId: details?.uid || '',
                    userType: details && details.userNetworks && details.userNetworks[0] && details.userNetworks[0].networkType || ''
                })
            }

            Api.addUsersToPlanExclusion(Number(planId), payload)
                .then(() => {
                    if (setSuccess) {
                        setSuccess(`Success! Excluded creator to plan`);
                    }
                    this.setState({
                        needRefresh: true,
                        showExclusion: false,
                        showActions: false
                    }, this.checkRefresh)
                })
                .catch(err => {
                    this.setState({
                        apiError: err
                    })
                })
        } else {
            this.setState({
                showExclusion: true,
                showActions: false,
                showFavorites: false
            })
        }
    }

    onClickExclusion = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const { onExclusionClick } = this.props;
        if (onExclusionClick)
            onExclusionClick();
    }

    onSetSuccess = (message, refresh) => {
        const { setSuccess } = this.props
        if (setSuccess) {
            setSuccess(message)
        }
        if (refresh) {
            this.setState({needRefresh: true})
        }
    }

    updateTab = () => {
        const { updateState} = this.state;
        this.setState({updateState: updateState + 1});
    }

    render() {
        const {
            details,
            invited,
            inviteDisabled,
            checked,
            inviteFunction,
            showPermissions,
            showExpandIcon,
            permission,
            showProfileSidePanel,
            sourceTypeFilter,
            showFavorite,
            planId,
            onFavClick,
            onExclusionClick,
            hideMore,
            isAlreadyInPlan,
            assignmentAdded,
            planName,
            excludeFunction,
            onRemove,
            openFromCreator,
            planIdNote,
            profile
        } = this.props;
        const { showCreator, boxWidth, favList, apiError, updateState } = this.state;
        const isConnectedInfluencer = Boolean(details.id);
        let totalReach = 0;
        let posts;
        let instagramNetwork = null;
        let defaultNetwork = null;
        let totalFollowers = null;
        let totalEngagments = 0;
        let viewRate = null;
        if (Array.isArray(details.userNetworks) && details.userNetworks.length > 0) {
            totalReach = details.userNetworks.map((un) => Number(un.reach)).reduce((a, b) => a + b);
            posts = details.userNetworks[0].posts;
            instagramNetwork = details.userNetworks.find((un) => un.networkType === 'instagram');
            if (!isConnectedInfluencer) {
                // the first one is default for public user
                defaultNetwork = details.userNetworks[0].networkType;
                totalFollowers = details.userNetworks.map((un) => Number(un.reach)).reduce((a, b) => a + b);
                totalEngagments = details.userNetworks[0]?.engagementRate;
                viewRate = details.userNetworks[0]?.viewRate;
            }
        }

        const mostRecentPost = details?.competitiveBrandAssociations?.[0];

        const rates = this.createRenderedRates();
        // const isFavorite = this.checkHeart(this.props.details.id, this.props.favoriteData);
        const isFavorite = favList && favList.length > 0 || showFavorite;
        const heart = classNames('favoriteButton', { heart: isFavorite }, { open: this.state.showFavorites }, { hide: onExclusionClick });
        const name = getNameLastNameText(details.firstname, details.lastname);

        let displayIGEngagementRate = false;
        if (instagramNetwork && typeof instagramNetwork.engagementRate === 'number' && instagramNetwork.engagementRate > 0) {
            displayIGEngagementRate = true;
        }

        const audienceCredibility = details?.userNetworks?.find(network => network.networkType === 'instagram')?.audienceCredibility;

        return (
            <div className="oneInfluencer2022">
                {this.renderConfirmationModal()}
                {(showCreator || showProfileSidePanel) && this.renderCreatorSlide(details)}
                <ApiError
                    show={apiError !== null}
                    error={apiError}
                    cancelFunction={() => this.setState({apiError: null})}
                />
                <div
                    className={cx('coreNew', { сhecked: this.props.checked, alreadyInPlan: (isAlreadyInPlan || assignmentAdded)})}
                    onClick={this.flipExtended}
                    onMouseLeave={this.hideActions}
                    ref={(e) => {
                        this.coreElement = e;
                    }}
                >
                    <div className={cx('coreFields', { 'coreFields-small': !isConnectedInfluencer })}>
                        {/* checkbox cell */}
                        {/* {showExpandIcon ? (
                            <div className="expand-icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    className={cx({ right: !this.state.showExtended }, { down: this.state.showExtended })}
                                >
                                    <path
                                        fill={this.state.showExtended ? '#4AA129' : '#666666'}
                                        fillRule="nonzero"
                                        d="M16.46 9.27a.889.889 0 0 1 1.276 0 .938.938 0 0 1 0 1.305l-5.097 5.154a.888.888 0 0 1-1.278 0l-5.096-5.154a.936.936 0 0 1 0-1.305.889.889 0 0 1 1.276 0l4.46 4.227 4.458-4.227z"
                                    />
                                </svg>
                            </div>
                        ) : ( */}
                        <>
                            <div
                                className="checkbox"
                                title={inviteDisabled ? 'This creator can`t be invited (not enough connections or missing rates' : ''}
                            >
                                {!inviteDisabled && permission && !isAlreadyInPlan &&(
                                    <div><Checkbox size={16} changeFunction={this.handleSelect} checked={checked} /></div>
                                )}
                                {isAlreadyInPlan && <div><Checkbox size={16} isDisabled checked={false} /></div>}
                            </div>
                            {/* <div className="checkSpacer" /> */}
                        </>
                        {/* )} */}
                        <div className="infl-info-container">
                            {/* photo cell */}
                            <div className={cx('photo', { 'photo-small': !isConnectedInfluencer })}>
                                {details.competitiveBrandAssociations?.length ? (
                                    <FlagAvatar>
                                        <InfluencerPhoto url={details.pictureUrl} firstName={details.firstname} lastName={details.lastname}/>
                                    </FlagAvatar>
                                ) : (
                                    <InfluencerPhoto url={details.pictureUrl} firstName={details.firstname} lastName={details.lastname}/>
                                )}
                                {details.authenticityRating &&
                                <div className="score">
                                    <img src="/images/ic-score.svg"/>
                                    <span>{Math.round(details.authenticityRating)}</span>
                                </div>
                                }
                            </div>

                            {/* cell with name and address */}
                            <div className="nameAndAddress">
                                <div className="name">{name}</div>
                                {!isConnectedInfluencer && (
                                    <div className="socialInfo">
                                        <span>{this.getSocialHandle(details)}</span>
                                        {this.getAccountVerify(details) && <img src="/images/ic-ins-verified.svg" />}
                                    </div>
                                )}
                                {isConnectedInfluencer && (
                                    <div className="location">
                                        {details.city && <div>{details.city},&nbsp;</div>}
                                        {details.state && <div>{details.state},&nbsp;</div>}
                                        {details.country && <div>&nbsp;{details.country}</div>}
                                    </div>
                                )}
                                {audienceCredibility &&
                                    <div className='social-percent'>
                                        <div className='social-percent-icon'>
                                            <img src='/images/social-instagram-pink.svg' alt='instagram' />
                                        </div>
                                        <div className='social-percent-number'>
                                            {Format.percentage(Math.round(audienceCredibility * 100), 0)}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        {isConnectedInfluencer && (
                            <div className='graph-container'>
                                {/* graph cell */}
                                <div
                                    className="graph"
                                    ref={(node) => {
                                        this.graphNode = node;
                                    }}
                                >
                                    {/* <div className="metrics">
                                        <div className="totalReach">
                                            <div>Total Followers:</div>
                                            <div>{Format.expressInK(totalReach, 2)}</div>
                                        </div>
                                        {displayIGEngagementRate && (
                                            <div className="engagementRate" style={{ width: boxWidth < 800 ? 340 - 150 : 380 - 150 }}>
                                                <div>Engagement:</div>
                                                <div className="green">{Format.percentage2(isConnectedInfluencer ? instagramNetwork.engagementRate : instagramNetwork.engagementRate * 100)}</div>
                                            </div>
                                        )}
                                    </div> */}
                                    <ColorBlockGraph userNetworks={details.userNetworks} boxWidth={boxWidth} />
                                </div>

                                {/* rates */}
                                <div className="rates">
                                    {/* <div className="top">
                                        <div>Rates</div>
                                    </div> */}
                                    <div className="table">{rates}</div>
                                </div>
                            </div>
                        )}
                        {!isConnectedInfluencer && (
                            <>
                                <div className={`infoWrapper infoWrapper-follow ${defaultNetwork === 'instagram' ? 'infoWrapper-instagram' : ''}`}>
                                    {/* <FavoriteLists
                                        show={this.state.showFavorites}
                                        closeFunction={() => {
                                            this.setState({ showFavorites: false });
                                        }}
                                        userDetails={details}
                                        isOnList={isFavorite}
                                    /> */}

                                    <ReachIcon network={defaultNetwork || 'instagram'} value={1} size={15} padding={6} />
                                    <div className={`dataWrapper dataInfo-reach-container ${defaultNetwork === 'instagram' ? 'dataInfo-instagram' : ''}`}>
                                        <div className="dataInfo dataInfo-follow-rate-container">
                                            <div className='dataInfo-follow-rate'>
                                                <div className='dataInfo-follow-rate-text'>Followers</div>
                                                <div className='dataInfo-follow-rate-number'>{Format.expressInK(totalFollowers, 2)}</div>
                                            </div>
                                            {(defaultNetwork === 'tiktok' || defaultNetwork === 'youtube') && totalReach >= 0 && (
                                                <div className='dataInfo-follow-rate'>
                                                    <div className='dataInfo-follow-rate-text'>Avg Views</div>
                                                    <div className='dataInfo-follow-rate-number'>{Format.expressInK(details.userNetworks[0].avgViews, 2)}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="infoWrapper infoWrapper-engagement">
                                    <div className={`dataWrapper ${defaultNetwork === 'instagram' ? 'dataInfo-instagram' : ''}`}>
                                        <div className="dataInfo dataInfo-engagement-rate-container">
                                            {totalEngagments !== null && (
                                                <div className='dataInfo-engagement-rate'>
                                                    <div className='dataInfo-engagement-rate-text'>Engagement rate</div>
                                                    <div className='dataInfo-engagement-rate-number'>{`${Format.percentage2(totalEngagments * 100)}`}</div>
                                                </div>
                                            )}
                                            {(defaultNetwork === 'tiktok' || defaultNetwork === 'youtube') && (
                                                <div className='dataInfo-engagement-rate'>
                                                    <div className='dataInfo-engagement-rate-text'>View rate</div>
                                                    <div className='dataInfo-engagement-rate-number'>
                                                        {Format.percentage2(
                                                            viewRate ? viewRate * 100 : 0,
                                                            2,
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        {planId && hideMore && <div className='more-options onlyOne'>
                            <div className="moreButton" onClick={this.showActions}>
                                <img src="/images/ic-more-vertical-gray.svg" />
                                <Actions
                                    profile={details}
                                    removeInfluencer={() => this.setState({ showDeleteConfirmation: true })}
                                    permission={permission}
                                    tmAction={showPermissions} // showPermissions is an attr for talent manager page.
                                    show={this.state.showActions}
                                    inviteFunction={inviteDisabled || Boolean(invited) ? null : inviteFunction}
                                    // addToPlan={this.addToPlanClick}
                                    hideAddToPlan
                                    excludeCreator={this.onExcludeCreator}
                                    onRemove={onRemove}
                                    planWidth
                                    openFromCreator={openFromCreator}
                                />
                            </div>
                        </div>}
                        {!hideMore && <div className='more-options'>
                            {/* {!planId && ((!showPermissions && permission && sourceTypeFilter!== "PUBLIC") || showFavorite) && ( */}
                            {((!showPermissions && permission) || showFavorite) && (
                                <div className={heart} onClick={this.favoriteButton}>
                                    <img id="noneHeart" src="/images/ic-heart-grey.svg" />
                                    <img id="Heart" src="/images/ic-heart.svg" />
                                </div>
                            )}
                            {/* {planId &&
                                <div className='middle-option heart' role='button' onClick={this.addToPlan}>
                                    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                                        <g fill="none" fillRule="evenodd">
                                            <path d="M0 0h24v24H0z"/>
                                            <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" stroke="#979797" strokeWidth="2" />
                                        </g>
                                    </svg>
                                    <div className='middle-option-tooltip heart-tooltip'>
                                        <div className='text'>Add creator to plan</div>
                                        <div className='arrow-down' />
                                    </div>
                                </div>
                            } */}
                            {!onFavClick && !onExclusionClick && <div className='middle-option negate' role='button' onClick={this.onExcludeCreator}>
                                <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <g id="icon">
                                            <rect id="bound" x="0" y="0" width="24" height="24" />
                                            <path d="M12,2 C17.5200005,2 22,6.48000002 22,12 C22,17.5200005 17.5200005,22 12,22 C6.57355934,22 2.15216317,17.6705779 2.00384421,12.2797649 L2,12 C2,6.48000002 6.48000002,2 12,2 Z M12,4 C7.66787953,4 4.13556256,7.45057885 4.00363151,11.725023 L3.99981122,11.9725209 L4.00308766,12.2247592 C4.1219319,16.5442816 7.66813936,20 12,20 C16.415431,20 20,16.415431 20,12 C20,7.58456934 16.4154308,4 12,4 Z M17,11 L17,13 L7,13 L7,11 L17,11 Z" id="Combined-Shape" fill="#979797" fillRule="nonzero" />
                                        </g>
                                    </g>
                                </svg>
                                <div className='middle-option-tooltip negate-tooltip'>
                                    <div className='text'>Exclude creator</div>
                                    <div className='arrow-down' />
                                </div>
                            </div>}
                            {onExclusionClick && <div className='middle-option' role='button' style={{ opacity: 1 }} onClick={this.onClickExclusion}>
                                <img src="/images/ic-exclusion.svg" />
                            </div>}
                            {/* <FavoriteLists
                                show={this.state.showFavorites}
                                closeFunction={() => {
                                    this.setState({ showFavorites: false });
                                }}
                                userDetails={details}
                                isOnList={isFavorite}
                            /> */}
                            {this.state.showFavorites && <FavoritePopup
                                usersSelected={details}
                                favList={favList}
                                onChange={(favs) => {this.setState({ favList: favs, settedFav: true })}}
                                setSuccess={e => this.onSetSuccess(e, false)}
                            />}
                            {this.state.showExclusion && <ExclusionPopup
                                usersSelected={details}
                                setSuccess={e => this.onSetSuccess(e, true)}
                                // onChange={(favs) => this.setState({ favList: favs })}
                            />}
                            {this.state.showAddToPlan && <PlanPopup
                                usersSelected={details}
                                setSuccess={e => this.onSetSuccess(e, false)}
                            />}
                            {/* {isConnectedInfluencer && ( */}
                            <div className="moreButton" onClick={this.showActions}>
                                <img src="/images/ic-more-vertical-gray.svg" />
                                <Actions
                                    profile={details}
                                    removeInfluencer={() => this.setState({ showDeleteConfirmation: true })}
                                    permission={permission}
                                    tmAction={showPermissions} // showPermissions is an attr for talent manager page.
                                    show={this.state.showActions}
                                    inviteFunction={inviteDisabled || Boolean(invited) ? null : inviteFunction}
                                    addToPlan={this.addToPlanClick}
                                />
                            </div>
                            {/* )} */}
                        </div>}
                        {excludeFunction && <div className='more-options'>
                            <div className='middle-option' role='button' style={{ opacity: 1 }} onClick={excludeFunction}>
                                <img src="/images/ic-exclusion.svg" />
                            </div>
                        </div>}
                    </div>
                    {/* end of coreFIelds */}

                    {isAlreadyInPlan && <div className='addedInPlanContainer'>Added to this plan:<span>&nbsp;</span><span>{planName}</span></div>}
                    {assignmentAdded && <div className='addedInPlanContainer'>Added to assignments:<span>&nbsp;</span><span>{assignmentAdded}</span></div>}
                    {mostRecentPost && (
                        <div className='recentSponsorPost'>
                            Most recent sponsor post with
                            <span>&nbsp;</span><span>{mostRecentPost.handle}</span><span>&nbsp;</span>
                            on
                            <span>&nbsp;</span><span>{moment(mostRecentPost.postDate).format('MMM DD, YYYY')}</span>.
                        </div>
                    )}
                    {Boolean(invited) && (
                        <div className="invitations">
                            <span>{invited}</span>
                        </div>
                    )}
                    {planIdNote && <div className='planNoteContainer'>
                        <PlanNoteComponent
                            planId={planIdNote}
                            type={isConnectedInfluencer ? 'CONNECTED' : 'PUBLIC'}
                            creator={details}
                            profile={profile}
                            updateState={updateState}
                        />
                    </div>}
                </div>

                {showPermissions ? (
                    <PermissionsExtended show={this.state.showExtended} details={details} />
                ) : (
                    <Extended show={this.state.showExtended} details={details} />
                )}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        refreshAssignmentList: (campaignId) => {
            dispatch(refreshAssignmentList(campaignId));
        },
    };
};

const mapStateToProps = (state) => {
    return {
        favoriteLists: state.FAVORITE_REDUCER.favoriteData,
        sourceTypeFilter: state.campaignReducer.influencerSearch.searchFilters.type,
        profile : state.global.loggedInUserProfile,
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OneInfluencer));
