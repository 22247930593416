import React, { useState } from 'react';
import Modal from "react-modal";
import './nudgeModal.scss';
import Api from '../../../../modules/Api';

export default function NudgeModal({isOpen, close, user, setNotificationMessage, assignmentId}) {

    const [message, setMessage] = useState("");

    if (!user || !isOpen)
        return null;

    const isLimitExceeded = (message && message.length > 1000);

    const onSubmit = () => {
        if (message && user && user.id && assignmentId && !isLimitExceeded) {
            Api.nudgeUsers(user.id, message, assignmentId).then(() => {
                setNotificationMessage("Message sent successfully!");
                setMessage("");
                close();
            }).catch(error => {
                console.log("Error while sending the message: " + error.message);
            });
        }
    }

    return (
        <Modal isOpen={isOpen}
            overlayClassName="invite-modal-overlay"
            className="invite-modal create-brand-modal permission-page agentAdmin new-user-form"
            onRequestClose={close}
            shouldCloseOnOverlayClick
            ariaHideApp={false}
        >
            <div className="modal-header">
                Nudge influencer: {user.firstname} {user.lastname}
            </div>
            <div className="nudgeMessageInput">
                <textarea resize="false" placeholder="Add a message" value={message} onChange={(e) => setMessage(e.target.value)} />
            </div>
            {isLimitExceeded &&
                <div className="nudgeMessageInputError">
                    The message cannot exceed 1000 characters!
                </div>
            }
            <div className="manager-card-bottom new-user-form-bottom">
                <button onClick={close} className="button cancel">Cancel</button>
                <button onClick={onSubmit} className="button" style={(message && !isLimitExceeded) ? null : {opacity: "0.5", cursor: "auto"}} >Submit</button>
            </div>
        </Modal>
    );
}
