import Age from '../../components/influencerProfile/piecesInMain/Age.js'
import Lookup from '../Lookup'
import moment from 'moment'

export const aboutKeyList = [
    'gender',
    'ethnicity',
    'languages',
    'occupation',
    'relationshipStatus',
    'children'
];

export const moreKeyList = [
    'agentName',
    'anniversary',
    'dateOfBirth',
    'createdOn',
    'brands',
    'favoriteColors',
    'foods',
    'hobbies',
    'pets',
    'politicalAffiliation',
    'politicallyActive',
    'religion',
    'memberships',
    ]

export const SINGLE_SELECTION = 1;
export const MULTIPLE_SELECTION = 2;
export const DATE = 3;
export const FREE_LIST = 4;
export const FREE_TEXT = 5;
export const AGE = 6;
export const YES_NO = 7;

// defaults :
//   type SINGLE_SELECTION,
//    lookup : key

export const fieldDetails = {
    age_readonly : {
        type : AGE
    },
    dateOfBirth : {
        type : DATE,
        editType: AGE
    },
    createdOn : {
        type : DATE,
        readOnly: true
    },
    languages : {
        type : MULTIPLE_SELECTION,
        lookup : 'language'
    },
    pets : {
        type : MULTIPLE_SELECTION,
        lookup : 'pet'
    },
    hobbies : {
        type : FREE_LIST
    },
    foods : {
        type : FREE_LIST
    },
    relationshipStatus : {
        lookup : 'relationship_status'
    },
    anniversary : {
        type : DATE
    },
    agentName : {
        type : FREE_TEXT
    },
    politicalAffiliation : {
        lookup : 'political_affiliation'
    },
    politicallyActive : {
        lookup : 'politically_active'
    },
    memberships : {
        type : MULTIPLE_SELECTION,
        lookup : 'membership'
    },
    brands : {
        type : FREE_LIST
    },
    favoriteColors : {
        type : MULTIPLE_SELECTION,
        lookup : 'color'
    }

}

export const fieldType = (key) => {
    let details = fieldDetails[key];
    if (!details) {
        return SINGLE_SELECTION;
    }
    let fieldType = details.type;
    return ( fieldType || SINGLE_SELECTION)
}

export const fieldEditType = (key) => {
    let details = fieldDetails[key];
    if (!details) {
        return SINGLE_SELECTION;
    }
    let fieldType = (details.editType)?details.editType:details.type;
    return ( fieldType || SINGLE_SELECTION)
}

export const fieldEditable = (key) => {
    let details = fieldDetails[key];
    if (!details) {
        return true;
    }
    return !details.readOnly;
}

export const fieldLookupKey = (key) => {
    let details = fieldDetails[key];
    if (!details) {
        return key;
    }
    let fieldLookup = details.lookup;
    return ( fieldLookup || key)
}

export const displayProfileField = (key, profile) => {

    let lookupValue = (key) => {
        let retval = '';
        let id = profile[key];
        let lookupKey = fieldLookupKey(key);
        if (id) {
            let d = Lookup.getDisplay(lookupKey, id, ' ');
            if (d) {
                retval = d;
            }
        }
        return retval;
    }

    let dateValue = (key) => {
        let value = profile[key];
        return value ? ('' + moment(value).format('MMM DD, YYYY')) : '';
    }

    let ageValue = (key) => {
        let val = profile.dateOfBirth;
        if (!val) {
            return '';
        }
        return Age.calculateAge(val);
    }

    let freeValue = (key) => {
        let list = profile[key] || [];
        let retval = '';
        for (let e of list) {
            if (retval.length > 0) {
                retval += ', '
            }
            retval += e;
        }
        return retval;
    }

    let freeSingleValue = (key) => {
        return profile[key];
    }

    if (fieldType(key) == SINGLE_SELECTION) {
        return lookupValue(key)
    }
    if (fieldType(key) === DATE) {
        return dateValue(key);
    }
    if (fieldType(key) === AGE) {
        return ageValue(key);
    }
    if (fieldType(key) === MULTIPLE_SELECTION) {
        return lookupValue(key);
    }
    if (fieldType(key) === FREE_LIST ){
        return freeValue(key);
    }
    if (fieldType(key) === FREE_TEXT) {
        return freeSingleValue(key);
    }
    return key + ' something went wrong'
}



