import React, { Component } from 'react';
import cx from 'classnames';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import FileSaver from 'file-saver';
import PdfViewer from '../shared/pdfViewer/PdfViewer';
import PleaseWait from '../shared/pleaseWait/PleaseWaitWhite';
// import CalendarCard from '../shared/calendarCard/CalendarCard';
import ModalDialog from '../shared/modalDialog/ModalDialogCampaign';
import RichContentEditor from '../shared/contentEditor/RichContentEditor';
import ModalAlertWithAction from '../shared/modalWithSingleAction/ModalAlertWithAction';
import ModalWithAction from '../shared/modalWithSingleAction/ModalWithAction';

import ChangeAddressModal from './modals/ChangeAddressModal';
import AcceptConfirm from './modals/AcceptConfirm';
import Spinner from '../shared/contract/spinner';
import ProposeRate from '../shared/proposeRate/ProposeRate';
import './OpportunityView.scss';
import InfluencerPhoto from '../oneInfluencerNew/InfluencerPhoto';
import Api from '../../modules/Api';
import Lookup from '../../modules/Lookup';
import { safeIsUrl } from '../../modules/CommonServices';
import Format from '../../modules/utils/Format';
import { storeUserProfile } from '../../store/global/globalActions';
// import DateRange from '../influencerAssignments/DateRange/DateRange';
import arraySort from '../../modules/utils/arraySort';
import ExpirationCheckService from '../../services/expirationCheckService';
import { ACCEPTED, DECLINED, REVOKED, EXPIRED, NEGOTIATE, PENDING, CONTRACT_UPLOADED } from '../../constants/statusTypes';
import { GENERAL } from '../../constants/rateTypes';
import { TALENT_MANAGER_STR } from '../../constants/authorities';
import AssignmentContractType from '../../constants/AssignmentContractType';
import ProfileHelper from '../../modules/utils/ProfileHelper';
import ApiError from '../shared/apiError/ApiError';

class OpportunityView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            typeOfSelectionDate: 'draft', // or "publish"
            detail: {},
            uid: '',
            assignment: {},
            publishPickedDate: null,
            draftPickedDate: null,
            declineConfirm: false,
            waitingForRatesApproval: false,
            waiting: false,
            viewStep: 1,
            proposeInProgress: false,
            isShowDraftDate: false,
            isDraftAndPublishDatesSameAssignment: false,
            isContractLoading: false,
            isContractSigningDeclined: false,
            isCurrentUserSignedContract: false,
            showError: false,
            errorMessage: {},
            apiError: null,
            parentStates: this.props.history.location ? this.props.history.location.state : null,
            isAcceptButtonDisabled: true,
            checkBoxes: {
                address: false,
                // payment: false,
                terms: false,
                contract: false,
            },
            isChangeAddressModal: false,
            acceptConfirm: false,
            shippingDetails: {},
            showAcceptedSuccessModal: false,
            isAccepted: false,
            isContractOpen: false,
            pendingAgentSign: false,
            showSuccessSignPopup: false,
            assignmentStatus: '',
            showContractSuccess: false,
            showInsufficientPrivilegesError: false,
            noContractForOfflineAssignment: false,
            deliverables: undefined,
        };

        this.calenderComponentRef = React.createRef();

        this.clearDates = this.clearDates.bind(this);
        this.cancelDatePick = this.cancelDatePick.bind(this);
        this.datePick = this.datePick.bind(this);
        this.backToSummaryPage = this.backToSummaryPage.bind(this);
        this.openContract = this.openContract.bind(this);
        this.closeContractView = this.closeContractView.bind(this);
        this.reviewContract = this.reviewContract.bind(this);
        this.handleProposeRateError = this.handleProposeRateError.bind(this);
        this.uuid = uuidv4();
        this.expiredMessage = 'The campaign has ended! Please reach out to your contact directly for any questions';
    }

    async componentDidMount() {
        this.setState({ waiting: true });
        const {assignmentId} = this.props.match.params;
        try {
            const profile = await ProfileHelper.getCurrentLoginUserProfile();

            this.props.storeProfile(profile);
            this.getOpportunityDetail(assignmentId, profile.uid);

            if (this.props.profile.role && this.props.profile.role !== TALENT_MANAGER_STR)
                this.setShippingDetails(profile);
        } catch(err) {
            this.setState({waiting: false});
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (this.props.profile && this.props.profile.role && 
            this.props.profile.role === TALENT_MANAGER_STR && !_.isEqual(prevState.detail, this.state.detail) && this.state.detail)
            this.setInfluencerShippingDetails(this.state.detail);
    }

    setInfluencerShippingDetails(detail) {
        const addressDetails = detail.user.fullAddress.split(",");
        const shippingStreetValue = addressDetails[0] ? addressDetails[0] : "";
        let restStreetValue = "";
        for (let i = 1; i < addressDetails.length; i++)
            restStreetValue += addressDetails[i];

        this.setState({
            shippingDetails: {
                influencerName: `${detail.user.firstname} ${detail.user.lastname}`,
                street: shippingStreetValue,
                state: restStreetValue,
            },
        });
    }

    setShippingDetails(profile) {
        this.setState({
            shippingDetails: {
                influencerName: `${profile.firstname} ${profile.lastname}`,
                street: `${profile.shippingStreet}, ${
                    profile.shippingApartment ? `${profile.shippingApartment  }, ` : ''
                }${profile.shippingCity}`,
                state: `${profile.shippingState} ${profile.shippingZip}, ${profile.shippingCountry}`,
            },
        });
    }

    getOpportunityDetail(assignmentId, uid) {
        // change call floq to be sequencerd, get assignment data then get opportunity details
        // this.props.match.params.influencerId
        if (this.props.profile && this.props.profile.role === TALENT_MANAGER_STR) {
            Api.getTMAssignment(assignmentId, this.props.match.params.influencerId).then((res) => {
                this.setState({ 
                    manageAssignments: res.manageAssignments,
                    deliverables: res.deliverables,
                });
                let currentUserSignedContract = false;
                let currentUserDeclinedContract = false;
                let pendingAgentSign = false;

                const hasOfflineContract = (res && res.contract && res.contract.status === CONTRACT_UPLOADED);

                const assignmentOpportunityAlreadyDeclined = res.status === DECLINED;
                Api.contractEnvelopeStatus(assignmentId, res.user.uid)
                    .then(
                        (resContractStatus) => {
                            if (resContractStatus.error) {
                                console.log('failed resErrContractStatus', resContractStatus.error);
                            }
                            let contractSigned = false;
                            if (resContractStatus && Array.isArray(resContractStatus.signers) && this.props.profile) {
                                contractSigned = true;
                                resContractStatus.signers.forEach((signer) => {
                                    const status = signer.status.toLowerCase();
                                    contractSigned = contractSigned && signer.status === 'completed';
                                    const originCreatorEmail = (res && res.user && res.user.email) || ''
                                    if (signer.email === this.props.profile.email || signer.email === originCreatorEmail) {
                                        if (signer.status === 'completed') {
                                            currentUserSignedContract = true;
                                        }
                                        if (signer.status === 'declined') {
                                            currentUserDeclinedContract = true;
                                        }
                                    } else {
                                        pendingAgentSign = status !== 'completed';
                                    }
                                });
                            }

                            contractSigned = contractSigned || hasOfflineContract;
                            pendingAgentSign = hasOfflineContract ? false : pendingAgentSign;

                            this.doFinalStuff(
                                res.assignment,
                                assignmentId,
                                uid,
                                res,
                                currentUserSignedContract,
                                currentUserDeclinedContract,
                                assignmentOpportunityAlreadyDeclined,
                                pendingAgentSign,
                                contractSigned
                            );
                        },
                        (errContractStatus) => {
                            console.log('failed errContractStatus', errContractStatus);
                            if (hasOfflineContract && res.assignment.assignmentContractType === AssignmentContractType.OFFLINE_CONTRACT) {
                                this.doFinalStuff(
                                    res.assignment,
                                    assignmentId,
                                    uid,
                                    res,
                                    currentUserSignedContract,
                                    currentUserDeclinedContract,
                                    assignmentOpportunityAlreadyDeclined,
                                    false,
                                    true
                                );
                            } else {
                                this.doFinalStuff(
                                    res.assignment,
                                    assignmentId,
                                    uid,
                                    res,
                                    currentUserSignedContract,
                                    currentUserDeclinedContract,
                                    assignmentOpportunityAlreadyDeclined,
                                );
                            }
                        },
                    )
                    .catch((errContractStatus) => {
                        console.log('failed contractEnvelopeStatus', errContractStatus);
                        this.doFinalStuff(
                            res.assignment,
                            assignmentId,
                            uid,
                            res,
                            currentUserSignedContract,
                            currentUserDeclinedContract,
                            assignmentOpportunityAlreadyDeclined,
                        );
                    });
            });
        } else {
            Api.getAssignmentUser(assignmentId, uid).then((resDetail) => {
                this.setState({ 
                    assignment: resDetail.assignment,
                    deliverables: resDetail.deliverables,
                });
                let currentUserSignedContract = false;
                let currentUserDeclinedContract = false;
                let pendingAgentSign = false;
                const assignmentOpportunityAlreadyDeclined = resDetail.status === DECLINED;
                const hasOfflineContract = (resDetail && resDetail.contract && resDetail.contract.status === CONTRACT_UPLOADED);
                Api.getCurrencyCode(resDetail.assignment.campaign.currencyCode).then(result => {
                    this.setState({currency: result.data})
                })
                Api.contractEnvelopeStatus(assignmentId, uid)
                    .then(
                        (resContractStatus) => {
                            if (resContractStatus.error) {
                                console.log('failed resErrContractStatus', resContractStatus.error);
                            }
                            let contractSigned = false;
                            // console.log('success resContractStatus', resContractStatus);
                            if (
                                resContractStatus &&
                                Array.isArray(resContractStatus.signers) &&
                                this.props.profile
                            ) {
                                contractSigned = true;
                                resContractStatus.signers.forEach((signer) => {
                                    const status = signer.status.toLowerCase();
                                    contractSigned = contractSigned && signer.status === 'completed';
                                    const originCreatorEmail = (resDetail && resDetail.user && resDetail.user.email) || ''
                                    if (signer.email === this.props.profile.email || signer.email === originCreatorEmail) {
                                        if (status === 'completed') {
                                            currentUserSignedContract = true;
                                        }
                                        if (status === 'declined') {
                                            currentUserDeclinedContract = true;
                                        }
                                    } else {
                                        pendingAgentSign = status !== 'completed';
                                    }
                                });
                            }
                            // add check to step step 3 using contractSigned check if offline upload was done.
                            contractSigned = contractSigned || hasOfflineContract;
                            pendingAgentSign = hasOfflineContract ? false : pendingAgentSign;
                            this.doFinalStuff(
                                resDetail.assignment,
                                assignmentId,
                                uid,
                                resDetail,
                                currentUserSignedContract,
                                currentUserDeclinedContract,
                                assignmentOpportunityAlreadyDeclined,
                                pendingAgentSign,
                                contractSigned,
                            );
                        },
                        (errContractStatus) => {
                            console.log('failed errContractStatus', errContractStatus);
                            if (hasOfflineContract && resDetail.assignment.assignmentContractType === AssignmentContractType.OFFLINE_CONTRACT) {
                                this.doFinalStuff(
                                    resDetail.assignment,
                                    assignmentId,
                                    uid,
                                    resDetail,
                                    currentUserSignedContract,
                                    currentUserDeclinedContract,
                                    assignmentOpportunityAlreadyDeclined,
                                    false,
                                    true
                                );
                            } else {
                                this.doFinalStuff(
                                    resDetail.assignment,
                                    assignmentId,
                                    uid,
                                    resDetail,
                                    currentUserSignedContract,
                                    currentUserDeclinedContract,
                                    assignmentOpportunityAlreadyDeclined,
                                );
                            }
                        },
                    )
                    .catch((errContractStatus) => {
                        console.log('failed contractEnvelopeStatus', errContractStatus);
                        this.doFinalStuff(
                            resDetail.assignment,
                            assignmentId,
                            uid,
                            resDetail,
                            currentUserSignedContract,
                            currentUserDeclinedContract,
                            assignmentOpportunityAlreadyDeclined,
                        );
                    });
            })
            .catch(err => {
                console.log('err', err);
                if (err && err.status === 404) {
                    this.props.history.push({ pathname: '/opportunities'});
                }else {
                    this.setState({
                        apiError: err,
                        waiting: false,
                    })    
                }
            });
            // });
        }
    }

    getAssignmentDates = (resDetail) => {
        const { negotiationsLogs, assignmentSlots } = resDetail;
        const dates = {};

        if (Array.isArray(negotiationsLogs) && negotiationsLogs.length) {
            const lastLog = negotiationsLogs.sort((a, b) => Number(b.id) - Number(a.id))[0];
            const datesArray = lastLog.dates || null;
            let publishPickedDate;
            let draftPickedDate = null;

            if (datesArray && datesArray.length) {
                const hasTwo = datesArray.length > 1;
                const pickedDate1 = moment(datesArray[0], 'YYYY-MM-DD');
                const pickedDate2 = hasTwo ? moment(datesArray[1], 'YYYY-MM-DD') : null;

                if (hasTwo) {
                    publishPickedDate = pickedDate1.isBefore(pickedDate2) ? pickedDate2 : pickedDate1;
                    draftPickedDate = pickedDate1.isBefore(pickedDate2) ? pickedDate1 : pickedDate2;
                } else {
                    publishPickedDate = pickedDate1;
                }
            }
            dates.publish = publishPickedDate;
            dates.darft = draftPickedDate;
        }

        if (!dates.publish && !dates.darft && assignmentSlots && assignmentSlots.length) {
            const publishDate = assignmentSlots.find(({ type }) => type === 'PUBLISH').dueDate;
            const draftDate = assignmentSlots.find(({ type }) => type === 'DRAFT').dueDate;
            dates.publish = publishDate ? moment(publishDate, 'YYYY-MM-DD') : null;
            dates.darft = draftDate ? moment(draftDate, 'YYYY-MM-DD') : null;
        }

        return dates;
    };

    redirectToDraftList(assignmentId) {
        this.props.history.push({
            pathname: `/assignments/${assignmentId}/draftList`,
            search: this.props.match.params.influencerId ? `?influencerId=${this.props.match.params.influencerId}` : '',
            state: this.state.parentStates,
        });
    }

    openContract() {
        this.setState({ isContractOpen: true });
    }

    closeContractView() {
        this.setState({ isContractOpen: false });
    }

    reviewContract() {
        this.setState({ waiting: true });
        const getDoc = this.props.profile && this.props.profile.role && this.props.profile.role === TALENT_MANAGER_STR ?
            () => Api.contractGenerateTMReviewContractLink(this.state.assignment.id, this.props.match.params.influencerId) :
            () => Api.contractGenerateReviewContractLink(this.state.assignment.id, this.state.uid);

        getDoc().then(
            (res) => {
                FileSaver.saveAs(res, `Koalifyed Contract Review ${  this.state.assignment.id  }.docx`);
                this.setState({ waiting: false });
                return Promise.resolve();
            }
        ).catch((err) => {
            console.log("Could not download the contract preview.", err);
            this.setState({
                waiting: false,
                apiError: err,
            })
            return Promise.resolve();
        });
    }

    doFinalStuff = (
        res,
        assignmentId,
        uid,
        resDetail,
        currentUserSignedContract,
        currentUserDeclinedContract,
        assignmentOpportunityAlreadyDeclined,
        pendingAgentSign = false,
        contractSigned = false,
    ) => {
        const { status, negotiationsLogs } = resDetail;
        const { assignment } = this.state;

        const expirationService = new ExpirationCheckService(
            assignment,
            assignmentId,
            status,
        );
        expirationService.init();
        const {assignmentStatus} = expirationService;

        let setStep = 1;
        const shouldHandleDecline = !assignmentOpportunityAlreadyDeclined && currentUserDeclinedContract;
        const isAccepted = assignmentStatus === ACCEPTED;
        const hasSigningCompleteEvent =
            this.props.location && this.props.location.search.includes('event=signing_complete');
        const dates = this.getAssignmentDates(resDetail);
        const hasDates = dates.publish || dates.darft;
        let waitingForRatesApproval = false;

        if (negotiationsLogs.length > 1) {
            arraySort(negotiationsLogs, 'DESC', 'id');
            const lastLog = negotiationsLogs[0];

            if (assignmentStatus === NEGOTIATE && lastLog.player === 'USER') {
                waitingForRatesApproval = true;
            }
        }

        let newState = {
            assignment: res,
            assignmentStatus,
            isAccepted,
            checkBoxes: {
                address: isAccepted,
                // payment: false,
                terms: isAccepted,
                contract: isAccepted,
            },
            detail: resDetail,
            uid,
            waiting: false,
            waitingForRatesApproval,
            pendingAgentSign,
            isContractSigned: contractSigned,
            isShowDraftDate: res.draftRequired,
            isCurrentUserSignedContract: currentUserSignedContract,
            isContractSigningDeclined: currentUserDeclinedContract,
            showSuccessSignPopup: hasSigningCompleteEvent && currentUserSignedContract,
        };

        if (isAccepted) {
            if (hasDates) {
                this.redirectToDraftList(assignmentId);
                return;
            }
            setStep = 2;
        }

        if (hasDates) {
            // TODO: according to vlad, isDraftAndPublishDatesSameAssignment is obsolete and should be deleted from usage.
            newState = {
                ...newState,
                publishPickedDate: dates.publish,
                draftPickedDate: dates.darft,
                isDraftAndPublishDatesSameAssignment: true,
                acceptedSuccess: hasSigningCompleteEvent && currentUserSignedContract,
            };
        } else {
            newState.viewStep = 2;
        }

        if (((pendingAgentSign || 
            (this.props.profile && this.props.profile.role && this.props.profile.role === TALENT_MANAGER_STR)) && 
            currentUserSignedContract && !currentUserDeclinedContract) || contractSigned) {
            // this.setState({ showContractSuccess: true });
            // setStep = 3;
            this.redirectToDraftList(assignmentId);
            return;
        }

        const hasOfflineContract = (resDetail && resDetail.contract && resDetail.contract.status === CONTRACT_UPLOADED);
        if (hasOfflineContract && resDetail.assignment.assignmentContractType === AssignmentContractType.OFFLINE_CONTRACT) {
            // setStep = 3;
            this.redirectToDraftList(assignmentId);
            return;
        }

        newState.viewStep = setStep;
        this.setState(newState, () => {
            this.handleDeclined(shouldHandleDecline, assignmentId, uid);
        });

        if (!hasOfflineContract && resDetail.assignment.assignmentContractType === AssignmentContractType.OFFLINE_CONTRACT)
            this.setState({noContractForOfflineAssignment: true});

        if (currentUserDeclinedContract) {
            this.setState({
                isContractSigningDeclined: currentUserDeclinedContract,
                showError: true,
                errorMessage: {
                    titleId: 'opportunityView.contractSigningDeclinedTitle',
                    messageId: 'opportunityView.contractSigningDeclinedMessage',
                },
            });
        }
    };

    handleDeclined = (currentUserDeclinedContract, assignmentId, uid) => {
        if (currentUserDeclinedContract) {
            // new use case, based on @Whitney and @qa_linda, decline them direct for the assignment.
            this.declineOpportunity(assignmentId, uid);
        }
    };

    datePick(year, month, day, isDraft) {
        const pickedOneDay = year && month && day ? moment({ year, month, day }) : null;
        this.setState(isDraft ? { draftPickedDate: pickedOneDay } : { publishPickedDate: pickedOneDay });
    }

    cancelDatePick(isDraft = false, isPublish = false) {
        const updateStateObj = {};
        if (isDraft) {
            updateStateObj.draftPickedDate = null;
            this.calenderComponentRef.current.clearDraftDate();
        }
        if (isPublish) {
            updateStateObj.publishPickedDate = null;
            this.calenderComponentRef.current.clearPublishDate();
        }
        this.setState(updateStateObj);
    }

    acceptOpportunity(assignmentId, uid, isDPFAF) {
        this.handleDocusignFlow(assignmentId, uid);
        if (!isDPFAF) {
            this.setState({
                viewStep: 2,
                proposeInProgress: false,
            });
        }
    }

    closeNegotiateModal = () => {
        this.setState({
            proposeInProgress: false,
        });
    };

    declineOpportunity(assignmentId, uid) {
        const input = {
            action: 'DECLINE',
        };
        const self = this;
        if (this.props.profile && this.props.profile.role && this.props.profile.role === TALENT_MANAGER_STR) {
            Api.TMChangeOpportunityStatus(assignmentId, this.props.match.params.influencerId, input)
                .then(() => {
                    self.getOpportunityDetail(assignmentId, uid);
                })
                .catch(err => {
                    this.setState({
                        apiError: err
                    })
                });
        } else {
            Api.changeOpportunityStatus(assignmentId, uid, input)
                .then(() => {
                    self.getOpportunityDetail(assignmentId, uid);
                    // this.props.history.push("/opportunities")
                })
                .catch(err => {
                    this.setState({
                        apiError: err
                    })
                });
        }
    }

    handleDocusignFlow = (assignmentId, uid) => {
        const self = this;
        const { isContractLoading, isContractSigningDeclined } = this.state;
        const { location, match }=this.props;
        const isAllowGenerateNewContract = isContractSigningDeclined === true;
        const returnPath = (location && location.pathname) || '/';
        if (!isContractLoading) {
            self.setState({ isContractLoading: true }, () => {
                // console.log('handleDocusignFlow');
                if (self.props.profile && self.props.profile.role && self.props.profile.role === TALENT_MANAGER_STR) {
                    Api.talentManagerContractGenerateContractLink(
                        assignmentId,
                        match.params.influencerId,
                        isAllowGenerateNewContract,
                        returnPath
                    )
                        .then(
                            (res) => {
                                this.handleResponse(res, self);
                            },
                            (err) => {
                                this.handleError(err, self);
                            },
                        )
                        .catch((err) => {
                            this.setState({apiError: err, isContractLoading: false, showAcceptedSuccessModal: false})
                        });
                } else {
                    Api.contractGenerateContractLink(assignmentId, uid, isAllowGenerateNewContract, returnPath)
                        .then(
                            (res) => {
                                this.handleResponse(res, self);
                            },
                            (err) => {
                                this.handleError(err, self);
                            },
                        )
                        .catch((err) => {
                            this.setState({apiError: err, isContractLoading: false, showAcceptedSuccessModal: false})
                        });
                }
            });
        }
    };

    catchError(err, self) {
        // todo log honey badger here
        console.log('unhandled catch error', err);

        // console.log('failed handleDocusignFlow 2', err);
        self.setState({
            isContractLoading: false,
            showError: true,
            errorMessage: {
                titleId: 'opportunityView.contractSigningGenericErrorTitle',
                messageId: 'opportunityView.contractSigningGenericErrorMsg',
            },
        });
    }

    handleError(err, self) {
        // console.log('failed handleDocusignFlow 1', err);
        if (err === 'Missing Contract Mapping') {
            self.setState({
                isContractLoading: false,
                showError: true,
                errorMessage: {
                    titleId: 'opportunityView.needTemplateTitle',
                    messageId: 'opportunityView.needTemplateMessage',
                },
            });
        } else if (err === 'Missing contractData mapping') {
            self.setState({
                isContractLoading: false,
                showError: true,
                errorMessage: {
                    titleId: 'opportunityView.needTemplateMappingTitle',
                    messageId: 'opportunityView.needTemplateMappingMessage',
                },
            });
        } else {
            self.setState({
                apiError: err,
                isContractLoading: false,
            });
        }
    }

    handleResponse(res, self) {
        console.log('success handleDocusignFlow', res);
        // console.log(res);

        if (res && res.error) {
            if (res.error === 'Incomplete Address') {
                self.setState({
                    isContractLoading: false,
                    showError: true,
                    errorMessage: {
                        titleId: 'opportunityView.needShippingInfoTitle',
                        messageId: 'opportunityView.needShippingInfoMessage',
                    },
                });
            } else {
                self.setState({
                    isContractLoading: false,
                    showError: true,
                    errorMessage: {
                        titleId: 'opportunityView.contractSigningGenericErrorTitle',
                        messageId: 'opportunityView.contractSigningGenericErrorMsg',
                    },
                });
            }
        }

        if (res.result && res.result.url) {
            window.location = res.result.url;
        }
    }

    isShowDraftDate = (isShowDraftDate) => {
        this.setState({
            isShowDraftDate,
        });
    };

    isDraftAndPublishFromSameAssignment = (isSameAssignment) => {
        this.setState({
            isDraftAndPublishDatesSameAssignment: isSameAssignment,
        });
    };

    clearDates() {
        this.cancelDatePick(moment(this.state.draftPickedDate).isSameOrAfter(moment(), 'day'), true);
    }

    backToSummaryPage() {
        this.setState({ viewStep: 1 });
    }

    handleNegotiateClick = () => {
        this.setState({ proposeInProgress: true });
    };

    handleDeclineClick = () => {
        this.setState({ declineConfirm: true });
    };

    handleAccept = async () => {
        const input = {
            action: 'ACCEPT',
        };
        const {assignmentId} = this.props.match.params;
        const { uid } = this.state;
        try {
            if (this.props.profile && this.props.profile.role && this.props.profile.role === TALENT_MANAGER_STR) {
                await Api.TMChangeOpportunityStatus(assignmentId, this.props.match.params.influencerId, input);
            } else {
                await Api.changeOpportunityStatus(assignmentId, uid, input);
            }
            this.setState({
                acceptConfirm: false,
                showAcceptedSuccessModal: true,
                isAccepted: true,
            });
        } catch (e) {
            this.handleError(e, this);
        }
    };

    getAllCheckBoxesChecked = () => Object.values(this.state.checkBoxes).every((value) => !!value);

    handleCheckBoxState = (event) => {
        const { name, checked } = event.target;
        this.setState({
            checkBoxes: {
                ...this.state.checkBoxes,
                [name]: checked,
            },
        });
    };

    acceptConfirmation = () => {
        this.setState({
            acceptConfirm: true,
        });
    };

    openChangeAddressModal = () => {
        if (this.state.isAccepted) return;

        this.setState({
            isChangeAddressModal: true,
        });
    };

    handleChangeAddress = async (address) => {
        try {
            if (this.props.profile && this.props.profile.role && this.props.profile.role === TALENT_MANAGER_STR) {
                const profile = await ProfileHelper.saveProfile(address, this.state.detail.user.id);
                this.setShippingDetails(profile);
            } else {
                const profile = await Api.updateShippingAddress(this.props.profile.id, address);
                this.setShippingDetails(profile);
                this.props.storeProfile(profile);
            }
        } catch (error) {
            this.setState({
                apiError: error
            })
        }

        this.setState({
            isChangeAddressModal: false,
        });
    };

    getConfirmAndReviewRates = () => {
        const {
            detail: { rates },
        } = this.state;

        if (!rates) return [];

        const perCampaign = Object.prototype.hasOwnProperty.call(rates, GENERAL);
        const perCampaignFilter = ([rateType, rateValue]) => rateType === GENERAL;
        const perChannelFilter = ([rateType, rateValue]) => rateType !== GENERAL;
        const filter = perCampaign ? perCampaignFilter : perChannelFilter;

        return Object.fromEntries(Object.entries(rates).filter(filter));
    };

    handleProposeRateError(error) {
        if (error && error.originalMessage === "Insufficient privileges")
            this.setState({showInsufficientPrivilegesError: true});
    }

    renderSingleDeliverableInfo = (type, count) => {
        if (count <= 0) {
            return null;
        }
        return (
            <div key={type} className="choreContainer">
                <div className="choreLabel">
                    {`${Lookup.getRateType(type).label} x ${count}`}
                </div>
            </div>
        );
    }

    renderAssignmentDeliverablesInfo() {
        const { deliverables } = this.state;
        if (!deliverables)
            return null;

        return (
            <div className="stepView__block calendar-container">
                <div className="summary">
                    <div className="title block-title chores-header">
                        Assignment Deliverables
                    </div>
                    {Object.entries(deliverables).map(([key, value]) => this.renderSingleDeliverableInfo(key, value))}
                </div>
            </div>
        );
    }

    renderSocialNetworkOptions() {
        const {assignment} = this.state;
        if (assignment && ((Array.isArray(assignment.brandHandles) && assignment.brandHandles.length > 0) ||
            (Array.isArray(assignment.programHashtags) && assignment.programHashtags.length > 0))) {
            return (
                <div className="stepView__block calendar-container">
                    <div className="summary">
                        <div className="title chores-header">Social Network Options</div>
                        {Array.isArray(assignment.brandHandles) && assignment.brandHandles.length > 0 && (
                            <div className="brandHandles firstHandle">
                                <div className="handleTitle">Brand Handles:</div>
                                {assignment.brandHandles.map((item, i) => {
                                    return (
                                        <div className="handleLabel" key={`bh-${  i}`}>
                                            <span>{item.type.toLowerCase()}: </span>
                                            {item.handle}
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                        {Array.isArray(assignment.programHashtags) && assignment.programHashtags.length > 0 && (
                            <div className={cx("brandHandles", {firstHandle: Array.isArray(assignment.brandHandles) && assignment.brandHandles.length === 0})}>
                                <div className="handleTitle">Program hashtags:</div>
                                {assignment.programHashtags.map((item, i) => {
                                    return (
                                        <div className="handleLabel" key={`ph-${  i}`}>
                                            {item}
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </div>
            )
        }
        return null;
    }

    renderBrandUrls() {
        const {assignment} = this.state;
        if (assignment && Array.isArray(assignment.brandUrls) && assignment.brandUrls.length > 0) {
            return (
                <div className="stepView__block calendar-container">
                    <div className="summary">
                        <div className="title chores-header">Brand Urls</div>
                        <ol>
                            {assignment.brandUrls.map((item, i) => {
                                return item.url && safeIsUrl(item.url) ? (
                                    <li key={i}>
                                        <a href={Format.webAddress(item.url)} target="_blank">
                                            {item.url}
                                        </a>
                                        <div>{item.description}</div>
                                    </li>
                                ) : null;
                            })}
                        </ol>
                    </div>
                </div>
            )
        }
        return null;
    }

    render() {
        const { detail, uid, assignment, viewStep, noContractForOfflineAssignment } = this.state;
        const { proposeInProgress, isContractLoading, shippingDetails, manageAssignments } = this.state;
        const { isAccepted, showAcceptedSuccessModal, pendingAgentSign, checkBoxes } = this.state;
        const { assignmentStatus, isContractSigningDeclined, isCurrentUserSignedContract } = this.state;
        const { waitingForRatesApproval, showError, apiError, errorMessage, currency } = this.state;
        const {assignmentId} = this.props.match.params;
        let lastPlayer = 'USER';
        let lastLog = {};
        if (Array.isArray(detail.negotiationsLogs) && detail.negotiationsLogs.length > 0) {
            lastLog = detail.negotiationsLogs.sort((s1, s2) => {
                return Number(s2.id) - Number(s1.id);
            })[0];
            lastPlayer = lastLog.player;
        }
        let assignmentTypes = [];
        if (detail && typeof detail.deliverables === 'object') {
            assignmentTypes = Object.keys(detail.deliverables);
        }

        let isAcceptButtonDisabled = true;
        let isDeclineButtonDisabled = true;

        if (this.props.profile && lastPlayer !== 'USER') {
            if (this.props.profile.role && this.props.profile.role === TALENT_MANAGER_STR) {
                isAcceptButtonDisabled = !(manageAssignments && this.getAllCheckBoxesChecked());
                isDeclineButtonDisabled = !manageAssignments;
            } else {
                isAcceptButtonDisabled = !this.getAllCheckBoxesChecked();
                isDeclineButtonDisabled = false;
            }
        }

        const self = this;
        const goBack = function () {
            if (self.props.profile && self.props.profile.role && self.props.profile.role === TALENT_MANAGER_STR) {
                self.props.history.push({ pathname: '/talentOpportunities', state: self.state.parentStates });
            } else {
                self.props.history.push({ pathname: '/opportunities', state: self.state.parentStates });
            }
        };

        const isAssignmentDeclined = assignmentStatus === DECLINED;
        const isAssignmentRevoked = assignmentStatus === REVOKED;
        const isAcceptFormDisabled =
            assignmentStatus === DECLINED || assignmentStatus === REVOKED || assignmentStatus === EXPIRED || isAccepted || waitingForRatesApproval;
        let warningBlockText = '';

        if (isAssignmentDeclined) {
            warningBlockText = 'Assignment declined!';
        }else if (isAssignmentRevoked) {
            warningBlockText = 'This opportunity has been withdrawn by the brand.';
        } else if (waitingForRatesApproval) {
            warningBlockText = 'Waiting for suggested rates approval!';
        } else if (pendingAgentSign && isCurrentUserSignedContract && !isContractSigningDeclined) {
            warningBlockText = 'Awaiting Final Contract. Please check back soon to begin working on this Assignment.';
        } else if (noContractForOfflineAssignment) {
            warningBlockText = 'Waiting for agent to upload your contract.';
        }

        const confirmAndReviewRates = this.getConfirmAndReviewRates();
        const readOnly = noContractForOfflineAssignment
        const dueDate = assignment.dueDate && moment(assignment.dueDate).format('ll');
        return (
            <div className="opportunity">
                {this.state.isContractOpen &&
                    <PdfViewer pdfUrl="/images/docs/PlatformPrivacyAgreement.pdf" onClose={() => this.closeContractView()} />
                }
                <PleaseWait show={this.state.waiting} />
                <ApiError
                    show={apiError}
                    error={apiError}
                    cancelFunction={() => this.setState({apiError: null})}
                />
                <ModalAlertWithAction
                    titleId={errorMessage.titleId}
                    messageId={errorMessage.messageId}
                    show={showError}
                    actionFunction={() => this.setState({ showError: false, errorMessage: {} })}
                />

                <ModalWithAction
                    titleId="opportunityView.acceptSuccessTitle"
                    messageId={`opportunityView.${
                        viewStep === 1 || viewStep === 2 ? 'acceptSuccessMessage' : 'acceptDatesSuccessMessage'
                    }`}
                    show={showAcceptedSuccessModal}
                    flavor="success"
                    customBtn={viewStep !== 3?(
                        <div
                            className="button docusign" style={{fontSize:0, width: "unset"}}
                            onClick={() => this.handleDocusignFlow(assignmentId, uid)}
                        >
                            {isContractLoading ? (
                                <Spinner label="Generating Contract. Loading." />
                            ) : (
                                <img src="/images/docusign.png" alt="" style={{width:100, height:28}}/>
                            )}
                        </div>
                    ):null}
                    actionFunction={() => {
                        this.setState({
                            showAcceptedSuccessModal: false,
                            viewStep: 2,
                        });
                    }}
                />

                {detail.user && (detail.status === NEGOTIATE || detail.status === PENDING) && (
                    <ProposeRate
                        player="USER"
                        isTalentManager={this.props.profile && this.props.profile.role && this.props.profile.role === TALENT_MANAGER_STR}
                        user={detail.user}
                        aid={assignmentId}
                        atypes={assignmentTypes}
                        slots={detail.negotiationsLogs}
                        show={proposeInProgress}
                        acceptReady
                        cancelFunction={() => {
                            this.setState({ proposeInProgress: false });
                        }}
                        proceedFunction={this.closeNegotiateModal}
                        cancelButtonFunction={() => {
                            this.setState({ declineConfirm: true, proposeInProgress: false });
                        }}
                        sentNewRate={() => this.getOpportunityDetail(assignmentId, uid)}
                        apiErrFunction={this.handleProposeRateError}
                    />
                )}
                <div className="opportunityHeader">
                    <div className="contentDiv">
                        <div className="opportunityHeader-info">
                            <img src="/images/ic-long-back.svg" onClick={goBack} alt="" className="back" />
                            {this.props.profile && this.props.profile.role && this.props.profile.role === TALENT_MANAGER_STR && (
                                <>
                                    <div className="opportunity-img-container">
                                        <InfluencerPhoto url={detail.user && detail.user.pictureUrl} firstName={detail.user && detail.user?.firstname} lastName={detail.user && detail.user?.lastname} />
                                    </div>
                                    <div className="name">
                                        {detail.user && detail.user.firstname} {detail.user && detail.user.lastname}
                                    </div>
                                    <div className="separator">|</div>
                                </>
                            )}
                            <div className="details">{detail.assignment && detail.assignment.campaign.name}: </div>
                            <div className="details-two">{detail.assignment && detail.assignment.name}</div>
                        </div>
                    </div>
                </div>
                <div data-step={viewStep} className="opportunityContainer opportunityContainer--assignment">
                    <div className="contentDiv">
                        <div className="stepView">
                            {!!warningBlockText && (
                                <div className="contractNotice">
                                    <img alt="" src="/images/ic-small-warning.svg" />
                                    {warningBlockText}
                                </div>
                            )}

                            {(viewStep === 1 || viewStep === 2) && (
                                <div data-step={viewStep} className="stepView__content assignmentDetails">
                                    <div className="stepView__block">
                                        <div className="assignmentDetails__header">
                                            {assignment.campaign && (
                                                <img
                                                    src={
                                                        assignment.campaign.brandImage
                                                            ? assignment.campaign.brandImage
                                                            : '/images/brandLogo.png'
                                                    }
                                                    className="logo"
                                                />
                                            )}
                                            <div>{assignment.name}</div>
                                        </div>
                                        <div className="summary">
                                            <div className="title block-title">Assignment Summary</div>
                                            <RichContentEditor
                                                className="richContentEditor"
                                                readOnly
                                                content={assignment.summary}
                                            />
                                        </div>
                                        {assignment.checklist && assignment.checklist.length && (
                                            <div className="checklist">
                                                <div className="title block-title">Checklist:</div>
                                                <ol>
                                                    {assignment.checklist.map((item, i) => {
                                                        return <li key={i}>{item}</li>;
                                                    })}
                                                </ol>
                                            </div>
                                        )}
                                    </div>
                                    {this.renderSocialNetworkOptions()}
                                    {this.renderBrandUrls()}
                                    {this.renderAssignmentDeliverablesInfo()}

                                </div>
                            )}
                            
                        </div>
                        <aside className="opportunity-sidebar">
                            {(viewStep === 1 || viewStep === 2) && (
                                <div data-step={viewStep} className="opportunityDetails sidebar-block">
                                    <div className="headerWrap">
                                        <div className="title">Review &amp; Confirm</div>
                                        <div className="subtitle">
                                            Please review and confirm the following information before accepting the
                                            assignment
                                        </div>
                                    </div>
                                    {dueDate && <div className="agreeWrap">
                                        <div className="agreeSubTitle">Due Date</div>
                                        <span >{dueDate}</span>
                                    </div>}
                                    <div className="accordionWrap">
                                        <Accordion
                                            allowMultipleExpanded
                                            allowZeroExpanded
                                            preExpanded={[this.uuid]}
                                        >
                                            <AccordionItem uuid={this.uuid}>
                                                <AccordionItemHeading>
                                                    <AccordionItemButton>Shipping Address</AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                    <div
                                                        className={`checkbox${isAcceptFormDisabled ? ' disabled' : ''}`}
                                                    >
                                                        <div className="checkboxHolder">
                                                            <input
                                                                type="checkbox"
                                                                className="checkboxCustom"
                                                                id="address"
                                                                name="address"
                                                                checked={checkBoxes.address && !(isAcceptFormDisabled || readOnly)}
                                                                disabled={isAcceptFormDisabled || readOnly}
                                                                onChange={this.handleCheckBoxState}
                                                            />
                                                            <label htmlFor="address">
                                                                <span>{shippingDetails.influencerName}</span><br />
                                                                {shippingDetails.street} <br />
                                                                {shippingDetails.state}
                                                            </label>
                                                        </div>
                                                        <div
                                                            className="checkboxEdit"
                                                            onClick={readOnly ? null : this.openChangeAddressModal}
                                                        >
                                                            <span style={{color: (isAcceptFormDisabled || readOnly) ? "#808080":"#4AA129", cursor: (isAcceptFormDisabled || readOnly) ? "default" : "pointer"}}>Edit</span>
                                                        </div>
                                                    </div>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                        </Accordion>
                                    </div>
                                    <div className="totalWrap">
                                        <div className="totalInner">
                                            <div className="totalItem">
                                                Total Payment
                                                <span>
                                                    {currency && detail && detail.totalRate !== undefined && detail.totalRate !== '' && currency.symbol}
                                                    {detail && detail.totalRate !== undefined && detail.totalRate !== '' ? new Intl.NumberFormat({ currency: 'CAD' }).format(
                                                        detail.totalRate,
                                                    ): ''}
                                                    {currency && detail && detail.totalRate !== undefined && detail.totalRate !== '' && ` ${currency.code}`}
                                                </span>
                                            </div>
                                            {Object.entries(confirmAndReviewRates).map(([type, rate], index) => {
                                                return (
                                                    <div className="totalItem" key={`${type  }_${   + index}`}>
                                                        {Lookup.getRateType(type).label}
                                                        <span>
                                                            {currency && currency.symbol}
                                                            {new Intl.NumberFormat({ currency: 'CAD' }).format(
                                                                rate,
                                                            )}
                                                            {currency && ` ${currency.code}`}
                                                        </span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    <div className="agreeWrap">
                                        <div className="agreeSubTitle">Contract</div>
                                        <div className="agree">
                                            <div className={`checkbox${isAcceptFormDisabled ? ' disabled' : ''}`}>
                                                <div className="checkboxHolder">
                                                    <input
                                                        type="checkbox"
                                                        className="checkboxCustom"
                                                        id="contract"
                                                        name="contract"
                                                        checked={checkBoxes.contract && !(isAcceptFormDisabled || readOnly)}
                                                        disabled={isAcceptFormDisabled || readOnly}
                                                        onChange={this.handleCheckBoxState}
                                                    />
                                                    <label htmlFor="contract" />
                                                    <label>
                                                        <span   style={{color: (isAcceptFormDisabled || readOnly) ? "#808080":"#4AA129", cursor: (isAcceptFormDisabled || readOnly) ? "default" : "pointer", fontSize: "15px"}}
                                                            onClick={(isAcceptFormDisabled || readOnly) ? null : this.reviewContract}>Review Contract
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="agreeWrap">
                                        <div className="agreeSubTitle">Terms &amp; Conditions</div>
                                        <div className="agree">
                                            <div className={`checkbox${isAcceptFormDisabled ? ' disabled' : ''}`}>
                                                <div className="checkboxHolder">
                                                    <input
                                                        type="checkbox"
                                                        className="checkboxCustom"
                                                        id="terms"
                                                        name="terms"
                                                        checked={checkBoxes.terms && !(isAcceptFormDisabled || readOnly)}
                                                        disabled={isAcceptFormDisabled || readOnly}
                                                        onChange={this.handleCheckBoxState}
                                                    />
                                                    <label htmlFor="terms">
                                                        I agree to the <span style={{cursor: "pointer"}} onClick={this.openContract}>Terms and Conditions</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="footerWrap">
                                        <div
                                            className="buttonDecline"
                                            disabled={isDeclineButtonDisabled || isAcceptFormDisabled || readOnly}
                                            onClick={this.handleDeclineClick}
                                        >
                                            Decline
                                        </div>
                                        <div
                                            className="buttonNegotiate"
                                            disabled={isAcceptFormDisabled || readOnly}
                                            onClick={this.handleNegotiateClick}
                                        >
                                            Negotiate
                                        </div>
                                        <div
                                            className="buttonAccept"
                                            disabled={
                                                // !isAssignmentDatesRange ||
                                                isAcceptButtonDisabled ||
                                                isAcceptFormDisabled ||
                                                readOnly
                                            }
                                            onClick={this.acceptConfirmation}
                                        >
                                            Accept
                                        </div>
                                    </div>
                                </div>
                            )}
                            {viewStep === 2 && (
                                <div data-step={viewStep} className="sidebar-block">
                                    <div className="contract">
                                        <div className="headerWrap">
                                            <div className="title">Contract</div>
                                            <div className="subtitle">Sign your contract with</div>
                                        </div>
                                        <div className="contract_footer">
                                            <div
                                                className="button docusign"
                                                onClick={() => this.handleDocusignFlow(assignmentId, uid)}
                                            >
                                                {isContractLoading ? (
                                                    <Spinner label="Generating Contract. Loading." />
                                                ) : (
                                                    <img src="/images/docusign.png" alt="" />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </aside>
                    </div>
                    <ModalDialog
                        show={this.state.declineConfirm}
                        title="Decline Opportunity"
                        proceedButtonLabel="DECLINE"
                        readyToProceed
                        closeButton
                        proceedFunction={() => this.declineOpportunity(assignmentId, uid)}
                        cancelFunction={() => {
                            this.setState({ declineConfirm: false });
                        }}
                        proceedButtonStyle={{ backgroundColor: '#d0021b' }}
                    >
                        <div className="deleteCampaign">
                            <div className="label">Are you sure you want to decline this opportunity?</div>
                        </div>
                    </ModalDialog>
                    <ModalAlertWithAction
                        titleId="opportunityView.contractSigningDeclinedTitle"
                        messageId="opportunityView.insufficientPrivilegesMessage"
                        show={this.state.showInsufficientPrivilegesError}
                        actionFunction={() => this.setState({ showInsufficientPrivilegesError: false })}
                    />
                    <ChangeAddressModal
                        show={this.state.isChangeAddressModal}
                        onAccept={this.handleChangeAddress}
                        onClose={() => this.setState({ isChangeAddressModal: false })}
                    />
                    <AcceptConfirm
                        show={this.state.acceptConfirm}
                        onAccept={this.handleAccept}
                        onClose={() => this.setState({ acceptConfirm: false })}
                        paymentValue={new Intl.NumberFormat({ currency: 'CAD' }).format(detail.totalRate)}
                    />
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        storeProfile: (profile) => {
            dispatch(storeUserProfile(profile));
        },
    };
};
const mapStateToProps = (state) => {
    return {
        profile: state.global.loggedInUserProfile,
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OpportunityView));
