export const getNextSelectedOptions = (selectValue, selectOptions, allFilter) => {
    // filter away 'ALL' filter if several filters are selected
    const filteredSelectValue = selectValue.length > 1
        ? selectValue.filter(({ value }) => value !== allFilter.value)
        : selectValue;

    // when one of selected assignment filters is ALL
    const allFilterIsSelected = filteredSelectValue.find(({ value }) => value === allFilter.value);
    // when all assignment filters are removed
    const noFilters = filteredSelectValue.length === 0;
    // when all possible filters (except ALL) are selected
    const everyFilterButAll = !allFilterIsSelected
        && (filteredSelectValue.length === selectOptions.length - 1);
    let nextSelectedOptions;

    switch (true) {
    case allFilterIsSelected:
    case noFilters:
    case everyFilterButAll:
        nextSelectedOptions = [allFilter];
        break;
    default:
        nextSelectedOptions = filteredSelectValue;
    }

    return nextSelectedOptions;
};
