import React from 'react';
import Select from 'react-select';
import Checkbox from '../checkbox2022/Checkbox';
import './multiselectDropdown.scss';

function MultiselectDropdown({ values, setValues, filterLabel, dropdownClassName='' }) {
  const numSelectedValues = values.filter((value) => value.selected).length;
  const allSelected = numSelectedValues === values.length;

  const selectValue = allSelected
    ? { label: `All` }
    : numSelectedValues
      ? { label: `Selected (${numSelectedValues})` }
      : { label: 'None' };

  const handleClick = (option) => {
    const selValues = values.reduce((list, curr) => [...list, curr.value === option.value ? { ...curr, selected: !curr.selected } : curr], []);
    setValues(selValues);
  }

  const handleSelectAll = () => setValues((prev) => prev.map((value) => ({ ...value, selected: !allSelected })));
  const getCheckboxValue = (option) => values.find((value) => value.value === option.value).selected;

  const menuRenderer = (props) => (
    <div>
      <div className="multiselect-option" onClick={handleSelectAll}>
        <Checkbox size={20} checked={allSelected} changeFunction={handleSelectAll} controlled fixedSize />
        All
      </div>
      <div className="line-break"></div>
      {props.options.map((option) => (
        <div className="multiselect-option" onClick={() => handleClick(option)} key={option.label}>
          <Checkbox
            size={20}
            checked={getCheckboxValue(option)}
            onClick={() => handleClick(option)}
            changeFunction={() => handleClick(option)}
            controlled
            fixedSize
          />
          {option.label}
        </div>
      ))}
    </div>
  );

  const valueRenderer = (props) => (
    <div className='multiselect-placeholder-container'>
      <span className="multiselect-placeholder">{filterLabel}:</span>
      {props.label}
    </div>
  );

  return (
    <Select
      className={`multiselect-dropdown ${dropdownClassName}`}
      searchable={false}
      value={selectValue}
      options={values}
      menuRenderer={menuRenderer}
      valueRenderer={valueRenderer}
      clearable={false}
    />
  );
};

export default MultiselectDropdown;
