import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import cx from 'classnames';
import PageDropDown from '../favourite/PageDropDown';
import Api from '../../modules/Api';

const CampaignDropDown = ({
    onChange = () => null,
    disabled = false,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [data, setData] = useState();
    const currentPage = useRef(1);
    const totalPages = useRef(0);

    useEffect(() => {
        fetchData();
    }, []);

    useLayoutEffect(() => {
        if (data) {
            if (data.results) {
                if (currentPage.current === 1) {
                    setDataList(data.results);
                } else {
                    const update = [...dataList, ...data.results];
                    setDataList(update);
                }
            };
            if (data.meta) {
                totalPages.current = Math.ceil(data.meta.totalCount / data.meta.pageSize);
            };
        };
    }, [data]);

    const fetchData = () => {
        const params = {
            desc: 'desc',
            page: currentPage.current,
            num: 20,
            sortby: 'date'
        }
        setIsLoading(true);
        Api.getCampaignList(params)
            .then(res => {
                setData(res);
                setIsLoading(false);
            })
            .catch(err => {
                // handleApiError(err);
                setIsLoading(false);
            });
    }

    const onLoadMore = () => {
        if (currentPage.current < totalPages.current) {
            currentPage.current++;
            fetchData();
        }
    };

    const getOptions = () => {
        if (dataList.length > 0) {
            return dataList.map(ab => ({
                ...ab,
                value: ab.id,
                label: ab.name
            }));
        } else {
            return [];
        }
    }

    return <PageDropDown
        options={getOptions()}
        onChange={onChange}
        placeHolder="Select"
        height={46}
        loading={isLoading}
        onLoadMore={onLoadMore}
        disabled={disabled}
    />

}

export default CampaignDropDown;