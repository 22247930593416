import React from 'react';
import './style.scss';
import Select from 'react-select';
import Api from '../../../../modules/Api';
import ErrorMessageBox from '../../../shared/errorMessageBox/ErrorMessageBox';
import FeedbackOnTheTop from '../../../shared/feedbackOnTheTop/FeedbackOnTheTop';

const roleOptions = [
    { value: 'BRAND_OWNER', label: 'Brand Owner' },
    { value: 'AGENT', label: 'User' },
    { value: 'VIEWER', label: 'Viewer' },
];

export default class EditUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedBrands: [],
            role: '',
            showApiError: false,
            apiError: '',
            availableBrands: [],
            availableAdvertisers: [],
            selectedAdvertisers: [],
            countryList: []
        };
    }

    componentDidMount() {
        const { user, brands } = this.props;
        Api.getBrandCountry().then(result => {
            const { data } = result;
            const countryList = [];
            data.forEach(x => {
                const country = {label: x.name, value: x.code, currencyCode: x.currencyCode}
                countryList.push(country)
            })
            this.setState({countryList}, this.setBrands)
        }).catch(err => {
            this.setBrands();
        })
        if (user) {
            Api.getMyAdvertisers()
                .then(res => {
                    let availableAdvertisers = [];
                    try {
                        res.forEach(item => {
                            if (!item.isArchived)
                                availableAdvertisers.push({
                                    value: item.id,
                                    label: item.name,
                                    logo: item.logoUrl,
                                });
                        });
                        availableAdvertisers.sort((a, b) => {
                            if (a.label > b.label) return 1;
                            if (a.label < b.label) return -1;
                            return 0;
                        })
                    }catch(error) {
                        console.log(error);
                    }
                    this.getUserData(user, availableAdvertisers);
                })
                .catch(err => {
                    console.log(err);
                })
        }
    }

    setBrands = () => {
        const { brands } = this.props;
        const { countryList } = this.state;
        const availableBrands = [];
        if (brands) {
            brands.forEach(item => {
                const countryToFind = countryList.find(x => x.value === item.countryCode)?.label
                availableBrands.push({
                    value: Number(item.brandId),
                    label: <div className="brandLabel">{item.brandName}{countryToFind && <div className="countryLabel"> - {countryToFind}</div>}</div>,
                    logo: item.brandLogo,
                    advertiserId: item.advertiser.id,
                    brandName: item.brandName
                })
            })
            availableBrands.sort((a, b) => {
                return a.brandName.toLowerCase().localeCompare(b.brandName.toLowerCase())
            })
            this.setState({ availableBrands });
        }
    }

    getUserData = (user, availableAdvertisers) => {
        const {brands} = this.props;
        const { countryList } = this.state;
        const self = this;
        const existBrandFull = [];
        const selectedAdvertisers = [];
        let i = 0;
        Api.getAgent(user.id)
            .then(async (res) => {
                if (res.advertiserPermissions && availableAdvertisers) {
                    for (i = 0; i < res.advertiserPermissions.length; i++) {
                        // get advertiser data
                        const advertiser = availableAdvertisers.find(a => a.value === res.advertiserPermissions[i]);
                        if (advertiser) {
                            selectedAdvertisers.push(advertiser);
                        }
                    }
                    selectedAdvertisers.sort((a, b) => {
                        if (a.label > b.label) return 1;
                        if (a.label < b.label) return -1;
                        return 0;
                    })
                }
                if (res.brandPermissions) {
                    for (i = 0; i < res.brandPermissions.length; i++) {
                        // get advertiser data
                        const brand = brands.find(b => b.id === res.brandPermissions[i]);
                        if (brand) {
                            const countryToFind = countryList.find(x => x.value === brand.countryCode)?.label
                            existBrandFull.push({
                                value: brand.id,
                                label: <div className="brandLabel">{brand.brandName}{countryToFind && <div className="countryLabel"> - {countryToFind}</div>}</div>,
                                logo: brand.brandLogo,
                                advertiserId: brand.advertiser.id,
                            });
                        }
                    }
                    existBrandFull.sort((a, b) => {
                        if (a.label > b.label) return 1;
                        if (a.label < b.label) return -1;
                        return 0;
                    })
                }
                self.setState({
                    role: user.role,
                    selectedBrands: existBrandFull,
                    availableAdvertisers,
                    selectedAdvertisers,
                })
            })
            .catch(err => {

            })
    }

    handleRoleChange = (e) => {
        this.setState({ role: e.value }, () => this.checkFormValid());
    };

    handleBrandChange = (e) => {
        const fullList = [...this.state.selectedBrands];
        const elem = fullList.find((item) => item.value === e.value);
        if (elem) return;
        fullList.push({ value: e.value, label: e.label, logo: e.logo });
        fullList.sort((a, b) => {
            if (a.label > b.label) return 1;
            if (a.label < b.label) return -1;
            return 0;
        })
        this.setState({ selectedBrands: fullList }, () => this.checkFormValid());
    };

    deleteBrand = (i) => {
        const fullList = [...this.state.selectedBrands];
        fullList.splice(i, 1);
        fullList.sort((a, b) => {
            if (a.label > b.label) return 1;
            if (a.label < b.label) return -1;
            return 0;
        })
        this.setState({ selectedBrands: fullList }, () => this.checkFormValid());
    };

    handleAdvertiserChange = (e) => {
        const {selectedAdvertisers} = this.state;
        const elem = selectedAdvertisers.find(a => a.value === e.value);
        if (elem) return;
        selectedAdvertisers.push(e);
        selectedAdvertisers.sort((a, b) => {
            if (a.label > b.label) return 1;
            if (a.label < b.label) return -1;
            return 0;
        })
        this.setState({
            selectedAdvertisers,
        }, () => this.checkFormValid());
    }

    deleteAdvertiser = (i) => {
        const {selectedAdvertisers, selectedBrands} = this.state;
        const deleteAdvertiserId = selectedAdvertisers[i].value;
        selectedAdvertisers.splice(i, 1);
        const newBrands = selectedBrands.filter(b => b.advertiserId !== deleteAdvertiserId);
        newBrands.sort((a, b) => {
            if (a.label > b.label) return 1;
            if (a.label < b.label) return -1;
            return 0;
        })
        selectedAdvertisers.sort((a, b) => {
            if (a.label > b.label) return 1;
            if (a.label < b.label) return -1;
            return 0;
        })
        this.setState({
            selectedAdvertisers,
            selectedBrands: newBrands,
        }, () => this.checkFormValid());
    }

    goBack = () => {
        this.props.handleSetUserToEdit(null);
    };

    checkFormValid = () => {
        const { role, selectedAdvertisers } = this.state;
        if (role) {
            this.setState({ formValid: true });
        } else {
            this.setState({ formValid: false });
        }
    };

    updateUser = () => {
        const { user } = this.props;
        const { selectedBrands, role, selectedAdvertisers } = this.state;
        const brandIds = [];
        const advertiserIds = [];
        selectedBrands.forEach(b => brandIds.push(b.value));
        selectedAdvertisers.forEach(a => advertiserIds.push(a.value));
        const payload = {};
        payload.brandPermissions = [...brandIds];
        payload.advertiserPermissions = [...advertiserIds];
        payload.role = role;
        Api.updateAgent(user.id, payload)
            .then(() => {
                this.setState({ successMessage: true });
            })
            .catch((e) => {
                this.setState({ apiError: e.message, showApiError: true });
            });
    };

    render() {
        const { selectedBrands, availableBrands, selectedAdvertisers, availableAdvertisers } = this.state;
        const { user, role } = this.props;
        const advertiserIds = [];
        selectedAdvertisers.forEach(a => advertiserIds.push(a.value));
        const brandIds = [];
        selectedBrands.forEach(b => brandIds.push(b.value));
        const allowSelectBrands = availableBrands.filter(b => advertiserIds.includes(b.advertiserId) && !brandIds.includes(b.value));
        const allowSelectAdvertisers = availableAdvertisers.filter(a => !advertiserIds.includes(a.value));
        console.log(selectedBrands)
        return (
            <div className="edit-user new-user-form permission-page">
                <ErrorMessageBox
                    show={this.state.showApiError}
                    closeFunction={() => this.setState({ apiError: '', showApiError: false })}
                    message={this.state.apiError}
                />
                <FeedbackOnTheTop
                    show={this.state.successMessage}
                    closeFunction={() => this.setState({ successMessage: false }, () => this.props.returnToAll())}
                    message="User has been updated"
                />
                <div className="header">
                    <div onClick={this.goBack} className="arrow" />
                    <h1>Edit User</h1>
                </div>
                <div className="edit-user-block agentAdmin ">
                    <div className="user-info-holder">
                        <div className="image-holder">
                            {user.firstname[0]}
                            {/* {user.pictureUrl ? <img src={user.pictureUrl} alt="" /> : user.firstname[0]} */}
                        </div>
                        <div className="user-info">
                            <div className="user-name">
                                {user.firstname} {user.lastname}
                            </div>
                            <div className="user-email">{user.email}</div>
                        </div>
                    </div>
                    <div className="inputPanel new-user-form">
                        <div className="input-holder">
                            <div className="label">Role</div>
                            <Select
                                className="select"
                                clearable={false}
                                searchable={false}
                                value={this.state.role}
                                single
                                // placeholder="Select"
                                options={roleOptions}
                                onChange={this.handleRoleChange}
                                disabled={role !== "SUPER_ADMIN"}
                            />
                            <div className="label">Advertiser</div>
                            <Select
                                className="select"
                                clearable={false}
                                searchable={false}
                                // value={this.props.pageLimit}
                                single
                                // placeholder="Select"
                                options={allowSelectAdvertisers}
                                onChange={this.handleAdvertiserChange}
                            />
                            {selectedAdvertisers.length ? (
                                <div className="brands">
                                    {selectedAdvertisers.map((advertiser, i) => {
                                        return (
                                            <div key={i} className="brand-holder">
                                                <div className="brand-content">
                                                    <div className="img-holder">
                                                        {advertiser.logo ? (
                                                            <img src={advertiser.logo} alt={advertiser.value} />
                                                        ) : (
                                                            advertiser.label[0]
                                                        )}
                                                    </div>
                                                    <div className="brand-name">{advertiser.label}</div>
                                                </div>
                                                <div className="del-brand-button" onClick={() => this.deleteAdvertiser(i)} />
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : (
                                ''
                            )}
                            <div className="label">Brand</div>
                            <Select
                                className="select"
                                clearable={false}
                                searchable={false}
                                // value={this.props.pageLimit}
                                single
                                // placeholder="Select"
                                options={allowSelectBrands}
                                onChange={this.handleBrandChange}
                            />
                            {selectedBrands.length ? (
                                <div className="brands">
                                    {selectedBrands.map((brand, i) => {
                                        let logo = null;
                                        if (brand.logo) {
                                            logo = <img src={brand.logo} alt={brand.value} />;
                                        } else if (typeof brand.label === "string") {
                                            logo = brand.label[0]
                                        } else {
                                            logo = brand.label.props.children[0][0]
                                        }
                                        return (
                                            <div key={i} className="brand-holder">
                                                <div className="brand-content">
                                                    <div className="img-holder">
                                                        {logo}
                                                    </div>
                                                    <div className="brand-name">{brand.label}</div>
                                                </div>
                                                <div className="del-brand-button" onClick={() => this.deleteBrand(i)} />
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </div>
                <div className="manager-card-bottom new-user-form-bottom">
                    <button className="button cancel" onClick={this.goBack}>
                        Cancel
                    </button>
                    <button
                        disabled={!this.state.formValid}
                        className={`button ${!this.state.formValid ? 'disabled' : ''}`}
                        onClick={this.updateUser}
                    >
                        Save
                    </button>
                </div>
            </div>
        );
    }
}
