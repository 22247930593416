import React from 'react'

import './rateBox.scss';

// For rate input. Props :
// - rateValue
// - changeFunction for the rate, takes rate and error message
// - inputClass for input box styling

export default class RateInput extends React.Component {

    constructor (props, context) {
        super(props, context);

        this.handleInputClick = this.handleInputClick.bind(this);
        this.blurFunction = this.blurFunction.bind(this);

        this.state = {
            lastValid : ''
        }
        this.focus = false;
    }

    handleInputClick() {
        this.inputNode.focus();
        // this.focus = true;
        this.setState({focus: true})
    }

    validate = (val) => {
        let errorMessage = null;
        const { minimumRate } = this.props
        if ((this.props.required || val) && Number(val) < minimumRate) {
            errorMessage = `Minimum rate: $${minimumRate}`;
        }

        return errorMessage;
    }

    handleChange = (e) => {
        e.preventDefault();
        let vlue =  (e.target.value).replace(/,/gi, '');
        if(vlue.match(/^\d*$/g)){
            let lastValid = vlue.replace(/(?=(?!\b)(\d{3})+$)/g, ',');
            this.setState({
                lastValid: lastValid
            })
            let errorMessage = this.validate(lastValid);
            if (typeof this.props.changeFunction === 'function') {
                this.props.changeFunction(vlue, errorMessage);
            }
            this.setState({
                rateEdited : true,
                widthChange: vlue.length>2 ? vlue.length*16 + 16 +'px'  : '40px'
            })
        }

    }
   
    blurFunction(){
        this.setState({focus: false})
    }

    componentDidMount() {
        const {alternativeRateInput, rateValue} = this.props;
        let value = rateValue !== '' ? Number(this.props.rateValue) : '';
        if (value >= 0 ) {
            let errorMessage = this.validate(value);
            if (errorMessage) {
                this.props.changeFunction(value, errorMessage);
            }
            this.setState({lastValid : '' +  value})

        }
        if(alternativeRateInput) {
            this.lastValid = 0;
            if (rateValue && !isNaN(rateValue)) {
                this.lastValid = Number(rateValue).toString().replace(/(?=(?!\b)(\d{3})+$)/g, ',');
            }
        }
    } 

    render () {
        const { rateValue, suggestion, alternativeRateInput, symbol } = this.props;
        let focusStyle= (this.state.focus) ? {borderColor:'#007D72', backgroundColor: '#fff'} : {};
        let editStyle = (this.state.focus) ? "edit focused" : 'edit';
        if(!!alternativeRateInput && alternativeRateInput){
            return (
                <div className={this.props.inputClass} style={focusStyle} onClick={this.handleInputClick} onBlur={this.blurFunction}>
                <input value={this.state.lastValid}
                      className={editStyle}
                      // style={{width: this.state.widthChange}}
                      ref={(node)=>{this.inputNode=node}}
                      onChange={this.handleChange}
                      onBlur={this.blurFunction}
                      placeholder=""
                      type="text"
                      maxLength="7"
                      symbolUse={symbol || "$"}
                      />
                </div>
            );
        }
        return (
            <div className={this.props.inputClass} style={focusStyle} onClick={this.handleInputClick} onBlur={this.blurFunction}>
                <span>{symbol || "$"}</span>
                <input value={this.state.lastValid }
                      className={editStyle}
                      // style={{width: this.state.widthChange}}
                      ref={(node)=>{this.inputNode=node}}
                      onChange={this.handleChange}
                      onBlur={this.blurFunction}
                      placeholder={Boolean(suggestion) ? suggestion : ''}
                      disabled = {this.props.disabled}
                      type="text"
                      maxLength="7"
                      />      
            </div>
        );
    }
}



