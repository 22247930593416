export default class Params {

    static initializeStateFields = () => {
        return {
            currentPage : 1,
            pageSize : 10,
            currentOrPast : 'current', // current, past or all
            searchKey: '',
            sortBy: 'date',
            nameSortDirection: 'desc',
            dateSortDirection: 'desc',
            brandSortDirection: 'desc',
            signerSortDirection: 'desc'
        }
    }

    static createParamsForApi = (fields) => {
        let retval = {
            page : fields.currentPage,
            num : fields.pageSize,
            type : fields.currentOrPast,
            sortby : fields.sortBy || 'date',
            isArchived: fields.isArchived
        }
        if ( typeof (fields.searchKey) === 'string' && (fields.searchKey).length > 0) {
            retval.key = fields.searchKey;
        }
        let directionKey = fields.sortBy + 'SortDirection';
        if (fields[directionKey] === 'desc') {
            retval.desc = 'desc';
        }
        return retval;
    }
}

