import React, {useState, useEffect} from 'react';
import { withRouter } from "react-router";
import moment from "moment";

const KeywordGroupRow = ({keywordGroup, history}) => {

    if (!keywordGroup)
        return null;

    const updatedOn = moment.utc(keywordGroup.updatedOn);
    updatedOn.local();

    return (
        <div key={keywordGroup.id} className="brandInfo" onClick={() => history.push({pathname: "/keywordGroups/" + keywordGroup.agencyBrandId + "/" + keywordGroup.id, search: history.location.search})}>
            <div className="column">
                <span>{keywordGroup.groupName}</span>
            </div>
            <div className="column">
                <span>{keywordGroup.keywords ? keywordGroup.keywords.length : "-"}</span>
            </div>
            <div className="column">
                <span>{updatedOn.format('MMM DD, YYYY')} at { updatedOn.format('hh:mmA') }</span>
            </div>
        </div>
    )
}

export default withRouter(KeywordGroupRow);
