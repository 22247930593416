import { all, fork } from 'redux-saga/effects';
import lookupFiltersSaga from '../../../general/sagas/lookupFiltersSaga';
import lookupTablesSaga from '../../../general/sagas/lookupTablesSaga';
import assignmentInfoSaga from '../../../general/sagas/assignmentInfoSaga';
import favoritesListSaga from '../../../general/sagas/favoritesListSaga';

export default function* influencerSearchDataInitSaga() {
    yield all([
        fork(lookupFiltersSaga),
        fork(lookupTablesSaga),
        fork(assignmentInfoSaga),
        fork(favoritesListSaga),
    ]);
}
