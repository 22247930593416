import React from 'react'
//import { SINGLE_SELECTION, MULTIPLE_SELECTION, DATE, AGE, FREE_LIST, FREE_TEXT,fieldType, fieldLookupKey} from '../../modules/ProfileFields'

import Lookup from '../../modules/Lookup'
import SocialNetworks from '../../modules/influencer/SocialNetworks'
//import Age from '../influencerProfile/piecesInMain/Age'
//import Format from '../../modules/utils/Format'
//import ReachIcon from '../shared/ReachIcon/ReachIcon'
import {aboutKeyList, moreKeyList, displayProfileField} from '../../modules/influencer/ProfileFields'

import './extended.scss'

// props
// - profile

export default class Profile extends React.Component {

    constructor (props, context) {
        super(props, context);

        this.renderedProfile = this.renderedProfile.bind(this);
        this.renderedProfileMore = this.renderedProfileMore.bind(this);
    }

    renderedColumn = (fields,newFields) => {
        let fieldLines1 = [];
        let fieldLines2 = [];
        let cnt = fields.length;
        let key = 1;
        if (Array.isArray(newFields) && newFields.length>0) {
            cnt += newFields.length;
            newFields.forEach(nf => {
                let label = Lookup.getFilterLabel(nf.replace(/[A-Z]/g, u=>'_'+u.toLowerCase()));
                let fieldLine = (<div key={key++}>
                                    <span className="label">{ label }:</span>
                                    { displayProfileField(nf, this.props.profile) }
                                </div>);
                if (key<=Math.ceil(cnt/2)+1) {
                    fieldLines1.push(fieldLine);
                } else {
                    fieldLines2.push(fieldLine);
                }
            });
        }
        
        for (let field of fields) {
            let label = Lookup.getFilterLabel(field.replace(/[A-Z]/g, u=>'_'+u.toLowerCase()));
            let fieldLine = (<div key={key++}>
                                <span className="label">{ label }:</span>
                                { displayProfileField(field, this.props.profile) }
                            </div>);
            if (key<=Math.ceil(cnt/2)+1) {
                fieldLines1.push(fieldLine);
            } else {
                fieldLines2.push(fieldLine);
            }
            
        }
        return (
            <div>
                <div className="columns half">
                    {fieldLines1}
                </div>
                <div className="columns half">
                    {fieldLines2}
                </div>
            </div>
        )
    }

    renderedProfile() {
        let {profile} = this.props;
        return (
            <div>
                <div className="columns summary">
                    <div className="title">
                        Summary
                    </div>
                    <div>
                        { profile.summary }
                    </div>
                </div>
                <div className="columns about">
                    <div className="title">
                        About
                    </div>
                    { this.renderedColumn(aboutKeyList,['age_readonly'])}
                </div>
            </div>
        )
    }

    renderedProfileMore() {
        let {profile} = this.props;
        return (
            this.renderedColumn(moreKeyList)
        )
    }

    render () {
        let profile = this.props.profile;
        if (!profile) {
            return null;
        }
        let reaches = SocialNetworks.getNetworkOptionList();
        let totalReach = 0;
        let networks = profile.userNetworks;
        if (Array.isArray(reaches)) {
            reaches.forEach( reach => { reach.reach = (Array.isArray(networks) && networks.find( un => un.networkType.toUpperCase() === reach.id ))?Number(networks.find( un => un.networkType.toUpperCase() === reach.id ).reach):0});
        } 
        
        if (Array.isArray(networks) && networks.length > 0) {
            totalReach = networks.map( un =>  Number(un.reach) ).reduce((a,b)=>a+b);
        }
        
        return (
            <div className="extendCard">
                { this.props.more ? this.renderedProfileMore() : this.renderedProfile() }
            </div>
        );
    }
}
