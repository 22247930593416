import { takeEvery } from 'redux-saga/effects';
import assignmentSelectSaga from './assignment/saga';
import statusSelectSaga from './status/saga';
import { ASSIGNMENT_SELECT_CHANGE } from './assignment/actions';
import { STATUS_SELECT_CHANGE } from './status/actions';

export default function* selectsSaga() {
    yield takeEvery(ASSIGNMENT_SELECT_CHANGE, assignmentSelectSaga);
    yield takeEvery(STATUS_SELECT_CHANGE, statusSelectSaga);
}
