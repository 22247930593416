import {
    BEFORE_ADD_TO_CAMPAIGN,
    CLEAR_ALL,
    FINISH_INVITE,
    GET_DATA,
    INFLUENCERS_LOADING,
    LIST_SPLASH_FILTER_CHANGE,
    PAGE_CHANGE,
    PAGE_LIMIT_CHANGE,
    SEARCH_FILTERS_CHANGE,
    SELECT_ALL,
    SELECTION_CHANGE,
    SET_API_ERROR,
    SET_DATA,
    SET_PAGE_LIMIT,
    SET_PLEASE_SHOW_WAIT,
    SET_SEARCH_FILTERS,
    SET_SHOW_DISCOVERY,
    SET_SORT_FILTER,
    SET_ORDER_TYPE,
    SHOW_MISSING_CHANNELS_ERROR,
    SORT_FILTER_CHANGE,
    ORDER_TYPE_CHANGE,
    RESET_DATA,
    INVITE_PAGE_CHANGE,
    CLEAR_CURRENT_PAGE,
    REFRESH_SEARCH,
    SET_CURRENCY,
} from './actions';

export const beforeAddToCampaign = () => ({
    type: BEFORE_ADD_TO_CAMPAIGN,
});

export const clearAll = () => ({
    type: CLEAR_ALL,
});

export const refreshSearch = () => ({
    type: REFRESH_SEARCH,
});

export const invitePageChange = () => ({
    type: INVITE_PAGE_CHANGE,
});

export const clearCurrentPage = (inviteList) => ({
    type: CLEAR_CURRENT_PAGE,
    inviteList,
});

export const finishInvite = (showInviteSent) => ({
    type: FINISH_INVITE,
    showInviteSent,
});

export const getData = () => ({
    type: GET_DATA,
});

export const listSplashFilterChange = (filter, setSearchPanelFilter) => ({
    type: LIST_SPLASH_FILTER_CHANGE,
    filter,
    setSearchPanelFilter,
});

export const pageChange = (payload) => ({
    type: PAGE_CHANGE,
    payload,
});

export const pageLimitChange = (pageLimit) => ({
    type: PAGE_LIMIT_CHANGE,
    pageLimit,
});

export const searchFilterChange = (newFilters) => ({
    type: SEARCH_FILTERS_CHANGE,
    newFilters,
});

export const selectAll = () => ({
    type: SELECT_ALL,
});

export const selectionChange = (id, isSelected, runInvitation) => ({
    type: SELECTION_CHANGE,
    id,
    isSelected,
    runInvitation,
});

export const setApiError = (error) => ({
    type: SET_API_ERROR,
    error,
});

export const setData = (payload) => ({
    type: SET_DATA,
    payload,
});

export const setInfluencersLoading = (influencersLoading) => ({
    type: INFLUENCERS_LOADING,
    influencersLoading,
});

export const setPageLimit = (pageLimit) => ({
    type: SET_PAGE_LIMIT,
    pageLimit,
});

export const setShowPleaseWait = (value) => ({
    type: SET_PLEASE_SHOW_WAIT,
    value,
});

export const setCurrency = (value) => ({
    type: SET_CURRENCY,
    value,
});

export const setSearchFilters = (newFilters) => ({
    type: SET_SEARCH_FILTERS,
    newFilters,
});

export const setShowDiscovery = (showDiscovery) => ({
    type: SET_SHOW_DISCOVERY,
    showDiscovery,
});

export const setSortFilter = (active) => ({
    type: SET_SORT_FILTER,
    active,
});

export const setSortOrder = (active) => ({
    type: SET_ORDER_TYPE,
    active,
});

export const setShowMissingChannelsError = (show) => ({
    type: SHOW_MISSING_CHANNELS_ERROR,
    show,
});

export const sortFilterChange = (active) => ({
    type: SORT_FILTER_CHANGE,
    active,
});

export const orderTypeChange = (active) => ({
    type: ORDER_TYPE_CHANGE,
    active,
});

export const resetData = () => ({
    type: RESET_DATA,
});
