import React from 'react';
import './vettingMain.scss';
import { connect } from 'react-redux';
import cx from 'classnames';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import InfluencerPhoto from '../../oneInfluencerNew/InfluencerPhoto';
import { LoadingAvatar } from './LoadingAvatar';
import MatchItem from './MatchItem';
import PostDetails from './PostDetails';
import { InputKeywordsBox } from './InputKeywordsBox';
import Api from '../../../modules/Api';
import ApiError from '../../shared/apiError/ApiError';
import PleaseWaitWhite from "../../shared/pleaseWait/PleaseWaitWhite";
import PaginationNew from '../../shared/pagination/PaginationNew';

const vetOptions = ["Keyword"];
const postType = ["INSTAGRAM"];
const pageLimitOptions = [
    { value:'20', label: '20 per page'},
    { value:'50', label: '50 per page'},
    { value:'100', label: '100 per page'}
];


class VettingMain extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isFetching: false,
            vetOption: "Keyword",
            status: "initial",
            results: [],
            showPostSlide: false,
            post: null,
            keywordsApply: [],
            showSelectOption: false,
            brandSelected: '',
            groupSelected: '',
            keywordsSelected: [],
            keywordsInput: [],
            groupSearch: '',
            groups: {},
            showApiError: false,
            totalCount: 0,
            pageLimit: 20,
            currentPage: 1,
            apiError: null,
        };

        this.searchBarRef = React.createRef();
        this.handleCloseSlide = this.handleCloseSlide.bind(this);
    }

    componentWillMount() {
        this.fetchGroups();
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.hideSelectOptions);
    }

    fetchGroups = () => {
        const { groupSearch } = this.state;

        this.setState({
            isFetching: true,
        })

        Api.searchKeywordGroups(groupSearch)
            .then(res => {
                const groups = {};
                if (res && res.content) {
                    res.content.map(item => {
                        if (item.brandName && item.brandName.length > 0) {
                            const list = groups[item.brandName] || [];
                            list.push({ groupName: item.groupName, keywords: item.keywords });
                            groups[item.brandName] = list;
                        }
                    })
                }
                this.setState({
                    isFetching: false,
                    groups
                })
            })
            .catch(err => {
                this.setState({
                    isFetching: false,
                    showApiError: true,
                    apiError: err,
                })
            })
    }

    fetchUserPost = () => {
        const { profile } = this.props;
        const { keywordsInput, keywordsSelected, currentPage, pageLimit } = this.state;
        const keywords = [...keywordsSelected, ...keywordsInput];
        this.setState({
            status: 'searching',
        })

        const promises = [];
        postType.map(pt => {
            if (profile.id){
                promises.push(Api.getUserPost({ userId: profile.id, keywords, postType: pt, page: currentPage - 1, size: pageLimit, sourceType: "CONNECTED" })
                    .then(res => {
                        if (res && res.content && res.content.length > 0) {
                            return {
                                networkType: pt.toLowerCase(),
                                recentPosts: res.content,
                                totalElements: res.totalElements
                            }
                        }
                    })
                    .catch(err => {
                        this.setState({
                            showApiError: true,
                            apiError: err.originalMessage ? err.originalMessage : err.message
                        })
                    })
                )
            } else {
                promises.push(Api.getUserPost({ networkUserId: profile.uid, keywords, postType: pt, page: currentPage - 1, size: pageLimit, sourceType: "PUBLIC" })
                .then(res => {
                    if (res && res.content && res.content.length > 0) {
                        return {
                            networkType: pt.toLowerCase(),
                            recentPosts: res.content,
                            totalElements: res.totalElements
                        }
                    }
                })
                .catch(err => {
                    this.setState({
                        showApiError: true,
                        apiError: err.originalMessage ? err.originalMessage : err.message
                    })
                })
            )
            }
        })

        Promise.all(promises)
            .then(list => {
                let results = [];
                if (list && list.length > 0) {
                    results = list.filter(l => l !== undefined);
                }
                this.setState({ results, keywordsApply: keywords, status: results.length > 0 ? "initial" : "noMatch", totalCount: results.length > 0 ? results[0].totalElements : 0 });
            })

    }

    onVetOption = (option) => {
        this.setState({
            vetOption: option
        })
    }

    onVetButton = () => {
        this.fetchUserPost();
    }

    onClickPost = (post) => {
        this.setState({
            showPostSlide: true,
            post
        })
    }

    handleGroupSearchChange = (e) => {
        this.setState({
            groupSearch: e.target.value
        })
    }

    handleKeyUp = (keyCode) => {
        if (keyCode === 13) {
            this.handleGroupSearch();
        }
    };

    handleGroupSearch = () => {
        this.fetchGroups();
    }

    onGroupSelected = (e, brand, group) => {
        e.stopPropagation();

        this.setState({
            showSelectOption: false,
            brandSelected: brand,
            groupSelected: group.groupName,
            keywordsSelected: group.keywords,
        })
        window.removeEventListener('click', this.hideSelectOptions);
    }

    handleKeywordChange = (keywords) => {
        const { keywordsSelected } = this.state;
        const inputs = [];
        const selected = [];
        if (keywords && keywords.length > 0) {
            keywords.map(kw => {
                if (keywordsSelected.findIndex(ks => ks === kw) > -1) {
                    selected.push(kw);
                } else {
                    inputs.push(kw);
                }
            })
        }
        this.setState({
            keywordsInput: inputs,
            keywordsSelected: selected
        })
    }

    handleCloseSlide() {
        this.setState({
            showPostSlide: false,
        })
    }

    renderPostSlide = () => {
        const { showPostSlide, post, keywordsApply } = this.state;

        return (
            <Modal
                closeTimeoutMS={300}
                isOpen={showPostSlide}
                overlayClassName="creatorOverlay"
                className="creatorModal"
                shouldCloseOnOverlayClick
                onRequestClose={() => this.handleCloseSlide()}
            >
                <div className="container">
                    <div className="closeButton" onClick={() => this.handleCloseSlide()}>
                        <img id="closeButton" src="/images/ic-close-g.svg" />
                    </div>
                    <PostDetails
                        {...this.props}
                        post={post}
                        editMode={false}
                        keywords={keywordsApply}
                    // handleScrollTopChangeFunction={this.handleScrollTopChange}
                    />
                </div>
            </Modal>)
    }

    showSelectOptions = (e) => {
        e.stopPropagation();
        this.setState({ showSelectOption: true });
        window.addEventListener('click', this.hideSelectOptions);
    };

    hideSelectOptions = () => {
        this.setState({ showSelectOption: false });
        window.removeEventListener('click', this.hideSelectOptions);
    }

    handlePageChange = (val) => {
        const { currentPage } = this.state;
        const { parentContainer } = this.props;
        if (val !== currentPage) {
            this.setState({ currentPage: val}, () => { 
                this.fetchUserPost()
                parentContainer.current.scrollIntoView({ behavior: 'smooth' });
            })
        }
    }

    limitChangeFunction = (val) => {
        const { pageLimit } = this.state;
        const { parentContainer } = this.props;
        if (val !== pageLimit) {
            this.setState({ pageLimit: val, currentPage: 1 }, () => { 
                this.fetchUserPost()
                parentContainer.current.scrollIntoView({ behavior: 'smooth' });
            })
        }
    }

    renderResults = () => {
        const { results, keywordsApply, pageLimit, currentPage, totalCount } = this.state;

        const postData = {};

        if (results.length > 0) {
            results.map(({ networkType, recentPosts, totalElements }) => {
                postData[networkType] = { recentPosts, totalElements };
            })
        }

        return (
            <div className="resultsContainer" ref={(e) => { this.listContainer = e; }}>
                {postData && Object.keys(postData).length > 0 &&
                    Object.keys(postData).map((item, index) => {
                        return <MatchItem
                            key={`MatchItem${  index}`}
                            type={item.toLowerCase()}
                            data={postData[item].recentPosts}
                            keywords={keywordsApply}
                            totalMatches={postData[item].totalElements}
                            expanded={index === 0}
                            onClickPost={(post) => this.onClickPost(post)}
                        />
                    })}

                {postData && Object.keys(postData).length > 0 && <PaginationNew
                    total={totalCount}
                    limit={pageLimit}
                    current={currentPage}
                    handlePageChange={this.handlePageChange}
                    limitChangeFunction={this.limitChangeFunction}
                    parentContainer={this.listContainer}
                    options={pageLimitOptions}
                />}
            </div>
        )
    }

    renderStatus = () => {
        const { profile } = this.props;
        const { status } = this.state;
        let message;
        switch (status) {
        case 'initial':
            message = (<div className="statusMessage">Input your criteria above to begin vetting
                <span> {profile.firstname} {profile.lastname}</span></div>)
            break;
        case 'searching':
            message = (<div className="statusMessage">Vetting, hang tight...</div>);
            break;
        case 'noMatch':
            message = (<div className="noMatchMessage"><div>No Matches Found</div>
                <span>Please try adjusting your Criteria</span></div>)
            break;
        default:
            break;
        }
        return (
            <div className="statusContainer">
                <div className="loadingContainer">
                    <LoadingAvatar
                        loading={status === 'searching'}
                    >
                        <div className="photoContainer">
                            <InfluencerPhoto
                                url={profile.pictureUrl}
                                firstName={profile?.firstname}
                                lastName={profile?.lastname}
                            />
                        </div>
                    </LoadingAvatar>
                </div>
                {message}
            </div>
        )
    }

    renderGroupOptions = () => {
        const { brandSelected, groupSelected, groups } = this.state;

        return (
            <>
                <div className="findGroupWrapper">
                    <img src="/images/ic-search.svg" onClick={this.handleGroupSearch} />
                    <input
                        placeholder="Find Group by Keyword..."
                        autoFocus
                        onChange={this.handleGroupSearchChange}
                        onKeyUp={({ keyCode }) => this.handleKeyUp(keyCode)}
                    />
                </div>
                <div className="groupList">
                    {Object.keys(groups).length > 0 ? Object.keys(groups).map(brand => {
                        return (
                            <div key={brand} className="groupItemWrapper">
                                <div className="leftSection">
                                    {brand}
                                </div>
                                <div className="rightSection">
                                    {groups[brand].map((group, index) => {
                                        return (
                                            <div key={`${group}${index}`} className={cx({ selected: brand === brandSelected && group.groupName === groupSelected })}
                                                onClick={(e) => this.onGroupSelected(e, brand, group)}
                                            >{group.groupName}</div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    }) : (
                        <div className="noGroupFound">
                            No Matches Found
                        </div>
                    )}
                </div>
                <div className="manageGroups">
                    <div className="manageLabel">
                        <Link className="manageLink" to="/keywordGroups">
                            Manage Groups
                        </Link>
                        <img src="/images/ic-arrow-left.svg" />
                    </div>
                </div>

            </>
        )
    }

    render() {
        const { vetOption, results, showPostSlide, showSelectOption, keywordsInput, keywordsSelected, isFetching, brandSelected, groupSelected,
            showApiError, apiError, status } = this.state;
        const searchLabel = brandSelected && groupSelected ? `${brandSelected}/${groupSelected}` : "Select Group";
        const keywords = [...keywordsSelected, ...keywordsInput];
        const vettingEnabled = keywords.length > 0;

        return (
            <div className="vettingMain">
                <ApiError
                    show={showApiError}
                    error={apiError}
                    cancelFunction={() =>
                        this.setState({ showApiError: false, apiError: null })
                    }
                />
                {showPostSlide && this.renderPostSlide()}
                <PleaseWaitWhite show={isFetching} />
                <div className="vettingSearchContainer">
                    <div className="labelsContainer">
                        <div className="vetFor">
                            <span>Vet for</span>
                        </div>
                        <div className="labels">
                            Criteria
                            <span>Choose an existing group or enter your own keywords</span>
                        </div>
                    </div>
                    <div className="bottomSection">
                        <div className="vetOptionsContainer">
                            <div className="vetOptions">
                                {vetOptions.map(option => {
                                    return (
                                        <div
                                            key={option}
                                            onClick={() => this.onVetOption(option)}
                                            className={cx({ selected: option === vetOption })}>
                                            {option}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="criteriaWrapper">
                            <div className="criteriaInput">
                                <div className="vettingSearchBox">
                                    <div ref={this.searchBarRef} className="vettingSearchSelect" onClick={(e) => this.showSelectOptions(e)}>
                                        <div className="vettingSearchLabelContainer">
                                            <div className="searchSelectLabel" >{searchLabel}</div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                <path
                                                    fill="#999"
                                                    fillRule="nonzero"
                                                    d="M16.46 9.27a.889.889 0 0 1 1.276 0 .938.938 0 0 1 0 1.305l-5.097 5.154a.888.888 0 0 1-1.278 0l-5.096-5.154a.936.936 0 0 1 0-1.305.889.889 0 0 1 1.276 0l4.46 4.227 4.458-4.227z"
                                                />
                                            </svg>
                                        </div>
                                        <div className={cx('searchSelectOptions', { hidden: !showSelectOption })}
                                            style={(this.searchBarRef && this.searchBarRef.current) ? { top: `${this.searchBarRef.current.clientHeight + 4  }px` } : null}>
                                            {this.renderGroupOptions()}
                                        </div>
                                    </div>
                                    <div className="divider" />
                                    <div className="keywords">
                                        <InputKeywordsBox
                                            placeHolder="Enter keywords..."
                                            data={keywords}
                                            onChange={this.handleKeywordChange}
                                            onEnter={vettingEnabled ? this.onVetButton : null}
                                            // changeIsFocused={value => this.setState({ isKeywordInputFocused: value })}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div onClick={vettingEnabled ? () => this.onVetButton() : null} className={cx("vetButton", { enabled: vettingEnabled })}>
                                Vet User
                            </div>
                        </div>

                    </div>
                </div>
                <div className="vettingSearchResultContainer">
                    {(results.length === 0 || status === "searching") ? this.renderStatus() : this.renderResults()}
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
});

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(VettingMain);