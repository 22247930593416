import React from 'react';
import { withRouter } from "react-router";

const BreadCrumbs = ({keywordGroups, brands, brandId, keywordGroupId, history}) => {

    const renderBrandName = () => {
        if (!brandId || !brands || brands.length === 0)
            return null;

        const brand = brands.find(brand => brand.id == brandId);
        if (!brand)
            return null;

        const onClick = () => history.push("/keywordGroups/" + brandId);
        return (
            <>
                <span className="breadcrumb"> / </span>
                <span className="breadcrumb" onClick={onClick}>{brand.brandName}</span>
            </>
        );
    }

    const renderGroupName = () => {
        if (!keywordGroupId || !keywordGroups || keywordGroups.length === 0 || !brandId )
            return null;

        const group = keywordGroups.find(group => group.id == keywordGroupId);
        if (!group)
            return null;

        const onClick = () => history.push("/keywordGroups/" + brandId + "/" + keywordGroupId);
        return (
            <>
                <span className="breadcrumb"> / </span>
                <span className="breadcrumb" onClick={onClick}>{group.groupName}</span>
            </>
        );
    }

    return (
        <div className="contentDiv breadcrumbs">
            <span className="breadcrumb" onClick={() => history.push("/keywordGroups/")}>Brands</span>
            {renderBrandName()}
            {renderGroupName()}
        </div>
    )
}

export default withRouter(BreadCrumbs);
