import React, { useEffect, useState, useLayoutEffect } from 'react';
import './listPageTopBar.scss';

const ListPageTopBar = ({
    label,
    onChange,
    onButton,
    searchPlaceHolder,
    buttonLabel,
}) => {
    return (
        <div className='listPageTopBarContainer'>
            <div className='topContainer'>
                <span>{label}</span>
                <div className='topRightSection'>
                    <div className="searchBoxContainer">
                        <input
                            onChange={onChange}
                            placeholder={searchPlaceHolder}
                            type="text"
                            id="search"
                        />
                        <img src="/images/ic-mag.svg" onClick={() => null} />
                    </div>
                    <div className="listPageTopBarAddButton" onClick={onButton}>
                        <img src="/images/ic-add-w.svg" />
                        <span>{buttonLabel}</span>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ListPageTopBar;
