import React from 'react'

// props :
// - initialValue
// - changeFunction - takes new value
// - errorMessage
// - placeholder
// - inputType
// - resetCounter

export default class CheckedInput extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            first: true,
            value : '',
            error : ''
        }

        this.timeout = null;
    }

    handleChange = (e) => {
        let newValue = e.target.value;
        this.setState({
            value : newValue
        })
        clearTimeout(this.timeout);
        this.timeout = setTimeout(()=>{
            this.props.changeFunction(newValue);
        }, 2000)
    }

    static getDerivedStateFromProps(props, state) {
        let newState = {};
        if (state.first || state.resetCounter !== props.resetCounter) {
            newState.first = false;
            newState.value = props.initialValue || '';
            newState.resetCounter = props.resetCounter;
        }
        return newState;
    }

     render() {
        return (
            <div style={{textAlign:'left'}}>
                <input
                    value={this.state.value}
                    onChange={this.handleChange}
                    placeholder={this.props.placeholder}
                    type={this.props.inputType || 'text'}
                />
                { this.props.errorMessage &&
                    <div className="errorMessage">
                        {this.props.errorMessage}
                    </div>
                }

            </div>
        )
    }
}