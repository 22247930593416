import React from 'react'
import './warningModal.scss'
import BreakOutOfDom from '../modalWithSingleAction/BreakOutOfDom'

export default class WarningModal extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.close = this.close.bind(this);

        this.state = {
        };
    }

    close() {
        let f = this.props.closeFunction;
        if (typeof f === 'function') {
            f();
        }
    }

    action() {
        let f = this.props.actionFunction;
        if (typeof f === 'function') {
            f();
        }
    }

    render() {
        const { title, message, show, closeButton, actionButton } = this.props;
        if (!show) {
            return null;
        }

        const close = closeButton ? closeButton : 'Close';
        return (
            <BreakOutOfDom>
                <div className="warningModal">
                    <div className="messageWindow">
                        <img src="/images/ic-warning.svg" alt="" />
                        <div className="title">
                            {title}
                        </div>
                        <div className="message">{message}</div>
                        <div className="buttonWrapper">
                            <div className="button" onClick={this.close}>
                                {close}
                            </div>
                            {actionButton && <div className="button action" onClick={this.action}>
                                Action
                        </div>}
                        </div>
                    </div>
                </div>
            </BreakOutOfDom>
        )
    }
}
