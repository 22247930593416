import React from 'react';
import cx from 'classnames';
import moment from 'moment';
import Select from 'react-select';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import FileSaver from 'file-saver';
import Api from '../../../modules/Api';
import Lookup from '../../../modules/Lookup';
import ModalDialog from '../../shared/modalDialog/ModalDialogCampaign';
import ProposeRate from '../../shared/proposeRate/ProposeRate';
import { changeChatStatusAction, changeSelectedInfluencerAction } from '../../../store/chat/actions/chatActions';
import { searchFilterChange } from '../../../store/campaign/influencerSearch/actionCreators';
import PleaseWait from '../../shared/pleaseWait/PleaseWaitWhite';
import ContractStatus from '../../shared/contract/status/contractStatus';
import ContractManualUpload from '../../shared/contract/upload/contractManualUpload';
import './campaignInfluencers.scss';
import DateChangeRequestModal from '../../shared/dateChangeRequestModal/DateChangeRequestModal';
import Influencers from './Influencers';
import FeedbackOnTheTopImproved from '../../shared/feedbackOnTheTop/FeedbackOnTheTopImproved';
import Checkbox from '../../shared/checkbox/Checbox';
import exportInfluencerShippingAddress from '../../../modules/influencer/exportInfluencerShippingAddress';
import Format from '../../../modules/utils/Format';
import {
    PENDING,
    DECLINED,
    REVOKED,
    NEGOTIATE,
    ACCEPTED,
    EXPIRED,
    COMPLETED,
    PAID,
    CONTRACT_UPLOADED
} from '../../../constants/statusTypes';
import AssignmentContractType from '../../../constants/AssignmentContractType';
import CommonUtils from '../../../modules/utils/CommonUtils';
import { AGENT_STR, BRAND_OWNER_STR, DisplayRoleNames, SUPER_ADMIN_STR, VIEWER_STR } from '../../../constants/authorities';
import Tooltip from '../../shared/tooltip/Tooltip'
import OtherDocumentsModal from '../../shared/contract/upload/OtherDocumentsModal';

// const permissions = ['ROLE_AGENT', 'ROLE_SUPER_ADMIN', 'ROLE_BRAND_OWNER', 'ROLE_VIEWER'];

const STATUSOBJ = {};
Lookup.getStatus().forEach((status) => {
    STATUSOBJ[status] = { count: 0, total: 0 };
});

export class CampaignInfluencers extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.startPropose = this.startPropose.bind(this);
        this.stopPropose = this.stopPropose.bind(this);
        this.acceptOpportunity = this.acceptOpportunity.bind(this);
        this.startRemove = this.startRemove.bind(this);
        this.removeUsers = this.removeUsers.bind(this);
        // this.refreshUser = this.refreshUser.bind(this);
        // this.changeSelectList = this.changeSelectList.bind(this);
        // this.processProps = this.processProps.bind(this);
        this.addButtonClicked = this.addButtonClicked.bind(this);
        // this.transferToCalenderForAssignemnt = this.transferToCalenderForAssignemnt.bind(this);
        this.handleEditInfluencer = this.handleEditInfluencer.bind(this);

        this.state = {
            assignmentUserList: [],
            users: [],
            assignments: [],
            selectedStatus: ['ALL'],
            selectedAssignment: [],
            proposeInProgress: false,
            selectedUser: null,
            removeInProgress: false,
            assignmentId: this.props.match.params.assignmentId,
            addEnabled: -1,
            statusCount: STATUSOBJ,
            totalCount: 0,
            campaignBudget: 0,
            retrievalInProgress: false,
            isOpenContractStatus: false,
            isOpenContractUpload: false,
            hideChangeRequestButton: null,
            showPopupMessage: false,
            assignmentDetails: null,
            contractStatusAid: '',
            contractStatusSingerId: '',
            contractStatusUid: '',
            contractStatusData: null,
            defContract: null,
            selectedInfluencers: [],
            allSelectedStatus: false,
            isAddressLoading: false,
            openExport: false,
            noFormUsers: [],
            openNoForm: false,
            downloadUsers: [],
            assignmentContractType: null,
            signerList: [],
            symbolArray: {},
            isOpenOtherDocuments: false
        };
        this.todaysDate = moment();
        this.campaignId = this.props.campaignId;
        this.popupMessage = "Creator's date change request is successfully updated!";
    }

    setShowPopupMessage = (value) => this.setState({ showPopupMessage: value });

    addButtonClicked(type) {
        if (!this.searchPageUrl) {
            return null;
        }
        if (!this.state.defContract && this.state.assignmentContractType === AssignmentContractType.CONTRACT_TEMPLATE && type !== "PUBLIC") {
            this.props.apiErrFunction('Oops! Contract is missing. Please choose contract settings before inviting creators to your assignment');
            return;
        }

        // the filter defaults to PUBLIC
        const searchData = {
            type
        }
        this.props.searchFilterChange(searchData);
        this.props.history.push({pathname: this.searchPageUrl, state: {disableSelect: true}});
    }

    handleEditInfluencer(data) {
        const { assignmentUserList, assignmentId } = this.state;
        const { campaignId } = this.props;
        if (data && data.user && data.user.user) {
            const { assignment } = assignmentUserList.find(a => a.user.id === data.user.id)
            if (assignment && !data.user.isPublic) {
                this.props.history.push({ pathname: `/campaignWork/${campaignId}/influencerInviteEdit/${assignment}/user/${data.user.user.uid}` });
            }
            if (data.user.isPublic) {
                this.props.history.push({ pathname: `/campaignWork/${campaignId}/influencerInviteEdit/${assignmentId}/user/${data.user.id}?isPublic=true` });
            }
        }
    }

    acceptOpportunity() {
        const { assignmentUserList, selectedUser } = this.state;
        const self = this;
        const input = {
            action: 'ACCEPT',
            dates: [],
        };
        const acceptUser = assignmentUserList.find(
            (u) => u.user.user.id === selectedUser.user.id && u.assignment === selectedUser.assignmentId,
        );
        if (Boolean(acceptUser) && acceptUser.user.status === NEGOTIATE) {
            Api.changeOpportunityStatus(acceptUser.assignment, acceptUser.user.user.uid, input).then(
                () => {
                    self.refreshUser(selectedUser);
                },
                (error) => {
                    const f = self.props.apiErrFunction;
                    if (typeof f === 'function') {
                        f(error);
                    }
                },
            );
        }
    }

    refreshUser(selectedUser) {
        const { users, statusCount } = this.state;
        this.setState({
            retrievalInProgress: true,
        });
        const self = this;
        const refreshUser = users.find(
            (u) => u.user.user.id === selectedUser.user.id && u.assignment === selectedUser.assignmentId,
        );
        Api.getAssignmentUser(Number(selectedUser.assignmentId), selectedUser.user.uid).then(
            (data) => {
                statusCount[PENDING].total += data.totalRate - refreshUser.user.totalRate;
                refreshUser.negotiations = data.negotiationsLogs;
                refreshUser.user.totalRate = data.totalRate;
                self.setState({
                    retrievalInProgress: false,
                });
            },
            (error) => {
                const f = self.props.apiErrFunction;
                if (typeof f === 'function') {
                    f(error);
                }
            },
        );
        // console.log('refresh', users, refreshUser, statusCount);
        this.setState({
            selectedUser: null,
            proposeInProgress: false,
            removeInProgress: false,
        });
    }

    startPropose(user) {
        this.setState({
            selectedUser: user,
            proposeInProgress: true,
        });
    }

    stopPropose() {
        this.setState({
            selectedUser: null,
            proposeInProgress: false,
            removeInProgress: false,
        });
    }

    startRemove() {
        const { selectedUser } = this.state;
        if (selectedUser) {
            this.setState({
                proposeInProgress: false,
                removeInProgress: true,
            });
        }
    }

    removeUsers() {
        const { selectedUser, users, statusCount } = this.state;
        this.setState({
            retrievalInProgress: true,
        });
        const self = this;
        if (selectedUser) {
            const removeUser = users.find(
                (u) => u.user.user.id === selectedUser.user.id && u.assignment === selectedUser.assignmentId,
            );
            if (Boolean(removeUser) && removeUser.user.status === NEGOTIATE) {
                Api.removeAssignmentUser(removeUser.assignment, removeUser.user.user.uid).then(() => {
                    removeUser.user.status = DECLINED;
                    statusCount[NEGOTIATE].count--;
                    statusCount[NEGOTIATE].total -= removeUser.user.totalRate;
                    statusCount[DECLINED].count++;
                    statusCount[DECLINED].total += removeUser.user.totalRate;
                    self.setState({
                        retrievalInProgress: false,
                    });
                });
            }
        }
        this.setState({ removeProgress: false, selectedUser: null });
    }

    handleSelectStatus(value) {
        let statusList = value.split(',');

        if (statusList.indexOf('ALL') >= 0) {
            if (this.state.selectedStatus[0] !== 'ALL') {
                statusList = ['ALL'];
            } else if (statusList.length > 1) {
                const a = statusList.indexOf('ALL');
                statusList.splice(a, 1);
            }
        }
        if (value === '' || statusList.length === 4) {
            statusList = ['ALL'];
        }
        this.setState({ selectedStatus: statusList }, this.getCurrentList);
    }

    handleSelectAssignments(value) {
        if (value.find((assignment) => assignment.value === 'ALL')) {
            if (this.state.selectedAssignment[0] !== 'ALL') {
                value = ['ALL'];
            } else if (value.length > 1) {
                const a = value.findIndex((assignment) => assignment.value === 'ALL');
                value.splice(a, 1);
            }
        }
        if (value.length === 0 || (value.length > 1 && value.length === this.state.assignments.length - 1)) {
            value = ['ALL'];
        }
        this.setState({ selectedAssignment: value }, this.getCurrentList);
    }

    getCurrentList() {
        const { assignmentUserList, selectedAssignment, selectedStatus, assignments, assignmentId } = this.state;
        let totalcount = 0;
        const statusCount = STATUSOBJ;
        Object.keys(STATUSOBJ).forEach((status) => {
            statusCount[status] = { count: 0, total: 0 };
        });
        if (selectedAssignment.length === 0) {
            selectedAssignment.push(assignments.find((a) => Number(a.value) === Number(assignmentId)));
        }
        if (selectedAssignment) {
            let addEnabled = -1;
            // find out if any of the selected assignments has flights
            if (selectedAssignment.length === 1 && selectedAssignment[0] !== 'ALL') {
                const assignment = assignments.find(
                    (a) => Number(a ? a.value : -1) === Number(selectedAssignment[0]?.value),
                );
                if (assignment) {
                    addEnabled = 1;
                }
            }

            const users = [];
            // console.log(statusCount)
            if (Array.isArray(assignmentUserList) && assignmentUserList.length > 0) {
                assignmentUserList.sort((a, b) => a.assignment > b.assignment);
                assignmentUserList.forEach((user) => {
                    const userStatus = user.user.status;
                    if (
                        user.user &&
                        (selectedAssignment[0] === 'ALL' || selectedAssignment.find((a) => a?.value === user?.assignment))
                    ) {
                        if (selectedStatus[0] === 'ALL' || selectedStatus.indexOf(userStatus) > -1) {
                            users.push(user);
                        }
                        if (Object.keys(STATUSOBJ).indexOf(userStatus) > -1) {
                            statusCount[userStatus].count++;
                            statusCount[userStatus].total += user.user.totalRate;
                            totalcount++;
                        }
                    }
                });
            }
            if (addEnabled > 0) {
                this.searchPageUrl =
                    `/campaignWork/${  this.props.campaignId  }/influencerSearch/${  selectedAssignment[0].value}`;    
            }
            this.setState({
                users,
                addEnabled,
                statusCount,
                totalCount: totalcount,
                retrievalInProgress: false,
                selectedInfluencers: [],
                allSelectedStatus: false,
            });
        }
    }

    renderStatus(status, count) {
        const sType = Lookup.getStatusType(status);
        const ovalStyle = {backgroundColor: sType.ovalColor}
        return (
            <div className="statusItem" key={sType.label}>
                <div className="label">
                    <div className="oval" style={ovalStyle}/> <span>{sType.slabel || sType.label}</span>{' '}
                </div>
                <div className="value">{count}</div>
            </div>
        );
    }

    componentDidMount() {
        const assignmentId = this.props.match.params.assignmentId ? Number(this.props.match.params.assignmentId) : 0;
        const {campaign, profile} = this.props;
        if (assignmentId > 0) {
            this.props.setNavigationFunction(assignmentId);
            this.processProps();
            Api.getAssignment(assignmentId)
                .then((res) => {
                    this.setState({
                        defContract: res.defaultContract,
                        assignmentContractType: res.assignmentContractType
                    });
                })
                .catch((err) => {
                    const f = this.props.apiErrFunction;
                    if (typeof f === 'function') {
                        f(err);
                    }
                });
        }
        if (campaign && campaign.agencyBrand && campaign.agencyBrand.brandId && profile && profile.role !== VIEWER_STR) {
            Api.getBrandAgents(campaign.agencyBrand.brandId)
                .then(res => {
                    if(Array.isArray(res)) {
                        const agentSignersList = [];
                        res.forEach(item => {
                            agentSignersList.push({
                                value: item.id,
                                label: `${item.firstname} ${item.lastname} (${DisplayRoleNames[item.role] || item.role})`
                            })
                        })
                        this.setState({ signerList: agentSignersList });
                    }
                })
                .catch(err => {
                    const f = this.props.apiErrFunction;
                    if (typeof f === 'function') {
                        f(err);
                    }
                })
        }
        Api.getCurrencyList().then(result => {
            const { data } = result;
            const symbolList = {}
            data.forEach(x => {
                symbolList[x.code] = x.symbol
            })
            this.setState({symbolArray: symbolList})
        })
    }

    processProps() {
        this.setState({
            retrievalInProgress: true,
        });
        const {campaignId, apiErrFunction} = this.props;
        const self = this;
        Api.getAssignmentList(Number(campaignId))
            .then((res) => {
                const data = res.results;
                const promises = [];
                const assignments = [{ value: 'ALL', label: 'All Assignments' }];
                let campaignBudget = 0;
                if (Array.isArray(data) && data.length > 0) {
                    data.forEach((assignment, index) => {
                        assignments.push({
                            value: assignment.id,
                            label: assignment.name,
                        });
                        if (index === 0) {
                            campaignBudget = assignment.campaign.budget;
                        }
                        if (Array.isArray(assignment.users) && assignment.users.length > 0) {
                            const agentEmail = this.props.profile.email;
                            assignment.users.forEach((user) => {
                                const userStatus = user.status;
                                const thisUser = user;
                                if (userStatus === NEGOTIATE) {
                                    promises.push(
                                        Api.getAssignmentUser(Number(assignment.id), user.user.uid).then(
                                            (data) => {
                                                return {
                                                    user: thisUser,
                                                    assignment: assignment.id,
                                                    assignmentName: assignment.name,
                                                    assignmentTypes: data.deliverables ? Object.keys(data.deliverables) : [],
                                                    negotiations: data.negotiationsLogs,
                                                    contractStatus: true,
                                                };
                                            },
                                            (error) => {
                                                return {
                                                    user: thisUser,
                                                    assignment: assignment.id,
                                                    assignmentName: assignment.name,
                                                    assignmentTypes: assignment.types,
                                                    negotiations: [],
                                                    contractStatus: true,
                                                };
                                            },
                                        ),
                                    );
                                } else if (userStatus === ACCEPTED || userStatus === COMPLETED) {
                                    promises.push(
                                        Api.contractEnvelopeStatus(Number(assignment.id), user.user.uid).then(
                                            (contract) => {
                                                const {
                                                    user: { email: thisUserEmail },
                                                } = thisUser;
                                                const { signers = [] } = contract;
                                                const influencerContract = signers.findIndex(
                                                    ({ email }) => email === thisUserEmail,
                                                );
                                                const adminContract = signers.findIndex(
                                                    ({ email }) => email === agentEmail,
                                                );
                                                let contractStatus =
                                                    contract.error ||
                                                    (influencerContract > -1 &&
                                                        signers[influencerContract].status !== 'completed') ||
                                                    adminContract < 0 ||
                                                    (adminContract > -1 &&
                                                        signers[adminContract].status === 'completed');
                                                if(user && user.contractStatus === CONTRACT_UPLOADED) {
                                                    // this change the contract status to not needed because its completed offline uploaded.
                                                    contractStatus = true;
                                                }
                                                return {
                                                    user: thisUser,
                                                    assignment: assignment.id,
                                                    assignmentName: assignment.name,
                                                    assignmentTypes: assignment.types,
                                                    negotiations: [],
                                                    contractStatus,
                                                    isOfflineContractUploaded: user && user.contractStatus === CONTRACT_UPLOADED
                                                };
                                            },
                                            (error) => {
                                                return {
                                                    user: thisUser,
                                                    assignment: assignment.id,
                                                    assignmentName: assignment.name,
                                                    assignmentTypes: assignment.types,
                                                    negotiations: [],
                                                    contractStatus: true,
                                                };
                                            },
                                        ),
                                    );
                                } else {
                                    promises.push({
                                        user: thisUser,
                                        assignment: assignment.id,
                                        assignmentName: assignment.name,
                                        assignmentTypes: assignment.types,
                                        negotiations: [],
                                        contractStatus: true,
                                    });
                                }
                            });
                        }
                        if (Array.isArray(assignment.publicUsers) && assignment.publicUsers.length > 0) {
                            assignment.publicUsers.forEach((user) => {
                                const userStatus = user.status;
                                const thisUser = user;
                                thisUser.user = user;
                                thisUser.isPublic = true
                                promises.push({
                                    user: thisUser,
                                    assignment: assignment.id,
                                    assignmentName: assignment.name,
                                    assignmentTypes: assignment.types,
                                    contractStatus: true,
                                });
                            })
                        }
                    });
                }
                Promise.all(promises).then((list) => {
                    self.setState(
                        {
                            assignmentUserList: list,
                            assignments,
                            campaignBudget,
                        },
                        self.getCurrentList,
                    );
                });
            })
            .catch((err) => {
                const f = apiErrFunction;
                if (typeof f === 'function') {
                    f(err);
                }
            });
    }

    // transferToCalenderForAssignemnt() {
    //     if (this.props.campaignId && this.state.assignmentId) {
    //         let {assignmentId} = this.state;
    //         if (typeof assignmentId === 'string') {
    //             assignmentId = parseInt(assignmentId);
    //         }
    //         this.props.history.push(`/campaignWork/${  this.props.campaignId  }/calendar/${  assignmentId}`);
    //     }
    // }

    handleOpenContractStatus = (event, assignmentId, userId) => {
        const self = this;
        const { users } = this.state;
        const currentUser = users.find((u) => u.user.user.id === userId && u.assignment === assignmentId);
        const uid =
            currentUser && currentUser.user && currentUser.user.user && currentUser.user.user.uid
                ? currentUser.user.user.uid
                : '';
        self.setState({
            isOpenContractStatus: true,
            contractStatusAid: assignmentId,
            contractStatusSingerId: this.props.profile.id,
            contractStatusUid: uid,
            contractStatusData: {
                campaignName: self.props.campaign.name,
                assignmentName: currentUser.assignmentName,
                userName: `${currentUser.user.user.firstname} ${currentUser.user.user.lastname}`,
                userEmail: currentUser.user.user.email,
            },
        });
    };

    handleOpenContractOffline = (event, assignmentId, userId) => {
        const self = this;
        const { users } = this.state;
        const currentUser = users.find((u) => u.user.user.id === userId && u.assignment === assignmentId);
        let uid =
            currentUser && currentUser.user && currentUser.user.user && currentUser.user.user.uid
                ? currentUser.user.user.uid
                : '';
        const {isPublic} = currentUser.user
        if (isPublic) {
            uid = currentUser.user.id
        }
        self.setState({
            isOpenContractUpload: true,
            contractStatusAid: assignmentId,
            contractStatusSingerId: this.props.profile.id,
            contractStatusUid: uid,
            contractStatusData: {
                campaignName: self.props.campaign.name,
                assignmentName: currentUser.assignmentName,
                userName: isPublic ? currentUser.user.creatorName : `${currentUser.user.user.firstname} ${currentUser.user.user.lastname}`,
                userEmail: currentUser.user.user.email,
                isPublic,
                contractResourceId: isPublic ? currentUser.user.contractResourceId : null
            },
        });
    };

    handleOpenOtherDocuments = (event, assignmentId, userId) => {
        const self = this;
        const { users } = this.state;
        const currentUser = users.find((u) => u.user.user.id === userId && u.assignment === assignmentId);
        let uid =
            currentUser && currentUser.user && currentUser.user.user && currentUser.user.user.uid
                ? currentUser.user.user.uid
                : '';
        const {isPublic} = currentUser.user
        if (isPublic) {
            uid = currentUser.user.id
        }
        self.setState({
            isOpenOtherDocuments: true,
            contractStatusAid: assignmentId,
            contractStatusSingerId: this.props.profile.id,
            contractStatusUid: uid,
            contractStatusData: {
                campaignName: self.props.campaign.name,
                assignmentName: currentUser.assignmentName,
                userName: isPublic ? currentUser.user.creatorName : `${currentUser.user.user.firstname} ${currentUser.user.user.lastname}`,
                userEmail: currentUser.user.user.email,
                isPublic,
                contractResourceId: isPublic ? currentUser.user.contractResourceId : null
            },
        });
    };

    handleCloseContractStatus = () => {
        this.setState({
            isOpenContractStatus: false,
        });
    };

    handleCloseContractUpload = (e, triggerReload) => {
        this.setState({
            isOpenContractUpload: false,
        }, () => {
            if(triggerReload) {
                window.location.reload();
            }
        });
    };

    checkboxChangeHandler = (selectInfo, selectAll) => {
        this.setState((prevState) => {
            const selectedInfluencers = prevState.selectedInfluencers.concat();
            const isAlreadySelected = selectedInfluencers.find(({ id }) => id === selectInfo.id);
            const newSelectedInfluencers = !isAlreadySelected
                ? [...selectedInfluencers, selectInfo]
                : selectAll
                    ? selectedInfluencers
                    : selectedInfluencers.filter(({ id }) => id !== selectInfo.id);
            return {
                selectedInfluencers: newSelectedInfluencers,
                allSelectedStatus: this.isAllSelected(newSelectedInfluencers),
            };
        });
    };

    selectAllHandler = () => {
        const { selectedInfluencers, users } = this.state;

        if (this.isAllSelected(selectedInfluencers) || selectedInfluencers.length) {
            this.setState({
                selectedInfluencers: [],
                allSelectedStatus: false,
            });

            return;
        }
        const newSelected = []
        users.map(({ user }) => {
            if(!user.isPublic) {
                newSelected.push({
                    id: user.id,
                    userId: user.user.id,
                    wformUploaded: user.user.wformUploaded,
                    userName: `${user.user.firstname} ${user.user.lastname}`
                })
            }
        });

        this.setState({
            selectedInfluencers: newSelected,
            allSelectedStatus: true,
        });
    };

    getUniqInfluencers = (users) =>
        users.reduce((acc, { userId }) => {
            if (!acc.find((id) => id === userId)) {
                acc.push(userId);
            }

            return acc;
        }, []);

    isAllSelected(selectedInfluencers) {
        return this.state.users.length === selectedInfluencers.length;
    }

    exportAddressButtonHandler = async () => {
        const { isAddressLoading, selectedInfluencers } = this.state;

        if (!isAddressLoading) {
            this.setState({
                isAddressLoading: true,
            });
            await exportInfluencerShippingAddress(this.props.campaignId, this.getUniqInfluencers(selectedInfluencers));
            this.setState({
                isAddressLoading: false,
            });
        }
    };

    exportFormHandler = async () => {
        const {  selectedInfluencers } = this.state;
        const noFormUsers = []
        const validatedUsers = []
        for (let i=0; i < selectedInfluencers.length; i+=1) {
            if (selectedInfluencers[i].wformUploaded) {
                validatedUsers.push(selectedInfluencers[i].userId)
            } else {
                noFormUsers.push(selectedInfluencers[i])
            }
        }
        if (noFormUsers.length > 0) {
            this.setState({ noFormUsers, openNoForm: true, downloadUsers: validatedUsers })
        } else {
            this.exportForm(validatedUsers);
        }
    }

    continueExportForm = () => {
        const { downloadUsers } = this.state;
        if (downloadUsers.length > 0) {
            this.exportForm(downloadUsers);
        }
        this.setState({openNoForm: false})
    }

    exportForm = (id) => {
        this.setState({retrievalInProgress: true})
        Api.getInfluencerWFormUrl(id).then(res => {
            if(res && res.url) {
                const updateFileProgress = (progressPercentage) => {
                    if (progressPercentage > 0) {
                        this.setState({downloadProgressPercentage: progressPercentage})
                    }
                };
        
                const updateFileFinished = (response) => {
                    // handle old data with no name & extensions
                    const ext = response.data && response.data.type ? Format.mimeToExt(response.data.type) : '';
                    const fileName = res.name==='File' ? `File${ext}` : res.name;
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', fileName); // or any other extension
                    document.body.appendChild(link);
                    link.click();
                    this.setState({ downloadProgressPercentage: null, retrievalInProgress: false });
                };
        
                updateFileProgress(1);
                // Call gradual download
                Api.getS3FileGradually(res.url,
                    (progressPercentage) => updateFileProgress(progressPercentage),
                    (response) => updateFileFinished(response));
    
            }else{
                this.setState({retrievalInProgress: false, downloadProgressPercentage: null})
            }
        })
            .catch(err => {
                this.setState({retrievalInProgress: false, downloadProgressPercentage: null})
            })
    }

    removeUserFromAssignment = (userId, isPublic) => {
        const { users, statusCount, totalCount } = this.state;
        const listToUpdate = [...users];
        let index = 0
        if (isPublic) {
            index = users.findIndex(({ user: { user: { id } } }) => id === userId);
        } else {
            index = users.findIndex(({ user: { user: { uid } } }) => uid === userId);
        }
        const oldstatus = listToUpdate[index].user.status
        statusCount[oldstatus].total -= listToUpdate[index].user.totalRate;
        statusCount[oldstatus].count -= 1;
        listToUpdate.splice(index, 1);
        this.setState({ users: listToUpdate, statusCount, totalCount: totalCount - 1 });
    };

    updateUserFromAssignment = (userId, status) => {
        const { users, statusCount } = this.state;
        const listToUpdate = [...users];
        const index = users.findIndex(({ user: { user: { id } } }) => id === userId);
        const oldstatus = listToUpdate[index].user.status
        listToUpdate[index].status = status;
        listToUpdate[index].user.status = status;
        statusCount[oldstatus].total -= listToUpdate[index].user.totalRate;
        statusCount[oldstatus].count -= 1;
        statusCount[status].total += listToUpdate[index].user.totalRate;
        statusCount[status].count += 1;
        this.setState({ users: listToUpdate,  statusCount });
    }

    handleExport = () => {
        const { openExport } = this.state;
        this.setState({openExport: !openExport});
    }

    closeExport = () => {
        this.setState({openExport: false})
    }

    closeError = () => {
        this.setState({openNoForm: false})
    }

    refreshContract = newItem => {
        this.setState({contractStatusData: newItem})
    }

    render() {
        const { campaign, profile, campaignId } = this.props;
        const { selectedUser, users, proposeInProgress, removeInProgress, assignmentId, isOpenOtherDocuments } = this.state;
        const { selectedAssignment, selectedStatus, assignments, totalCount, statusCount, campaignBudget, noFormUsers } = this.state;
        const { isOpenContractStatus, isOpenContractUpload, contractStatusAid, contractStatusSingerId } = this.state;
        const { contractStatusUid, contractStatusData, selectedInfluencers, allSelectedStatus, openNoForm } = this.state;
        const { retrievalInProgress, showPopupMessage, openExport, signerList, addEnabled, symbolArray }=this.state;
        const permission = profile && (profile.role === AGENT_STR || profile.role === SUPER_ADMIN_STR || profile.role === BRAND_OWNER_STR || profile.role === VIEWER_STR);
        const self = this;
        const statuslist = [];
        const statusOptions = [{ value: 'ALL', label: 'All status' }];
        Object.keys(STATUSOBJ).forEach((status) => {
            if (status !== 'EXPIRED') {
                statuslist.push(self.renderStatus(status, statusCount[status].count));
                const statusInfoObj = Lookup.getStatusType(status);
                statusOptions.push({ value: status, label: statusInfoObj.slabel || statusInfoObj.label });
            }
        });
        const numberFormat = new Intl.NumberFormat({ currency: 'CAD' });

        const isPartialSelected = selectedInfluencers.length && !allSelectedStatus;
        const amountContracted = numberFormat.format(
            statusCount[ACCEPTED].total +
            statusCount[COMPLETED].total +
            statusCount[PAID].total
        );
        const hasConnected = users.some(user => !user.user.isPublic)
        return (
            <div className="campaignInfluencersContainer">
                <Modal
                    overlayClassName="influencerCardOverlay"
                    className="confirmRatesModal"
                    isOpen={openNoForm}>
                    <div className="formError">
                        <div className="title">Missing form</div>
                        <div className="description">The following creator(s) have not uploaded their W8/W9 forms:</div>
                        <ul className="list">
                            {noFormUsers.map(user => (<li key={user.id}>{user.userName}</li>))}
                        </ul>
                        <div className="buttonSection">
                            <div className="cancelButton" onClick={this.closeError}>Cancel</div>
                            <div className="continueButton" onClick={this.continueExportForm}>Continue</div>
                        </div>
                    </div>
                </Modal>
                {Boolean(selectedUser) && (
                    <ProposeRate
                        player="AGENT"
                        user={selectedUser.user}
                        aid={selectedUser.assignmentId}
                        atypes={selectedUser.types}
                        slots={selectedUser.slots}
                        show={proposeInProgress}
                        acceptReady
                        cancelFunction={this.stopPropose}
                        proceedFunction={this.acceptOpportunity}
                        cancelButtonFunction={this.startRemove}
                        sentNewRate={() => this.refreshUser(selectedUser)}
                        signerList={signerList}
                    />
                )}
                {Boolean(selectedUser) && (
                    <ModalDialog
                        show={removeInProgress}
                        title="Remove Creator"
                        proceedButtonLabel="DECLINE"
                        readyToProceed
                        closeButton
                        proceedFunction={this.removeUsers}
                        cancelFunction={this.stopPropose}
                        proceedButtonStyle={{ backgroundColor: '#d0021b' }}
                    >
                        <div className="deleteinflencer">
                            <div className="label">
                                Are you sure you want to remove the creator {selectedUser.user.firstname}{' '}
                                {selectedUser.user.lastname}?
                            </div>
                        </div>
                    </ModalDialog>
                )}
                <div className="campaignInfluencers">
                    {retrievalInProgress && (
                        <div className="refreshWaiting">
                            <PleaseWait show={retrievalInProgress} top={200} />
                        </div>
                    )}
                    <div>
                        <div className="influersList">
                            <div className="selector">
                                <div className="select-box">
                                    <div className="label">Assignments:</div>
                                    <Select
                                        className="select"
                                        disabled={!permission}
                                        clearable={false}
                                        value={selectedAssignment}
                                        multi
                                        placeholder=""
                                        options={assignments}
                                        onChange={(...args) => self.handleSelectAssignments(...args)}
                                    />
                                </div>
                                <div className="select-box">
                                    <div className="label">Status:</div>
                                    <Select
                                        className="select"
                                        disabled={!permission}
                                        clearable={false}
                                        value={selectedStatus}
                                        multi
                                        placeholder=""
                                        onChange={(...args) => this.handleSelectStatus(...args)}
                                        simpleValue
                                        options={statusOptions}
                                    />
                                </div>
                            </div>
                            {this.state.selectedStatus[0] !== 'ALL' || users.length ? (
                                <div>
                                    <div className="operation">
                                        {hasConnected && <div className="selectAllContainer">
                                            <div className="selectAllCheckbox" onClick={this.selectAllHandler}>
                                                <Checkbox
                                                    id="userCheckbox-selectAll"
                                                    size={16}
                                                    controlled
                                                    partialSelectController
                                                    partialSelect={isPartialSelected}
                                                    changeFunction={this.selectAllHandler}
                                                    checked={allSelectedStatus}
                                                />
                                                {isPartialSelected || allSelectedStatus ? `Selected ${  selectedInfluencers.length   } creator(s)` : 'Select All'}
                                            </div>
                                            {/* {!!selectedInfluencers.length && (
                                                <div className="selectAllInfo">
                                                    <span>{selectedInfluencers.length}</span> creator(s) selected
                                                </div>
                                            )} */}
                                        </div>}

                                        {!!selectedInfluencers.length && (
                                            <div className="exportSection" tabIndex={0} onBlur={this.closeExport}>
                                                <div className="exportAddressBtn" onClick={this.handleExport}>
                                                    <img className="exportIcon" src="/images/Upload.svg" alt="export button" />
                                                    <span>Export</span>
                                                    <img className={cx("arrow", {opened: openExport})} src="/images/ic-bown-b.svg" alt="arrow" />
                                                </div>
                                                {openExport && <div className="exportItems">
                                                    <div onClick={this.exportAddressButtonHandler}>Export Address</div>
                                                    <div onClick={this.exportFormHandler}>Export W8/W9 form</div>
                                                </div>}
                                            </div>
                                        )}

                                        {permission && profile.role !== VIEWER_STR && (
                                            <div className={cx("newButtonSection", !!selectedInfluencers.length && "noMargin")}>
                                                <div
                                                    className={cx('buttons', 'creator', { valid: addEnabled > 0 })}
                                                    onClick={addEnabled > 0 ? () => this.addButtonClicked('PUBLIC') : null}
                                                >
                                                    <img  src="/images/whitePlus.svg" alt="creator" className="icon" />
                                                    Public
                                                </div>
                                                <div
                                                    className={cx('buttons', 'creator', { valid: addEnabled > 0 })}
                                                    onClick={addEnabled > 0 ? () => this.addButtonClicked('CONNECTED') : null}
                                                >
                                                    <img  src="/images/whitePlus.svg" alt="creator" className="icon" />
                                                    Connected
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <Influencers
                                        player="AGENT"
                                        campaignId={campaignId}
                                        campaign={campaign}
                                        campaignAssignmentId={assignmentId}
                                        notReady=""
                                        permission={permission}
                                        users={users}
                                        profile={profile}
                                        handleOpenContractStatus={this.handleOpenContractStatus}
                                        handleOpenContractUpload={this.handleOpenContractOffline}
                                        handleOpenOtherDocuments={this.handleOpenOtherDocuments}
                                        startPropose={this.startPropose}
                                        selectedInfluencers={selectedInfluencers}
                                        checkboxChangeHandler={this.checkboxChangeHandler}
                                        removeUserFromAssignment={this.removeUserFromAssignment}
                                        signerList={signerList}
                                        onEditing={this.handleEditInfluencer}
                                        symbol={symbolArray[campaign.currencyCode]}
                                        updateUserFromAssignment={this.updateUserFromAssignment}
                                    />
                                </div>
                            ) : (
                                <div className="blankInfluencer">
                                    {permission && profile.role !== VIEWER_STR && <div className="newButtonSection">
                                        <div
                                            className={cx('buttons', 'creator', { valid: addEnabled > 0 })}
                                            onClick={addEnabled > 0 ? () => this.addButtonClicked('PUBLIC') : null}
                                        >
                                            <img  src="/images/whitePlus.svg" alt="creator" className="icon" />
                                            Public
                                        </div>
                                        <div
                                            className={cx('buttons', 'creator', { valid: addEnabled > 0 })}
                                            onClick={addEnabled > 0 ? () => this.addButtonClicked('CONNECTED') : null}
                                        >
                                            <img  src="/images/whitePlus.svg" alt="creator" className="icon" />
                                            Connected
                                        </div>
                                    </div>}
                                    <img src="/images/ic-person.svg" alt='add'/>
                                    You haven't added any creators
                                </div>
                            )}
                        </div>
                        <div>
                            <div className="influencerStatusNew">
                                <div className="title">Invite Status</div>
                                <div className="total">
                                    <div>Total Invited</div>
                                    <div>{totalCount}</div>
                                </div>
                                <div className="status">{statuslist}</div>
                                <div className="budgetSection">
                                    <div className="budgetFirst">
                                        <div className="title">Budget Status</div>
                                        <div className="currency">
                                            Currency:
                                            <div className="currencyCode">
                                                {` ${campaign.currencyCode} (${symbolArray[campaign.currencyCode]})`}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="total">
                                        <div>Campaign Budget</div>
                                        <div>${numberFormat.format(campaignBudget)}</div>
                                    </div>
                                    <div className="budgetItems">
                                        <div className="items">
                                            <div>Amount Contracted</div>
                                            <div>${amountContracted}</div>
                                        </div>
                                        <div className="items">
                                            <div>Amount Pending</div>
                                            <div>${numberFormat.format(statusCount[PENDING].total + statusCount[NEGOTIATE].total)}</div>
                                        </div>
                                        <div className="items">
                                            <div>Total Declined</div>
                                            <div>$
                                                {numberFormat.format(
                                                    statusCount[DECLINED].total + statusCount[EXPIRED].total,
                                                )}</div>
                                        </div>
                                        <div className="items">
                                            <div>Total Revoked</div>
                                            <div>$
                                                {numberFormat.format(
                                                    statusCount[REVOKED].total,
                                                )}</div>
                                        </div>
                                    </div>
                                    {/* <div className="budgetItem2">
                                        <div className="items">
                                            <div>Potential savings
                                                <Tooltip tooltipText="sniff saving tooltip" image="/images/tooltipUser.svg" />
                                            </div>
                                            <div>{symbolArray[campaign.currencyCode]}
                                                {numberFormat.format(
                                                    campaign.sniffSavings,
                                                )}</div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ContractStatus
                    contractStatusData={contractStatusData}
                    assignmentId={contractStatusAid}
                    signerId={contractStatusSingerId}
                    uid={contractStatusUid}
                    isOpen={isOpenContractStatus}
                    onClose={this.handleCloseContractStatus}
                    location={this.props.location}
                />
                <ContractManualUpload
                    contractStatusData={contractStatusData}
                    uploader={contractStatusSingerId}
                    assignmentId={contractStatusAid}
                    uid={contractStatusUid}
                    isOpen={isOpenContractUpload}
                    onClose={this.handleCloseContractUpload}
                    uploadSuccess={() => {}}
                    refreshContract={this.refreshContract}
                />
                <OtherDocumentsModal
                    contractStatusData={contractStatusData}
                    isOpen={isOpenOtherDocuments}
                    assignmentId={contractStatusAid}
                    uid={contractStatusUid}
                    onClose={() => this.setState({isOpenOtherDocuments: false})}
                />
                <FeedbackOnTheTopImproved
                    show={showPopupMessage}
                    message={this.popupMessage}
                    closeFunction={() => this.setShowPopupMessage(false)}
                />
            </div>
        );
    }
}

CampaignInfluencers.propTypes = {
    campaignId: PropTypes.number,
    campaign: PropTypes.shape(),
    profile: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => {
    return {
        profile: state.global.loggedInUserProfile,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeChatStatus: (isChatOpen) => {
            dispatch(changeChatStatusAction(isChatOpen));
        },
        changeSelectedInfluencer: (influencerId, activeInfluencerObj) => {
            dispatch(changeSelectedInfluencerAction(influencerId, activeInfluencerObj));
        },
        searchFilterChange: (newFilters) => {
            dispatch(searchFilterChange(newFilters));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CampaignInfluencers);
