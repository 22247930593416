import {
    EXCLUSIONS_CREATE,
    EXCLUSIONS_READ,
    EXCLUSIONS_UPDATE,
    EXCLUSIONS_DELETE,
    EXCLUSION_LOADING,
    EXCLUSION_ERROR,
    EXCLUSION_SUCCESS
} from '../types';

import { store } from '../../index.jsx';

import Api from '../../modules/Api';

export const allFavoritesName = "All Favorites";

export const maxNumberOfFavoriteLists = 50;

// works properly only after a FAVORITE_READ success
export const getListsForInfluencer = (id) => {
    const favoriteState = store.getState().FAVORITE_REDUCER;
    if (!favoriteState.favoriteData) {
        return null;
    }
    const retval = [];
    for (const list of favoriteState.favoriteData) {
        if (Array.isArray(list.users) && list.users.find((user) => { return user.id == id })) {
            retval.push(list);
        }
    }
    return retval;
}

export const loadingExclusionsList = isLoading => ({
    type: EXCLUSION_LOADING,
    isLoading
});

export const saveExclusionList = exclusion => ({
    type: EXCLUSIONS_UPDATE,
    exclusion
});

export const deleteExclusionList = exclusionId => ({
    type: EXCLUSIONS_DELETE,
    exclusionId
});

export const createExclusionList = exclusion => ({
    type: EXCLUSIONS_CREATE,
    exclusion
});

export const readExclusionList = exclusions => ({
    type: EXCLUSIONS_READ,
    exclusions
});

export const errorExclusionList = err => ({
    type: EXCLUSION_ERROR,
    err
});

export const successExclusionList = success => ({
    type: EXCLUSION_SUCCESS,
    success
});

// CREATE
export const createExclusion = (exclusion, callback) => {
    return (dispatch) => {
        dispatch(loadingExclusionsList(true));
        Api.createExclusion(exclusion).then(
            result => {
                dispatch(loadingExclusionsList(false));
                dispatch(createExclusionList(result))
                if (typeof callback === 'function')
                    callback(result);
            }).catch(err => {
                dispatch(loadingExclusionsList(false));
                dispatch(errorExclusionList(err))
            })
    }
}

// READ
export const fetchExclusions = () => {
    return (dispatch) => {
        dispatch(loadingExclusionsList(true));
        Api.getExclusions().then(
            result => {
                // const list = result.sort( (a,b) => {
                //     if (a.combined) {
                //         return -1;
                //     }
                //     if (b.combined) {
                //         return 1;
                //     }
                //     if (a.name < b.name) {
                //         return -1;
                //     }
                //     if (b.name < a.name) {
                //         return 1;
                //     }
                //     return 0;
                // })
                dispatch(readExclusionList(result));
                dispatch(loadingExclusionsList(false));
            }).catch(err => {
                dispatch(loadingExclusionsList(false));
                dispatch(errorExclusionList(err))
            })
    }
}

// UPDATE
export const saveExclusion = (exclusionId, exclusion, callback) => {
    // const bookmarkIds = bookmark.users.map(user => (user.id)? user.id : user)
    // const bookmarkIdsx = bookmarkIds.filter((value, index, self) => { 
    //     return self.indexOf(value) === index;
    // });
    // console.log('Help',{...bookmark, users: bookmarkIdsx});
    return (dispatch) => {
        dispatch(loadingExclusionsList(true));
        Api.saveExclusion(exclusionId, exclusion).then(
            result => {
                dispatch(loadingExclusionsList(false));
                dispatch(saveExclusionList(result))
                if (typeof callback === 'function')
                    callback(result);
            }).catch(err => {
                dispatch(loadingExclusionsList(false));
                dispatch(errorExclusionList(err))
            })
    }
}

// UPDATE_SEVERAL
// export const saveSeveralFavorites = (bookmarks) => {
//     return (dispatch) => {
//         dispatch(loadingExclusionsList(true));
//         const promises = [];
//         for (const bookmark of bookmarks) {
//             const users = bookmark.users.map(user => (user.id)? user.id : user);
//             const promise = Api.saveFavorites(bookmark.id, {...bookmark, users}).then(
//                 result => {
//                     dispatch(loadingExclusionsList(false));
//                     dispatch(saveExclusionList({
//                         id: result.id,
//                         name: result.name,
//                         users: result.users
//                     }))
//                 }
//             );
//             promises.push(promise);
//         }
//         Promise.all(promises).then(
//             result =>{
//                 fetchFavorites();
//             }).catch(err => {
//             dispatch(loadingExclusionsList(false));
//             dispatch(errorExclusionList(err))
//         })
//     }
// }


// DELETE
export const deleteExclusion = (exclusionId) => {
    return (dispatch) => {
        dispatch(loadingExclusionsList(true));
        Api.deleteExclusion(exclusionId).then(
            () => {
                dispatch(loadingExclusionsList(false));
            }).catch(err => {
                dispatch(loadingExclusionsList(false));
                dispatch(errorExclusionList(err));
            })
    }
}
