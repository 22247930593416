import React from 'react'
import { withRouter } from 'react-router'
import {connect} from 'react-redux';
import cx from 'classnames'
import moment from 'moment'
import ManagementStatus from "../../constants/managementStatus";
import Checkbox from '../shared/checkbox/Checbox'
import Actions from './actions/Actions'
import InfluencerPhoto from './InfluencerPhoto'

// import './oneInfluencer.scss'

const status = {
    REMOVED_BY_TM: 'Removed',
    PENDING: 'Pending',
    REMOVED_BY_INFLUENCER: 'Removed'
};

class PendingInfluencer extends React.Component {

    constructor (props, context) {
        super(props, context);

        this.handleSelect = this.handleSelect.bind(this);
        this.showActions = this.showActions.bind(this);
        this.hideActions = this.hideActions.bind(this);

        this.state = {
            metricWidth : 50,
            showExtended : false,
            showActions : false,
            showFavorites: false,
            heart: false,
            rating:'',
            online:false
        }
    }

    showActions(e) {
        e.stopPropagation();
        e.preventDefault();
        this.setState({
            showActions : !this.state.showActions,
            showFavorites: false
        })
    }

    hideActions() {
        this.setState({
            showActions : false,
            showFavorites: false
        })
    }


    handleSelect(flag) {
        const f = this.props.selectFunction;
        if (typeof f === 'function') {
            f(this.props.details.uid, flag);
        }
    }

    setWidth = () => {
        this.setState({boxWidth : this.coreElement.offsetWidth})
    }

    componentDidMount() {

        window.addEventListener('click',this.hideActions)
        window.addEventListener('resize', this.setWidth)
        this.setWidth()
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.hideActions);
        window.removeEventListener('resize', this.setWidth);
    }



    render () {
        const {details, invited, inviteDisabled, checked, inviteFunction, showExpandIcon, permission} = this.props;
        return (
            <div className="oneInfluencer pending-influencer">
                <div
                    className={this.props.checked?"core checked":"core"}
                    onClick={this.flipExtended}
                    onMouseLeave={this.hideActions}
                    ref={(e)=> {this.coreElement = e} }
                >
                    <div className="coreFields">
                        {/* checkbox cell */}
                        {
                            showExpandIcon ?
                                <div className="expand-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        className={cx({'right':!this.state.showExtended},{'down':this.state.showExtended})}
                                    >
                                        <path fill={this.state.showExtended ? '#4AA129' : "#666666"} fillRule="nonzero" d="M16.46 9.27a.889.889 0 0 1 1.276 0 .938.938 0 0 1 0 1.305l-5.097 5.154a.888.888 0 0 1-1.278 0l-5.096-5.154a.936.936 0 0 1 0-1.305.889.889 0 0 1 1.276 0l4.46 4.227 4.458-4.227z"/>
                                    </svg>
                                </div>
                                :
                                <>
                                    <div className="checkbox"
                                        title={ inviteDisabled ? 'This creator can`t be invited (not enough connections or missing rates' : ''}
                                    >
                                        { !inviteDisabled && permission &&
                                        <Checkbox size={22} changeFunction={this.handleSelect} checked={checked} />
                                        }

                                    </div>
                                    <div className="checkSpacer"/>
                                </>
                        }
                        <div className="infl-info-container">
                            <div className="photo" onClick={this.handleClickOnName}>
                                <InfluencerPhoto url={details.pictureUrl}firstName={details?.firstname} lastName={details?.lastname} />
                            </div>

                            <div className="email-container">
                                <div className="details-email">{details.email}</div>
                            </div>
                        </div>

                        <div className="right-side-wrapper">
                            <div className="right-side">
                                <div className="invited-data">
                                    <div>invited on:</div>
                                    <div className="invite-date">{moment(details.management.invitedAt).format("MMM D, YYYY")}</div>
                                </div>
                                <div className={`invite-status ${status[details.management.status]}`}>{status[details.management.status]}</div>
                                { (ManagementStatus.ACTIVE === details.management.status) &&
                                    <div className="moreButton" onClick={this.showActions}>
                                        <img src="/images/ic_more2.png"/>
                                        <Actions profile={details}
                                            showApiError={this.props.showApiError}
                                            showSuccess={this.props.showSuccess}
                                            removeInfluencer={this.props.handleDeleteUser}
                                            permission={permission}
                                            show={this.state.showActions}
                                            inviteFunction={(inviteDisabled || Boolean(invited)) ? null : inviteFunction}
                                        />
                                    </div>
                                }
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        refreshAssignmentList : (campaignId) => {
            dispatch(refreshAssignmentList(campaignId));
        },
        /* addInvitationInSession : (invites) => {
            dispatch(addInvitationsInSession(invites));
        } */
    }
};

const mapStateToProps= (state) => {
    return{
        // userInvitations : state.global.userInvitations,
        favoriteLists : state.FAVORITE_REDUCER.favoriteData
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PendingInfluencer))
