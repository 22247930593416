import React from 'react'
import { Route, Redirect } from 'react-router'
import './onBoard.scss';
import {connect} from 'react-redux';
import NetworksStep from './NetworksStep';
import RatesStep from './RatesStep';
import ProfileStep from './profileStep/ProfileStep';
import {startLoadingLookupTables, storeUserProfile}  from '../../store/global/globalActions'
import Api from '../../modules/Api'
import Format from '../../modules/utils/Format'
import PleaseWaitWhite from '../shared/pleaseWait/PleaseWaitWhite';
import ModalDialog from '../shared/modalDialog/ModalDialog'
import Lookup from '../../modules/Lookup'
import ProfileUtils from '../../modules/influencer/ProfileUtils'
import SocialNetworks from "../../modules/influencer/SocialNetworks";
import ProfileHelper from '../../modules/utils/ProfileHelper';

// props provided by the Redux connection
// - getLookupTables  function
// - lookupTablesLoading  flag
// - lookupTables the lookup table set (object keyed with the field to look up)

const NETWORK_INDEX = 0;
const RATES_INDEX = 1;
const PROFILE_INDEX = 2;
const ALL_INDEX = -1;

class OnBoard extends React.Component {

    constructor (props, context) {
        super(props, context);

        this.setProfile = this.setProfile.bind(this);
        this.renderedStepInHeader = this.renderedStepInHeader.bind(this);
        this.continue = this.continue.bind(this);
        this.navigateToStep = this.navigateToStep.bind(this);
        this.checkCompletion = this.checkCompletion.bind(this);
        this.updateNetworks = this.updateNetworks.bind(this);
        this.renderFullName = this.renderFullName.bind(this);

        this.steps = ['Networks','Rates','Profile'];
        this.stepTitles = {
            Networks : 'Manage Accounts',
            Rates : 'Set Rates',
            Profile : 'Complete Profile'
        }

        this.state = {
            stepIndex: 0,
            lastCompletedStep: -1,
            networks: null,
            profile: null,
            address: {},
            isAcceptedTermsAndConditions: false,
            isProfileStepOk: false,
            ratesValid: false,
            photoVersion: 1,
            minimumRate: 0
        }

        this.autoSaveTimerId = null;
    }

    checkIsRateRequired (rateType, requiredRateFields) {
        return !!requiredRateFields.find(requiredRateField => requiredRateField === rateType);
    }

    isRateStepValid (profile) {
        const { rates } = profile;
        const { minimumRate } = this.state;
        let isValid = true;
        if (rates.GENERAL && rates.GENERAL > minimumRate) {
            return true;
        }

        const requiredFields = Lookup.getRequiredRateFields(this.state.networks);

        for (const key of Lookup.getRateTypeList()) {
            if (key !== 'GENERAL') {
                const isRequired = this.checkIsRateRequired(key, requiredFields);
                if (isRequired && (!rates[key] || rates[key] < minimumRate)) {
                    isValid = false;
                } else if (rates[key] && rates[key] < minimumRate) {
                    isValid = false;
                }
            }
        }
        return isValid;
    }

    checkCompletion() {
        const message = '';  // for debugging though methinks the user should see something luke this too
        const { profile } = this.state;
        // TODO rework check is steps are valid
        const networksOk = Array.isArray(this.state.networks) && this.state.networks.length >= 1;
        const topicsOk = Array.isArray(profile.topics) && profile.topics.length > 1;
        const ratesOk = this.isRateStepValid(profile);
        const profileOk = this.state.isProfileStepOk;

        let lastCompletedStep = -1;
        if (networksOk) {
            lastCompletedStep = 0;
            if (ratesOk && topicsOk) {
                lastCompletedStep = 1;
                if (profileOk) {
                    lastCompletedStep = 2;
                }
            }
        }

        this.setState({
            lastCompletedStep,
            ratesValid: ratesOk
        });
    }

    updateNetworks(networkType, pieces) {
        let networks = this.state.networks || [];
        if (pieces) {
            networks.push(pieces);
        } else {
            networks = [];
            if (Array.isArray(this.state.networks)) {
                for (const n of this.state.networks) {
                    if (n.networkType.toUpperCase() !== networkType.toUpperCase()) {
                        networks.push(n);
                    }
                }
            }
        }
        ProfileHelper.getCurrentLoginUserProfile(true);
        this.setState({networks}, this.checkCompletion);
    }

    navigateToStep(stepIndex) {
        this.setState({
            stepIndex
        })
    }

    setProfile(profileFields) {
        if (this.autoSaveTimerId) {
            clearTimeout(this.autoSaveTimerId)
        }

        this.autoSaveTimerId = setTimeout(() => {
            const newProfile = { ...this.state.profile };

            for (const key in profileFields) {
                newProfile[key] = profileFields[key];
            }

            this.setState({
                profile: newProfile,
                photoVersion: this.state.photoVersion + 1
            });

            ProfileHelper.saveProfile(this.addAdditionalFields(newProfile)).then((res) => {
                this.props.storeProfile(res);
            })

            this.checkCompletion();
        }, 150)
    }

    addAdditionalFields = (profile) => {
        if (profile.shippingStreet)
            profile.addressOne = profile.shippingStreet;

        if (profile.shippingApartment)
            profile.addressTwo = profile.shippingApartment;

        if (profile.shippingCity)
            profile.city = profile.shippingCity;

        if (profile.shippingState)
            profile.state = profile.shippingState;

        if (profile.shippingZip)
            profile.zipCode = profile.shippingZip;

        if (profile.shippingCountry)
            profile.country = profile.shippingCountry;

        profile.termsAccept = this.state.isAcceptedTermsAndConditions;

        return profile;
    }

    renderedStepInHeader(number, step) {
        const completed = this.state.lastCompletedStep >= (number-2);  // that is stepIndex -1
        const numberStyle = {}
        let elementClass = "";
        const selected = (step === this.steps[this.state.stepIndex]);
        if (selected) {
            numberStyle.borderColor = '#FFF';
            numberStyle.color = '#FFF';
        } else if (!completed) {
            elementClass = "muted"
        }

        const self = this;
        const clickHandler = function(){
            if (selected || !completed) {
                return;
            }
            self.navigateToStep(number-1);
        }

        return (<div key={number} className={elementClass} onClick={clickHandler} style={{color: numberStyle.color}}>
            <div className="number" style={numberStyle}>{ number }</div>
            { this.stepTitles[step] }
        </div>);
    }


    continue() {
        if (this.state.stepIndex < this.steps.length-1) {
            const newIndex = this.state.stepIndex + 1;
            this.setState({
                stepIndex : newIndex,
            })
        } else {
            this.setState({ proceedToProfile : true})
        }
    }

    componentDidMount() {
        if (!this.props.lookupTablesLoading  && !this.props.lookupTables) {
            this.props.getLookupTables();
        }
        ProfileHelper.getCurrentLoginUserProfile().then(profile=> {
            this.setState({
                profile,
                networks : profile.userNetworks,
                address : {
                    formattedAddress : profile.formattedAddress
                }
            }, this.checkCompletion)
            this.props.storeProfile(profile);
        });
    }

    renderFullName(){
        const {firstname, lastname} = this.state.profile;
        return `${firstname} ${lastname}`
    }

    setRatesOk = (flag) => {
        this.setState({rateRangesOk : flag})
    }

    setProfileStepValidStatus = (flag, completion) => {
        this.setState({
            isProfileStepOk: flag
        }, () => {
            if (completion) {
                this.checkCompletion()
            }
        })
    }

    render () {
        if (!this.props.lookupTables || !this.state.profile) {
            return (
                <PleaseWaitWhite  show={!this.props.lookupTables || !this.state.profile}/>
            )
        }

        if (this.state.proceedToProfile) {
            return <Redirect to='/profile' />
        }

        const self = this;

        const step = this.steps[this.state.stepIndex];
        let continueClass = 'continueButton controlButton';
        if (this.state.lastCompletedStep >= this.state.stepIndex) {
            continueClass += " showing";
        }

        const stepsInHeader = [];
        let stepNumber = 1;
        for (const currStep of this.steps) {
            stepsInHeader.push( this.renderedStepInHeader(stepNumber, currStep ));
            stepNumber++;
        }
        const continueText = (this.state.stepIndex < this.steps.length - 1) ? "Continue" : "Finish";

        return (
            <div className="onBoard">
                <div id="modalForBlog" />
                <div className="top1">
                    <img src="/images/Koalifyed-Logo.png" alt="logo"/>
                    <div className="onBoard-profile">
                        {this.renderFullName()}
                    </div>
                </div>
                <div className="top2">
                    <div>
                        { stepsInHeader }
                    </div>
                </div>
                <div className="onBoardStep">
                    <div ref={ (e) => {this.scrollContainer = e}}>
                        {(step === 'Networks') &&
                            <NetworksStep
                                continueFunction={this.setProfile}
                                profile={this.state.profile}
                                networks={this.state.networks}
                                changeFunction={this.updateNetworks}
                            />
                        }
                        {(step === 'Rates') &&
                            <RatesStep
                                profile={this.state.profile}
                                networks={this.state.networks}
                                continueFunction={this.setProfile}
                                changeFunction={this.checkCompletion}
                                alternativeRateInput
                                setRatesOk={this.setRatesOk}
                                isRatesValid={this.state.ratesValid}
                                minimumRate={this.state.minimumRate}
                            />
                        }
                        {(step === 'Profile') &&
                            <ProfileStep
                                continueFunction={this.setProfile}
                                setProfileStepValidStatus={this.setProfileStepValidStatus}
                                profile={this.state.profile}
                                address={this.state.address}
                                changeTermsAndConditionsState={(newTermsAndConditionsState) =>
                                    this.setState({ isAcceptedTermsAndConditions: newTermsAndConditionsState }, this.checkCompletion)
                                }
                                photoVersion = {this.state.photoVersion}
                                scrollContainer={this.scrollContainer}
                            />
                        }

                        <div
                            className={continueClass}
                            onClick={this.continue}
                        >
                            {continueText}
                        </div>
                    </div>
                </div>


            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getLookupTables: () => {
            dispatch(startLoadingLookupTables())
        },
        storeProfile: (profile) => {
            dispatch(storeUserProfile(profile))
        }
    }
};

const mapStateToProps= (state) => {
    return{
        lookupTablesLoading : state.global.lookupTablesLoading,
        lookupTables : state.global.lookupTables,
        photoVersion : state.global.profilePictureVersion
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(OnBoard)
