import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import BreakOutOfDom from '../modalWithSingleAction/BreakOutOfDom';

import './errorMessageBox.scss';

// props :
// - messageId
// - messageValues
// - closeFunction
// - show
// - interval defaults to 5000

export default class ErrorMessageBox extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            show: false,
            timeout: null,
        };
    }

    close = () => {
        if (typeof this.props.closeFunction === 'function') {
            this.props.closeFunction();
        }
    };

    componentWillUnmount() {
        clearTimeout(this.state.timeout);
    }

    static getDerivedStateFromProps(props, state) {
        let newState = {};
        if (props.show && !state.show) {
            clearTimeout(state.timeout);
            let interval = 5000;
            if (props.interval && !isNaN(props.interval)) {
                interval = Number(props.interval);
            }
            let timeout = setTimeout(props.closeFunction, interval);
            newState.timeout = timeout;
        }
        newState.show = props.show;
        return newState;
    }

    render() {
        const { show } = this.state;
        const { messageValues, messageId, closeFunction, apiMsg } = this.props;
        if (!show) {
            return null;
        }
        let values = {};
        if (typeof messageValues === 'object') {
            values = messageValues;
        }

        return (
            <BreakOutOfDom>
                <div className="errorMessageBox active" onClick={closeFunction}>
                    <div>
                        <img
                            src="/images/ic-error.svg"
                            style={{ height: 40, width: 40, cursor: 'pointer' }}
                            onClick={this.close}
                        />
                        {apiMsg ? <div>{apiMsg}</div> : <FormattedMessage id={messageId} values={values} />}
                    </div>
                </div>
            </BreakOutOfDom>
        );
    }
}

ErrorMessageBox.propTypes = {
    apiMsg: PropTypes.string,
    messageId: PropTypes.string,
    messageValues: PropTypes.shape(),
    closeFunction: PropTypes.func,
};
