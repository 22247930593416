import { fork, put } from 'redux-saga/effects';
import influencersSaga from './influencersSaga';
import assignmentInfoSaga from '../../../general/sagas/assignmentInfoSaga';
import {
    clearAll,
    setData,
} from '../actionCreators';

/*
    This saga is called after influencers were added to the campaign.
    It will:
    * show success message
    * clear invite lists
    * request updated assignmentInfo and influencers data
 */
export default function* afterAddToCampaignSaga(data) {
    if (data && data.showInviteSent) {
        yield put(setData({
            // Invite popup will be closed in a different place - just show success message.
            showInviteSuccess: true,
        }));
    }
    yield put(clearAll());
    // also - update the data
    yield fork(assignmentInfoSaga);
    yield fork(influencersSaga);
}
