/**
 simply spinner icon usage
 import Spinner from './spinner';
 <Spinner label={'Loading'}/>
 */
import React from 'react';
import styleSpinner from './spinner.module.scss';
import classNames from "classnames/bind"
const cx = classNames.bind(styleSpinner);

const Spinner = ({label, className, progressPercentage, onCancel}) => {
    return <div className={cx(styleSpinner.loader, className)}>
        <div className={styleSpinner.spinner}><div>a</div></div>
        <div>{label}</div>
        {progressPercentage && <div>{progressPercentage + " %"}</div>}
        {onCancel && <div className={styleSpinner.spinnerCancel} onClick={onCancel}>CANCEL</div>}
    </div>;
};

export default Spinner;
