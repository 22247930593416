export const BLOG_POST = 'BLOG_POST';
export const FACEBOOK_POST = 'FACEBOOK_POST';
export const GENERAL = 'GENERAL';
export const INSTAGRAM_POST = 'INSTAGRAM_POST';
export const INSTAGRAM_STORY = 'INSTAGRAM_STORY';
export const SOCIAL_SHARE = 'SOCIAL_SHARE';
export const TWITTER_POST = 'TWITTER_POST';
export const TIKTOK_POST = 'TIKTOK_POST';
export const YOUTUBE = 'YOUTUBE';

// influencer profile active rates tab options
export const PER_CHANNEL = 'perChannel';
export const PER_POST = 'perPost';
export const ACTIVE_TAB = 'ACTIVE_TAB';
