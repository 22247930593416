import { combineReducers } from 'redux';
import campaignReducer from './campaign/reducers/campaignReducer';
import filterReducer from './filter/reducers/reducer';
import agentReducer from './agent/reducers/reducer';
import chatReducer from './chat/reducers/chatReducer';
import globalReducer from './global/globalReducer';
import modalsReducer from './modals/modalsReducer';
import campaign from './campaign/newCampaignReducer';
import {
    favoriteReducer,
    name as favoriteReducerName,
} from './reducers/favoriteReducer';
import {
    exclusionReducer,
    name as exclusionReducerName,
} from './reducers/exclusionReducer';
import selects from './selects/reducer';
import general from './general/reducer';

export default combineReducers({
    campaign: campaignReducer,
    campaignReducer: campaign,
    filter: filterReducer,
    agent: agentReducer,
    global: globalReducer,
    [favoriteReducerName]: favoriteReducer,
    [exclusionReducerName]: exclusionReducer,
    chat: chatReducer,
    modals: modalsReducer,
    selects,
    general,
});
