const messagesEn = {
    splash: {
        summaryLine: 'Authentic Content Delivered to An Engaged Audience',
    },

    influencerProfile: {
        oneConnectionErrorMessage: 'You deleted a connected network. Please connect another network to continue.',
        noConnectionErrorMessage:
            'You deleted your connected networks. Please connect at least one networks to continue.',
        failedToConnect: 'Unable to connect this network. Please try again.'
    },

    onBoard: {
        selectCategories: 'Please select at least 2 categories',
    },

    opportunityView: {
        needShippingInfoTitle: 'Need Shipping Info!',
        needShippingInfoMessage: 'Enter your full shipping address in Account Settings before proceeding.',
        needTemplateTitle: 'Oops',
        needTemplateMessage: 'No Contract Template was assigned by the Agent',
        needTemplateMappingTitle: 'Oops',
        needTemplateMappingMessage: 'No Contract Template Mapping was assigned by the Agent',
        acceptSuccessTitle: 'Success',
        acceptSuccessMessage: 'You have accepted this assignment. Please sign your contract.',
        acceptDatesSuccessMessage: 'You have accepted dates.',
        contractSigningDeclinedTitle: 'Oh No',
        contractSigningDeclinedMessage: 'You have declined to sign the contract.',
        insufficientPrivilegesMessage: 'You do not have necessary privileges for that action.',
        contractSigningGenericErrorTitle: 'Error!',
        contractSigningGenericErrorMsg: 'Please refresh or try again later.',
        contractSuccessTitle: 'Congratulations!',
        contractSuccessMessage:
            "We can't wait to start working with you on this assignment. \n " +
            'Please select your draft and publish dates so we know when we can expect your content.',
        needPostAnalyticsTitle: "You're almost done!",
        needPostAnalyticsMsg:
            "In order to verify your posts success we ask that you upload a " +
            "screenshot of your posts's analytics 3 days after its gone live. \n\n" +
            "Don't worry - we'll send you an email reminder. Thanks! Koalifyed Team",
    },

    campaign: {
        noPermissionMessage: 'User must be added to this Campaign in order to view its details',
        noPermissionTitle: 'User Error!',
    },

    posts: {
        noSubscriptionMessage: 'All subscriptions should be in completed status',
        noSubscriptionTitle: 'Error!',
    },

    fileUpload: {
        fileSizeErrorMessage: 'Error! File is too large. Please upload a file under {maxFileSize}',
        fileTypeErrorMessage: 'Wrong file type. Allowed files: {allowedExtensions}',
    },

    OK: 'OK',
    continue: 'Continue',
    done: 'Done',
    errorExclamation: 'Error!',
};

export default messagesEn;
