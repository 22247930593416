import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import Select from 'react-select';
import FileSaver from 'file-saver';
import { CSVLink } from 'react-csv';
import { pdf } from '@react-pdf/renderer';
import { useCurrentPng } from 'recharts-to-png';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, LabelList } from 'recharts';
import { default as TextTooltip } from '../../../shared/tooltip/Tooltip';
import ReachIcon from '../../../shared/ReachIcon/ReachIcon';
import { formatBigNumber, formatPercentageToWhole } from '../../../../modules/utils/helpers';
import { OverallPerceptionCard } from '../AnalyticsBySentiment/AnalyticsBySentiment';
import PendoHelper from '../../../../modules/utils/PendoHelper';
import { OverviewReport } from '../PDFReport';
import './analyticsOverview.scss';
import { getNetworkByChannel, getNetworkNameByChannel, getBrandedEngagements } from '../Analytics.helper';
import { channel } from 'redux-saga';

const headers = [
    { label: 'Campaign', key: 'campaign' },
    { label: 'Assignment', key: 'assignments' },
    { label: 'Date', key: 'date' },

    { label: 'Cost Per Engagement', key: 'costPerEngagement' },
    { label: 'Engagement', key: 'engagements' },
    { label: 'Engagement Rate', key: 'engagementRate' },

    { label: 'Total Cost', key: 'totalCost' },
    { label: 'Followers', key: 'followers' },
    { label: 'View', key: 'views' },
    { label: 'View Rate', key: 'viewRate' },
    { label: 'Engagement Rate by Views', key: 'engagementRateByViews' },

    { label: 'Creator Assigned', key: 'creatorAssigned' },
    { label: 'Posts Published', key: 'postsAssigned' },

    { label: 'Channel Summary - IG - Followers', key: 'channelSummaryIGFollowers' },
    { label: 'Channel Summary - IG - Views', key: 'channelSummaryIGViews' },
    { label: 'Channel Summary - TT - Followers', key: 'channelSummaryTTFollowers' },
    { label: 'Channel Summary - TT - Views', key: 'channelSummaryTTViews' },
    { label: 'Channel Summary - YT - Followers', key: 'channelSummaryYTFollowers' },
    { label: 'Channel Summary - YT - Views', key: 'channelSummaryYTViews' },

    { label: 'Overall Perception - Total Mentions', key: 'overallPerceptionTotalMentions' },
    { label: 'Overall Perception - Positives', key: 'overallPerceptionPositives' },
    { label: 'Overall Perception - Neutral', key: 'overallPerceptionNeutral' },
    { label: 'Overall Perception - Negative', key: 'overallPerceptionNegative' },

    { label: 'Top Post Views', key: 'topPostViews' },
    { label: 'Top Post Engagements', key: 'topPostEngagements' },
    { label: 'Saves', key: 'saves' },
    { label: 'Shares', key: 'shares' },
    { label: 'Branded Comments', key: 'brandedComments' },
    { label: 'Branded Engagements', key: 'brandedEngagements' },
];

const channelNames = {
    INSTAGRAM: 'IG Post',
    TIKTOK: 'TikTok',
    YOUTUBE: 'YouTube',
};

const defaultExportOptions = [
    { label: '.csv', value: 'csv' },
    { label: '.pdf', value: 'pdf' },
];

const defaultDataByPost = {
    topReach: null,
    topViews: null,
    topEngagements: null,
    posts: [],
};

function AnalyticsOverview({
    campaignInfo,
    dataByChannel,
    overviewData,
    summaryData,
    analysisData,
    dataByPost,
    startDate,
    endDate,
    selectTab,
}) {
    const [loadingPDF, setLoadingPDF] = useState(false);
    const [getPng, { ref: channelSummaryChartRef }] = useCurrentPng();
    const [getPng2, { ref: cardRef }] = useCurrentPng();
    const { topReach, topViews, topEngagements, topShares, topSaves } = dataByPost;
    const brandedCommentCount =
        analysisData?.productRelatedPerception?.neutralCount + analysisData?.productRelatedPerception?.positiveCount;
    const assignmentSummaryData = {
        costPerEngagement: summaryData.costPerEngagement,
        engagements: summaryData.engagements,
        engagementRate: summaryData.engagementRate,
        assignedPosts: summaryData.influencersAssigned,
        publishedPosts: summaryData.influencersPosted,
        totalCost: summaryData.totalCost,
        potentialSavings: overviewData.potentialSavings,
        followers: overviewData.followers,
        views: summaryData.views,
        totalFollowers: summaryData.totalFollowerCount,
        shares: summaryData.totalShareCount,
        saves: summaryData.totalSaveCount,
        brandedComments: brandedCommentCount,
        brandedEngagements: brandedCommentCount + summaryData.totalShareCount + summaryData.totalSaveCount,
    };
    const IGFollowers =
        dataByChannel && dataByChannel.summary && dataByChannel.summary.instagram
            ? dataByChannel.summary.instagram.reach
            : 0;
    const IGViews =
        dataByChannel && dataByChannel.summary && dataByChannel.summary.instagram
            ? dataByChannel.summary.instagram.impressions
            : 0;

    const TIKTOKFollowers =
        dataByChannel && dataByChannel.summary && dataByChannel.summary.tiktok ? dataByChannel.summary.tiktok.reach : 0;
    const TIKTOKViews =
        dataByChannel && dataByChannel.summary && dataByChannel.summary.tiktok
            ? dataByChannel.summary.tiktok.impressions
            : 0;

    const YOUTUBEFollowers =
        dataByChannel && dataByChannel.summary && dataByChannel.summary.youtube
            ? dataByChannel.summary.youtube.reach
            : 0;
    const YOUTUBEViews =
        dataByChannel && dataByChannel.summary && dataByChannel.summary.youtube
            ? dataByChannel.summary.youtube.impressions
            : 0;

    const channelSummaryData = [
        {
            channel: 'IG Post',
            followers: IGFollowers,
            views: IGViews,
            viewRate: IGFollowers && IGViews ? formatPercentageToWhole((IGViews / IGFollowers) * 100) : 0,
        },
        {
            channel: 'TikTok',
            followers: TIKTOKFollowers,
            views: TIKTOKViews,
            viewRate:
                TIKTOKFollowers && TIKTOKViews ? formatPercentageToWhole((TIKTOKViews / TIKTOKFollowers) * 100) : 0,
        },
        {
            channel: 'YouTube',
            followers: YOUTUBEFollowers,
            views: YOUTUBEViews,
            viewRate:
                YOUTUBEFollowers && YOUTUBEViews ? formatPercentageToWhole((YOUTUBEViews / YOUTUBEFollowers) * 100) : 0,
        },
    ];

    const topPostsData = overviewData.topPosts || [];
    const topCommentsData = overviewData.topComments || [];
    const sentimentData = overviewData.sentiment;

    const CSVDownload = (props) => {
        const btnRef = useRef(null);
        useEffect(() => btnRef.current?.click(), [btnRef]);
        return (
            <CSVLink {...props}>
                <span ref={btnRef} />
            </CSVLink>
        );
    };

    const exportCSV = () => {
        let topPostViewsStr = '';
        let topPostEngagementsStr = '';

        if (topPostsData.length > 0) {
            for (let i = 0; i < topPostsData.length; i++) {
                topPostViewsStr += topPostsData[i].views + (i != topPostsData.length - 1 ? ',' : '');
                topPostEngagementsStr += topPostsData[i].postUrl + (i != topPostsData.length - 1 ? ',' : '');
            }
        }

        let channelSummaryIGFollowersStr = '';
        let channelSummaryIGViewsStr = '';
        let channelSummaryIGViewRateStr = '';
        let channelSummaryTTFollowersStr = '';
        let channelSummaryTTViewsStr = '';
        let channelSummaryTTViewRateStr = '';
        let channelSummaryYTFollowersStr = '';
        let channelSummaryYTViewsStr = '';
        let channelSummaryYTViewRateStr = '';

        if (channelSummaryData.length > 0) {
            for (let i = 0; i < channelSummaryData.length; i++) {
                if (channelSummaryData[i].channel == 'IG Post') {
                    channelSummaryIGFollowersStr = channelSummaryData[i].followers;
                    channelSummaryIGViewsStr = channelSummaryData[i].views;
                    channelSummaryIGViewRateStr = channelSummaryData[i].viewRate;
                }

                if (channelSummaryData[i].channel == 'TikTok') {
                    channelSummaryTTFollowersStr = channelSummaryData[i].followers;
                    channelSummaryTTViewsStr = channelSummaryData[i].views;
                    channelSummaryTTViewRateStr = channelSummaryData[i].viewRate;
                }

                if (channelSummaryData[i].channel == 'YouTube') {
                    channelSummaryYTFollowersStr = channelSummaryData[i].followers;
                    channelSummaryYTViewsStr = channelSummaryData[i].views;
                    channelSummaryYTViewRateStr = channelSummaryData[i].viewRate;
                }
            }
        }

        let overallPerceptionTotalMentionsStr =
            sentimentData.neutralCount + sentimentData.positiveCount + sentimentData.negativeCount;

        const csvData = [
            {
                campaign: `${campaignInfo.campaignName}`,

                assignments: `${campaignInfo.assignments}`,
                date: `${startDate} - ${endDate}`,

                costPerEngagement: `$${summaryData.costPerEngagement || 0}`,
                engagements: `${summaryData.engagements || 0}`,
                engagementRate: `${summaryData.engagementRate || 0}%`,
                totalCost: `$${summaryData.totalCost || 0}`,
                followers: `${summaryData.totalFollowerCount || 0}`,
                views: `${summaryData.views || 0}`,
                viewRate: `${formatPercentageToWhole((summaryData.views / summaryData.totalFollowerCount) * 100)}%`,
                creatorAssigned: `${summaryData.influencersAssigned || 0}`,
                postsAssigned: `${summaryData.influencersPosted || 0}`,

                channelSummaryIGFollowers: `${channelSummaryIGFollowersStr || 0}`,
                channelSummaryIGViews: `${channelSummaryIGViewsStr || 0}`,
                channelSummaryTTFollowers: `${channelSummaryTTFollowersStr || 0}`,
                channelSummaryTTViews: `${channelSummaryTTViewsStr || 0}`,
                channelSummaryYTFollowers: `${channelSummaryYTFollowersStr || 0}`,
                channelSummaryYTViews: `${channelSummaryYTViewsStr || 0}`,

                overallPerceptionTotalMentions: `${overallPerceptionTotalMentionsStr || 0}`,
                overallPerceptionPositives: `${sentimentData.positiveCount || 0}`,
                overallPerceptionNeutral: `${sentimentData.neutralCount || 0}`,
                overallPerceptionNegative: `${sentimentData.negativeCount || 0}`,

                topPostViews: `${summaryData.views || 0}`,
                topPostEngagements: `${summaryData.engagements || 0}`,
                engagementRateByViews: `${formatPercentageToWhole((summaryData.engagements / summaryData.views) * 100)}%`,
                saves: `${summaryData.totalSaveCount || 0}`,
                shares: `${summaryData.totalShareCount || 0}`,
                brandedComments: brandedCommentCount,
                brandedEngagements: brandedCommentCount + summaryData.totalShareCount + summaryData.totalSaveCount,
            },
        ];
        const link = (
            <CSVDownload
                data={csvData}
                target="_blank"
                headers={headers}
                filename={`Overview-${startDate}-${endDate}.csv`}
            />
        );

        const elem = document.createElement('div');
        elem.id = 'export-report-container';
        document.getElementById('root').appendChild(elem);
        ReactDOM.render(link, elem);

        if (startDate && endDate) {
            PendoHelper.pendoTrack('Campaign_Assignment_Analytics_ExportCSV', {});
        }
    };
    const overallPerceptionData = [
        {
            category: 'Positive',
            value: sentimentData?.positiveCount || 0,
            color: '#75d551',
        },
        {
            category: 'Negative',
            value: sentimentData?.negativeCount || 0,
            color: '#fd625e',
        },
        {
            category: 'Neutral',
            value: sentimentData?.neutralCount || 0,
            color: '#ffbe16',
        },
    ];

    const totalPerception = overallPerceptionData.reduce((prev, curr) => (prev += curr.value), 0) || 0;
    const handleExportSelect = (option) => {
        if (option.value === 'csv') {
            exportCSV();
        } else if (option.value === 'pdf') {
            exportPDF({
                loadingPDF,
                setLoadingPDF,
                channelSummaryChartRef,
                getPng,
                summaryData,
                campaignInfo,
                startDate,
                endDate,
                getPng2,
                dataByPost,
                assignmentSummaryData,
                overviewData,
                totalPerception,
            });
        }
    };
    return (
        <div className="analytics-overview">
            <div className="section-title-line">
                <div className="section-title">Overview</div>
                <div className="export-section">
                    {campaignInfo.currency && (
                        <div className="currency">
                            <span className="currency-label">Currency:</span>
                            <span className="currency-value">{` ${campaignInfo.currency?.code} (${campaignInfo.currency?.symbol})`}</span>
                        </div>
                    )}
                    <Select
                        className="export-select"
                        searchable={false}
                        value={{ label: 'Export' }}
                        single
                        options={defaultExportOptions}
                        onChange={handleExportSelect}
                        clearable={false}
                    />
                </div>
            </div>
            <AssignmentSummary data={assignmentSummaryData} currencyCode={campaignInfo.currency?.code} />
            <div className="channel-summary-wrapper">
                <ChannelSummary
                    chartRef={channelSummaryChartRef}
                    data={channelSummaryData}
                    openByChannelTab={() => selectTab(2)}
                />
                <OverallPerceptionCard cardRef={cardRef} overallData={sentimentData} handleLink={() => selectTab(5)} />
            </div>
            <TopPosts
                topPostsData={topPostsData}
                topCommentsData={topCommentsData}
                totalViews={topViews}
                openByPostTab={() => selectTab(4)}
                totalEngagements={summaryData.engagements}
                topEngagements={topEngagements}
                topShares={topShares}
                topSaves={topSaves}
                topReach={topReach}
            />
        </div>
    );
}

function AssignmentSummary({ data, currencyCode }) {
    return (
        <div className="assignment-summary">
            <div className="card-title">
                <div>Assignment Summary</div>
            </div>
            <div className="card-content">
                <div className="data-rows">
                    <div className="left-side">
                        <AssignmentSummaryRow
                            title="Cost Per Engagement"
                            tooltipText="Total cost of assignments divided by the total number of engagements (likes, comments, shares and saves)."
                            data={`$${new Intl.NumberFormat({ currency: currencyCode || 'CAD' }).format(data.costPerEngagement || 0)}`}
                        />
                        <AssignmentSummaryRow
                            title="Engagement"
                            tooltipText="Sum of Instagram likes and comments, TikTok likes, comments, shares and saves, and YouTube likes and comments for selected assignments"
                            data={data.engagements ? formatBigNumber(data.engagements) : '-'}
                        />
                        <AssignmentSummaryRow
                            title="Engagement Rate"
                            tooltipText="Engagements divided by number of followers for selected assignments. It is calculated based on the engagements (likes, comments, shares and saves) gathered within the selected date range."
                            data={data.engagementRate ? `${formatPercentageToWhole(data.engagementRate)}%` : '-'}
                        />
                        <AssignmentSummaryRow
                            title="Total Cost"
                            tooltipText="Total cost of selected assignments"
                            data={`$${new Intl.NumberFormat({ currency: currencyCode || 'CAD' }).format(data.totalCost || 0)}`}
                        />
                        <AssignmentSummaryRow
                            title="Creators Assigned / Published Posts"
                            tooltipText="Number of creators associated with selected assignments / number of posts published by these creators"
                            data={`${data.assignedPosts ?? '-'} / ${data.publishedPosts ?? '-'}`}
                        />
                    </div>
                    <div className="middle-side">
                        <AssignmentSummaryRow
                            title="Followers"
                            tooltipText="Number of followers for selected assignments"
                            data={data.totalFollowers ? formatBigNumber(data.totalFollowers) : '-'}
                        />
                        <AssignmentSummaryRow
                            title="Views"
                            tooltipText="Sum of Instagram views for reels / videos, TikTok plays, and YouTube views for selected assignments"
                            data={data.views ? formatBigNumber(data.views) : '-'}
                        />
                        <AssignmentSummaryRow
                            title="View Rate"
                            tooltipText="The percentage of plays for Instagram reels and views on TikTok and YouTube content by total number of followers of the creators for selected assignment."
                            data={
                                data.views && data.totalFollowers
                                    ? `${formatPercentageToWhole((data.views / data.totalFollowers) * 100)}%`
                                    : '-'
                            }
                        />
                        <AssignmentSummaryRow
                            title="Engagement Rate by Views"
                            tooltipText="Engagements(likes, comments, shares and saves) divided by views."
                            data={
                                data.engagements && data.views
                                    ? `${formatPercentageToWhole((data.engagements / data.views) * 100)}%`
                                    : '-'
                            }
                        />
                    </div>
                    <div className="right-side">
                        <AssignmentSummaryRow
                            title="Shares"
                            tooltipText="Total Shares of selected assignments"
                            data={data.shares ? formatBigNumber(data.shares) : '-'}
                        />
                        <AssignmentSummaryRow
                            title="Saves"
                            tooltipText="Number of Saves for selected assignments"
                            data={data.saves ? formatBigNumber(data.saves) : '-'}
                        />
                        <AssignmentSummaryRow
                            title="Branded Comments"
                            tooltipText="Count of positive and neutral comments from Overall Perception (comments relevant to the campaign category and/or products)"
                            data={data.brandedComments ? formatBigNumber(data.brandedComments) : '-'}
                        />
                        <AssignmentSummaryRow
                            title="Branded Engagements"
                            tooltipText="Branded comments + Shares + Saves"
                            data={data.brandedEngagements ? formatBigNumber(data.brandedEngagements) : '-'}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

function AssignmentSummaryRow({ title, tooltipText, data }) {
    return (
        <div className="data-row">
            <span className="data-row-label">{title}</span>
            <span className="label-tooltip">
                {tooltipText && <TextTooltip tooltipText={tooltipText} image="/images/tooltipUser.svg" />}
            </span>
            <span className="data-row-value">{data}</span>
        </div>
    );
}

function ChannelSummary({ data, chartRef, openByChannelTab }) {
    const renderLegendText = (value) => {
        return <span style={{ color: 'black', textTransform: 'capitalize' }}>{value}</span>;
    };

    return (
        <div className="channel-summary">
            <div className="card-title">
                <div>Channel Summary</div>
                <div className="view-all" onClick={openByChannelTab}>
                    View All
                </div>
            </div>
            <div className="card-content">
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        width={500}
                        height={300}
                        data={data}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                        ref={chartRef}
                    >
                        <CartesianGrid vertical={false} />
                        <XAxis dataKey="channel" axisLine={false} tickLine={false} />
                        <YAxis
                            axisLine={false}
                            tickLine={false}
                            tickFormatter={(val) => formatBigNumber(val)}
                            fill="#999999"
                        />
                        <Legend verticalAlign="top" height={36} align="right" formatter={renderLegendText} />
                        <Bar dataKey="followers" fill="#72C255" barSize={36}>
                            <LabelList
                                dataKey="followers"
                                position="top"
                                formatter={(val) => formatBigNumber(val)}
                                style={{ fontSize: 14 }}
                                fill="#999999"
                            />
                        </Bar>
                        <Bar dataKey="views" fill="#00BBAB" barSize={36}>
                            <LabelList
                                dataKey="views"
                                position="top"
                                formatter={(val) => formatBigNumber(val)}
                                style={{ fontSize: 14 }}
                                fill="#999999"
                            />
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
}

function TopPosts({
    topPostsData,
    topCommentsData,
    totalViews,
    totalEngagements,
    openByPostTab,
    topEngagements,
    topShares,
    topSaves,
    topReach,
}) {
    return (
        <div className="top-posts">
            <div className="section-title">
                <span className="title">Top Posts</span>
                <div className="view-all" onClick={openByPostTab}>
                    View All
                </div>
            </div>
            <div className="cards">
                <div className="top-posts-card">
                    <div className="card-title">
                        <img src="/images/ic_eye_a8.svg" />
                        Views
                        <span className="card-value">{totalViews ? formatBigNumber(totalViews?.views) : '-'}</span>
                    </div>
                    <div className="card-content">
                        {!!totalViews ? (
                            <div className="post-row" key={totalViews.url}>
                                <ReachIcon network={totalViews.channel} value={1} size={14} noToolTip />
                                <div className="channel-name">{channelNames[totalViews.channel]}</div>
                                <div className="post-author-label">
                                    By:{` `}
                                    <span className="post-author">{totalViews.influencerName}</span>
                                </div>
                                <a href={totalViews.url} className="view-post-link" target="_blank">
                                    View post
                                </a>
                            </div>
                        ) : (
                            <div className="empty-label">No posts to show.</div>
                        )}
                    </div>
                </div>
                <div className="top-posts-card">
                    <div className="card-title">
                        <img src="/images/ic-thread-d.svg" />
                        Engagement
                        <span className="card-value">
                            {topEngagements ? formatBigNumber(topEngagements.engagement) : '-'}
                        </span>
                    </div>
                    <div className="topPostCardLower">
                        {topEngagements ? (
                            <>
                                <div className="topPostCardLowerText">
                                    <div className="topPostCardLowerFirst">
                                        {topEngagements.channel && (
                                            <ReachIcon
                                                network={getNetworkByChannel(topEngagements.channel)}
                                                value={1}
                                                size={8}
                                                padding={4}
                                                noToolTip
                                            />
                                        )}
                                        <span>
                                            {getNetworkNameByChannel(topEngagements.channel)}
                                            {topEngagements.postType && `(${topEngagements.postType.toLowerCase()})`}
                                        </span>
                                        <div className="topPostCardSeparator" />
                                        <div>
                                            <span>By:&nbsp;</span>
                                            {topEngagements.influencerName}
                                        </div>
                                        <div className="topPostCardSeparator" />
                                        <a className="topPostCardLink" href={topEngagements.url} target="_blank">
                                            View post
                                        </a>
                                    </div>
                                    <div>{topEngagements.name}</div>
                                </div>
                            </>
                        ) : (
                            <div className="topPostCardLowerText">
                                <div>No post yet!</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

async function exportPDF({
    summaryData,
    overviewData,
    loadingPDF,
    setLoadingPDF,
    getPng,
    campaignInfo,
    dataByPost,
    startDate,
    endDate,
    getPng2,
    assignmentSummaryData,
    totalPerception,
}) {
    if (loadingPDF) return;

    setLoadingPDF(true);
    const channelSummaryChartImg = await getPng();
    const pieChart = await getPng2();
    const pdfContent = (
        <OverviewReport
            overviewData={summaryData}
            overviewDataPost={overviewData}
            assignmentSummaryData={assignmentSummaryData}
            info={campaignInfo}
            channel={{ img: channelSummaryChartImg }}
            influencer={{ img: channelSummaryChartImg }}
            post={dataByPost || defaultDataByPost}
            pieimg={{ img: pieChart }}
            totalPerception={totalPerception}
        />
    );

    await createAndDownloadPDF(pdfContent, startDate, endDate);
    setLoadingPDF(false);
}

async function createAndDownloadPDF(pdfContent, startDate, endDate) {
    const blob = await pdf(pdfContent).toBlob();
    FileSaver.saveAs(blob, `Overview-${startDate}-${endDate}.pdf`);
}

export default AnalyticsOverview;
