import React, { Component } from 'react';
import get from 'lodash/get';
import head from 'lodash/head';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import Api from '../../../../modules/Api';

import constants from './constants';
import utils from '../utils';
import FORM_UTILS from './components/Form/utils';

import Header from './components/Header';
import Footer from './components/Footer';
import BrandLogoSection from './components/BrandLogoSection';
import Form from './components/Form';

import styles from './styles.module.scss';
import ApiError from '../../../shared/apiError/ApiError';
import { competitiveBrandsValidator } from './helpers';
import { LoadingDialogue } from '../../../shared/loadingDialogue/loadingDialogue';

class BrandModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            imagePreview: undefined,
            brandName: '',
            billingContact: '',
            advertisers: undefined,
            // brandOwners: undefined,
            buttonIsClicked: false,
            errors: {
                brandName: undefined,
                advertisers: undefined,
                billingContact: undefined,
                competitiveBrands: undefined
                // brandOwners: undefined,
            },
            isAvatarLoading: false,
            uploadProgressPercentage: null,
            onUploadCancel: null,
            apiError: null,
            countryCode: undefined,
            defaultCurrencyCode: undefined,
            countryList: [],
            competitiveBrands: [],
            currencyList: [],
            accessRights: []
        };
    }

    componentDidMount = async () => {

        const {
            validateEmail,
            validateField,
            validateSelect,
        } = FORM_UTILS;

        const {
            advertiserOptionsFactory,
            brandOwnerOptionsFactory,
            convertAdvertiserOption,
            convertBrandOwnerOption,
        } = utils;

        const {
            type: modalType,
            brand,
            userAdvertisers,
            // userBrandOwners,
        } = this.props;

        const billingContact = get(brand, 'email', '');

        const brandName = get(brand, 'brand', '');

        /** options for advertiser/brand-owner select inputs */
        const advertisersOptions = advertiserOptionsFactory(userAdvertisers);
        // const brandOwnersOptions = brandOwnerOptionsFactory(userBrandOwners);

        /** --- */

        /** set predefined values for advertiser/brand-owner select inputs */

        const PREDEFINED_ADVERTISER = get(brand, 'advertiserOption', {});

        const PREDEFINED_BRAND_OWNER = get(brand, 'agents', []);

        const convertedAdvertiser = convertAdvertiserOption(PREDEFINED_ADVERTISER);

        let convertedBrandOwner = [];
        let competitiveBrands = [];

        PREDEFINED_BRAND_OWNER.forEach((brandOwner) => {
            convertedBrandOwner = [...convertedBrandOwner, convertBrandOwnerOption(brandOwner)];
        });

        const advertisers = modalType === 'create' ? [] : [convertedAdvertiser];


        if(brand?.id) {
            this.setState({ loading: true });

            try {
                competitiveBrands = await Api.getBrandCompetitors(brand.id).then(result => {
                    return result.map(c => ({
                        name: c.socialHandle,
                        error: false,
                        errorMessage: null,
                        valid: true,
                        uid: c.uid
                    }))
                });
            } catch (error) {
                this.setState({ apiError: error, loading: false });
            }
        }

        // const brandOwners = modalType === 'create' ? [] : convertedBrandOwner;

        /** --- */
        Api.getCurrencyList().then(result => {
            const { data } = result;
            const currencyList = []
            data.forEach(x => {
                const currency = {label: `${x.code} (${x.symbol})`, value: x.code, symbol: x.symbol}
                currencyList.push(currency)
            })
            this.setState({currencyList})
        })
        const accessOptions = [{label: "Full Access", value: "fullAccess", items: ["edit:plan","create:plan","delete:plan","view:plan","create:campaign","edit:campaign",
            "view:campaign"]}, 
        {label: "Discovery & Planning", value:"discoveryPlanning", items: ["edit:plan","create:plan","delete:plan","view:plan"]}]
        let foundOption = null
        const accessRights = get(brand, 'accessRights', []);
        accessOptions.forEach(item => {
            const checker1 = item?.items?.every(v => accessRights?.includes(v));
            const checker2 = accessRights?.every(v => item?.items?.includes(v));
            if (checker1 && checker2) {
                foundOption = item.value
            }
        })
        this.setState({
            loading: false,
            billingContact: billingContact || '',
            brandName,
            advertisers,
            imagePreview: get(brand, 'logo', undefined),
            // brandOwners,
            advertisersOptions,
            // brandOwnersOptions,
            errors: {
                brandName: validateField(brandName),
                billingContact: validateEmail(billingContact),
                advertisers: validateSelect(advertisers),
                competitiveBrands: undefined
            },
            countryCode: get(brand, 'countryCode', undefined),
            defaultCurrencyCode: get(brand, 'defaultCurrencyCode', undefined),
            competitiveBrands,
            accessRights: foundOption,
        });
    }

    onHandleFile = async (image) => {
        this.setState({ isAvatarLoading: true });
        const { imageObject } = image;
        try {
            const imageResponse = await Api.uploadResource(
                imageObject,
                true,
                null,
                null,
                (percentage) => this.setState({uploadProgressPercentage: percentage}),
                (fn) => this.setState({onUploadCancel: fn})
            );
            this.setState({
                imagePreview: get(imageResponse, 'url'),
                isAvatarLoading: false,
                uploadProgressPercentage: null,
                onUploadCancel: null
            });
        } catch (error) {
            this.setState({
                isAvatarLoading: false,
                uploadProgressPercentage: null,
                onUploadCancel: null
            });
        }
    };

    onSearchChange = (text) => {
        const {
            brandOwnerOptionsFactory,
        } = utils;
        const fields = {
            sortby: "name",
            order: "asc",
            num: 50
        }
        Api.searchUserList(text, fields).then(response => {
            this.setState({ brandOwnersOptions: brandOwnerOptionsFactory(response.results) })
        })
            .catch(err => {
                this.setState({apiError: err});
            })
    }

    setCountryList = list => {
        list.sort(((a,b) => a.label.localeCompare(b.label)));
        this.setState({countryList: list});
    }

    onSelectChange = ({ target: { name, value } }) => {
        const { countryList } = this.state;
        const {
            validateSelect,
        } = FORM_UTILS;
        this.setState(({ errors }) => ({
            [name]: value,
            errors: {
                ...errors,
                [name]: validateSelect(value),
            },
        }));
        if (name === 'countryCode') {
            const currencyCodeToUse = countryList.find(item => item.value === value)
            this.setState({defaultCurrencyCode: currencyCodeToUse.currencyCode})
        }
    };

    onTextChange = (isEmail) => ({ target: { name, value } }) => {
        const {
            validateField,
            validateEmail,
        } = FORM_UTILS;

        this.setState(({ errors }) => ({
            [name]: value,
            errors: {
                ...errors,
                [name]: isEmail ? validateEmail(value) : validateField(value)
            }
        }));
    };

    validateCompetitiveBrands = async () => {
        try {
            const validation = await competitiveBrandsValidator(
                this.state.competitiveBrands
            );
            this.setState({ competitiveBrands: validation.brands })
            return validation.valid
        } catch (e) {
            this.setState({ apiError: e })
            return false;
        }
    }

    onSubmit = async () => {
        const { validateForm } = FORM_UTILS;

        const {
            onManipulateBrand,
            type,
            brand: { id: brandId },
        } = this.props;

        const { emptyFieldKey, wrongEmailKey } = constants;

        const {
            errors,
            brandName,
            billingContact,
            // brandOwners,
            advertisers,
            imagePreview,
            defaultCurrencyCode,
            countryCode,
            accessRights
        } = this.state;
        this.setState({ buttonIsClicked: true, loading: true });
        const competitiveBrandsValid = await this.validateCompetitiveBrands();
        const errorsValues = Object.values(errors);
        const containsEmptyField = validateForm(emptyFieldKey, errorsValues);
        const containsWrongEmail = validateForm(wrongEmailKey, errorsValues);
        if (containsEmptyField || containsWrongEmail || !competitiveBrandsValid) {
            this.setState({ loading: false })
            return;
        }
        const advertiser = head(advertisers);
        const accessOptions = [{label: "Full Access", value: "fullAccess", items: ["edit:plan","create:plan","delete:plan","view:plan","create:campaign","edit:campaign",
            "view:campaign"]}, 
        {label: "Discovery & Planning", value:"discoveryPlanning", items: ["edit:plan","create:plan","delete:plan","view:plan"]}]
        const access = accessOptions.find(x => x.value === accessRights)?.items
        const data = {
            name: brandName,
            logoUrl: imagePreview || null,
            billingContractEmail: billingContact,
            // agentIds: brandOwners.length > 0 ? brandOwners.map(({ id }) => id) : [],
            advertiserId: get(advertiser, 'id'),
            defaultCurrencyCode,
            countryCode: countryCode || 'US',
            competitorBrands: this.state.competitiveBrands.map(b => ({
                uid: b.uid,
                socialHandle: b.name
            })),
            accessRights: access
        }
        this.setState({ loading: false })
        onManipulateBrand(brandId, data, type);
    };

    onCompetitiveBrandsChange = (brands) => {
        this.setState({
            competitiveBrands: brands
        })
    }

    render() {
        const { headerTitles, submitButtonTitles } = constants;
        const { type, onCloseModal, show, brand, advertiser, role } = this.props;
        const {
            imagePreview,
            brandName,
            billingContact,
            advertisers,
            advertisersOptions,
            errors,
            buttonIsClicked,
            isAvatarLoading,
            apiError,
            countryCode,
            defaultCurrencyCode,
            countryList,
            competitiveBrands,
            currencyList,
            accessRights
        } = this.state;
        if (type === "edit" && role !== "SUPER_ADMIN") {
            const currencyLabel = currencyList.find(currency => currency.value === defaultCurrencyCode)?.label
            return <Modal
                isOpen={show}
                overlayClassName="modalDialog"
                className="dialog">
                <div className="brandOwnerEdit">
                    <ApiError
                        show={apiError}
                        error={apiError}
                        cancelFunction={() => this.setState({apiError: null})}
                    />
                    <div className="header">Edit Brand</div>
                    <div className="brandInfo">
                        <img src={brand?.advertiserOption?.logoUrl} alt="logo" />
                        <div className="nonEditable">
                            <div className="nonEditRow">
                                <div className="left">Brand</div>
                                <div className="right">{brand?.brand}</div>
                            </div>
                            <div className="nonEditRow">
                                <div className="left">Country</div>
                                <div className="right" />
                            </div>
                            <div className="nonEditRow">
                                <div className="left">Currency</div>
                                <div className="right">{currencyLabel}</div>
                            </div>
                            <div className="nonEditRow">
                                <div className="left">Advertiser</div>
                                <div className="right">{advertiser}</div>
                            </div>
                            <div className="nonEditRow">
                                <div className="left">Access Level</div>
                                <div className="right" />
                            </div>
                        </div>
                    </div>
                    <Form
                        buttonIsClicked={buttonIsClicked}
                        errors={errors}
                        brandName={brandName}
                        billingContact={billingContact}
                        advertisers={advertisers}
                        advertisersOptions={advertisersOptions}
                        type={type}
                        onTextChange={this.onTextChange}
                        onSelectChange={this.onSelectChange}
                        onSearchChange={this.onSearchChange}
                        countryCode={countryCode}
                        defaultCurrencyCode={defaultCurrencyCode}
                        countryList={countryList}
                        setCountryList={this.setCountryList}
                        competitiveBrands={competitiveBrands}
                        onCompetitiveBrandsChange={this.onCompetitiveBrandsChange}
                        limited
                        accessRights={accessRights}
                    />
                    <Footer
                        isSubmitDisabled={isAvatarLoading}
                        onSubmit={this.onSubmit}
                        onCancel={onCloseModal}
                        submitButtonTitle={submitButtonTitles[type]}
                    />
                </div>
            </Modal>
        }
        return (
            <>
                <LoadingDialogue show={this.state.loading} />
                <Modal
                    isOpen={show}
                    overlayClassName="modalDialog"
                    className="dialog"
                >
                    <div
                    >
                        <ApiError
                            show={apiError}
                            error={apiError}
                            cancelFunction={() => this.setState({apiError: null})}
                        />
                        <Header title={headerTitles[type]} />
                        <div className="brandForm">
                            <BrandLogoSection
                                isUploading={isAvatarLoading}
                                onChangeFile={this.onHandleFile}
                                imagePreview={imagePreview}
                                brand={brandName}
                                uploadProgressPercentage={this.state.uploadProgressPercentage}
                                onUploadCancel={this.state.onUploadCancel}
                            />
                            <Form
                                buttonIsClicked={buttonIsClicked}
                                errors={errors}
                                brandName={brandName}
                                billingContact={billingContact}
                                advertisers={advertisers}
                                advertisersOptions={advertisersOptions}
                                type={type}
                                onTextChange={this.onTextChange}
                                onSelectChange={this.onSelectChange}
                                onSearchChange={this.onSearchChange}
                                countryCode={countryCode}
                                defaultCurrencyCode={defaultCurrencyCode}
                                countryList={countryList}
                                setCountryList={this.setCountryList}
                                competitiveBrands={competitiveBrands}
                                onCompetitiveBrandsChange={this.onCompetitiveBrandsChange}
                                accessRights={accessRights}
                            />
                        </div>
                        <Footer
                            isSubmitDisabled={isAvatarLoading}
                            onSubmit={this.onSubmit}
                            onCancel={onCloseModal}
                            submitButtonTitle={submitButtonTitles[type]}
                        />
                    </div>
                </Modal>
            </>
        )
    }
}

BrandModal.propTypes = {
    brand: PropTypes.shape({
        id: PropTypes.string,
        advertiser: PropTypes.string,
        email: PropTypes.string,
    }),
    // userBrandOwners: PropTypes.arrayOf(PropTypes.shape({
    //     uid: PropTypes.string,
    //     firstname: PropTypes.string,
    //     lastname: PropTypes.string,
    //     id: PropTypes.number,
    // })),
    userAdvertisers: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
    })),
    onManipulateBrand: PropTypes.func,
    type: PropTypes.string,
    onCloseModal: PropTypes.func,
};

export default BrandModal;
