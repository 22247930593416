import React, { Component } from 'react';
import PropTypes from 'prop-types';
import head from 'lodash/head';

import constants from './constants';

import style from './styles.module.scss';

/** this component can be moved to shared folder, since it is completely reusable for avatar purposes */
class UploadAvatar extends Component {
  onHandleChange = ({ target }) => {
    const { handleSelectedFile } = this.props;
    const selectedFile = head(target.files);
    if (!selectedFile) return;
    const reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onload = () => {
      const { result } = reader;
      const file = { imagePreview: result, imageObject: selectedFile };
      handleSelectedFile(file);
    };
  };

  render() {
    const { ACCEPTED_EXTENSIONS } = constants;

    const { imagePreview, brand } = this.props;

    return (
      <div className={style.inputUploadCover}>
        <label htmlFor="upload-button">
          <div className={style.imageWrapper}>
            {imagePreview ? (
              <img className={style.imagePreview} src={imagePreview} alt="brand_logo" />
            ) : brand && brand.length > 0 ? (
              <div className={style.brandPlaceholderLogo}>{brand[0]}</div>
            ) : (
              <span className={style.fakeImagePreview} />
            )}
            <span className={style.plusButton}>+</span>
          </div>
        </label>
        <input type="file" id="upload-button" className={style.fileUpload} onChange={this.onHandleChange} accept={ACCEPTED_EXTENSIONS} />
        <br />
      </div>
    );
  }
}

UploadAvatar.propTypes = {
  handleSelectedFile: PropTypes.func,
  imagePreview: PropTypes.string,
};

export default UploadAvatar;
