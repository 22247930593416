import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { get , cloneDeep } from 'lodash';
import Format from '../../../modules/utils/Format';

import ProfileUtils from '../../../modules/influencer/ProfileUtils';
import RateInput from '../../oneInfluencer/rateBox/RateInput';
import {
    GENERAL,
    PER_POST,
    ACTIVE_TAB,
} from '../../../constants/rateTypes';
import RatesService from '../../../services/ratesService';
// imports below have cycle dependencies (potential memory leaks)
import Lookup from '../../../modules/Lookup';
import RateIcon from '../../shared/rateIcon/RateIcon';
import ProfileRates from '../../influencers/detailPage/influencerProfileBU/ProfileRates';


// The Rate part of the influencer profile
// Props :
// - profile
// - editMode
// - saveFlag
// - changeFunction  (takes updated Profile object)
// - errorStatusFunction takes a flag to delegate error status to EditablePiece

class ProfileRate extends React.Component {
    constructor(props) {
        super(props);

        this.renderedRateDisplay = this.renderedRateDisplay.bind(this);
        this.processProps = this.processProps.bind(this);
        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
        this.fromProfileToState = this.fromProfileToState.bind(this);
        this.validate = this.validate.bind(this);
        this.perCampaignRateKey = GENERAL;

        this.state = {
            ratesLoaded: false,
        };
    }

    validate() {
        const {
            activeRatesOption,
            profile,
            errorStatusFunction,
        } = this.props;

        const errorMessage = ProfileRate.getMissingMessage(
            profile,
            this.state,
            activeRatesOption,
        );

        this.setState({
            missingRateError : errorMessage
        });

        if (typeof errorStatusFunction === 'function') {
            errorStatusFunction(errorMessage);
        }
    }

    fromStateToProfile = () => {
        const {
            activeRatesOption,
            profile,
        } = this.props;
        const { rates } = profile;
        const rateTypeList = Lookup.getRateTypeList();
        const newRates = rateTypeList.reduce((acc, rate) => {
            acc[rate] = rates[rate];
            acc[rate] = this.state[rate];

            return acc;
        }, {});
        const ratesService = new RatesService();
        const activeTabValue = ratesService.getActiveTabFromActiveRatesOption(activeRatesOption);
        newRates[ACTIVE_TAB] = activeTabValue;

        this.setState({
            rates: newRates,
            [ACTIVE_TAB]: activeTabValue,
        });

        const updatedProfile = cloneDeep(profile);
        updatedProfile.rates = newRates;
        updatedProfile.rates[ACTIVE_TAB] = activeTabValue;

        return updatedProfile;
    }

    static getMissingMessage = (profile, objectWithRates, activeRatesOption) => {
        let retval = null;
        let missingList = '';

        const perPostValue = activeRatesOption === PER_POST;

        if (perPostValue) {
            missingList = objectWithRates[GENERAL] > 0
                ? ''
                : Lookup.getRateType(GENERAL).label;
        } else {
            const allMissing = ProfileUtils.getMissingRatesFromOtherRates(profile, objectWithRates);

            for (const key of Lookup.getRateTypeList()) {
                if (allMissing[key]) {
                    if (missingList.length) {
                        missingList += ', ';
                    }

                    missingList += Lookup.getRateType(key).label;
                }
            }
        }

        if (missingList.length) {
            retval = `Please enter your ${missingList} rate to receive Campaign Invitations.`;
        }

        return retval;
    }

    fromProfileToState(profile, ratesOption) {
        const newState = {
            ratesLoaded : true
        };
        for ( const key of Lookup.getRateTypeList()) {
            newState[key] = profile.rates[key] || '';
        }

        /*
            https://jira.hubub.com/browse/PGSIMPL-731
            missingRateError message is created here before activeRatesOption is initialized.
            so this method is called from componentWillReceiveProps with a valid activeRatesOption param.
         */
        const { activeRatesOption } = this.props;
        const option = ratesOption || activeRatesOption;

        newState.missingRateError = ProfileRate.getMissingMessage(profile, newState, option);
        this.setState(newState);
    }

    processProps(props) {
        if (!this.state.ratesLoaded && props.profile) {
            this.fromProfileToState(props.profile);
        }
    }

    save = () => {
        const {
            changeFunction,
            isTalentManager,
        } = this.props;
        const { rates } = this.state;

        const updatedProfile = this.fromStateToProfile();

        if (typeof changeFunction === 'function') {
            if (isTalentManager) {
                // changeFunction({ rates });
                changeFunction({rates: updatedProfile.rates});
            } else {
                changeFunction(updatedProfile);
            }
        }
    }

    cancel() {
        this.fromProfileToState(this.props.profile)
    }

    handleRateChange = (key, value, errorMessage) => {
        const newState = {};
        const numValue = Number(value);
        newState[key] = numValue;
        if (numValue == 0) {
            newState[`${key  }errorMessage`] = null;
        } else {
            newState[`${key  }errorMessage`] = errorMessage;
        }
        this.setState(newState, this.validate);
    }

    renderedRatesDisplay = () => {
        const { missingRateError } = this.state;
        const { symbol } = this.props;
        const ratesLine1 = [];
        const ratesLine2 = [];
        const maxPerLine = 4;
        let missingList = '';
        const profile = this.getProfile();
        const ratesService = new RatesService(profile);
        const activeRatesOption = ratesService.getInfluencerActiveRatesOption();
        const ratesList = ratesService.filterRatesListByActiveTab();

        for (const key of ratesList) {
            const missing = ProfileUtils.getMissingRates(profile);

            if (missing[key]) {
                if (missingList.length > 0) {
                    missingList += ', ';
                }

                missingList += Lookup.getRateType(key).label;
            }

            if (ratesLine1.length < maxPerLine) {
                ratesLine1.push(this.renderedRateDisplay(
                    key,
                    activeRatesOption === PER_POST,
                ));
            } else {
                ratesLine2.push(this.renderedRateDisplay(key));
            }
        }

        return (
            <div className="rateSection">
                <ProfileRates profile={profile} symbol={symbol} />
            </div>
        )
    }

    renderedInput = (rateKey) => {
        const { symbol } = this.props;
        const changeFunction = (newRate, errorMessage) => {
            this.handleRateChange(rateKey, newRate, errorMessage);
        }

        const value = this.state[rateKey];

        const message = this.state[`${rateKey  }errorMessage`];

        return (
            <div>
                <RateInput
                    key={rateKey}
                    rateValue={value}
                    inputClass="rateInput"
                    changeFunction={changeFunction}
                    symbol={symbol}
                />
                <div style={{color:'#e5004d', marginTop: 3, display : message ? '' : 'none'}}>
                    { this.state[`${rateKey  }errorMessage`]}
                </div>
            </div>
        )
    }

    renderedRatesEditing = (isPerCampaignMode) => {
        const {
            missingRateError,
            rateError,
        } = this.state;
        const { editMode } = this.props;
        const couples = [];
        let currentCouple = [];
        let keys = Lookup.getRateTypeList();

        if (isPerCampaignMode) {
            keys = keys.filter((key) => key.indexOf(this.perCampaignRateKey) > -1);
        } else {
            keys = keys.filter((key) => key.indexOf(this.perCampaignRateKey) === -1);
        }

        for (const rateKey of keys) {
            if (currentCouple.length === 1) {
                couples.push(currentCouple);
                currentCouple = [];
            }

            currentCouple.push({
                key: rateKey,
                label: Lookup.getRateType(rateKey).label,
                inputElement: this.renderedInput(rateKey),
            });
        }

        couples.push(currentCouple);

        const rows = [];
        let rowKey = 1;
        for (const couple of couples) {
            rows.push(
                <div className={isPerCampaignMode ? 'perCampaignRow' : ''} key={rowKey++}>
                    <div className="label">
                        { isPerCampaignMode ? '' : couple[0].label }
                    </div>
                    <div>
                        { couple[0].inputElement }
                    </div>
                    {couple.length > 1 && (
                        <>
                            <div className="label">
                                { couple[1].label }
                            </div>
                            <div>
                                {couple[1].inputElement}
                            </div>
                        </>
                    )}
                </div>,
            );
        }

        let className = '';

        if (rateError) {
            className = 'checkedInputError';
        }

        return (
            <div
                className={className}
                style={{
                    paddingTop: 20,
                    paddingLeft: 30,
                    paddingRight: 30,
                }}
            >
                {isPerCampaignMode && (
                    <div className="editedRateTable_title">Per campaign</div>
                )}
                <div className="editedRateTable">
                    { rows }
                </div>
                {!editMode && (
                    <div
                        style={{
                            position: 'relative',
                        }}
                    >
                        <div
                            style={{
                                position: 'absolute',
                                left: 20,
                                top: 35,
                                color: 'red',
                            }}
                        >
                            {missingRateError}
                        </div>
                    </div>
                )}
            </div>
        )
    }

    renderedRateDisplay(key, renderedRateDisplay) {
        const rateType = Lookup.getRateType(key);
        const {label} = rateType;
        const value = this.state[key];
        let displayedRate = " ";
        if (!isNaN(value) && Number(value)>0) {
            displayedRate = `$${  value}`;
        }

        return (
            <div
                key={key}
            >
                <div className="displayedRate">
                    <div>
                        <RateIcon rateKey={key} size={30} value={value}/>
                    </div>
                    <div>
                        <div className="rate">
                            {displayedRate}
                        </div>
                        {renderedRateDisplay &&
                            <div className="help-text">avg per post/campaign</div>
                        }
                    </div>
                </div>
            </div>)
    }

    getProfile = () => {
        const { profile, profileFromStore } = this.props;
        const profileToUse = profileFromStore && profileFromStore.role !== 'TALENT_MANAGER' && Object.prototype.hasOwnProperty.call(profileFromStore, 'rates')
            ? profileFromStore
            : profile;

        return profileToUse;
        // return profile;
    };

    componentWillReceiveProps(newProps) {
        this.processProps(newProps);
        if ( this.props.editMode && !newProps.editMode) {
            // coming out of local editing
            if (newProps.saveFlag) {
                this.save();
            } else {
                this.cancel();
            }
        }
        if (!this.props.editMode && newProps.editMode) {
            for (const ix in this.rateHandles) {
                this.rateHandles[ix].suggested = this.state[this.rateHandles[ix].key]
            }
        }

        const { activeRatesOption: currentActiveRatesOption } = this.props;
        const {
            activeRatesOption: nextActiveRatesOption,
            profile,
        } = newProps;

        if (!currentActiveRatesOption && nextActiveRatesOption) {
            this.fromProfileToState(profile, nextActiveRatesOption);
        }
    }

    componentDidMount() {
        this.processProps(this.props);
    }

    render() {
        const {
            activeRatesOption,
            editMode,
            profile,
        } = this.props;
        const isPerCampaignMode = activeRatesOption === PER_POST;

        if (!profile) {
            return null;
        }

        return (
            <div className="profileRates">
                {editMode && this.renderedRatesEditing(isPerCampaignMode)}
                {!editMode && this.renderedRatesDisplay()}
            </div>
        );
    }
}

const mapStateToProps = ({ global }) => ({
    profileFromStore: global.loggedInUserProfile,
    // profile: get(state, 'global.loggedInUserProfile', {}),
});

export default connect(mapStateToProps)(ProfileRate);
