export default class EngagementParams {

    static initializeStateFields = () => {
        return {
            currentPage : 1,
            pageSize : 10,
            sortBy: '',
            campaignSortDirection: 'desc',
            dateSortDirection: 'desc',
            brandSortDirection: 'desc',
            assignmentSortDirection: 'desc',
            paymentSortDirection: 'desc',
        }
    }

    static createParamsForApi = (fields) => {
        let retval = {
            page : fields.currentPage,
            size : fields.pageSize,
        }
        if (fields.sortBy && fields.sortBy.length > 0) {
            retval.sortby = fields.sortBy;
        }
        if (fields.year && fields.year.length > 0 && fields.year !== 'all') {
            retval.year = fields.year;
        }
        let directionKey = fields.sortBy + 'SortDirection';
        if (fields[directionKey] === 'asc') {
            retval.order = 'asc';
        }
        return retval;
    }
}

