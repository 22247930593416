import React from 'react'
import {startLoadingLookupTables}  from '../../store/global/globalActions'
import {getAgentList, getAgentBrands} from "../../store/agent/actions";
import { Route, Switch } from "react-router-dom";

import PleaseWaitWhite from '../shared/pleaseWait/PleaseWaitWhite';
import TopNavigation from "../shared/topNavigation/TopNavigation"
import {connect} from 'react-redux';
import CampaignList  from './campaignList/CampaignList'
import CampaignDetails from './campaignDetails/CampaignDetails'
import  './campaign.scss'

// props, provided through redux /  thunk (see mapStateToProps, mapDispatchToProps below)
// - getCampaignList
// - campaignList

class Campaign extends React.Component {

    constructor(props, context) {
        super(props, context);
    }

    componentDidMount() {
        if (!this.props.lookupTablesLoading  && !this.props.lookupTables) {
            this.props.getLookupTables();
        }
        // if (!this.props.agent) {
        //     this.props.getAgentList();
        // }
        if (!this.props.brands) {
            this.props.getAgentBrands();
        }
    }

    render() {
        if (!this.props.lookupTables) {// || !this.props.agent || !this.props.brands) {
            return (
                <PleaseWaitWhite  show={!this.props.lookupTables || !this.props.lookupFilters}/>
            )
        }
        return(
            <div className="campaign">
                <TopNavigation current="campaign"/>
                <div>
                    <Switch>
                        <Route exact path={`${this.props.match.url}`}  component={CampaignList} />
                        <Route exact path={`${this.props.match.url}/:campaignId`}  component={CampaignDetails}/>
                    </Switch>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getAgentList: () => {
            dispatch(getAgentList())
        },
        getAgentBrands: () => {
            dispatch(getAgentBrands())
        },
        getLookupTables: () => {
            dispatch(startLoadingLookupTables())
        }
    }
};

const mapStateToProps= (state) => {
    return{
        brands: state.agent.brands,
        agent : state.agent.list,
        lookupTablesLoading : state.global.lookupTablesLoading,
        lookupTables : state.global.lookupTables
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Campaign)
