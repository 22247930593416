import React from 'react'

// props
// - editMode  flag
// - birthdate yyyy-mm-dd
// - changeFunction takes a new yyyy-mm-dd
// - setSaveEnabled takes flag

export default class Age extends React.Component {

    constructor (props, context) {
        super(props, context);

        this.handleChange = this.handleChange.bind(this);

        this.state = {
            errorMessage : null
        };
    }

    handleChange(e) {
        let value = e.target.value;
        let age = Age.calculateNumberOfYears(value);
        let notTooYoung = (age >= 18);
        let notTooOld=(age <=100);
        this.setState({
            errorMessage : !notTooYoung ? 'You must be at least 18 years old' : !notTooOld?'Please make sure your birthday is correct':null
        })
        this.props.setSaveEnabled(notTooYoung && notTooOld);
        let f = this.props.changeFunction;
        if (typeof f === 'function') {
            f(value);
        }
    }

    componentDidMount() {
        this.setState({
        })
    }

    render() {
        if (this.props.editMode) {
            return (
                <div key="dateOfBirth">
                    <div className="inputCell">
                        <input type="date" className="free" max={'9999-12-31'}
                               onChange={this.handleChange}
                               defaultValue={this.props.birthdate}
                        />
                    </div>
                    <div style={{color:'#e5004d', marginTop:5, fontWeight: 'bold',
                        display: (this.state.errorMessage ? '' : 'none')}}>
                        { this.state.errorMessage }
                    </div>
                </div>
            )
        } else {
            return <div></div>
        }
    }

    static calculateNumberOfYears(dateString) {
        if (typeof dateString !== 'string' || dateString.length < 6) {
            return null;
        }
        let today = new Date();
        let birth = new Date(dateString);
        let age =  today.getFullYear() - birth.getFullYear();
        if (today.getMonth() < birth.getMonth()) {
            age -= 1
        } else {
            if (today.getMonth() === birth.getMonth()) {
                if (today.getDate() < birth.getDate()) {
                    age -= 1
                }
            }
        }
        return age;
    }

    static calculateAge(dateString) {
        let age = Age.calculateNumberOfYears(dateString);
        return '' + age + ' years';
    }
}

