import React from 'react'
import ErrorMessageBoxIntl from '../errorMessageBox/ErrorMessageBoxIntl'
import PleaseWaitPartial from '../pleaseWait/PleaseWaitPartial'
import Format from '../../../modules/utils/Format'

// props :
// - accept  list extensions as needed for the input type="file"
// - handleSelectedFile  takes file object  (that went through all the front end checks)
// - maxSize  defaults to 5 M
// - clickCounter (to trigger file selection box )
// - showSpinner flag

export default class FileUpload extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            showSpinner : false,
            maxSize : this.props.maxSize||5368709120, // 5G bytes
            clickCounter : 1,
            errorMessage : {},
            showError : false
        }
    }

    handleFileChange = () => {
        const file = this.state.inputElement.files[0];
        if (!file) {
            return;
        }

        // check extension
        if (file.name) {
            if (this.props.accept && this.props.accept !== '*') {
                const extensions = this.props.accept.split(',');
                let fileExtOk = true;
                const startExtension = file.name.lastIndexOf('.');
                if (startExtension < 0) {
                    fileExtOk = false;
                } else {
                    const extension = file.name.substring(startExtension + 1).toLowerCase();
                    let extensionFound = false;
                    for (const acceptedExtension of extensions) {
                        if (extension === acceptedExtension.toLowerCase().trim().substring(1)) {
                            extensionFound = true;
                            break;
                        }
                    }
                    if (!extensionFound) {
                        fileExtOk = false;
                    }
                }
                if (!fileExtOk) {
                    this.setState({
                        showError: true,
                        errorMessage: {
                            messageId: 'fileUpload.fileTypeErrorMessage',
                            messageValues: {
                                allowedExtensions: this.props.accept
                            }
                        }
                    })
                    return;
                }
            }
        }

        // check file size
        // if (file.size > this.state.maxSize) {
        //     this.setState({
        //         showError : true,
        //         errorMessage : {
        //             messageId : 'fileUpload.fileSizeErrorMessage',
        //             messageValues : {
        //                 maxFileSize : Format.fileSize(Math.floor(this.state.maxSize/1000000)*1000000)
        //             }
        //         }
        //     })
        //     return;
        // }
        this.props.handleSelectedFile(file);
        this.resetFileInput();
    }

    resetFileInput() {
        let randomString = Math.random().toString(36);
      
        this.setState({
          theInputKey: randomString,
          inputElement: null
        });
      }

    handleClick = () => {
        this.inputElement.click();
    }

    static getDerivedStateFromProps(props, state) {
        const newState = {};
        if (state.clickCounter + 1 == props.clickCounter) {
            newState.clickCounter = props.clickCounter;
            if ( state.inputElement) {
                state.inputElement.click();
            }
        }
        return newState;
    }

    render() {
        let {accept} = this.props;
        if (!accept) {
            accept = '*'
        }

        const refFunction = (e) => {
            if (!this.state.inputElement) {
                this.setState({ inputElement : e})
            }
        }

        return (
            <div className="fileInput" style={{position:'relative'}} ref={ (e) => {this.container = e}}>
                <PleaseWaitPartial
                    show = {this.props.showSpinner}
                    container = {this.container}
                />
                <ErrorMessageBoxIntl
                    messageId = {this.state.errorMessage.messageId}
                    messageValues = { this.state.errorMessage.messageValues}
                    show = { this.state.showError }
                    closeFunction = {() => this.setState({showError : false})}
                />
                <input
                    style={{display:'none'}}
                    type="file"
                    key={this.state.theInputKey || '' }
                    accept={accept}
                    onChange={this.handleFileChange}
                    onClick={ (e) => { e.stopPropagation()}}
                    ref={refFunction}
                />
            </div>
        )
    }
}
