import { call, put, select } from 'redux-saga/effects';
import { lookupFiltersLoaded, setLookupFiltersLoading, setData, setError } from '../actionCreators';
import Api from '../../../modules/Api';

export default function* lookupFiltersSaga() {
    try {
        yield put(setLookupFiltersLoading(true));

        const { type: source } = yield select(({ campaignReducer }) => ({
            type: campaignReducer.influencerSearch.searchFilters.type,
        }));

        let lookupFilters;

        const publicFilters = yield call(Api.getFilters, 'PUBLIC');
        const connectedFilters = yield call(Api.getFilters, 'CONNECTED');
        if (source === 'PUBLIC') {
            lookupFilters = publicFilters;
        } else if (source === 'CONNECTED') {
            lookupFilters = connectedFilters;
        } else {
            lookupFilters = yield call(Api.getFilters, source);
        }

        const { lookup } = yield select(({ general }) => ({
            lookup: general.lookup,
        }));

        yield put(lookupFiltersLoaded({ lookupFilters, publicFilters, connectedFilters }));
        yield put(
            setData({
                lookup: {
                    ...lookup,
                    lookupFilters,
                },
            }),
        );
    } catch (error) {
        yield put(
            setError({
                lookupFilters: error.message,
            }),
        );
    } finally {
        yield put(setLookupFiltersLoading(false));
    }
}
