import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames'
import './SavedSearch.scss';
import Select from 'react-select';

const SavedSearch = ({
    revert,
    save,
    existingSavedSearches,
    show
}) => {
    const [useNewSearch, setUseNewSearch] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);
    const [newSearchName, setNewSearchName] = useState('');
    const [existingSearches, setExistingSearches] = useState([]);

    useEffect(() => {
        const searches = [];
        if (Array.isArray(existingSavedSearches)) {
            existingSavedSearches.forEach(e => {
                searches.push({label: e.name, value: e.id})
            })
            setExistingSearches(searches);
        }else {
            setExistingSearches([]); 
        }
    }, [existingSavedSearches]);

    const handleSearchNameChange = (e) => {
        setNewSearchName(e.target.value);
    }

    const reset = () => {
        setUseNewSearch(false);
        setNewSearchName('');
        setSelectedValue(null);
    }

    const handleSave = () => {
        if (useNewSearch) {
            save(newSearchName, null, reset);
        } else {
            save(selectedValue?.label, selectedValue?.value, reset);
        }
    }

    let disableSaveButton = false;
    if ((useNewSearch && newSearchName === '') || (!useNewSearch && !selectedValue)) {
        disableSaveButton = true;
    }

    return (
        <div className={cx("savedSearch", {show})}>
            <div className={cx("updateExisting",{show: !useNewSearch})}>
                <div className="title">Update existing saved search</div>
                <Select
                    className="dropdown brand"
                    searchable
                    clearable={false}
                    disabled={existingSearches.length === 0}
                    value={selectedValue}
                    single
                    placeholder="Select"
                    options={existingSearches}
                    onChange={setSelectedValue}
                    menuIsOpen
                />
                <div className="saveAs">
                    <div className="line"/>
                    or save as
                    <div className="line"/>
                </div>
                <div className="createSearch" onClick={() => setUseNewSearch(true)} role='button'>
                    <img src="/images/plusIconSearch.svg" alt="plus icon" />
                    New Search
                </div>
            </div>
            <div className={cx("updateNew",{show: useNewSearch})}>
                <div className="topSection">
                    <div onClick={() => setUseNewSearch(false)} role='button'>
                        <img src="/images/BackBlack.svg" alt="back arrow" />
                    </div>
                    <div className="titleLabel">Save as new search</div>
                    <div className="filler"/>
                </div>
                <div className="inputLabel">Name</div>
                <input 
                    placeholder="Enter Saved Search name"
                    type="text"
                    value={newSearchName}
                    onChange={handleSearchNameChange}
                />
            </div>
            <div className="bottomSection">
                <div onClick={revert} className="cancelB" role='button'>Cancel</div>
                <div onClick={handleSave} className={cx("saveButton", {disabled: disableSaveButton})} role='button'>Save</div>
            </div>
        </div>
    )
};

SavedSearch.propTypes = {

};

export default SavedSearch;
