import React, { useState, useRef } from 'react';
import CloseIcon from '../../../assets/icons/CloseIcon';
import './multiselect.scss';

const MultiSelect = ({ values, onChange, valueLimit, inputCharLimit }) => {
    const [handleInput, setHandleInput] = useState('');
    const inputRef = useRef(null);

    const onHandleSubmit = async (e) => {
        e.preventDefault();

        const trimmedInput = handleInput.trim();

        if (values.find((value) => value === trimmedInput)) return;

        if (trimmedInput) {
            onChange([...values, trimmedInput]);
            setHandleInput('');
        }
    };

    const onHandleRemove = (e, clickedLabel) => {
        e.stopPropagation();
        onChange(values.filter((value) => value !== clickedLabel));
    };

    const onWrapperClick = () => inputRef?.current?.focus();

    const handleInputChange = (e) => {
        if (inputCharLimit) {
            if (e.target.value.length < inputCharLimit) {
                setHandleInput(e.target.value);
            }
        } else {
            setHandleInput(e.target.value);
        }
    };

    return (
        <form onSubmit={onHandleSubmit} onClick={onWrapperClick} className="multiselect-field">
            {values.map((value) => (
                <div className="multiselect-tag" key={value}>
                    <div className="tag-text">{value}</div>
                    <button className="tag-close-btn" type="button" onClick={(e) => onHandleRemove(e, value)}>
                        <CloseIcon />
                    </button>
                </div>
            ))}
            {values.length < valueLimit && (
                <input
                    className="multiselect-input"
                    type="text"
                    value={handleInput}
                    onChange={handleInputChange}
                    ref={inputRef}
                    onBlur={onHandleSubmit}
                />
            )}
        </form>
    );
};

export default MultiSelect;
