import React, { useState } from 'react';
import CreatableSelect from 'react-select-v5/creatable';
import './creatableSelect.scss';

const CHAR_LIMIT = 50;

export default ({ name, values, setValues, maxValues }) => {
    const [inputValue, setInputValue] = useState('');

    const handleKeyDown = (event) => {
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                event.preventDefault();
                if (!!inputValue && !values.find((value) => value.value === inputValue)) {
                    const trimmedInput = inputValue.trim();
                    const newVal = {
                        label: trimmedInput,
                        selected: true,
                        type: 'CATEGORY',
                        value: trimmedInput,
                    };
                    setValues([...values, newVal]);
                    setInputValue('');
                }
        }
    };

    const handleInputChange = (val) => {
        if (val.length > CHAR_LIMIT || values.length >= maxValues) return;
        setInputValue(val);
    };

    return (
        <CreatableSelect
            isMulti
            name={name}
            value={values}
            onChange={(newValues) => newValues && setValues(newValues)}
            components={{
                DropdownIndicator: null,
            }}
            inputValue={inputValue}
            className="creatable-select"
            classNamePrefix="select"
            isClearable
            menuIsOpen={false}
            onInputChange={handleInputChange}
            onKeyDown={handleKeyDown}
        />
    );
};
