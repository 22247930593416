import React, { useState, useEffect, useRef, createRef, useLayoutEffect } from 'react';
import cx from 'classnames';
import ReactDOM from 'react-dom'
import './loadingDialogue.scss';
import PulseLoader from '../pulseLoader/PulseLoader';

export const LoadingDialogue = ({
    show,
    container = "messageContainer"
}) => {
    const el = useRef(document.createElement('div'));

    useEffect(() => {
        document.getElementById(container).appendChild(el.current);

        return () => {
            if (document.getElementById(container).contains(el.current)) {
                document.getElementById(container).removeChild(el.current);
            }
        }
    }, []);

    const renderNode = () => {
        return (
            <div className="loadingDialogueContainer">
                <PulseLoader color="#4AA129" size="16px" margin="4px" />
            </div>
        )
    }
    return (show ? ReactDOM.createPortal(
        renderNode(),
        el.current
    ) : null);
}