import React, { Component } from 'react';
import { debounce } from 'lodash';
import cx from 'classnames';
import './style.scss';
import TopNavigation from '../../shared/topNavigation/TopNavigation';
// import AdvertiserHeader from './AdvertiserHeader';
import Filters from './Filters';
import AdvertiserItem from './AdvertiserItem';
import Api from '../../../modules/Api';
import AddModal from './Modals/AddModal';
import PaginationNew from '../../shared/pagination/PaginationNew';
import PleaseWait from '../../shared/pleaseWait/PleaseWaitWhite';
import { SUPER_ADMIN, SUPER_ADMIN_STR } from '../../../constants/authorities';
import ProfileHelper from '../../../modules/utils/ProfileHelper';
import ApiError from '../../shared/apiError/ApiError';


export default class Advertisers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            advertisers: null,
            isAddModalOpen: false,
            // true - asc
            // false - desc
            // TODO: RENAME!
            filters: {
                name: false,
                totalBrands: false,
                createdBy: false,
                createdDate: true,
            },
            selectedSortBy: 'createdDate',
            selectedAdvertiser: null,
            showArchived: false,
            page: 1,
            totalCount: 0,
            pageSize: 10,
            search: null,
            shouldEdit: false,
            isArchived: false,
            profile: null,
            loading: true,
            apiError: null,
        };
    }

    getAdvertisers = async (page, pageSize, sortBy = ['createdDate', true], isArchived = false, search = null) => {
        try {
            const { meta, results } = await Api.getAdvertisers(page, pageSize, sortBy, isArchived, search);

            this.setState({
                loading: false,
                advertisers: results,
                page: meta.currentPage,
                totalCount: meta.totalCount,
            });
        } catch (error) {
            console.log(error);
        }
    }

    componentDidMount = async () => {
        const {
            page,
            pageSize,
        } = this.state;

        const profile = await ProfileHelper.getCurrentLoginUserProfile();
        await this.getAdvertisers(page, pageSize);

        this.setState({ profile });
    }

    handleModal = () => {
        this.setState({
            isAddModalOpen: !this.state.isAddModalOpen,
        });
    }

    setStateForFilters = async (filterName) => {
        const {
            page,
            pageSize,
            filters,
            isArchived,
            search,
        } = this.state;

        this.setState({
            filters: {
                ...filters,
                [filterName]: !filters[filterName],
            },
            selectedSortBy: filterName,
            loading: true,
        }, async() => {
            await this.getAdvertisers(
                page,
                pageSize,
                [filterName, !filters[filterName]],
                isArchived,
                search
            );
        });
    }

    handleFiltersChange = async (param) => {
        await this.setStateForFilters(param);
    }

    closeAddModal = () => {
        this.setState({
            isAddModalOpen: false,
            selectedAdvertiser: null,
            shouldEdit: false,
        });
    }

    acceptAddModal = async (data) => {
        const {
            shouldEdit,
            selectedAdvertiser,
            advertisers,
            page,
            pageSize,
            selectedSortBy,
            filters,
            isArchived,
            search,
        } = this.state;

        if (!shouldEdit) {
            try {
                const payLoad = {
                    name: data.advertiserName
                }
                if (data.logo) {
                    const response = await Api.uploadResource(data.logo, true);
                    if (response && response.url) {
                        payLoad.logoUrl = response.url
                    }
                }
                await Api.addAdvertiser(payLoad);
                this.setState({
                    isAddModalOpen: false,
                    selectAdvertiser: null,
                }, async() => {
                    await this.getAdvertisers(
                        page,
                        pageSize,
                        [selectedSortBy, filters[selectedSortBy]],
                        isArchived,
                        search
                    );
                });
            } catch (error) {
                console.log(error);
                this.setState({
                    loading: false,
                    isAddModalOpen: false,
                    selectAdvertiser: null,
                });
                if (error && error.originalMessage) {
                    this.setState({ apiError: error.originalMessage})
                }
            }
        } else {
            this.setState({
                isAddModalOpen: false,
                loading: true,
            });
            try {
                const name = data.advertiserName;
                let logoUrl = '';
                let response = null;

                if (!data.logo) {
                    logoUrl = selectedAdvertiser.logoUrl;
                } else {
                    response = await Api.uploadResource(data.logo, true);
                    logoUrl = response.url;
                }

                const advertiser = await Api.updateAdvertiser(
                    { name, logoUrl },                    
                    selectedAdvertiser.id
                );
                const index = advertisers.findIndex((adv) => adv.id === selectedAdvertiser.id);
                const updatedList = [...advertisers];
                updatedList[index] = advertiser;
                this.setState({
                    shouldEdit: false,
                    isAddModalOpen: false,
                    selectedAdvertiser: null,
                    advertisers: [...updatedList],
                    loading: false,
                });
                
            } catch (error) {
                console.log(error);
                this.setState({
                    shouldEdit: false,
                    isAddModalOpen: false,
                    selectedAdvertiser: null,
                    loading: false,
                });
                if (error && error.originalMessage) {
                    this.setState({ apiError: error.originalMessage})
                }
            }
        }
    };

    setIsArchived = async (shouldShowArchived) => {
        const {
            pageSize,
            selectedSortBy,
            filters,
            search,
        } = this.state;

        this.setState({
            isArchived: shouldShowArchived,
            loading: true,
        }, async() => {
            await this.getAdvertisers(
                1,
                pageSize,
                [selectedSortBy, filters[selectedSortBy]],
                shouldShowArchived,
                search
            );
        });
    }

    handlePageChange = async (page) => {
        const {
            pageSize,
            search,
            filters,
            selectedSortBy,
            isArchived,
        } = this.state;

        this.setState({ loading: true }, async() => {
            await this.getAdvertisers(
                page,
                pageSize,
                [selectedSortBy, filters[selectedSortBy]],
                isArchived,
                search
            );
        });
    }

    handlePageSize = async (pageSize) => {
        const {
            search,
            filters,
            selectedSortBy,
            isArchived,
        } = this.state;
        this.setState({
            pageSize,
            loading: true,
        }, async () => {
            await this.getAdvertisers(
                1,
                pageSize,
                [selectedSortBy, filters[selectedSortBy]],
                isArchived,
                search
            );
        });
    }

    onSearch = debounce(async (value) => {
        const {
            pageSize,
            filters,
            selectedSortBy,
            isArchived,
        } = this.state;
        this.setState({
            search: value,
            loading: true,
        }, async () => {
            await this.getAdvertisers(
                1,
                pageSize,
                [selectedSortBy, filters[selectedSortBy]],
                isArchived,
                value
            );
        });
    }, 500);

    handleEditOpen = (advertiser) => {
        this.setState({
            selectedAdvertiser: advertiser,
            isAddModalOpen: true,
            shouldEdit: true,
        });
    }

    handleAddNew = () => {
        this.setState({
            isAddModalOpen: true,
            shouldEdit: false,
        })
    }

    archiveAdvertiser = async (id) => {
        const { advertisers } = this.state;
        await Api.archiveAdvertiser(id);
        const index = advertisers.findIndex(({ id: advertiserId }) => advertiserId === id);
        const newAdvertisers = [...advertisers];
        newAdvertisers.splice(index, 1);

        this.setState({
            advertisers: [...newAdvertisers],
        });
    }

    moveToBrands = (id) => {
        this.props.history.push(`/adminBrands?advertiserId=${id}`);
    }

    render = () => {
        const { advertisers, isAddModalOpen, filters, selectedAdvertiser, showArchived, isArchived }=this.state;
        const{ page, totalCount, pageSize, shouldEdit, profile, loading, selectedSortBy } = this.state;
        return (
            <>
                <ApiError
                    show={!!this.state.apiError}
                    error={this.state.apiError}
                    cancelFunction={() => this.setState({apiError: null})}
                />
                <div className="admin-advertisers-page admin-management-page talent-management">
                    <TopNavigation current="/advertisers" />
                    <div className="blue-header admin-header">
                        <div className="contentDiv">
                            <div className="controlContainer">
                                <div className="left-container">
                                    <h1>Advertisers</h1>
                                </div>
                                <div className="right-container">
                                    <div className="userSearch">
                                        <img src="/images/ic-mag.svg" />
                                        <input
                                            className="search"
                                            type="text"
                                            placeholder="Search Advertiser"
                                            onChange={(event) => this.onSearch(event.target.value)}
                                        />
                                    </div>
                                    {profile && profile.role === SUPER_ADMIN_STR && (
                                        <button
                                            onClick={() => this.handleAddNew()}
                                            className="invite-button"
                                        >
                                            <img src="/images/ic-plus-circle-w.svg" />
                                            New Advertiser
                                        </button>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <AdvertiserHeader */}
                    {/*    handleModal={this.handleModal} */}
                    {/*    profile={profile} */}
                    {/* /> */}
                    <div className="all-users-page advertisers-list">
                        <PleaseWait
                            show={loading}
                            top={200}
                        />
                        <Filters
                            handleFiltersChange={this.setIsArchived}
                            handlePageSize={this.handlePageSize}
                            onSearch={this.onSearch}
                            showArchived={showArchived}
                        />
                        <div className="contentDiv advertiserdDiv" ref={ (e) => {this.table = e}}>
                            <table className="data-table-advertisers">
                                <tbody>
                                    <tr className="table-header">
                                        <th
                                            className="user-info-holder"
                                            onClick={() => this.handleFiltersChange('name')}
                                        >
                                            Advertiser
                                            <img src="/images/ic_down14x14.svg" className={cx({up: !filters.name, no: selectedSortBy !== 'name'})} />
                                        </th>
                                        <th
                                            className="brands"
                                            onClick={() => this.handleFiltersChange('totalBrands')}
                                        >
                                            Total Brands
                                            <img src="/images/ic_down14x14.svg" className={cx({up: !filters.totalBrands, no: selectedSortBy !== 'totalBrands'})} />
                                        </th>
                                        <th
                                            className="role"
                                            onClick={() => this.handleFiltersChange('createdBy')}
                                        >
                                            Created By
                                            <img src="/images/ic_down14x14.svg" className={cx({up: !filters.createdBy, no: selectedSortBy !== 'createdBy'})} />
                                        </th>
                                        <th
                                            className="date"
                                            onClick={() => this.handleFiltersChange('createdDate')}
                                        >
                                            Date Created
                                            <img src="/images/ic_down14x14.svg" className={cx({up: !filters.createdDate, no: selectedSortBy !== 'createdDate'})} />
                                        </th>
                                        <th className="actions"/>
                                    </tr>
                                    {
                                        advertisers && advertisers.map((advertiser) => (
                                            <AdvertiserItem
                                                key={advertiser.id}
                                                advertiser={advertiser}
                                                handleEditOpen={this.handleEditOpen}
                                                handleArchive={this.archiveAdvertiser}
                                                profile={profile}
                                                moveToBrandsWithFilter={this.moveToBrands}
                                                isArchived={isArchived}
                                            />
                                        ))
                                    }
                                </tbody>
                            </table>
                            <PaginationNew
                                total={totalCount}
                                current={page}
                                limit={pageSize}
                                handlePageChange={this.handlePageChange}
                                parentContainer={this.table}
                                limitChangeFunction={this.handlePageSize}
                            />
                        </div>
                    </div>
                </div>
                <AddModal
                    isOpen={isAddModalOpen}
                    onCancel={this.closeAddModal}
                    onAccept={this.acceptAddModal}
                    shouldEdit={shouldEdit}
                    advertiser={selectedAdvertiser}
                />
            </>
        )
    }
}
