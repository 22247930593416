import React from 'react'
import { VIEWER_STR } from '../../../constants/authorities';
import ProfileHelper from '../../../modules/utils/ProfileHelper';
import Checkbox from '../../shared/checkbox/Checbox';

// props
// - draftRequired
// - assignmentId
// - delegateChange
// - isAssignmentHaveAssignmentFlights

export default class DraftRequiredSelection extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            isDraftRequired: this.props.draftRequired,
            role: undefined,
        };
    }

    componentDidMount() {
        ProfileHelper.getCurrentLoginUserProfile()
            .then(profile => {
                this.setState({role: profile.role});
            });
    }

    changeDraftRequiredSelection(newCheckboxState) {
        const {delegateChange} = this.props;
        if (typeof delegateChange === 'function') {
            delegateChange(newCheckboxState);
        }
        this.setState({ isDraftRequired: newCheckboxState });
    }

    render() {
        const {permission} = this.props;
        const {isDraftRequired, role} = this.state;
        return (
            <div className="">
                <div className="checkboxLine">
                    <div>
                        <Checkbox
                            checked={isDraftRequired}
                            size={20}
                            changeFunction={(newState) => this.changeDraftRequiredSelection(newState)}
                            isDisabled={role === VIEWER_STR || permission}
                        />
                    </div>
                    <div>
                        Require creators to submit a draft?
                    </div>
                </div>
            </div>
        )
    }
}
