import React from 'react'
import './freeStringArray.scss'

/*
 props :
 - strings
 */

export default class FreeStringArrayDisplayed extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        let strings = this.props.strings || [];
        let columns = [[],[],[]];
        for (let i=0; i<strings.length; i++) {
            columns[i%3].push(<div key={i}>
                {strings[i]}
            </div>)
        }
        return(
            <div className="freeStringArray display">
                <div className="arrayList">
                    <div>
                        { columns[0] }
                    </div>
                    <div>
                        { columns[1] }
                    </div>
                    <div>
                        { columns[2] }
                    </div>
                </div>
            </div>
        )
    }
}