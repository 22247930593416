import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './FloatingLabelInput.scss';

class FloatingLabelInput extends Component{

    constructor(props){
        super(props);
        // console.log(props);
        this.state = {
            hasValue: !!props.defaultValue,
            hasError: false,
            value: props.defaultValue ? props.defaultValue : ''
        };
        this.onBlur = this.onBlur.bind(this);
        this.onChange = this.onChange.bind(this);
        this.changeVisibility = this.changeVisibility.bind(this);
    }

    changeVisibility(event){
        event.preventDefault();
        if(event.currentTarget.parentNode.firstChild.type === "password"){
            event.currentTarget.parentNode.firstChild.type = "text";
            event.currentTarget.style.backgroundImage = 'url(/images/ic_eye.svg)';
        }else{
            event.currentTarget.parentNode.firstChild.type = "password";
            event.currentTarget.style.backgroundImage = 'url(/images/ic_invisible.svg)'
        }
    }

    onBlur(event){
        const { pattern } = this.props;
        this.setState({
            hasError: !pattern.test(event.currentTarget.value),
            hasValue: Boolean(event.currentTarget.value)
        });
        if (this.props.onBlurEvent) {
            this.props.onBlurEvent();
        }
    }

    onChange(event){
        const {pattern, handleChange, liveValidation} = this.props;
        const hasError = !pattern.test(event.currentTarget.value);
        handleChange(event.target.id, event.target.value, hasError);
        this.setState({
            hasValue: Boolean(event.currentTarget.value),
            value: event.currentTarget.value,
            hasError: liveValidation ? hasError : false
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.defaultValue !== this.props.defaultValue && this.props.defaultValue) {
            this.setState({
                value: this.props.defaultValue
            });
        }
    }

    render(){
        const {autoComplete, errorMsg, id, isDisabled, pattern, placeholder, type, autoFocus, showErrorOnMount} = this.props;
        const {hasValue, hasError, value} = this.state;
        const inputClasses = classNames('fl-input',{'fl-valid': hasValue && !hasError}, {'fl-invalid': hasValue && hasError});
        const errMsgClasses = classNames({'fl-error-msg': errorMsg}, {'fl-error-show' : (hasError && hasValue && errorMsg && pattern) || showErrorOnMount});
        const errorPack = (Array.isArray(errorMsg)) ? errorMsg.map((value)=>value):errorMsg;
        return(
            <div className='fl-input-container'>
                <input
                    className={inputClasses}
                    autoComplete={autoComplete}
                    id={id}
                    type={type}
                    disabled={isDisabled}
                    onBlur={this.onBlur}
                    onChange={pattern ? this.onChange : null}
                    value={value}
                    autoFocus={autoFocus}
                />
                { ( type === "password" && window.location.pathname === '/login' ) &&
                    <input type="checkbox" id={`CB1-${  id}`}  className="fl-checkbox" onClick={this.changeVisibility}/>
                }
                <label className="fl-input-label" htmlFor={id}>{placeholder}</label>
                {errorMsg && <div className={errMsgClasses}>{errorPack}</div>}
            </div>

        )
    }

}

FloatingLabelInput.propTypes  = {
    autoComplete: PropTypes.string,
    isDisabled: PropTypes.bool,
    placeholder: PropTypes.string.isRequired,
    pattern: PropTypes.any,
    type: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    errorMsg: PropTypes.any,
    defaultValue: PropTypes.string,
    autoFocus: PropTypes.bool,
    liveValidation: PropTypes.bool
};

export default FloatingLabelInput;
