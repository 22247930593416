import React from 'react'
import './modalDialog.scss'
import {Motion, spring} from 'react-motion'

// Props :
// - show
// - title
// - proceedButtonLabel (defaults to Create)
// - cancelButtonLabel (defaults to Cancel)
// - readyToProceed
// - proceedFunction
// - cancelFunction
// - justOkFlag  if all we want is to display something
// - closeButton flag if you want a Cancel Dialog x on the right top
// The component's children will be interpreted as the content of the input panel


export default class ModalDialog extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.proceed = this.proceed.bind(this);
        this.cancel = this.cancel.bind(this);

        this.state = {
        };
    }

    proceed() {
        if (this.props.readyToProceed) {
            if(!this.props.dontProceed){
                this.cancel();
            }
            const f = this.props.proceedFunction;
            if (typeof f === 'function') {
                f();
            }
        }
    }

    cancel() {
        const f = this.props.cancelFunction;
        if (typeof f === 'function') {
            f();
        }
    }

    render() {
        const style={};
        if (!this.props.show && !this.props.fadeIn) {
            style.display = "none";
        }else if (!this.props.show && this.props.fadeIn){
            style.visibility='hidden';
            style.opacity=0
        }else if (this.props.show && this.props.fadeIn){
            style.visibility='visible';
            style.opacity=1
        }

        const proceedButtonClass = this.props.readyToProceed?'button2 proceed controlButton':'button2 proceed controlButton disabled';
        // if (!this.props.readyToProceed) {
        //     proceedButtonStyle.opacity = 0.4;
        // }
        let proceedLabel = 'CREATE';
        if (this.props.proceedButtonLabel) {
            proceedLabel = this.props.proceedButtonLabel;
        }
        let cancelLabel = 'CANCEL';
        if (this.props.cancelButtonLabel) {
            cancelLabel = this.props.cancelButtonLabel;
        }
        return(
            <div
                className="modalDialog"
                style={style}
                onClick={this.handleClick}
            >
                <Motion style={this.props.fadeIn?{
                    x: spring((this.props.show?0:-200),{stiffness: 200, damping: 18}),
                    y: spring(1,{stiffness: 200, damping: 30})
                }:{x:0,y:1}}>
                    {({x, y}) =>
                        <div className="dialog"
                            ref={e => {
                                this.dialogPanel = e
                            }}
                            style={{ opacity: `${y}`,
                                WebkitTransform: `translate3d(0,${x}px, 0)`,
                                transform: `translate3d(0,${x}px, 0)`,
                                maxHeight: this.state.panelMaxHeight}}>
                            <div className="closeContainer">
                                {this.props.closeButton &&
                                <img className="close" src="/images/ic-close-b-d.svg" onClick={this.cancel}/>}
                            </div>
                            {!this.props.noTitle && <div className="title">
                                {this.props.title}
                            </div>}
                            <div className="inputArea">
                                {this.props.children}
                            </div>
                            <div className={(this.props.closeButton && !this.props.footerColor) ? "footer closeButton" : "footer"}>
                                {this.props.leftIcon && this.props.leftIconAction && 
                                    <div className="optional-icon" onClick={this.props.leftIconAction}>
                                        <img src={this.props.leftIcon} />
                                    </div>
                                }
                                {(!this.props.justOk && !this.props.backBtn && !this.props.noCancel) && <div className="button2 cancel cancelButton"
                                    onClick={this.cancel}
                                >
                                    {cancelLabel}
                                </div>
                                }
                                {!this.props.justOk && <div className={proceedButtonClass}
                                    style={this.props.proceedButtonStyle}
                                    onClick={this.proceed}
                                >
                                    {proceedLabel}
                                </div>
                                }
                                {this.props.justOk && <div className="button2 cancel cancelButton"
                                    onClick={this.cancel}
                                >
                                    OK
                                </div>
                                }
                                {
                                    this.props.backBtn && <div className="backButton" onClick={this.props.backBtnPressed}>
                                        <svg version="1.1" id="Capa_1" x="0px" y="0px" width="22px" height="22px" viewBox="0 0 268.832 268.832"><g transform="matrix(-1 -1.22465e-16 1.22465e-16 -1 268.832 268.832)"><g><path d="M265.171,125.577l-80-80c-4.881-4.881-12.797-4.881-17.678,0c-4.882,4.882-4.882,12.796,0,17.678l58.661,58.661H12.5   c-6.903,0-12.5,5.597-12.5,12.5c0,6.902,5.597,12.5,12.5,12.5h213.654l-58.659,58.661c-4.882,4.882-4.882,12.796,0,17.678   c2.44,2.439,5.64,3.661,8.839,3.661s6.398-1.222,8.839-3.661l79.998-80C270.053,138.373,270.053,130.459,265.171,125.577z" data-original="#000000" className="active-path" data-old_color="#0065da" fill="#414141" style={{verticalAlign: 'baseline'}} /></g></g></svg>
                                        <span className="backBtn">
                                            Back
                                        </span>
                                    </div>
                                }

                            </div>
                        </div>
                    }
                </Motion>
            </div>
        )
    }
}
