import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import {Motion, spring} from 'react-motion'
import cx from 'classnames'
import Select from "react-select";
import {connect} from 'react-redux';
import Api from "../../modules/Api"
import Lookup from "../../modules/Lookup"
import Pagination from '../shared/pagination/Pagination'
import PleaseWait from '../shared/pleaseWait/PleaseWaitWhite';
import ProfileHelper from '../../modules/utils/ProfileHelper';
import "./OpportunityList.scss"
import ApiError from '../shared/apiError/ApiError';
import { TALENT_MANAGER_STR, INFLUENCER_STR } from '../../constants/authorities';

class OpportunityList extends Component {
    constructor(props) {
        super(props);
        this.renderedFilter = this.renderedFilter.bind(this);
        this.sortByName=this.sortByName.bind(this);
        this.sortByDate=this.sortByDate.bind(this);
        this.sortByRate=this.sortByRate.bind(this);
        this.sortByStatus=this.sortByStatus.bind(this);
        this.handlePageChange=this.handlePageChange.bind(this);
        this.onCreatorFilterChange=this.onCreatorFilterChange.bind(this);
        this.composeInfluencerOptions = this.composeInfluencerOptions.bind(this);

        const initialState = (this.props.history.location.state)?this.props.history.location.state:null;
        this.state = {
            currentPage: initialState?initialState.currentPage:1,
            currentFilter: 'current',
            opportunityList: [],
            waiting:false,
            sortBy:initialState?initialState.sortBy:'date',
            desc:initialState?initialState.desc:true,
            totalCount:0,
            focusKey: initialState?initialState.focusKey:'',
            influencerOptions: [],
            influencerFilter: null,
            apiError: null,
            currentLoginUserProfile: undefined,
        };
    }

    componentDidMount = async() => {
        const {sortBy, desc, currentPage, currentFilter} = this.state;
        const profile = await ProfileHelper.getCurrentLoginUserProfile();
        this.getList(sortBy, desc, currentPage, currentFilter);
        this.composeInfluencerOptions(profile);
        this.setState({currentLoginUserProfile: profile})
    };

    componentDidUpdate = async(prevProps, prevState) => {
        const {sortBy, desc} = this.state;
        const {profile} = this.props;
        const self = this;
        if (!_.isEqual(prevState.influencerFilter, this.state.influencerFilter) && !this.state.influencerFilter && 
            profile && profile.role && profile.role === TALENT_MANAGER_STR) {
            Api.getTMAssignmentOpportunityList(sortBy, desc, 1, this.state.currentFilter)
                .then(res=>this.setState({ opportunityList: res.results, waiting: false, totalCount:res.meta.totalCount, currentPage: 1 }, function() {
                    self.notifyUserStatusChanged();
                }))
                .catch(error => {
                    self.setState({waiting: false, apiError: error})
                })
        }
    };

    composeInfluencerOptions(profile) {
        if (profile && profile.role && profile.role === TALENT_MANAGER_STR)
            Api.getTMusers({status: "active", pageNumber: 1, pageSize: 200}).then((res) => {
                const options = res.content.map(entry => ({value: entry.id, label: `${entry.firstname  } ${  entry.lastname}`}));
                this.setState({influencerOptions: options});
            })
                .catch(error => {
                    this.setState({waiting: false, apiError: error})
                })
    }

    getList = (option,desc,currentPage,statusFilter) => {
        const {profile} = this.props;
        const self = this;
        this.setState({waiting: true});
        const apiOption = option || 'date';
        const apiCurrentPage = currentPage || 1;
        if (profile && profile.role && profile.role === TALENT_MANAGER_STR) {
            Api.getTMAssignmentOpportunityList(apiOption, desc, apiCurrentPage, statusFilter)
                .then(
                    res=>this.setState({
                        opportunityList:res.results, 
                        waiting:false, 
                        totalCount: 
                        res.meta.totalCount,
                        currentPage
                    }, function() {
                        self.notifyUserStatusChanged();
                    })
                )
                .catch(error => {
                    self.setState({waiting: false, currentPage: 1, opportunityList: [], totalCount: 0, apiError: error})
                })
        } else {
            Api.getAssignmentOpportunityList(apiOption, desc, apiCurrentPage, statusFilter)
                .then(
                    res=>this.setState({
                        opportunityList:res.results,
                        waiting:false, 
                        totalCount: res.meta.totalCount,
                        currentPage
                    }, function() {
                        self.notifyUserStatusChanged();
                    })
                )
                .catch(error => {
                    self.setState({waiting: false, currentPage: 1, opportunityList: [], totalCount: 0, apiError: error})
                })
        }
    };

    sortByName(){
        if (this.state.sortBy!=='name'){
            this.setState({sortBy:'name',desc:false,waiting:true,currentPage:1});
            this.getList('name',false, 1,this.state.currentFilter)
        }else if (this.state.desc){
            this.setState({desc:false,waiting:true, currentPage:1});
            this.getList('name',false, 1,this.state.currentFilter)
        }else {
            this.setState({desc:true,waiting:true, currentPage:1});
            this.getList('name',true, 1,this.state.currentFilter)
        }
    }

    sortByDate(){
        if (this.state.sortBy!=='date'){
            this.setState({sortBy:'date',desc:true,waiting:true,currentPage:1});
            this.getList('date',true, 1,this.state.currentFilter)
        }else if (this.state.desc){
            this.setState({desc:false,waiting:true, currentPage:1});
            this.getList('date',false, 1,this.state.currentFilter)
        }else {
            this.setState({desc:true,waiting:true, currentPage:1});
            this.getList('date',true, 1,this.state.currentFilter)
        }
    }

    sortByRate(){
        if (this.state.sortBy!=='rate'){
            this.setState({sortBy:'rate',desc:true,waiting:true,currentPage:1});
            this.getList('rate',true, 1,this.state.currentFilter)
        }else if (this.state.desc){
            this.setState({desc:false,waiting:true, currentPage:1});
            this.getList('rate',false, 1,this.state.currentFilter)
        }else {
            this.setState({desc:true,waiting:true, currentPage:1});
            this.getList('rate',true, 1,this.state.currentFilter)
        }
    }

    sortByStatus(){
        if (this.state.sortBy!=='status'){
            this.setState({sortBy:'status',desc:false,waiting:true,currentPage:1});
            this.getList('status',false, 1,this.state.currentFilter)
        }else if (this.state.desc){
            this.setState({desc:false,waiting:true, currentPage:1});
            this.getList('status',false, 1,this.state.currentFilter)
        }else {
            this.setState({desc:true,waiting:true, currentPage:1});
            this.getList('status',true, 1,this.state.currentFilter)
        }
    }

    sortByInfluencer = () => {
        if (this.state.sortBy!=='influencer'){
            this.setState({sortBy:'influencer',desc:false,waiting:true,currentPage:1});
            this.getList('influencer',false, 1,this.state.currentFilter)
        }else if (this.state.desc){
            this.setState({desc:false,waiting:true, currentPage:1});
            this.getList('influencer',false, 1,this.state.currentFilter)
        }else {
            this.setState({desc:true,waiting:true, currentPage:1});
            this.getList('influencer',true, 1,this.state.currentFilter)
        }
    }

    notifyUserStatusChanged = () => {
        const {opportunityList} = this.state;
        const newList = [...opportunityList];
        let changed = false;
        for (let i = 0; i < newList.length; i++) {
            const {negotiationsLogs} = newList[i];
            if (!negotiationsLogs || negotiationsLogs.length === 0) {
                if (newList[i].showPopupForStatusChanged) {
                    newList[i].showPopupForStatusChanged = false;
                    changed = true;
                }
                continue;
            }

            // under negotiation flow
            if (newList[i].status === 'NEGOTIATE') {
                negotiationsLogs.sort((a1, a2) => {
                    if (a1.id > a2.id)
                        return 1;
                    if (a1.id < a2.id)
                        return -1;

                    return 0;
                })
                const lastLog = negotiationsLogs[negotiationsLogs.length - 1];
                // if last player is agent and the action is not pending or invite, then it's pending on user's action or agent has already accepted & wait for user to sign
                if (lastLog.player === 'AGENT' && ((lastLog.statusAgent !== 'PENDING' && lastLog.playerAction !== 'INVITE') || lastLog.statusAgent === 'ACCEPTED')
                 && lastLog.statusUser === 'PENDING') {
                    newList[i].showPopupForStatusChanged = true;
                    changed = true;
                }
            } else if (newList[i].showPopupForStatusChanged) {
                newList[i].showPopupForStatusChanged = false;
                changed = true;
            }
        }
        if (changed) {
            this.setState({
                opportunityList: newList,
            })
        }
    }

    renderedFilter(label) {
        const {profile} = this.props;
        let className = "filter";
        if (label === this.state.currentFilter) {
            className += ' active';
        }
        const self = this;
        const clickHandler = function () {
            if (profile && profile.role && profile.role === TALENT_MANAGER_STR) {
                self.setState({ currentFilter: label, sortBy:'date', desc:true, influencerFilter: null})
            } else {
                self.setState({ currentFilter: label, sortBy:'date', desc:true})
            }
            self.getList('date', true, 1, label)
        }

        return (
            <div className={className} onClick={clickHandler}>
                {label}
            </div>
        )
    }

    handlePageChange(currentPage) {
        this.listContainer.scrollTop=0;
        const {sortBy, desc, influencerFilter, currentFilter}=this.state;
        const {profile} = this.props;
        const self = this;
        if (profile && profile.role && profile.role === TALENT_MANAGER_STR && influencerFilter) {
            this.setState({waiting:true});
            Api.getTMAssignmentOpportunityListByInfluencer(sortBy,desc, currentPage, currentFilter, influencerFilter)
                .then(res=>this.setState({opportunityList:res.results, currentPage, waiting:false, totalCount: res.meta.totalCount}))
                .catch((error) => {
                    self.setState({waiting:false, opportunityList: [], totalCount: 0, apiError: error});
                })
        } else {
            this.getList(sortBy, desc, currentPage, currentFilter);
        }
    }

    opportunityClickHandler = ({ oStatus, isTalentManager, publishDate, draftDate, opportunityId, assignmentId, userId }) => {
        const { sortBy, desc, currentPage } = this.state;
        const state = {
            sortBy,
            desc,
            currentPage,
            focusKey: opportunityId,
        };
        let pathName;
        let search;

        if (isTalentManager) {
            if ((oStatus === 'ACCEPTED' && publishDate && draftDate) || oStatus === 'COMPLETED' || oStatus === 'PAID') {
                pathName = `assignments/${assignmentId}/draftList`;
                search= `?influencerId=${userId}`
            }
            else if (oStatus === 'ACCEPTED' || oStatus === 'PENDING' || oStatus === 'NEGOTIATE') {
                pathName = `talentOpportunities/${assignmentId}/influencer/${userId}`;
            }
        } else {
            if ((oStatus === 'ACCEPTED' && publishDate && draftDate) || oStatus === 'COMPLETED' || oStatus === 'PAID') {
                pathName = `assignments/${assignmentId}/draftList`;
            } else {
                pathName = `opportunities/${assignmentId}`;
            }

            if (oStatus === 'PENDING' || oStatus === 'NEGOTIATE') {
                pathName = `opportunities/${assignmentId}`;
            }
        }
        this.props.history.push({
            pathname: pathName,
            state,
            search
        });
    }

    onCreatorFilterChange(selectedOption) {
        const {sortBy,desc}=this.state;
        if (selectedOption)
            Api.getTMAssignmentOpportunityListByInfluencer(sortBy, desc, 1, this.state.currentFilter, selectedOption.value)
                .then(res=>this.setState({opportunityList:res.results, currentPage: 1, waiting:false, influencerFilter: selectedOption.value, totalCount:res.meta.totalCount}))
                .catch(error => {
                    this.setState({waiting: false, apiError: error})
                })
        else
            this.setState({
                influencerFilter: null
            });
    }

    renderCreatorFilter() {
        if (!this.state.opportunityList)
            return null;

        return (
            <div className="influencer-filter-container">
                <div className="influencer-filter">
                    <Select className="select"
                        clearable
                        searchable={false}
                        value={this.state.influencerFilter}
                        single
                        placeholder="Select Creator"
                        options={this.state.influencerOptions}
                        onChange={this.onCreatorFilterChange}
                    />
                </div>
            </div>
        );
    }

    render() {
        const { opportunityList, sortBy, desc, totalCount, currentPage, focusKey, apiError, currentLoginUserProfile} = this.state;
        const isTalentManager = this.props.match.url === "/talentOpportunities";
        const addExtraTop = currentLoginUserProfile && (currentLoginUserProfile.role === TALENT_MANAGER_STR || currentLoginUserProfile.role === INFLUENCER_STR) && !currentLoginUserProfile.accountVerified;

        return (
            <div className="opportunity" ref={e=>this.container=e}>
                <PleaseWait
                    show={this.state.waiting}
                />
                <ApiError
                    show={apiError}
                    error={apiError}
                    cancelFunction={() => this.setState({apiError: null})}
                />
                <div className={cx("opportunityHeader", {extraHeader: addExtraTop})} >
                    <div className="contentDiv">
                        <div className="listedPanelTitle">Opportunities</div>
                    </div>
                </div>
                <div className={cx("wrapper", {extraWrapperTop: addExtraTop})}>
                    <div className="contentDiv">
                        <div className="opportunityFilter">
                            {this.renderedFilter('all')}
                            {this.renderedFilter('past')}
                            {this.renderedFilter('current')}
                            {isTalentManager && this.renderCreatorFilter()}
                        </div>
                    </div>
                </div>
                <div className={cx("opportunityContainer", {heavyExtra: addExtraTop})} ref={(e)=>this.listContainer=e}>
                    <div className="contentDiv">
                        {opportunityList.length === 0
                            ? <div className="listedOpportunity empty">
                                This is where you will see invitations to opportunities from brand.
                            </div>
                            : <div>
                                <div className="listedOpportunityTitle">
                                    <div className={`brand ${isTalentManager ? 'tm-brand' : ''}`} onClick={this.sortByName}>
                                        <span>Campaign/Assignment Name</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                            className={cx({'up':sortBy==='name'&&(desc)},{'no':sortBy!=='name'})}>
                                            <path fill="#666666" fillRule="nonzero" d="M16.46 9.27a.889.889 0 0 1 1.276 0 .938.938 0 0 1 0 1.305l-5.097 5.154a.888.888 0 0 1-1.278 0l-5.096-5.154a.936.936 0 0 1 0-1.305.889.889 0 0 1 1.276 0l4.46 4.227 4.458-4.227z"/>
                                        </svg>
                                    </div>

                                    <div className="type">
                                        Assignment Type
                                    </div>

                                    {/* <div className="total" onClick={this.sortByRate}> */}
                                    {/*    <span>Total Payment</span> */}
                                    {/*    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" */}
                                    {/*         className={cx({'up':sortBy==='rate'&&(!desc)},{'no':sortBy!=='rate'})}> */}
                                    {/*        <path fill="#666666" fillRule="nonzero" d="M16.46 9.27a.889.889 0 0 1 1.276 0 .938.938 0 0 1 0 1.305l-5.097 5.154a.888.888 0 0 1-1.278 0l-5.096-5.154a.936.936 0 0 1 0-1.305.889.889 0 0 1 1.276 0l4.46 4.227 4.458-4.227z"/> */}
                                    {/*    </svg> */}

                                    {/* </div> */}
                                    <div className="date" onClick={this.sortByDate}>
                                        <span>Invited Date</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                            className={cx({'up':sortBy==='date'&&(!desc)},{'no':sortBy!=='date'})}>
                                            <path fill="#666666" fillRule="nonzero" d="M16.46 9.27a.889.889 0 0 1 1.276 0 .938.938 0 0 1 0 1.305l-5.097 5.154a.888.888 0 0 1-1.278 0l-5.096-5.154a.936.936 0 0 1 0-1.305.889.889 0 0 1 1.276 0l4.46 4.227 4.458-4.227z"/>
                                        </svg>
                                    </div>

                                    <div className="status" onClick={this.sortByStatus}>
                                        <span>Status</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                            className={cx({'up':sortBy==='status'&&(desc)},{'no':sortBy!=='status'})}>
                                            <path fill="#666666" fillRule="nonzero" d="M16.46 9.27a.889.889 0 0 1 1.276 0 .938.938 0 0 1 0 1.305l-5.097 5.154a.888.888 0 0 1-1.278 0l-5.096-5.154a.936.936 0 0 1 0-1.305.889.889 0 0 1 1.276 0l4.46 4.227 4.458-4.227z"/>
                                        </svg>
                                    </div>
                                    {
                                        isTalentManager ?
                                            <div className="influencer" onClick={this.sortByInfluencer}>
                                                <span>Creator</span>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                                    className={cx({'up':sortBy==='status'&&(desc)},{'no':sortBy!=='status'})}>
                                                    <path fill="#666666" fillRule="nonzero" d="M16.46 9.27a.889.889 0 0 1 1.276 0 .938.938 0 0 1 0 1.305l-5.097 5.154a.888.888 0 0 1-1.278 0l-5.096-5.154a.936.936 0 0 1 0-1.305.889.889 0 0 1 1.276 0l4.46 4.227 4.458-4.227z"/>
                                                </svg>
                                            </div>
                                            : ''
                                    }

                                </div>
                                <Motion defaultStyle={{x:20, y:0}}
                                    style={{x: spring(0,{stiffness: 200, damping: 30}),
                                        y: spring(1)}}>
                                    {({x, y}) =>
                                        <div style={{ opacity: `${y}`,
                                            WebkitTransform: `translate3d(0,${x}px, 0)`,
                                            transform: `translate3d(0,${x}px, 0)`, transition:'none'}}>
                                            {
                                                Array.isArray(opportunityList) && opportunityList.map(opportunity => {
                                                    const slots = opportunity.assignmentSlots;
                                                    const {status} = opportunity;
                                                    const types = opportunity.deliverables && Object.keys(opportunity.deliverables) || [];
                                                    const posts=[];
                                                    const draftPosts = opportunity.userDraftPosts;
                                                    if (Array.isArray(types)&&types.length>0&&Array.isArray(draftPosts)) {
                                                        types.forEach(type=>{
                                                            const fpost=draftPosts.find(post=>post.type === type);
                                                            if (fpost) {
                                                                posts.push(fpost);
                                                            }
                                                        })
                                                    }
                                                    // const published = opportunity.userPublishedPosts;
                                                    let publishOverDue = -1;
                                                    let publishDate = null;
                                                    let draftOverDue = -1;
                                                    let draftDate = null;
                                                    if (Array.isArray(slots)) {
                                                        for (const slot of slots) {
                                                            if (slot.type === 'DRAFT') {
                                                                draftDate = slot.dueDate;
                                                                draftOverDue = moment().isAfter(draftDate, 'day') ? 1 :
                                                                    (moment(draftDate).isSame(moment(), 'day') ? 0 : -1);
                                                            }
                                                            if (slot.type === 'PUBLISH') {
                                                                publishDate = slot.dueDate;
                                                                publishOverDue = moment().isAfter(publishDate, 'day') ? 1 :
                                                                    (moment(publishDate).isSame(moment(), 'day') ? 0 : -1);

                                                            }
                                                        }
                                                    }

                                                    const oStatus= status;
                                                    if (oStatus === 'COMPLETED') {
                                                        publishOverDue = -1;
                                                    }
                                                    const statusObj = Lookup.getStatusType(oStatus);
                                                    const statusName = statusObj.label;
                                                    const statusColor = { color: statusObj.color };
                                                    const itemClass=focusKey===opportunity.id?"listOpportunity focus":"listOpportunity";
                                                    const assignmentId = opportunity.assignment.id;
                                                    const userId = opportunity.user.id;
                                                    const opportunityClickArgs = {
                                                        oStatus,
                                                        isTalentManager,
                                                        publishDate,
                                                        draftDate,
                                                        opportunityId: opportunity.id,
                                                        assignmentId,
                                                        userId,
                                                    };
                                                    const dueDate = opportunity.assignment && opportunity.assignment.dueDate && moment(opportunity.assignment.dueDate).format('ll');
                                                    return (
                                                        <div className={itemClass} key={opportunity.id}>
                                                            <div
                                                                className="listedOpportunity"
                                                                onClick={() => this.opportunityClickHandler(opportunityClickArgs)}
                                                            >
                                                                <div className="campaign-info-container">
                                                                    <div className="logo">
                                                                        <img src={opportunity.assignment.campaign.brandImage ? opportunity.assignment.campaign.brandImage : "/images/brandLogo.png"} alt='brand'/>
                                                                    </div>

                                                                    <div className="name">
                                                                        <div className="campName">{opportunity.assignment.campaign.name}</div>
                                                                        <div className="asmtName">{opportunity.assignment.name}</div>
                                                                    </div>
                                                                </div>

                                                                <div className="type">
                                                                    { 
                                                                        Array.isArray(types) && types.length > 0 && types.map((type, i) => {
                                                                            return <div key={type}>{`${Lookup.getRateType(type).label} x ${opportunity.deliverables[type]}`}</div>
                                                                        })

                                                                    }
                                                                </div>

                                                                {/* <div className="total"> */}
                                                                {/*    ${new Intl.NumberFormat({ currency:'CAD'}).format(Number(opportunity.totalRate))} */}
                                                                {/* </div> */}

                                                                <div className="date">
                                                                    {opportunity.invitedOn && moment.utc(opportunity.invitedOn).local().format('MMM DD, YYYY')}
                                                                </div>

                                                                <div className="status" style={statusColor}>
                                                                    {oStatus === 'COMPLETED'&& <img src={statusObj.img} alt="" />}
                                                                    {statusName}
                                                                    {dueDate && <div ><span>Due Date:</span> {dueDate}</div>}
                                                                    {/* {oStatus === 'ACCEPTED' && Boolean(publishDate) && <div className={publishClass}><span>Publish:</span> {moment(publishDate).format('MMM DD, YYYY')}</div> } */}
                                                                </div>
                                                                {
                                                                    isTalentManager ?
                                                                        <div>
                                                                            <div className="influencer-info">
                                                                                <div className="user-img">
                                                                                    <img src={opportunity.user.pictureUrl} alt=""/>
                                                                                </div>
                                                                                <div className="user-name">{`${opportunity.user.firstname} ${opportunity.user.lastname}`}</div>
                                                                            </div>
                                                                        </div>
                                                                        : ''
                                                                }

                                                            </div>
                                                            {opportunity.showPopupForStatusChanged &&
                                                                <div className="statusNotice">
                                                                    <img alt="" src="/images/ic-small-warning.svg" />
                                                                    Changes have been made to your rates and/or contract - please review before accepting
                                                                </div>
                                                            }
                                                            {oStatus === 'ACCEPTED' && ( (Array.isArray(posts) && posts.length>0) || publishOverDue > -1) &&
                                                            <div className="postlist">
                                                                {/* {publishOverDue > -1 && <div className="dueStatusNotice"><div>{ publishOverDue === 0 ? 'Publish Due ' : 'Post Past Due '}: {moment(publishDate).format('MMM DD, YYYY')}</div></div> } */}
                                                                {Array.isArray(posts) && posts.length>0 && posts.map((post)=>{
                                                                    return (
                                                                        <div className="post" key={post.id}>
                                                                            <div className="status" style={{color: Lookup.getStatusType(post.status).icolor}}>{Lookup.getStatusType(post.status).ilabel}</div>
                                                                            <div className="title">{Lookup.getRateType(post.type).label}</div>
                                                                        </div>
                                                                    )

                                                                })}
                                                            </div>}
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>}
                                </Motion>
                                <Pagination
                                    total={totalCount}
                                    limit={10}
                                    current={currentPage}
                                    parentContainer={this.container}
                                    handlePageChange={(e)=>this.handlePageChange(e)}
                                />
                            </div>
                        }

                    </div>
                </div>
            </div>
        )
    }
}
// const mapDispatchToProps = dispatch => {
//     return {
//         getLookupTables: () => {
//             dispatch(startLoadingLookupTables())
//         }
//     }
// };

const mapStateToProps= (state) => {
    return{
        profile: state.global.loggedInUserProfile
    }
};

export default connect(mapStateToProps, {})(OpportunityList)
