import cx from 'classnames';
import React, { Component } from 'react';
import ImageGallery from 'react-image-gallery';
import { connect } from 'react-redux';
import Select from 'react-select';
import { VIEWER_STR } from '../../../constants/authorities';
import { APPROVED, EDITS_SENT, NEEDS_REVIEW, PUBLISHED } from '../../../constants/statusTypes';
import Api from '../../../modules/Api';
import Lookup from '../../../modules/Lookup';
import ProfileHelper from '../../../modules/utils/ProfileHelper';
import '../../shared/InfChatBox/infTabHeaders.scss';
import ApiError from '../../shared/apiError/ApiError';
import ConfirmModalContinue from "../../shared/confirmModal/ConfirmModalContinue";
import CKEditor from '../../shared/editor/editor';
import TopNavigation from '../../shared/topNavigation/TopNavigation';
import ManageMediaModal from "./ManageMediaModal";
import UserDraftPostComments from "./UserDraftPostComments";
import UserDraftPostResources from "./UserDraftPostResources";
import './draftComment.scss';
import SaveNotifyWindow from "./saveNotifyWindow";

const resolutions = [{value: "fhdUrl", label: "1080p"}, {value: "shdUrl", label: "720p"}, {value: "sdUrl", label: "480p"}, {value: "lsdUrl", label: "360p"}];
class DraftComment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            assignment: '',
            comments: [],
            editorContent: '',
            draftPost: null,
            showVideo: false,
            showDownloading: false,
            resourceList: [],
            archivedResourceList: [],
            showSaveNotify: false,
            showConfirm: false,
            confirmType: '',
            isManageMediaModalVisible: false,
            isBrandViewer: false,
        };

        // let wnArray=window.frames.name.split('/_');
        this.editStatusType = {
            IN_REVIEW: 'IN_REVIEW',
            EDITS_SENT: 'EDITS_SENT',
            APPROVED: 'APPROVED',
        };

        this.showApiError = this.showApiError.bind(this);
        this.fetchResourceList = this.fetchResourceList.bind(this);
        this.assignmentId = this.props.match.params.assignmentId;
        this.postId = this.props.match.params.postId;
    }

    componentDidMount() {
        if (this.assignmentId && this.postId) {
            const {assignmentId} = this;
            const {postId} = this;

            Api.getAssignment(Number(assignmentId))
                .then((result) => {
                    this.setState({ assignment: result });
                })
                .catch((error) => {
                    this.showApiError(error);
                });

            this.fetchResourceList();

            Api.getDraftPost(Number(assignmentId), postId).then((result) => {
                this.setState({
                    isInvalid: result && result.status === "NEW",
                    invalidMsg: result && result.status === "NEW" && 'Post in progress' || '',
                    editorContent: result.content,
                    draftPost: result
                });
            }).catch((error) => {
                this.showApiError(error);
            });
            ProfileHelper.getCurrentLoginUserProfile()
                .then(profile => {
                    if (profile && profile.role === VIEWER_STR) {
                        this.setState({
                            isBrandViewer: true
                        });
                    }
                })
        }
    }

    fetchResourceList() {
        Api.getAllUserDraftPostResources(this.postId).then((result) => {
            const resourceList = [];
            if (result && result.content) {
                for (let i = 0; i < result.content.length; i++) {
                    resourceList.push({
                        ...result.content[i],
                        ...this.createOptions(result.content[i].resource),
                        size: 0,
                        percentage: 0,
                        isComplete: false,
                    });
                }
            }
            this.setState({
                resourceList: resourceList.filter(r => r.status === "ACTIVE"),
                archivedResourceList: resourceList.filter(r => r.status === "ARCHIVED")
            });
        }).catch((error) => {
            this.showApiError(error);
        });
    }

    createOptions = (res) => {
        const options = [];
        resolutions.map(resolution => {
            if (res[resolution.value]) {
                options.push(resolution);
            }
        })
        return {options, selected: options.length > 0 ? options[0].value : ""};
    }

    showApiError(error) {
        this.setState({
            showApiError: true,
            apiError: error,
        });
    }

    saveComments = (threadId, comments) => {
        const otherThreadsComments = this.state.comments.filter(c => c.threadId != threadId);
        const combinedComments = [...otherThreadsComments, ...comments];
        combinedComments.sort((a, b) => new Date(b.updatedOnLocal) - new Date(a.updatedOnLocal));

        this.setState({
            comments: combinedComments,
        });
    };

    approvePost = async () => {
        const { draftPost, assignment } = this.state;
        const data = {
            content: this.state.editorContent,
            status: this.editStatusType.APPROVED,
            type: draftPost.type,
            itemType: draftPost.itemType,
            title: draftPost.title,
        };

        try {
            const response = await Api.updateDraftPost(assignment.id, draftPost.id, data);

            if (!!response && assignment.campaign && assignment.campaign.id && assignment.id) {
                this.props.history.push(
                    `/campaignWork/${assignment.campaign.id}/posts/${assignment.id}`,
                );
            }
        } catch (error) {
            console.log(error);
        }
    };

    saveEditorData = (editorContent, suggestions = []) => {
        this.setState({
            editorContent,
            suggestions,
        });
    };

    saveDraftWithSuggestions = async (status, userList = []) => {
        const {
            draftPost: {
                id: draftPostId,
                type,
                itemType,
            },
            editorContent: content,
            assignment,
        } = this.state;
        const userIdList = userList.map(({ value }) => value);
        const data = {
            content,
            url: '',
            status,
            type,
            itemType,
            notificationAgentIds: userIdList
        };

        try {
            await Api.updateDraftPost(assignment.id, draftPostId, data);
            if (assignment.campaign && assignment.campaign.id && assignment.id) {
                this.props.history.push(`/campaignWork/${assignment.campaign.id}/posts/${assignment.id}`);
            }
        } catch (error) {
            this.setState({
                showApiError: true,
                apiError: error,
                waiting: false,
            });
        }
    };

    isValidVideo = (url) => {
        const tempUrl = url.toLowerCase();
        return (tempUrl.indexOf('.mov') != -1) || (tempUrl.indexOf('.mp4') != -1) || (tempUrl.indexOf('.ogg') != -1) || (tempUrl.indexOf('.webm') != -1);
    }

    isValidImage = (url) => {
        const tempUrl = url.toLowerCase();
        return (tempUrl.indexOf('.heic') != -1 || tempUrl.indexOf('.jpg') != -1) || tempUrl.indexOf('.jpeg') != -1 || (tempUrl.indexOf('.png') != -1) || (tempUrl.indexOf('.gif') != -1) || (tempUrl.indexOf('.webp') != -1);
    }

    showVideos = (resource, index) => {
        const videoPreview = {
            indexCount: index,
            original: resource.resource.url,
            thumbnail: '/images/ic-play.png',
            name: resource.resource.name,
            options: resource.options,
            selected: resource.resource.selected,
            isValidImage: this.isValidImage(resource.resource.name),
            isValidVideo: this.isValidVideo(resource.resource.name)
        };

        if (videoPreview.options.length > 0 && resource.resource[resource.resource.selected]) {
            videoPreview.original = resource.resource[resource.resource.selected];
            videoPreview.isValidVideo = true;
        }

        this.setState({
            showVideo: true,
            videoPreview,
        });
    }

    optionsChange = (option, videoPreview) => {
        const resourcesList = Object.assign([], this.state.resourceList);

        resourcesList[videoPreview.indexCount].resource.selected = option.value;

        videoPreview.selected = option.value;
        videoPreview.original = resourcesList[videoPreview.indexCount].resource[option.value];

        this.setState({
            videoPreview,
            resourceList: resourcesList
        })
    }

    apiError = (error) => {
        this.setState({
            showApiError: true,
            apiError: error,
        });
    }

    downloadVideo = (url, name, index) => {
        if (this.state.showDownloading === false) {
            // Api.getS3File(url, name); // download without progress bar
            this.setState({
                // enable download progress UI
                showDownloading: true
            }, () => {
                this.downloadFileListGradually(index, index + 1);
            });
        }
    }

    downloadAllVideos = () => {
        const resourcesList = this.state.resourceList;
        if (this.state.showDownloading === false) {
            this.setState({
                // enable download progress UI
                showDownloading: true
            }, () => {
                this.downloadAllHelperRef = this.downloadFileListGradually(0, resourcesList.length);
            });
        } else {
            for (let i = 0; i < resourcesList.length; i++) {
                resourcesList[i].percentage = 0;
                resourcesList[i].isComplete = false;
            }
            this.setState({
                showDownloading: false,
                resourceList: resourcesList
            });
        }
    }

    downloadFileListGradually(fileCountStart, fileCountEnd) {
        let currentFileCount = fileCountStart;

        const isCancelFileDownload = () => {
            return this.state.showDownloading === false;
        }

        const updateFileProgress = (fileNumber, progressPercentage) => {
            if (isCancelFileDownload()) {
                // cancel download
                return true;
            }
            if (progressPercentage > 0) {
                const downloadList = this.state.resourceList;
                downloadList[fileNumber].percentage = progressPercentage;
                downloadList[fileNumber].isComplete = false;
                this.setState({
                    resourceList: downloadList,
                    showDownloading: true,
                });
            }

        }

        const updateFileFinished = (fileNumber, response) => {
            const downloadList = this.state.resourceList;
            // download blob file data
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', downloadList[fileNumber].resource.name); // or any other extension
            document.body.appendChild(link);
            link.click();
            // Update UI
            downloadList[fileNumber].percentage = 100;
            downloadList[fileNumber].isComplete = true;
            this.setState({
                resourceList: downloadList,
                showDownloading: true,
            });
            // start next file download
            currentFileCount++
            downloadFile(currentFileCount);
        }

        const updateDownloadFileError = (err) => {
            const resourcesList = Object.assign([], this.state.resourceList);
            for (let i = 0; i < resourcesList.length; i++) {
                resourcesList[i].percentage = 0;
                resourcesList[i].isComplete = false;
            }
            this.setState({
                showApiError: true,
                apiError: err,
                showDownloading: false,
                resourceList: resourcesList,
            })
        }

        const downloadFile = (currentFileCount) => {
            const downloadList = this.state.resourceList;
            if (downloadList.length > 0 && currentFileCount > -1 && downloadList.length > currentFileCount && fileCountEnd > currentFileCount) {
                // Call gradual download
                Api.getS3FileGradually(downloadList[currentFileCount].resource.url,
                    (progressPercentage) => updateFileProgress(currentFileCount, progressPercentage),
                    (response) => updateFileFinished(currentFileCount, response),
                    (err) => updateDownloadFileError(err));
            } else {
                this.setState({
                    showDownloading: false
                })
            }
        }

        // start downloading first file
        downloadFile(currentFileCount);
    }

    createImages = (videoPreview) => {
        return [{ original: videoPreview.original }];
    }

    preConfirm = (confirmType) =>{
        this.setState({ showConfirm: true, confirmType });
    };

    confirmModalContinue = () => {
        const { confirmType } = this.state;
        if (confirmType === 'approve') {
            this.approvePost();
        } else {
            this.saveDraftWithSuggestions(EDITS_SENT);
        }
    };

    renderCreatorInfo() {
        const { draftPost } = this.state;
        if (!draftPost)
            return null;

        return (
            <div className="title user-info">
                {draftPost.userPictureUrl && <img src={draftPost.userPictureUrl} alt="" className="avatar" />}
                {!draftPost.userPictureUrl && <div className="filler">{draftPost.userFirstName[0]}</div>}
                <p>
                    {`${draftPost.userFirstName  } ${  draftPost.userLastName}`}
                </p>
                <div className="separator">
                    |
                </div>
                <p>
                    {Lookup.getRateType(draftPost.type).label + (Lookup.getRateType(draftPost.type).label.indexOf(' Post') < 0 ? ' Post' : '')}
                </p>
                <span className="user-info-status" style={{ backgroundColor: Lookup.getStatusType(draftPost.status).color }}>
                    {draftPost.status === 'APPROVED' && (
                        <img src={Lookup.getStatusType(draftPost.status).img} alt="" />
                    )}
                    {Lookup.getStatusType(draftPost.status).label}
                </span>
            </div>
        );
    }

    renderMediaAndComments(userDraftPost) {
        const { resourceList, isBrandViewer } = this.state;

        if (!userDraftPost)
            return null;

        return (
            <div className="media-and-comments">
                <div className="media">
                    <UserDraftPostResources resourceList={resourceList}
                        userDraftPostId={userDraftPost.id}
                        showVideos={this.showVideos}
                        downloadAllVideos={this.downloadAllVideos}
                        refreshResources={this.fetchResourceList}
                        showApiError={this.showApiError}
                        openMediaModal={() => this.setState({isManageMediaModalVisible: true})}
                        isBrandViewer={isBrandViewer}
                        draftPostStatus={userDraftPost.status}
                    />
                </div>
                <div className="comments">
                    <UserDraftPostComments
                        userDraftPostId={userDraftPost.id}
                    />
                </div>
            </div>
        );
    }

    render() {
        const { draftPost, assignment, showVideo, videoPreview, resourceList, archivedResourceList, isBrandViewer } = this.state;
        const { showSaveNotify, showConfirm, confirmType, isInvalid, invalidMsg } = this.state;
        const confirmTitle = confirmType === 'approve' ? 'Approve' : 'Send to creator';
        const confirmMsg =
            confirmType === 'approve'
                ? 'Are you sure? This will mark the content as approved and no additional changes can be made.'
                : 'Are you sure? This will send all the edits back to the creator. You will not be able to make additional changes until they have re-submitted for review.';
        const confirmBtn = confirmType === 'approve' ? 'Yes, Approve' : 'Yes, Send';
        const videoWidth = Math.floor(window.innerWidth * 0.6);

        return (
            <div className="draftCommentPage">
                <TopNavigation />
                <ApiError
                    show={this.state.showApiError}
                    error={this.state.apiError}
                    cancelFunction={() => this.setState({ showApiError: false })}
                />
                {this.state.isManageMediaModalVisible &&
                    <ManageMediaModal   close={() => this.setState({isManageMediaModalVisible: false})}
                        resources={resourceList}
                        archivedResources={archivedResourceList}
                        showVideos={this.showVideos}
                        userDraftPostId={draftPost.id}
                        showApiError={this.showApiError}
                        refreshResources={this.fetchResourceList}
                        withRestore/>
                }
                <ConfirmModalContinue
                    show={showConfirm}
                    maxWidth={420}
                    title={confirmTitle}
                    msg={confirmMsg}
                    actionText={confirmBtn}
                    cancelFunc={() => this.setState({ showConfirm: false, confirmType:'' })}
                    proceedFunc={this.confirmModalContinue}
                />
                <SaveNotifyWindow
                    show={showSaveNotify}
                    campaignId={assignment.campaign && assignment.campaign.id}
                    cancelFunc={() => this.setState({ showSaveNotify: false })}
                    saveFunc={() => this.saveDraftWithSuggestions(NEEDS_REVIEW)}
                    saveNotifyFunc={(users) => this.saveDraftWithSuggestions(NEEDS_REVIEW, users)}
                />
                <div style={{ borderTop: '1px solid #EEEEEE' }}>
                    {!isInvalid ? (
                        <div className="draftCommentContainer">
                            <div className="draftCommentWrapper">
                                <div className="pageHeaderContainer">
                                    <div className="pageHeader">
                                        <div className="title">
                                            <img src="/images/ic-back-b.svg"
                                                onClick={() => {
                                                    if (assignment.campaign && assignment.campaign.id && assignment.id) {
                                                        this.props.history.push(`/campaignWork/${assignment.campaign.id}/posts/${assignment.id}`)
                                                    }}
                                                }
                                            />
                                            <span>{assignment.name}</span>
                                        </div>
                                        <div className="actionButtons">
                                            {draftPost && draftPost.status !== APPROVED && draftPost.status !== EDITS_SENT && draftPost.status !== PUBLISHED && (
                                                <div>
                                                    <button
                                                        className={cx("save", {disabled: isBrandViewer})}
                                                        onClick={() => this.setState({showSaveNotify: true})}
                                                        disabled={isBrandViewer}
                                                        type="button"
                                                    >
                                                        Save & Notify
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="pageHeaderContainer">
                                    <div className="pageHeader">
                                        {this.renderCreatorInfo()}
                                        {draftPost && draftPost.status !== APPROVED && draftPost.status !== EDITS_SENT && draftPost.status !== PUBLISHED && (
                                            <div className="actionButtons">
                                                    <button
                                                        className={cx("send sendEdits", {disabled: isBrandViewer})}
                                                        onClick={() => this.preConfirm('sendEdits')}
                                                        type="button"
                                                        disabled={isBrandViewer}
                                                    >
                                                        Send to creator
                                                    </button>
                                                    <button
                                                        className={cx("button approve")}
                                                        onClick={() => this.preConfirm('approve')}
                                                        type="button"
                                                    >
                                                        Approve
                                                    </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {this.renderMediaAndComments(draftPost)}
                                <div
                                    className="draft-editor"
                                    onContextMenu={(e) => {
                                        e.preventDefault();
                                    }}
                                >
                                    <div className="draft-editor-content">
                                        <CKEditor
                                            user={this.props.profile}
                                            saveEditorData={this.saveEditorData}
                                            isNew={false}
                                            assignment={assignment}
                                            postId={draftPost && draftPost.id}
                                            draftPost={draftPost}
                                            saveComments={this.saveComments}
                                            readOnly={draftPost && ((draftPost.status === APPROVED|| draftPost.status === EDITS_SENT || draftPost.status === PUBLISHED) || isBrandViewer)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="what!?">{invalidMsg || "Invalid URL"}</div>
                    )}
                </div>
                {showVideo && videoPreview && videoPreview.original && (
                    <div className="video-preview">
                        <div className="video-preview-wrapper">
                            <div className="video-preview-actions">
                                <button
                                    className="download-btn"
                                    onClick={() => this.downloadVideo(videoPreview.original, videoPreview.name, videoPreview.indexCount)}
                                >
                                    <img
                                        src='/images/email-assets/ic-invited-assignment.png'
                                        alt="download"
                                    />
                                    Download
                                </button>
                                <div className="close-btn" onClick={() => this.setState({ showVideo: false })} />
                            </div>
                            {videoPreview.isValidVideo == true ?
                                <video
                                    src={videoPreview.original}
                                    controls
                                    autoPlay
                                    width={videoWidth}
                                >
                                    <source src={videoPreview.original} type="video/mp4" />
                                    <source src={videoPreview.original} type="video/ogg" />
                                    <source src={videoPreview.original} type="video/webm" />
                                    Your browser doesn't support HTML5 video tag.
                                </video>
                                : videoPreview.isValidImage === true ?
                                    <div className="image-preview-wrapper">
                                        <ImageGallery
                                            items={this.createImages(videoPreview)}
                                            showPlayButton={false}
                                            showFullscreenButton={false}
                                            showThumbnails={false}
                                        // onSlide={this.onSlide}
                                        />
                                    </div>
                                    : <div className="not-valid-video">
                                        Cannot play the file in browser. Please download the file to view it.
                                    </div>}
                            {videoPreview.isValidVideo == true && <div className="videoOptions">
                                <Select className="drop-up"
                                    clearable={false}
                                    searchable={false}
                                    value={videoPreview.selected}
                                    single
                                    disabled={!videoPreview.options.length}
                                    placeholder="Quality"
                                    options={videoPreview.options}
                                    onChange={(option) => this.optionsChange(option, videoPreview)}
                                />
                            </div>}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.global.loggedInUserProfile,
    };
};

export default connect(mapStateToProps, null)(DraftComment);
