import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import TopNavigation from '../shared/topNavigation/TopNavigation';
import './FAQ.scss';
import ProfileHelper from '../../modules/utils/ProfileHelper';
import { TALENT_MANAGER_STR, INFLUENCER_STR } from '../../constants/authorities';

const FAQ = () => {
    const [addExtraTop, setAddExtraTop] = useState(false);
    useEffect(() => {
        ProfileHelper.getCurrentLoginUserProfile().then((profile) => {
            if (
                profile &&
                (profile.role === TALENT_MANAGER_STR || profile.role === INFLUENCER_STR) &&
                !profile.accountVerified
            ) {
                setAddExtraTop(true);
            }
        });
    }, []);

    return (
        <div className="faq">
            <TopNavigation current="support" />
            <div className="opportunity">
                <div className={cx('opportunityContainer extra', { moreExtra: addExtraTop })}>
                    <div className="contentDiv">
                        <div className="supportPage">
                            <div className="spContent">
                                <h2>FAQ</h2>
                                <h3>A new team member needs access to the platform, what do I do?</h3>
                                Please contact your Super Admin or our team to assign a new user account to your new
                                team member. The new user will receive a temporary password in their inbox.
                                <h3>Am I required to fill out all portions of the profile?</h3>
                                You are not required to complete your profile in full. However, the more information you
                                provide, the more brands will be able to learn about you and see if you’re a good fit
                                for their influencer campaign.
                                <h3>Can I overlap draft and publishing schedules?</h3>
                                Yes – draft and publishing windows can overlap to fit your campaign's scheduling needs.
                                <h3>Can you outreach on this tool? Would this email probably go to spam?</h3>
                                To ensure that the message is coming first-hand from your brand or agency, we have
                                provided an email template with the Training Library for your use to outreach to
                                creators you want to work with. Emails from our platform do not go into spam folders.
                                <h3>Do I need to connect all social media channels to my account?</h3>
                                Brands often reach out to influencers based on their reach. Therefore, it’s in your best
                                interest to connect all social media channels. In addition, your social media channels
                                must be connected to verify your content, which triggers compensation.
                                <h3>How will I receive payment?</h3>
                                Once you verify all required deliverables and upload a signed W9 to the Influential platform, your payment will be processed.  Payment method is determined by the agency or brand whether it be by direct deposit, third-party vendors like PayPal, etc.
                                <h3>How would content approvals work if you need to take them offline for the client to review and revise as well?</h3>
                                Our platform's content editor allows multiple parties to provide feedback before sending edits over to the creators. This can be achieved by clicking the "save" button. Click on the "send edits" button to notify the creator when all parties have finished their review.
                                <h3>Is my personal information secure?</h3>
                                We understand the need for privacy and all your proprietary information will always be secure. The amount of information you share on your profile is up to you. However, the more information you share, the easier it is for brands to see if you’re a campaign fit.
                                <h3>Is our agency/company information secure?</h3>
                                Yes! Our state-of-the-art blockchain technology secures all information and ensures immutability within a digital ledger.
                                <h3>Is this tool free for creators? or talent managers, who would be handling negotiations for them?</h3>
                                This platform is FREE for all creators and talent managers to participate. Signing up on Koalifyed is quick and easy.
                                <h3>What does "flight" mean when scheduling an assignment?</h3>
                                A "flight" is the period in which the assignment starts and ends. Within that period, windows to submit drafts and publish content are made. The creators will have to select the dates they will be submitting their draft and published content for that assignment.
                                <h3>What happens if a creator I want to use isn't connected to the channel I need work done on?</h3>
                                Our platform will alert you if a creator has missing connected social channels that are included in your assignment. You can ask a creator to connect to the social channel or make other arrangements by clicking on "Contact Influencer".
                                <h3>What happens if a creator needs to revise the dates they gave to submit their draft and/or published content?</h3>
                                The creator can request a schedule change on their end. To review that request, visit the "Influencers" tab within an assignment to review schedule change requests.
                                <h3>What is a pixel?</h3>
                                An assignment may or may not require you to apply a pixel when publishing content. A pixel is a piece of code installed on your blog that loads each time someone visits your site. The pixel provides data such audience demographics, traffic patterns and audience patterns. The pixel does not allow us to track PII (personally identifiable information) about your visitors.
                                <h3>What kinds of influencers would this tool work with? Does the fraud detection and verification process work only with TW, IG and FB? Does it extend to blogs, LinkedIn, TikTok, etc?</h3>
                                Although we support Instagram, Facebook, Twitter, Youtube and Blogs, our fraud detection technology currently only evaluates Instagram followers to verify the creator's true reach.
                                <h3>Where can I find a creators authenticity score?</h3>
                                If a creator has been scanned through our anti-fraud technology, their authenticity score can be found on the bottom right corner of their profile pic.
                                <h3>Who can access my profile?</h3>
                                All brands and agencies who have joined Koalifyed can view the information you share on your profile.
                                <h3>Why don't all creators who have joined have an authenticity score?</h3>
                                We prioritize the creators you want to use within your campaigns. Two actions trigger a fraud detection scan: inviting a creator onto the platform or adding an existing creator into your campaign.
                                <h3>Would influencers have to be signed up for this tool as well? How would this work to get creators onboarded?</h3>
                                Yes. In order for brands and agencies to work with creators, and vice versa, creators must join the platform to link their their social channels to obtain account data. Under our "Playbook" section in the Training Library, we have an email template to use for your internal outreach to the creators you want to join the platform.
                                <h3>Would the NDA process be outside of this?</h3>
                                If an NDA needs to be addressed and signed by the creators you are working with, this will have to be dealt with outside the platform. The contract for your campaign lives on the platform.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FAQ;
