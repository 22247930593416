import React from 'react';
import Select from 'react-select';
import './filters.scss';

export const sortOptions = [
    { label: 'Likes: High to low', value: 'DESC' },
    { label: 'Likes: Low to high', value: 'ASC' },
];

function SortFilter ({ sortOption, setSortOption }) {
  const valueRenderer = (props) => (
    <div className='comment-sort-value-label'>
      <span className="filter-placeholder">{props.label}</span>
    </div>
  );

  const menuRenderer = ({ options }) => (
    <>
        {options.map((option) => (
            <div 
              key={option.label} 
              className={`sort-option ${sortOption.value === option.value ? 'selected' : ''}`}
              onClick={() => setSortOption(option)}
            >
                {option.label}
            </div>
        ))}
    </>
);

  return (
    <Select
      className="comments-sort"
      searchable={false}
      value={sortOption}
      options={sortOptions}
      menuRenderer={menuRenderer}
      valueRenderer={valueRenderer}
      clearable={false}
    />
  );
};

export default SortFilter;
